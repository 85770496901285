import React from 'react';
import Fade from '@mui/material/Fade';

//Components
import {BlankIcon, CheckedIcon, IndeterminateIcon} from './components/checkbox-icons';
import IconRouter from 'hsi/components/IconRouter';
import * as components from './components';
import colors from './colors';
import elements from './elements';

//Classes
import ThemeBuilder from 'hsi/classes/ThemeBuilder';

//Other
import baseTheme from 'hsi/themes/_base';
import './components/checkbox-icons/styles';
import {ComponentsProps} from '@mui/material/styles';
import {Colors} from 'hsi/types/theme';

//The theme
const theme = new ThemeBuilder(
    'bw',
    true,
    {
        components,
        colors: function () {
            return (
                ThemeBuilder.nullMerge<Partial<Colors>>(colors, this._getExtendedValue('colors')) ||
                {}
            );
        },
        font: () => ({
            url: 'https://fonts.googleapis.com/css2?family=Roboto:wght@200;300;400;500;700&display=swap&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese',
            name: "'Roboto', sans-serif",
            light: 200,
            medium: 300,
            bold: 700,
            semiBold: 500,
            regular: 400,
        }),
        fonts: ({font}) => ({
            display2Strong: {
                fontSize: '40px',
                fontWeight: font.semiBold,
            },
            display1Normal: {
                fontSize: '32px',
                fontWeight: font.light,
            },
            display1Strong: {
                fontSize: '32px',
                fontWeight: font.semiBold,
            },
            headlineNormal: {
                fontSize: '22px',
                fontWeight: font.medium,
            },
            headlineStrong: {
                fontSize: '22px',
                fontWeight: font.semiBold,
            },
            headtitleNormal: {
                fontSize: '17px',
                fontWeight: font.regular,
            },
            headtitleStrong: {
                fontSize: '17px',
                fontWeight: font.bold,
            },
            bodyLargeNormal: {
                fontSize: '14px',
                fontWeight: font.regular,
            },
            bodyLargeStrong: {
                fontSize: '14px',
                fontWeight: font.bold,
            },
            bodyNormal: {
                fontSize: '13px',
                fontWeight: font.regular,
            },
            bodyStrong: {
                fontSize: '13px',
                fontWeight: font.bold,
            },
            bodySmall: {
                fontSize: '12px',
                fontWeight: font.regular,
            },
            bodySmallStrong: {
                fontSize: '12px',
                fontWeight: font.bold,
            },
            buttonLarge: {
                fontSize: '14px',
                fontWeight: font.semiBold,
                textTransform: 'uppercase',
            },
            buttonNormal: {
                fontSize: '12px',
                fontWeight: font.semiBold,
                textTransform: 'uppercase',
            },
            labelStrong: {
                fontSize: '10px',
                fontWeight: font.bold,
                textTransform: 'uppercase',
            },
            labelNormal: {
                fontSize: '10px',
                fontWeight: font.regular,
                textTransform: 'uppercase',
            },
        }),
        mixin: function ({colors}) {
            const baseMixins = this._getExtendedValue('mixin');

            const mixins = {
                linkColor: {
                    color: colors.textHyperlink,
                    fill: colors.textHyperlink,
                    '&:visited': {
                        color: colors.textHyperlink,
                        fill: colors.textHyperlink,
                    },
                    '&:hover': {
                        color: colors.accentHover,
                        fill: colors.accentHover,
                    },
                    '&:active': {
                        color: colors.accentHover,
                        fill: colors.accentHover,
                    },
                },
            } as any;

            mixins.linkButton = {
                ...baseMixins.blankButton,
                ...mixins.linkColor,

                fontSize: 'inherit',
                display: 'inline',

                '&:hover': {
                    textDecoration: 'underline',
                },

                '&:focus': {
                    textDecoration: 'underline',
                },
            };

            return ThemeBuilder.nullMerge(mixins, baseMixins);
        },
        globals: function (theme) {
            const {colors, font, fonts} = theme;

            return ThemeBuilder.nullMerge(
                {
                    'html,body': {
                        fontFamily: font.name,
                        color: colors.darkGrey100,
                        backgroundColor: colors.uiGrayDarker,
                        fontSize: '0.875rem',
                        lineHeight: 1.43,
                        letterSpacing: '0.01071em',
                    },
                    input: {
                        '&::-webkit-input-placeholder, &::placeholder': {
                            fontStyle: 'normal !important',
                            fontWeight: `${fonts.bodyNormal!.fontWeight} !important`,
                            color: `${colors.darkGrey60} !important`,
                            fontSize: `${fonts.bodyNormal!.fontSize} !important`,
                        },
                        '&:-moz-placeholder, &::-moz-placeholder,&:-ms-input-placeholder': {
                            fontStyle: 'normal !important',
                            fontWeight: `${fonts.bodyNormal!.fontWeight} !important`,
                            color: `${colors.darkGrey60} !important`,
                            fontSize: `${fonts.bodyNormal!.fontSize} !important`,
                        },
                    },
                    textarea: {
                        '&::-webkit-input-placeholder, &::placeholder': {
                            fontStyle: 'normal !important',
                            fontWeight: `${fonts.bodyNormal!.fontWeight} !important`,
                            color: `${colors.darkGrey60} !important`,
                            fontSize: `${fonts.bodyNormal!.fontSize} !important`,
                        },
                        '&:-moz-placeholder, &::-moz-placeholder,&:-ms-input-placeholder': {
                            fontStyle: 'normal !important',
                            fontWeight: `${fonts.bodyNormal!.fontWeight} !important`,
                            color: `${colors.darkGrey60} !important`,
                            fontSize: `${fonts.bodyNormal!.fontSize} !important`,
                        },
                    },

                    '.recharts-legend-wrapper': {
                        paddingTop: '20px',
                        '& ul.recharts-default-legend': {
                            position: 'relative',
                            '& .recharts-legend-item': {
                                display: 'inline-flex',
                                margin: '0 8px',
                                lineHeight: '20px',
                                '& .recharts-surface': {
                                    flex: '0 0 12px',
                                    width: '12px',
                                    height: '12px',
                                    alignSelf: 'center',
                                    borderRadius: '50%',
                                    marginRight: '0 !important',
                                },
                                '& .recharts-legend-item-text': {
                                    flex: '1 1 auto',
                                    fontSize: '12px',
                                    paddingLeft: '5px',
                                    color: colors.darkGrey100,
                                    maxWidth: '200px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                },
                            },
                        },
                    },

                    '.save-search-cta': {
                        width: '150px',
                        fontSize: '14px',
                        textAlign: 'left',
                        fontWeight: 400,
                        color: colors.darkGrey100,

                        '& span, & a': {
                            color: '#007faf',
                            fontWeight: font.semiBold,
                        },
                        '& .read-only': {
                            span: {
                                color: colors.darkGrey100,
                            },
                        },
                    },

                    '.video-react-big-play-button': {
                        top: '50% !important',
                        left: '50% !important',
                        transform: 'translate(-50%, -50%)',
                    },
                    '.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${colors.error} !important`, //!important is needed to override anotehr important style. I do not know where that style is
                    },
                    //primaryBlue10
                    '.grey-select-field': {
                        borderRadius: '3px !important',
                        '& .MuiInputBase-formControl': {
                            backgroundColor: `${colors.white}`,
                            borderRadius: '3px !important',
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: `1px solid ${colors.borders} !important`,
                            },
                            '& .MuiOutlinedInput-input': {
                                paddingLeft: '12px',
                                color: colors.textDefault,
                            },
                            '& input': {
                                fontWeight: `${fonts.bodyNormal!.fontWeight} !important`,
                                '&::-webkit-input-placeholder, &::placeholder': {
                                    opacity: 1,
                                    fontStyle: 'normal !important',
                                    fontWeight: `${fonts.bodyNormal!.fontWeight} !important`,
                                    color: `${colors.textDisabled} !important`,
                                },
                                '&:-moz-placeholder, &::-moz-placeholder,&:-ms-input-placeholder': {
                                    opacity: 1,
                                    fontStyle: 'normal !important',
                                    fontWeight: `${fonts.bodyNormal!.fontWeight} !important`,
                                    color: `${colors.textDisabled} !important`,
                                },
                            },

                            '&.Mui-disabled': {
                                backgroundColor: `${colors.bgDisabled}`,
                                '& input': {
                                    '&::-webkit-input-placeholder, &::placeholder': {
                                        opacity: 1,
                                        fontStyle: 'normal !important',
                                        fontWeight: `${fonts.bodyNormal!.fontWeight} !important`,
                                        color: `${colors.textDisabled} !important`,
                                    },
                                    '&:-moz-placeholder, &::-moz-placeholder,&:-ms-input-placeholder':
                                        {
                                            opacity: 1,
                                            fontStyle: 'normal !important',
                                            fontWeight: `${
                                                fonts.bodyNormal!.fontWeight
                                            } !important`,
                                            color: `${colors.textDisabled} !important`,
                                        },
                                },
                            },
                        },
                    },
                },
                this._getExtendedValue('globals'),
            );
        },

        elements: elements,

        overrides: ({fonts, font, colors}) => {
            return {
                /********     BUTTON    ********/
                MuiButton: {
                    root: {
                        padding: '12px 16px',
                        height: '40px',
                        lineHeight: '16px',
                        fontSize: fonts.buttonNormal!.fontSize,
                        fontWeight: fonts.buttonNormal!.fontWeight,
                        borderRadius: '3px',
                        textTransform: fonts.buttonNormal!.textTransform,
                    },
                    contained: {
                        boxShadow: 'none',
                        backgroundColor: colors.white,
                        color: colors.accentDefault,
                        border: `1px solid ${colors.borders}`,
                        transition:
                            'background-color 0.15s ease-out 0s, border-color 0.1s ease-out 0s, box-shadow 0.1s ease-out 0s',
                        '&:hover': {
                            color: colors.accentHover,
                            backgroundColor: colors.bgSubtle,
                            border: '1px solid transparent',
                            boxShadow: 'none',
                        },
                        '&:active': {
                            boxShadow: 'none',
                            color: colors.accentActive,
                            backgroundColor: colors.bgSubtle,
                            border: '1px solid transparent',
                        },
                        '&:focus': {
                            boxShadow: `0 0 0 2pt ${colors.secondaryOrange100}`,
                            color: colors.accentActive,
                            backgroundColor: colors.bgSubtle,
                            outline: 'none',
                            border: '1px solid transparent',
                        },
                        '&.Mui-disabled': {
                            border: `1px solid ${colors.borders}`,
                            color: colors.textDisabled,
                            backgroundColor: colors.white,
                        },
                    },
                    containedPrimary: {
                        color: colors.white,
                        backgroundColor: colors.accentDefault,
                        border: '1px solid transparent',
                        borderRadius: '3px',
                        '&:hover': {
                            backgroundColor: colors.accentHover,
                            color: colors.white,
                            boxShadow: 'none',
                            border: '1px solid transparent',
                        },
                        '&:active': {
                            backgroundColor: colors.accentActive,
                            color: colors.white,
                            boxShadow: 'none',
                            border: '1px solid transparent',
                        },
                        '&:focus': {
                            backgroundColor: colors.accentActive,
                            color: colors.white,
                            boxShadow: `0 0 0 2pt ${colors.secondaryOrange100}`,
                            outline: 'none',
                            border: '1px solid transparent',
                        },
                        '&.Mui-disabled': {
                            color: colors.textDisabled,
                            backgroundColor: colors.bgDisabled,
                            border: '1px solid transparent',
                        },
                    },
                    containedSecondary: {
                        boxShadow: 'none',
                        backgroundColor: colors.white,
                        color: colors.accentDefault,
                        border: `1px solid ${colors.borders}`,
                        transition:
                            'background-color 0.15s ease-out 0s, border-color 0.1s ease-out 0s, box-shadow 0.1s ease-out 0s',
                        '&:hover': {
                            color: colors.accentHover,
                            backgroundColor: colors.bgSubtle,
                            border: `1px solid ${colors.borders}`,
                            boxShadow: 'none',
                        },
                        '&:active': {
                            boxShadow: 'none',
                            color: colors.accentActive,
                            backgroundColor: colors.bgSubtle,
                            border: `1px solid ${colors.borders}`,
                        },
                        '&:focus': {
                            boxShadow: `0 0 0 2pt ${colors.secondaryOrange100}`,
                            color: colors.accentActive,
                            backgroundColor: colors.bgSubtle,
                            outline: 'none',
                            border: `1px solid ${colors.borders}`,
                        },
                        '&.Mui-disabled': {
                            border: `1px solid ${colors.borders}`,
                            color: colors.textDisabled,
                            backgroundColor: colors.white,
                        },
                    },
                    text: {
                        transition:
                            'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        padding: '0 16px',
                        color: colors.accentDefault,
                        border: 'none',
                        backgroundColor: 'transparent',
                        '&:hover': {
                            color: colors.accentHover,
                            boxShadow: 'none',
                            backgroundColor: 'transparent',
                        },
                        '&:active': {
                            boxShadow: 'none',
                            backgroundColor: 'transparent',
                        },
                        '&:focus': {
                            boxShadow: `0 0 0 2pt ${colors.secondaryOrange100}`,
                            outline: 'none',
                            backgroundColor: 'transparent',
                        },
                        '&.Mui-disabled': {
                            color: colors.textDisabled,
                            border: 'none',
                            backgroundColor: 'transparent',
                        },
                    },
                    textPrimary: {
                        color: colors.accentDefault,
                        '& .startIcon, & .endIcon': {
                            '& svg': {
                                fill: colors.accentDefault,
                            },
                        },
                        '&:hover': {
                            color: colors.accentDefault,
                            boxShadow: 'none',
                            backgroundColor: colors.bgHover,
                        },
                        '&:active': {
                            boxShadow: 'none',
                            color: colors.accentDefault,
                            backgroundColor: colors.bgActive,
                        },
                    },
                    sizeSmall: {
                        fontSize: '12px',
                        lineHeight: '16px',
                        textTransform: 'uppercase',
                        height: 40,
                        fontWeight: 500,
                    },
                    iconSizeSmall: {
                        '& svg': {
                            width: 10,
                            height: 10,
                        },
                    },
                },

                /********   BUTTON GROUP    *******/
                MuiButtonGroup: {
                    grouped: {
                        color: colors.primaryBlue100,
                        border: `0px solid transparent`,
                        backgroundColor: colors.primaryBlue100,
                        '&:not(:last-child)': {
                            borderRight: 'none',
                        },
                        '&:not(:first-child)': {
                            borderLeft: 'none',
                            marginLeft: '0px',
                        },
                        '&:hover': {
                            backgroundColor: colors.primaryBlue60,
                            color: colors.primaryBlue100,
                            boxShadow: 'none',
                        },
                    },
                    groupedOutlinedHorizontal: {
                        '&:not(:first-child)': {
                            marginLeft: '0px',
                        },
                    },
                },

                /********   ICON BUTTON    *******/
                MuiIconButton: {
                    root: {
                        color: colors.bgOverlayHeavy,
                        borderWidth: '0px',
                        borderStyle: 'solid',
                        borderColor: 'none',
                        padding: '14px',
                        backgroundColor: 'transparent',
                        transition:
                            'background-color 0.15s ease-out 0s, border-color 0.1s ease-out 0s, box-shadow 0.1s ease-out 0s',
                        '& svg': {
                            fill: colors.bgOverlayHeavy,
                        },
                        '&:hover, &.active': {
                            backgroundColor: 'transparent',

                            '& svg': {
                                fill: colors.accentHover,
                                color: colors.accentHover,
                            },
                        },
                        '&:focus': {
                            boxShadow: `0 0 0 2pt ${colors.accentHover}`,
                            outline: 'none',
                        },
                        '&:active': {
                            backgroundColor: 'transparent',
                        },
                    },
                },

                /********  OUTLINED INPUT  *********/
                MuiOutlinedInput: {
                    root: {
                        backgroundColor: colors.white,
                        color: colors.textDefault,
                        borderRadius: '3px',
                        lineHeight: '28px',
                        '&.Mui-focused': {
                            '& .notchedOutline': {
                                boxShadow: 'none',
                                border: `1px solid ${colors.borders}`,
                            },
                        },
                        '&.Mui-error': {
                            '& .notchedOutline': {
                                border: `2px solid ${colors.primaryRed100} !important`,
                            },
                        },
                        '& .app-icon.icon-down-arrow': {
                            position: 'absolute',
                            right: '7px',
                            top: 'calc(50% - 7px)',
                            pointerEvents: 'none',
                            fill: colors.bgOverlayHeavy,
                        },
                    },
                    input: {
                        fontSize: fonts.bodyNormal!.fontSize,
                        padding: '0px 8px',
                        height: '40px',
                        fontWeight: fonts.bodyNormal!.fontWeight,
                    },
                    multiline: {
                        fontSize: fonts.bodyNormal!.fontSize,
                        padding: '0px',
                        height: 'auto',
                        fontWeight: fonts.bodyNormal!.fontWeight,
                    },
                    inputMultiline: {
                        height: 'auto',
                        padding: '6px 12px',
                    },
                    notchedOutline: {
                        border: `1px solid ${colors.borders} !important`,
                        borderRadius: '3px',
                        borderColor: colors.borders,
                        top: 0,
                        height: 'auto',
                        '& legend': {
                            display: 'none !important',
                        },
                    },
                },

                /****  FORM LABEL (textfield mainly) ****/
                MuiFormLabel: {
                    root: {
                        position: 'static !important' as any,
                        transform: 'none !important',
                        color: `${colors.textDefault} !important`,
                        fontSize: fonts.bodyNormal!.fontSize,
                        fontWeight: fonts.bodyNormal!.fontWeight,
                        marginBottom: '5px',
                        lineHeight: '1.5',
                    },
                },

                /**** FORM HELPER TEXT ****/
                MuiFormHelperText: {
                    root: {
                        fontSize: 13,
                        lineHeight: '20px',
                        //margin: '12px 12px 0 0 !important',
                        margin: '4px 0 !important',
                        display: 'block !important',
                    },
                },
                /**** MUI FORM CONTROL LABEL (checkbox label mainly) ****/
                MuiFormControlLabel: {
                    root: {
                        height: '40px',
                        marginLeft: 0,
                    },
                    label: {
                        fontSize: '14px',
                        color: colors.textDefault,
                        fontWeight: font.semiBold,
                        paddingRight: '12px',
                        userSelect: 'text!important' as any,
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        lineHeight: 0,
                    },
                },

                /**** CHECKBOX   *****/
                MuiCheckbox: {
                    root: {
                        width: '40px',
                        height: '40px',
                        backgroundColor: 'transparent',
                        border: 'none !important',
                        '& input': {
                            width: '40px',
                            height: '40px',
                        },
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    },
                },

                /***** MENU ******/
                MuiMenu: {
                    paper: {
                        borderRadius: '2px !important',
                        borderImage: 'initial',
                        border: `2px solid ${colors.accentDefault} !important`,
                        boxShadow: 'rgba(47, 54, 56, 0.2) 0px 16px 24px 0px !important',
                    },
                },

                /****** MENU ITEM *****/
                MuiMenuItem: {
                    root: {
                        '&.Mui-selected': {
                            backgroundColor: colors.bgHover,
                            fontWeight: 700,
                            '&:hover': {
                                backgroundColor: colors.bgHover,
                            },
                        },
                        '&:hover': {
                            backgroundColor: colors.bgHover,
                        },
                        height: '40px',
                        minHeight: '40px',
                        borderWidth: '2px',
                        borderColor: 'transparent',
                        fontSize: fonts.bodyNormal!.fontSize,
                        fontWeight: fonts.bodyNormal!.fontWeight,
                        color: colors.textSubtle,
                        '&.link-item': {
                            color: 'inherit',
                        },
                        '& .bw-simple-menu-item-icon': {
                            marginRight: '0px',
                        },
                        '& .bw-simple-menu-item-text': {
                            padding: '0',
                            '& span': {
                                fontSize: '14px !important',
                            },
                        },
                    },
                    gutters: {
                        paddingLeft: '24px',
                        paddingRight: '24px',
                    },
                },

                MuiList: {
                    root: {
                        '&:focus': {
                            outline: 'none',
                        },
                    },
                },

                MuiListItemText: {
                    primary: {
                        fontSize: fonts.bodyNormal!.fontSize,
                        fontWeight: fonts.bodyNormal!.fontWeight,
                    },
                },

                /**** SNACK BAR *****/

                MuiSnackbar: {
                    root: {
                        zIndex: 10200,
                    },
                },
                /*********    CARD   *********/
                MuiCard: {
                    root: {
                        borderRadius: '3px',
                        boxShadow: 'none',
                        backgroundColor: colors.white,
                        '@media print': {
                            border: `1px solid ${colors.borders}`,
                        },
                    },
                },
                MuiCardHeader: {
                    root: {
                        paddingTop: '28px',
                        paddingLeft: '28px',
                        paddingRight: '28px',
                        paddingBottom: '22px',
                    },
                    content: {
                        overflow: 'hidden',
                    },
                    title: {
                        fontSize: fonts.headtitleStrong!.fontSize,
                        color: colors.textDefault,
                        fontWeight: fonts.headtitleStrong!.fontWeight,
                        lineHeight: '25px',
                        paddingBottom: '3px',
                    },
                    subheader: {
                        fontSize: '12px',
                        color: colors.textDefault,
                        lineHeight: '17px',
                    },
                    action: {
                        '& button': {
                            marginTop: '-3px',
                            marginRight: '-4px',
                        },
                    },
                },
                MuiCardContent: {
                    root: {
                        fontSize: '14px',
                        paddingTop: '0px',
                        paddingLeft: '28px',
                        paddingRight: '28px',
                        paddingBottom: '28px',
                        '&:last-child': {
                            paddingBottom: '15px',
                        },
                    },
                },

                /*********    DRAWER   *********/
                MuiDrawer: {
                    root: {
                        display: 'flex',
                    },
                    paper: {
                        backgroundColor: colors.uiGrayDark,
                        position: 'relative',
                    },
                    docked: {
                        height: '100%',
                    },
                    paperAnchorDockedLeft: {
                        borderLeft: 'none',
                        borderRight: 'none',
                        top: '0px',
                        left: '0px',
                        height: '100%',
                    },
                    paperAnchorDockedRight: {
                        borderLeft: 'none',
                        borderRight: 'none',
                        top: '0px',
                        right: '0px',
                        height: '100%',
                    },
                },

                /*********    DIALOG   *********/
                MuiDialog: {
                    paper: {
                        width: '596px',
                        minHeight: '237px',
                        border: `1px solid ${colors.borders}`,
                        boxShadow: `0px 4px 9px ${colors.borders}`,
                        borderRadius: '3px',
                    },
                },
                MuiDialogTitle: {
                    root: {
                        color: colors.textDefault,
                        margin: '0',
                        padding: '10px 20px',
                        borderBottom: `1px solid ${colors.borders}`,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        '&.MuiTypography-root': {
                            fontSize: fonts.headtitleNormal!.fontSize,
                            textOverflow: 'ellipsis',
                            fontWeight: fonts.headtitleNormal!.fontWeight,
                            paddingTop: 14,
                            paddingBottom: 14,
                        },
                    },
                },
                MuiDialogContent: {
                    root: {
                        padding: '24px',
                        margin: '0',
                        //All of this needs to go eventually
                        '& p': {
                            fontSize: '14px',
                            color: colors.textDefault,
                            paddingLeft: '6px',
                            wordBreak: 'break-word',
                        },
                        '& .info': {
                            margin: '0 0 24px 0',
                            '& .category': {
                                fontSize: fonts.bodyStrong!.fontSize,
                                fontWeight: fonts.bodyStrong!.fontWeight,
                                //color: font.textDefault,//Probably meant to be colors.textDefault? But hasn't been doing anything, so probably safest to leave commented out
                                paddingBottom: '5px',
                            },
                            '& .value': {
                                fontSize: fonts.bodyNormal!.fontSize,
                                fontWeight: fonts.bodyNormal!.fontWeight,
                            },
                        },
                    },
                },
                MuiDialogActions: {
                    root: {
                        width: '100%',
                        height: '76px',
                        backgroundColor: colors.white,
                        padding: '24px',
                        margin: '0',
                    },
                },
                MuiPaper: {
                    root: {
                        color: colors.textDefault,
                        '& .error-message': {
                            display: 'block',
                        },
                    },
                },
                MuiBackdrop: {
                    root: {
                        backgroundColor: colors.bgOverlayHeavy,
                    },
                },

                /*********    TOOLTIP   *********/
                MuiTooltip: {
                    tooltip: {
                        backgroundColor: '#2F3638',
                        fontSize: '14px',
                        wordBreak: 'break-word',
                        position: 'relative',
                        top: '-5px',
                        minWidth: '50px',
                    },
                    popper: {
                        '&[data-popper-placement*="bottom"] span.arrow': {
                            position: 'absolute',
                            top: '0',
                            left: '25%',
                            right: '75%',
                            marginTop: '-0.95em',
                            fontSize: '6px',
                            width: '3em',
                            height: '1em',
                            '&::before': {
                                content: '""',
                                margin: 'auto',
                                display: 'block',
                                width: '0',
                                height: '0',
                                borderStyle: 'solid',
                                borderWidth: '0 1em 1em 1em',
                                borderColor: 'transparent transparent #2F3638 transparent',
                            },
                        },
                    },
                },
                /*********    List Items   *********/
                MuiListItem: {
                    root: {
                        '&.Mui-selected': {
                            backgroundColor: colors.bgHover,
                            fontWeight: 700,
                            '&:hover': {
                                backgroundColor: colors.bgHover,
                            },
                        },
                        '&:hover': {
                            backgroundColor: colors.bgHover,
                        },
                    },
                    dense: {
                        paddingTop: '2px',
                        paddingBottom: '2px',
                    },
                    button: {
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                },

                MuiListItemIcon: {
                    root: {
                        minWidth: '48px',
                    },
                },

                /*********    Chip   *********/
                MuiChip: {
                    root: {
                        color: colors.textDefault,
                        fontWeight: fonts.bodyNormal!.fontWeight,
                        fontSize: fonts.bodyNormal!.fontSize,
                        height: '32px',
                        padding: '0',
                        borderRadius: '50px',
                        backgroundColor: colors.bgActive,
                        '&.hlight': {
                            backgroundColor: colors.bgSubtle,
                        },
                    },
                    clickable: {
                        '&:hover': {
                            backgroundColor: colors.bgSubtle,
                        },
                        '&:focus': {
                            backgroundColor: colors.bgSubtle,
                            boxShadow: `0 0 0 2pt ${colors.secondaryOrange100}`,
                            outline: 'none',
                        },
                        '&:active': {
                            backgroundColor: colors.bgSubtle,
                        },
                    },
                    label: {
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        lineHeight: '20px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '180px',
                        fontWeight: fonts.bodyNormal!.fontWeight,
                        fontSize: fonts.bodyNormal!.fontSize,
                        display: 'inline-block',
                    },
                    icon: {
                        height: '14px',
                        width: '14px',
                        marginRight: '0',
                        marginLeft: '8px',
                        fill: colors.bgOverlayHeavy,
                        flex: '0 0 auto',
                    },
                    deleteIcon: {
                        marginLeft: '0',
                        marginRight: '12px',
                        height: '14px',
                        width: '14px',
                        fill: colors.bgOverlayHeavy,
                        flex: '0 0 auto',
                        '&:hover': {
                            fill: colors.accentActive,
                        },
                    },
                    sizeSmall: {
                        height: 22,
                    },
                    labelSmall: {
                        fontSize: '12px',
                        lineHeight: '22px',
                    },
                    iconSmall: {
                        height: 10,
                        width: 10,
                        marginLeft: 8,
                    },
                    deleteIconSmall: {
                        height: 12,
                        width: 12,
                        marginLeft: 0,
                        marginRight: 8,
                    },
                    deletable: {
                        '&:hover, &:focus': {
                            backgroundColor: colors.bgActive,
                        },
                    },
                },

                /*********    SELECT   *********/
                MuiSelect: {
                    root: {
                        color: colors.textDefault,
                        backgroundColor: colors.white,
                        '& .app-icon.icon-chevron-down': {
                            position: 'absolute',
                            right: '7px',
                            top: 'calc(50% - 7px)',
                            pointerEvents: 'none',
                            fill: colors.bgOverlayHeavy,
                        },
                    },
                },
                MuiDivider: {
                    root: {
                        borderColor: colors.borders,
                    },
                },

                /*
                 * @material-ui/pickers
                 * https://material-ui-pickers.dev/guides/css-overrides
                 */
                MuiPickersDay: {
                    current: {
                        backgroundColor: colors.primaryBlue20,
                    },
                    day: {
                        '&:hover': {
                            backgroundColor: colors.primaryBlue10,
                        },
                    },
                },
            };
        },

        props: (): ComponentsProps => ({
            MuiDialog: {
                maxWidth: false,
            },
            MuiButton: {
                disableRipple: true,
            },
            MuiRadio: {
                disableRipple: true,
            },
            MuiFormControlLabel: {
                className: 'chbox-label',
            },
            MuiCheckbox: {
                disableRipple: true,
                icon: <BlankIcon />,
                checkedIcon: <CheckedIcon />,
                indeterminateIcon: <IndeterminateIcon />,
            },
            MuiFormHelperText: {
                //component: 'div',//This is erroring in TS, meaning it weas probably never doing anything?
            },
            MuiMenu: {
                TransitionComponent: Fade,
                disableAutoFocusItem: true,
                MenuListProps: {
                    disablePadding: true,
                },
            },
            MuiPopover: {
                TransitionComponent: Fade,
            },
            MuiChip: {
                deleteIcon: <IconRouter name="close" />,
            },
        }),
        palette: function ({colors}) {
            return ThemeBuilder.nullMerge(
                {
                    primary: {
                        main: colors.primaryBlue80!,
                        dark: colors.primaryBlue100!,
                        contrastText: '#FFF',
                    },
                    secondary: {
                        main: colors.primaryYellow80!,
                        dark: colors.primaryYellow100,
                        contrastText: '#FFF',
                    },
                    error: {
                        main: colors.error!,
                    },
                    background: {
                        default: colors.lightGrey100,
                    },
                },
                this._getExtendedValue('palette'),
            );
        },
        typography: function ({font}) {
            return ThemeBuilder.nullMerge(
                {
                    fontSize: 14,
                    fontFamily: font.name,
                },
                this._getExtendedValue('typography'),
            );
        },
    },
    //Extends
    baseTheme,
);

export default theme;
