import {QueryType} from 'hsi/types/shared';

export default function getQueryTypeFromUrl(
    location: {pathname?: string},
    urlParams: Record<string, string>,
): QueryType | undefined {
    if (location.pathname?.startsWith('/search/edit')) {
        return urlParams.id && urlParams.projectId ? 'edit' : undefined;
    }

    if (location.pathname?.startsWith('/search/results')) {
        return urlParams.id && urlParams.projectId ? 'saved' : 'quick';
    }

    return undefined; //not actually in a search
}
