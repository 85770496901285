// TODO: Add tests

/**
 * This should probably be refactored to use the CheckboxList component.
 */
import {useCallback} from 'react';

import {Link} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';

//Components
import IconRouter from 'hsi/components/IconRouter';
import MultipleOptionsPopover from 'hsi/containers/MultipleOptionsPopover';
import Tooltip from 'hsi/components/Tooltip';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import {useLinkedinChannels} from 'hsi/hooks/useDataSource';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useLoadMentions from 'hsi/hooks/useLoadMentions';
import useQueryContext from 'hsi/hooks/useQueryContext';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import useFlags from 'hsi/hooks/useFlags';

//Other
import useStyles from './styles';
import {selectSavingQuery} from 'hsi/selectors';
import {clearResults} from 'hsi/actions/resultsActions';
import {persistActiveQuery, setLinkedinChannelIds} from 'hsi/actions/queryActions';
import {T} from 'hsi/i18n';
import {LinkedinChannelIdsType} from 'hsi/types/shared';

type LinkedinSelectorProps = {
    className?: string;
    currentLinkedInChannelIds: string[];
    linkedinChannels: ReturnType<typeof useLinkedinChannels>;
    availableLinkedInChannelIds: string[];
    initialAdditionalQueries: number[];
};

export default function LinkedinSelector({
    className,
    currentLinkedInChannelIds,
    linkedinChannels,
    availableLinkedInChannelIds,
    initialAdditionalQueries,
}: LinkedinSelectorProps) {
    const {classes, cx} = useStyles();
    const dispatch = useAppDispatch();
    const {track} = useEventTrack();
    const loadMentions = useLoadMentions();
    const {project} = useQueryContext();
    const {
        multipleSearches: {maxMultipleSearches},
    } = useConfig();
    const {hasDashboardPinAndHide} = useFlags();

    const isSavingQuery = useAppSelector(selectSavingQuery);

    const onSave = useCallback(
        (ids: string[]) => {
            if (isSavingQuery) {
                return;
            }

            //Sadly, there seems to be some confusion in the code as to whether linkedin
            //channel ids are strings or numbers, so we're converting back and forth the whole time
            const nextLinkedinChannelIds: LinkedinChannelIdsType = ids.map(Number);

            track('linkedinChannelsSaved', {channels: nextLinkedinChannelIds});
            dispatch(setLinkedinChannelIds(nextLinkedinChannelIds));
            dispatch(persistActiveQuery());

            dispatch(clearResults());
            loadMentions({append: false});
        },
        [dispatch, loadMentions, isSavingQuery, track],
    );

    const getOptionLabel = (id: string) => {
        const isActive = !!linkedinChannels.active.find(channel => channel.id === id);
        let channelName = `${isActive ? '' : 'INACTIVE: '}${linkedinChannels.data?.find((channel) => channel.id === id)?.name}`
        return project?.name
            ? `${channelName} (${T('team')}: ${project.name})`
            : channelName ?? 'LinkedIn page';
    };

    //IF there are no selected linked-in channels, we use the black and white version of the icon
    //otherwise, we use the coloured version
    const iconName = currentLinkedInChannelIds?.length! > 0 ? 'linkedin' : 'linkedin-bw';

    return <>
        <MultipleOptionsPopover<string>
            dialogTitle={T('linkedinChannels.multipleOptionsPopoverTitle')}
            options={availableLinkedInChannelIds}
            initialOptions={currentLinkedInChannelIds}
            getOptionLabel={getOptionLabel}
            title={T('linkedinChannels.popover.title')}
            info={hasDashboardPinAndHide ? T('linkedinChannels.popover.info') : undefined}
            noOptionsMessage={<NoOptions />}
            onOpen={() => track('linkedinMenuClicked')}
            onSaveSelected={onSave}
            otherSavedSearches={initialAdditionalQueries}
            maxOptions={maxMultipleSearches}
            disabledOptionTooltip={T('multipleSearch.popover.disabledOptionTooltip', {
                maxOptions: maxMultipleSearches,
            })}
        >
            <Tooltip tooltip={T('linkedinChannels.buttonLbl')} noAria>
                <IconButton
                    className={cx(classes.addMoreButton, className)}
                    data-testid="linkedinSelectorButton"
                    aria-label={T('linkedinChannels.buttonLbl')}
                    size="large">
                    <IconRouter aria-hidden className={classes.addMoreIcon} name={iconName} />
                </IconButton>
            </Tooltip>
            
        </MultipleOptionsPopover>
    </>;
}

function NoOptions() {
    const config = useConfig();

    return (
        <>
            <div style={{textAlign: 'center', marginTop: 16, marginBottom: 16}}>
                {T('linkedinChannels.popover.noResults')}
            </div>
            <Link to={config.dataManagement.rootUrl}>
                {T('linkedinChannels.popover.addChannel')}
            </Link>
        </>
    );
}
