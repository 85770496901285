//The standard method for creating an entry - ensures all minimally required setup is performed
//This isn't 100% required a to create a new entry, but is heavily recommended.
// If you wish to create a heavily customised entry without using this, please
// ensure that you are doing all the required initialisation step, e.g. you import
// universal-imports, and call the config set method with the config.

import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {ThemeProvider, Theme, StyledEngineProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {createBrowserHistory, History} from 'history';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

//Components
import DefaultApp from 'hsi/containers/App';

//utils
import configureStore, {ConfiguredPreloadedState, Store} from 'hsi/utils/configureStore';
import loadIcons from 'hsi/utils/loadIcons';
import createRootReducer from 'hsi/reducers/rootReducer';

//Other
import {set} from 'hsi/config';
import ConfigContext from 'hsi/contexts/ConfigContext';
import 'hsi/utils/universal-imports';
import {AriaAnnounceProvider} from 'hsi/hooks/useAriaAnnounce';
import ThemeBuilder from 'hsi/classes/ThemeBuilder';
import {T} from 'hsi/i18n';

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

//The utility
export default function entryFactory(
    entryPath: string,
    config: any,
    theme: ThemeBuilder,
    {
        //redux stuff
        preloadedState = undefined,
        getPreConfiguredStore,

        //Components
        App = DefaultApp,
    }: {
        preloadedState?: ConfiguredPreloadedState;
        getPreConfiguredStore?: any;
        App?: React.ElementType;
    } = {},
) {
    //record config globally
    set(config);

    //Init the history and Redux store
    const history = getHistory(entryPath);

    const [store, connectedHistory] = getStore(getPreConfiguredStore, history, preloadedState);

    //Load icons
    config.svgPath && loadIcons(config.svgPath);

    const queryClient = new QueryClient();

    //Finally render the initial app
    const root = createRoot(document.getElementById('root')!);

    root.render(
        <BaseApp config={config} theme={theme} store={store} queryClient={queryClient}>
            <App history={connectedHistory} />
        </BaseApp>,
    );
}

type BaseAppProps = {
    children: JSX.Element;
    config: any;
    queryClient: QueryClient;
    store: Store;
    theme: ThemeBuilder;
};

export function BaseApp({config, theme, store, children, queryClient}: BaseAppProps) {
    //Global styles hook
    theme.getGlobalStyles()();

    return (
        <ConfigContext.Provider value={config}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme.muiTheme}>
                    <CssBaseline />
                    <Provider store={store}>
                        <AriaAnnounceProvider>
                            <QueryClientProvider client={queryClient}>
                                {children}
                            </QueryClientProvider>
                        </AriaAnnounceProvider>
                    </Provider>
                </ThemeProvider>
            </StyledEngineProvider>
        </ConfigContext.Provider>
    );
}

document.getElementById('header')?.setAttribute('aria-label', T('headerLbl'));

export function getHistory(entryPath: string) {
    return entryPath ? createBrowserHistory({basename: `/${entryPath}/`}) : createBrowserHistory();
}

export function getStore(
    getPreConfiguredStore: any = undefined,
    history: History,
    preloadedState?: ConfiguredPreloadedState,
) {
    return (
        getPreConfiguredStore?.(history) ||
        configureStore(createRootReducer, history, preloadedState)
    );
}

//In development and staging, supress unhelpful console errors/messages
if(process.env.NODE_ENV !== 'test' && (process.env.REACT_APP_DEPLOYMENT_TYPE === 'development' || process.env.DEPLOYMENT_TYPE === 'development' || process.env.DEPLOYMENT_TYPE === 'stage')) {
    const errorsToSuppress = [
        'The pseudo class ":nth-child" is potentially unsafe when doing server-side rendering. Try changing it to ":nth-of-type".'
    ];

    const consoleError = console.error;

    console.error = (...args) => {
        if(!errorsToSuppress.includes(args[0]))
        {
            consoleError(...args);
        }
    }
} else if(process.env.NODE_ENV === 'test') {
    const consoleError = console.error;

    console.error = (...args) => {
        if(!args[0].startsWith("Warning: An update to"))
        {
            consoleError(...args);
        }
    }
}
