
//Entry configuration
import theme from 'hsi/themes/cision';
import config from 'hsi/configurations/c3/config';

//Utils
import entryFactory from 'hsi/utils/entry-factory';

//Init the app
entryFactory('c3', config, theme, {});
