import {excludeConfig} from './excludeConfig';
import {parseTerms, parseLinks, parseHashtags} from 'hsi/utils/formulaParser';
import {brandContextComponent} from './brandConfig';

import tns from 'hsi/i18n/tns';
const t = tns('queryBuilder');

const issueIncludeConfig = {
    name: 'include',
    label: 'include',
    operator: 'OR',
    title: t('includeTitle'),
    components: [
        brandContextComponent,

        {
            name: 'terms',
            type: 'input-chips',
            title: t('issueTermsTitle'),
            subtitle: t('issueTermsSubtitle'),
            placeholder: t('issueTermsPlaceholder'),
            parseFormula: parseTerms,
            chipElemProps: {
                newChipChars: ',',
            },
        },

        {
            name: 'links',
            type: 'input-chips',
            title: t('issueLinksTitle'),
            subtitle: t('issueLinksSubtitle'),
            placeholder: t('issueLinksPlaceholder'),
            parseFormula: parseLinks,
        },

        {
            name: 'hashtags',
            type: 'input-hashtags',
            title: t('issueHashtagsTitle'),
            subtitle: t('issueHashtagsSubtitle'),
            placeholder: t('issueHashtagsPlaceholder'),
            parseFormula: parseHashtags,
        },
    ],
};

const issueConfig = {
    sections: [issueIncludeConfig, excludeConfig],
};

export {issueConfig};
