import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({v2, colors}) => {
    let base = {
        inputRoot: {
            '&[class*="MuiOutlinedInput-root"]': {
                paddingTop: 3,
                paddingBottom: 3,
            },
        },
        input: {
            paddingTop: !v2 ? '5px !important' : '0px !important',
            paddingBottom: !v2 ? '5px !important' : '0px !important',
        },
        popupIndicator: {
            backgroundColor: 'transparent !important',
        },
        clearIndicator: {
            backgroundColor: 'transparent !important',
        },
        option: {
            '&.Mui-focused': {
                backgroundColor: 'transparent',
            },
            '&:hover': {
                backgroundColor: colors.bgHover,
            },
        },
    };
    return v2
        ? base
        : {
              ...base,
              option: {
                  '&[aria-selected="true"]': {
                      backgroundColor: `${colors.primaryYellow40} !important`,
                  },
                  '&.Mui-focused': {
                      backgroundColor: 'transparent',
                  },
                  '&:hover': {
                      backgroundColor: colors.primaryYellow20,
                  },
              },
          };
});
