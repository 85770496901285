import React, {useCallback, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';

//Components
import {Dialog, DialogContent, DialogActions} from 'hsi/components/Dialog';
import AddEditAlert from './AddEditAlert';

//Actions
import {clearError} from 'hsi/slices/alerts';

//Other
import useStyles from './styles.js';

//The component
export default function AlertDialog({title, alert, queryId, open, closeFunc, savedSearch}) {
    const {classes} = useStyles();
    const dispatch = useDispatch();

    const [isVisible, setIsVisible] = useState(open);

    const onClose = useCallback(() => {
        dispatch(clearError());
        closeFunc();
    }, [closeFunc, dispatch]);

    const onClosed = useCallback(() => {
        setIsVisible(false);
    }, []);

    useEffect(() => {
        open && setIsVisible(true);
    }, [open]);

    //Rendering
    return (
        <Dialog
            title={title}
            open={open}
            onClose={onClose}
            onClosed={onClosed}
            className={classes.alertDialog}
        >
            {(open || isVisible) && (
                <AddEditAlert //Each time the dialog is opened, we get a new instance of this component, which prevents state from being carried over from previous alerts
                    alert={alert}
                    queryId={queryId}
                    savedSearch={savedSearch}
                    onClose={onClose}
                    wrapContent={wrapContent}
                    wrapActions={wrapActions}
                />
            )}
        </Dialog>
    );
}

AlertDialog.propTypes = {
    title: PropTypes.string,
    alert: PropTypes.object,
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    savedSearch: PropTypes.object,
};

//Internal helper methods
function wrapContent(children) {
    return <DialogContent>{children}</DialogContent>;
}

function wrapActions(children) {
    return <DialogActions>{children}</DialogActions>;
}
