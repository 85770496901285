import qs from 'qs';

import http from 'hsi/classes/Http';

export const serializeParams = (params) => {
    return qs.stringify(params, {addQueryPrefix: true, indices: false});
};

const temporaryTagsParamRename = (params) => {
    //quick fix for tags that allows to get any mention that is using at least one tag
    if (params?.tag) {
        params.anyTag = params.tag;
        delete params['tag'];
    }
    return params;
};
export const urlByType = {
    saved: (projectId) => `/api/projects/${projectId}/data/mentions`,
    quick: (projectId) => `/api/projects/${projectId}/search/data/mentions`,
};

export const fetchByType = {
    saved: (url) => async (params, filters) => {
        const {body} = await http.get(
            `${url}${serializeParams(temporaryTagsParamRename({...params, ...filters}))}`,
        );

        return body;
    },

    quick: (url) => async (params, filters) => {
        let {query, ...otherParams} = params;
        const {body} = await http.get(
            `${url}${serializeParams({search: query, ...otherParams, ...filters})}`,
        );

        return body;
    },
};

export const fetch = (searchType, projectId) => {
    const getFetch = fetchByType[searchType];
    const getUrl = urlByType[searchType];
    if (!getFetch || !getUrl) throw new Error(`Search type [${searchType}] not supported`);
    return getFetch(getUrl(projectId));
};

export const loadMentions = async ({
    projectId,
    searchType,
    searchId,
    query,
    startDate,
    endDate,
    filters,
    page,
    pageSize,
}) => {
    try {
        const data = await fetch(searchType, projectId)(
            {
                queryId: searchId,
                query,
                startDate,
                endDate,
                page,
                pageSize,
            },
            filters,
        );
        return {data};
    } catch (error) {
        return {error};
    }
};
