import {
    CLEAR_REPORT_ERROR,
    CLEAR_REPORT_SUCCESS,
    CREATE_REPORT,
    DELETE_REPORT,
    EDIT_REPORT,
    LOAD_REPORTS,
    LOAD_REPORTS_ERROR,
    LOADING_REPORT_ITEM,
    LOADING_REPORTS,
    REPORT_ERROR,
    TOGGLE_REPORT,
} from 'hsi/constants/actionTypes';

import * as reportsService from 'hsi/services/reportsService';

const parseInterval = (interval, timespanTypes) => ({
    timespan: Object.keys(timespanTypes).find((key) => timespanTypes[key] === interval.type),
    hours: interval.schedule.schedules[0].h[0],
    minutes: interval.schedule.schedules[0].m[0],
    startDate: interval.startDate,
});

const formatReport = (rawReport, getState, timespanTypes) => {
    if (!rawReport) {
        throw new Error(`Missing required argument 'rawReport' in 'formatReport'`);
    }
    const {searches} = getState().search;
    const query = searches?.find(({id}) => id === rawReport.queryData?.queryId[0]);

    return {
        active: rawReport.active,
        createdAt: rawReport.createdAt,
        id: rawReport.id,
        name: rawReport.name,
        nextRunAt: rawReport.nextRunAt,
        projectId: rawReport.queryData?.projectId,
        queryId: rawReport.queryData?.queryId,
        queryName: query?.name,
        recipients: rawReport.recipients,
        ...parseInterval(rawReport.interval, timespanTypes),
    };
};

export const loadReports =
    ({errorMsg, timespanTypes}) =>
    async (dispatch, getState) => {
        const projects = getState().user?.projects;

        dispatch({type: LOADING_REPORTS});
        try {
            const reports = await reportsService.listReports({projects});
            dispatch({
                type: LOAD_REPORTS,
                payload: {
                    reports: reports.map((report) => formatReport(report, getState, timespanTypes)),
                },
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: LOAD_REPORTS_ERROR,
                payload: {
                    message: errorMsg,
                    variant: 'warning',
                },
            });
        }
    };

export const createReport =
    ({errorMsg, report, successMsg, timespanTypes}) =>
    async (dispatch, getState) => {
        dispatch({type: LOADING_REPORT_ITEM, payload: report});

        try {
            const response = await reportsService.createReport({...report});
            dispatch({
                type: CREATE_REPORT,
                payload: {
                    message: successMsg,
                    report: formatReport(response.body, getState, timespanTypes),
                    variant: 'success',
                },
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: REPORT_ERROR,
                payload: {
                    message: errorMsg,
                    report,
                    variant: 'warning',
                },
            });
        }
    };

export const editReport =
    ({errorMsg, report, successMsg, timespanTypes}) =>
    async (dispatch, getState) => {
        dispatch({type: LOADING_REPORT_ITEM, payload: report});

        try {
            const response = await reportsService.editReport({...report});
            dispatch({
                type: EDIT_REPORT,
                payload: {
                    message: successMsg,
                    report: formatReport(response.body, getState, timespanTypes),
                    variant: 'success',
                },
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: REPORT_ERROR,
                payload: {
                    message: errorMsg,
                    report,
                    variant: 'warning',
                },
            });
        }
    };

export const deleteReport =
    ({errorMsg, report, successMsg}) =>
    async (dispatch) => {
        dispatch({type: LOADING_REPORT_ITEM, payload: report});

        try {
            await reportsService.deleteReport(report);
            dispatch({
                type: DELETE_REPORT,
                payload: {
                    message: successMsg,
                    report,
                    variant: 'success',
                },
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: REPORT_ERROR,
                payload: {
                    message: errorMsg,
                    report,
                    variant: 'warning',
                },
            });
        }
    };

export const toggleReport =
    ({errorMsg, report, successMsg, timespanTypes}) =>
    async (dispatch, getState) => {
        dispatch({type: LOADING_REPORT_ITEM, payload: report});
        const {active, id} = report;

        try {
            const response = await reportsService.updateReport({id, params: {active: !active}});
            dispatch({
                type: TOGGLE_REPORT,
                payload: {
                    message: successMsg,
                    report: formatReport(response.body, getState, timespanTypes),
                    variant: 'success',
                },
            });
        } catch (error) {
            dispatch({
                type: REPORT_ERROR,
                payload: {
                    message: errorMsg,
                    report,
                    variant: 'warning',
                },
            });
        }
    };

export const clearReportError = () => (dispatch) => {
    dispatch({type: CLEAR_REPORT_ERROR});
};

export const clearReportSuccess = () => (dispatch) => {
    dispatch({type: CLEAR_REPORT_SUCCESS});
};
