import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);

    return {
        insightsContainer: {
            display: 'flex',
            height: 'auto',
            width: '100%',
            margin: '0 auto',
            position: 'relative',
            flex: '1 1 auto',
            overflow: 'hidden',
        },
        filters: {
            flex: '0 0 auto',
        },
        content: {
            flex: '1 1 0',
            //transition: 'flex-grow 400ms',
            width: 0,
            position: 'relative',
            [theme.breakpoints.up('lg')]: {
                marginLeft: 0,
                marginRight: 0,
            },
            display: 'flex',
            flexFlow: 'column nowrap',
        },
        contentCollapsed: {
            flexGrow: 0,
        },
        validationContainer: {
            paddingTop: '10vh',
        },
        hide: {
            display: 'none',
        },

        toggleFiltersButtonWrapper: {
            position: 'sticky',
            zIndex: 2,
            top: 0,
        },

        toggleFiltersButton: {
            position: 'absolute',
            top: '18px',
            right: '8px',
        },

        filtersWrapper: {
            padding: '10px 20px 20px',
        },

        filtersHeader: {
            position: 'sticky',
            zIndex: 1,
            top: 0,
            padding: '20px 45px 20px 0',
            display: 'flex',
            justifyContent: 'space-between',
            background: v2 ? theme.colors.uiGrayDark : theme.colors.lightGrey60,
        },

        filtersTitle: {
            lineHeight: '24px',
            paddingLeft: '20px',
            margin: 0,

            ...(v2
                ? theme.fonts.headtitleStrong
                : {
                      fontSize: '20px',
                      fontWeight: theme.font.bold,
                  }),
        },

        clearAllFilters: {
            ...theme.mixin.blankButton,
            ...(v2
                ? theme.fonts.headtitleStrong
                : {
                      fontSize: '20px',
                      fontWeight: theme.font.bold,
                  }),

            color: v2 ? theme.colors.textHyperlink : theme.colors.secondaryBlue100,
            lineHeight: '24px',
        },
    };
});
