import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

//Components
import PlatformIcon from 'hsi/components/PlatformIcons';
import Checkbox from 'hsi/components/Checkbox';
import Label from 'hsi/components/Filters/Label';

//Hooks
import useFiltersState from 'hsi/components/Filters/hooks/useFiltersState';

//Other
import useStyles from './styles';
import {T} from 'hsi/i18n';

//The component
export default function CheckboxesFilter({
    compact,
    onChange,
    filterConf: {filterName, disabled, label, options},
}) {
    const {classes, cx} = useStyles();

    const {
        useSelector,
        actions: {toggleIsCheckboxChecked},
    } = useFiltersState();
    const filterState = useSelector((state) => state.filters[filterName]);

    return (
        <fieldset className={classes.checkboxesFilter}>
            {!!label && (
                <Label indent component="legend">
                    {T(label)}
                </Label>
            )}
            <List className={cx(classes.checkboxesFilterList, 'no-focus-outline')}>
                {options.map((item, i) => {
                    const value = typeof item.value != 'undefined' ? item.value : item;
                    const label = T(item.label || item);

                    return (
                        <ListItem
                            key={i}
                            dense
                            disableGutters
                            className={cx(classes.checkboxWrapper, disabled && classes.disabled)}
                        >
                            <Checkbox
                                disabled={disabled}
                                checked={!!filterState[value]}
                                onChange={() => {
                                    toggleIsCheckboxChecked(filterName, value);
                                    onChange &&
                                        onChange(
                                            filterName,
                                            'checkboxes',
                                            value,
                                            !filterState[value],
                                        );
                                }}
                                className={cx(classes.checkbox, compact && classes.compact)}
                                inputProps={{'aria-label': label}}
                                children={
                                    <>
                                        {!!item.iconName && (
                                            <PlatformIcon
                                                aria-hidden
                                                name={item.iconName}
                                                className={classes.checkboxIcon}
                                            />
                                        )}
                                        <span aria-hidden>{label}</span>
                                    </>
                                }
                                data-testid={(label || '').toLowerCase()}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </fieldset>
    );
}
