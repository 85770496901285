import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles((theme: Theme) => {
    const v2 = isV2(theme);
    const {colors, elements} = theme;

    return {
        drawer: {
            "--search-help-drawer-width": '360px',
            position: 'fixed',
            top: elements.searchBarContainerHeight,
            bottom: 0,
            right: 0,
            display: 'flex',
            transform: 'translate(var(--search-help-drawer-width), 0)',
            willChange: 'transform',
            transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',

            '&$open': {
                transform: 'translate(0, 0)',
            },
        },
        open: {},
        content: {
            display: 'flex',
            flexFlow: 'column nowrap',
            minHeight: 0,
            width: 'var(--search-help-drawer-width)',
            boxShadow: v2 ? `-1px 0px 0px 0px ${colors.borders}` : undefined,
            background: colors.lightGrey60,
        },
        sticky: {
            position: 'sticky',
            backgroundColor: 'inherit',
            top: '0px',
            zIndex: 1,
        },
        scroll: {
            overflowY: 'scroll',
        },
        intro: {
            padding: 0,
            margin: '0 16px 5px',
        },
    };
});
