//TODO Intro storage
//add a mechanism to rename stored keys (IDs, disabled to finished, etc)
//detect and migrate renamed keys that were previously saved (remove old, create news)

const loadIntroState = (id: string) => localStorage.getItem(id);
const saveIntroState = (id: string, value: string) => localStorage.setItem(id, value);

export const saveIntroFinished = (id: string) => saveIntroState(id, 'disabled');
export const isIntroFinished = (id: string) => loadIntroState(id) === 'disabled';
//export const isIntroInitialized = id => !isNaN(parseInt(loadIntroState(id)));

export const saveStepIdx = (id: string, idx: number) => saveIntroState(id, String(idx));
export const loadStepIdx = (id: string) => {
    const idx = parseInt(loadIntroState(id) || '0');
    return !isNaN(idx) ? idx : 0;
};
