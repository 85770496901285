import React, {useCallback} from 'react';
import cn from 'classnames';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//The component
export default function ClearFiltersButton({clearFilterEnabled, handleClear, clearAll, compact}) {
    const classes = useStyles();

    const handleClearFilters = useCallback(
        (e) => {
            e.stopPropagation();
            handleClear();
        },
        [handleClear],
    );

    return clearFilterEnabled ? (
        <button
            type="button"
            onClick={handleClearFilters}
            className={cn(classes.clear, compact && classes.compact, {divider: clearAll})}
        >
            {clearAll ? T('filters.clearAll') : T('filters.clear')}
        </button>
    ) : null;
}
