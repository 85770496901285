//Components
import IconRouter from "hsi/components/IconRouter";
import Button from "hsi/components/Button";
import classNames from "classnames";

//Other
import useStyles from './styles';
import {T} from 'hsi/i18n';

//Types
type NotificationPropsWithoutRetry = {
    title: string;
    description?: string;
    retry?: never;
    retryLbl?: never;
    retryDesc?: never;
    role?: 'alert' | 'error',
} & Omit<JSX.IntrinsicElements['div'], 'title' | 'role'>;

type NotificationPropsWithRetry = {
    title: string;
    description?: string;
    retry: () => void;
    retryLbl?: string;
    retryDesc: string;
    role?: 'alert' | 'error',
} & Omit<JSX.IntrinsicElements['div'], 'title' | 'role'>;

type NotificationProps = NotificationPropsWithRetry | NotificationPropsWithoutRetry;

//The component
export default function Notification({title, description, retry, retryDesc, retryLbl = T('notification.retryLbl'), role = 'alert', className,  ...rest}: NotificationProps) {
    const classes = useStyles();

    return <div className={classNames(classes.notification, className)} {...rest}>
        <IconRouter name="c-info" aria-hidden className={classes.icon} />
        <div className={classes.message} role={role}>
            <div className={classes.title}>
                {title}
            </div>
            {description && <div
                className={classes.description}
            >
                {description}
            </div>}
        </div>
        
        {retry && <Button priority="cta" onClick={retry} aria-label={retryDesc}>{retryLbl}</Button>}
    </div>
}
