import React from 'react';
import MuiSwitch, {SwitchProps} from '@mui/material/Switch';

//Components
import Checkbox from 'hsi/components/Checkbox';

//Hooks
import useConfig from 'hsi/hooks/useConfig';

//Other
import {T} from 'hsi/i18n';
import useStyles, {switchTheme as useSwitchTheme} from './styles';

//Consts
const switchSizeStyles = {
    normal: undefined,
    large: {
        '--switch-size-base': '24px',
    },
} as const;

//Types
interface ToggleProps extends Omit<SwitchProps, 'size'> {
    showLabel?: boolean;
    mode?: 'switch' | 'checkbox';

    /**
     * Only applies when in switch mode
     */
    size?: keyof typeof switchSizeStyles;
}

//The component
export default function Toggle({
    checked,
    onChange,
    showLabel = false,
    id,
    className,
    name,
    mode = 'checkbox',
    size = 'normal',
    ...props
}: ToggleProps) {
    const {classes, cx} = useStyles();
    const switchThemeClasses = useSwitchTheme().classes;
    const {toggle: config} = useConfig();

    mode = mode || config.defaultMode;

    switch (mode) {
        case 'switch':
            return (
                <div className={cx(classes.container, className)} style={switchSizeStyles[size]}>
                    <MuiSwitch
                        classes={switchThemeClasses}
                        checked={checked}
                        onChange={onChange}
                        color="primary"
                        id={id}
                        inputProps={name ? {'aria-labelledby': name} : undefined}
                        {...props}
                    />
                    {showLabel && (
                        <div>{checked ? T('queryBuilder.on') : T('queryBuilder.off')}</div>
                    )}
                </div>
            );
        default:
            return (
                <div className={cx(classes.checkboxContainer, className)}>
                    <Checkbox
                        className={className}
                        checked={checked}
                        onChange={onChange}
                        id={id}
                        aria-labelledby={name}
                    />
                </div>
            );
    }
}
