//TODO Unit tests
import React, {useCallback, useMemo, useState} from 'react';
import {MenuItem, MenuList} from '@mui/material';

//Types
import {InputDateType, RangeDefinition, TDateISODate} from 'hsi/types/dates';

//Components
import DateRangePicker from 'hsi/components/DateRangePicker';
import TimezoneSelector from 'hsi/components/TimezoneSelector';
import HelpIcon from 'hsi/components/HelpIcon';
import InfoPopupContent from 'hsi/components/InfoPopupContent';
import Heading, {HeadingContents, ResetHeadingLevel} from 'hsi/components/aria/Heading';

//Hooks
import useRollingTime from 'hsi/hooks/useRollingTime';

//Utils
import {normalizeDate, toISODate} from 'hsi/utils/dates';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';
import {toISODateRange} from './utils';
import {ReadonlyDeep} from 'type-fest';

//Types
type DateRangeDialogArgs = {
    id?: string;

    now?: InputDateType;

    maxDate?: InputDateType;
    minDate?: InputDateType;

    showDate?: TDateISODate;
    setShowDate?: (newShowDate: TDateISODate) => void;

    endDate?: TDateISODate;
    startDate?: TDateISODate;
    setDateSelection?: (
        startDate: TDateISODate | undefined,
        endDate: TDateISODate | undefined,
    ) => void;

    //Relative date ranges
    selectedRelativeDateRangeId?: string;
    setSelectedRelativeDateRangeId?: (value: string | undefined) => void;
    relativeDateRanges?: ReadonlyDeep<RangeDefinition[]>;

    dragEnabled?: boolean;

    //Timezones
    hasTimezones?: boolean;
    timezone?: Parameters<typeof TimezoneSelector>[0]['timezone'];
    setTimezone?: Parameters<typeof TimezoneSelector>[0]['setTimezone'];
    defaultTimezone?: Parameters<typeof TimezoneSelector>[0]['defaultTimezone'];
    timezones?: Parameters<typeof TimezoneSelector>[0]['timezones'];

    disabled?: boolean;
};

//The component
export default function DateRangeAndTimezonePicker({
    id,

    now: nowArg,

    maxDate: maxDateArg,
    minDate: minDateArg,

    showDate,
    setShowDate,

    endDate,
    startDate,
    setDateSelection,

    dragEnabled = true,

    //Relative date ranges
    selectedRelativeDateRangeId,
    setSelectedRelativeDateRangeId,
    relativeDateRanges,

    //Timezones
    hasTimezones,
    timezone = 'UTC',
    setTimezone,
    defaultTimezone = 'UTC',
    timezones,

    disabled,
}: DateRangeDialogArgs) {
    const {classes, cx} = useStyles();
    const normalisedNow = useMemo(
        //If 'now' supplied as argument, normalise to DateTime format + set timezone
        () => (nowArg ? normalizeDate(nowArg, timezone) : undefined),
        [nowArg, timezone],
    );
    const now = useRollingTime({now: normalisedNow, timezone}); //if 'now' supplied will use this value, otherwise will generate a rolling updated value
    const today = useMemo(
        () => toISODate(now), //generate 'today' from 'now'
        [now],
    );

    const minDate = useMemo(
        () => (minDateArg ? toISODate(normalizeDate(minDateArg, timezone)) : undefined),
        [minDateArg, timezone],
    );

    const maxDate = useMemo(
        () => (maxDateArg ? toISODate(normalizeDate(maxDateArg, timezone)) : undefined),
        [maxDateArg, timezone],
    );

    const [isPreview, setIsPreview] = useState<boolean>(false);
    const [[previewStart, previewEnd], setPreviewRange] = useState<
        [TDateISODate | undefined, TDateISODate | undefined]
    >([undefined, undefined]); //Should this be handled locally?

    //Interval representing the selected date range (startDate/endDate)
    const selectedRelativeDateRange = useMemo(
        () =>
            selectedRelativeDateRangeId
                ? relativeDateRanges?.find(({id}) => id === selectedRelativeDateRangeId) ??
                  undefined
                : undefined,
        [selectedRelativeDateRangeId, relativeDateRanges],
    );

    const [selectedStart, selectedEnd] = useMemo(() => {
        if (selectedRelativeDateRange) {
            const todayDateTime = normalizeDate(today);
            return [
                toISODate(todayDateTime.minus(selectedRelativeDateRange.start)),
                toISODate(todayDateTime.minus(selectedRelativeDateRange.end)),
            ];
        }

        return [startDate, endDate]; //TODO clamp to within min/max dates?
    }, [today, selectedRelativeDateRange, startDate, endDate]);

    //callback
    const onSelectDate = useCallback(
        (value: TDateISODate | undefined) => {
            if (!value) {
                setDateSelection?.(undefined, undefined);
                return;
            }

            if (isPreview) {
                //select new range
                setDateSelection?.(...toISODateRange(value, selectedStart)); //TODO just start? what about end?
                setPreviewRange([undefined, undefined]); //clear the preview
            } else {
                //select the day you clicked
                setDateSelection?.(value, value);
            }

            //toggle mode
            setIsPreview(!isPreview);

            setSelectedRelativeDateRangeId?.(undefined);
        },
        [isPreview, selectedStart, setSelectedRelativeDateRangeId, setDateSelection],
    );
    const onChangeActiveDate = useCallback(
        (value: TDateISODate | undefined) => {
            if (isPreview) {
                setPreviewRange(toISODateRange(value, selectedStart));
            } else {
                setPreviewRange([value, value]);
            }
        },
        [isPreview, selectedStart],
    );

    //Render
    return (
        <ResetHeadingLevel>
            <div className={cx(classes.wrapper, disabled && classes.disabled)} data-testid="datePicker">
                <Heading className="offscreen">{T('datepicker.title')}</Heading>
                <HeadingContents>
                    <Heading className="offscreen">{T('datepicker.relativeDateRangesTitle')}</Heading>
                    <HeadingContents>
                        <MenuList className={classes.relativeDateRanges}>
                            {relativeDateRanges?.map(({label, id}) => (
                                <MenuItem
                                    key={id}
                                    onClick={() => setSelectedRelativeDateRangeId?.(id)}
                                    className={cx(
                                        classes.relativeDateRange,
                                        selectedRelativeDateRangeId === id && classes.selected,
                                    )}
                                >
                                    {T(label)}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </HeadingContents>
                    <div className={classes.dateRangePicker}>
                        <Heading className="offscreen">{T('datepicker.fixedDateRangeTitle')}</Heading>
                        <HeadingContents>
                            <DateRangePicker
                                today={today}
                                minDate={minDate}
                                maxDate={maxDate}
                                showDate={showDate}
                                onChangeShowDate={setShowDate}
                                selectedStart={selectedStart}
                                selectedEnd={selectedEnd}
                                previewStart={previewStart}
                                previewEnd={previewEnd}
                                onSelectDate={onSelectDate}
                                onChangeActiveDate={onChangeActiveDate}
                                onDragSelectStart={
                                    dragEnabled
                                        ? (start: TDateISODate) => {
                                            setIsPreview(false);
                                            setDateSelection?.(start, start);
                                        }
                                        : undefined
                                }
                                onDragSelect={
                                    dragEnabled
                                        ? (startDate, endDate) => {
                                            setIsPreview(false);
                                            setDateSelection?.(startDate, endDate);
                                        }
                                        : undefined
                                }
                            />
                        </HeadingContents>
                        {hasTimezones && (
                            <div className={classes.timezone} data-testid="barDatePickerTimezone">
                                <div className={classes.timezoneTitleWrapper}>
                                    <Heading className={classes.timezoneTitle}>
                                        {T('datepicker.timezoneSelector.title')}
                                    </Heading>
                                    <HelpIcon
                                        className={classes.timezoneTitleIcon}
                                        icon="c-question-e"
                                        buttonLbl={T('datepicker.timezoneSelector.helpTitle')}
                                        onClick={(event) => event.stopPropagation()}
                                        placement="top-start"
                                        tooltip={
                                            <InfoPopupContent
                                                className={classes.timezoneHelpTooltip}
                                                copy={T('datepicker.timezoneSelector.helpTooltipCopy')}
                                                markdown
                                                title={T('datepicker.timezoneSelector.helpTooltipTitle')}
                                            />
                                        }
                                        tooltipDistance={{crossAxis: -75, mainAxis: 10}}
                                    />
                                </div>
                                <TimezoneSelector
                                    timezone={timezone}
                                    setTimezone={setTimezone}
                                    defaultTimezone={defaultTimezone}
                                    timezones={timezones}
                                />
                            </div>
                        )}
                    </div>
                </HeadingContents>
            </div>
        </ResetHeadingLevel>
    );
}
