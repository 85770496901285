//TODO need to make component polymorphic
//TODO change to not wrap component in div, but instead in new

/*
TODO future improvements include:
-Making positioning async, displaying loading indicator/hiding unpositioned items?
*/

import React, {useMemo, useRef, useEffect, createContext, useContext, useCallback} from 'react';

import Item from './Item';

import useElementSize from 'hsi/hooks/useElementSize';
import useRefCallback from 'hsi/hooks/useRefCallback';
import {useDebounceCallback} from '@react-hook/debounce';

import {polymorphicForwardRef} from 'hsi/types/react-polymorphic';
import {MutableOrImmutable} from 'hsi/types/shared';

import useStyles from './styles';
import classNames from 'classnames';
import useUniqueId from 'hsi/hooks/useUniqueId';
import { arraysEqual } from 'hsi/utils/array';

type HorizontalPositionedColumnsProps = {
    columns: number | ((width: number) => number);
    gutters?: MutableOrImmutable<[number, number]>;
    debounceOptions?: Omit<
        Exclude<Parameters<typeof useElementSize<HTMLDivElement>>[1], undefined>,
        'width' | 'enabled'
    >;
    transitionTime?: string;
    positionItemCallback?: (
        curColumn: number,
        columns: number,
        height: number,
        columnHeights: number[],
        itemColumns: number[],
    ) => number;
};

type StateRefType = {
    itemHeights: number[];
    prevNumColumns: number;
    prevColWidth: number;
    prevItemHeights: number[];
    prevItemColumn: number[];
    prevColumnHeights: number[];
    prevItemPositions: Record<string, number>;
    prevItemsSequence: string[],
};

type HorizontalPositionedColumnsContextType = {
    id: string;
    setItemHeight: (itemId: string, height: number) => void;
    setItemRef: (itemId: string, elem: HTMLElement | null) => void;
};

const HorizontalPositionedColumnsContext = createContext<
    HorizontalPositionedColumnsContextType | undefined
>(undefined);
HorizontalPositionedColumnsContext.displayName = 'HorizontalPositionedColumnsContext';

export function useHorizontalPositionedContext() {
    return useContext(HorizontalPositionedColumnsContext);
}

//The component
const HorizontalPositionedColumns: ReturnType<
    typeof polymorphicForwardRef<HorizontalPositionedColumnsProps, 'div'>
> & {
    Item: typeof Item;
} = polymorphicForwardRef<HorizontalPositionedColumnsProps, 'div'>(
    function HorizontalPositionedColumns(
        {
            as: Component = 'div',
            children,
            columns: _columns,
            debounceOptions = undefined,
            transitionTime = undefined,
            positionItemCallback = positionItem,
            gutters = [0, 0],
            className,
            id: idParam,

            ...rest
        },
        ref,
    ) {
        const classes = useStyles();
        const id = useUniqueId(idParam, 'hpc');

        const childRefs = useRef<Record<string, HTMLElement | null>>({});

        const [elementSizeRef, elementSize, rootElement] = useElementSize(ref ?? undefined, {
            width: true,
            ...debounceOptions,
        });
        const width = (elementSize && elementSize.width) || 1;
        const isVisible = !!elementSize?.width;

        const [gutterWidth, gutterHeight] = gutters;
        const transitionString = transitionTime
            ? `transform ${transitionTime}, width ${transitionTime}`
            : undefined;

        const columns = useMemo(
            () => (_columns instanceof Function ? _columns(width) : _columns),
            [width, _columns],
        );

        const columnWidth = (width - gutterWidth * (columns - 1)) / columns;

        const stateRef = useRef<StateRefType>({
            itemHeights: [],
            prevNumColumns: 0,
            prevColWidth: 0,
            prevItemHeights: [],
            prevItemColumn: [],
            prevColumnHeights: [],
            prevItemPositions: {},
            prevItemsSequence: [],
        });

        //if width changes, regenerate item heights immediately
        useEffect(
            () => {
                //This needs to be fired after children change
                if (!isVisible || !rootElement) {
                    return;
                }

                const items = getItems(rootElement!);
                const widthStr = `${Math.floor(columnWidth)}px`;
                const {prevItemPositions} = stateRef.current;

                stateRef.current.itemHeights = items.map((element) => {
                    if (!element) {
                        return 0;
                    }

                    const itemId: string = getItemId(element)!;

                    element.style.display = 'block'; //<<not sure why this is needed?? - can we do this better?

                    //If transitions enabled AND this item has been previously positioned
                    if (transitionString && prevItemPositions[itemId]) {
                        //record current width
                        const originalWidth = element.clientWidth;

                        //clear transitions
                        element.style.transition = '';

                        // apply new width
                        element.style.width = widthStr;
                        // Read height of item after applying new width
                        const newHeight = element.clientHeight;

                        //reset width
                        element.style.width = `${originalWidth}px`;

                        //apply transition
                        element.style.transition = transitionString;

                        //apply new dimesions
                        element.style.width = widthStr;

                        return newHeight;
                    } else {
                        //clear transitions
                        element.style.transition = '';

                        // apply new width
                        element.style.width = widthStr;

                        // Read height of item after applying new width
                        return element.clientHeight;
                    }
                });

                repositionItems();
            },
            // Check whether we can add other deps without issue
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [width, rootElement, children, columns],
        );

        //if item heights change, reposition items that require it
        const repositionItems = useRefCallback(() => {
            if (!isVisible || !rootElement) {
                return;
            }

            const {
                itemHeights,
                prevNumColumns,
                prevColWidth,
                prevItemHeights,
                prevItemColumn,
                prevColumnHeights,
                prevItemPositions,
                prevItemsSequence,
            } = stateRef.current;

            if (itemHeights.length === 0) {
                return;
            }

            const hasColWidthChanged = prevColWidth !== columnWidth;
            const numColumnsChanged = prevNumColumns !== columns;
            const hasNewItems = itemHeights.length !== prevItemHeights.length;

            const items = getItems(rootElement);
            const newItemsSequence = items.map(getItemId);
            const firstSequenceDifference = newItemsSequence.findIndex((id, i) => prevItemsSequence[i] !== id);
            const firstHeightDifferenceIndex = hasColWidthChanged
                ? 0
                : itemHeights.findIndex((height, i) => {
                      return prevItemHeights[i] !== height;
                  });

            const firstDifferenceIndex = firstSequenceDifference === -1 
                ? firstHeightDifferenceIndex 
                : Math.min(firstSequenceDifference, firstHeightDifferenceIndex);

            if (
                !hasColWidthChanged &&
                firstDifferenceIndex === -1 &&
                !hasNewItems &&
                !numColumnsChanged
            ) {
                //no differences in height found, same number of columns, and same width columns
                return; //no need to reposition
            }

            //what needs repositioning?
            let columnHeights: number[];
            let itemColumns: number[];
            let childrenToPosition: HTMLElement[] | undefined;
            const itemPositions = {...prevItemPositions};

            if (numColumnsChanged || firstDifferenceIndex === 0) {
                //need to reposition everything
                childrenToPosition = items;
                columnHeights = [];
                itemColumns = [];

                for (let i = 0; i < columns; i++) {
                    columnHeights[i] = 0;
                }
            } else if (
                firstDifferenceIndex === -1 ||
                (hasNewItems && firstDifferenceIndex === prevItemHeights.length)
            ) {
                //only need to reposition new items
                childrenToPosition = items.slice(prevItemHeights.length);
                itemColumns = [...prevItemColumn];
                columnHeights = [...prevColumnHeights];
            } else {
                //reposition from first difference
                childrenToPosition = items.slice(firstDifferenceIndex);
                itemColumns = prevItemColumn.slice(0, firstDifferenceIndex);

                //calculate column heights from unchanged items
                columnHeights = [];

                for (let col = 0; col < columns; ++col) {
                    const lastitemInColumn = itemColumns.lastIndexOf(col);

                    if (lastitemInColumn === -1) {
                        columnHeights[col] = 0;
                    } else {
                        columnHeights[col] = prevItemPositions[getItemId(items[lastitemInColumn])!];
                    }
                }
            }

            let curColumn = -1;

            childrenToPosition.forEach((element) => {
                if (!element) {
                    return;
                }

                const height = element.clientHeight;

                // calculate which column to use
                curColumn = positionItemCallback(
                    curColumn,
                    columns,
                    height,
                    columnHeights,
                    itemColumns,
                );

                // position the element
                const x = Math.round(
                    curColumn * columnWidth + (curColumn > 0 ? curColumn * gutterWidth : 0),
                );
                const y = Math.round(columnHeights[curColumn] + gutterHeight);
                const bottom = y + height;

                itemPositions[getItemId(element)!] = bottom;

                element.style.transform = `translateX(${x}px) translateY(${y}px)`;

                // record updated column heights
                columnHeights[curColumn] += height + gutterHeight;
                itemColumns.push(curColumn);
            });

            //apply overall height to root element
            if (rootElement) {
                rootElement.style.paddingTop = `${Math.max.apply(Math, columnHeights)}px`;
            }

            //finally, update previous values
            stateRef.current.prevNumColumns = columns;
            stateRef.current.prevColWidth = columnWidth;
            stateRef.current.prevItemHeights = [...itemHeights];
            stateRef.current.prevItemColumn = itemColumns;
            stateRef.current.prevColumnHeights = columnHeights;
            stateRef.current.prevItemPositions = itemPositions;
            stateRef.current.prevItemsSequence = [...newItemsSequence];
        });

        const repositionItemsDebounce = useDebounceCallback(
            repositionItems,
            debounceOptions?.wait,
            debounceOptions?.leading,
        );

        const setItemHeight = useCallback(
            (itemId: string, height: number) => {
                const {itemHeights} = stateRef.current;
                const newItemHeights = getAllSiblingItems(itemId).map((element) => element.clientHeight);

                if(!arraysEqual(newItemHeights, itemHeights)) {
                    stateRef.current.itemHeights = newItemHeights;
                    repositionItemsDebounce();
                }
            },
            [repositionItemsDebounce],
        );

        const setItemRef = useCallback((itemId: string, elem: HTMLElement | null) => {
            childRefs.current[itemId] = elem;
        }, []);

        const contextValue = useMemo<HorizontalPositionedColumnsContextType>(
            () => ({
                id,
                setItemHeight,
                setItemRef,
            }),
            [id, setItemHeight, setItemRef],
        );

        return (
            <HorizontalPositionedColumnsContext.Provider value={contextValue}>
                <Component
                    ref={elementSizeRef}
                    id={id}
                    className={classNames(className, classes.root)}
                    {...rest}
                >
                    {children}
                </Component>
            </HorizontalPositionedColumnsContext.Provider>
        );
    },
) as any;

export default HorizontalPositionedColumns;

//Internal helpers
function getItems(rootElement: HTMLElement) {
    return Array.from(
        rootElement.querySelectorAll(`[data-hpc-id="${rootElement.id}"]`),
    ) as HTMLElement[];
}

function getItemId(itemElement: HTMLElement) {
    return itemElement.id;
}

function getAllSiblingItems(itemId: string) {
    const itemElement = document.getElementById(itemId);
    if (!itemElement) {
        throw new Error('itemId not found');
    }

    const rootElement = document.getElementById(itemElement.dataset.hpcId!);

    if(!rootElement) {
        throw new Error('Element not a horizontally positioned item');
    }

    return getItems(rootElement);
}

const COL_MATCH_DELTA = 100;

function positionItem(
    lastColumn: number,
    numColumns: number,
    height: number,
    columnHeights: number[],
) {
    //find the current shortest column
    let shortestColumnHeight = Number.POSITIVE_INFINITY;
    let shortestColumnIndex = -1;

    for (let i = 0; i < numColumns; i++) {
        if (columnHeights[i] < shortestColumnHeight) {
            shortestColumnHeight = columnHeights[i];
            shortestColumnIndex = i;
        }
    }

    // Now see if any columns to the left are within an acceptible distance to
    // count as the 'same row'
    let nextColumnIndex = shortestColumnIndex;

    for (let i = shortestColumnIndex - 1; i > -1; --i) {
        if (columnHeights[i] - COL_MATCH_DELTA <= shortestColumnHeight) {
            nextColumnIndex = i;
        }
    }

    return nextColumnIndex;
}

HorizontalPositionedColumns.Item = Item;
