/**
 * We should really swap the input to the newer autocomplete input field that we use for targeted data
 * and also not have this custom add hashtag feature, it should just be the same dialog that we use in
 * targeted data as well as we do with Facebook pages and Instagram accounts
 */
import {MouseEventHandler, useCallback, useMemo} from 'react';
import difference from 'lodash/difference';

import HashtagChip from './HashtagChip';
import InputChips from '../InputChips';
import InputWarningFooter from '../InputWarningFooter';

import useConfig from 'hsi/hooks/useConfig';
import {useAppDispatch} from 'hsi/hooks/useRedux';
import {useInstagramAccounts, useInstagramHashtags} from 'hsi/hooks/useDataSource';

import {toggleFacebookLoginDialog} from 'hsi/slices/facebookAuthentication';

import {T} from 'hsi/i18n';
import {MAX_DATA_SOURCES} from 'hsi/constants/config';
import {formatIgHashtag, unformatIgHashtag} from 'hsi/utils/hashtags';

type InputHashtagsProps = {
    name: string;
    onChange: (args: {sectionName: string; componentName: string; values: string[]}) => void;
    placeholder: string;
    sectionName: string;
    subtitle: string;
    title: string;
    values: string[];
};

const InputHashtags = (props: InputHashtagsProps) => {
    const {values} = props;
    const config = useConfig();
    const dispatch = useAppDispatch();

    const instagramAccounts = useInstagramAccounts({defaultSize: MAX_DATA_SOURCES});
    const instagramHashtags = useInstagramHashtags({defaultSize: MAX_DATA_SOURCES});

    const activeHashtags = useMemo(
        () => instagramHashtags?.active.map(({name}) => name.toLowerCase()),
        [instagramHashtags?.active],
    );

    const hasMissingIgHashtags = useMemo(
        () =>
            !!difference(
                values.map((value) => value.toLowerCase()),
                activeHashtags,
            ).length,
        [activeHashtags, values],
    );

    const hasIGAuth =
        !!instagramAccounts?.activeOwned?.length &&
        instagramAccounts.activeOwned.some(({hashtagsRemaining}) => hashtagsRemaining > 0);

    const openFBAuth = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (event) => {
            event.preventDefault();
            event.stopPropagation();

            dispatch(toggleFacebookLoginDialog(true));

            return false;
        },
        [dispatch],
    );

    const warningContent = (
        <InputWarningFooter
            desc={
                <>
                    <div>
                        {!hasIGAuth
                            ? T('queryBuilder.noIGAuthDesc')
                            : T('queryBuilder.noIGHashtagsDesc')}
                    </div>
                    {config.appSource === 'HSI' && !hasIGAuth && (
                        <a href="/" onClick={openFBAuth}>
                            {T('queryBuilder.authNow')}
                        </a>
                    )}
                </>
            }
        />
    );

    const renderTags = useCallback(
        (
            values: InputHashtagsProps['values'],
            getCustomizedTagProps: ({index}: {index: number}) => any,
        ) => {
            return values.map((value, index) => (
                <HashtagChip
                    hasIGAuth={hasIGAuth}
                    label={formatIgHashtag(value)} // Display chips with hashtag
                    missingIG={!activeHashtags?.includes(value.toLowerCase())}
                    value={value} // Use value without hashtag for API calls
                    {...getCustomizedTagProps({index})}
                />
            ));
        },
        [activeHashtags, hasIGAuth],
    );

    return (
        <InputChips
            {...props}
            chipElemProps={{
                getOptionLabel: (option: string) => formatIgHashtag(option), // Display list with hashtags
                inputModifier: (array: string[]) => array.map(unformatIgHashtag), // Remove user inputted hashtags
                options: activeHashtags.filter(
                    (hashtag) =>
                        !values.map((value) => value.toLowerCase()).includes(hashtag.toLowerCase()),
                ), // Remove already selected hashtags from the suggestion list
                popupTitle: T('queryBuilder.igHashtagsPopupTitle'),
                renderTags,
            }}
            contentBottom={hasMissingIgHashtags ? warningContent : null}
        />
    );
};

export default InputHashtags;
