import {makeStyles} from 'tss-react/mui';

import {isV2} from 'hsi/types/theme';

export default makeStyles()((theme, _params, classes) => {
    const v2 = isV2(theme);

    return {
        root: {
            '& > .MuiButtonBase-root': {
                width: 'unset',
            },
            '& .Mui-focusVisible .chbox-icon-wrapper': {
                outline: v2
                    ? `2px solid ${theme.colors.accentActive}`
                    : `2px solid ${theme.colors.secondaryOrange100}`,
            },
        },
    };
});
