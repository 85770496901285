import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles((theme: Theme) => ({
    popover: {
        zIndex: 999999999,
        backgroundColor: 'white !important',
        border: theme.v2
            ? `1px solid ${theme.colors.borders}`
            : `2px solid ${theme.colors.secondaryPurple100}`,
        padding: 0,
        minWidth: 120,
        borderRadius: theme.v2 ? 3 : 0,
        boxShadow: 'none',
        color: theme.colors.darkGrey100,
    },

    blankOverlay: {
        zIndex: 999999999,
    },

    overlay: {
        background: '#00000033',
        position: process.env.STORYBOOK ? ('absolute!important' as any) : undefined, //jss needs the cast to allow !important
        overflow: process.env.STORYBOOK ? 'visible!important' : undefined,
        zIndex: 999999999,
    },
}));
