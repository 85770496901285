import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';

import withStyles from '@mui/styles/withStyles';
import { Theme, isV2 } from 'hsi/types/theme';

export const tabsHeight = '40px';

export const Tabs = withStyles((theme) => {
    const {colors} = theme as Theme;
    const v2 = isV2(theme);

    return {
        root: {
            height: v2 ? '45px' : tabsHeight,
            minHeight: v2 ? '45px' : tabsHeight,
            margin: '0px 20px',
            ...(v2
                ? {
                      borderBottom: `1px solid ${colors.borders}`,
                  }
                : {}),
        },
        indicator: {
            backgroundColor: v2 ? colors.accentDefault : colors.primaryBlue100,
            height: '4px',
        },
    };
})(MuiTabs);

export const Tab = withStyles((_theme) => {
    const theme = _theme as Theme;
    const v2 = isV2(theme);

    return {
        root: {
            margin: '0 16px 0 0',
            minWidth: 'unset',
            padding: '6px 2px',
            ...(v2
                ? {
                      ...theme.fonts.bodyLargeStrong,
                      textTransform: 'none',
                  }
                : {fontWeight: 'bold'}),

            '&:focus-visible': {
                color: v2 ? undefined : theme.colors.secondaryOrange100,
            },
        },
    };
})(MuiTab);

type TabPanelProps = {tabId: string; value: number; index: number} & JSX.IntrinsicElements['div'];

export function TabPanel({tabId, children, value, index, ...other}: TabPanelProps) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${tabId}.tabpanel.${index}`}
            aria-labelledby={`${tabId}.tab.${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

export function tabProps(id: string, index: number) {
    return {
        id: `${id}.tab.${index}`,
        'aria-controls': `${id}.tabpanel.${index}`,
    };
}
