import last from 'lodash/last';

import {SENTIMENT_CATEGORIES} from 'hsi/constants/config';

export function getMagnitude(n: number) {
    let order = Math.floor(Math.log(n) / Math.LN10);
    return Math.pow(10, order);
}

export function getChartRangeMax(n: number) {
    let firstRound = [0, 0.1, 0.2, 0.25, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];
    let secondRound = [0, 0.2, 0.4, 0.5, 0.8, 1];
    let selectedRound;

    let mag = getMagnitude(n);
    let base = mag;

    if (n <= 2 * mag) {
        base = mag;
        selectedRound = firstRound;
    } else if (n <= 4 * mag) {
        base = n > 3 * mag ? 3 * mag : 2 * mag;
        selectedRound = secondRound;
    } else {
        selectedRound = firstRound;
        mag *= 10;
        base = 0;
    }

    for (let x of selectedRound) {
        let res = parseFloat((x * mag + base).toFixed(2));
        if (res >= n) {
            return res;
        }
    }
}

export function extractEmotionId(id: number | string) {
    return last(String(id).split(':'))?.toLowerCase();
}

export function hasHistoryData(data: any, usesIndexedValues?: boolean) {
    const hasDataLoaded =
        !!data &&
        !!data?.categories &&
        data.categories.length > 0 &&
        !!data?.series &&
        data.series.length > 0;

    if (hasDataLoaded) {
        const categories = data.categories.map(({id}: {id: any}) => id);

        return data.series.some((val: any) => {
            return usesIndexedValues
                ? categories
                : categories.some((category: any) => val[category] > 0);
        });
    }

    return false;
}

export function hasCategoriesData(data: any) {
    const hasDataLoaded =
        !!data &&
        !!data?.categories &&
        data.categories.length > 0 &&
        !!data?.results &&
        data.results.length > 0;

    if (hasDataLoaded) {
        const categories = data.categories.map(({id}: {id: any}) => id);

        return data.results.some((val: any) => {
            return categories.some((category: any) => val[category] > 0);
        });
    }

    return false;
}

export function formatHistoryPeaks(peaks: any, type: string) {
    let actualPeaks: any[] = [];

    peaks[type] &&
        peaks[type].forEach((p: any) => {
            if (type === 'mentionsHistory') {
                actualPeaks = actualPeaks.concat(
                    p.peaks.map((pk: any) => ({
                        ...pk,
                        id: p.id,
                    })),
                );
            } else if (type === 'sentimentHistory' && SENTIMENT_CATEGORIES.includes(p.id)) {
                p.peaks.forEach((pk: any) => (pk.id = p.id));
                actualPeaks = actualPeaks.concat(p.peaks);
            } else if (
                type === 'emotionHistory' &&
                typeof p.id === 'string' &&
                p.id.includes('emotion')
            ) {
                p.peaks.forEach((pk: any) => (pk.id = p.id.split(':')[1].toLowerCase()));
                actualPeaks = actualPeaks.concat(p.peaks);
            }
        });

    return actualPeaks;
}
