import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    loading: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    container: {
        width: 'auto',
        height: '100%',
        margin: '20px',
    },

    noAlerts: {
        position: 'absolute',
        top: '50%',
        left: 0,
        right: 0,
        color: theme.colors.darkGrey100,
        textAlign: 'center',
    },

    noAlertsMsg: {
        fontSize: '30px',
        fontWeight: 700,
        margin: 0,
        marginBottom: '10px',
    },

    noAlertsInfo: {
        fontSize: '16px',
        fontWeight: 500,
        margin: 0,
    },
}));
