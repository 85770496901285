import {useMemo} from 'react';
import {useAppSelector} from './useRedux';
import {parseErrors} from 'hsi/utils/api/errorParsing';

/**
 * Parses query errors into standard format, and filters out errors that are not relevent to us
 *
 * @returns An array of ApiErrorType objects or null, will never return an empty array
 */
export default function useQueryApiErrors() {
    const {errors: rawQueryErrors} = useAppSelector((state) => state.query);

    return useMemo(() => parseErrors(rawQueryErrors), [rawQueryErrors]);
}
