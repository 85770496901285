import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({colors, typgrphy, v2}: any) => ({
    container: {
        display: 'flex',
    },
    txtContainer: {
        flex: '1 1 auto',
        marginRight: '8px',
        position: 'relative',
    },
    inputElem: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: v2 ? colors.textDefault : '#2f3638',
        fontWeight: v2 ? typgrphy.bodyNormal.fontWeight : 'normal',
        fontSize: v2 ? typgrphy.bodyNormal.fontSize : '14px',
    },
    txtOutline: {
        borderTopRightRadius: v2 ? '' : '0 !important',
        borderBottomRightRadius: v2 ? '' : '0 !important',
    },
    btn: {
        position: 'relative',
        flex: '0 0 auto',
        borderTopLeftRadius: v2 ? '' : '0 !important',
        borderBottomLeftRadius: v2 ? '' : '0 !important',
    },
}));
