import Button from 'hsi/components/Button';
import HelpIcon from 'hsi/components/HelpIcon';
import IconRouter from 'hsi/components/IconRouter';
import InfoPopupContent from 'hsi/components/InfoPopupContent';
import HeaderBar from 'hsi/components/HeaderBar';

import useStyles from './styles';

import {T} from 'hsi/i18n';

type TitleBarProps = {
    handleAddDataSource: () => void;
    isAddDataSourceEnabled: boolean;
    learnMoreLink?: string;
    titleDescId: string;
};

const TitleBar = ({
    handleAddDataSource,
    isAddDataSourceEnabled,
    learnMoreLink,
    titleDescId,
}: TitleBarProps) => {
    const classes = useStyles();
    return (
        <HeaderBar
            backButtonUrl="/"
            title={
                <>
                    <div className={classes.titleWrapper}>
                        <h1 aria-describedby={titleDescId} className={classes.title}>
                            {T('dataManagement.title')}
                        </h1>
                        <HelpIcon
                            className={classes.titleHelpIcon}
                            children={undefined}
                            icon="c-question-e"
                            placement="bottom-start"
                            buttonLbl={T('dataManagement.tooltip.lbl')}
                            tooltip={
                                <InfoPopupContent
                                    title={T('dataManagement.tooltip.title')}
                                    copy={T('dataManagement.tooltip.copy')}
                                    ctaLabel={T('dataManagement.tooltip.cta')}
                                    ctaUrl={learnMoreLink || undefined}
                                    markdown
                                />
                            }
                        />
                    </div>
                    <p className={classes.titleDescription} id={titleDescId}>
                        {T('dataManagement.titleDesc')}
                    </p>
                </>
            }
            titleComponent="div"
        >
            <Button
                disabled={!isAddDataSourceEnabled}
                onClick={handleAddDataSource}
                priority="cta"
                className={classes.addSrcBtn}
            >
                <IconRouter className={classes.titleButtonIcon} name="f-add" aria-hidden />
                {T('dataManagement.addDataSources')}
            </Button>
        </HeaderBar>
    );
};

export default TitleBar;
