// TODO: Add tests
import {useState, useCallback, useMemo, useEffect} from 'react';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';

import PulseLoader from 'hsi/components/PulseLoader';
import SelectField from 'hsi/components/LegacySelectField';
import authenticate from 'hsi/utils/dataManagement/linkedin/authenticate';
import {parseLinkedinOrgInput} from 'hsi/utils/dataManagement/linkedin';

import Button from 'hsi/components/Button';
import DialogContainer from 'hsi/containers/DataManagement/AddDataSourceDialog/DialogContainer';
import IconRouter from 'hsi/components/IconRouter';
import TextField, {TextFieldProps} from 'hsi/components/TextField';

import useEventTrack from 'hsi/hooks/useEventTrack';
import useStyles from './styles';

import tns from 'hsi/i18n/tns';
const t = tns('addTargetedDataDialog.LinkedinChannel.modal');

type OrgDetailsBoxProps = {
    classes: ReturnType<typeof useStyles>;
    orgName: string;
    orgUrl: string;
    orgLogoUrl: string;
};

const OrgDetailsBox = ({classes, orgName, orgUrl, orgLogoUrl}: OrgDetailsBoxProps) => (
    <div className={classes.orgDetailsBox}>
        <div className={cn(classes.orgDetailsCol, classes.orgDetailsColTitle)}>
            <div className={classes.orgDetailsItemTitle}>{t('orgDetailsBox.orgNameTitle')}</div>
            <div className={classes.orgDetailsItemTitle}>{t('orgDetailsBox.orgUrlTitle')}</div>
        </div>

        <div className={cn(classes.orgDetailsCol, classes.orgDetailsColValue)}>
            <div className={classes.orgDetailsItemValue}>{orgName}</div>
            <div className={classes.orgDetailsItemValue}>{orgUrl}</div>
        </div>

        {orgLogoUrl && (
            <div className={cn(classes.orgDetailsCol, classes.orgDetailsColLogo)}>
                <img src={orgLogoUrl} className={classes.orgDetailsLogo} alt={orgName} />
            </div>
        )}
    </div>
);

type MessageBoxProps = {
    classes: ReturnType<typeof useStyles>;
    description?: string;
    title?: string;
};

const MessageBox = ({classes, title, description}: MessageBoxProps) => (
    <div className={classes.messageBox}>
        <IconRouter name="c-warning" className={classes.messageIcon} />
        <div className={classes.messageBody}>
            {title && <div className={classes.messageTitle}>{title}</div>}
            {description && <div>{description}</div>}
        </div>
    </div>
);

type OrgInputProps = {
    classes: ReturnType<typeof useStyles>;
    error: TextFieldProps['error'];
    onKeyDown: React.KeyboardEventHandler<HTMLElement>;
    onOrgChange: TextFieldProps['onChange'];
    onSearch: () => void;
    orgInputDisabled: boolean;
    orgValue: string;
    searchOrgBtnAvailable: boolean;
    searchOrgBtnDisabled: boolean;
};

const OrgInput = ({
    classes,
    error,
    onKeyDown,
    onOrgChange,
    onSearch,
    orgInputDisabled,
    orgValue,
    searchOrgBtnAvailable,
    searchOrgBtnDisabled,
}: OrgInputProps) => (
    <div className={classes.orgInputContainer}>
        <div className={classes.orgInputTitle}>{t('orgInputTitle')}</div>
        <div className={classes.orgInputDescription}>{t('orgInputDescription')}</div>

        <div className={classes.orgInputSearchContainer}>
            <TextField
                fullWidth
                disabled={orgInputDisabled}
                value={orgValue}
                onChange={onOrgChange}
                onKeyDown={onKeyDown}
                placeholder={t('orgInputPlaceholder')}
                error={error}
                required
            />

            {searchOrgBtnAvailable && (
                <Button
                    className={classes.orgInputSearchButton}
                    priority="secondary"
                    onClick={onSearch}
                    onKeyDown={onKeyDown}
                    disabled={searchOrgBtnDisabled}
                >
                    {t('search')}
                </Button>
            )}
        </div>
    </div>
);

type LinkedinChannelDialogContentProps = {
    createChannel: (payload: {channelName: string; projectId: string}) => void;
    isAuthenticated: boolean;
    loading: boolean;
    onAuthenticationDone: () => void;
    onBack: () => void;
    onClose: () => void;
    orgDetails: any;
    projects: any[];
    updateOrgDetails: (payload: {org: string}) => void;
};

const LinkedinChannelDialogContent = ({
    createChannel,
    isAuthenticated,
    loading,
    onAuthenticationDone,
    onBack,
    onClose,
    orgDetails,
    projects,
    updateOrgDetails,
}: LinkedinChannelDialogContentProps) => {
    const classes = useStyles();
    const {track} = useEventTrack();

    const [orgValue, setOrgValue] = useState('');
    const [channelName, setChannelName] = useState('');
    const [project, setProject] = useState<any>();

    const projectOptions = useMemo(
        () =>
            projects.map((d) => ({
                value: d.id,
                label: d.name,
            })),
        [projects],
    );

    const handleOrgDetailsChange = useCallback(
        () =>
            updateOrgDetails({
                org: parseLinkedinOrgInput(orgValue),
            }),
        [orgValue, updateOrgDetails],
    );

    const handleCreateChannel = useCallback(() => {
        createChannel({
            channelName: (channelName || '').trim(),
            projectId: project.value,
        });
        track('targetedDataLinkedinChannelCreated', {project, channelName});
    }, [channelName, project, createChannel, track]);

    const onAuthenticationStart = useCallback(() => {
        track('targetedDataSourceAuthStarted', {
            type: 'Owned Linkedin Page',
        });
        authenticate(() => {
            track('targetedDataSourceAuthCompleted', {
                type: 'Owned Linkedin Page',
            });
            onAuthenticationDone();
        });
    }, [onAuthenticationDone, track]);

    // This is difficult to debug so the structure should probably be changed to make this clearer
    const init = isEmpty(orgDetails);
    const hasOrgDetails = !!orgDetails.orgName;
    const createChannelAvailable = orgDetails.userIsOrgAdmin;
    const hasIntroMessage = isAuthenticated && (init || orgDetails.error);
    const hasAdminRequiredMessage = !init && !orgDetails.error && !orgDetails.userIsOrgAdmin;
    const hasConnectWithLinkedinButton = !isAuthenticated || hasAdminRequiredMessage;
    const updateOrgBtnDisabled = !isAuthenticated || !orgValue;
    const searchOrgBtnAvailable = createChannelAvailable || hasConnectWithLinkedinButton;

    useEffect(() => {
        if (typeof orgDetails.orgName === 'string') setChannelName(orgDetails.orgName);
    }, [orgDetails]);

    return (
        <DialogContainer
            actions={
                <div className={classes.actions}>
                    <Button onClick={onBack}>{t('back')}</Button>

                    <div className={classes.updateActions}>
                        {createChannelAvailable && (
                            <Button
                                disabled={!channelName || !project}
                                onClick={handleCreateChannel}
                                onKeyDown={(event) =>
                                    event.key === 'Enter' && handleCreateChannel()
                                }
                                priority="cta"
                            >
                                {t('createChannelButton')}
                            </Button>
                        )}

                        {!searchOrgBtnAvailable && (
                            <Button
                                disabled={updateOrgBtnDisabled}
                                onClick={handleOrgDetailsChange}
                                onKeyDown={(event) =>
                                    event.key === 'Enter' && handleOrgDetailsChange()
                                }
                                priority="cta"
                            >
                                {t('next')}
                            </Button>
                        )}
                    </div>
                </div>
            }
            onClose={onClose}
            title={t('title')}
        >
            {loading && (
                <div className={classes.loader}>
                    <PulseLoader size="large" />
                </div>
            )}

            <OrgInput
                classes={classes}
                error={!!orgDetails.error}
                onKeyDown={(event) => event.key === 'Enter' && handleOrgDetailsChange()}
                onOrgChange={(event) => setOrgValue(event.target.value)}
                onSearch={handleOrgDetailsChange}
                orgInputDisabled={!isAuthenticated}
                orgValue={orgValue}
                searchOrgBtnAvailable={searchOrgBtnAvailable}
                searchOrgBtnDisabled={updateOrgBtnDisabled}
            />

            {orgDetails.error && (
                <div className={classes.orgErrorMessage}>{t('orgNotFoundMessage')} </div>
            )}

            {hasOrgDetails && (
                <OrgDetailsBox
                    classes={classes}
                    orgLogoUrl={orgDetails.orgLogoUrl}
                    orgName={orgDetails.orgName}
                    orgUrl={orgDetails.orgUrl}
                />
            )}

            {!isAuthenticated && (
                <MessageBox
                    classes={classes}
                    description={t('authRequiredMessage.description')}
                    title={t('authRequiredMessage.title')}
                />
            )}

            {hasIntroMessage && (
                <MessageBox classes={classes} description={t('introMessage.description')} />
            )}

            {hasAdminRequiredMessage && (
                <MessageBox
                    classes={classes}
                    description={t('adminRequiredMessage.description')}
                    title={t('adminRequiredMessage.title')}
                />
            )}

            {hasConnectWithLinkedinButton && (
                <div className={classes.connectWithLinkedin}>
                    <Button
                        className={classes.connectWithLinkedinBtn}
                        priority="cta"
                        startIcon={
                            <IconRouter
                                name="linkedin-inv"
                                className={classes.connectWithLinkedinIcon}
                            />
                        }
                        onClick={onAuthenticationStart}
                    >
                        {t('connectWithLinkedin')}
                    </Button>
                </div>
            )}

            {createChannelAvailable && (
                <>
                    <div className={classes.channelInputTitle}>{t('channelInputTitle')}</div>
                    <TextField
                        fullWidth
                        onChange={(event) => setChannelName(event.target.value)}
                        onKeyDown={(event) => event.key === 'Enter' && handleCreateChannel()}
                        placeholder={t('channelInputPlaceholder')}
                        value={channelName}
                    />

                    <div className={classes.channelInputTitle}>{t('projectInputTitle')}</div>
                    <SelectField
                        fullWidth
                        onChange={(newProject) => setProject(newProject)}
                        options={projectOptions}
                        placeholder={t('projectInputPlaceholder')}
                        value={project}
                        // TODO: Remove prop once SelectField is typed
                        InputProps={undefined}
                    />
                </>
            )}
        </DialogContainer>
    );
};

export default LinkedinChannelDialogContent;
