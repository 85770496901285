import {parseEmotions} from 'hsi/components/Mention/normalize';
import {EMOTIONS_CATEGORIES} from 'hsi/constants/config';
import {MentionType} from 'hsi/types/mentions';
import {EmotionsType} from 'hsi/types/shared';
import {ucfirst} from '../misc';

export default function getEmotionsFromMention(
    mention: MentionType,
): Record<EmotionsType, boolean> {
    const parsedEmotions = parseEmotions(mention);

    return EMOTIONS_CATEGORIES.reduce<Record<EmotionsType, boolean>>((output, emotion) => {
        output[emotion] = !!parsedEmotions?.[ucfirst(emotion)];

        return output;
    }, {} as any);
}
