import React, {Component, Fragment, memo} from 'react';
import PropTypes from 'prop-types';
import {BarChart, Bar, Cell, XAxis, YAxis, Customized, CartesianGrid} from 'recharts';
import withStyles from '@mui/styles/withStyles';
import ChartTooltip from 'hsi/components/ChartTooltip';
import ChartTooltipContent from 'hsi/components/ChartTooltipContent';
import {formatBigIntFull, formatBigInt} from 'hsi/utils/formatNumber';
import {T} from 'hsi/i18n';
import styles from './styles.js';

class HsiBarChart extends Component {
    static propTypes = {
        data: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                value: PropTypes.number,
                id: PropTypes.string,
                color: PropTypes.string,
            }),
        ),
        showLegend: PropTypes.bool,
        animate: PropTypes.bool,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        showLegend: true,
        animate: false,
        onClick: null,
    };

    state = {tooltip: null};

    showTooltip = (ev) => {
        this.setState({
            tooltip: {
                data: ev.payload,
                pos: {x: ev.x + ev.width / 2, y: ev.y},
            },
        });
        this.lastId = ev.payload.id;
    };

    hideTooltip = (ev) => {
        this.hideTooltipTimeout = setTimeout(() => {
            if (ev.payload.id === this.lastId) {
                this.lastId = null;
                this.setState({tooltip: null});
            }
        }, 300);
    };

    hideTooltipNow = () => {
        this.hideTooltipTimeout && clearTimeout(this.hideTooltipTimeout);
        this.setState({tooltip: null});
    };

    componentWillUnmount() {
        this.hideTooltipTimeout && clearTimeout(this.hideTooltipTimeout);
    }

    render() {
        const {data, width, height, animate, onClick, classes, formatTootip} = this.props;
        const {tooltip} = this.state;

        if (!width || !height) return <span />;

        return (
            <BarChart
                className={classes.barChart}
                data={data}
                width={width}
                height={height}
                layout="vertical"
                barGap={0}
                barCategoryGap={12}
                margin={{left: 80, right: 80, bottom: 10}}
                onMouseLeave={this.hideTooltipNow}
            >
                <CartesianGrid horizontal={false} stroke="#cdd2d4" />
                <XAxis
                    dataKey="value"
                    type="number"
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={(v) => formatBigInt(v, 1000)}
                    tickMargin={5}
                    height={70}
                    label={{
                        value: T('volumeHorizontalChart.XaxisLegend'),
                        position: 'bottom',
                        className: 'xAxislegend',
                        offset: -20,
                    }}
                />
                <YAxis
                    type="category"
                    dataKey="name"
                    tickFormatter={(id) => T(id)}
                    axisLine={false}
                    tickLine={false}
                    mirror={true}
                    tickMargin={-86}
                    padding={{top: 4, bottom: 4}}
                />
                <Bar
                    dataKey="value"
                    onMouseEnter={this.showTooltip}
                    onMouseLeave={this.hideTooltip}
                    isAnimationActive={animate}
                    onClick={(ev) => onClick(ev.payload) || null}
                >
                    {data && data.map((item, i) => <Cell key={`bar-${i}`} fill={item.color} />)}
                </Bar>
                {!!tooltip && (
                    <Customized
                        key="tooltip"
                        component={
                            <Fragment>
                                <ChartTooltip
                                    {...tooltip.pos}
                                    open={true}
                                    showArrow={true}
                                    animationMs={150}
                                    distance={8}
                                    autoHideOnScroll
                                >
                                    <ChartTooltipContent
                                        title={
                                            (formatTootip && formatTootip(tooltip.data.name)) ||
                                            tooltip.data.name
                                        }
                                        valueItems={[
                                            {
                                                color: tooltip.data.color,
                                                amount: formatBigIntFull(tooltip.data.value),
                                                unit: T('mentions'),
                                            },
                                        ]}
                                    />
                                </ChartTooltip>
                            </Fragment>
                        }
                    />
                )}
            </BarChart>
        );
    }
}

export default memo(withStyles(styles)(HsiBarChart));
