import {ReactNode, useCallback} from 'react';

import Popover, {PopoverRenderFunc} from 'hsi/components/Popover';
import MultipleOptionsPopoverContent, {MultipleOptionsPopoverContentProps} from './Content';
import Heading, { HeadingContents, ResetHeadingLevel } from 'hsi/components/aria/Heading';

export {default as useMultipleOptionsPopover} from './useMultipleOptionsPopover';

interface MultipleOptionsPopoverProps<T>
    extends Omit<MultipleOptionsPopoverContentProps<T>, 'close' | 'labelId'> {
    dialogTitle: string;
    children: ReactNode;
    onOpen?: () => void;
    onClose?: () => void;
}

//The component
export default function MultipleOptionsPopover<T>({
    dialogTitle,
    options,
    initialOptions,
    getOptionLabel,
    onChangeSelected,
    onSaveSelected,
    onCancelSelected,
    onClearAllSelected,
    loading,
    title,
    disabledOptionTooltip,
    maxOptions,
    otherSavedSearches,
    noResultsMessage,
    noOptionsMessage,
    onOpen,
    onClose,
    info,
    children,
}: MultipleOptionsPopoverProps<T>) {
    const popoverContent = useCallback<PopoverRenderFunc>(
        ({close, labelId}) => {
            return (
                <ResetHeadingLevel>
                    <Heading className="offscreen" id={labelId}>
                        {dialogTitle}
                    </Heading>
                    <HeadingContents>
                        <MultipleOptionsPopoverContent<T>
                            options={options}
                            initialOptions={initialOptions}
                            getOptionLabel={getOptionLabel}
                            onChangeSelected={onChangeSelected}
                            onSaveSelected={onSaveSelected}
                            onCancelSelected={onCancelSelected}
                            onClearAllSelected={onClearAllSelected}
                            loading={loading}
                            title={title}
                            info={info}
                            disabledOptionTooltip={disabledOptionTooltip}
                            maxOptions={maxOptions}
                            otherSavedSearches={otherSavedSearches}
                            noResultsMessage={noResultsMessage}
                            noOptionsMessage={noOptionsMessage}
                            close={close}
                        />
                    </HeadingContents>
                </ResetHeadingLevel>
            );
        },
        [
            dialogTitle,
            disabledOptionTooltip,
            getOptionLabel,
            initialOptions,
            loading,
            maxOptions,
            noOptionsMessage,
            noResultsMessage,
            onCancelSelected,
            onChangeSelected,
            otherSavedSearches,
            onClearAllSelected,
            onSaveSelected,
            options,
            title,
            info,
        ],
    );

    return (
        <Popover placement="bottom" content={popoverContent} onShow={onOpen} onHide={onClose}>
            {children}
        </Popover>
    );
}
