import {CONFIG_BY_APP_SOURCE} from 'hsi/constants/stringsByAppSource';
//Rolled back change to fix user facing bug. Replace with a call to config
const appName = CONFIG_BY_APP_SOURCE[window.APP_SOURCE]?.appName ?? 'Insights';

const phrases = {
    active: 'Active',
    addNew: 'Add new',
    alert: {
        created: 'You\'ve successfully created alert "%{name}".',
        deleted: 'You\'ve successfully deleted alert "%{name}".',
        edited: 'You\'ve successfully edited alert "%{name}".',
        instructions: 'This will appear in the email subject.',
        paused: 'You\'ve paused your alert "%{name}".',
        resumed: 'You\'ve activated your alert "%{name}".',
        warning: 'This action cannot be undone.',
        recipients: {
            errors: {
                noRecipients: {
                    lbl: 'You\'re missing a recipient.',
                    desc: 'Enter at least one recipient email',
                },
            },
        },
        type: {
            action: 'Choose at least one alert type.',
            error: "You haven't selected an alert type.",
        },
        
    },
    alerts: 'Alerts',
    api: {
        action: 'Enter a different one.',
        error: 'There was a problem with your request (%{code} "%{text}")',
        error2: "There was a problem with your query. You'll need to fix it before you can save it.",
        error3: 'An alert with this name already exists.',
        error4: {
            title: "There was a problem with your query. You'll need to fix it before you can save it.",
            desc: 'Query name must be unique',
        },
        error5: {
            desc: '[link] to use the proximity operator.',
            link: 'Save your search ',
        },
    },
    any: 'Any',
    authModalTitle: 'Authenticate with Facebook',
    author: {
        filter: 'Author filter',
        name: 'Author name',
    },
    apply: 'Apply',
    select: 'Select',
    addTargetedDataDialog: {
        authTitle: 'Authenticate with Facebook',
        authMsg:
            'Authenticate with a Facebook page tied to an instagram business account, or invite someone else to. For every authenticated page, you can track up to 30 hashtags',
        targetedDataTitle: 'Add new targeted data',
        hsiTargetedDataTitle: 'Add targeted data',
        subhead: 'Select a data type:',
        noTokensTitle: "You don't have any Facebook tokens",
        noTokensDesc: 'You can add a token from the [link] tab.',
        noTokensDescLink: 'Authentication tokens',
        sourceTypes: {
            facebookOwned: {
                name: 'Owned Facebook page',
                description:
                    'This is a page that your organization or client owns. For the best data coverage, the page admin needs to authenticate with Brandwatch.',
            },
            facebookNonOwned: {
                name: 'Facebook page (not owned)',
                description:
                    'This is a community or competitor owned page. For the best data coverage you need as many Facebook users to authenticate with Brandwatch as possible.',
            },
            instagramHashtag: {
                name: 'Instagram hashtag',
                description:
                    'Authenticate with Facebook using an account with a role on a Facebook page linked to an Instagram business account. For every account you can track up to 30 hashtags.',
            },
            instagramOwned: {
                name: 'Owned Instagram business account',
                description:
                    'Authenticate with an owned Instagram business account to monitor all posts and comments on an owned Instagram account.',
            },
            linkedinChannel: {
                name: 'Owned LinkedIn Page',
                description:
                    'Track LinkedIn pages you or your organization owns. LinkedIn data can be added to an existing Saved Search (not available in Quick Search).',
            },
        },
        limitReachedFbPages: {
            title: "You've reached your Facebook pages limit of %{limit}",
            desc: "Remove pages you're not using (owned or not owned) to make room for new ones.",
        },
        IGHashtags: {
            IGHashtagsModalTitle: 'Add new Instagram hashtag',
            HSIIGHashtagsModalTitle: 'Add Instagram hashtag',
            infoBanner:
                'Added hashtags will count towards your hashtag quota immediately. Hashtags can only be removed at 7 day increments from when you start tracking.',
            authPage: 'Authenticated page',
            addHashtags: 'Add hashtags',
            pageSelectLabel: 'Authenticated page',
            tagSelectLabel: 'List your hashtags here',
            tagsHelpText: 'Add multiple hashtags by separating with a comma or a space.',
            acknowledge:
                "You acknowledge that added hashtags will count towards your hashtag quota and can't be removed for at least 7 days.",
            addNewTokenLink: 'Add new authentication token',
            chipErrorTooltip: 'This hashtag is already tracked by the selected page.',
            chipErrorTitle: 'Hashtag names should be unique',
            chipErrorDesc: 'Make sure the hashtag has a unique name within the page.',
            chipType: 'hashtags',
            successMesg: '%{num} Instagram hashtags have been added.',
            mixedMesgSuccess: '%{num} hashtags successfully added',
            mixedMesgFail: '%{num} hashtags were unsuccessful',
            hashtagsRemaining: '%{num} hashtags left',
        },
        IGAccounts: {
            accountsTitle: 'Add new owned Instagram account',
            hsiAccountsTitle: 'Add owned Instagram account',
            selectLabel: 'Choose one or more pages.',
            searchPlaceholder: 'Search pages',
            addPages: 'Add pages',
            noPagesWithFilter: 'No page names found with keyword: "%{filter}"',
            successMesg: "You've added %{num} Instagram business accounts.",
            mixedMesgSuccess: '%{num} Accounts successfully added',
            mixedMesgFail: '%{num} Accounts were unsuccessful',
        },
        FacebookPage: {
            titleNonOwned: 'Add new Facebook page (not owned)',
            titleOwned: 'Add new owned Facebook page',
            hsiTitleNonOwned: 'Add Facebook page (not owned)',
            hsiTitleOwned: 'Add owned Facebook page',
            labelNonOwned: 'Enter a Facebook profile name or URL.',
            labelOwned: 'Choose one or more pages.',
            placeholder: 'For example, https://www.facebook.com/unicef',
            placeholderOwned: 'Search pages',
            addPage: 'Add page',
            successTitle: 'Success',
            successMessageNonOwned: "You've added a Facebook page (not owned).",
            successMessageOwned: "You've added %{num} owned  pages.",
            noTokenTitle: 'Add a Facebook token',
            noTokenDesc1: 'You can add a new token from the',
            noTokenDesc2: 'authentication',
            noTokenDesc3: 'tokens section',
            alreadyAdded: 'Facebook page already added.',
            pageNotFound: "We couldn't find that Facebook page. Please check the URL.",
            fbUrlInvalid: 'Not a valid Facebook page URL.',
        },
        LinkedinChannel: {
            modal: {
                title: 'Track owned LinkedIn Page',
                orgInputTitle: 'Connect a LinkedIn account to track linked pages',
                orgInputDescription: 'Enter a profile name or URL',
                orgInputPlaceholder: 'e.g. Unicef',
                channelInputPlaceholder: 'Page name...',
                channelInputTitle: 'Add a page name',
                projectInputPlaceholder: 'Team...',
                projectInputTitle: 'Select a team',
                authRequiredMessage: {
                    title: 'You need to authenticate first.',
                    description:
                        'Please use the button below to authenticate with an admin user of the organization you want to track.',
                },
                introMessage: {
                    description:
                        'You have to be an admin of the organisation you’re trying to track. You’ll need super admin access to track it.',
                },
                adminRequiredMessage: {
                    title: 'You need a super admin account to continue.',
                    description:
                        'If this is not your organisation, please go try adding the correct organisation using the LinkedIn URL. Otherwise authenticate with a super admin account for the organisation.',
                },
                orgNotFoundMessage:
                    'We couldn’t find the page. Please check the organization name or URL.',
                orgAlreadyAddedMessage: 'The page is already added.',
                orgDetailsBox: {
                    orgNameTitle: 'Organization name',
                    orgUrlTitle: 'URL',
                },
                createChannelButton: 'Confirm and start tracking',
                connectWithLinkedin: 'Connect with LinkedIn',
                next: 'Next',
                back: 'Back',
                search: 'Search',
            },
            notifications: {
                channelCreationSuccess: {
                    title: 'Page successfuly added',
                    message:
                        'You’re now tracking the page %{name} in the team %{team}. You will need to activate it in your saved search results page by clicking the LinkedIn icon at the top bar.',
                },
                channelCreationError: {
                    title: 'Page not added',
                    genericMessage:
                        'We’re unable to track your LinkedIn page. Try again. If the problem continues, contact Support',
                    channelExistsMessage:
                        'There is already a data source with the name %{name} in this project.',
                    orgAlreadyAddedMessage:
                        'LinkedIn Organization is already associated with a channel for this project',
                },
            },
        },
    },
    dataManagement: {
        // TODO: Refactor to take data type name as props
        addDataSources: 'Add Data Sources',
        addDataSourcesDialog: {
            selectSource: {
                title: 'What do you want to track?',
                subtitle: 'Select a data type:',
            },
        },
        tooltip: {
            title: 'Get actionable insights',
            copy: `
Connect social media accounts accounts to track hashtags, trends, topics and social conversations.

Anyone with an admin or editor page role can connect a page to ${appName}. To track hashtags, make sure your Instagram business profile is linked to a Facebook page.
`,
            cta: 'Learn more',
        },
        tabs: {
            facebookPages: 'Facebook pages',
            instagramAccounts: 'Instagram accounts',
            instagramHashtags: 'Instagram hashtags',
            linkedinPages: 'LinkedIn pages',
            whosConnected: 'Facebook connections',
            linkedinAuthentications: 'LinkedIn connections',
        },
        noResults: {
            desc: 'Track new pages to start collecting content.',
            cta: 'Get started',
            title: "You're not tracking any<br/> %{dataType} yet",
        },
    },
    authTokens: {
        nav: {
            authTokensTab: 'Authentication tokens',
            targetDataTab: 'Targeted data sources',
        },
        tokensPage: {
            loginBlock: {
                title: 'Add power to your data',
                desc1: 'Authenticate with Facebook using an account with a role on a Facebook page linked to an Instagram business account. Invite others to authenticate too.',
                desc2: 'For every authenticated Instagram business account you can track 30 hashtags.',
                loginButton: 'Connect with Facebook',
                invite: 'Invite someone to authenticate with this link',
                or: 'or',
                copyLinkLabel: 'Invite someone to authenticate with this link',
                copyLinkFailed: "We can't load the invite URL.",
            },
            allowModal: {
                desc: "You're providing access to Facebook pages you manage, as well as any linked Instagram business accounts. This allows users within this account to see and use pages to improve coverage.",
                noFbPages: "We couln't find any facebook pages for your account.",
                fbPagesLoadError: "We're unable to get your pages.",
                loadGrantedPagesLoadError: "Something's gone wrong. Please try again.",
            },
            tokenList: {
                title: 'Facebook tokens',
                noItemsMsg: "You haven't authorized any tokens yet.",
                errorMsg: "Something's gone wrong while loading the tokens.",
                user: 'User',
                page: 'Page',
                linkedAccount: 'Linked account',
            },
            userTokenSaved: "You've successfully saved your user token.",
            fbLoginError: 'We were unable to log you into Facebook.',
            successTitle: 'Success',
            successMesg: "You've added %{num} Facebook tokens.",
            unknownError: 'Something went wrong!',
        },
        targetedData: {
            landing: {
                emptyTitle: 'Add power to your data',
                emptyDescription:
                    'Collect data from Facebook and Instagram to use in your searches.',
                emptyAddButton: 'Add targeted data',
                title: '%{num} Data sources',
                addButton: 'Add new',
                hsiAddButton: 'Add',
                hashtag: 'Hashtag',
                account: 'Account',
                page: 'Page',
                user: 'User',
                dataSource: 'Data source',
                active: 'Active',
                inactive: 'Inactive',
                toBeDeleted: 'to be deleted',
                scheduledDeletionTooltip:
                    'This hashtag will be deleted in <strong>%{num} days</strong>.',
                cancelDeletion: 'Cancel deletion',
                delete: 'Delete',
                deletionCancelSuccess: "You've successfully cancelled the deletion.",
                deletionScheduleSuccess: "You've successfully scheduled the hashtag for deletion.",
                deletionSuccess: "You've successfully deleted the item.",
                confirmDeleteTitle: 'Delete targeted data',
                confirmDeleteLine1:
                    'Confirm that you want to delete the %{itemType} <strong>"%{itemName}"</strong>.',
                confirmDeleteLine2: 'This action cannot be undone',
                limitAlert:
                    'You have reached your limit, please contact your CSM to increase your data sources limit',
                team: 'Team',
            },
        },
    },
    back: 'Back',
    'by.user': 'By user',
    actions: 'Actions',
    callout: 'Callout',
    cancel: 'Cancel',
    close: 'Close',
    chart: 'Chart',
    'edit.saved.search': 'Save changes',
    clear: 'Clear',
    'clear.filters': 'Clear filters',
    'confirm.delete.alert': 'Do you want to delete the alert "%{alert}"?',
    'confirm.delete.item': 'Do you want to delete the saved search "%{search}"?',
    'confirm.delete.saved.search': 'Do you want to delete the saved search "%{search}"?',
    'confirm.delete.lost.data.1': 'Existing mention annotations ',
    'confirm.delete.lost.data.2': '(such as sentiment, emotion, location, tags) ',
    'confirm.delete.lost.data.3': 'will be deleted.',
    'confirm.delete.cannot.undo': 'This action cannot be undone.',
    'confirm.edit.saved.search': 'Do you want to save the changes to the saved search "%{search}"?',
    'confirm.edit.cannot.undo': 'This action cannot be undone.',
    'content.source.filter': 'Content source filter',
    copy: 'Copy',
    created: 'Created',
    'date.created': 'Date created',
    'date.filter': 'Date filter',
    delete: 'Delete',
    'delete.alert': 'Delete alert',
    'delete.saved.search': 'Delete saved search',
    description: 'Description',
    edit: 'Edit',
    'edit.email.alert': 'Edit email alert',
    'editing.saved.search': "You're editing a saved search.",
    'editing.saved.search.characters': 'Save your changes to view them.',
    'editing.saved.search.tooLong':
        'Your search is too long to save. Try using operators to make your search shorter.',
    'email.alerts': 'Email qlerts',
    emotion: 'Emotion',
    'emotion.filter': 'Emotion filter',
    'enter.your.password': 'Enter your password',
    'enter.your.username': 'Enter your username',
    'error.deleteQuery': 'An error occured while deleting your search. Please try again later.',
    'exit.edit.mode': 'Exit edit mode',
    expires: 'Expires',
    explore: 'Explore',
    fieldErrorTitle: 'Error',
    fieldWarningTitle: 'Warning',
    filterOptions: 'Filter options...',
    'fix.error': 'Fix %{error} error',
    'fix.errors': 'Fix %{errors} errors',
    followed: 'Followed',
    followers: 'Followers',
    geography: 'Geography',
    'give.feedback': 'Give feedback',
    hashtag: 'Hashtag',
    'help.center': 'Help Center',
    nonactive: 'Non active',
    inactive: 'Inactive',
    'language.label': 'Language',
    'language.authorTotalTooltip': 'Total number of authors posting in this language',
    'language.sparklineTooltip':
        'Number of authors posting in this language over the selected period',
    impressions: 'Impressions',
    reach: 'Reach',
    'just.you': 'Just you',
    'keyword.filter': 'Keyword filter',
    language: 'Language',
    'language.filter': 'Language filter',
    'last.modified': 'Last modified',
    loading: 'Loading...',
    login: 'Sign in',
    loginUserIsUpdating: 'Please wait a few seconds while we update your account information.',
    loginUserIsProvisioning: 'Please wait a few seconds while we set up your account.',
    'manage.search': 'Edit Search',
    manualLogin: {
        email: 'Email',
        password: 'Password',
        login: 'Sign in',
        almostThere: 'Almost there!',
        getStarted: 'Get started',
        subtitle: 'Enter your Brandwatch details.',
        linkUserSubtitle: 'Enter your Brandwatch details to complete the sign up.',
        somethingMissing: "Something's missing. Check and try again.",
        somethingMissingDesc: 'Enter your email address and password',
        badCredentials: 'The email or password you entered is incorrect.',
        badCredentialsDesc: 'Please try again.',
        productDisabled: "This product isn't enabled for you.",
        productDisabledDesc: 'Contact Support if this is a mistake.',
        genericError: "Something's gone wrong…",
        genericErrorDesc: 'Try again or contact Support.',
    },
    'mention.list': 'Mentions list',
    mention: 'Mention',
    mentions: 'Mentions',
    min: 'Min',
    max: 'Max',
    score: 'Net sentiment score',
    mentionsTitle: 'Place mentions here',
    more: 'More',
    name: 'Name',
    'name.action': 'Enter a name for your search',
    'name.action2': 'Shorten your search name and try again.',
    'name.action3': "This is likely a duplicate search name, make sure it's unique",
    'name.action4': 'Enter a name for your alert',
    'name.error': 'Enter a name and try again.',
    invalidVolumeAlertValueError:
        'Oops! Alerts are only available for increases in volume. Please enter a non-zero positive integer',
    'name.length.error': 'Your search name cannot be more than %{limit} characters',
    'new.email.alert': 'New email alert',
    'no.email.alerts.found': 'No email alerts found.',
    'no.filters.applied': 'No filters applied.',
    noDefaultProjectError:
        "You haven't been given access to any projects. Contact your administrator to get access.",
    'no.value': 'No %{val}',
    homeNavBar: {
        quicksearch: 'Quick search',
        savedsearches: 'Saved searches',
        emailAlerts: 'Email alerts',
        settings: 'Settings',
        reports: 'Reports',
    },
    'num.recipients': '%{num} recipients',
    'one.recipient': '1 recipient',
    next: 'Next',
    oops: "Something's wrong",
    optional: '(optional)',
    owner: 'Owner',
    pause: 'Pause',
    paused: 'Paused',
    percentage: '%{pct}%',
    'percent.mentions': '% of top 10 mentions',
    platform: 'Platform',
    pleaseSelect: 'Please choose...',
    'please.select.a.team': 'Choose a team...',
    'powered.by.brandwatch': 'powered by Brandwatch',
    project: 'Project',
    'project.action': 'Choose a project from the list',
    'project.error': "You haven't selected a project.",
    'query.here': 'Query here',
    'query.saved': 'Query saved!',
    'quicksearch.example': 'Or start with an example',
    'quicksearch.boolean-help': 'Search Help',
    'quicksearch.quicksearch': 'Quick Search',
    readMore: 'Read more',
    readLess: 'Read less',
    'recently.viewed': 'Recently saved searches',
    'recently.viewAll': 'View all',
    recipients: 'Recipients',
    'refresh.the.search': 'Refresh the search',
    'view.your.searches': 'View your saved searches',
    resume: 'Resume',
    save: 'Save',
    set: 'Set',
    continue: 'Continue',
    'save.as.a.new.search': 'Save as a new search',
    'save.changes': 'Save changes',
    'save.search': 'Save search',
    'saved.search': 'Saved search',
    'saved.searches': 'Saved searches',
    'saved.searches.data.gathering': 'Gathering data for this search',
    'saved.searches.data.gathered': 'Data for this search now complete.',
    'saved.searches.left':
        '%{smart_count} saved search left |||| %{smart_count} saved searches left',
    'search.saved': 'Search saved!',
    'search.search': 'search',
    'search.placeholder': 'Search for a brand, hashtag, handle or topic.',
    'search.validating': 'Validating',
    'search.validationOk': 'Validation successful',
    'search.unauthorizedToSave':
        'Your current user role does not permit creation of Saved Searches. Please contact an Admin user within your organization to request additional permissions.',
    'select.project': 'Choose a project',
    sentiment: 'Sentiment',
    'sentiment.filter': 'Sentiment filter',
    'site.name': 'Site name',
    'sorry.no.items.found': 'No %{items} found.',
    search: 'Search',
    'search.shortPlaceholder': 'Search...',
    searches: 'searches',
    submit: 'Submit',
    successTitle: 'Success',
    status: 'Status',
    left: '%{num} %{name} left',
    team: 'Team',
    'team.action': 'Choose a team from the list',
    'team.error': "You haven't selected a team",
    'there.appears.an.error': "There's an issue with your search",
    to: 'to',
    token: 'Token',
    'netSentiment.cta':
        '[link] to see the net sentiment of this search based on the selected date frame.',
    'total.authors': '%{authors} authors',
    'total.gender': '%{volume} mentions',
    type: 'Type',
    unauthorized: {
        title: `${appName} authorization failed`,
        autoprovisionErrorMsg: `An error occured while setting up your ${appName} account. Try reloading your browser. If that doesn't work, contact Support.`,
        autoprovisionTimeoutMsg:
            "Your account setup timed out. Try reloading your browser. If that doesn't work, contact Support.",
        defaultMsg: "Try reloading your browser. If that doesn't work, contact Support.",
    },
    unauthorizedMessage: 'Authorization failed :(',
    uniqAuthors: 'Unique authors',
    unknownError: "Something's gone wrong. Try again later.",
    username: 'Username',
    user: 'User',
    'weekday.monday': 'Mon',
    'weekday.tuesday': 'Tue',
    'weekday.wednesday': 'Wed',
    'weekday.thursday': 'Thu',
    'weekday.friday': 'Fri',
    'weekday.saturday': 'Sat',
    'weekday.sunday': 'Sun',
    'you.can.create.an.alert': 'Create an email alert from any saved search.',
    quicksearch: {
        heading: 'Welcome to Listen',
        title: 'Explore the voices of billions of people',
    },
    validation: {
        validating: 'Validating',
        validationOk: 'Validation successful',
    },
    searchbox: {
        placeholder: 'Search for a brand, hashtag, handle or topic...',
    },
    searchbar: {
        newsearch: 'New search',
        savequery: 'Save query',
        savesearch: 'Save search',
        quicksearch: 'Quick search',
        lastNdays: 'Last %{days} days',
        notValidated: 'Please hit enter to test and validate your search before you save.',
        searchesLimitReached:
            "You've **reached your limit** of saved searches. Contact your Customer Success Manager if you want to upgrade your package.",
    },
    savedsearchbar: {
        newquicksearch: 'New quick search',
        managesearch: 'Edit search',
        managetags: 'Manage tags',
        savedsearches: 'Saved searches',
        createalert: 'Create alert',
        buildreport: {
            button: 'Build report',
            tooltip: 'Build new report.',
            exportPDF: 'Export dashboard as PDF',
            reportCreated: 'The scheduled report "%{name}" has been created.',
            reportError: 'There was an error creating your schedule.',
            reportOptions: {
                scheduleReport: 'Schedule report',
                exportToPDF: 'Export dashboard as PDF',
                exportToPDFWithNotes: 'Export as PDF',
            },
        },
    },
    entitiesRemaining: {
        msg: '%{smart_count} left |||| %{smart_count} left',
    },
    savedSearchesLeft: {
        popupTitle: 'Total saved searches',
        popupContent:
            'On the Standard Plan all your teams can save up to %{num} searches together. Contact your Customer Success Manager to increase your limit.',
        banner: {
            firstSentence: "You've reached your limit.",
            secondSentence:
                'Contact your Customer Success Manager to increase your saved searches limit.',
        },
    },
    dataSourcesLeft: {
        title: 'Total targeted data sources',
        content:
            "Make sure you've authenticated with a token before adding non-owned pages. For the best coverage, it is recommended to authenticate with one token for every 100 non-owned pages added.",
    },
    selectAll: 'Select all',
    buildnewreport: {
        title: 'Build new report',
        message: "You'll be redirected to Analytics to continue building your Insights report.",
        checkboxmessage: "Don't show this again",
    },
    managesearchdialog: {
        lastmodifiedusername: 'By user',
        lastmodifiedBy: 'Modified by',
        team: 'Team',
        lastmodificationdate: 'Last modified',
        editsearch: 'Edit search',
        search: 'Search',
    },
    managetagsdialog: {
        tags: 'Tags',
        manageTags: 'Manage tags',
        newTag: 'New tag name...',
        searchPlaceholder: 'Search tags',
        add: 'Add',
        close: 'Close',
        edit: 'Rename tag',
        delete: {
            title: "Do you want to delete the tag '%{name}'?",
            sub: "Deleting this tag will remove it from any mentions it's been applied to.",
            disclaimer: 'This action cannot be undone.',
        },
        taggedMentionsTitle: '%{number} mentions found for tag "%{tag}"',
        taggedMentions: 'Tagged mentions',
        selectATag: 'Choose a tag from the list to see the related mentions.',
        noMentions: 'No mentions with this tag',
        tryAgain: 'Try selecting a different tag from the list.',
        errors: {
            tagExists: {
                main: 'This tag name already exists',
                secondary: 'Enter a different name.',
            },
        },
        notifications: {
            success: {
                first: 'The tag ',
                created: ' has been successfully created',
                edited: ' has been successfully renamed to',
            },
        },
        hsiAddNewPlaceHolder: 'Tag name',
        hsiNewTitle: 'Add new tag',
        hsiSearchPlaceholder: 'Type or search a tag',
        hsiSearchTitle: 'Tags list',
        hsiSearchSubTitle: 'Choose a tag from the list to see the related mentions.',
    },
    cards: {
        noData: {
            msg1: "There's no data.",
            msg2: 'Check the filters section.',
            msg2b: 'Check the filters section or the tile configuration.',
        },
        error: {
            msg1: "There's a problem loading card data.",
            msg2: 'Try again later or contact support.',
        },
        unsupported: {
            msg1: 'Unsupported breakdown for selected date range.',
            msg2: 'Choose a time range of 1 day when segmenting by hours.',
        },
        notAvailablePerDataLimitation: {
            topinterests: {
                msg1: 'This card is only available for X (Twitter) data.',
                msg2: 'Check your platform filters.',
            },
            heatmap: {
                msg1: 'This card does not support the selected date range.',
                msg2: 'Choose a date range up to 365 days',
            },
            benchmark: {
                msg1: 'The benchmark element of this card has been removed.',
                msg2: 'This is because the benchmark period includes dates which are beyond the 1 year of available historical data.',
            },
            benchmarkBySearch: {
                msg1: 'The benchmark element of this card has been removed.',
                msg2: 'This is because the benchmark period includes dates which are beyond the 1 year of available historical data.',
            },
        },
        totalMentions: {
            title: 'Total mentions',
            subheader: 'Powered by Brandwatch',
            previousPeriodLabel: 'Previous period',
        },
        totalVolume: {
            sections: {
                volume: 'Total mentions',
                reachEstimate: 'Total reach',
                twitterRetweets: 'Total reposts',
                impressions: 'Total impressions',
                authors: 'Unique authors',
                avgFollowers: 'Average followers',
                retweetRate: 'Repost rate',
            },
            previous: 'Previous period:',
            title: 'Total volume',
            info: {
                title: 'Quickly see how popular your search is.',
                copy: 'Compare six key metrics (such as volume, impressions and reach) to see change over time. Adjust these using the settings icon.',
            },
            subtitle: 'Benchmarked for %{start_date} against data from %{end_date}',
            noBenchmark:
                'The benchmark element of this card has been removed. This is because the benchmark period includes dates which are beyond the 1 year of available historical data.',
        },
        mentionsHistory: {
            title: 'Mention volume over time',
            generalDimension: 'Mention ',
            overDimension: ' over time ',
            subheader: 'Powered by Brandwatch',
            yAxisName: 'Mention volume',

            info: {
                title: 'Identify changes in conversation over time.',
                copy: 'View your mention volume/impressions/reach by hour, day, week, or month. Listen’s AI assistant will detect and pinpoint moments where interest was higher than usual. Click these for more information.',
            },
        },
        mentionsHistoryBySearch: {
            title: 'Mention volume',
            subtitle: 'broken down by searches',
        },
        heatmap: {
            title: 'Mention volume by day of the week and hour',

            info: {
                title: 'Discover the best time to post your content.',
                copy: 'Spot trends by time of day or day of the week. Hover over the heat map to see the number of mentions at that time.',
            },
        },
        sentimentHistory: {
            title: 'Sentiment volume over time',
            subheader: 'Powered by Brandwatch',
            yAxisName: 'Mention volume',

            info: {
                title: 'Explore changes in sentiment.',
                copy: 'Identify events that may have triggered a positive or negative reaction from your audience. Listen’s AI assistant highlights any sharp rises. View by hour, day, week, or month.',
            },
        },
        emotionHistory: {
            title: 'Emotion volume over time',
            subheader: 'Powered by Brandwatch',
            yAxisName: 'Mention volume',

            info: {
                title: 'Explore changes in emotion over time.',
                copy: 'Identify events that may have triggered an emotive reaction from your audience. Listen’s AI assistant highlights any sharp rises. View by hour, day, week, or month.',
            },
        },
        netSentimentHistory: {
            title: 'Net sentiment over time',
            subheader: 'Powered by Brandwatch',
            yAxisName: 'Mention net sentiment',
            multipleSearch: {
                title: 'Net sentiment broken down by searches',
            },
            info: {
                title: 'Net sentiment over time',
                copy: 'See how the overall sentiment of each search changes over time. Click on a plot point to view mentions for a specific time. Net sentiment is calculated as: ( Positive - negative mentions / Positive + negative mentions ) * 5.',
            },
        },
        sentimentVolume: {
            title: 'Sentiment volume',
            multipleSearch: {
                title: 'Sentiment volume across all searches',
            },
            info: {
                title: 'Understand sentiment and identify key drivers.',
                copy: "Get an overview of your positive, neutral and negative mentions. Sentiment is determined by Listen's advanced machine learning classifier.",
            },
        },
        sentimentVolumeBySearch: {
            title: 'Sentiment volume broken down by searches',
        },
        emotionVolume: {
            title: 'Emotion volume',

            info: {
                title: 'Understand the emotions of your audience.',
                copy: "Gain insight on the emotions of your audience and identify key drivers. Emotion is detected by Listen's machine learning.",
            },
        },
        pageTypeBySearch: {
            title: 'Platform volume broken down by searches',
        },
        geography: {
            sparklineTooltipQuick: 'Number of authors per country over the last 30 days.',
            sparklineTooltipSaved: 'Number of authors per country over the selected period.',
            uniqAuthorCTASplitBy: 'country',

            info: {
                title: 'Understand your demographics.',
                copy: 'Discover where your audience is located based on the top countries associated with your search.',
            },
        },
        geographyBySearch: {
            title: 'Mention volume by countries broken down by searches',
        },
        topinterests: {
            title: 'Top interests',
            uniqAuthorCTASplitBy: 'interest',

            info: {
                title: 'Understand your audience’s interests.',
                copy: 'Build a strategy that resonates with your audience. Explore the most popular interests, and how these change over time.',
            },
        },
        toplanguages: {
            title: 'Top languages',
            uniqAuthorCTASplitBy: 'language',

            info: {
                title: 'Understand your demographics.',
                copy: 'See the top languages for your search, and the number of authors for each one. (Languages not detected will show as unknown.)',
            },
        },
        topauthors: {
            title: 'Top authors',
            tableFields: {
                name: 'Author name',
                mentions: 'Mentions',
                followers: 'X (Twitter) Followers',
                reach: 'Reach',
            },
            percentOfMentions: '%{percent} of mentions from top authors',

            info: {
                title: 'Identify influential authors',
                copy: 'View your top authors for your mentions, sorted by number of mentions, reach, or X (Twitter) followers, and analyse their percentage share.',
            },
        },
        topauthorsBySearch: {
            title: 'Top authors broken down by searches',
            nameFieldLabel: 'Author',
        },
        tophashtags: {
            title: 'Top hashtags',

            info: {
                title: 'Identify new or trending themes.',
                copy: 'Explore the most popular hashtags, and how often they are used in your mentions.',
            },
        },
        tophashtagsBySearch: {
            title: 'Top hashtags broken down by searches',
            nameFieldLabel: 'Hashtag',
        },
        topSharedURLs: {
            info: {
                title: 'Identify trending links.',
                copy: 'Understand what content is resonating with your audience by exploring the frequently shared URLs on X (Twitter).',
            },
        },
        topsites: {
            info: {
                title: 'Identify influential websites.',
                copy: 'Explore where conversations are taking place. Apply filters to spot sites with mostly positive or negative discussions.',
            },
        },
        topsitesBySearch: {
            title: 'Top sites broken down by searches',
            nameFieldLabel: 'Site',
        },
        wordCloud: {
            title: 'Word cloud',

            info: {
                title: 'Identify trending topics.',
                copy: 'Explore frequently used words and phrases from your search. The larger the word, the more it is mentioned. Adjust this display using the settings icon.',
            },
        },
        wordCloudBySearch: {
            title: 'Top topics across all searches',
        },
        toptopicsBySearch: {
            title: 'Top topics broken down by searches',
            nameFieldLabel: 'Topic',
        },
        gender: {
            title: 'Gender split',
            total: '%{volume} mentions',

            info: {
                title: 'Understand your demographics.',
                copy: 'See how your X (Twitter) audience is split between male and female authors.',
            },
        },
        topicWheel: {
            title: 'Topic wheel',
            cta: '[link] to see a breakdown of this search based on topic group.',

            info: {
                title: 'Understand your popular topics. ',
                copy: 'Discover the relationships between topics to develop themes. Use the interactive wheel to explore the most popular topics in the centre, and subtopics in the outer circle.',
            },
        },
        benchmark: {
            title: 'Mention volume benchmark',
            subtitle: 'Benchmarked for %{start_date} against data from %{end_date}',

            info: {
                title: 'Explore changes in share of voice.',
                copy: 'Compare volume of mentions between the current period and the previous period. Filter by platform, sentiment, emotion, interest, language, or country.',
            },
        },

        comingSoon: {
            msg1: 'Coming soon!',
            msg2: "This visualization isn't quite ready yet. Check again soon.",
        },
        breakdownBy: 'by ',
        historyBreakdownBySearch: 'broken down by searches',
    },
    filters: {
        title: 'Filters',
        clear: 'Clear',
        clearAll: 'Clear all',
        all: 'All',
        any: 'Any',
        type: {
            gender: 'Gender',
            keyword: 'Keyword',
            classifications: 'Emotion',
            location: 'Location',
            language: 'Language',
            pageType: 'Platform',
            sentiment: 'Sentiment',
            author: 'Author',
            site: 'Site',
            tag: 'Tags',
            category: 'Categories',
            workflow: 'Workflow',
            twitterAuthor: 'X (Twitter) account type',
            ownedContent: 'Owned content',
            threadEntryType: 'Mention type',
        },
        typeBCR: {
            gender: 'Gender',
            keyword: 'Keyword',
            classifications: 'Emotion',
            location: 'Location',
            language: 'Language',
            pageType: 'Content source',
            sentiment: 'Sentiment',
            author: 'Author',
            tag: 'Tags',
        },
        placeholders: {
            search: {
                keyword: 'Add keywords and hit enter',
                author: 'Search author',
                tag: 'Enter tags',
                language: 'Search languages',
                location: 'Search locations',
                owned: 'Search owned handles',
                domain: 'Search owned websites',
                site: 'Search websites',
                handles: 'Search owned handles',
            },
        },
        inclusionDropDown: {
            include: 'Include',
            exclude: 'Exclude',
            filterBy: 'Filter by',
        },
        disabled: {
            bold: 'Save your search',
            normal: ' to enable this filter.',
        },
        workflow: {
            assignment: 'Assignment',
            checked: 'Checked',
            priority: 'Priority',
            status: 'Status',
            high: 'high',
            low: 'low',
            medium: 'medium',
            na: 'n/a',
            open: 'open',
            pending: 'pending',
            closed: 'closed',
            yes: 'yes',
            no: 'no',
            any: 'Any',
        },
        twitterAuthor: {
            verification: 'Verification',
            verified: 'Verified',
            unverified: 'Unverified',
            userRole: 'User role',
            audience: 'Audience',
            owner: 'Owner',
            followersLabel: 'Followers',
            validateIntMsg: 'Enter a positive integer number.',
            accountType: 'Account Type',
            individual: 'Individual',
            organizational: 'Organizational',
        },
        ownedContent: {
            validateDomainMsg: 'Enter a valid domain.',
        },
        threadEntryType: {
            post: 'Original post',
            reply: 'Comment / Reply',
            share: 'Share / Repost',
        },
    },
    mentionsContainer: {
        title: 'Mentions',
        info: {
            title: 'See all your mentions in detail.',
            copy: 'Find useful mentions for your reports, tag individual mentions, and determine which require further action. Get detailed information about each mention, including date, source and author.',
        },
        noData: {
            msg1: "There's no data.",
            msg2: 'Check the filters section.',
        },
        error: {
            msg1: "There's a problem loading mentions.",
            msg2: 'Try again later or contact Support.',
        },
        error300: {
            msg1: "There's a problem loading mentions from the selected group.",
            msg2: "At least 1 of the queries of this group isn't active.",
        },
        drillIn: {
            clear: 'Clear drill-in',
            viewing: 'Viewing',
            from: ' from ',
            apply: 'Apply as filter',
            filterAlreadyApplied: "You've already applied this filter. Check your filters.",
        },
        export: {
            export: 'Export data',
            downloadCsv: 'Download CSV file',
            downloadJson: 'Download JSON file',
            loadingMsg: 'Loading export data...',
            noMentionsMsg:
                'No mentions to export. Change your search keywords and check your filters.',
            failMsg: "Something's gone wrong. Try again.",
            exportDisabled: "Your pricing plan doesn't allow mention exports.",
        },
        peak: {
            drawerTitle: 'Iris Peak detection',
            label: 'Peak: %{name}, on: %{peaked}',
            highestVolumeLbl: 'highest volume: %{value}',
            diff: 'Volume was %{percent}% higher than usual',
            driver: {
                facebook: '**%{volume} mentions** on a [Facebook thread](%{url})',
                hashtag: '**%{volume} mentions** using the hashtag **%{hashtag}**',
                news: '**%{volume} mentions** from news sites',
                none: 'There are no distinct drivers for this peak.',
                reddit: '**%{volume} mentions** on a [Reddit thread](%{url})',
                retweet: '**%{volume} reposts** of this [Post](%{url})',
                url: '**%{volume} mentions** sharing a link from [%{url}](%{data})',
                video: '**%{volume} mentions** from video sites',
            },
        },
        fullLayout: {
            masonry: 'Masonry layout',
        },
    },
    mentionComponent: {
        via: 'via %{plattform}',
        metadata: {
            findLocations: 'Type to find locations',
            sentimentNA: 'Sentiment n/a',
            emotionNA: 'Emotion n/a',
            locationNA: 'Location n/a',
            languageNA: 'Language n/a',
            msg202: "Your changes have been stored. But it'll take a while to show on our database.",
            locationPlaceholder: 'Search location',
        },
        tags: {
            placeholder: 'Search tags',
            create: 'Create new tag',
            add: 'Add tag',
        },
        reach: 'Reach %{value}',
        author: 'Author',
        showMore: 'Show more',
        showLess: 'Show less',
    },
    singleMentionView: {
        date: 'Date:',
        location: 'Location:',
        language: 'Language:',
        sentiment: 'Sentiment:',
        emotion: 'Emotion:',
        reach: 'Reach:',
        retweeets: 'Reposts:',
        impressions: 'Impressions:',
        replies: 'Replies:',
        likes: 'Likes:',
        comments: 'Comments:',

        delete: {
            body: 'This will remove this mention from this saved search.',
        },
    },
    noResults: 'No results',
    noSearchResults: {
        title: 'No results returned',
        subtitleQuick: 'Check your spelling.',
        subtitleSaved: 'Try selecting a different date range.',
        tryQuick: ' or try ',
        trySaved: ' or ',
        clearFilters: 'clearing your filters',
        linkedinWarning:
            "You have selected LinkedIn in your platform filters but haven't connected any pages. Please check the LinkedIn icon at the top bar or try ",
    },
    anchors: {
        mentions: 'mentions count',
        sentiment: 'sentiment',
        emotion: 'emotion',
        topMeasures: 'top measures',
        wordCloud: 'word cloud',
        demographics: 'demographics',
        topicWheel: 'topic wheel',
        benchmark: 'benchmark',
    },
    datepicker: {
        last7: 'Last 7 days',
        last30: 'Last 30 days',
        last60: 'Last 60 days',
        lastYear: 'Last year',
        disabled: {
            bold: 'Save your search',
            boldbcr: 'Save your query',
            normal: ' to track this data over time.',
            historical: ' for instant access to 1 year of historical data.',
        },
        dateOnlyTooltip: 'Date',
        tooltip: 'Date and Timezone',
        timezoneSelector: {
            helpTitle: 'Timezone help',
            placeholder: 'Search for a timezone',
            title: 'Timezone',
            defaultTimezone: '%{defaultTimezone}',
            helpTooltipTitle: 'Adjust Timezones',
            helpTooltipCopy: `
The data in this dashboard will be calculated based on the selected timezone.

These changes will affect all dashboard components, mention details, and PDF exports.

Alerts, snapshot scheduled reports, and CSV exports will not be affected and remain in UTC.
`,
        },
    },
    'notFound.oops': "Something's wrong",
    'notFound.broken': 'The link you clicked may be broken or the page has been removed.',
    'notFound.return': 'Return to homepage',
    breakdowns: {
        minutes: 'Minute',
        hours: 'Hour',
        days: 'Day',
        weeks: 'Week',
        months: 'Month',
        pageTypes: 'Platform',
        sentiment: 'Sentiment',
        emotions: 'Emotion',
        interest: 'Interest',
        languages: 'Language',
        countries: 'Country',
    },
    saveSearchTooltip: {
        main: 'Save your search',
        mentions: ' to see more details, like: sentiment, emotion, and language.',
        filters: ' to enable this filter.',
        exclusionFilter: ' to enable the option to exclude items with this filter',
        export: ' to export and download mentions.',
        unauthorized:
            'Your current user role does not permit creation of Saved Searches which are required to use the exclude option of this filter. Please contact an Admin user within your organization to request additional permissions.',
        link: 'Save your search',
        characterLimitError: '[%{linkText}](%{linkUrl}) to get unlimited characters.',
    },
    newStreams: {
        followStory: 'Follow story',
        goToStream: 'Go to stream',
        email: 'Send mention to email',
        starMention: 'Star',
        unstarMention: 'Unstar',
    },
    streams: {
        appBar: {
            logout: 'Sign out',
            filters: 'Filters',
            settings: 'Settings',
            editSetup: 'Edit project / query',
            signOut: 'Sign out',
            myBrandwatch: 'My Brandwatch',
        },
        filters: {
            title: 'Filters',
            clearAll: 'Clear filters',
            apply: 'Apply',
        },
        querySetup: {
            title: 'Setup',
            subtitle: 'Just pick a project and a query',
            projectLabel: 'Project',
            projectPlaceholder: 'Select a project',
            queryLabel: 'Query',
            queryPlaceholder: 'Select a query',
            done: 'Done',
            group: 'group',
        },
        mentions: {
            loadingMentions: 'Loading mentions...',
        },
        mentionActions: {
            addTags: 'Add tags',
            assignTo: 'Assign to',
            editTags: 'Edit tags',
            workflow: 'Workflow',
            moreActions: 'More actions',
            starMention: 'Star',
            unstarMention: 'Unstar',
            retweet: 'Repost',
            dm: 'Direct message',
            reply: 'Reply',
        },
        mentionsTagger: {
            addTags: 'Add tags',
            editTags: 'Edit tags',
            apply: 'Apply',
            helperText: 'Select multiple predefined tags.',
            placeholder: 'Search tags',
        },
        editWorkflow: {
            title: 'Workflow',
            assignTo: 'Assign to a user',
            any: 'Any',
            selectPh: 'Select',
        },
    },
    uniqAuthorsCTA: {
        message:
            '[link] to see the number of authors who posted about this search, split by %{splitBy}.',
        linkText: 'Save your search',
    },
    TopBar: {
        powered: 'powered by Brandwatch',
        insights: appName,
    },
    errorMap: {
        mixed_and_or: `You mixed AND and OR operators in a way we can't understand. For example, if your search string was "hot chocolate OR cocoa", we don't know if you meant "(hot chocolate) OR cocoa", or "hot AND (chocolate OR cocoa)". To fix this error, use parentheses or brackets to make it clear what you're searching for.`,
        lowercase_operator: `You used the words "and" or "or" without capitalizing them, or putting them in quotes. If you meant to use a search operator you need to capitalize them, like "apple AND juice" instead of "apple and juice". If you meant the word to be a part of a search string you need quotes, like "half and half".`,
        missing_left_operand: `You can't start a search with AND or OR. For example, "apple AND juice" is valid, but "AND juice" or "OR juice" isn't.`,
        missing_right_operand: `You can't end a search with AND or OR. For example, "apple AND juice" is valid, but "apple AND" or "apple OR" isn't.`,
        dangling_parenthesis: `You forgot a closing parenthesis. For example, "(hot chocolate) OR cocoa" is valid, but "(hot chocolate OR cocoa" isn't.`,
        trailing_parenthesis: `You forgot an opening parenthesis. For example, "(hot chocolate) OR cocoa" is valid, but "hot chocolate) OR cocoa" isn't.`,
        dangling_quote: `You forgot to close a quoted term. For example, "hot chocolate" is valid, but "hot chocolate (without the closing quote) isn't.`,
        quote_in_term_error: `You forgot a start quote. For example, "hot chocolate" is valid, but hot chocolate" (without the starting quote) isn't. Alternatively, if you want a quote mark in your search you must precede it with a backslash character, like this: \\"`,
    },
    configSideDrawer: {
        title: 'Configure tile',
        emptySelection: 'None',
        wordCloud: {
            show: 'Show',
            orderBy: 'Word size',
            color: 'Color',
            cloudSize: 'Cloud size',
            selectItems: {
                color: {
                    topicType: 'Topic type',
                    gender: 'Gender',
                    sentiment: 'Sentiment',
                    volume: 'Volume',
                    trending: 'Trending',
                    random: 'Random',
                    none: 'None',
                },
                orderBy: {
                    trending: 'Trending',
                    volume: 'Volume',
                },
            },
        },
        totalVolume: {
            select: {
                label: 'Metric %{i}',
                none: 'None',
            },
        },
    },
    queryBuilder: {
        guidedTitle: 'Or try a guided search',
        guidedOptions: {
            brand: 'Search for a <strong>brand</strong> (e.g Apple)',
            issue: 'Search for an <strong>issue</strong> (e.g. #GlobalWarming)',
            campaign: 'Search for a <strong>campaign</strong> (e.g. "Share a coke")',
            person: 'Search for an <strong>influencer(s)</strong> (e.g. @rihanna)',
            socialAccount: 'Search for a <strong>social account</strong> (e.g. @myCompany)',
            topic: 'Search for a <strong>topic</strong> (e.g. Halloween)',
        },

        guidedOptionsLabels: {
            brand: 'a brand',
            issue: 'an issue',
            campaign: 'a campaign',
            person: 'an influencer(s)',
            socialAccount: 'a social account',
            topic: 'a topic',
        },

        headerTitle: 'Search for %{label}',

        includeTitle: 'Hit “enter” to input separate words and phrases.',
        excludeIrrelevantKeywordsTitle: 'Words and phrases',
        excludeIrrelevantKeywordsSubtitle:
            'Enter any words and phrases to exclude from your results.',
        excludeIrrelevantKeywordsPlaceholder: 'Music, Apple podcasts',
        excludeWebsitesTitle: 'Websites',
        excludeWebsitesSubtitle: 'Enter any websites to exclude from your results.',
        excludeWebsitesPlaceholder: 'everymac.com',
        excludeAuthorsTitle: 'Social accounts',
        excludeAuthorsSubtitle:
            'Enter any usernames or social media handles to exclude from your results.',
        excludeAuthorsPlaceholder: 'applebot404',

        brandNameTitle: 'Brand name',
        brandNameSubtitle: 'What brand would you like to track?',
        brandNamePlaceholder: 'Apple',
        brandRelatedTermsTitle: "The brand's name is also a common word, e.g. Apple",
        brandRelatedTermsInfo:
            'We will ask you for words you would expect to find near the brand (we call these "context terms") so we can sort brand mentions from everyday conversation.',
        brandRelatedTermsSubtitle: 'Enter words that you’d expect to find near it.',
        brandRelatedTermsPlaceholder: 'Computer, Mac, hard drive',
        brandNicknamesTitle: 'Alternative brand or product names',
        brandNicknamesSubtitle: 'Enter nicknames, common misspellings, or branded products.',
        brandNicknamesPlaceholder: 'Macintosh, Mackintosh, MacBook Pro',
        brandWebsitesTitle: 'Results from websites',
        brandWebsitesSubtitle: 'List any brand websites you would like to track.',
        brandWebsitesPlaceholder: 'apple.com',
        brandHashtagsTitle: 'Hashtags',
        brandHashtagsSubtitle: 'Enter any unique hashtags which refer to the brand.',
        brandHashtagsPlaceholder: '#iOS, #iMac',
        brandOwnedSocialAccountsTitle: 'Social accounts',
        brandOwnedSocialAccountsSubtitle: 'Enter any social handles associated with the brand.',
        brandOwnedSocialAccountsPlaceholder: 'apple, applesupport',

        brandContextTitle: 'Only show results related to my brand',
        brandContextPlaceholder: 'Select a saved brand search',
        brandContextLoading: 'Loading, please wait...',

        issueTermsTitle: 'Words and phrases',
        issueTermsSubtitle: 'Enter any words and phrases that are relevant to this issue.',
        issueTermsPlaceholder: 'Global warming, greenhouse gas',
        issueLinksTitle: 'Websites',
        issueLinksSubtitle: 'Enter specific websites, blogs, or articles related to this issue.',
        issueLinksPlaceholder: 'nrdc.org/stories/global-warming-101',
        issueHashtagsTitle: 'Hashtags',
        issueHashtagsSubtitle: 'Enter any relevant hashtags to include in your results.',
        issueHashtagsPlaceholder: '#actonclimate',

        campaignTermsTitle: 'Words and phrases',
        campaignTermsSubtitle: 'Enter any words and phrases specific to this campaign.',
        campaignTermsPlaceholder: 'Share a coke',
        campaignLinksTitle: 'Links',
        campaignLinksSubtitle:
            'Enter websites, blogs, or articles that are specific to this campaign.',
        campaignLinksPlaceholder: 'https://www.coca-colacompany.com/share-a-coke',
        campaignHashtagsTitle: 'Hashtags',
        campaignHashtagsSubtitle: 'Enter any relevant hashtags to include in your results.',
        campaignHashtagsPlaceholder: '#ShareACoke, #CocaColaMoments',

        topicTermsTitle: 'Words and phrases',
        topicTermsSubtitle: 'Enter any words and phrases that are specific to this topic.',
        topicTermsPlaceholder: 'Halloween makeup, Halloween costumes',
        topicHashtagsTitle: 'Hashtags',
        topicHashtagsSubtitle: 'Enter any relevant hashtags to include in your results.',
        topicHashtagsPlaceholder: '#HalloweenMakeover, #HalloweenOOTD',

        personNamesTitle: 'Names',
        personNamesSubitle: 'Enter the influencer(s) name(s)',
        personNamesPlaceholder: 'Rihanna',
        personSocialAccountsTitle: 'Social accounts',
        personSocialAccountsSubitle: 'Enter the influencer(s) social media handles.',
        personSocialAccountsPlaceholder: 'Rihanna, badgalriri',

        socialAccountsInstagramHashtagsTitle: 'Instagram Hashtags',
        socialAccountsInstagramHashtagsSubtitle:
            'Select any Instagram hashtags that you are currently tracking.',
        socialAccountsInstagramHashtagsPlaceholder: '#apple, #mac, #macbook',
        socialAccountsSocialAccountsTitle: 'Social accounts',
        socialAccountsSocialAccountsSubtitle:
            "Select any Facebook, Instagram, and X (Twitter) accounts you are currently tracking (LinkedIn can be added once you've saved the search)",
        socialAccountsSocialAccountsPlaceholder: '@apple, apple',

        recentQueriesTitle: 'Recent searches',
        placeholder: 'Search for a brand, topic, campaign, or anything else',
        validationError:
            'Error while validating your query. Please check your connection and try again later.',
        showAdvancedOptions: 'Show Advanced Options',
        showAdvanced: 'Show Advanced',
        editingThisQuery: 'Leave guided search?',
        swithToAdvancedDescription:
            'Are you sure you want to leave guided mode and continue editing this search manually? You will not be able to return to this form to make changes.',
        swithToAdvancedButton: 'Leave guided search',
        stayInGuidedButton: 'Cancel',
        search: 'Search',
        on: 'On',
        off: 'Off',
        formulaTitle: 'Search',
        formulaHelp: 'Give us some information and we’ll create your search for you.',
        moreFeedbackTitle: 'Share more feedback',
        moreFeedbackDescription:
            'We’d love to hear your thoughts to help us improve your experience.',
        moreFeedbackButton: 'Provide more feedback',
        searchHelpTitle: 'Guidance for creating searches',
        searchHelpBoxPlaceholder: 'Search for an operator',
        tryThisSearch: 'Try this search',
        next: 'Next',
        authNow: 'Authenticate now',
        igHashtagsPopupTitle: 'Currently tracking on Instagram',
        noIGAuthDesc:
            'Some hashtags won’t be collected on Instagram until you select an authenticated Facebook page to track them with.',
        noIGHashtagsDesc:
            'Some hashtags won’t be tracked on Instagram until you select an authenticated Facebook page to track them with.',
        characterLimitError: 'Please limit your query to %{characterLimit} characters or fewer. ',

        //targeted data
        menuFBAuthStartTitle: 'No authenticated pages found',
        menuFBAuthStartLink: 'Authenticate with Facebook',
        hashtagsRemaining: `%{num} hashtags left`,
        igbaSelectTitle: 'Select a page to track with',
        trackWithPage: 'Track with "%{page}"',
        trackInfoTitle: 'Instagram hashtags will count toward your limit.',
        trackInfoDesc:
            'Adding a hashtag will count towards your hashtag limits immediately. They can only be removed at **7 day increments** from when you start tracking.',
        confirm: 'Confirm',
        cancel: 'Cancel',
        addNewPage: 'Add new page',
        trackWithIgTooltip: `**Do you want to track this hashtag on Instagram?**
Select an authenticated Facebook page to track with.`,
        trackWithIgBtn: 'Add instagram account to hashtag %{hashtag}',
        twitterAccounts: 'X (Twitter) Accounts',
        fbPagesOwned: 'Owned Facebook Pages',
        fbPagesNonOwned: 'Facebook Pages (Non-Owned)',
        igAccounts: 'Owned Instagram Business Accounts',
        addNewFbPagesNonOwned: 'Add new Facebook Page (Non-Owned)',
        addPagesButton: 'Add',
        noPagesFound: 'No pages found',
        noFbAuthTokenTitle: 'Not authenticated',
        fbAuthToAddPages: '<a>Authenticate with Facebook</a> to add pages',
        addNonOwnedLabel: 'Enter a Facebook profile name or URL',
        searchAccountsInfo: 'Start typing and we’ll show you some accounts.',
        noAccountResults: 'No accounts found',
        searchPlaceholder: 'Search...',
        twitterAccountsTooltip:
            'This will return all owned posts and @mentions of a given X (Twitter) account.',
        ownedFbPageTooltip:
            'This is a page that my organization or client owns. For an Owned Channel to have the best possible data coverage, the Page Admin needs to authenticate.',
        nonOwnedFbPageTooltip:
            'This is a community or competitor owned page. In order to have the best possible data coverage you need as many Facebook users to authenticate as possible.',
        igbaTooltip:
            'Authenticate with Facebook using an account with a role on a Facebook page tied to an Instagram business account. For every account you can track up to 30 hashtags.',
        enterTwitterAccounts: 'Enter X (Twitter) Accounts',
        twitterUsernameValidationErrorTitle: 'Not a valid X (Twitter) username.',
        twitterUsernameValidationErrorDesc: 'Please check the username and try again.',
        twitterInputHelperText: 'Separate multiple accounts with a comma or space.',
        linkedinChannels: 'Owned LinkedIn Pages',
        linkedinChannelsTooltip: '[PLACEHOLDER] linkedinChannelsTooltip',
    },

    intro: {
        stepStatus: 'Step %{stepNum} of %{totalSteps}',
        next: 'Next',
        accept: 'Got it!',

        quickSearch: {
            welcomeTitle: 'Welcome to %{appName}!',
            welcomeDescription: `
            Monitor your brand mentions and conversations that you care about.
            Enter the name of a brand, campaign, or any topic of interest to begin your search.`,
            guidedMenuTitle: 'Try a guided search',
            guidedMenuDescription:
                'Need some help? Choose a guided use case option to answer some questions, and we’ll build the search for you.',
            searchHelpTitle: 'Refine your search',
            searchHelpDescription: `You can use advanced search operators, such as <strong>AND, OR, NOT</strong> and many more, to refine your results.

            Click <strong>Advanced search help</strong> to see a full list of operators and examples.`,
            helpCenterTitle: 'Help Center',
            helpCenterDescription: `For more information plus guides and resources, visit the help center.`,
        },

        quickSearchResults: {
            needGuidanceTitle: 'Need guidance?',
            needGuidanceDescription:
                'It looks like you aren’t using enough boolean operators. Would you like some guidance?',
            operatorsTitle: 'Advanced search help',
            operatorsDescription: `Operators like <strong>AND</strong>, and <strong>OR</strong> can be combined  with keywords to refine results.
            See a list of all available operators and some examples of advanced searches you can use as templates to get started.`,
            guidanceTitle: 'Need help?',
            guidanceDescription:
                'Select <strong>Advanced search help</strong> to see a list of operators and examples to help refine your results.',
        },

        filters: {
            panelTitle: 'Filter results',
            panelDescription:
                'You can filter your dashboard by specific keywords (and operators), platform type, sentiment, demographics, and more. Use filters to refine your results further or isolate specific mentions.',
        },

        savedSearchAlerts: {
            createAlertTitle: 'Create an alert',
            createAlertDescription:
                'Set up email alerts for your saved searches so you never miss changes in your data or important conversations.',
        },

        savedSearchReports: {
            createReportTitle: 'Build a report',
            createReportDescription: 'Take your insights to action.',
        },

        timezone: {
            selectTimezoneTitle: 'Timezones',
            selectTimezoneDescription: 'Now you can pick a timezone for your searches',
        },

        quickSearchFeedback: {
            feedbackTitle: 'Share your feedback',
            feedbackDescription: 'How was your guided search experience today?',
        },

        multipleSearch: {
            addSearchTitle: 'You can now analyze multiple searches in one place!',
            addSearchDescription:
                "Add up to %{maxMultipleSearches} saved searches to identify your brand's share of voice, analyze top leaders in your industry, and much more.",
            gotIt: 'Got it',
        },
    },

    feedback: {
        feedbackTitle: 'Is this helpful?',
        feedbackSent: 'Thank you for your feedback!',
    },

    sessionBlocked: {
        title: `Browser may be blocking ${appName}`,
        chrome: `Your browser settings may be blocking your access to ${appName}. Change your settings to allow cookies for ${appName}, then try again.`,
        learnChrome: 'Allow cookies in Google Chrome',
        safari: `Your browser settings may be blocking your access to ${appName}. Go to your browser preferences and uncheck <b>Prevent cross-site tracking</b> to allow cross-site tracking from ${appName}, then try again.`,
        learnSafari: 'Allow cross-site tracking in Safari',
        firefox: `Your browser settings may be blocking your access to ${appName}. Change your settings to enable cookies for ${appName}, then try again.`,
        learnfirefox: 'Enable cookies for specific sites in Mozilla Firefox',
        edge: `Your browser settings may be blocking your access to ${appName}. Change your settings to allow cookies for ${appName}, then try again.`,
        learnEdge: 'Allow cookies in Microsoft Edge',
        contact: 'Still having trouble viewing this page? Contact your Customer Success Manager.',
    },
    benchmark: {
        tooltips: {
            currentValue: 'Current',
            previousValue: 'Previous',
        },
    },
    volumeHorizontalChart: {
        XaxisLegend: 'Total Mention Volume',
    },
    exportToPDF: {
        generating: 'Your PDF report is being generated...',
        success: 'Your PDF report was generated successfuly. Download will start soon...',
        error: 'There was a problem with the PDF export. Please try again.',
        header: {
            brandwatch: 'Brandwatch Insights',
            cision: 'Cision Social Listening',
            falcon: 'Listen',
            hootsuite: 'Hootsuite Insights',
            poweredBy: 'powered by Brandwatch',
            insights: 'insights',
        },
        annotations: {
            notes: 'notes',
            clear: 'Clear',
            annotationPlaceholder: 'Add optional context and insights for your report recipients.',
            modalTitle: 'Export results as PDF',
            clearAll: 'Clear all notes',
            summary: 'Summary',
            summaryPlaceholder: 'Add an optional summary for your report.',
            cardsSelector: 'Select the widgets you want to export',
            byDefault: 'By default, all widgets will be exported.',
            warning: {
                notes: 'Notes have a limit of 1000 characters',
                summary: 'Summary has a limit of 1000 characters',
            },
        },
        goBack: 'Cancel',
        save: 'Export PDF',
    },
    reports: {
        title: 'Scheduled reports',
        header: {
            name: 'Name',
            queryName: 'Saved search',
            timespan: 'Frequency',
            nextRunAt: 'Next delivery',
            active: 'Status',
            createdAt: 'Created',
        },
        actions: {
            edit: 'Edit',
            pause: 'Pause',
            resume: 'Resume',
            delete: 'Delete',
        },
        status: {
            active: 'Active',
            paused: 'Paused',
            loading: 'Processing',
        },
        actionError: 'There was an error processing your action',
        reportDeleted: 'The scheduled report "%{name}" has been deleted.',
        reportDeletedError: 'There was an error deleting your report.',
        reportEdited: 'The scheduled report "%{name}" has been edited.',
        reportEditionError: 'There was an error editing your schedule.',
        editReportModal: {
            reportName: 'Report name',
            reportNamePlaceholder: 'Please enter a report name...',
            reportNameError: 'Please enter a name for your report',
            schedulePickerTitle: 'Deliver',
            scheduleSummary:
                'Report delivered <strong>%{timespanLabel}</strong> at <strong>%{hours}:%{minutes}</strong> (%{timezone})',
            timePickerAt: 'at',
            startDatePickerTitle: 'Start date',
            reportExample: 'See an example report',
            cancelSchedule: 'Cancel',
            createScheduleAcceptButton: 'Schedule',
            createScheduleTitle: 'Schedule a new report',
            editScheduleAcceptButton: 'Edit',
            editScheduleTitle: 'Edit Scheduled Report',
            selectRecipients: {
                helperText: 'Separate email addresses with spaces or commas.',
                invalidEmailErrorDesc:
                    "One or more or the email address you've added are invalid. Please fix them to schedule a report.",
                invalidEmailErrorItem: 'This email address is invalid.',
                invalidEmailErrorTitle: 'Validation error',
                label: 'Deliver report to:',
                missingEmailErrorDesc: 'Please add a recipient to schedule a report.',
                missingEmailErrorTitle: 'No recipients added',
            },
        },
        timespanLabels: {
            P1D: 'daily',
            P7D: 'weekly',
            P14D: 'biweekly',
            P1M: 'monthly',
            P3M: 'quarterly',
        },
    },
    alertInvite: {
        success: 'Thank you, your alert preference has been saved.',
        error: 'There was an error saving your alert preference. Please contact your account manager.',
    },
    multipleOptionsPopover: {
        searchBoxPlaceholder: 'Search',
        noResults: 'No results found',
        noResultsHint: 'Check for mispellings',
        clearAll: 'Clear All',
        cancel: 'Cancel',
        save: 'Save',
    },
    linkedinChannels: {
        popover: {
            title: 'LinkedIn Pages',
            noResults: "We couldn't find any active LinkedIn pages in your team.",
            addChannel: 'Add LinkedIn Page',
        },
        saveSearchDialog: {
            title: 'LinkedIn Pages',
        },
        listItemNote: 'LinkedIn Page',
    },
    multipleSearch: {
        addSearch: 'add saved search',
        popover: {
            title: 'Saved searches',
            disabledOptionTooltip: 'You can add a maximum of %{maxOptions} saved searches',
        },
        saveQueryError: 'There was an error saving your settings',
        editSearchTooltip: 'To edit "%{name}", remove any additional searches',
        alertsButtonTooltip: 'You cannot create an Alert for multiple searches.',
        reportsButtonTooltip: 'You cannot build a Report for multiple searches.',
    },
    savedSearchNotFound: 'Oops! There was a problem loading your saved search.',
    exportCard: {
        exportAs: 'Export as',
        exportComponent: 'Export component',
        buttonLabel: 'Export',
        png: 'Export as PNG',
        jpg: 'Export as JPG',
        pdf: 'Export as PDF',
        svg: 'Export as SVG',
        csv: 'Export as CSV',
        generating: 'Your %{format} is being generated...',
        volume: '%{queryName} Volume',
        uniqueAuthorPercent: 'Unique authors %',
        uniqueAuthor: 'Unique authors',
        header: {
            brandwatch: 'Brandwatch Insights',
            cision: 'Cision Social Listening',
            falcon: 'Listen',
            poweredBy: 'powered by Brandwatch',
        },
        totalVolume: {
            current: '%{queryName} Current Period',
            previous: '%{queryName} Previous Period',
            metric: 'Metric',
            change: '% change',
        },
        heatmap: {
            dayAndHour: 'Day & Hour',
        },
        wordCloud: {
            label: 'Topic Name',
            topicType: 'Type',
            sentimentScore: 'Sentiment Score',
            female: 'Female',
            male: 'Male',
            trending: 'Trending',
        },
        benchmark: {
            currentVolume: 'Current Period %{queryName} Volume',
            previousVolume: 'Previous Period %{queryName} Volume',
            change: '% Change',
        },
        topSites: {
            site: 'Site',
        },
        sentimentVolume: {
            sentiment: 'Sentiment',
        },
        emotionVolume: {
            emotion: 'Emotion',
            anger: 'Anger',
            disgust: 'Disgust',
            fear: 'Fear',
            joy: 'Joy',
            sadness: 'Sadness',
            surprise: 'Surprise',
        },
        topLanguages: {
            name: 'Language',
        },
        topInterests: {
            name: 'Interest',
        },
        topHashtags: {
            name: 'Hashtag',
        },
        sentimentHistory: {
            positive: 'Positive Mention Volume',
            neutral: 'Neutral Mention Volume',
            negative: 'Negative Mention Volume',
        },
        emotionHistory: {
            anger: 'Anger Mention Volume',
            disgust: 'Disgust Mention Volume',
            fear: 'Fear Mention Volume',
            joy: 'Joy Mention Volume',
            sadness: 'Sadness Mention Volume',
            surprise: 'Surprise Mention Volume',
        },
        gender: {
            gender: 'Gender',
        },
        geography: {
            country: 'Country',
        },
        topAuthors: {
            authorName: 'Author name',
            id: 'Author id',
            countryName: 'Author country',
            followers: 'X (Twitter) Followers',
            reach: 'Reach',
        },
        topSharedURLs: {
            label: 'Top stories',
        },
        netSentiment: {
            value: '%{queryName} Net Sentiment Value',
        },
    },
    exportMentionsDialog: {
        title: 'Export Mentions',
        format: 'Format',
        formats: {
            CSV: 'CSV',
            JSON: 'JSON',
            PDF: 'PDF',
        },
        num: 'How many mentions would you like to export?',
        numInfo: 'A maximum of %{max} mentions can be exported at a time.',
        maxInfo: 'A maximum of 5,000 mentions can be exported per day.',
        exportBtn: 'Export',
    },
    autocomplete: {
        noOptions: {
            defaultTitle: 'No results found',
            defaultDesc: 'Check for spellings',
        },
    },

    emailAlerts: {
        title: 'Alerts',
    },
    benchmarkSubtitle: {
        subtitle: 'Benchmarked for %{start_date} against data from the %{end_date}',
        xDays: '%{days} days',
        day: 'day',
        lastXDays: 'the last %{days} days',
        lastDay: 'the last day',
        today: 'today',
        yesterday: 'yesterday',
        previous: 'previous',
    },
    savedSearchesList: {
        noSearchesAndNoQuotaLeft:
            "Your organisation has %{num} saved searches spread across all teams within the organisation. If you cannot see any searches, it's because you are not a member of the teams in which those searches exist.",
    },
};

export default phrases;
