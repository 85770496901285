import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

//Components
import IconRouter from 'hsi/components/IconRouter';
import Markdown from 'hsi/components/Markdown';
import Heading from 'hsi/components/aria/Heading';

//Hooks
import {usePopoverContext} from 'hsi/components/Popover';

//Other
import useStyles from './styles';

//The component
function InfoPopupContent({
    className,
    copy,
    ctaLabel = 'More info',
    ctaUrl,
    markdown = false,
    title,
}) {
    const classes = useStyles();
    const popoverContext = usePopoverContext();

    return (
        <div className={cn(classes.root, className)}>
            {title && (
                <Heading className={classes.title} id={popoverContext?.labelId}>
                    {title}
                </Heading>
            )}
            <div
                className={classes.copy}
                data-testid="infoPopupContentCopy"
                id={popoverContext?.descriptionId}
            >
                {copy && (markdown ? <Markdown classes={{p: classes.p}}>{copy}</Markdown> : copy)}
            </div>
            {ctaUrl && (
                <a className={classes.link} href={ctaUrl} rel="noreferrer" target="_blank">
                    <IconRouter name="launch" className={classes.linkIcon} /> {ctaLabel}
                </a>
            )}
        </div>
    );
}

InfoPopupContent.propTypes = {
    className: PropTypes.string,
    copy: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    markdown: PropTypes.bool,
    title: PropTypes.string,
};

export default InfoPopupContent;
