import {useCallback, useState} from 'react';
import cn from 'classnames';
import capitalize from 'lodash/capitalize';

import InputAdornment from '@mui/material/InputAdornment';

import IconRouter from 'hsi/components/IconRouter';
import PulseLoader from 'hsi/components/PulseLoader';
import TextField, {TextFieldProps} from 'hsi/components/TextField';

import useStyles from './styles';

import {T} from 'hsi/i18n';

const sanitizeQuotes = (val: any) =>
    typeof val === 'string' ? val.replace(/[“”]/g, '"').replace(/[‘’]/g, "'") : undefined;

interface SearchBoxProps extends TextFieldProps {
    hasIcon?: boolean;
    loading?: boolean;
    maxLength?: number;
    onSearch?: (value: string | undefined) => void;
    value?: string;
}

const SearchBox = ({
    autoFocus = true,
    className,
    defaultValue,
    disabled,
    hasIcon = true,
    loading,
    maxLength,
    onBlur,
    onChange,
    onFocus,
    onSearch,
    placeholder = T('searchbox.placeholder', capitalize),
    value,
    InputProps,
    ...rest
}: SearchBoxProps) => {
    const classes = useStyles();
    const [el, setElem] = useState<HTMLInputElement | null>(null);

    const onKeyPress = useCallback(
        (e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
                onSearch?.(sanitizeQuotes(el?.value));
            }
        },
        [el?.value, onSearch],
    );

    return (
        <TextField
            autoFocus={autoFocus}
            className={cn(classes.searchBox, className)}
            defaultValue={defaultValue}
            disabled={disabled}
            endAdornment={loading && <PulseLoader size="small" />}
            inputRef={(ref) => setElem(ref)}
            inputProps={{maxLength}}
            InputProps={{
                ...InputProps,
                startAdornment: hasIcon && (
                    <InputAdornment position="start">
                        <IconRouter name="search" aria-hidden />
                    </InputAdornment>
                ),
            }}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onKeyPress={onKeyPress}
            placeholder={placeholder}
            value={sanitizeQuotes(value)}
            {...rest}
        />
    );
};

export default SearchBox;
