import makeStyles from '@mui/styles/makeStyles';

//For class component, cannot use makeStyles
export default makeStyles((theme) => ({
    map: {
        position: 'relative',
    },
    legend: {
        padding: '0 8px',
        display: 'flex',
    },
    bucketItem: {
        flex: '1 1 16.6666666667%',
    },
    bucketColorSample: {
        height: '8px',
        borderLeft: '1px solid white',
    },
    bucketLabel: {
        fontSize: '12px',
        color: 'text-light-grey',
        textAlign: 'center',
        height: '17px',
        marginTop: '9px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    dashSep: {
        margin: '0 1px',
    },
    '.bucketItem:last-child .bucketColorSample': {
        borderRight: '1px solid white',
    },
    tooltipAnchor: {
        width: '1px',
        height: '1px',
        position: 'absolute',
        top: 0,
        left: 0,
        pointerEvents: 'none',
    },
}));
