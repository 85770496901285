// TODO: Replace targeted data banner data provider with this
import {useQuery} from '@tanstack/react-query';

import * as TargetedDataService from 'hsi/services/targetedDataService';

import useDataManagementConfig from 'hsi/hooks/useDataManagementConfig';

import * as queryKeys from 'hsi/constants/queryKeys';
import {QueryContextType} from 'hsi/types/query';
import {useLinkedinChannels} from 'hsi/hooks/useDataSource';

import {LinkedinChannelItem} from 'hsi/types/dataManagement';
import { useMemo } from 'react';

const useDataSourceCount = (projectId?: QueryContextType['projectId']) => {
    const {maxDataSources} = useDataManagementConfig();
    const linkedin = useLinkedinChannels({isGetEnabled: !!projectId});

    const sourcesCount = useQuery({
        queryKey: [queryKeys.DATA_SOURCE_COUNT],
        queryFn: () => TargetedDataService.getTotalCounts(),
    });

    const totalCount = sourcesCount?.data?.total;
    // If projectId is provided then we check if Linkedin exists in that particular project
    // If not, we just check if Linkedin exists as all
    const hasLinkedinChannels = sourcesCount?.data?.linkedinChannelCount > 0;
    const hasLinkedinChannelInProject =
        (linkedin?.data as LinkedinChannelItem[])?.filter(
            (item) => item?.projectId === String(projectId) && item.active,
        ).length > 0;

    return useMemo(() => ({
        isLoading: sourcesCount.isLoading,
        isSuccess: sourcesCount.isSuccess,
        hasFacebookAuthentications: sourcesCount?.data?.facebookUserAuthenticationsCount > 0,
        hasFacebookPages: sourcesCount?.data?.facebookPageCount > 0,
        hasInstagramAccounts: sourcesCount?.data?.instagramAccountCount > 0,
        hasInstagramHashtags: sourcesCount?.data?.instagramHashtagCount > 0,
        hasLinkedinChannels: projectId ? hasLinkedinChannelInProject : hasLinkedinChannels,
        sourcesCount: totalCount || 0,
        sourcesLimit: maxDataSources,
        sourcesRemaining: maxDataSources - (totalCount === undefined ? maxDataSources : totalCount),
    }), [hasLinkedinChannelInProject, hasLinkedinChannels, maxDataSources, projectId, sourcesCount?.data?.facebookPageCount, sourcesCount?.data?.facebookUserAuthenticationsCount, sourcesCount?.data?.instagramAccountCount, sourcesCount?.data?.instagramHashtagCount, sourcesCount.isLoading, sourcesCount.isSuccess, totalCount]);
};

export default useDataSourceCount;
