//Hooks
import useDates from 'hsi/hooks/useDates';
import {InputDateType} from 'hsi/types/dates';
import {DateTimeFormats} from 'hsi/utils/dates';

//The component
function Time({
    className,
    format,
    value,
}: {
    className?: string;
    format: DateTimeFormats;
    value?: InputDateType;
}) {
    const {formatTo, now} = useDates();

    const date = value || now();

    return (
        <time className={className} dateTime={formatTo(date, 'yyyy-LL-dd')}>
            {formatTo(date, format)}
        </time>
    );
}

export default Time;
