import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    pendingDisplay: {
        position: 'relative',
        minWidth: 'var(--pendingDisplay-minWidth, 0)',
    },
    pendingWrapper: {
        zIndex: 1,
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export default useStyles;
