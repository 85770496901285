import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({colors, v2}: any) => ({
    radioInput: {
        '&:hover+.MuiPaper-root': {
            cursor: 'pointer',
            outline: v2
                ? `3px solid ${colors.accentActive}`
                : `3px solid ${colors.secondaryOrange100}`,
        },
        '&:focus+.MuiPaper-root': {
            outline: v2
                ? `3px solid ${colors.accentActive}`
                : `3px solid ${colors.secondaryOrange100}`,
        },
        '&:checked+.MuiPaper-root': {
            backgroundColor: v2 ? colors.primaryBlue10 : colors.primaryYellow10,
            outline: v2
                ? `3px solid ${colors.accentActive}`
                : `3px solid ${colors.secondaryOrange100}`,
            ...(v2 ? {} : {filter: `drop-shadow(4px 4px 0px ${colors.secondaryOrange40})`}),
        },
        '&:disabled+.MuiPaper-root': {
            cursor: 'not-allowed',
            filter: 'grayscale(100%)',
            opacity: 0.6,
        },
    },
}));
