import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
    numberInput: {
        alignSelf: 'flex-start',
    },
    label: {
        fontWeight: theme.font.bold,
    },
    info: {
        fontSize: theme.v2 ? '13px' : '14px',
        color: 'rgba(0, 0, 0, 0.54)',
    },
}));
