import React, {useMemo} from 'react';
import MuiTextField, {
    OutlinedTextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

//Components
import {InputErrorMsg} from 'hsi/components/ErrorMessage';

//Utils
import {mergeRefs} from 'hsi/utils/react';

export interface TextFieldProps extends Partial<MuiTextFieldProps> {
    descId?: string;
    endAdornment?: React.ReactNode;
    errorProps?:
        | boolean
        | {
              title: string;
              desc: React.ReactNode;
          };
    label?: string; // TODO: This should be labelId
    startAdornment?: React.ReactNode;
}

//The component
const TextField = React.forwardRef(function TextField(
    {
        descId,
        endAdornment,
        error,
        errorProps,
        helperText: _helperText,
        InputProps = undefined,
        inputProps = undefined,
        inputRef,
        label,
        startAdornment,
        ...rest
    }: TextFieldProps,
    ref,
) {
    // TODO: We should replace error with errorProps everywhere.
    // https://mui.com/material-ui/api/text-field/
    // 'error' is a boolean field used by the Mui component and we basically hijack it for our own error.
    // We should use 'errorProps' instead.
    const errorOverride = error !== undefined ? error : errorProps;

    const helperText =
        !!errorOverride && typeof errorOverride !== 'boolean' ? (
            <InputErrorMsg {...errorOverride} />
        ) : (
            _helperText
        );

    // endAdornment and startAdornment can give quick access to InputProps.endAdornment etc
    InputProps = useMemo(
        () => ({
            ...InputProps,
            endAdornment: endAdornment ? (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
            ) : (
                InputProps?.endAdornment
            ),
            startAdornment: startAdornment ? (
                <InputAdornment position="start">{startAdornment}</InputAdornment>
            ) : (
                InputProps?.startAdornment
            ),
        }),
        [InputProps, endAdornment, startAdornment],
    );

    // descId and label can give quick access to inputProps['aria-describedby'] etc
    inputProps = useMemo(
        () => ({
            ...(inputProps || {}),
            'aria-describedby': descId || inputProps?.['aria-describedby'] || undefined,
            'aria-labelledby': label || inputProps?.['aria-labelledby'] || undefined,
        }),
        [descId, inputProps, label],
    );

    return (
        <MuiTextField
            InputLabelProps={{shrink: true}}
            error={!!errorOverride}
            helperText={helperText}
            inputRef={mergeRefs(ref, inputRef)}
            {...rest}
            InputProps={InputProps}
            inputProps={inputProps}
            variant="outlined"
        />
    );
});

export default TextField;
