import React, {useEffect, useCallback, useRef, useState} from 'react';
import cn from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import IconRouter from 'hsi/components/IconRouter';

import {searchLoaded} from 'hsi/actions/savedSearchActions';
import {clearResults} from 'hsi/actions/resultsActions';
import savedSearchService from 'hsi/services/savedSearchService';

import useEventTrack from 'hsi/hooks/useEventTrack';
import useLoadMentions from 'hsi/hooks/useLoadMentions';
import useQueryContext from 'hsi/hooks/useQueryContext';

import {T} from 'hsi/i18n';

import styles from './styles';

const useStyles = makeStyles(styles);
const DATA_POLLING_INTERVAL_MS = 5000;

type refreshIntervalType = {
    ctrl?: AbortController | null;
    refreshInterval?: ReturnType<typeof setInterval> | null;
};

const DataGathering = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {trackWithSearchData} = useEventTrack();

    const loadMentions = useLoadMentions();
    const {savedSearch, projectId} = useQueryContext();

    const [show, _setShow] = useState(false);
    const [currentPercent, setCurrentPercent] = useState(savedSearch?.percentComplete || 0);

    const refreshIntervalRef = useRef<refreshIntervalType>();

    const setShow = useCallback(
        (value: boolean) => {
            _setShow(value);
        },
        [_setShow],
    );

    const onClose = useCallback(() => {
        setShow(false);
        trackWithSearchData('dataGatheringToggled', {
            state: 'closed',
        });
    }, [setShow, trackWithSearchData]);

    const refreshSearch = useCallback(() => {
        dispatch(clearResults());
        loadMentions({append: false});
    }, [dispatch, loadMentions]);

    const updatePercentInfo = useCallback(() => {
        refreshIntervalRef.current?.ctrl && refreshIntervalRef.current.ctrl.abort();
        const {loadSearch, ctrl} = savedSearchService.loadSearchApi();
        if (refreshIntervalRef.current) refreshIntervalRef.current.ctrl = ctrl;

        loadSearch(projectId, savedSearch?.id)
            .then((res) => {
                setCurrentPercent(res.percentComplete);
                if (refreshIntervalRef.current) refreshIntervalRef.current.ctrl = null;
                if (res.percentComplete === 100) {
                    dispatch(searchLoaded({...res, project: savedSearch?.project}));
                    refreshSearch();
                    if (refreshIntervalRef.current?.refreshInterval) {
                        clearInterval(refreshIntervalRef.current.refreshInterval);
                    }
                }
            })
            .catch((e) => console.log('error loading query', e));
    }, [dispatch, projectId, refreshSearch, savedSearch?.id, savedSearch?.project]);

    useEffect(() => {
        if (!refreshIntervalRef.current) {
            refreshIntervalRef.current = {};
        }
        if (currentPercent !== 100 && !refreshIntervalRef.current.refreshInterval) {
            setShow(true);
            refreshIntervalRef.current.refreshInterval = setInterval(() => {
                updatePercentInfo();
            }, DATA_POLLING_INTERVAL_MS);
        }
        if (!savedSearch?.active) {
            setShow(true);
        }
        return () => {
            if (refreshIntervalRef.current?.refreshInterval) {
                clearInterval(refreshIntervalRef.current.refreshInterval);
                refreshIntervalRef.current.ctrl && refreshIntervalRef.current.ctrl.abort();
                refreshIntervalRef.current.ctrl = null;
                refreshIntervalRef.current.refreshInterval = null;
            }
        };
    }, [savedSearch?.active, currentPercent, setShow, updatePercentInfo]);

    if(!savedSearch) {
        return null;
    }

    return show ? (
        <div className={cn(classes.root, currentPercent === 100 && 'done')}>
            <div>
                {savedSearch.active ? (
                    <>
                        <div>
                            <IconRouter
                                style={{height: '20px', width: '20px'}}
                                name={currentPercent === 100 ? 'success' : 'info'}
                            />
                        </div>
                        {currentPercent === 100 ? (
                            T('saved.searches.data.gathered')
                        ) : (
                            <>
                                {T('saved.searches.data.gathering')} &nbsp;
                                <strong>({currentPercent || 0}%)</strong>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <div></div>
                        {T('saved.searches.refresh')} &nbsp;
                    </>
                )}
                <button type="button" className="refresh-link" onClick={refreshSearch}>
                    {T('refresh.the.search')}
                </button>
            </div>
            <div>
                {savedSearch.active && (
                    <div className="divider">
                        <Link className="refresh-link" to="/searches">
                            {T('view.your.searches')}
                        </Link>
                    </div>
                )}

                <button type="button" className={classes.blankButton} onClick={onClose}>
                    <IconRouter
                        style={{height: '24px', width: '24px', marginTop: '4px'}}
                        name="close"
                    />
                </button>
            </div>
        </div>
    ) : null;
};

export default DataGathering;
