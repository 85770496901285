import {makeStyles} from 'tss-react/mui';

export default makeStyles()((theme) => ({
    addOrCreateTagOption: {
        flex: '1 1 auto',
        display: 'flex',
        flexFlow: 'row nowrap',
        minWidth: 0,
        gap: '8px',
        alignItems: 'center',
    },

    disabledWrapper: {
        pointerEvents: 'auto',
        flex: '1 1 auto',
        minWidth: 0,
    },

    icon: {
        flex: '0 0 auto',
        width: 32,
        height: 32,
        padding: 8,
        borderRadius: '100%',
        border: `1px solid ${theme.colors.lightGrey80}`,
        overflow: 'visible',
        background: '#FFF',
    },

    label: {
        flex: '1 1 auto',
        ...theme.mixin.truncate,
    },

    contentRight: {
        flex: '0 0 auto',
        display: 'flex',
        flexFlow: 'row nowrap',
        gap: '5px',
        alignItems: 'center',
    },

    addNewHint: {
        textTransform: 'uppercase',
        fontWeight: theme.font.bold,
        fontSize: '10px',
    },

    addNewIcon: {
        transform: 'scaleY(-1)',
    },
}));
