import React from 'react';

import useStyles from './styles';

//The component
export default function InputWrapper({children, chips}) {
    const classes = useStyles();

    return (
        <div className={classes.textFilterField}>
            <div className={classes.textFilterInput}>{children}</div>
            {chips && <div className={classes.chipsContainer}>{chips}</div>}
        </div>
    );
}
