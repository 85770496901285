//TODO generic type for forwardRef
import {
    Children,
    ForwardedRef,
    PropsWithChildren,
    ReactElement,
    cloneElement,
    forwardRef,
    useMemo,
} from 'react';
import {BaseFeedItemProps} from '../FeedItem';
import {mergeRefs} from 'hsi/utils/react';
import {useFeedContext} from '..';

type FeedItemWrapperProps = PropsWithChildren<BaseFeedItemProps>;

const FeedItemWrapperInner = function FeedItemWrapper<T extends HTMLElement>(
    {children, 'aria-posinset': ariaPosInSet, tabIndex}: FeedItemWrapperProps,
    ref: ForwardedRef<T>,
) {
    const child = useMemo(() => Children.only(children), [children]) as ReactElement;
    const {setSize} = useFeedContext() ?? {setSize: -1};

    return useMemo(
        () =>
            cloneElement(child, {
                'aria-posinset': ariaPosInSet,
                'aria-setsize': setSize,
                tabIndex: tabIndex || child.props.tabIndex || 0,
                ref: mergeRefs(ref, (child as any).ref),
            }),
        [child, ariaPosInSet, tabIndex, setSize, ref],
    );
};

export default forwardRef(FeedItemWrapperInner) as <T extends HTMLElement>(
    props: FeedItemWrapperProps & {ref?: React.ForwardedRef<T>},
) => ReturnType<typeof FeedItemWrapperInner>;
