import React, {useMemo, useCallback} from 'react';

//Components
import MultiValueTextInput from 'hsi/components/Filters/MultiValueTextInput';
import ErrorMessage from 'hsi/components/Filters/ErrorMessage';

//Hooks
import useFiltersState from 'hsi/components/Filters/hooks/useFiltersState';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Consts
const defaultAddValueKeyCodes = [13]; //enter

//The component
export default function MultiValueTextfieldFilter({
    compact,
    autoScrollDrawer = null,
    isSavedSearch,
    disabled,
    onChange,
    filterConf: filterConfig,
}) {
    const classes = useStyles();

    const {
        filterName,
        label,
        placeholder,
        maxValues,
        limitLabel,
        autosuggestType,
        addValueKeyCodes = defaultAddValueKeyCodes,
        displayChipInsideField,
        getLabel,
        getId,
    } = filterConfig;

    const {
        useSelector,
        actions: {
            addValueMultiValueTextfield,
            removeValueMultiValueTextfield,
            setError,

            isValueValid,
        },
    } = useFiltersState();

    const filterState = useSelector((state) => state.filters[filterName]);
    const error = useSelector((state) => state.error[filterName]);
    const pending = useSelector((state) => state.pending[filterName]);

    //Callbacks
    const onAdd = useCallback(
        (value) => {
            addValueMultiValueTextfield(filterName, value);

            onChange && onChange(filterName, 'multiValueTextfield', value);
        },
        [filterName, addValueMultiValueTextfield, onChange],
    );

    const onDelete = useCallback(
        (chip) => chip && removeValueMultiValueTextfield(filterName, chip.value),
        [filterName, removeValueMultiValueTextfield],
    );

    const onInputChange = useCallback(
        () => error && setError(filterName, null),
        [error, setError, filterName],
    );

    //Calculated values
    const chips = useMemo(
        () =>
            filterState.map((value) => ({
                value,
                label: getLabel?.(value) || value,
                key: getId?.(value) || value,
            })),
        [filterState, getId, getLabel],
    );

    const errorMsg = useMemo(() => (error ? <ErrorMessage error={error} wide /> : null), [error]);

    //Render
    return (
        <div className={classes.root}>
            <MultiValueTextInput
                placeholderText={placeholder ? T(placeholder) : null}
                labelText={label ? T(label) : null}
                autoScrollDrawer={autoScrollDrawer}
                autosuggestType={autosuggestType}
                errorMsg={errorMsg}
                chips={chips}
                displayChipInsideField={displayChipInsideField}
                maxValues={maxValues}
                limitLabel={limitLabel}
                onBeforeAdd={(value) => {
                    if (!value) {
                        return false;
                    }

                    const okOrErrorCode = isValueValid(filterName, value, true);

                    if (okOrErrorCode !== true) {
                        setError(filterName, okOrErrorCode);
                        return false;
                    }

                    return true;
                }}
                onAdd={onAdd}
                onDelete={onDelete}
                onInputChange={onInputChange}
                addValueKeyCodes={addValueKeyCodes}
                disabled={disabled}
                pending={pending}
                error={!!error}
            />
        </div>
    );
}
