import React, {useEffect, useState, forwardRef} from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import useEventTrack from 'hsi/hooks/useEventTrack';

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import tns from 'hsi/i18n/tns';
const t = tns('feedback');

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        color: theme.colors.secondaryBlue100,
        fontWeight: 'bold',
    },
}));

const ThumbButton = withStyles((theme) => ({
    root: {
        padding: '5px',
        backgroundColor: 'unset',
        margin: '0 0 0 4px',
        '& svg': {
            width: '17px',
            height: '17px',
            fill: theme.colors.secondaryBlue100,
        },
    },
}))(IconButton);

const Feedback = forwardRef(({onFeedbackSent, ui, type}, ref) => {
    const cl = useStyles();
    const {trackWithPathname} = useEventTrack();

    const [feedbackSent, setFeedbackSent] = useState(false);

    const sendFeedback = (value) => {
        if (type === 'guided search') {
            trackWithPathname('guidedSearchUserResponded', {
                ui,
                value,
            });
        }
        if (type === 'intro tooltip') {
            trackWithPathname('introTooltipUserResponded', {
                ui,
                value,
            });
        }
        setFeedbackSent(true);
    };

    useEffect(() => {
        if (feedbackSent && typeof onFeedbackSent === 'function') {
            let timer = setTimeout(() => onFeedbackSent(), 1000);
            return () => clearTimeout(timer);
        }
    }, [feedbackSent, onFeedbackSent]);

    return (
        <div className={cl.container} ref={ref}>
            {feedbackSent ? (
                <div>{t('feedbackSent')}</div>
            ) : (
                <>
                    <div id="feedback">{t('feedbackTitle')}</div>
                    <div role="group" aria-labelledby="feedback">
                        <ThumbButton
                            aria-label="positive feedback"
                            onClick={() => sendFeedback('positive')}
                        >
                            <ThumbUpIcon />
                        </ThumbButton>
                        <ThumbButton
                            aria-label="negative feedback"
                            onClick={() => sendFeedback('negative')}
                        >
                            <ThumbDownIcon />
                        </ThumbButton>
                    </div>
                </>
            )}
        </div>
    );
});

Feedback.propTypes = {
    onFeedbackSent: PropTypes.func.isRequired,
    ui: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['guided search', 'intro tooltip']).isRequired,
};

export default Feedback;
