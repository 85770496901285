import React from 'react';
import {withStyles} from 'tss-react/mui';
import Menu from '@mui/material/Menu';

const styles = {
    paper: {
        width: 'auto',
        marginTop: '30px',
        borderRadius: '3px !important',
        boxShadow: '0px 24px 24px 0px rgba(47, 53, 56, 0.25) !important',
    },
};

const ActionsMenu = ({id, anchorEl, open, onClose, children, classes}) => (
    <Menu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        elevation={0}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
        children={children}
        classes={{paper: classes.paper}}
    />
);

export default withStyles(ActionsMenu, styles);
