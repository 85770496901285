import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    root: {
        flex: '1 1 100%',
        color: theme.colors.textDefault,
        
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '40px',
    },
    icon: {
        width: '49px',
        height: '49px',
        marginBottom: '45px',
        fill: theme.colors.textDefault,
    },
    title: {
        fontSize: '30px',
        fontWeight: 'bold',
    },
    subtitle: {
        fontSize: '16px',
        marginTop: '11px',
    },
    link: {
        ...theme.mixin.linkButton,
        fontWeight: 'bold',
    },
}));
