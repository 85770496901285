import React, {
    useRef,
    useState,
    useEffect,
    useMemo,
    useContext,
    useCallback,
    PropsWithChildren,
} from 'react';
import {shallowEqual} from 'react-redux';

//Hooks
import useRefCallback from 'hsi/hooks/useRefCallback';

//Utils
import {mergeRefs} from 'hsi/utils/react';

//TODO combine this and useOnScreen? - either add an option to allow 'onVisible' to be recalled, or have different callbacks or soemthing?
//OR, just leave separate, as they handle two slightly different cases

export type VisibleObserverOptions = {
    /**
     * Optional margin value, in pixels. The distance to extend the element for testing for intersection. Will default to 0
     */
    margin?: number;
};

/**
 * Used to handle managing the IntersectionObserver object
 */
export class VisibleObserver {
    root?: HTMLElement;
    options?: VisibleObserverOptions;
    elements: Map<Element, () => void>;
    observer?: IntersectionObserver;

    constructor(root: HTMLElement | undefined, options?: VisibleObserverOptions) {
        this.root = root;
        this.options = options;

        this.elements = new Map<Element, () => void>();

        this._initObserver();
    }

    setRoot(element: HTMLElement | undefined | null) {
        if (this.root === element) {
            return;
        }
        this.root = element ?? undefined;

        this._initObserver();
    }

    setOptions(options?: VisibleObserverOptions) {
        if (shallowEqual(options, this.options)) {
            return; //if options have not changed, do nothing
        }

        this.options = options;

        this._initObserver();
    }

    dispose() {
        this.observer && this.observer.disconnect();
    }

    addTarget = (element: Element, callback: () => void) => {
        this.elements.set(element, callback);
        this.observer?.observe(element);
    };

    removeTarget = (element: Element) => {
        this.elements.delete(element);
        this.observer?.unobserve(element);
    };

    _initObserver() {
        const {options, root, elements} = this;

        if (this.observer) {
            this.observer.disconnect();
        }

        if (root) {
            this.observer = new IntersectionObserver(this._observerCallback, {
                root,
                rootMargin: options?.margin ? `${options.margin}px` : '0px',
            });

            //Register targets with new observer
            elements.forEach((callback, element) => {
                this.observer?.observe(element);
            });
        }
    }

    _observerCallback = (entries: IntersectionObserverEntry[]) => {
        const {elements} = this;

        for (let i = 0; i < entries.length; i++) {
            const entry = entries[i];

            if (entry.isIntersecting) {
                const callback = elements.get(entry.target);

                callback && callback();
            }
        }
    };
}

type VisibleContainerProps = PropsWithChildren<{
    options?: VisibleObserverOptions;
    /**
     * Optional alternative context, to allow different scolling contexts to be monitored simultaneously. Context value should be a VisibleObserver object
     */
    context?: React.Context<VisibleObserver>;
}>;

//-Observer provider handles creating and providing the observer to children
/**
 * The VisibleContainer component is used to define the VisibleObserver, and provide the value via a context.
 * It should be used to wrap a ReactElement that supports forwarded refs
 */
export const VisibleContainer = React.forwardRef<
    HTMLElement,
    Parameters<React.FC<VisibleContainerProps>>[0]
>(function VisibleContainer({children, options, context = defaultContext}, ref) {
    const observerManagerRef = useRef<VisibleObserver>();

    if (!observerManagerRef.current) {
        observerManagerRef.current = new VisibleObserver(undefined, options);
    }

    //Callbacks
    const setContainer = useCallback(
        (element: HTMLElement | null) => observerManagerRef.current?.setRoot(element),
        [],
    );

    //Calculated values
    const child = useMemo(() => {
        const child = React.Children.only(children) as any;

        return React.cloneElement(child, {ref: mergeRefs(child.ref, setContainer, ref)});
    }, [children, ref, setContainer]);

    //Side effects
    useEffect(
        //keep options updated
        () => {
            observerManagerRef.current?.setOptions(options);
        },
        [options],
    );

    useEffect(
        //on unmount, tidy up
        () => () => {
            observerManagerRef.current?.dispose();
        },
        [],
    );

    //Render
    return useMemo(() => {
        return <context.Provider value={observerManagerRef.current!}>{child}</context.Provider>;
    }, [child, context]);
});

/**
 * Trigger a callback when an element appears onscreen (or is close to being). Used for things like lazy loading.
 * Very similar to the useOnScreen hook, however is a more complex implementation that allows for a single
 * IntersectionObserver to be shared by multiple elements, which is a more performant solution when multiple elements
 * in the same scrolling context are being checked
 *
 * @param forwardedRef optional ref to 'passthrough' the returned ref to allow multiple things to add refs to the same element
 * @param onVisible Optional callback to be called when the element first 'intersects'. Is called with the element beign tested.
 * @param context Optional alternative context, to allow different scolling contexts to be monitored simultaneously. Context value should be a VisibleObserver object
 */
export default function useOnVisible<T extends Element>(
    forwardedRef?: React.ForwardedRef<T>,
    onVisible?: (element: T) => void,
    context = defaultContext,
) {
    const observerManager = useContext(context);
    const [isVisible, setIsVisible] = useState(false);
    const [element, _setElement] = useState<T | undefined>(undefined);

    const setVisible = useRefCallback(() => {
        setIsVisible(true);
        onVisible?.(element!);

        //now we're visible, remove from observer
        observerManager.removeTarget(element!);
    });

    const setElement = useRefCallback((newElement: T | null) => {
        const lastElement = element;
        _setElement(newElement ?? undefined);

        if (isVisible) {
            return;
        }

        //Keep observer updated with the target element
        lastElement && observerManager.removeTarget(lastElement);
        newElement && observerManager.addTarget(newElement, setVisible);
    });

    return useMemo(
        () => [isVisible, mergeRefs(setElement, forwardedRef), element] as const,
        [isVisible, element, setElement, forwardedRef],
    );
}

/**
 * Utility function to simplify creating alternative scrolling contexts
 *
 * @param name The name of the context, only really used for dev/debug purposes
 * @param root The default root element. Can be replaced with the VisibleContainer component. Defaults to the document body
 * @returns A new react context object with a value type of VisibleObserver
 */
export function createContext(name: string, root: HTMLElement = document.body) {
    const context = React.createContext(new VisibleObserver(root));

    context.displayName = `${name}Context`;

    return context;
}

const defaultContext = createContext('OnVisibleContext');
