import makeStyles from '@mui/styles/makeStyles';

const MAX_WIDTH = '1040px';

export default makeStyles((theme) => ({
    ManageTagsDialog: {
        color: theme.colors.textDefault,
        '& .wrapper': {
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexFlow: 'column nowrap',
        },
        '& .MuiDialog-paper': {
            minWidth: MAX_WIDTH,
            alignSelf: 'stretch',
        },
        '& .MuiList-padding': {
            padding: 0,
            margin: '0 0 5px 0',
            border: theme.v2
                ? `1px solid ${theme.colors.borders} !important`
                : `2px solid ${theme.colors.secondaryPurple100} !important`,
            borderTop: 'none !important',
            backgroundColor: theme.colors.lightGrey10,
            maxHeight: 'calc( 80vh - 440px )',
            minHeight: '100px',
            overflowY: 'scroll',
        },
        '& .info': {
            margin: '15px 0 30px 0',
            '& .category': {
                fontSize: '14px',
                fontWeight: theme.font.semiBold,
                paddingBottom: '5px',
            },
            '& .value': {
                fontSize: '14px',
                fontWeight: theme.font.regular,
            },
        },
        '& .link': {
            ...theme.mixin.linkColor,
            textDecoration: 'none',
            fontWeight: theme.font.semiBold,
            marginBottom: '17px',
            display: 'inline-block',
            cursor: 'pointer',
        },
    },
    DialogContent: {
        display: 'flex',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
        '& .tags-mgmt': {
            background: theme.v2 ? theme.colors.uiGrayDark : theme.colors.lightGrey40,
            flex: '1 1 603px',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '40px 48px 24px',
            minHeight: '100%',
            position: 'sticky',
            overflowY: 'auto',
            '& .MuiListItem-button': {
                height: '40px',
            },
            '& .label': {
                fontSize: '16px',
                fontWeight: '700',
                '&.sub': {
                    fontWeight: 'normal',
                    paddingBottom: '8px',
                },
                '&.padded': {
                    paddingBottom: '8px',
                },
            },
            '& .container': {
                display: 'flex',
                alignItems: 'flex-end',
                '& .inputAndLabelContainer': {
                    flex: '1 1 auto',
                    marginRight: '7px',
                    '& .new-tag-input': {},
                },
            },
            '& .MuiListItemIcon-root': {
                minWidth: 0,
            },
            '& .tag-icon-container': {
                width: '32px',
                height: '32px',
                background: 'none',
                borderRadius: '0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: `none`,
                marginLeft: '5px',
            },
            '& .icon-wrapper': {
                ...theme.mixin.blankButton,
                width: 44,
                height: 44,
                flex: '0 0 auto',
                verticalAlign: 'middle',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                '& .app-icon': {
                    fill: '#2f3538',
                },
                '& .app-icon.icon-pencil': {
                    width: theme.v2 ? 24 : 16,
                    height: theme.v2 ? 24 : 16,
                },
                '& .app-icon.icon-trash': {
                    width: theme.v2 ? 24 : 16,
                    height: theme.v2 ? 24 : 16,
                },
            },
            '& .new-tag-input': {
                width: '100%',
                marginRight: '8px',
            },
            '& .loader-container': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '228px',
            },
        },
    },
    DialogActions: {
        background: theme.colors.bg100,
        height: '68px',
        margin: 0,
    },
    Divider: {
        margin: '24px 0 0',
        borderColor: 'transparent',
    },
    searchBox: {
        width: '100%',
        marginBottom: '0',
        '& .MuiOutlinedInput-notchedOutline': {
            border: theme.v2
                ? 'inherit'
                : `2px solid ${theme.colors.secondaryPurple100} !important`,
            borderBottomLeftRadius: theme.v2 ? 0 : '2px',
            borderBottomRightRadius: theme.v2 ? 0 : '2px',
        },
    },
    label: {
        fontSize: '14px',
        flex: '1 1 auto',
        minWidth: 0,
        margin: '4px 0',
        padding: '0 10px 0 0',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));
