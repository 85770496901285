import React, {useRef} from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

//Components
import IconRouter from 'hsi/components/IconRouter';
import PulseLoader from 'hsi/components/PulseLoader';
import Tooltip from 'hsi/components/Tooltip';
import ConditionalWrap from 'hsi/components/ConditionalWrap';

import useForceUpdate from 'hsi/hooks/useForceUpdate';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Consts
const showSuccessFor = 5; //seconds

//The component
export default function AsyncStatusAdornment({pending, error, errorMsg, value, skipTick}) {
    const {classes, cx} = useStyles();
    const ref = useRef();

    const forceUpdate = useForceUpdate();

    if (!ref.current) {
        ref.current = {
            lastPending: pending,
            lastValue: value,
            showSuccessUntil: null,
        };
    }

    if (ref.current.lastPending !== pending) {
        clearHideSuccess(ref.current);

        if (!pending && !error) {
            ref.current.showSuccessUntil = Date.now() + showSuccessFor * 1000;
            ref.current.showSuccessUntilTId = setTimeout(() => {
                ref.current.showSuccessUntil = null;
                ref.current.showSuccessUntilTId = null;

                forceUpdate();
            }, showSuccessFor * 1000 + 16);
        }

        ref.current.lastPending = pending;
    } else if (error) {
        clearHideSuccess(ref.current);
    }

    if (value !== ref.current.lastValue) {
        clearHideSuccess(ref.current);
        ref.current.showSuccessUntil = null;

        ref.current.lastValue = value;
    }

    //Check if success should still be visible
    const showSuccess =
        !skipTick && ref.current.showSuccessUntil && Date.now() <= ref.current.showSuccessUntil;

    const activeState = pending ? 'pending' : error ? 'error' : showSuccess ? 'success' : 'none';

    //Render
    return (
        <div className={cx(classes.root, !value && classes.noValue)}>
            <TransitionGroup>
                {activeState === 'pending' && (
                    <CSSTransition classNames={classes} timeout={400} key="pending">
                        <div className={cx(classes.wrapper, classes.pending)}>
                            <PulseLoader size="small" />
                            <span className="offscreen">{T('loading')}</span>
                        </div>
                    </CSSTransition>
                )}
                {activeState === 'error' && (
                    <CSSTransition classNames={classes} timeout={400} key="error">
                        <div className={cx(classes.wrapper, classes.error)}>
                            <ConditionalWrap
                                wrap={(children) =>
                                    errorMsg === null ? (
                                        children
                                    ) : (
                                        <Tooltip
                                            theme="light"
                                            disabled
                                            tooltip={errorMsg || ''}
                                            placement="top"
                                            distance={15}
                                            portal
                                        >
                                            {children}
                                        </Tooltip>
                                    )
                                }
                            >
                                <IconRouter
                                    name="mui-warning-rounded"
                                    className={cx(classes.icon, classes.error)}
                                    tabIndex={0}
                                />
                            </ConditionalWrap>
                        </div>
                    </CSSTransition>
                )}
                {activeState === 'success' && (
                    <CSSTransition classNames={classes} timeout={400} key="success">
                        <div className={cx(classes.wrapper, classes.success)}>
                            <IconRouter
                                name="success"
                                className={cx(classes.icon, classes.success)}
                            />
                        </div>
                    </CSSTransition>
                )}
            </TransitionGroup>
        </div>
    );
}

//Internal helpers
function clearHideSuccess(current) {
    if (current.showSuccessUntilTId) {
        clearTimeout(current.showSuccessUntilTId);
        current.showSuccessUntilTId = null;
    }
}
