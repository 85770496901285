import AuthenticateFacebookDialog from './AuthenticateFacebookDialog';
import AddOwnedFacebookPagesAndInstagramAccountsDialog from './AddOwnedFacebookPagesAndInstagramAccountsDialog';

const FacebookAuthenticationDialogs = () => (
    <>
        <AuthenticateFacebookDialog />
        <AddOwnedFacebookPagesAndInstagramAccountsDialog />
    </>
);

export default FacebookAuthenticationDialogs;
