import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState: boolean = false;

const slice = createSlice({
    name: 'filtersOpen',
    initialState,
    reducers: {
        openFilters: (state, {payload}: PayloadAction<boolean>) => {
            return !!payload;
        },
    },
});

export const {openFilters} = slice.actions;
export default slice.reducer;
