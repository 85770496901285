//This component has multiple accessibility issues. DO NOT USE in ANY
//new development. Now only used in the streams app - KEEP IT THAT WAY

import FormControlLabel, {FormControlLabelProps} from '@mui/material/FormControlLabel';
import MuiCheckbox, {CheckboxProps as MuiCheckboxProps} from '@mui/material/Checkbox';

import useStyles from './styles';
import {ReactNode} from 'react';

export interface CheckboxProps extends MuiCheckboxProps {
    formControlProps?: Partial<FormControlLabelProps>;
    label?: ReactNode;
}

const Checkbox = ({formControlProps, label, ...rest}: CheckboxProps) => {
    const {classes} = useStyles();
    return (
        <FormControlLabel
            control={<MuiCheckbox {...rest} classes={classes as any} />}
            label={label}
            {...formControlProps}
        />
    );
};

export default Checkbox;
