import {ReactNode, forwardRef} from 'react';
import cn from 'classnames';
import IconButton, {IconButtonProps} from '@mui/material/IconButton';

import IconRouter from 'hsi/components/IconRouter';

import useStyles from './styles';
import {T} from 'hsi/i18n';

type ToggleDrawerButtonProps = {
    badge?: ReactNode;
    direction: 'left' | 'right';
    open?: boolean;
    toggleDrawer?: () => void;
    openLabel: string;
    closeLabel?: string;
    disabled?: boolean;
} & Omit<IconButtonProps, 'disabled' | 'onClick' | 'children'>;

export default forwardRef<HTMLButtonElement, ToggleDrawerButtonProps>(function ToggleDrawerButton(
    {
        badge,
        className,
        direction,
        open,
        toggleDrawer,
        openLabel,
        closeLabel,
        disabled = false,
        ...rest
    },
    ref,
) {
    const classes = useStyles();

    const collapsedIcon = {
        left: 'filter',
        right: 'a-chat',
    };
    const openIcon = {
        left: 'hsi-double-arrow-left',
        right: 'close',
    };

    const label = !open ? openLabel : closeLabel ?? T('dialog.close');

    return (
        <IconButton
            aria-label={label}
            className={cn(classes.button, className)}
            onClick={disabled ? undefined : toggleDrawer}
            data-testid="configSideDrawerCloseButton"
            aria-disabled={disabled}
            ref={ref}
            {...rest}
            size="large">
            <IconRouter
                name={cn({
                    [openIcon[direction]]: open,
                    [collapsedIcon[direction]]: !open,
                })}
                suppressIconClassName
                className={classes.icon}
            />
            {badge !== undefined && badge !== null && badge !== false && !open && (
                <div className={classes.badge}>{badge}</div>
            )}
        </IconButton>
    );
});
