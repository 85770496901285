import {useMemo, useCallback} from 'react';
import ReactDOM from 'react-dom';
//libs
import {saveAs} from 'file-saver';
import html2canvas from 'html2canvas';
import {Parser as Json2csvParser} from 'json2csv';
import cn from 'classnames';
//hooks
import useConfig from 'hsi/hooks/useConfig';
import useGetCards from 'hsi/hooks/useGetCards';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
//actions
import {exportedCard} from 'hsi/actions/exportActions';

const ExportChartPortal = () => {
    const {isPortalEnabled, currentCard, title, format, queryName} = useAppSelector(
        (state) => state.pdfExport,
    );

    const config = useConfig();
    const dispatch = useAppDispatch();

    const rules = {
        ...config.searchResults,
        cardRules: config.searchResults.cardRules.filter(({name}) => currentCard === name),
    };
    const cards = useGetCards(rules);

    const onExportLoaded = useCallback(async () => {
        // TODO: We should probably get this from query context
        if (format && queryName) {
            await handleExport(title, format, queryName);
            dispatch(exportedCard());
        }
    }, [title, format, queryName, dispatch]);

    const cardElements = useMemo(
        () =>
            cards.map((card) => (
                <div key={card.name}>
                    <card.component
                        title={card.title}
                        height={card.height || 320}
                        exportLoadedCallback={onExportLoaded}
                    />
                </div>
            )),
        [cards, onExportLoaded],
    );

    if (!isPortalEnabled) return null;

    return ReactDOM.createPortal(
        <div
            className={cn('printMedia', 'imgExport', 'hiddenCard')}
            id={'printCard'}
            data-testid="exportChartPortal"
        >
            {cardElements}
        </div>,
        document.getElementsByTagName('body')[0],
    );
};

const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const handleExport = async (title: string, format: string, queryName: string) => {
    const options = {
        windowWidth: 750,
        windowHeight: 800,
        imageTimeout: 8000,
        proxy: '/proxyImages',
    };
    const exportElement = document.getElementById('printCard');
    const allIconsSvgBundle = document.getElementsByTagName('svg')[0];
    const svgElements = exportElement?.getElementsByTagName('svg');

    if (!exportElement || !svgElements) {
        console.log('Something went wrong handling the export');
        return;
    }

    for(const svgElement of svgElements) {
        if(!svgElement) {
            continue;// how would this happen?
        }

        const refId = svgElement?.getElementsByTagName('use')[0]?.href?.baseVal;

        if(refId) {
            console.log(svgElement, refId, allIconsSvgBundle.getElementById(
                refId.replace('#', ''),
            ));
            // I *think* this is to pull icon content out of the icon bundle, as referencing the icon via the <use> does not work with the html2convas?
            svgElement.innerHTML = allIconsSvgBundle.getElementById(
                refId.replace('#', ''),
            )?.innerHTML ?? '';
        }
    }

    await timeout(5000);

    const canvas = await html2canvas(exportElement, options);
    const img = canvas.toDataURL?.(`image/${format === 'jpg' ? 'jpeg' : format}`, 1.0);

    await saveAs(img, `${queryName.toLowerCase()}-${title.toLowerCase()}.${format}`);
};

type HandleCSVExportArgs = {
    appName: string;
    data: any;
    dateRange: string;
    exportTitle?: string;
    onSuccess: () => void;
    queryName: string;
};

const handleCSVExport = async ({
    appName,
    data,
    dateRange,
    exportTitle = '',
    onSuccess,
    queryName,
}: HandleCSVExportArgs) => {
    const parser = new Json2csvParser();
    const title = `${appName}\r\n${queryName}\r\n"${dateRange}"\r\n${exportTitle}\r\n\r\n`;
    const csv = title + parser.parse(data);
    const blob = new Blob([csv], {type: 'text\\csv;charset=utf-8'});

    await timeout(5000);

    await saveAs(blob, `${queryName.replaceAll(' ', '_')}-${exportTitle.replaceAll(' ', '_')}.csv`);

    onSuccess();
};

export {ExportChartPortal, handleExport, handleCSVExport};
