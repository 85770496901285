import {ValueOfArray} from 'hsi/types/shared';

//Consts
const availablePlatformIcons = [
    // specific
    'discuz',
    'disqus',
    'facebook',
    'linkedin',
    'flickr',
    'google',
    'hackernews',
    'instagram',
    'mumsnet',
    'reddit',
    'tumblr',
    'twitter',
    'x-twitter',
    'vimeo',
    'yahoo',
    'youtube',
    'qq',

    // generic
    'blog',
    'blogs',
    'custom',
    'forum',
    'forums',
    'review',
    'reviews',
    'news',
    'rss',
] as const;

const specialPlatformIconsMappings = {
    blog: 'blogs',
    forum: 'forums',
    review: 'reviews',
    twitter: 'x-twitter',
} as const;

//Types
export type Platform = ValueOfArray<typeof availablePlatformIcons>;
type SpecialPlatformMapping = keyof typeof specialPlatformIconsMappings;
type PlatformIcons = {
    [K in Exclude<Platform, SpecialPlatformMapping>]: K;
} & typeof specialPlatformIconsMappings;

//Type guard
function isPlatform(platform: string): platform is Platform {
    return platform in platformIcons;
}

function isSpecialPlatform(platform: Platform): platform is SpecialPlatformMapping {
    return platform in specialPlatformIconsMappings;
}

//Build the actual data
export const platformIcons = availablePlatformIcons.reduce<any>((output, platform) => {
    output[platform] = isSpecialPlatform(platform)
        ? specialPlatformIconsMappings[platform]
        : platform;

    return output;
}, {}) as PlatformIcons;

//Utility methods
export function inferPlatformFromDomain(domain: string | undefined | null) {
    if (!domain) {
        return undefined;
    }

    const platform = domain.split('.')[0];

    return isPlatform(platform) ? platform : undefined;
}
