import React, {forwardRef, ForwardedRef} from 'react';

//Components
import ProgressInfinite from 'hsi/components/Progress';

//Hooks
import {useTheme} from '@mui/material/styles';

export type PulseLoaderSize = 'small' | 'medium' | 'large';

type PulseLoaderProps = {
    className?: string;
    size?: PulseLoaderSize;
    'data-testid'?: string;
    message?: string;
};

//The component
const PulseLoader = forwardRef(function PulseLoader(
    {message, ...rest}: PulseLoaderProps,
    ref: ForwardedRef<HTMLDivElement>,
) {
    const {
        components: {Loader}, //TODO type Loader component
    } = useTheme() as {components: {Loader: React.ComponentType<any>}};

    return <>
        {Loader ? (
            <Loader {...rest} ref={ref as React.Ref<SVGSVGElement>} />
        ) : (
            <ProgressInfinite
                className={rest.className}
                data-testid={rest['data-testid'] ?? 'pulse-loader'}
                size={rest.size}
                ref={ref as React.Ref<SVGSVGElement>}
            />
        )}
        {message && <div className="offscreen" role="status">{message}</div>}
    </>
});

export default PulseLoader;
