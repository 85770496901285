//Components
import ExportHeader from 'hsi/components/ExportHeader';
import ExportMention from './ExportMention';
import Markdown from 'hsi/components/Markdown';

//Hooks
import useConfig from 'hsi/hooks/useConfig';

//Other
import useStyles from './styles';
import {T} from 'hsi/i18n';

// This can only use window.desData for state

//The container
export default function ExportMentions() {
    const classes = useStyles();
    const {exportType} = useConfig();

    const {mentions, state} = window.desData;
    const {additionalQueries, linkedinChannelIds} = state.query;
    const {savedSearch, isMultipleSearch, timezone} = state.query.context;
    const dateRange = state.filters.dateRange;
    const searches = state.search.searches;

    const multipleSearchIds = [...(additionalQueries || []), ...(linkedinChannelIds || [])];
    const names = multipleSearchIds.map(
        (id) => searches?.find((search: any) => search.id === id)?.name,
    );

    return (
        <div className={classes.root} data-testid="exportMentions">
            <ExportHeader
                title={savedSearch.name}
                subtitle={
                    isMultipleSearch && names ? (
                        <Markdown classes={{p: classes.markdown_p}}>
                            {T('exportMentionsToPDF.multipleSearchMentions', {
                                other: names.join(', '),
                            })}
                        </Markdown>
                    ) : (
                        T('mentions')
                    )
                }
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                tagline={T(`exportToPDF.header.${exportType}`)}
                timezone={timezone}
            />
            {mentions.map((mention) => (
                <ExportMention mention={mention} key={mention.resourceId} />
            ))}
        </div>
    );
}
