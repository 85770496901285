import {SavedSearchMentionApiType, SavedSearchMentionType} from 'hsi/types/mentions';

export default function normaliseSavedSearchMention(
    mention: SavedSearchMentionApiType,
): SavedSearchMentionType {
    return {
        id: mention.resourceId,
        apiType: 'saved',
        ...mention,
    };
}
