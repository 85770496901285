import React, {HTMLAttributes} from 'react';
import IconRouter from 'hsi/components/IconRouter';

import useConfig from 'hsi/hooks/useConfig';
import useStyles from './styles';

export type InputErrorMsgProps = {
    className?: string;
    customStyle?: HTMLAttributes<HTMLDivElement>['style'];
    desc?: React.ReactNode | string;
    showMoreHelpInfo?: boolean;
    title?: React.ReactNode | string;
};

// Error component
// see https://hootsuite.com/dashboard#/tapas/?@=global-stage-3/input-text
const InputErrorMsg = ({
    title,
    desc,
    className,
    showMoreHelpInfo = false,
    customStyle,
}: InputErrorMsgProps) => {
    const classes = useStyles();
    const {links} = (useConfig as any)();

    return (
        <div className={className} style={customStyle}>
            <div className={classes.container}>
                <div className={classes.iconWrapper}>
                    <IconRouter name="mui-warning-rounded" className={classes.icon} />
                </div>
                <div>
                    {!!title && <div className={classes.title}>{title}</div>}
                    {!!desc && <div className={classes.desc}>{desc}</div>}
                </div>
            </div>
            {showMoreHelpInfo && (
                <div>
                    For more help, read our{' '}
                    <a href={links.searchHelp} rel="noreferrer" target="_blank">
                        Search Help article
                    </a>
                    .
                </div>
            )}
        </div>
    );
};

export {InputErrorMsg};
