import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>(({v2}) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',

        minHeight: v2 ? undefined : '23px',
    },

    btn: {
        "&$normal": {
            padding: 0,
        },
        "&$large": {
            padding: v2 ? '3px' : '14px',
        },
        "&$medium": {
            padding: v2 ? undefined : '9px',
        },
    },

    msg: {
        cursor: 'pointer',
    },

    icon: {
        width: 17,
        height: 17,

        "&$medium": {
            width: v2 ? 17 : 14,
            height: v2 ? 17 : 14,
        },

        "&$large": {
            width: v2 ? 20 : undefined,
            height: v2 ? 20 : undefined,
        },
    },

    //declaration needed to be available in above selectors
    //small: {},
    normal: {},
    medium: {},
    large: {},
}));
