import {useMemo} from 'react';

//Actions
import {loadSearchResults, abortSearchResults} from 'hsi/actions/resultsActions';
import {useActiveQueryPageTypes} from 'hsi/hooks/usePageTypes';
import useFlags from 'hsi/hooks/useFlags';
import { useAppDispatch } from 'hsi/hooks/useRedux';
import { CardType } from 'hsi/types/cards';
import { useCardTypeLimit } from 'hsi/utils/cards/getCardTypeLimit';

//The hook
export default function useGetLoadData(type: CardType) {
    const dispatch = useAppDispatch();
    const flags = useFlags();
    const pageTypes = useActiveQueryPageTypes();
    const {limit} = useCardTypeLimit(type);

    return useMemo(() => {
        const func = () => dispatch(loadSearchResults(type, flags, pageTypes, {limit}));

        func.abort = () => {
            // TODO: Stop this from triggering multiple times
            dispatch(abortSearchResults(type));
        };

        return func;
    }, [dispatch, type, flags, pageTypes, limit]);
}
