
import {useMemo} from 'react';

//Hooks
import {useAppSelector} from 'hsi/hooks/useRedux';
import { TotalVolumeMetric } from 'hsi/slices/cardPersistState';
import { TotalVolumeBySearchCardData, TotalVolumeMetricType, TotalVolumePerQueryData } from 'hsi/types/cards';
import { useSearchesById } from 'hsi/hooks/useSearchesById';
import { ParsedTotalVolumeBySearchRow } from './types';

// The component
export const useDataParser = (data: TotalVolumeBySearchCardData | undefined, queryIds: number[]) => {
    const _metrics = useAppSelector((state) => state.cardPersistState.totalVolumeBySearch);
    const {searchesById} = useSearchesById();

    const metrics = useMemo(
        () => Object.values(_metrics).filter((metricValue) => !!metricValue) as TotalVolumeMetric[],
        [_metrics],
    );

    const metricsByQueryId = useMemo(() => {
        if (!data || !queryIds) return null;

        return queryIds.reduce((volumePerSearchOutput, queryId) => {
            volumePerSearchOutput[queryId] = metrics.reduce((queryMetricsOutput, {id: metric}) => {

                const metricCurrentValues = getMetricCurrentValues(data, metric);
                queryMetricsOutput[metric] = metricCurrentValues 
                    ? sumPerQueryValuesForQueryId(metricCurrentValues, queryId) 
                    : 0;

                return queryMetricsOutput;
            }, {} as Partial<Record<TotalVolumeMetricType, number>>);
            return volumePerSearchOutput;
        }, {} as Record<number, Partial<Record<TotalVolumeMetricType, number>>>);
    }, [data, metrics, queryIds]);

    const rows = useMemo(
        () =>
            metricsByQueryId === null
                ? null
                : queryIds.map((queryId) => ({
                    id: queryId,
                    name: searchesById[queryId]?.name,
                    ...metrics.reduce<Partial<Record<TotalVolumeMetricType, number | null>>>((res, {id: metric}) => {
                        res[metric] = metricsByQueryId?.[queryId]?.[metric] ?? null;
                        return res;
                    }, {}),
                } as ParsedTotalVolumeBySearchRow)),
        [metrics, queryIds, searchesById, metricsByQueryId],
    );

    return useMemo(
        () => ([rows, metrics, rows !== null] as const), 
        [metrics, rows]
    );
};


function getMetricCurrentValues(data: TotalVolumeBySearchCardData, metric: TotalVolumeMetricType) {
    return metric 
        ? data.find(({id}) => id === metric)?.currentValues ?? undefined//find(data, ({id}) => id === key)?.currentValues
        : undefined ;
};

function sumPerQueryValuesForQueryId(results: TotalVolumePerQueryData[], queryId: number) {
    return results.reduce((acc, curr) => acc + (curr.id === queryId ? curr?.value : 0), 0);
}