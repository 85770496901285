//Components
import Tooltip from 'hsi/components/Tooltip';

// Hooks
import useIsCardInteractivityDisabled from 'hsi/hooks/useIsCardInteractivityDisabled';

//Utils
import {polymorphicForwardRef, PolymorphicComponentPropWithRef} from 'hsi/types/react-polymorphic';
import format from 'hsi/utils/number/format';

//Types
type NumericMetricPropsBase = {
    children: number;
    locale?: string | string[];
};

export type NumericMetricProps<TElement extends React.ElementType = React.ElementType> =
    PolymorphicComponentPropWithRef<TElement, NumericMetricPropsBase>;

//Consts
const TOOLTIP_THRESHOLD = 1000;

//The component
export default polymorphicForwardRef<NumericMetricPropsBase, 'span'>(
    ({as: Component = 'span', children: value, locale, ...rest}, ref) => {
        const isCardInteractivityDisabled = useIsCardInteractivityDisabled();
        const tooltipDisabled = value < TOOLTIP_THRESHOLD || isCardInteractivityDisabled;

        return (
            <>
                <span className="offscreen">{format(value, 'number', locale)}</span>
                <Tooltip tooltip={format(value, 'number', locale)} noAria disable={tooltipDisabled}>
                    <Component
                        ref={ref}
                        tabIndex={tooltipDisabled ? undefined : 0}
                        {...rest}
                        aria-hidden
                    >
                        {format(value, 'compact')}
                    </Component>
                </Tooltip>
            </>
        );
    },
);
