

//Other
import { FC } from 'react';
import useStyles from './styles';

//Components
import Item from './Item';
export type {NavItemProps} from './Item';

//Types
export type NavProps = JSX.IntrinsicElements['nav'];


//The component
const Nav: FC<NavProps> = ({children, className, ...rest}: NavProps) => {
    const {classes, cx} = useStyles();

    return<nav className={cx(classes.nav, className)} {...rest}>{children}</nav>
}

Object.assign(Nav, {Item});

export default Nav as FC<NavProps> & {Item: typeof Item};
