import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
    dialog: {
        '& .MuiDialog-paper': {
            width: 805,
        },
    },
    loadingContainer: {
        textAlign: 'center',
        padding: 50,
    },
    dialogContentText: {
        paddingLeft: '0px !important',
    },
    noFbPagesMsg: {
        textAlign: 'center',
        padding: 50,
    },
    selectList: {
        height: 274,
    },
    checkboxItem: {
        display: 'flex',
        alignItems: 'center',
    },
    checkboxIcon: {
        marginRight: 4,
    },
    backButton: {
        marginRight: 'auto',
    },
}));
