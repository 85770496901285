import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    notFound: {
        textAlign: 'center',
        marginTop: 'calc(50vh - 60px)',
    },
    body: {
        marginBottom: '24px',
    },
}));
