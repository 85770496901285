import React, {useRef, useContext, useMemo} from 'react';

import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

import {useSyncExternalStoreWithSelector} from 'use-sync-external-store/shim/with-selector';

//Constants
const defaultContext = React.createContext(null);
defaultContext.displayName = 'ContextStoreContext';

//The component
export default function ContextStore({children, reducer, initialState, context = defaultContext}) {
    //cache initial values
    const ref = useRef();

    if (!ref.current) {
        //on first run, create the store
        ref.current = createStore(reducer, initialState, applyMiddleware(thunk));
    }

    const providerVars = useMemo(
        () => ({
            useSelector: (selector, compare) => useSelector(selector, compare, context),
            dispatch: ref.current.dispatch,
            getState: ref.current.getState, //does this need to be a ref func?
            subscribe: ref.current.subscribe,
        }),
        [context],
    );

    if (children instanceof Function) {
        children = children(providerVars);
    }

    const ContextComponent = context;

    return <ContextComponent.Provider value={ref.current}>{children}</ContextComponent.Provider>;
}

function refEquality(a, b) {
    return a === b;
}

//e.g. const val = useSelector(state => state.myVal, undefined, ExampleContextStateContext)
export function useSelector(selector, equalityFn = refEquality, context = defaultContext) {
    const store = useContext(context);

    return useSyncExternalStoreWithSelector(
        store.subscribe,
        store.getState,
        store.getState,
        selector,
        equalityFn,
    );
}

export function useDispatch(context = defaultContext) {
    const store = useContext(context);

    return store?.dispatch;
}
