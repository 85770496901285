import React, {useMemo, useCallback} from 'react';

//Components
import InputWrapper from 'hsi/components/Filters/InputWrapper';
import FilterChip from 'hsi/components/Filters/FilterChip';
import TextfieldWithAlert from 'hsi/components/Filters/TextfieldWithAlert';
import AutosuggestInput from 'hsi/components/Filters/AutosuggestInput';

//Hooks
import useRefCallback from 'hsi/hooks/useRefCallback';

//Other
import useStyles from './styles';

//The components
export default function TextFieldInput({
    placeholderText,
    autoScrollDrawer,
    autosuggestType,
    chips,
    maxValues = undefined, //do I need this here?
    limitLabel, //do I need this here?
    tooltip,
    tooltipPlacement,
    addValueKeyCodes, //array of keycodes that when pressed will attempt to add a new chip

    textfieldValue,
    setTextfieldValue,

    onBeforeAdd, //called with value, returns true or false if value is allowed
    onAdd, //called with value IF onBeforeAdd returns true
    onDelete,
    onInputChange, //called after each key input
    disabled,
    id,
    ...rest
}) {
    useStyles();

    //Calculated values
    const renderedChips = useMemo(
        () =>
            chips.map((chip) => (
                <FilterChip
                    {...chip}
                    onDelete={() => onDelete(chip.value)}
                    key={chip.key || chip.value}
                />
            )),
        [chips, onDelete],
    );

    //Callbacks
    const tryAddValue = useRefCallback((value) => {
        if (onBeforeAdd(value)) {
            onAdd(value);
            //setTextfieldValue('');
        }
    });
    const tryAddCurrentValue = useRefCallback(() => {
        if (onBeforeAdd(textfieldValue)) {
            onAdd(textfieldValue);
            //setTextfieldValue('');
        }
    });

    const onChange = useCallback(
        (evt) => {
            onInputChange();
            setTextfieldValue(evt.target.value);
        },
        [onInputChange, setTextfieldValue],
    );

    const onKeyDown = useRefCallback((evt) => {
        onInputChange();

        //If one of the add new chip keys is pressed
        if (addValueKeyCodes.includes(evt.which)) {
            evt.preventDefault();

            tryAddCurrentValue();
        }
    });

    //Render
    return (
        <InputWrapper
            children={
                autosuggestType ? (
                    <AutosuggestInput
                        autosuggestType={autosuggestType}
                        autoScrollDrawer={autoScrollDrawer}
                        onInputChange={onInputChange}
                        onAdd={tryAddValue}
                        textfieldValue={textfieldValue}
                        setTextfieldValue={setTextfieldValue}
                        addValueKeyCodes={addValueKeyCodes}
                        id={id}
                        placeholder={placeholderText}
                        alert={tooltip}
                        tooltipPlacement={tooltipPlacement}
                        disabled={disabled}
                        {...rest}
                    />
                ) : (
                    <TextfieldWithAlert
                        placeholder={placeholderText}
                        disabled={disabled}
                        onBlur={tryAddCurrentValue}
                        onKeyDown={onKeyDown}
                        onChange={onChange}
                        onPaste={onInputChange}
                        value={textfieldValue}
                        alert={tooltip}
                        tooltipPlacement={tooltipPlacement}
                        id={id}
                        {...rest}
                    />
                )
            }
            chips={renderedChips}
        />
    );
}
