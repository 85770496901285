import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';

import useConfig from 'hsi/hooks/useConfig';

import tns from 'hsi/i18n/tns';
const t = tns('reports.editReportModal');
const tspan = tns('reports.timespanLabels');

const Select = ({className, label, onChange, options, value}) => (
    <FormControl className={className} variant="outlined">
        <MuiSelect
            inputProps={{'aria-labelledby': label}}
            onChange={onChange}
            SelectDisplayProps={{'aria-labelledby': label}}
            value={value}
        >
            {options.map((d) => (
                <MenuItem value={d.value} key={d.value}>
                    {d.label}
                </MenuItem>
            ))}
        </MuiSelect>
    </FormControl>
);

const TimePicker = ({
    cl,
    enableTimeSelection,
    hours,
    setHours,
    minutes,
    setMinutes,
    timespan,
    setTimespan,
}) => {
    const {reports: config} = useConfig();

    const timespanOpts = Object.keys(config.timespanTypes).map((ts) => ({
        value: ts,
        label: tspan(ts),
    }));

    const hoursOptions = [...Array(24).keys()].map((d) => ({
        value: d,
        label: moment.utc(d).format('HH'),
    }));

    const minutesOptions = [...Array(60).keys()].map((d) => ({
        value: d,
        label: moment.utc(d).format('mm'),
    }));

    return (
        <>
            <Select
                className={cl.timespanLabel}
                label="frequency"
                onChange={(e) => setTimespan(e.target.value)}
                options={timespanOpts}
                value={timespan}
            />

            {enableTimeSelection && (
                <>
                    <div className={cl.timePickerAt}>{t('timePickerAt')}</div>

                    <Select
                        value={hours}
                        options={hoursOptions}
                        onChange={(e) => setHours(e.target.value)}
                        className={cl.timePickerHours}
                    />

                    <div className={cl.timePickerDiv}>:</div>

                    <Select
                        value={minutes}
                        options={minutesOptions}
                        onChange={(e) => setMinutes(e.target.value)}
                        className={cl.timePickerMinutes}
                    />
                </>
            )}
        </>
    );
};

TimePicker.propTypes = {
    cl: PropTypes.object.isRequired,
    enableTimeSelection: PropTypes.bool.isRequired,
    hours: PropTypes.number,
    setHours: PropTypes.func,
    minutes: PropTypes.number,
    setMinutes: PropTypes.func,
    timespan: PropTypes.string.isRequired,
    setTimespan: PropTypes.func.isRequired,
};

export default TimePicker;
