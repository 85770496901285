// Redux
import {loadLanguages, loadLocations} from 'hsi/actions/autocompleteActions';
import {loadTags} from 'hsi/actions/tagActions';
import {RootReducer} from 'hsi/reducers/rootReducer';
import {getSocialPanels} from 'hsi/slices/socialPanels';

// Types
import {AutosuggestConfig} from 'hsi/types/autosuggest';
import {LocationDefinition, TagDefinition} from 'hsi/types/filters';
import {ConfigType} from 'hsi/types/config';
import type {ISocialPanel} from 'hsi/types/socialPanels';

const BLANK_ARR: any[] = [];

const autosuggest: ConfigType['autosuggest'] = {
    language: {
        loadDataHandler: loadLanguages,
        justOnce: true,
        listableKey: 'fullName',
        idKey: 'id',
        isLoadingSelector: (state: RootReducer) => state.autocomplete.languageAutocomplete.loading,
        valuesSelector: (state: RootReducer) => state.autocomplete.languageAutocomplete.results,
    },
    location: {
        loadDataHandler: loadLocations,
        justOnce: false,
        listableKey: 'fullName',
        idKey: 'id',
        isLoadingSelector: (state: RootReducer) => state.autocomplete.locationAutocomplete.loading,
        valuesSelector: (state: RootReducer) => state.autocomplete.locationAutocomplete.results,
    } as AutosuggestConfig<LocationDefinition>,
    tag: {
        loadDataHandler: (projectId: number) => loadTags(projectId),
        justOnce: true,
        listableKey: 'name',
        idKey: 'id',
        isLoadingSelector: (state: RootReducer, projectId: number) =>
            state.allTags[projectId]?.isLoading ?? false,
        valuesSelector: (state: RootReducer, projectId: number) =>
            state.allTags[projectId]?.allTags ?? BLANK_ARR,
    } as AutosuggestConfig<TagDefinition>,
    socialPanel: {
        loadDataHandler: (projectId: number) => getSocialPanels({projectId}),
        justOnce: true,
        listableKey: 'name',
        idKey: 'id',
        isLoadingSelector: (state: RootReducer) => state.socialPanels.loading ?? false,
        valuesSelector: (state: RootReducer) => state.socialPanels.results ?? BLANK_ARR,
    } as AutosuggestConfig<ISocialPanel>,
} as const;

export default autosuggest;
