//TODO error handling?
import {ReactNode, useCallback, useState} from 'react';
import cn from 'classnames';

//Components
import Dialog, {
    DialogActions,
    DialogContent,
    DialogContentText,
} from 'hsi/components/Dialog';
import IconRouter from 'hsi/components/IconRouter';
import PulseLoader from 'hsi/components/PulseLoader';
import Button from 'hsi/components/Button';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

type ConfirmDialogProps = {
    title?: ReactNode;
    body: ReactNode;
    information?: ReactNode;
    open?: boolean;
    showCannotUndo?: boolean;
    closeFunc: () => void;
    confirmFunc: () => void | Promise<void>;
    type?: 'info' | 'warning';
    cancelLabel?: ReactNode;
    confirmLabel?: ReactNode;
};

//The component
export default function ConfirmDialog({
    title,
    body,
    information,
    open = false,
    showCannotUndo,
    closeFunc,
    confirmFunc,
    type = 'info',
    cancelLabel = T('confirmDialog.cancel'),
    confirmLabel = T('confirmDialog.confirm'),
}: ConfirmDialogProps) {
    const classes = useStyles();

    //State
    const [isPending, setIsPending] = useState(false);

    //Callbacks
    const onConfirm = useCallback(async () => {
        const result = confirmFunc();

        if (result instanceof Promise) {
            setIsPending(true);
            await result;
            setIsPending(false);
        }
    }, [confirmFunc]);

    return (
        <Dialog
            open={open}
            className={classes.confirmDialog}
            inert={isPending ? 'inert' : undefined}
            title={title}
            onClose={closeFunc}
        >
            {isPending && (
                <div className={classes.pendingHolder}>
                    <PulseLoader size="medium" />
                </div>
            )}
            <DialogContent>
                <DialogContentText className={cn(classes.body, classes[type])}>
                    <span className={classes.iconContainer}>
                        <IconRouter name={'warning'} className={cn(classes.icon, classes[type])} />
                    </span>
                    <span
                        className={cn(classes.copy, classes[type])}
                        data-testid="confirmDialogBody"
                    >
                        {body}
                    </span>
                </DialogContentText>
                {information && (
                    <DialogContentText className={cn(classes.information, classes[type])}>
                        {information}
                    </DialogContentText>
                )}
                {showCannotUndo && (
                    <DialogContentText className={classes.cannotUndo}>
                        {T('confirmDialog.cannotUndo')}
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeFunc} priority="primary">
                    {cancelLabel}
                </Button>
                <Button onClick={onConfirm} priority="cta">
                    {confirmLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
