import {useState} from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Cookies from 'js-cookie';

import {Dialog, DialogContent, DialogActions} from 'hsi/components/Dialog';
import Button from 'hsi/components/Button';
import Checkbox from 'hsi/components/Checkbox';

import useConfig from 'hsi/hooks/useConfig';
import useEventTrack from 'hsi/hooks/useEventTrack';

import {T} from 'hsi/i18n';

import styles from './styles.js';

const COOKIE_NAME = 'hsreportmodal';

const BuildHSIReportModal = ({open, classes, closeFunc, search}) => {
    const {links} = useConfig();
    const {track} = useEventTrack();

    const [checked, setChecked] = useState(false);

    const onClose = () => {
        closeFunc();
    };

    const redirect = () => {
        track('buildReportButtonClicked');
        let deeplinkSource = [
            {
                data_service: 'brandwatch_insights',
                data_type: 'BRANDWATCH_INSIGHTS',
                id: `id=${search.savedSearch.id}&projectId=${search.project.id}&projectTimezone=${search.project.timezone}`,
            },
        ];
        window.parent.location.href =
            links.externalReport + '?sources=' + btoa(JSON.stringify(deeplinkSource));
    };

    const openReport = () => {
        if (checked) Cookies.set(COOKIE_NAME, checked, {expires: 6000, sameSite: 'strict'});
        redirect();
    };

    const renderOrRedirect = () => {
        if (Cookies.get(COOKIE_NAME)) {
            redirect();
            return null;
        }
        return (
            <Dialog
                title={T('buildnewreport.title')}
                open={open}
                onClose={onClose}
                className={classes?.CreateReportMenu}
            >
                <DialogContent>
                    <div className="info">
                        <div className="value">{T('buildnewreport.message')}</div>
                    </div>
                </DialogContent>

                <DialogActions>
                    <Checkbox
                        className={classes.checkboxField}
                        checked={checked}
                        onChange={() => {
                            setChecked(!checked);
                        }}
                    >{T('buildnewreport.checkboxmessage')}</Checkbox>
                    <Button onClick={onClose} priority="secondary" classes={{label: 'secondary'}}>
                        {T('cancel')}
                    </Button>
                    <Button priority="primary" onClick={openReport}>
                        {T('continue')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    BuildHSIReportModal.propTypes = {
        open: PropTypes.bool,
        closeFunc: PropTypes.func,
        classes: PropTypes.object,
        search: PropTypes.object,
    };

    return <>{open && renderOrRedirect()}</>;
};

export default withStyles(styles)(BuildHSIReportModal);
