const focusableSelector =
    'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])';
const focusableFilterFunc = (el: Element) =>
    !el.hasAttribute('disabled') &&
    !el.getAttribute('aria-hidden') &&
    !el.getAttribute('hidden') &&
    isElementVisible(el);

export function getAllFocusableElements(
    elem: {querySelectorAll: Document['querySelectorAll']} = document,
) {
    return Array.from(elem.querySelectorAll<HTMLElement>(focusableSelector)).filter(
        focusableFilterFunc,
    );
}

export function isElementFocusable(elem: Element) {
    return elem.matches(focusableSelector) && focusableFilterFunc(elem);
}

//Does NOT CHECK: if in viewport, if obscured by another element, if scrolled out of view, if hidden by overflow, opacity
//Does check: if it or any parent is display: none, or visibility: hidden
export function isElementVisible(element: Element) {
    if (element.getClientRects().length > 0) {
        const styles = getComputedStyle(element);

        return styles.visibility !== 'hidden';
    }

    return false;
}

var sortByTabIndex = function (elementA: HTMLElement, elementB: HTMLElement) {
    let a = elementA.tabIndex || 0;
    let b = elementB.tabIndex || 0;
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
};

//currently excludes anything with tabIndex = -1
//This feels correct, but might not be ideal. Could be changed to include
//them, or add an optional parameter
export function getFocusableElementsInTabOrder(
    elem: {querySelectorAll: Document['querySelectorAll']} = document,
) {
    //filter out anything with tabIndex
    const focussable = getAllFocusableElements(elem).filter((elem) => +elem.tabIndex !== -1);

    focussable.sort(sortByTabIndex);

    return focussable;
}

export function getNextFocusableElement(
    element: Element,
    context: {querySelectorAll: Document['querySelectorAll']} = document,
) {
    const focusable = getAllFocusableElements(context);
    const index = focusable.indexOf(element as HTMLElement);

    if (index !== -1) {
        return index + 1 === focusable.length ? focusable[0] : focusable[index + 1];
    } else {
        const focusableChildren = getAllFocusableElements(element);

        if (focusableChildren.length > 0) {
            const index = focusable.indexOf(focusableChildren[focusableChildren.length - 1]);

            if (index !== -1) {
                return index + 1 === focusable.length ? focusable[0] : focusable[index + 1];
            }
        }
    }

    return undefined;
}

export function getPrevFocusableElement(
    element: Element,
    context: {querySelectorAll: Document['querySelectorAll']} = document,
) {
    const focusable = getAllFocusableElements(context);
    const index = focusable.indexOf(element as HTMLElement);

    if (index !== -1) {
        return index === 0 ? focusable[focusable.length - 1] : focusable[index - 1];
    } else {
        const focusableChildren = getAllFocusableElements(element);

        if (focusableChildren.length > 0) {
            const index = focusable.indexOf(focusableChildren[0]);

            if (index !== -1) {
                return index === 0 ? focusable[focusable.length - 1] : focusable[index - 1];
            }
        }
    }

    return undefined;
}

export function findAncestor<T extends Element = HTMLElement>(
    elem: T,
    test: (element: T) => boolean,
    excludeSelf: boolean = false,
) {
    let current = excludeSelf ? elem.parentElement : elem;

    while (current) {
        if (test(current as T)) {
            return current;
        }

        current = current.parentElement;
    }

    return undefined;
}

export function getMatchingChildren(child: Element, selector: string) {
    return Array.from(child.children).filter((child) => child.matches(selector));
}
