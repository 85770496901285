import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({colors, font, typgrphy, v2}: any) => ({
    divider: {
        position: 'relative',
        margin: '16px 0',
        color: v2 ? colors.textSubtle : colors.darkGrey40,
        textTransform: 'uppercase',
        textAlign: 'center',
        ...(v2 ? typgrphy.bodyLargeNormal : {fontSize: 14, fontWeight: font.semiBold}),
        '&:before': {
            content: "' '",
            position: 'absolute',
            left: 0,
            right: 0,
            top: '50%',
            borderTop: v2 ? `1px solid ${colors.borders}` : `1px solid ${colors.lightGrey100}`,
            zIndex: 0,
        },
    },
    dividerText: {
        position: 'relative',
        display: 'inline-block',
        background: colors.white,
        padding: '0 8px',
        zIndex: 2,
    },
    copyLinkLabel: {
        paddingBottom: 6,
        ...(v2 ? typgrphy.bodyNormal : {fontSize: 14, fontWeight: font.semiBold}),
    },
}));
