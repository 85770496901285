import React, {
    DetailedHTMLProps,
    forwardRef,
    ImgHTMLAttributes,
    useCallback,
    useState,
    useMemo,
} from 'react';

type ImgProps = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

interface SiteFaviconArgs extends Omit<ImgProps, 'src' | 'onError' | 'ref'> {
    domain: string;
}

export default forwardRef<HTMLImageElement, SiteFaviconArgs>(function SiteFavicon(
    {domain, alt = '', ...rest},
    ref,
) {
    const [loadFailed, setLoadFailed] = useState(false);

    //Callbacks
    const onError = useCallback(() => {
        setLoadFailed(true);
    }, [setLoadFailed]);

    // Twitter to X workaround because they are not using the default favicon.ico
    const favicon = useMemo(()=> domain !== 'twitter.com' ? 'favicon.ico' : 'twitter.3.ico',[domain]);

    //Render
    return (
        <img
            ref={ref}
            alt={alt}
            onError={loadFailed ? undefined : onError}
            src={
                loadFailed
                    ? `https://s2.googleusercontent.com/s2/favicons?domain=${domain}`
                    : `https://${domain}/${favicon}`
            }
            {...rest}
        />
    );
});
