
export type VisibilityTypes = {
    display?: boolean;
    hidden?: boolean;
    opacity?: boolean;

    //TODO size (width&height > 0)
    //TODO viewport?
    //TODO depth?
    //TODO overflow
};

/**
 * Method to check if an element is 'visible'. Optional arguments allow you to choose what is counted as 'visible'.
 * This method connot current check for: is the element within the viewport, is the element obsured by another element, is the element hidden by overflow
 * @param element The element to test visibility for
 * @param options Optional parameter to configure what is counted for 'visibility', defaults to display: none | visibility: hidden 
 */
export default function isElementVisible<T extends HTMLElement = HTMLElement>(element: T, {display = true, hidden = true, opacity = false} = {}) {
    if(!element || (!display && !hidden && !opacity)) {
        throw new Error('Invalid arguments, nothing to check');
    }

    return (!display || visibilityCheck(element)) && (!hidden || hiddenCheck(element)) && (!opacity || opacityCheck(element))
}

//For all checks, true  = visible

function visibilityCheck<T extends HTMLElement = HTMLElement>(element: T) {
    for (let el: HTMLElement | null = element; !!el; el = el.parentElement) {
        if(el.style.display === 'none') {
            return false;
        }
    }

    return true;
}

function hiddenCheck<T extends HTMLElement = HTMLElement>(element: T) {
    return getComputedStyle(element).visibility !== 'hidden';
}

function opacityCheck<T extends HTMLElement = HTMLElement>(element: T) {
    for (let el: HTMLElement | null = element; !!el; el = el.parentElement) {
        if(+getComputedStyle(el).opacity <= 0) {
            return false;
        }
    }

    return true
}