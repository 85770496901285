import {DateTime, Interval} from 'luxon';

import {InputDateType, TDateISODate} from 'hsi/types/dates';
import {normalizeDate, toISODate} from 'hsi/utils/dates';
import {TimezoneID} from 'hsi/utils/timezones';

export function makeDaysIntervalFrom(
    d1?: InputDateType,
    d2?: InputDateType,
    timezone?: TimezoneID,
): Interval | undefined {
    if (!d1 || !d2) {
        return undefined;
    }

    const d1n = normalizeDate(d1, timezone);
    const d2n = normalizeDate(d2, timezone);

    return Interval.fromDateTimes(
        DateTime.min(d1n, d2n).startOf('day'),
        DateTime.max(d1n, d2n).endOf('day'),
    );
}

export function toISODateRange(
    d1?: InputDateType,
    d2?: InputDateType,
): [TDateISODate | undefined, TDateISODate | undefined] {
    if (!d1 || !d2) {
        return [undefined, undefined];
    }

    const interval = makeDaysIntervalFrom(d1, d2);

    return interval ? [toISODate(interval.start), toISODate(interval.end)] : [undefined, undefined];
}
