import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';

//Components
import {MenuWrapper, CheckableMenuItem} from 'hsi/components/SelectableMenuItem';
import Chip from 'hsi/components/Chip';
import Button from 'hsi/components/Button';
import IconRouter from 'hsi/components/IconRouter';
import Popover, {sizeToOpener} from 'hsi/components/Popover';

//Other
import useStyles from './styles.js';
import {T} from 'hsi/i18n';

//The component
export default function ChipMultipleSelect({
    applyHandler,
    deleteHandler,
    scrollHandler,
    options,
    selectedOptions,
    placeholder,
    btnLabel = 'chipMultipleSelect.label',
    chipsLbl = 'chipMultipleSelect.chipsLbl',
    menuLbl = 'chipMultipleSelect.menuLbl',
    menuDesc = 'chipMultipleSelect.menuDesc',
}) {
    const classes = useStyles();

    const chips = useMemo(
        () =>
            selectedOptions.map((value, i) => (
                <Chip
                    component="li"
                    key={value.name + i}
                    label={value.label}
                    size={'medium'}
                    onDelete={(e) => deleteHandler(value)}
                    overflowTooltip
                    clickable={true}
                    onClick={(e) => (scrollHandler ? scrollHandler(e, value.name) : null)}
                    data-testid={`${value.name}-chip`}
                    className={classes.chip}
                />
            )),
        [deleteHandler, selectedOptions, scrollHandler, classes],
    );

    return (
        <div className={classes.control}>
            <div className={classes.configMenu}>
                <Popover
                    content={({close, labelId, descriptionId, context}) => (
                        <>
                            {menuLbl && (
                                <p id={labelId} className="offscreen">
                                    {T(menuLbl)}
                                </p>
                            )}
                            {menuDesc && (
                                <p id={descriptionId} className="offscreen">
                                    {T(menuDesc)}
                                </p>
                            )}
                            <MenuContent
                                {...{
                                    classes,
                                    applyHandler: (...args) => {
                                        applyHandler(...args);
                                        close();
                                    },
                                    options,
                                    onCancel: close,
                                    selectedOptions,
                                    floatingContext: context,
                                }}
                            />
                        </>
                    )}
                    size={sizeToOpener}
                >
                    <button className={classes.openMenuBtn}>
                        <span className="offscreen">{T(btnLabel)}</span>
                    </button>
                </Popover>
                {chipsLbl && chips.length > 0 && <span className="offscreen">{T(chipsLbl)}</span>}
                {chips.length ? (
                    <ul
                        className={classes.chipsWrapper}
                        data-testid="chipMultipleSelect-chipWrapper"
                    >
                        {chips}
                    </ul>
                ) : (
                    <span className={classes.placeholder}>{placeholder}</span>
                )}
                <IconRouter aria-hidden name="chevron-down" className={classes.downArrowIcon} />
            </div>
        </div>
    );
}

const optionsArray = PropTypes.arrayOf(
    PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.any.isRequired,
    }),
).isRequired;

ChipMultipleSelect.propTypes = {
    applyHandler: PropTypes.func.isRequired,
    scrollHandler: PropTypes.func,
    deleteHandler: PropTypes.func,
    placeholder: PropTypes.string,
    options: optionsArray,
    selectedOptions: optionsArray,

    //Language codes
    btnLabel: PropTypes.string,
    chipsLbl: PropTypes.string,
    menuLbl: PropTypes.string,
    menuDesc: PropTypes.string,
};

//Internal components
const MenuContent = ({
    classes,
    applyHandler,
    options,
    onCancel,
    selectedOptions,
    floatingContext,
}) => {
    //Initial menu selections are based on the provided types
    const defaultData = useMemo(
        () =>
            options.map((item) =>
                find(selectedOptions, (option) => option.name === item.name)
                    ? {...item, state: true}
                    : {...item, state: false},
            ),
        [options, selectedOptions],
    );

    const [data, setData] = useState(defaultData);

    const onApply = useCallback(
        (event) => {
            event.preventDefault();
            //Data is saved as an array of the selected ids === types
            const chosenOptions = data.filter((item) => item.state === true);
            applyHandler(chosenOptions);
            onCancel();
        },
        [data, applyHandler, onCancel],
    );

    const items = useMemo(() => {
        const onChange = (value, updatedItem) => {
            setData((data) =>
                data.map((item) =>
                    item.name === updatedItem.name ? {...item, state: value} : item,
                ),
            );
        };

        return data.map((item) => (
            <CheckableMenuItem
                key={item.name}
                checked={item.state}
                onChange={(value) => onChange(value, item)}
            >
                {item.label}
            </CheckableMenuItem>
        ));
    }, [data, setData]);

    return (
        <form className={classes.menuContent} onSubmit={onApply}>
            <MenuWrapper>{items}</MenuWrapper>
            <div className={classes.selectMenuActions}>
                <Button
                    priority="primary"
                    data-testid="chipMultipleSelect-cancel"
                    onClick={onCancel}
                >
                    {T('cancel')}
                </Button>
                <Button type="submit" priority="cta">
                    {T('select')}
                </Button>
            </div>
        </form>
    );
};

MenuContent.propTypes = {
    classes: PropTypes.object.isRequired,
    applyHandler: PropTypes.func.isRequired,
    options: optionsArray,
    onCancel: PropTypes.func.isRequired,
    selectedOptions: optionsArray,
};
