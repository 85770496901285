import PulseLoader, {PulseLoaderSize} from 'hsi/components/PulseLoader';
import useStyles from './styles';
import {ReactNode} from 'react';
import classNames from 'classnames';

export type FullscreenLoadingProps = {
    classes?: Partial<ReturnType<typeof useStyles>>;
    message: ReactNode;
    loaderSize?: PulseLoaderSize;
    offscreenMessage?: boolean;
} & JSX.IntrinsicElements['div'];

export default function FullscreenLoading({
    classes: extraClasses,
    className,
    message,
    loaderSize = 'large',
    role = 'status',
    offscreenMessage = false,
    ...rest
}: FullscreenLoadingProps) {
    const classes = {...useStyles(), ...extraClasses};

    return (
        <div className={classNames(classes.root, className)} role={role} {...rest}>
            <div className={classes.animContainer} data-testid="fullscreen-loading">
                <PulseLoader size={loaderSize} />
                <div className={classNames(classes.message, offscreenMessage && 'offscreen')}>
                    {message}
                </div>
            </div>
        </div>
    );
}
