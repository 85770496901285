import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    dropDownIcon: {
        width: 12,
        height: 12,
        margin: 16,
    },
    textFieldNotchedOutline: {
        boxShadow: `none !important`,
        border: `1px solid ${theme.colors.keyline} !important`,
    },
    textFieldRoot: {
        cursor: 'pointer',
    },
    textFieldAdornedEnd: {
        paddingRight: 0,
    },
    dense: {
        marginLeft: 0,
    },
    inputAdornment: {
        position: 'relative',
    },
    menuContent: {
        padding: 0,
    },
    searchFieldWrapper: {
        padding: '10px 10px',
    },
    noOptionsFound: {
        padding: '30px 16px 40px',
        textAlign: 'center',
        color: theme.colors.textMeta,
    },
    menuItem: {
        cursor: 'pointer',
    },
    menuList: {
        maxHeight: 45 * 5,
        padding: '2px 0',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    denseIcon: {
        width: 12,
        height: 12,
        margin: theme.v2 ? 0 : '18px 8px 16px 4px',
    },
    nested: {
        paddingLeft: theme.spacing(5),
    },
    checkboxFControlRoot: {
        marginRight: 0,
    },
    checkboxFControlParentRoot: {
        marginRight: 0,
        '&.chbox-label': {
            width: '36px !important',
        },
    },
    okContainer: {
        paddingTop: 16,
        paddingBottom: 2,
        paddingLeft: 10,
        paddingRight: 10,
    },
    listItemIcon: {
        minWidth: 0,
        '& .chbox-label': {
            minWidth: '0 !important',
            width: 40,
        },
    },
    checkboxRoot: {
        width: 28,
        '& input': {
            width: '30px !important',
        },
    },
    expandButton: {
        height: 30,
        width: 30,
        marginLeft: 3,
        backgroundColor: 'transparent',
        '&:focus': {
            boxShadow: 'none',
        },
        '&:hover': {
            backgroundColor: theme.colors.lightGrey20,
        },
    },
    expandIcon: {
        fill: '#aaa !important',
        width: 20,
        height: 20,
        margin: 2,
    },
    nestedListPadding: {
        paddingTop: 0,
    },
    listItemText: {
        paddingRight: 12,
    },
    parentItemTextContainer: {
        display: 'flex',
    },
    parentItemTextName: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    parentItemTextNum: {
        whiteSpace: 'nowrap',
        marginLeft: 5,
    },
    subcatItemTextName: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));
