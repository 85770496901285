import IconButton from '@mui/material/IconButton';

import IconRouter from 'hsi/components/IconRouter';

import {Theme} from 'hsi/types/theme';
import makeStyles from '@mui/styles/makeStyles';

import tns from 'hsi/i18n/tns';
import { T } from 'hsi/i18n';
import Heading from 'hsi/components/aria/Heading';
const t = tns('queryBuilder');

const useStyles = makeStyles(({colors, fonts, v2}: Theme) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '5px 10px',
    },
    title: {
        color: v2 ? colors.textDefault : colors.darkGrey100,
        ...(v2 ? fonts.headtitleStrong : {fontSize: '18px', fontWeight: 'bold'}),
        margin: 5,
    },
    close: {
        margin: 5,
        '& svg': {
            width: v2 ? 22 : 24,
            height: v2 ? 22 : 24,
        },
        '& button': {
            padding: 5,
        },
    },
}));

type HeaderProps = {
    id?: string;
    onClose: () => void;
};

const Header = ({onClose, id}: HeaderProps) => {
    const cl = useStyles();

    return (
        <header className={cl.header} data-testid="searchHelpTitle" id={id}>
            <Heading className={cl.title} id={`${id}.heading`}>{t('searchHelpTitle')}</Heading>
            <div className={cl.close}>
                <IconButton aria-label={T('close')} onClick={onClose} size="large">
                    <IconRouter aria-hidden name="mui-close" />
                </IconButton>
            </div>
        </header>
    );
};

export default Header;
