import {PayloadAction} from '@reduxjs/toolkit';
import {WORD_CLOUD_DEFAULT, WORD_CLOUD_TYPES, TOTAL_VOLUME_TYPES} from 'hsi/constants/config';
import {createSlice} from '@reduxjs/toolkit';

import {Breakdowns, Aggregates} from 'hsi/types/filters';
import { TotalVolumeMetricType } from 'hsi/types/cards';

export type TotalVolumeMetric = {
    id: TotalVolumeMetricType;
    label: string;
};

type BreakdownsTypes = keyof Breakdowns;
type AggregatesTypes = keyof Aggregates;
type AggregateType = string;//Ideally, these would be better typed, but this will do for now
type BreakdownType = string;//Ideally, these would be better typed, but this will do for now

export type CardPersistStateType = 
    Record<BreakdownsTypes, {breakdown: BreakdownType}> & 
    Record<AggregatesTypes, {aggregate: AggregateType}> &
    {
        toptopicsBySearch: {
            types: string[];
            orderBy: string;
            color: string;
            size: number;
        };
        totalVolume: {
            metric1?: TotalVolumeMetric;
            metric2?: TotalVolumeMetric;
            metric3?: TotalVolumeMetric;
            metric4?: TotalVolumeMetric;
        };
        totalVolumeBySearch: {
            metric1?: TotalVolumeMetric;
            metric2?: TotalVolumeMetric;
            metric3?: TotalVolumeMetric;
            metric4?: TotalVolumeMetric;
        };
        wordCloud: {
            types: string[];
            orderBy: string;
            color: string;
            size: number;
        };
    };

export type PersistedCardType = keyof CardPersistStateType;

export const initialState = {
    benchmark: {
        breakdown: 'pageTypes',
    },
    emotionHistory: {
        breakdown: 'days',
    },
    mentionsHistory: {
        breakdown: 'days',
        aggregate: 'volume',
    },
    netSentimentHistory: {
        breakdown: 'days',
    },
    sentimentHistory: {
        breakdown: 'days',
    },
    toptopicsBySearch: {
        types: WORD_CLOUD_TYPES.map((t) => t.id),
        orderBy: 'volume',
        color: 'topicType',
        size: WORD_CLOUD_DEFAULT,
    },
    totalVolume: {
        metric1: TOTAL_VOLUME_TYPES[0],
        metric2: TOTAL_VOLUME_TYPES[1],
        metric3: TOTAL_VOLUME_TYPES[2],
        metric4: TOTAL_VOLUME_TYPES[3],
    },
    totalVolumeBySearch: {
        metric1: TOTAL_VOLUME_TYPES[0],
        metric2: TOTAL_VOLUME_TYPES[1],
        metric3: TOTAL_VOLUME_TYPES[2],
        metric4: TOTAL_VOLUME_TYPES[3],
    },
    wordCloud: {
        types: WORD_CLOUD_TYPES.map((t) => t.id),
        orderBy: 'volume',
        color: 'topicType',
        size: WORD_CLOUD_DEFAULT,
    },
};

type CardInnerPayloadType = PayloadAction<{type: PersistedCardType; value: CardPersistStateType[PersistedCardType]}>;

type updateBreakdownPayloadType = PayloadAction<{
    type: BreakdownsTypes;
    value: BreakdownType;
}>;

type updateAggregatePayloadType = PayloadAction<{
    type: AggregatesTypes;
    value: AggregateType;
}>;

type LoadPersistCardAction = PayloadAction<{
    filterCard: any;//TODO this 'any' is unacceptable. This persistence should probably also be moved to be part of the queryUserData system
}>;

const slice = createSlice({
    name: 'cardPersistState',
    initialState,
    reducers: {
        //breakdown
        updateBreakdown: (state, {payload: {type, value}}: updateBreakdownPayloadType) => {
            if (state[type]) {
                state[type].breakdown = value;
            } else {
                state = {
                    ...state,
                    [type]: {
                        ...state[type],
                        breakdown: value,
                    },
                };
            }
            return state;
        },
        updateAggregate: (state, {payload: {type, value}}: updateAggregatePayloadType) => {
            if (state[type]) {
                state[type].aggregate = value;
            } else {
                state = {
                    ...state,
                    [type]: {
                        ...state[type],
                        aggregate: value,
                    },
                };
            }
            return state;
        },
        updateCardsInnerState: (state, {payload: {type, value}}: CardInnerPayloadType) => {
            state = {
                ...state,
                [type]: value,
            };
            return state;
        },
        loadPersistCardPersistState: (
            state,
            {payload: {filterCard}}: LoadPersistCardAction,
        ) => {
            //revert to initial state & merge in loaded values
            state = {
                ...initialState,
                ...filterCard,//We should never, never, never do this. Just, take some random object and stick it in the state? What does this action do? What does it mean when it is called? when should it be used? Who knows! It can do ANYTHING. This action is meaningless, and makes this slice unmaintainable, as you have no way to be sure what is actually going on within this state without checking every use of this action, and at any point someone can easily do something stupid and break things
            };
        },
    },
});

export const {
    updateBreakdown,
    updateAggregate,
    updateCardsInnerState,
    loadPersistCardPersistState,
} = slice.actions;
export default slice.reducer;
