import React from 'react';
import MuiSelect, {SelectProps} from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';

//Components
import IconRouter from 'hsi/components/IconRouter';

//Other
import useStyles, {useIconStyles} from './styles';

//The component
export default function Select(props: SelectProps) {
    const {classes} = useStyles();

    return (
        <MuiSelect
            IconComponent={DropdownIcon}
            input={<OutlinedInput />}
            classes={classes}
            {...props}
        />
    );
}

//Internal helper component
const DropdownIcon = () => {
    const {classes} = useIconStyles();

    return <IconRouter aria-hidden className={classes.downArrowIcon} name="chevron-down" />;
};
