import {isV2} from 'hsi/types/theme';
import {makeStyles} from 'tss-react/mui';

export default makeStyles()((theme) => {
    const v2 = isV2(theme);
    return {
        container: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        checkboxContainer: {
            [`&>.MuiFormControlLabel-label`]: {
                [`& .MuiTypography-root`]: {
                    display: 'none',
                },
            },

            [`&>.MuiFormControlLabel-root`]: {
                width: v2 ? '16px' : undefined,
                height: v2 ? '16px' : undefined,
                margin: v2 ? 0 : undefined,
                display: v2 ? 'block' : undefined,

                [`&>.MuiCheckbox-root`]: {
                    display: v2 ? 'block' : undefined,
                    padding: v2 ? 0 : undefined,
                    margin: v2 ? '0 -12px -24px' : undefined,
                },
            },
        },
    };
});

export const switchTheme = makeStyles()((theme, _params, classes) => {
    if (isV2(theme)) {
        const onColor = theme.colors.accentDefault;
        const offColor = theme.colors.bgSubtle;

        return {
            root: {
                '--switch-border-width': '1px',
                '--switch-size': `var(--switch-size-base, 16px)`,
                '--switch-diameter': 'calc(var(--switch-size) - var(--switch-border-width) * 2)',

                width: 'calc(var(--switch-size) * 2)',
                height: 'var(--switch-size)',

                padding: 0,
                display: 'flex',
                overflow: 'initial',
            },
            switchBase: {
                //the bit that moves
                padding: 1,
                background: 'none',
                '&:hover': {
                    background: 'none',
                },
                '&.MuiSwitch-switchBase.Mui-checked': {
                    transform: `translateX(var(--switch-size))`,
                    '+ .MuiSwitch-track': {
                        opacity: 1,
                        backgroundColor: onColor,
                        borderColor: 'transparent',
                    },
                },
            },
            thumb: {
                //the circle in the bit that moves
                width: 'var(--switch-diameter)',
                height: 'var(--switch-diameter)',
                boxShadow: 'none',
                backgroundColor: '#FFFFFF',

                '$input:focus-visible + &': {
                    outline: `3px solid ${theme.colors.secondaryPurple100}`,
                },
            },
            track: {
                //the base?
                border: `1px solid transparent`,
                borderRadius: 'calc(var(--switch-size) / 2)',
                opacity: 1,
                backgroundColor: offColor,
            },
            checked: {},
            input: {},
        };
    } else {
        const onColor = theme.colors.secondaryPurple100;
        const offColor = theme.colors.darkGrey40;

        return {
            root: {
                '--switch-padding': '2px',
                '--switch-size': `var(--switch-size-base, 16px)`,
                '--switch-diameter': 'calc(var(--switch-size) - var(--switch-padding) * 2)',

                width: 'calc(var(--switch-size) * 2)',
                height: 'var(--switch-size)',
                padding: 0,
                display: 'flex',
                overflow: 'initial',
            },
            switchBase: {
                //the bit that moves
                padding: 'var(--switch-padding)',
                background: 'none',
                '&:hover': {
                    background: 'none',
                },
                '&.MuiSwitch-switchBase.Mui-checked': {
                    transform: 'translateX(var(--switch-size))',
                    '+ .MuiSwitch-track': {
                        opacity: 1,
                        backgroundColor: onColor,
                        borderColor: onColor,
                    },
                },
            },
            thumb: {
                //the circle in the bit that moves
                width: 'var(--switch-diameter)',
                height: 'var(--switch-diameter)',
                boxShadow: 'none',
                backgroundColor: '#FFFFFF',

                'input:focus-visible + &': {
                    outline: `3px solid ${theme.colors.secondaryOrange100}`,
                },
            },
            track: {
                //the base?
                border: `1px solid ${offColor}`,
                borderRadius: 'calc(var(--switch-size) / 2)',
                opacity: 1,
                backgroundColor: offColor,
            },
            checked: {},
            input: {},
        };
    }
});
