import React, {useMemo, useRef, useState} from 'react';
import cn from 'classnames';

//Components
import HorizontalPositionedColumns from 'hsi/components/layout/HorizontalPositionedColumns';
import SearchResultsCard from './SearchResultsCard';
import SearchResultsHeader from './Header';
import VerticalScroll from 'hsi/components/layout/VerticalScroll';
import {VisibleContainer, createContext} from 'hsi/hooks/useOnVisible';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useGetCards from 'hsi/hooks/useGetCards';
import useMultipleSearchIds from 'hsi/hooks/useMultipleSearchIds';
//Other
import useStyles from './styles';

//Constants
export const fadeContext = createContext('SearchResultsFade');

//The component
export default function SearchResults() {
    const classes = useStyles();
    const {searchIds} = useMultipleSearchIds();
    // config
    const {searchResults: config} = useConfig();

    //Refs
    const cardAreaRef = useRef<HTMLDivElement>(null);

    //state
    const [sectionElements, setSectionElements] = useState<Record<string, Record<string, HTMLElement>>>({});

    // card conf
    const cards = useGetCards(config);

    const columns = useMemo(
        () => config.getNumColumns(searchIds.length),
        [config, searchIds.length],
    );

    const cardElements = useMemo(
        () =>
            cards.map((card) => (
                <HorizontalPositionedColumns.Item key={card.name}>
                    <SearchResultsCard>
                        <card.component
                            title={card.title}
                            height={card.height || 320}
                            ref={(elem) =>
                                setSectionElements((sectionElements) => {
                                    if(elem !== null) {
                                        return {
                                            ...sectionElements,
                                            [card.section]: {
                                                ...sectionElements[card.section],
                                                [card.name]: elem,
                                            },
                                        }
                                    }

                                    const {[card.name]: _, ...rest} = sectionElements[card.section];
                                    
                                    return {
                                        ...sectionElements,
                                        [card.section]: {
                                            ...rest
                                        }
                                    }
                                })
                            }
                        />
                    </SearchResultsCard>
                </HorizontalPositionedColumns.Item>
            )),
        [cards, setSectionElements],
    );

    //Render
    return (
        <>
            <SearchResultsHeader sectionElements={sectionElements} containerRef={cardAreaRef} />

            <div className={cn(classes.cardArea)}>
                <VisibleContainer options={config.cardLoadOptions}>
                    <VisibleContainer options={config.cardFadeOptions} context={fadeContext}>
                        <VerticalScroll ref={cardAreaRef}>
                            <HorizontalPositionedColumns
                                columns={columns}
                                debounceOptions={config.cardPositionDebounceOptions}
                                transitionTime={config.cardTransitionTime}
                            >
                                {cardElements}
                            </HorizontalPositionedColumns>
                        </VerticalScroll>
                    </VisibleContainer>
                </VisibleContainer>
            </div>
        </>
    );
}
