import {mentionsDrillIn} from 'hsi/slices/mentions';
import useEventTrack from 'hsi/hooks/useEventTrack';
import {CardType} from 'hsi/types/cards';
import {useAppDispatch} from './useRedux';
import {PeakType} from 'hsi/types/peaks';
import {FiltersState} from 'hsi/types/filters';
import {DateRange} from 'hsi/types/filters';
import {useCallback} from 'react';

export type DrillInMentionsArgs = {
    filter: FiltersState;
    type: CardType;
    dates?: DateRange;
    label: string;
    tracking?: {
        name: string;
        type?: CardType;
        value: string | number;
    };
    peak?: PeakType;
    drillInFrom?: string;
};

/**
 * Hook that returns a function to call to drill in for a given card.
 * Handles dispatching the drill in action & tracking
 */
export default function useDrillInMentions() {
    const dispatch = useAppDispatch();
    const {trackWithSearchData} = useEventTrack();

    return useCallback(
        ({filter, type, dates, label, tracking, peak, drillInFrom}: DrillInMentionsArgs) => {
            if (tracking) {
                trackWithSearchData(tracking.name as any, {
                    type,
                    value: tracking.value,
                });
            }

            dispatch(
                mentionsDrillIn(
                    filter,
                    type,
                    dates,
                    label,
                    peak || undefined,
                    drillInFrom || undefined,
                ),
            );
        },
        [dispatch, trackWithSearchData],
    );
};
