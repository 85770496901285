import {ForwardedRef, forwardRef, useMemo} from 'react';
import isEmpty from 'lodash/isEmpty';

// Components
import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle, { formatCardTitleAsString } from 'hsi/components/Card/CardTitle';
import ManagedGridTable from 'hsi/components/table/ManagedGridTable';
import useSortRows from 'hsi/components/table/ManagedTable/useSortRows';
import CompactNumber from 'hsi/components/format/CompactNumber';
import PaginationButtons from 'hsi/components/PaginationButtons';

// Hooks
import useCardData from 'hsi/hooks/useCardData';
import useMultipleSearchIds from 'hsi/hooks/useMultipleSearchIds';
import useDrillInMentions, { DrillInMentionsArgs } from 'hsi/hooks/useDrillInMentions';
import { useSearchesById } from 'hsi/hooks/useSearchesById';
import useCardTableTrackingAndPersistence from '../hooks/useCardTableTrackingAndPersistence';
import useAddPositionToRows, { WithPosition } from '../hooks/useAddPositionToRows';
import usePaginateRows from 'hsi/components/table/ManagedTable/usePaginateRows';
import useFormatRows from 'hsi/components/table/ManagedTable/useFormatRows';
import { useCardTypeLimit } from 'hsi/utils/cards/getCardTypeLimit';

// Other
import useStyles from './styles';
import {T} from 'hsi/i18n';

// Types
import { CardType, CardTypeProps } from 'hsi/types/cards';
import { ColumnDefinition, FormatColumnDefinition, SortColumnDefinition } from 'hsi/components/table/ManagedTable/types';
import { RootState } from 'hsi/utils/configureStore';
import { DefaultChartStateType } from 'hsi/slices/chart';

export type TopMeasureItem = WithPosition<{
    id: string;
    name: 'name',
    value: number;

    [key: number]: number;
}>;
type Columns = 'position' | 'name' | 'value' | number;
type TableColumnDefinition = ColumnDefinition<Columns> & FormatColumnDefinition<TopMeasureItem, Columns> & SortColumnDefinition<TopMeasureItem, Columns>;

export type TopMeasureBySearchCardProps = CardTypeProps & {
    type: CardType;
    dataSelector: (root: RootState) => DefaultChartStateType<TopMeasureItem[]>;
    getDrillInData: (data: TopMeasureItem) => DrillInMentionsArgs;
    nameField: {
        name?: 'name';
        label: ColumnDefinition<any>['label']
    } & Partial<Omit<TableColumnDefinition, 'name' | 'label'>>;
};

// Consts
const DEFAULT_DATA: TopMeasureItem[] = [];
const DEFAULT_SORT_COLUMN = 'value';
const DEFAULT_NAME_FIELD = {
    name: 'name',
    width: '1fr',
    truncateCellText: true,
    horizontalAlign: 'start',
    sortable: true,
    format: ({name}: TopMeasureItem) => name
} as const


// The component
function TopMeasureBySearchCard(
    {title, type, dataSelector, getDrillInData, nameField, ...props}: TopMeasureBySearchCardProps,
    ref: ForwardedRef<HTMLDivElement>,
) {
    const drillInMentions = useDrillInMentions();
    const {searchIds} = useMultipleSearchIds();
    const {perPage} = useCardTypeLimit(type);
    const columns = useGetColumns(nameField, searchIds);

    const {data, loading, loaded, error, loadData, isCardSelected} = useCardData({
        type,
        dataSelector,
    });

    const rows = data ?? DEFAULT_DATA;

    const {page, pages, setPage, ...sorting} = useCardTableTrackingAndPersistence(type, columns, {totalRows: rows.length ?? 0, rowsPerPage: perPage, defaultSortColumn: DEFAULT_SORT_COLUMN});

    const sortedRows = useSortRows(rows, columns, sorting.sortColumn, sorting.sortDirection);

    // Add position property to row data
    const positionedRows = useAddPositionToRows(sortedRows);

    // Apply pagination
    const paginatedRows = usePaginateRows(positionedRows, page, perPage);

    // Apply formatting
    const formattedRows = useFormatRows(paginatedRows, columns);
    
    return (
        <CardLoadState
            title={<CardTitle title={title} type={type} hasData={!isEmpty(data)} />}
            data-testid={type}
            error={error}
            hasData={!isEmpty(data)}
            loading={loading}
            loaded={loaded}
            loadData={loadData}
            selected={isCardSelected}
            ref={ref}
            type={type}
            {...props}
        >
            <ManagedGridTable
                columns={columns}
                rowData={formattedRows}
                caption={T('managedTable.caption', {name: formatCardTitleAsString(title)})}

                rowSelectable
                onRowClick={(index: number) => {
                    drillInMentions(getDrillInData(paginatedRows[index]));
                }}
                {...sorting}
            />

            <PaginationButtons page={page} pages={pages} setPage={setPage} />
        </CardLoadState>
    );
};

export default forwardRef(TopMeasureBySearchCard);

function useGetColumns(nameField: TopMeasureBySearchCardProps['nameField'], queryIds: number[]) {
    const classes = useStyles();
    const {searchesById} = useSearchesById();

    return useMemo<TableColumnDefinition[]>(() => {
        const columns: TableColumnDefinition[] = [
            {
                name: 'position',
                label: <span className="offscreen">{T('cards.topMeasures.tableFields.position')}</span>,
                horizontalAlign: 'start',
                width: 'auto',
                format: (row) => <span className={classes.tableRowPosition}>{row.position}</span>
            },
            {
                ...DEFAULT_NAME_FIELD,
                ...nameField
            },
            {
                name: 'value',
                label: T(`cards.topMeasures.tableFields.value`),
                width: '100px',
                sortable: true,
                sortType: 'numeric',
                defaultSortDir: 'desc',
                format: (row) => <CompactNumber value={+row.value} tooltip />
            },
            ...queryIds.map((queryId) => ({
                name: queryId,
                label: searchesById[queryId]?.name ?? '-',
                width: '100px',
                sortable: true,
                sortType: 'numeric',
                defaultSortDir: 'desc',
                truncateHeaderLabelText: true,
                format: (row) => <CompactNumber value={row[queryId] ?? 0} tooltip />
            } as TableColumnDefinition))
        ];

        return columns;

        
    }, [classes.tableRowPosition, nameField, queryIds, searchesById]);
}