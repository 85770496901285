import React, {PropsWithChildren, ReactNode, useCallback, useMemo} from 'react';
import cn from 'classnames';

import IconRouter from 'hsi/components/IconRouter';
import ConditionalWrap, {ConditionalWrapFunc} from 'hsi/components/ConditionalWrap';
import WithMenuAndTooltip from 'hsi/components/Mention/WithMenuAndTooltip';
import SentimentMenu from 'hsi/components/Mention/SentimentMenu';
import Tooltip from 'hsi/components/Tooltip';
import PulseLoader from 'hsi/components/PulseLoader';

import {T} from 'hsi/i18n';
import stopPropagation from 'hsi/utils/html/stopPropagation';
import format from 'hsi/utils/number/format';

import useStyles from './styles';
import useTooltipStyles from 'hsi/components/Mention/WithMenuAndTooltip/styles';
import useFlags from 'hsi/hooks/useFlags';
import classNames from 'classnames';
import {
    MentionDataType,
    MentionType,
    SavedSearchMentionType,
    isSavedSearchMention,
} from 'hsi/types/mentions';
import {EditMentionType} from 'hsi/slices/mentions';
import useIsSavedSearch from 'hsi/hooks/useIsSavedSearch';

export type MetricsProps = {
    mentionData: MentionDataType;
    mention: MentionType;
    editable?: boolean;
    className?: string;
    editMentionStatus?: EditMentionType;
};

const Metrics = ({
    mentionData,
    mention,
    editMentionStatus,
    editable = false,
    className = undefined,
}: MetricsProps) => {
    const classes = useStyles();
    let PlatformMetrics = null;
    const {hasEditSentimentFromMention} = useFlags();

    switch (mentionData.pageTypeName) {
        case 'X':
            PlatformMetrics = TwitterMetrics;
            break;
        case 'Facebook':
            PlatformMetrics = FacebookMetrics;
            break;
        case 'Reddit':
            PlatformMetrics = RedditMetrics;
            break;
        case 'Instagram':
            PlatformMetrics = InstagramMetrics;
            break;
        default:
            PlatformMetrics = null;
    }

    const isSavedSearch = useIsSavedSearch();
    if (!isSavedSearch) {
        return null;
    }
    return (
        <div className={classNames(classes.root, className)} data-testid="mentionMetrics">
            <Sentiment
                mentionData={mentionData}
                mention={mention}
                editMentionStatus={editMentionStatus}
                classes={classes}
                editable={editable && !!hasEditSentimentFromMention}
            />
            {mentionData.reachEstimate !== undefined && (
                <NumericalMetric label="mentionComponent.reach" value={mentionData.reachEstimate} />
            )}
            {PlatformMetrics && <PlatformMetrics mentionData={mentionData} />}
        </div>
    );
};

export default Metrics;

type MetricProps = {
    label: string;
    value: string | number;
    icon?: ReactNode;
    emptyLabel?: ReactNode;
    tooltip?: ReactNode;
    menu?: Parameters<typeof WithMenuAndTooltip>[0]['menu'];
    onMenuHide?: Parameters<typeof WithMenuAndTooltip>[0]['onMenuHide'];
    truncate?: boolean;
};

function Metric({
    icon,
    label,
    value,
    emptyLabel,
    tooltip,
    menu,
    onMenuHide,
    truncate,
}: MetricProps) {
    const classes = useStyles();

    //If no label supplied, there is no text.
    const content = useMemo(
        () =>
            label
                ? !emptyLabel || value //if an emptyLabel has been specified and the value is nothing, use the emptyLabel
                    ? T(label, {value: value})
                    : emptyLabel
                : null,
        [label, value, emptyLabel],
    );

    const isEmpty = content === emptyLabel;

    tooltip = tooltip === undefined ? content : tooltip; //if no tooltip specified, use standard content - supply null for no tooltip

    const wrapTooltip = useCallback<ConditionalWrapFunc>(
        (children) => {
            if (menu) {
                return (
                    <WithMenuAndTooltip
                        menu={menu}
                        onMenuHide={onMenuHide}
                        tooltip={tooltip}
                        tooltipNoAria
                    >
                        <MetricWithMenu isEmpty={isEmpty} truncate={truncate}>
                            {children}
                        </MetricWithMenu>
                    </WithMenuAndTooltip>
                );
            }

            //If no menu
            const childElement = (
                <span
                    className={cn(
                        classes.metric,
                        isEmpty && classes.metricEmpty,
                        truncate && classes.truncate,
                    )}
                >
                    {children}
                </span>
            );

            return tooltip ? (
                <WithMenuAndTooltip tooltip={tooltip} tooltipNoAria>
                    {childElement}
                </WithMenuAndTooltip>
            ) : (
                childElement
            );
        },
        [classes, isEmpty, menu, onMenuHide, tooltip, truncate],
    );

    return (
        <ConditionalWrap wrap={wrapTooltip}>
            {icon && <span className={classes.metricIcon}>{icon}</span>}
            {content && (
                <span
                    className={classes.metricContent}
                    dangerouslySetInnerHTML={{__html: content}}
                />
            )}
        </ConditionalWrap>
    );
}

function NumericalMetric({
    label,
    value,
    truncate = false,
}: {
    label: string;
    value?: number;
    truncate?: boolean;
}) {
    return (
        <Metric
            label={label}
            value={format(value ?? 0, 'compact')}
            tooltip={T(label, {
                value: format(value ?? 0),
            })}
            truncate={truncate}
        />
    );
}

type MetricWithMenuProps = PropsWithChildren<{
    isEmpty?: boolean;
    truncate?: boolean;
}>;

const MetricWithMenu = React.forwardRef<HTMLButtonElement, MetricWithMenuProps>(
    ({children, isEmpty, truncate}, ref) => {
        const classes = useStyles();

        return (
            <button
                ref={ref}
                type="button"
                className={cn(
                    classes.metric,
                    isEmpty && classes.metricEmpty,
                    classes.metricBtn,
                    truncate && classes.truncate,
                )}
            >
                {children}
            </button>
        );
    },
);

type SentimentProps = {
    mentionData: MentionDataType;
    mention: MentionType;
    editMentionStatus?: EditMentionType;
    classes: ReturnType<typeof useStyles>;
    editable?: boolean;
};

function Sentiment({
    mentionData,
    mention,
    classes,
    editMentionStatus,
    editable = false,
}: SentimentProps) {
    const tooltipClasses = useTooltipStyles();
    const isLoading = !!editMentionStatus?.loading; //An action is taking place for this mention (could be emotion, location etc)
    const isPending = !!(editMentionStatus?.loading && editMentionStatus?.sentiment); //sentiment is being updated or this mention
    const sentimentTxt = T(
        editable && isPending ? 'mentionComponent.sentimentPending' : 'mentionComponent.sentiment',
        {value: mentionData.sentiment},
    );

    if (editable && !isSavedSearchMention(mention)) {
        throw new Error('Only saved search mentions may be editable');
    }

    const icon = (
        <IconRouter
            name={`emoticon-${mentionData.sentiment}`}
            className={cn(
                classes.sentimentIcon,
                classes[mentionData.sentiment!],
                isPending && classes.hidden,
            )}
            aria-hidden
        />
    );

    return (
        <>
            <span className="offscreen">{sentimentTxt}</span>
            <SentimentMenu editable={editable} mention={mention as SavedSearchMentionType}>
                <Tooltip
                    getPositionElement={(elem: HTMLElement) => {
                        const selector = `.${classes.sentimentIcon}`;

                        if (elem.matches(selector)) {
                            return elem;
                        }

                        return elem.querySelector<HTMLElement>(selector);
                    }}
                    noAria
                    portal
                    tooltip={<div className={tooltipClasses.tooltipContent}>{sentimentTxt}</div>}
                >
                    {editable ? (
                        <button
                            onClick={stopPropagation}
                            className={classes.editableMetric}
                            disabled={isLoading}
                            onKeyDown={(e) => {
                                if (e.code === 'Enter' || e.code === 'Space') {
                                    e.stopPropagation();
                                }
                            }}
                        >
                            {isPending && <PulseLoader size="small" />}
                            <span className="offscreen">{T('mentionComponent.editSentiment')}</span>
                            {icon}
                            <IconRouter
                                aria-hidden
                                name="chevron-down"
                                className={classes.editableIcon}
                            />
                        </button>
                    ) : (
                        <>
                            {isPending && <PulseLoader size="small" />}
                            {icon}
                        </>
                    )}
                </Tooltip>
            </SentimentMenu>
        </>
    );
}

type PlatformMetrics = {
    mentionData: MentionDataType;
};

const TwitterMetrics = ({mentionData}: PlatformMetrics) => {
    return (
        <>
            <NumericalMetric
                label="mentionComponent.twitterRetweets"
                value={mentionData.twitterRetweets}
            />
            <Metric
                label="mentionComponent.twitterImpressions"
                value={format(mentionData.twitterImpressions ?? 0, 'compact')}
                tooltip={T('mentionComponent.twitterImpressionsTooltip', {
                    value: format(mentionData.twitterImpressions ?? 0),
                })}
                truncate
            />
        </>
    );
};

const FacebookMetrics = ({mentionData}: PlatformMetrics) => {
    return (
        <>
            <NumericalMetric
                label="mentionComponent.facebookComments"
                value={mentionData.facebookComments}
            />
            <NumericalMetric
                label="mentionComponent.facebookLikes"
                value={mentionData.facebookLikes}
            />
        </>
    );
};

const RedditMetrics = ({mentionData}: PlatformMetrics) => {
    return (
        <>
            <NumericalMetric
                label="mentionComponent.redditScore"
                value={mentionData.redditScore ?? undefined}
            />
        </>
    );
};

const InstagramMetrics = ({mentionData}: PlatformMetrics) => {
    return (
        <>
            <NumericalMetric
                label="mentionComponent.instagramInteractionsCount"
                value={mentionData.instagramInteractionsCount}
            />
        </>
    );
};
