import React from 'react';

import Paper, {PaperProps} from '@mui/material/Paper';

import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

const useStyles = makeStyles<Theme>(({colors, v2}) => ({
    root: {
        borderRadius: v2 ? 3 : 0,
        border: !v2
            ? `2px solid ${colors.secondaryPurple100}`
            : `1px solid ${colors.uiGrayPopupBorder}`,
        boxShadow: v2 ? `0px 4px 9px 0px #3F3F3F33` : 'none',
    },
}));

const MenuPaper = React.forwardRef<HTMLDivElement, PaperProps>(({children, ...rest}, ref) => {
    const {root} = useStyles();
    return (
        <Paper {...rest} ref={ref} classes={{root}}>
            {children}
        </Paper>
    );
});

export {MenuPaper};
