import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => ({
    taggedMentions: {
        background: theme.v2 ? theme.colors.uiGrayDarker : theme.colors.lightGrey60,
        flex: '0 0 438px',
        padding: '25px 8px',
        //minHeight: '100%',
        //position: 'sticky',
        //overflowY: 'auto',
        display: 'flex',
        flexFlow: 'column nowrap',
    },
    title: {
        fontSize: '12px',
        color: theme.colors.textDefault,
        fontWeight: theme.font.bold,
        textTransform: 'uppercase',
        paddingBottom: '16px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        padding: '0 16px 16px 16px',
    },
    list: {
        position: 'relative',
        flex: 'auto 1 1',
    },
    mentionsList: {
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
    },

    iconWrapper: {
        width: 44,
        height: 44,
        flex: '0 0 auto',
        verticalAlign: 'middle',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },

    icon: {
        fill: '#2f3538',
        height: theme.v2 ? '36px' : '56px',
        width: theme.v2 ? '36px' : '56px',
    },

    main: {
        fontSize: '24px',
        margin: '12px 0',
        fontWeight: theme.font.semiBold,

        flex: '0 0 100%',
        textAlign: 'center',
        color: theme.colors.textDefault,
    },

    secondary: {
        fontSize: '16px',

        flex: '0 0 100%',
        textAlign: 'center',
        color: theme.colors.textDefault,
    },
}));
