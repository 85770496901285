import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);
    const iconSize = v2 ? 12 : 10;

    const tickIconSvg = v2
        ? `<svg xmlns="http://www.w3.org/2000/svg" width="${iconSize}" height="${iconSize}" viewBox="0 0 16 16"><path d="m3.25 8.25 2.75 2.75 6.75-6.75" fill="none" stroke="#FFF" stroke-width="2.1"></path></svg>`
        : `<svg xmlns="http://www.w3.org/2000/svg" width="${iconSize}" height="${iconSize}" viewBox="0 0 20 20" fill="${theme.colors.primaryBlue100}"><polygon points="7 12 3 8 0 11 7 18 20 5 17 2"></polygon></svg>`;
    const tickIconSvgEncoded = btoa(tickIconSvg);

    const indeterminateSvg = v2 
        ? `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><rect x="3" y="7" fill="#FFF" width="10" height="2"></rect></svg>`
        : `<svg xmlns="http://www.w3.org/2000/svg" width="${iconSize}" height="${iconSize}" viewBox="0 0 16 16"><rect y="6" fill="#111111" width="16" height="4"></rect></svg>`;
    const indeterminateSvgEncoded = btoa(indeterminateSvg);

    return {
        input: {
            //the 'real' input element is not visible
            ...theme.mixin.offscreen
        },

        label: {
            '--checkbox-size': '16px',
            '--checkbox-gap': '10px',
            cursor: 'pointer',
            position: 'relative',
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
            gap: 'var(--checkbox-gap)',
            
        },

        checkboxProxy: {
            display: 'block',
            flex: '0 0 auto',
            width: 'var(--checkbox-size)',
            height: 'var(--checkbox-size)',
            boxSizing: 'border-box',
            border: v2 ? `1px solid ${theme.colors.borders}` : `1px solid transparent`,
            borderRadius: v2 ? '2px' : undefined,
            boxShadow: v2 ? undefined : `inset 0 0 0 1px #5D6366`,
            background: '#FFF',
            cursor: 'inherit',

            '&:not($input[aria-disabled] + $label > &)': {
                cursor: 'pointer',
            },

            //Checked styles
            '$input:checked + $label > &': {
                boxShadow: v2 ? undefined : `inset 0 0 0 1px ${theme.colors.primaryBlue100}`,
                borderColor: v2 ? undefined : theme.colors.primaryBlue100,

                background: v2
                    ? `${theme.colors.accentActive} url("data:image/svg+xml;base64,${tickIconSvgEncoded}") no-repeat center`
                    : `#FFF url("data:image/svg+xml;base64,${tickIconSvgEncoded}") no-repeat center`,
            },

            '$input:indeterminate + $label > &': {
                background: v2
                    ? `${theme.colors.accentActive} url("data:image/svg+xml;base64,${indeterminateSvgEncoded}") no-repeat center`
                    : `#FFF url("data:image/svg+xml;base64,${indeterminateSvgEncoded}") no-repeat center`,
            },

            //Hover styles
            '$label:hover > &': {
                boxShadow: v2 ? undefined : `inset 0 0 0 1px ${theme.colors.primaryBlue100}`,
                borderColor: v2 ? theme.colors.accentDefault : undefined,
            },

            //Focus styles
            '$input:focus-visible + $label > &': {
                //Focus styles
                outline: `3px solid ${
                    v2 ? theme.colors.secondaryPurple100 : theme.colors.secondaryOrange100
                }`,
                outlineOffset: v2 ? undefined : '-1px',
            },
        },

        labelContent: {
            flex: '1 1 auto',
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
        },
    };
});
