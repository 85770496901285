import {useMemo} from 'react';

import Banner from 'hsi/components/Banner';
import Markdown from 'hsi/components/Markdown';

import useAuthentications from 'hsi/hooks/useAuthentications';
import useHideForXSessions from 'hsi/hooks/useHideForXSessions';
import useStyles from './styles';
import useConfig from 'hsi/hooks/useConfig';

import {RECONNECTION_BANNER_HIDE_NUM_SESSIONS as HIDE_NUM_SESSIONS} from 'hsi/constants/config';
import {T} from 'hsi/i18n';

const TargetedDataBanner = () => {
    const {authentications, loaded, error} = useAuthentications(true);
    const classes = useStyles();
    const config = useConfig();

    const {show, onHide} = useHideForXSessions('fbReconnectBannerHide', HIDE_NUM_SESSIONS);

    const hasAuthentications = authentications.length;
    const hasInactiveAuthentications = authentications.filter(
        ({authenticated}: any) => !authenticated,
    ).length;

    const message = useMemo(() => {
        if (!hasAuthentications) return 'noTokens';
        if (hasInactiveAuthentications) return 'inactiveTokens';
    }, [hasAuthentications, hasInactiveAuthentications]);

    return (
        <Banner
            animate
            className={classes.banner}
            hidable
            onHide={onHide}
            show={!error && loaded && !!message && show}
            variant="warning"
        >
            <Markdown className={classes.message} data-testid="targetedDataBannerText">
                {message
                    ? T(`authTokens.banner.${message}`, {
                          settingsLink: config.dataManagement.rootUrl,
                      })
                    : ''}
            </Markdown>
        </Banner>
    );
};

export default TargetedDataBanner;
