import React from 'react';

//Components
import Button, {ButtonProps} from '..';

//Other
import useStyles from './styles';

interface LabelledButtonProps extends ButtonProps {
    label: React.ReactNode;
    a11yLabel?: string;
    labelClassName?: string;
    hiddenLabel?: string;
}

//The component
const LabelledButton = React.forwardRef(
    (props: LabelledButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
        const {
            label,
            labelClassName,
            className,
            fullWidth,
            startIcon,
            endIcon,
            hiddenLabel,
            a11yLabel,
            ...rest
        } = props;
        const {classes, cx} = useStyles();

        return (
            <div className={cx(classes.wrapper, fullWidth && classes.fullWidth, className)}>
                {a11yLabel && <span className="offscreen">{a11yLabel}</span>}
                <span aria-hidden={a11yLabel ? true : undefined} className={cx('MuiButton-label', classes.label, labelClassName)}>
                    {startIcon && (
                        <span className={classes.startIcon}>
                            {startIcon}
                        </span>
                    )}
                    {label}
                    {endIcon && <span className={classes.endIcon}>{endIcon}</span>}
                </span>
                <Button ref={ref} fullWidth={fullWidth} className={cx(classes.btn)} {...rest}>
                    {/* label removed from MUI: https://mui.com/material-ui/migration/v5-component-changes/#remove-span-and-label */}
                    <span className={classes.hidden}>{hiddenLabel}</span>
                </Button>
                
            </div>
        );
    },
);

export default LabelledButton;
