import {useMemo} from 'react';

import useConfig from 'hsi/hooks/useConfig';
import useQueryContext from 'hsi/hooks/useQueryContext';
import {useAppSelector} from './useRedux';
import {RootState} from 'hsi/utils/configureStore';
import {TimezoneID} from 'hsi/utils/timezones';

const selectDefaultProject = (state: RootState): TimezoneID | undefined =>
    state.user?.defaultProject?.timezone;

function useTimezoneStandard(projectId?: number) {
    const {defaultTimezone} = useConfig();
    const defaultProjectTimezone = useAppSelector(selectDefaultProject);
    const queryTimezone = useQueryContext()?.timezone as TimezoneID | undefined;
    const projects = useAppSelector((state) => state.user.projects);
    const filters = useAppSelector((state) => state.filters);

    const projectTimezone: TimezoneID | undefined = useMemo(
        () => projects?.find(({id}: {id: number}) => id === projectId)?.timezone,
        [projectId, projects],
    );

    const timezone = useMemo(() => {
        if (projectTimezone) {
            return projectTimezone;
        }

        if (filters?.dateRange?.timezone) {
            return filters.dateRange.timezone as TimezoneID;
        }

        if (queryTimezone) {
            return queryTimezone;
        }

        if (defaultProjectTimezone) {
            return defaultProjectTimezone;
        }

        return defaultTimezone;
    }, [
        defaultProjectTimezone,
        defaultTimezone,
        filters?.dateRange?.timezone,
        queryTimezone,
        projectTimezone,
    ]);

    return timezone;
}

//TODO this needs to change to be driven by a context
export default function useTimezone(projectId?: number) {
    const timezone = useTimezoneStandard(projectId);

    const exportTimezone: TimezoneID | undefined = (window as any)?.desData?.timezone;

    return exportTimezone || timezone;
}
