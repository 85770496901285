import makeStyles from '@mui/styles/makeStyles';
import { Theme } from 'hsi/types/theme';

export default makeStyles<Theme>(({v2, colors, fonts, font}) => {
    return {
        uniqueAuthorsCTAWrapper: {
            display: 'flex',
            alignItems: 'flex-start',
        },

        content: {
            flex: '1 1 auto'
        },

        uniqueAuthorsCTA: {
            flex: '0 0 200px',
        },
        mockRowItem: {
            flex: '1 1 auto',
            '--mockRow-height': '8px',
            display: 'flex',
            gap: '12px',
            height: 'var(--mockRow-height)',
            alignItems: 'stretch',

            '&::before, &::after': {
                content: '""',
                background: v2 ? colors.uiGrayDark : colors.lightGrey60,
                borderRadius: 'calc(var(--mockRow-height) / 2)',
            },

            '&:before': {
                flex: '0 0 20px',
            },

            '&:after': {
                flex: '1 1 auto',
            },
            
        },

        msg: {
            background: v2 ? colors.uiGrayDark : colors.lightGrey60,

            padding: '12px',
            borderRadius: '4px',
            margin: '4px',

            ...v2 
                ? fonts.bodySmall 
                : {fontSize: '12px', fontWeight: font.semiBold,},
        },
    };
});
