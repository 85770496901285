import {useCallback, useMemo} from 'react';
import {useLocation} from 'react-router-dom';

import useFlags from 'hsi/hooks/useFlags';
import {useAppSelector} from 'hsi/hooks/useRedux';
import useTrackingLocation from 'hsi/hooks/useTrackingLocation';

import mixpanel from 'hsi/utils/mixpanel';
import trackingNames, {TrackingNames} from 'hsi/utils/trackingNames';
import {
    selectAdditionalQueries,
    selectLinkedinChannelIds,
    selectSavedSearchId,
    selectSearchType,
    selectIsEditSearch,
    selectIsMultipleSearch,
    selectIsGuidedSearch,
} from 'hsi/selectors';

const useEventTrack = () => {
    const flags = useFlags();
    const location = useLocation();
    const trackingLocation = useTrackingLocation();

    // Needs to be this way so that the new streams app doesn't complain
    const additionalQueries = useAppSelector(selectAdditionalQueries);
    const linkedinChannelIds = useAppSelector(selectLinkedinChannelIds);
    const isEditSearch = useAppSelector(selectIsEditSearch);
    const isGuidedSearch = useAppSelector(selectIsGuidedSearch);
    const isMultipleSearch = useAppSelector(selectIsMultipleSearch);
    const savedSearchId = useAppSelector(selectSavedSearchId);
    const searchType = useAppSelector(selectSearchType);

    // Log out mixpanel values
    const debug = useCallback(
        (...args: any[]) => {
            if (flags?.debugMixpanel || process.env.REACT_APP_MIXPANEL_DEBUG === 'true') {
                const argsArray = [].slice.apply(args);
                console.log(...argsArray);
            }
        },
        [flags?.debugMixpanel],
    );

    // Base tracking function, all other should inherit from this
    const track = useCallback(
        (key: TrackingNames, value?: Record<string, any>) => {
            // We only warn of missing keys but should error in future
            if (!trackingNames[key] && typeof trackingNames[key] !== 'function') {
                console.log(`tracking key not defined: ${key}`);
            }

            const getName = () => {
                if (!trackingNames?.[key]) {
                    return key;
                } else {
                    return trackingNames[key](trackingLocation || 'unknown');
                }
            };
            const name = getName();

            if (value) {
                debug(name, value);
                mixpanel.track(name, value);
            } else {
                debug(name);
                mixpanel.track(name);
            }
        },
        [debug, trackingLocation],
    );

    // Plug in other bits of data or utilities to create re-usable standard
    // tracking payloads
    const searchData = useMemo(
        () => ({
            additionalQueries,
            linkedinChannelIds,
            isEditSearch,
            isGuidedSearch,
            isMultipleSearch,
            searchId: savedSearchId,
            searchType,
        }),
        [
            additionalQueries,
            linkedinChannelIds,
            isEditSearch,
            isGuidedSearch,
            isMultipleSearch,
            savedSearchId,
            searchType,
        ],
    );

    const pathname = useMemo(() => location.pathname, [location.pathname]);

    // This function bundles in search data which is used in multiple locations. It
    // MUST be used in the context of searches otherwise you'll get incorrect results
    // (queryContext uses the url params to figure out what search you're looking at).
    const trackWithSearchData = useCallback(
        (name: Parameters<typeof track>[0], value: any) => {
            track(name, {...searchData, ...value});
        },
        [searchData, track],
    );

    // This hook adds in the page path that was used when tracking is triggered
    const trackWithPathname = useCallback(
        (name: Parameters<typeof track>[0], value: any) => {
            track(name, {pathname, ...value});
        },
        [pathname, track],
    );

    return {
        trackingLocation,
        trackingNames,
        track,
        trackWithPathname,
        trackWithSearchData,
    };
};

export default useEventTrack;
