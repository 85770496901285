//TODO not a tooltip, rename as such

import React from 'react';

//Components
import Tooltip from 'hsi/components/Tooltip';
import TooltipMsg from 'hsi/components/SaveSearchTooltipDialog/TooltipMsg';

//The component
const SaveSearchTooltipDialog = ({children, distance, messageId, placement, ...rest}) => {
    return (
        <Tooltip
            tooltip={<TooltipMsg messageId={messageId} />}
            distance={distance || 5}
            placement={placement || 'bottom-start'}
            theme={'light'}
            dialog
            morePadding
            {...rest}
        >
            {children}
        </Tooltip>
    );
};

export default SaveSearchTooltipDialog;
