import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>(({v2, colors}) => ({
    paginationButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: v2 ? '12px 12px 0' : '12px',
    },
    btn: {
        backgroundColor: v2 ? undefined : colors.primaryBlue20,
        border: v2 ? `1px solid ${colors.uiGrayDarker}` : undefined,
        padding: v2 ? '4px' : undefined,

        '&:disabled': {
            backgroundColor: v2 ? colors.bgDisabled : colors.lightGrey40,
            border: v2 ? '0' : undefined,
        },

        '@media print': {
            display: 'none',
        },
        '.printMedia &': {
            display: 'none',
        },
    },
    icon: {
        //Ugly workaround to deal with specificity issue due to poorly written CSS
        '&&': {
            fill: v2 ? colors.icons : colors.primaryBlue100,
        },

        ':disabled > &': {
            fill: v2 ? colors.textDisabled : undefined,
        },
    },
    pageIndicator: {
        margin: '0 12px',
    },
}));
