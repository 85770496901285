import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    if (theme.v2) {
        return {
            container: {
                width: '100%',
                height: '100%',
            },
            loading: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            },
            recentlyViewed: {
                width: '250px',
                minHeight: '100vh',
                backgroundColor: theme.colors.uiGrayDefault,

                '& h3': {
                    padding: '40px 0 0 25px',
                    fontWeight: theme.typgrphy.headtitleStrong.fontWeight,
                    fontSize: theme.typgrphy.headtitleStrong.fontSize,
                    color: theme.colors.textDefault,
                    textTransform: 'uppercase',
                    margin: '0 0 28px',
                },
            },

            recentSearches: {
                listStyleType: 'none',
                margin: 0,
                padding: 0,
                display: 'flex',
                justifyContent: 'center',
                flexFlow: 'row wrap',
                alignItems: 'center',

                '& li': {
                    flex: '0 0 202px',
                    overflow: 'hidden',
                    '& a': {
                        color: theme.colors.textDefault,
                        textDecoration: 'none',
                        fontSize: theme.typgrphy.bodyNormal.fontSize,
                        fontWeight: theme.typgrphy.bodyNormal.fontWeight,
                        padding: '8px 24px 9px 24px',
                        margin: '0 0 10px 0',
                        cursor: 'pointer',
                        backgroundColor: theme.colors.uiGrayDefault,
                        borderRadius: '100px',
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: 'block',
                        '&span': {
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: 'block',
                        },
                        '&:hover': {
                            backgroundColor: theme.colors.textHyperlink,
                        },
                    },
                },
            },
            savedSearchesRoot: {
                padding: '0',
                backgroundColor: theme.colors.uiGrayDark,

                minWidth: '1032px',
                minHeight: '100vh',
                '& .tableWrapper': {
                    margin: '20px',
                    minWidth: '1080px',
                },
            },

            savedSearchesWrapper: {
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: theme.colors.uiGrayDefault,
                height: '75px',
                padding: '0 20px',
                alignItems: 'center',
            },

            savedSearchesTitle: {
                margin: '0',
                display: 'flex',
                flex: '0 0 auto',
                alignItems: 'center',

                '& h1': {
                    lineHeight: '20px',
                    fontWeight: theme.typgrphy.headtitleStrong.fontWeight,
                    fontSize: theme.typgrphy.headtitleStrong.fontSize,
                    color: theme.colors.textDefault,
                    marginRight: '12px',
                    margin: '0',
                    flex: '0 0 auto',
                    textTransform: 'capitalize',
                },
            },

            newQuickSearch: {
                flex: '0 0 auto',
            },

            banner: {
                marginTop: '-19px',
            },

            popup: {
                padding: '16px',
                display: 'flex',
                textAlign: 'left',
            },

            popupTextContainer: {
                flex: '1 1 auto',
            },

            popupTitle: {
                fontSize: theme.typgrphy.bodyNormal.fontSize,
                fontWeight: theme.typgrphy.bodyNormal.fontWeight,
                marginBottom: '8px',
            },

            popupContent: {
                fontSize: '11px',
                maxWidth: '208px',
            },

            confirmDelete: {
                '& .cannot-undo': {
                    fontWeight: theme.font.bold,
                },
                '& .information': {
                    wordBreak: 'break-word',
                },
                '& .warning': {
                    display: 'inline-flex',
                    backgroundColor: theme.colors.warningBg,
                    padding: '12px',
                    marginRight: '5px',
                    marginLeft: '5px',
                    fontSize: '14px',
                    color: theme.colors.textDefault,
                    maxWidth: 'none !important',
                    '& .warning-icon-container': {
                        display: 'inline-flex',
                        backgroundColor: theme.colors.warningAccent,
                        height: '36px',
                        width: '36px',
                        minWidth: '36px',
                        borderRadius: '18px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '12px',

                        '& .icon-warning': {
                            fill: theme.colors.warning,
                            flex: '0 0 20px',
                            height: '20px',
                            width: '20px',
                            position: 'relative',
                            top: -'1px',
                        },
                    },
                },
            },
        };
    } else {
        return {
            container: {
                width: '100%',
                height: '100%',
            },
            loading: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            },
            recentlyViewed: {
                width: '250px',
                minHeight: '100vh',
                backgroundColor: theme.colors.bg300,

                '& h3': {
                    padding: '40px 0 0 25px',
                    fontWeight: theme.font.bold,
                    fontSize: '12px',
                    color: theme.colors.textDefault,
                    textTransform: 'uppercase',
                    margin: '0 0 28px',
                },
            },

            recentSearches: {
                listStyleType: 'none',
                margin: 0,
                padding: 0,
                display: 'flex',
                justifyContent: 'center',
                flexFlow: 'row wrap',
                alignItems: 'center',

                '& li': {
                    flex: '0 0 202px',
                    overflow: 'hidden',
                    '& a': {
                        color: theme.colors.textDefault,
                        textDecoration: 'none',
                        fontSize: '14px',
                        fontWeight: theme.font.semiBold,
                        padding: '8px 24px 9px 24px',
                        margin: '0 0 10px 0',
                        cursor: 'pointer',
                        backgroundColor: theme.colors.bg400,
                        borderRadius: '100px',
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: 'block',
                        '&span': {
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: 'block',
                        },
                        '&:hover': {
                            backgroundColor: theme.colors.keyline,
                        },
                    },
                },
            },
            savedSearchesRoot: {
                padding: '0',
                backgroundColor: theme.colors.lightGrey100,

                minWidth: '1032px',
                minHeight: '100vh',
                '& .tableWrapper': {
                    margin: '20px',
                    minWidth: '1080px',
                },
            },

            savedSearchesWrapper: {
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: theme.colors.lightGrey20,
                height: '75px',
                padding: '0 20px',
                alignItems: 'center',
            },

            savedSearchesTitle: {
                margin: '0',
                display: 'flex',
                flex: '0 0 auto',
                alignItems: 'center',

                '& h1': {
                    lineHeight: '20px',
                    fontWeight: theme.font.bold,
                    color: theme.colors.darkGrey100,
                    marginRight: '20px',
                    borderRight: `1px solid ${theme.colors.darkGrey60}`,
                    fontSize: '24px',
                    margin: '0',
                    flex: '0 0 auto',
                    paddingRight: '20px',
                },
            },

            newQuickSearch: {
                flex: '0 0 auto',
            },

            banner: {
                marginTop: '-19px',
            },

            popup: {
                padding: '16px',
                display: 'flex',
                textAlign: 'left',
            },

            popupTextContainer: {
                flex: '1 1 auto',
            },

            popupTitle: {
                fontSize: '14px',
                fontWeight: theme.font.bold,
                marginBottom: '8px',
            },

            popupContent: {
                fontSize: '11px',
                maxWidth: '208px',
            },

            confirmDelete: {
                '& .cannot-undo': {
                    fontWeight: theme.font.bold,
                },
                '& .information': {
                    wordBreak: 'break-word',
                },
                '& .warning': {
                    display: 'inline-flex',
                    backgroundColor: theme.colors.warningBg,
                    padding: '12px',
                    marginRight: '5px',
                    marginLeft: '5px',
                    fontSize: '14px',
                    color: theme.colors.textDefault,
                    maxWidth: 'none !important',
                    '& .warning-icon-container': {
                        display: 'inline-flex',
                        backgroundColor: theme.colors.warningAccent,
                        height: '36px',
                        width: '36px',
                        minWidth: '36px',
                        borderRadius: '18px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '12px',

                        '& .icon-warning': {
                            fill: theme.colors.warning,
                            flex: '0 0 20px',
                            height: '20px',
                            width: '20px',
                            position: 'relative',
                            top: -'1px',
                        },
                    },
                },
            },
        };
    }
});

export default useStyles;
