import {MouseEvent, ReactNode, forwardRef, useCallback} from 'react';
import { LocationDescriptor } from 'history';
import {useDispatch} from 'react-redux';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import {NavLink} from 'react-router-dom';
import {push} from 'redux-first-history';

//Components
import IconRouter from 'hsi/components/IconRouter';
import Tooltip from 'hsi/components/Tooltip';
import ConditionalWrap from 'hsi/components/ConditionalWrap';

//Other
import useStyles from './styles';


//Types
type NavBarProps = {
    icon: string;
    label: string;
    hideTextLabel?: boolean;
    to: LocationDescriptor;
    tooltip?: ReactNode | ((props: {tooltipId?: string}) => ReactNode);
    tooltipNoAria?: boolean;
    active?: boolean;
    right?: boolean;
} & JSX.IntrinsicElements['a'];

//The component
const NavButton = forwardRef<HTMLAnchorElement, NavBarProps>(function NavButton(
    {label, className, icon, to, onClick: _onClick, tooltip, active, tooltipNoAria, hideTextLabel = false, right = false, ...rest},
    ref,
) {
    const {cx, classes} = useStyles();
    const dispatch = useDispatch();

    const wrapFunc = useCallback(
        (children: ReactNode) =>
            tooltip ? (
                <Tooltip distance={7} tooltip={tooltip} noAria={tooltipNoAria}>
                    {children}
                </Tooltip>
            ) : (
                children
            ),
        [tooltip, tooltipNoAria],
    );

    const onClick = useCallback(
        (e: MouseEvent<HTMLAnchorElement>) => {
            _onClick?.(e);
            dispatch(push(to));

            e.preventDefault();
        },
        [dispatch, to, _onClick],
    );

    return (
        <ConditionalWrap wrap={wrapFunc}>
            <NavLink
                component={IconButtonWithoutNavigate}
                aria-label={label}
                className={cx(classes.navButton, right && classes.right, className)}
                to={to}
                ref={ref as any}
                onClick={onClick}
                activeClassName="active"
                {...rest}
            >
                <IconRouter className={classes.icon} name={icon} aria-hidden />
                {!hideTextLabel && <span className={cx(classes.label)} aria-hidden>{label}</span>}
            </NavLink>
        </ConditionalWrap>
    );
});

export default NavButton;

const IconButtonWithoutNavigate = forwardRef<HTMLButtonElement, {navigate: any} & IconButtonProps>(function IconButtonWithoutNavigate(
    {navigate, ...rest},
    ref,
) {
    return <IconButton {...rest} ref={ref} size="large" />;
});
