import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles(({v2, fonts, font, colors}: Theme) => ({
    table: {
        '--table-row-minHeight': '40px',
        '--table-row-height': 'minmax(var(--table-row-minHeight), auto)',
        '--table-gap': '0 4px',
        display: 'grid',
        gridAutoRows: 'var(--table-row-height)',
        gridAutoFlow: 'row',
        gridTemplateColumns: 'var(--table-columns)',
        gap: 'var(--table-gap)',
    },
    head: {
        display: 'grid',
        gridTemplateColumns: 'subgrid',
        gridAutoFlow: 'column',
        gridColumn: '1 / -1',
        gridAutoRows: 'inherit',
    },
    body: {
        display: 'grid',
        gridTemplateColumns: 'subgrid',
        gridAutoFlow: 'column',
        gridColumn: '1 / -1',
        gridAutoRows: 'inherit',
    },
    foot: {
        display: 'grid',
        gridTemplateColumns: 'subgrid',
        gridAutoFlow: 'column',
        gridColumn: '1 / -1',
        gridAutoRows: 'inherit',
    },
    row: {
        display: 'grid',
        gridTemplateColumns: 'subgrid',
        gridAutoFlow: 'column',
        gridColumn: '1 / -1',

        borderBottom: `1px dotted ${v2 ? colors.borders : colors.lightGrey40}`,

        '&$active, &$selectable:hover, &$selectable:has(:focus-visible)': {
            background: v2 ? colors.bgHover : colors.lightGrey20,
            backgroundClip: 'padding-box',
        },

        '$head > &': {
            borderBottomStyle: 'solid',
        }
    },
    th: {
        ...v2 ? fonts.bodyStrong : {fontWeight: font.bold},
        padding: 0,
        display: 'flex',
        minWidth: 0,
        alignItems: 'center',
        justifyContent: 'var(--table-horizontal-align, center)',

        '&$sort-asc, &$sort-desc': {

        },

        '&$allowContentOverflow': {
            minWidth: 0,
        },
    },
    td: {
        '--table-cell-columns': 1,

        ...v2 ? fonts.bodyLargeNormal : {},
        padding: 0,
        display: 'flex',
        minWidth: 0,
        alignItems: 'center',
        justifyContent: 'var(--table-horizontal-align, center)',

        gridColumn: 'span var(--table-cell-columns, 1)',

        '&$sort-asc, &$sort-desc': {
            ...v2 ? fonts.bodyLargeStrong : {fontWeight: font.bold},
        },

        '&$loading': {
            opacity: 0.5,
        },

        '&$allowContentOverflow': {
            minWidth: 0,
        },
    },
    caption: {},

    'sort-asc': {

    },
    'sort-desc': {

    },

    'horizontalAlign-start': {
        '--table-horizontal-align': 'start',
    },
    'horizontalAlign-center': {
        '--table-horizontal-align': 'center',
    },
    'horizontalAlign-end': {
        '--table-horizontal-align': 'end',
    },

    active: {},
    selectable: {cursor: 'pointer'},
    loading: {},
    allowContentOverflow: {},
}));