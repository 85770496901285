import { useCallback, useEffect, useMemo, useRef } from "react";

//Components
import { ColumnDefinition, SortColumnDefinition, SortColumnsDefinition } from "hsi/components/table/ManagedTable/types";
import useManagedPagination from "hsi/components/table/ManagedTable/useManagedPagination";
import { TableSortDir } from "hsi/components/table/types";

//Hooks
import useEventTrack from "hsi/hooks/useEventTrack";
import useQueryContext from "hsi/hooks/useQueryContext";
import useQueryUserData, { useGetQueryUserDataSetter } from "hsi/hooks/useQueryUserData";

//Other
import { CardType } from "hsi/types/cards";


type UseCardTableTrackingAndPersistenceOptions<Columns> = {
    defaultSortColumn?: Columns,

    //pagination
    totalRows?: number,
    /** value of zero = cannot be paginated */
    rowsPerPage?: number,

    /**
     * 
     * @param newColumn undefined = no sorting
     * @param newDirection undefined = no sorting
     * @param oldColumn undefined = no sorting, null = first load
     * @param oldDirection undefined = no sorting, null = first load
     * @returns 
     */
    onSortingChanged?: (newColumn: Columns | undefined, newDirection: TableSortDir | undefined, oldColumn: Columns | null | undefined, oldDirection: TableSortDir | null | undefined) => void
};

type StateRef<Columns> = {
    firstRender: boolean;
    lastSortColumn: Columns | null | undefined;
    lastSortDirection: TableSortDir | null | undefined;
};

export default function useCardTableTrackingAndPersistence<RowItemType, Columns extends string | number | symbol = keyof RowItemType>(
    type: CardType,
    columns: (ColumnDefinition<Columns> & SortColumnDefinition<RowItemType, Columns>)[] | Readonly<(ColumnDefinition<Columns> & SortColumnDefinition<RowItemType, Columns>)[]>,
    {defaultSortColumn, totalRows = 0, rowsPerPage = 10, onSortingChanged}: UseCardTableTrackingAndPersistenceOptions<Columns>
) {
    const stateRef = useRef<StateRef<Columns>>({firstRender: true, lastSortColumn: null, lastSortDirection: null});
    const {savedSearchId} = useQueryContext();
    const setUserQueryData = useGetQueryUserDataSetter(savedSearchId ?? null);
    const allCardTablesSort = useQueryUserData(savedSearchId, 'cardTableSort');
    const cardTableSort = allCardTablesSort?.[type] ?? null;

    // Get the current sort order + direction (if any)
    const {sortColumn, sortDirection} = useMemo(
        () => getSort<RowItemType, Columns>(columns, defaultSortColumn, cardTableSort), 
        [cardTableSort, columns, defaultSortColumn]
    );

    const {trackWithSearchData} = useEventTrack();

    // Sorting
    const onSortClick = useCallback((column: Columns) => {
        setUserQueryData?.('cardTableSort', (cardTableSort) => {
            return {
                ...cardTableSort,
                [type]: getNewSort(column, sortColumn, cardTableSort?.[type]?.sortDirection, columns)
            }
        })
    }, [columns, setUserQueryData, sortColumn, type]);

    useEffect(() => {
        onSortingChanged?.(sortColumn, sortDirection, stateRef.current.lastSortColumn, stateRef.current.lastSortDirection);

        stateRef.current.lastSortColumn = sortColumn;
        stateRef.current.lastSortDirection = sortDirection;

        if(stateRef.current.firstRender) {
            //Do not track sorting being applied on first render
            stateRef.current.firstRender = false;
            return;
        }

        setPage(1);// If sort column/direction changes, reset to page 1

        // Track when sort is changed
        trackWithSearchData('cardTableSorted', {
            sortDir: sortDirection,
            sortField: sortColumn,
            type,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortColumn, sortDirection]);

    // Pagination
    const {page, pages, setPage} = useManagedPagination(totalRows, rowsPerPage);

    // Output
    return useMemo(() => ({
        page,
        pages,
        setPage,
        sortColumn,
        sortDirection,
        onSortClick,
    }), [onSortClick, page, pages, setPage, sortColumn, sortDirection])
}

/**
 * Returns the sorting column and direction this table should used, based on the persisted value, the default value
 * and the column definitions
 * 
 * @param columns The columns difinition
 * @param defaultSortColumn If no persisted sort column, this column will be used
 * @param persistedSort 
 * @param legacySorting 
 * @returns the sort column and direction this table should use
 */
function getSort<RowItemType, Column extends string | number | symbol = keyof RowItemType>(
    columns: SortColumnsDefinition<RowItemType, Column>, 
    defaultSortColumn: Column | undefined,
    persistedSort: {sortColumn?: string | null, sortDirection?: TableSortDir | null} | null,
): { sortColumn: Column | undefined; sortDirection: TableSortDir | undefined; } {
    if(!columns || columns.length === 0) {
        throw new Error('Argument Error: getDefaultSort, columns argument must be an array with at least 1 valid column definition');
    }

    //Decide on the column to use
    const column: Column | undefined = isValidColumn(columns, persistedSort?.sortColumn) 
        ? persistedSort!.sortColumn! as Column // if persisted value is set & is a valid column, use that
        : isValidColumn(columns, defaultSortColumn) 
            ? defaultSortColumn as Column // otherwise use the default column if that is set & valid
            : undefined; //finally, no column, so no sorting
    
    return {
        sortColumn: column, 
        sortDirection: column === undefined 
            ? undefined // if no column, so direction
            : column === persistedSort?.sortColumn && isValidSortDir(persistedSort?.sortDirection)
                ? persistedSort?.sortDirection as TableSortDir //If persisted direction is set & valid, use that
                : getColumnDefaultSortDir(columns, column) ?? 'asc' //otherwise pick the default sort direction from the columns definition, or default to 'asc'
    }
}

function getColumnDefaultSortDir<Column>(columns: SortColumnsDefinition<any, any>, column: any): TableSortDir | null {
    if(isValidColumn<Column>(columns, column)) {
        return columns.find(({name}) => name === column)?.defaultSortDir ?? 'asc' ;
    }

    return null;
}

function isValidColumn<Column>(columns: SortColumnsDefinition<any, any>, column: any): column is Column {
    if(!columns || columns.length === 0) {
        return false;
    }

    if(column === null || column === undefined) {
        return false;
    }

    return columns.some((curColumn) => curColumn?.name === column);
}

function isValidSortDir(sortDirection: any): sortDirection is TableSortDir {
    return sortDirection === 'asc' || sortDirection === 'desc';
}

export function getNewSort<Column>(column: Column, curColumn: Column | undefined, curSortDir: TableSortDir | undefined, columns: SortColumnsDefinition<any, any>) {
    const definition = column ? columns.find(({name}) => name === column) : undefined;
    const defaultSortDir = definition?.defaultSortDir || "asc";

    return  column === curColumn 
        ? {
            sortColumn: column,
            sortDirection: curSortDir
                ? curSortDir === 'asc' ? 'desc' : 'asc'// < Can force the objects to non-null with ! because we know they implicitly can't be null thanks to the condition of the ternary operator
                : defaultSortDir === 'asc' ? 'desc' : 'asc'
        }
        : {
            sortColumn: column,
            sortDirection: defaultSortDir
        }
}