import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({colors, mixin, v2}: any) => ({
    container: {},
    label: {
        color: colors.textDefault,
        fontWeight: 'bold',
        marginBottom: 12,
    },
    searchWrapper: {
        paddingTop: 3,
        marginBottom: 17,
    },
    search: {
        '& .Mui-focused': {
            outline: v2
                ? `3px solid ${colors.accentActive}`
                : `3px solid ${colors.secondaryOrange100}`,
        },
    },
    selAllItem: {
        padding: '12px 12px 12px 13px',
        borderBottom: v2 ? `1px solid ${colors.accentDefault}` : `1px solid ${colors.keyline}`,
    },
    optionList: {
        border: v2 ? `1px solid ${colors.borders}` : `1px solid ${colors.darkGrey60}`,
        maxHeight: 244,
        overflowY: 'auto',
        margin: 0,
        padding: 0,
    },
    listItem: {
        padding: '12px',
        '&:hover': {
            backgroundColor: v2 ? colors.bgHover : colors.primaryYellow20,
        },
    },
    noResults: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 240,
    },
    noResultsIcon: {
        fill: colors.darkGrey60,
        margin: 8,
        ...mixin.icon.massiveSize,
    },
    noResultsTitle: {
        fontSize: 20,
        fontWeight: 700,
        margin: 8,
    },
    noResultsDesc: {
        fontSize: 16,
        fontWeight: 400,
    },
    cta: {
        border: v2 ? `1px solid ${colors.borders}` : `1px solid ${colors.darkGrey60}`,
        borderRadius: 0,
        color: colors.secondaryBlue100,
        marginBottom: 2,
        padding: '0px 12px',
        position: 'relative',
        top: '-1px',
        '&.MuiButtonBase-root': {
            justifyContent: 'flex-start',
        },
    },
    ctaIcon: {
        fill: colors.secondaryBlue100,
        marginRight: 8,
    },
}));
