import {makeStyles} from 'tss-react/mui';

export default makeStyles()((theme, _params, classes) => ({
    bar: {
        display: 'flex',
        borderRadius: 3,
        height: 31,
        overflow: 'hidden',
        marginBottom: 13,
    },
    section: {
        [`&.${classes.male}`]: {
            background: theme.colors.male,
        },
        [`&.${classes.female}`]: {
            background: theme.colors.female,
        },
    },
    male: {},
    female: {},

    values: {
        display: 'flex',
    },
    info: {
        flex: '1 1 50%',

        '&:last-child': {
            textAlign: 'right',
        },

        [`&.${classes.male}`]: {
            color: theme.colors.male,
        },
        [`&.${classes.female}`]: {
            color: theme.colors.female,
        },
    },
    value: {
        ...theme.fonts.display2Strong,
        lineHeight: 1.1,
        marginBottom: 4,
    },
    name: {
        ...theme.fonts.bodyLargeStrong,
    },
}));
