import {
    useState,
    useCallback,
    useEffect,
    useRef,
    Dispatch,
    SetStateAction,
    useMemo,
    Ref,
} from 'react';
import {useDebounce} from '@react-hook/debounce';
import useRefCallback from 'hsi/hooks/useRefCallback';
import {assignRef} from 'hsi/utils/react';

interface DimensionOptionsType {
    width?: boolean;
    height?: boolean;
    x?: boolean;
    y?: boolean;
}

interface UseElementSizeOptions<BaseElementType, TargetElementType> extends DimensionOptionsType {
    enabled?: boolean;
    wait?: number; //Time in milliseconds
    leading?: boolean;
    getTargetElement?: (element: BaseElementType) => TargetElementType | undefined;
}

type ObserverStateRef<TargetElementType> = {
    dimensions?: DOMRect;
    setDimensions: Dispatch<DOMRect>;
    node?: TargetElementType;
    setNode?: Dispatch<SetStateAction<TargetElementType | undefined>>;
    observer?: ResizeObserver;
    //used when disabled
    lastRect?: DOMRect;
};

//This is what gets used if nothing passed for the second argument
const defaultOptions = {
    enabled: true,
    wait: undefined,
    leading: false,
    width: true,
    height: true,
    x: true,
    y: true,
};

//The hook (using observers, the modern way)
function useElementSizeObserver<
    BaseElementType extends Element,
    TargetElementType extends Element = BaseElementType,
>(
    currentRef?: Ref<BaseElementType | null>,
    {
        enabled = true,
        wait,
        leading,
        getTargetElement: getTargetElementArg,
        ...dimensionOptions
    }: UseElementSizeOptions<BaseElementType, TargetElementType> = defaultOptions,
) {
    const getTargetElement = useMemo(
        () =>
            getTargetElementArg
                ? getTargetElementArg
                : (elem: BaseElementType) => elem as unknown as TargetElementType,
        [getTargetElementArg],
    );

    const [dimensions, setDimensionsDebounce, setDimensionsImmediate] = useDebounce<
        DOMRect | undefined
    >(undefined, wait, leading);
    const setDimensions = wait ? setDimensionsDebounce : setDimensionsImmediate;

    const [, setNode] = useState<TargetElementType | undefined>(undefined);

    //keep track of props in ref so we can avoid creating new functions constantly
    const ref = useRef<ObserverStateRef<TargetElementType>>({
        dimensions,
        setDimensions,
        node: undefined,
        setNode: undefined,
        observer: undefined,
        //used when disabled
        lastRect: undefined,
    });

    //Ensure that ref is up to date
    ref.current.dimensions = dimensions;
    ref.current.setDimensions = setDimensions;
    ref.current.setNode = setNode;

    //initialise the observer
    if (!ref.current.observer) {
        ref.current.observer = new ResizeObserver((entries, observer) => {
            if (entries[0]) {
                //get element bounding box
                const rect = entries[0].target.getBoundingClientRect(); //cannot use entries[0].contentRect because it's left/right top/bottom values are different to getBoundingClientRect values;

                if (enabled) {
                    //if dimensions have changed, update
                    !compareRects(rect, ref.current.dimensions, dimensionOptions) &&
                        ref.current.setDimensions(rect);
                } else {
                    ref.current.lastRect = rect; //record for future use
                }
            }
        });
    }

    //Monitor the node ref, and set the observer as required
    const _setNodeCallback = useCallback(
        (baseNode: BaseElementType) => {
            const node = getTargetElement(baseNode);

            if (node !== ref.current.node) {
                const {observer, node: curNode} = ref.current;

                if (curNode) {
                    observer?.unobserve(curNode);
                }

                if (node) {
                    observer?.observe(node);
                }

                //record the node
                ref.current.node = node;
                ref.current.setNode?.(node); //this will trigger update
            }
        },
        [getTargetElement],
    );

    const setNodeCallback = useRefCallback((elem: BaseElementType) => {
        _setNodeCallback(elem);

        currentRef && assignRef(currentRef, elem);
    });

    //If we switch from disabled to enabled, apply the last size
    useEffect(() => {
        if (enabled && ref.current.node) {
            ref.current.setDimensions(ref.current.node.getBoundingClientRect());
            ref.current.lastRect = undefined;
        }
    }, [enabled]);

    //on unmount, stop listening to events & tidy up
    useEffect(() => {
        return () => {
            ref.current.observer?.disconnect();
            ref.current.observer = undefined;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            ref.current.node = undefined;
        };
    }, []);

    return [setNodeCallback, enabled ? dimensions : undefined, ref.current.node] as const;
}

type LegacyStateRefType<TargetElementType> = {
    updateActive: boolean;
    isMounted: boolean;
    dimensions?: DOMRect;
    setDimensions: Dispatch<SetStateAction<DOMRect | undefined>>;
    node?: TargetElementType;
};

//The hook (using polling, the legacy way)
function useElementSizeLegacy<
    BaseElementType extends Element,
    TargetElementType extends Element = BaseElementType,
>(
    currentRef?: Ref<BaseElementType | null>,
    {
        enabled = true,
        wait,
        leading,
        getTargetElement: getTargetElementArg, //: (from: From) => To = (from: From) => from as unknown as To
        ...dimensionOptions
    }: UseElementSizeOptions<BaseElementType, TargetElementType> = defaultOptions,
) {
    //This function is apparently broken...
    const getTargetElement = getTargetElementArg
        ? getTargetElementArg
        : (elem: BaseElementType) => elem as unknown as TargetElementType;

    const [dimensions, setDimensionsDebounce, setDimensionsImmediate] = useDebounce<
        DOMRect | undefined
    >(undefined, wait, leading);
    const setDimensions = wait ? setDimensionsDebounce : setDimensionsImmediate;

    //keep track of props in ref so we can avoid creating new functions constantly
    const ref = useRef<LegacyStateRefType<TargetElementType>>({
        updateActive: false,
        isMounted: true,
        dimensions,
        setDimensions,
    });

    ref.current.dimensions = dimensions;
    ref.current.setDimensions = setDimensions;

    const updateDimensions = useCallback(() => {
        if (!ref.current.isMounted) {
            return; //stop loop once component is unmounted
        }

        const node = ref.current.node;

        if (node && enabled) {
            //keep refreshing
            const rect = node.getBoundingClientRect();

            if (!ref.current.dimensions) {
                //No not yet record any dimensions
                ref.current.dimensions = rect;
                ref.current.setDimensions(rect);
            } else if (!compareRects(rect, ref.current.dimensions, dimensionOptions)) {
                //If dimensions have changed record and trigger an update
                ref.current.dimensions = rect;
                ref.current.setDimensions(rect);
            }

            window.requestAnimationFrame(updateDimensions);
        } else {
            //no node element...
            //...record that update loop has finished...
            ref.current.updateActive = false;
            //...and clear dimensions.
            ref.current.setDimensions(undefined);
        }
    }, [dimensionOptions, enabled]);

    const setNodeCallback = useRefCallback((node: BaseElementType) => {
        currentRef && assignRef(currentRef, node);

        if (node) {
            ref.current.node = getTargetElement(node);

            if (ref.current.node) {
                //we have a node
                if (!ref.current.updateActive) {
                    //update loop is not active
                    updateDimensions(); //begin the update loop
                }
            }
        }
    });

    useEffect(
        () => {
            if (enabled && ref.current.node) {
                //we have a node
                if (!ref.current.updateActive) {
                    //update loop is not active
                    updateDimensions(); //begin the update loop
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [enabled],
    );

    //on unmount, record that component is unmounted
    useEffect(() => {
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            ref.current.isMounted = false;
        };
    }, []);

    return [setNodeCallback, enabled ? dimensions : undefined, ref.current.node] as const;
}

function compareRects(
    rect1: DOMRect | undefined,
    rect2: DOMRect | undefined,
    options: DimensionOptionsType,
) {
    if (rect1 === rect2) {
        return true;
    }

    if (rect1 === undefined || rect2 === undefined) {
        return false;
    }

    return (
        (options.width ? rect1.width === rect2.width : true) &&
        (options.height ? rect1.height === rect2.height : true) &&
        (options.x ? rect1.left === rect2.left && rect1.right === rect2.right : true) &&
        (options.y ? rect1.top === rect2.top && rect1.bottom === rect2.bottom : true)
    );
}

//feature detect for best version of hook
const useElementSize = window.ResizeObserver ? useElementSizeObserver : useElementSizeLegacy;

export default useElementSize;
