import 'hsi/utils/polyfills/readableStreamAsyncIterator';

export default async function readStreamAsString(stream: ReadableStream<Uint8Array>, onData?: (curString: string, chunk: Uint8Array) => void): Promise<string> {
    let buffer = new Uint8Array(0);
    let string: string | undefined = undefined;

    for await (const chunk of stream as any) {
        const data = chunk as Uint8Array;
        const newBuffer = new Uint8Array(buffer.byteLength + data.byteLength);
        newBuffer.set(buffer);
        newBuffer.set(data, buffer.byteLength);
        buffer = newBuffer;

        if(onData) {
            onData(string = decoder.decode(buffer), data);
        }
    }

    return string ?? decoder.decode(buffer);
}

const decoder = new TextDecoder();

