import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    return {
        tooltipContent: theme.v2
            ? {
                  ...theme.fonts.bodyLargeNormal,
                  padding: '6px 28px',
                  display: 'block',
              }
            : {
                  fontSize: '16px',
                  lineHeight: '20px',
                  fontWeight: theme.font.bold,
                  padding: '6px 28px',
                  display: 'block',
              },
    };
});
