import makeStyles from '@mui/styles/makeStyles';
import {isV2, Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);

    return {
        quickPie: {
            marginTop: 24,
            marginLeft: 16,
            flex: '0 0 50px',
            position: 'relative',
        },

        svg: {
            position: 'relative',
            zIndex: 1,
        },

        legend: {
            position: 'absolute',
            zIndex: 2,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: v2 ? theme.colors.textDefault : theme.colors.darkGrey100,
            stroke: v2 ? theme.colors.accentDefault : undefined,

            ...(v2 ? theme.fonts.labelStrong : {fontSize: 12, fontWeight: 600}),
        },
    };
});
