import cn from 'classnames';

//Components
import NavButton from '../NavButton';

//Hooks
import useEventTrack from 'hsi/hooks/useEventTrack';

//Other
import {introAnchors} from 'hsi/containers/Intro';
import {T} from 'hsi/i18n';

//Types
type HelpCenterButtonProps = {
    url: string;
};

//The component
export default function HelpCenterButton({url}: HelpCenterButtonProps) {
    const {track} = useEventTrack();

    if (!url) {
        return null;
    }

    return (
        <NavButton
            className={cn(introAnchors.quickSearch.helpCenter)}
            to={url}
            label={T('homeNavBar.helpCenter')}
            hideTextLabel
            icon="hat"
            tooltip={T('homeNavBar.helpCenter')}
            tooltipNoAria
            onClick={(e) => {
                track('helpCenterButtonClicked', {});
                window.open(url, '_blank');
                e.preventDefault();
            }}
            right
        />
    );
}
