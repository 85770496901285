import {getChangePercentage} from 'hsi/utils/misc';
import {T} from 'hsi/i18n';
import tns from 'hsi/i18n/tns';
import {
    BenchmarkCardData,
    EmotionHistoryCardData,
    EmotionVolumeCardData,
    GenderCardData,
    GeographyCardData,
    HeatmapCardData,
    MentionsHistoryCardData,
    NetSentimentHistoryCardData,
    SentimentHistoryCardData,
    SentimentVolumeCardData,
    TopAuthorsCardData,
    TopHashtagsCardData,
    TopInterestsCardData,
    TopLanguagesCardData,
    TopSharedURLsCardData,
    TopSitesCardData,
    TotalVolumeCardData,
    WordCloudCardData,
} from 'hsi/types/cards';

const t = tns('exportCard');

const geographyFormatter = (data: GeographyCardData, queryName: string) =>
    data.map((datum) => ({
        [t('geography.country')]: datum.name,
        [t('volume', {queryName})]: datum.total,
        [t('uniqueAuthor')]: datum.authorTotal,
        [t('uniqueAuthorPercent')]: datum.authorPercent * 100,
    }));

const genderFormatter = (data: GenderCardData, queryName: string) =>
    data.map((datum) => ({
        [t('gender.gender')]: datum.name,
        [t('volume', {queryName})]: datum.value,
        '%': datum.pct,
    }));

const sentimentHistoryFormatter = (
    data: SentimentHistoryCardData,
    queryName: unknown,
    breakdown: string,
): any[] =>
    data.series.map((datum) => ({
        [T(`breakdowns.${breakdown}`)]: datum.date,
        [t('sentimentHistory.positive')]: datum.positive,
        [t('sentimentHistory.neutral')]: datum.neutral,
        [t('sentimentHistory.negative')]: datum.negative,
    }));

const benchmarkFormatter = (data: BenchmarkCardData, queryName: string, breakdown: string) =>
    data.categories.map((elem) => {
        const changePercentage = getChangePercentage(elem.currentValue, elem.previousValue);
        const change = (changePercentage === Infinity ? '∞' : Math.abs(changePercentage)) + '%';
        return {
            [T(`breakdowns.${breakdown}`)]: elem.name,
            [t('benchmark.currentVolume', {queryName})]: elem.currentValue,
            [t('benchmark.previousVolume', {queryName})]: elem.previousValue,
            [t('benchmark.change')]: change,
        };
    });

const emotionVolumeFormatter = (data: EmotionVolumeCardData, queryName: string) =>
    data.map((elem) => {
        return {
            [t('emotionVolume.emotion')]: t(`emotionVolume${elem.name.replace('emotions', '')}`),
            [t('volume', {queryName})]: elem.value,
        };
    });

const heatmapFormatter = (data: HeatmapCardData, queryName: string) => {
    const csvData: {[x: number]: string | number}[] = [];
    data.results.forEach((datum) =>
        datum.values.forEach((day) =>
            csvData.push({
                [t('heatmap.dayAndHour', {queryName})]: `${T(datum.name)}  ${day.name}`,
                [t('volume', {queryName})]: day.value,
            }),
        ),
    );
    return csvData;
};

const sentimentVolumeFormatter = (data: SentimentVolumeCardData, queryName: string) =>
    data.categories.map((elem) => ({
        [t('sentimentVolume.sentiment')]: elem.name,
        [t('volume', {queryName})]: elem.value,
        '%': `${(elem.value / data.total) * 100} %`,
    }));

const netSentimentHistoryFormatter = (
    data: NetSentimentHistoryCardData,
    queryName: string,
    breakdown: string,
) =>
    //TODO: support multiplesearch
    data.series.map((datum: any) => ({
        [T(`breakdowns.${breakdown}`)]: datum.date,
        [t('netSentiment.value', {queryName})]: datum[data.categories[0].id],
    }));

const emotionHistoryFormatter = (data: EmotionHistoryCardData, queryName: any, breakdown: string) =>
    data.series.map((datum) => ({
        [T(`breakdowns.${breakdown}`)]: datum.date,
        [t('emotionHistory.anger')]: datum.anger,
        [t('emotionHistory.disgust')]: datum.disgust,
        [t('emotionHistory.fear')]: datum.fear,
        [t('emotionHistory.joy')]: datum.joy,
        [t('emotionHistory.sadness')]: datum.sadness,
        [t('emotionHistory.surprise')]: datum.surprise,
    }));

const mentionsHistoryFormatter = (
    data: MentionsHistoryCardData,
    queryName: string,
    breakdown: string,
) =>
    //TODO: support multiplesearch
    data.series.map((datum: any) => ({
        [T(`breakdowns.${breakdown}`)]: datum.date,
        [t('volume', {queryName})]: datum[data.categories[0].id],
    }));

const topHashtagsFormatter = (data: TopHashtagsCardData, queryName: string) =>
    data.map((elem) => ({
        [t('topHashtags.name')]: elem.name,
        [t('volume', {queryName})]: elem.value,
        [t('topHashtags.impressions', {queryName})]: elem.impressions,
        [t('topHashtags.reachEstimate', {queryName})]: elem.reachEstimate,
    }));

const topInterestsFormatter = (data: TopInterestsCardData, queryName: string) =>
    data.map((elem) => ({
        [t('topInterests.name')]: elem.name,
        [t('volume', {queryName})]: elem.total,
        [t('uniqueAuthor')]: elem.authorTotal,
        [t('uniqueAuthorPercent')]: elem.authorPercent,
    }));

const topLanguagesFormatter = (data: TopLanguagesCardData, queryName: string) =>
    data.map((elem) => {
        return {
            [t('topLanguages.name')]: elem.name,
            [t('volume', {queryName})]: elem.total,
            [t('uniqueAuthor')]: elem.authorTotal,
            [t('uniqueAuthorPercent')]: elem.authorPercent,
        };
    });

const topSharedURLsFormatter = (data: TopSharedURLsCardData, queryName: string) =>
    data.map((elem) => {
        return {
            [t('topSharedURLs.label')]: elem.name,
            [t('volume', {queryName})]: elem.volume,
        };
    });

const topSitesFormatter = (data: TopSitesCardData, queryName: string) =>
    data.map((elem) => ({
        [t('topSites.site')]: elem.name,
        [t('volume', {queryName})]: elem.value,
    }));

const wordCloudFormatter = (data: WordCloudCardData, queryName: string) =>
    data.topics.map((elem) => ({
        [t('wordCloud.label')]: elem.label,
        [t('wordCloud.topicType')]: elem.topicType,
        [t('volume', {queryName})]: elem.volume,
        [t('wordCloud.sentimentScore')]: elem.sentimentScore,
        [t('wordCloud.female')]: elem.gender.female,
        [t('wordCloud.male')]: elem.gender.male,
        [t('wordCloud.trending')]: elem.trending,
    }));

const totalVolumeFormatter = (data: TotalVolumeCardData, queryName: string) =>
    data.map((elem) => ({
        [t('totalVolume.metric')]: T(`cards.totalVolume.sections.${elem.id}`),
        [t('totalVolume.current', {queryName})]: elem.current,
        [t('totalVolume.previous', {queryName})]: elem.previous,
        [t('totalVolume.change')]: elem.changePercentage,
    }));

const topAuthorsFormatter = (data: TopAuthorsCardData, queryName: string) =>
    data.map((elem) => ({
        [t('topAuthors.authorName')]: elem.name,
        [t('topAuthors.id')]: elem.id,
        [t('topAuthors.countryName')]: elem.countryName,
        [t('volume', {queryName})]: elem.value,
        [t('topAuthors.followers')]: elem.twitterFollowers,
        [t('topAuthors.reach')]: elem.reachEstimate,
        '%': (elem.percentageOfTotal * 100).toFixed(2),
    }));

const formatter = {
    geography: geographyFormatter,
    gender: genderFormatter,
    sentimentHistory: sentimentHistoryFormatter,
    benchmark: benchmarkFormatter,
    emotionVolume: emotionVolumeFormatter,
    heatmap: heatmapFormatter,
    sentimentVolume: sentimentVolumeFormatter,
    emotionHistory: emotionHistoryFormatter,
    mentionsHistory: mentionsHistoryFormatter,
    tophashtags: topHashtagsFormatter,
    topinterests: topInterestsFormatter,
    toplanguages: topLanguagesFormatter,
    topSharedURLs: topSharedURLsFormatter,
    topsites: topSitesFormatter,
    wordCloud: wordCloudFormatter,
    totalVolume: totalVolumeFormatter,
    topauthors: topAuthorsFormatter,
    netSentimentHistory: netSentimentHistoryFormatter,
} as const;
export default formatter;
