import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {deserializeSearch} from 'hsi/utils/url';

const useURLSearchParams = () => {
    const {search} = useLocation();

    return useMemo(() => deserializeSearch(search), [search]);
};

export default useURLSearchParams;
