/** 
 * The purpose of this module as to provide a set of functions to make creating the filter objects for various drill-ins easier.
 */
import { GeographyDataItem, TopInterestsType, TopLanguageType } from "hsi/types/cards";
import { FiltersState } from "hsi/types/filters";


export function getAuthorDrillInFilters(authorName: string, domain: string | undefined, isSavedSearch: boolean): FiltersState {
    return isSavedSearch
        ? domain
            ? {
                  site: {
                      fields: {
                          domain: {include: [domain], exclude: []},
                      },
                  },
                  author: {
                      fields: {
                          author: {include: [authorName], exclude: []},
                      },
                  },
              }
            : {
                  author: {
                      fields: {
                          author: {include: [authorName], exclude: []},
                      },
                  },
              }
        : {
              ownedContent: {
                  fields: {
                      author: {include: [authorName], exclude: []},
                      domain: {include: domain ? [domain] : [], exclude: []},
                  },
              },
          }
}

export function getDomainDrillInFilters(domain: string, isSavedSearch: boolean): FiltersState {
    return {
        [isSavedSearch ? 'site' : 'ownedContent']: {
            fields: {
                domain: {include: [domain], exclude: []},
            },
        },
    };
}

export function getHashtagDrillInFilters(hashtag: string): FiltersState{
    return {insightsHashtag: hashtag};
}

export function getInterestsDrillInFilters({id}: TopInterestsType): FiltersState {
    return  {interest: id};
}

export function getLanguageDrillInFilters({id, name}: TopLanguageType): FiltersState {
    return {
        language: {
            activeModeIsInclude: true,
            values: [{id, fullName: name}],
        },
    };
}

export function getLocationDrillInFilters({id, name}: GeographyDataItem): FiltersState {
    return {
        location: {
            activeModeIsInclude: true,
            values: [{id, name, fullName: name}],
        },
    };
}

export function getQueryIdDrillInFilters(queryId: number | string): FiltersState{
    return {queryId: queryId.toString()}
}

export function getSharedUrlDillInFilters(url: string): FiltersState{
    return {insightsUrl: url};
}

export function getTopicDrillInfilters(topicName: string): FiltersState{
    return {keyword: topicName}
}


