import find from 'lodash/find';

const getTagNameById = (tags, id) => {
    const tag = find(tags, (mtag) => mtag.id === id);
    return tag ? tag.label : undefined;
};

export const markActiveTags = (mentions, filters, filterOptions) => {
    if (!filters?.tag || !filterOptions?.metadata?.tag) return mentions;

    const tagIds = filters.tag;
    const activeTagNames = tagIds.map((tid) => getTagNameById(filterOptions.metadata.tag, tid));

    const result = mentions.map((mention) => {
        if (mention.tags && mention.tags.length) {
            mention.tags = mention.tags.map((tag) => {
                if (typeof tag === 'string' && activeTagNames.includes(tag)) {
                    return {label: tag, active: true};
                }
                return tag;
            });
        }
        return mention;
    });

    return result;
};
