import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    root: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden',
        gap: 'var(--gap, 0)',
        alignItems: 'center',
    },
}));
