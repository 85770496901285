//TODO need to convert filters slice to TS to remove 'any' casts
import {useCallback, useEffect, useMemo} from 'react';

//Hooks
import useEventTrack from 'hsi/hooks/useEventTrack';
import useDates from 'hsi/hooks/useDates';
import useTimezone from 'hsi/hooks/useTimezone';
import useConfig from 'hsi/hooks/useConfig';
import {useAppSelector, useAppDispatch} from 'hsi/hooks/useRedux';

//Actions
import {updateDateRange, updateRelativeDateRange} from 'hsi/slices/filters';
import {clearResults} from 'hsi/actions/resultsActions';
import {persistActiveQuery} from 'hsi/actions/queryActions';

//Utils
import {getIntervalFromRelativeDateRange} from 'hsi/utils/dates';
import useRefCallback from './useRefCallback';
import {RangeDefinition, TDateISO} from 'hsi/types/dates';
import {TimezoneID} from 'hsi/utils/timezones';
import {Interval} from 'luxon';
import {ReadonlyDeep} from 'type-fest';

//The hook
export default function useQueryDateRange(isQuickSearch: boolean = false) {
    const timezone = useTimezone();
    const dispatch = useAppDispatch();
    const dates = useDates();
    const {trackWithSearchData} = useEventTrack();
    const {startDate, endDate, relativeRange} = useAppSelector((state) => state.filters.dateRange);
    const {quickSearchDateRanges, savedSearchDateRanges, defaultTimezone} = useConfig();

    const dateRanges: ReadonlyDeep<RangeDefinition[]> = isQuickSearch
        ? quickSearchDateRanges
        : savedSearchDateRanges;

    const relativeDateRangeId = relativeRange?.id;

    const onCalendarSet = useCallback(
        (
            selection: {relativeDateRange?: string; startDate?: TDateISO; endDate?: TDateISO},
            timezone: TimezoneID,
        ) => {
            if (selection.relativeDateRange) {
                dispatch(
                    (updateDateRange as any)({
                        relativeDateRange: dateRanges.find(
                            ({id}) => id === selection.relativeDateRange,
                        ),
                        timezone,
                    }),
                );
                dispatch(clearResults());
                dispatch(persistActiveQuery());
                trackWithSearchData('searchRelativeDateRangeApplied', {
                    range: selection.relativeDateRange,
                    timezone,
                });
            } else {
                dispatch(
                    (updateDateRange as any)({
                        startDate: selection.startDate,
                        endDate: selection.endDate,
                        timezone,
                    }),
                );
                dispatch(clearResults());
                dispatch(persistActiveQuery());
                trackWithSearchData('searchDateRangeApplied', {
                    endDate: selection.startDate,
                    startDate: selection.endDate,
                    timezone,
                });
            }
        },
        [dateRanges, dispatch, trackWithSearchData],
    );

    const onDateSelectionChange = useCallback(
        (selection: Interval | undefined) => {
            selection &&
                trackWithSearchData('searchDateRangeSelected', {
                    endDate: dates.formatTo(selection.start, 'yyyy-LL-dd'),
                    startDate: dates.formatTo(selection.end, 'yyyy-LL-dd'),
                });
        },
        [dates, trackWithSearchData],
    );

    const onTimezoneChange = useCallback(
        (timezone: TimezoneID) => {
            trackWithSearchData('searchDateRangeSelected', {
                timezone,
            });
        },
        [trackWithSearchData],
    );

    const onRelativeDateRangeIdChange = useCallback(
        (relativeDateRangeId: string | undefined) => {
            trackWithSearchData('searchRelativeDateRangeSelected', {
                relativeDateRange: relativeDateRangeId,
            });
        },
        [trackWithSearchData],
    );

    const checkUpdateRelativeDateRange = useRefCallback(() => {
        if (relativeRange) {
            const interval = getIntervalFromRelativeDateRange(relativeRange, timezone);

            if (interval.start.toISO() !== startDate || interval.end.toISO() !== endDate) {
                dispatch((updateRelativeDateRange as any)());
                dispatch(persistActiveQuery());
            }
        }
    });

    useEffect(() => {
        //Keep relative date range updated
        const iId = setInterval(checkUpdateRelativeDateRange, 5000);

        return () => clearInterval(iId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return useMemo(
        () => ({
            startDate: startDate,
            endDate: endDate,
            onCalendarSet,
            onDateSelectionChange,
            onTimezoneChange,
            hasTimezones: true,
            timezone,
            defaultTimezone,
            relativeDateRanges: dateRanges,
            selectedRelativeDateRangeId: relativeDateRangeId,
            onRelativeDateRangeIdChange,
        }),
        [
            dateRanges,
            defaultTimezone,
            endDate,
            onCalendarSet,
            onDateSelectionChange,
            onRelativeDateRangeIdChange,
            onTimezoneChange,
            relativeDateRangeId,
            startDate,
            timezone,
        ],
    );
}
