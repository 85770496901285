export function sortArrayOnProp(array: any[], prop: string, sortFunc: { (a: any, b: any): 0 | 1 | -1; (arg0: any, arg1: any): number; }) {
    array.sort((a, b) => sortFunc(a[prop], b[prop]));

    return array;
}

export function sortNumeric(a: number, b: number) {
    return a - b;
}

export function sortNumericAsc(a: number, b: number) {
    return b - a;
}
