// TODO: Add tests for all of this section
import {useCallback, useState} from 'react';

import AddInstagramHashtags from 'hsi/containers/DataManagement/AddDataSourceDialog/AddInstagramHashtags';
import AddOwnedFacebookPagesAndInstsgramAccounts from 'hsi/containers/DataManagement/AddDataSourceDialog/AddOwnedFacebookPagesAndInstagramAccounts';
import AddNonOwnedFacebookPages from 'hsi/containers/DataManagement/AddDataSourceDialog/AddNonOwnedFacebookPages';
import AddNonOwnedInstagramAccounts from 'hsi/containers/DataManagement/AddDataSourceDialog/AddNonOwnedInstagramAccounts';
import CreateLinkedinChannelDialog from 'hsi/containers/DataManagement/AddDataSourceDialog/CreateLinkedinChannelDialog';
import SelectDataSource, {
    SelectDataSourceTypes,
} from 'hsi/containers/DataManagement/AddDataSourceDialog/SelectDataSource';

import useDataSourceCount from 'hsi/hooks/useDataSourceCount';
import useEventTrack from 'hsi/hooks/useEventTrack';

export type AddDataSourceDialogViews = 'selectSource' | SelectDataSourceTypes;

export type AddDataSourceDialogProps = {
    defaultView?: AddDataSourceDialogViews;
    info: ReturnType<typeof useDataSourceCount>;
    onClose: () => void;
    open: boolean;
};

const AddDataSourceDialog = ({
    defaultView = 'selectSource',
    info,
    onClose,
    open,
}: AddDataSourceDialogProps) => {
    const {track} = useEventTrack();

    const [view, setView] = useState<AddDataSourceDialogViews>(defaultView);

    const onViewClose = useCallback(() => {
        setView(defaultView);
        onClose();
    }, [defaultView, onClose]);

    const onBack = useCallback(() => {
        setView(defaultView);
    }, [defaultView]);

    const onNoAuthentications = useCallback(() => {
        setView(SelectDataSourceTypes.ownedFacebookPagesAndInstagramAccounts);
    }, []);

    const onSelectSource = useCallback(
        (selectedSource: SelectDataSourceTypes) => {
            setView(selectedSource);
            track('targetedDataSourceSelected', {type: selectedSource});
        },
        [track],
    );

    if (!open) {
        return null;
    }

    switch (view) {
        case SelectDataSourceTypes.ownedFacebookPagesAndInstagramAccounts:
            return (
                <AddOwnedFacebookPagesAndInstsgramAccounts onBack={onBack} onClose={onViewClose} />
            );
        case SelectDataSourceTypes.nonOwnedFacebookPages:
            return <AddNonOwnedFacebookPages info={info} onBack={onBack} onClose={onViewClose} />;
        case SelectDataSourceTypes.nonOwnedInstagramAccounts:
            return (
                <AddNonOwnedInstagramAccounts info={info} onBack={onBack} onClose={onViewClose} />
            );
        case SelectDataSourceTypes.instagramHashtags:
            return (
                <AddInstagramHashtags
                    onBack={onBack}
                    onClose={onViewClose}
                    onNoAuthentications={onNoAuthentications}
                />
            );
        case SelectDataSourceTypes.ownedLinkedinPages:
            return (
                <CreateLinkedinChannelDialog
                    onBack={() => setView(defaultView)}
                    onClose={onViewClose}
                />
            );
        case 'selectSource':
        default:
            return (
                <SelectDataSource
                    hasFacebookAuthentications={info?.hasFacebookAuthentications}
                    onClose={onViewClose}
                    onNext={onSelectSource}
                />
            );
    }
};

export default AddDataSourceDialog;
