
import { PropsWithChildren, ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

// Components
import SaveSearchButton from 'hsi/components/SaveSearchButton';

// Hooks
import useQueryContext from 'hsi/hooks/useQueryContext';
import { useAppSelector } from 'hsi/hooks/useRedux';


// Other
import {T} from 'hsi/i18n';
import { CardType } from 'hsi/types/cards';
import isBCR from 'hsi/utils/isBCR';
import useStyles from './styles';
import useTableDisplayStyles from 'hsi/components/table/TableDisplay/styles';

// Types
export type UniqAuthorsCTAProps = {
    type: CardType
};

// Consts
const tableStyles = {
    "--table-columns": 'auto',
} as const;


// The component
export default function UniqAuthorsCTA({type}: UniqAuthorsCTAProps) {
    const classes = useStyles();
    const tableClasses = useTableDisplayStyles();

    const msg: ReactNode[] = T('uniqAuthorsCTA.message', {
        splitBy: T(`cards.${type}.uniqAuthorCTASplitBy`),
    }).split('[link]');

    const saveLink = <SaveSearchButton key="saveSearchButton" inlineLink>{T('uniqAuthorsCTA.linkText')}</SaveSearchButton>;

    msg.splice(1, 0, saveLink);

    return <div className={classNames(classes.uniqueAuthorsCTA, tableClasses.table)} style={tableStyles}>
        <div className={tableClasses.head}>
            <div className={tableClasses.row}>
                <div className={tableClasses.th}>
                    {T('uniqAuthors')}
                </div>
            </div>
        </div>
        <div className={tableClasses.body}>
            {[1, 2, 3].map((i) => (<div className={tableClasses.row} key={i.toString()}>
                <div className={tableClasses.td}>
                    <div className={classes.mockRowItem} />
                </div>
            </div>))}
        </div>

        <div className={classes.msg}>
            {msg.map((val, index) => {
                if(!val) {
                    return null
                }

                return typeof val === 'string'
                    ? <span key={index.toString()}>{val}</span>
                    : val
            })}
        </div>
    </div>
}

export function AddUniqAuthorsCTA({type, children}: PropsWithChildren<UniqAuthorsCTAProps>): ReactElement {
    const classes = useStyles();

    const {isMultipleSearch, isSavedSearch} = useQueryContext();

    const userIsViewOnly = useAppSelector((state) => state.user.isViewOnly);

    const showUniqueAuthorCTA = !isBCR() && !userIsViewOnly && !isMultipleSearch && !isSavedSearch;

    return showUniqueAuthorCTA 
        ? <div className={classes.uniqueAuthorsCTAWrapper}>
            <div className={classes.content}>
                {children}
            </div>
            <UniqAuthorsCTA type={type} />
        </div>
        : (children ?? null) as ReactElement;
}
