import {AriaAttributes, ReactNode} from 'react';
import cn from 'classnames';

//Components
import IconRouter from 'hsi/components/IconRouter';

//Other
import useStyles from './styles';

type LoadErrorMsgProps = {
    line1: ReactNode;
    line2?: ReactNode;
    className?: string;
    iconType?: 'info' | 'warning';
    ariaLive?: AriaAttributes['aria-live'];
};

//The component
export default function LoadErrorMsg({
    line1,
    line2,
    className,
    iconType = 'warning',
    ariaLive = 'polite',
}: LoadErrorMsgProps) {
    const classes = useStyles();

    return (
        <div
            className={cn(classes.root, className)}
            data-testid="loadError"
            role={iconType === 'info' ? 'status' : 'alert'}
            aria-live={ariaLive}
        >
            <IconRouter
                name={iconType === 'info' ? 'mui-info' : 'mui-warning'}
                aria-hidden
                className={classes.icon}
            />
            <div>{line1}</div>
            {!!line2 && <div>{line2}</div>}
        </div>
    );
}
