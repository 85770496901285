import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
    banner: {
        marginBottom: 0,
        '& .hsib-action .MuiIconButton-root': {
            backgroundColor: 'rgba(252, 252, 251, 0.6)',
        },
    },
    message: {
        fontSize: '16px',
    },
}));
