import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => ({
    root: {
        marginRight: 8,

        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        '--borderCol': theme.v2 ? theme.colors.borders : theme.colors.darkGrey60,
        '--focusCol': theme.v2 ? theme.colors.accentHover : theme.colors.secondaryOrange100,
        '--hoverCol': theme.colors.lightGrey20,
        '--selectedCol': theme.v2 ? theme.colors.accentDefault : theme.colors.primaryBlue100,

        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: `inset 0 0 0 1px var(--borderCol), inset 0 -1px 0 var(--borderCol)`,
        backgroundColor: 'transparent',

        '$root.Mui-focusVisible &': {
            outline: '2px auto var(--focusCol)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.colors.lightGrey20,
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
        },
    },
    checkedIcon: {
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                'radial-gradient(var(--selectedCol),var(--selectedCol) 28%,transparent 32%)',

            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: 'var(--hoverCol)',
        },
    },
    radioGroup: {
        display: 'contents',
    },
}));
