import React, {PropsWithChildren, useMemo} from 'react';

//Components
import IconRouter from 'hsi/components/IconRouter';
import {MenuWrapper, SelectableMenuItem} from 'hsi/components/SelectableMenuItem';

//Hooks
import Popover from 'hsi/components/Popover';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useProjectId from 'hsi/hooks/useProjectId';
import {useAppDispatch} from 'hsi/hooks/useRedux';

//Actions
import {updateMentionsSentiment} from 'hsi/slices/mentions';

//Types
import {SavedSearchMentionType} from 'hsi/types/mentions';

//Consts
import {SENTIMENT_CATEGORIES} from 'hsi/constants/config';

//Other
import {T} from 'hsi/i18n';
import stopPropagation from 'hsi/utils/html/stopPropagation';

type SentimentMenuNonEditableProps = PropsWithChildren<{
    editable?: false;
}>;

type SentimentMenuEditableProps = PropsWithChildren<{
    editable: true;
    mention: SavedSearchMentionType;
}>;

type SentimentMenuProps = PropsWithChildren<{
    editable?: boolean;
    mention?: SavedSearchMentionType;
}>;

export default function SentimentMenu(props: SentimentMenuNonEditableProps): ReturnType<React.FC>;
export default function SentimentMenu(props: SentimentMenuEditableProps): ReturnType<React.FC>;
export default function SentimentMenu(props: SentimentMenuProps): ReturnType<React.FC>;
export default function SentimentMenu({children, editable = false, mention}: SentimentMenuProps) {
    const sentimentMenuContent = useMemo(
        () =>
            editable && mention
                ? ({
                      close,
                      labelId,
                      descriptionId,
                  }: {
                      labelId: string;
                      descriptionId: string;
                      close: () => void;
                  }) => (
                      <SentimentMenuContent
                          labelId={labelId}
                          descriptionId={descriptionId}
                          close={close}
                          value={mention?.sentiment}
                          mention={mention}
                      />
                  )
                : undefined,
        [editable, mention],
    );

    return sentimentMenuContent ? (
        <Popover content={sentimentMenuContent}>{children}</Popover>
    ) : (
        children
    );
}

//Internal helper components
type SentimentMenuContentArgs = {
    value: string;
    mention?: SavedSearchMentionType;
    close: () => void;
    labelId: string;
    descriptionId: string;
};

export function SentimentMenuContent({
    value,
    mention,
    close,
    labelId,
    descriptionId,
}: SentimentMenuContentArgs) {
    const dispatch = useAppDispatch();
    const {trackWithSearchData} = useEventTrack();
    const projectId = useProjectId() ?? undefined;

    return (
        <>
            <span className="offscreen" id={labelId}>
                {T('sentimentMenu.label')}
            </span>
            <span className="offscreen" id={descriptionId}>
                {T('sentimentMenu.description')}
            </span>
            <MenuWrapper
                tabIndex={0}
                onClick={stopPropagation}
                onKeyDown={(e) => {
                    if (e.code === 'Enter' || e.code === 'Space') {
                        e.stopPropagation();
                    }
                }}
            >
                {SENTIMENT_CATEGORIES.map((sentiment) => (
                    <SelectableMenuItem
                        key={sentiment}
                        icon={<IconRouter name={`emoticon-${sentiment}`} />}
                        selected={value === sentiment}
                        onClick={() => {
                            trackWithSearchData('mentionEdited', {
                                mention: mention?.id,
                                type: 'sentiment',
                                value: {sentiment},
                            });

                            dispatch(
                                updateMentionsSentiment({ids: [mention!.id], sentiment, projectId}),
                            );
                            close();
                        }}
                    >
                        {T(`sentiments.${sentiment}`)}
                    </SelectableMenuItem>
                ))}
            </MenuWrapper>
        </>
    );
}
