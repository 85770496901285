import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    genderCard: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        marginBottom: '30px',
        justifyContent: 'space-evenly',
        '& .gender': {
            width: 'auto',
            height: 'auto',
            alignItems: 'center',
            display: 'flex',
            flexWrap: ' nowrap',
            flexDirection: 'column',
            alignSelf: 'center',
            alignContent: 'flex-start',

            '&.female': {
                '& h3': {color: theme.colors.female},
                '& h4': {color: theme.colors.darkGrey80},
            },

            '&.male': {
                '& h3': {color: theme.colors.male},
                '& h4': {color: theme.colors.darkGrey80},
            },

            '& svg': {
                display: 'block',
                flex: '0 0 94%',
                order: '1',
                width: '100px',
                height: '100px',
                marginRight: '13px',
                '&.icon-hsi-female': {
                    fill: theme.colors.female,
                },
                '&.icon-hsi-male': {
                    fill: theme.colors.male,
                },
                '&.icon-female': {
                    fill: theme.colors.female,
                },
                '&.icon-male': {
                    fill: theme.colors.male,
                },
            },

            '& h3': {
                fontSize: '54px',
                fontWeight: 'normal',
                flex: '0 0 50%',
                order: '2',
                margin: '0',
            },

            '& h4': {
                fontSize: '14px',
                flex: '0 0 20%',
                order: '3',
                margin: '0',
            },
        },
    },
}));
