import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => {
    return {
        filtersWrapper: {
            display: 'flex',
            flexFlow: 'column nowrap',
            margin: 0,
            listStyle: 'none',
            gap: theme.v2 ? 16 : 8,
        },
        compact: {
            gap: theme.v2 ? 8 : 0,
        },
    };
});
