import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
    root: {
        '& > div > div': {
            maxWidth: 400,
        },
    },
    copy: {
        '&&': {
            fontSize: 14,
            paddingLeft: 0,
        },
    },
    loadingHolder: {
        position: 'absolute',
        zIndex: 1,
        top: '-16px',
        right: 0,
        bottom: '-16px',
        left: 0,
        background: 'rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
