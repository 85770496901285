import {PropsWithChildren, useCallback} from 'react';

import {useRadioButtonsContext} from '..';
import useStyles from '../styles';
import useUniqueId from 'hsi/hooks/useUniqueId';
import classNames from 'classnames';

type RadioButtonItemProps<TValues extends string = string> = {
    value: TValues;
} & Omit<JSX.IntrinsicElements['div'], 'value'>;

//The component
export default function RadioButtonItem<TValues extends string = string>({
    id: paramId,
    value,
    children,
    className,
    ...rest
}: PropsWithChildren<RadioButtonItemProps<TValues>>) {
    const classes = useStyles();
    const {value: selectedValue, setValue, name} = useRadioButtonsContext<TValues>();

    const id = useUniqueId(paramId, `radio-${name}-`);
    const isChecked = !!selectedValue && value === selectedValue;

    const onChanged = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            setValue?.(event.target.value as TValues);
        },
        [setValue],
    );

    return (
        <div className={classNames(classes.item, className)} {...rest}>
            <input
                id={id}
                type="radio"
                className={classes.input}
                name={name}
                value={value}
                checked={isChecked}
                onChange={onChanged}
            />
            <label htmlFor={id} className={classes.itemLabel}>
                {children}
            </label>
        </div>
    );
}
