import IconRouter from 'hsi/components/IconRouter';
import useStyles from './styles';
import useUniqueId from 'hsi/hooks/useUniqueId';

type FormErrorProps = {
    type: string;
    errorText: string;
    actionText?: string;
} & Omit<JSX.IntrinsicElements['div'], 'type'>;

export default function FormError({
    type,
    errorText,
    actionText,
    role = 'alert',
    className,
    id: _id,
    ...rest
}: FormErrorProps) {
    const id = useUniqueId(_id, 'formError');
    const {classes, cx} = useStyles();

    return (
        <div
            id={id}
            aria-labelledby={`${id}.label`}
            className={cx(classes.formError, type, 'form-error', className)}
            role={role}
            {...rest}
        >
            <div className="icon-wrapper" aria-hidden>
                <IconRouter name={type} />
            </div>
            <div className="text-wrapper">
                <div className="error-text" id={`${id}.label`}>
                    {errorText}
                </div>
                {actionText && <div className="action-text">{actionText}</div>}
            </div>
        </div>
    );
}
