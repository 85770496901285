import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    
    return {
        chip: {
            width: '28px',
            height: '20px',
            display: 'inline-block',
        },
        icon: {
            width: '100%',
            height: '100%',
        }
    };
});
