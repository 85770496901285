import {useCallback, useMemo, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';

//Components
import Button from 'hsi/components/Button';
import IconRouter from 'hsi/components/IconRouter';
import Markdown from 'hsi/components/Markdown';
import {MenuHeader, MenuFooter} from 'hsi/components/Menu';
import PulseLoader from 'hsi/components/PulseLoader';
import SearchableSelectContent from 'hsi/components/SearchableSelectContent';

//Actions
import {toggleFacebookLoginDialog} from 'hsi/slices/facebookAuthentication';

//Hooks
import {useInstagramAccounts, useInstagramHashtags} from 'hsi/hooks/useDataSource';
import {useAppDispatch} from 'hsi/hooks/useRedux';

//Other
import {T} from 'hsi/i18n';

import {Theme} from 'hsi/types/theme';
import {InstagramAccountItem} from 'hsi/types/dataManagement';

const useStyles = makeStyles((theme: Theme) => {
    const {colors, font} = theme;

    return {
        root: {
            maxWidth: 300,
            position: 'relative',
        },
        confirmContent: {
            padding: 24,
        },
        selector: {
            marginTop: 8,
            marginBottom: 0,
        },
        trackInfo: {
            textAlign: 'left',
            backgroundColor: colors.lightGrey20,
            padding: '16px 24px',
        },
        trackInfoIcon: {
            fill: colors.primaryBlue100,
            height: 24,
            width: 24,
            marginBottom: 12,
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        trackInfoTitle: {
            fontSize: '14px',
            color: colors.darkGrey100,
            fontWeight: font.bold,
            paddingBottom: 8,
        },
        trackInfoDesc: {
            fontSize: '14px',
            color: colors.darkGrey100,
        },
        footerBtn: {
            marginLeft: 8,
        },
        loadingContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255,255,255, 0.9)',
            textAlign: 'center',
            paddingTop: '45%',
            zIndex: 10,
        },
    };
});

type TrackWithIGBAProps = {
    hashtag: string;
    onClose: () => void;
};

type FilteredOption = {
    contentRight: string;
    data: InstagramAccountItem;
    disabled: boolean;
    label: string;
    value: string;
};

const TrackWithIGBA = ({hashtag, onClose}: TrackWithIGBAProps) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const [searchStr, setSearchStr] = useState('');

    const instagramAccounts = useInstagramAccounts();
    const instagramHashtags = useInstagramHashtags();

    const [selectedAccount, setSelectedAccount] = useState<InstagramAccountItem>();

    const onPageSelect = useCallback((item: InstagramAccountItem | undefined) => {
        setSelectedAccount(item);
    }, []);

    const filteredOptions: FilteredOption[] = useMemo(
        () =>
            instagramAccounts.activeOwned
                .filter(({name}) => name.toLowerCase().startsWith(searchStr.toLowerCase()))
                .map((item) => ({
                    contentRight: T('queryBuilder.hashtagsRemaining', {
                        num: item.hashtagsRemaining,
                    }),
                    data: item,
                    disabled: !item.hashtagsRemaining,
                    label: item.name,
                    value: item.id,
                })),
        [instagramAccounts.activeOwned, searchStr],
    );

    const onSaveHashtag = useCallback(async () => {
        instagramHashtags.add.mutate({
            instagramBusinessAccountId: selectedAccount?.id,
            hashtags: [hashtag],
        });
        onClose();
    }, [hashtag, instagramHashtags.add, onClose, selectedAccount?.id]);

    return (
        <div className={classes.root}>
            {(instagramAccounts.isLoading || instagramHashtags.isLoading) && (
                <div className={classes.loadingContainer}>
                    <PulseLoader size="medium" />
                </div>
            )}
            {!selectedAccount ? (
                <>
                    <MenuHeader>{T('queryBuilder.igbaSelectTitle')}</MenuHeader>
                    <SearchableSelectContent
                        className={classes.selector}
                        options={filteredOptions}
                        onChange={setSearchStr}
                        onSelect={(option: FilteredOption) => onPageSelect(option.data)}
                        searchPlaceholder={'search'}
                        showSearch={instagramAccounts.activeOwned.length > 5}
                    />
                    <MenuFooter>
                        <Button
                            color="primary"
                            size="small"
                            priority="text"
                            startIcon={<IconRouter name="f-add" />}
                            onClick={() => {
                                onClose();
                                dispatch(toggleFacebookLoginDialog(true));
                            }}
                        >
                            {T('queryBuilder.addNewPage')}
                        </Button>
                    </MenuFooter>
                </>
            ) : (
                <>
                    <MenuHeader onBack={() => onPageSelect(undefined)}>
                        {T('queryBuilder.trackWithPage', {page: selectedAccount.name})}
                    </MenuHeader>
                    <div className={classes.confirmContent}>
                        <div className={classes.trackInfo}>
                            <IconRouter name="c-info" className={classes.trackInfoIcon} />
                            <div className={classes.trackInfoTitle}>
                                {T('queryBuilder.trackInfoTitle')}
                            </div>
                            <Markdown
                                className={classes.trackInfoDesc}
                                data-testid="trackWithIgbaMenuInfoDesc"
                            >
                                {T('queryBuilder.trackInfoDesc')}
                            </Markdown>
                        </div>
                    </div>
                    <MenuFooter alignRight>
                        <Button
                            priority="primary"
                            size="small"
                            className={classes.footerBtn}
                            onClick={onClose}
                        >
                            {T('queryBuilder.cancel')}
                        </Button>
                        <Button
                            priority="cta"
                            size="small"
                            className={classes.footerBtn}
                            onClick={onSaveHashtag}
                        >
                            {T('queryBuilder.confirm')}
                        </Button>
                    </MenuFooter>
                </>
            )}
        </div>
    );
};

export default TrackWithIGBA;
