import React, {useCallback, useMemo, useState} from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useLocation, useRouteMatch} from 'react-router-dom';

import {LoadErrorMsg} from 'hsi/components/ErrorMessage';
import {FullViewport} from 'hsi/components/FullViewport';
import NoResults from './NoResults';
import Loader from 'hsi/components/PulseLoader/FullViewportPulseLoader';
import Table from './Table';
import ValidationErrors from './ValidationErrors';

import useTrackNlaMentions from 'hsi/hooks/useTrackNlaMentions';

import useFilterOptions from './useFilterOptions';
import useLocationsFilterParser from './useLocationsFilterParser';
import useMentions from './useMentions';
import useMessageParser from './useMessageParser';
import useMessage from './useMessage';
import useTableLayout from './useTableLayout';

import {parseErrorMessage} from './errors';
import {getUrlParams} from 'hsi/utils/url';

const GlobalCss = withStyles({
    '@global': {
        body: {
            backgroundColor: 'white',
        },
    },
})(() => null);

const initialPage = 0;
const pageSize = 30;

const sortMentions = (mentions, sort) => {
    if (isEmpty(sort)) return mentions;
    const {keys, orders} = sort;
    return orderBy(mentions, keys, orders);
};

const parseUrlParams = (urlParams) => ({
    query: urlParams?.search?.query,
    searchId: urlParams?.searchId,
    sortBy: urlParams?.sortBy,
    sortOrder: urlParams?.sortOrder,
});

//TODO move to hsi/utils
const getSearchType = ({query, searchId}) => (query ? 'quick' : searchId ? 'saved' : undefined);

//pick either query or searchId
const queryOrSearch = (query, searchId) => ({
    query,
    searchId: query === undefined ? searchId : undefined,
});

const MentionsTable = ({messageParserId}) => {
    const location = useLocation();
    const match = useRouteMatch();
    const projects = useSelector((state) => state.user.projects);

    const {
        query: queryFromUrl,
        searchId: searchIdFromUrl,
        sortBy,
        sortOrder,
    } = useMemo(() => parseUrlParams(getUrlParams(location, match)), [location, match]);

    const [sort, setSort] = useState(
        sortBy || sortOrder
            ? {
                  sort_by: sortBy,
                  sort_order: sortOrder,
              }
            : undefined,
    );
    const [page, setPage] = useState(initialPage);
    const [payload, setPayload] = useState({
        dateRange: undefined,
        filters: {},
        searches: [],
    });

    //TODO multiple searches, get the first one FTTB
    const _query = useMemo(
        () => queryFromUrl || payload?.searches?.[0]?.query,
        [payload?.searches, queryFromUrl],
    );
    const _searchId = useMemo(
        () => searchIdFromUrl || payload?.searches?.[0]?.searchId,
        [payload?.searches, searchIdFromUrl],
    );
    const {query, searchId} = useMemo(() => queryOrSearch(_query, _searchId), [_query, _searchId]);
    const searchType = useMemo(() => getSearchType({query, searchId}), [query, searchId]);

    const updateAction = useCallback(
        ({searches: newSearches, dateRange: newDateRange, filters: newFilters, sort: newSort}) => {
            setPayload({
                dateRange: newDateRange,
                filters: newFilters,
                searches: newSearches,
            });
            setSort(newSort);
            setPage((prevPage) =>
                !isEqual(payload?.filters, newFilters) ? initialPage : prevPage,
            );
        },
        [payload?.filters],
    );

    const actions = useMemo(
        () => ({
            update: updateAction,
        }),
        [updateAction],
    );

    const {columns, loading: loadingTableLayout, error: tableLayoutError} = useTableLayout();

    const messageParser = useMessageParser(messageParserId)({
        columns,
    });

    const {
        filterOptions,
        loading: loadingFilterOptions,
        error: filterOptionsError,
    } = useFilterOptions({searchType});

    const {filters: parsedFilters, loading: parsingFilters} = useLocationsFilterParser({
        filters: payload?.filters,
        filterOptions,
    });

    const {
        mentions,
        hasMoreData,
        loading: loadingMentions,
        error: mentionsError,
        validationErrors,
        project,
    } = useMentions({
        filters: parsedFilters,
        filterOptions,
        query,
        searchId,
        searchType,
        projects,
        page,
        pageSize,
        payload,
        sort,
    });

    const loading = useMemo(
        () =>
            loadingMentions ||
            loadingFilterOptions ||
            loadingTableLayout ||
            parsingFilters ||
            mentions === undefined,
        [loadingFilterOptions, loadingMentions, loadingTableLayout, mentions, parsingFilters],
    );

    const errors = useMemo(
        () => [mentionsError, filterOptionsError, tableLayoutError].filter((e) => !!e),
        [filterOptionsError, mentionsError, tableLayoutError],
    );
    const allErrors = useMemo(
        () => errors.concat(validationErrors).filter((e) => !!e),
        [errors, validationErrors],
    );
    const hasErrors = useMemo(() => !isEmpty(errors), [errors]);
    const hasData = useMemo(() => Boolean(mentions), [mentions]);
    const hasResults = useMemo(() => !isEmpty(mentions), [mentions]);
    const message = useMessage({
        actions,
        messageParser,
        columns,
        filterOptions,
        loading,
        errors: allErrors,
        hasData,
        hasResults,
        hasMoreData,
        page,
        sort,
    });

    useTrackNlaMentions(loading, mentions);

    if (hasErrors) {
        return (
            <FullViewport>
                {errors.map((error, i) => (
                    <LoadErrorMsg line1={parseErrorMessage(error)} key={i} />
                ))}
            </FullViewport>
        );
    }

    if (loading && page === 0) {
        return <Loader />;
    }

    if (validationErrors) {
        return (
            <FullViewport>
                <ValidationErrors errors={validationErrors} />;
            </FullViewport>
        );
    }

    if (!hasResults) {
        return <NoResults fullViewport />;
    }

    return (
        <>
            <GlobalCss />
            <Table
                columns={columns}
                hasMoreData={hasMoreData}
                loadMore={() => {
                    setPage((prevPage) => prevPage + 1);
                }}
                onHeaderClick={({columnId}) => {
                    message.send('sort-table', {columnId});
                }}
                rows={sortMentions(mentions, sort)}
                sort={sort}
                tz={project.timezone}
            />
        </>
    );
};

MentionsTable.propTypes = {
    messageParserId: PropTypes.oneOf(['hootsuite']).isRequired,
};

export default MentionsTable;
