import {useCallback, useMemo, useState} from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, {TextFieldProps} from '@mui/material/TextField';

import HighlightedText from 'hsi/components/QueryEditor/HighlightedText';
import IconRouter from 'hsi/components/IconRouter';
import RawTextButton from 'hsi/containers/QueryBuilder/components/RawTextButton';

import {operators} from './config';

import {Theme, isV2} from 'hsi/types/theme';
import makeStyles from '@mui/styles/makeStyles';

import {T} from 'hsi/i18n';
import Heading, { HeadingContents } from 'hsi/components/aria/Heading';
import AnnounceMessageDebounced from 'hsi/components/aria/AnnounceMessageDebounced';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    searchBox: {
        margin: '28px 16px 16px 16px',
        '& svg': {
            width: '16px',
            height: '16px',
        },
    },
    noResults: {
        fontSize: '18px',
        textAlign: 'center',
        fontWeight: theme.font.semiBold,
    },
}));

type SearchBoxProps = {
    className?: string;
    onChange: TextFieldProps['onChange'];
};

const SearchBox = ({onChange, className}: SearchBoxProps) => (
    <TextField
        className={className}
        variant="outlined"
        type="search"
        placeholder={T('queryBuilder.searchHelpBoxPlaceholder')}
        onChange={onChange}
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <IconRouter name="search" aria-hidden />
                </InputAdornment>
            ),
        }}
    />
);

type ShowAdvancedButtonProps = {
    isOpen: boolean;
    onClick: React.MouseEventHandler;
};

const ShowAdvancedButton = ({onClick, isOpen}: ShowAdvancedButtonProps) => (
    <RawTextButton
        size="small"
        endIcon={!isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        onClick={onClick}
        aria-expanded={isOpen}
    >
        {isOpen ? T('queryBuilder.hideAdvanced') : T('queryBuilder.showAdvanced')}
    </RawTextButton>
);

const operatorStyles = makeStyles((theme: Theme) => {
    const v2 = isV2(theme);
    return {
        container: {
            margin: v2 ? '16px 20px' : '0 16px',
            ...(v2
                ? {}
                : {
                      padding: '8px 0 16px 0',
                      borderBottom: `1px solid ${theme.colors.darkGrey20}`,
                  }),
        },
        operator: {
            ...(v2
                ? {
                      fontWeight: theme.fonts.bodyLargeStrong.fontWeight,
                      fontSize: theme.fonts.bodyLargeStrong.fontSize,
                      paddingBottom: 10,
                  }
                : {fontWeight: 'bold'}),
        },
        description: {
            ...(v2 ? theme.fonts.bodyNormal : {}),
            marginBottom: 8,
        },
        queryContainer: {
            backgroundColor: v2 ? theme.colors.uiGrayDefault : theme.colors.lightGrey20,
            padding: v2 ? '16px 8px 16px 16px' : 4,
            ...(v2
                ? {
                      borderRadius: '3px',
                      fontWeight: theme.fonts.bodyNormal.fontWeight,
                      fontSize: theme.fonts.bodyNormal.fontSize,
                  }
                : {}),
        },
        query: {
            ...(v2 ? {} : {margin: 4}),
        },
    };
});

type OperatorProps = {
    description: string;
    operator: string;
    query: string;
};

function Operator({description, operator, query}: OperatorProps) {
    const cl = operatorStyles();
    return (
        <div className={cl.container} data-testid="searchHelpOperator">
            <Heading className={cl.operator}>{operator}</Heading>
            <HeadingContents>
                <div className={cl.description}>{description}</div>
                <div className={cl.queryContainer}>
                    <div className={cl.query}>
                        <HighlightedText text={query} />
                    </div>
                </div>
            </HeadingContents>
        </div>
    );
};

function AllOperators() {
    const [showAdvanced, setShowAdvanced] = useState(false);
    return (
        <>
            {operators
                .filter((d) => !d.advanced)
                .map((d) => (
                    <Operator {...d} key={d.operator} />
                ))}
            <ShowAdvancedButton
                onClick={() => setShowAdvanced((prev) => !prev)}
                isOpen={showAdvanced}
            />
            {!showAdvanced && <div style={{marginBottom: 40}} />}
            {showAdvanced &&
                operators
                    .filter((d) => !!d.advanced)
                    .map((d) => <Operator {...d} key={d.operator} />)}
        </>
    );
};

export default function Operators() {
    const cl = useStyles();
    const [searchTerm, setSearchTerm] = useState<string>();
    const term = searchTerm?.toLowerCase().trim() ?? '';
    const hasSearch = term !== '';

    const filteredOperators = useMemo(
        () => hasSearch ? operators.filter((d) => d.operator.toLowerCase().includes(term)) : undefined,
        [hasSearch, term]
    );

    const hasResults = (filteredOperators?.length ?? 0) > 0;

    const announceMessage = useMemo(() => {
        return hasSearch 
            ? hasResults 
                ? T('foundResults', {num: filteredOperators!.length}) 
                : T('noResults')
            : '';
    }, [hasSearch, hasResults, filteredOperators]);

    const onSearchChange = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) =>
        {
            setSearchTerm(e.target.value);
            
        }, 
        []
    );

    return (
        <div className={cl.container} data-testid="searchHelpOperators">
            <SearchBox onChange={onSearchChange} className={cl.searchBox} />
            {!hasSearch 
                ? <AllOperators /> 
                : hasResults 
                    ? filteredOperators!.map((filteredOperator) => (
                        <Operator {...filteredOperator} key={filteredOperator.operator} />
                    ))
                    : <div aria-hidden className={cl.noResults}>
                    {T('noResults')}
                </div>
            }
            <AnnounceMessageDebounced message={announceMessage} />
        </div>
    );
};
