import React, {RefObject, useCallback, useMemo} from 'react';

//Components
import HorizontalOverflow, {GetOverflowContent} from 'hsi/components/layout/HorizontalOverflow';
import IconRouter from 'hsi/components/IconRouter';
import SimpleMenu, { SimpleMenuEntry } from 'hsi/components/SimpleMenu';

//Hooks
import useStyles from './styles';
import useConfig from 'hsi/hooks/useConfig';
import useEventTrack from 'hsi/hooks/useEventTrack';

//utils
import {getRelativePos} from 'hsi/utils/scroll';

//Other
import {T} from 'hsi/i18n';
import isEmpty from 'lodash/isEmpty';
import { CARD_SECTION_PINNED, CARD_SECTION_HIDDEN } from 'hsi/constants/config';

//Types
type SearchResultsHeaderProps = {
    containerRef: RefObject<HTMLDivElement>;
    sectionElements: Record<string, Record<string, HTMLElement>>;
};


//The component
export default function SearchResultsHeader({
    containerRef,
    sectionElements,
}: SearchResultsHeaderProps) {
    const classes = useStyles();
    const {track} = useEventTrack();

    //config
    const {
        searchResults: {cardSectionsOrder: baseCardSectionOrder},
    } = useConfig();

    //Add special pinned & hidden sections, & 
    const cardSectionsOrder = useMemo(() => {
        return [CARD_SECTION_PINNED, ...baseCardSectionOrder, CARD_SECTION_HIDDEN];
    }, [baseCardSectionOrder]);

    //callbacks
    const getSectionElements = useCallback(
        (section: string) => Object.values(sectionElements[section] || {}).filter((e) => !!e),
        [sectionElements],
    );

    const scrollToHighestElementInList = useCallback(
        (elements: HTMLElement[]) => {
            const scrollElement = containerRef?.current;
            let curHighestElem: HTMLElement | null = null;

            if(!scrollElement) {
                return;
            }

            const highestPos = elements.reduce<ReturnType<typeof getRelativePos> | undefined>(
                (prevHighestPos, element) => {
                    const curHighestPos = getRelativePos(element, scrollElement);

                    if (!prevHighestPos) {
                        curHighestElem = element;

                        return curHighestPos;
                    }

                    if (prevHighestPos.top < curHighestPos.top) {
                        return prevHighestPos;
                    } else {
                        curHighestElem = element;

                        return curHighestPos;
                    }
                },
                undefined,
            );

            if (highestPos) {
                curHighestElem!?.querySelector('article')?.focus();

                scrollElement.scrollTop = highestPos.top;
            }
        },
        [containerRef],
    );

    const getOverflowContent = useCallback<GetOverflowContent>(
        (overflowIndex, totalChildren) => {
            return (
                <SimpleMenu
                    portal
                    buttonComponent={
                        <button className={classes.moreIcon}>
                            <IconRouter name="more-horizontal" className="more-horiz" />
                        </button>
                    }
                    entries={cardSectionsOrder
                        .map((section, index) =>
                            overflowIndex === -1 || index < overflowIndex
                                ? null
                                : {
                                      label: T('anchors.' + section) as string,
                                      disabled: isEmpty(sectionElements[section]),
                                      onClick: () =>
                                          scrollToHighestElementInList(getSectionElements(section)),
                                  },
                        )
                        .filter((entry) => !!entry) as SimpleMenuEntry[]}
                />
            );
        },
        [cardSectionsOrder, classes.moreIcon, getSectionElements, scrollToHighestElementInList, sectionElements],
    );

    const content = useMemo(
        () =>
            cardSectionsOrder.map((section, index) => {
                const elements = getSectionElements(section);
                const isSectionEmpty = elements.length === 0;
                const translatedSectionName = T(`anchors.${section}`);

                return (
                    <button
                        className={classes.anchor}
                        key={'anchor-' + section}
                        disabled={isSectionEmpty}
                        onClick={() => {
                            track('quickLinkClicked', {
                                buttonType: translatedSectionName,
                            });
                            return scrollToHighestElementInList(elements);
                        }}
                        aria-label={T(`anchorsLabel`, {name: translatedSectionName})}
                    >
                        {translatedSectionName}
                    </button>
                );
            }),
        [cardSectionsOrder, classes.anchor, getSectionElements, scrollToHighestElementInList, track],
    );

    return (
        <HorizontalOverflow className={classes.root} getOverflowContent={getOverflowContent}>
            {content}
        </HorizontalOverflow>
    );
}
