import {useId} from 'react';

export default function useUniqueId(id?: string | null, prefix: string = ''): string {
    const uniqueId = useId();

    if (id) {
        return id;
    }

    return `${prefix}${uniqueId}`;
}
