import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    root: {
        padding: '40px',
        paddingTop: '20vh',
        textAlign: 'center',
    },

    img: {
        width: 180,
    },

    title: {
        fontSize: 28,
        marginBottom: 20,
    },

    msg: {
        fontSize: 18,
        width: 500,
        maxWidth: '86vw',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});

export default useStyles;
