import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>(({colors, v2}) => ({
    table: {
        position: 'relative',
    },
    loadingBody: {
        position: 'relative',
        minHeight: 'var(--table-row-minHeight)',
    },
    loadingRow: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    loadingCell: {
        
    },
    caption: {
        display: 'none',
        position: 'absolute',
        bottom: '100%',
        left: 0,
        right: 0,
        border: v2
            ? `1px solid ${colors.uiGrayPopupBorder}`
            : `2px solid ${colors.secondaryPurple100}`,
        borderRadius: v2 ? 3 : undefined,
        padding: '4px',
        margin: '4px',
        background: 'white',
        boxShadow: '1px 2px 2px 0px rgba(0,0,0,0.1)',

        'table:has(:focus-visible) > &': {
            display: 'unset',
        }
    },
}));