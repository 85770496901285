// TODO: Add tests
import {useMemo} from 'react';

import useConfig from 'hsi/hooks/useConfig';
import useLinkedinIsEnabled from 'hsi/hooks/useLinkedinIsEnabled';
import useFlags from 'hsi/hooks/useFlags';
import {
    isLinkedinChannelSourceType,
    isLinkedinAuthenticationSourceType,
    isLinkedinSelectableSource,
} from 'hsi/utils/dataManagement/linkedin';

const useDataManagementConfig = () => {
    const config = useConfig();
    const isLinkedinEnabled = useLinkedinIsEnabled();
    const flags = useFlags();

    const selectableSources = useMemo(
        () =>
            config.dataManagement.selectableDataSources.filter(
                (source) => !isLinkedinSelectableSource(source?.id) || isLinkedinEnabled,
            ),
        [config.dataManagement.selectableDataSources, isLinkedinEnabled],
    );

    const tabs = useMemo(
        () =>
            config.dataManagement.tabs.filter(
                (dataSourceType) =>
                    (!isLinkedinChannelSourceType(dataSourceType?.id) &&
                        !isLinkedinAuthenticationSourceType(dataSourceType?.id)) ||
                    isLinkedinEnabled,
            ),
        [config.dataManagement.tabs, isLinkedinEnabled],
    );

    return useMemo(
        () => ({
            learnMoreLink: config.links.dataManagementLearnMore,
            maxDataSources: flags?.limitMaxDataSources
                ? config?.limitedDataSources
                : config?.maxDataSources,
            rootUrl: config.dataManagement.rootUrl,
            selectableSources,
            tabs,
        }),
        [config, selectableSources, tabs, flags],
    );
};

export default useDataManagementConfig;
