import React, {useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Button from 'hsi/components/Button';
import CheckboxList from 'hsi/components/CheckboxList';
import IconRouter from 'hsi/components/IconRouter';
import {MenuHeader, MenuFooter} from 'hsi/components/Menu';
import NoFacebookPagesFound from './NoFacebookPagesFound';

import {T} from 'hsi/i18n';

const useStyles = makeStyles(() => ({
    flexGrow: {
        flex: '10 0 auto',
    },
    footerBtn: {
        marginLeft: 8,
    },
    menuContent: {
        padding: 16,
    },
}));

type CheckboxOptionsMenuProps = {
    alwaysShowFooter?: boolean;
    defaultOptions?: any[];
    labelField?: string;
    menuTitle: string;
    NoPagesFoundComponent?: React.ElementType;
    onAddPages: (values: any[]) => void;
    onAddNewPage?: () => void;
    onBack: () => void;
    onCancel: () => void;
    options: any[];
    setLoading: (value: boolean) => void;
    valueField: string;
};

const CheckboxOptionsMenu = ({
    alwaysShowFooter = false,
    defaultOptions = [],
    labelField = 'name',
    menuTitle,
    NoPagesFoundComponent = NoFacebookPagesFound,
    onAddPages,
    onAddNewPage,
    onBack,
    onCancel,
    options,
    setLoading,
    valueField = 'pageId',
}: CheckboxOptionsMenuProps) => {
    const [selectedOptions, setSelectedOptions] = useState(defaultOptions);
    const cl = useStyles();
    const hasOptions = !!options?.length;

    return (
        <>
            <MenuHeader onBack={onBack}>{menuTitle}</MenuHeader>
            {hasOptions ? (
                <div className={cl.menuContent}>
                    <CheckboxList
                        onChange={setSelectedOptions}
                        options={options}
                        searchAutoFocus={true}
                        selectedOptions={selectedOptions}
                        showSearchAtLength={5}
                        {...{valueField, labelField}}
                    />
                </div>
            ) : (
                <NoPagesFoundComponent {...{onCancel, onBack, setLoading}} />
            )}
            {(hasOptions || alwaysShowFooter) && (
                <MenuFooter>
                    {onAddNewPage && (
                        <Button
                            color="primary"
                            onClick={onAddNewPage}
                            priority="text"
                            size="small"
                            startIcon={<IconRouter name="add" />}
                        >
                            {T('queryBuilder.addNewPage')}
                        </Button>
                    )}
                    <div className={cl.flexGrow} />
                    <Button
                        className={cl.footerBtn}
                        onClick={onCancel}
                        priority="primary"
                        size="small"
                    >
                        {T('queryBuilder.cancel')}
                    </Button>
                    {hasOptions && (
                        <Button
                            className={cl.footerBtn}
                            disabled={!selectedOptions?.length}
                            onClick={() => onAddPages(selectedOptions)}
                            priority="cta"
                            size="small"
                        >
                            {T('queryBuilder.addPagesButton')}
                        </Button>
                    )}
                </MenuFooter>
            )}
        </>
    );
};

export default CheckboxOptionsMenu;
