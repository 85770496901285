import {useEffect, useCallback, useState, useMemo} from 'react';

//Hooks
import {useAppSelector} from 'hsi/hooks/useRedux';
import usePersistLocal from './usePersistLocal';

//Other
import {selectSessionStart} from 'hsi/selectors';
import {TDateISO} from 'hsi/types/dates';

type HidePersistDataType = {
    lastSessionStart: TDateISO;
    timesLoadedSinceClose: number;
};

export default function useHideForXSessions(key: string, numSessions: number) {
    const [show, setShow] = useState(false);
    const {getItem, setItem} = usePersistLocal();
    const sessionStart = useAppSelector(selectSessionStart);

    useEffect(() => {
        (async () => {
            const value = await getItem<HidePersistDataType>(key);

            if (!value || value.timesLoadedSinceClose > numSessions) {
                setShow(true);
            }

            if (value && sessionStart !== value.lastSessionStart) {
                setItem(key, {
                    lastSessionStart: sessionStart,
                    timesLoadedSinceClose: value.timesLoadedSinceClose + 1,
                });
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onHide = useCallback(() => {
        setShow(false);
        setItem(key, {lastSessionStart: sessionStart, timesLoadedSinceClose: 0});
    }, [sessionStart, setItem, key]);

    return useMemo(
        () => ({
            show,
            onHide,
        }),
        [show, onHide],
    );
}
