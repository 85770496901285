const colors = {
    //accent
    accentActive: '#0084AF',
    accentDefault: '#007FA9',
    accentHover: '#0BA5D6',

    // borders
    borders: 'rgba(178, 178, 178, 0.5)',
    // sentiment
    positive: '#00C68D',
    negative: '#FF626B',

    // emotion
    disgust: '#8575CD',
    fear: '#FDD500',
    joy: '#FDE568',
    sadness: '#6B5DA3',

    // gender
    female: '#049F73',
    male: '#916CA8',
};

export default colors;
