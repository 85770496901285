import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);

    return {
        notification: {
            display: 'flex',
            flexFlow: 'column nowrap',
            gap: '16px',
            alignItems: 'center',
            padding: 16,
        },
        icon: {
            fill: theme.colors.primaryBlue100,
            height: 16,
            width: 16,
        },
        message: {
            display: 'flex',
            flexFlow: 'inherit',
            alignItems: 'inherit',
            gap: '4px',
        },
        title: {
            ...v2 ? theme.fonts.bodyLargeStrong : {fontSize: '13px', fontWeight: theme.font.bold},
        },
        description: {
            ...v2 ? theme.fonts.bodyNormal : {fontSize: '13px'},
        },
    };
});

