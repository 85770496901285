import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    topicWheelUpsell: {
        backgroundColor: theme.colors.lightGrey60,
        fontSize: '12px',
        padding: '12px',
        fontWeight: theme.font.semiBold,
        borderRadius: '4px',
        '& a': {
            textDecoration: 'none',
        },
    },
    topicWheelContents: {
        height: '360px',
        cursor: 'pointer',
    },
    container: {
        height: '360px',
        cursor: 'pointer',
    },
}));
