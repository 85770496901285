import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import Tippy from '@tippy.js/react';
import {useTheme} from '@mui/material';
import cn from 'classnames';

//Components
import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle from 'hsi/components/Card/CardTitle';
import ChartTooltipContent from 'hsi/components/ChartTooltipContent';

//Hooks
import useGetLoadData from 'hsi/components/Cards/useGetLoadData';
import {useGetPopup} from 'hsi/components/Cards/Gender';

//Utils
import {formatBigInt} from 'hsi/utils/formatNumber';
import {sortArrayOnProp} from 'hsi/utils/sorting';

//Other
import useStyles from './styles';
import useChartTooltipStyles from 'hsi/components/ChartTooltip/styles';
import {T} from 'hsi/i18n';

//Consts
const type = 'gender';

//The component
const Gender = React.forwardRef(function Gender(props, ref) {
    const {classes} = useStyles();
    const chartTooltipClasses = useChartTooltipStyles();
    const {colors} = useTheme();

    //Redux
    const {data, loading, loaded, error} = useSelector((state) => state.chart[type]);
    const isSelectedCard = useSelector((state) => state.mentions.drillInCard === type);

    //Calculated values
    const loadData = useGetLoadData(type);

    const popup = useGetPopup();

    const content = useMemo(() => {
        if (!data) {
            return null;
        }

        const sortedData = sortArrayOnProp([...data], 'id', (a, b) =>
            a === b ? 0 : a.toString() < b.toString() ? 1 : -1,
        );

        return (
            <div>
                <div className={classes.bar}>
                    {sortedData.map(({id, name, pct, value}) => (
                        <Tippy
                            key={id}
                            className={chartTooltipClasses.tooltip}
                            arrow
                            theme="dark"
                            content={
                                <ChartTooltipContent
                                    title={name}
                                    valueItems={[
                                        {
                                            color: colors[id],
                                            amount: formatBigInt(value),
                                            unit: T('mentions'),
                                        },
                                    ]}
                                />
                            }
                        >
                            <div
                                className={cn(classes.section, classes[id])}
                                style={{flex: `${pct}%`}}
                            ></div>
                        </Tippy>
                    ))}
                </div>
                <div className={classes.values}>
                    {sortedData.map(({id, name, pct}) => (
                        <div key={id} className={cn(classes.info, classes[id])}>
                            <div className={classes.value}>{pct}%</div>
                            <div className={classes.name}>{name}</div>
                        </div>
                    ))}
                </div>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classes, data, chartTooltipClasses, colors]);

    //Render
    return (
        <CardLoadState
            {...props}
            title={<CardTitle title={`cards.${type}.title`} tooltipComponent={popup} type={type} />}
            error={error}
            loading={loading}
            loaded={loaded}
            hasData={!!data}
            selected={isSelectedCard}
            loadData={loadData}
            ref={ref}
            data-testid={type}
            type={type}
        >
            {content}
        </CardLoadState>
    );
});

export default Gender;
