import makeStyles from '@mui/styles/makeStyles';
import {isV2, Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);

    return {
        root: {
            color: theme.colors.textDefault,
        },

        //Tag classes - just add a class here with the name of the HTML tag
        // and it will be applied to all tags with that name generated by the
        // markdown parser.
        // Avoid if AT ALL POSSIBLE using any cascading styles, as we may be
        // embedding components within the markdown in future, and cascading
        // styles may clash.
        p: {
            '&&': {
                //needed to increase specificity beyond badly written MUI styles
                textAlign: 'left',
                padding: 0,
                margin: 0,
                maxWidth: '100%',
                color: 'inherit', //theme.colors.textDefault

                ...(v2
                    ? theme.fonts.bodyNormal
                    : {
                          fontSize: '16px',
                      }),
            },

            '& + &': {
                marginTop: 4,
            },
        },
        strong: {
            fontWeight: theme.font.bold,
        },
        a: {
            ...theme.mixin.linkColor,
            textDecoration: 'none',
        },
        //TODO add styles for other tags
    };
});
