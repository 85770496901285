import {useEffect, useRef} from 'react';
import cn from 'classnames';
import {useMonaco} from '@monaco-editor/react';

import useStyles from './styles';

import {editorTheme, languageId} from './config/editorConfig';
import {bracketMatchingConfig, syntaxHighlightingConfig} from './config/languageConfig';
import useConfig from 'hsi/hooks/useConfig';

type HighlightedTextProps = {
    ellipsis?: boolean;
    text: string;
} & Omit<JSX.IntrinsicElements['div'], 'ref' | 'children'>;

const HighlightedText = ({text, className, ellipsis, ...rest}: HighlightedTextProps) => {
    const config = useConfig();
    const monaco = useMonaco();
    const ref = useRef<HTMLDivElement>(null);
    const classes = useStyles(config.editorConfig);

    useEffect(() => {
        if (monaco) {
            monaco.languages.register({id: languageId});
            monaco.languages.setMonarchTokensProvider(
                languageId,
                syntaxHighlightingConfig as any, // TODO: we shouldn't need to coerce this
            );
            monaco.languages.setLanguageConfiguration(
                languageId,
                bracketMatchingConfig as any, //TODO: we shouldn't need to coerce this
            );
            monaco.editor.defineTheme(
                'brandwatchTheme',
                editorTheme as any, // TODO: we shouldn't need to coerce this
            );
            monaco.editor.setTheme('brandwatchTheme');
        }
    }, [monaco]);

    useEffect(() => {
        if (monaco) {
            monaco.editor.colorize(text, languageId, {}).then((result: string) => {
                if (ref.current) {
                    ref.current.innerHTML = result.replace(
                        new RegExp(String.fromCharCode(160), 'g'),
                        ' ',
                    );
                }
            });
        }
    }, [text, monaco]);

    return (
        <div
            className={cn(classes.hlText, ellipsis && classes.hlTextEllipsis, className)}
            {...rest}
            ref={ref}
        >
            {text}
        </div>
    );
};

export default HighlightedText;
