import {makeStyles} from 'tss-react/mui';

export default makeStyles()((theme) => ({
    addMoreButton: {
        flex: '0 0 auto',
    },

    addMoreIcon: {
        fill: `${theme.colors.icons} !important`,
        ...theme.mixin.icon.normalSize,
    },
}));
