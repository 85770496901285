import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

const ColorSample = ({color, className, classes, style}) => (
    <div
        className={cn(classes.colorSample, className)}
        style={{...style, backgroundColor: color}}
    />
);

ColorSample.propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
};

ColorSample.defaultProps = {
    style: {},
};

export default withStyles(styles)(ColorSample);
