if((ReadableStream.prototype as any)[Symbol.asyncIterator] === undefined) {
    (ReadableStream.prototype as any)[Symbol.asyncIterator] = async function* () {
        const reader = this.getReader();
        try {
          while (true) {
            const {done, value} = await reader.read()
            if (done) return
            yield value
          }
        }
        finally {
          reader.releaseLock()
        }
      }
}