import ThemeBuilder from 'hsi/classes/ThemeBuilder';
import noResultsImg from 'hsi/themes/bw/img/no-results.png';

export default function elements(theme: ThemeBuilder) {

    return {
        searchBarContainerHeight: '64px',
        navBarContainerHeight: '64px',
        mentionsDrawerWidth: '424px',
        noResultsImg,
    };
}
