import { isFirefox } from 'react-device-detect';

import workerAsyncConnector from 'hsi/utils/workerAsyncConnector';
import loadData from './loadData';

// Once we upgrade to webpack 5, we can use this syntax:
//const worker = new Worker(new URL('./worker.js', import.meta.url));

//But as long as we have webpack 4, we need to use this syntax:
import Worker from './worker.js';
const worker = new (Worker as any)();
//end webpack 4

// firefox currently has a bug where the web worker is using a new cookie so auth is failing.
// so we don't use a web worker if its firefox.
// jira ticket: https://brandwatch.atlassian.net/browse/SW-13254 
const workerAsync = isFirefox ? {loadData} : workerAsyncConnector(worker, null); 

export default workerAsync as {
    loadData: typeof loadData
};



