import {useCallback} from 'react';

import {loadMentions, loadMentionsCursoredPagination} from 'hsi/slices/mentions';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useQueryContext from 'hsi/hooks/useQueryContext';
import {useAppDispatch, useAppSelector} from './useRedux';
import {QueryContextType} from 'hsi/types/query';

// If cursored = true AND this is a saved search, load mentions
// useing cursored pagination. This fixes some potential bugs and
// edge cases.
// //TODO refactor to always use cursored pagination? I think we no longer need non-cursored mode?
const useLoadMentions = (cursored: boolean = false) => {
    const dispatch = useAppDispatch();
    const {trackWithSearchData} = useEventTrack();
    const queryContext = useQueryContext() as QueryContextType;
    const isLoading = useAppSelector((state) => state.mentions.loading);

    return useCallback(
        ({append = false}: {append: boolean}) => {
            if (isLoading) {
                return;
            }

            if (append) {
                trackWithSearchData('mentionDrawerScrolled', {});
            }

            return dispatch(
                cursored && queryContext.searchType !== 'quick'
                    ? loadMentionsCursoredPagination({queryContext, append})
                    : loadMentions({queryContext, append}),
            );
        },
        [isLoading, cursored, dispatch, queryContext, trackWithSearchData],
    );
};

export default useLoadMentions;
