import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>(({v2, colors}) => ({
    tableRowPosition: {
        color: v2 ? colors.textSubtle : colors.darkGrey60,
    },
    favicon: {
        width: '16px',
        height: '16px',
        marginRight: '10px',
        verticalAlign: 'middle',
    },
}));
