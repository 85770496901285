import { useMemo, useState } from "react";
import { TableSortDir } from "../types";
import { SortColumnsDefinition } from "./types";


/**
 * Reuseable hook for handling sorting for a ManagedTable. Does not actually sort the data, just keeps track of what sorting
 * should be taking place.
 * @param columns The columns definition for the table being sorted
 * @param defaultSortColumn Optional - If supplied, will assume this is the sorted column if no other column selected
 * @returns onSortClick handler to be passed to a ManagedTable, as well as the current value for sortColumn and sortDir
 */
export default function useManagedSort<RowItemType, Columns extends string | number | symbol = keyof RowItemType>(columns: SortColumnsDefinition<RowItemType, Columns>, defaultSortColumn?: Columns) {
    const [sortColumn, setSortColumn] = useState<Columns | undefined>(defaultSortColumn);
    const [sortDirection, setSortDirection] = useState<TableSortDir | undefined>(() => (sortColumn && columns.find(({name}) => name === sortColumn)?.defaultSortDir) || "asc");

    return useMemo(() => ({
        onSortClick: (column: Columns) => {
            if(column === sortColumn) {
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
            } else {
                setSortColumn(column);

                //Find default sort column, & set sort direction
                const definition = column ? columns.find(({name}) => name === column) : undefined
                setSortDirection(definition?.defaultSortDir || "asc");
            }
        },
        sortColumn,
        sortDirection
    }), [columns, sortColumn, sortDirection]);
}