import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);

    return {
        closeButton: {
            position: 'absolute',
            zIndex: 2,
            cursor: 'pointer',
            margin: 0,

            ...(v2
                ? {
                      top: '19px',
                      right: '15px',
                  }
                : {
                      top: '20px',
                      right: '20px',
                  }),
            '& svg': {
                width: '16px',
                height: '16px',

                transform: 'scale(1.7)',
            },
        },
        //Component styles
        root: {
            display: 'flex',
            flexFlow: 'row nowrap',

            ...(v2
                ? {
                      //marginBottom: '24px',
                  }
                : {
                      marginBottom: '40px',
                  }),
        },
        loadingHolder: {
            position: 'absolute',
            zIndex: 1,
            top: '-16px',
            right: 0,
            bottom: '-16px',
            left: 0,
            background: 'rgba(0, 0, 0, 0.1)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        leftCol: {
            flex: '0 0 500px',
            width: '500px',
            ...(v2
                ? {
                      padding: '24px',
                      minWidth: 0,
                  }
                : {
                      padding: '0 40px',
                      background: '#FFF',
                      borderRight: `1px solid ${theme.colors.lightGrey100}`,
                  }),
        },
        rightCol: {
            flex: '1 1 auto',
            ...(v2
                ? {
                      padding: '24px',
                      background: theme.colors.recentSearchesBg,
                  }
                : {
                      padding: '0 40px',
                      background: '#FFF',
                      overflow: 'hidden',
                      borderLeft: `1px solid ${theme.colors.lightGrey100}`,
                  }),
        },

        //Left column styles
        seeOriginalAndDelete: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '24px',
            paddingTop: '3pt',
        },
        seeOriginalLink: {
            display: 'flex',
            alignItems: 'center',
            ...theme.mixin.linkColor,
            textDecoration: 'none',

            ...(v2
                ? {
                      gap: '6px',
                      ...theme.fonts.bodyLargeStrong,
                  }
                : {
                      gap: '4px',
                      fontSize: '16px',
                      fontWeight: theme.font.bold,
                  }),
        },
        seeOriginalIcon: {
            ...(v2
                ? {
                      fill: theme.colors.textHyperlink,
                  }
                : {
                      width: '16px',
                      height: '16px',
                  }),
        },
        deleteBtn: {
            ...(v2
                ? {
                      ...theme.mixin.linkColor,
                      padding: '5px',
                      marginRight: '-5px',
                  }
                : {
                      padding: '9px',
                      '&.disabled': {
                          opacity: 0.5,
                      },
                  }),
        },
        deleteBtnIcon: v2
            ? {
                  width: '20px',
                  height: '20px',
              }
            : {},

        //Right column styles
        sectionTitle: {
            ...(v2
                ? {
                      ...theme.fonts.headtitleStrong,
                      padding: '0 0 16px',
                      margin: 0,
                  }
                : {
                      padding: '0 0 24px',
                      margin: 0,
                      fontSize: '20px',
                      fontWeight: theme.font.bold,
                  }),
        },
        hr: v2
            ? {
                  border: 0,
                  height: 0,
                  borderTop: `1px dashed ${theme.colors.borders}`,
                  padding: 0,
                  margin: '16px 0',
              }
            : {
                  border: 0,
                  height: '1px',
                  background: theme.colors.lightGrey100,
                  padding: 0,
                  margin: '40px 0',
              },
        link: {
            textDecoration: 'none',
            fontWeight: theme.font.bold,
            ...theme.mixin.linkColor,
        },

        //author/source section
        source: {
            display: 'flex',
            alignItems: 'center',
        },
        avatar: {
            position: 'relative',
            display: 'block',
            flex: '0 0 48px',
            marginRight: '12px',
        },
        sourceAndHandle: {
            flex: '1 1 0',
            display: 'flex',
            flexFlow: 'column nowrap',
            minWidth: 0,
        },
        sourceName: {
            ...theme.mixin.truncate,
            ...(v2
                ? {
                      ...theme.fonts.bodyLargeStrong,
                  }
                : {
                      fontWeight: theme.font.bold,
                      fontSize: '16px',
                  }),
        },
        sourceHandle: {
            ...theme.mixin.truncate,
            ...(v2
                ? {
                      ...theme.fonts.bodyNormal,
                  }
                : {
                      fontSize: '16px',
                  }),
        },
        singleColMarginTop: {
            marginTop: '16px',
            columns: 1,
        },

        metricsList: {
            columns: 2,
            margin: 0,
            padding: 0,
        },

        iconAndText: {
            display: 'inline-flex',
            alignItems: 'center',
            gap: '4px',
        },
        sentimentIcon: {
            width: v2 ? '14px' : '16px',
            height: v2 ? '14px' : '16px',
            display: 'block',
        },
        neutral: {
            fill: theme.colors.neutral,
        },
        positive: {
            fill: theme.colors.positive,
        },
        negative: {
            fill: theme.colors.negative,
        },
        //emotion styles
        emotionIcon: {
            display: 'block',
            width: '12px',
            height: '12px',
            borderRadius: '100%',
        },
        emotionNAIcon: {
            width: '14px',
            height: '14px',
        },
        emotion_anger: {
            backgroundColor: theme.colors.anger,
        },
        emotion_disgust: {
            backgroundColor: theme.colors.disgust,
        },
        emotion_fear: {
            backgroundColor: theme.colors.fear,
        },
        emotion_joy: {
            backgroundColor: theme.colors.joy,
        },
        emotion_sadness: {
            backgroundColor: theme.colors.sadness,
        },
        emotion_surprise: {
            backgroundColor: theme.colors.surprise,
        },

        //navigation styles
        navigation: {
            flex: '1 1 auto',
            display: 'flex',
            justifyContent: 'center',
            gap: v2 ? '8px' : '12px',
            borderTop: v2 ? `1px solid ${theme.colors.bgSubtle}` : undefined,
            padding: v2 ? '20px' : undefined,
        },
        navBtn: v2
            ? {
                  border: `1px solid ${theme.colors.primaryBlue20}`,
                  padding: '11px',
                  position: 'relative',
                  color: theme.colors.primaryBlue100,

                  '&:after': {
                      ...theme.mixin.downArrow,
                      transform: 'rotate(-45deg)',
                      left: '7px',
                      top: '7px',
                  },

                  '&[disabled]': {
                      background: theme.colors.primaryBlue20,
                  },
              }
            : {
                  padding: '9px',
              },
        leftArrow: v2
            ? {
                  '&:after': {
                      transform: 'rotate(135deg)',
                      left: '8px',
                  },
              }
            : {},
        navBtnIcon: {
            display: v2 ? 'none' : undefined,
        },
        leftArrowIcon: v2
            ? {}
            : {
                  transform: 'scale(-1.14, 1.14) translateX(1.5px)',
              },
        rightArrowIcon: v2
            ? {}
            : {
                  transform: 'scale(1.14) translateX(1.5px)',
              },
        //dialog styles
        singleMentionView: v2
            ? {
                  color: theme.colors.textDefault,
                  '& .go': {
                      position: 'absolute',
                      bottom: '15px',
                  },
                  '& .link': {
                      ...theme.mixin.linkColor,
                      marginLeft: '6px',
                      textDecoration: 'none',
                      fontWeight: theme.fonts.buttonNormal.fontWeight,
                      fontSize: theme.fonts.buttonNormal.fontSize,
                      marginBottom: '17px',
                      display: 'inline-block',
                      cursor: 'pointer',
                  },
                  '& .MuiPaper-root': {
                      width: '900px',
                  },
                  '& .MuiDialogContent-root': {
                      padding: 0,
                  },
                  '& .MuiDialogActions-root': {
                      padding: 0,
                      height: 'auto',
                  },
              }
            : {
                  color: theme.colors.textDefault,
                  '& .MuiPaper-root': {
                      width: '1024px',
                  },
                  '& .MuiDialogTitle-root': {
                      margin: '0',
                      '&.MuiTypography-root': {
                          overflow: 'hidden',
                          whiteSpace: 'noWrap',
                          textOverflow: 'ellipsis',
                          fontWeight: 900,
                      },
                  },
                  '& .MuiDialogContent-root': {
                      padding: 0,
                  },
                  '& .info': {
                      margin: '0px 6px 26px 6px',
                      '& .category': {
                          fontSize: '16px',
                          fontWeight: theme.font.bold,
                          paddingBottom: '6px',
                      },
                      '& .value': {
                          fontSize: '16px',
                          fontWeight: theme.font.regular,
                      },
                  },
                  '& .go': {
                      position: 'absolute',
                      bottom: '15px',
                  },
                  '& .link': {
                      ...theme.mixin.linkColor,
                      marginLeft: '6px',
                      textDecoration: 'none',
                      fontWeight: theme.font.semiBold,
                      marginBottom: '17px',
                      display: 'inline-block',
                      cursor: 'pointer',
                  },
              },
    };
});
