import {DateTime} from 'luxon';
import tzdata from 'tzdata';

// Generic timezones aren't really supported on the API
// You can check this via /api/timezones
// TODO: We should use the list provided by BCR as the basis for this list in future
const timezoneExclusionList = {
    CET: true,
    CST6CDT: true,
    EET: true,
    EST: true,
    EST5EDT: true,
    Factory: true,
    GMT: true,
    'GMT+0': true,
    'GMT-0': true,
    GMT0: true,
    HST: true,
    MST7MDT: true,
    PST8PDT: true,
    'W-SU': true,
    UCT: true,
    Universal: true,
};

const zones = tzdata.zones;

export type TimezoneID = keyof Omit<typeof zones, keyof typeof timezoneExclusionList>;

export interface Timezone {
    id: TimezoneID;
    label: string;
}

const validTimezoneIDs = new Set<TimezoneID>(
    Object.keys(zones).filter(
        (timezone) =>
            DateTime.local().setZone(timezone).isValid &&
            !timezone.toLowerCase().startsWith('etc/') &&
            !timezoneExclusionList[timezone as keyof typeof timezoneExclusionList],
    ) as TimezoneID[],
);

export function isValidTimezone(timezone: string | TimezoneID): timezone is TimezoneID {
    return validTimezoneIDs.has(timezone as TimezoneID);
}

// As per the following issue luxon doesn't let you just export all of the timezones for some reason
// https://github.com/moment/luxon/issues/353
const validTimezones: Timezone[] = Array.from(validTimezoneIDs)
    .sort((a, b) => (a < b ? -1 : 1))
    .map((timezone) => {
        const localTimezone = DateTime.local().setZone(timezone);

        if (timezone !== localTimezone.toFormat('z')) {
            console.error(
                'If this is ever true, then it may break the typescript TimezoneID typing',
            );
            debugger;
        }

        return {
            id: timezone as TimezoneID,
            label: localTimezone.toFormat('(ZZZZ) z').replace(/_/g, ' '),
        };
    });

export default validTimezones;
