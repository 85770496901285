import React from 'react';
import Collapse from '@mui/material/Collapse';
import Badge from '@mui/material/Badge';

//Components
import IconRouter from 'hsi/components/IconRouter';

//Other
import useStyles from './styles';

//The component
export default function CollapsibleSection({
    children,
    label,
    compact = false,
    collapsed,
    onClickCollapse,
    headerContent = null,
    showBadge = false,
    className,
    labelProps = null,
    component: Component = 'div',
    ...rest
}) {
    const {classes, cx} = useStyles();

    return (
        <Component
            {...rest}
            className={cx(classes.section, compact && classes.compact, className)}
            data-testid={label}
        >
            <div className={cx(classes.sectionHead, compact && classes.compact)}>
                <button
                    onClick={onClickCollapse}
                    type="button"
                    className={cx(classes.sectionHeadButton, compact && classes.compact)}
                    aria-expanded={!collapsed}
                >
                    <IconRouter
                        className={cx(classes.icon, compact && classes.compact)}
                        name={collapsed ? 'hsi-arrow-right' : 'hsi-arrow-down'}
                    />
                    <Badge
                        overlap="rectangular"
                        color="primary"
                        variant="dot"
                        invisible={!showBadge || !collapsed}
                    >
                        <span
                            className={cx(classes.groupTitle, compact && classes.compact)}
                            {...labelProps}
                        >
                            {label}
                        </span>
                    </Badge>
                </button>
                {headerContent}
            </div>
            <Collapse in={!collapsed}>{children}</Collapse>
        </Component>
    );
}
