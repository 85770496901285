import React, {useRef, Fragment, PropsWithChildren} from 'react';
import cn from 'classnames';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';

import {MenuPaper} from './MenuPaper';

const useStyles = makeStyles((theme) => ({
    popper: {
        zIndex: theme.zIndex.modal + 1,
        maxWidth: 300,
    },
}));

type MenuPopperProps = PropsWithChildren<{
    anchorEl: Parameters<typeof Popper>[0]['anchorEl'];
    onClose: (event: MouseEvent | TouchEvent) => void
    menuPaperProps?: Parameters<typeof MenuPaper>[0];
    popperClassName?: string;
    flip?: boolean;
}>;

const MenuPopper = ({
    anchorEl,
    onClose,
    menuPaperProps,
    popperClassName,
    children,
    flip = false,
}: MenuPopperProps) => {
    const classes = useStyles();
    const popperRef = useRef<any>(null); //I can't work out how to type this correctly
    const updatePopper = () => popperRef.current?.forceUpdate();

    return !!anchorEl ? (
        <Fragment>
            <Popper
                open
                className={cn(classes.popper, popperClassName)}
                anchorEl={anchorEl}
                role="presentation"
                placement={'bottom'}
                popperRef={popperRef}
                modifiers={[
                    {
                        name: 'flip',
                        enabled: flip,
                    },
                ]}
            >
                <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown">
                    <MenuPaper {...menuPaperProps}>
                        {React.Children.map(children, (child) => {
                            if (React.isValidElement(child)) {
                                return React.cloneElement(child, {updatePopper} as any);
                            }
                            return child;
                        })}
                    </MenuPaper>
                </ClickAwayListener>
            </Popper>
            <Modal
                //TODO What does this do?
                open
                disableEscapeKeyDown
                disableAutoFocus
                disableEnforceFocus
                disableRestoreFocus
                hideBackdrop
            >
                <span />
            </Modal>
        </Fragment>
    ) : null;
};

export {MenuPopper};
