import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles(({colors, v2}: Theme) => ({
    searchBox: {
        '& .Mui-focused': {
            outline: v2
                ? `3px solid ${colors.accentActive}`
                : `3px solid ${colors.secondaryOrange100}`,
        },
    },
}));
