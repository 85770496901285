import React, {useState} from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import MuiIconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import IconRouter from 'hsi/components/IconRouter';
import InputChipsElement from './InputChipsElement';
import Toggle from 'hsi/components/Toggle';

import useComponentsBaseStyles from './baseStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

const useStyles = makeStyles({
    container: {
        alignItems: 'center',
        display: 'flex',
    },
    title: {
        margin: '0 10px 0 0',
    },
    toggle: {
        margin: '0 20px',
    },
});

const IconButton = withStyles((theme) => ({
    root: {
        padding: '3px',
        '& svg': {
            width: '17px',
            height: '17px',
        },
    },
}))(MuiIconButton);

const InputChipsToggle = ({
    chipElemProps,
    info,
    name,
    onChange,
    placeholder,
    sectionName,
    subtitle,
    title,
    values,
}) => {
    const ccl = useComponentsBaseStyles();
    const cl = useStyles();
    const [checked, setChecked] = useState(!isEmpty(values));

    const _onChange = (values) =>
        onChange({
            componentName: name,
            sectionName,
            values,
        });

    const toggleChecked = () => {
        setChecked((prev) => !prev);
        onChange({
            sectionName,
            componentName: name,
            values: [],
        });
    };

    const descriptionId = `${name}-desc`;

    return (
        <div>
            <div className={cn(ccl.container, cl.container)}>
                <div className={cn(ccl.title, cl.title)} id={name}>
                    {title}
                </div>
                {info && (
                    <Tooltip title={info} placement="top">
                        <IconButton aria-label="info" size="large">
                            <IconRouter name="info" />
                        </IconButton>
                    </Tooltip>
                )}
                <Toggle
                    className={cl.toggle}
                    checked={checked}
                    onChange={toggleChecked}
                    mode="switch"
                    name={name}
                />
            </div>

            {checked && (
                <div>
                    <div className={ccl.subtitle} id={descriptionId}>
                        {subtitle}
                    </div>
                    <InputChipsElement
                        defaultValue={values}
                        descriptionId={descriptionId}
                        labelId={name}
                        onChange={_onChange}
                        placeholder={placeholder}
                        {...chipElemProps}
                    />
                </div>
            )}
        </div>
    );
};

InputChipsToggle.propTypes = {
    chipElemProps: PropTypes.object.isRequired,
    info: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    sectionName: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
};

export default InputChipsToggle;
