import React from 'react';

//Components
import AsyncStatusAdornment from 'hsi/components/Filters/AsyncStatusAdornment';

//The function
export default function getAsyncStateProps(
    pending,
    error,
    errorMsg,
    disabled,
    value = null,
    skipTick = false,
) {
    return {
        endAdornment: (
            <AsyncStatusAdornment
                pending={pending}
                error={error}
                errorMsg={errorMsg}
                value={value}
                skipTick={skipTick}
            />
        ),
        disabled: !!(disabled || pending),
    };
}
