import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles(({colors, mixin, v2}: Theme) => ({
    dialog: {
        '& .MuiPaper-root': {
            height: v2 ? 680 : 600,
        },
    },
    infoSection: {
        display: 'flex',
        flexDirection: 'row',
    },
    infoIcon: {
        marginRight: 11,
        ...mixin.icon.bigSize,
    },
    infoIconValid: {
        fill: colors.success,
    },
    infoIconInvalid: {
        fill: colors.error,
    },
    infoTableWrapper: {
        width: '100%',
    },
    infoTable: {
        borderCollapse: 'collapse',
        background: colors.white,
        width: '100%',
        listStyle: 'none', 
        margin: 0,
        padding: 0,
        display: 'table',
    },
    infoRow: {
        margin: 0,
        padding: 0,
        width: '100%',
        display: 'table-row',
    },
    infoCell: {
        border: v2 ? `1px solid ${colors.borders}` : `1px solid ${colors.darkGrey60}`,
        padding: 10,
        display: 'table-cell',
    },
}));
