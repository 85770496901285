import React, {useMemo, useCallback} from 'react';

//Components
import InclusionSelect from 'hsi/components/Filters/InclusionSelect';
import MultiValueTextInput from 'hsi/components/Filters/MultiValueTextInput';
import ErrorMessage from 'hsi/components/Filters/ErrorMessage';

//Hooks
import useFiltersState from 'hsi/components/Filters/hooks/useFiltersState';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//The component
export default function IncludeAndExcludeFilter({
    compact,
    autoScrollDrawer = null,
    isSavedSearch,
    disabled,
    onChange,
    filterConf: {
        filterName,
        noExclusion,
        fields, //{fieldName, label, placeholder, validation, maxValues, limitLabel, autosuggestType, maxValues}
    },
}) {
    const classes = useStyles();

    const {
        useSelector,
        actions: {
            setIncludeAndExcludeMode,
            addValueIncludeAndExclude,
            removeValueIncludeAndExclude,
            setError,
            isValueValid,
        },
    } = useFiltersState();

    const filterState = useSelector((state) => state.filters[filterName]);
    const error = useSelector((state) => state.error[filterName]);
    const pending = useSelector((state) => state.pending[filterName]);

    //Callbacks
    const onIncludeModeChange = useCallback(
        (mode) => setIncludeAndExcludeMode(filterName, mode),
        [filterName, setIncludeAndExcludeMode],
    );

    const allChips = useMemo(() => {
        return fields.map((fieldConfig) => {
            const {fieldName, getLabel, getId} = fieldConfig;
            const fieldState = filterState.fields[fieldName];

            return fieldState
                ? [
                      ...fieldState.include.map((value) => ({
                          actionType: 'c-add-2',
                          include: true,
                          key: (getId && getId(value)) || value,
                          label: (getLabel && getLabel(value)) || value,
                          value,
                      })),
                      ...fieldState.exclude.map((value) => ({
                          actionType: 'c-delete-2',
                          include: false,
                          key: (getId && getId(value)) || value,
                          label: (getLabel && getLabel(value)) || value,
                          value,
                      })),
                  ]
                : [];
        });
    }, [filterState, fields]);

    //Calculated values
    const textfields = useMemo(
        () =>
            fields.map((fieldConfig, i) => {
                const chips = allChips[i];
                const {
                    autosuggestType,
                    displayChipInsideField,
                    fieldName,
                    getLabel,
                    hidden,
                    label,
                    limitLabel,
                    maxValues,
                    placeholder,
                } = fieldConfig;

                if (hidden) {
                    return null;
                }

                const errorMsg =
                    error && error[fieldName] ? (
                        <ErrorMessage error={error[fieldName]} wide />
                    ) : null;

                return (
                    <MultiValueTextInput
                        key={fieldName}
                        placeholderText={placeholder ? T(placeholder) : null}
                        labelText={label ? T(label) : null}
                        autoScrollDrawer={autoScrollDrawer}
                        autosuggestType={autosuggestType}
                        chips={chips}
                        displayChipInsideField={displayChipInsideField}
                        maxValues={maxValues}
                        limitLabel={limitLabel}
                        onBeforeAdd={(value) => {
                            if (!value) {
                                return false;
                            }

                            const okOrErrorCode = isValueValid(
                                filterName,
                                value,
                                fieldName,
                                filterState.activeModeIsInclude,
                                true,
                            );

                            if (okOrErrorCode !== true) {
                                //not ok, set error
                                setError(filterName, {...error, [fieldName]: okOrErrorCode});

                                return false;
                            }

                            return true;
                        }}
                        onAdd={(value) => {
                            addValueIncludeAndExclude(
                                filterName,
                                fieldName,
                                filterState.activeModeIsInclude,
                                value,
                            );

                            onChange &&
                                onChange(
                                    filterName,
                                    'includeAndExclude',
                                    fieldName,
                                    filterState.activeModeIsInclude,
                                    (getLabel && getLabel(value)) || value,
                                );
                        }}
                        onDelete={(chip) => {
                            chip && removeValueIncludeAndExclude(filterName, fieldName, chip);
                        }}
                        onInputChange={() =>
                            error[fieldName] && setError(filterName, {...error, [fieldName]: null})
                        }
                        disabled={!!disabled}
                        pending={!!pending[fieldName]}
                        error={!!error[fieldName]}
                        errorMsg={errorMsg}
                    />
                );
            }),
        [
            addValueIncludeAndExclude,
            allChips,
            autoScrollDrawer,
            disabled,
            error,
            fields,
            filterName,
            filterState,
            isValueValid,
            onChange,
            pending,
            removeValueIncludeAndExclude,
            setError,
        ],
    );

    //Render
    return (
        <div className={classes.root}>
            <InclusionSelect
                include={filterState.activeModeIsInclude}
                onChange={onIncludeModeChange}
                disabled={disabled}
                noExclusion={!!noExclusion}
            />
            {textfields}
        </div>
    );
}
