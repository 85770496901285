import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
    root: {
        '& .MuiPaper-root': {
            maxWidth: '410px',
        },
    },
    loadingHolder: {
        position: 'absolute',
        zIndex: 1,
        top: '-16px',
        right: 0,
        bottom: '-16px',
        left: 0,
        background: 'rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    sentiments: {
        display: 'flex',
        flexDirection: 'column',
        gridGap: 8,
    },
    sentiment: {
        marginRight: 8,
        padding: '10px 28px',
        '.MuiFormControlLabel-label': {
            fontSize: 16,
        },
        '.MuiFormControlLabel-asterisk': {
            display: 'none',
        },
    },
    
}));
