import React from 'react';
import PropTypes from 'prop-types';

import InputChipsElement from './InputChipsElement';

import useComponentsBaseStyles from './baseStyles';

const InputChips = ({
    chipElemProps,
    contentBottom,
    name,
    onChange,
    placeholder,
    sectionName,
    subtitle,
    title,
    values,
}) => {
    const ccl = useComponentsBaseStyles();

    const _onChange = (values) =>
        onChange({
            sectionName,
            componentName: name,
            values,
        });

    const descriptionId = `${name}-desc`;

    return (
        <div className={ccl.container}>
            <div className={ccl.title} id={name}>
                {title}
            </div>
            <div className={ccl.subtitle} id={descriptionId}>
                {subtitle}
            </div>
            <InputChipsElement
                defaultValue={values}
                descriptionId={descriptionId}
                labelId={name}
                onChange={_onChange}
                placeholder={placeholder}
                {...chipElemProps}
            />
            {contentBottom}
        </div>
    );
};

InputChips.propTypes = {
    chipElemProps: PropTypes.object,
    contentBottom: PropTypes.any,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    sectionName: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
};

export default InputChips;
