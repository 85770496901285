import {makeStyles} from 'tss-react/mui';

export default makeStyles()((theme, _params, classes) => ({
    disabled: {}, //This needs to exist even if it is empty so it can be referred to elsewhere, as $disabled
    compact: {}, //ad above

    wrapper: {
        marginLeft: theme.v2 ? 24 : 29,
        border: 0,
        padding: 0,
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: 12,

        [`&.${classes.compact}`]: {
            gap: 4,
        },
    },
    rangeFieldInputs: {
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
    },
    rangeMinInput: {
        flex: '0 0 60px',
    },
    rangeToLabel: {
        flex: '0 0 36px',
        textAlign: 'center',
        fontSize: 16,
    },
    rangeMaxInput: {
        flex: '0 0 60px',
    },
    fieldLabel: {
        ...theme.mixin.offscreen,
    },
}));
