import cn from 'classnames';

import IconRouter from 'hsi/components/IconRouter';
import RawTextButton from 'hsi/containers/QueryBuilder/components/RawTextButton';

import {Theme} from 'hsi/types/theme';
import makeStyles from '@mui/styles/makeStyles';

import tns from 'hsi/i18n/tns';
const t = tns('queryBuilder');

const useStyles = makeStyles(({colors, mixin, v2}: Theme) => ({
    text: {
        textTransform: 'none',
    },
    container: {
        '& .MuiButton-text': {
            ...(v2 ? mixin.linkColor : {color: colors.secondaryBlue100}),
            '& svg': {
                ...mixin.linkColor,
            },
        },
        '& .MuiButton-label': {
            ...(v2 ? {textTransform: 'none'} : {}),
        },
        '& svg': {
            ...(v2 ? mixin.linkColor : {fill: colors.secondaryBlue100}),
            marginRight: 5,
        },
    },
}));

type SearchHelpButtonProps = {
    open?: boolean;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const SearchHelpButton = ({onClick, className, open}: SearchHelpButtonProps) => {
    const cl = useStyles();
    return (
        <div className={cn(cl.container, className)}>
            <RawTextButton size="small" onClick={onClick} aria-expanded={open}>
                <IconRouter aria-hidden name="pin2" />
                <span className={cl.text}>{t('searchHelpButton')}</span>
            </RawTextButton>
        </div>
    );
};

export default SearchHelpButton;
