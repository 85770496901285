import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import useFlags from 'hsi/hooks/useFlags';

type useConditionProps = {
    condition?: (x: any) => boolean;
    id: string;
    selectConditionState: (x: any) => any;
};

const useCondition = ({id, condition, selectConditionState}: useConditionProps) => {
    const flags = useFlags();
    const state = useSelector(selectConditionState || ((state) => state));
    const [passed, setPassed] = useState<boolean | null>(null);

    const getCondition = useCallback(async () => {
        if (!condition) {
            setPassed(true);
            return;
        }

        const response = await condition({flags, ...state});

        setPassed(response);
    }, [condition, flags, state]);

    useEffect(() => {
        getCondition();
    }, [getCondition, id]);

    return id && passed;
};

export default useCondition;
