import {
    FacebookPageItem,
    InstagramAccountItem,
    InstagramHashtagItem,
    TargetedDataPlatform,
} from 'hsi/types/dataManagement';

type GuidedSearchFacebookPageType = {
    enabled: boolean;
    fbPageId: string;
    name: string;
    owned: boolean;
    type: TargetedDataPlatform.Facebook;
};

type GuidedSearchInstagramAccountType = {
    igbid: string;
    name: string;
    username: string;
    owned: boolean;
    type: TargetedDataPlatform.Instagram;
};

type GuidedSearchInstagramHashtagType = {
    igbid: string;
    name: string;
    type: TargetedDataPlatform.Instagram;
};

type GuidedSearchTwitterAccountType = {
    name: string;
    username: string;
    type: TargetedDataPlatform.Twitter;
};

export type GuidedSearchTargetedDataTypes =
    | GuidedSearchFacebookPageType
    | GuidedSearchInstagramAccountType
    | GuidedSearchInstagramHashtagType
    | GuidedSearchTwitterAccountType;

export const isGuidedSearchFacebookPage = (
    item: GuidedSearchTargetedDataTypes,
): item is GuidedSearchFacebookPageType => item?.type === TargetedDataPlatform.Facebook;

export const isGuidedSearchFacebookOwnedPage = (
    item: GuidedSearchTargetedDataTypes,
): item is GuidedSearchFacebookPageType => isGuidedSearchFacebookPage(item) && item.owned;

export const isGuidedSearchFacebookNonOwnedPage = (
    item: GuidedSearchTargetedDataTypes,
): item is GuidedSearchFacebookPageType => isGuidedSearchFacebookPage(item) && !item.owned;

export const isGuidedSearchInstagramAccount = (
    item: GuidedSearchTargetedDataTypes,
): item is GuidedSearchInstagramAccountType =>
    item.type === TargetedDataPlatform.Instagram &&
    (item as GuidedSearchInstagramAccountType)?.owned !== undefined;

export const isGuidedSearchInstagramOwnedAccount = (
    item: GuidedSearchTargetedDataTypes,
): item is GuidedSearchInstagramAccountType => isGuidedSearchInstagramAccount(item) && item.owned;

export const isGuidedSearchInstagramNonOwnedAccount = (
    item: GuidedSearchTargetedDataTypes,
): item is GuidedSearchInstagramAccountType => isGuidedSearchInstagramAccount(item) && !item.owned;

export const isGuidedSearchInstagramHashtag = (
    item: GuidedSearchTargetedDataTypes,
): item is GuidedSearchInstagramHashtagType =>
    item.type === TargetedDataPlatform.Instagram && !isGuidedSearchInstagramAccount(item);

export const isGuidedSearchTwitterAccount = (
    item: GuidedSearchTargetedDataTypes,
): item is GuidedSearchTwitterAccountType => item?.type === TargetedDataPlatform.Twitter;

export const inputAccountValueParser = {
    facebook: (item: FacebookPageItem): GuidedSearchFacebookPageType => ({
        enabled: item.enabled,
        fbPageId: item.id,
        name: item.name,
        owned: item.owned,
        type: TargetedDataPlatform.Facebook,
    }),
    instagram: (item: InstagramAccountItem): GuidedSearchInstagramAccountType => ({
        igbid: item.id,
        name: item.name,
        username: item.name,
        owned: item.owned,
        type: TargetedDataPlatform.Instagram,
    }),
    instagramHashtag: (item: InstagramHashtagItem): GuidedSearchInstagramHashtagType => ({
        igbid: item.linkedAccountId,
        name: item.name,
        type: TargetedDataPlatform.Instagram,
    }),
    twitter: (item: string): GuidedSearchTwitterAccountType => ({
        name: item,
        username: item,
        type: TargetedDataPlatform.Twitter,
    }),
};
