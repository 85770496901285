import React, {useState, useCallback} from 'react';

import Popover from 'hsi/components/Popover';
import Tooltip from 'hsi/components/Tooltip';

import {mergeRefs} from 'hsi/utils/react';

import useStyles from './styles';
import {ReactElement, ReactNode} from 'react-markdown';

type WithMenuAndTooltipProps = {
    children: ReactNode;
    menu?: Parameters<typeof Popover>[0]['content'];
    tooltip?: ReactNode;
    tooltipNoAria?: boolean;
    onMenuHide?: () => void;
    onMenuShow?: () => void;
};

//The component
const WithMenuAndTooltip = ({
    children,
    menu,
    tooltip,
    onMenuHide,
    onMenuShow,
    tooltipNoAria = false,
}: WithMenuAndTooltipProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const onShow = useCallback(() => {
        setIsOpen(true);
        onMenuShow?.();
    }, [onMenuShow]);

    const onHide = useCallback(() => {
        setIsOpen(false);
        onMenuHide?.();
    }, [onMenuHide]);

    const withMenu = menu ? (
        <Popover overlay={false} portal content={menu} onShow={onShow} onHide={onHide}>
            {children}
        </Popover>
    ) : (
        children
    );

    return tooltip ? (
        <WrapWithTooltip isOpen={isOpen} tooltip={tooltip} noAria={tooltipNoAria}>
            {withMenu}
        </WrapWithTooltip>
    ) : (
        (withMenu as ReactElement)
    );
};

export default WithMenuAndTooltip;

type WrapWithTooltipProps = {
    children: ReactNode;
    tooltip: ReactNode;
    noAria?: boolean;
    isOpen?: boolean;
};

const WrapWithTooltip = React.forwardRef<Element, WrapWithTooltipProps>(
    ({children, tooltip, isOpen, noAria = false}, ref) => {
        const classes = useStyles();
        const childrenArray = React.Children.toArray(children);

        if (childrenArray.length > 1) {
            throw new Error('Please supply a single child element');
        }

        const child = childrenArray[0];

        const childWithRef = React.cloneElement(child as any, {
            ref: mergeRefs(ref, (child as any).ref),
        });

        return (
            <Tooltip
                disable={!!isOpen} //disable if menu opened
                tooltip={<div className={classes.tooltipContent}>{tooltip}</div>}
                noAria={noAria}
            >
                {childWithRef}
            </Tooltip>
        );
    },
);
