//This is a temporary fix for pageTypes changing dynamically.
//I have been unable to come up with a way to make this work
//better without major changes.

// TODO: Add test

import {useEffect, useRef} from 'react';
import isEqual from 'lodash/isEqual';

import {useActiveQueryPageTypes} from 'hsi/hooks/usePageTypes';
import useQueryContext from 'hsi/hooks/useQueryContext';
import {useAppSelector} from 'hsi/hooks/useRedux';

import {updateFilterOptions} from 'hsi/slices/filters';

import {selectLinkedinChannelIds} from 'hsi/selectors';

import {AppDispatch} from 'hsi/utils/configureStore';
import {PageType} from 'hsi/types/shared';
import mapPageTypesToOptions from 'hsi/utils/filters/mapPageTypesToOptions';

export default function useTempUpdateFilterOptions(dispatch: AppDispatch) {
    const queryContext = useQueryContext();
    const linkedinChannelIds = useAppSelector(selectLinkedinChannelIds);
    const newPageTypes = useActiveQueryPageTypes();
    const prevPageTypes = useRef<PageType[]>();

    // TODO: REMOVE THIS AS SOON AS YOU CAN!!!
    // This is to handle the fact that we don't update isSavedSearch until after a search has been saved
    // Once the query slice has been refactored this should definitely be remove!!!
    const newPageTypesWithLinkedinCheck =
        !queryContext?.isSavedSearch || !linkedinChannelIds?.length
            ? newPageTypes.filter((pageType) => pageType.value !== 'linkedin')
            : newPageTypes;

    useEffect(() => {
        if (!isEqual(newPageTypesWithLinkedinCheck, prevPageTypes.current)) {
            dispatch(
                (updateFilterOptions as any)({
                    filterName: 'pageType',
                    options: mapPageTypesToOptions(newPageTypesWithLinkedinCheck),
                }),
            );
            prevPageTypes.current = newPageTypesWithLinkedinCheck;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPageTypesWithLinkedinCheck]);
}
