import React from 'react';
import PropTypes from 'prop-types';

import SessionBlockedError from './SessionBlockedError';
import AuthErrorPage from './AuthErrorPage';

const UnauthorizedScreen = ({errors, status, code}) =>
    status === 401 && code === 'NO_AUTH_SESSION' ? (
        <SessionBlockedError />
    ) : (
        <AuthErrorPage {...{errors, status, code}} />
    );

UnauthorizedScreen.propTypes = {
    code: PropTypes.string,
    errors: PropTypes.array,
    status: PropTypes.number,
};

export default UnauthorizedScreen;
