import {useCallback, useMemo, useState} from 'react';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import qs from 'qs';

export const statusMessage = (uuid, payload) => ({
    type: 'fetch-data-status',
    uuid,
    payload: {
        ...payload,
        state: 'DONE',
    },
});

export const errorMessage = (message) => ({
    type: 'fetch-data-status',
    payload: {
        message,
        state: 'FAILED',
    },
});

export const getColumnsById = (columns) =>
    columns.reduce((res, col) => {
        res[col.id] = col;
        return res;
    }, {});

export const nextSortOrder = (type) => {
    if (!type) return sortTypes[0];
    const i = sortTypes.findIndex((t) => type === t);
    if (i < 0) return sortTypes[0];
    const ii = i + 1 < sortTypes.length ? i + 1 : 0;
    return sortTypes[ii];
};

export const sortMessagePayload = (payload, {columnId}) => ({
    ...payload,
    sort_by: columnId,
    sort_order: nextSortOrder(payload?.sort_order),
});

export const initMessagePayload = ({options, metadata}) => ({
    //TODO schema,
    options,
    metadata,
});

export const messageMaker = {
    error:
        ({uuid: _uuid, payload: _payload}) =>
        (err) =>
            errorMessage(String(err)),

    'loading-data':
        ({uuid: _uuid, payload: _payload}) =>
        () =>
            undefined,
    //() => statusMessage(uuid, { loading_data: 'TRUE' }),

    'no-data':
        ({uuid, payload: _payload}) =>
        () =>
            statusMessage(uuid, {no_data: 'TRUE'}),

    'data-loaded':
        ({uuid, payload: _payload}) =>
        () =>
            statusMessage(uuid, {}), //success event

    'dataset-loaded':
        ({uuid: _uuid, payload: _payload}) =>
        () =>
            undefined,
    //() => statusMessage(uuid, { dataset_loaded: 'TRUE' }),

    'sort-table':
        ({uuid: _uuid, payload}) =>
        (data) => ({
            type: 'change-widget',
            payload: sortMessagePayload(payload, data),
        }),

    'table-init':
        ({uuid: _uuid, payload: _payload}) =>
        (data) => ({
            type: 'fetch-data-init',
            payload: initMessagePayload(data),
        }),
};

export const makeMessage =
    ({uuid, payload}) =>
    (type, data) => {
        if (typeof messageMaker[type] !== 'function') {
            console.warn('[MentionsTable] unsupported message type:', type);
            return;
        }
        return messageMaker[type]({uuid, payload})(data);
    };

export const actionPayloadFromMessage = (payload, {columns, columnsById}) => ({
    searches: searchesFromMessage(payload),
    dateRange: dateRangeFromMessage(payload),
    filters: filtersFromMessage(payload),
    sort: sortFromMessage(payload, {columns, columnsById}),
});

export const isUpdateAction = (data) => data?.type === 'fetch-data';

export const isTableMessage = (data) => data?.payload?.visualization_type === 'table';

export const actionFromMessage =
    (actions, {columns, columnsById}) =>
    (data) => {
        let action;
        Object.keys(actions).some((key) => {
            action = actions[key](data, {columns, columnsById});
            return !!action;
        });
        if (!action) console.warn('Unsupported action from message', data);
        return action;
    };

//XXX see grpc/services/dataSources.js
export const deserializeSourceData = (sourceData) => qs.parse(sourceData);

export const searchesFromMessage = (payload) => {
    const sources = payload?.sources;

    if (!Array.isArray(sources)) return [];

    return sources.map(({id, query}) => ({
        searchId: deserializeSourceData(id)?.id,
        query,
    }));
};

export const dateRangeFromMessage = (payload) => {
    const timeframeField = payload?.primary_timeframe ? 'primary_timeframe' : 'timeframe';
    const initStartDate = payload?.[timeframeField]?.since;
    const initEndDate = payload?.[timeframeField]?.until;
    const startDate =
        typeof initStartDate === 'number'
            ? moment.unix(initStartDate).utc().toISOString()
            : initStartDate;
    const endDate =
        typeof initEndDate === 'number'
            ? moment.unix(initEndDate).utc().toISOString()
            : initEndDate;

    if (isEmpty(startDate) || isEmpty(endDate)) return;

    return {startDate, endDate};
};

export const filtersFromMessage = (payload) => {
    const filtersData = payload?.filters || {};

    const newFilters = Object.keys(filtersData).reduce((filters, key) => {
        const filter = filtersData[key];

        if (!Array.isArray(filter)) {
            console.warn('Received invalid filter:', key, filter);
            return filters;
        }

        filters[key] = filter.map(({id}) => id);

        return filters;
    }, {});

    return newFilters;
};

//map sort params to https://lodash.com/docs/4.17.11#orderBy
export const sortMap = {ascending: 'asc', descending: 'desc'};

export const sortTypes = Object.keys(sortMap).map((d) => d.toUpperCase());

export const sortFromMessage = (payload, {columns, columnsById}) => {
    const criteria = payload?.sort_by;
    const order = payload?.sort_order ? payload?.sort_order.toLowerCase() : undefined;

    if (criteria === undefined || order === undefined) return;

    const col = columns?.[parseInt(criteria)] || columnsById?.[criteria];

    if (!col) {
        console.warn('Received invalid [sort_by]:', criteria);
        return;
    }

    if (!sortMap[order]) {
        console.warn('Received invalid [sort_order]:', order);
        return;
    }

    const notNullValues = (order, value) => {
        const isAscending = order === 'ascending';
        if (typeof value !== 'string' && isNaN(value)) {
            return isAscending ? Infinity : -Infinity;
        }
        // for nulls and for gender with value 'Unknown'
        const calculatedValue = value && value !== 'Unknown' ? value : '';
        return isAscending ? value : calculatedValue;
    };

    const dataKey = col?.dataKeys?.[0];
    const parser = col?.parsers?.[dataKey];

    //const keys = [d => (parser ? parser(d[dataKey]) : d[dataKey])];
    const keys = [
        (d) =>
            parser ? notNullValues(order, parser(d[dataKey])) : notNullValues(order, d[dataKey]),
    ];
    const orders = [sortMap[order]];

    return {keys, orders, criteria};
};

const useHootsuiteMessageParser = ({columns}) => {
    const [payload, setPayload] = useState();
    const [uuid, setUuid] = useState();

    const columnsById = useMemo(() => getColumnsById(columns || []), [columns]);

    const updateAction = useCallback((data, {columns, columnsById}) => {
        if (!isUpdateAction(data)) return;

        setPayload(data?.payload);
        setUuid(data?.uuid);

        return {
            type: 'update',
            payload: actionPayloadFromMessage(data?.payload, {columns, columnsById}),
        };
    }, []);

    const actions = useMemo(
        () => ({
            update: updateAction,
        }),
        [updateAction],
    );

    if (!columns) return;

    return {
        makeMessage: makeMessage({uuid, payload}),
        actionFromMessage: actionFromMessage(actions, {columns, columnsById}),
        isTableMessage,
        sortTypes,
    };
};

export default useHootsuiteMessageParser;
