import React, {useCallback, useState} from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';

import RadioGroup, {RadioGroupProps} from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

//Components
import {Dialog, DialogContent, DialogActions} from 'hsi/components/Dialog';
import TextField, {TextFieldProps} from 'hsi/components/TextField';
import Button from 'hsi/components/Button';
import Radio from 'hsi/components/Radio';
import EntitiesRemaining from 'hsi/components/EntitiesRemaining';
import PendingDisplay from 'hsi/components/PendingDisplay';

//Hooks
import {useAppDispatch} from 'hsi/hooks/useRedux';
import useFlags from 'hsi/hooks/useFlags';
import useConfig from 'hsi/hooks/useConfig';
import useEventTrack from 'hsi/hooks/useEventTrack';

//Actions
import {exportMentionsToPDF, exportMentionsToCSV} from 'hsi/actions/exportActions';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';
import {getMentionsExportAllowance} from 'hsi/services/exportService';

//Consts
import {getMentionsExportAllowanceQueryKey} from 'hsi/constants/queryKeys';
import {QueryContextType} from 'hsi/types/query';

interface ExportMentionsDialogProps {
    queryContext?: QueryContextType;
    checkExportAllowance: boolean;
    onClose: () => void;
}

type ExportFormat = keyof ReturnType<typeof useConfig>['exportMentions'];

//The component
export default function ExportMentionsDialog({
    queryContext,
    onClose,
    checkExportAllowance = false,
}: ExportMentionsDialogProps) {
    const isDialogOpen = !!queryContext; //Not a fan of this

    const {classes} = useStyles();
    const flags = useFlags();
    const {appSource, exportMentions: config} = useConfig();
    const {trackWithSearchData} = useEventTrack();

    const dispatch = useAppDispatch();

    const defaultFormat = config.PDF.enabled ? 'PDF' : config.CSV.enabled ? 'CSV' : undefined;
    const [format, setFormat] = useState<ExportFormat | undefined>(defaultFormat);
    const selectedFormat = config[format!];
    const [numToExport, setNumToExport] = useState(
        selectedFormat && selectedFormat.enabled ? selectedFormat.defaultNum ?? 0 : 0,
    );

    const {min: minNumToExport, max: maxNumToExport} = selectedFormat.enabled
        ? selectedFormat
        : {min: 0, max: 0};

    const queryClient = useQueryClient();

    const queryData = useQuery({
        queryKey: getMentionsExportAllowanceQueryKey,
        queryFn: getMentionsExportAllowance,
        enabled: checkExportAllowance && isDialogOpen, //do not automatically execute, will fetch when dialog is opened
    });

    const maxNumToExportWithAllowance = Math.min(
        maxNumToExport,
        queryData.data?.limit ?? Number.MAX_SAFE_INTEGER,
        queryData.data?.remaining ?? Number.MAX_SAFE_INTEGER,
    );

    const isNumValid = numToExport >= minNumToExport && numToExport <= maxNumToExportWithAllowance;

    //Callbacks
    const onClickExportToPDF = useCallback(() => {
        trackWithSearchData('mentionDrawerExported', {
            count: numToExport,
            format: 'pdf',
            num: numToExport,
        });
        dispatch(exportMentionsToPDF(queryContext!, numToExport, flags!, appSource));
    }, [dispatch, queryContext, flags, appSource, numToExport, trackWithSearchData]);

    const onClickExportToCSV = useCallback(() => {
        trackWithSearchData('mentionDrawerExported', {
            format: 'csv',
            count: numToExport,
            num: numToExport,
        });
        dispatch(exportMentionsToCSV(queryContext!, numToExport));
    }, [trackWithSearchData, dispatch, queryContext, numToExport]);

    const onFormSubmit = useCallback(() => {
        switch (format) {
            case 'PDF':
                onClickExportToPDF();
                break;
            case 'CSV':
                onClickExportToCSV();
                break;
            default:
                return; //no valid format selected, ignore
        }

        onClose?.();
    }, [format, onClickExportToPDF, onClickExportToCSV, onClose]);

    const reset = useCallback(() => {
        setFormat(defaultFormat);
        const defaultFormatConfig = config[defaultFormat!];
        defaultFormatConfig &&
            defaultFormatConfig.enabled &&
            setNumToExport(defaultFormatConfig.defaultNum);
        queryClient.removeQueries({queryKey: getMentionsExportAllowanceQueryKey});
    }, [config, defaultFormat, queryClient]);

    const handleFormatChange = useCallback<NonNullable<RadioGroupProps['onChange']>>(
        (event) => setFormat(event.target.value as any),
        [],
    );

    const onChangeNumToExport = useCallback<NonNullable<TextFieldProps['onChange']>>((event) => {
        setNumToExport(+event.target.value);
    }, []);

    //Render
    return (
        <Dialog
            title={T('exportMentionsDialog.title')}
            titleInfo={
                checkExportAllowance ? (
                    <PendingDisplay minWidth="122px" isLoaded={queryData.isFetched}>
                        <EntitiesRemaining
                            title={T('exportMentionsDialog.allowance.title')}
                            content={T('exportMentionsDialog.allowance.copy')}
                            maxEntities={queryData.data?.limit ?? 0}
                            numEntities={queryData.data?.used ?? 0}
                        />
                    </PendingDisplay>
                ) : undefined
            }
            onClose={onClose}
            onClosed={reset}
            open={isDialogOpen}
        >
            <DialogContent>
                <FormControl component="fieldset">
                    <FormLabel component="legend" className={classes.label}>
                        {T('exportMentionsDialog.format')}
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-label={T('exportMentionsDialog.format')}
                        name="format"
                        value={format}
                        onChange={handleFormatChange}
                    >
                        {config.PDF.enabled && (
                            <FormControlLabel
                                value="PDF"
                                control={<Radio />}
                                label={T('exportMentionsDialog.formats.PDF')}
                            />
                        )}
                        {config.CSV.enabled && (
                            <FormControlLabel
                                value="CSV"
                                control={<Radio />}
                                label={T('exportMentionsDialog.formats.CSV')}
                            />
                        )}
                    </RadioGroup>
                </FormControl>
            </DialogContent>

            <DialogContent>
                <FormControl error={!isNumValid}>
                    <FormLabel htmlFor="exportMentionsDialog.num" className={classes.label}>
                        {T('exportMentionsDialog.num')}
                    </FormLabel>
                    <TextField
                        className={classes.numberInput}
                        type="number"
                        id="exportMentionsDialog.num"
                        onChange={onChangeNumToExport}
                        value={numToExport}
                        error={!isNumValid}
                        InputProps={{
                            inputProps: {
                                min: minNumToExport,
                                max: maxNumToExportWithAllowance,
                                maxLength: maxNumToExport.toString().length,
                                step: '1',
                                'aria-describedby': 'exportMentionsDialog.numInfo',
                            },
                        }}
                    />
                    <FormHelperText id="exportMentionsDialog.numInfo">
                        {T('exportMentionsDialog.numInfo', {
                            min: minNumToExport,
                            max: maxNumToExport,
                        })}
                    </FormHelperText>
                </FormControl>
            </DialogContent>

            <DialogActions>
                <Button priority="secondary" onClick={onClose}>
                    {T('cancel')}
                </Button>
                <Button priority="cta" onClick={onFormSubmit} disabled={!format || !isNumValid}>
                    {T('exportMentionsDialog.exportBtn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
