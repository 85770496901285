import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    list: {
        margin: 0,
        padding: 0,
        display: 'block',
        minWidth: '280px',
    },

    filter: {
        padding: '0 0 8px 45%',
        position: 'relative',
        textAlign: 'left',

        '&:last-child': {
            paddingBottom: 0,
        },
    },

    filterName: {
        position: 'absolute',
        top: 0,
        right: '55%',
        margin: 0,
        padding: 0,
        paddingRight: '8px',
        whiteSpace: 'nowrap',

        ...(theme.v2
            ? theme.typgrphy.bodyNormal
            : {
                  fontWeight: theme.font.bold,
              }),
    },
    semicolon: {},
    filterValue: {
        display: 'inline',
        margin: 0,
        padding: 0,

        ...(theme.v2 ? theme.typgrphy.bodyNormal : {}),

        '&:after': {
            content: "',\\0020'",
        },

        '&$semicolon:after': {
            content: "';\\0020'",
        },

        '&:last-child:after': {
            content: "''",
        },
    },
}));
