import React from 'react';
import {useSelector} from 'react-redux';

//Components
import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle from 'hsi/components/Card/CardTitle';

//Utils
import {formatBigInt} from 'hsi/utils/formatNumber';

//Other
import useStyles from './styles';
import useGetLoadData from '../useGetLoadData';

//Constants
const type = 'totalMentions';

//The components
const TotalMentions = React.forwardRef(({title, ...props}, ref) => {
    const classes = useStyles();

    //Redux
    const {data, loading, loaded, error} = useSelector((state) => state.chart[type]);
    const hasData = !!data;

    //Callbacks
    const loadData = useGetLoadData(type);

    //Render
    return (
        <CardLoadState
            {...props}
            title={<CardTitle title={title} hasData={hasData} type={type} />}
            error={error}
            loading={loading}
            loaded={loaded}
            hasData={hasData}
            loadData={loadData}
            ref={ref}
            data-testid={type}
            type={type}
        >
            <div>
                <div className={classes.total}>{formatBigInt(data)}</div>
            </div>
        </CardLoadState>
    );
});

TotalMentions.displayName = 'TotalMentions';

export default TotalMentions;
