import React, {useCallback, useEffect, useMemo, useState} from 'react';
import find from 'lodash/find';
import {useDispatch, useSelector} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';

import AlertDialog from 'hsi/components/AlertDialog';
import ConfirmDialog from 'hsi/components/ConfirmDialog';
import HeaderBar from 'hsi/components/HeaderBar';
import PulseLoader from 'hsi/components/PulseLoader';
import SortableTable from 'hsi/components/SortableTable';

import useDates from 'hsi/hooks/useDates';
import useEventTrack from 'hsi/hooks/useEventTrack';

import {loadAlerts, deleteAlert, updateAlert} from 'hsi/slices/alerts';

import {T} from 'hsi/i18n';

import useStyles from './styles';
import {HeadingContents} from 'hsi/components/aria/Heading';

const Alerts = () => {
    const classes = useStyles();
    const dates = useDates();
    const dispatch = useDispatch();
    const {track} = useEventTrack();

    const alerts = useSelector((state) => state.alerts.alerts);
    const projects = useSelector((state) => state.user.projects);
    const searches = useSelector((state) => state.search.searches);
    const userIsViewOnly = useSelector((state) => state.user.isViewOnly);

    const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
    const [editAlertOpen, setEditAlertOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const savedSearch = useMemo(
        () => find(searches, {id: selectedItem?.queryId}) || null,
        [searches, selectedItem?.queryId],
    );

    const toggleEditAlert = useCallback((alert) => {
        setEditAlertOpen((editAlertOpen) => !editAlertOpen);
        setSelectedItem(alert);
    }, []);

    const pauseOrResumeAlert = useCallback(
        (alert) => {
            const editedAlert = {
                ...alert,
                enabled: !alert.enabled,
                projectId: find(searches, {id: alert.queryId}).project.id,
            };

            const msg = editedAlert.enabled
                ? T('alert.resumed', {name: editedAlert.name})
                : T('alert.paused', {name: editedAlert.name});

            dispatch(updateAlert({alert: editedAlert, successMessage: msg, onSuccess: undefined}));
        },
        [dispatch, searches],
    );

    const toggleDeleteAlert = useCallback((alert) => {
        setDeleteAlertOpen((deleteAlertOpen) => !deleteAlertOpen);
        setSelectedItem(alert);
    }, []);

    const onDeleteAlert = useCallback(() => {
        const alert = {
            ...selectedItem,
            projectId: find(searches, {id: selectedItem.queryId}).project.id,
            enabled: false,
            deleted: true,
        };

        dispatch(
            deleteAlert({
                alertId: alert.id,
                successMessage: T('alert.deleted', {name: alert.name}),
            }),
        );
        setDeleteAlertOpen(false);
        setSelectedItem(null);
    }, [dispatch, searches, selectedItem]);

    const fields = [
        {
            id: 'name',
            label: T('name'),
            width: '25%',
            showTooltip: true,
        },
        {
            id: 'queryName',
            label: T('saved.search'),
            width: '20%',
            showTooltip: true,
            format: (queryName, item) => {
                const projectId = find(searches, {id: item.queryId})?.project?.id;
                const linkTo = !!projectId ? `/search/results/${projectId}/${item.queryId}` : '#';
                return <Link to={linkTo}>{queryName}</Link>;
            },
        },
        {
            id: 'additionalRecipients',
            label: T('recipients'),
            width: '15%',
            format: (recipients) =>
                recipients?.length === 1
                    ? T('one.recipient')
                    : T('num.recipients', {num: recipients.length}),
        },
        {
            id: 'enabled',
            label: T('status'),
            width: '15%',
            format: (status) => (status ? T('active') : T('paused')),
        },
        {
            id: 'created',
            label: T('created'),
            width: '20%',
            format: (date) => dates.formatTo(date, 'ccc, MMM dd yyyy'),
        },
    ];

    useEffect(() => {
        track('viewed', {});
        dispatch(loadAlerts({projects}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects]);

    if (userIsViewOnly) {
        return <Redirect to="/" />;
    }

    if (alerts === null) return <PulseLoader className={classes.loading} size="large" />;

    return (
        <>
            <HeaderBar backButtonUrl="/" title={T('emailAlerts.title')} />
            <HeadingContents>
                {alerts === null && <PulseLoader className={classes.loading} size="large" />}
                {alerts !== null && alerts.length === 0 && (
                    <div className={classes.noAlerts}>
                        <p className={classes.noAlertsMsg}>{T('no.email.alerts.found')}</p>
                        <p className={classes.noAlertsInfo}>{T('you.can.create.an.alert')}</p>
                    </div>
                )}
                {alerts !== null && alerts.length > 0 && (
                    <div className={classes.container} data-testid="alertsTable">
                        <SortableTable
                            caption={T('alerts')}
                            defaultSort="created"
                            fields={fields}
                            items={alerts}
                            itemTypeName={T('alerts')}
                            menuEntries={[
                                {
                                    label: T('edit'),
                                    onClick: toggleEditAlert,
                                },
                                {
                                    formatLabel: (item) =>
                                        item.enabled ? T('pause') : T('resume'),
                                    onClick: pauseOrResumeAlert,
                                },
                                {
                                    label: T('delete'),
                                    onClick: toggleDeleteAlert,
                                },
                            ]}
                            onSorted={(sortData) =>
                                track('tableSorted', {
                                    ...sortData,
                                })
                            }
                        />
                    </div>
                )}
            </HeadingContents>

            <AlertDialog
                alert={selectedItem}
                closeFunc={toggleEditAlert}
                open={editAlertOpen}
                savedSearch={savedSearch}
                title={T('edit.email.alert')}
            />

            <ConfirmDialog
                body={T('confirm.delete.alert', {
                    alert: (selectedItem && selectedItem.name) || 'N/A',
                })}
                cancelLabel={T('cancel')}
                closeFunc={toggleDeleteAlert}
                confirmLabel={T('delete')}
                confirmFunc={onDeleteAlert}
                open={deleteAlertOpen}
                showCannotUndo
                title={T('delete.alert')}
                type="warning"
            />
        </>
    );
};

Alerts.propTypes = {};

export default Alerts;
