import {isV2} from 'hsi/types/theme';
import {makeStyles} from 'tss-react/mui';

export default makeStyles()((theme) => {
    const v2 = isV2(theme);

    return {
        mentionsContainer: {
            flex: 1,
            minHeight: '200px',
            display: 'flex',
            flexFlow: 'column nowrap',
            minWidth: 0,
        },

        drillIn: {
            padding: v2 ? '0 20px' : 0,
            backgroundColor: '#d9f3fd',
            display: 'flex',
            alignItems: 'center',
            height: '55px',
        },

        drillInIcon: {
            flex: '0 0 24px',
            width: '24px',
            height: '24px',
            fill: theme.colors.textMeta,
            marginLeft: '16px',
            marginRight: '9px',
        },

        drillInMsgContent: {
            flex: '4 1 auto',
            fontSize: '14px',
            position: 'relative',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },

        drillInMsgLabel: {
            fontWeight: 600,
        },
        drillInMsgValue: {
            position: 'absolute',
            left: '62px',
            right: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        drillInClearBtn: {
            flex: '0 0 auto',
            marginRight: '5px',
            backgroundColor: 'inherit',
            border: 'none',
            padding: '0 12px',
            '&& .MuiButton-label': {
                textTransform: 'uppercase',
            },
            '&:active, &:focus': {
                boxShadow: 'none',
            },
        },

        body: {
            flex: '1 1 auto',
            position: 'relative',
            minHeight: 80,
            paddingTop: 6,
            paddingBottom: '20px',
        },
        scroll: {
            display: 'flex',
            flexFlow: 'column nowrap',
            gap: '16px',
        },
        helpIcon: {
            '& svg': {
                fill: v2 ? theme.colors.primaryBlue100 : undefined,
            },
        },
    };
});
