import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => {
    return {
        cardArea: {
            position: 'relative',
            flex: '1 1 0',
            padding: '3px 10px 10px 10px',
            margin: '0 8px',
        },
    };
});
