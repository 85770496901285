import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {setMentionSelected, clearSelectedMentions} from './mentions';
import {T} from 'hsi/i18n';
import {WritableDraft} from 'immer/dist/internal';

type AriaLivePriority = 'assertive' | 'polite';

export type AriaMessage = {
    message: string;
    priority: AriaLivePriority;
    timestamp: number;
    id: number;
};

export type AriaAnnounceStateType = {
    lastId: number;
    messages: AriaMessage[];
};

const initialState: AriaAnnounceStateType = {
    lastId: 0,
    messages: [],
};

const slice = createSlice({
    name: 'ariaAnnounce',
    initialState,
    reducers: {
        addMessage: (
            state,
            {
                payload: {message, priority = 'polite'},
            }: PayloadAction<{message: string; priority?: AriaLivePriority}>,
        ) => {
            addAMessage(state, message, priority);
        },
    },
    extraReducers(builder) {
        builder
            .addCase(
                setMentionSelected.fulfilled,
                (state, {payload: {selected, newNumSelected, maxSelectedMentions}}) => {
                    addAMessage(
                        state,
                        T(
                            selected
                                ? 'mentionsContainer.mentionSelected'
                                : 'mentionsContainer.mentionUnselected',
                            {num: newNumSelected, max: maxSelectedMentions},
                        ),
                        'assertive',
                    );
                },
            )
            .addCase(clearSelectedMentions, (state) => {
                addAMessage(state, T('mentionsContainer.clearedSelectedMentions'), 'polite');
            });
    },
});

export const {addMessage} = slice.actions;
export default slice.reducer;

function addAMessage(
    state: WritableDraft<AriaAnnounceStateType>,
    message: string,
    priority: AriaLivePriority,
) {
    state.messages.push({message, priority, timestamp: Date.now(), id: state.lastId++});
}
