import React from 'react';
import cn from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

import IconRouter from 'hsi/components/IconRouter';
import Heading from 'hsi/components/aria/Heading';

import useConfig from 'hsi/hooks/useConfig';

import {T} from 'hsi/i18n';

const useStyles = makeStyles((theme) => {
    if (theme.v2)
        return {
            container: {
                display: 'flex',
                alignItems: 'center',
                color: theme.colors.textDefault,
                fontWeight: theme.fonts.headtitleNormal.fontWeight,
                fontSize: theme.fonts.headtitleNormal.fontSize,
                justifyContent: 'space-between',
            },
        };
    else
        return {
            container: {
                display: 'flex',
                alignItems: 'center',
                color: theme.colors.secondaryPurple10,
                fontWeight: 'bold',
            },
        };
});

const BackButton = withStyles((theme) => {
    if (theme.v2)
        return {
            root: {
                backgroundColor: 'unset',
                padding: '5px',
                margin: '0 5px',
                order: 2,
                '&:hover': {
                    backgroundColor: 'transparent',
                },
                '&:focus': {
                    backgroundColor: 'transparent',
                },
                '& svg': {
                    fill: theme.colors.bgOverlayHeavy,
                    width: '24px',
                    height: '24px',
                },
            },
        };
    else
        return {
            root: {
                backgroundColor: 'unset',
                padding: '5px',
                margin: '0 5px',
                '&:hover': {
                    backgroundColor: theme.colors.secondaryPurple80,
                },
                '&:focus': {
                    backgroundColor: theme.colors.secondaryPurple80,
                },
                '& svg': {
                    fill: theme.colors.secondaryPurple10,
                },
            },
        };
})(IconButton);

const Header = ({className, label, onBack, id}) => {
    const cl = useStyles();
    const {appSource} = useConfig();

    return (
        <div className={cn(cl.container, className)}>
            <BackButton aria-label="Exit guided search" onClick={onBack}>
                {appSource === 'HSI' ? <ArrowBackIcon /> : <IconRouter name="cross" />}
            </BackButton>
            <Heading id={id}>{T('queryBuilder.headerTitle', {label})}</Heading>
        </div>
    );
};

Header.propTypes = {
    classname: PropTypes.string,
    label: PropTypes.string.isRequired,
    onBack: PropTypes.func.isRequired,
};

export default Header;
