import {useEffect, useMemo, useState} from 'react';
import debounce from 'lodash/debounce';

import useGetIsMounted from 'hsi/hooks/useGetIsMounted';
import {DebounceSettings} from 'lodash';

/**
 * Hook to allow eaily debounced values
 * 
 * @param value the value to debounce
 * @param wait the time to debounce, in milliseconds
 * @param options debounce options, see the lodash docs for debounce
 * @returns an array where the first value is the deboucned value, and the second is a cancel method, which will prevent any upcoming changes
 */
export default function useDebouncedValue<T>(value: T, wait: number, options?: DebounceSettings) {
    const isMounted = useGetIsMounted();
    const [debouncedValue, setDebouncedValue] = useState(value);

    const setDebouncedState = useMemo(() => {
        return debounce(
            (value: T) => {
                isMounted() && setDebouncedValue(value);
            },
            wait,
            options,
        );
    }, [isMounted, options, wait]);

    useEffect(() => {
        setDebouncedState(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return [debouncedValue, setDebouncedState.cancel] as const;
}
