import {useMemo, useState} from 'react';
import cn from 'classnames';

//Components
import Button from 'hsi/components/Button';
import {CheckableMenuItem, MenuWrapper} from 'hsi/components/SelectableMenuItem';

//Hooks
import {useAppDispatch} from 'hsi/hooks/useRedux';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useProjectId from 'hsi/hooks/useProjectId';

//Types
import {MentionType} from 'hsi/types/mentions';

//Other
import {EMOTIONS_CATEGORIES} from 'hsi/constants/config';
import {updateMentionsEmotion} from 'hsi/slices/mentions';
import useSingleMentionViewStyles from '../styles';
import useStyles from './styles';
import {T} from 'hsi/i18n';
import getEmotionsFromMention from 'hsi/utils/mentions/getEmotionsFromMention';

type EmotionMenuContentProps = {
    mention: MentionType;
    close: () => void;
    labelId?: string;
    descriptionId?: string;
};

export default function EmotionMenuContent({
    mention,
    labelId,
    descriptionId,
    close: closeMenu,
}: EmotionMenuContentProps) {
    const classes = useStyles();
    const smvClasses = useSingleMentionViewStyles();
    const dispatch = useAppDispatch();
    const {trackWithSearchData} = useEventTrack();
    const projectId = useProjectId() ?? undefined;

    const initialEmotions = useMemo(() => getEmotionsFromMention(mention), [mention]);
    const [emotions, setEmotions] = useState(initialEmotions);

    const onApply = async () => {
        closeMenu?.();

        const result = await dispatch(
            updateMentionsEmotion({ids: [mention.id], emotions, projectId}),
        );

        if (result.meta.requestStatus === 'fulfilled') {
            trackWithSearchData('mentionEdited', {
                mention: mention?.id,
                type: 'emotion',
                value: result.payload,
            });
        }
    };

    return (
        <>
            <span className="offscreen" id={labelId}>
                {T('emotionMenu.label')}
            </span>
            <span className="offscreen" id={descriptionId}>
                {T('emotionMenu.description')}
            </span>
            <MenuWrapper className={classes.menu}>
                {EMOTIONS_CATEGORIES.map((em) => (
                    <CheckableMenuItem
                        key={em}
                        icon={
                            <span
                                className={cn(smvClasses.emotionIcon, smvClasses[`emotion_${em}`])}
                            />
                        }
                        checked={!!emotions[em]}
                        className="capitalize-label"
                        onChange={(val) => setEmotions((emotions) => ({...emotions, [em]: val}))}
                    >
                        {T(`emotions.${em}`)}
                    </CheckableMenuItem>
                ))}
            </MenuWrapper>

            <div className={classes.buttonWrapper}>
                <Button priority={'cta'} onClick={onApply} fullWidth>
                    Apply
                </Button>
            </div>
        </>
    );
}
