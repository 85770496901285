import {useState, useCallback, useEffect, useMemo, useRef} from 'react';
import cn from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Popper from '@mui/material/Popper';

import AccountChip from './AccountChip';
import {MenuPaper as _MenuPaper} from 'hsi/components/Menu';
import PulseLoader from 'hsi/components/PulseLoader';
import SelectAccounts from './SelectAccounts';
import TextField from 'hsi/components/TextField';

import useComponentsBaseStyles from '../baseStyles';
import {useAppSelector} from 'hsi/hooks/useRedux';
import useDataSourceCount from 'hsi/hooks/useDataSourceCount';

import {Theme, isV2} from 'hsi/types/theme';
import {PopperProps} from '@mui/material/Popper';
import {GuidedSearchTargetedDataTypes} from '../../utils';

const MenuPaper: any = _MenuPaper; // TODO: Needs to be updated once component is TS friendly

const useStyles = makeStyles((theme: Theme) => {
    const v2 = isV2(theme);
    return {
        inputRoot: {
            paddingLeft: 9,
            paddingTop: 3,
            paddingBottom: 3,
            flexWrap: 'wrap',
            display: 'flex',
            paddingRight: 26 + 4 + 9,
            minHeight: v2 ? 46 : 44,
        },
        endAdornment: {
            position: 'absolute',
            right: 9,
            top: 'calc(50% - 11px)',
        },
        arrowOpen: {
            transform: 'rotate(180deg)',
        },
        popper: {
            zIndex: theme.zIndex.modal,
        },
        paper: {
            padding: 0,
            position: 'relative',
        },
        loading: {
            backgroundColor: 'rgba(255,255,255, 0.8)',
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 2,
        },
        loadingAnim: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
    };
});

type InputAccountsProps = {
    name: string;
    onChange: (args: {
        sectionName: string;
        componentName: string;
        values: GuidedSearchTargetedDataTypes[];
    }) => void;
    placeholder: string;
    sectionName: string;
    subtitle: string;
    title: string;
    values: GuidedSearchTargetedDataTypes[];
};

const InputAccounts = ({
    name,
    onChange,
    placeholder,
    sectionName,
    subtitle,
    title,
    values,
}: InputAccountsProps) => {
    const cl = useStyles();
    const ccl = useComponentsBaseStyles();
    const info = useDataSourceCount();

    const popperRef = useRef<any | null>(null); // TODO: Update any once our own popper is used

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [loading, setLoading] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [popupPlacement, setPopupPlacement] = useState<PopperProps['placement']>('bottom');

    const fbAuthInProcess = useAppSelector(
        (state) =>
            state.facebookAuthentication.isFacebookLoginDialogOpen ||
            state.facebookAuthentication.isAddOwnedDialogOpen,
    );

    const closeMenu = useCallback(
        // TODO: Check this
        () => !loading && !fbAuthInProcess && setMenuOpen(false),
        [fbAuthInProcess, loading],
    );

    const handleChange = useCallback(
        (values: GuidedSearchTargetedDataTypes[]) => {
            onChange({
                sectionName,
                componentName: name,
                values,
            });
            closeMenu();
        },
        [closeMenu, name, onChange, sectionName],
    );

    const onItemDelete = useCallback(
        (index: number) => {
            const valuesCopy = values.slice();
            valuesCopy.splice(index, 1);
            handleChange(valuesCopy);
        },
        [handleChange, values],
    );

    const updatePopper = useCallback(() => popperRef?.current?.update(), []);

    useEffect(() => {
        if (anchorEl) {
            try {
                const top = anchorEl?.getBoundingClientRect().top + window.scrollY;
                const placement = top + 484 >= window.innerHeight ? 'top' : 'bottom';
                setPopupPlacement(placement);
            } catch (e) {
                console.log('error determining account popup placement', e);
            }
        }
    }, [anchorEl]);

    const hasValues = useMemo(() => values.length > 0, [values]);

    return (
        <div className={ccl.container}>
            <div className={ccl.title} id={name}>
                {title}
            </div>
            <div className={ccl.subtitle} id={`${name}-desc`}>
                {subtitle}
            </div>
            <TextField
                fullWidth
                inputProps={{
                    autoCapitalize: 'none',
                    autoComplete: 'off',
                    spellCheck: 'false',
                    style: {display: hasValues ? 'none' : undefined},
                }}
                label={name}
                placeholder={!hasValues ? placeholder : undefined}
                value={''}
                InputProps={{
                    startAdornment: (values || []).map(({name, type}, index) => (
                        <AccountChip
                            accountType={type}
                            label={name}
                            onDelete={() => onItemDelete(index)}
                            key={`${index}-${name}`}
                        />
                    )),
                    endAdornment: (
                        <div className={cl.endAdornment}>
                            <KeyboardArrowDownIcon className={cn(menuOpen && cl.arrowOpen)} />
                        </div>
                    ),
                    classes: {
                        root: cl.inputRoot,
                    },
                    readOnly: true,
                    onClick: () => {
                        !menuOpen && setMenuOpen(true);
                    },
                    ref: setAnchorEl,
                }}
            />
            {menuOpen && !!anchorEl && (
                <Popper
                    open
                    className={cl.popper}
                    style={{
                        width: anchorEl?.clientWidth ?? null,
                    }}
                    anchorEl={anchorEl}
                    role="presentation"
                    placement={popupPlacement}
                    popperRef={popperRef}
                    modifiers={[
                        {
                            name: 'flip',
                            enabled: true,
                        },
                    ]}
                    disablePortal
                >
                    <ClickAwayListener onClickAway={closeMenu}>
                        <MenuPaper className={cl.paper}>
                            {loading && (
                                <div className={cl.loading}>
                                    <PulseLoader size="small" className={cl.loadingAnim} />
                                </div>
                            )}
                            <SelectAccounts
                                {...{
                                    info,
                                    menuLabel: `${name} menu`,
                                    onCancel: closeMenu,
                                    onChange: handleChange,
                                    setLoading,
                                    updatePopper,
                                    values,
                                }}
                            />
                        </MenuPaper>
                    </ClickAwayListener>
                </Popper>
            )}
        </div>
    );
};

export default InputAccounts;
