import React from 'react';
import PropTypes from 'prop-types';

//Hooks
import useConfig from 'hsi/hooks/useConfig';

//Utils
import {formatBigIntFull} from 'hsi/utils/formatNumber';

//Other
import useStyles from './styles';

export type QuickPieProps = {
    max: number;
    num: number;
    size?: number;
};

export default function QuickPie({max, num, size = 60}: QuickPieProps) {
    const classes = useStyles();

    const {themeColors} = useConfig(); //Not a fan of access theme colours this way. Maybe props, or a dedicated context? Not important now.

    const currentValue = num > max ? max : num;
    const strokeWidth = 4;
    const r = size / 2 - strokeWidth;
    const circumference = Math.PI * 2 * r;
    const strokeDasharray = (circumference * currentValue) / max;

    return (
        <div className={classes.quickPie} style={{width: `${size}px`, height: `${size}px`}}>
            <svg width={size} height={size} className={classes.svg}>
                <circle //The 'background' circle
                    strokeWidth={strokeWidth}
                    stroke="#E8EBEC"
                    fill="none"
                    cx={size / 2}
                    cy={size / 2}
                    r={r}
                />
                <circle //the circular 'fill'
                    strokeDasharray={[strokeDasharray, circumference].toString()}
                    strokeWidth={strokeWidth}
                    style={{transform: 'rotate(-90deg)', transformOrigin: 'center center 0'}}
                    strokeLinecap="round"
                    fill="none"
                    stroke={themeColors.sparkline}
                    cx={size / 2}
                    cy={size / 2}
                    r={r}
                />
            </svg>
            <div className={classes.legend}>
                {formatBigIntFull(currentValue)}
                {'/'}
                {formatBigIntFull(max)}
            </div>
        </div>
    );
}

QuickPie.propTypes = {
    max: PropTypes.number.isRequired,
    num: PropTypes.number.isRequired,
    size: PropTypes.number,
};
