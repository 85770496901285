import React, {useState, useCallback, useEffect, useRef} from 'react';
import cn from 'classnames';

//Components
import CollapsibleSection from 'hsi/components/CollapsibleSection';
import ClearFiltersButton from 'hsi/components/Filters/ClearFiltersButton';

//Hooks
import useFiltersState from 'hsi/components/Filters/hooks/useFiltersState';

//Utils
import {focusEnter, focusLeave} from 'hsi/utils/react';

//Other
import useStyles from './styles';

//The component
export default function CollapsibleFilterSection({
    children,
    name,
    compact,

    onCollapsedChange = null,

    ...rest
}) {
    const classes = useStyles();
    const {
        useSelector,
        actions: {setSectionOpen, clearSectionFilters},
    } = useFiltersState();

    const [hovered, setHovered] = useState(false);
    const [focused, setFocussed] = useState(false);
    const onEnter = useCallback(() => setHovered(true), [setHovered]);
    const onLeave = useCallback(() => setHovered(false), [setHovered]);
    const onFocusEnter = useCallback(() => setFocussed(true), [setFocussed]);
    const onFocusLeave = useCallback(() => setFocussed(false), [setFocussed]);

    const sectionHasFiltersApplied = useSelector((state) => state.sectionHasFiltersApplied[name]);

    const onClearClick = useCallback(() => clearSectionFilters(name), [clearSectionFilters, name]);

    const collapsed = useSelector((state) => state.collapsedSections[name]);
    const onClickCollapse = useCallback(
        () => setSectionOpen(name, collapsed),
        [name, collapsed, setSectionOpen],
    );

    const firstTimeRef = useRef(false);
    useEffect(
        () => {
            if (!firstTimeRef.current) {
                firstTimeRef.current = true;
                return;
            }

            onCollapsedChange && onCollapsedChange(collapsed);
        },
        // Check whether other deps can be added without issue
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [collapsed],
    );

    return (
        <CollapsibleSection
            collapsed={collapsed}
            onClickCollapse={onClickCollapse}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
            onFocus={focusEnter(onFocusEnter)}
            onBlur={focusLeave(onFocusLeave)}
            showBadge={sectionHasFiltersApplied}
            headerContent={
                <ClearFiltersButton
                    clearFilterEnabled={sectionHasFiltersApplied && (hovered || focused)}
                    handleClear={onClearClick}
                    compact={compact}
                />
            }
            compact={compact}
            component="li"
            {...rest}
        >
            <div className={cn(classes.content, compact && classes.compact)}>{children}</div>
        </CollapsibleSection>
    );
}
