import { isV2 } from 'hsi/types/theme';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => {
    const v2 = isV2(theme);

    return {
        list: {
            display: 'flex',
            flexFlow: 'row nowrap',
            gap: '18px',
        },

        button: {
            ...theme.mixin.linkButton,
            whiteSpace: 'nowrap',
            color: v2 ? theme.colors.textSubtle : theme.colors.textDefault,

            '&[aria-disabled]': {
                opacity: 0.5,
                cursor: 'default',
                //pointerEvents: 'none',
            },

            ...(v2 ? theme.fonts.bodyLargeStrong : {fontWeight: theme.font.bold, fontSize: '16px'}),
        },

        moreActionsBtn: {
            ...theme.mixin.blankButton,
            color: v2 ? theme.colors.textSubtle : undefined,
            whiteSpace: 'nowrap',
            paddingRight: '16px',
            ...(v2 ? theme.fonts.bodyLargeStrong : {fontWeight: theme.font.bold, fontSize: '16px'}),
        },

        moreActionsBtnLbl: {
            position: 'relative',
            '&:after': {
                ...theme.mixin.downArrow,
            },
        },
    };
});
