import React, {forwardRef} from 'react';

//Components
import ChartTooltip from 'hsi/components/ChartTooltip';
import ChartTooltipContent from 'hsi/components/ChartTooltipContent';

//Utils
import {formatBigIntFull} from 'hsi/utils/formatNumber';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Internal components
const ToolTipAnchorComponent = forwardRef(({style}, ref) => (
    <div className={useStyles().tooltipAnchor} style={style} ref={ref} />
));

//The component
const Tooltip = ({item, x, y}) => {
    if (!x || !y || !item) return null;

    return (
        <ChartTooltip
            x={x}
            y={y}
            open={true}
            AnchorComponent={ToolTipAnchorComponent}
            autoHideOnScroll
            animationMs={150}
        >
            {!!item && (
                <ChartTooltipContent
                    title={item.name}
                    valueItems={[
                        {
                            amount: formatBigIntFull(item.total),
                            unit: T('mentions'),
                        },
                    ]}
                />
            )}
        </ChartTooltip>
    );
};

export default Tooltip;
