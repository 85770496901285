/**
 * Alert Service
 */
import http, { HttpResult } from 'hsi/classes/Http';
import {ProjectType} from 'hsi/types/shared';

import {ApiAlert} from 'hsi/types/alerts';

import throttledQueue from 'throttled-queue';

//TODO: put all the throttling in just one place
//trying not to hit the rate limiter here.

const throttle = throttledQueue(1, 2000);

interface HandleAlertInviteArgs {
    action: string;
    recipient: string;
    alertId: string;
}

export async function createAlert(alert: Omit<ApiAlert, 'id'>) {
    const {projectId, ...apiAlert} = alert;

    return http.post<ApiAlert>(`/api/projects/${projectId}/alerts`, apiAlert);
}

export async function updateAlert(alert: ApiAlert) {
    const {projectId, ...apiAlert} = alert;

    return http.put<ApiAlert>(`/api/projects/${projectId}/alerts`, apiAlert);
}

export async function deleteAlert(alertId: number, projectId: number): Promise<HttpResult<undefined>> {
    return http.delete<undefined>(`/api/projects/${projectId}/alerts/${alertId}`);
}

export async function loadAlerts(projects: ProjectType[]): Promise<ApiAlert[]> {
    return Promise.all(
        projects.map((project, i) => {
            return throttle(() =>
                http.get(`/api/projects/${project.id}/alerts`).then((res) => res.body),
            );
        }),
    ).then((alertsByProject) => {
        return [].concat(...alertsByProject);
    });
}

export async function handleAlertInvite({
    action,
    recipient,
    alertId,
}: HandleAlertInviteArgs): Promise<HttpResult<any> | {error: any}> {//TODO sort out types
    return http
        .post(`/api/public/alerts/${action}`, {
            recipient,
            alertId,
        })
        .catch((e) => ({
            error: e?.body?.errors?.[0]?.message,
        }));
}
