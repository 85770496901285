import {isV2} from 'hsi/types/theme';
import {makeStyles} from 'tss-react/mui';

export default makeStyles<void, 'error' | 'space'>()((theme, _params, classes) => {
    return {
        checkboxesList: {
            display: 'flex',
            flexFlow: 'column nowrap',
            padding: '10px 16px',
            gap: '4px',
            background: isV2(theme) ? theme.colors.uiGrayDefault : '#f0f2f2',
            borderRadius: theme.v2 ? 0 : '3px',
            [`&.${classes.error}`]: {
                border: isV2(theme)
                    ? `2px solid ${theme.colors.error}`
                    : `2px solid ${theme.colors.primaryRed100}`,
            },
            [`&.${classes.space}`]: {
                marginBottom: '8px',
            },
        },
        checkboxWithLabel: {
            ...theme.v2 
                ? theme.fonts.bodyStrong 
                : {fontSize: '16px', fontWeight: theme.font.bold},

            display: 'flex',
            alignItems: 'center',
            gap: '8px',
        },
        checkboxLabel: {
            cursor: 'pointer',
        },
        optionLabel: {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',

            ...(isV2(theme)
                ? theme.fonts.bodyNormal
                : {
                      fontSize: '16px',
                  }),
        },
        alertMe: {
            marginBottom: '4px',
        },
        heading: isV2(theme)
            ? theme.fonts.bodyStrong
            : {
                  fontSize: '16px',
                  fontWeight: theme.font.bold,
              },
        alertDetails: {
            paddingLeft: theme.v2 ? '40px' : '56px',
        },

        input: {
            height: theme.v2 ? '32px' : undefined,

            '& input': {
                height: theme.v2 ? '32px' : undefined,
            },

            '&.MuiTextField-root': {
                width: '75px',
                padding: '0 8px',
            },
            '&.MuiOutlinedInput-notchedOutline': {
                border: theme.v2 ? undefined : '0 !important',
                borderRadius: theme.v2 ? '3px' : '2px',
            },
        },

        secondTime: {
            display: 'inline-block',
            margin: '0 -4px 0 8px',
        },
        interstitial: {
            padding: '0 8px',

            '&:first-of-type': {
                paddingLeft: 0,
            },
        },

        info: {
            display: 'flex',
            flexWrap: 'nowrap',
            flexFlow: 'column',
            margin: '0 0 30px 0',
            padding: 0,
            border: 0,
        },
        alertName: {
            order: theme.v2 ? 2 : 3,
        },
        category: {
            order: 1,
            paddingBottom: '5px',
            ...(isV2(theme)
                ? theme.fonts.bodyNormal
                : {
                      fontWeight: 700,
                      fontSize: '16px',
                  }),
        },
        instructions: {
            order: theme.v2 ? 3 : 2,
            opacity: 1,
            paddingBottom: '28px',
            height: '19px',
            color: isV2(theme) ? theme.colors.textSubtle : theme.colors.darkGrey100,

            ...(isV2(theme)
                ? theme.fonts.bodyNormal
                : {
                      fontSize: '16px',
                  }),
        },

        numAlertsLabel: {
            ...(isV2(theme)
                ? theme.fonts.bodyStrong
                : {
                      fontWeight: theme.font.bold,
                      fontSize: '16px',
                  }),

            cursor: 'pointer',
            display: 'flex',
            gap: 8,
            alignItems: 'center',
        },

        numAlerts: {},

        filters: {
            '&&': {
                display: 'flex',
                alignItems: 'center',
                flexFlow: 'row nowrap',
                gap: '4px',
                marginBottom: 4,
            },
            
        },
        numFilters: {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
        filtersInfo: {
            margin: '0 0 4px',
        },
        optionsButtonLink: {
            ...theme.mixin.linkButton,
            ...(isV2(theme)
                ? theme.fonts.bodyNormal
                : {
                      fontWeight: theme.font.bold,
                  }),

            color: isV2(theme) ? theme.colors.textHyperlink : '#0078a4',
            paddingLeft: '8px',
            cursor: 'pointer',
        },

        //Tooltip styles
        tooltipContent: {
            ...(isV2(theme)
                ? theme.fonts.bodyNormal
                : {
                      fontSize: '14px',
                  }),
            color: isV2(theme) ? theme.colors.textDefault : theme.colors.darkGrey100,
            padding: '17px 24px',
            width: '360px',
            wordWrap: 'break-word',
        },
        wideTooltip: {
            '&&': {
                maxWidth: '400px',
            },
        },
        toggle: {},
        addRecipients: {
            '> div:first-of-type': {
                padding: 0,
            },
            '& label:first-of-type': {
                fontWeight: theme.font.bold,
            },
        },
        error: {},
        space: {},
    };
});
