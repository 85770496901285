import {useState} from 'react';

import Banner from 'hsi/components/Banner';
import Button from 'hsi/components/Button';
import DialogContainer from 'hsi/containers/DataManagement/AddDataSourceDialog/DialogContainer';
import UploadInfoDialog from 'hsi/containers/DataManagement/AddDataSourceDialog/UploadInfoDialog';
import NonOwnedFacebookPageInput from './NonOwnedFacebookPageInput';
import UploadZone from 'hsi/components/UploadZone';

import useDataSourceCount from 'hsi/hooks/useDataSourceCount';
import {useFacebookPages} from 'hsi/hooks/useDataSource';
import useNonOwnedInput from 'hsi/containers/DataManagement/AddDataSourceDialog/NonOwnedInput/useNonOwnedInput';
import {MAX_PAGES_PER_TRACK} from 'hsi/constants/config';
import useStyles from './styles';

import {T} from 'hsi/i18n';
import tns from 'hsi/i18n/tns';

const t = tns('dataManagement.addDataSourcesDialog.addNonOwnedFacebookPages');

const getVerifyErrorFromCode = ({
    errorCode,
    failureReason,
}: {
    errorCode?: number;
    failureReason?: string;
}) => {
    switch (errorCode) {
        case 417:
            return 'dataManagement.addDataSourcesDialog.addNonOwnedFacebookPages.chipError.formatError';
        case 404:
        case 0:
            return 'dataManagement.addDataSourcesDialog.addNonOwnedFacebookPages.chipError.notFound';
        default:
            return 'dataManagement.addDataSourcesDialog.addNonOwnedFacebookPages.chipError.unknown';
    }
};

type AddNonOwnedFacebookPagesProps = {
    info: ReturnType<typeof useDataSourceCount>;
    onBack: () => void;
    onClose: () => void;
};

const AddNonOwnedFacebookPages = ({info, onBack, onClose}: AddNonOwnedFacebookPagesProps) => {
    const classes = useStyles();
    const facebookPages = useFacebookPages();
    const [isInfoOpen, setIsInfoOpen] = useState(false);

    const maxLength =
        info.sourcesRemaining > MAX_PAGES_PER_TRACK ? MAX_PAGES_PER_TRACK : info.sourcesRemaining;

    const {
        acceptedFileTypes,
        atMaxLength,
        items,
        isLoading,
        onItemsChange,
        onSubmit,
        parseCsv,
        resetInput,
        parserError,
        submitError,
        validateItems,
    } = useNonOwnedInput({
        onAdd: (succeeded) => ({
            pages: succeeded.map((item: any) => ({pageId: item.id, owned: false})),
        }),
        dataType: facebookPages,
        getVerifyErrorFromCode,
        maxLength,
        onClose,
    });

    return (
        <>
            <DialogContainer
                actions={
                    <>
                        <Button
                            className={classes.backButton}
                            onClick={onBack}
                            priority="secondary"
                        >
                            {T('back')}
                        </Button>
                        <Button onClick={onClose} priority="primary">
                            {T('cancel')}
                        </Button>
                        <Button
                            disabled={!items.length || submitError || isLoading}
                            onClick={onSubmit}
                            priority="cta"
                        >
                            {t('addButton')}
                        </Button>
                    </>
                }
                onClose={onClose}
                title={t('title')}
            >
                <form className={classes.container} onSubmit={onSubmit}>
                    <UploadZone accept={acceptedFileTypes} parseData={parseCsv}>
                        <NonOwnedFacebookPageInput
                            atMaxLength={atMaxLength}
                            error={submitError}
                            maxLength={maxLength}
                            onPagesChange={onItemsChange}
                            openInfo={() => setIsInfoOpen(true)}
                            pages={items}
                            parserError={parserError}
                            resetInput={resetInput}
                            validatePages={validateItems}
                        />
                    </UploadZone>
                    <Banner className={classes.infoBanner}>{t('infoBanner')}</Banner>
                </form>
            </DialogContainer>
            <UploadInfoDialog
                acceptText={t('info.cta')}
                invalidExamples={[
                    {columnA: t('info.invalidExample1a'), columnB: t('info.invalidExample1b')},
                ]}
                invalidTitle={t('info.invalidTitle')}
                isOpen={isInfoOpen}
                onAccept={() => setIsInfoOpen(false)}
                title={t('title')}
                validTitle={t('info.validTitle')}
                validExamples={[
                    t('info.validExample1'),
                    t('info.validExample2'),
                    t('info.validExample3'),
                    t('info.validExample4'),
                    t('info.validExample5'),
                ]}
            />
        </>
    );
};

export default AddNonOwnedFacebookPages;
