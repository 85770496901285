import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({colors}) => ({
    '@global': {
        '.chbox-label': {
            '& .chbox-icon-wrapper': {
                width: '16px',
                height: '16px',
                textAlign: 'center',
                position: 'relative',
                '& .chbox-icon-frame': {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: colors.white,
                    borderRadius: '2px',
                    '&.blank': {
                        border: `1px solid ${colors.borders}`,
                        backgroundColor: colors.white,
                        width: '16px',
                        height: '16px',
                    },
                    '&.checked': {
                        width: '16px',
                        height: '16px',
                        position: 'relative',
                        backgroundColor: colors.accentActive,
                        border: `1px solid ${colors.accentActive}`,
                        '& .chbox-icon': {
                            width: '12px',
                            height: '12px',
                            position: 'absolute',
                            top: '1px',
                            left: '1px',
                            fill: colors.white,
                        },
                    },
                    '&.icon-frame-indeterminate': {
                        '& .indeterminate-chbox-icon': {
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '8px',
                        },
                    },
                },
            },
            '&:hover': {
                '& .chbox-icon-frame': {
                    borderColor: `${colors.primaryBlue100} !important`,
                },
            },
            '&.Mui-disabled': {
                '& .chbox-icon-wrapper': {
                    '& .chbox-icon-frame': {
                        '&.blank': {
                            border: `1px solid ${colors.bgDisabled}`,
                        },
                    },
                },
            },
            '&.Mui-disabled:hover': {
                '& .chbox-icon-frame': {
                    borderColor: `${colors.bgDisabled} !important`,
                },
            },
        },
    },
}));
