//TODO supply values to filter out (already selected values)?
//TODO replace with ChipInput/MUI autosuggest component

import React, {useCallback, useEffect, useRef, useMemo} from 'react';
import ReactAutosuggest, {
    AutosuggestProps,
    RenderSuggestionsContainerParams,
} from 'react-autosuggest';
import deburr from 'lodash/deburr';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import cn from 'classnames';
import mergeProps from 'merge-props';

//Component
import TextfieldWithAlert from 'hsi/components/Filters/TextfieldWithAlert';

//Hooks
import useRefCallback from 'hsi/hooks/useRefCallback';
import useProjectId from 'hsi/hooks/useProjectId';
import useGetAutosuggestSettings from 'hsi/hooks/useGetAutosuggestSettings';
import {useAppDispatch} from 'hsi/hooks/useRedux';

//Utils
import {mergeRefs} from 'hsi/utils/react';

//Other
import useStyles from './styles';

//Types
import {AutosuggestDataTypeFromAutosuggestType, AutosuggestTypes} from 'hsi/types/autosuggest';

type OnChangeType<TSuggestion> = AutosuggestProps<TSuggestion, any>['inputProps']['onChange'];

type GetInputPropsType<TSuggestion> = (
    value: string,
    onAdd: (value: TSuggestion) => void,
    onKeyDownCheckForAddValue: React.KeyboardEventHandler<HTMLInputElement>,
    onChange: OnChangeType<TSuggestion>,
    otherProps: any,
    classes?: Record<string, string>,
) => AutosuggestProps<TSuggestion, any>['inputProps'];

interface AutosuggestInputProps<TAutosuggestType extends AutosuggestTypes>
    extends React.HTMLAttributes<HTMLDivElement> {
    autosuggestType: TAutosuggestType;
    autoScrollDrawer?: (elem: HTMLDivElement) => void;

    //
    getInputProps?: GetInputPropsType<AutosuggestDataTypeFromAutosuggestType<TAutosuggestType>>;
    renderInput?: AutosuggestProps<
        AutosuggestDataTypeFromAutosuggestType<TAutosuggestType>,
        any
    >['renderInputComponent'];

    onInputChange?: OnChangeType<AutosuggestDataTypeFromAutosuggestType<TAutosuggestType>>;
    onAdd: (value: AutosuggestDataTypeFromAutosuggestType<TAutosuggestType>) => void;
    addValueKeyCodes?: number[];

    textfieldValue: string;
    setTextfieldValue: (value: string) => void;
}

//The component
const AutosuggestInput = React.forwardRef(function AutosuggestInput<
    TAutosuggestType extends AutosuggestTypes,
>(
    {
        autosuggestType,
        autoScrollDrawer,

        //
        getInputProps = defaultGetInputProps,
        renderInput = defaultRenderInput,

        onInputChange,
        onAdd,
        addValueKeyCodes,

        textfieldValue,
        setTextfieldValue,

        ...otherProps
    }: AutosuggestInputProps<TAutosuggestType>,
    ref: React.ForwardedRef<HTMLDivElement>,
) {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const suggestionRef = useRef<HTMLDivElement>(null);
    const projectId = useProjectId();

    const {
        loadDataHandler,
        justOnce,
        listableKey,
        loading,
        results: foundSuggestions = [],
    } = useGetAutosuggestSettings(autosuggestType, projectId!);

    //Internal state
    const [stateSuggestions, setSuggestions] = React.useState(foundSuggestions?.slice(0, 10) ?? []);

    //Callbacks
    const onSuggestionsFetchRequested = useRefCallback(
        ({value, reason}: {value: string; reason: string}) => {
            if (justOnce) {
                setSuggestions(getSuggestions(value, foundSuggestions, listableKey));
            }
            if (!justOnce && reason === 'input-changed') {
                loadDataHandler && dispatch(loadDataHandler(value));
            }

            //Ref not working
            !loading && autoScrollDrawer && autoScrollDrawer(suggestionRef.current!);
        },
    );

    const handleSuggestionsClearRequested = useCallback(() => setSuggestions([]), [setSuggestions]);

    const handleSuggestionSelected = useRefCallback<
        NonNullable<
            AutosuggestProps<
                AutosuggestDataTypeFromAutosuggestType<TAutosuggestType>,
                any
            >['onSuggestionSelected']
        >
    >((event, {suggestion}) => {
        onAdd?.(suggestion);
    });

    const getSuggestionValue = useCallback(
        (suggestion: any) => suggestion[listableKey],
        [listableKey],
    );

    const renderSuggestion = useRefCallback(
        (suggestion: any, {query, isHighlighted}: {query: string; isHighlighted?: boolean}) => {
            const matches = match(suggestion[listableKey], query);
            const parts = parse(suggestion[listableKey], matches);

            return (
                <MenuItem selected={isHighlighted} component="div" key={suggestion[listableKey]}>
                    <div className={classes.suggestionWrapper}>
                        {parts.map((part) => (
                            <span
                                key={part.text}
                                className={cn(
                                    classes.suggestionPart,
                                    part.highlight && classes.highlight,
                                )}
                            >
                                {part.text}
                            </span>
                        ))}
                    </div>
                </MenuItem>
            );
        },
    );

    const onChange = useCallback<
        OnChangeType<AutosuggestDataTypeFromAutosuggestType<TAutosuggestType>>
    >(
        (...args) => {
            onInputChange?.(...args);

            setTextfieldValue(args[1].newValue);
        },
        [onInputChange, setTextfieldValue],
    );

    const selectIfValid = useRefCallback(() => {
        const matchingSuggestion = suggestions.find(
            (suggestion) => suggestion[listableKey] === textfieldValue,
        );

        matchingSuggestion && onAdd(matchingSuggestion);
    });

    //Allows the uer to supply keycodes for buttons that submit the current value if it is valid
    const onKeyDownCheckForAddValue = useRefCallback<React.KeyboardEventHandler<HTMLInputElement>>(
        (evt) => {
            if (addValueKeyCodes && addValueKeyCodes.includes(evt.which)) {
                selectIfValid();
                evt.stopPropagation();
                return false;
            }

            return true;
        },
    );

    //Calculated values
    const inputProps = useMemo(
        () =>
            getInputProps(
                textfieldValue,
                selectIfValid,
                onKeyDownCheckForAddValue,
                onChange,
                otherProps,
                classes,
            ),
        [
            classes,
            getInputProps,
            onChange,
            onKeyDownCheckForAddValue,
            otherProps,
            selectIfValid,
            textfieldValue,
        ],
    );

    const suggestions = useMemo(
        () => (justOnce ? stateSuggestions : foundSuggestions),
        [justOnce, stateSuggestions, foundSuggestions],
    );

    //Side effects
    useEffect(() => {
        if (justOnce) {
            loadDataHandler && dispatch(loadDataHandler());
        }
        // Check whether other deps can be added without issue
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [justOnce]);

    //Render
    return (
        <div className={classes.root} ref={mergeRefs(suggestionRef, ref)}>
            <ReactAutosuggest
                theme={{
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                }}
                renderInputComponent={renderInput}
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={handleSuggestionsClearRequested}
                renderSuggestionsContainer={renderSuggestionsContainer}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={handleSuggestionSelected}
                focusInputOnSuggestionClick
                inputProps={inputProps}
            />
        </div>
    );
});

export default AutosuggestInput;

//Internal functions
const defaultGetInputProps: GetInputPropsType<any> = (
    value,
    onAdd,
    onKeyDownCheckForAddValue,
    onChange,
    otherProps,
    classes,
) => ({
    otherProps, //These are any other props passed to this component
    value, //These are required by the autocomplete component
    onChange,
    //These are additional props to be merged into the props passed to the input
    _customProps: {
        className: classes?.fullWidth,
        inputProps: {onKeyDown: onKeyDownCheckForAddValue, onBlur: onAdd}, //Is this right? onBlur = onAdd???
    },
});

function defaultRenderInput({
    otherProps: {ref: otherRef, ...otherProps},
    _customProps,
    ...props
}: any) {
    //TODO better typing here?

    //merge the props for the input. Need to do this here because the
    //autocomplete component mixes in required props at this point
    const mergedProps = mergeProps(otherProps, props, _customProps);

    mergedProps.inputProps = mergeProps(
        props.inputProps,
        otherProps.inputProps,
        _customProps.inputProps,
    );

    return <TextfieldWithAlert {...mergedProps} ref={otherRef} />;
}

function renderSuggestionsContainer(options: RenderSuggestionsContainerParams) {
    return (
        <Paper {...options.containerProps} square style={{zIndex: 3000}}>
            {options.children}
        </Paper>
    );
}

export function getSuggestions<T>(value: string, data: T[], listableKey: keyof T) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
        ? []
        : data.filter((suggestion) => {
              const field = suggestion[listableKey] as string;
              const keep = count < 5 && field.slice(0, inputLength).toLowerCase() === inputValue;

              if (keep) {
                  count += 1;
              }

              return keep;
          });
}
