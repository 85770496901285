import React from 'react';
import PropTypes from 'prop-types';

import UnauthorizedScreen from 'hsi/components/UnauthorizedScreen';

import useMessageParser from './useMessageParser';
import useMessage from './useMessage';

import {createError} from './errors';

const UnauthorizedMentionsTable = ({errors, messageParserId}) => {
    const messageParser = useMessageParser(messageParserId)({
        columns: [],
    });
    const msgErrors =
        errors?.length === 2 && errors[1] === 'safari'
            ? [createError('Safari Unauthorized')]
            : [createError('Unauthorized')];
    useMessage({
        messageParser,
        errors: msgErrors,
    });

    return <UnauthorizedScreen errors={errors} />;
};

UnauthorizedMentionsTable.propTypes = {
    errors: PropTypes.array.isRequired,
    messageParserId: PropTypes.string.isRequired,
};

export default UnauthorizedMentionsTable;
