import React from 'react';
import cn from 'classnames';

//Other
import useStyles from './styles';
import {PropsFrom} from 'hsi/types/react';

type LabelProps<TComponent extends React.ElementType> = {
    component?: TComponent;
    indent?: boolean;
    invisible?: boolean;
} & PropsFrom<TComponent>;

//The component
export default function Label<TComponent extends React.ElementType>({
    children,
    component: Component = 'label',
    indent,
    invisible = false,
    ...rest
}: LabelProps<TComponent>) {
    const classes = useStyles();

    return (
        <Component
            {...rest}
            className={cn(invisible ? classes.invisible : classes.label, indent && classes.indent)}
        >
            {children}
        </Component>
    );
}
