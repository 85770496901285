import {AriaMessage} from 'hsi/slices/ariaAnnounce';

export default function AriaAnnounce({messages}: {messages: AriaMessage[]}) {
    return (
        <div className="offscreen">
            {messages.map(({message, priority, id}) => (
                <span key={id} aria-live={priority}>
                    {message}
                </span>
            ))}
        </div>
    );
}
