import {forwardRef, useMemo } from "react";
import classNames from "classnames";

import useStyles from './styles';


export type MeterProps = {
    /** Optional  */
    description?: string;
    min?: number;
    max?: number;
    value: number;
    /** Optionally specify the units of the value used in the meter, e.g. %, km, GB, etc - only needed if description is not specified */
    unit?: string
} & Omit<JSX.IntrinsicElements['div'], 'children' | 'title' | 'role' | 'aria-valuenow' | 'aria-valuemin' | 'aria-valuemax'>;

/**
 * Used to indicate a value within a known finite range, e.g. a percentage (if it can't exceed 100)
 */
const Meter = forwardRef<HTMLDivElement, MeterProps>(function Meter({value, description: _description, min = 0, max = 100, className, unit, style: _style, ...rest}, ref) {
    const classes = useStyles();

    const description = _description ?? `${value}${unit}/${max}${unit}`;

    const style = useMemo(() => ({
        ..._style,
        '--meter-percentage': `${(value - min) / (max - min) * 100}%`,
    }), [_style, max, min, value]);

    return <div role="meter" aria-valuenow={value} className={classNames(className, classes.meter)} aria-valuemin={min} aria-valuemax={max} aria-valuetext={description} style={style} {...rest} ref={ref} />
})

export default Meter;