import {useState, useEffect, useCallback} from 'react';

import Popper, {PopperProps} from '@mui/material/Popper';
import Tooltip, { PopperPlacement } from './Tooltip'; //This needs to be changed to an accessible component

type StepProps = {
    step: {
        anchor: keyof HTMLElementTagNameMap;
        content: React.ReactElement;
        disablePortal: PopperProps['disablePortal'];
        offset: any;
        placement: PopperPlacement;
        style: React.CSSProperties;
        title: React.ReactElement;
        showArrow?: boolean | undefined;
    };
    stepIdx: number;
    stepsLength: number;
    onNext: () => any;
    onClose: () => any;
};

/** Time (in seconds) between checking for the anchor element */
const pollForAnchorInterval = 0.5;

const Step = ({step, stepIdx, stepsLength, onNext, onClose}: StepProps) => {
    const {anchor, placement, offset, style, title, content, disablePortal, showArrow = true} = step;

    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

    const getAnchor = useCallback(
        () => document.querySelector(anchor) as HTMLElement | null,
        [anchor],
    );

    useEffect(() => {
        let lastAnchorElement: HTMLElement | null = null;

        //keep polling for anchor element
        const intervalId = setInterval(() => {
            const anchor = getAnchor();

            if (anchor !== lastAnchorElement) {
                lastAnchorElement = anchor;
                setAnchorElement(anchor); //if anchor element changes, record new anchor
            }
        }, pollForAnchorInterval * 1000);

        return () => {
            //Tidy up method
            clearTimeout(intervalId);
        };
        // Check if deps can be added without issue
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const modifiers = [
        {
            name: 'flip',
            enabled: false,
        },
    ];

    if (anchorElement === null) {
        return null;
    }

    return (
        <Popper
            disablePortal={disablePortal}
            open={true}
            anchorEl={anchorElement}
            placement={placement}
            modifiers={modifiers}
            style={{zIndex: Number.MAX_SAFE_INTEGER}}
        >
            <Tooltip
                stepIdx={stepIdx}
                stepsLength={stepsLength}
                title={title}
                content={content}
                placement={placement}
                offset={offset}
                style={style}
                onNext={onNext}
                onClose={onClose}
                showArrow={showArrow}
            />
        </Popper>
    );
};

export default Step;
