import cn from 'classnames';
import PropTypes from 'prop-types';

import Button from 'hsi/components/Button';

import makeStyles from '@mui/styles/makeStyles';

import {T} from 'hsi/i18n';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-end',
        borderTop: `1px ${theme.colors.darkGrey20} solid`,
        padding: ` ${theme.v2 ? '24px 0' : '10px 16px'}`,
    },
}));

const Footer = ({className, disabled, onSearch}) => {
    const cl = useStyles();

    return (
        <div className={cn(cl.container, className)}>
            <Button disabled={disabled} onClick={onSearch} priority="cta">
                {T('queryBuilder.search')}
            </Button>
        </div>
    );
};

Footer.propTypes = {
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
};

export default Footer;
