import Ajv from 'ajv';
import isEmpty from 'lodash/isEmpty';

//TODO json-schema.org

const ajv = new Ajv();

export const validate = (schema, payload) => ({
    success: ajv.validate(schema, payload),
    error: ajv.errorsText(),
});

export const actionSchema = ({action, data}) => ({
    type: 'object',
    required: ['action', 'data'],
    additionalProperties: false,
    properties: {
        action: {
            type: 'string',
            const: action,
        },
        data,
    },
});

export const optionItemSchema = (opt) => ({
    type: 'object',
    required: ['key', 'values'],
    properties: {
        key: {
            type: 'string',
            const: opt.key,
        },
        values: {
            type: 'array',
            items: {
                enum: !isEmpty(opt.values) ? opt.values : undefined,
            },
        },
    },
});

export const optionSchema = ({action, data}) =>
    actionSchema({
        action,
        data: {
            type: 'array',
            items: {
                oneOf: data.map(optionItemSchema),
            },
        },
    });

export const debug = actionSchema({
    action: 'debug',
    data: {type: 'boolean'},
});

// Not currently used anywhere
export const makeSchema = (options) => ({
    oneOf: options.map(optionSchema).concat(debug),
});
