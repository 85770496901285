import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';

import {getSearchResultsUrl} from 'hsi/utils/url';
import {useSearchesById} from 'hsi/hooks/useSearchesById';
import OverflowTooltipHook from 'hsi/components/SimpleTooltip/OverflowTooltipHook';
import MultipleSearchChips from 'hsi/containers/MultipleSearchBar/MultipleSearchChips';
import {useStyles} from './styles';

//The component
export default function NameField({
    label,
    item,
    getAdditionalQueries,
    getHiddenChipsText,
    onClickSearch,
    containerRef,
}) {
    const cl = useStyles();
    const {searchesById} = useSearchesById();

    const additionalQueries = getAdditionalQueries(item.id);

    //Callbacks
    const getChipLabel = useCallback((id) => searchesById[id]?.name, [searchesById]);

    //Render
    return (
        <div className={cl.root}>
            <Link
                to={getSearchResultsUrl(item)}
                className={cl.searchNameContainer}
                onClick={() => onClickSearch(item)}
            >
                <OverflowTooltipHook tooltip={item.name}>
                    <div className={cl.searchName}>{label}</div>
                </OverflowTooltipHook>
            </Link>

            {additionalQueries?.length > 0 && (
                <MultipleSearchChips
                    items={additionalQueries}
                    getChipLabel={getChipLabel}
                    className={cl.additionalQueriesChips}
                    getHiddenChipsText={getHiddenChipsText}
                />
            )}
        </div>
    );
}
