import React, {useState, useRef, useCallback, useEffect, useMemo, Fragment} from 'react';
import cn from 'classnames';
import {find} from 'lodash';

import Popover from '@mui/material/Popover';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';

import IconRouter from 'hsi/components/IconRouter';
import Button from 'hsi/components/Button';
import SearchBox from 'hsi/components/SearchBox';
import TextField from 'hsi/components/TextField';
import Checkbox from 'hsi/components/LegacyCheckboxField';
import {OverflowTooltip} from 'hsi/components/SimpleTooltip';
import {T} from 'hsi/i18n';
import useStyles from './styles';

const getParentCatCheckboxProps = (cat, selectedCat) => {
    if (!selectedCat) {
        return {checked: false};
    }
    if ((cat.origChildren || cat.children).length === selectedCat.subcategories.length) {
        return {checked: true};
    } else {
        return {checked: true, indeterminate: true};
    }
};

const BLANK_ARR = [];

const SelectCategoryField = ({
    categories,
    fullWidth = true,
    placeholder,
    disabled,
    dense = false,
    withSearch,
    searchMinOpts = 8,
    onChange,
    value,
    label,
    className,
}) => {
    const [innerState, setInnerState] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState(categories || BLANK_ARR);
    const [popMaxHeight, setPopMaxHeight] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [expand, setExpand] = useState({});
    const [menuWidth, setMenuWidth] = useState(20);
    const containerRef = useRef();
    const popoverRef = useRef();
    const classes = useStyles();

    const onOpen = useCallback(() => {
        if (!disabled) {
            setIsOpen(true);
            setInnerState(value);
        }
    }, [disabled, value]);

    const onClose = useCallback(() => {
        setIsOpen(false);
        setSearch('');
    }, []);

    const onExpand = useCallback(
        (ev, id) => {
            ev.stopPropagation();
            if (expand[id]) {
                setExpand({...expand, [id]: false});
            } else {
                setExpand({...expand, [id]: true});
            }
        },
        [expand],
    );

    const onSelectParentCat = useCallback(
        (cat) => {
            const found = find(innerState, (item) => item.id === cat.id);
            let result;
            if (!found) {
                result = [
                    ...innerState,
                    {id: cat.id, subcategories: cat.children.map((c) => c.id)},
                ];
            } else {
                result = innerState.filter((item) => item.id !== cat.id);
            }
            setInnerState(result);
        },
        [setInnerState, innerState],
    );

    const onSelectChildCat = useCallback(
        (cat, subcat) => {
            let parent = find(innerState, (item) => item.id === cat.id);
            let result;
            if (!parent) {
                parent = {id: cat.id, subcategories: [subcat.id]};
                result = [...innerState, parent];
            } else if (parent.subcategories && parent.subcategories.includes(subcat.id)) {
                const newSubcats = parent.subcategories.filter((id) => id !== subcat.id);
                if (!newSubcats.length) {
                    result = innerState.filter((pc) => pc.id !== parent.id);
                } else {
                    parent.subcategories = newSubcats;
                    result = [...innerState.filter((pc) => pc.id !== parent.id), parent];
                }
            } else {
                parent.subcategories = [...(parent.subcategories || []), subcat.id];
                result = [...innerState.filter((pc) => pc.id !== parent.id), parent];
            }
            setInnerState(result);
        },
        [setInnerState, innerState],
    );

    const onSearch = useCallback((ev) => {
        const searchStr = ev.target.value;
        //const lcSearch = searchStr.toLowerCase(); This wasn't used but maybe should be?
        setSearch(searchStr);
    }, []);

    const onPopoverResize = () => {
        const paperH = document.querySelector('.category-popover').offsetHeight;
        if (paperH >= popMaxHeight) {
            setPopMaxHeight(paperH);
            popoverRef.current && popoverRef.current.updatePosition();
        }
    };

    const onApply = () => {
        !!onChange && onChange(innerState);
        onClose();
    };

    useEffect(() => {
        containerRef.current && setMenuWidth(containerRef.current.offsetWidth);
        // Check if deps can be updated without issue
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerRef.current && containerRef.current.offsetWidth]);

    useEffect(() => {
        let options = [];
        const forceExpand = {};
        const lcSearch = search.trim().toLowerCase();

        if (!lcSearch) {
            options = categories || BLANK_ARR;
        } else {
            categories.forEach((cat) => {
                if (cat.name.toLowerCase().includes(lcSearch)) {
                    options.push(cat);
                } else {
                    const subcats = cat.children.filter((scat) =>
                        scat.name.toLowerCase().includes(lcSearch),
                    );
                    if (subcats.length) {
                        options.push({...cat, children: subcats, origChildren: cat.children});
                        forceExpand[cat.id] = true;
                    }
                }
            });
        }

        setFilteredOptions(options);
        setExpand({...expand, ...forceExpand});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories, search]);

    useEffect(() => {
        setInnerState(value);
    }, [value]);

    useEffect(() => {
        if (!isOpen) {
            setPopMaxHeight(0);
        }
    }, [isOpen]);

    const numOpts = useMemo(
        () =>
            (categories || []).reduce(
                (acc, curr) => (acc + 1 + curr.children ? curr.children.length : 0),
                0,
            ),
        [categories],
    );
    const showSearch = !!withSearch && (!searchMinOpts || numOpts >= searchMinOpts);

    return (
        <div className={cn(classes.selectInputWrapper, className)} ref={containerRef}>
            <TextField
                fullWidth={fullWidth}
                value={''}
                label={label}
                placeholder={placeholder || T('pleaseSelect')}
                InputProps={{
                    onClick: onOpen,
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            classes={{
                                positionEnd: cn(classes.inputAdornment, dense && classes.dense),
                            }}
                        >
                            <IconRouter
                                className={cn(classes.dropDownIcon, dense && classes.denseIcon)}
                                name="down-arrow"
                            />
                        </InputAdornment>
                    ),
                    classes: {
                        root: classes.textFieldRoot,
                        adornedEnd: classes.textFieldAdornedEnd,
                        notchedOutline: classes.textFieldNotchedOutline,
                    },
                    inputProps: {
                        style: {cursor: 'pointer'},
                    },
                    disabled,
                }}
                disabled={disabled}
            />
            <Popover
                open={isOpen && !!menuWidth}
                anchorEl={containerRef.current}
                onClose={onClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                transformOrigin={{vertical: 'top', horizontal: 'center'}}
                TransitionComponent={Fade}
                action={popoverRef}
                PaperProps={{
                    style: {
                        width: menuWidth || 0,
                        position: 'relative',
                        backgroundColor: 'white',
                    },
                    className: 'category-popover',
                }}
            >
                {showSearch && (
                    <div className={classes.searchFieldWrapper}>
                        <SearchBox
                            onChange={onSearch}
                            value={search}
                            fullWidth
                            placeholder={T('filterOptions')}
                            onSearch={() => true}
                        />
                    </div>
                )}
                <List>
                    {filteredOptions.map((cat, idx) => {
                        const sel = find(innerState, (item) => item.id === cat.id);
                        const numSubcats =
                            cat.origChildren || cat.children
                                ? (cat.origChildren || cat.children).length
                                : 0;
                        return (
                            <Fragment key={idx}>
                                <ListItem dense button onClick={() => onSelectParentCat(cat)}>
                                    <ListItemIcon classes={{root: classes.listItemIcon}}>
                                        <span>
                                            <IconButton
                                                onClick={(ev) => onExpand(ev, cat.id)}
                                                className={cn(classes.expandButton, 'no-glow')}
                                                size="small"
                                            >
                                                {!!expand[cat.id] ? (
                                                    <IconRouter
                                                        name="mui-remove-circle-outline"
                                                        className={classes.expandIcon}
                                                    />
                                                ) : (
                                                    <IconRouter
                                                        name="mui-add-circle-outline"
                                                        className={classes.expandIcon}
                                                    />
                                                )}
                                            </IconButton>
                                            <Checkbox
                                                {...getParentCatCheckboxProps(cat, sel)}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{'aria-labelledby': `pcat-${cat.id}`}}
                                                formControlProps={{
                                                    classes: {
                                                        root: classes.checkboxFControlParentRoot,
                                                    },
                                                }}
                                                classes={{
                                                    root: classes.checkboxRoot,
                                                }}
                                            />
                                        </span>
                                    </ListItemIcon>
                                    <ListItemText
                                        id={`pcat-${cat.id}`}
                                        primary={
                                            <div className={classes.parentItemTextContainer}>
                                                <OverflowTooltip tooltip={cat.name}>
                                                    <div
                                                        className={cn(
                                                            classes.parentItemTextName,
                                                            'no-focus-outline',
                                                        )}
                                                    >
                                                        {cat.name}
                                                    </div>
                                                </OverflowTooltip>
                                                <div className={classes.parentItemTextNum}>
                                                    ({numSubcats})
                                                </div>
                                            </div>
                                        }
                                        classes={{root: classes.listItemText}}
                                    />
                                </ListItem>
                                {!!cat.children && (
                                    <Collapse in={!!expand[cat.id]} onEntered={onPopoverResize}>
                                        <List classes={{padding: classes.nestedListPadding}}>
                                            {cat.children.map((subcat, idx2) => (
                                                <ListItem
                                                    key={idx2}
                                                    dense
                                                    button
                                                    onClick={() => onSelectChildCat(cat, subcat)}
                                                    className={classes.nested}
                                                >
                                                    <ListItemIcon
                                                        classes={{root: classes.listItemIcon}}
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                !!sel?.subcategories &&
                                                                sel.subcategories.includes(
                                                                    subcat.id,
                                                                )
                                                            }
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{
                                                                'aria-labelledby': `scat-${subcat.id}`,
                                                            }}
                                                            formControlProps={{
                                                                classes: {
                                                                    root: classes.checkboxFControlRoot,
                                                                },
                                                            }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        id={`scat-${subcat.id}`}
                                                        primary={
                                                            <OverflowTooltip tooltip={subcat.name}>
                                                                <div
                                                                    className={cn(
                                                                        classes.subcatItemTextName,
                                                                        'no-focus-outline',
                                                                    )}
                                                                >
                                                                    {subcat.name}
                                                                </div>
                                                            </OverflowTooltip>
                                                        }
                                                        classes={{root: classes.listItemText}}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                )}
                            </Fragment>
                        );
                    })}
                    <ListItem classes={{root: classes.okContainer}}>
                        <Button onClick={onApply} fullWidth size="small">
                            {T('apply')}
                        </Button>
                    </ListItem>
                </List>
            </Popover>
        </div>
    );
};

export default SelectCategoryField;
