import {useCallback, useEffect, useMemo} from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import {Link} from 'react-router-dom';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

//Components
import Heading, {HeadingContents} from 'hsi/components/aria/Heading';
import Intro, {
    dataManagementIntro,
    quickSearchIntro,
    showFunnelToSMMMessage,
} from 'hsi/containers/Intro';
import OverflowTooltip from 'hsi/components/Tooltip/OverflowTooltip';
import QueryBuilder from 'hsi/containers/QueryBuilder';
import TargetedDataBanner from './TargetedDataBanner';
import PulseLoader from 'hsi/components/PulseLoader';

//Hooks
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import useConfig from 'hsi/hooks/useConfig';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useFlags from 'hsi/hooks/useFlags';

//Other
import {HELP_BUTTON_PLACEMENT} from 'hsi/containers/QueryBuilder/config';
import {isLinkedinSearch} from 'hsi/utils/dataManagement/linkedin';
import withLoadQueryState from 'hsi/hocs/withLoadQueryState';
import {T} from 'hsi/i18n';
import useStyles from './styles';
import {SavedSearchType} from 'hsi/types/shared';

import {
    openQueryBuilder,
    closeQueryBuilder,
    openSearchHelp,
    closeSearchHelp,
    reset,
} from 'hsi/slices/quickSearchPage';
import useQueryBuilderState from '../QueryBuilder/useQueryBuilderState';

const QuickSearch = () => {
    const classes = useStyles();
    const {appName} = useConfig();
    const {displayExitMessage} = useFlags();
    const {track} = useEventTrack();
    const dispatch = useAppDispatch();

    const lastModifiedSearches: SavedSearchType[] = useAppSelector(
        (state) => (state.search as any).lastModifiedSearches,
    );
    const isLoading: boolean = useAppSelector((state) => (state.search as any).searchesLoading);

    const isQueryBuilderOpen = useAppSelector((state) => state.quickSearchPage.isQueryBuilderOpen);
    const isQueryHelpPanelOpen = useAppSelector(
        (state) => state.quickSearchPage.isQueryHelpPanelOpen,
    );

    const setIsQueryBuilderOpen = useCallback(
        (isOpen: boolean) => {
            dispatch(isOpen ? openQueryBuilder() : closeQueryBuilder());
        },
        [dispatch],
    );

    const setIsQueryHelpPanelOpen = useCallback(
        (isOpen: boolean) => {
            dispatch(isOpen ? openSearchHelp() : closeSearchHelp());
        },
        [dispatch],
    );

    const {autofocusHelpPanel, setQueryBuilderOpen, setHelpOpen} = useQueryBuilderState(
        setIsQueryBuilderOpen,
        setIsQueryHelpPanelOpen,
    );

    useEffect(() => {
        track('viewed', {});
        // track should not be included here as the track payload would cause the function
        // to be triggered again
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            //on page unload reset the redux state for this page
            dispatch(reset());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClickViewAll = () => track('viewAllSearchesButtonClicked', {});

    const onClick = (searchName: string) =>
        track('recentSavedSearchButtonClicked', {
            search: searchName,
        });

    const searches = useMemo(
        () =>
            lastModifiedSearches?.filter((search) => !isLinkedinSearch(search) && search.id) ?? [],
        [lastModifiedSearches],
    );

    const hasLastModSearches = !isEmpty(searches);

    const sequence = useMemo(() => {
        const baseSequence = [quickSearchIntro(appName), dataManagementIntro];

        if (displayExitMessage && appName === 'Insights') {
            return [...baseSequence, showFunnelToSMMMessage];
        }

        return baseSequence;
    }, [appName, displayExitMessage]);

    return (
        <div
            className={cn(
                classes.root,
                !hasLastModSearches && classes.bgNoSearch,
                isQueryHelpPanelOpen && classes.helpOpen,
            )}
        >
            <Heading className="offscreen">{T('quicksearch.heading')}</Heading>
            <HeadingContents>
                <TargetedDataBanner />
                <section
                    className={cn(classes.container, hasLastModSearches && classes.bgWithSearches)}
                    aria-label={T('quicksearch.sectionLbl')}
                >
                    <div className={classes.titleContainer}>
                        <Typography variant="h2" component="p" className={classes.title}>
                            {T('quicksearch.title')}
                        </Typography>
                    </div>

                    <Intro sequence={sequence} />

                    <QueryBuilder
                        open={isQueryBuilderOpen}
                        setOpen={setQueryBuilderOpen}
                        helpOpen={isQueryHelpPanelOpen}
                        setHelpOpen={setHelpOpen}
                        autofocusHelpPanel={autofocusHelpPanel}
                        className={classes.queryBuilder}
                        helpButtonPlacement={HELP_BUTTON_PLACEMENT.TOP}
                    />
                </section>
                {isLoading && <PulseLoader className={classes.loading} size="medium" />}
                {hasLastModSearches && (
                    <section
                        aria-labelledby="recentlyViewedTitle"
                        className={classes.recentlyViewed}
                    >
                        <header className={classes.recentlyViewedHeader}>
                            <Heading
                                className={classes.recentlyViewedTitle}
                                id="recentlyViewedTitle"
                            >
                                {T('recently.viewed')}
                            </Heading>
                            <Link
                                to={'/searches'}
                                className={classes.recentlyViewedViewAll}
                                onClick={onClickViewAll}
                            >
                                {T('recently.viewAll')}
                            </Link>
                        </header>
                        <Divider aria-hidden />
                        <ul className={classes.recentSearches}>
                            {searches.map((search) => (
                                <li key={search.id} className={classes.recentSearchItem}>
                                    <OverflowTooltip tooltip={search.name}>
                                        <Link
                                            className={classes.recentSearchLink}
                                            to={`/search/results/${search.project.id}/${search.id}`}
                                            onClick={() => onClick(search.name)}
                                        >
                                            {search.name}
                                        </Link>
                                    </OverflowTooltip>
                                </li>
                            ))}
                        </ul>
                    </section>
                )}
            </HeadingContents>
        </div>
    );
};

export default withLoadQueryState(QuickSearch);
