type PeakLabelProps = {
    fill: string;
    onClick?: () => void;
    text: string;
    label?: string;
    className?: string;
};

//The component
export default function PeakLabel({fill, onClick, text = 'A', label, className}: PeakLabelProps) {
    const svg = (
        <svg
            className={className}
            style={{fill}}
            onClick={onClick}
            aria-hidden={label ? 'true' : undefined}
        >
            <path d="M15.73,23.41,12,29,8.27,23.41a12,12,0,1,1,7.46,0Z"></path>
            <text x="12" y="16" textAnchor="middle" fill="#FFF">
                {text}
            </text>
        </svg>
    );

    return label ? (
        <>
            <span className="offscreen">{label}</span>
            {svg}
        </>
    ) : (
        svg
    );
}
