import React, {useMemo, forwardRef, useCallback} from 'react';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle from 'hsi/components/Card/CardTitle';
import HorizontalStackedBarChart from 'hsi/components/HorizontalStackedBarChart';

import {mentionsDrillIn} from 'hsi/slices/mentions';

import useEventTrack from 'hsi/hooks/useEventTrack';
import useGetLoadData from '../useGetLoadData';
import {useActiveQueryPageTypes} from 'hsi/hooks/usePageTypes';

import {hasCategoriesData} from 'hsi/utils/chart';

const type = 'pageTypeBySearch';

const PageTypeBySearch = forwardRef(({title, ...props}, ref) => {
    const dispatch = useDispatch();
    const {trackWithSearchData} = useEventTrack();
    const pageTypes = useActiveQueryPageTypes();
    const {data, loading, loaded, error} = useSelector((state) => state.chart[type]);
    const hasData = useMemo(() => hasCategoriesData(data), [data]);

    const loadData = useGetLoadData(type);

    const drillIn = useCallback(
        (drillIn) => {
            const drillinValue = drillIn.id;
            const drillinFilter = {
                pageType: {[drillinValue]: true},
                queryId: drillIn.query.id?.toString(),
            };
            const label = `${drillIn.query.name} - ${drillIn.name}`;
            trackWithSearchData('cardDrilledIn', {
                type,
                value: label,
            });
            dispatch(mentionsDrillIn(drillinFilter, type, null, label));
        },
        [dispatch, trackWithSearchData],
    );

    const formatLegend = useCallback(
        (value) => find(pageTypes, (pageType) => pageType.value === value)?.label || value,
        [pageTypes],
    );

    const height = props.height || (data?.results?.length ?? 1) * 75 + 50;

    return (
        <CardLoadState
            {...props}
            error={error}
            hasData={hasData}
            loaded={loaded}
            loading={loading}
            title={<CardTitle title={title} searchType="multiple" type={type} hasData={hasData} />}
            loadData={loadData}
            data-testid={type}
            type={type}
            ref={ref}
        >
            <HorizontalStackedBarChart
                data={data}
                data-testid="horizontalStackedBarChart"
                height={height}
                legendFormatter={formatLegend}
                onClickBar={drillIn}
            />
        </CardLoadState>
    );
});

PageTypeBySearch.propTypes = {
    fadeOnVisible: PropTypes.object,
    height: PropTypes.number,
    isVisible: PropTypes.bool,
    onContentChanged: PropTypes.func,
    showConfig: PropTypes.bool,
};

PageTypeBySearch.displayName = 'PageTypeBySearch';

export default PageTypeBySearch;
