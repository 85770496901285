import {useSelector} from 'react-redux';
import {useMemo} from 'react';
import {selectProjects} from 'hsi/selectors';

const useProjectsById = () => {
    const projects = useSelector(selectProjects);

    const projectsById = useMemo(
        () =>
            (projects || []).reduce((res, d) => {
                res[d.id] = d;
                return res;
            }, {}),
        [projects],
    );

    return projectsById;
};

export default useProjectsById;
