// TODO: Add tests
import {useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';

import LinkedinChannelDialogContent from './DialogContent';

import {useLinkedinAuthentications} from 'hsi/hooks/useDataSource';
import useCreateChannel from './useCreateChannel';
import {useAppSelector} from 'hsi/hooks/useRedux';

import * as targetedDataService from 'hsi/services/targetedDataService';
import {selectProjects} from 'hsi/selectors';
import {isAuthenticated} from 'hsi/utils/dataManagement/linkedin';
import * as queryKeys from 'hsi/constants/queryKeys';

type LinkedinChannelDialogProps = {
    onBack: () => void;
    onClose: () => void;
};

const LinkedinChannelDialog = ({onBack, onClose}: LinkedinChannelDialogProps) => {
    const queryClient = useQueryClient();

    const [loading, setLoading] = useState(false);
    const [orgDetails, setOrgDetails] = useState<any>({}); // TODO: Parse and type orgDetail endpoint

    const {authentications, loading: loadingAuthentications} = useLinkedinAuthentications();
    const projects = useAppSelector(selectProjects);

    const onAuthenticationDone = async () => {
        setLoading(true);
        await queryClient.invalidateQueries([queryKeys.LINKEDIN_CHANNEL_AUTHENTICATIONS]);
        setLoading(false);
        orgDetails?.orgName &&
            updateOrgDetails({
                org: orgDetails?.orgName,
            });
    };

    const updateOrgDetails = async ({org}: {org: string}) => {
        setLoading(true);
        const orgDetails = await targetedDataService.getLinkedinOrgDetails(org);
        setLoading(false);
        setOrgDetails(orgDetails);
    };

    const createChannel = useCreateChannel({
        org: orgDetails.orgId,
        setLoading,
        onClose,
    });

    return (
        <LinkedinChannelDialogContent
            loading={loading || loadingAuthentications}
            orgDetails={orgDetails}
            projects={projects}
            isAuthenticated={isAuthenticated(authentications)}
            updateOrgDetails={updateOrgDetails}
            createChannel={createChannel}
            onAuthenticationDone={onAuthenticationDone}
            {...{onBack, onClose}}
        />
    );
};

export default LinkedinChannelDialog;
