import React, {useMemo, useEffect, useState, useCallback} from 'react';
import {debounce} from 'lodash';

//Components
import ChipInput, {ChipInputProps} from 'hsi/components/ChipInput';

//Hoooks
import useGetAutosuggestSettings from 'hsi/hooks/useGetAutosuggestSettings';
import useProjectId from 'hsi/hooks/useProjectId';
import {useAppDispatch} from 'hsi/hooks/useRedux';

//Types
import {AutosuggestTypes, AutosuggestDataTypeFromAutosuggestType} from 'hsi/types/autosuggest';
import {ChipType} from 'hsi/types/shared';

type ChipInputAutosuggestProps<TAutosuggestType extends AutosuggestTypes> = ChipInputProps<
    ChipType<AutosuggestDataTypeFromAutosuggestType<TAutosuggestType>>,
    false
> & {
    autoSuggestType: TAutosuggestType;
};

//The component
export function ChipInputAutosuggest<TAutosuggestType extends AutosuggestTypes>({
    autoSuggestType,
    onInputChange,
    ...props
}: ChipInputAutosuggestProps<TAutosuggestType>) {
    const projectId = useProjectId();
    const dispatch = useAppDispatch();

    const {loadDataHandler, justOnce, listableKey, idKey, loading, results} =
        useGetAutosuggestSettings(autoSuggestType, projectId!);

    //ChipInput expects options in the same format as it's chips, so need to wrap the results here
    const options = useMemo(() => {
        return results.map(
            (result) =>
                ({
                    //These aren't required, just added here to make the type happy
                    actionType: '',
                    include: false,

                    key: result[idKey] as string,
                    label: result[listableKey] as string,
                    value: result,
                } as ChipType<typeof result>),
        );
    }, [idKey, listableKey, results]);

    //For autosuggest data sources that just need to be loaded once in their entirety at the start
    useEffect(() => {
        if (justOnce) {
            dispatch(loadDataHandler());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [awaitingDebounce, setAwaitingDebounce] = useState(false);

    //Add debouncing to the request
    const debouncedLoadData = React.useMemo(
        () =>
            debounce((str: string) => {
                dispatch(loadDataHandler(str));
                setAwaitingDebounce(false);
            }, 400),
        [dispatch, loadDataHandler],
    );

    const fetch = useCallback(
        (str: string) => {
            setAwaitingDebounce(true);
            debouncedLoadData(str);
        },

        [debouncedLoadData],
    );

    return (
        <ChipInput
            freeSolo={false}
            onInputChange={(str) => {
                !justOnce && fetch(str); //trigger loading of values

                onInputChange?.(str);
            }}
            options={options}
            loading={awaitingDebounce || loading}
            getOptionLabel={(option) => {
                return option.value[listableKey] as string;
            }}
            {...props}
        />
    );
}
