import defaults from 'lodash/defaultsDeep';
import bw_en from './bw-en.js';

const phrases = defaults(
    {
        quicksearch: {
            heading: 'Welcome to Social Listening',
        },
        intro: {
            quickSearchResults: {
                needGuidanceTitle: 'Need help?',
                needGuidanceDescription:
                    "Your search isn't using many boolean operators. Would you like to improve it?",
                guidanceDescription:
                    'Click the dropdown and select <strong>Advanced search help</strong> to see a list of operators and examples to help refine your results.',
            },
        },
        cards: {
            gender: {
                title: 'Male vs. Female breakdown',
                info: {
                    copy: "This metric is calculated by matching the first name in an author's profile to our curated dictionary of almost 50,000 names. If an author has a name that falls into both categories or one that does not match the dictionary, male/female will not be assigned to that author. While it is not a perfect methodology and is not inclusive of all gender identities, it has helped users understand audiences and optimize strategies.",
                },
            },
        },
        filters: {
            type: {
                gender: 'Male vs. Female',
            },
        },
        configSideDrawer: {
            wordCloud: {
                selectItems: {
                    color: {
                        gender: 'Male vs. Female',
                    },
                },
            },
        },
    },
    bw_en,
);

export default phrases;
