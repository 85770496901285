import React from 'react';

import Button from 'hsi/components/Button';

import {T} from 'hsi/i18n';

import useStyles from './styles';

export default function NotFound() {
    const classes = useStyles();

    return (
        <div className={classes.notFound}>
            <h1 className={classes.title}>{T('notFound.oops')}</h1>
            <div className={classes.body}>{T('notFound.broken')}</div>
            <Button priority="cta" onClick={() => (window.location.href = '/')}>
                {T('notFound.return')}
            </Button>
        </div>
    );
}
