import {useEffect, Fragment} from 'react';
import makeStyles from '@mui/styles/makeStyles';

//Components
import QuickSearchBar from 'hsi/components/QuickSearchBar';
import SearchResults from 'hsi/containers/SearchResults';
import InsightsContainer from 'hsi/containers/InsightsContainer';
import Intro, {
    dataManagementIntro,
    filtersIntro,
    quickSearchResultsIntro,
    quickSearchFeedbackIntro,
    timezoneIntro,
} from 'hsi/containers/Intro';

//Actions
import {setMentionsOrder} from 'hsi/slices/mentions';

//Hooks
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';

//Utils
import {scrollTop} from 'hsi/utils/scroll';

//Other
import withLoadQueryState from 'hsi/hocs/withLoadQueryState';
import FullscreenLoading from 'hsi/components/FullscreenLoading';
import {T} from 'hsi/i18n';
import { HeadingContents } from 'hsi/components/aria/Heading';

//Constants
const useStyles = makeStyles({
    '@global': {
        body: {
            overflowY: 'hidden',
        },
    },
});

//The component
const QuickSearchResults = () => {
    useStyles();
    const dispatch = useAppDispatch();

    const isLoaded = useAppSelector((state) => state.query.recentQueriesLoaded);
    const hasDates = useAppSelector(
        (state) => !!state.filters.dateRange.startDate && !!state.filters.dateRange.endDate,
    );

    useEffect(() => {
        scrollTop();

        //reset sort by to prevent invalid sort order when switching between quick and saved search
        dispatch(setMentionsOrder('date', false));
        //We only ever want this to execute once on mount, so we are not passing any dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return !isLoaded || !hasDates ? (
        <FullscreenLoading message={T('loading')} />
    ) : (
        <>
            <Intro
                sequence={[
                    quickSearchResultsIntro,
                    quickSearchFeedbackIntro,
                    timezoneIntro,
                    dataManagementIntro,
                    filtersIntro,
                ]}
            />
            <QuickSearchBar />
            <HeadingContents>
                <InsightsContainer>
                    <SearchResults />
                </InsightsContainer>
            </HeadingContents>
        </>
    );
};

export default withLoadQueryState(QuickSearchResults);
