import {createContext, useCallback, useContext, useMemo, useState} from 'react';
import {
    useDropzone,
    DropzoneOptions,
    FileRejection,
    DropzoneRootProps,
    DropzoneInputProps,
} from 'react-dropzone';

type UploadError = 'pageLimitExceeded' | 'unknown' | 'fileFormat' | 'fileSize';

type UploadZoneContextType = {
    error?: UploadError;
    file?: File;
    getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
    getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
    isActive: boolean;
    isLoading: boolean;
    open: () => void;
    reset: () => void;
};

const UploadZoneContext = createContext<UploadZoneContextType | undefined>(undefined);

export const useUploadZone = () => {
    const context = useContext(UploadZoneContext);

    if (!context) {
        throw new Error('useUploadZone must be used with UploadZoneProvider');
    }

    return context;
};

export type UploadZoneProps = {
    accept?: DropzoneOptions['accept'];
    children: React.ReactNode;
    maxSize?: number;
    onError?: (reason: string) => void;
    onReset?: () => void;
    onUpload?: () => void;
    parseData: (file: File) => void;
};

const UploadZone = ({
    accept,
    children,
    maxSize = 1024000,
    onError,
    onReset,
    onUpload,
    parseData,
}: UploadZoneProps) => {
    const [error, setError] = useState<UploadError>();
    const [file, setFile] = useState<File>();

    const reset = useCallback(() => {
        setError(undefined);
        setFile(undefined);
        onReset?.();
    }, [onReset]);

    const {getInputProps, getRootProps, isFileDialogActive, open} = useDropzone({
        accept,
        maxSize,
        multiple: false,
        noClick: true,
        noDrag: !!file || !!error,
        onDrop: async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
            reset();

            if (acceptedFiles.length) {
                onUpload?.();
                setFile(acceptedFiles[0]);
                parseData(acceptedFiles[0]);
            }

            if (fileRejections.length) {
                const getError = () => {
                    const firstError = fileRejections[0].errors[0].code;
                    switch (firstError) {
                        case 'file-invalid-type':
                            return 'fileFormat';
                        case 'file-too-large':
                            return 'fileSize';
                        default:
                            return 'unknown';
                    }
                };

                const fileRejectionError = getError();
                setError(fileRejectionError);
                onError?.(fileRejectionError);
            }
        },
    });

    const isLoading = isFileDialogActive;
    const isActive = !!error || isLoading || !!file;

    const value = useMemo(
        () => ({
            error,
            file,
            getInputProps,
            getRootProps,
            isActive,
            isLoading,
            open,
            reset,
        }),
        [error, file, getInputProps, getRootProps, isActive, isLoading, open, reset],
    );

    return (
        <UploadZoneContext.Provider value={value}>
            <div data-testid="uploadZone" {...value.getRootProps({style: {display: 'contents'}})}>
                {children}
            </div>
            <input data-testid="uploadZoneInput" hidden {...value.getInputProps()} />
        </UploadZoneContext.Provider>
    );
};

export default UploadZone;
