import {T} from 'hsi/i18n';
import { TableColumnDefinition } from './types';
import CompactNumber from 'hsi/components/format/CompactNumber';



//The hook
export default function useGetTableColumns(isSavedSearch: boolean, classes: Record<string, string>): TableColumnDefinition[] {
    const columns: TableColumnDefinition[] = [
        {
            name: 'position',
            label: <span className="offscreen">{T('cards.tophashtags.tableFields.position')}</span>,
            horizontalAlign: 'start',
            width: 'auto',
            format: (row) => <span className={classes.tableRowPosition}>{row.position}</span>
        },
        {
            name: 'name',
            label: T('cards.tophashtags.tableFields.name'),
            horizontalAlign: 'start',
            truncateHeaderLabelText: true,
            truncateCellText: true,

            width: '2fr',
        },

        {
            name: 'value',
            label: T('cards.tophashtags.tableFields.volume'),
            width: '1fr',
            truncateHeaderLabelText: true,
            sortable: true,
            defaultSortDir: 'desc',
            format: ({value}) => <CompactNumber value={value} tooltip />,
        },
    ];

    if(isSavedSearch)  {
        columns.push({
            name: 'impressions',
            label: T('cards.tophashtags.tableFields.impressions'),
            width: '1fr',
            truncateHeaderLabelText: true,
            sortable: true,
            defaultSortDir: 'desc',
            format: ({impressions}) => <CompactNumber value={impressions} tooltip />,
        }, {
            name: 'reachEstimate',
            label: T('cards.tophashtags.tableFields.reach'),
            width: '1fr',
            truncateHeaderLabelText: true,
            sortable: true,
            defaultSortDir: 'desc',
            format: ({reachEstimate}) => <CompactNumber value={reachEstimate} tooltip />,
        });
    }  

    return columns;
};
