import useConfig from "hsi/hooks/useConfig";
import { HeadingLevel } from "hsi/types/shared";
import { PropsWithChildren, createContext, forwardRef, useContext } from "react";


export type HeadingProps = JSX.IntrinsicElements['h1'];

/**
 * Defaults to a value of undefined, in which case should use the initialHeadingLevel from the config.
 * If the maximum heading level (6) is exceeded, will be set to a value of 'null'. Otherwise will
 * be the current heading level (1-6)
 */
const HeadingLevelContext = createContext<HeadingLevel | undefined | null>(undefined);
HeadingLevelContext.displayName = 'HeadingLevelContext';

export default forwardRef<HTMLHeadingElement, HeadingProps>(function Heading(props, ref) {
    const headingLevel = useHeadingLevel();
    const Component:'p' | `h${HeadingLevel}` = headingLevel !== null ? `h${headingLevel}` : 'p';

    if(process.env.DEPLOYMENT_TYPE === 'development') {
        if(headingLevel === null) {
            console.warn('Maximum heading level exceeded - headers at this depth will use P tags');
        }
    }

    return <Component {...props} ref={ref} />;
});

export function useHeadingLevel() {
    const {initialHeadingLevel} = useConfig();
    const contextHeadingLevel = useContext(HeadingLevelContext)

    return contextHeadingLevel === undefined ? initialHeadingLevel : contextHeadingLevel;
}

export function HeadingContents(props: PropsWithChildren) {
    const currentHeadingLevel = useHeadingLevel();

    const newHeadingLevel: HeadingLevel | null = currentHeadingLevel === null || currentHeadingLevel === 6 
        ? null 
        : currentHeadingLevel + 1 as HeadingLevel;

    return <HeadingLevelContext.Provider value={newHeadingLevel}>{props.children}</HeadingLevelContext.Provider>
}

/** Can be used to fix portalled components incorrectly inheriting heading levels */
export function ResetHeadingLevel({children, transformLevel}: PropsWithChildren<{transformLevel?: (curLevel: HeadingLevel | null) => HeadingLevel | null}>) {
    const {initialHeadingLevel} = useConfig();
    const currentHeadingLevel = useHeadingLevel();

    return <HeadingLevelContext.Provider value={transformLevel ? transformLevel(currentHeadingLevel) : initialHeadingLevel}>{children}</HeadingLevelContext.Provider>
}
export function normaliseHeadingLevel(level: number | null): HeadingLevel | null {
    if(level === null) {
        return null;
    }
    
    level = Math.floor(level);

    return (level < 1 ? 1 : level > 6 ? null : level) as HeadingLevel | null;
}