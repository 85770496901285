import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    activeLineDot: {
        fillOpacity: 0.5,
        transition: 'stroke-width 0.2s ease',
        strokeWidth: 0,
        strokeOpacity: 0.35,
        pointerEvents: 'none',

        '&.entered': {
            strokeWidth: '12px',
        },
    },
}));
