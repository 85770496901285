const regex = /(auto|scroll)/;

export default function findFirstScrollElement(node: HTMLElement | SVGElement | null) {
    if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
        throw new Error('Please supply a valid HTMLElement or SVGElement');
    }

    while (node?.parentNode) {
        const style = getComputedStyle(node, null);

        if (
            regex.test(style.getPropertyValue('overflow')) ||
            regex.test(style.getPropertyValue('overflow-x')) ||
            regex.test(style.getPropertyValue('overflow-y'))
        ) {
            return node;
        } else if (style.getPropertyValue('position') === 'fixed') {
            break;
        }

        //check next node up
        node = node.parentNode as HTMLElement | SVGElement | null;
    }

    //If nothing found use document scrolling
    return document.scrollingElement || document.documentElement;
}
