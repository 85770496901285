import React, {useState, useEffect} from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import MuiSelect_ from '@mui/material/Select';

import Toggle from 'hsi/components/Toggle';

import {getBrandName} from '../config/brandConfig';

import useComponentsBaseStyles from './baseStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import tns from 'hsi/i18n/tns';
const t = tns('queryBuilder');

const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        marginRight: '10px',
    },
    select: {
        width: '100%',
    },
});

const useSelectMenuStyles = makeStyles({
    paper: {
        maxHeight: '300px',
    },
});

const MuiSelect = withStyles((theme) => ({
    select: {
        paddingLeft: '6px',
    },
}))(MuiSelect_);

const Select = ({getOptions, name, onChange, placeholder, sectionName, title, values}) => {
    const ccl = useComponentsBaseStyles();
    const scl = useSelectMenuStyles();
    const cl = useStyles();

    const [options, setOptions] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [open, setOpen] = useState(!isEmpty(values));

    const _onChange = (e) =>
        onChange({
            sectionName,
            componentName: name,
            values: [String(e.target.value)].filter((d) => d !== placeholder),
        });

    const toggleOpen = () => {
        setOpen((prev) => !prev);
        onChange({
            sectionName,
            componentName: name,
            values: [],
        });
    };

    useEffect(() => {
        const getOpts = async () => {
            const opts = await getOptions();
            setOptions(opts);
            setLoaded(true);
        };
        getOpts();
    }, [getOptions]);

    const loadingText = t('brandContextLoading');

    const selectOpts = !loaded
        ? [loadingText]
        : [
              ...new Set(
                  [placeholder].concat(
                      (Array.isArray(options) ? options : []).map((d) => getBrandName(d.formState)),
                  ),
              ),
          ].filter((d) => !isEmpty(d));

    return (
        <>
            <div className={cn(ccl.container, cl.container)}>
                <div className={cn(ccl.title, cl.title)}>{title}</div>
                <Toggle checked={open} name={name} onChange={toggleOpen} mode="switch" />
            </div>
            {open && (
                <MuiSelect
                    value={!loaded ? loadingText : get(values, 0) || placeholder}
                    onChange={_onChange}
                    className={cl.select}
                    MenuProps={{
                        disablePortal: true,
                        classes: {
                            paper: scl.paper,
                        },
                    }}
                >
                    {selectOpts.map((brandName, i) => (
                        <MenuItem key={i} value={brandName}>
                            {brandName}
                        </MenuItem>
                    ))}
                </MuiSelect>
            )}
        </>
    );
};

Select.propTypes = {
    getOptions: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    sectionName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
};

export default Select;
