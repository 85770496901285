import {makeStyles} from 'tss-react/mui';
import {isV2} from 'hsi/types/theme';

export default makeStyles()((theme) => {
    const v2 = isV2(theme);
    const chipBg = v2 ? theme.colors.bgActive : theme.colors.secondaryOrange20;

    return {
        container: {},
        chip: {
            backgroundColor: chipBg,
            '&:hover': {
                backgroundColor: chipBg,
            },
            '&:focus': {
                backgroundColor: chipBg,
                boxShadow: 'none',
            },
        },
        hiddenChips: {
            whiteSpace: 'nowrap',
            cursor: 'default',
        },
    };
});
