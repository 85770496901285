import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    msg: {
        width: '150px',
        fontSize: '14px',
        textAlign: 'left',
        fontWeight: theme.font.regular,
    },
    button: {
        ...theme.mixin.linkButton,
        fontWeight: theme.font.semiBold,
    },
}));

export default useStyles;
