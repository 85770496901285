import { EditorProps } from '@monaco-editor/react';
import HIGHLIGHTING_COLORS from './syntaxHighlightingColors';

export const languageId = 'brandwatchQueryLanguage';

export const editorTheme = {
    base: 'vs',
    inherit: true,
    rules: HIGHLIGHTING_COLORS,
    colors: {
        'editor.lineHighlightBackground': '#fff',
    },
} as const;

export function editorConfig (appConfig: EditorProps['options']): NonNullable<EditorProps['options']> {
    return {
        language: languageId,
        contextmenu: false,
        overviewRulerBorder: false,
        hideCursorInOverviewRuler: true,
        minimap: {
            enabled: false,
        },
        scrollbar: {
            horizontal: 'hidden',
        },
        lineNumbers: 'off',
        lineDecorationsWidth: 0,
        lineNumbersMinChars: 2,
        wordWrap: 'off',
        fixedOverflowWidgets: true,
        scrollBeyondLastLine: false,
        hover: {delay: 0},
        wordBasedSuggestions: 'off',
        quickSuggestions: false,
        suggestSelection: 'first',
        overviewRulerLanes: 0,
        renderLineHighlight: 'none',
        automaticLayout: false,
        glyphMargin: false,
        ...appConfig,
    }   
}
