import {useCallback, useEffect, useState} from 'react';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Message from './message';

import usePrevious from 'hsi/hooks/usePrevious';

import {parseErrorMessage} from '../errors';
//import { makeSchema } from './schema';
import {parseOptions} from './options';

const RENDER_TIME_MARGIN = 100;

const useMessage = ({
    actions,
    columns,
    errors,
    filterOptions,
    hasData,
    hasMoreData,
    hasResults,
    loading,
    messageParser,
    page,
    sort,
}) => {
    const [failed, setFailed] = useState(false);
    const [message, setMessage] = useState();
    const [tableInited, setTableInited] = useState(false);

    const onMessage = useCallback(
        (messageParser) => (data) => {
            const {isTableMessage, actionFromMessage} = messageParser;

            if (!isTableMessage(data)) return;

            const {type, payload} = actionFromMessage(data);
            const action = get(actions, type);

            if (typeof action === 'function') action(payload);
        },
        [actions],
    );

    const send = useCallback(
        (type, payload) => messageParser && message?.send(messageParser.makeMessage(type, payload)),
        [message, messageParser],
    );

    const prevHasData = usePrevious(hasData);
    const prevLoading = usePrevious(loading);
    const prevSort = usePrevious(sort);

    const init = useCallback(() => {
        if (!message && messageParser)
            setMessage(
                new Message().init({
                    onMessage: onMessage(messageParser),
                    //schema: makeSchema(options),
                }),
            );
    }, [message, messageParser, onMessage]);

    const initTable = useCallback(() => {
        const options = parseOptions({
            columns,
            messageParser,
            filterOptions: filterOptions.filters,
        });

        const metadata = {filters: filterOptions.metadata};
        send('table-init', {
            options,
            metadata,
        });

        setTableInited(true);
    }, [columns, filterOptions, messageParser, send]);

    useEffect(() => {
        //TODO test useMessage dispose
        return () => {
            if (message) message.dispose();
        };
    }, [message]);

    useEffect(() => {
        init();

        if (!message) return;

        if (!isEmpty(errors)) {
            //TODO: REMOVE START - fix until we figure out safari's third party cookie issue
            if (!isEmpty(filter(errors, {error_description: 'Safari Unauthorized'}))) {
                if (failed) {
                    setTimeout(() => send('data-loaded'), RENDER_TIME_MARGIN);
                } else {
                    setFailed(true);
                    const options = parseOptions({
                        columns,
                        messageParser,
                        filterOptions: {},
                    });

                    const metadata = {filters: []};
                    send('table-init', {
                        options,
                        metadata,
                    });
                }
                return;
            }
            //TODO: REMOVE END - fix until we figure out safari's third party cookie issue
            else {
                send('error', errors.map(parseErrorMessage));
                return;
            }
        }

        if (!filterOptions) return;
        if (!tableInited && messageParser) {
            initTable();
            return;
        }
        if (!tableInited) return;
        if (hasData && !loading && prevLoading && page === 0) {
            // added a timeout to fix reportedly receiving data-loaded
            // before loading animation goes away. TODO better solution
            if (hasResults) {
                setTimeout(() => send('data-loaded'), RENDER_TIME_MARGIN);
            } else {
                setTimeout(() => send('no-data'), RENDER_TIME_MARGIN);
            }
        }
        if (hasData && hasMoreData === false && !loading) {
            send('dataset-loaded');
        }
        if (loading && !prevLoading) {
            send('loading-data');
        }
        if (!hasData && prevHasData && !loading) {
            send('no-data');
        }
        // send success message on sort to standardize success actions for consumer
        if (sort && hasData && tableInited && !loading && !prevLoading && prevSort !== sort) {
            send('data-loaded');
        }
    }, [
        columns,
        errors,
        failed,
        filterOptions,
        hasData,
        hasMoreData,
        hasResults,
        init,
        initTable,
        loading,
        message,
        messageParser,
        page,
        prevHasData,
        prevLoading,
        send,
        sort,
        tableInited,
        prevSort,
    ]);

    return {send};
};

export default useMessage;
