import useHootsuiteMessageParser from './useHootsuiteMessageParser';

const messageParsersHooks = {
    hootsuite: useHootsuiteMessageParser,
};

const useMessageParser = (messageParserId) => {
    const _useMessageParser = messageParsersHooks[messageParserId];
    if (!_useMessageParser) {
        console.warn('[MentionsTable] unsupported message parser', messageParserId);
        return;
    }
    return _useMessageParser;
};

export default useMessageParser;
