//Abstract base class for tooltip, and tooltip like components.
//Should not be called directly.
//Does not need unit tests or storybook integration, concrete implementations should do that

//TODO focus management for tooltip-dialog - reverse tabbing opens tooltip, putting tooltip in a portal probably breaks tab-order.
//Both of the above could be fixed with fake elements to move tab order around

//TODO much, much better typing!

//TODO add disable property, which will just render the child as if there was no tooltip (no props, event handlers etc)

import {
    forwardRef,
    cloneElement,
    useMemo,
    useRef,
    useEffect,
    memo,
    Children,
    ForwardedRef,
    MutableRefObject,
    HTMLProps,
    HTMLAttributes,
    ReactNode,
} from 'react';
import {
    offset,
    flip,
    shift,
    arrow,
    useFloating,
    useInteractions,
    useHover,
    useFocus,
    useRole,
    useDismiss,
    FloatingPortal,
    Placement,
    ReferenceType,
    Side,
} from '@floating-ui/react-dom-interactions';
import classNames from 'classnames';
import {isEqual} from 'lodash';

//Hooks
import useRefCallback from 'hsi/hooks/useRefCallback';
import useMemoCompare from 'hsi/hooks/useMemoCompare';

//Utils
import {combineProps, assignRef} from 'hsi/utils/react';
import {filter} from 'hsi/utils/object';

//Other
import useStyles from './styles';
import {ReactElement} from 'react-markdown';
import {Diff, FilterKeys} from 'hsi/types/shared';

export type PositionedBubbleContent = React.ReactNode | ((props: {tooltipId?: string}) => ReactNode);

export interface PositionedBubbleProps<TChildElement extends HTMLElement>
    extends Omit<React.HTMLAttributes<TChildElement>, 'content'> {
    align?: 'center' | 'left' | 'right';
    children?: React.ReactNode;
    content?: PositionedBubbleContent;
    debug?: boolean;
    delay?: number | [number, number];
    distance?: number;
    floatingProps?: JSX.IntrinsicElements['div'];
    //
    labelled?: boolean;
    mergeReferenceProps?: typeof defaultMergeReferenceProps<TChildElement>;
    morePadding?: boolean;
    noAria?: boolean;
    noFocus?: boolean;
    noHover?: boolean;
    open?: boolean;
    setOpen?: () => void;
    placement?: Placement;
    portal?: boolean;
    role?: 'tooltip' | 'dialog' | 'alert' | 'none';
    theme?: 'light' | 'dark' | null;
    onShow?: () => void;
    onHide?: () => void;
}

export interface PositionedBubblePositionElementProps<
    TChildElement extends HTMLElement,
    TPositionedElement extends HTMLElement,
> extends PositionedBubbleProps<TChildElement> {
    getPositionElement: (element: TChildElement) => TPositionedElement | null;
}

//The exported element type (combines ref as one of the props)
type PositionedBubbleType = {
    <TChildElement extends HTMLElement = HTMLElement>(
        props: PositionedBubbleProps<TChildElement> & {ref?: ForwardedRef<TChildElement>},
    ): ReactElement;
    <
        TChildElement extends HTMLElement = HTMLElement,
        TPositionedElement extends HTMLElement = TChildElement,
    >(
        props: PositionedBubblePositionElementProps<TChildElement, TPositionedElement> & {
            ref?: ForwardedRef<TChildElement>;
        },
    ): ReactElement;
};

type PrevPositionRefsType<
    TChildElement extends HTMLElement,
    TPositionedElement extends HTMLElement,
> = {
    unsubscribe?: () => void;
    elem?: TChildElement;
    positionElem?: TPositionedElement;
};

function PositionedBubble<TChildElement extends HTMLElement = HTMLElement>(
    props: PositionedBubbleProps<TChildElement>,
    ref: ForwardedRef<TChildElement>,
): React.ReactElement;

function PositionedBubble<
    TChildElement extends HTMLElement = HTMLElement,
    TPositionedElement extends HTMLElement = TChildElement,
>(
    props: PositionedBubblePositionElementProps<TChildElement, TPositionedElement>,
    ref: ForwardedRef<TChildElement>,
): React.ReactElement;

//The component
function PositionedBubble<
    TChildElement extends HTMLElement = HTMLElement,
    TPositionedElement extends HTMLElement = TChildElement,
>(
    {
        align = 'center',
        children,
        content,
        debug = false,
        distance = 5,
        delay = 0,
        floatingProps: customFloatingProps,
        getPositionElement,
        labelled = false,
        mergeReferenceProps = defaultMergeReferenceProps,
        morePadding = false,
        noAria = false,
        noHover = false,
        noFocus = false,
        open,
        placement = 'top',
        portal = false,
        role = 'tooltip',
        setOpen,
        onShow,
        onHide,
        theme = null,
        'aria-hidden': ariaHidden,
        ...rest
    }: PositionedBubbleProps<TChildElement> &
        Partial<
            Diff<
                PositionedBubblePositionElementProps<TChildElement, TPositionedElement>,
                PositionedBubbleProps<TChildElement>
            >
        >,
    ref: ForwardedRef<TChildElement>,
) {
    debug && console.log('render PositionedBubble');

    const {classes} = useStyles();
    const arrowRef = useRef<HTMLDivElement | null>(null);
    const prevPositionRefs = useRef<PrevPositionRefsType<TChildElement, TPositionedElement>>({});
    const referenceElemRef = useRef<TChildElement>(); //This is a reference to the child element

    const renderFloating = !!open || (role !== 'alert' && role !== 'none');

    const {
        x,
        y,
        reference,
        floating,
        strategy,
        context,
        placement: usedPlacement,
        middlewareData: {arrow: baseArrowData},
    } = useFloating<TPositionedElement>({
        placement,
        open,
        onOpenChange: setOpen,
        middleware: [
            offset(distance),
            flip(),
            shift({padding: 8}),
            arrow({element: arrowRef, padding: 5}),
        ],
        whileElementsMounted: updateOnAnimFrame,
        strategy: 'fixed',
    });

    const usedPlacementSide = usedPlacement.split('-')[0] as Side;

    const arrowData = useMemo<typeof baseArrowData>(() => {
        if (usedPlacementSide === 'right') {
            return {y: baseArrowData?.y, centerOffset: baseArrowData?.centerOffset ?? 0};
        } else if (usedPlacementSide === 'top') {
            return {x: baseArrowData?.x, centerOffset: baseArrowData?.centerOffset ?? 0};
        }

        return baseArrowData;
    }, [usedPlacementSide, baseArrowData]);

    const {getReferenceProps, getFloatingProps} = useInteractions(
        [
            useHover(context, {enabled: !noHover, delay: getDelay(delay)}),
            useFocus(context, {enabled: !noFocus}),
            useRole(context, {
                enabled: role !== 'none',
                role: role === 'none' ? undefined : (role as any),
            }),
            useDismiss(context),
        ].filter((x) => !!x),
    );

    // targetRef gets assigned to the child component
    const targetRef = useRefCallback((elem: TChildElement | null) => {
        if (debug) {
            debugger;
        }

        //If a getPositionedElement method is supplied, use it now - if not, use the child ref instead
        const positionElem =
            elem && getPositionElement
                ? getPositionElement(elem)
                : (elem as TPositionedElement | null);

        referenceElemRef.current = elem ?? undefined; //keep a reference to the current child element
        assignRef(reference, positionElem as any); //pass the positioned element to the float-ui hook
        assignRef((children as any)?.ref, elem); //Maintain the child's ref (if applicable)
        assignRef(ref, elem); //If this component was passed a ref, assign the child element to that

        if (getPositionElement) {
            //If we are using getPositionElement, we need to proxy event handlers from the positioning target to the child
            proxyEvents<TChildElement, TPositionedElement>(elem, positionElem, prevPositionRefs);
        }
    });

    //This is a ref to the floating element
    //I think I am doing this to prevent a new 'floating' function
    //from triggering a re-render, which can cause infinite re-render loops
    const floatingRef = useRefCallback((elem: HTMLDListElement) => {
        assignRef(floating, elem);
    });

    const floatingProps = useMemoCompare(
        () =>
            (renderFloating
                ? getFloatingProps(
                      combineProps(customFloatingProps, {
                          ref: floatingRef,
                          className: classNames(
                              classes.root,
                              theme && classes[theme],
                              classes[usedPlacementSide],
                              morePadding && classes.morePadding,
                              classes[`align-${align}`],
                              classes[role as keyof typeof classes],
                          ),
                          style: {
                              position: strategy,
                              top: y ?? 0,
                              left: open ? x ?? 0 : -10000,
                              '--tooltip-distance': distance + 'px',
                          },
                          'data-tooltip-is-open': open ? 'true' : 'false',
                      }) as HTMLProps<HTMLElement>, //getFloatingProps isn't generic, so requires that it's props be limited, so we cast instead
                  )
                : {}) as JSX.IntrinsicElements['div'],
        [
            getFloatingProps,
            floatingRef,
            classes.root,
            strategy,
            x,
            y,
            open,
            theme,
            role,
            usedPlacementSide,
            distance,
        ],
        isEqual,
        (key, value) => key.startsWith('on') && (value as any) instanceof Function,
    );

    const [floatingContent, tooltipId] = useMemo(
        () => {
            debug &&
                console.log(
                    'PositionedBubble floatingContent:',
                    floatingProps,
                    classes,
                    content,
                    arrowData?.x,
                    arrowData?.y,
                    usedPlacementSide,
                    noAria,
                );

            if (!renderFloating) {
                return [null, undefined];
            }

            return [
                <div
                    {...stripAria(floatingProps, noAria, true)}
                    aria-labelledby={
                        noAria || !labelled || role === 'none'
                            ? undefined
                            : `${floatingProps.id}-label`
                    }
                    aria-hidden={ariaHidden ?? (noAria || role === 'none')}
                >
                    <div
                        className={classNames(
                            classes.arrow,
                            theme && classes[theme],
                            classes[usedPlacementSide],
                        )}
                        style={
                            arrowData
                                ? {
                                      left: (arrowData.x ?? `${arrowData.x}px`) || undefined,
                                      top: (arrowData.y ?? `${arrowData.y}px`) || undefined,
                                  }
                                : undefined
                        }
                        ref={arrowRef}
                    ></div>
                    {content instanceof Function ? content({tooltipId: floatingProps.id}) : content}
                </div>,
                floatingProps.id,
            ];
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            floatingProps,
            classes,
            content,
            arrowData?.x,
            arrowData?.y,
            usedPlacementSide,
            labelled,
            noAria,
            theme,
            morePadding,
            align,
            open,
            debug,
        ],
    );

    const referenceProps = useMemoCompare(
        () => getReferenceProps() as HTMLAttributes<TChildElement>,
        [getReferenceProps],
        undefined,
        (key: string, value: any) => (key as string).startsWith('on') && value instanceof Function,
    );

    const child = useMemo(() => Children.only(children) as ReactElement, [children]);

    const elementProps = useMemo(
        () => {
            //debug && console.log('PositionedBubble elementProps');
            return stripAria(
                mergeReferenceProps(targetRef, tooltipId, child, rest, referenceProps),
                noAria,
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [noAria, tooltipId, child, rest, referenceProps, getPositionElement, mergeReferenceProps],
    );

    useEffect(() => {
        //handle onShow/onHide callbacks
        open ? onShow?.() : onHide?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!!open]);

    useEffect(() => {
        //Tidy up func
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            prevPositionRefs.current?.unsubscribe?.();
        };
    }, []);

    return useMemo(() => {
        debug && console.log('PositionedBubble render output');

        return (
            <>
                {cloneElement(child, elementProps)}
                {renderFloating &&
                    (portal ? <FloatingPortal>{floatingContent}</FloatingPortal> : floatingContent)}
            </>
        );
    }, [child, elementProps, portal, renderFloating, floatingContent, debug]);
}

const Component = memo(forwardRef(PositionedBubble)) as unknown as PositionedBubbleType;
//<TChildElement extends HTMLElement, TPositionedElement extends HTMLElement = TChildElement>(props: PositionedBubbleProps<TChildElement> & { ref?: Ref<HTMLDivElement> }) => ReactElement

export default Component;

//Internal helpers
function defaultMergeReferenceProps<TChildElement extends HTMLElement>(
    targetRef: ForwardedRef<TChildElement>,
    tooltipId: string | undefined,
    child: ReactElement,
    rest: HTMLAttributes<TChildElement>,
    referenceProps: HTMLAttributes<TChildElement>,
) {
    return combineProps(child.props, rest, referenceProps, {
        ref: targetRef,
        'aria-describedby': tooltipId,
    });
}

export function mergeReferencePropsMUI<TChildElement extends HTMLElement>(
    targetRef: ForwardedRef<TChildElement>,
    tooltipId: string | undefined,
    child: ReactElement,
    rest: HTMLAttributes<TChildElement> & {inputProps: any},
    referenceProps: HTMLAttributes<TChildElement>,
) {
    return combineProps(
        {
            ref: targetRef, //I think this gets the input, right?
        },
        child.props,
        rest,
        {
            inputProps: combineProps(
                (child.props as any).inputProps,
                rest.inputProps,
                referenceProps,
            ),
        },
    );
}

const updateOnAnimFrame = (reference: ReferenceType, floating: HTMLElement, update: () => void) => {
    let frameUpdateId: any;

    function onUpdate() {
        if (reference && floating?.dataset.tooltipIsOpen === 'true') {
            update();
        }

        frameUpdateId = requestAnimationFrame(onUpdate);
    }

    onUpdate();

    return () => {
        cancelAnimationFrame(frameUpdateId);
    };
};

function getDelay(delay: number | [number, number]) {
    return typeof delay === 'number'
        ? delay
        : {
              open: delay[0],
              close: delay[1],
          };
}

function stripAria<TProps extends {}>(props: TProps, enabled: false, addHidden?: boolean): TProps;
function stripAria<TProps extends {}>(
    props: TProps,
    enabled: true,
    addHidden?: false,
): FilterKeys<TProps, `aria-${string}`>;
function stripAria<TProps extends {}>(
    props: TProps,
    enabled: true,
    addHidden: true,
): FilterKeys<TProps, `aria-${string}`> & {'aria-hidden': true};
function stripAria<TProps extends {}>(props: TProps, enabled: boolean, addHidden?: boolean): TProps;

function stripAria<TProps extends {}>(props: TProps, enabled: boolean, addHidden: boolean = false) {
    if (!enabled) {
        return props;
    }

    const stripped = filter<TProps, FilterKeys<TProps, `aria-${string}`>>(
        props,
        (value: any, key: any) =>
            key !== 'role' && key !== 'tabIndex' && (!key.startsWith('aria-') || key === 'aria-expanded'),
    );

    return addHidden
        ? {
              ...stripped,
              'aria-hidden': 'true',
          }
        : stripped;
}

const proxyEventsEvents = ['mouseenter', 'mousemove', 'mouseleave'] as const;

function proxyEvents<TChildElement extends HTMLElement, TPositionedElement extends HTMLElement>(
    elem: TChildElement | null,
    positionElem: TPositionedElement | null,
    prevPositionRefs: MutableRefObject<PrevPositionRefsType<TChildElement, TPositionedElement>>,
) {
    if (prevPositionRefs.current.elem) {
        //Not the first time
        //If same element
        if (
            prevPositionRefs.current.elem === elem &&
            prevPositionRefs.current.positionElem === positionElem
        ) {
            return; //Do nothing
        }

        //tidy up
        prevPositionRefs.current?.unsubscribe?.();
        prevPositionRefs.current.unsubscribe = undefined;
    }

    if (elem) {
        //If new elements, proxy events
        const handlers = proxyEventsEvents.map((eventType) => {
            const handler = (e: Event) => {
                if (e.target !== positionElem) {
                    positionElem?.dispatchEvent(
                        //TODO should this be a 'new' here?
                        //new (EventModifier as any)(e, {target: positionElem}),
                        EventModifier(e, {target: positionElem}),
                    );
                }
            };

            elem.addEventListener(eventType, handler);

            return handler;
        });

        //record props to allow tidy up in future, if needed
        prevPositionRefs.current.elem = elem;
        prevPositionRefs.current.positionElem = positionElem ?? undefined;
        prevPositionRefs.current.unsubscribe = () =>
            handlers.forEach((handler, i) =>
                elem.removeEventListener(proxyEventsEvents[i], handler),
            );
    }
}

//Creates a proxy of an object, except overriding props supplied in obj
function EventModifier<T1 extends Event, T2 extends Partial<T1>>(evt: T1, obj: T2): T1 {
    const proxy = new Proxy(evt, {
        get: (target, prop) => obj[prop as keyof T2] || target[prop as keyof T1],
    });

    const constructor = Object.getPrototypeOf(evt).constructor;

    return new constructor(evt.type, proxy);
}
