import { ConfigType } from "hsi/types/config";
import { createContext, useContext } from "react";

const ConfigContext = createContext<ConfigType | undefined>(undefined);
ConfigContext.displayName = 'ConfigContext';

export default ConfigContext;

export function useConfig() {
    return useContext(ConfigContext)!;
}