import {useCallback, useMemo} from 'react';

import {resetLocations, loadLocations} from 'hsi/actions/autocompleteActions';
import SearchableSelectContent from 'hsi/components/SearchableSelectContent';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useProjectId from 'hsi/hooks/useProjectId';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import {updateMentionsLocation} from 'hsi/slices/mentions';
import {MentionType} from 'hsi/types/mentions';
import {T} from 'hsi/i18n';

type LocationMenuContentProps = {
    mention: MentionType;
    close: () => void;
    labelId?: string;
    descriptionId?: string;
};

export default function LocationMenuContent({
    mention,
    close: closeMenu,
    labelId,
    descriptionId,
}: LocationMenuContentProps) {
    const dispatch = useAppDispatch();
    const {trackWithSearchData} = useEventTrack();
    const projectId = useProjectId()!;

    const {results, loading} = useAppSelector((state) => state.autocomplete.locationAutocomplete);

    //Callbacks
    const onLocationSelect = useCallback(
        (item: {value: string}) => {
            closeMenu();
            const location = results.find((location) => location.id === item.value);

            dispatch(resetLocations());
            location && dispatch(updateMentionsLocation({ids: [mention.id], location, projectId}));

            trackWithSearchData('mentionEdited', {
                mention: mention?.id,
                type: 'location',
                value: {location: item.value},
            });
        },
        [closeMenu, dispatch, mention, projectId, trackWithSearchData, results],
    );

    //Calculated values
    const options = useMemo(
        () => (results || []).map((item) => ({value: item.id, label: item.qualifiedName})),
        [results],
    );

    return (
        <>
            <span className="offscreen" id={labelId}>
                {T('locationMenu.label')}
            </span>
            <span className="offscreen" id={descriptionId}>
                {T('locationMenu.description')}
            </span>
            <SearchableSelectContent
                loading={loading}
                options={options}
                startSearchMsg={T('locationMenu.findLocations')}
                onChange={(val: string | undefined) => dispatch(val ? loadLocations(projectId, val) : resetLocations())}
                onSelect={onLocationSelect}
                searchPlaceholder={T('locationMenu.locationPlaceholder')}
                debounceSearch
                isAsync
                showSearch
            />
        </>
    );
}
