import cn from 'classnames';
import {ReactElement, forwardRef, useMemo} from 'react';

import IconButton from '@mui/material/IconButton';

import IconRouter from 'hsi/components/IconRouter';
import SimpleMenu from 'hsi/components/SimpleMenu';

import {MentionOrderByType, setMentionsOrder} from 'hsi/slices/mentions';

import useEventTrack from 'hsi/hooks/useEventTrack';

import {T} from 'hsi/i18n';

import Tooltip from 'hsi/components/Tooltip';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import useStyles from './styles';

type SortEntryType = {
    by: MentionOrderByType;
    asc: boolean;
    icon?: ReactElement;
};

const quickSearchOrders: SortEntryType[] = [
    {by: 'date', asc: false},
    {by: 'date', asc: true},
    {by: 'random', asc: false},
    {by: 'relevance', asc: false},
    {by: 'relevance', asc: true},
];

const savedSearchOrders: SortEntryType[] = [
    {by: 'reachEstimate', asc: false},
    {by: 'date', asc: false},
    {by: 'date', asc: true},
    {by: 'md5', asc: false},
    {by: 'twitterRetweets', asc: false, icon: <IconRouter name="logo-twitter" />},
    {by: 'impressions', asc: false, icon: <IconRouter name="logo-twitter" />},
];

type OrderingMenuProps = {
    isSavedSearch: boolean;
};

export default function OrderingMenu({isSavedSearch}: OrderingMenuProps) {
    const {classes} = useStyles();
    const dispatch = useAppDispatch();
    const orderBy = useAppSelector((state) => state.mentions.orderBy);
    const orderIsAsc = useAppSelector((state) => state.mentions.orderIsAsc);
    const {trackWithSearchData} = useEventTrack();

    const entries = useMemo(() => {
        return (isSavedSearch ? savedSearchOrders : quickSearchOrders).map(({by, asc, icon}) => ({
            label: T(`mentionsContainer.order.${by + (asc ? 'Asc' : 'Desc')}`),
            onClick: () => {
                trackWithSearchData('mentionDrawerSorted', {
                    sortField: by,
                    sortDir: asc ? 'asc' : 'desc',
                });
                dispatch(setMentionsOrder(by, asc));
            },
            icon,
            selected: orderBy === by && asc === orderIsAsc,
        }));
    }, [dispatch, isSavedSearch, orderBy, orderIsAsc, trackWithSearchData]);

    //Show export button with on click menu
    return (
        <div className={classes.orderMenuRoot}>
            <span
                className={classes.orderMenuText}
                aria-label={`${T('mentionsContainer.order.orderBy')} ${T(
                    `mentionsContainer.order.${orderBy + (orderIsAsc ? 'Asc' : 'Desc')}`,
                )}`}
            >
                <span className={classes.orderMenuLabel} aria-hidden>
                    {T('mentionsContainer.order.orderBy')}
                </span>{' '}
                <span aria-hidden>
                    {T(`mentionsContainer.order.${orderBy + (orderIsAsc ? 'Asc' : 'Desc')}`)}
                </span>
            </span>

            <SimpleMenu
                title={T('mentionsContainer.sortMenuLbL')}
                hideTitle
                entries={entries}
                buttonComponent={<OrderButton disabled={false} classes={useStyles().classes} />}
                menuDistance={8}
                nonBWStyle
                portal
            />
        </div>
    );
}

type OrderButtonProps = {
    classes: Record<
        'orderMenuRoot' | 'orderMenuBtn' | 'orderMenuIcon' | 'orderMenuText' | 'orderMenuLabel',
        string
    >;
    disabled: boolean;
} & Omit<Parameters<typeof IconButton>[0], 'children'>;

const OrderButton = forwardRef<HTMLButtonElement, OrderButtonProps>(
    ({classes, disabled, className, ...rest}, ref) => (
        <Tooltip tooltip={T('mentionsContainer.sortBtnLbl')} noAria portal>
            <IconButton
                className={cn(classes.orderMenuBtn, disabled && 'disabled', className)}
                {...rest}
                ref={ref}
                aria-label={T('mentionsContainer.sortBtnLbl')}
                size="large"
            >
                <IconRouter name="sort" className={classes.orderMenuIcon} />
            </IconButton>
        </Tooltip>
    ),
);
