//TODO visually indicate current focus position, not with preview, as preview range isn't obvious
//TODO mouse out should clear active cell
import React, {HTMLAttributes, useCallback, useMemo} from 'react';
import {DateTime, DurationLike, Interval, WeekdayNumbers} from 'luxon';
import {range} from 'lodash';
import {IconButton} from '@mui/material';

//Components
import DataGrid, {Directions, WrapType} from 'hsi/components/aria/DataGrid';
import IconRouter from 'hsi/components/IconRouter';

//Hooks
import useUniqueId from 'hsi/hooks/useUniqueId';
import useRefCallback from 'hsi/hooks/useRefCallback';

//Utils
import {mod} from 'hsi/utils/math';
import {dateTimeNow, formatTo, normalizeDate, toISODate} from 'hsi/utils/dates';

//Other
import useStyles from './styles';
import {T} from 'hsi/i18n';
import {isTDateISODate, TDateISODate} from 'hsi/types/dates';

//Consts
const weekDayShortNames = {
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
    7: 'Sun',
};

type DateRangePickerArgs = {
    id?: string;
    showDate?: TDateISODate;
    selectedStart?: TDateISODate;
    selectedEnd?: TDateISODate;
    minDate?: TDateISODate;
    maxDate?: TDateISODate;
    previewStart?: TDateISODate;
    previewEnd?: TDateISODate;
    weekStartsOn?: WeekdayNumbers; //1-7 = mon-sun,
    monthsToShow?: number;
    today?: TDateISODate;

    //Callbacks
    onChangeShowDate?: (value: TDateISODate) => void;
    onChangeActiveDate?: (value: TDateISODate | undefined) => void;
    onSelectDate?: (value: TDateISODate | undefined) => void;
    onDragSelectStart?: (value: TDateISODate) => void;
    onDragSelect?: (start: TDateISODate, end: TDateISODate) => void;
    onDragSelectEnd?: (start: TDateISODate | undefined, end: TDateISODate | undefined) => void; //will be called with undefined/undefined if drag ends outside gridcell
};

//The components
export default function DateRangePicker({
    id: _id,

    showDate: showDateArg,
    //The selected date range
    selectedStart,
    selectedEnd,
    //The allowed date range
    minDate,
    maxDate,
    //Optional preview date range
    previewStart,
    previewEnd,
    today,

    weekStartsOn = 7,

    monthsToShow: _monthstoShow = 2,

    //Optional callbacks
    onChangeShowDate,
    onChangeActiveDate,
    onSelectDate,

    onDragSelectStart,
    onDragSelect,
    onDragSelectEnd,
}: DateRangePickerArgs) {
    const {classes} = useStyles();

    const monthsToShow = Math.max(1, _monthstoShow) | 0;

    //TODO handle invalid format minDate or maxDate?

    //The days that can be selected
    const selectableDateRange = useMemo(() => {
        if (minDate && maxDate) {
            try {
                const normalisedMinDate = normalizeDate(minDate, 'UTC');
                const normalisedMaxDate = normalizeDate(maxDate, 'UTC');

                return normalisedMinDate.isValid && normalisedMaxDate.isValid
                    ? Interval.fromDateTimes(
                          normalisedMinDate.startOf('day'),
                          normalisedMaxDate.endOf('day'),
                      )
                    : undefined;
            } catch (error) {
                //if we get here, normalising dates failed - should probably log this?
                console.error(
                    'Unable to parse dates: ',
                    error,
                    ', minDate = ',
                    minDate,
                    ', maxDate = ',
                    maxDate,
                );
            }
        }
    }, [minDate, maxDate]);

    //The currently selected date range
    const selectedDateRange = useMemo(() => {
        if (!selectedStart || !selectedEnd) {
            return undefined;
        }

        return Interval.fromDateTimes(
            clampDateTime(normalizeDate(selectedStart, 'UTC').startOf('day'), selectableDateRange),
            clampDateTime(normalizeDate(selectedEnd, 'UTC').endOf('day'), selectableDateRange),
        );
    }, [selectedStart, selectedEnd, selectableDateRange]);

    //The dates being previewed
    const previewDateRange = useMemo(
        () =>
            previewStart && previewEnd
                ? Interval.fromDateTimes(
                      normalizeDate(previewStart, 'UTC').startOf('day'),
                      normalizeDate(previewEnd, 'UTC').endOf('day'),
                  )
                : undefined,
        [previewStart, previewEnd],
    );

    const showDate: DateTime = useMemo(() => {
        return clampDateTime(
            showDateArg ? normalizeDate(showDateArg, 'UTC') : dateTimeNow('UTC'),
            selectableDateRange,
        ).startOf('month');
    }, [showDateArg, selectableDateRange]);

    //All the days that could be displayed
    const renderableDateRange = useMemo(
        () =>
            selectableDateRange
                ? Interval.fromDateTimes(
                      selectableDateRange.start.startOf('month'),
                      selectableDateRange.end.plus({months: monthsToShow - 1}).endOf('month'),
                  )
                : undefined,
        [selectableDateRange, monthsToShow],
    );

    //The currently visible months
    const visibleDateRange = useMemo(
        () =>
            Interval.fromDateTimes(
                showDate,
                showDate.plus({months: monthsToShow - 1}).endOf('month'),
            ),
        [showDate, monthsToShow],
    );

    //Callbacks
    const toNextMonth = useRefCallback(() => {
        //limit to visible range
        onChangeShowDate?.(
            toISODate(clampDateTime(showDate.plus({months: 1}), renderableDateRange)),
        );
    });

    const toPrevMonth = useRefCallback(() => {
        //limit to visible range
        onChangeShowDate?.(
            toISODate(clampDateTime(showDate.minus({months: 1}), renderableDateRange)),
        );
    });

    const onDataGridWrap = useCallback<WrapType>((direction: Directions, isLoop: boolean, x, y) => {
        return direction !== 'up' && direction !== 'down' && !isLoop;
    }, []);

    const onDataGridSelect = useCallback(
        (value: string | undefined) => onSelectDate?.((value as TDateISODate) ?? undefined),
        [onSelectDate],
    );

    const onDataGridActive = useRefCallback((value: string | undefined) => {
        if (value && isTDateISODate(value)) {
            //would only change if we go outside the currently rendered range
            const date = DateTime.fromISO(value, {zone: 'UTC'});
            const startOfMonth = date.startOf('month');

            if (!visibleDateRange.contains(startOfMonth)) {
                if (monthsToShow === 1 || startOfMonth < visibleDateRange.start) {
                    onChangeShowDate?.(toISODate(startOfMonth));
                } else {
                    //selecting a date after currently visible range
                    //just move enough to bring the selected date into view on the right
                    onChangeShowDate?.(toISODate(startOfMonth.minus({months: monthsToShow - 1})));
                }
            }

            onChangeActiveDate?.(value);
        } else {
            onChangeActiveDate?.(undefined);
        }
    });

    //Init Calculated vars
    const id = useUniqueId(_id, 'datepicker');
    const weekdaysSequence = useMemo(() => {
        const weekdaysSequence: WeekdayNumbers[] = [];

        for (let i = 0; i < 7; i++) {
            weekdaysSequence[i] = (mod(i + weekStartsOn - 1, 7) + 1) as WeekdayNumbers;
        }

        return weekdaysSequence;
    }, [weekStartsOn]);

    const [prevMonth, finalMonth] = useMemo(
        () => [showDate.minus({months: 1}), showDate.plus({months: monthsToShow})],
        [showDate, monthsToShow],
    );

    const monthsToRender = useMemo(
        () => range(-1, monthsToShow + 1).map((offset) => showDate.plus({months: offset})),
        [monthsToShow, showDate],
    );

    const grid = useMemo(() => {
        const monthProps = {
            weekdaysSequence,
            selectableDateRange,
            selectedDateRange,
            previewDateRange,
            today,
        };

        return (
            <DataGrid
                key="grid"
                id={id}
                onWrap={onDataGridWrap}
                onSelect={onDataGridSelect}
                onActive={onDataGridActive}
                initialCell={showDate.toISO()}
                getGridFromSource
                multiselectable
                onValueDragStart={
                    onDragSelectStart
                        ? (initialValue) => {
                              onDragSelectStart(
                                  toISODate(DateTime.fromISO(initialValue, {zone: 'UTC'})),
                              );
                          }
                        : undefined
                }
                onValueDrag={
                    onDragSelect
                        ? (initialValue: string, currentValue: string) => {
                              const initial = DateTime.fromISO(initialValue, {zone: 'UTC'});
                              const current = DateTime.fromISO(currentValue, {zone: 'UTC'});

                              onDragSelect(
                                  toISODate(DateTime.min(initial, current).startOf('day')),
                                  toISODate(DateTime.max(initial, current).endOf('day')),
                              );
                          }
                        : undefined
                }
                onValueDragEnd={
                    onDragSelectEnd
                        ? (initialValue?: string, currentValue?: string) => {
                              if (initialValue === undefined || currentValue === undefined) {
                                  onDragSelectEnd(undefined, undefined);
                              } else {
                                  const initial = DateTime.fromISO(initialValue, {zone: 'UTC'});
                                  const current = DateTime.fromISO(currentValue, {zone: 'UTC'});

                                  onDragSelectEnd(
                                      toISODate(DateTime.min(initial, current).startOf('day')),
                                      toISODate(DateTime.max(initial, current).endOf('day')),
                                  );
                              }
                          }
                        : undefined
                }
            >
                <table className={classes.days} key="datagrid table">
                    {monthsToRender.map((month) => {
                        const isVisible = visibleDateRange.contains(month);

                        return (
                            <Month
                                key={month.toISO()}
                                isVisible={isVisible}
                                aria-labelledby={
                                    isVisible
                                        ? `${id}-monthLabel-${month.year}-${month.month}`
                                        : undefined
                                }
                                month={month}
                                {...monthProps}
                            />
                        );
                    })}
                </table>
            </DataGrid>
        );
        //Only re-render if actually needed, avoid if possible as it breaks the focus
        //handling if the element is re-created
    }, [
        weekdaysSequence,
        selectableDateRange,
        selectedDateRange,
        previewDateRange,
        onDataGridWrap,
        onDataGridSelect,
        onDataGridActive,
        showDate,
        onDragSelectStart,
        onDragSelect,
        onDragSelectEnd,
        classes.days,
        monthsToRender,
        visibleDateRange,
        id,
        today,
    ]);

    return (
        <div className={classes.dateRangePicker}>
            <div className={classes.monthBar}>
                <IconButton
                    aria-label={T('dateRangePicker.prevMonth')}
                    className={classes.changeMonthBtn}
                    onClick={toPrevMonth}
                    disabled={renderableDateRange && !renderableDateRange.contains(prevMonth)}
                    size="large">
                    <IconRouter className={classes.changeMonthBtnIcon} name="arrow-left" />
                </IconButton>
                {monthsToRender.map((month) =>
                    visibleDateRange.contains(month) ? (
                        <span
                            key={month.toISO()}
                            id={`${id}-monthLabel-${month.year}-${month.month}`}
                            className={classes.monthLabel}
                            aria-label={formatTo(month, 'UTC', 'LLLL yyyy')}
                            data-testid="monthLabel"
                        >
                            {formatTo(month, 'UTC', 'LLL yyyy')}
                        </span>
                    ) : undefined,
                )}
                <IconButton
                    aria-label={T('dateRangePicker.nextMonth')}
                    className={classes.changeMonthBtn}
                    onClick={toNextMonth}
                    disabled={renderableDateRange && finalMonth >= renderableDateRange.end}
                    size="large">
                    <IconRouter className={classes.changeMonthBtnIcon} name="arrow-right" />
                </IconButton>
            </div>
            {grid}
        </div>
    );
}

interface MonthArgs extends HTMLAttributes<HTMLTableSectionElement> {
    month: DateTime;
    isVisible: boolean;
    selectableDateRange?: Interval;
    selectedDateRange?: Interval;
    previewDateRange?: Interval;
    weekdaysSequence: WeekdayNumbers[];
    today: TDateISODate | undefined;
}

function Month({
    month,
    isVisible,
    className,
    weekdaysSequence,
    selectableDateRange,
    selectedDateRange,
    previewDateRange,
    today,
    ...rest
}: MonthArgs) {
    const {classes, cx} = useStyles();

    const thisMonth = Interval.fromDateTimes(month.startOf('month'), month.endOf('month'));
    const selectable = !selectableDateRange
        ? thisMonth
        : intervalOverlap(selectableDateRange, thisMonth); //How much of this month is selectable
    const weekStartsOn = weekdaysSequence[0];
    const weekEndsOn = weekdaysSequence[weekdaysSequence.length - 1];

    //was using endOf('week'), but calendar uses sunday as the start of the week currently, and luxon always uses monday
    //so had to change to this To allow configurable start/end of week day
    const rendered = Interval.fromDateTimes(
        prevWeekDay(thisMonth.start, weekStartsOn),
        nextWeekDay(thisMonth.end, weekEndsOn),
    );

    return (
        <tbody
            key={month.toISO()}
            className={cx(className, isVisible ? classes.monthDays : 'offscreen')}
            {...rest}
            aria-hidden={!isVisible ? 'true' : undefined}
            data-testid="monthDays"
        >
            {isVisible && (
                <tr key="header" className={classes.headerRow} aria-hidden>
                    {weekdaysSequence?.map((weekday) => (
                        <td
                            key={weekday}
                            className={cx(
                                classes.columnHeader,
                                weekday === 6 || weekday === 7 ? classes.weekend : classes.weekday,
                            )}
                        >
                            {weekDayShortNames[weekday]}
                        </td>
                    ))}
                </tr>
            )}

            {Array.from(splitIntervalByDuration(rendered, {weeks: 1})).map((week) => (
                <tr key={week.toISO()} className={classes.week}>
                    {Array.from(intervalDurationIterator(week, {days: 1})).map((day) => {
                        const value = toISODate(day);
                        const isThisMonth = thisMonth.contains(day);
                        const isSelectable = selectable?.contains(day) ?? false;
                        const isWeekend = day.weekday === 6 || day.weekday === 7;
                        const isStartOfWeek = day.weekday === weekStartsOn;
                        const isEndOfWeek = day.weekday === weekEndsOn;
                        const isStartOfMonth = day.day === 1;
                        const isEndOfMonth = day.day === day.daysInMonth;
                        const isToday = isThisMonth && today === value; //Only counted for 'active' cells, decorative cells not counted as 'today'

                        const isSelected =
                            isSelectable && (selectedDateRange?.contains(day) ?? false);
                        const isSelectedStart =
                            isSelected && +day === +(selectedDateRange as Interval).start;
                        const isSelectedEnd =
                            isSelected &&
                            day.plus({days: 1}) >= (selectedDateRange as Interval).end;

                        const isPreview =
                            isSelectable && (previewDateRange?.contains(day) ?? false);
                        const isPreviewStart =
                            isPreview && +day === +(previewDateRange as Interval).start;
                        const isPreviewEnd =
                            isPreview && day.plus({days: 1}) >= (previewDateRange as Interval).end;

                        return (
                            <DataGrid.Cell
                                value={value}
                                key={day.day}
                                disabled={!isSelectable}
                                selected={isSelected}
                            >
                                <td
                                    key={day.day}
                                    aria-label={
                                        day.toFormat('ccc, MMM dd yyyy', {locale: 'en-US'}) +
                                        (isToday ? T('dateRangePicker.isToday') : '')
                                    }
                                    className={cx(
                                        classes.day,
                                        !isThisMonth && classes.passive,
                                        !isSelectable && classes.disabled,
                                        isWeekend ? classes.weekend : classes.weekday,
                                        isStartOfWeek && classes.startOfWeek,
                                        isEndOfWeek && classes.endOfWeek,
                                        isStartOfMonth && classes.startOfMonth,
                                        isEndOfMonth && classes.endOfMonth,
                                        isToday && classes.today,

                                        isSelected && classes.selected,
                                        isSelectedStart && classes.selectedStart,
                                        isSelectedEnd && classes.selectedEnd,

                                        isPreview && classes.preview,
                                        isPreviewStart && classes.previewStart,
                                        isPreviewEnd && classes.previewEnd,
                                    )}
                                >
                                    <time
                                        aria-hidden
                                        className={classes.dayNumber}
                                        dateTime={day.toISODate()}
                                    >
                                        {day.day}
                                    </time>
                                </td>
                            </DataGrid.Cell>
                        );
                    })}
                </tr>
            ))}
        </tbody>
    );
}

//Utility methods

function* splitIntervalByDuration(interval: Interval, duration: DurationLike) {
    let cursor = interval.start;

    while (cursor < interval.end) {
        const next = cursor.plus(duration);

        yield Interval.fromDateTimes(cursor, next);

        cursor = next;
    }
}

function* intervalDurationIterator(interval: Interval, duration: DurationLike) {
    let cursor = interval.start;

    while (cursor < interval.end) {
        yield cursor;
        cursor = cursor.plus(duration);
    }
}

//if the supplied date is the selected weekday, will return the start of that day,
//otherwise will return the first day before this one that = weekdayNumber
function prevWeekDay(date: DateTime, weekDayNumber: number): DateTime {
    const initial = date.startOf('day');

    if (initial.weekday === weekDayNumber) {
        return initial;
    }

    return initial.minus({days: mod(initial.weekday - 1 - (weekDayNumber - 1), 7)});
}

function nextWeekDay(date: DateTime, weekDayNumber: number): DateTime {
    const initial = date.endOf('day');

    if (initial.weekday === weekDayNumber) {
        return initial;
    }

    return initial.plus({days: mod(weekDayNumber - 1 - (initial.weekday - 1), 7)});
}

function clampDateTime(date: DateTime, interval?: Interval): DateTime;
function clampDateTime(date: DateTime, min?: DateTime, max?: DateTime): DateTime;

function clampDateTime(date: DateTime, arg1?: any, arg2?: any): DateTime {
    let min: DateTime | undefined;
    let max: DateTime | undefined;

    if (arg1 instanceof Interval) {
        const interval = arg1 as Interval;

        min = interval.start;
        max = interval.end;
    } else {
        min = arg1;
        max = arg2;
    }

    if (min && date < min) {
        return min;
    } else if (max && date > max) {
        return max;
    }

    return date;
}

function intervalOverlap(
    int1: Interval | undefined,
    int2: Interval | undefined,
): Interval | undefined {
    return int1 && int2
        ? Interval.fromDateTimes(
              DateTime.max(int1.start, int2.start),
              DateTime.min(int1.end, int2.end),
          )
        : undefined;
}
