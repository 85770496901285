import React, {memo, useMemo, useState} from 'react';
import cn from 'classnames';

import {
    BarChart,
    Bar,
    CartesianGrid,
    Customized,
    Legend,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';
import PropTypes from 'prop-types';

import ChartTooltip from 'hsi/components/ChartTooltip';
import ChartTooltipContent from 'hsi/components/ChartTooltipContent';
import {OverflowTooltip} from 'hsi/components/SimpleTooltip';

import {formatBigIntFull, formatPercent} from 'hsi/utils/formatNumber';

import {T} from 'hsi/i18n';

const barHeight = 34;

//foreingObject tag has issues when exporting as canvas
// to solve this it needs inline styles
// also -webkit-box-orient and -webkit-line-clamp show a warning
// as non valid jsx wise but required to work on canvas

const CustomTick = ({tickFormatter, payload, width, x, y}) => {
    const content = !!tickFormatter ? tickFormatter(payload.value) : payload.value;

    return (
        <foreignObject
            cursor="pointer"
            height={barHeight}
            width={width}
            x={x - 5}
            y={y - barHeight / 2}
        >
            <div
                style={{
                    //why are these styles inline?
                    height: barHeight,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <OverflowTooltip tooltip={content}>
                    <div
                        style={{
                            //why are these styles inline?
                            color: 'grey',
                            fontSize: 12,
                            maxWidth: 80,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            alignItems: 'center',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                        }}
                    >
                        <span style={{width}}>{content}</span>
                    </div>
                </OverflowTooltip>
            </div>
        </foreignObject>
    );
};

const HorizontalStackedBarChart = ({className, data, height, legendFormatter, onClickBar}) => {
    const [tooltip, setTooltip] = useState(null);

    const tooltipContent = useMemo(
        () => ({
            amount: formatPercent(tooltip?.amount),
            color: tooltip?.color,
            unit: `(${formatBigIntFull(tooltip?.unit)} ${T('mentions')})`,
        }),
        [tooltip],
    );

    return (
        <div className={cn(className)} data-testid="horizontalStackedBarChart">
            <ResponsiveContainer
                debounce={1} // Solves this issue: https://github.com/recharts/recharts/issues/511
                height={height}
                width={'100%'}
            >
                <BarChart
                    barGap={0}
                    data={data?.results}
                    layout="vertical"
                    margin={{left: 90, right: 50, bottom: 10}}
                    onMouseLeave={() => setTooltip(null)}
                    stackOffset="expand"
                >
                    <CartesianGrid horizontal={false} stroke="#cdd2d4" strokeDasharray={4} />
                    <XAxis
                        axisLine={false}
                        tick={<CustomTick count={data?.result?.length} />}
                        tickFormatter={(value) => formatPercent(value)}
                        tickLine={false}
                        tickMargin={5}
                        type="number"
                    />
                    <YAxis
                        axisLine={false}
                        dataKey="name"
                        mirror={true}
                        padding={{top: 4, bottom: 4}}
                        stroke="#504C4D"
                        tick={<CustomTick />}
                        tickLine={false}
                        tickMargin={-86}
                        type="category"
                    />
                    <Legend
                        align="center"
                        formatter={(value) => (!!legendFormatter ? legendFormatter(value) : value)}
                        height={80}
                        iconSize={12}
                        iconType="circle"
                        verticalAlign="bottom"
                        wrapperStyle={{paddingTop: '0px'}}
                    />

                    {data?.categories?.map(({id, color, name}) => (
                        <Bar
                            barSize={barHeight}
                            cursor="pointer"
                            dataKey={id}
                            fill={color}
                            key={id}
                            onClick={(ev) =>
                                !!onClickBar &&
                                onClickBar({
                                    id,
                                    name,
                                    query: {
                                        id: ev.id,
                                        name: ev.name,
                                    },
                                })
                            }
                            onMouseEnter={(ev) =>
                                setTooltip({
                                    amount: ev.value[1] - ev.value[0],
                                    color,
                                    name,
                                    pos: ev.tooltipPosition,
                                    unit: ev.tooltipPayload[0].value,
                                })
                            }
                            stackId="a"
                        />
                    ))}

                    {!!tooltip && (
                        <Customized
                            key="tooltip"
                            component={
                                <>
                                    <ChartTooltip
                                        {...tooltip.pos}
                                        animationMs={200}
                                        distance={0}
                                        onHide={() => setTooltip(null)}
                                        open={true}
                                        showArrow={false}
                                    >
                                        <ChartTooltipContent
                                            title={tooltip.name}
                                            valueItems={[tooltipContent]}
                                        />
                                    </ChartTooltip>
                                </>
                            }
                        />
                    )}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

HorizontalStackedBarChart.propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({
        categories: PropTypes.arrayOf(
            PropTypes.shape({
                color: PropTypes.string.isRequired,
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            }),
        ).isRequired,
        results: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
                name: PropTypes.string.isRequired,
            }),
        ).isRequired,
    }).isRequired,
    height: PropTypes.number,
    legendFormatter: PropTypes.func,
    onClickBar: PropTypes.func,
};

export default memo(HorizontalStackedBarChart);
