import React, {useMemo} from 'react';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//The component
export default function ErrorMessage({error, errorProps = null, wide = false}) {
    useStyles();

    const errorMsg = useMemo(() => parseError(error, errorProps), [error, errorProps]);

    return errorMsg;
}

function parseError(error, errorProps) {
    if (error instanceof Array) {
        return error.map((error, index) => <p key={index}>{parseError(error, errorProps)}</p>);
    }

    if (typeof error === 'string') {
        return T(error, errorProps);
    }

    return T(error.key, error.props || errorProps);
}
