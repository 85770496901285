import {useCallback, useMemo, useState} from 'react';

import Banner from 'hsi/components/Banner';
import ColorSample from 'hsi/components/ColorSample';
import DataTable, {DataTableProps, Order, RenderDefaultErrorProps} from 'hsi/components/DataTable';
import DeleteDialog from './DeleteDialog';
import Intro, {introAnchors, dataManagementExploreIntro} from 'hsi/containers/Intro';
import LoadError from './LoadError';
import Markdown from 'hsi/components/Markdown';
import NoResults from './NoResults';

import useConfig from 'hsi/hooks/useConfig';
import useDataManagement from 'hsi/hooks/useDataManagement';
import useDates from 'hsi/hooks/useDates';
import useProjectsById from 'hsi/hooks/useProjectsById';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useStyles from './styles';

import {T} from 'hsi/i18n';

import {DataManagementDataTypes, Item} from 'hsi/types/dataManagement';

import {
    isLinkedinChannelSourceType,
    isLinkedinAuthenticationSourceType,
} from 'hsi/utils/dataManagement/linkedin';

type TableProps = {
    info?: string;
    handleAddDataSource: () => void;
    label: string;
    type: DataManagementDataTypes;
};

const Table = ({info, handleAddDataSource, label, type}: TableProps) => {
    const classes = useStyles();
    const config: any = useConfig();
    const {formatTo} = useDates();
    const dataSources = useDataManagement(type);
    const projectsById = useProjectsById();
    const {track} = useEventTrack();

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState<Item>();

    const dataSource = useMemo(() => dataSources[type], [dataSources, type]);

    const actions = useMemo(
        () => [
            ...(!!dataSource?.onExplore
                ? [
                      {
                          id: 'explore',
                          icon: 'search', // This icon is referenced in the explore intro tooltip so will need to be updated if that changes
                          label: T('explore'),
                          handleClick: (item: any) => {
                              dataSource.onExplore(item);
                              track('targetedDataExploreModeClicked', item);
                          },
                      },
                  ]
                : []),
            ...(!isLinkedinAuthenticationSourceType(type)
                ? [
                      {
                          id: 'delete',
                          icon: 'delete',
                          label: T('delete'),
                          handleClick: (item: any) => {
                              setIsDeleteDialogOpen(true);
                              setCurrentItem(item);
                          },
                      },
                  ]
                : []),
        ],
        [dataSource, type, track],
    );

    const fields = useMemo(
        () => [
            {
                id: 'name',
                isSortable: true,
                sortId: ['instagram-account'].includes(type)
                    ? 'igbaHandle'
                    : ['facebookUserAuthentications'].includes(type)
                    ? 'facebookUserName'
                    : ['instagram-hashtag'].includes(type)
                    ? 'hashtag'
                    : undefined,
                label: T('name'),
                width: '30%',
            },
            ...(['facebook-page'].includes(type)
                ? [
                      {
                          id: 'username',
                          label: T('dataManagement.table.username'),
                          isSortable: true,
                          width: '10%',
                      },
                  ]
                : []),
            ...(['instagram-account'].includes(type)
                ? [
                      {
                          id: 'displayName',
                          sortId: 'igbaDisplayName',
                          label: T('dataManagement.table.displayName'),
                          isSortable: true,
                          width: '10%',
                      },
                  ]
                : []),
            ...(isLinkedinChannelSourceType(type)
                ? [
                      {
                          id: 'projectId',
                          label: T('dataManagement.table.team'),
                          format: (projectId: string) => projectsById[projectId]?.name,
                          isSortable: true,
                          width: '10%',
                      },
                  ]
                : []),
            ...(['facebook-page', 'instagram-account'].includes(type)
                ? [
                      {
                          id: 'owned',
                          label: T('dataManagement.table.ownership'),
                          format: (owned: boolean): string =>
                              owned
                                  ? T('dataManagement.table.owned')
                                  : T('dataManagement.table.nonOwned'),
                          isSortable: true,
                          width: '10%',
                      },
                      {
                          id: 'id',
                          sortId: ['facebook-page'].includes(type)
                              ? 'pageId'
                              : ['instagram-account'].includes(type)
                              ? 'instagramBusinessAccountId'
                              : undefined,
                          label: T('dataManagement.table.id'),
                          isSortable: true,
                          tooltipLbl: T('dataManagement.tableTooltip.lbl'),
                          tooltip: T('dataManagement.tableTooltip.id'),
                          width: '10%',
                      },
                  ]
                : []),
            ...(!isLinkedinAuthenticationSourceType(type)
                ? [
                      {
                          id: 'created',
                          label: T('dataManagement.table.created'),
                          format: (date: string) => formatTo(date, 'ccc, MMM dd yyyy'),
                          isSortable: true,
                          width: '10%',
                      },
                  ]
                : []),
            {
                id: 'authenticated',
                label: T('status'),
                format: (active: boolean) => (
                    <span>
                        <ColorSample
                            color={active ? config.themeColors.success : config.themeColors.error}
                        />
                        {active
                            ? T('dataManagement.table.active')
                            : T('dataManagement.table.inactive')}
                    </span>
                ),
                isSortable: true,
                width: '10%',
            },
            ...(['instagram-hashtag'].includes(type)
                ? [
                      {
                          id: 'linkedAccount',
                          label: T('dataManagement.table.linkedAccount'),
                          isSortable: true,
                          width: '10%',
                      },
                  ]
                : []),
            ...(['facebookUserAuthentications'].includes(type)
                ? [
                      {
                          id: 'email',
                          label: T('dataManagement.table.email'),
                          isSortable: true,
                          sortId: 'facebookUserEmail',
                          width: '100%',
                      },
                  ]
                : []),
        ],
        [config, formatTo, projectsById, type],
    );

    const renderError = useCallback(
        ({classes: tableClasses}: RenderDefaultErrorProps) => (
            <div className={tableClasses.wrapper} data-testid="dataManagementLoadError">
                <LoadError />
            </div>
        ),
        [],
    );

    const renderNoResults = useCallback<NonNullable<DataTableProps['renderNoResults']>>(
        ({classes: tableClasses}) => (
            <div className={tableClasses.wrapper} data-testid="dataManagementNoResults">
                <NoResults handleAddDataSource={handleAddDataSource} label={label} />
            </div>
        ),
        [handleAddDataSource, label],
    );

    const handleDeleteDialogClose = useCallback(() => {
        setIsDeleteDialogOpen(false);
        setCurrentItem(undefined);
    }, []);

    const handleDeleteDataSource = useCallback(async () => {
        if (currentItem) {
            await dataSource?.remove?.mutate?.(currentItem);
        }

        handleDeleteDialogClose();
    }, [currentItem, dataSource?.remove, handleDeleteDialogClose]);

    return (
        <div className={classes.container} data-testid="dataManagementTable">
            <div className={classes.contentContainer}>
                {info && (
                    <Banner className={classes.banner} hidable>
                        <Markdown className={classes.bannerText}>{info}</Markdown>
                    </Banner>
                )}

                <DataTable
                    actions={actions}
                    actionsClassName={
                        [
                            'facebook-page',
                            'instagram-account',
                            'instagram-hashtag',
                            'linkedin-channel',
                        ].includes(type)
                            ? introAnchors.dataManagement.explore
                            : ''
                    }
                    caption={T('dataManagement.table.caption')}
                    data={dataSource?.data}
                    defaultFilter={dataSource?.params?.search}
                    defaultOrder={dataSource?.params?.order as Order}
                    defaultOrderBy={dataSource?.params?.orderBy}
                    error={dataSource?.isError}
                    fields={fields}
                    filterPlaceholder={T('dataManagement.table.filterPlaceholder')}
                    hasFilter
                    label={T('dataManagement.table.label')}
                    loading={dataSource?.isLoading}
                    notFound={
                        !dataSource?.isError &&
                        !dataSource?.isLoading &&
                        !dataSource?.data?.length &&
                        !!dataSource?.params?.search
                    }
                    noResults={
                        !dataSource?.isError &&
                        !dataSource?.isLoading &&
                        !dataSource?.data?.length &&
                        !dataSource?.params?.search
                    }
                    onFilter={(value) => {
                        dataSource?.params?.setSearch?.(value);
                    }}
                    onSort={(orderBy, order) => {
                        dataSource?.params?.setOrder?.(order);
                        (dataSource?.params as any)?.setOrderBy?.(orderBy);
                    }}
                    onVisible={() => {
                        dataSource?.fetchNextPage?.();
                    }}
                    renderError={renderError}
                    renderNoResults={renderNoResults}
                    totalRows={dataSource?.totalElements}
                />
            </div>
            <DeleteDialog
                name={currentItem?.name}
                onClose={handleDeleteDialogClose}
                onConfirm={handleDeleteDataSource}
                open={isDeleteDialogOpen}
                type={type}
            />
            <Intro sequence={[dataManagementExploreIntro]} />
        </div>
    );
};

export default Table;
