import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {openImpersonationDialog} from 'hsi/actions/userActions';
import {Dialog} from 'hsi/components/Dialog';

import ImpersonationForm from './ImpersonationForm';

const selectDialogOpen = (state) => state.user.openImpersonationDialog;

const ImpersonationDialog = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector(selectDialogOpen);
    const onClose = () => dispatch(openImpersonationDialog(false));

    return isOpen ? (
        <Dialog title="Impersonate User" onClose={onClose} open={isOpen}>
            <ImpersonationForm />
        </Dialog>
    ) : null;
};

export default ImpersonationDialog;
