import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

//Components
import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle from 'hsi/components/Card/CardTitle';
import InfoPopupContent from 'hsi/components/InfoPopupContent';
import HeatmapChart from './HeatmapChart';

//Actions
import {mentionsDrillIn} from 'hsi/slices/mentions';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useGetLoadData from '../useGetLoadData';

//Other
import {T} from 'hsi/i18n';
const type = 'heatmap';

//The components
const Heatmap = React.forwardRef(function Heatmap({title, ...props}, ref) {
    const dispatch = useDispatch();
    const {
        links: {dashboardInfoHeatmapCTA: popupCTA},
    } = useConfig();
    const {trackWithSearchData} = useEventTrack();

    const drillInCard = useSelector((state) => state.mentions.drillInCard);
    const {data, loading, loaded, error, errorCode} = useSelector((state) => state.chart[type]);

    const loadData = useGetLoadData(type);
    const hasData = useMemo(
        () => !!data?.results.some(({values}) => values.some(({value}) => value > 0)),
        [data],
    );

    const drillIn = useCallback(
        (drillIn) => {
            const {day, hour, label} = drillIn;

            trackWithSearchData('cardDrilledIn', {
                type,
                value: label,
            });
            dispatch(mentionsDrillIn({dayOfWeek: day, hourOfDay: hour}, type, null, label));
        },
        [dispatch, trackWithSearchData],
    );

    const content = useMemo(() => {
        if (!data) return null;
        return (
            <HeatmapChart
                buckets={data.buckets}
                data={data.results}
                onClick={drillIn}
                values={data.results[0].values}
            />
        );
    }, [data, drillIn]);

    const popup = useMemo(
        () => (
            <InfoPopupContent
                copy={T(`cards.${type}.info.copy`)}
                ctaLabel={T('cards.infoCTALabel')}
                ctaUrl={popupCTA}
                title={T(`cards.${type}.info.title`)}
            />
        ),
        [popupCTA],
    );

    return (
        <CardLoadState
            {...props}
            data-testid={type}
            error={error}
            hasData={hasData}
            loadData={loadData}
            loaded={loaded}
            loading={loading}
            notAvailablePerDataLimitation={errorCode === 'invalid_dimension'}
            ref={ref}
            selected={drillInCard === type}
            title={
                <CardTitle title={title} tooltipComponent={popup} type={type} hasData={!!data} />
            }
            type={type}
        >
            {content}
        </CardLoadState>
    );
});

Heatmap.propTypes = {
    props: PropTypes.shape({
        fadeOnVisible: PropTypes.func.isRequired,
        height: PropTypes.number.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onContentChanged: PropTypes.func.isRequired,
        showConfig: PropTypes.bool.isRequired,
    }),
};

Heatmap.displayName = 'Heatmap';

export default Heatmap;
