import Button from 'hsi/components/Button';

import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';

import {logOut, openImpersonationDialog} from 'hsi/actions/userActions';

import {selectCanImpersonate, selectIsImpersonated} from 'hsi/selectors';

import useStyles from './styles'

// This should only ever be available to superadmins and no external user should
// be able to view these options

const ImpersonateButton = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const canImpersonate = useAppSelector(selectCanImpersonate);
    const isImpersonated = useAppSelector(selectIsImpersonated);

    if (!canImpersonate && !isImpersonated) return null;

    if (canImpersonate) {
        return <Button className={classes.btn} onClick={() => dispatch(openImpersonationDialog(true))}>Impersonate</Button>;
    }

    if (isImpersonated) {
        <Button className={classes.btn} onClick={() => dispatch(logOut())}>Logout</Button>;
    }

    return null;
};

export default ImpersonateButton;
