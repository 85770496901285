import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles(({v2, fonts, font, colors, mixin}: Theme) => ({
    sortButton: {
        ...mixin.blankButton,
        display: 'flex',
        minWidth: 0,
        flexFlow: 'row nowrap',
        gap: '3px',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        outline: 'none',
        cursor: 'default',
        justifyContent: 'inherit',

        '&$active': {
            cursor: 'pointer',

            '&:hover': {
                background: v2 ? colors.bgHover : colors.lightGrey20,
            },

            '&:focus-visible': {
                color: v2 ? colors.accentHover : colors.secondaryOrange100,
            },
        },

        
    },

    content: {
        flex: 'auto 0 1',
        minWidth: 0,
    },

    icon: {
        fill: 'currentcolor',
        flex: 'auto 0 0',
    },

    active: {},
}));