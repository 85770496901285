import { createAsyncThunk } from '@reduxjs/toolkit';
import { find, isEmpty } from 'lodash';
import { push, replace } from 'redux-first-history';

import { saveExportConfig } from 'hsi/actions/exportActions';
import { initDateRange, loadPersistedFilters, reset, setConfig } from 'hsi/actions/filtersActions';
import { clearResults } from 'hsi/actions/resultsActions';
import QueryService from 'hsi/services/queryService';
import { validateQuery } from 'hsi/services/queryValidationService';
import { loadPersistCardPersistState } from 'hsi/slices/cardPersistState';
import { serializeSearch } from 'hsi/utils/url';

import getConfig from 'hsi/config';
import { SHOW_SNACKBAR_NOTIFICATION } from 'hsi/constants/actionTypes';
import { normalisePersistedFilters } from 'hsi/utils/filters';
import mixpanel from 'hsi/utils/mixpanel';

import { setQueryStateFromURL, updateSavedAndRecentQueries } from './slice';
import { createDefaultQueryObj, createQueryObjFromState, parseGuidedFormToSave } from './utils';

import { T } from 'hsi/i18n';
import { RootReducer } from 'hsi/reducers/rootReducer';
import { CCFlagsType } from 'hsi/types/cc-flags';
import { AllFilteringState, LegacyFilterStateFormat } from 'hsi/types/filters';
import { QueryContextType, QueryType } from 'hsi/types/query';
import { PageType } from 'hsi/types/shared';
import getQueryTypeFromUrl from 'hsi/utils/app/getQueryTypeFromUrl';
import { isLinkedinSearch } from 'hsi/utils/dataManagement/linkedin';
import getFiltersConfig from 'hsi/utils/filters/getFiltersConfig';

/*
  "Thunk" actions
*/

export const validateBooleanQuery = createAsyncThunk<
    void,
    {
        booleanQuery: string;
        projectId: number;
        isSaved: boolean;
    },
    {
        state: RootReducer;
        rejectValue: string[];
    }
>('query/validateBooleanQuery', async ({booleanQuery, projectId, isSaved}, {rejectWithValue}) => {
    const result: {errors?: string[]} = await validateQuery({
        query: booleanQuery,
        projectId,
        isSaved,
    });

    if (result.errors && result.errors.length > 0) {
        return rejectWithValue(result.errors);
    }
});

export const loadRecentQueries = createAsyncThunk<
    QueryType[],
    string | number, //TODO probably a number, needs better typing
    {
        state: RootReducer;
    }
>('query/loadRecentQueries', async (userId, {getState}) => {
    return await QueryService.getUserRecentQueries(userId || getState().user.account.id);
});

export const saveActiveQuery = createAsyncThunk<
    void,
    number,
    {
        state: RootReducer;
    }
>('query/saveActiveQuery', (savedQueryId, {dispatch}) => {
    //use the slices extraReducer to update the state to include the new ID in the context
    dispatch(persistActiveQuery(savedQueryId));
});
//This still feels overly complex
//Should JUST be persisting the active query, not doing this other stuff..
export const persistActiveQuery = createAsyncThunk<
    QueryType | void,
    number | void,
    {
        state: RootReducer;
    }
>('query/persistActiveQuery', async (savedQueryId, {dispatch, getState, rejectWithValue}) => {
    const {query: queryState, filters, pdfExport: exportState, cardPersistState} = getState();
    const savedSearchId = queryState.context?.savedSearch?.id || savedQueryId || null;
    //EA-1715 patch boolean query for linkedin searches
    //TODO linkedin: remove this when LI is implemented without BCR channels
    const booleanQuery = isLinkedinSearch(queryState.context?.savedSearch)
        ? '[linkedin query placeholder]'
        : queryState.booleanQuery;

    if (!booleanQuery) return; //Ignore empty searches

    // Get appropriate filter state data
    const filterState: Pick<
        AllFilteringState,
        'collapsedSections' | 'dateRange' | 'filters' | 'version'
    > =
        queryState.context.isEditSearch && savedSearchId !== null
            ? queryState.savedSearchQueries[savedSearchId]?.filterState || filters
            : (filters as any as AllFilteringState); //This is required until filters slice gets propering TS typing
    //create the new query obj
    const queryObj = createQueryObjFromState({
        queryState: parseGuidedFormToSave({
            ...queryState,
            booleanQuery,
        }),
        cardPersistState,
        filterState,
        exportState,
        savedSearchId: savedSearchId ?? undefined,
    });

    // QUICK SEARCH
    // try to find a recent query with same booleanQuery and use it's id to avoid dupes
    if (!savedSearchId && !queryObj.id) {
        const rquery = find(
            queryState.recentQueries,
            (rq) => rq.booleanQuery === queryObj.booleanQuery,
        );
        if (rquery) {
            queryObj.id = rquery.id;
        }
    }

    dispatch(updateSavedAndRecentQueries(queryObj));

    try {
        return await QueryService.saveQuery(queryObj);
    } catch (e) {
        console.error('Error persisting query settings', e);
        rejectWithValue(e);
    }
});

//TODO This should not be an action at all,
//as it doesn't do anything to the state.
export const goToRecentQuery = createAsyncThunk<
    void,
    QueryType,
    {
        state: RootReducer;
    }
>('query/goToRecentQuery', async (recentQueryData, {dispatch, getState}) => {
    const {savedSearchId, projectId, isEditSearch} = (getState() as any).query.context;
    const {booleanQuery, id} = recentQueryData;
    let pathname = isEditSearch ? `/search/edit/${projectId}/${savedSearchId}` : '/search/results';
    dispatch(
        push({
            pathname,
            search: serializeSearch({
                query: booleanQuery,
                ...(id === undefined ? {} : {rqid: id.toString()}),
            }),
        }),
    );
});

//This is used for quicksearch and edit search
//TODO Not sure this should be an action at all,
//as it doesn't do anything to the state
//TODO None of this logic belongs in a thunk, putting it here is
//confusing and hard to understand
export const submitQuery = createAsyncThunk<
    void,
    string,
    {
        state: RootReducer;
    }
>('query/submitQuery', async (booleanQuery, {dispatch, getState}) => {
    const {context, recentQueries, persistId, isGuided} = getState().query;
    const {isEditSearch, urlSearchParams, savedSearchId, projectId} = context;

    if (booleanQuery) {
        const isReload = urlSearchParams?.query === booleanQuery;
        const urlParams: {[key: string]: any} = {
            query: booleanQuery,
            guided: isGuided,
        };

        if (isReload) {
            //TODO - I think this is just to force a reload, because the params have changed?
            //If so, surely we can do better?
            //Wait no, isReload is true if the state and the url match?
            //so, why are we adding a url parameter?
            urlParams.reload = (parseInt(urlSearchParams?.reload || '0') + 1).toString();
        }

        if (persistId && !isEditSearch) {
            urlParams.rqid = persistId;
        }

        let pathname = isEditSearch
            ? `/search/edit/${projectId}/${savedSearchId}`
            : '/search/results';

        if (!isReload && !isEditSearch && !persistId) {
            // look for an identical previous query before creating a new one
            const prevRecentQuery = find(
                recentQueries,
                (rq) => rq.booleanQuery === booleanQuery.trim(),
            );

            if (prevRecentQuery) {
                dispatch(goToRecentQuery(prevRecentQuery));
                return;
            }
        }

        //TODO update recentQueries/savedSaerch/booleanquery here?
        //OR do that and move persist to loadQueryStateFromURL?

        !isReload && !isEditSearch && dispatch(persistActiveQuery());

        //
        dispatch(
            push({
                pathname,
                search: serializeSearch(urlParams),
            }),
        );
    } else if (!isEditSearch) {
        // GO HOME on empty query, except when on edit search
        dispatch(replace('/'));
    }
});

/*
  This action does the initial setup for all query environments
  (QuickSearch, QuicksearchResults, SavedSearchResults, EditSavedSearch),
  and should be called ONLY ONCE when entering the respective route.

  See hocs/withLoadQueryState where it's used as a HOC on those components,
  and is only called once per instantiation.

  After this initial setup, all changes to the queryState, results, and filters
  are then managed by their respective actions
*/

//Not happy with this, far, far too much logic in the action
//
export const loadQueryStateFromURL = createAsyncThunk<
    void,
    {
        location: {pathname?: string};
        urlParams: {[key: string]: string};
        urlSearchParams: {[key: string]: string};
        pageTypes: PageType[];
        flags: CCFlagsType;
    },
    {
        state: RootReducer;
    }
>(
    'query/loadQueryStateFromURL',
    async ({location, urlParams, urlSearchParams, pageTypes, flags}, {dispatch, getState}) => {
        // stuff we need from the store
        const {
            query,
            search,
            user: {defaultProject},
        } = getState();
        const {savedSearchQueries, recentQueries} = query;

        //TODO proper types
        const savedSearches: any[] = (search as any).searches;

        // 1. find out if we're on quickSearch or savedSearch,
        // find corresponding savedSearch and query object data on redux
        const queryType = getQueryTypeFromUrl(location, urlParams);
        const isEditSearch: boolean = queryType === 'edit';

        let queryObj: QueryType;
        let savedSearch: any; //TODO type this?

        // SAVED SEARCH: id and projectId found in router url parameters
        if (queryType === 'saved' || queryType === 'edit') {
            // find savedSearch, check if isEditSearch
            const savedSearchId = parseInt(urlParams.id);
            savedSearch = find(savedSearches, (s) => s.id === savedSearchId);

            // find corresponding query object (or create one
            // for searches prior to insights query introduction)
            queryObj =
                savedSearchQueries[savedSearchId] ||
                createDefaultQueryObj({booleanQuery: savedSearch.booleanQuery, savedSearchId});

            // EDIT SAVED SEARCH: use booleanQuery from url
            if (isEditSearch) {
                const booleanQuery = urlSearchParams.query || savedSearch.booleanQuery || '';
                queryObj = {...queryObj, booleanQuery};
            }
        } else if (queryType === 'quick') {
            // QUICK SEARCH
            const booleanQuery = urlSearchParams.query || '';
            const recentQueryId = urlSearchParams.rqid || null;
            // find query obj or setup minimal one (just booleanQuery)
            queryObj =
                find(
                    recentQueries,
                    (rq) =>
                        rq.id === parseInt(recentQueryId || '') || rq.booleanQuery === booleanQuery,
                ) || createDefaultQueryObj({booleanQuery, savedSearchId: undefined});
        } else {
            throw new Error('loadQueryStateFromUrl :: current URL is not any kind of query');
        }

        // 2. update the state
        dispatch(
            setQueryStateFromURL({
                query: queryObj,
                isEditSearch,
                project: savedSearch?.project || defaultProject,
                savedSearch,
                urlSearchParams,
            }),
        );

        // 3. set filters using persisted state in queryObj
        await dispatch(
            setupQueryFilters({
                filterState: queryObj.filterState as AllFilteringState,
                pageTypes,
                flags,
            }) as any,
        );
        await dispatch(
            loadPersistCardPersistState({
                filterCard: queryObj.filterState?.cardsInnerState || undefined,
            }),
        );

        //4. set pdf export config using persisted state in queryObj
        dispatch(saveExportConfig(queryObj.exportState));

        // 4. clear any previously loaded results
        //TODO this should be handled as part of the results reducer, listening for the loadQueryStateFromURL.fulfilled action
        dispatch(clearResults());
    },
);

//Not touching this more than I have to as I'm still mid refactor in another branch
//Also, should this even be a thunk? Probably not.
export const setupQueryFilters = createAsyncThunk<
    void,
    {
        filterState: LegacyFilterStateFormat | AllFilteringState;
        pageTypes: PageType[];
        flags: CCFlagsType;
    },
    {state: RootReducer}
>('query/setupQueryFilters', async ({filterState, pageTypes, flags}, {dispatch, getState}) => {
    //Example legacy filters - kept for testing purposes
    //filterState = {"filtersList":{"gender":[],"keyword":[],"sentiment":[],"classifications":[],"pageType":["twitter"],"author":[],"xauthor":[],"language":[],"domain":[],"xdomain":[],"xlanguage":[],"location":[],"xlocation":[],"tag":[],"xtag":[],"category":[],"xcategory":[],"parentCategory":[],"xparentCategory":[],"twitterVerified":[],"twitterRole":[],"assigned":"","status":"","priority":"","checked":""}};
    // filterState = {
    //     ...filterState,
    //       filters: {}
    // };

    // delete (filterState as any).filtersList;
    // delete (filterState as any).version;

    const state = getState();
    const config = getConfig();
    const {timezone, isEditSearch, searchType, isSavedSearch, projectId} = state.query
        .context as QueryContextType;

    const {
        config: filtersConfig,
        version,
        allFiltersConfig,
    } = getFiltersConfig(isSavedSearch ? 'saved' : 'quick', pageTypes, projectId, flags);

    // init filters date range
    dispatch((initDateRange as any)(filterState?.dateRange?.timezone || timezone));

    if (
        !isEditSearch &&
        (!isEmpty((filterState as LegacyFilterStateFormat)?.filtersList) ||
            !isEmpty((filterState as AllFilteringState)?.filters))
    ) {
        let normalisedFilters;

        try {
            normalisedFilters = normalisePersistedFilters(
                filterState,
                allFiltersConfig,
                searchType,
                config.filters.upgradePersistedFiltersStateMethods,
            );
        } catch (e: any) {
            //If normalising filters fails
            normalisedFilters = {}; //load blank filter settings

            mixpanel.track('Error normalising persisted filters', {error: e.toString()});

            dispatch({
                //show toast
                type: SHOW_SNACKBAR_NOTIFICATION,
                payload: {message: T('filters.errors.loadPersistedFailed'), variant: 'warning'},
            });
        }

        dispatch(
            (loadPersistedFilters as any)({
                config: filtersConfig,
                version,
                collapsedSections: filterState.collapsedSections || undefined,
                dateRange: filterState.dateRange || undefined,
                filters: normalisedFilters,
                flags,
            }),
        );
    } else {
        dispatch((reset as any)({preserveDateRange: true}));
        dispatch((setConfig as any)({config: filtersConfig, version}));
    }
});
