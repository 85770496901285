//TODO This needs some serious work to be accessible

import React, {useEffect, useRef, useState, useMemo, useCallback} from 'react';
import capitalize from 'lodash/capitalize';
import _get from 'lodash/get';
import sortby from 'lodash/sortBy';
import {useDispatch, useSelector} from 'react-redux';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import Button from 'hsi/components/Button';
import DeleteMessage from './DeleteMessage';
import {Dialog, DialogContent, DialogActions} from 'hsi/components/Dialog';
import IconRouter from 'hsi/components/IconRouter';
import Popover from 'hsi/components/Popover';
import PulseLoader from 'hsi/components/PulseLoader';
import SearchBox from 'hsi/components/SearchBox';
import Tooltip from 'hsi/components/Tooltip';
import TaggedMentions from './TaggedMentions';
import TextField from 'hsi/components/TextField';
import SuccessMessage from 'hsi/components/Notifications/TagSuccessMessage';
import ErrorMessage from 'hsi/components/Notifications/TagErrorMessage';

//Hooks
import useEventTrack from 'hsi/hooks/useEventTrack';

//Actions
import {
    deleteTag,
    editTag,
    loadTags,
    saveTag,
    loadTaggedMentions,
    resetTaggedMentions,
} from 'hsi/actions/tagActions';

//Utils
import {getSuggestions} from 'hsi/utils/misc';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Consts
const MAX_LENGTH = 200;

//The component
export default function ManageTagsDialog({closeFunc: _closeFunc, open}) {
    const classes = useStyles();
    const {trackWithSearchData} = useEventTrack();

    const dispatch = useDispatch();
    const tags = useSelector((state) => state.tags);

    const [newTag, setNewTag] = useState('');
    const [selectedTag, setSelectedTag] = useState(null);
    const [hoveredTag, setHoveredTag] = useState(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [editedTag, setEditedTag] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [filteredTags, setFilteredTags] = useState(tags.results);

    const newTagInput = useRef(null);

    //Side effects
    useEffect(() => {
        //Keep filtered tags updated
        setFilteredTags(
            searchValue ? getSuggestions(searchValue, tags.results, 'name') : tags.results,
        );
    }, [tags.results, searchValue]);

    useEffect(
        //On init load tags
        () => {
            dispatch(loadTags());
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const closeFunc = () => {
        _closeFunc();
        setSelectedTag(null);
        setEditedTag(null);
        setHoveredTag(null);
        setNewTag('');
        setSearchValue('');
        setFilteredTags(tags.results);
        dispatch(resetTaggedMentions());
    };
    //Callbacks
    const onNewTagNameChange = useCallback((ev) => setNewTag(ev.target.value), []);

    const onSearchBoxChange = useCallback((e) => setSearchValue(e.target.value), []);

    const onDeleteDialogOpenCloseToggle = useCallback(
        (e) => {
            e.stopPropagation();
            if (isDeleteDialogOpen) {
                setHoveredTag(null);
            }
            setIsDeleteDialogOpen(!isDeleteDialogOpen);
        },
        [isDeleteDialogOpen],
    );

    const onConfirmDelete = useCallback(
        (e, tag) => {
            setIsDeleteDialogOpen(false);
            setHoveredTag(null);
            dispatch(deleteTag(tag.id));
        },
        [dispatch, setIsDeleteDialogOpen],
    );

    const onClickEditTag = useCallback((e, id) => {
        e.stopPropagation();
        setEditedTag(id);
    }, []);

    const createTag = useCallback(() => {
        const successMessage = <SuccessMessage value={newTagInput.current.value} />;
        const errorMessage = <ErrorMessage />;
        trackWithSearchData('tagCreated', {
            tag: newTagInput.current.value,
        });
        dispatch(saveTag(newTagInput.current.value, successMessage, errorMessage));
        setNewTag('');
    }, [dispatch, trackWithSearchData]);

    const onEnterCreateTag = useCallback((e) => e.key === 'Enter' && createTag(), [createTag]);

    const onEditTagChange = useCallback(
        (e, {name, id}) => {
            if (e.key === 'Enter' || e.type === 'blur') {
                const value = e.target.value;

                if (value && value !== name) {
                    const errorMessage = (
                        <>
                            <span>{T('managetagsdialog.errors.tagExists.main')}</span>
                            <br />
                            {T('managetagsdialog.errors.tagExists.secondary')}
                        </>
                    );

                    const successMessage = (
                        <>
                            {T('managetagsdialog.notifications.success.first')}
                            <span className="itemName">{`"${name}"`}</span>
                            {T('managetagsdialog.notifications.success.edited')}{' '}
                            <span className="itemName">{`"${value}"`}</span>
                        </>
                    );

                    dispatch(editTag({name: value, id}, successMessage, errorMessage));
                }

                setEditedTag('');
            }
        },
        [dispatch],
    );

    const onClickSelectTag = useCallback(
        (tag) => {
            if (!isDeleteDialogOpen) {
                setSelectedTag(tag);
                trackWithSearchData('taggedMentionsViewed', {
                    tag: tag?.name,
                });
                dispatch(loadTaggedMentions(null, tag));
            }
        },
        [isDeleteDialogOpen, dispatch, trackWithSearchData],
    );

    const loadMentionsFunc = useCallback(() => {
        if (!tags.mentions.loading) {
            dispatch(loadTaggedMentions(true, selectedTag));
        }
    }, [dispatch, selectedTag, tags.mentions.loading]);

    return (
        <Dialog
            title={T('managetagsdialog.manageTags')}
            onClose={closeFunc}
            open={open}
            className={classes.ManageTagsDialog}
        >
            <div className="wrapper">
                <DialogContent className={classes.DialogContent}>
                    <div className="tags-mgmt">
                        <div className="container">
                            <div className="inputAndLabelContainer">
                                <label htmlFor="manage-tags-new-tag-input" className="label padded">
                                    {T('managetagsdialog.hsiNewTitle')}
                                </label>
                                <TextField
                                    id="manage-tags-new-tag-input"
                                    className="new-tag-input"
                                    placeholder={T('managetagsdialog.hsiAddNewPlaceHolder')}
                                    onChange={onNewTagNameChange}
                                    value={newTag}
                                    onKeyDown={onEnterCreateTag}
                                    inputProps={{maxLength: MAX_LENGTH, ref: newTagInput}}
                                    required
                                    error={
                                        tags.error
                                            ? {
                                                  title: T(
                                                      'managetagsdialog.errors.tagExists.main',
                                                  ),
                                                  desc: T(
                                                    'managetagsdialog.errors.tagExists.secondary',
                                                  ),
                                              }
                                            : null
                                    }
                                />
                            </div>
                            <Button onClick={createTag} priority="primary">
                                {capitalize(T('managetagsdialog.add'))}
                            </Button>
                        </div>

                        <Divider className={classes.Divider} />

                        <div className="label">{T('managetagsdialog.hsiSearchTitle')}</div>
                        <div className="label sub">{T('managetagsdialog.hsiSearchSubTitle')}</div>
                        <SearchBox
                            placeholder={T('managetagsdialog.hsiSearchPlaceholder')}
                            onChange={onSearchBoxChange}
                            onSearch={() => null}
                            value={searchValue}
                            className={classes.searchBox}
                            autoFocus={false}
                        />

                        <TagList
                            tags={tags}
                            filteredTags={filteredTags}
                            editedTag={editedTag}
                            setEditedTag={setEditedTag}
                            hoveredTag={hoveredTag}
                            setHoveredTag={setHoveredTag}
                            isDeleteDialogOpen={isDeleteDialogOpen}
                            setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                            onConfirmDelete={onConfirmDelete}
                            onClickEditTag={onClickEditTag}
                            onEditTagChange={onEditTagChange}
                            onClickSelectTag={onClickSelectTag}
                            onDeleteDialogOpenCloseToggle={onDeleteDialogOpenCloseToggle}
                        />
                    </div>

                    <TaggedMentions
                        selectedTag={selectedTag}
                        mentions={tags.mentions}
                        loadMentionsFunc={loadMentionsFunc}
                        classes={classes}
                    />
                </DialogContent>
                <DialogActions className={classes.DialogActions}>
                    <Button onClick={closeFunc} priority="cta">
                        {capitalize(T('managetagsdialog.close'))}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

function TagList({
    tags,
    filteredTags,
    editedTag,
    setEditedTag,
    hoveredTag,
    setHoveredTag,
    isDeleteDialogOpen,
    setIsDeleteDialogOpen,
    onConfirmDelete,
    onClickEditTag,
    onEditTagChange,
    onClickSelectTag,
    onDeleteDialogOpenCloseToggle,
}) {
    const classes = useStyles();

    const editTagInput = useRef(null);

    const sortedList = useMemo(() => sortby(filteredTags, ['name']), [filteredTags]);

    return (
        <List>
            {tags.loading && (
                <div className="loader-container">
                    <PulseLoader size="large" />
                </div>
            )}
            {!tags.loading &&
                sortedList.map((tag) => (
                    <ListItem
                        key={tag.id}
                        button
                        onMouseEnter={() => {
                            if (!isDeleteDialogOpen) setHoveredTag(tag);
                        }}
                        onMouseLeave={() => {
                            if (!isDeleteDialogOpen) setHoveredTag(null);
                        }}
                        onClick={() => onClickSelectTag(tag)}
                    >
                        <ListItemIcon>
                            <div className="tag-icon-container">
                                <IconRouter name="tag" />
                            </div>
                        </ListItemIcon>
                        {editedTag === tag.id && (
                            <ClickAwayListener
                                onClickAway={() => {
                                    setIsDeleteDialogOpen(false);
                                    setEditedTag(null);
                                }}
                            >
                                <div className="new-tag-input">
                                    <TextField
                                        className="new-tag-input"
                                        inputRef={editTagInput}
                                        onKeyDown={(e) => onEditTagChange(e, tag)}
                                        onClick={(e) => e.stopPropagation()}
                                        defaultValue={tag.name}
                                        inputProps={{maxLength: MAX_LENGTH}}
                                    />
                                </div>
                            </ClickAwayListener>
                        )}
                        {editedTag !== tag.id && (
                            <>
                                <div className={classes.label}>{tag.name}</div>
                                {_get(hoveredTag, 'id') === tag.id && (
                                    <>
                                        <Tooltip
                                            tooltip={T('managetagsdialog.edit')}
                                            distance={0}
                                            placement="bottom"
                                            noAria
                                        >
                                            <button
                                                className="icon-wrapper"
                                                onClick={(e) => onClickEditTag(e, tag.id)}
                                                aria-label={T('managetagsdialog.edit')}
                                            >
                                                <IconRouter name="pencil" />
                                            </button>
                                        </Tooltip>
                                        <Tooltip
                                            tooltip={T('delete')}
                                            distance={0}
                                            placement="bottom"
                                            noAria
                                        >
                                            <Popover
                                                distance={10}
                                                portal
                                                content={
                                                    <DeleteMessage
                                                        toggleMsg={onDeleteDialogOpenCloseToggle}
                                                        tag={tag}
                                                        confirmDelete={onConfirmDelete}
                                                    />
                                                }
                                            >
                                                <button
                                                    className="icon-wrapper"
                                                    aria-label={T('delete')}
                                                >
                                                    <IconRouter
                                                        className="more-horiz"
                                                        name="delete"
                                                    />
                                                </button>
                                            </Popover>
                                        </Tooltip>
                                    </>
                                )}
                            </>
                        )}
                    </ListItem>
                ))}
        </List>
    );
}
