import makeStyles from '@mui/styles/makeStyles';

import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles((theme: Theme) => {
    const v2 = isV2(theme);

    return {
        header: {
            display: 'flex',
            flexFlow: 'row nowrap',
            marginBottom: '12px',
            gridArea: 'header',
            gap: '12px',
        },

        avatar: {
            position: 'relative',
            display: 'block',
            flex: '0 0 48px',
        },
        nameLocationHandleAndDate: {
            flex: '1 1 auto',
            minWidth: 0,
            display: 'flex',
            flexFlow: 'row wrap',
        },
        nameAndDate: {
            flex: '0 0 100%',
            maxWidth: '100%',
            display: 'flex',
            flexFlow: 'row nowrap',
        },
        name: {
            flex: '1 1 0',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            ...(v2
                ? {
                      ...theme.fonts.bodyLargeStrong,
                  }
                : {
                      fontWeight: theme.font.bold,
                      fontSize: '16px',
                  }),
        },
        date: {
            flex: '0 0 auto',
            textAlign: 'right',
            textDecoration: 'none',
            color: 'inherit',
            ...(v2
                ? {
                      ...theme.fonts.bodySmall,
                  }
                : {
                      fontSize: '14px',
                  }),
        },
        handleAndLocation: {
            flex: '0 0 auto',
            minWidth: 0,
            maxWidth: '100%',
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
        },
        handle: {
            flex: '0 1 auto',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            ...(v2
                ? {
                      ...theme.fonts.bodyNormal,
                  }
                : {
                      fontSize: '16px',
                  }),
        },
        link: {
            textDecoration: 'none',
            fontWeight: theme.font.bold,
            ...theme.mixin.linkColor,
        },
        location: {
            ...theme.mixin.blankButton,
            flex: '1 1 0',
            minWidth: '60px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: 'left',
            ...(v2
                ? {
                      ...theme.fonts.bodyNormal,
                  }
                : {
                      fontSize: '16px',
                  }),
        },

        select: {
            flex: '0 0 auto',
            padding: v2 ? undefined : '2px 0',
            pointerEvents: 'none',
        },

        hidden: {
            visibility: 'hidden',
        },
    };
});
