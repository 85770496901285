import React, {useMemo, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

//Components
import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle from 'hsi/components/Card/CardTitle';
import BenchmarkBarChart from 'hsi/components/BenchmarkBarChart';
import InfoPopupContent from 'hsi/components/InfoPopupContent';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useElementSize from 'hsi/hooks/useElementSize';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useGetLoadData from '../useGetLoadData';
import useMultipleSearchIds from 'hsi/hooks/useMultipleSearchIds';
import useBenchmarkSubtitle from 'hsi/hooks/useBenchmarkSubtitle';

//Actions
import {mentionsDrillIn} from 'hsi/slices/mentions';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Constants
const type = 'benchmarkBySearch';

//The components
const BenchmarkBySearch = React.forwardRef(({title, ...props}, ref) => {
    const classes = useStyles();
    const {
        links: {dashboardInfoBenchmarkCTA: popupCTA},
    } = useConfig();
    const [sizeRef, {height, width} = {}] = useElementSize(null, {width: true, height: true});
    const {trackWithSearchData} = useEventTrack();
    const {searchIds} = useMultipleSearchIds();
    const dispatch = useDispatch();

    //Redux
    const {data, loading, loaded, error} = useSelector((state) => state.chart[type]);
    const {startDate, endDate} = useSelector((state) => state.filters.dateRange);

    const loadData = useGetLoadData(type);

    const {hasPreviousValue, benchmarkSubHeader} = useBenchmarkSubtitle(startDate, endDate);

    const popup = useMemo(
        () => (
            <InfoPopupContent
                copy={T(`cards.${type}.info.copy`)}
                ctaLabel={T('cards.infoCTALabel')}
                ctaUrl={popupCTA}
                title={T(`cards.${type}.info.title`)}
            />
        ),
        [popupCTA],
    );

    const onBarClick = useCallback(() =>
        ({payload: {name, searchId}}, i, ev) => {
            ev.stopPropagation();

            const drillinFilter = {queryId: searchId?.toString()};
            trackWithSearchData('cardDrilledIn', {
                type,
                value: name,
            });
            dispatch(mentionsDrillIn(drillinFilter, 'benchmarkBySearch', null, name));
        },
        [dispatch, trackWithSearchData],
    );

    const aggregatedData = useMemo(() => {
        if (isEmpty(data)) {
            return null;
        }

        const {currentValues, previousValues} = data;

        return searchIds.map((searchId, i) => ({
            id: `${type}-${i}`,
            name: find(currentValues, ({id}) => id === searchId)?.name,
            currentValue: find(currentValues, ({id}) => id === searchId)?.value,
            previousValue: find(previousValues, ({id}) => id === searchId)?.value,
            color: find(currentValues, ({id}) => id === searchId)?.color,
            searchId,
        }));
    }, [data, searchIds]);

    const content = useMemo(
        () => (
            <div className={classes.chartContainer} data-testid="benchmark-by-search-chart">
                <div className={cn('chart', 'catSize' + aggregatedData?.length ?? 0)} ref={sizeRef}>
                    {hasPreviousValue && (
                        <BenchmarkBarChart
                            barSize={24}
                            breakdown="queries"
                            data={aggregatedData}
                            height={height}
                            onClick={onBarClick}
                            width={width}
                        />
                    )}
                </div>
            </div>
        ),
        [
            aggregatedData,
            classes.chartContainer,
            height,
            onBarClick,
            sizeRef,
            width,
            hasPreviousValue,
        ],
    );

    return (
        <CardLoadState
            {...props}
            title={
                <CardTitle
                    searchType="multiple"
                    title={title}
                    tooltipComponent={popup}
                    type={type}
                    hasData={!!aggregatedData}
                />
            }
            subTitle={hasPreviousValue ? benchmarkSubHeader : null}
            error={error}
            hasData={!!aggregatedData}
            loading={loading}
            loaded={loaded}
            loadData={loadData}
            ref={ref}
            data-testid={type}
            type={type}
        >
            {content}
        </CardLoadState>
    );
});

BenchmarkBySearch.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            title: PropTypes.string.isRequired, //a lang ID
            breakdownType: PropTypes.string.isRequired, //a lang ID
            subTitle: PropTypes.string,
        }),
    ]).isRequired,
    fadeOnVisible: PropTypes.object,
    height: PropTypes.number,
    isVisible: PropTypes.bool,
    onContentChange: PropTypes.func,
};

BenchmarkBySearch.displayName = 'BenchmarkBySearch';

export {BenchmarkBySearch};
