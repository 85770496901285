import {CONFIG_BY_APP_SOURCE} from 'hsi/constants/stringsByAppSource';

const appName = CONFIG_BY_APP_SOURCE[window.APP_SOURCE]?.appName ?? 'Insights';

const phrases = {
    headerLbl: `${appName} header`,
    test: 'This is a test value',
    active: 'Active',
    addNew: 'Add new',
    dayNames: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
    },
    alert: {
        created: 'Success! The Alert "%{name}" has been created.',
        deleted: 'Successfully deleted alert "%{name}"',
        edited: 'Successfully edited alert "%{name}"',
        recipientErrorTitle: 'Check the highlighted additional recipients for more information',
        recipientErrorDesc: 'One or more of the additional recipients is invalid',
        name: 'Alert name',
        instructions: 'Will appear in the email subject.',
        paused: 'The alert "%{name}" is now paused.',
        resumed: 'The alert "%{name}" is now active.',
        emailCreator: {
            lbl: 'Send to you (%{email})?',
        },
        recipients: {
            heading: 'Recipients',
            instructions: 'Add multiple recipients by separating with a comma or a space.',
            additional: 'Additional recipients',
            errors: {
                noRecipients: {
                    lbl: 'Please enter at least one recipient email',
                    desc: 'Oops! You forgot to add a recipient',
                },
                invalidRecipient: {
                    lbl: 'This is not a valid email address.',
                    desc: 'Supplied address is not a valid email address',
                },
                self: {
                    lbl: 'Cannot use your email address as an additional recipient',
                    desc: 'Cannot enter your email address as an additional recipient, select "Send to you" instead.',
                },
            },
        },
        type: {
            action: 'Please select at least one alert type',
            error: 'Oops! You forgot to select an alert type',
        },
        warning: 'This action cannot be undone.',

        alertMe: 'Alert me',

        alertVolumeIncrease: 'Alert me about increases in volume...',
        increaseThreshold: 'When there is an increase in mentions of',
        alertNewMentions: 'Alert me when new mentions are found...',
        newMentionFrequency: {
            5: 'As it appears',
            15: 'Every 15 minutes',
            30: 'Every 30 minutes',
            60: 'Hourly',
            720: 'Twice daily',
            1440: 'Daily',
            10080: 'Weekly',
        },
        numMentions: {
            question: 'How many mentions should the alert show?',
            upTo: 'Up to',
            mentions: 'mentions',
        },
        editFilters: 'edit filters',
        filtersInfo: 'Any filter applied will be taken into account when sending alerts.',
        infoPopup: {
            title: 'Email alerts',
            copy: `Alerts are **email notifications** about your search results triggered by specific changes in your data. You set the triggers and thresholds, which can be as broad or narrow as you wish. For example, you can set alerts for every time there's a new mention in your search results, or a **sudden increase** in volume.

Insights updates alerts in near real-time **(every 5 minutes)** by scanning the data from your search results **over the previous 7 days**.`,
            cta: 'Learn more',
        },
        increaseVolumePopup: {
            title: 'Alert on increases',
            copy: `Get an email alert when the daily average number of mentions increases by a percentage you set.

We determine the daily average using the number of mentions **over the last 7 days**.`,
        },
        newAlertsPopup: {
            title: 'Alert on new Mentions',
            copy: `Get an email alert when we find new mentions for your search.

Mentions are included in alerts once we receive the data from our sources. For some sources this can mean a few hours delay between the mention being posted online and it appearing in ${appName}. If we don't find any mentions, we won't send an alert.`,
        },
        numMentionsPopup: {
            title: 'Number of mentions',
            copy: 'By default, we show up to 10 mentions in alert emails, but you can specify a different number here.',
        },
        errors: {
            load: {
                noNetwork:
                    'Sorry, we were unable to load the alert(s) as we could not connect to the server. Please check your internet connection and try again',
                unknown:
                    'Sorry, we were unable to load the alert(s), please try again. If the problem persists, please contact support.',
            },
            delete: {
                noNetwork:
                    'Sorry, we were unable to delete the alert(s) as we could not connect to the server. Please check your internet connection and try again',
                unknown:
                    'Sorry, we were unable to delete the alert(s), please try again. If the problem persists, please contact support.',
            },
        },
        alertMeHelpLbl: 'More info on email alerts',
        alertVolumeIncreaseHelpLbl: 'More info on alert on increase in volume',
        alertNewMentionsHelpLbl: 'More info on alert when new mentions are found',
        alertNumMentionsHelpLbl: 'More info on mentions per alert',
    },
    alerts: 'Alerts',
    helpIcon: {
        default: 'Click for more info',
    },
    api: {
        action: 'Please enter a different one.',
        error: 'Oops! There was a problem with your request (%{code} "%{text}")',
        error2: "Oops! There's a problem with your query and you'll need to fix it to be able to save it.",
        error3: 'Oops! An alert with this name already exists.',
        error4: {
            title: 'Sorry, this name already exists.',
            desc: 'You already have a saved search with this name. Please enter a different name.',
        },
        error5: {
            desc: '[link] to use the proximity operator.',
            link: 'Save your search ',
        },
        unknownError:
            'An unknown error has occurred. Please check your internet connection and try again.',
    },
    any: 'Any',
    author: {
        filter: 'Author filter',
        name: 'Author name',
    },
    apply: 'Apply',
    select: 'Select',
    addTargetedDataDialog: {
        authTitle: 'Authenticate with Facebook',
        authMsg:
            'Authenticate with a Facebook page tied to an instagram business account, or invite someone else to. For every authenticated page, you can track up to 30 hashtags',
        targetedDataTitle: 'Add New Targeted Data',
        hsiTargetedDataTitle: 'Add Targeted Data',
        subhead: 'Select a data type:',
        noTokensTitle: 'Please add a Facebook token first.',
        noTokensDesc: 'You can add a token from the [link] tab.',
        noTokensDescLink: 'Authentication Tokens',
        sourceTypes: {
            facebookOwned: {
                name: 'Owned Facebook Page',
                description: `This is a page that my organization or client owns. For an Owned Channel to have the best possible data coverage, the Page Admin needs to authenticate with ${appName}.`,
            },
            facebookNonOwned: {
                name: 'Facebook Page (Non-Owned)',
                description: `This is a community or competitor owned page. In order to have the best possible data coverage you need as many Facebook users to authenticate with ${appName} as possible.`,
            },
            instagramHashtag: {
                name: 'Instagram Hashtag',
                description:
                    'Authenticate with Facebook using an account with a role on a Facebook page tied to an Instagram business account. For every account you can track up to 30 hashtags.',
            },
            instagramOwned: {
                name: 'Owned Instagram Business Account',
                description:
                    'Authenticate with an Owned Instagram Business Account to monitor all posts and comments on an owned Instagram account.',
            },
            linkedinChannel: {
                name: 'Owned LinkedIn Page',
                description:
                    'Track LinkedIn pages you or your organization owns. LinkedIn data can be added to an existing Saved Search (not available in Quick Search).',
            },
        },
        limitReachedFbPages: {
            title: 'You have reached your Facebook Pages limit of %{limit}.',
            desc: 'Remove non-used pages (owned or non-owned) to make room for new ones.',
        },
        IGHashtags: {
            IGHashtagsModalTitle: 'Add New Instagram Hashtag',
            HSIIGHashtagsModalTitle: 'Add Instagram Hashtag',
            infoBanner:
                'Added hashtags will count towards your hashtag quota immediately. Hashtags can only be removed at 7 day increments from when you start tracking.',
            authPage: 'Authenticated Page',
            addHashtags: 'Add Hashtags',
            pageSelectLabel: 'Authenticated Page',
            tagSelectLabel: 'List your hashtags here',
            tagsHelpText: 'Add multiple hashtags by separating with a comma or a space.',
            acknowledge:
                "I acknowledge that added hashtags will count towards my hashtag quota and can't be removed for at least 7 days.",
            addNewTokenLink: 'Add New Authentication Token',
            chipErrorTooltip: 'This hashtag is already tracked by the selected page.',
            chipErrorTitle: 'Oops, a hashtag name should be unique within a page.',
            chipErrorDesc: 'Hover over a red marked hashtag for details.',
            chipType: 'hashtags',
            successMesg: '%{num} Instagram hashtags have been added.',
            mixedMesgSuccess: '%{num} hashtags successfully added',
            mixedMesgFail: '%{num} hashtags were unsuccessful',
            hashtagsRemaining: '%{num} hashtags left',
        },
        IGAccounts: {
            accountsTitle: 'Add New Owned Instagram Account',
            hsiAccountsTitle: 'Add Owned Instagram Account',
            selectLabel: 'Please select one or more pages',
            searchPlaceholder: 'Search Pages',
            addPages: 'Add Pages',
            noPagesWithFilter: 'No page names found with keyword: "%{filter}"',
            successMesg: '%{num} Instagram Business Accounts have been added.',
            mixedMesgSuccess: '%{num} Accounts successfully added',
            mixedMesgFail: '%{num} Accounts were unsuccessful',
        },
        FacebookPage: {
            titleNonOwned: 'Add New Facebook Page (Non-Owned)',
            titleOwned: 'Add New Owned Facebook page',
            hsiTitleNonOwned: 'Add Facebook Page (Non-Owned)',
            hsiTitleOwned: 'Add Owned Facebook page',
            labelNonOwned: 'Enter a Facebook profile name or URL',
            labelOwned: 'Please select one or more pages',
            placeholder: 'e.g. https://www.facebook.com/unicef',
            placeholderOwned: 'Search Pages',
            addPage: 'Add Page',
            successTitle: 'Success!',
            successMessageNonOwned: 'A Facebook Page (Non-Owned) has been added.',
            successMessageOwned: '%{num} Owned Pages have been added.',
            noTokenTitle: 'Please add a Facebook token first',
            noTokenDesc1: 'You can add a new token from the',
            noTokenDesc2: 'authentication',
            noTokenDesc3: 'tokens section.',
            alreadyAdded: 'Facebook page already added.',
            pageNotFound: "We couldn't find that Facebook page. Please check the URL.",
            fbUrlInvalid: 'Not a valid Facebook page URL.',
        },
        LinkedinChannel: {
            modal: {
                title: 'Track owned LinkedIn Page',
                orgInputTitle: 'Connect a LinkedIn account to track linked pages',
                orgInputDescription: 'Enter a profile name or URL',
                orgInputPlaceholder: 'e.g. Unicef',
                channelInputPlaceholder: 'Page name...',
                channelInputTitle: 'Add a page name',
                projectInputPlaceholder: 'Team...',
                projectInputTitle: 'Select a team',
                authRequiredMessage: {
                    title: 'You need to authenticate first.',
                    description:
                        'Please use the button below to authenticate with an admin user of the organization you want to track.',
                },
                introMessage: {
                    description:
                        'You have to be an admin of the organisation you’re trying to track. You’ll need super admin access to track it.',
                },
                adminRequiredMessage: {
                    title: 'You need a super admin account to continue.',
                    description:
                        'If this is not your organisation, please go try adding the correct organisation using the LinkedIn URL. Otherwise authenticate with a super admin account for the organisation.',
                },
                orgNotFoundMessage:
                    'We couldn’t find the page. Please check the organization name or URL.',
                orgAlreadyAddedMessage: 'The page is already added.',
                orgDetailsBox: {
                    orgNameTitle: 'Organization name',
                    orgUrlTitle: 'URL',
                },
                createChannelButton: 'Confirm and start tracking',
                connectWithLinkedin: 'Connect with LinkedIn',
                next: 'Next',
                back: 'Back',
                search: 'Search',
            },
            notifications: {
                channelCreationSuccess: {
                    title: 'Page successfuly added',
                    message:
                        'You’re now tracking the page %{name} in the team %{team}. You will need to activate it in your saved search results page by clicking the LinkedIn icon at the top bar.',
                },
                channelCreationError: {
                    title: 'Page not added',
                    genericMessage:
                        'We’re unable to track your LinkedIn page. Try again. If the problem continues, contact Support',
                    channelExistsMessage:
                        'There is already a data source with the name %{name} in this project.',
                    orgAlreadyAddedMessage:
                        'LinkedIn Organization is already associated with a channel for this project',
                },
            },
        },
    },
    dataManagement: {
        // TODO: Refactor to take names of data type as a prop
        addDataSources: 'Track data sources',
        title: 'Data Management',
        titleDesc:
            'Connect social accounts to track hashtags, trends, topics and social conversations',
        tooltip: {
            lbl: 'About data management',
            title: 'Get actionable insights',
            copy: `
Connect social media accounts to track hashtags, trends, topics and social conversations.
`,
            cta: 'Learn more',
        },
        subtitle: '%{num} Data Sources',
        tableSectionLbl: 'Data source categories',
        tabs: {
            facebookPages: 'Facebook pages',
            instagramAccounts: 'Instagram accounts',
            instagramHashtags: 'Instagram hashtags',
            linkedinPages: 'LinkedIn pages',
            whosConnected: 'Facebook connections',
            linkedinAuthentications: 'LinkedIn connections',
        },
        alternateLabel: {
            whosConnected: 'connections',
        },
        noResults: {
            desc: 'Track new pages to start collecting content.',
            cta: 'Track data source',
            title: "You're not tracking any<br/> %{dataType} yet",
        },
        loadError: {
            title: 'There was an error loading this page',
            desc: 'Try again or contact support',
        },
        deleteDialog: {
            title: {
                'facebook-page': 'Remove this Facebook page?',
                'instagram-account': 'Remove this Instagram account?',
                'instagram-hashtag': 'Remove this Instagram hashtag?',
                'linkedin-channel': 'Remove this LinkedIn page?',
                facebookUserAuthentications: 'Remove this connection?',
            },
            desc: {
                'facebook-page':
                    'Stop tracking the Facebook page "**%{name}**" and remove it from your list. You’ll keep the content you\'ve collected so far.',
                'instagram-account':
                    'Stop tracking the Instagram account "**%{name}**" and remove it from your list. You’ll keep the content you\'ve collected so far.',
                'instagram-hashtag':
                    'Stop tracking the Instagram hashtag "**%{name}**" and remove it from your list. You’ll keep the content you\'ve collected so far.',
                'linkedin-channel':
                    'Stop tracking the LinkedIn page "**%{name}**" and remove it from your list. You’ll keep the content you\'ve collected so far.',
                facebookUserAuthentications:
                    'Remove the connection "**%{name}**" from your list. You will stop tracking any social account connected to "**%{name}**" but will keep the content that you\'ve collected so far.',
            },
        },
        info: {
            facebookPages:
                'Track social conversations on your own Facebook Page and on other Facebook Pages by connecting a Facebook account.',
            instagramAccounts:
                'Track social conversations on your own Business or Creator account by connecting a Facebook account that is admin of a Page linked to your Instagram account. Track social conversations on other Business or Creator accounts by connecting your Facebook account.',
            instagramHashtags:
                'A maximum of 30 hashtags can be tracked at the same time, per Instagram account. Hashtags are automatically monitored for a minimum period of 7 days. During that time, they count towards the 30 hashtags limit per Instagram account.',
            linkedinPages:
                "Connect with LinkedIn to monitor organic posts and anonymized comments on your own organization's page. You must be an administrator of the organization's page you want to track.",
            whosConnected: `
- It is important to re-connect your expired profile as soon as possible to ensure continuous tracking.
- We will send your contacts a reminder before the connection expires as long as they allowed visibility to their email address.
- You may need to ask your contacts to re-connect their account if we don’t have access to their email address.
            `,
        },
        table: {
            active: 'Active',
            caption: 'A table displaying the targeted data sources that you are tracking',
            created: 'Tracking start date',
            displayName: 'Display name',
            email: 'Email',
            filterPlaceholder: 'Search items',
            id: 'Channel ID',
            inactive: 'Inactive',
            label: 'Targeted data source table',
            linkedAccount: 'Linked account',
            nonOwned: 'Non-owned',
            owned: 'Owned',
            ownership: 'Ownership',
            team: 'Team',
            username: 'Username',
        },
        tableTooltip: {
            lbl: 'What is channel ID?',
            id: 'The Channel ID is the ID given by the social media provider. You can use this in a search with the **channelId:** operator.',
        },
        addDataSourcesDialog: {
            selectSource: {
                title: 'Track data source',
                subtitle: 'What do you want to track?',
            },
            addOwnedFacebookPagesAndInstagramAccounts: {
                title: 'Connect with a Facebook account',
                desc: 'Connect a Facebook account to track your owned Facebook pages and your linked Instagram Business accounts.',
                infoBanner:
                    'It can take up to two hours after adding an Facebook page or Instagram account to see content from it in your searches.',
            },
            addNonOwnedFacebookPages: {
                title: 'Track other Facebook pages',
                type: 'pages',
                platform: 'Facebook',
                helperText:
                    'You can enter multiple Facebook page URLs, usernames, or Facebook page IDs separated with a comma or by pressing enter.',
                chipError: {
                    formatError:
                        'Cannot find a page with this formatting. Try the page URL instead.',
                    notFound: 'Page cannot be found',
                    unknown: 'Something went wrong',
                },
                info: {
                    validTitle:
                        'Your file should contain a single column of Facebook page URLs, usernames, or Facebook page IDs with no header',
                    validExample1: 'https://www.facebook.com/brandwatch',
                    validExample2: 'www.facebook.com/brandwatch',
                    validExample3: 'brandwatch',
                    validExample4: '175011370072',
                    validExample5: 'www.facebook.com/brandwatch-page-175011370072',
                    invalidTitle: 'Do not use a second column as this will not be matched',
                    invalidExample1a: 'brandwatch',
                    invalidExample1b: '1234844856',
                    cta: 'Got it!',
                },
                infoBanner:
                    'It can take up to two hours after adding a Facebook page to see content from it in your searches.',
                addButton: 'Track',
            },
            addNonOwnedInstagramAccounts: {
                title: 'Track other Instagram accounts',
                platform: 'Instagram',
                type: 'accounts',
                helperText:
                    'You can enter multiple usernames separated with a comma or by pressing enter.',
                chipError: {
                    notFound: 'Account cannot be found',
                    unknown: 'Something went wrong',
                    alreadyTracked: 'Account is already tracked',
                },
                info: {
                    validTitle:
                        'Your file should contain a single column of Instagram usernames with no header.',
                    validExample1: 'unicef_uk',
                    validExample2: 'unicef',
                    validExample3: '@unicef',
                    invalidTitle: 'Do not use a second column as this will not be matched',
                    invalidExample1a: 'joebloggs',
                    invalidExample1b: '@joebloggs',
                    cta: 'Got it!',
                },
                infoBanner:
                    'It can take up to two hours after adding an Instagram account to see content from it in your searches.',
                addButton: 'Track',
            },
            addInstagramHashtags: {
                title: 'Add Instagram hashtags',
                type: 'hashtags',
                selectAccountLabel: 'Instagram accounts to track hashtags with',
                selectAccountCta: 'Connect a new account',
                selectAccountPlaceholder: 'Select an Instagram account',
                selectHashtagLabel: 'Instagram hashtags to track',
                selectHashtagPlaceholder: 'List your hashtags here',
                selectHashtagErrorTooltip: 'This hashtag is already tracked by the selected page.',
                selectHashtagErrorTitle: 'Oops, a hashtag name should be unique within a page.',
                selectHashtagErrorDesc: 'Hover over a red marked hashtag for details.',
                selectHashtagHelpText:
                    'Add multiple hashtags by separating with a comma or a space.',
                selectHashtagItemName: 'hashtags',
                acknowledge:
                    "I acknowledge that added hashtags will count towards my hashtag quota and can't be removed for at least 7 days.",
                infoBanner:
                    'It can take up to two hours after adding an Instagram hashtag to see content from it in your searches.',
                addButton: 'Add',
            },
            nonOwnedInput: {
                label: '%{platform} %{type} to track',
                desc: 'You can enter multiple usernames separated with a comma or by pressing enter.',
                placeholder: 'List %{platform} %{type} here',
                cta: 'Upload CSV',
                chipError: "We can't track %{num} %{type}. Hover over them to learn more or ",
                chipErrorButton: 'remove errors',
                maxError: 'You have entered the maximum of %{num} %{type}.',
                infoButtonTooltip: 'How should my file be formatted?',
                infoButtonLabel: 'Open file formatting guide',
            },
            uploadStatus: {
                success: {
                    title: 'File successfully uploaded',
                    desc: '%{num} %{type} ready to track.',
                    cta: 'Upload another file',
                },
                pageLimitExceeded: {
                    title: 'File contains too many %{type}',
                    desc: 'You can only upload a maximum of %{max} %{type}.',
                    cta: 'Try again',
                },
                unknown: {
                    title: 'Something went wrong',
                    desc: 'Try uploading a different file.',
                    cta: 'Try again',
                },
                fileFormat: {
                    title: 'File format is invalid',
                    desc: 'You can only upload CSV files.',
                    cta: 'Try again',
                },
                fileSize: {
                    title: 'File too big',
                    desc: 'File should be a maximum of 1MB. Try using a plain csv file.',
                    cta: 'Try again',
                },
            },
        },
        selectableDataSources: {
            ownedFacebookPagesAndInstagramAccounts: {
                title: 'Owned Facebook Pages and Instagram Accounts',
                desc: 'Track Facebook Pages and Instagram Accounts.',
            },
            nonOwnedFacebookPages: {
                title: 'Other Facebook Pages',
                desc: 'Track any public Facebook page.',
            },
            ownedLinkedinPages: {
                title: 'Owned LinkedIn Page',
                desc: 'Track LinkedIn pages you or your organization owns. LinkedIn data can be added to an existing Saved Search.',
            },
            nonOwnedInstagramAccounts: {
                title: 'Other Instagram Pages',
                desc: 'Track any public Instagram business or creator account.',
            },
            instagramHashtags: {
                title: 'Instagram Hashtags',
                desc: 'Track hashtags on Instagram.',
            },
            requiresFacebookAuth:
                'You need to authenticate with Facebook before you can track this data source',
        },
        notifications: {
            addOneSuccess: {
                'facebook-page': 'Successfully added Facebook page: %{name}',
                'instagram-account': 'Successfully added Instagram account: %{name}',
                'instagram-hashtag': 'Successfully added Instagram hashtag: %{name}',
                'linkedin-channel': 'Successfully added LinkedIn page: %{name}',
                facebookUserAuthentications: 'Successfully added connection: %{name}',
            },
            addManySuccess: {
                'facebook-page': 'Successfully added %{amount} Facebook pages',
                'instagram-account': 'Successfully added %{amount} Instagram accounts',
                'instagram-hashtag': 'Successfully added %{amount} Instagram hashtags',
                'linkedin-channel': 'Successfully added %{amount} LinkedIn pages',
                facebookUserAuthentications: 'Successfully added %{amount} connections',
            },
            deleteSuccess: {
                'facebook-page': 'Successfully deleted Facebook page: %{name}',
                'instagram-account': 'Successfully deleted Instagram account: %{name}',
                'instagram-hashtag': 'Successfully deleted Instagram hashtag: %{name}',
                'linkedin-channel': 'Successfully deleted LinkedIn page: %{name}',
                facebookUserAuthentications: 'Successfully deleted connection: %{name}',
            },
        },
    },
    authTokens: {
        nav: {
            authTokensTab: 'Authentication Tokens',
            targetDataTab: 'Targeted Data Sources',
        },
        banner: {
            noTokens: `**You're not collecting data from Facebook or Instagram.** Set this up in **[settings](%{settingsLink})** to bring Facebook and Instagram data into your search.`,
            inactiveTokens: `**Reconnection required.** Some of your accounts are not publishing or collecting data. **[Reconnect your social accounts](%{settingsLink})**.`,
        },
        tokensPage: {
            loginBlock: {
                title: 'Connect your Facebook and Instagram accounts',
                desc1: 'Add your Facebook and Instagram accounts to Hootsuite Insights to track your hashtags and content activity and return the most relevant results for your search.',
                desc2: 'Anyone with an admin or editor Page role can connect a Facebook Page to Insights. To track hashtags, make sure your Instagram business profile is linked to a Facebook Page.',
                learnMoreButton: 'Learn more',
                loginButton: 'Connect with Facebook',
                invite: 'Invite someone to connect with Facebook',
                or: 'or',
                copyLinkLabel: 'Invite someone to authenticate with this link',
                copyLinkFailed: 'Failed to load invite url',
            },
            allowModal: {
                title: 'Track owned Facebook pages and Instagram accounts',
                desc: 'You are providing access to Facebook pages you manage, as well as any linked Instagram Business accounts. This will allow users within this account to see and use pages to improve coverage.',
                listTitle: 'Select one or more Facebook pages or Instagram accounts:',
                noFbPages: "We couln't find any facebook pages for your account :(",
                fbPagesLoadError: 'Unable to get your pages via the Graph API',
                loadGrantedPagesLoadError: 'An unexpected error ocurred :(. Please try again.',
                track: 'Track',
                cta: 'Connect a new account',
                searchPlaceholder: 'Search for a Facebook page or an Instagram account',
                facebookIconLabel: 'Facebook page: ',
                instagramIconLabel: 'Instagram account: ',
            },
            tokenList: {
                title: 'Facebook Tokens',
                noItemsMsg: "You haven't authorized any tokens, yet.",
                errorMsg: 'Oops! There was an error while loading the tokens.',
                user: 'User',
                page: 'Page',
                linkedAccount: 'Linked Account',
            },
            userTokenSaved: 'Success! Your user token has been saved.',
            fbLoginError: 'Ooops. We were unable to log you into Facebook.',
            successTitle: 'Success!',
            successMesg: '%{num} Facebook tokens have been added',
            unknownError: 'Something went wrong!',
        },
        targetedData: {
            landing: {
                emptyTitle: 'Add Power To Your Data',
                emptyDescription:
                    'Collect data from Facebook and Instagram for use in your Searches.',
                emptyAddButton: 'Add Targeted Data',
                title: '%{num} Data Sources',
                addButton: 'Add New',
                hsiAddButton: 'Add',
                hashtag: 'Hashtag',
                account: 'Account',
                page: 'Page',
                user: 'User',
                dataSource: 'Data source',
                active: 'Active',
                inactive: 'Inactive',
                toBeDeleted: 'to be deleted',
                scheduledDeletionTooltip:
                    'This hashtag will be deleted in <strong>%{num} days</strong>.',
                cancelDeletion: 'Cancel Deletion',
                delete: 'Delete',
                deletionCancelSuccess: 'Deletion cancelled successfully!',
                deletionScheduleSuccess: 'Hashtag has been successfully scheduled for deletion.',
                deletionSuccess: 'Item successfully deleted.',
                confirmDeleteTitle: 'Delete Targeted Data',
                confirmDeleteLine1:
                    'Please confirm that you want to delete the %{itemType} <strong>"%{itemName}"</strong>.',
                confirmDeleteLine2: 'This action cannot be undone',
                limitAlert:
                    'You have reached your limit, please contact your Customer Account Manager to increase your data sources limit',
                team: 'Team',
            },
        },
    },
    back: 'Back',
    'by.user': 'By user',
    actions: 'Actions',
    callout: 'Callout',
    cancel: 'Cancel',
    close: 'Close',
    characterLimit: {
        msg: '%{current}/%{max} characters',
        ariaAnnounce: 'You have %{remaining} characters remaining',
        maxLimitMsg: 'Character limit reached. You have %{tooMany} characters too many.',
    },
    chart: 'Chart',
    'edit.saved.search': 'Save Changes',
    clear: 'Clear',
    'clear.filters': 'clear filters',
    'confirm.delete.alert': 'Please confirm that you want to delete the Alert "%{alert}".',
    'confirm.delete.item': 'Please confirm that you want to delete the Saved Search "%{search}".',
    'confirm.delete.saved.search':
        'Please confirm that you want to delete the Saved Search "%{search}".',
    'confirm.delete.lost.data.1': 'Existing mention annotations ',
    'confirm.delete.lost.data.2': '(i.e. sentiment, emotion, location, tags) ',
    'confirm.delete.lost.data.3': 'will be deleted.',
    'confirm.delete.cannot.undo': 'This action cannot be undone.',
    'confirm.edit.saved.search':
        'Please confirm that you want to save the changes to the Saved Search "%{search}".',
    'confirm.edit.cannot.undo': 'This action cannot be undone.',
    'content.source.filter': 'Content Source Filter',
    copy: 'Copy',
    created: 'Created',
    'date.created': 'Date Created',
    'date.filter': 'Date Filter',
    delete: 'Delete',
    'delete.alert': 'Delete Alert',
    'delete.saved.search': 'Delete Saved Search',
    description: 'Description',
    edit: 'Edit',
    'edit.email.alert': 'Edit Email Alert',
    'edit.search': 'Edit Search',
    'editing.saved.search': 'You are editing a saved search.',
    'editing.saved.search.characters': 'Save your changes to view the updated results.',
    'editing.saved.search.tooLong':
        'Your search is too long to save. Try using operators to make your search shorter.',
    'email.alerts': 'Email Alerts',
    emotion: 'Emotion',
    'emotion.filter': 'Emotion Filter',
    'enter.your.password': 'Enter your password',
    'enter.your.username': 'Enter your username',
    'error.deleteQuery': 'An error occured while deleting your search. Please try again later.',
    'error.loadSearches': 'An error occured while loading your searches. Please try again later',
    'exit.edit.mode': 'Exit Edit Mode',
    expires: 'Expires',
    explore: 'Explore',
    fieldErrorTitle: 'Error',
    fieldWarningTitle: 'Warning',
    filterOptions: 'Filter options...',
    'fix.error': 'Please fix error',
    'fix.errors': 'Please fix %{errors} errors',
    followed: 'Followed',
    followers: 'Followers',
    geography: 'Geography',
    'give.feedback': 'Give feedback',
    hashtag: 'Hashtag',
    'help.center': 'Help Center',
    nonactive: 'Non active',
    inactive: 'Inactive',
    'language.label': 'Language',
    'language.authorTotalTooltip': 'Total number of authors posting in this language',
    'language.sparklineTooltip':
        'Number of authors posting in this language over the selected period',
    impressions: 'Impressions',
    reach: 'Reach',
    'just.you': 'Just you',
    'keyword.filter': 'Keyword Filter',
    language: 'Language',
    'language.filter': 'Language Filter',
    'last.modified': 'Last modified',
    login: 'Login',
    loginUserIsUpdating: 'Please wait a few seconds while we update your account information.',
    loginUserIsProvisioning: 'Please wait a few seconds while we set up your account.',
    'manage.search': 'Edit Search',
    manualLogin: {
        email: 'Email',
        password: 'Password',
        login: 'Sign in',
        almostThere: 'Almost there!',
        getStarted: 'Get Started',
        subtitle: 'Please enter your Brandwatch details.',
        linkUserSubtitle: 'Please enter your Brandwatch details to complete the sign up.',
        somethingMissing: 'Oops! Something is missing here…',
        somethingMissingDesc: 'Please enter your email address and password',
        badCredentials: 'The email or password you entered is incorrect.',
        badCredentialsDesc: 'Please retry.',
        productDisabled: 'This product is not enabled for you.',
        productDisabledDesc: 'Please contact support if you think this is a mistake.',
        genericError: 'Oops! Something went wrong…',
        genericErrorDesc: 'Please try again or contact support.',
    },
    'mention.list': 'Mentions List',
    mention: 'Mention',
    mentions: 'Mentions',
    mentions_lc: 'mentions',
    '0_mentions': '0 Mentions',
    min: 'Min',
    max: 'Max',
    score: 'Net Sentiment Score',
    mentionsTitle: 'Place mentions here',
    more: 'More',
    name: 'Name',
    'name.action': 'Please enter a name for your search',
    'name.action2': 'Please shorten your search name and try again',
    'name.action3': 'This is likely a duplicate search name, make sure it is unique',
    'name.action4': 'Please enter a name for your alert',
    'name.error': 'Oops! You forgot to enter a name',
    invalidVolumeAlertValueError:
        'Oops! Alerts are only available for increases in volume. Please enter a non-zero positive integer',
    'name.length.error': "Oops! Your search's name cannot exceed %{limit} characters",
    'new.email.alert': 'New Email Alert',
    'no.email.alerts.found': 'No email alerts found.',
    'no.filters.applied': 'No filters applied',
    noDefaultProjectError:
        "Sorry, you haven't been given access to any projects. Please contact your administrator in order to gain access.",
    'no.value': 'No %{val}',
    homeNavBar: {
        navLbl: `${appName} navigation`,

        quicksearch: 'Quick Search',
        savedsearches: 'Saved Searches',
        emailAlerts: 'Email alerts',
        settings: 'Settings',
        helpCenter: 'Help Center',
        reports: 'Reports',
        dataManagement: 'Data management',
    },
    'num.recipients': '%{num} recipients',
    'one.filter.applied': '**1 filter** applied',
    'num.filters.applied': '**%{num} filters** applied',
    'one.recipient': '1 recipient',
    next: 'Next',
    oops: 'Oops...',
    optional: '(optional)',
    owner: 'Owner',
    pause: 'Pause',
    paused: 'Paused',
    percentage: '%{pct}%',
    'percent.mentions': '% of top 10 mentions',
    platform: 'Platform',
    pleaseSelect: 'Please select...',
    'please.select.a.team': 'Please select a team...',
    'powered.by.brandwatch': 'powered by Brandwatch',
    project: 'Project',
    'project.action': 'Please choose a project from the list',
    'project.error': "Oops! You haven't selected a project",
    'query.here': 'Query here',
    'query.saved': 'Query saved!',
    'query.edited': 'Query edited!',
    'quicksearch.example': 'Or start with an example',
    'quicksearch.boolean-help': 'Search Help',
    'quicksearch.quicksearch': 'Quick Search',
    readMore: 'Read more',
    readLess: 'Read less',
    'recently.viewed': 'Recently saved searches',
    'recently.viewAll': 'View all',
    recipients: 'Recipients',
    'refresh.the.search': 'Refresh the Search',
    'view.your.searches': 'View your Saved Searches',
    resume: 'Resume',
    save: 'Save',
    set: 'Set',
    continue: 'Continue',
    'save.as.a.new.search': 'Save as a new search',
    'save.changes': 'Save Changes',
    'save.search': 'Save Search',
    'saved.search': 'Saved Search',
    'saved.searches': 'Saved Searches',
    'saved.searches.data.gathering': 'Gathering data for this search',
    'saved.searches.data.gathered': 'Data for this search now complete.',
    'saved.searches.refresh': 'Data for this search may be out of date.',
    'saved.searches.left':
        '%{smart_count} Saved Search left |||| %{smart_count} Saved Searches left',
    'search.saved': 'Search saved!',
    'search.edited': 'Search edited!',
    'search.search': 'search',
    'search.placeholder': 'search for a brand, hashtag, handle or topic',
    'search.validating': 'validating',
    'search.validationOk': 'validation successful',
    'search.unauthorizedToSave':
        'Your account is not authorized to save searches. Please get in contact with your Customer Account Manager.',
    'select.project': 'Please select a project...',
    sentiment: 'Sentiment',
    'sentiment.filter': 'Sentiment Filter',
    'site.name': 'Site name',
    'sorry.no.items.found': 'Sorry, no %{items} found.',
    search: 'Search',
    'search.shortPlaceholder': 'Search...',
    searches: 'searches',
    submit: 'Submit',
    successTitle: 'Success!',
    status: 'Status',
    left: '**%{num}** %{name} left',
    team: 'Team',
    'team.action': 'Please choose a team from the list',
    'team.error': "Oops! You haven't selected a team",
    'there.appears.an.error': 'There appears to be an issue with your search',
    to: 'to',
    token: 'Token',
    total: 'Total',
    type: 'Type',
    unauthorized: {
        title: `${appName} authorization failed`,
        autoprovisionErrorMsg: `An error occured while setting up your ${appName} account. Try reloading your browser. If that doesn't work, please contact support.`,
        autoprovisionTimeoutMsg:
            "Your account setup timed out. Try reloading your browser. If that doesn't work, please contact support.",
        defaultMsg: "Try reloading your browser. If that doesn't work, please contact support.",
    },
    unauthorizedMessage: 'Authorization failed :(',
    unavailable: 'Unavailable',
    uniqAuthors: 'Unique authors',
    unknownError: 'An unknown error occured. Please try again later.',
    username: 'Username',
    user: 'User',
    'weekday.monday': 'Mon',
    'weekday.tuesday': 'Tue',
    'weekday.wednesday': 'Wed',
    'weekday.thursday': 'Thu',
    'weekday.friday': 'Fri',
    'weekday.saturday': 'Sat',
    'weekday.sunday': 'Sun',
    'you.can.create.an.alert': 'You can create an email alert from any saved search.',
    quicksearch: {
        heading: 'Welcome to Insights',
        title: 'Explore the voices of billions',
        sectionLbl: 'Perform a quick search',
    },

    validation: {
        validating: 'validating',
        validationOk: 'validation successful',
    },
    searchbox: {
        placeholder: 'search for a brand, hashtag, handle or topic',
    },
    searchbar: {
        newsearch: 'New Search',
        backBtn: 'Back to searches',
        savequery: 'Save Query',
        savesearch: 'Save Search',
        quicksearch: 'Quick Search',
        lastNdays: 'last %{days} days',
        notValidated: 'Please hit enter to test and validate your search before you save.',
        searchesLimitReached:
            'Sorry, you have **reached the limit** of Saved Searches. Please get in contact with your Customer Account Manager if you are interested in upgrading your package.',
    },
    savedsearchbar: {
        newquicksearch: 'New Quick Search',
        managesearch: 'Edit Search',
        managetags: 'Manage Tags',
        savedsearches: 'Saved Searches',
        createalert: 'Create Alert',
        breadcrumbToolTip: '%{section} > %{name}',
        buildreport: {
            button: 'Build Report',
            tooltip: 'Build New Report in Hootsuite Analytics',
            exportPDF: 'Export dashboard as PDF',
            reportCreated: 'The scheduled report "%{name}" has been created.',
            reportError: 'There was an error creating your schedule.',
            reportOptions: {
                scheduleReport: 'Schedule report',
                exportToPDF: 'Export dashboard as PDF',
                exportToPDFWithNotes: 'Export as PDF',
            },
        },
        scheduleReportTooltip:
            'Scheduled reports are not available for multiple searches or view only users',
    },
    entitiesRemaining: {
        msg: '%{smart_count} left |||| %{smart_count} left',
        ariaTooltipLbl: '%{title}. used %{used} of %{total}. %{content}',
    },
    savedSearchesLeft: {
        popupTitle: 'Total Saved Searches',
        popupContent:
            'On the Standard Plan all your teams can save up to %{num} searches together. Get in contact with your Customer Account Manager to increase your limit.',
        banner: {
            firstSentence: 'You have reached your limit.',
            secondSentence:
                'Please contact your Customer Account Manager to increase your Saved Searches limit.',
        },
    },
    dataSourcesLeft: {
        title: 'Total Targeted Data Sources',
        content:
            'On the Standard Plan all your teams can save up to %{num} data sources together. Get in contact with your Customer Account Manager to increase your limit.',
    },
    selectAll: 'Select all',
    notifyUserBeenLinked: `We noticed an existing Brandwatch Consumer Research account: **%{clientName}** with your username. 
        We've linked you to that account so you'll be able to interact with your Brandwatch Consumer Research data in Listen. 
        If you'd like to be moved to a different account, please reach out to your customer success manager.`,
    buildnewreport: {
        title: 'Build New Report',
        message:
            'You will be redirected to Hootsuite Analytics to continue building your Insights report.',
        checkboxmessage: "Don't show this again",
    },
    managesearchdialog: {
        lastmodifiedusername: 'By user',
        lastmodifiedBy: 'Modified by',
        team: 'Team',
        lastmodificationdate: 'Last modified',
        editsearch: 'Edit Search',
        search: 'Search',
    },
    managetagsdialog: {
        tags: 'Tags',
        manageTags: 'Manage tags',
        newTag: 'New tag name...',
        searchPlaceholder: 'Search Tags',
        add: 'Add',
        close: 'Close',
        edit: 'Rename Tag',
        delete: {
            title: "Are you sure you want to delete the tag '%{name}'?",
            sub: 'Deleting this tag will remove it from any mentions to which it has been applied.',
            disclaimer: 'This action cannot be undone.',
        },
        taggedMentionsTitle: '%{number} MENTIONS FOUND FOR TAG "%{tag}"',
        taggedMentions: 'Tagged Mentions',
        selectATag: 'Select a tag from the list to see the related mentions.',
        noMentions: 'No mentions with this tag',
        tryAgain: 'Try selecting a different tag from the list.',
        errors: {
            tagExists: {
                main: 'Oops! This tag name already exists',
                secondary: 'Please enter a different one',
            },
        },
        notifications: {
            success: {
                first: 'The tag ',
                created: ' has been successfully created',
                edited: ' has been successfully renamed to',
            },
        },
        hsiAddNewPlaceHolder: 'Tag name',
        hsiNewTitle: 'Add new tag',
        hsiSearchPlaceholder: 'Type or search a tag',
        hsiSearchTitle: 'Tags list',
        hsiSearchSubTitle: 'Select a tag from the list to see the related mentions.',
    },
    cards: {
        infoCTALabel: 'Learn more',
        moreInfoLbl: 'More info about %{name} card',
        pinCard: 'Pin %{title} to top',
        unpinCard: 'Un-pin %{title} from top',
        hideCard: 'Hide %{title}',
        unhideCard: 'Show %{title}',
        noData: {
            msg1: 'There is no data.',
            msg2: 'Try checking the filters section.',
            msg2b: 'Check the filters section or the tile configuration.',
        },
        error: {
            msg1: 'Error loading card data.',
            msg2: 'Try again later or contact support.',
        },
        unsupported: {
            msg1: 'Unsupported breakdown for selected date range.',
            msg2: 'Please select a time range of 1 day when segmenting by hours.',
        },
        notAvailablePerDataLimitation: {
            topinterests: {
                msg1: 'This card is only available for X (Twitter) data.',
                msg2: 'Please check your platform filters.',
            },
            heatmap: {
                msg1: 'This card does not support the selected date range.',
                msg2: 'Choose a date range up to 365 days',
            },
            benchmark: {
                msg1: 'The benchmark element of this card has been removed.',
                msg2: 'This is because the benchmark period includes dates which are beyond the 1 year of available historical data.',
            },
            benchmarkBySearch: {
                msg1: 'The benchmark element of this card has been removed.',
                msg2: 'This is because the benchmark period includes dates which are beyond the 1 year of available historical data.',
            },
        },
        totalMentions: {
            title: 'Total mentions',
            subheader: 'Powered by Brandwatch',
            previousPeriodLabel: 'Previous Period',
        },
        totalVolume: {
            title: 'Total volume',
            trendUp: 'Increase',
            trendDown: 'Decrease',
            multipleSearch: {
                title: 'Total volume across all searches',
            },
            sections: {
                volume: 'Total mentions',
                reachEstimate: 'Total reach',
                twitterRetweets: 'Total reposts',
                impressions: 'Total impressions',
                authors: 'Unique authors',
                avgFollowers: 'Average followers',
                retweetRate: 'Repost rate',
            },
            previous: 'Previous period:',
            savedTitle: 'Total volume',
            info: {
                title: 'Total Volume',
                copy: 'Key metrics to quickly see how popular your search term is. You can choose which metrics to display, such as total mentions, total impressions, total reach, unique authors, average followers, and repost rate. ',
            },
            subtitle: 'Benchmarked for %{start_date} against data from %{end_date}',
            noBenchmark:
                'The benchmark element of this card has been removed. This is because the benchmark period includes dates which are beyond the 1 year of available historical data.',
        },
        totalVolumeBySearch: {
            title: 'Total volume by search',

            tableFields: {
                position: 'Position',
                name: 'Search',
                volume: 'Mentions',
                reachEstimate: 'Reach',
                impressions: 'Impressions',
                authors: 'Authors',
                avgFollowers: 'Followers',
                retweetRate: 'Reposts',//', label: 'Repost rate'},
            },
            info: {
                title: '',
                copy: '',
            },
        },
        mentionsHistory: {
            title: 'Mention volume over time',
            generalDimension: 'Mention ',
            overDimension: ' over time ',
            subheader: 'Powered by Brandwatch',
            yAxisName: 'Mention volume',

            info: {
                title: 'Mention volume, impressions and reach over time',
                copy: 'The distribution of mention volume/impressions/reach for the selected time frame (by hour, day, week, or month). Point to a place on the graph to see the figure for a specific point in time',
            },
        },
        mentionsHistoryBySearch: {
            title: 'Mention volume broken down by searches',
            overDimension: ' by searches over time ',
        },
        heatmap: {
            title: 'Mention volume by day of the week and hour',

            info: {
                title: 'Mention volume by day of the week and hour',
                copy: 'The volume of mentions and their frequency at different times of the day and on different days of the week. Point to a square on the grid to see the number of mentions for a specific hour on a specific day.',
            },
        },
        sentimentHistory: {
            title: 'Sentiment volume over time',
            subheader: 'Powered by Brandwatch',
            yAxisName: 'Mention volume',

            info: {
                title: 'Sentiment volume over time',
                copy: 'The total number of mentions for each sentiment for the selected time period (hour, day, week, or month). Point to a place on the graph to see the number of mentions for a sentiment at a specific point in time.',
            },
        },
        emotionHistory: {
            title: 'Emotion volume over time',
            subheader: 'Powered by Brandwatch',
            yAxisName: 'Mention volume',

            info: {
                title: 'Emotion volume over time',
                copy: 'The total number of mentions, classified by emotion, for the selected time period (hour, day, week, or month). Point to a place on any line chart to see the number of mentions for that emotion at a specific point in time.',
            },
        },
        netSentimentHistory: {
            title: 'Net sentiment over time',
            subheader: 'Powered by Brandwatch',
            yAxisName: 'Mention Net Sentiment',
            cta: '[link] to see the net sentiment of this search based on the selected date frame.',
            multipleSearch: {
                title: 'Net sentiment broken down by searches',
            },
            info: {
                title: 'Net sentiment over time',
                copy: 'See how the overall sentiment of each search changes over time. Click on a plot point to view mentions for a specific time. Net sentiment is calculated as: ( Positive - negative mentions / Positive + negative mentions ) * 5.',
            },
        },
        sentimentVolume: {
            title: 'Sentiment volume',
            multipleSearch: {
                title: 'Sentiment volume across all searches',
            },
            info: {
                title: 'Sentiment volume',
                copy: 'The distribution of mentions by positive, neutral, and negative sentiment. Point to a segment to see the number of mentions classified by that sentiment. ',
            },
        },
        sentimentVolumeBySearch: {
            title: 'Sentiment volume broken down by searches',
        },
        emotionVolume: {
            title: 'Emotion volume',

            info: {
                title: 'Emotion volume',
                copy: 'The distribution of mentions by emotion. Point to a bar to see the number of mentions assigned to that emotion.',
            },
        },
        pageTypeBySearch: {
            title: 'Platform volume broken down by searches',
        },
        geography: {
            title: 'Volume by countries',
            countries: 'Country',
            sparklineTooltipQuick: 'Number of authors per country over the last 30 days',
            sparklineTooltipSaved: 'Number of authors per country over the selected period',
            uniqAuthorCTASplitBy: 'country',

            info: {
                title: 'Volume by countries',
                copy: 'The distribution of mentions in your search results based on country of origin. Different colors represent different countries. The darker the color, the more often that country appears in the results.',
            },
        },
        geographyBySearch: {
            title: 'Mention volume by countries broken down by searches',
        },
        topinterests: {
            title: 'Top interests',
            uniqAuthorCTASplitBy: 'interest',

            info: {
                title: 'Top interests',
                copy: 'The interests most often reported by authors in your search results, sorted by number of mentions. Saved search results show the number of unique authors for each interest, and the strength of the interest over the period of the search results.',
            },

            tableFields: {
                position: 'Position',
                name: 'Interest',
                total: 'Mentions',
                authorTotal: 'Unique authors',
                authorSeries: 'Trend',
                authorSeriesDesc: 'Number of authors reporting this interest over the selected period',
            },
        },
        toplanguages: {
            title: 'Top languages',
            uniqAuthorCTASplitBy: 'language',

            info: {
                title: 'Top languages',
                copy: 'The languages used most often in the search results, sorted by number of mentions. Saved search results list the number of unique authors for each language.',
            },

            tableFields: {
                position: 'Position',
                name: 'Language',
                total: 'Mentions',
                authorTotal: 'Unique authors',
            },
        },
        topauthors: {
            title: 'Top authors',
            authorNameA11yDesc: 'Name: %{name}',
            authorLocationA11yDesc: 'location: %{location}',
            authorDomainA11yDesc: 'domain: %{domain}',
            authorWithLocationA11yDesc: 'Name: %{name}, domain: %{domain}, location: %{location}',
            tableFields: {
                position: 'Position',
                name: 'Author',
                mentions: 'Mentions (% from top authors)',
                followers: 'X (Twitter) Followers',
                reach: 'Reach',
            },
            info: {
                title: 'Top authors',
                quickSearchCopy:
                    'View the top authors for your mentions, sorted by number of mentions.',
                savedSearchCopy:
                    'The authors who appear most often in your search results, sorted by number of mentions, reach, or X (Twitter) followers, and showing percentage share of mentions.',
            },
        },
        topauthorsBySearch: {
            title: 'Top authors broken down by searches',
            nameFieldLabel: 'Author',
        },
        tophashtags: {
            title: 'Top hashtags',

            tableFields: {
                position: 'Position',
                name: 'Hashtag',
                volume: 'Volume',
                impressions: 'X (Twitter) Impressions',
                reach: 'Reach',
            },

            info: {
                title: 'Top hashtags',
                quickSearchCopy:
                    'The most popular hashtags in your search results, sorted by the number of times they appear.',
                savedSearchCopy:
                    'The hashtags that appear most often in your search results, sorted by number of mentions, reach, or X (Twitter) impressions. Number appears as 0 when that metric isn’t available for any of the platforms on which that hashtag has appeared (e.g. there is no ‘reach’ on Tumblr)',
            },
        },
        tophashtagsBySearch: {
            title: 'Top hashtags broken down by searches',
            nameFieldLabel: 'Hashtag',
        },
        topMeasures: {
            tableFields: {
                position: 'Position',
                value: 'Total',
            },
        },
        topSharedURLs: {
            title: 'Top shared URLs',
            label: 'Top stories',

            info: {
                title: 'Top shared URLs',
                copy: 'The links shared most often on X (Twitter), sorted by how many times they appear in the search results.',
            },

            tableFields: {
                position: 'Position',
                name: 'Top stories',
                volume: 'Mentions',
            },
        },
        topsites: {
            title: 'Top sites',

            info: {
                title: 'Top sites',
                copy: 'The sites where mentions appear most often, sorted by the number of mentions.',
            },

            tableFields: {
                position: 'Position',
                name: 'Site name',
                value: 'Mentions',
            },
        },
        topsitesBySearch: {
            title: 'Top sites broken down by searches',
            nameFieldLabel: 'Site',
        },
        topicWheel: {
            title: 'Topic wheel',
            cta: '[link] to see a breakdown of this search based on topic group.',

            info: {
                title: 'Topic wheel',
                copy: 'The most popular topics related to your mention results. The most popular topics are in the inner circle, and popular subtopics are in the outer circle.',
            },
        },
        wordCloud: {
            title: 'Word cloud',

            info: {
                title: 'Word cloud',
                copy: 'The most frequently used words and phrases in your search results. The larger the word, the more often it appears compared to the other terms.',
            },
        },
        wordCloudBySearch: {
            title: 'Top topics across all searches',
        },
        toptopicsBySearch: {
            title: 'Top topics broken down by searches',
            nameFieldLabel: 'Topic',
        },
        gender: {
            title: 'Gender split',
            total: '%{volume} mentions',

            info: {
                title: 'Gender split',
                copy: 'An estimated breakdown of mentions (as a percentage of the total) by male and female authors, based on author name.',
            },
        },

        comingSoon: {
            msg1: 'Coming soon!',
            msg2: "This visualization isn't quite ready yet, check back again soon",

            info: {
                title: '',
                copy: '',
            },
        },
        titleWithBreakdown: '%{title} %{breakdownType} %{breakdown} %{subTitle}',
        titleWithAggregationAndBreakdown:
            '%{generalDimension}%{aggregate}%{overDimension}%{breakdownType} %{breakdown}%{subTitle}',
        titleBreakdownWithPrefix: '%{title} %{prefix}',
        breakdownWithPrefix: ' %{prefix} ',
        breakdownBy: 'by',
        breakdownFor: 'for',

        aggregations: {
            btnLbl: 'change card data aggregation',
            menuLbl: 'Select aggregate',
            menuDesc:
                'Change the way the data for this card is aggregated by selecting from one of the below options',
        },

        breakdowns: {
            btnLbl: 'change card breakdown',
            menuLbl: 'Select breakdown',
            menuDesc:
                'Change the way the data for this card is broken down by selecting from one of the below options',
        },
        historyBreakdownBySearch: 'broken down by searches',
        benchmark: {
            title: 'Mention volume benchmark',
            info: {
                title: 'Mention volume benchmark',
                copy: 'Comparison of mention volume between the current period and the previous period, by category (platform, sentiment, emotion, interest, language, or country). ',
            },
        },
        benchmarkBySearch: {
            title: 'Mention volume benchmark for searches',
            info: {
                title: 'Mention volume benchmark for searches',
                copy: 'Comparison of mention volume between the current period and the previous period for your searches.',
            },
        },
        shareOfVoice: {
            title: 'Share of voice by search',
        },
    },
    filters: {
        title: 'Filters',
        sectionLbl: 'Filters',
        showFiltersLbl: 'Show filters, %{num} filters currently active',
        closeFiltersLbl: 'Close filters',
        filtersSummaryTooltip: 'Active filters:',
        noActiveFiltersTooltip: 'No active filters',
        noActiveFiltersTooltipAndSelectMode:
            'No active filters - filters cannot be changed when selecting multiple mentions',
        filterLockedInSelectMode: 'Filters cannot be changed when selecting multiple mentions',
        clear: 'Clear',
        clearAll: 'Clear all',
        all: 'All',
        any: 'Any',
        exclude: '%{name} (exclude)',
        type: {
            temp: 'Temp',
            gender: 'Gender',
            keyword: 'Keyword',
            classifications: 'Emotion',
            location: 'Location',
            language: 'Language',
            pageType: 'Platform',
            sentiment: 'Sentiment',
            author: 'Author',
            site: 'Site',
            tag: 'Tags',
            category: 'Categories',
            workflow: 'Workflow',
            twitterAuthor: 'X (Twitter) Account Type',
            ownedContent: 'Owned Content',
            threadEntryType: 'Mention Type',
            twitterFollowersMin: 'Max X (Twitter) followers',
            twitterFollowersMax: 'Min X (Twitter) followers',
            accountType: 'Account type',
            domain: 'Domain',
            twitterVerified: 'X (Twitter) verified',
            followersRange: 'X (Twitter) followers',
            socialPanels: 'Social panels',
        },
        typeBCR: {
            gender: 'Gender',
            keyword: 'Keyword',
            classifications: 'Emotion',
            location: 'Location',
            language: 'Language',
            pageType: 'Content Source',
            sentiment: 'Sentiment',
            author: 'Author',
            tag: 'Tags',
        },
        placeholders: {
            search: {
                keyword: 'Add keywords and hit enter',
                author: 'Search Author',
                tag: 'Enter Tags',
                language: 'Search Languages',
                location: 'Search Locations',
                owned: 'Search Owned Usernames',
                domain: 'Search Owned Websites',
                site: 'Search websites',
                handles: 'Search owned handles',
                socialPanels: 'Search social panels',
                assigned: 'Assigned',
                checked: 'Checked',
                priority: 'Priority',
                status: 'Status',
            },
        },
        helperText: {
            keyword: ``,
        },
        inclusionDropDown: {
            include: 'Include',
            exclude: 'Exclude',
            filterBy: 'Filter by',
        },
        disabled: {
            bold: 'Save your search',
            normal: ' to enable this filter.',
        },
        workflow: {
            assignment: 'Assignment',
            checked: 'Checked',
            priority: 'Priority',
            status: 'Status',
            high: 'high',
            low: 'low',
            medium: 'medium',
            na: 'n/a',
            open: 'open',
            pending: 'pending',
            closed: 'closed',
            yes: 'yes',
            no: 'no',
            any: 'Any',
        },
        twitterAuthor: {
            verification: 'Verification',
            verified: 'Verified',
            unverified: 'Unverified',
            userRole: 'User Role',
            audience: 'Audience',
            owner: 'Owner',
            followersLabel: 'Followers',
            validateIntMsg: 'Please enter a positive integer number.',
            accountType: 'Account Type',
            individual: 'Individual',
            organizational: 'Organizational',
        },
        ownedContent: {
            validateDomainMsg: 'Please enter a valid domain.',
        },
        threadEntryType: {
            post: 'Original Post',
            reply: 'Comment / Reply',
            share: 'Share / Repost',
        },
        oops: 'Oops!',
        validation: {
            validateDomainMsg: 'Please enter a valid domain.',
            duplicateValue: 'This value has already been added.',
            maxValues: 'You have reached the maximum number of values for this field',

            rangeMin: 'Please enter a value of at least %{min}',
            rangeMax: 'Please enter a value of at most %{max}',

            noExclusion: 'Exclude mode is not available for this filter',

            unknownValue: 'Please select one of the available options',
        },
        summary: {
            rangeBetween: '%{min} - %{max}',
            rangeMin: '%{min}+',
            rangeMax: 'Up to %{max}',
        },
        category: {
            chipLabelAllSubcats: '%{name}: All',
            chipLabel: '%{name}: %{subCats}',
        },
        errors: {
            loadPersistedFailed:
                'Unable to load your saved filter settings for this query. If this error persists, please contract support',
        },
    },
    simpleMenu: {
        selected: ' (selected)',
    },
    mentionsContainer: {
        title: 'Mentions',
        ariaLabel:
            'Mentions feed: To move between mentions, use PageUp/PageDn. To jump to before/after the mentions list, use Ctrl + Home/End',
        hideMentions: 'Hide',
        showMore: 'Expand view',
        showLess: 'Collapse view',
        infoBtnLbl: 'More info about mentions',
        info: {
            title: 'Mentions Table',
            copy: 'A list of all the mentions returned in your search results. Each entry contains information about the mention, such as source, author, location, date and time.',
        },
        noData: {
            msg1: 'There is no data.',
            msg2: 'Try checking the filters section.',
        },
        error: {
            msg1: 'Error loading mentions.',
            msg2: 'Try again later or contact support.',
        },
        error300: {
            msg1: 'Error loading mentions from the selected group.',
            msg2: 'At least one of the queries of this group is not active.',
        },
        drillIn: {
            clear: 'Clear drill-in',
            viewing: 'Viewing',
            from: ' from ',
            apply: 'Apply as Filter',
            filterAlreadyApplied:
                'Oops! This filter is already applied. Please check your filters.',
            previousDate: ' for %{startDate} - %{endDate}',
        },
        export: {
            export: 'Export',
            exportAs: 'Export as',
            downloadCsv: 'CSV',
            downloadJson: 'JSON',
            loadingMsg: 'Loading export data...',
            noMentionsMsg:
                'No mentions to export. Please change your search keywords and/or check your filters',
            failMsg: 'Oops. Something went wrong. Please try again.',
            exportDisabled: 'Your pricing plan does not allow mention exports.',
            exportMentions: 'Export mentions',
            exportLimitMsg:
                'This export would take you over your daily export limit of 5000 mentions. Please reduce the amount of mentions you’re exporting to stay within the limit, or try again tomorrow.',
        },
        peak: {
            drawerTitle: 'Peak Detection',
            label: 'Peak: %{name}, on: %{peaked}',
            highestVolumeLbl: 'highest volume: %{value}',
            diff: 'Volume was %{percent}% higher than usual',
            driver: {
                facebook: '**%{volume} mentions** on a [Facebook thread](%{url})',
                hashtag: '**%{volume} mentions** using the hashtag **%{hashtag}**',
                news: '**%{volume} mentions** from news sites',
                none: 'There are no distinct drivers for this peak.',
                reddit: '**%{volume} mentions** on a [Reddit thread](%{url})',
                retweet: '**%{volume} reposts** of this [Post](%{url})',
                url: '**%{volume} mentions** sharing a link from [%{url}](%{data})',
                video: '**%{volume} mentions** from video sites',
            },
        },
        sortBtnLbl: 'Change sort order',
        sortMenuLbL: 'Select a sort order',
        order: {
            orderBy: 'Sort By',
            dateDesc: 'Newest First',
            dateAsc: 'Oldest First',
            relevanceDesc: 'Most Relevant',
            relevanceAsc: 'Least Relevant',
            reachEstimateDesc: 'Reach',
            randomDesc: 'Random',
            md5Desc: 'Random',
            twitterRetweetsDesc: 'X (Twitter) Reposts',
            impressionsDesc: 'X (Twitter) Impressions',
        },
        fullLayout: {
            label: 'Select mentions layout',
            uniform: 'Uniform layout',
            masonry: 'Brick layout',
        },
        selectModeToggleLbl: 'Select multiple',

        mentionSelected: 'Mention selected, %{num} of %{max} selected',
        mentionUnselected: 'Mention unselected, %{num} of %{max} selected',
        clearedSelectedMentions: 'Selected mentions cleared',

        numSelectedMentions: '%{num}/%{max} mentions selected',
        clearSelectedMentions: 'Clear selected mentions',

        bulkActionsMenu: {
            moreActionsBtnLbL: 'More actions',
            moreActionsDisabledLbl: 'Turn on select mode to use these options',
            moreActionsNoneSelectedLbl: 'Select at least one mention to enable',
            actions: {
                sentiment: {
                    title: 'Change sentiment',
                },
                tags: {
                    title: 'Add tags',
                },
                delete: {
                    title: 'Delete',
                },
            },
        },
        bulkAddTagsDialog: {
            title: 'Apply tags',
            description: 'Please select a tag to apply',
            cancelAction: 'Cancel',
            confirmAction: 'Apply to %{count} mentions',
            pending: 'Please wait...',
            newTagLbl: 'a new tag will be created',
            tagField: {
                errorText: 'Oops! You forgot to select a tag',
                actionText: 'Please select a tag to apply to the selected mentions',
            },
            addtagError:
                'Tagging mention(s) failed. Please try again later, and if this problem persists please contact support.',
        },
        bulkChangeSentiment: {
            title: 'Change sentiment',
            cancelAction: 'Cancel',
            confirmAction: 'Apply to %{count} mentions',
            sentimentField: {
                label: 'Choose a sentiment to apply',
                errorText: 'Oops! You forgot to select a new sentiment',
                actionText: 'Please select a sentiment to apply to the selected mentions',
            },
            pending: 'Please wait...',
        },
        bulkDeleteDialog: {
            title: 'Delete mentions',
            alert: 'This will remove these mentions from this saved search',
            copy: 'This action cannot be undone. You can retrieve deleted mentions by creating a new Saved Search with the same boolean query.',
            cancelAction: 'Cancel',
            confirmAction: 'Delete %{count} mentions',
            pending: 'Please wait...',
        },
    },
    mentionComponent: {
        via: 'via %{plattform}',
        errors: {
            patch: {
                noNetwork:
                    'Sorry, we were unable to edit the mention(s) as we could not connect to the server. Please check your internet connection and try again',
                unknown:
                    'Sorry, we were unable to edit the mention(s), please try again. If the problem persists, please contact support.',
                bulkActionPending:
                    'Sorry, we were unable to edit the mention(s) as there is an ongoing edit action, please wait until the previous action has completed and try again.',
            },
            delete: {
                noNetwork:
                    'Sorry, we were unable to delete the mention(s) as we could not connect to the server. Please check your internet connection and try again',
                unknown:
                    'Sorry, we were unable to delete the mention(s), please try again. If the problem persists, please contact support.',
                bulkActionPending:
                    'Sorry, we were unable to delete the mention(s) as there is an ongoing delete action, please wait until the previous action has completed and try again.',
            },
            saveTag: {
                unknown:
                    'Sorry, we were unable to create the tag, please try again. If the problem persists, please contact support.',
            },
        },
        metadata: {
            sentimentNA: 'Sentiment n/a',
            emotionNA: 'Emotion n/a',
            locationNA: 'Location n/a',
            languageNA: 'Language n/a',
            msg202: 'Your changes have been stored, but will take a while to be reflected on our database.',
        },
        tags: {
            placeholder: 'Search Tags',
            create: 'Create new tag',
            add: 'Add Tag',
            alreadyAdded: 'Tag already added',
            pending: 'Updating tags',
            addTagMenuLbl: 'Select or create a new tag',
            addTagMenuDesc:
                'Type the name of the tag you want to add to this mention. If the tag does not currently exist, it will be created',
        },
        reach: 'Reach %{value}',
        author: 'Author',
        showMore: '(show more)',
        showLess: '(show less)',

        emotion: '%{value}',
        emotionTooltip: 'Emotion: %{value}',
        sentiment: 'Sentiment: %{value}',
        sentimentPending: 'Updating sentiment',
        editSentiment: 'Edit sentiment',
        twitterRetweets: 'Reposts %{value}',
        twitterImpressions: '<span class="truncate">Impressions</span>&nbsp;%{value}',
        twitterImpressionsTooltip: 'Impressions %{value}',
        facebookComments: 'Comments %{value}',
        facebookLikes: 'Likes %{value}',
        redditScore: 'Reddit Score %{value}',
        instagramInteractionsCount: 'Total Interactions %{value}',
        seeOriginal: 'See original post',
        openSingleMention: 'View details',

        platform: 'Platform: %{name}',

        mentionsDeleted: 'Mention(s) deleted successfully',
        mentionsTagAdded: "The tag '%{tagName}' has been applied to %{count} mention(s)",
        mentionsTagRemoved: "The tag '%{tagName}' has been removed from %{count} mention(s)",
        mentionsSentimentChanged:
            "Sentiment '%{sentiment}' has been applied to %{count} mention(s)",
        mentionsEmotionChanged: 'Emotion has been updated for %{count} mention(s)',
        mentionsLocationChanged: 'Location has been updated for %{count} mention(s)',
        mentionAriaLbl: 'Mention: %{name}, by %{author}, from %{platform}',
        mentionSelectableAriaLbl: {
            selected:
                'Mention (selected): %{name}, by %{author}, from %{platform}, press space to unselect',
            unselected:
                'Mention (unselected): %{name}, by %{author}, from %{platform}, press space to select',
            selectedDisabled: 'Mention (selected): %{name}, by %{author}, from %{platform}',
            unselectedDisabled: 'Mention (unselected): %{name}, by %{author}, from %{platform}',
        },
        clickToSelectLbl: 'Press [Space] to select/unselect',
    },
    singleMentionView: {
        title: 'Mention details',
        seeOriginal: 'See original post',
        deleteMention: 'Delete this mention',
        author: 'Author',
        source: 'Source',
        post: 'Post',
        tags: 'Tags',
        tweets: 'Posts',
        followers: 'Followers',
        following: 'Following',
        replies: 'Replies',
        likes: 'Likes',
        comments: 'Comments',
        date: 'Date',
        location: 'Location',
        language: 'Language',
        sentiment: 'Sentiment',
        emotion: 'Emotion',
        reach: 'Reach',
        retweets: 'Reposts',
        interactions: 'Interactions',
        redditScore: 'Reddit score',
        impressions: 'Impressions',
        nextMention: 'Next mention',
        prevMention: 'Previous mentions',
        'n/a': 'n/a',
        delete: {
            title: 'Delete mention',
            body: 'This will remove this mention from the results for this saved search. Please confirm you wish to proceed.',
            confirm: 'Delete',
        },
    },
    confirmDialog: {
        confirm: 'Confirm',
        cancel: 'Cancel',
        cannotUndo: 'This action cannot be undone.',
    },
    mentionsDrawer: {
        open: 'Show mentions',
        close: 'Hide mentions',
    },
    noResults: 'No Results',
    foundResults: 'Found %{num} results',
    noSearchResults: {
        noDataTitle: 'No results returned',
        checkDataErrorTitle: 'Unable to load results',
        subtitleQuick: 'Check your spelling',
        subtitleSaved: 'Try selecting a different date range',
        tryQuick: ' or try ',
        trySaved: ' or ',
        clearFilters: 'clearing your filters',
        linkedinWarning:
            "You have selected LinkedIn in your platform filters but haven't connected any pages. Please check the LinkedIn icon at the top bar or try ",
        checkingHasDataError: 'check your internet connection and click to ',
        retryCheckingHasData: 'retry loading results',
        
    },
    anchors: {
        mentions: 'mentions count',
        sentiment: 'sentiment',
        emotion: 'emotion',
        topMeasures: 'top measures',
        wordCloud: 'word cloud',
        demographics: 'demographics',
        topicWheel: 'topic wheel',
        benchmark: 'benchmark',
        "$pinned": 'pinned',
        "$hidden": 'hidden',
    },
    anchorsLabel: 'Skip to %{name}',
    datepicker: {
        bar: {
            a11yRangeLabel: 'Showing results for date range: %{start} - %{end}',
            dateWithYear: '%{date}, %{year}',
            withTimezone: '%{date} %{timezone}',
        },
        title: 'Select date range',
        relativeDateRangesTitle: 'Rolling date ranges',
        fixedDateRangeTitle: 'Fixed date range',
        today: 'Today',
        yesterday: 'Yesterday',
        last7: 'Last 7 Days',
        last30: 'Last 30 Days',
        last60: 'Last 60 Days',
        lastYear: 'Last Year',
        disabled: {
            bold: 'Save your search',
            boldbcr: 'Save your query',
            normal: ' to track this data over time.',
            historical: ' for instant access to 1 year of historical data.',
        },
        dateOnlyTooltip: 'Date',
        tooltip: 'Date and Timezone',
        popoverTitle: 'Select date range',
        popoverDescription:
            'Select a fixed start and end time using the calendars, or use the options on the left to select a rolling time range.',
        timezoneSelector: {
            btnLabel: 'Change timezone',
            helpTitle: 'Timezone help',
            placeholder: 'Search for a timezone',
            title: 'Timezone',
            defaultTimezone: 'Default: %{defaultTimezone}',
            accessibleSelectedTimezone: 'Selected timezone: ',
            accessibleDialogTitle: 'Select a timezone',
            accessibleDialogDescription:
                'Select the desired timezone from the list below, or use the filter option to find a specific timezone',
            inputLabel: 'Filter timezones',
            helpTooltipTitle: 'Adjust Timezones',
            helpTooltipCopy: `
The data in this dashboard will be calculated based on the selected timezone.

These changes will affect all dashboard components, mention details, and PDF exports.

Alerts, snapshot scheduled reports, and CSV exports will not be affected and remain in UTC.
`,
        },
    },
    'notFound.oops': 'Oops!',
    'notFound.broken': 'The link you clicked may be broken or the page may have been removed.',
    'notFound.return': 'Return to homepage',
    breakdowns: {
        minutes: 'Minute',
        hours: 'Hour',
        days: 'Day',
        weeks: 'Week',
        months: 'Month',
        pageTypes: 'Platform',
        sentiment: 'Sentiment',
        emotions: 'Emotion',
        interest: 'Interest',
        languages: 'Language',
        countries: 'Country',
    },
    aggregations: {
        volume: 'Volume',
        impressions: 'Impressions',
        reachEstimate: 'Reach',
    },
    saveSearchTooltip: {
        main: 'Save your search',
        mentions: ' to see more details, like: sentiment, emotion and language.',
        filters: ' to enable this filter.',
        exclusionFilter: ' to enable the option to exclude items with this filter',
        export: ' to export and download mentions.',
        unauthorized:
            'Your current user role does not permit creation of Saved Searches which are required to use the exclude option of this filter. Please contact an Admin user within your organization to request additional permissions.',
        link: 'save your search',
        characterLimitError:
            'Shorten it to view results or [%{linkText}](%{linkUrl}) to remove the character limit. ',
    },
    newStreams: {
        followStory: 'Follow story',
        goToStream: 'Go To Stream',
        email: 'Send mention to email',
        starMention: 'Star',
        unstarMention: 'Unstar',
    },
    streams: {
        appBar: {
            logout: 'Sign out',
            filters: 'Filters',
            settings: 'Settings',
            editSetup: 'Edit Project / Query',
            signOut: 'Sign Out',
            myBrandwatch: 'My Brandwatch',
        },
        filters: {
            title: 'Filters',
            clearAll: 'Clear Filters',
            apply: 'Apply',
        },
        querySetup: {
            title: 'Setup',
            subtitle: 'Just pick a Project and a Query',
            projectLabel: 'Project',
            projectPlaceholder: 'Select a Project',
            queryLabel: 'Query',
            queryPlaceholder: 'Select a Query',
            done: 'Done',
            group: 'group',
        },
        mentions: {
            loadingMentions: 'Loading mentions...',
        },
        mentionActions: {
            addTags: 'Add tags',
            assignTo: 'Assign To',
            editTags: 'Edit tags',
            workflow: 'Workflow',
            moreActions: 'More Actions',
            starMention: 'Star',
            unstarMention: 'Unstar',
            retweet: 'Repost',
            dm: 'Direct Message',
            reply: 'Reply',
        },
        mentionsTagger: {
            addTags: 'Add tags',
            editTags: 'Edit tags',
            apply: 'Apply',
            helperText: 'Select multiple predefined tags.',
            placeholder: 'Search tags',
        },
        editWorkflow: {
            title: 'Workflow',
            assignTo: 'Assign to a user',
            any: 'Any',
            selectPh: 'Select',
        },
    },
    uniqAuthorsCTA: {
        message:
            '[link] to see the number of authors who posted about this search, split by %{splitBy}.',
        linkText: 'Save your search',
    },
    TopBar: {
        powered: 'powered by Brandwatch',
        insights: appName,
    },
    errorMap: {
        mixed_and_or: `You mixed AND and OR operators in a way we can't understand. For example, if your search string was "hot chocolate OR cocoa", we don't know if you meant "(hot chocolate) OR cocoa", or "hot AND (chocolate OR cocoa)". To fix this error, please use parentheses like those to make it clear what you are searching for.`,
        lowercase_operator: `You used the words "and" or "or" without capitalizing them, or putting them in quotes. If you meant to use a search operator, you need to capitalize them, like "apple AND juice" instead of "apple and juice". If you meant the word to be a part of a search string, you need quotes, like "half and half".`,
        missing_left_operand: `You can't start a search with AND or OR. For example, "apple AND juice" is valid, but "AND juice" or "OR juice" is not.`,
        missing_right_operand: `You can't end a search with AND or OR. For example, "apple AND juice" is valid, but "apple AND" or "apple OR" is not.`,
        dangling_parenthesis: `You forgot a closing parenthesis. For example, "(hot chocolate) OR cocoa" is valid, but "(hot chocolate OR cocoa" is not.`,
        trailing_parenthesis: `You forgot an opening parenthesis. For example, "(hot chocolate) OR cocoa" is valid, but "hot chocolate) OR cocoa" is not.`,
        dangling_quote: `You forgot to close a quoted term. For example, "hot chocolate" is valid, but "hot chocolate (without the closing quote) is not.`,
        quote_in_term_error: `You forgot a start quote. For example, "hot chocolate" is valid, but hot chocolate" (without the starting quote) is not. Alternatively, if you want a quote mark in your search, you must precede it with a backslash character, like this: \\"`,
    },
    configSideDrawer: {
        title: 'Configure Tile',
        emptySelection: 'None',
        wordCloud: {
            show: 'Show',
            orderBy: 'Word Size',
            color: 'Color',
            cloudSize: 'Cloud Size',
            selectItems: {
                color: {
                    topicType: 'Topic Type',
                    gender: 'Gender',
                    sentiment: 'Sentiment',
                    volume: 'Volume',
                    trending: 'Trending',
                    random: 'Random',
                    none: 'None',
                },
                orderBy: {
                    trending: 'Trending',
                    volume: 'Volume',
                },
            },
        },
        totalVolume: {
            select: {
                label: 'Metric %{i}',
                none: 'None',
            },
        },
    },
    queryBuilder: {
        guidedTitle: 'Or try a guided search',
        showGuidedLbl: 'Show guided search options',
        hideGuidedLbl: 'Hide guided search options',
        guidedShown: 'Guided search options shown',
        guidedHidden: 'Guided search options hidden',
        guidedOptions: {
            brand: 'Search for a <strong>brand</strong> (e.g Apple)',
            issue: 'Search for an <strong>issue</strong> (e.g. #GlobalWarming)',
            campaign: 'Search for a <strong>campaign</strong> (e.g. "Share a coke")',
            person: 'Search for a <strong>person</strong> (e.g. @rihanna)',
            socialAccount: 'Search for a <strong>social account</strong> (e.g. @myCompany)',
            topic: 'Search for a <strong>topic</strong> (e.g. Halloween)',
        },

        guidedOptionsLabels: {
            brand: 'a brand',
            issue: 'an issue',
            campaign: 'a campaign',
            person: 'a person',
            socialAccount: 'a social account',
            topic: 'a topic',
        },

        headerTitle: 'Search for %{label}',

        includeTitle: 'Use commas to separate words and phrases.',
        excludeIrrelevantKeywordsTitle: 'Words and phrases',
        excludeIrrelevantKeywordsSubtitle:
            'Enter any words and phrases to exclude from your results.',
        excludeIrrelevantKeywordsPlaceholder: 'Music, Apple podcasts',
        excludeWebsitesTitle: 'Websites',
        excludeWebsitesSubtitle: 'Enter any websites to exclude from your results.',
        excludeWebsitesPlaceholder: 'everymac.com',
        excludeAuthorsTitle: 'Social accounts',
        excludeAuthorsSubtitle:
            'Enter any usernames or social media handles to exclude from your results.',
        excludeAuthorsPlaceholder: 'applebot404',

        brandNameTitle: 'Brand name',
        brandNameSubtitle: "Enter your brand name, a competitor's, or any other.",
        brandNamePlaceholder: 'Apple',
        brandRelatedTermsTitle: 'Brand name is a common word',
        brandRelatedTermsInfo:
            "Toggle on if your brand name is a common word like Apple or Fox. We'll then ask you to add words associated with your brand to ensure accurate search results.",
        brandRelatedTermsSubtitle: 'Enter words that are typically associated with the brand.',
        brandRelatedTermsPlaceholder: 'Apple computer, Apple Mac, Apple iPhone',
        brandNicknamesTitle: 'Alternative brand or product names',
        brandNicknamesSubtitle:
            'Enter other names for the brand, common misspellings, or brand products.',
        brandNicknamesPlaceholder: 'Macintosh, Mackintosh, MacBook Pro',
        brandWebsitesTitle: 'Websites',
        brandWebsitesSubtitle: 'Enter any brand websites you want to track.',
        brandWebsitesPlaceholder: 'apple.com',
        brandHashtagsTitle: 'Hashtags',
        brandHashtagsSubtitle: 'Enter any unique hashtags associated with the brand.',
        brandHashtagsPlaceholder: '#apple, #iOS, #iMac',
        brandOwnedSocialAccountsTitle: 'Social accounts',
        brandOwnedSocialAccountsSubtitle:
            'Enter any usernames or social handles associated with the brand.',
        brandOwnedSocialAccountsPlaceholder: 'apple, applesupport',

        brandContextTitle: 'Search in the context of my brand',
        brandContextPlaceholder: 'Select a saved brand search',
        brandContextLoading: 'Loading, please wait...',

        issueTermsTitle: 'Words and phrases',
        issueTermsSubtitle: 'Enter any words and phrases to include in your results.',
        issueTermsPlaceholder: 'Global warming, greenhouse gas',
        issueLinksTitle: 'Links',
        issueLinksSubtitle:
            'Enter any links to websites, blogs, or articles to include in your results.',
        issueLinksPlaceholder: 'nrdc.org/stories/global-warming-101',
        issueHashtagsTitle: 'Hashtags',
        issueHashtagsSubtitle: 'Enter any relevant hashtags to include in your results.',
        issueHashtagsPlaceholder: '#actonclimate',

        campaignTermsTitle: 'Words and phrases',
        campaignTermsSubtitle: 'Enter any words and phrases to include in your results.',
        campaignTermsPlaceholder: 'Coca-Cola, friendship',
        campaignLinksTitle: 'Links',
        campaignLinksSubtitle:
            'Enter any links to websites, blogs, or articles to include in your results.',
        campaignLinksPlaceholder: 'https://www.adweek.com/brand-marketing/',
        campaignHashtagsTitle: 'Hashtags',
        campaignHashtagsSubtitle:
            'Enter any relevant Instagram hashtags to include in your results.',
        campaignHashtagsPlaceholder: '#ShareACoke, #CocaColaMoments',

        topicTermsTitle: 'Words and phrases',
        topicTermsSubtitle: 'Enter any words and phrases to include in your results.',
        topicTermsPlaceholder: 'Halloween makeup, Halloween costumes',
        topicHashtagsTitle: 'Hashtags',
        topicHashtagsSubtitle: 'Enter any relevant hashtags to include in your results.',
        topicHashtagsPlaceholder: '#HalloweenMakeover, #HalloweenOOTD',

        personNamesTitle: 'Full name',
        personNamesSubitle: "Enter the person's first and last name.",
        personNamesPlaceholder: 'Rihanna Fenty',
        personSocialAccountsTitle: 'Social accounts',
        personSocialAccountsSubitle: "Enter the person's username or social media handle.",
        personSocialAccountsPlaceholder: 'rihanna',

        socialAccountsInstagramHashtagsTitle: 'Instagram Hashtags',
        socialAccountsInstagramHashtagsSubtitle:
            'Select any Instagram hashtags that you are currently tracking.',
        socialAccountsInstagramHashtagsPlaceholder: '#apple, #mac, #macbook',
        socialAccountsSocialAccountsTitle: 'Social accounts',
        socialAccountsSocialAccountsSubtitle:
            "Select any Facebook, Instagram, and X (Twitter) accounts you are currently tracking (LinkedIn can be added once you've saved the search)",
        socialAccountsSocialAccountsPlaceholder: '@apple, apple',

        editGuidedSearchBtnLbl: 'Edit guided search',
        recentQueriesTitle: 'Recent searches',
        recentQueriesLoadError: 'Could not load recent queries',
        tabFormattingMsg:
            'Tab formatting can be toggled with CTRL+SHIFT+M (mac) or CTRL+M (windows)',
        placeholder: 'Search for a brand, campaign, or person',
        validationError:
            'Error while validating your query. Please check your connection and try again later.',
        showAdvancedOptions: 'Show Advanced Options',
        showAdvanced: 'Show Advanced',
        hideAdvanced: 'Hide Advanced',
        editingThisQuery: 'Exit guided search?',
        swithToAdvancedDescription:
            'Exiting this form takes you back to the advanced search. You can continue to edit the search formula manually, but you won’t be able to return to this form to make changes.',
        swithToAdvancedButton: 'Exit guided search',
        stayInGuidedButton: 'Cancel',
        search: 'Search',
        on: 'On',
        off: 'Off',
        formulaTitle: 'Formula',
        formulaHelp:
            "Select anything to include and exclude in your results and we'll create your search formula here.",
        moreFeedbackTitle: 'Share more feedback',
        moreFeedbackDescription:
            'We’d love to hear more about your experience with the new search in %{appName}.',
        moreFeedbackButton: 'Take a quick survey',
        searchHelpButton: 'Need help with searches?',
        searchHelpTitle: 'Guidance for creating searches',
        searchHelpBoxPlaceholder: 'Search for an operator',
        tryThisSearch: 'Try this search',
        authNow: 'Authenticate now',
        igHashtagsPopupTitle: 'Currently tracking on Instagram',
        noIGAuthDesc: 'Hashtags won’t be tracked on instagram until you authenticate.',
        noIGHashtagsDesc:
            'Some hashtags won’t be tracked on Instagram until you select an authenticated Facebook page to track them with.',
        characterLimitError: 'Your quick search exceeds the %{characterLimit}-character limit. ',

        //targeted data
        menuFBAuthStartTitle: 'No authenticated pages found',
        menuFBAuthStartLink: 'Authenticate with Facebook',
        hashtagsRemaining: `%{num} hashtags left`,
        igbaSelectTitle: 'Select a page to track with',
        trackWithPage: 'Track with "%{page}"',
        trackInfoTitle: 'Instagram hashtags will count toward your limit.',
        trackInfoDesc:
            'Adding a hashtag will count towards your hashtag limits immediately. They can only be removed at **7 day increments** from when you start tracking.',
        confirm: 'Confirm',
        cancel: 'Cancel',
        addNewPage: 'Add new page',
        trackWithIgTooltip: `**Do you want to track this hashtag on Instagram?**
Select an authenticated Facebook page to track with.`,
        trackWithIgBtn: 'Add instagram account to hashtag %{hashtag}',
        twitterAccounts: 'X (Twitter) Accounts',
        fbPagesOwned: 'Owned Facebook Pages',
        fbPagesNonOwned: 'Facebook Pages (Non-Owned)',
        igAccounts: 'Owned Instagram Business Accounts',
        nonOwnedInstagramAccounts: 'Non-Owned Instagram Business Accounts',
        addNewFbPagesNonOwned: 'Add new Facebook Page (Non-Owned)',
        addPagesButton: 'Add',
        noPagesFound: 'No pages found',
        noFbAuthTokenTitle: 'Not authenticated',
        fbAuthToAddPages: '<a>Authenticate with Facebook</a> to add pages',
        addNonOwnedLabel: 'Enter a Facebook profile name or URL',
        notTrackingNonOwnedFacebookPages: 'Not tracking any non-owned Facebook pages, yet.',
        notTrackingNonOwnedInstagramAccounts: 'Not tracking any non-owned Instagram accounts, yet.',
        searchAccountsInfo: 'Start typing and we’ll show you some accounts.',
        noAccountResults: 'No accounts found',
        searchPlaceholder: 'Search...',
        twitterAccountsTooltip:
            'This will return all owned posts and @mentions of a given X (Twitter) account.',
        ownedFbPageTooltip:
            'This is a page that my organization or client owns. For an Owned Channel to have the best possible data coverage, the Page Admin needs to authenticate.',
        nonOwnedFbPageTooltip:
            'This is a community or competitor owned page. In order to have the best possible data coverage you need as many Facebook users to authenticate as possible.',
        igbaTooltip:
            'Authenticate with Facebook using an account with a role on a Facebook page tied to an Instagram business account. For every account you can track up to 30 hashtags.',
        nonOwnedInstagramAccountTooltip:
            'This is a community or competitor owned business or creator account. You need to authenticate with Facebook first in order to track these accounts.',
        enterTwitterAccounts: 'Enter X (Twitter) Accounts',
        twitterUsernameValidationErrorTitle: 'Not a valid X (Twitter) username.',
        twitterUsernameValidationErrorDesc: 'Please check the username and try again.',
        twitterInputHelperText: 'Separate multiple accounts with a comma or space.',
        linkedinChannels: 'Owned LinkedIn Pages',
        linkedinChannelsTooltip: '[PLACEHOLDER] linkedinChannelsTooltip',
    },

    intro: {
        stepStatus: 'Step %{stepNum} of %{totalSteps}',
        next: 'Next',
        accept: 'Got it!',

        quickSearch: {
            welcomeTitle: 'Welcome to %{appName}!',
            welcomeDescription: `
            Use %{appName} to monitor brand mentions and conversations that matter to you most.
            Enter the name of a brand, campaign, or person to start your search.`,
            guidedMenuTitle: 'Try a guided search',
            guidedMenuDescription:
                'You can also choose a guided search option and we’ll help you refine the results.',
            searchHelpTitle: 'Search like a pro',
            searchHelpDescription: `You can use special characters and words - also known as advanced search operators - to narrow your search and get laser-focused results.

              Selecting "<strong>Need some help?</strong>" opens a list of operators with definitions and examples you can use in your search.`,
            helpCenterTitle: 'Quickly access help and resources',
            helpCenterDescription: `Access our Help Center here for specific answers or step-by-step guides.`,
        },

        quickSearchResults: {
            needGuidanceTitle: 'Need guidance?',
            needGuidanceDescription:
                'It looks like you aren’t using enough boolean operators. Would you like some guidance?',
            operatorsTitle: 'List of operators',
            operatorsDescription:
                'Try mixing some of these operators or go straight to the examples tab to use Insights to its fullest.',
            guidanceTitle: 'Guidance',
            guidanceDescription:
                'You can always see this view by clicking this arrow and selecting create effective searches.',
        },

        filters: {
            panelTitle: 'Filter options',
            panelDescription:
                'You can narrow down your search by using all of these filter options.',
        },

        savedSearchAlerts: {
            createAlertTitle: 'Create an alert',
            createAlertDescription:
                'Create an email alert for this saved search so you’re always up to date with mentions.',
        },

        savedSearchReports: {
            createReportTitle: 'Build a report',
            createReportDescription: 'Hootsuite provides Insights reports in Analytics.',
        },

        timezone: {
            selectTimezoneTitle: 'Timezones',
            selectTimezoneDescription:
                'You asked, we listened! Now you can pick a timezone for your searches.',
        },

        quickSearchFeedback: {
            feedbackTitle: 'Give feedback',
            feedbackDescription: 'We’ve noticed you are using our new guided mode feature.',
        },

        multipleSearch: {
            addSearchTitle: 'You can now analyze multiple searches in one place!',
            addSearchDescription:
                "Add up to %{maxMultipleSearches} saved searches to identify your brand's share of voice, analyze top leaders in your industry, and much more.",
            gotIt: 'Got it',

        },
        dataManagement: {
            title: 'LinkedIn data',
            desc: 'You can add owned LinkedIn data as a source in the Data Management area.',
        },
        dataManagementExplore: {
            title: 'Explore your data',
            desc: 'Select the magnifying glass for each source to explore the data brought in by that source. The icon will appear when you hover over each row in the table.',
        },
        linkedinSearch: {
            title: 'LinkedIn data',
            desc: 'Add a LinkedIn page to your search and compare it against other data sources.',
        },
        bulkActions: {
            title: 'Bulk actions',
            desc: 'You can now change sentiment, add a tag or delete multiple mentions at once',
        },
        funnelMessage: {
            title: `Don't lose your saved searches`,
            content: `Insights Powered by Brandwatch will no longer be available after your next renewal. You can preserve all your searches and data by moving to Brandwatch’s own social listening tool. Learn more about Brandwatch Listen and the rest of the Brandwatch social suite. `,
        },
    },

    feedback: {
        feedbackTitle: 'Is this helpful?',
        feedbackSent: 'Thank you for your feedback!',
    },

    sessionBlocked: {
        title: 'Page may be blocked by your browser settings',
        chrome: 'Your browser settings may be blocking your access to Insights. Please change your settings to allow cookies for Insights, and then try again.',
        learnChrome: 'Learn how to allow cookies in Google Chrome',
        safari: 'Your browser settings may be blocking your access to Insights. Please uncheck <b>Prevent cross-site tracking</b> in your browser Preferences to allow cross-site tracking from Insights, and then try again.',
        learnSafari: 'Learn how to allow cross-site tracking in Safari',
        firefox:
            'Your browser settings may be blocking your access to Insights. Please change your settings to enable cookies for Insights, and then try again.',
        learnfirefox: 'Learn how to enable cookies for specific sites in Mozilla Firefox',
        edge: 'Your browser settings may be blocking your access to Insights. Please change your settings to allow cookies for Insights, and then try again.',
        learnEdge: 'Learn how to allow cookies in Microsoft Edge',
        contact:
            'Still having trouble viewing this page? Please contact your Customer Account Manager.',
    },
    benchmark: {
        tooltips: {
            currentValue: 'Current',
            previousValue: 'Previous',
        },
    },
    volumeHorizontalChart: {
        XaxisLegend: 'Total Mention Volume',
    },
    exportToPDF: {
        generating: 'Generating your PDF report...',
        success: 'Your PDF report was generated successfuly. Download will start soon...',
        error: 'There was a problem with the PDF export. Please try again.',
        errors: {
            noNetwork:
                'Sorry, there was a problem with the PDF export as we could not connect to the server. Please check your internet connection and try again',
            unknown:
                'Sorry, there was a problem with the PDF export, please try again. If the problem persists, please contact support.',
        },
        cardSelectLbl: 'Select cards to export',
        selectedCards: 'Selected cards: ',
        header: {
            brandwatch: 'Brandwatch Insights',
            cision: 'Cision Social Listening',
            falcon: 'Listen',
            hootsuite: 'Hootsuite Insights',
            poweredBy: 'powered by Brandwatch',
            insights: 'insights',
            multipleSearch: 'Compared to: %{other}',
        },
        annotations: {
            notes: 'notes',
            clear: 'Clear',
            clearAccessible: 'Clear annotations for %{cardTitle}',
            clearSummaryAccessible: 'Clear summary',
            summaryLabel: 'Add a summary to the report (optional)',
            notesLabel: 'Notes on the %{cardTitle} results (optional)',
            maxLengthLabel: 'max %{maxLength} characters',
            annotationPlaceholder: 'Add optional context and insights for your report recipients.',
            modalTitle: 'Export results as PDF',
            clearAll: 'Clear all notes',
            summary: 'Summary',
            summaryPlaceholder: 'Add an optional summary for your report.',
            cardsSelector: 'Select the metrics you want to export',
            byDefault: 'By default, all metrics will be exported.',
            warning: {
                notes: 'Notes have a limit of %{max} characters',
                summary: 'Summary has a limit of %{max} characters',
            },
        },
        goBack: 'Cancel',
        save: 'Export PDF',
        cancel: 'Cancel',
    },
    exportMentionsToCSV: {
        generating: 'Your CSV is being generated...',
    },
    exportMentionsToPDF: {
        generating: 'Your PDF is being generated...',
        success: 'Your PDF was generated successfuly. Download will start soon...',
        error: 'There was a problem with the PDF export. Please try again.',
        multipleSearchMentions: `Compared to: %{other}  
Mentions`,
    },
    exportMentionsDialog: {
        title: 'Export Mentions',
        format: 'Format',
        formats: {
            CSV: 'CSV',
            JSON: 'JSON',
            PDF: 'PDF',
        },
        num: 'How many mentions do you want to export?',
        numInfo:
            'You can export a maximum %{max} mentions at a time, up to your remaining daily export allowance',
        exportBtn: 'Export',

        allowance: {
            title: 'Daily mentions export allowance',
            copy: 'This is the maximum number of mentions you are able to export each day, either in PDF or CSV format',
        },
    },
    reports: {
        title: 'Scheduled Reports',
        tableLbL: 'Reports',
        header: {
            name: 'Name',
            queryName: 'Saved Search',
            timespan: 'Frequency',
            nextRunAt: 'Next delivery',
            active: 'Status',
            createdAt: 'Created',
        },
        actions: {
            edit: 'Edit',
            pause: 'Pause',
            resume: 'Resume',
            delete: 'Delete',
        },
        actionError: 'There was an error processing your action',
        reportDeleted: 'The scheduled report "%{name}" has been deleted.',
        reportDeletedError: 'There was an error deleting your report.',
        reportEdited: 'The scheduled report "%{name}" has been edited.',
        reportEditionError: 'There was an error editing your schedule.',
        editReportModal: {
            reportName: 'Report name',
            reportNamePlaceholder: 'Please enter a report name...',
            reportNameError: 'Please enter a name for your report',
            schedulePickerTitle: 'Deliver',
            scheduleSummary:
                'Report delivered <strong>%{timespanLabel}</strong> at <strong>%{hours}:%{minutes}</strong> (%{timezone})',
            timePickerAt: 'at',
            startDateCheckbox: 'Specify start date',
            startDatePickerTitle: 'Start date',
            reportExample: 'See an example report',
            cancelSchedule: 'Cancel',
            createScheduleAcceptButton: 'Schedule',
            createScheduleTitle: 'Schedule a new report',
            editScheduleAcceptButton: 'Edit',
            editScheduleTitle: 'Edit Scheduled Report',
            selectRecipients: {
                helperText: 'Separate email addresses with spaces or commas.',
                invalidEmailErrorDesc:
                    "One or more or the email address you've added are invalid. Please fix them to schedule a report.",
                invalidEmailErrorItem: 'This email address is invalid.',
                invalidEmailErrorTitle: 'Validation error',
                label: 'Deliver report to:',
                missingEmailErrorDesc: 'Please add a recipient to schedule a report.',
                missingEmailErrorTitle: 'No recipients added',
            },
        },
        status: {
            active: 'Active',
            paused: 'Paused',
            loading: 'Processing',
        },
        timespanLabels: {
            P1D: 'daily',
            P7D: 'weekly',
            P14D: 'biweekly',
            P1M: 'monthly',
            P3M: 'quarterly',
        },
    },
    alertInvite: {
        success: 'Thank you, your alert preferences have been saved.',
        error1: 'There was an error saving your alert preference.',
        error2: 'Please contact your account manager.',
        actionDesc: 'You can close this window or open',
        actionLink: 'Hootsuite Insights powered by Brandwatch.',
    },
    male: 'Male',
    female: 'Female',
    pageTypes: {
        twitter: 'X (Twitter)',
        facebook: 'Facebook (tracked)',
        facebook_public: 'Facebook (public)',
        instagram: 'Instagram',
        linkedin: 'LinkedIn',
        tumblr: 'Tumblr',
        forum: 'Forums',
        youtube: 'Youtube',
        reddit: 'Reddit',
        news: 'News',
        blog: 'Blogs',
        review: 'Reviews',
        qq: 'QQ',
    },
    sentiments: {
        positive: 'Positive',
        neutral: 'Neutral',
        negative: 'Negative',
    },
    emotions: {
        anger: 'Anger',
        disgust: 'Disgust',
        fear: 'Fear',
        joy: 'Joy',
        sadness: 'Sadness',
        surprise: 'Surprise',
    },
    chipInput: {
        limitLabel: '%{num} values left',
    },
    loading: 'Loading, please wait...',
    raw: '%{value}',
    dialog: {
        close: 'close',
    },
    multipleOptionsPopover: {
        label: 'Add or remove saved searches', //TODO needs to be configurable
        searchBoxLbl: 'Filter options by name', //TODO needs to be configurable
        searchBoxPlaceholder: 'Search',
        availableOptionsStatus: '%{num} options available', //TODO needs to be configurable
        filterResultsStatus: '%{num} matching options found', //TODO needs to be configurable
        noResults: 'No results found',
        noResultsHint: 'Check for mispellings',
        selectableLimitHint: 'You may select up to %{num} options',
        optionsRemaining: '%{num} left',
        clearAll: 'Clear All',
        cancel: 'Cancel',
        save: 'Save',
    },
    linkedinChannels: {
        multipleOptionsPopoverTitle: 'Add or remove LinkedIn pages from this search',
        buttonLbl: 'Manage LinkedIn pages',
        popover: {
            title: 'LinkedIn Pages',
            noResults: "We couldn't find any active LinkedIn pages in your team.",
            addChannel: 'Add LinkedIn Page',
            info: 'Single and multi-searches save your Pinned and Hidden selections separately',
        },
        saveSearchDialog: {
            title: 'LinkedIn Pages',
        },
        listItemNote: 'LinkedIn Page',
    },
    multipleSearch: {
        addSearch: 'add saved search',
        popover: {
            dialogTitle: 'Add or remove saved searches',
            title: 'Saved searches',
            disabledOptionTooltip: 'You can add a maximum of %{maxOptions} saved searches',
            info: 'Single and multi-searches save your Pinned and Hidden selections separately',
        },
        saveQueryError: 'There was an error saving your settings',
        editSearchTooltip: 'To edit "%{name}", remove any additional searches',
        alertsButtonTooltip: "You can't create an Alert for multiple searches at once.",
        reportsButtonTooltip: "You can't build a Report for multiple searches at once.",
        hiddenChipsMsgLong: '+%{num} more',
    },
    horizontalOverflow: {
        defaultMsg: '+%{num}',
    },
    savedSearchNotFound: 'Oops! There was a problem loading your saved search.',
    exportCard: {
        exportAs: 'Export as',
        exportComponent: 'Export component',
        buttonLabel: 'Export',
        png: '.png',
        jpg: '.jpg',
        pdf: '.pdf',
        svg: '.svg',
        csv: '.csv',
        generating: 'Generating your %{format} ...',
        volume: '%{queryName} Mentions Volume',
        uniqueAuthorPercent: 'Unique authors %',
        uniqueAuthor: 'Unique authors',
        header: {
            brandwatch: 'Brandwatch Insights',
            cision: 'Cision Social Listening',
            falcon: 'Listen',
            hootsuite: 'Hootsuite Insights',
            poweredBy: 'powered by Brandwatch',
            insights: 'insights',
        },
        totalVolume: {
            current: '%{queryName} Current Period',
            previous: '%{queryName} Previous Period',
            metric: 'Metric',
            change: '% change',
        },
        heatmap: {
            dayAndHour: 'Day & Hour',
        },
        wordCloud: {
            label: 'Topic Name',
            topicType: 'Type',
            sentimentScore: 'Sentiment Score',
            female: 'Female',
            male: 'Male',
            trending: 'Trending',
        },
        benchmark: {
            currentVolume: 'Current Period %{queryName} Volume',
            previousVolume: 'Previous Period %{queryName} Volume',
            change: '% Change',
        },
        topSites: {
            site: 'Site',
        },
        sentimentVolume: {
            sentiment: 'Sentiment',
        },
        emotionVolume: {
            emotion: 'Emotion',
            anger: 'Anger',
            disgust: 'Disgust',
            fear: 'Fear',
            joy: 'Joy',
            sadness: 'Sadness',
            surprise: 'Surprise',
        },
        topLanguages: {
            name: 'Language',
        },
        topInterests: {
            name: 'Interest',
        },
        topHashtags: {
            name: 'Hashtag',
            impressions: 'X (Twitter) Impressions',
            reachEstimate: 'Reach',
        },
        sentimentHistory: {
            positive: 'Positive Mention Volume',
            neutral: 'Neutral Mention Volume',
            negative: 'Negative Mention Volume',
        },
        emotionHistory: {
            anger: 'Anger Mention Volume',
            disgust: 'Disgust Mention Volume',
            fear: 'Fear Mention Volume',
            joy: 'Joy Mention Volume',
            sadness: 'Sadness Mention Volume',
            surprise: 'Surprise Mention Volume',
        },
        gender: {
            gender: 'Gender',
        },
        geography: {
            country: 'Country',
        },
        topAuthors: {
            authorName: 'Author name',
            id: 'Author id',
            countryName: 'Author country',
            followers: 'X (Twitter) Followers',
            reach: 'Reach',
        },
        topSharedURLs: {
            label: 'Top stories',
        },
        netSentiment: {
            value: '%{queryName} Net Sentiment Value',
        },
    },
    newBadge: 'new',
    chipMultipleSelect: {
        label: 'Select items',
        chipsLbl: 'Selected items: ',
        menuLbl: 'Select items',
        menuDesc: 'Check items in the list below that you want to select',
    },
    autocomplete: {
        noOptions: {
            defaultTitle: 'No results found',
            defaultDesc: 'Check for spellings',
        },
        languages: {
            loadingError: 'There was a problem loading languages. Please try again later and if the issue persist, contact support.'
        },
        locations: {
            loadingError: 'There was a problem loading locations. Please try again later and if the issue persist, contact support.'
        },
        socialPanels: {
            loadingError: 'There was a problem loading social panels. Please try again later and if the issue persist, contact support.'
        },
    },
    emailAlerts: {
        title: 'Alerts',
    },
    checkboxList: {
        noResultsTitle: 'No results found',
        noResultsDesc: 'Check your spelling',
    },
    targetedData: {
        error: {
            loadIGBAs:
                'An error occurred while loading your Instagram data sources. If this problem persists, please contact support',
            loadIGHashtags:
                'An error occurred while loading your Instagram data sources. If this problem persists, please contact support',
            loadFBPages:
                'An error occurred while loading your Facebook data sources. If this problem persists, please contact support',
            loadLinkedinChannels:
                'An error occurred while loading your LinkedIn data sources. If this problem persists, please contact support',
        },
    },
    dataTable: {
        noResultsTitle: 'No results found',
        noResultsDesc: 'Check your spelling',
        noResultsCta: 'Clear search',
        errorTitle: 'error',
        actionLabel: 'Actions',
        filterPlaceholder: 'Search...',
    },
    selectField: {
        noOptionsFound: 'No options found',
    },
    dateRangePicker: {
        isToday: ' (today)',
        nextMonth: 'View next month',
        prevMonth: 'View previous month',
    },
    sentimentMenu: {
        label: 'Edit mention sentiment',
        description:
            'Please select the sentiment that you believe best describes the content of this mention',
    },
    emotionMenu: {
        label: 'Edit mention emotion(s)',
        description:
            'Please select the emotions that you believe best describes the content of this mention',
    },
    locationMenu: {
        label: 'Edit mention location',
        description: 'Use the searchbox to select a location that best describes this mention',
        findLocations: 'Type to find locations',
        locationPlaceholder: 'Search Location',
    },
    benchmarkSubtitle: {
        subtitle: 'Benchmarked for %{start_date} against data from %{end_date}',
        xDays: '%{days} days',
        day: 'day',
        lastXDays: 'the last %{days} days',
        lastDay: 'the last day',
        today: 'today',
        yesterday: 'yesterday',
        previous: 'previous',
    },
    include: 'Include',
    exclude: 'Exclude',
    savedSearchesList: {
        tableLbL: 'Saved searches',
        noSearchesAndNoQuotaLeft:
            "Your organisation has %{num} saved searches spread across all teams within the organisation. If you cannot see any searches, it's because you are not a member of the teams in which those searches exist.",
    },
    sortableTable: {
        defaultTableLbL: 'Data table',
        tableLbl: '%{label}, Column headers with buttons are sortable',
        sortOrderChangedToAsc: 'table sort order changed to column "%{column}" ascending',
        sortOrderChangedToDesc: 'table sort order changed to column "%{column}" descending',

        sortedColumnAsc: ', sorted (ascending)',
        sortedColumnDesc: ', sorted (descending)',
    },
    managedTable: {
        sortableTableLbl: 'column headers with buttons are sortable',
        rowClickableLbl: 'rows can be clicked to select',
        caption: '%{name} table.',

        loadingMsg: 'Loading %{caption}',
        loadedMsg: 'Loaded %{caption}',
    },
    mangedTableGrid: {
        keyboardInstructions: 'use cursor keys to move between cells',
    },
    paginationButtons: {
        nextLbl: 'next page',
        prevLbl: 'previous page',
        a11yPageDesc: 'page %{page} of %{pages}',
    },
    queryEditor: {
        editorLbl: 'Search editor, Press Alt + F1 for search authoring help',
    },
    insightsContainer: {
        results: {
            title: 'Search results',
            loading: 'Loading search results...',
        },
    },
    copyField: {
        defaultAriaCopiedAnnounce: 'Copied to clipboard',
    },
    addOrCreateTag: {
        comboboxLbl: 'Find or create a tag',
        placeholder: 'Search Tags',
        create: 'Create new tag "%{tagName}"',
        add: 'Add tag',
        alreadyAdded: 'Tag already added',
        pending: 'Updating tags, please wait',
    },
    snackBar: {
        dismissLbl: 'Dismiss notification',
    },
    queryHelpPanel: {
        intro: 'Please note the capitalization and use of punctuation in the below examples, these are required. Screen-reader users may want to adjust their verbosity settings.',
        operators: {
            heading: 'Operators',
        },
        examples: {
            heading: 'Examples',
        },
    },
    aiChip: {
        a11yLabel: 'powered by AI',
    },
    irisConversationInsights: {
        heading: 'Iris conversation insights',
        openCTA: 'Summarize mentions',
        openCTAA11y: '',
        closeCTA: 'Hide',
        closeCTAA11y: 'hide mentions summary',
        infoHeading: 'What are conversation insights?',
        infoCopy: `This summary has been created by OpenAI based on top themes from recent mentions. Iris is best used as an assistant to your work. Please check that the insights it provides are accurate before you continue.`,
        infoHelpCTA: `learn more about conversation insights in the help center`,
        infoDismissTooltip: 'Dismiss',
        infoDismissA11y: 'Dismiss Iris conversation insights info',
        copyToclipboard: 'Copy to clipboard',
        error: {
            title: 'Loading failed',
            description: 'We’re unable to summarize mentions.',
            retryDesc: 'Retry loading conversation insights',
        },
        
    },
    copyToClipboard: {
        label: 'Copy to clipboard',
        copied: 'Copied',
        failed: 'Copy failed',
    },
    notification: {
        retryLbl: 'retry',
    },
    sparkline: {
        trend: {
            up: 'upwards',
            down: 'downwards',
            same: 'stable',
        },
    },
};

export default phrases;
