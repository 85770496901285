import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({colors, typgrphy, v2}: any) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
        marginTop: 4,
    },
    iconWrapper: {
        height: 40,
        width: 40,
        borderRadius: '50%',
        backgroundColor: v2 ? 'transparent' : 'rgb(249, 191, 191)',
        textAlign: 'center',
        lineHeight: '36px',
        margin: '10px 10px 10px 0',
    },
    icon: {
        position: 'relative',
        top: 4,
        fill: v2 ? colors.error : 'rgb(230, 0, 0)',
        width: 24,
        height: 24,
    },
    title: {
        fontWeight: v2 ? typgrphy.bodyStrong.fontWeight : '700',
        color: v2 ? colors.textDefault : 'rgb(47, 54, 56)',
        fontSize: v2 ? typgrphy.bodyStrong.fontSize : 14,
        marginTop: '0',
        lineHeight: '1.2',
        marginBottom: 4,
    },
    desc: {
        color: v2 ? colors.textDefault : 'rgb(47, 54, 56)',
        fontSize: v2 ? typgrphy.bodyNormal.fontSize : '14px',
        fontWeight: v2 ? typgrphy.bodyNormal.fontWeight : 'inherit',
    },
}));
