import {T} from 'hsi/i18n';

export type ApiError = {error: true; status: number; body: any}; // error body returned by http class is any
type MentionAPIError = {
    httpCode: number | undefined;
    errors: {
        code: number | undefined;
        message: string;
    }[];
    message: string;
};

export function normaliseAPIError(error?: ApiError, msgLangKey?: string): MentionAPIError {
    if (!error || !error.status || !error.body) {
        return {
            httpCode: undefined,
            errors: [
                {
                    code: -1,
                    message: 'Unknown',
                },
            ],
            message: T(`${msgLangKey}.unknown`),
        };
    }

    if (error?.body?.error) {
        return {
            httpCode: error.status,
            errors: [
                {
                    code: error.body.error,
                    message: error.body.error_description,
                },
            ],
            message: T(`${msgLangKey}.unknown`),
        };
    }

    const errors = error?.body?.errors?.map(({code, message}: any) => ({
        code,
        message,
    })) ?? [{code: -2, message: 'Unable to connect to server'}];

    return {
        httpCode: error.body.errors ? error.status : undefined,
        errors,
        message:
            errors.length === 1 && errors[0].code === -2
                ? T(`${msgLangKey}.noNetwork`)
                : T(`${msgLangKey}.unknown`),
    };
}
