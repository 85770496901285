import mergeUniqueValues from "hsi/utils/array/mergeUniqueValues";

/**
 * Take the various query ids and returns the string to be used in the apiv2 url
 * @param queryId 
 * @param additionalQueries 
 * @param linkedinChannelIds 
 * @returns 
 */
export default function getQueryIdUrl(queryId: number, additionalQueries?: number[], linkedinChannelIds?: number[]): string {
    return mergeUniqueValues(queryId, additionalQueries, linkedinChannelIds).filter((val) => !!val).join('-');
}