// TODO: Convert to axios
// TODO: Add tests
import http from 'hsi/classes/Http';

const TOKEN_SERVICE_URL = '/facebookapi';

class FacebookTokenService {
    static async getInviteUrl(clientId: string, signal?: AbortSignal) {
        const {body} = await http.get(`${TOKEN_SERVICE_URL}/invite/path/${clientId}`, {signal});

        return body;
    }

    static async storeUserToken(clientId: string, accessToken: string, facebookAppId: string) {
        const {body} = await http.post(`${TOKEN_SERVICE_URL}/clients/${clientId}/usertoken`, {
            accessToken,
            facebookAppId,
        });

        return body;
    }
}

export default FacebookTokenService;
