import makeStyles from '@mui/styles/makeStyles';
import {isV2, Theme} from 'hsi/types/theme';

const styles = (theme: Theme) => {
    const v2 = isV2(theme);

    return {
        root: {
            display: 'flex',
            alignItems: 'flex-start',
            gap: v2 ? '0' : '5px',
        },

        buttonsWrapper: {
            margin: v2 ? 0 : '5px',
            display: 'flex',
            alignItems: 'flex-start',
            gap: v2 ? '0' : '5px',
            '@media print': {
                display: 'none',
            },
            '.printMedia &': {
                display: 'none',
            },
        },

        header: {
            display: 'flex',
            flexFlow: 'row wrap',
            alignItems: 'center',
            flex: '1 1 100%',
            gap: '0 5px',
        },

        title: {
            flex: '0 0 auto',
            maxWidth: '100%',
            marginTop: v2 ? 0 : 12,
            marginBottom: 0,
            fontSize: 'inherit',

            '&.hasBreakdown': {
                marginTop: v2 ? undefined : 6,
                lineHeight: v2 ? undefined : '33px',
            },
        },

        breakdown: {
            ...theme.mixin.blankButton,
            fontSize: 'inherit',
            fontWeight: 'inherit',
            lineHeight: v2 ? undefined : '33px',
            flex: '0 0 auto',
            color: v2 ? theme.colors.textHyperlink : theme.colors.primaryBlue100,
            borderRadius: '50px',
            backgroundColor: v2 ? theme.colors.white : theme.colors.primaryBlue20,
            padding: v2 ? '0 2px' : '0 28px',
            cursor: 'pointer',
            outline: 'none',
            margin: v2 ? 0 : '5px 0',

            '&:focus': {
                outline: `3pt solid ${theme.colors.secondaryOrange100}`,
            },

            '&[aria-disabled="true"]': {
                cursor: 'default',
                color: v2 ? theme.colors.textDisabled : theme.colors.primaryBlue40,
                backgroundColor: v2 ? undefined : theme.colors.primaryBlue10,
            },

            '@media print': {
                color: v2 ? theme.colors.textDefault : theme.colors.darkGrey100,
                backgroundColor: v2 ? undefined : 'transparent',
                textTransform: 'lowercase',
                fontWeight: v2 ? theme.fonts.headtitleStrong.fontWeight : 'inherit',
                marginTop: '0',
                padding: 0,
                fontFamily: theme.font.name,
            },
            '.printMedia &': {
                color: v2 ? theme.colors.textDefault : theme.colors.darkGrey100,
                backgroundColor: v2 ? undefined : 'transparent',
                textTransform: 'lowercase',
                fontWeight: v2 ? theme.fonts.headtitleStrong.fontWeight : 'inherit',
                marginTop: '0   ',
                padding: 0,
                fontFamily: theme.font.name,
            },
        },

        breakdownDropdownIcon: {
            fill: 'currentColor',
            transformOrigin: 'center',
            verticalAlign: 'middle',
            position: 'relative' as any,
            top: '-1px',
            marginLeft: v2 ? '6px' : '10px',
            width: '15px',
            height: '15px',

            '@media print': {
                display: 'none',
            },
            '.printMedia &': {
                display: 'none',
            },
        },

        config: {
            flex: '0 0 auto',

            ...(v2
                ? {
                      padding: 3,
                  }
                : undefined),

            ...showOnHoverOrFocus(),

            '& svg': {
                width: v2 ? 20 : 17,
                height: v2 ? 20 : 17,
            },
        },

        tooltip: {
            flex: '0 0 auto',

            ...showOnHoverOrFocus(),
        },

        //Needed to use as combined class
        formatIcon: {
            backgroundColor: theme.colors.primaryBlue100,
            borderRadius: '2px',
            color: '#FFF',
            display: 'inline-block',
            fontWeight: 700,
            fontSize: '9px',
            height: '15px',
            marginRight: '8px',
            textAlign: 'center' as any, //Not sure why this is necessary, but without it TS complains
            textTransform: 'uppercase' as any, //Not sure why this is necessary, but without it TS complains
            width: '26px',
            marginTop: '1px',
        },
        entry: {
            display: 'flex',
            alignItems: 'center',
        },

        hasBreakdown: {},
        isPointerIn: {},
        isFocusIn: {},
        disabled: {},
    };
};

export default makeStyles(styles);

const showOnHoverOrFocus = () => {
    return {
        opacity: 0,

        '$isPointerIn &, $isFocusIn &': {
            opacity: 1,
        },
    };
};
