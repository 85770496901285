import cn from 'classnames';

import Button from 'hsi/components/Button';
import DialogContainer from 'hsi/containers/DataManagement/AddDataSourceDialog/DialogContainer';
import IconRouter from 'hsi/components/IconRouter';

import useStyles from './styles';

type InfoProps = {
    acceptText: string;
    invalidExamples: {columnA: string; columnB: string}[];
    invalidTitle: string;
    isOpen: boolean;
    onAccept: () => void;
    title: string;
    validTitle: string;
    validExamples: string[];
};

const Info = ({
    acceptText,
    invalidExamples,
    invalidTitle,
    isOpen,
    onAccept,
    title,
    validExamples,
    validTitle,
}: InfoProps) => {
    const classes = useStyles();

    if (!isOpen) return null;

    return (
        <DialogContainer
            actions={
                <Button onClick={onAccept} priority="cta">
                    {acceptText}
                </Button>
            }
            className={classes.dialog}
            onClose={onAccept}
            title={title}
        >
            <div>
                <h3>{validTitle}</h3>
                <div className={classes.infoSection}>
                    <IconRouter
                        className={cn(classes.infoIcon, classes.infoIconValid)}
                        name="success"
                    />
                    <ul className={classes.infoTable}>
                        {validExamples.map((example) => (
                            <li className={classes.infoRow} key={example}>
                                <span className={classes.infoCell}>{example}</span>
                            </li>
                        ))}
                    </ul>
                </div>
                <h3>{invalidTitle}</h3>
                <div className={classes.infoSection}>
                    <IconRouter
                        className={cn(classes.infoIcon, classes.infoIconInvalid)}
                        name="c-warning"
                    />
                    <div className={classes.infoTableWrapper}>
                        <table className={classes.infoTable}>
                            <tbody>
                                {invalidExamples.map(({columnA, columnB}) => (
                                    <tr className={classes.infoRow} key={`${columnA}-${columnB}`}>
                                        <td className={classes.infoCell}>{columnA}</td>
                                        <td className={classes.infoCell}>{columnB}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </DialogContainer>
    );
};

export default Info;
