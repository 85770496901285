import cn from 'classnames';

import IconRouter from 'hsi/components/IconRouter';

import useStyles from './styles';

import {T} from 'hsi/i18n';

const LoadError = () => {
    const classes = useStyles();

    return (
        <>
            <IconRouter className={cn(classes.pageImg, classes.pageErrorIcon)} name="warning" />
            <h4 className={classes.pageTitle}>{T('dataManagement.loadError.title')}</h4>
            <p className={classes.pageDesc}>{T('dataManagement.loadError.desc')}</p>
        </>
    );
};

export default LoadError;
