const styles = {
    colorSample: {
        display: 'inline-flex',
        width: 12,
        height: 12,
        marginRight: 8,
        borderRadius: '50%',
        verticalAlign: 'middle',
        position: 'relative',
        top: -1,
    },
};

export default styles;
