import { isV2 } from 'hsi/types/theme';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => {
    const v2 = isV2(theme);

    return {
        orderMenuRoot: {
            position: v2 ? 'relative' : undefined,
            paddingRight: v2 ? '15px' : undefined,
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
        },
        orderMenuBtn: {
            order: 1,
            padding: v2 ? 0 : '9px',
            position: v2 ? 'absolute' : 'relative',
            top: v2 ? 0 : undefined,
            right: v2 ? 0 : undefined,
            bottom: v2 ? 0 : undefined,
            left: v2 ? 0 : undefined,
            zIndex: v2 ? 1 : undefined,
            cursor: 'pointer',
            borderRadius: v2 ? '1px' : undefined,
            width: v2 ? '100%' : 'auto',

            '&:focus': {
                background: v2 ? 'none' : undefined,
            },

            '& *': {
                display: v2 ? 'none' : undefined,
            },
        },
        orderMenuIcon: {
            display: v2 ? 'none' : undefined,
        },
        orderMenuText: theme.v2
            ? {
                  position: 'relative',
                  ...theme.fonts.bodyStrong,
                  fontFamily: theme.font.name,
                  margin: '0',

                  '&:after': {
                      ...theme.mixin.downArrow,
                  },
              }
            : {
                  order: 2,
                  fontSize: '16px',
                  fontFamily: theme.font.name,
                  whiteSpace: 'nowrap',
                  margin: '0 8px', //'6px 8px',
                  fontWeight: theme.font.regular,
              },
        orderMenuLabel: {
            fontWeight: v2 ? theme.font.regular : theme.font.bold,

            '&:after': {
                content: v2 ? '":"' : undefined,
            },
        },
    };
});
