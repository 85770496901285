import React, {useMemo} from 'react';

//Components
import ConditionalWrap from 'hsi/components/ConditionalWrap';
import PlatformIcon from 'hsi/components/PlatformIcons';

//Other
import {T} from 'hsi/i18n';
import {MentionDataType} from 'hsi/types/mentions';
import stopPropagation from 'hsi/utils/html/stopPropagation';
import {ReactNode} from 'react-markdown';
import type {Platform} from 'hsi/utils/platform';

//Helpers
export function iconFromMentionData(mentionData: MentionDataType) {
    return (
        <PlatformIcon
            name={mentionData.site}
            fallback={mentionData.contentCategory?.toLowerCase?.() as Platform}
            aria-label={T('mentionComponent.platform', {name: mentionData.pageTypeName})}
        />
    );
}

//Hooks
export function useAuthorDetails(
    mentionData: MentionDataType | undefined | null,
    classes: {link?: string},
    noLinks: boolean = false,
) {
    return useMemo(() => {
        if (!mentionData) {
            return {name: null, handle: null, rawName: null};
        }

        const wrapAuthorUrl = (children: ReactNode) =>
            !noLinks && mentionData.authorUrl ? (
                <a
                    className={classes.link}
                    href={mentionData.authorUrl}
                    onClick={stopPropagation}
                    rel="noreferrer"
                    target="_blank"
                >
                    {children}
                </a>
            ) : (
                children
            );

        if (mentionData.fullname) {
            return {
                name: mentionData.fullname,
                handle: mentionData.username ? (
                    <ConditionalWrap wrap={wrapAuthorUrl}>{mentionData.username}</ConditionalWrap>
                ) : null,
                rawName: mentionData.fullname,
            };
        } else {
            // Some mentions have no username so we display the domain which is always available.
            const name = mentionData.username || mentionData.domain;
            return {
                name: <ConditionalWrap wrap={wrapAuthorUrl}>{name}</ConditionalWrap>,
                handle: null,
                rawName: name,
            };
        }
    }, [mentionData, classes, noLinks]);
}
