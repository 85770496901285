import {languages} from 'monaco-editor';

const KEY_OPERATORS = ['AND', 'OR', 'NOT', '-'];
const COLON_OPERATORS = [
    'author:',
    'authorFollowers:',
    'authorGender:',
    'authorVerified:',
    'blogName:',
    'brandIds:',
    'city:',
    'continent:',
    'country:',
    'entityId:',
    'entity:',
    'engagementType:',
    'engagingWith:',
    'engagingWithGuid:',
    'imageType:',
    'itemReview:',
    'language:',
    'latitude:',
    'links:',
    'location:',
    'longitude:',
    'minuteOfDay:',
    'objects:',
    'parentBlogName:',
    'parentPostId:',
    'publisherSubType:',
    'pubType:',
    'rating:',
    'rootBlogName:',
    'rootPostId:',
    'site:',
    'taggedUsers:',
    'tags:',
    'title:',
    'topLevelDomain:',
    'url:',
    'weblogTitle:',
    'region:',
];

const HIGHLIGHTING_RULES: Array<
    languages.IShortMonarchLanguageRule1 | languages.IShortMonarchLanguageRule2
> = [
    [/(\()(#\p{L}+)(\))/u, ['delimiter.parenthesis', 'hashtag', 'delimiter.parenthesis']],
    [/(\()(#\p{L}+)/u, ['delimiter.parenthesis', 'hashtag']],
    [/(\()(@\p{L}+)(\))/u, ['delimiter.parenthesis', '@', 'delimiter.parenthesis']],
    [/(\()(@\p{L}+)/u, ['delimiter.parenthesis', '@']],
    [/NEAR\/[0-9]+(f|F)?\b/, 'keyword.operator.near'],
    [/[()]/, '@brackets'],
    [
        /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
        'link',
    ],
    [/[A-Za-z_$][\w$]*:/, {cases: {'@operators': 'operator'}}],
    [/[A-Za-z_$_-][\w$]*/, {cases: {'@keywords': 'keyword'}}],
    [/<<</, 'comment', '@comment'],
    [/[?]/, 'constant.character.interro'],
    [/~[0-9]+\b/, 'constant.character.proximity'],
    [/"([^"\\]|\\.)*$/, 'string.invalid'], // non-teminated string
    [/"/, {token: 'string.quote', bracket: '@open', next: '@string'}],
    [/ #\p{L}+/u, 'hashtag'],
    [/^#\p{L}+/u, 'hashtag'],
    [/ @\p{L}+/u, '@'],
    [/^@\p{L}+/u, '@'],
    [/^\*/, ''],
    [/\s\*/, ''],
    [/\*/, 'constant.character.asterisk'],
    [/\{[A-Za-zÀ-ÿ]{1,5}\}/, 'exactspelling'],
];

export const syntaxHighlightingConfig: languages.IMonarchLanguage = {
    operators: COLON_OPERATORS,
    keywords: KEY_OPERATORS,
    brackets: [{open: '(', close: ')', token: 'delimiter.parenthesis'}],
    tokenizer: {
        root: HIGHLIGHTING_RULES,
        comment: [
            [/>>>/, 'comment', '@pop'],
            [/./, 'comment.content'],
        ],
        string: [
            [/[^\\"]+/, 'string'],
            [/\\./, 'string.escape.invalid'],
            [/"/, {token: 'string.quote', bracket: '@close', next: '@pop'}],
        ],
    },
};

export const bracketMatchingConfig: languages.LanguageConfiguration = {
    autoClosingPairs: [
        {open: '(', close: ')'},
        {open: '{', close: '}'},
        {open: '[', close: ']'},
        {open: '<<<', close: '>>>'},
    ],
    brackets: [
        ['(', ')'],
        ['{', '}'],
        ['[', ']'],
    ],
};
