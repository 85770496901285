import {useMemo} from 'react';
import flatten from 'lodash/flatten';
import max from 'lodash/max';
import min from 'lodash/min';
import uniq from 'lodash/uniq';

import useConfig from 'hsi/hooks/useConfig';
import useDates from 'hsi/hooks/useDates';

const AXIS_DAY_FORMAT = 'MMM d';
const AXIS_MONTH_FORMAT = 'LLL yyyy';
const AXIS_HOUR_FORMAT = 'h a';
const DAY_INTERVAL = 24 * 60 * 60 * 1000;
const MIN_HOUR_INTERVAL = 3 * 1000 * 60 * 60;
const MONTH_INTERVAL = DAY_INTERVAL; //29 * 24 * 60 * 60 * 1000;
const PEAK_LABEL_MAP = 'ABCDEFGHIJKLMNOPQRST'.split('');
const X_AXIS_HOUR_MIN_TICK_WIDTH = 65;

const useLineChart = ({_series, categories, interval, peaks, width}) => {
    const {
        themeColors: {sparkline},
    } = useConfig();
    const dates = useDates();

    const dateMinInterval = useMemo(() => {
        switch (interval) {
            case 'hours':
                return MIN_HOUR_INTERVAL;
            case 'months':
                return MONTH_INTERVAL;
            default:
                return DAY_INTERVAL;
        }
    }, [interval]);

    const peaksAugmented = useMemo(() => {
        if (!peaks) return null;

        return peaks.map((peak, i) => {
            const category = (categories || []).find(({id}) => id === peak?.id);

            return {
                ...peak,
                fill: category ? category.color : sparkline,
                text: PEAK_LABEL_MAP[i],
                dateNumber: dates.getTimestamp(peak.highestVolumeDate) / dateMinInterval,
            };
        });
    }, [categories, dateMinInterval, dates, peaks, sparkline]);

    const series = useMemo(
        () =>
            (_series || []).map((point) => ({
                ...point,
                date: dates.getLocalDate(point.date),
                dateNumber: dates.getTimestamp(point.date) / dateMinInterval,
            })),
        [_series, dateMinInterval, dates],
    );

    const x = useMemo(() => {
        const all = series.map((point) => dates.getTimestamp(point.date));
        const minDateNumber = min(all);
        const maxDateNumber = max(all);

        const getTickOverride = () => {
            if (interval === 'hours') {
                const ticks = [];
                const result = [];

                let current = parseInt(minDateNumber / MIN_HOUR_INTERVAL) * MIN_HOUR_INTERVAL;

                while (current < maxDateNumber) {
                    if (current >= minDateNumber) {
                        const h = dates.getHours(current);
                        let priority;
                        if (h === 0) {
                            priority = 1;
                        } else if (h % 12 === 0) {
                            priority = 2;
                        } else if (h % 6 === 0) {
                            priority = 3;
                        } else if (h % 3 === 0) {
                            priority = 4;
                        } else {
                            priority = 5;
                        }
                        ticks.push({val: current, priority});
                    }
                    current += MIN_HOUR_INTERVAL;
                }

                for (let pr = 1; pr < 6; pr++) {
                    const prTicks = ticks.filter(({priority}) => priority === pr);
                    if (
                        width / (result.length + prTicks.length || 1) <
                        X_AXIS_HOUR_MIN_TICK_WIDTH
                    ) {
                        break;
                    }
                    prTicks.forEach(({val}) => result.push(val));
                }
                result.sort();

                return result.map((tick) => tick / dateMinInterval);
            } else if (interval === 'months') {
                const ticks = [];
                let now = dates.getLocalDate(minDateNumber);

                while (dates.getTimestamp(now) <= maxDateNumber) {
                    ticks.push(dates.getTimestamp(now));
                    now = dates.getFutureDate(now, {months: 1});
                }

                return ticks.map((tick) => tick / dateMinInterval);
            }
        };

        const axisDateFormat = (dateNumber) => {
            const date = dates.getLocalDate(dateNumber * dateMinInterval);

            if (interval === 'months') {
                return dates.formatTo(date, AXIS_MONTH_FORMAT);
            } else if (interval === 'days') {
                return dates.formatTo(date, AXIS_DAY_FORMAT);
            } else {
                return dates.formatTo(date, AXIS_HOUR_FORMAT);
            }
        };

        return {
            axisDateFormat,
            domain:
                minDateNumber && maxDateNumber
                    ? [minDateNumber / dateMinInterval, maxDateNumber / dateMinInterval]
                    : undefined,
            ticks: getTickOverride(),
        };
    }, [dateMinInterval, dates, interval, series, width]);

    const y = useMemo(() => {
        const all = flatten(
            (categories || []).map((category) => series.map((point) => point[category.id])),
        );
        const maxValue = max(all);
        const minValue = min(all);
        const axisPositiveMaxTicks = Math.max(Math.ceil(maxValue), 2) || undefined;
        const axisNegativeMaxTicks = Math.min(Math.floor(minValue), 2) || undefined;
        const minTicks = Math.max(Math.ceil(maxValue), 2) || undefined;

        // The if statement logic is just for netSentimentHistory
        const getDomain = () => {
            if (maxValue < 5 && minValue >= 0) {
                return [0, minTicks];
            } else if (minValue < 0) {
                return [axisNegativeMaxTicks, axisPositiveMaxTicks];
            } else {
                return [0, 'auto'];
            }
        };

        // This is just for netSentimentHistory
        const getTickOverride = () => {
            if (maxValue < 5 && minValue >= 0) {
                return [...Array(minTicks + 1).keys()];
            }

            if (minValue < 0) {
                return uniq(
                    [...Array(axisNegativeMaxTicks * -1 + 1).keys()]
                        .map((item) => item * -1)
                        .sort((a, b) => a - b)
                        .concat([...Array(axisPositiveMaxTicks + 1).keys()]),
                );
            }
        };

        return {
            domain: getDomain(),
            ticks: getTickOverride(),
        };
    }, [categories, series]);

    return {
        peaksAugmented,
        series,
        x,
        y,
    };
};

export default useLineChart;
