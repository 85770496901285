import React from 'react';
import Banner from 'hsi/components/Banner';

import tns from 'hsi/i18n/tns';
const t = tns('alertInvite');

const HandleAlertInvite = ({success, error}) => {
    const resText = success
        ? t('success')
        : `${t('error')} ${error ? '(' + JSON.stringify(error) + ')' : ''}`;
    const variant = success ? 'success' : 'warning';
    return <Banner variant={variant}>{resText}</Banner>;
};

export default HandleAlertInvite;
