
import useStyles from './styles';

// Components
import SiteFavicon from 'hsi/components/SiteFavicon';

function DomainHeader({name}: {name: string}) {
    const classes = useStyles();

    return <>
        <SiteFavicon domain={name} className={classes.favicon} aria-hidden />
        {name}
    </>
}

export function formatDomainHeader({name}: {name: string}) {
    return <DomainHeader name={name} />
};
