import baseConfig from 'hsi/configurations/_axiomy/config';
import _basePageTypes from 'hsi/configurations/cision/pageTypes';
import {AppConfigType} from 'hsi/types/config';

//Modify config here as required
const config: AppConfigType = {
    ...baseConfig,
    appName: 'Social Listening',
    links: {
        ...baseConfig.links,
        searchHelp: 'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290257964',
        helpCenter:
            'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/25747456436',
        feedbackLink:
            'https://docs.google.com/forms/d/e/1FAIpQLSdv1WhUkuxz0GaaiY4l7esyB-ObVLgs0_PAHh1suZ_DrnNGVg/viewform',
        alertsLearnMore:
            'https://cision.atlassian.net/plugins/servlet/servicedesk/customer/confluence/shim/spaces/CSM/pages/26290422212',

        //card info popup ctas
        dashboardInfoTotalVolumeCTA:
            'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290454704',
        dashboardInfoMentionsHistoryCTA:
            'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290454704',
        dashboardInfoHeatmapCTA:
            'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290454704',
        dashboardInfoWordCloudCTA:
            'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290454704',
        dashboardInfoBenchmarkCTA:
            'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290454704',
        dashboardInfoSentimentVolumeCTA:
            'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290454704',
        dashboardInfoSentimentHistoryCTA:
            'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290454704',
        dashboardInfoEmotionVolumeCTA:
            'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290454704',
        dashboardInfoEmotionHistoryCTA:
            'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290454704',
        dashboardInfoTopSharedURLsCTA:
            'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290454704',
        dashboardInfoTopSitesCTA:
            'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290454704',
        dashboardInfoTopAuthorsCTA:
            'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290454704',
        dashboardInfoTopHashtagsCTA:
            'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290454704',
        dashboardInfoTopInterestsCTA:
            'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290454704',
        dashboardInfoTopLanguagesCTA:
            'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290454704',
        dashboardInfoGenderCTA:
            'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290454704',
        dashboardInfoGeographyCTA:
            'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290454704',
        dashboardInfoTopicWheelCTA:
            'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290454704',
        dashboardInfoMentionsCTA:
            'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290454704',
        dashboardInfoNetSentimentCTA: undefined,
        dataManagementLearnMore: 'https://cision.atlassian.net/servicedesk/customer/portal/1019/article/26290421839',
    },
    showHelp: false,
    themeColors: {
        ...baseConfig.themeColors,
        colorScaleDefault: '#F0F0EF',
        primaryColor: '#007FA9',
        topicType: {
            hashtag: '#4CC1D4',
            place: '#895CA2',
            organisation: '#FD8126',
            person: '#D3CD0B',
            word: '#393A3A',
            phrase: '#8C4019',
        },
        gender: {male: '#3B79CC', female: '#A72E5F', unknown: '#878787'},
        heatmap: [
            'rgba(35, 158, 219, 0.2)',
            'rgba(35, 158, 219, 0.4)',
            'rgba(35, 158, 219, 0.6)',
            'rgba(35, 158, 219, 0.8)',
        ],
        sentiment: {positive: '#00C68D', negative: '#FF626B', neutral: '#BBB9C0'},
        volume: {low: '#C2C2C2', mid: '#5ACBDC', high: '#3B74B7'},
        trending: {low: '#C2C2C2', mid: '#F9B800', high: '#FF634A'},
        random: [
            '#F598B7',
            '#E35A7A',
            '#906BA7',
            '#90a015',
            '#55cee6',
            '#24adbd',
            '#c8c819',
            '#009E71',
            '#FFBE0A',
            '#9E6139',
            '#78909C',
        ],
        none: ['#393A3A'],
        default: ['#d3f1ff', '#94d2f1', '#0087ca', '#63c9fb', '#239edb'],
        emotion: {
            anger: '#e35a7a',
            disgust: '#906ba7',
            fear: '#f99131',
            joy: '#ffbe0b',
            sadness: '#6163a1',
            surprise: '#8ac539',
        },
        sparkline: '#007FA9',
        success: '#00BE96',
        error: '#FC4870',
        warning: '#DAAE45',
        choroplethMapColors: {
            render: ['#D3F1FF', '#94D2F1', '#63C9FB', '#239EDB'],
            hover: '#F99132',
            pressed: '#F99132',
        },
        heatmapColors: [
            'rgba(35, 158, 219, 0.2)',
            'rgba(35, 158, 219, 0.4)',
            'rgba(35, 158, 219, 0.6)',
            'rgba(35, 158, 219, 0.8)',
        ],
        queryColors: [
            '#834996',
            '#3ABBDF',
            '#FD7DA8',
            '#6E9500',
            '#E54369',
            '#F1EE4D',
            '#FF5E63',
            '#297DBD',
            '#8EF0CF',
            '#A33E3B',
        ],
    },
    _basePageTypes,
    editorConfig: {
        padding: {
            top: 12,
            bottom: 10,
        },
        fontSize: 14,
        lineHeight: 20,
        suggestFontSize: 14,
        suggestLineHeight: 20,
        fontFamily: "'Proxima Nova', sans-serif",
    },
    exportType: 'cision',
    lineChart: {
        leftPadding: -15,
        hasSideLabel: false,
        tickLine: true,
        axisLine: true,
        tickMargin: 11,
    },
} as const;

export default config;
