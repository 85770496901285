import makeStyles from '@mui/styles/makeStyles';

import {Theme} from 'hsi/types/theme';

export default makeStyles(({v2}: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0px 20px',
    },
    backButton: {
        marginRight: 'auto',
    },
    facebookButtons: {
        margin: '12px 0',
        width: '100%',
    },
    infoBanner: {
        marginTop: 24,
        marginBottom: 8,
    },
}));
