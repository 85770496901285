import React, {useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import capitalize from 'lodash/capitalize';

//Components
import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle from 'hsi/components/Card/CardTitle';
import HorizontalBarChart from 'hsi/components/HorizontalBarChart';
import InfoPopupContent from 'hsi/components/InfoPopupContent';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useElementSize from 'hsi/hooks/useElementSize';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useGetLoadData from '../useGetLoadData';

//Actions
import {mentionsDrillIn} from 'hsi/slices/mentions';

//Other
import useStyles from './styles';
import {T} from 'hsi/i18n';
const type = 'emotionVolume';

//The components
const EmotionVolume = React.forwardRef(({title, ...props}, ref) => {
    const classes = useStyles();
    const {
        links: {dashboardInfoSentimentHistoryCTA: popupCTA},
    } = useConfig();
    const [sizeRef, {height, width} = {}] = useElementSize(null, {width: true, height: true});
    const {trackWithSearchData} = useEventTrack();

    //Redux
    const dispatch = useDispatch();
    const {data, loaded, loading, error} = useSelector((state) => state.chart[type]);
    const isDrilledIn = useSelector((state) => state.mentions.drillInCard === type);

    //Calculated values
    const loadData = useGetLoadData(type);
    const hasData = useMemo(
        () => !!data?.map(({value}) => value).some((value) => value > 0),
        [data],
    );

    //Callbacks
    const drillIn = useCallback(
        (drillIn) => {
            const drillinValue = drillIn.id;
            const drillinFilter = {classifications: {[drillinValue]: true}};
            const label = capitalize(T(drillIn.name));
            trackWithSearchData('cardDrilledIn', {
                type,
                value: label,
            });
            dispatch(mentionsDrillIn(drillinFilter, type, null, label));
        },
        [dispatch, trackWithSearchData],
    );

    const content = useMemo(
        () => (
            <div className={classes.chart} ref={sizeRef}>
                <HorizontalBarChart
                    data={data}
                    height={height}
                    onClick={drillIn}
                    formatTootip={T}
                    width={width}
                />
            </div>
        ),
        [classes.chart, data, drillIn, height, sizeRef, width],
    );

    const popup = useMemo(
        () => (
            <InfoPopupContent
                copy={T(`cards.${type}.info.copy`)}
                ctaLabel={T('cards.infoCTALabel')}
                ctaUrl={popupCTA}
                title={T(`cards.${type}.info.title`)}
            />
        ),
        [popupCTA],
    );

    //Render
    return (
        <CardLoadState
            {...props}
            title={
                <CardTitle title={title} tooltipComponent={popup} type={type} hasData={hasData} />
            }
            data-testid={type}
            error={error}
            hasData={hasData}
            loading={loading}
            loaded={loaded}
            selected={isDrilledIn}
            loadData={loadData}
            type={type}
            ref={ref}
        >
            {content}
        </CardLoadState>
    );
});

EmotionVolume.propTypes = {
    props: PropTypes.shape({
        fadeOnVisible: PropTypes.func.isRequired,
        height: PropTypes.number.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onContentChanged: PropTypes.func.isRequired,
        showConfig: PropTypes.bool.isRequired,
    }),
};

EmotionVolume.displayName = 'EmotionVolume';

export default EmotionVolume;
