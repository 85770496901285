import {createContext, forwardRef, useCallback, useContext, useMemo, useState} from 'react';
import cn from 'classnames';
import MuiRadio, {RadioProps as MuiRadioProps} from '@mui/material/Radio';

//Other
import useStyles from './styles';

interface RadioProps extends MuiRadioProps {}

//The component
export default function Radio(props: RadioProps) {
    const classes = useStyles();

    return (
        <MuiRadio
            className={classes.root}
            disableRipple
            checkedIcon={<span className={cn(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

type RadioContextType = {
    checkedValue?: string;
    handleChange: (value: string) => void;
};

export const RadioContext = createContext<RadioContextType | null>(null);

export const useRadioGroup = () => {
    const context = useContext(RadioContext);

    if (!context) {
        throw new Error('Radio buttons needs to be used inside a Radio Context');
    }

    return context;
};

export type RadioButtonGroupProps = {
    children: React.ReactNode;
    className?: string;
    labelId: string;
    onChange?: (value: string) => void;
};

export const RadioButtonGroup = ({
    children,
    className,
    labelId,
    onChange,
}: RadioButtonGroupProps) => {
    const classes = useStyles();

    const [checkedValue, setCheckedValue] = useState<string | undefined>();

    const handleChange = useCallback(
        (value: string) => {
            setCheckedValue(value);
            onChange?.(value);
        },
        [onChange],
    );

    const value = useMemo(() => ({checkedValue, handleChange}), [checkedValue, handleChange]);

    return (
        <RadioContext.Provider value={value}>
            <div
                aria-labelledby={labelId}
                className={cn(classes.radioGroup, className)}
                role="radiogroup"
            >
                {children}
            </div>
        </RadioContext.Provider>
    );
};

type RadioButtonProps = {
    children: React.ReactNode;
    className: string;
    disabled: boolean;
    name: string;
    unstyled?: boolean;
    value: string;
};

export const RadioButton = forwardRef(
    (
        {children, className, disabled, name, unstyled, value}: RadioButtonProps,
        ref: React.ForwardedRef<HTMLLabelElement>,
    ) => {
        const {handleChange} = useRadioGroup();

        return (
            <label ref={ref}>
                <input
                    className={className}
                    disabled={disabled}
                    name={name}
                    onChange={!disabled ? (event) => handleChange(event.target.value) : undefined}
                    type="radio"
                    style={unstyled ? {appearance: 'none', display: 'flex', margin: 0} : {}}
                    value={value}
                />
                {children}
            </label>
        );
    },
);
