const styles = (theme) => {
    if (theme.v2) {
        return {
            barChart: {
                '& .recharts-cartesian-axis-tick-value': {
                    fontSize: theme.typgrphy.bodySmall.fontSize,
                    fontWeight: theme.typgrphy.bodySmall.fontWeight,
                    color: theme.colors.textSubtle,
                },
                '&.recharts-cartesian-grid-vertical': {
                    '& line': {
                        strokeDasharray: '1 1',
                    },
                },
                '& .xAxislegend': {
                    fill: theme.colors.textSubtle,
                    fontSize: theme.typgrphy.bodyNormal.fontSize,
                    fontWeight: theme.typgrphy.bodyNormal.fontWeight,
                },
                '& path': {
                    cursor: 'pointer !important',
                },
            },
        };
    } else {
        return {
            barChart: {
                '& .recharts-cartesian-axis-tick-value': {
                    fontSize: '12px',
                    color: theme.colors.darkGrey80,
                },
                '&.recharts-cartesian-grid-vertical': {
                    '& line': {
                        strokeDasharray: '1 1',
                    },
                },
                '& .xAxislegend': {
                    fill: theme.colors.darkGrey100,
                },
                '& path': {
                    cursor: 'pointer !important',
                },
            },
        };
    }
};

export default styles;
