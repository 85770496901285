import isElementVisible from "./isElementVisible";

/**
 * Gets keyboard-focusable elements within a specified element
 * @param {HTMLElement} [element=document] element
 * @returns {Array}
 */
export default function getFocusableElements(element: HTMLElement | Document = document) {
    return [
      ...element.querySelectorAll<HTMLElement>(
        'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])',
      ),
    ].filter(isFocusable)
}

function isFocusable(el: HTMLElement) {
    return !el.hasAttribute('disabled') && isElementVisible(el);
}
