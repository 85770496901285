import {useEffect} from 'react';
import {useAppDispatch} from 'hsi/hooks/useRedux';
import {setMentionTracked} from 'hsi/slices/mentions';
import useEventTrack from 'hsi/hooks/useEventTrack';
import {MentionType} from 'hsi/types/mentions';

export default function useTrackNlaMentions(isLoading: boolean, mentions: MentionType[]) {
    const {track} = useEventTrack();
    const dispatch = useAppDispatch();

     useEffect(() => {
        if (!isLoading && mentions.length > 0) {
            mentions
                .filter((mention) => !mention.hasBeenTracked && (mention.contentSource === 'news' || mention.contentSource === 'blog'))
                .forEach((mention) => {
                    if (!mention.hasBeenTracked) {
                        track('mentionNLAViewed', {
                            id: mention.id,
                            sourceType: mention.contentSource,
                            publisherId: mention.author,
                            date: mention.date,
                            domain: mention.domain,
                            pubType: mention.pubType,
                        });
                        dispatch(setMentionTracked(mention.id));
                    }
                });
        }
    }, [dispatch, mentions, isLoading, track]);
}
