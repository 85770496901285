import { isV2 } from 'hsi/types/theme';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => {
    const v2 = isV2(theme);
    const {colors} = theme;
    return {
        actionsContent: {
            ...(!v2 && {backgroundColor: colors.lightGrey20, order: 2}),
        },
        alertDialog: {
            padding: '0 24px',
            marginTop: 10,
            ...(!v2 && {order: 1, padding: '0 48px', marginBottom: 29}),
        },
        alertBanner: {
            backgroundColor: v2 ? colors.recentSearchesBg : colors.primaryRed10,
            padding: v2 ? 16 : 12,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        alertIcon: {
            fill: v2 ? '#F99131' : colors.error,
            width: 24,
            height: 24,
        },
        alertIconWrapper: {
            ...(!v2 && {
                borderRadius: '50%',
                backgroundColor: colors.primaryRed40,
                width: 36,
                height: 36,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }),
        },
        alertCopy: {
            margin: 0,
            ...(v2
                ? {
                      borderLeft: '1px solid #48464D',
                      marginLeft: 16,
                      paddingLeft: 16,
                  }
                : {marginLeft: 12}),
        },
        cancelButton: {
            ...(v2 && {color: colors.textHyperlink}),
        },
        dialogCopy: {
            fontSize: 13,
            ...(!v2 && {order: 0, marginBottom: 22}),
        },
        loadingHolder: {
            position: 'absolute',
            zIndex: 1,
            top: '-16px',
            right: 0,
            bottom: '-16px',
            left: 0,
            background: 'rgba(0, 0, 0, 0.1)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
});
 