export const arrowSize = 10;

export type OffsetType = {
    x: string;
    y: string;
};

export const arrowPlacementStyles = () => {
    const size = arrowSize;
    const boxOffset = 20;
    const transformRight = `translate(-${size / 2}px, -${size / 2}px) rotate(45deg)`;
    const transformLeft = `translate(${size / 2}px, -${size / 2}px) rotate(225deg)`;
    const transformTop = `translate(0px, ${size / 2}px) rotate(-45deg)`;
    const transformBottom = `translate(0px, -${size / 2}px) rotate(135deg)`;

    return {
        'container-right': ({offset}: {offset: OffsetType}) => ({
            top: offset.y,
            left: `calc(${size}px + ${offset.x})`,
        }),
        'arrow-right': ({offset}: {offset: OffsetType}) => ({
            top: `calc(50% + ${offset.y})`,
            left: `calc(${size}px + ${offset.x})`,
            transform: transformRight,
        }),
        'container-right-start': ({offset}: {offset: OffsetType}) => ({
            top: `calc(-${size + boxOffset}px + ${offset.y})`,
            left: `calc(${size}px + ${offset.x})`,
        }),
        'arrow-right-start': ({offset}: {offset: OffsetType}) => ({
            top: offset.y,
            left: `calc(${size}px + ${offset.x})`,
            transform: transformRight,
        }),
        'container-right-end': ({offset}: {offset: OffsetType}) => ({
            bottom: `calc(-${size + boxOffset}px - ${offset.y})`,
            left: `calc(${size}px + ${offset.x})`,
        }),
        'arrow-right-end': ({offset}: {offset: OffsetType}) => ({
            top: `calc(100% + ${offset.y})`,
            left: `calc(${size}px + ${offset.x})`,
            transform: transformRight,
        }),

        //left
        'container-left': ({offset}: {offset: OffsetType}) => ({
            top: offset.y,
            right: `calc(${size}px + ${offset.x})`,
        }),
        'arrow-left': ({offset}: {offset: OffsetType}) => ({
            top: `calc(50% + ${offset.y})`,
            right: `calc(${size}px + ${offset.x})`,
            transform: transformLeft,
        }),
        'container-left-start': ({offset}: {offset: OffsetType}) => ({
            top: `calc(-${size + boxOffset}px + ${offset.y})`,
            right: `calc(${size}px + ${offset.x})`,
        }),
        'arrow-left-start': ({offset}: {offset: OffsetType}) => ({
            top: offset.y,
            right: `calc(${size}px + ${offset.x})`,
            transform: transformLeft,
        }),
        'container-left-end': ({offset}: {offset: OffsetType}) => ({
            bottom: `calc(-${size + boxOffset}px - ${offset.y})`,
            right: `calc(${size}px + ${offset.x})`,
        }),
        'arrow-left-end': ({offset}: {offset: OffsetType}) => ({
            top: `calc(100% + ${offset.y})`,
            right: `calc(${size}px + ${offset.x})`,
            transform: transformLeft,
        }),

        //top
        'container-top': ({offset}: {offset: OffsetType}) => ({
            bottom: `calc(${size}px + ${offset.y})`,
            left: offset.x,
        }),
        'arrow-top': ({offset}: {offset: OffsetType}) => ({
            bottom: `calc(${size}px + ${offset.y})`,
            left: `calc(50% + ${offset.x})`,
            transform: transformTop,
        }),
        'container-top-start': ({offset}: {offset: OffsetType}) => ({
            bottom: `calc(${size}px - ${offset.y})`,
            left: `calc(-${size + boxOffset}px + ${offset.x})`,
        }),
        'arrow-top-start': ({offset}: {offset: OffsetType}) => ({
            bottom: `calc(${size}px - ${offset.y})`,
            left: `calc(-${size / 2}px + ${offset.x})`,
            transform: transformTop,
        }),
        'container-top-end': ({offset}: {offset: OffsetType}) => ({
            bottom: `calc(${size}px - ${offset.y})`,
            right: `calc(-${size + boxOffset}px - ${offset.x})`,
        }),
        'arrow-top-end': ({offset}: {offset: OffsetType}) => ({
            bottom: `calc(${size}px - ${offset.y})`,
            right: `calc(-${size / 2}px - ${offset.x})`,
            transform: transformTop,
        }),

        //bottom
        'container-bottom': ({offset}: {offset: OffsetType}) => ({
            top: `calc(${size}px + ${offset.y})`,
            left: offset.x,
        }),
        'arrow-bottom': ({offset}: {offset: OffsetType}) => ({
            top: `calc(${size}px + ${offset.y})`,
            left: `calc(50% + ${offset.x})`,
            transform: transformBottom,
        }),
        'container-bottom-start': ({offset}: {offset: OffsetType}) => ({
            top: `calc(${size}px + ${offset.y})`,
            left: `calc(-${size + boxOffset}px + ${offset.x})`,
        }),
        'arrow-bottom-start': ({offset}: {offset: OffsetType}) => ({
            top: `calc(${size}px + ${offset.y})`,
            left: `calc(-${size / 2}px + ${offset.x})`,
            transform: transformBottom,
        }),
        'container-bottom-end': ({offset}: {offset: OffsetType}) => ({
            top: `calc(${size}px + ${offset.y})`,
            right: `calc(-${size + boxOffset}px - ${offset.x})`,
        }),
        'arrow-bottom-end': ({offset}: {offset: OffsetType}) => ({
            top: `calc(${size}px + ${offset.y})`,
            right: `calc(-${size / 2}px - ${offset.x})`,
            transform: transformBottom,
        }),
    };
};
