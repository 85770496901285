import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>(({v2, mixin, colors}) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        gap: '4px',
    },
    favicon: {
        transform: `scale(${1 / 0.7})`,
    },
    avatar: {
        backgroundColor: '#eee',
        width: 40,
        transform: 'scale(0.7)',
    },
    authorDetails: {
        overflow: 'hidden',
    },
    authorName: {
        ...mixin.truncate,
    },
    location: {
        color: v2 ? colors.textSubtle : colors.textDefault,
        ...mixin.truncate,
    },
}));
