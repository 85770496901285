/**
  @author Rodrigo Derteano
*/

export interface HttpResult<TBody = any> {
    status: number;
    ok: boolean;
    body: TBody;
}

export type HttpError = {
    error: true,
    status: number,
    body: {
        code: string,
        status: number,
        message: string,
        errors: string[]
    },
}

export function isHttpError(e: any): e is HttpError {
    return e?.error === true && (typeof e?.status === 'number') && (typeof e?.body === 'object') && (typeof e.body.message === 'string')
}

export default class Http {
    //These get... directly accessed.
    static defaultOptions: RequestInit = {
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json'},
    };

    static on401?: (url: string, option: RequestInit) => void;

    static async doFetchJSON<TBody = any>(url: string, options: RequestInit = {}) {
        try {
            const res = await fetch(url, options);

            let body: TBody | undefined = undefined;

            //This is... awful. I would fix, but better to just aim to replace this lib entirely
            //PP - I am changing this behaviour to throw an error if JSON parsing fails. Lets see if this works?
            //PP - adding check for empty response, which will return 'undefined' as the response body
            try {
                const bodyText = await res.text();

                body = (bodyText === '' ? undefined : JSON.parse(bodyText)) as TBody;
            } catch (e) {
                return Promise.reject(e);
            }

            return Http.fetchCheck<TBody>(body, res, url, options);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    static async doFetchRaw(url: string, options: RequestInit = {}) {
        try {
            const res = await fetch(url, options);

            const body = await res.text();

            return Http.fetchCheck(body, res, url, options);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    static async fetchCheck<TBody>(
        body: TBody,
        res: Response,
        url: string,
        options: RequestInit,
    ): Promise<HttpResult<TBody>> {
        if (res.status >= 200 && res.status < 300) {
            return Promise.resolve({
                status: res.status,
                ok: res.ok,
                body,
            });
        } else {
            //If the status is 401, call the on401 callback (if applicable)
            res.status === 401 && Http.on401?.(url, options);

            return Promise.reject({
                error: true,
                status: res.status,
                body,
            });
        }
    }

    static async get<TBody = any>(
        url: string,
        options?: RequestInit,
        raw?: false,
    ): Promise<HttpResult<TBody>>;
    static async get(url: string, options: RequestInit, raw: true): Promise<HttpResult<string>>;
    static async get<TBody = any>(
        url: string,
        options: RequestInit,
        raw: boolean,
    ): Promise<HttpResult<TBody | string>>;

    static async get(
        url: string,
        options: RequestInit = {},
        raw: boolean = false,
    ): Promise<HttpResult<any>> {
        const headers = options.headers
            ? {...Http.defaultOptions.headers, ...options.headers}
            : Http.defaultOptions.headers;
        const optionsWithDefaults = {...Http.defaultOptions, ...options, headers};

        return raw
            ? Http.doFetchRaw(url, optionsWithDefaults)
            : Http.doFetchJSON<any>(url, optionsWithDefaults);
    }

    static async post<TBody = any>(url: string, params: any, options: RequestInit = {}) {
        const headers = options.headers
            ? {...Http.defaultOptions.headers, ...options.headers}
            : Http.defaultOptions.headers;

        return Http.doFetchJSON<TBody>(url, {
            method: 'POST',
            ...Http.defaultOptions,
            ...options,
            headers,
            body: JSON.stringify(params || {}),
        });
    }

    static async patch<TBody = any>(url: string, params = {}, options: RequestInit = {}) {
        const headers = options.headers
            ? {...Http.defaultOptions.headers, ...options.headers}
            : Http.defaultOptions.headers;

        return Http.doFetchJSON<TBody>(url, {
            method: 'PATCH',
            ...Http.defaultOptions,
            ...options,
            headers,
            body: JSON.stringify(params || {}),
        });
    }

    static async delete<TBody = any>(url: string, params = {}, options: RequestInit = {}) {
        const headers = options.headers
            ? {...Http.defaultOptions.headers, ...options.headers}
            : Http.defaultOptions.headers;

        return Http.doFetchJSON<TBody>(url, {
            method: 'DELETE',
            ...Http.defaultOptions,
            ...options,
            headers,
            body: JSON.stringify(params || {}),
        });
    }

    static async put<TBody = any>(url: string, params = {}, options: RequestInit = {}) {
        const headers = options.headers
            ? {...Http.defaultOptions.headers, ...options.headers}
            : Http.defaultOptions.headers;

        return Http.doFetchJSON<TBody>(url, {
            method: 'put',
            ...Http.defaultOptions,
            ...options,
            headers,
            body: JSON.stringify(params || {}),
        });
    }
}
