import React, {useMemo, useCallback, ReactNode} from 'react';

import Button from 'hsi/components/Button';
import LabelledButton from 'hsi/components/Button/LabelledButton';
import DateRangeAndTimezonePicker from 'hsi/components/DateRangeAndTimezonePicker';
import IconRouter from 'hsi/components/IconRouter';
import Tooltip from 'hsi/components/Tooltip';
import Popover, {PopoverRenderFunc} from 'hsi/components/Popover';
import {Interval} from 'luxon';

//Hooks
import useRollingTime from 'hsi/hooks/useRollingTime';
import useDateRangeAndTimezonePickerState from 'hsi/components/DateRangeAndTimezonePicker/useDateRangeAndTimezonePickerState';

//Utils
import {isSame, formatTo, normalizeDate} from 'hsi/utils/dates';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';
import {TimezoneID} from 'hsi/utils/timezones';
import {InputDateType, RangeDefinition, TDateISO} from 'hsi/types/dates';
import {ReadonlyDeep} from 'type-fest';
interface DateRangeBarArgs {
    className?: string;
    banner?: ReactNode;
    minDate: InputDateType;
    maxDate: InputDateType;
    startDate: InputDateType;
    endDate: InputDateType;
    onCalendarSet: (
        props: {
            relativeDateRange?: string;
            startDate?: TDateISO;
            endDate?: TDateISO;
        },
        timezone: TimezoneID,
    ) => void;

    onDateSelectionChange: (range: Interval | undefined) => void;
    onTimezoneChange: (newTimezone: TimezoneID) => void;
    hasTimezones?: boolean;
    timezone: TimezoneID;
    defaultTimezone: TimezoneID;
    disabled?: boolean;

    selectedRelativeDateRangeId?: string;
    relativeDateRanges: ReadonlyDeep<RangeDefinition[]>;
    onRelativeDateRangeIdChange: (dateRange: string | undefined) => void;
}

//The component
export default function DateRangeBar({
    className,
    banner,
    minDate,
    maxDate,
    startDate: initialStartDate,
    endDate: initialEndDate,
    onCalendarSet,
    onDateSelectionChange,
    onTimezoneChange,
    hasTimezones,
    timezone: initialTimezone,
    defaultTimezone,
    disabled,

    selectedRelativeDateRangeId: defaultSelectedRelativeDateRangeId,
    relativeDateRanges,
    onRelativeDateRangeIdChange,
}: DateRangeBarArgs) {
    const classes = useStyles();

    const timezone = hasTimezones 
            ? initialEndDate
                ? formatTo(initialEndDate, initialTimezone, 'ZZZZ')
                : '---- / ---'
            : undefined;

    //Calculated values
    const [barValue, barLabel] = useMemo(() => {
        const startValue = formatTo(initialStartDate, initialTimezone, 'MMM d');
        const startYear = formatTo(initialStartDate, initialTimezone, 'yyyy');
        const endValue = formatTo(initialEndDate, initialTimezone, 'MMM d');
        const endYear = formatTo(initialEndDate, initialTimezone, 'yyyy');
        

        if (isSame(initialStartDate, initialEndDate, 'year', initialTimezone)) {
            return [
                    (<>
                        <time dateTime={formatTo(initialStartDate, initialTimezone, 'yyyy-LL-ddZZ')}>
                            {startValue}
                        </time>{' '}
                        -{' '}
                        <time
                            dateTime={formatTo(initialEndDate, initialTimezone, 'yyyy-LL-ddZZ')}
                        >{T('datepicker.bar.dateWithYear', {date: endValue, year: endYear})}</time>
                    </>), 
                    T("datepicker.bar.a11yRangeLabel", {
                        start: startValue, 
                        end: T('datepicker.bar.dateWithYear', {date: endValue, year: endYear})
                    })
            ] as const;
        } else {
            return [(
                    <>
                        <time
                            dateTime={formatTo(initialEndDate, initialTimezone, 'yyyy-LL-ddZZ')}
                        >{T('datepicker.bar.dateWithYear', {date: startValue, year: startYear})}</time>{' '}
                        -{' '}
                        <time
                            dateTime={formatTo(initialEndDate, initialTimezone, 'yyyy-LL-ddZZ')}
                        >{T('datepicker.bar.dateWithYear', {date: endValue, year: endYear})}</time>
                    </>
                ), 
                T("datepicker.bar.a11yRangeLabel", {
                    start: T('datepicker.bar.dateWithYear', {date: startValue, year: startYear}), 
                    end: T('datepicker.bar.dateWithYear', {date: endValue, year: endYear})
                })
            ] as const;
        }
    }, [initialEndDate, initialStartDate, initialTimezone]);

    const popoverContent = useCallback<PopoverRenderFunc>(
        ({close, labelId, descriptionId}) => (
            <DialogContents
                banner={banner}
                close={close}
                labelId={labelId}
                descriptionId={descriptionId}
                maxDate={maxDate}
                minDate={minDate}
                defaultTimezone={defaultTimezone}
                onCalendarSet={onCalendarSet}
                onDateSelectionChange={onDateSelectionChange}
                onRelativeDateRangeIdChange={onRelativeDateRangeIdChange}
                onTimezoneChange={onTimezoneChange}
                hasTimezones
                initialStartDate={initialStartDate}
                initialEndDate={initialEndDate}
                initialTimezone={initialTimezone}
                relativeDateRanges={relativeDateRanges}
                defaultSelectedRelativeDateRangeId={defaultSelectedRelativeDateRangeId}
            />
        ),
        [
            banner,
            defaultSelectedRelativeDateRangeId,
            defaultTimezone,
            initialEndDate,
            initialStartDate,
            initialTimezone,
            maxDate,
            minDate,
            onCalendarSet,
            onDateSelectionChange,
            onRelativeDateRangeIdChange,
            onTimezoneChange,
            relativeDateRanges,
        ],
    );

    return (
        <Tooltip
            placement="top"
            theme="dark"
            tooltip={hasTimezones ? T('datepicker.tooltip') : T('datepicker.dateOnlyTooltip')}
            noAria
        >
            <Popover content={popoverContent} distance={0} padding={75} portal>
                <LabelledButton
                    className={className}
                    fullWidth
                    labelClassName={classes.label}
                    data-testid="barDatePicker"
                    priority="primary"
                    startIcon={<IconRouter aria-hidden className={classes.calendarIcon} data-testid="calendarIcon" name="calendar" />}
                    endIcon={<IconRouter aria-hidden className={classes.dropdownIcon} data-testid="arrowDownIcon" name="mui-keyboard-arrow-down" />}
                    hiddenLabel={hasTimezones ? T('datepicker.tooltip') : T('datepicker.dateOnlyTooltip')}
                    label={
                        <>
                            <span>{barValue}</span>
                            {hasTimezones && (
                                <span className={classes.timezone}>
                                    {timezone}
                                </span>
                            )}
                        </>
                    }
                    a11yLabel={hasTimezones ? T("datepicker.bar.withTimezone", {date: barLabel, timezone}) : barLabel}
                >
                </LabelledButton>
            </Popover>
        </Tooltip>
    );
}

interface DialogContentsArgs
    extends Pick<
        DateRangeBarArgs,
        | 'onDateSelectionChange'
        | 'onTimezoneChange'
        | 'onRelativeDateRangeIdChange'
        | 'onCalendarSet'
        | 'minDate'
        | 'maxDate'
        | 'hasTimezones'
        | 'defaultTimezone'
    > {
    labelId?: string;
    descriptionId?: string;
    banner?: ReactNode;
    disabled?: boolean;
    initialStartDate?: InputDateType;
    initialEndDate?: InputDateType;
    initialTimezone?: TimezoneID;
    relativeDateRanges?: ReadonlyDeep<RangeDefinition[]>;
    defaultSelectedRelativeDateRangeId?: string;

    close?: () => void;
}

function DialogContents({
    labelId,
    descriptionId,
    banner,
    disabled,
    initialStartDate,
    initialEndDate,
    initialTimezone,
    relativeDateRanges,
    defaultSelectedRelativeDateRangeId,
    onDateSelectionChange,
    onTimezoneChange,
    onRelativeDateRangeIdChange,
    onCalendarSet,
    minDate,
    maxDate,
    hasTimezones,
    defaultTimezone,
    close,
}: DialogContentsArgs) {
    const classes = useStyles();

    //Calculate real selected range (use relative date range if applicable)
    const now = useRollingTime();

    //Internal state
    const {
        startDate,
        endDate,
        showDate,
        setShowDate,
        setDateSelection,
        timezone,
        setTimezone,
        selectedRelativeDateRangeId,
        setSelectedRelativeDateRangeId,
        reset,
    } = useDateRangeAndTimezonePickerState(now, {
        initialStartDate,
        initialEndDate,
        initialTimezone,
        relativeDateRanges,
        defaultSelectedRelativeDateRangeId,
        onDateSelectionChange,
        onTimezoneChange,
        onRelativeDateRangeIdChange,
    });

    //Callbacks
    const onClickSet = useCallback(() => {
        if (selectedRelativeDateRangeId) {
            onCalendarSet({relativeDateRange: selectedRelativeDateRangeId}, timezone);
        } else {
            onCalendarSet(
                {
                    startDate: startDate
                        ? (normalizeDate(startDate, timezone).toISO() as TDateISO)
                        : undefined,
                    endDate: endDate
                        ? (normalizeDate(endDate, timezone).endOf('day').toISO() as TDateISO)
                        : undefined,
                },
                timezone,
            );
        }
    }, [selectedRelativeDateRangeId, timezone, onCalendarSet, startDate, endDate]);

    return (
        <>
            <span id={labelId} className="offscreen">
                {T('datepicker.popoverTitle')}
            </span>
            <span id={descriptionId} className="offscreen">
                {T('datepicker.popoverDescription')}
            </span>
            {banner}
            <DateRangeAndTimezonePicker
                dragEnabled
                startDate={startDate}
                endDate={endDate}
                showDate={showDate}
                setShowDate={setShowDate}
                maxDate={maxDate}
                minDate={minDate}
                setDateSelection={setDateSelection}
                hasTimezones={hasTimezones}
                defaultTimezone={defaultTimezone}
                timezone={timezone}
                setTimezone={setTimezone}
                relativeDateRanges={relativeDateRanges}
                selectedRelativeDateRangeId={selectedRelativeDateRangeId}
                setSelectedRelativeDateRangeId={setSelectedRelativeDateRangeId}
            />

            <div className={classes.footer}>
                <Button
                    priority="secondary"
                    onClick={() => {
                        reset?.();
                        close?.();
                    }}
                >
                    {T('cancel')}
                </Button>
                <Button
                    priority="cta"
                    disabled={disabled}
                    onClick={() => {
                        onClickSet?.();
                        close?.();
                    }}
                >
                    {T('set')}
                </Button>
            </div>
        </>
    );
}
