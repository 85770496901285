import {useCallback} from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isEmail from 'validator/lib/isEmail';
import PropTypes from 'prop-types';

import tns from 'hsi/i18n/tns';

import ChipInput from 'hsi/components/ChipInput';

const t = tns('reports.editReportModal.selectRecipients');

const SelectRecipients = ({classname, error, recipients, setError, setRecipients}) => {
    const onRecipientsChange = useCallback(
        (newValue) => {
            const lowerCaseValues = newValue.map((value) => value.toLowerCase());
            const uniqueValues = [...new Set(Array.from(lowerCaseValues))];

            setError(false);
            setRecipients(uniqueValues);
            return uniqueValues;
        },
        [setError, setRecipients],
    );

    const validateRecipients = useCallback(
        (values) => {
            const invalidEmailAddress = values.filter((recipient) => !isEmail(recipient));
            const newErrors = invalidEmailAddress.map((email) => ({
                label: 'reports.editReportModal.selectRecipients.invalidEmailErrorItem',
                reason: 'Email address did not pass validation',
                value: email,
            }));

            newErrors.length && setError(true);
            return newErrors;
        },
        [setError],
    );

    return (
        <div className={cn('recipients', classname)} data-testid="reportRecipients">
            <ChipInput
                error={error && isEmpty(recipients)}
                getInputErrorMessage={(errorValues) =>
                    errorValues.length
                        ? {
                              title: t('invalidEmailErrorTitle'),
                              desc: t('invalidEmailErrorDesc'),
                          }
                        : isEmpty(recipients)
                        ? {
                              title: t('missingEmailErrorTitle'),
                              desc: t('missingEmailErrorDesc'),
                          }
                        : null
                }
                helperText={t('helperText')}
                label={t('label')}
                onValuesChange={onRecipientsChange}
                validateValues={validateRecipients}
                values={recipients}
                required={isEmpty(recipients)}
            />
        </div>
    );
};

SelectRecipients.propTypes = {
    className: PropTypes.string,
    error: PropTypes.bool.isRequired,
    recipients: PropTypes.array.isRequired,
    setError: PropTypes.func.isRequired,
    setRecipients: PropTypes.func.isRequired,
};

export default SelectRecipients;
