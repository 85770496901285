import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

//Components
import Dialog, {DialogActions, DialogContent, DialogTitle} from 'hsi/components/Dialog';
import Button from 'hsi/components/Button';
import IconRouter from 'hsi/components/IconRouter';
import PendingModal from 'hsi/components/PendingModal';

//Hooks
import {useAppSelector, useAppDispatch} from 'hsi/hooks/useRedux';
import useProjectId from 'hsi/hooks/useProjectId';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useIsActionPendingOnMentions from 'hsi/hooks/useIsActionPendingOnMentions';

//Other
import {T} from 'hsi/i18n';
import {deleteMentions as _deleteMentions} from 'hsi/slices/mentions';
import useStyles from './styles';

//Types
import {isSavedSearchMention, SavedSearchMentionType} from 'hsi/types/mentions';

export type BulkDeleteDialogProps = {
    isOpen?: boolean;
    onClose?: () => void;
    onClosed?: () => void;
};

//The component
export default function BulkDeleteDialog({
    isOpen = false,
    onClose: _onClose,
    onClosed,
}: BulkDeleteDialogProps) {
    const {classes} = useStyles();
    const dispatch = useAppDispatch();
    const projectId = useProjectId()!;
    const {trackWithSearchData} = useEventTrack();
    const mentionsStatePending = useAppSelector(
        (state) => state.mentions.loading || state.mentions.isBulkActionPending,
    );
    const stateSelectedMentionIds = useAppSelector((state) => state.mentions.selectedMentions);
    const mentionsArray = useAppSelector((state) => state.mentions.results);

    //This is used to track the mentions that actually get changed,
    //as the process of changing sentiment can happen so fast that it
    //applies before this dialog completes it's closing transition
    const selectedMentionsAppliedRef = useRef<Record<string, boolean>>();
    const selectedMentionsIds = selectedMentionsAppliedRef.current ?? stateSelectedMentionIds;
    const numSelectedMentions = Object.keys(selectedMentionsIds).length;

    const mentionsHaveActionPending = useIsActionPendingOnMentions(selectedMentionsIds);

    //Internal state
    const [working, setWorking] = useState(false); //If this component performing a bulk action

    //Memoised values
    const selectedMentions = useMemo(() => {
        let selected: SavedSearchMentionType[] = [];
        mentionsArray.find((mention) => {
            const selectedIds = Object.keys(selectedMentionsIds);
            selectedIds.forEach((key) => {
                if (mention.id === key && isSavedSearchMention(mention)) {
                    selected.push(mention);
                    return mention;
                }
            });
            return false;
        });
        return selected;
    }, [mentionsArray, selectedMentionsIds]);

    //Callbacks
    const onClose = useMemo(() => {
        return _onClose
            ? () => {
                  !working && _onClose();
              }
            : undefined;
    }, [working, _onClose]);

    const deleteMentions = useCallback(async () => {
        setWorking(true);
        //store the mentions that are selected at this exact point
        selectedMentionsAppliedRef.current = stateSelectedMentionIds;

        //Store this for the event tracking later
        const numItemsDeleted = Object.keys(selectedMentionsAppliedRef.current).length;

        //Make the API call, and wait for the response
        await dispatch(
            _deleteMentions({mentions: selectedMentions, projectId}),
        ).then(() => {
            trackWithSearchData('bulkMentionDeleteComplete', {
                selectedMentions: numItemsDeleted,
            });
        });

        setWorking(false);

        _onClose?.();
    }, [
        dispatch,
        _onClose,
        projectId,
        selectedMentions,
        stateSelectedMentionIds,
        trackWithSearchData,
    ]);

    //Side effects
    useEffect(() => {
        isOpen === false && (selectedMentionsAppliedRef.current = undefined);
    }, [isOpen]);

    //Rendering
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            onClosed={onClosed}
            disableCloseBtn={working}
            aria-labelledby="bulkDeleteDialog.title"
        >
            <PendingModal
                pending={working || mentionsStatePending || mentionsHaveActionPending}
                message={T('mentionsContainer.bulkDeleteDialog.pending')}
                loaderSize="medium"
            >
                <div>
                    <DialogTitle id="bulkDeleteDialog.title">
                        {T('mentionsContainer.bulkDeleteDialog.title')}
                    </DialogTitle>
                    <DialogContent className={classes.alertDialog}>
                        <div className={classes.alertBanner}>
                            <div className={classes.alertIconWrapper} aria-hidden={true}>
                                <IconRouter name={'warning'} className={classes.alertIcon} />
                            </div>
                            <p className={classes.alertCopy}>
                                {T('mentionsContainer.bulkDeleteDialog.alert')}
                            </p>
                        </div>
                    </DialogContent>
                    <DialogContent className={classes.dialogCopy}>
                        {T('mentionsContainer.bulkDeleteDialog.copy')}
                    </DialogContent>
                    <DialogActions className={classes.actionsContent}>
                        <Button priority="text" className={classes.cancelButton} onClick={onClose}>
                            {T('mentionsContainer.bulkDeleteDialog.cancelAction')}
                        </Button>
                        <Button priority="cta" onClick={deleteMentions}>
                            {T('mentionsContainer.bulkDeleteDialog.confirmAction', {
                                count: numSelectedMentions,
                            })}
                        </Button>
                    </DialogActions>
                </div>
            </PendingModal>
        </Dialog>
    );
}
