const styles = (theme) => {
    if (theme.v2) {
        return {
            root: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                minHeight: 36,
            },
            iconContainer: {
                backgroundColor: 'transparent',
            },
            icon: {
                fill: theme.colors.error,
                width: 38,
                height: 38,
                margin: '0 6px',
                paddingRight: 12,
                borderRight: `1px ${theme.colors.uiGrayDarker} solid`,
            },
            content: {
                marginLeft: 20,
                minHeight: 36,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            },
            errorItem: {
                display: 'flex',
                flexDirection: 'column',
            },
            title: {
                fontWeight: theme.typgrphy.bodyStrong.fontWeight,
                color: theme.colors.textDefault,
                fontSize: theme.typgrphy.bodyStrong.fontSize,
                marginTop: 0,
                lineHeight: 1.2,
            },
            desc: {
                margin: '0.1em 0 0.5em',
                color: theme.colors.textDefault,
                fontSize: theme.typgrphy.bodyNormal.fontSize,
                fontWeight: theme.typgrphy.bodyNormal.fontWeight,
            },
            link: {
                ...theme.mixin.linkColor,
                ...theme.typgrphy.bodyStrong,
                cursor: 'pointer',
            },
        };
    } else {
        return {
            root: {
                position: 'relative',
                marginTop: '4px',
                minHeight: 36,
            },
            iconContainer: {
                position: 'absolute',
                top: 0,
                left: 0,
                height: 36,
                width: 36,
                borderRadius: '50%',
                backgroundColor: theme.colors.primaryRed40,
                textAlign: 'center',
                lineHeight: '36px',
            },
            icon: {
                position: 'relative',
                top: 4,
                fill: theme.colors.primaryRed100,
                width: 20,
                height: 20,
            },
            content: {
                marginLeft: 48,
                minHeight: 36,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            },
            errorItem: {
                display: 'flex',
                flexDirection: 'column',
            },
            title: {
                fontWeight: 700,
                color: theme.colors.darkGrey100,
                fontSize: '14px',
                marginTop: 0,
                lineHeight: 1.2,
            },
            desc: {
                margin: '0.1em 0 0.5em',
                color: theme.colors.darkGrey100,
                fontSize: '14px',
            },
            link: {
                ...theme.mixin.linkColor,
                cursor: 'pointer',
            },
        };
    }
};

export default styles;
