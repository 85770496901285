//TODO this needs to be replaced
import {useMemo, memo} from 'react';
import cn from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import CloseIcon from '@mui/icons-material/Close';
import MuiIconButton from '@mui/material/IconButton';

import Button from 'hsi/components/Button';

import {arrowSize, arrowPlacementStyles, OffsetType} from './tooltipArrow';

import tns from 'hsi/i18n/tns';

const t = tns('intro');

export type PopperPlacement = 'top' | 'bottom' | 'left' | 'right';

const IconButton = withStyles((theme) => ({
    root: {
        padding: '3px',
        '& svg': {
            width: '18px',
            height: '18px',
        },
    },
}))(MuiIconButton);

const useStyles = makeStyles(({colors, v2}: {colors: any; v2: boolean}) => ({
    container: {
        backgroundColor: v2 ? colors.white : colors.lightGrey10,
        border: v2 ? `1px solid ${colors.borders}` : `2px solid ${colors.secondaryPurple100}`,
        color: v2 ? colors.textDefault : colors.darkGrey100,
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
        position: 'relative',
        zIndex: 0,
        ...(v2 ? {boxShadow: `0px 4px 9px ${colors.borders}`, borderRadius: '3px'} : {}),
    },
    body: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        ...(v2 ? {marginBottom: '16px'} : {}),
    },
    bodyInner: {
        marginRight: '16px',
    },
    title: {
        fontSize: 24,
        fontWeight: 700,
        marginBottom: '4px',
    },
    singleStepFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    multiStepFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    arrow: {
        backgroundColor: colors.lightGrey10,
        position: 'absolute',
        zIndex: 1,
        width: `${arrowSize}px`,
        height: `${arrowSize}px`,
        borderLeft: v2 ? `1px solid ${colors.borders}` : `2px solid ${colors.secondaryPurple100}`,
        borderBottom: v2 ? `1px solid ${colors.borders}` : `2px solid ${colors.secondaryPurple100}`,
    },
    footer: {
        marginTop: v2 ? '8px' : '16px',
    },
    ...arrowPlacementStyles(),
}));

type TooltipProps = {
    content:
        | JSX.Element
        | (({onClose, onNext}: {onClose?: () => any; onNext?: () => any}) => JSX.Element);
    offset: OffsetType;
    onClose: () => any;
    onNext: () => any;
    placement: PopperPlacement;
    stepIdx: number;
    stepsLength: number;
    style: React.CSSProperties;
    title:
        | JSX.Element
        | (({onClose, onNext}: {onClose?: () => any; onNext?: () => any}) => JSX.Element);
    showArrow: boolean;
};

const Tooltip = ({
    content: _content,
    offset,
    onClose,
    onNext,
    placement,
    stepIdx,
    stepsLength,
    style,
    title: _title,
    showArrow = true
}: TooltipProps) => {
    const off = {x: offset?.x || '0px', y: offset?.y || '0px'};
    const cl = useStyles({offset: off});

    const isMultiStep = stepsLength > 1;
    const isLastStep = stepIdx >= stepsLength - 1;

    const stepStatus = t('stepStatus', {
        stepNum: stepIdx + 1,
        totalSteps: stepsLength,
    });

    const title = useMemo(
        () => (typeof _title === 'function' ? _title({onClose, onNext}) : _title),
        [_title, onClose, onNext],
    );

    const content = useMemo(
        () => (typeof _content === 'function' ? _content({onClose, onNext}) : _content),
        [_content, onClose, onNext],
    );

    return (
        <div style={style}>
            {showArrow && <div className={cn(cl.arrow, cl[`arrow-${placement}`])} />}
            <div className={cn(cl.container, cl[`container-${placement}`])}>
                <div className={cl.body}>
                    <div className={cl.bodyInner}>
                        <div className={cl.title}>{title}</div>
                        {content}
                    </div>

                    <IconButton onClick={onClose} size="large">
                        <CloseIcon />
                    </IconButton>
                </div>

                {isMultiStep && (
                    <div className={cl.multiStepFooter}>
                        <div>{stepStatus}</div>
                        {!isLastStep && (
                            <Button onClick={onNext} priority="cta">
                                {t('next')}
                            </Button>
                        )}
                        {isLastStep && (
                            <Button onClick={onClose} priority="cta">
                                {t('accept')}
                            </Button>
                        )}
                    </div>
                )}

                {!isMultiStep && (
                    <div className={cl.singleStepFooter}>
                        <Button onClick={onClose} priority="cta">
                            {t('accept')}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(Tooltip);
