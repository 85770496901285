import PulseLoader, {PulseLoaderSize} from 'hsi/components/PulseLoader';
import useStyles from './styles';
import {Children, ReactElement, ReactNode, cloneElement, useMemo} from 'react';
import classNames from 'classnames';

export type FullscreenLoadingProps = {
    pending?: boolean;
    message: ReactNode;
    loaderSize?: PulseLoaderSize;
    offscreenMessage?: boolean;
} & JSX.IntrinsicElements['div'];

export default function PendingModal({
    pending = false,
    children,
    message,
    loaderSize = 'large',
    role = 'status',
    offscreenMessage = false,
    ...rest
}: FullscreenLoadingProps) {
    const classes = useStyles();

    const child = useMemo(() => {
        const child = Children.only(children) as ReactElement;

        return pending
            ? cloneElement(child, {
                  inert: 'inert',
                  className: classNames(child.props.className, classes.content),
              })
            : child;
    }, [children, classes.content, pending]);

    return (
        <>
            {pending && (
                <div
                    className={classes.loadingHolder}
                    role={role}
                    data-testid="pending-modal"
                    {...rest}
                >
                    <PulseLoader size={loaderSize} />
                    <div className={classNames(classes.message, offscreenMessage && 'offscreen')}>
                        {message}
                    </div>
                </div>
            )}
            {child}
        </>
    );
}
