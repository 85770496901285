import React, {ReactNode, useMemo} from 'react';
import Chip from '@mui/material/Chip';
import cn from 'classnames';

//Components
import IconRouter from 'hsi/components/IconRouter';
import OverflowTooltip from 'hsi/components/SimpleTooltip/OverflowTooltipHook';

//Other
import useStyles from './styles';

//Constants
const deleteIcon = <IconRouter name="e-remove" />;

type FilterChipProps = {
    label: ReactNode;
    actionType?: string;
    onDelete?: (event: any) => void;
    error?: boolean;
    fullWidth?: boolean;
    className?: string;
};

//The component
export default function FilterChip({
    label,
    actionType,
    onDelete,
    error,
    fullWidth,
    className,
}: FilterChipProps) {
    const classes = useStyles();

    //Calculated values
    const labelContent = useMemo(
        () => (
            //Temp cast to any to fix type error with non-TS component
            <OverflowTooltip tooltip={label as any}>
                <div className={classes.chipLabelInner}>{label}</div>
            </OverflowTooltip>
        ),
        [classes.chipLabelInner, label],
    );

    const icon = useMemo(
        () => (actionType ? <IconRouter name={actionType} /> : undefined),
        [actionType],
    );

    return (
        <Chip
            className={cn(
                classes.chip,
                error && classes.chipError,
                fullWidth && classes.fullWidth,
                className,
            )}
            icon={icon}
            label={labelContent}
            onDelete={onDelete}
            deleteIcon={deleteIcon}
        />
    );
}
