import {makeStyles} from 'tss-react/mui';

export default makeStyles()(({v2, mixin, colors}) => ({
    wrapper: {
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        minHeight: v2 ? 40 : 44,
    },
    fullWidth: {
        width: '100%',
    },
    label: {
        pointerEvents: 'none',
        display: 'flex',
        position: 'relative',
        zIndex: 2,
        alignItems: 'center',
        padding: v2 ? '0 16px' : '0 24px',
        color: v2 ? colors.textDefault : colors.primaryBlue100,
    },
    btn: {
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        height: 'auto',
    },
    endIcon: {
        display: 'inherit',
        marginRight: '-4px',
        marginLeft: 'auto',
    },
    startIcon: {
        display: 'inherit',
        marginLeft: '-4px',
        marginRight: '8px',
    },
    hidden: {
        ...mixin.offscreen,
    },
}));
