import makeStyles from '@mui/styles/makeStyles';

import {Theme} from 'hsi/types/theme';

export default makeStyles((theme: Theme) => {
    return {
        media: {
            display: 'flex',
            flexFlow: 'column nowrap',
            gap: '12px',
        },
        image: {
            display: 'block',
            width: '100%',
            height: 'auto',

            '&.tall': {
                height: '100%',
                objectFit: 'cover',
            },
        },

        error: {
            display: 'none',
        },

        tall: {},

        videoContainer: {
            position: 'relative',
            //height: '100%',
        },
    };
});
