import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        gap: 6,
        alignItems: 'center',
        height: '40px',
        width: '100%',

        '& .MuiChip-root': {
            height: '20px',
        },
        '& .MuiChip-label': {
            paddingLeft: '8px',
            paddingRight: '8px',
        },
    },

    searchNameContainer: {
        flex: '0 1 auto',
        minWidth: 0,
    },

    additionalQueriesChips: {
        '--minWidth': '16px',
        flex: '1 0 var(--minWidth)',
        minWidth: 'var(--minWidth)',
    },

    searchName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

export {useStyles};
