//TODO error handling as part of this?

import {ComponentType, FC, ReactNode, useEffect, useMemo, useRef} from 'react';

//Component
import PulseLoader from 'hsi/components/PulseLoader';
import { ReactElement } from 'react-markdown';

//types
type WithLoadFirstHookOptions<P extends {}, D, M = P> = {
    /**
     * Called every update as a hook, use to extract data, perform calculations etc
     * 
     * @param props the components props
     * @returns data which is passed to the isLoaded, and onFirstLoad functions
     */
    useCheckHook?: (props: P) => D,

    /**
     * If the first call to isLoaded does not return true, this function is called ONCE, can be used to trigger side effects, e.g. loading data
     * 
     * @param props the components props
     * @param data data from useCheckHook
     * @returns nothing
     */
    onFirstLoad?: (props: P, data: D) => void,

    /**
     * Optional method to merge in props from useCheckHook to pass into the supplied component
     * 
     * @param props 
     * @param data 
     * @returns 
     */
    mergeProps?: (props: P, data: D) => M,

    /**
     * Optional loading indicator - will default to a large PulseLoader
     */
    loadingIndicator?: ReactNode | ((props: P, data: D) => ReactNode)
};

type WithLoadFirstNoHookOptions<P extends {}, M = P> = {
    useCheckHook?: never,

    /**
     * If the first call to isLoaded does not return true, this function is called ONCE, can be used to trigger side effects, e.g. loading data
     * 
     * @param props the components props
     * @param data data from useCheckHook
     * @returns nothing
     */
    onFirstLoad?: (props: P) => void,

    /**
     * Optional method to merge in props from useCheckHook to pass into the supplied component
     * 
     * @param props 
     * @param data 
     * @returns 
     */
    mergeProps?: (props: P) => M,

    /**
     * Optional loading indicator - will default to a large PulseLoader
     */
    loadingIndicator?: ReactNode | ((props: P) => ReactNode)
};

type WithLoadFirstOptions<P extends {}, D, M = P> = WithLoadFirstHookOptions<P, D, M> | WithLoadFirstNoHookOptions<P, M>

//Consts
const defaultLoadingIndicator = (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '300px',
            paddingBottom: 50,
        }}
    >
        <PulseLoader size="large" />
    </div>
);

export default function WithLoadFirst<P extends {}, D = never, M extends {} = P>(
    checkIsLoaded: (props: P, data?: D) => boolean,
    {
        useCheckHook,
        onFirstLoad,
        mergeProps,
        loadingIndicator = defaultLoadingIndicator
    }: WithLoadFirstOptions<P, D>
) {
    return (Component: ComponentType<M>) => {
        const LoadFirstComponent: FC<P> = (props: P) => {
            const hasLoaded = useRef(false);
            const data = useCheckHook?.(props);

            if(!hasLoaded.current && checkIsLoaded(props, data)) {
                hasLoaded.current = true;
            }

            useEffect(
                //If when the component first mounts it is not loaded, call onFirstLoad
                () => {
                    if (!hasLoaded.current) {
                        onFirstLoad?.(props, data as any);
                    }
                },
                // eslint-disable-next-line react-hooks/exhaustive-deps
                [],
            );

            const mergedProps = useMemo(() => (mergeProps ? mergeProps(props, data as any) : props) as unknown as M, [props, data]);

            if (hasLoaded.current) {
                return <Component {...mergedProps} />;
            } else {
                return (loadingIndicator instanceof Function
                    ? loadingIndicator(props, data as any)
                    : loadingIndicator
                ) as ReactElement;
            }
        }

        LoadFirstComponent.displayName = `WithLoadFirst(${getDisplayName(Component)})`;

        return LoadFirstComponent;
    }
}

function getDisplayName<P extends {} = {}>(WrappedComponent: ComponentType<P>) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
