import typeToFormatter from 'hsi/utils/formatter';
import resultService from 'hsi/services/resultService';

import {LinkedinChannelIdsType, PageType} from 'hsi/types/shared';
import {QueryContextType} from 'hsi/types/query';
import {APIFilterFormat, Breakdowns, DateRange} from 'hsi/types/filters';
import {RootReducer} from 'hsi/reducers/rootReducer';
import {ChartKey} from 'hsi/types/charts';
import { ProcessedCardRules } from 'hsi/utils/cards/processCardRules';



export default function loadData(
    queryContext: QueryContextType,
    type: ChartKey,
    apiFilterParams: APIFilterFormat,
    dateRange: DateRange,
    additionalQueries: number[],
    cardPersistState: RootReducer['cardPersistState'],
    orderBy: string | undefined | null,
    limit: number | undefined,
    linkedinChannelIds: LinkedinChannelIdsType,
    rules: ProcessedCardRules | null | undefined,
    config: {
        themeColors: {[key: string]: any};
        pageTypes: PageType[];
    },
): Promise<any> {// This really should be typed, but we're probably going to totally change this, so not much point
    const hasAdditionalCall = !!rules?.additionalCall;

    //start loading data
    const dataLoadPromise = resultService.loadData({
        queryContext,
        type,
        apiFilterParams,
        dateRange,
        additionalQueries,
        orderBy,
        linkedinChannelIds,
        cardPersistState,
        limit,
    });

    const additionalDataLoadPromise = hasAdditionalCall
        ? resultService.loadData({
              queryContext,
              type: rules.additionalCall!,
              apiFilterParams,
              dateRange,
              additionalQueries,
              orderBy,
              linkedinChannelIds,
              cardPersistState,
          })
        : Promise.resolve(null);

    return Promise.all([dataLoadPromise, additionalDataLoadPromise]).then(
        ([data, otherResults]) => {
            //handle data loaded
            return typeToFormatter[type as keyof typeof typeToFormatter]({
                    data,
                    otherResults,
                    config: config as any,
                    breakdown: cardPersistState?.[type as keyof Breakdowns]?.breakdown,
                    additionalQueries,
                    queryContext,
                    linkedinChannelIds,
                    limit,
            });
        },
    );
}
