import {SearchType} from 'hsi/types/shared';
import getFiltersConfig from 'hsi/utils/filters/getFiltersConfig';
import {useMemo} from 'react';
import usePageTypes from './usePageTypes';
import useFlags from './useFlags';

export default function useGetFiltersConfig(
    searchType: SearchType,
    projectId: number,
    override?: boolean,
) {
    const pageTypes = usePageTypes(searchType === 'saved' || searchType === 'quick', projectId);

    const flags = useFlags();

    return useMemo(
        () =>
            !override
                ? getFiltersConfig(searchType, pageTypes, projectId, flags)
                : {config: {}, allFiltersConfig: {}},
        [searchType, pageTypes, projectId, override, flags],
    );
}
