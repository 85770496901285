import {validate} from './schema';

//XXX TODO temporarily use * as target origin
export const postOrigin = () => '*';
//const postOrigin = () => [
//'http://localhost:5000',
//'https://hootsuite-insights.stage.brandwatch.com',
//'https://hootsuite.com',
//].filter(origin => document.referrer.indexOf(origin) > -1)[0];

export const validate_ = (schema, data) => {
    if (!schema) return true;
    const d = validate(schema, data);
    if (!d.success) {
        console.warn('Received invalid message:', d.error);
        return false;
    }
    return true;
};

class Message {
    init = ({onMessage, messageParser, schema}) => {
        this.postOrigin = postOrigin();

        this.receive = (e) => {
            if (!validate_(schema, e.data)) return;
            if (typeof onMessage === 'function') onMessage(e.data);
        };

        window.addEventListener('message', this.receive);
        return this;
    };

    send = (e) => {
        if (e) window.parent.postMessage(e, this.postOrigin);
        return this;
    };

    dispose = () => {
        window.removeEventListener('message', this.receive);
        this.receive = null;
    };
}

export default Message;
