import React from 'react';

import useStyles from './styles';

export interface IconProps extends Omit<React.SVGAttributes<SVGSVGElement>, 'id'> {
    id?: number;
    title?: string;
    labelId?: string; // Add accessible name
    name: string;
    suppressIconClassName?: boolean;
    realName?: string; //For when the mapped name doesn't match the icon name, for legacy styles
}

//TODO do we ever use data-id? What if we need a real id?
const Icon = React.forwardRef<SVGSVGElement, IconProps>(function Icon(
    {className, id, labelId, name, suppressIconClassName, realName, title, ...rest},
    ref,
) {
    const {classes, cx} = useStyles();

    return (
        <svg //The 'app-icon' class is needed for where other classes apply styles to the icon
            aria-labelledby={labelId}
            className={cx(
                classes['app-icon'],
                'app-icon',
                !suppressIconClassName && 'icon-' + realName,
                className,
            )}
            data-id={id}
            data-testid="icon"
            ref={ref}
            {...rest}
        >
            {title && <title id={labelId}>{title}</title>}
            <use data-testid="iconUse" href={'#icon-' + name} />
        </svg>
    );
});

export default Icon;
