import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {T} from 'hsi/i18n';

import LinkText from '../Utils/LinkText';

const ErrorItem = ({action, classes, item}) => {
    const {isViewOnly} = useSelector((state) => state.user);

    // Show a title if there is one and use i18n if it's available
    if (!!item.title || !!item.titleId) {
        return (
            <div className="error-message-title">{item.titleId ? T(item.titleId) : item.title}</div>
        );
    }

    // If there isn't a CTA show the description if there is one and use i18n if it's available
    if (!item.linkId && (!!item.desc || !!item.descId)) {
        return <div className="error-message-desc">{item.descId ? T(item.descId) : item.desc}</div>;
    }

    // If its a view only user, just show the default description
    if (isViewOnly && !!item.desc) {
        return <div className="error-message-desc">{item.desc}</div>;
    }

    // If the user isn't view only and there is a link and CTA, convert that into a CTA
    if (!isViewOnly && !!action && !!item.linkId && !!item.descId) {
        return (
            <LinkText
                classes={classes}
                handleClick={action}
                link={T(item.linkId)}
                text={T(item.descId)}
            />
        );
    }

    //Default message if nothing matches
    return <div className="error-message-desc">{T('manualLogin.genericError')}</div>;
};

ErrorItem.propTypes = {
    action: PropTypes.func,
    classes: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
};

export default ErrorItem;
