//Somehow, there are conditions under which react does not seem to fire onBlur events, despoite focus leaving an element.
//This breaks this code.

import { FocusEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import useUniqueId from "./useUniqueId";

type UseTrackFocusWithinProps<T extends HTMLElement = HTMLElement> = {
    onFocus?: FocusEventHandler<T>;
    onBlur?: FocusEventHandler<T>;
};

/**
 * Keeps track of if focus is contained within a given element (including children). Takes into account react portals
 * @returns isFocusWithin prop + event handlers to apply to element to enable focus tracking
 */
export default function useTrackFocusWithin<T extends HTMLElement = HTMLElement>({onFocus: _onFocus, onBlur: _onBlur}: UseTrackFocusWithinProps<T> = {}) {
    const trackingProp = `__useDetectClickOutside_${useUniqueId()}`;
    const [isFocusWithin, setIsFocusWithin] = useState(false);

    const onFocus: FocusEventHandler<T> = useCallback((e) => {
        _onFocus?.(e);
        
        //Mark this event as having come from within the currently tracked element
        (e.nativeEvent as any)[trackingProp] = true;

        setIsFocusWithin(true);
    }, [_onFocus, trackingProp]);

    const onBlur: FocusEventHandler<T> = useCallback((e) => {
        _onBlur?.(e);

        if(e.target === e.currentTarget) {
            //blur event was generated by this element, so this has indeed blurred
            setIsFocusWithin(false);
        }
        
        //setIsFocusWithin(true);
    }, [_onBlur]);

    const onWindowFocus = useCallback((e: FocusEvent) => {
        const focusWithin = !!(e as any)[trackingProp];

        if(!focusWithin) {
            setIsFocusWithin(false);
        }
    }, [trackingProp]);

    const onWindowBlur = useCallback((e: FocusEvent) => {
        //somehow, sometimes element get blurred without the blur event apparently getting fired,
        //this seems to happen when focus returns to document.body, so we can detect that here
        if(document.activeElement === document.body) {
            setIsFocusWithin(false);
        }
    }, []);

    useEffect(() => {
        if(isFocusWithin) {
            document.addEventListener('focusin', onWindowFocus);
            document.addEventListener('focusout', onWindowBlur);
        } else {
            document.removeEventListener('focusin', onWindowFocus);
            document.removeEventListener('focusout', onWindowBlur);
        }

        return () => {
            document.removeEventListener('focusin', onWindowFocus);
            document.removeEventListener('focusout', onWindowBlur);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFocusWithin]);

    return useMemo(() => ({
        isFocusWithin,
        onFocus,
        onBlur,
    }), [isFocusWithin, onBlur, onFocus]);
}
