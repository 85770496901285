import {ReactElement, ReactNode} from 'react';

export type ConditionalWrapFunc = (children: ReactNode) => ReactNode;

type ConditionalWrapProps = {
    children: ReactNode;
    wrap: ConditionalWrapFunc;
};

export default function ConditionalWrap({wrap, children}: ConditionalWrapProps) {
    return wrap(children) as ReactElement;
}
