import {Router, Route, Switch} from 'react-router-dom';

import Alerts from 'hsi/containers/Alerts';
import DataManagement from 'hsi/containers/DataManagement';
import EditSavedSearch from 'hsi/containers/EditSavedSearch';
import ExportMentions from 'hsi/containers/ExportMentions';
import {FacebookAuthenticationDialogs} from 'hsi/containers/FacebookAuthentication';
import {handleAlertInvitePath, HandleAlertInvite} from 'hsi/containers/Alerts/HandleAlertInvite';
import HomeNavBar from 'hsi/containers/HomeNavBar';
import Home from './Home';
import Login from 'hsi/containers/Login';
import MentionsTable from 'hsi/containers/MentionsTable';
import NotFound from 'hsi/containers/NotFound';
import QuickSearchResults from 'hsi/containers/QuickSearchResults';
import Reports from 'hsi/containers/Reports';
import SavedSearches from 'hsi/containers/SavedSearches';
import SavedSearchResults from 'hsi/containers/SavedSearchResults';
import SnackBarNotification from 'hsi/components/SnackBarNotification';
import UnauthorizedMentionsTable from 'hsi/containers/MentionsTable/Unauthorized';
import {History} from 'history';
import {useAppSelector} from 'hsi/hooks/useRedux';
import AriaAnnounce from 'hsi/components/aria/Announce';
import Portal from 'hsi/components/Portal';
import TestBed from '../Testbed';
import ChurnZeroSnippet from 'hsi/containers/ChurnZero';

type AppProps = {history: History};

// Additional paths should be added to 'hsi/hooks/useTrackingLocation' in order
// for them to have correctly named mixpanel events
export default function App({history}: AppProps) {
    return (
        <>
            <Router history={history}>
                <Switch>
                    <Route exact path={handleAlertInvitePath} component={HandleAlertInvite} />

                    <Route exact path="/mentions-table/:id?">
                        <Login
                            unauthorized={(errors: any) => (
                                <UnauthorizedMentionsTable
                                    messageParserId="hootsuite"
                                    errors={errors}
                                />
                            )}
                        >
                            <MentionsTable messageParserId="hootsuite" />
                        </Login>
                    </Route>

                    <Route exact path="/search/results/:projectId/:id/export-mentions/:count">
                        <Login>
                            <ExportMentions />
                        </Login>
                    </Route>

                    {process.env.REACT_APP_DEPLOYMENT_TYPE !== 'production' && (
                        <Route path="/testbed">
                            <TestBed />
                        </Route>
                    )}

                    <Route>
                        <Login>
                            <Portal target="header">
                                <HomeNavBar />
                            </Portal>
                            <Switch>
                                <Route exact path="/" component={Home} />
                                <Route exact path="/searches" component={SavedSearches} />
                                <Route
                                    exact
                                    path="/search/results/:projectId/:id"
                                    component={SavedSearchResults}
                                />

                                <Route
                                    exact
                                    path="/search/edit/:projectId/:id"
                                    component={EditSavedSearch}
                                />
                                <Route
                                    exact
                                    path="/search/results"
                                    component={QuickSearchResults}
                                />
                                <Route path="/data-management" component={DataManagement} />
                                <Route exact path="/alerts" component={Alerts} />
                                <Route exact path="/reports" component={Reports} />
                                <Route component={NotFound} />
                            </Switch>
                            <SnackBarNotification />
                            <FacebookAuthenticationDialogs />
                            <ChurnZeroSnippet />
                        </Login>
                    </Route>
                </Switch>
            </Router>
            <ConnectedAriaAnnounce />
        </>
    );
}

function ConnectedAriaAnnounce() {
    const ariaMessages = useAppSelector((state) => state.ariaAnnounce.messages);

    return <AriaAnnounce messages={ariaMessages} />;
}
