import Banner from 'hsi/components/Banner';
import Button from 'hsi/components/Button';
import DialogContainer from 'hsi/containers/DataManagement/AddDataSourceDialog/DialogContainer';
import {AuthenticateFacebook} from 'hsi/containers/FacebookAuthentication';

import useStyles from './styles';

import {T} from 'hsi/i18n';
import tns from 'hsi/i18n/tns';

const t = tns('dataManagement.addDataSourcesDialog.addOwnedFacebookPagesAndInstagramAccounts');

type AddOwnedFacebookPagesAndInstsgramAccountsProps = {
    onBack: () => void;
    onClose: () => void;
};

const AddOwnedFacebookPagesAndInstsgramAccounts = ({
    onBack,
    onClose,
}: AddOwnedFacebookPagesAndInstsgramAccountsProps) => {
    const classes = useStyles();

    return (
        <DialogContainer
            actions={
                <Button className={classes.backButton} onClick={onBack}>
                    {T('back')}
                </Button>
            }
            onClose={onClose}
            title={t('title')}
        >
            <div className={classes.container}>
                <p>{t('desc')}</p>
                <AuthenticateFacebook className={classes.facebookButtons} onLogin={onClose} />
                <Banner className={classes.infoBanner}>{t('infoBanner')}</Banner>
            </div>
        </DialogContainer>
    );
};

export default AddOwnedFacebookPagesAndInstsgramAccounts;
