//TODO remove old messages?

import {
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import useGetIsMounted from './useGetIsMounted';
import useUniqueId from './useUniqueId';

function defaultAnnounceStub() {
    console.log('ARIA announce method not defined - messages will not be announced');
}

//Types

type Priority = 'polite' | 'assertive' | 'alert';

export type AriaAnnounceProviderProps = PropsWithChildren<{
    disabled?: boolean;
}>;

//Consts
const ARIAAnnounceContext =
    createContext<(message: string, priority?: Priority) => void>(
        defaultAnnounceStub,
    );

/**
 * Minimum time elapsed before a message is considered 'old' and can be removed.
 */
const removeMessageTimeThreshold = 10000;
/**
 * Time, in ms to delay after adding a message to check if there are messages to be removed.
 * Should be larger than value for removeMessageTimeThreshold
 */
const removeMessageCheckDelay = 20000;

/**
 * Hook to provide an announce function, use to announce messages to screen-reader users
 * @returns announce function
 */
export default function useAriaAnnounce() {
    return useContext(ARIAAnnounceContext)!;
}


/**
 * The announce provider. Creates the context & renders the elements to make the announcements. Only components 
 * within a provider can use the useAriaAnnounce hook
 * @param props 
 * @returns 
 */
export function AriaAnnounceProvider({children, disabled}: AriaAnnounceProviderProps) {
    const id = useUniqueId();
    const mIdRef = useRef(0);
    const [messages, setMessages] = useState<
        {id: string; time: number; message: string; priority: Priority}[]
    >([]);

    const isMounted = useGetIsMounted();

    const removeMessages = useCallback(() => {
        // Check if the A11yAnnounceProvider component has been unmounted
        if (!isMounted()) {
            return;
        }
        setMessages(
            (messages) =>
                // Remove a message if it has been in the messages array for longer than 10 seconds
                (messages = messages.filter((message) => Date.now() - message.time < removeMessageTimeThreshold))
        );
    }, [isMounted]);

    const announce = useCallback(
        (message: string, priority: Priority = 'polite') => {
            if(!isMounted()) {
                return;
            }

            !disabled &&
                setMessages((messages) => [
                    ...messages,
                    {id: `${id}-${mIdRef.current++}`, time: Date.now(), message, priority},
                ]);
            
            setTimeout(removeMessages, removeMessageCheckDelay);
        },
        [disabled, id, isMounted, removeMessages],
    );

    //If this is disabled, clear all messages
    useEffect(() => {
        if (disabled) {
            setMessages([]);
        }
    }, [disabled]);

    return (
        <ARIAAnnounceContext.Provider value={announce}>
            {children}
            <div aria-live="polite" style={{position: 'absolute', opacity: 0,}}>
                {messages.filter(({priority}) => priority === 'polite').map(({message, id }) => {
                    return (
                        <p key={id}>
                            {message}
                        </p>
                    );
                })}
            </div>
            <div aria-live="assertive" style={{position: 'absolute', opacity: 0,}}>
                {messages.filter(({priority}) => priority === 'assertive').map(({message, id }) => {
                    return (
                        <p key={id}>
                            {message}
                        </p>
                    );
                })}
            </div>
            {messages.filter(({priority}) => priority === 'alert').map(({ message, id }) => {
                return <p role="alert" key={id} className="offscreen">
                    {message}
                </p>
            })}
        </ARIAAnnounceContext.Provider>
    );
}
