
import { ReactNode } from 'react';
import {Link, LinkProps} from 'react-router-dom';
import IconButton, {IconButtonProps} from '@mui/material/IconButton';

//Components
import IconRouter from 'hsi/components/IconRouter';
import Heading from 'hsi/components/aria/Heading';

//Other
import useStyles from './styles';
import { T } from 'hsi/i18n';

interface IconButtonStripNavigateProps extends Partial<IconButtonProps> {
    navigate: LinkProps['component'];
}

//Consts
const IconButtonStripNavigate = ({navigate, ...props}: IconButtonStripNavigateProps) => (
    <IconButton {...props} size="large" />
);

//types
export type HeaderBarProps = {
    backButtonUrl?: string;
    backButtonLbl?: string;
    title?: ReactNode;
    titleComponent?: React.ElementType;
} & Omit<JSX.IntrinsicElements['div'], 'title'> 
  & {[key: `data-${string}`]: string};

//The component
export default function HeaderBar({
    backButtonUrl,
    backButtonLbl = T('back'),

    title,
    titleComponent: TitleComponent = Heading,

    className,
    children,
    ...rest
}: HeaderBarProps) {
    const {classes, cx} = useStyles();

    return <div className={cx(classes.headerBar, className)} {...rest}>
        {!!backButtonUrl && (
            <Link
                aria-label={backButtonLbl}
                className={classes.backBtn}
                component={IconButtonStripNavigate}
                to={backButtonUrl}
            >
                <IconRouter name="arrow-back" className={classes.backBtnIcon} aria-hidden />
            </Link>
        )}
        {!!title && (
            <TitleComponent className={classes.title}>
                {title}
            </TitleComponent>
        )}
        {children}
    </div>
}
