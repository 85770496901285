import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);

    return {
        tags: {
            flex: '0 0 100%',
            justifyContent: 'flex-start',
            position: 'relative',
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '8px',

            '@media print': {
                gap: 'unset',
                '-webkit-print-color-adjust': 'exact !important',

                '&:not(:empty)': {
                    margin: '0 -4px',
                },
                '& > *': {
                    margin: '4px',
                },
            },
        },

        addTag: {
            ...theme.mixin.blankButton,
            ...theme.mixin.linkColor,
            display: 'flex',
            flex: '0 0 auto',
            margin: '0px 4px 0 0px',
            alignItems: 'center',
            cursor: 'pointer',

            '& .app-icon': {
                fill: 'inherit',
                height: theme.v2 ? '16px' : '16px',
                width: theme.v2 ? '16px' : '16px',
            },
            '& > span': {
                fontSize: '12px',
                fontWeight: theme.font.semiBold,
                marginLeft: '5px',
                textTransform: 'uppercase',
            },

            '&.disabled': {
                opacity: 0.5,
            },
        },

        addFirstTag: {
            ...theme.mixin.linkColor,
            cursor: 'pointer',
            fontSize: '14px',
            fontWeight: 700,
            display: 'flex',
            justifyContent: 'center',
            '& span': {
                marginLeft: '5px',
                fontWeight: theme.font.bold,
            },
            '& .app-icon': {
                fill: 'inherit',
                height: '16px',
                width: '16px',
                marginTop: '2px',
            },
        },

        withEllipsis: {
            '& .addTag': {
                flex: '0 0 auto',
            },
            '&.tags': {
                flexWrap: 'nowrap',
            },
        },

        overflowTagsHolder: {
            flex: '1 1 auto',
        },

        hiddenChips: {
            fontSize: '14px',
            whiteSpace: 'normal',
        },

        loader: {},

        disabled: {},

        autocompleteRoot: {
            '--autocomplete-input-margin': v2 ? '6px 15px' : '6px 9px',
        },
    };
});
