import makeStyles from '@mui/styles/makeStyles';

import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles((theme: Theme) => {
    const v2 = isV2(theme);

    return {
        contents: {
            maxWidth: '720px',
            margin: '0 auto',
        },
        cardArea: {
            flex: `0 0 auto`,
            padding: '0',
            margin: '0 auto',
            position: 'relative',
        },
        sectionWrapper: {
            position: 'relative',
            '@media print': {
                '-webkit-print-color-adjust': 'exact',
                pageBreakInside: 'avoid',
            },
            '.printMedia &': {
                '-webkit-print-color-adjust': 'exact',
                pageBreakInside: 'avoid',
            },
        },

        annotations: {
            margin: v2 ? '22px 10px' : '22px 0',

            ...(v2
                ? theme.fonts.bodyLargeStrong
                : {
                      fontSize: '16px',
                      fontWeight: 'bold',
                  }),
        },

        text: {
            marginTop: '3px',
            fontWeight: v2 ? theme.fonts.bodyLargeNormal.fontWeight : 'normal',
            padding: v2 ? '12px 28px' : '12px',
            wordWrap: 'break-word',
            // to avoid changing export settings to allow backgrounds
            boxShadow: `inset 0 0 0 1000px ${
                v2 ? theme.colors.bgDisabled : theme.colors.lightGrey10
            }`,
            borderRadius: v2 ? '3px' : undefined,
        },
    };
});
