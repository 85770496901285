import React, {useMemo, forwardRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import CardLoadState from 'hsi/components/Card/CardLoadState';
import HorizontalStackedBarChart from 'hsi/components/HorizontalStackedBarChart';
import CardTitle from 'hsi/components/Card/CardTitle';

import {mentionsDrillIn} from 'hsi/slices/mentions';
import useGetLoadData from '../useGetLoadData';
import useEventTrack from 'hsi/hooks/useEventTrack';

import {hasCategoriesData} from 'hsi/utils/chart';

//Consts
const type = 'sentimentVolumeBySearch';

//The component
const SentimentVolumeBySearch = forwardRef(({title, ...props}, ref) => {
    const dispatch = useDispatch();
    const {trackWithSearchData} = useEventTrack();
    const {data, loading, loaded, error} = useSelector((state) => state.chart[type]);
    const hasData = useMemo(() => hasCategoriesData(data), [data]);

    const loadData = useGetLoadData(type);

    const drillIn = useCallback(
        (drillIn) => {
            const drillinValue = drillIn.id;
            const drillinFilter = {
                sentiment: {[drillinValue]: true},
                queryId: drillIn.query.id?.toString(),
            };
            const label = `${drillIn.query.name} - ${drillIn.name}`;
            trackWithSearchData('cardDrilledIn', {
                type,
                value: label,
            });
            dispatch(mentionsDrillIn(drillinFilter, type, null, label));
        },
        [dispatch, trackWithSearchData],
    );

    const height = props.height || (data?.results?.length ?? 1) * 75 + 50;

    return (
        <CardLoadState
            {...props}
            error={error}
            hasData={hasData}
            loaded={loaded}
            loading={loading}
            title={<CardTitle title={title} searchType="multiple" type={type} hasData={hasData} />}
            loadData={loadData}
            data-testid={type}
            type={type}
            ref={ref}
        >
            <HorizontalStackedBarChart data={data} height={height} onClickBar={drillIn} />
        </CardLoadState>
    );
});

SentimentVolumeBySearch.propTypes = {
    fadeOnVisible: PropTypes.object,
    height: PropTypes.number,
    isVisible: PropTypes.bool,
    onContentChanged: PropTypes.func,
    showConfig: PropTypes.bool,
};

SentimentVolumeBySearch.displayName = 'SentimentVolumeBySearch';

export default SentimentVolumeBySearch;
