import React, {useEffect, useState} from 'react';
import * as configcat from 'configcat-js';
import {useAppSelector} from 'hsi/hooks/useRedux';
import useConfig from 'hsi/hooks/useConfig';
import {CCFlagsType} from 'hsi/types/cc-flags';

export type CCContextType = {
    CCFlags: CCFlagsType;
};

export const CCFlagsContext = React.createContext<CCFlagsType | null>(null);
CCFlagsContext.displayName = 'CCFlagsContext';

interface Props {
    children: React.ReactNode;
}

const CCFlagsProvider: React.FC<Props> = ({children}) => {
    const {settings, account} = useAppSelector((state) => state.user);
    const [CCFlags, setData] = useState<any | null>(null);
    const {appSource} = useConfig();

    const getClient = async (settings: any) => {
        const configCatClient = configcat.getClient(settings.CONFIGCAT_SDK_KEY);
        const userObject = new configcat.User(account.id, account.userEmail, undefined, {
            clientId: account.client.id,
            appSource: appSource,
        });
        const CCFlags = {};
        return await configCatClient.getAllValueDetailsAsync(userObject).then((flags: any) => {
            flags.forEach((item: any) => Object.assign(CCFlags, {[item.key]: item.value}));
            setData(CCFlags);
        });
    };

    useEffect(() => {
        getClient(settings);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <CCFlagsContext.Provider value={CCFlags}>{children}</CCFlagsContext.Provider>;
};

export default CCFlagsProvider;
