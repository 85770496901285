//DO NOT USE
//TODO replace all uses of this with new Select component

import {useCallback, useMemo, useState} from 'react';
import cn from 'classnames';
import find from 'lodash/find';
import isObject from 'lodash/isObject';

//import Fade from '@mui/material/Fade';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import IconRouter from 'hsi/components/IconRouter';
import PulseLoader from 'hsi/components/PulseLoader';
import SearchBox from 'hsi/components/SearchBox';
import TextField from 'hsi/components/TextField';
import Popover, {PopoverRenderFunc, sizeToOpener} from 'hsi/components/Popover';

import useStyles from './styles';

import {T} from 'hsi/i18n';

export type SelectFieldOption = {
    disabled?: boolean;
    label: string;
    secondaryAction?: string;
    value: any;
};

export type SelectFieldProps = {
    className?: string;
    dense?: boolean;
    disabled?: boolean;
    filterMinOpts?: number;
    fullWidth?: boolean;
    inputProps?: any;
    InputProps: any;
    label?: string;
    loading?: boolean;
    menuBottomContent?: React.ReactNode;
    onChange: (option: SelectFieldOption) => void;
    onClose?: () => void;
    onOpen?: () => void;
    options: SelectFieldOption[];
    placeholder?: string;
    readOnly?: boolean;
    value?: SelectFieldOption | string;
    withArrow?: boolean;
    withFilter?: boolean;
    id?: string;
};

//The component
const SelectField = ({
    className,
    dense = false,
    disabled,
    filterMinOpts = 7,
    fullWidth = true,
    inputProps,
    InputProps,
    label,
    loading,
    menuBottomContent,
    onChange,
    onClose,
    onOpen,
    options = [],
    placeholder = T('pleaseSelect'),
    readOnly,
    value,
    withArrow = false,
    withFilter = true,
    id,
    ...rest
}: SelectFieldProps) => {
    const classes = useStyles();

    const selectedLabel = isObject(value)
        ? value.label
        : (value !== undefined
              ? (find(options, (option) => option.value === value) || {}).label
              : value) || '';

    const popoverContent = useCallback<PopoverRenderFunc>(
        ({close, labelId, descriptionId}) => {
            return (
                <SelectFieldMenu
                    labelId={labelId}
                    descriptionId={descriptionId}
                    withFilter={withFilter}
                    menuBottomContent={menuBottomContent}
                    options={options}
                    filterMinOpts={filterMinOpts}
                    onClose={() => {
                        onClose?.();
                        close();
                    }}
                    onChange={onChange}
                />
            );
        },
        [withFilter, menuBottomContent, options, filterMinOpts, onChange, onClose],
    );

    return (
        <div className={className}>
            <Popover
                content={popoverContent}
                size={sizeToOpener}
                boundary={window.document.body}
                distance={positionOverOpener}
                portal
            >
                <button
                    className={classes.selectInputWrapper}
                    id={id}
                    disabled={disabled || loading}
                >
                    <span className="offscreen">{label}</span>
                    <TextField
                        aria-hidden="true"
                        fullWidth={fullWidth}
                        value={selectedLabel}
                        label={label}
                        placeholder={placeholder}
                        InputProps={{
                            readOnly: true, //So, we are presenting this to the user as a readonly textfield, and just... hoping they realise that clicking it will actually open a menu to edit it?
                            startAdornment: !!loading && (
                                <div className={classes.loadingContainer}>
                                    <PulseLoader size="small" className={classes.loadingAnim} />
                                </div>
                            ),
                            classes: {
                                root: classes.textFieldRoot,
                                adornedEnd: classes.textFieldAdornedEnd,
                                notchedOutline: classes.textFieldNotchedOutline,
                            },
                            inputProps: {
                                style: {cursor: 'pointer'}, //Why isn't this done with CSS classes?
                                ...inputProps,
                            },
                            disabled: disabled || loading,
                            ...InputProps,
                        }}
                        endAdornment={
                            <InputAdornment
                                position="end"
                                classes={{
                                    positionEnd: cn(classes.inputAdornment, dense && classes.dense),
                                }}
                            >
                                <IconRouter
                                    className={cn(classes.dropDownIcon, dense && classes.denseIcon)}
                                    name="down-arrow"
                                />
                            </InputAdornment>
                        }
                        disabled={disabled || loading}
                        {...rest}
                    />
                </button>
            </Popover>
        </div>
    );
};

export default SelectField;

const positionOverOpener: Parameters<typeof Popover>[0]['distance'] = (props) => {
    return -props.rects.reference.height;
};

type SelectFieldMenuProps = {
    withFilter: boolean;
    value?: SelectFieldOption | string;
    options: SelectFieldOption[];
    menuBottomContent?: React.ReactNode;
    filterMinOpts: number;
    onClose: () => void;
    onChange: (option: SelectFieldOption) => void;
    labelId: string;
    descriptionId: string;
};

function SelectFieldMenu({
    withFilter,
    menuBottomContent,
    value,
    options,
    filterMinOpts,
    onClose,
    onChange,
    labelId,
    descriptionId,
}: SelectFieldMenuProps) {
    const classes = useStyles();

    const [filterText, setFilterText] = useState('');
    const [selectIndex, setSelectIndex] = useState(-1);

    const showFilter = withFilter && options?.length >= filterMinOpts;

    const onItemClick = useCallback(
        (event: React.MouseEvent, item: SelectFieldOption) => {
            event.stopPropagation();
            event.preventDefault();

            if (!item.disabled) {
                onChange?.(item);
                onClose();
            }
        },
        [onClose, onChange],
    );

    const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(event.target.value);
        setSelectIndex(-1);
    };

    const filteredOptions = useMemo(() => {
        return options.filter((item) =>
            (item?.label ? item.label : typeof item === 'string' ? item : '')
                .toLowerCase()
                .includes(filterText.toLowerCase()),
        );
    }, [filterText, options]);

    const onKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
            ['ArrowDown', 'ArrowUp'].includes(event.code) && event.preventDefault();

            if (event.code === 'ArrowDown') {
                // down
                if (selectIndex + 1 < filteredOptions.length) {
                    setSelectIndex((currentSelectIndex) => currentSelectIndex + 1);
                }
            } else if (event.code === 'ArrowUp') {
                // up
                if (selectIndex >= 0) {
                    setSelectIndex((currentSelectIndex) => currentSelectIndex - 1);
                }
            } else if (event.code === 'Enter') {
                // enter
                if (selectIndex >= 0 && selectIndex < filteredOptions.length) {
                    onChange?.(filteredOptions[selectIndex]);
                    onClose?.();
                }
            }
        },
        [filteredOptions, onChange, onClose, selectIndex],
    );

    const selectedValue =
        typeof value != 'undefined' ? (isObject(value) ? value.value : value) : '';

    return (
        <div className={classes.menuContent}>
            {showFilter && (
                <div className={classes.searchFieldWrapper} onKeyDown={onKeyDown}>
                    <SearchBox
                        fullWidth
                        onChange={onFilterChange}
                        value={filterText}
                        placeholder={T('filterOptions')}
                    />
                </div>
            )}
            {!!filteredOptions.length && (
                <List className={classes.menuList}>
                    {filteredOptions.map((option, i) => (
                        <ListItem
                            key={option.value}
                            aria-disabled={!!option.disabled}
                            aria-label={option.label}
                            className={classes.listItem}
                            // This is not actually valid HTML so we set the aria-disabled property as well. Should be changed.
                            disabled={option.disabled}
                            onClick={
                                option.disabled ? () => null : (event) => onItemClick(event, option)
                            }
                            selected={selectIndex === i || option.value === selectedValue}
                            value={option.value}
                        >
                            <ListItemText>{option.label}</ListItemText>
                            {!!option?.secondaryAction && (
                                // If you actually want a secondary action, it needs to be set on the ListItem component.
                                // This will correctly make the button and list item seperately focusable
                                <ListItemText className={classes.listItemSecondaryAction}>
                                    {option.secondaryAction}
                                </ListItemText>
                            )}
                        </ListItem>
                    ))}
                </List>
            )}
            {!filteredOptions.length && (
                <div className={classes.noOptionsFound}>{T('selectField.noOptionsFound')}</div>
            )}
            {!!menuBottomContent && menuBottomContent}
        </div>
    );
}
