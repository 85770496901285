import makeStyles from '@mui/styles/makeStyles';

import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles((theme: Theme) => {
    const v2 = isV2(theme);

    return {
        root: {
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'stretch',
            border: `1px solid ${theme.colors.borders}`,
            borderRadius: v2 ? '4px' : undefined,
            padding: '20px',
            gap: '20px',
            textDecoration: 'unset',
            color: 'unset',

            '&:visited, &:hover': {
                color: 'unset',
            },
        },

        content: {
            flex: '1 1 0',
            overflow: 'hidden',
        },

        media: {
            flex: '1 1 0',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            maxHeight: '500px',
        },

        image: {
            width: '100%',
            height: 'auto',
            display: 'block',
            maxWidth: 'none',
        },

        video: {
            width: '100%',
        },
    };
});
