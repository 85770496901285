import {isV2} from 'hsi/types/theme';
import {makeStyles} from 'tss-react/mui';

export default makeStyles()((theme) => {
    const v2 = isV2(theme);

    return {
        exportMenuBtn: {
            display: 'grid',
            gridAutoFlow: 'column',
            gap: '8px',
            padding: '0',

            ...(v2
                ? {
                      color: theme.colors.textDefault,
                      borderRadius: '3px',

                      '& > .MuiTouchRipple-root': {
                          display: 'none',
                      },
                      '&.disabled': {
                          opacity: 0.5,
                      },
                  }
                : {
                      padding: '9px',
                      '&.disabled': {
                          opacity: 0.5,
                      },
                  }),
        },

        exportMenuIcon: v2
            ? {
                  fill: `${theme.colors.textDefault}!important`,

                  width: '18px',
                  height: '18px',
              }
            : {},
        exportMenuLabel: {
            fontWeight: 500,
            ...(theme.v2
                ? {
                      ...theme.fonts.bodyStrong,
                      paddingLeft: '4px',
                      position: 'relative',
                  }
                : {...theme.mixin.offscreen}),
        },
        exportsDisabled: {
            padding: 16,
            maxWidth: 240,
        },
    };
});
