import {AnyAction} from 'redux';

import {EXPORTED_PDF, EXPORTED_CARD, EXPORT_CARD} from 'hsi/constants/actionTypes';
import {AnnotationKey, ChartKey} from 'hsi/types/charts';
import {QueryContextType} from 'hsi/types/query';

export type ExportReducerState = {
    annotations: {
        [key: QueryContextType['savedSearchId']]: Partial<Record<AnnotationKey, string>>;
    };
    cardsToExport: {[key: QueryContextType['savedSearchId']]: ChartKey[]};
    currentCard: ChartKey | null;
    format?: string; // TODO: Type this
    isExporting?: boolean;
    isPortalEnabled: boolean;
    queryName?: string;
    title: string;
};

const initialState: ExportReducerState = {
    annotations: {},
    cardsToExport: [],
    currentCard: null,
    isPortalEnabled: false,
    format: 'pdf',
    title: '',
};

const exportReducer = (state = initialState, action: AnyAction): ExportReducerState => {
    switch (action.type) {
        case EXPORTED_PDF:
        case EXPORT_CARD:
            return {...state, ...action.payload};

        case EXPORTED_CARD:
            return {
                ...state,
                currentCard: null,
                isExporting: false,
                isPortalEnabled: false,
                title: initialState.title,
            };
        default:
            return state;
    }
};

export default exportReducer;
