import {DefaultOptionComponent, RenderOptionComponentType} from 'hsi/components/Autocomplete';

//Components
import IconRouter from 'hsi/components/IconRouter';
import OverflowTooltip from 'hsi/components/Tooltip/OverflowTooltip';
import Tooltip from 'hsi/components/Tooltip';

//Utils
import stopPropagation from 'hsi/utils/html/stopPropagation';

//Other
import useStyles from './styles';
import {T} from 'hsi/i18n';

//Types
import {AddOrCreateOption} from '..';

//The component
const RenderOptionComponent: RenderOptionComponentType<AddOrCreateOption> = (props) => {
    const {classes} = useStyles();

    if (props.option.isNew) {
        return (
            <div
                className={classes.addOrCreateTagOption}
                aria-label={T('addOrCreateTag.create', {tagName: props.inputValue})}
            >
                <span aria-hidden className={classes.label}>
                    {props.inputValue}
                </span>

                <div className={classes.contentRight} aria-hidden>
                    <span className={classes.addNewHint}>{T('addOrCreateTag.add')}</span>
                    <IconRouter name="reply" className={classes.addNewIcon} />
                </div>
            </div>
        );
    }

    const option = (
        <div className={classes.addOrCreateTagOption}>
            <IconRouter name="tag" aria-hidden className={classes.icon} />
            <OverflowTooltip
                open={props.isHighlighted ? true : props.disabled ? false : undefined}
                tooltip={<DefaultOptionComponent {...props} />}
                placement="right"
                getPositionElement={getPositionElement}
            >
                <span className={classes.label}>
                    <DefaultOptionComponent {...props} />
                </span>
            </OverflowTooltip>
        </div>
    );

    return props.disabled ? (
        <div className={classes.disabledWrapper} onClick={stopPropagation}>
            <Tooltip portal tooltip={T('addOrCreateTag.alreadyAdded')} placement="right" noAria>
                {option}
            </Tooltip>
        </div>
    ) : (
        option
    );
};

export default RenderOptionComponent;

function getPositionElement(e: Element) {
    return e.parentElement;
}
