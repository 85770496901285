import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles(({colors, font, mixin, fonts, v2}: Theme) => ({
    inputLabel: {
        ...(v2 ? fonts.bodyLargeStrong : {fontSize: 14, fontWeight: font.semiBold}),
    },
    input: {
        marginTop: v2 ? 22 : 0,
        '& .MuiInputBase-root': {
            height: 240,
        },
    },
    cta: {
        border: 'none',
        color: colors.secondaryBlue100,
        width: 120,
        '&.MuiButtonBase-root': {
            justifyContent: 'space-between',
            padding: '0px 10px !important',
        },
    },
    ctaIcon: {
        fill: `${colors.secondaryBlue100} !important`,
        margin: 2,
        ...mixin.icon.normalSize,
    },
    infoButton: {
        padding: 0,
    },
    uploadButton: {
        border: v2 ? `1px solid ${colors.borders}` : `1px solid ${colors.darkGrey60}`,
        borderRadius: 0,
        color: colors.secondaryBlue100,
        marginBottom: 2,
        padding: '0px 12px',
        position: 'relative',
        top: '-1px',
        '&.MuiButtonBase-root': {
            justifyContent: 'flex-start',
        },
    },
    uploadButtonIcon: {
        fill: colors.secondaryBlue100,
        marginRight: 8,
    },
    inputErrorWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
    },
    inputErrorButton: {
        ...mixin.linkButton,
        cursor: 'pointer',
        height: 'unset',
        '& .MuiButton-label': {
            ...(v2 ? fonts.bodyStrong : undefined),
            textTransform: 'lowercase !important',
        },
    },
}));
