//This file is obsolete, all below configuration should be move to the relevent config files

let CONFIG_BY_APP_SOURCE = {
    HSI: {
        appName: 'Insights',
    },
    BW: {
        appName: 'Insights',
    },
    FALCON: {
        appName: 'Listen',
    },
    C3: {
        appName: 'Social Listening',
    },
};

//TODO: a better way to deal with app source configurartions
CONFIG_BY_APP_SOURCE.NGC3 = CONFIG_BY_APP_SOURCE.C3;
CONFIG_BY_APP_SOURCE.LUQI = {...CONFIG_BY_APP_SOURCE.C3};
CONFIG_BY_APP_SOURCE.C1 = {...CONFIG_BY_APP_SOURCE.C3};

export {CONFIG_BY_APP_SOURCE};
