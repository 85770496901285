import qs from 'qs';

//import {isProduction} from 'hsi/utils/url';
const IS_PRODUCTION = process.env.DEPLOYMENT_TYPE === 'production';
const BYPASS_CC = process.env.REACT_APP_BYPASS_FE_CC === 'true';

export const bypassCC =
    (!IS_PRODUCTION && BYPASS_CC) || !!qs.parse(document.location.search.replace('?', '')).bypassCC;

export default function useBypassCC() {
    // TODO How do we prevent this in production? An env flag?
    return bypassCC;
}
