import {forwardRef, useCallback, useMemo} from 'react';
import isEmpty from 'lodash/isEmpty';

//Components
import CardTitle, { formatCardTitleAsString } from 'hsi/components/Card/CardTitle';
import InfoPopupContent from 'hsi/components/InfoPopupContent';
import Sparkline from 'hsi/components/Sparkline';
import { AddUniqAuthorsCTA } from 'hsi/components/UniqAuthorsCTA';
import { HelpIconButton } from 'hsi/components/HelpIcon';
import Tooltip from 'hsi/components/Tooltip';
import useFormatRows from 'hsi/components/table/ManagedTable/useFormatRows';
import CompactNumber from 'hsi/components/format/CompactNumber';
import NumberWithPercentMeter from 'hsi/components/NumberWithPercentMeter';
import ManagedGridTable from 'hsi/components/table/ManagedGridTable';
import CardLoadState from 'hsi/components/Card/CardLoadState';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useIsSavedSearch from 'hsi/hooks/useIsSavedSearch';
import { useAppSelector } from 'hsi/hooks/useRedux';
import useCardData from 'hsi/hooks/useCardData';
import useDrillInMentions from 'hsi/hooks/useDrillInMentions';
import useAddPositionToRows, { WithPosition } from '../hooks/useAddPositionToRows';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';
import { getInterestsDrillInFilters } from 'hsi/utils/filters/drillInFilters';

//Types
import { CardTypeProps, TopInterestsType } from 'hsi/types/cards';
import { ColumnDefinition, FormatColumnDefinition } from 'hsi/components/table/ManagedTable/types';
import { CheckboxesFilterState } from 'hsi/types/filters';

//Types
type TopLanguageWithPositionType = WithPosition<TopInterestsType>;
type Columns = keyof TopLanguageWithPositionType;
type TableColumnDefinition = ColumnDefinition<Columns> & FormatColumnDefinition<TopLanguageWithPositionType, Columns>;

//Constants
const type = 'topinterests';
const DEFAULT_DATA: TopInterestsType[] = [];

//The components
const TopInterests = forwardRef<HTMLDivElement, CardTypeProps>(({title, ...props}, ref) => {
    const {
        links: {dashboardInfoTopInterestsCTA: popupCTA},
    } = useConfig();
    
    const drillInMentions = useDrillInMentions();
    const getDrillInData = useGetDrillInData();

    //Redux
    const filtersPagesTypes = useAppSelector((state) => state.filters.filters?.pageType as CheckboxesFilterState | null);
    const {data, loading, loaded, error, loadData, isCardSelected} = useCardData({
        type,
        dataSelector: (state) => state.chart[type],
    });
    

    //Calculated values
    const hasData = !!data && !isEmpty(data);

    // Add position property to row data
    const positionedRows = useAddPositionToRows(data ?? DEFAULT_DATA);

    const columns = useGetColumns(positionedRows);

    // Apply formatting
    const formattedRows = useFormatRows(positionedRows, columns);

    // //TODO need to do something with this
    const notAvailablePerDataLimitation = useMemo(() => {
        if (
            !!filtersPagesTypes &&
            Object.values(filtersPagesTypes).some((value) => value === true) &&
            (filtersPagesTypes.twitter === false || filtersPagesTypes.twitter === undefined)
        ) {
            return true;
        }
        return false;
    }, [filtersPagesTypes]);

    const popup = useMemo(
        () => (
            <InfoPopupContent
                title={T(`cards.${type}.info.title`)}
                copy={T(`cards.${type}.info.copy`)}
                ctaUrl={popupCTA}
                ctaLabel={T('cards.infoCTALabel')}
            />
        ),
        [popupCTA],
    );

    return (
        <CardLoadState
            title={
                <CardTitle
                    title={title}
                    type={type}
                    tooltipComponent={popup}
                    hasData={hasData}
                />
            }
            notAvailablePerDataLimitation={notAvailablePerDataLimitation}
            error={error}
            hasData={hasData}
            loading={loading}
            loaded={loaded}
            loadData={loadData}
            selected={isCardSelected}
            ref={ref}
            data-testid={type}
            type={type}
            {...props}
        >
            <AddUniqAuthorsCTA type={type}>
                <ManagedGridTable
                    columns={columns}
                    rowData={formattedRows}
                    caption={T('managedTable.caption', {name: formatCardTitleAsString(title)})}

                    rowSelectable
                    onRowClick={(index: number) => {
                        drillInMentions(getDrillInData(positionedRows[index]));
                    }}
                    sortColumn="total"
                    sortDirection="desc"
                />
            </AddUniqAuthorsCTA>
        </CardLoadState>
    );
});

TopInterests.displayName = 'TopInterests';

export default TopInterests;


function useGetColumns(data: TopInterestsType[]): TableColumnDefinition[] {
    const classes = useStyles();
    const {
        themeColors: {sparkline: sparklineColor},
    } = useConfig();

    const isSavedSearch = useIsSavedSearch();

    return useMemo<TableColumnDefinition[]>(() => {
        const columns: TableColumnDefinition[] = [
            {
                name: 'position',
                label: <span className="offscreen">{T('cards.topinterests.tableFields.position')}</span>,
                horizontalAlign: 'start',
                width: 'auto',
                format: (row) => <span className={classes.tableRowPosition}>{row.position}</span>
            },
            {
                name: 'name',
                label: T(`cards.topinterests.tableFields.name`),
                width: '1fr',
                truncateCellText: true,
                horizontalAlign: 'start',
                format: ({name}) => <>
                    {name}
                </>
            },
            {
                name: 'total',
                label: T(`cards.topinterests.tableFields.total`),
                width: 'minmax(auto, 15%)',
                format: (row) => <CompactNumber value={+row.total} tooltip />
            },
        ];

        if(isSavedSearch && data.every(({authorTotal}) => (authorTotal ?? false) as any !== false)) {
            columns.push({
                name: 'authorTotal',
                label: T(`cards.topinterests.tableFields.authorTotal`),
                width: 'minmax(150px, 25%)',
                format: (row) => <NumberWithPercentMeter value={row.authorTotal} fraction={row.authorPercent} />
            });
        }

        if(isSavedSearch && data.every(({authorSeries}) => !isEmpty(authorSeries))) {
            columns.push({
                name: 'authorSeries',
                label: <>
                    {T('cards.topinterests.tableFields.authorSeries')}
                    <span className="offscreen">{T('cards.topinterests.tableFields.authorSeriesDesc')}</span>
                    &nbsp;
                    <Tooltip
                        tooltip={T('cards.topinterests.tableFields.authorSeriesDesc')}
                        portal
                        noAria
                    >
                        <HelpIconButton />
                    </Tooltip>
                </>,
                width: '100px',
                format: ({authorSeries}) => (<Sparkline
                    height={22}
                    width={80}
                    series={authorSeries}
                    withTrend
                    dataKey="value"
                    color={sparklineColor}
                />)
            });
        }

        return columns;
        
    }, [classes.tableRowPosition, data, isSavedSearch, sparklineColor]);
}


function useGetDrillInData() {
    return useCallback(
        (interest: TopInterestsType) => ({
            dates: undefined,
            filter: getInterestsDrillInFilters(interest),
            label: interest.name,
            tracking: {
                name: 'cardDrilledIn',
                type,
                value: interest.name,
            },
            type,
        } as const),
        [],
    );
};
