import {PropsWithChildren, createContext, useContext, useMemo} from 'react';

import Item from './Item';

import useStyles from './styles';

export {default as Item} from './Item';

export type RadioButtonsProps<TValues extends string = string> = {
    label: string;
    name: string;
    value?: TValues;
    setValue?: (newValue: TValues) => void;
};

type RadioButtonsContextType<TValues extends string = string> = {
    name: string;
    value?: TValues;
    setValue?: (newValue: TValues) => void;
};

const RadioButtonsContext = createContext<RadioButtonsContextType | undefined>(undefined);

function RadioButtons<TValues extends string = string>({
    label,
    name,
    value,
    setValue,
    children,
}: PropsWithChildren<RadioButtonsProps<TValues>>) {
    const classes = useStyles();

    const contextValue = useMemo(
        () => ({
            name,
            value,
            setValue,
        }),
        [name, value, setValue],
    );

    return (
        <RadioButtonsContext.Provider value={contextValue as any}>
            <div className={classes.root} role="radiogroup" aria-label={label}>
                {children}
            </div>
        </RadioButtonsContext.Provider>
    );
}

RadioButtons.Item = Item;

export default RadioButtons;

export function useRadioButtonsContext<TValues extends string = string>() {
    return useContext(RadioButtonsContext) as unknown as RadioButtonsContextType<TValues>;
}
