//import excludeConfigSnippets from './excludeConfigSnippets';

import {OR, parseLinks, parseAuthors, parseMultiWord} from 'hsi/utils/formulaParser';

import tns from 'hsi/i18n/tns';
const t = tns('queryBuilder');

const excludeConfig = {
    name: 'exclude',
    label: 'exclude',
    operator: 'NOT',
    components: [
        //XXX exclusion snippets DEPRECATED to solve the 600 character limit in the new validation api
        //{
        //name: 'mention-types',
        //type: 'checkboxes-panel',
        //title: 'Select the types of mentions you want excluded from your search.',
        //items: excludeConfigSnippets.results,
        //hideFromFormula: true,
        //parseFormula: ({ values }) => OR(values.map(d => d.query)),
        //},

        //{
        //type: 'advanced-options',
        //components: [
        {
            name: 'irrelevant-keywords',
            type: 'input-chips',
            title: t('excludeIrrelevantKeywordsTitle'),
            subtitle: t('excludeIrrelevantKeywordsSubtitle'),
            placeholder: t('excludeIrrelevantKeywordsPlaceholder'),
            parseFormula: ({values}) => OR(values.map(parseMultiWord)),
            chipElemProps: {
                newChipChars: ',',
            },
        },

        {
            name: 'websites',
            type: 'input-chips',
            title: t('excludeWebsitesTitle'),
            subtitle: t('excludeWebsitesSubtitle'),
            placeholder: t('excludeWebsitesPlaceholder'),
            parseFormula: parseLinks,
        },

        {
            name: 'authors',
            type: 'input-accounts',
            title: t('excludeAuthorsTitle'),
            subtitle: t('excludeAuthorsSubtitle'),
            placeholder: t('excludeAuthorsPlaceholder'),
            parseFormula: parseAuthors,
        },
        //],
        //},
    ],
};

export {excludeConfig};
