/**
 * Stream Service
 */
import http from '../classes/Http';

class filtersService {
    static async loadFilterOptions() {
        return http.get('/fe-api/filterOptions').then((res) => res.body);
    }
}

export default filtersService;
