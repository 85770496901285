import useStyles from './styles';

//hooks
import useConfig from 'hsi/hooks/useConfig';

//Other
import {T} from 'hsi/i18n';


//The component
export default function MainTitle () {
    const classes = useStyles();
    const {hasBrandwatchLabel} = useConfig();
    
    return (
        <div className={classes.title}>
            <div className={classes.brand}>{T(`TopBar.insights`)}</div>
            {hasBrandwatchLabel && <div className={classes.subBrand}>{T('TopBar.powered')}</div>}
        </div>
    );
};
