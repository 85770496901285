import {createAsyncThunk} from '@reduxjs/toolkit';
import {showNotification} from 'hsi/actions/notificationsActions';
import {T} from 'hsi/i18n';
import {getGenerationalSocialPanels, getSocialPanelList} from 'hsi/services/socialPanelsService';
import {IPanelSearchRequestParams} from 'hsi/types/socialPanels';

interface IGetSocialPanelsAPI {
    projectId: number;
    params?: IPanelSearchRequestParams;
}

export const DEFAULT_GET_SOCIAL_PANEL_PARAMS: IPanelSearchRequestParams = {
    filters: {
        displayTypes: ['USER', 'PUBLIC'],
    },
    count: 1000,
    offset: 0,
    sort: {
        by: 'CREATED',
        direction: 'DESC',
    },
};

export const getSocialPanels = createAsyncThunk(
    'socialPanels/getSocialPanels',
    async ({projectId, params}: IGetSocialPanelsAPI, {dispatch, rejectWithValue}) => {
        const _params = {...DEFAULT_GET_SOCIAL_PANEL_PARAMS, ...params, projectId};
        try {
            const res = await getSocialPanelList(_params);

            // We only want generational social panels
            const generationalSocialPanels = getGenerationalSocialPanels(res.results);
            return {
                results: generationalSocialPanels,
                total: generationalSocialPanels.length,
            };
        } catch (error: unknown) {
            dispatch(
                showNotification({
                    message: T('autocomplete.socialPanels.loadingError'),
                    hidable: true,
                    variant: 'warning',
                }),
            );
            if (error instanceof Error) {
                return rejectWithValue(error.message);
            }

            return rejectWithValue('An unknown error occurred fetching social panels.');
        }
    },
);
