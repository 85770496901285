import {Children, useMemo} from 'react';

//Components
import PulseLoader from 'hsi/components/PulseLoader';
import {LoadErrorMsg} from 'hsi/components/ErrorMessage';
import InfiniteScroll from 'hsi/components/InfiniteScroll';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';
import Feed from '../aria/Feed';
import {polymorphicForwardRef} from 'hsi/types/react-polymorphic';
import classNames from 'classnames';

//The component
export type MentionsListProps = {
    //Mentions props
    loadMore: () => void;
    loading: boolean;
    error: boolean;

    grid?: boolean;

    //Infinite scroll props
    asFeed?: boolean;
    getScrollParent?: () => HTMLElement;
    hasMore?: boolean;
    infiniteScrollThreshold?: number;
};

const MentionsListComponent = polymorphicForwardRef<MentionsListProps, 'div'>(function MentionsList(
    {
        as: Component = 'div',
        children,
        error,
        loading,
        loadMore,
        getScrollParent,
        infiniteScrollThreshold,
        hasMore,
        asFeed = false,
        grid = false,

        className,
        ...rest
    },
    ref,
) {
    const classes = useStyles();

    const childrenArray = useMemo(
        () => Children.toArray(children).filter((child) => !!child),
        [children],
    );

    //Calculated values
    const hasData = childrenArray.length > 0;

    const renderedList = useMemo(
        () =>
            asFeed ? (
                <Feed
                    {...rest}
                    as={InfiniteScroll}
                    element={Component}
                    className={classNames(className, classes.list, grid && classes.grid)}
                    pageStart={0}
                    loadMore={loadMore}
                    loader={
                        <div aria-hidden className={classes.moreMentionsLoader}>
                            <PulseLoader size="small" />
                        </div>
                    }
                    hasMore={hasMore}
                    useWindow={false}
                    initialLoad={false}
                    getScrollParent={getScrollParent}
                    threshold={infiniteScrollThreshold}
                    //Feed props
                    busy={loading}
                    ref={ref}
                >
                    {childrenArray.map((child, index) => (
                        <Feed.ItemWrapper key={(child as any).key} aria-posinset={index + 1}>
                            {child}
                        </Feed.ItemWrapper>
                    ))}
                </Feed>
            ) : (
                <Component
                    {...rest}
                    ref={ref}
                    className={classNames(className, classes.list, grid && classes.grid)}
                >
                    {childrenArray}
                </Component> //Should this be a feed? I guess not...?
            ),
        [
            asFeed,
            rest,
            Component,
            className,
            classes.list,
            classes.grid,
            classes.moreMentionsLoader,
            grid,
            loadMore,
            hasMore,
            getScrollParent,
            infiniteScrollThreshold,
            loading,
            ref,
            childrenArray,
        ],
    );

    //Render
    if (loading && !hasData) {
        return (
            <div className={classes.loader}>
                <PulseLoader size="medium" />
            </div>
        );
    } else if (error) {
        return (
            <LoadErrorMsg
                className={classes.error}
                line1={T('mentionsContainer.error.msg1')}
                line2={T('mentionsContainer.error.msg2')}
            />
        );
    } else if (!hasData) {
        return (
            <LoadErrorMsg
                className={classes.error}
                line1={T('mentionsContainer.noData.msg1')}
                line2={T('mentionsContainer.noData.msg2')}
                iconType="info"
            />
        );
    } else {
        return renderedList;
    }
});

export default MentionsListComponent;
