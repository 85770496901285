import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {OverflowTooltip} from 'hsi/components/SimpleTooltip';

const useStyles = makeStyles((theme) => ({
    tooltip: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
    },
}));

const CustomLegend = ({content}) => {
    const cl = useStyles();
    return (
        <OverflowTooltip tooltip={content}>
            <div className={cl.tooltip}>{content}</div>
        </OverflowTooltip>
    );
};

export default CustomLegend;
