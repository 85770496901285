import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    return {
        menu: {
            maxHeight: 'none',
        },
        buttonWrapper: {
            margin: 8,
        },
    };
});
