import { isV2 } from 'hsi/types/theme';
import {makeStyles} from 'tss-react/mui';

export default makeStyles()((theme, _params, classes) => {
    if (isV2(theme))
        return {
            barChart: {
                '& .recharts-cartesian-axis-tick-value': {
                    fontSize: theme.fonts.bodySmall.fontSize,
                    fontWeight: theme.fonts.bodySmall.fontWeight,
                    color: theme.colors.textSubtle,
                },
                '& .recharts-cartesian-grid-vertical': {
                    '& line': {
                        strokeDasharray: '1 1',
                    },
                },
                '& .benchmarkLine': {
                    fill: theme.colors.carbon,
                },
                '& .percentage': {
                    fill: theme.colors.textDefault,
                    transform: 'translate(20px)',
                },
                '& .trendingArrow': {
                    fill: theme.colors.secondaryPurple10,
                },
                '& rect': {
                    transition: 'opacity 0.5s ease',
                    cursor: 'pointer !important',
                },
                '& .xAxislegend': {
                    fill: theme.colors.textSubtle,
                    fontSize: theme.typgrphy.bodyNormal.fontSize,
                    fontWeight: theme.typgrphy.bodyNormal.fontWeight,
                },
            },
        };
    else
        return {
            barChart: {
                '& .recharts-cartesian-axis-tick-value': {
                    fontSize: '12px',
                    color: theme.colors.darkGrey80,
                },
                '& .recharts-cartesian-grid-vertical': {
                    '& line': {
                        strokeDasharray: '1 1',
                    },
                },
                '& .benchmarkLine': {
                    fill: theme.colors.darkGrey100,
                },
                '& .percentage': {
                    fill: theme.colors.darkGrey80,
                    fontSize: '16px',
                    fontWeight: 700,
                    transform: 'translate(28px)',
                },
                '& .positive': {
                    fill: theme.colors.positive,
                },
                '& .negative': {
                    fill: theme.colors.negative,
                },
                '& .trendingArrow': {
                    fill: theme.colors.error,
                },
                '& rect': {
                    transition: 'opacity 0.5s ease',
                    cursor: 'pointer !important',
                },
                '& .xAxislegend': {
                    fill: theme.colors.darkGrey100,
                },
            },
        };
});
