import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    root: {
        width: '75%',
        margin: '125px auto 0 auto',
        fontSize: '1.5em',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '& img': {
            width: '150px',
        },

        '& h1': {
            fontSize: '30px',
            margin: '1em 0 0 0',
            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
        },

        '& p': {
            textAlign: 'center',
            width: '60%',
        },

        '& a': {
            fontWeight: '600',
            textDecoration: 'none',

            '&:hover': {
                textDecoration: 'underline',
            },
            svg: {
                width: '24px',
                height: '24px',
            },
        },
    },
});

export default useStyles;
