import {useCallback, useEffect} from 'react';
import cn from 'classnames';

import GuidedQueryForm from './GuidedQueryForm';
import CreateOrSelectQuery from './CreateOrSelectQuery';
import SaveSearchDialog from 'hsi/components/SaveSearchDialog';
import SearchHelpButton from './components/SearchHelpButton';
import SearchHelpDrawer from './components/SearchHelpDrawer';

import useEventTrack from 'hsi/hooks/useEventTrack';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import useQueryContext from 'hsi/hooks/useQueryContext';

import {submitQuery, setQueryUnguided} from 'hsi/actions/queryActions';

import {HELP_BUTTON_PLACEMENT} from './config';
import {introAnchors} from 'hsi/containers/Intro';

import useStyles from './styles';
import {ValueOf} from 'hsi/types/shared';
import useDetectLeaveElement from 'hsi/hooks/useDetectLeaveElement';
import useTrackFocusWithin from 'hsi/hooks/useTrackFocusWithin';

type QueryBuilderProps = {
    open?: boolean;
    setOpen?: (isOpen: boolean) => void;
    helpOpen?: boolean;
    setHelpOpen?: (isOpen: boolean, fromUserAction?: boolean) => void;
    autofocusHelpPanel?: boolean;
    //forceHelpOpen?: boolean;
    className?: string;
    defaultQuery?: string;
    helpButtonPlacement: ValueOf<typeof HELP_BUTTON_PLACEMENT>; //This only works because HELP_BUTTON_PLACEMENT is declared with 'as const'
};

const QueryBuilder = ({
    open = false,
    setOpen,
    helpOpen = false,
    setHelpOpen,
    autofocusHelpPanel = false,
    className,
    defaultQuery,
    helpButtonPlacement,
}: QueryBuilderProps) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const {track, trackWithSearchData} = useEventTrack();

    const {isFocusWithin, ...trackFocusWithinProps} = useTrackFocusWithin();

    const isGuided = useAppSelector((state) => state.query?.isGuided);
    const {isEditSearch} = useQueryContext();

    const detectLeaveElementProps = useDetectLeaveElement(() => {
        setOpen?.(false);
    });

    useEffect(() => {
        // close help when doing a guided search
        if (isGuided && helpOpen) {
            setHelpOpen?.(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGuided]);

    useEffect(() => {
        if (isFocusWithin && !isGuided) {
            setOpen?.(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFocusWithin, isGuided]);

    const onSubmit = (query: string | null) => {
        setOpen?.(false);
        if (query === null) {
            return;
        }

        trackWithSearchData('quickSearchTriggered', {
            booleanQuery: query,
        });
        setHelpOpen?.(false);
        dispatch(submitQuery(query));
    };

    const setUnguided = () => {
        dispatch(setQueryUnguided());
        setTimeout(() => {
            //TODO is this still required?
            //Needs a slight delay to allow time for the focus trap to have unmounted
            document.querySelector<HTMLElement>('.query-editor textarea')?.focus();
        }, 50);
    };

    const onHelpShortcutPressed = useCallback(() => {
        track('advancedSearchHelpKeyboardShortcutPressed', {});
        setHelpOpen?.(true, true);
    }, [setHelpOpen, track]);

    return (
        <>
            <div className={cn(classes.wrapper, className)} {...detectLeaveElementProps}>
                {helpButtonPlacement === HELP_BUTTON_PLACEMENT.TOP && (
                    <SearchHelpButton
                        open={helpOpen}
                        className={cn(
                            classes.searchHelpButton,
                            introAnchors.quickSearch.searchHelp,
                        )}
                        onClick={() => {
                            track('advancedSearchHelpButtonClicked', {});
                            setHelpOpen?.(true, true);
                        }}
                    />
                )}
                <div {...trackFocusWithinProps}>
                    <div className={classes.createOrSelectQueryWrapper}>
                        <CreateOrSelectQuery
                            autofocus={isEditSearch}
                            defaultQuery={defaultQuery}
                            isEditSearch={isEditSearch}
                            onSubmit={onSubmit}
                            onGoToHelp={onHelpShortcutPressed}
                            open={open && !isGuided}
                            setOpen={() => setOpen?.(true)}
                        >
                            {helpButtonPlacement === HELP_BUTTON_PLACEMENT.LIST && open && (
                                <div className={classes.listHelpWrapper}>
                                    <SearchHelpButton
                                        open={helpOpen}
                                        onClick={() => {
                                            track('advancedSearchHelpButtonClicked', {});
                                            setHelpOpen?.(true, true);
                                        }}
                                    />
                                </div>
                            )}
                        </CreateOrSelectQuery>
                    </div>
                    <SearchHelpDrawer
                        open={helpOpen}
                        autofocus={autofocusHelpPanel}
                        onClose={() => {
                            setHelpOpen?.(false, true);
                        }}
                        onSubmit={onSubmit}
                    />
                </div>
                {isGuided && open && (
                    <GuidedQueryForm
                        isEditSearch={isEditSearch}
                        onSubmit={onSubmit}
                        setUnguided={setUnguided}
                    />
                )}
            </div>
            <SaveSearchDialog />
        </>
    );
};

export default QueryBuilder;
