import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles((theme: Theme) => {
    const {colors} = theme;
    const v2 = isV2(theme);

    return {
        wrapper: {
            minHeight: v2 ? 40 : 44,
            overflowY: 'visible',
            zIndex: 1000,
        },
        createOrSelectQueryWrapper: {
            position: 'relative',
            ...(v2 ? {} : {border: `1px solid ${colors.darkGrey60}`}),
        },
        listHelpWrapper: {
            borderTop: `1px solid ${colors.borders}`,
            background: '#FFFFFF',
            padding: '8px 16px',
            display: 'flex',
            justifyContent: 'space-between',
        },
        searchHelpButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '& .MuiButton-root': {
                display: 'flex',
                flex: '0 0 auto',
                height: 32,
            },
        },
        hidden: {
            visibility: 'hidden',
        },
        characterLimitTop: {
            position: 'absolute',
            top: '6px',
        },
        characterLimitList: {
            padding: '12px 0',
        },
    }
});

