import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column nowrap',

        '& .MuiCardHeader-content': {
            overflow: 'visible',
        },

        '@media print': {
            '-webkit-print-color-adjust': 'exact',
            pageBreakInside: 'avoid',
        },
        '.printMedia &': {
            '-webkit-print-color-adjust': 'exact',
            pageBreakInside: 'avoid',
        },
    },

    loadingWrapper: {
        flex: 'auto 1 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 0 28px',
    },

    selected: {
        boxShadow: theme.v2 ? `0 0 0 3px ${theme.colors.accentActive}` : `0 0 0 3px #06b0ef`,
    },
}));
