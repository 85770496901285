import React from 'react';
import IconRouter from 'hsi/components/IconRouter';

import useStyles from './styles';

export function BlankIcon() {
    useStyles();

    return (
        <div className="chbox-icon-wrapper">
            <div className="chbox-icon-frame blank" />
        </div>
    );
}

export function CheckedIcon() {
    useStyles();

    return (
        <div className="chbox-icon-wrapper">
            <div className="chbox-icon-frame checked">
                <svg
                    className="chbox-icon"
                    height="16"
                    viewBox="0 0 16 16"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                >
                    <path
                        d="m3.25 8.25 2.75 2.75 6.75-6.75"
                        fill="none"
                        stroke="#FFF"
                        strokeWidth="2.1"
                    />
                </svg>

                {/* <IconRouter name="tick" /> */}
            </div>
        </div>
    );
}

export function IndeterminateIcon() {
    useStyles();

    return (
        <div className="chbox-icon-wrapper">
            <div className="chbox-icon-frame icon-frame-indeterminate blank">
                <IconRouter
                    aria-hidden="true"
                    name="d-delete"
                    className="indeterminate-chbox-icon"
                />
            </div>
        </div>
    );
}
