import find from 'lodash/find';

import {CCFlagsType} from 'hsi/types/cc-flags';
import {QueryContextType} from 'hsi/types/query';

import getConfig from 'hsi/config'; //Not a fan of this
import {CardType} from 'hsi/types/cards';

export type ProcessedCardRules = ReturnType<typeof processCardRules>;

export default function processCardRules(
    type: CardType,
    queryContext: QueryContextType,
    flags: CCFlagsType,
) {
    const conditions = {queryContext, flags};

    const {
        searchResults: {cardRules},
    } = getConfig();

    const rules = find(cardRules, ({name}) => name === type);

    if (!rules) {
        return undefined;
    }

    const {getTitle, additionalCall, additionalCallRequires, ...otherRules} = rules;

    return {
        ...otherRules,
        additionalCall: additionalCall && (!additionalCallRequires || additionalCallRequires(conditions)) ? additionalCall : undefined,
        peaks: !!rules?.peaks ? rules.peaks(conditions) : null,
        requires: !!rules?.requires ? rules.requires(conditions) : null,
    };
}
