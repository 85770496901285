import {useEffect, useRef, useMemo, useCallback} from 'react';

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

//Components
import Button from 'hsi/components/Button';
import MultipleOptionsPopover from 'hsi/containers/MultipleOptionsPopover';
import MultipleSearchChips from './MultipleSearchChips';

//Actions
import {showNotification} from 'hsi/actions/notificationsActions';
import {setAdditionalQueries, persistActiveQuery} from 'hsi/actions/queryActions';
import {clearResults} from 'hsi/actions/resultsActions';

//Hooks
import useLoadMentions from 'hsi/hooks/useLoadMentions';
import {useSearchesById} from 'hsi/hooks/useSearchesById';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useConfig from 'hsi/hooks/useConfig';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import useGetAllLinkedInChannelIds from 'hsi/hooks/useGetAllLinkedInIds';
import useFlags from 'hsi/hooks/useFlags';

// Selectors
import {
    selectSearches,
    selectSavedSearch,
    selectProjectId,
    selectSavingQuery,
} from 'hsi/selectors';

//Other
import {introAnchors} from 'hsi/containers/Intro';
import {hasAdditionalQueries as getHasAdditionalQueries} from 'hsi/utils';

import useStyles from './styles';
import {T} from 'hsi/i18n';


type MultipleSearchBarProps = {
    initialAdditionalQueries: number[];
    currentLinkedInChannelIds: string[];
    className: string;
} & JSX.IntrinsicElements['div'];

//The component
export default function MultipleSearchBar({
    className,
    initialAdditionalQueries,
    currentLinkedInChannelIds,
    ...rest
}: MultipleSearchBarProps) {
    const {classes, cx} = useStyles();
    const dispatch = useAppDispatch();
    const loadMentions = useLoadMentions();
    const {searchesById} = useSearchesById();
    const {trackWithSearchData} = useEventTrack();
    const {
        multipleSearches: {maxMultipleSearches},
    } = useConfig();
    const {hasDashboardPinAndHide} = useFlags();

    const searches = useAppSelector(selectSearches);
    const savedSearch = useAppSelector(selectSavedSearch);
    const projectId = useAppSelector(selectProjectId);
    const savingQuery = useAppSelector(selectSavingQuery);
    const saveQueryError = useAppSelector((state) => state.query?.saveError);

    const hasAdditionalQueries = getHasAdditionalQueries(initialAdditionalQueries);

    const allLinkedInChannelIds  = useGetAllLinkedInChannelIds();

    //TODO better typing here - the searches selector ultimately needs to be typed
    const allSelectableQueryIds: number[] = useMemo(
        () =>
            searches
                ?.filter((d: any) => !!searchesById[d.id]) //how would this not be the case?
                .filter((d: any) => d.id !== savedSearch?.id) //filter out this query
                .filter((d: any) => d.project?.id === projectId) //only select queryies from the same project
                .filter((d: any) => !allLinkedInChannelIds?.includes(String(d.id))) //remove linkedIn queries
                .map((d: any) => d.id) || [], //finally map to ID prop
        [projectId, savedSearch, searches, searchesById, allLinkedInChannelIds],
    );

    const getOptionLabel = (id: number) => searchesById[id]?.name as string;

    const lastSave = useRef<number[] | null>(null);

    const applyUpdatedAdditionalQueries = useCallback(
        (newSelectedQueryIds: number[]) => {
            if (savingQuery) {
                lastSave.current = newSelectedQueryIds;
                return;
            }
            dispatch(setAdditionalQueries(newSelectedQueryIds));
            dispatch(persistActiveQuery());
            dispatch(clearResults());
            loadMentions({append: false});
        },
        [dispatch, loadMentions, savingQuery],
    );

    const multiSelectEventHandlers = useMemo(
        () => ({
            onOpen: () =>
                trackWithSearchData('multipleSearchDropdownToggled', {
                    state: 'open',
                }),
            onClose: () => {
                trackWithSearchData('multipleSearchDropdownToggled', {
                    state: 'closed',
                });

                document.getElementById('addEditMultipleSearchBtn')?.focus();
            },

            onSaveSelected: (selectedQueries: number[]) => {
                applyUpdatedAdditionalQueries(selectedQueries);

                trackWithSearchData('multipleSearchApplied', {
                    count: selectedQueries.length,
                    selectedQueries,
                });
            },
            onClearAllSelected: () =>
                trackWithSearchData('multipleSearchClearAllButtonClicked', {
                }),
        }),
        [applyUpdatedAdditionalQueries, trackWithSearchData],
    );

    const removeAdditionalQuery = (id: any) => {
        const nextAdditionalQueries = initialAdditionalQueries.filter((_id) => _id !== id);

        trackWithSearchData('multipleSearchRemoved', {
            removed: id,
        });
        applyUpdatedAdditionalQueries(nextAdditionalQueries);
    };

    //TODO extract lastSave logic to a hook: see LinkedinChannelsBar
    //PP Honestly not sure what this is for?
    useEffect(() => {
        if (savingQuery || !lastSave.current) return;
        applyUpdatedAdditionalQueries(lastSave.current);
        lastSave.current = null;
    }, [applyUpdatedAdditionalQueries, savingQuery]);

    useEffect(() => {
        if (saveQueryError)
            dispatch(
                showNotification({
                    message: T('multipleSearch.saveQueryError'),
                    variant: 'warning',
                }),
            );
    }, [dispatch, saveQueryError]);

    return (
        <div className={cx(className, classes.container)} {...rest}>
            <div className={classes.divider} />

            {hasAdditionalQueries && (
                <MultipleSearchChips
                    items={initialAdditionalQueries}
                    getChipLabel={getOptionLabel}
                    onDeleteChip={removeAdditionalQuery}
                    className={classes.chips}
                    getHiddenChipsText={getHiddenChipsText}
                    sizeToContent
                />
            )}

            <MultipleOptionsPopover<number>
                dialogTitle={T('multipleSearch.popover.dialogTitle')}
                options={allSelectableQueryIds}
                initialOptions={initialAdditionalQueries}
                getOptionLabel={getOptionLabel}
                maxOptions={maxMultipleSearches}
                otherSavedSearches={currentLinkedInChannelIds}
                title={T('multipleSearch.popover.title')}
                info={hasDashboardPinAndHide ? T('linkedinChannels.popover.info') : undefined}
                disabledOptionTooltip={T('multipleSearch.popover.disabledOptionTooltip', {
                    maxOptions: maxMultipleSearches,
                })}
                {...multiSelectEventHandlers}
            >
                {/* The design has two very different styled buttons for if this is a multiple search or not, so we are faking this
                by nesting components that look like buttons, but aren't, then re-creating the styles where needed
                */}
                <button
                    id="addEditMultipleSearchBtn"
                    aria-label={T('multipleSearch.addSearch')}
                    className={cx(
                        classes.buttonWrapper,
                        hasAdditionalQueries && classes.addMore,
                        introAnchors.savedSearch.addMultipleSearch,
                    )}
                >
                    {hasAdditionalQueries ? (
                        <IconButton
                            tabIndex={-1}
                            aria-hidden
                            component="div"
                            className={classes.addMoreButton}
                            size="large">
                            <AddIcon className={classes.addMoreIcon} />
                        </IconButton>
                    ) : (
                        <Button
                            tabIndex={-1}
                            aria-hidden
                            component="div"
                            priority="text"
                            color="primary"
                            className={classes.addButton}
                        >
                            <AddCircleOutlineIcon className={classes.addIcon} />
                            <span>{T('multipleSearch.addSearch')}</span>
                        </Button>
                    )}
                </button>
            </MultipleOptionsPopover>
        </div>
    );
}

function getHiddenChipsText(overflowIndex: number, totalChildren: number) {
    return T('multipleSearch.hiddenChipsMsgLong', {num: totalChildren - overflowIndex});
}

export {default as MultipleSearchChips} from './MultipleSearchChips';
