import {forwardRef} from 'react';

//Components
import TopMeasureBySearch, { TopMeasureItem } from 'hsi/components/Cards/TopMeasureBySearch';

//Utils
import { getHashtagDrillInFilters } from 'hsi/utils/filters/drillInFilters';

//Other
import {T} from 'hsi/i18n';
import { CardTypeProps } from 'hsi/types/cards';

//Consts
const type = 'tophashtagsBySearch';
const getDrillInData = ({name: hashtag}: TopMeasureItem) => ({
    type,
    label: hashtag,
    tracking: {
        name: 'cardDrilledIn',
        type,
        value: hashtag,
    },
    filter: getHashtagDrillInFilters(hashtag),
} as const);
const nameField = {
    label: T('cards.tophashtagsBySearch.nameFieldLabel'),
};

//The component
const TopHashtagsBySearch = forwardRef<HTMLDivElement, CardTypeProps>(function TopHashtagsBySearch(props, ref) {
    return (
        <TopMeasureBySearch
            type={type}
            dataSelector={(state) => state.chart[type]}
            getDrillInData={getDrillInData}
            nameField={nameField}
            ref={ref}
            {...props}
        />
    );
});

export default TopHashtagsBySearch;
