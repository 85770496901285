import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    pieChart: {
        '& .recharts-pie-sector': {
            cursor: 'pointer',
        },
    },
    withLegend: {
        marginBottom: '40px',
    },
}));
