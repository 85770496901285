// TODO: Add tests
import {useCallback, useState} from 'react';

import useFacebookLogin from 'hsi/hooks/useFacebookLogin';
import {useFacebookPages, useInstagramAccounts} from 'hsi/hooks/useDataSource';
import {useAppSelector} from 'hsi/hooks/useRedux';

import {AuthenticationDataTypes} from 'hsi/types/dataManagement';

const useAddFacebookPagesAndInstagramAccounts = () => {
    const {loading: isLoginLoading, trackableOwnedAuthentications} = useFacebookLogin();
    const facebookPages = useFacebookPages();
    const instagramAccounts = useInstagramAccounts();

    const {isAddOwnedDialogOpen} = useAppSelector((state) => state.facebookAuthentication);

    const [selectedOptions, setSelectedOptions] = useState(
        trackableOwnedAuthentications.data || [],
    );

    const addFacebookPagesAndInstagramAccounts = useCallback(() => {
        const selectedFacebookPages = selectedOptions
            .filter(({type}) => type === AuthenticationDataTypes.FacebookPageAuthentication)
            .map(({id, name}) => ({pageId: id, name, owned: true}));

        if (selectedFacebookPages?.length) {
            facebookPages.add.mutate({pages: selectedFacebookPages});
        }

        const selectedInstagramAccounts = selectedOptions
            .filter(({type}) => type === AuthenticationDataTypes.InstagramAccountAuthentication)
            .map(({id, name}) => ({instagramBusinessAccountId: id, name, owned: true}));

        if (selectedInstagramAccounts?.length) {
            instagramAccounts.add.mutate({accounts: selectedInstagramAccounts});
        }
    }, [facebookPages.add, instagramAccounts.add, selectedOptions]);

    return {
        addFacebookPagesAndInstagramAccounts,
        isAddEnabled: selectedOptions.length,
        isAddOwnedDialogOpen,
        isLoginLoading,
        selectProps: {
            onChange: setSelectedOptions,
            options: trackableOwnedAuthentications.data,
            selectedOptions,
        },
    };
};

export default useAddFacebookPagesAndInstagramAccounts;
