import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({colors, font, fonts, mixin, v2}) => ({
    banner: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        padding: 16,
        marginBottom: 16,
        ...(v2
            ? {
                  ...fonts.bodyLargeNormal,
                  colors: colors.textDefault,
              }
            : {
                  colors: colors.darkGrey100,
                  fontSize: 16,
                  fontWeight: font.regular,
              }),
    },

    noMargin: {
        marginBottom: 0,
    },
    content: {
        flex: '5 1 auto',
    },
    closeIconWrapper: {
        flex: '0 0 44px',
        marginTop: -4,
        marginBottom: -4,
    },
    closeIcon: {
        ...mixin.icon.bigSize,
    },
    regularStatusIconWrapper: {
        flex: '0 0 34px',
        width: 34,
        height: 34,
        padding: 8,
        marginLeft: 8,
        marginRight: 8,
        borderRadius: '50%',
    },
    regularStatusIcon: {
        ...mixin.icon.normalSize,
    },
    smallStatusIconWrapper: v2
        ? {
              flex: '0 0 14px',
              width: 14,
              height: 14,
              padding: 0,
              marginLeft: 0,
              marginRight: 5,
              borderRadius: 0,
              backgroundColor: 'transparent !important',
          }
        : {
              flex: '0 0 20px',
              width: 20,
              height: 20,
              marginLeft: 8,
              marginRight: 8,
              paddingLeft: 3,
              borderRadius: '50%',
          },
    smallStatusIcon: {
        width: 14,
        height: 14,
    },

    info: {
        backgroundColor: v2 ? colors.info : colors.secondaryBlue20,
    },
    infoStatusIconWrapper: {
        backgroundColor: v2 ? 'transparent' : colors.secondaryBlue40,
    },
    infoStatusIcon: {
        fill: v2 ? colors.accentDefault : colors.secondaryBlue100,
    },

    success: {
        backgroundColor: v2 ? colors.info : colors.secondaryGreen20,
    },
    successStatusIconWrapper: {
        backgroundColor: v2 ? 'transparent' : colors.secondaryGreen40,
    },
    successStatusIcon: {
        fill: v2 ? colors.accentDefault : colors.secondaryGreen100,
    },

    warning: {
        backgroundColor: v2 ? '#FBF6EC' : colors.primaryYellow10,
    },
    warningStatusIconWrapper: {
        backgroundColor: v2 ? '#F0DEB4' : colors.primaryYellow40,
    },
    warningStatusIcon: {
        fill: v2 ? colors.warning : colors.primaryYellow100,
    },

    hsiExit: {
        backgroundColor: '#F8EAEB',
    },
    hsiExitStatusIconWrapper: {
        backgroundColor: '#E3ACAF'
    },
    hsiExitStatusIcon: {
        fill: '#BA3038'
    }

}));
