import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';

import TextField, { TextFieldProps } from 'hsi/components/TextField';
import AutocompletePopupPaper from './AutocompletePopupPaper';

import useStyles from './styles';

interface AutocompleteMultipleProps<T = string>
    extends Omit<AutocompleteProps<T, true, true, true>, 'onChange' | 'renderInput' | 'options'>,
        Pick<TextFieldProps, 'autoFocus' | 'helperText' | 'placeholder'> {
    error: TextFieldProps['errorProps'];
    options?: AutocompleteProps<T, true, true, true>['options'];
    autoFocus?: boolean;
    descriptionId?: string;
    inputModifier?: (val: (string | T)[]) => (string | T)[];
    labelId: TextFieldProps['label'];
    newChipChars?: string;
    popupFooter?: ReactNode;
    popupTitle?: ReactNode;
    onChange?: (value: (string | T)[]) => void;
}

export default function AutocompleteMultiple<T>({
    autoFocus,
    defaultValue = [],
    descriptionId,
    error,
    getOptionLabel = (opt) => (opt as any).toString(),
    helperText,
    inputModifier,
    labelId,
    newChipChars = ' ,',
    onChange,
    options = [],
    placeholder,
    popupFooter,
    popupTitle,
    value,
    ...props
}: AutocompleteMultipleProps<T>) {
    const {classes} = useStyles();
    const [_value, _setValue] = useState(value || defaultValue);
    const [lastEvent, setLastEvent] = useState<string>();
    const finalValue = value || _value;
    const _onChange: NonNullable<AutocompleteProps<T, true, true, true>['onChange']> = (
        ev,
        val,
    ) => {
        if (ev.type !== 'blur' || (ev.type === 'blur' && lastEvent === 'keydown')) {
            ev.preventDefault();
            ev.stopPropagation();
            const modVal = inputModifier ? inputModifier(val) : val;
            _setValue(modVal);
            onChange?.(modVal);
        }
        setLastEvent(ev.type);
    };

    options = options || [];
    const PaperComponent = useCallback(
        (props: Omit<Parameters<typeof AutocompletePopupPaper>[0], 'title' | 'footer'>) => (
            <AutocompletePopupPaper title={popupTitle} footer={popupFooter} {...props} />
        ),
        [popupTitle, popupFooter],
    );
    const detectNewChip = useMemo(() => {
        const regexp = newChipChars
            ? new RegExp(`([^${newChipChars}]+)?(${newChipChars.split('').join('|')})$`)
            : undefined;

        return (str: string) => {
            const res = regexp ? str.match(regexp) : null;
            return !res ? null : res[1] ? res[1].trim() : '';
        };
    }, [newChipChars]);

    return (
        <Autocomplete
            classes={classes}
            disableCloseOnSelect
            filterSelectedOptions
            forcePopupIcon={!!options.length}
            getOptionLabel={getOptionLabel}
            multiple
            onChange={_onChange}
            options={options}
            PaperComponent={PaperComponent}
            popupIcon={<KeyboardArrowDownIcon />}
            renderInput={(params) => {
                const inputProps: NonNullable<TextFieldProps['inputProps']> = (
                    params as TextFieldProps
                ).inputProps!;
                const _onInputChange = inputProps.onChange;
                inputProps.onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
                    let result = detectNewChip(ev.target.value);
                    if (result !== null) {
                        _onChange(
                            ev,
                            result ? [...finalValue, result] : finalValue,
                            undefined as any,
                        );
                    } else {
                        setLastEvent('keydown');
                        _onInputChange?.(ev);
                    }
                };

                inputProps['aria-describedby'] = descriptionId;

                return (
                    <TextField
                        {...params}
                        {...{autoFocus, errorProps: error, helperText, label: labelId, placeholder}}
                    />
                );
            }}
            value={finalValue}
            {...props}
        />
    );
}
