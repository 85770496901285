//This needs refactoring, it is not a reliable solution
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {createContext, useContext} from 'react';
import {RootReducer} from 'hsi/reducers/rootReducer';

const defaultProjectIdSelector = (state: RootReducer) => state.user.defaultProject?.id;

const projectIdContext = createContext<number | null>(null);
projectIdContext.displayName = 'ProjectIdContext';

export default function useProjectId(): number | null {
    const params: {[key: string]: string} = useParams();
    const paramsProjectId = 'projectId' in params ? +params.projectId : null;
    const contextProjectId = useContext(projectIdContext);
    const defaultProjectId: number | null = useSelector(defaultProjectIdSelector);

    return contextProjectId || paramsProjectId || defaultProjectId;
}

export const ProjectIdProvider = projectIdContext.Provider;
