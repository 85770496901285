import React from 'react';
import PropTypes from 'prop-types';

import ChartTooltipContent from 'hsi/components/ChartTooltipContent';
import Tooltip from 'hsi/components/Tooltip';

import {T, CT} from 'hsi/i18n';
import useStyles from './styles';

const Cell = ({color, day, drillin, hour, onClick, value}) => (
    <Tooltip
        distance={5}
        placement="top"
        theme="dark"
        tooltip={
            <ChartTooltipContent
                title={`${T(day)}, ${hour}`}
                valueItems={[
                    {
                        amount: value,
                        color,
                        unit: value === 1 ? T('mention') : T('mentions'),
                    },
                ]}
            />
        }
    >
        <div
            className="grid-item"
            data-name={hour}
            data-testid="heatmapCell"
            data-val={value}
            onClick={!onClick ? null : () => onClick(drillin)}
            style={{background: color}}
        />
    </Tooltip>
);

const Legend = ({buckets}) => (
    <div className="legend">
        {buckets?.map(({color, label}, i) => (
            <div className="bucket-item" key={i}>
                <div className="bucket-color-sample" style={{backgroundColor: color}} />
                <div className="bucket-label" data-testid="heatmapBucket">
                    {CT(label)}
                </div>
            </div>
        ))}
    </div>
);

const Table = ({data, onClick}) => (
    <>
        {data.map((item) => (
            <div className="row" data-testid="heatmapRow" key={item.id}>
                <label>{T(item.name)}</label>
                {item.values.map((value, i) => (
                    <Cell
                        key={value.name}
                        color={value.color}
                        day={item.name}
                        drillin={{
                            day: item.id,
                            hour: value.id,
                            label: `${T(item.name)}, ${value.name}`,
                            value: value.value,
                        }}
                        hour={value.name}
                        onClick={onClick}
                        value={value.value}
                    />
                ))}
            </div>
        ))}
    </>
);

const Xaxis = ({values}) => (
    <div className="x-axis">
        {values?.map(({name: hour}, i) => (
            <label key={i}>{hour}</label>
        ))}
    </div>
);

const HeatmapChart = ({data, onClick, buckets, values}) => {
    const classes = useStyles();

    return (
        <div className={classes.heatmapCard} data-testid="heatmapChart">
            <Table data={data} onClick={onClick} />
            <Xaxis values={values} />
            <Legend buckets={buckets} />
        </div>
    );
};

HeatmapChart.propTypes = {
    buckets: PropTypes.arrayOf(
        PropTypes.shape({
            color: PropTypes.string.isRequired,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
        }),
    ).isRequired,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            values: PropTypes.array.isRequired,
        }).isRequired,
    ).isRequired,
    onClick: PropTypes.func,
    values: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
        }).isRequired,
    ).isRequired,
};

export default HeatmapChart;
