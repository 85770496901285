import {T} from './index';
import isFunction from 'lodash/isFunction';

//namespaced T util
const tns = (ns) => (id, varsOrTransf, transf) => {
    const isFn = isFunction(varsOrTransf);
    const vars = !isFn ? varsOrTransf : undefined;
    transf = isFn ? varsOrTransf : transf || ((d) => d);
    return transf(T(`${ns}.${id}`, vars));
};

export default tns;
