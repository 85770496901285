import {useMemo} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

//Utils
import {
    initStateForFilterFromConfig,
    initErrorStateForFilterFromConfig,
    initPendingStateForFilterFromConfig,
    setErrorForFilter,
    setPendingForFilter,
    performValidatedReducer,
    checkUpdate,
    allFilterReducers,
    getFuncsToBind,
    validateAsyncAction,
    setPersistedFilterValue,
} from './filters/utils';
import {getInitialPeriod, getIntervalFromRelativeDateRange} from 'hsi/utils/dates';

//Other
//Import the different filter types to register them
import './filters/types/checkboxes';
import './filters/types/includeAndExclude';
import './filters/types/includeOrExclude';
import './filters/types/multiValueTextfield';
import './filters/types/range';
import './filters/types/textfield';
import './filters/types/select';
import './filters/types/category';

//Helper methods

function doLoadPersistedFilters(
    state,
    {collapsedSections, dateRange, filters},
) {
    state.persistFilters = null; //clear record of persisted filters

    if (collapsedSections) {
        //parse collapsed sections into state
        Object.entries(collapsedSections).forEach(([section, isOpen]) => {
            if (section in state.collapsedSections) {
                state.collapsedSections[section] = isOpen;
            }
        });
    }

    if (dateRange) {
        state.dateRange = cloneDeep(dateRange);

        //If persisted a relative date range, need to check that the specified date range is correct.
        //e.g. the user sets a relative date range, it is persisted, then several days later they
        //revist the quest and load the persisted filters. The date range will still be for the
        //previous date range.
        setDateRangeFromRelative(state.dateRange);
    }

    if (filters) {
        //parse persisted filters into state

        Object.entries(filters).forEach(([filter, value]) => {
            const filterConfig = state.allFiltersConfig[filter];

            if (!filterConfig) {
                //Hmm.. maybe silently fail? to deal with changes to filters, rather than break?
            } else {
                state.filters[filter] = setPersistedFilterValue(
                    filterConfig,
                    state.filters[filter],
                    value,
                );
            }
        });
    }

    checkUpdate(state);
}

export function initStateFromConfig(state) {
    // no point in return value for a foreach - technically we should not cause
    // side effects, but for a simple method like this, I think it's fine
    // eslint-disable-next-line no-unused-expressions
    state.config?.forEach(({sectionName, initOpened, filters: filtersConfig}) => {
        //Init collapsed from configuration
        state.collapsedSections[sectionName] = !initOpened;

        //init filters
        filtersConfig.forEach((filterConfig) => {
            state.filters[filterConfig.filterName] = initStateForFilterFromConfig(filterConfig);

            state.allFiltersConfig[filterConfig.filterName] = filterConfig;

            state.error[filterConfig.filterName] = initErrorStateForFilterFromConfig(filterConfig);
            state.pending[filterConfig.filterName] =
                initPendingStateForFilterFromConfig(filterConfig);
        });
    });

    if (state.persistFilters) {
        doLoadPersistedFilters(state, state.persistFilters);
    }

    return state;
}

function setDateRangeFromRelative(dateRange) {
    if (dateRange.relativeRange) {
        let {start, end} = getIntervalFromRelativeDateRange(
            dateRange.relativeRange,
            dateRange.timezone,
        );

        const startDate = start.toISO();
        const endDate = end.toISO();

        if (dateRange.startDate !== startDate) {
            dateRange.startDate = startDate;
        }

        if (dateRange.endDate !== endDate) {
            dateRange.endDate = endDate;
        }
    }
}

//The reducer slice
//-Init state
export const initialState = {
    version: 0,
    update: 0,
    noFiltersApplied: true,
    numAppliedFilters: 0,
    filters: {},
    collapsedSections: {},
    sectionHasFiltersApplied: {},
    error: {},
    pending: {}, //used for async validation
    config: null,
    allFiltersConfig: {},
    dateRange: {
        startDate: '',
        endDate: '',
        timezone: null,
        relativeRange: null,
    },
    persistFilters: null,
    cardsInnerState: {
        open: false,
        type: 'wordCloud',
    },
};

/////////////////////
// Define Reducers //
/////////////////////

const reducers = {
    loadPersistedFilters: (state, {payload: {config, version, ...persistedFilters}}) => {
        state.version = version;

        if (config) {
            state.config = config;

            state.update++;

            initStateFromConfig(state);
            doLoadPersistedFilters(state, persistedFilters);
        } else {
            if (!state.config) {
                state.persistFilters = persistedFilters;
            } else {
                doLoadPersistedFilters(state, persistedFilters);
            }
        }
    },
    //date range
    updateDateRange: (state, {payload: {relativeDateRange, startDate, endDate, timezone}}) => {
        state.dateRange.timezone = timezone;

        if (relativeDateRange) {
            state.dateRange.relativeRange = relativeDateRange;
            setDateRangeFromRelative(state.dateRange);
        } else {
            state.dateRange.startDate = startDate;
            state.dateRange.endDate = endDate;
            state.dateRange.relativeRange = null;
        }

        state.update++;
    },
    updateRelativeDateRange: (state) => {
        if (state.dateRange.relativeRange) {
            setDateRangeFromRelative(state.dateRange);
        }
    },
    initDateRange: (state, {payload: timezone}) => {
        state.dateRange = {...getInitialPeriod(timezone), timezone};
    },
    updateFilter: (state, {payload: {name, value}}) => {
        state.filters[name] = value;
    },
    //Filters
    ...allFilterReducers,
    //Temp
    updateFilterOptions: (state, {payload: {filterName, options}}) => {
        const filterConfig = state.allFiltersConfig[filterName];

        if (filterConfig) {
            filterConfig.options = [...options];
            state.config.forEach(({filters}) => {
                const config = filters.find((filter) => filter.filterName === filterName);

                config && (config.options = [...options]);
            });
            state.update++;
        }
    },
    //General action/reducers
    clearFilter: (state, {payload: filterName}) => {
        const filterConfig = state.allFiltersConfig[filterName];

        if (!filterConfig) {
            throw new Error('Invalid filterName');
        }

        state.filters[filterName] = initStateForFilterFromConfig(filterConfig);

        checkUpdate(state);
    },

    clearSectionFilters: (state, {payload: sectionName}) => {
        state.config
            .find((section) => section.sectionName === sectionName)
            .filters.forEach((filterConfig) => {
                state.filters[filterConfig.filterName] = initStateForFilterFromConfig(filterConfig);

                state.error[filterConfig.filterName] =
                    initErrorStateForFilterFromConfig(filterConfig);
            });

        checkUpdate(state);
    },

    setConfig: (state, {payload: {config, version = null}}) => {
        state.config = config;
        state.version = version;
        state.update++;
        initStateFromConfig({
            ...cloneDeep(initialState),
            ...state,
        });
    },
    reset: (state, {payload}) => {
        if (payload?.preserveDateRange) {
            return initStateFromConfig({
                ...cloneDeep(initialState),
                config: state.config,
                dateRange: state.dateRange,
            });
        } else if (payload?.reset) {
            return initStateFromConfig({
                ...cloneDeep(initialState),
                config: state.config,
                update: state.update + 1,
                dateRange: state.dateRange,
            });
        }

        return initStateFromConfig({
            ...cloneDeep(initialState),
            config: state.config,
            update: state.update + 1,
        });
    },
    setSectionOpen: (state, {payload: {section, open}}) => {
        state.collapsedSections[section] = !open;
        //state.update++;
    },
    setError: (state, {payload: {filter, value}}) => {
        state.error[filter] = value;
        state.update++;
    },
    //Not a lifecycle action for the validateThenApply thunk, as we dispatch
    //this action conditionally, but lifecycle methods are always dispatched
    validateThenApplyPending: (state, {payload: {filterName, fieldName}}) => {
        //mark field as pending
        setFieldPendingState(true, state, filterName, fieldName);
    },
};

const extraReducers = {};

//////////////////////////
// create slice factory //
//////////////////////////
export function getFiltersSlice(name, additionalReducers = null) {
    const validateThenApply = createAsyncThunk(
        `${name}/validateThenApply`,
        async (action, {dispatch, rejectWithValue, fulfillWithValue}) => {
            const state = action.getState();

            const filterConfig = state.allFiltersConfig[action.filterName];

            if (!filterConfig) {
                throw new Error('Unknown filter: ', action.filterName);
            }

            //Perform validation for filter
            const result = validateAsyncAction(state, filterConfig, action); //filterTypeValidation[filterConfig.type](state, filterConfig, action);
            //Handle results
            if (typeof result === 'string') {
                return rejectWithValue(result); //it failed
            } else if (result === true) {
                return fulfillWithValue(true); //it's fine
            }

            //If we get here, this is a promise, meaning the async validation is ongoing
            //-mark field as pending - do not reply of lifecycle method, as that always
            // fires even when there is no async validation
            dispatch(slice.actions.validateThenApplyPending(action));

            return result.then((result) => {
                if (result !== true) {
                    return rejectWithValue(result);
                }

                return true;
            });
        },
    );

    const slice = createSlice({
        name,
        initialState,
        reducers: {
            ...reducers,
            ...additionalReducers,
        },
        extraReducers: {
            ...extraReducers,
            //validateThenApply lifecycle methods
            [validateThenApply.fulfilled]: (state, {meta: {arg}}) => {
                const {action, filterName, fieldName} = arg;

                //undo mark field as pending
                setFieldPendingState(false, state, filterName, fieldName);

                // call actual update reducer
                performValidatedReducer(action, state, arg);

                //Has the state been updated + update is filters applied, etc
                checkUpdate(state);

                //clear error
                setFieldError(null, state, action, filterName, fieldName);
            },
            [validateThenApply.rejected]: (
                state,
                {
                    payload,
                    error: {message},
                    meta: {
                        arg: {action, filterName, fieldName},
                    },
                },
            ) => {
                //mark field as not pending
                setFieldPendingState(false, state, filterName, fieldName);

                //Create error
                const error =
                    payload ||
                    (message ? {key: 'raw', props: {value: message}} : 'api.unknownError');

                //set error
                setFieldError(error, state, action, filterName, fieldName);
            },
        },
    });

    slice.actions.validateThenApply = validateThenApply;

    // React hook to allow easy access to the different actions and functions available
    slice.useBoundActions = function useBoundActions(dispatch, getState) {
        return useMemo(
            () => ({
                setConfig: (payload) => dispatch(slice.actions.setConfig(payload)),
                reset: () => dispatch(slice.actions.reset()),

                clearFilter: (filterName) => dispatch(slice.actions.clearFilter(filterName)),

                clearSectionFilters: (sectionName) =>
                    dispatch(slice.actions.clearSectionFilters(sectionName)),
                setSectionOpen: (section, open) =>
                    dispatch(slice.actions.setSectionOpen({section, open})),
                setError: (filter, value) => dispatch(slice.actions.setError({filter, value})),

                ...getFuncsToBind(dispatch, getState, slice.actions),
            }),
            [getState, dispatch],
        );
    };

    return slice;
}

//////////////////////////////
// Create the default slice //
//////////////////////////////
const slice = getFiltersSlice('filters');

export default slice.reducer;
export const {
    updateFilter,
    updateDateRange,
    initDateRange,
    updateTimezone,
    reset,
    setConfig,
    loadPersistedFilters,
    updateRelativeDateRange,
    updateFilterOptions,
    clearFilter,
} = slice.actions;

export const useBoundActions = slice.useBoundActions;

//////////////////////////////////////////////
// Utility functions for the Thunk reducers //
//////////////////////////////////////////////

function setFieldPendingState(isPending, state, filterName, fieldName = null) {
    setPendingForFilter(
        state.allFiltersConfig[filterName],
        isPending,
        state,
        filterName,
        fieldName,
    );
}

function setFieldError(error, state, action, filterName, fieldName = null) {
    setErrorForFilter(
        state.allFiltersConfig[filterName],
        error,
        state,
        action,
        filterName,
        fieldName,
    );
}
