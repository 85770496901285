import {useRef, useMemo, useCallback, useState, ReactNode, useEffect, forwardRef} from 'react';
import {isObject} from 'lodash';
import isEmpty from 'lodash/isEmpty';
import Typography from '@mui/material/Typography';

//Components
import IconRouter from 'hsi/components/IconRouter';
import VerticalScroll from 'hsi/components/layout/VerticalScroll';
import OverflowTooltip from 'hsi/components/Tooltip/OverflowTooltip';
import Button from 'hsi/components/Button';
import MentionsList from 'hsi/components/MentionsList';
import PeakExplanation from './PeakExplanation';
import HorizontalPositionedColumns from 'hsi/components/layout/HorizontalPositionedColumns';
import Mention, {MentionProps} from 'hsi/components/Mention';
import SingleMentionView from 'hsi/components/Mention/SingleMentionView';
import MentionsContainerHeader from './Header';
import BulkChangeSentimentDialog from './BulkChangeSentimentDialog';
import BulkDeleteDialog from './BulkDeleteDialog';
import BulkEditTagsDialog from './BulkEditTagsDialog';

//Actions
import {
    MentionsLayout,
    MentionsStateType,
    mentionsDrillOut,
    clearMentions,
    setMentionSelected,
    setBulkChangeSentimentIsOpen,
    setBulkDeleteIsOpen,
    setBulkEditTagsIsOpen,
} from 'hsi/slices/mentions';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useConnectedMentions from 'hsi/hooks/useConnectedMentions';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import useTrackNlaMentions from 'hsi/hooks/useTrackNlaMentions';
import useDates from 'hsi/hooks/useDates';

//Other
import useStyles from './styles';
import {T} from 'hsi/i18n';

//Types
import {MentionType} from 'hsi/types/mentions';
import {PeakType} from 'hsi/types/peaks';
import {MAX_SELECTED_MENTIONS} from 'hsi/constants/config';
import ConversationSummary from './ConversationSummary';
import { HeadingContents } from 'hsi/components/aria/Heading';

export type MentionsContainerProps = {
    isSelectMode?: boolean;
    setIsSelectMode?: (value: boolean) => void;
    isSavedSearch: boolean;
    canEdit: boolean;
    isFull: boolean;
    fullLayout: MentionsLayout;
    setFullLayout: (newLayout: MentionsLayout) => void;
    toggleIsOpen: () => void;
    toggleIsFull: () => void;
    peak?: PeakType; //Pretty sure the Peak type here is not correct, I need to investigate further
    drillIn: Pick<
        MentionsStateType,
        'drillInFrom' | 'drillInLabel' | 'drillInFilter' | 'drillInDates'
    >;
};

//The component
const MentionsContainer = ({
    isSelectMode: _isSelectMode,
    setIsSelectMode,
    isSavedSearch,
    canEdit,
    isFull: _isFull,
    fullLayout,
    setFullLayout,
    peak,
    drillIn: {drillInFrom, drillInLabel, drillInFilter, drillInDates},
    toggleIsOpen,
    toggleIsFull,
}: MentionsContainerProps) => {
    const {classes, cx} = useStyles();
    const {
        mentionsGrid: {masonry: masonryLayoutProps},
    } = useConfig();

    const {formatTo} = useDates();
    const startDate = drillInDates ? formatTo(drillInDates?.startDate, 'DDD') : undefined;
    const endDate = drillInDates ? formatTo(drillInDates?.endDate, 'DD ZZZZ') : undefined;

    const editable = isSavedSearch && canEdit;
    const isSelectMode = !!_isSelectMode && editable;
    const isFull = _isFull || isSelectMode;

    const mentionsLayout = isFull ? fullLayout : 'column';

    const dispatch = useAppDispatch();
    const isLoading = useAppSelector((state) => state.mentions.loading);
    const bulkChangeSentimentIsOpen = useAppSelector(
        (state) => state.mentions.bulkChangeSentimentIsOpen,
    );
    const bulkDeleteIsOpen = useAppSelector((state) => state.mentions.bulkDeleteIsOpen);
    const bulkEditTagsIsOpen = useAppSelector((state) => state.mentions.bulkEditTagsIsOpen);

    const {trackWithSearchData} = useEventTrack();

    const scrollRef = useRef<HTMLDivElement>(null);

    const {results: mentions, loadMentionsFunc, ...mentionsListProps} = useConnectedMentions();

    const loadMoreMentions = useCallback(
        () => loadMentionsFunc({append: true}),
        [loadMentionsFunc],
    );

    const filterVal = drillInFilter ? drillInLabel || null : null;
    const siteVal = drillInFilter ? drillInFrom || undefined : undefined;

    const getMentionsScrollParent = useCallback(() => scrollRef?.current!, []);

    //Selected mention in ths context means selected for SingleMentionView
    const {
        selectedMention,
        setSelectedMentionId,
        selectedMentionEditStatus,
        nextMention,
        prevMention,
    } = useSelectedMention(mentions, mentionsListProps.hasMore, loadMoreMentions);

    const onViewOriginalClick = useCallback(
        (id: string | number) => {
            trackWithSearchData('mentionLinkClicked', {
                mention: id,
            });
        },
        [trackWithSearchData],
    );

    const mentionElements: ReactNode = useMemo(
        () =>
            mentions &&
            mentions.length > 0 &&
            mentions.map((mention) => {
                const props: ConnectedMentionProps = {
                    data: mention,
                    key: mention.id,
                    editable,

                    selectable: isSelectMode,

                    onViewOriginalClick: isSelectMode
                        ? undefined
                        : () => onViewOriginalClick(mention.id),

                    //single mention view stuff
                    setAsSelectedMention: isSelectMode
                        ? undefined
                        : () => setSelectedMentionId(mention.id),
                    'aria-haspopup': isSelectMode ? undefined : 'dialog',
                    'aria-expanded': isSelectMode ? undefined : selectedMention?.id === mention.id,
                    'aria-controls': isSelectMode
                        ? undefined
                        : selectedMention?.id === mention.id
                        ? 'singleMentionView'
                        : undefined,
                };

                switch (mentionsLayout) {
                    case 'masonry':
                        return (
                            <HorizontalPositionedColumns.Item as={ConnectedMention} {...props} />
                        );
                    default:
                        return (
                            <ConnectedMention
                                {...props}
                                wideLayout={mentionsLayout === 'uniform'}
                            />
                        );
                }
            }),
        [
            mentions,
            editable,
            isSelectMode,
            selectedMention?.id,
            mentionsLayout,
            onViewOriginalClick,
            setSelectedMentionId,
        ],
    );

    const mentionsListAriaLabel = T('mentionsContainer.ariaLabel');

    useTrackNlaMentions(isLoading, mentions);

    //Side effects
    useEffect(() => {
        //When the component unloads, clear the loaded mentions
        return () => {
            dispatch(clearMentions());
        };
    }, [dispatch]);

    return (
        <section aria-label={T('mentionsContainer.title')} className={classes.mentionsContainer}>
            <MentionsContainerHeader
                isSelectMode={isSelectMode}
                setIsSelectMode={editable ? setIsSelectMode : undefined}
                isFull={isFull}
                fullLayout={fullLayout}
                setFullLayout={setFullLayout}
                isSavedSearch={isSavedSearch}
                toggleIsFull={isSelectMode ? undefined : toggleIsFull}
                toggleIsOpen={isSelectMode ? undefined : toggleIsOpen}
            />
            <HeadingContents>
                <div className={cx(classes.body)}>
                    <VerticalScroll className={classes.scroll} ref={scrollRef}>
                        {/* Announce the change in drill-in for screen-readers */}
                        <div role="status" className="offscreen">
                            {T('mentionsContainer.drillIn.viewing')}
                            {isObject(filterVal)
                                ? (filterVal as any).name
                                : filterVal}
                            
                            {isEmpty(siteVal)
                                ? ''
                                : T('mentionsContainer.drillIn.from')}
                            {drillInDates && (T('mentionsContainer.drillIn.previousDate', {
                                startDate,
                                endDate,
                            }))}
                            {isObject(siteVal) ? (siteVal as any).name : siteVal}
                        </div>
                        {drillInFilter && (
                            <div className={classes.drillIn}>
                                <IconRouter
                                    name="mui-remove-red-eye"
                                    className={classes.drillInIcon}
                                    aria-hidden
                                />
                                <div className={classes.drillInMsgContent} aria-hidden>
                                    <span className={classes.drillInMsgLabel}>
                                        {T('mentionsContainer.drillIn.viewing')}:
                                    </span>
                                    <OverflowTooltip>
                                        <span className={classes.drillInMsgValue}>
                                            <Typography variant="inherit">
                                                {isObject(filterVal)
                                                    ? (filterVal as any).name
                                                    : filterVal}
                                                <span className={classes.drillInMsgLabel}>
                                                    {isEmpty(siteVal)
                                                        ? ''
                                                        : T('mentionsContainer.drillIn.from')}
                                                </span>
                                                {drillInDates && (
                                                    <span>
                                                        {T('mentionsContainer.drillIn.previousDate', {
                                                            startDate,
                                                            endDate,
                                                        })}
                                                    </span>
                                                )}
                                                {isObject(siteVal) ? (siteVal as any).name : siteVal}
                                            </Typography>
                                        </span>
                                    </OverflowTooltip>
                                </div>
                                <Button
                                    priority="text"
                                    className={classes.drillInClearBtn}
                                    onClick={() => dispatch(mentionsDrillOut())}
                                    aria-label={T('mentionsContainer.drillIn.clear')}
                                >
                                    <span aria-hidden>{T('clear')}</span>
                                </Button>
                            </div>
                        )}
                        
                        {peak && <PeakExplanation peak={peak} />}

                        <ConversationSummary />

                        {mentionsLayout === 'masonry' ? (
                            <HorizontalPositionedColumns
                                as={MentionsList}
                                columns={masonryLayoutProps.getNumColumns}
                                gutters={masonryLayoutProps.gutters}
                                debounceOptions={masonryLayoutProps.debounceOptions}
                                transitionTime={masonryLayoutProps.transitionTime}
                                infiniteScrollThreshold={masonryLayoutProps.infiniteScrollThreshold}
                                asFeed
                                getScrollParent={getMentionsScrollParent}
                                loadMore={loadMoreMentions}
                                aria-label={mentionsListAriaLabel}
                                {...mentionsListProps}
                            >
                                {mentionElements}
                            </HorizontalPositionedColumns>
                        ) : mentionsLayout === 'uniform' ? (
                            <MentionsList
                                grid
                                key="mentionsList"
                                asFeed
                                getScrollParent={getMentionsScrollParent}
                                loadMore={loadMoreMentions}
                                infiniteScrollThreshold={masonryLayoutProps.infiniteScrollThreshold}
                                aria-label={mentionsListAriaLabel}
                                {...mentionsListProps}
                            >
                                {mentionElements}
                            </MentionsList>
                        ) : (
                            <MentionsList
                                key="mentionsList"
                                asFeed
                                getScrollParent={getMentionsScrollParent}
                                loadMore={loadMoreMentions}
                                infiniteScrollThreshold={masonryLayoutProps.infiniteScrollThreshold}
                                aria-label={mentionsListAriaLabel}
                                {...mentionsListProps}
                            >
                                {mentionElements}
                            </MentionsList>
                        )}
                        <SingleMentionView
                            //TODO when the dialog is closed, focus should be returned to the opening element (also Esc should close)
                            open={!!selectedMention}
                            mention={selectedMention}
                            editMentionStatus={selectedMentionEditStatus}
                            closeFunc={() => setSelectedMentionId(null)}
                            editable={editable}
                            nextMention={nextMention}
                            prevMention={prevMention}
                            loading={mentionsListProps.loading}
                            onViewOriginalClick={onViewOriginalClick as any} //TODO remove once SingleMentionView component is converted to TS
                        />
                    </VerticalScroll>
                </div>

                <BulkChangeSentimentDialog
                    isOpen={bulkChangeSentimentIsOpen}
                    onClose={() => dispatch(setBulkChangeSentimentIsOpen(false))}
                    onClosed={getDialogClosedHandler('sentiment')}
                />
                <BulkDeleteDialog
                    isOpen={bulkDeleteIsOpen}
                    onClose={() => dispatch(setBulkDeleteIsOpen(false))}
                    onClosed={getDialogClosedHandler('delete')}
                />
                <BulkEditTagsDialog
                    isOpen={bulkEditTagsIsOpen}
                    onClose={() => dispatch(setBulkEditTagsIsOpen(false))}
                    onClosed={getDialogClosedHandler('tags')}
                />
            </HeadingContents>
        </section>
    );
};

export default MentionsContainer;

type ConnectedMentionProps = Omit<
    MentionProps,
    'isSelected' | 'setIsSelected' | 'editMentionStatus'
>;

const ConnectedMention = forwardRef<HTMLElement, ConnectedMentionProps>(function ConnectedMention(
    {data: mention, selectable, ...rest},
    ref,
) {
    const dispatch = useAppDispatch();

    const editMentionStatus = useAppSelector(
        (state) => state.mentions.editMentionStatus[mention.id],
    );
    const isSelected = useAppSelector(
        (state) => selectable && !!state.mentions.selectedMentions[mention.id],
    );
    const numSelectedMentions = useAppSelector((state) => state.mentions.numSelectedMentions);
    const setIsSelected = useCallback(
        (isSelected: boolean) =>
            dispatch(setMentionSelected({id: mention.id, selected: isSelected})),
        [dispatch, mention.id],
    );

    const isMaxMentionsSelected = numSelectedMentions >= MAX_SELECTED_MENTIONS;

    return (
        <Mention
            data={mention}
            selectable={selectable}
            isSelected={isSelected}
            setIsSelected={selectable ? setIsSelected : undefined} //TODO disable if max mentions selected
            selectionDisabled={!isSelected && isMaxMentionsSelected}
            editMentionStatus={editMentionStatus}
            {...rest}
            ref={ref}
        />
    );
});

function useSelectedMention(
    mentions: MentionType[] | undefined,
    hasMore: boolean,
    loadMoreMentions: () => void,
) {
    const {trackWithSearchData} = useEventTrack();

    const [selectedMentionId, _setSelectedMentionId] = useState<string | number | null>(null);
    const setSelectedMentionId = useCallback(
        (id: string | number | null) => {
            id &&
                trackWithSearchData('mentionViewed', {
                    mention: id,
                });

            _setSelectedMentionId(id);
        },
        [_setSelectedMentionId, trackWithSearchData],
    );

    const [selectedMention, nextMention, prevMention] = useMemo(() => {
        if (selectedMentionId === null || !mentions) {
            return noMentionResult;
        }

        const index = mentions.findIndex((mention) => mention.id === selectedMentionId);

        if (index === -1) {
            return noMentionResult;
        }

        return [
            mentions[index],
            hasMore || index + 1 < mentions.length
                ? () => {
                      //next mention
                      if (index + 1 < mentions.length) {
                          setSelectedMentionId(mentions[index + 1].id);
                      } else {
                          //need to load more results, then set the selected mention
                          loadMoreMentions();
                      }
                  }
                : undefined,
            index > 0
                ? () => {
                      //prev mention
                      setSelectedMentionId(mentions[index - 1].id);
                  }
                : undefined,
        ];
    }, [selectedMentionId, mentions, hasMore, setSelectedMentionId, loadMoreMentions]);

    const selectedMentionEditStatus = useAppSelector((state) =>
        selectedMentionId ? state.mentions.editMentionStatus[selectedMentionId] : undefined,
    );

    return useMemo(
        () => ({
            selectedMention,
            selectedMentionEditStatus,
            nextMention,
            prevMention,
            setSelectedMentionId,
        }),
        [
            nextMention,
            prevMention,
            selectedMention,
            selectedMentionEditStatus,
            setSelectedMentionId,
        ],
    );
}

const noMentionResult = [undefined, undefined, undefined, undefined];

function getDialogClosedHandler(name: string) {
    return () => {
        //When dialog closes, returns focus to correct place
        const elem =
            document.getElementById(`mentions-bulkActionsMenu-${name}`) ??
            document.getElementById('mentions-bulkActionsMenu-menuBtn');

        elem?.focus();
    };
}
