import React from 'react';
import {FullViewport} from 'hsi/components/FullViewport';
import PulseLoader from 'hsi/components/PulseLoader';

const FullViewportPulseLoader = () => (
    <FullViewport>
        <PulseLoader size="large" />
    </FullViewport>
);

export default FullViewportPulseLoader;
