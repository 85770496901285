import React, {Component, ComponentClass, PropsWithChildren, ReactNode, forwardRef} from 'react';
import {assignRef} from 'hsi/utils/react';
import {WheelEvent} from 'react';
import {ForwardedRef} from 'react';

type InfiniteScrollProps = PropsWithChildren<{
    element?: any; //ElementType,
    hasMore?: boolean;
    initialLoad?: boolean;
    isReverse?: boolean;
    loader?: ReactNode;
    loadMore: (page: number) => void;
    pageStart?: number;
    getScrollParent?: () => HTMLElement;
    threshold?: number;
    useCapture?: boolean;
    useWindow?: boolean;
}>;

class InfiniteScroll extends Component<InfiniteScrollProps> {
    static defaultProps = {
        element: 'div',
        hasMore: false,
        initialLoad: true,
        pageStart: 0,
        threshold: 250,
        useWindow: true,
        isReverse: false,
        useCapture: false,
        loader: null,
        getScrollParent: null,
    };

    loadMore: boolean = false;
    pageLoaded: number = 0;
    options: any; //TODO
    scrollComponent: HTMLElement | null = null;
    beforeScrollHeight: number = 0;
    beforeScrollTop: number = 0;

    constructor(props: InfiniteScrollProps) {
        super(props);

        this.scrollListener = this.scrollListener.bind(this);
        this.eventListenerOptions = this.eventListenerOptions.bind(this);
        this.mousewheelListener = this.mousewheelListener.bind(this);
    }

    componentDidMount() {
        this.pageLoaded = this.props.pageStart!;
        this.options = this.eventListenerOptions();
        this.attachScrollListener();
    }

    componentDidUpdate() {
        if (this.props.isReverse && this.loadMore) {
            const parentElement = this.getParentElement(this.scrollComponent);
            parentElement!.scrollTop =
                parentElement!.scrollHeight - this.beforeScrollHeight + this.beforeScrollTop;
            this.loadMore = false;
        }
        this.attachScrollListener();
    }

    componentWillUnmount() {
        this.detachScrollListener();
        this.detachMousewheelListener();
    }

    isPassiveSupported() {
        let passive = false;

        const testOptions = {
            get passive() {
                return (passive = true);
            },
        };

        try {
            document.addEventListener('test' as any, null as any, testOptions as any);
            document.removeEventListener('test' as any, null as any, testOptions as any);
        } catch (e) {
            // ignore
        }
        return passive;
    }

    eventListenerOptions() {
        let options: any = this.props.useCapture; //<<This makes no sense, but leaving as is

        if (this.isPassiveSupported()) {
            options = {
                useCapture: this.props.useCapture,
                passive: true,
            };
        } else {
            options = {
                passive: false,
            };
        }
        return options;
    }

    detachMousewheelListener() {
        let scrollEl: typeof window | ParentNode | null = window;

        if (this.props.useWindow === false) {
            scrollEl = this.scrollComponent!.parentNode;
        }

        scrollEl?.removeEventListener(
            'mousewheel',
            this.mousewheelListener as any,
            this.options ? this.options : this.props.useCapture,
        );
    }

    detachScrollListener() {
        let scrollEl: typeof window | HTMLElement | null = window;

        if (this.props.useWindow === false) {
            scrollEl = this.getParentElement(this.scrollComponent);
        }

        scrollEl?.removeEventListener(
            'scroll',
            this.scrollListener,
            this.options ? this.options : this.props.useCapture,
        );
        scrollEl?.removeEventListener(
            'resize',
            this.scrollListener,
            this.options ? this.options : this.props.useCapture,
        );
    }

    getParentElement(el: HTMLElement | null): HTMLElement | null {
        const scrollParent = this.props.getScrollParent && this.props.getScrollParent();
        if (!!scrollParent) {
            return scrollParent;
        }

        return el && (el.parentNode as HTMLElement);
    }

    attachScrollListener() {
        const parentElement = this.getParentElement(this.scrollComponent);

        if (!this.props.hasMore || !parentElement) {
            return;
        }

        let scrollEl: typeof window | HTMLElement = window;

        if (this.props.useWindow === false) {
            scrollEl = parentElement;
        }

        scrollEl.addEventListener(
            'mousewheel',
            this.mousewheelListener as any,
            this.options ? this.options : this.props.useCapture,
        );
        scrollEl.addEventListener(
            'scroll',
            this.scrollListener,
            this.options ? this.options : this.props.useCapture,
        );
        scrollEl.addEventListener(
            'resize',
            this.scrollListener,
            this.options ? this.options : this.props.useCapture,
        );

        if (this.props.initialLoad) {
            this.scrollListener();
        }
    }

    mousewheelListener(e: WheelEvent<HTMLElement>) {
        // Prevents Chrome hangups
        // See: https://stackoverflow.com/questions/47524205/random-high-content-download-time-in-chrome/47684257#47684257
        if (e.deltaY === 1 && !this.isPassiveSupported()) {
            e.preventDefault();
        }
    }

    scrollListener() {
        const el = this.scrollComponent;

        if (!el) {
            return;
        }

        const scrollEl = window;
        const parentNode = this.getParentElement(el);

        let offset: number;

        if (this.props.useWindow) {
            const doc = document.documentElement || document.body.parentNode || document.body;
            const scrollTop =
                scrollEl.pageYOffset !== undefined ? scrollEl.pageYOffset : doc.scrollTop;
            if (this.props.isReverse) {
                offset = scrollTop;
            } else {
                offset = this.calculateOffset(el, scrollTop);
            }
        } else if (this.props.isReverse) {
            offset = parentNode?.scrollTop ?? 0;
        } else {
            offset =
                el.scrollHeight - (parentNode?.scrollTop ?? 0) - (parentNode?.clientHeight ?? 0);
        }

        // Here we make sure the element is visible as well as checking the offset
        if (offset < Number(this.props.threshold) && el && el.offsetParent !== null) {
            this.detachScrollListener();
            this.beforeScrollHeight = parentNode?.scrollHeight ?? 0;
            this.beforeScrollTop = parentNode?.scrollTop ?? 0;
            // Call loadMore after detachScrollListener to allow for non-async loadMore functions
            if (typeof this.props.loadMore === 'function') {
                this.props.loadMore((this.pageLoaded += 1));
                this.loadMore = true;
            }
        }
    }

    calculateOffset(el: Element | null, scrollTop: number) {
        if (!el || !(el instanceof HTMLElement)) {
            return 0;
        }

        return this.calculateTopPosition(el) + (el.offsetHeight - scrollTop - window.innerHeight);
    }

    calculateTopPosition(el: Element | null): number {
        if (!el || !(el instanceof HTMLElement)) {
            return 0;
        }
        return el.offsetTop + this.calculateTopPosition(el.offsetParent);
    }

    render() {
        const {
            children,
            element,
            hasMore,
            initialLoad,
            isReverse,
            loader,
            loadMore,
            pageStart,
            forwardedRef,
            threshold,
            useCapture,
            useWindow,
            getScrollParent,
            ...props
        } = this.props as typeof this.props & {forwardedRef?: ForwardedRef<HTMLElement>};

        const ref = (node: HTMLElement | null) => {
            this.scrollComponent = node;

            forwardedRef && assignRef(forwardedRef, node);
        };

        const Element = element!;

        return (
            <Element {...props} ref={ref}>
                {hasMore && !!loader && isReverse && loader}
                {children}
                {hasMore && !!loader && !isReverse && loader}
            </Element>
        );
    }
}

const withForwardedRef = (Comp: any) => {
    const handle = (props: any, ref: any) => <Comp {...props} forwardedRef={ref} />;
    const name = Comp.displayName || Comp.name;
    handle.displayName = `withForwardedRef(${name})`;

    return forwardRef(handle);
};

export default withForwardedRef(
    InfiniteScroll,
) as unknown as ComponentClass<InfiniteScrollProps> & {ref: ForwardedRef<HTMLElement>};
