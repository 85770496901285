import {useCallback, useMemo} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Components
import Autocomplete, {renderDefaultInputComponent} from 'hsi/components/AutocompleteLegacy';
import LabelledButton from 'hsi/components/Button/LabelledButton';
import Popover, {PopoverRenderFunc, sizeToOpener} from 'hsi/components/Popover';

//Other
import useConfig from 'hsi/hooks/useConfig';
import useStyles from './styles';

import {T} from 'hsi/i18n';
import validTimezones, {Timezone, TimezoneID} from 'hsi/utils/timezones';
import classNames from 'classnames';

interface TimezoneSelectorProps {
    className?: string;
    timezone?: TimezoneID;
    defaultTimezone?: TimezoneID;
    setTimezone?: (newTimezone: TimezoneID) => void;
    timezones?: Timezone[];
}

//The component
const TimezoneSelector = ({
    className,
    timezone,
    setTimezone,
    defaultTimezone,
    timezones = validTimezones,
}: TimezoneSelectorProps) => {
    const classes = useStyles();
    const {
        datePicker: {hasTimezoneIcon},
    } = useConfig() as unknown as {[key: string]: any};

    const timezoneObject = useMemo(
        () => timezones.find(({id}) => id === timezone),
        [timezone, timezones],
    );

    const defaultTimezoneObject = useMemo(() => {
        //Find the timezone object for the default timezone.
        const timezone = timezones.find(({id}) => id === defaultTimezone);

        if (!timezone) {
            return null;
        }

        return {
            id: timezone.id,
            label: T('datepicker.timezoneSelector.defaultTimezone', {
                defaultTimezone: timezone.label,
            }),
        };
    }, [defaultTimezone, timezones]);

    const timezoneOptions = useMemo(
        () => timezones.filter(({id}) => id !== defaultTimezoneObject?.id),
        [defaultTimezoneObject?.id, timezones],
    );

    const handleChange = useCallback(
        (newTimezone: Timezone) => {
            setTimezone?.(newTimezone.id);
        },
        [setTimezone],
    );

    const popoverContent = useCallback<PopoverRenderFunc>(
        ({close, labelId, descriptionId}) => (
            <>
                <span id={labelId} className="offscreen">
                    {T('datepicker.timezoneSelector.accessibleDialogTitle')}
                </span>
                <span id={descriptionId} className="offscreen">
                    {T('datepicker.timezoneSelector.accessibleDialogDescription')}
                </span>
                <span id={`${labelId}-label`} className="offscreen">
                    {T('datepicker.timezoneSelector.inputLabel')}
                </span>
                <Autocomplete
                    onChange={(_e, newTimezone: Timezone) => {
                        handleChange(newTimezone);
                        close();
                    }}
                    open
                    options={timezoneOptions}
                    renderInput={(props: any) => (
                        <>
                            {renderDefaultInputComponent({
                                ...props,
                                label: `${labelId}-label`,
                                placeholder: T('datepicker.timezoneSelector.placeholder'),
                            })}
                            {defaultTimezoneObject?.label && (
                                <div
                                    className={cn(
                                        classes.timezoneDropdownDefaultOption,
                                        defaultTimezoneObject.id === timezoneObject?.id &&
                                            classes.timezoneDropdownDefaultOptionSelected,
                                    )}
                                    onClick={() => {
                                        handleChange(defaultTimezoneObject);
                                        close();
                                    }}
                                    role="button"
                                >
                                    {renderOptionComponent({
                                        ...props,
                                        hasTimezoneIcon,
                                        iconClassName: classes.timezoneOptionIcon,
                                        parts: [{text: defaultTimezoneObject.label}],
                                    })}
                                </div>
                            )}
                        </>
                    )}
                    renderOptionComponent={(props: any) =>
                        renderOptionComponent({
                            ...props,
                            hasTimezoneIcon,
                            iconClassName: classes.timezoneOptionIcon,
                        })
                    }
                />
            </>
        ),
        [
            classes,
            handleChange,
            hasTimezoneIcon,
            defaultTimezoneObject,
            timezoneObject?.id,
            timezoneOptions,
        ],
    );

    return (
        <Popover size={sizeToOpener} content={popoverContent}>
            <LabelledButton
                data-testid="timeZoneSelectorBtn"
                aria-label={T('datepicker.timezoneSelector.btnLabel')}
                className={classNames(classes.timezoneSelect, className)}
                endIcon={<ExpandMoreIcon className={classes.timezoneSelectDropdownIcon} />}
                fullWidth
                priority="primary"
                startIcon={
                    hasTimezoneIcon && (
                        <AccessTimeIcon
                            className={classes.timezoneSelectClockIcon}
                            data-testid="timezoneSelectorClockIcon"
                        />
                    )
                }
                label={
                    <div aria-live="polite" aria-atomic="true">
                        <span className="offscreen">
                            {T('datepicker.timezoneSelector.accessibleSelectedTimezone')}
                        </span>
                        <span className={classes.timezoneSelectBtnLbl}>
                            {timezoneObject?.id === defaultTimezoneObject?.id
                                ? defaultTimezoneObject?.label
                                : timezoneObject?.label}
                        </span>
                    </div>
                }
            >
                {T('datepicker.timezoneSelector.btnLabel')}
            </LabelledButton>
        </Popover>
    );
};

TimezoneSelector.propTypes = {
    className: PropTypes.string,
    timezone: PropTypes.string.isRequired,
    setTimezone: PropTypes.func.isRequired,
    defaultTimezone: PropTypes.string.isRequired,
    timezones: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
        }),
    ),
};

interface renderOptionComponentProps {
    hasTimezoneIcon: boolean;
    iconClassName: string;
    parts: any;
}

const renderOptionComponent = ({
    hasTimezoneIcon,
    iconClassName,
    parts,
}: renderOptionComponentProps) => (
    <>
        {hasTimezoneIcon && (
            <AccessTimeIcon
                aria-hidden="true"
                className={iconClassName}
                data-testid="timezoneSelectorOptionClockIcon"
            />
        )}
        <div>
            {parts.map((part: any, index: number) => {
                if (part.highlight) {
                    return <strong key={index}>{part.text}</strong>;
                } else {
                    return <span key={index}>{part.text}</span>;
                }
            })}
        </div>
    </>
);

export default TimezoneSelector;
