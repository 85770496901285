///////////////////
// Legacy to API //
///////////////////

//Used in legacyToState

import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import {classificationToAPI, isValidValue} from './utils';
import {APIFilterFormat, LegacyFiltersState} from 'hsi/types/filters';
import {SearchType} from 'hsi/types/shared';

const MAX_MENTIONS = 500; //needed to prevent issue with excessive query sizes

const getId = (n: any) => n.id || n;

const getMapVal = (to: string, func: (value: any, key: string) => any) => {
    return (output: {[key: string]: any}, value: any, key: string) => {
        const result = func(value, key);

        if (isValidValue(result)) {
            output[to] = result;
        }
    };
};

const getMapBy = (func: (value: any, index: number, array: any[]) => any) => {
    return (output: any, arr: any[], key: string) => {
        if (!isEmpty(arr) && arr.length > 0) {
            output[key] = arr.map(func);
        }
    };
};

function copyIfValid(output: {[key: string]: any}, value: any, key: string) {
    if (isValidValue(value)) {
        output[key] = value;
    } else {
        output[key] = undefined;
    }
}

const mapId = getMapBy(getId);
const mapAuthor = getMapBy((val) => (val.indexOf('@') === 0 ? val.slice(1) : val));

const mapSaved = {
    location: mapId,
    language: mapId,
    xlocation: mapId,
    xlanguage: mapId,
    xtag: mapId,

    tag: getMapVal('anyTag', (arr) => [...(arr || []).map(getId)]),
    sentiment: getMapBy((val) => val.toLowerCase()),
    classifications: getMapBy(classificationToAPI),
    author: mapAuthor,
    xauthor: mapAuthor,
    keyword: (output: any, value: string[], key: string) => {
        if (value?.length > 0) {
            const result = value[0].replace(/[,']/g, '');

            if (result) {
                output.search = result;
            }
        }
    },
    topicWheel: (output: any, value: any[], key: string) => {
        output.topicLabel = value[0].name;
        output.mentionId = value[0].mentions.slice(0, MAX_MENTIONS).join(',');
    },
    gender: (output: {[key: string]: any}, value: any, key: string) => {
        if (isValidValue(value)) {
            output[key] = value.map((value: string) =>
                value === 'M' ? 'male' : value === 'F' ? 'female' : value,
            );
        } else {
            output[key] = undefined;
        }
    }, //M/F to male/female
    accountType: copyIfValid,
    threadEntryType: copyIfValid,
    twitterVerified: (output: any, value: any[]) => {
        if (value && value.length === 1) {
            output.twitterVerified = value[0].toString() === 'true';
        }
    },
    twitterFollowersMin: copyIfValid,
    twitterFollowersMax: copyIfValid,
    pageType: (output: any, value: any) => {
        if (!isEmpty(value)) {
            output.pageType = value;
        }
    },
    domain: copyIfValid,
    xdomain: copyIfValid,

    //workflow
    assigned: copyIfValid,
    priority: copyIfValid,
    checked: copyIfValid,
    status: copyIfValid,

    //category
    category: copyIfValid,
    xcategory: copyIfValid,
    parentCategory: copyIfValid,
    xparentCategory: copyIfValid,
};

function getMapLocationsQuick(prop: string) {
    return (output: any, value: any[]) => {
        if (value.length > 0) {
            if (!output.locationFilter) {
                output.locationFilter = {};
            }

            output.locationFilter[prop] = [...value.map(getId)];
        }
    };
}

const mapQuick = {
    location: getMapLocationsQuick('includedLocations'),
    xlocation: getMapLocationsQuick('excludedLocations'),
    classifications: getMapVal('emotion', (arr) => arr.map(capitalize)),
    keyword: (output: any, value: string[]) => {
        if (value.length > 0 && value[0] !== '') {
            output.queryAppend = ` AND (${value[0].replace(/[,']/g, '')})`;
        }
    },
    pageType: (output: any, value: any[]) => {
        if (!isEmpty(value)) {
            output.contentSource = value;
        }
    },
    gender: copyIfValid,
    language: mapId,
    //xlanguage does not exist for quick search
    sentiment: copyIfValid,
    author: mapAuthor,
    //xauthor does not exist
};

export default function legacyFilterStateToAPIFormat(
    filters: LegacyFiltersState,
    searchType: SearchType = 'saved',
): APIFilterFormat {
    return parseObjectFields(filters || {}, searchType === 'saved' ? mapSaved : mapQuick);
}

//Internal helpers
type MapFieldMethod = (output: {[key: string]: any}, value: any, field: any) => void;

function parseObjectFields(
    obj: {[key: string]: any},
    mapFieldMethods: {[key: string]: MapFieldMethod},
    all: boolean = false,
): {[key: string]: any} {
    const output: {[key: string]: any} = {};

    Object.keys(all ? mapFieldMethods : obj).forEach((field) => {
        const value = obj[field];

        const mapFieldMethod = mapFieldMethods[field];

        if (mapFieldMethod) {
            mapFieldMethod(output, value, field);
        }
    });

    return output;
}
