import {shallowEqual} from 'react-redux';

//Hooks
import useMultipleSearchIds from 'hsi/hooks/useMultipleSearchIds';
import {useAppSelector} from './useRedux';
import {useMemo, useRef} from 'react';
import {SearchStateType} from 'hsi/types/shared';

//The hook
export default function useOtherQueryNames() {
    const lastValue = useRef<(string | null)[]>();
    const {searchIds: allSearchIds} = useMultipleSearchIds();
    const searches = useAppSelector((state) => (state.search as any).searches);

    useMemo<(string | null)[]>(() => {
        const newList = allSearchIds
            .slice(1)
            .map((id) => searches?.find((search: SearchStateType) => search.id === id)?.name);

        if (!shallowEqual(newList, lastValue.current)) {
            lastValue.current = newList;
        }

        return newList;
    }, [allSearchIds, searches]);

    return lastValue.current;
}
