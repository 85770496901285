import {PropsWithChildren, ReactElement, useEffect, useMemo} from 'react';
import localForage from 'localforage';
import qs from 'qs';

import UnauthorizedScreen from 'hsi/components/UnauthorizedScreen';
import ManualLogin from 'hsi/containers/Login/ManualLogin';
import FullscreenLoading, {FullscreenLoadingProps} from '../../components/FullscreenLoading';

import LoadFlags from './LoadFlags';
import {logIn} from 'hsi/actions/userActions';

import {LOGIN_DEBOUNCE_MS} from 'hsi/constants/config';
import {ActionCreatorWithPayload} from '@reduxjs/toolkit';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import {T} from 'hsi/i18n';

const DEV_CREDS = process.env.REACT_APP_DEV_CREDENTIALS;

/**
 * Not 100% sure this is correct - I THINK it's user/pass, but might be username and password
 */
type Credentials = {jwt: string} | {user: string; pass: string};

type SubmitActionType = ActionCreatorWithPayload<{user: string; pass: string}>;

type LoginProps = PropsWithChildren<{
    unauthorized?: (errors: any) => ReactElement; //TODO errors are the values from state.user.errors, so once we work out what they are, we can type this
    classes?: {}; //do we ever use this? Should we?
    loadingProps?: Omit<FullscreenLoadingProps, 'message'>;
    loadHSIExtras?: boolean;
    submitAction?: SubmitActionType;
    btnPriority?: 'primary' | 'cta';
}>;

export default function Login({
    children,
    unauthorized,
    classes,
    loadingProps,
    loadHSIExtras = true,
    submitAction,
    btnPriority = 'primary',
}: LoginProps) {
    const dispatch = useAppDispatch();

    const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
    const loginLoading = useAppSelector((state) => state.user.loginLoading);
    const loadingMsg = useAppSelector((state) => state.user.loginLoadingMessage);
    const linkPending = useAppSelector((state) => !!state.user.linkPending);
    const errors = useAppSelector((state) => state.user.errors);
    const errorCode = useAppSelector((state) => state.user.errorCode);
    const status = useAppSelector((state) => state.user.status);

    const params = useMemo(() => {
        let jwt;
        try {
            const urlParams = qs.parse(document.location.search.replace('?', ''));
            const hashParams = qs.parse(document.location.hash.replace('#', ''));
            jwt = urlParams.apiAuthorization || urlParams.id_token || hashParams.id_token || null;
        } catch (e) {}

        let params;
        if (jwt) {
            params = {jwt};
        } else if (DEV_CREDS) {
            params = JSON.parse(DEV_CREDS);
        }

        return params as Credentials;
    }, []);

    useEffect(() => {
        const doLogin = async () => {
            let lastLogin: number | null = null;
            try {
                lastLogin = await localForage.getItem<number>('lastLogin');
            } catch (e) {}

            const runLogin = () => {
                localForage.setItem('lastLogin', Date.now());
                dispatch(logIn(params, loadHSIExtras));
            };

            //Not sure why we need to do the parseInt here, leaving it for 'safety'
            if (!lastLogin || Date.now() - parseInt(lastLogin.toString()) > LOGIN_DEBOUNCE_MS) {
                runLogin();
            } else {
                const tid = setTimeout(() => runLogin(), LOGIN_DEBOUNCE_MS);
                return () => clearTimeout(tid);
            }
        };
        doLogin();
    }, [dispatch, loadHSIExtras, params]);

    const loadingComp = (
        <FullscreenLoading {...loadingProps} message={loadingMsg || T('loading')} />
    );

    if (isLoggedIn === null) {
        return loadingComp;
    } else if (isLoggedIn === true && !loginLoading) {
        return (
            <LoadFlags>
                {children}
            </LoadFlags>
        );
    } else if (!!params && loginLoading === false && !linkPending) {
        return typeof unauthorized === 'function' ? (
            unauthorized(errors)
        ) : (
            <UnauthorizedScreen {...{errors, code: errorCode, status}} />
        );
    } else {
        return (
            <>
                {loginLoading && loadingComp}
                {isLoggedIn === false && <ManualLogin {...{classes, submitAction, btnPriority}} />}
            </>
        );
    }
}
