import http from "hsi/classes/Http";
import { AllFilteringState } from "hsi/types/filters";
import { QueryContextType } from "hsi/types/query";
import getQueryIdUrl from "hsi/utils/app/getQueryIdUrl";
import { filterStateToAPIFormat } from "hsi/utils/filters";
import { getSearchParams, queryParamsToString, SavedSearchParamsType } from "hsi/utils/url";


export async function checkSearchHasData(
    queryId: number, 
    projectId: number, 
    filters: Pick<AllFilteringState, 'filters' | 'allFiltersConfig' | 'dateRange'>,
    queryContext: QueryContextType,
    additionalQueries: number[] | undefined,
    linkedinChannelIds: number[] | undefined,
) {
    //queryId not required as it's part of the url, and timezone is not required as start & end date are already specified
    const {queryId: _, timezone, ...params} = getSearchParams(
        filterStateToAPIFormat(filters.filters, filters.allFiltersConfig),
        filters.dateRange,
        queryContext,
        additionalQueries,
        linkedinChannelIds,
        true
    ) as SavedSearchParamsType;

    return http.get<{result: boolean}>(`/apiv2/${projectId}/saved-search/${getQueryIdUrl(queryId, additionalQueries, linkedinChannelIds)}/results/hasData${queryParamsToString(params)}`)
}
