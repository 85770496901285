import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles(({colors, font, mixin, fonts, v2}: Theme) => ({
    statusContainer: {
        border: v2 ? `1px solid ${colors.borders}` : `1px solid ${colors.darkGrey60}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 286,
        width: '100%',
    },
    statusIconWrapper: {
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 20,
        padding: 10,
        ...mixin.icon.massiveSize,
    },
    statusIcon: {
        ...mixin.icon.normalSize,
    },
    statusTitle: {
        ...(v2 ? fonts.headtitleStrong : {fontSize: 20, fontWeight: font.bold}),
        marginBottom: 8,
    },
    statusDesc: {
        ...(v2 ? fonts.headtitleNormal : {fontSize: 16, fontWeight: font.regular}),
        marginBottom: 4,
    },
    statusButton: {
        backgroundColor: 'unset',
        margin: 12,
    },
    errorStatusIconWrapper: {
        backgroundColor: v2 ? colors.primaryRed60 : colors.primaryRed40,
    },
    successStatusIconWrapper: {
        backgroundColor: colors.secondaryGreen40,
    },
    errorStatusIcon: {
        fill: colors.primaryRed100,
    },
    successStatusIcon: {
        fill: colors.secondaryGreen100,
    },
    errorStatus: {
        backgroundColor: colors.primaryRed10,
    },
    successStatus: {
        backgroundColor: colors.secondaryGreen10,
    },
}));
