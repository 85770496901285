import capitalize from 'lodash/capitalize';
import isFQDN from 'validator/lib/isFQDN';

import {EMOTIONS_CATEGORIES, GENDERS, MAX_INT, SENTIMENT_CATEGORIES} from 'hsi/constants/config';
import {validateFilterQuery} from 'hsi/services/queryValidationService';
import {
    APIFilterFormat,
    CheckboxesFilterConfig,
    CheckboxesFilterState,
    FiltersConfigType,
    FilterSectionConfig,
    FiltersState,
    FilterState,
    FromApiFormatData,
    IncludeAndExcludeFilterConfig,
    IncludeAndExcludeFilterFieldState,
    IncludeAndExcludeFilterState,
    IncludeOrExcludeFilterState,
    LanguageDefinition,
    LocationDefinition,
    RangeFilterConfig,
    TagDefinition,
    TextfieldFilterConfig,
    UpgradeFilterFormatMethod,
} from 'hsi/types/filters';
import {IdObject, PageType} from 'hsi/types/shared';
import mapPageTypesToOptions from 'hsi/utils/filters/mapPageTypesToOptions';
import {includeOrExcludeToIncludeAndExclude} from 'hsi/utils/filters/normalisePersistedFilters';
import {
    checkboxesFromApiFormat,
    checkboxesToApiFormat,
    classificationFromAPI,
    classificationToAPI,
    getCheckboxesFromApiFormat,
    getCheckboxesToApiFormat,
    getIncludeAndExcludeFromApiFormat,
    getIncludeAndExcludeToApiFormat,
    getMapValFromAPI,
    getPageTypesToAPI,
    getRangeFromApiFormat,
    getRangeToApiFormat,
    getTextfieldToApiFormat,
    isValidValue,
    textfieldFilterFromAPIFormat,
    textfieldFilterToAPIFormat,
} from 'hsi/utils/filters/utils';
import {isEqual, pick} from 'lodash';
import type {ISocialPanel} from 'hsi/types/socialPanels';
import {CCFlagsType} from 'hsi/types/cc-flags';

//Utils (temp?)
const compareById = <T extends {id: any}>({id}: T, {id: id2}: T): boolean => id === id2;
const getFullName = ({fullName}: LanguageDefinition | LocationDefinition) => fullName;
const getId = <T = string | number>({id}: IdObject<T>): T => id;

const getLanguageById = (
    id: string,
    {languages}: FromApiFormatData,
): LanguageDefinition | undefined => {
    return languages.find((item) => item.id === id);
};

const getLocationById = (id: string, {locations}: FromApiFormatData): LocationDefinition => {
    const location = locations.find((item) => item.id === id);

    if (!location) {
        throw new Error('Unknown location');
    }

    return location;
};

const getSocialPanelById = (id: string, {socialPanels}: FromApiFormatData): ISocialPanel =>
    socialPanels?.find((item) => item.id === id)!;
const getSocialPanelLabel = (socialPanel: ISocialPanel) => socialPanel.name;

const getTagById = (id: number, {tags}: FromApiFormatData): TagDefinition | undefined => {
    return tags.find((item) => item.id === id);
};

const genderToQSAPI = (value: string) => {
    if (value.toLowerCase() === 'male') {
        return 'M';
    } else if (value.toLowerCase() === 'female') {
        return 'F';
    }

    //Should only ever be male or female
    console.warn('Unknown gender value: ', value);

    return value;
};

function handleQueryAppend(filterState: FilterState, output: APIFilterFormat, prop: string): void {
    const value: string = (filterState as string)?.trim();

    if (value) {
        output.queryAppend = (output.queryAppend || '') + ` AND (${value.replace(/[,']/g, '')})`;
    }
}

const genderFromQSAPI = (value: string | boolean): string => {
    switch (value) {
        case 'M':
            return 'male';
        case 'F':
            return 'female';
    }

    console.warn('Unknown gender value from QS API: ', value);

    return value.toString();
};

function mergeArraysBy<T>(arr1: T[], arr2: T[], isEqualTestFunc: (a: T, b: T) => boolean): T[] {
    return [
        ...arr1,
        ...arr2.filter((value2) => !arr1.some((value1) => isEqualTestFunc(value1, value2))),
    ];
}

function mergeIncludeAndExcludeField<T>(
    field1: IncludeAndExcludeFilterFieldState<T> | undefined,
    field2: IncludeAndExcludeFilterFieldState<T> | undefined,
    isEqualTestFunc: (a: T, b: T) => boolean = isEqual,
): IncludeAndExcludeFilterFieldState<T> {
    const include = mergeArraysBy<T>(field1?.include ?? [], field2?.include ?? [], isEqualTestFunc);

    //same value can't be in exclude and include
    return {
        include,
        exclude: mergeArraysBy(field1?.exclude ?? [], field2?.exclude ?? [], isEqual).filter(
            (excludeValue) =>
                !include.some((includeValue) => isEqualTestFunc(excludeValue, includeValue)),
        ),
    };
}

function mergeIncludeAndExclude<T = string>(
    f1: IncludeAndExcludeFilterState<T> | undefined,
    f2: IncludeAndExcludeFilterState<T> | undefined,
    fields: string[],
): IncludeAndExcludeFilterState<T> {
    if (!f1 && !f2) {
        return {
            activeModeIsInclude: true,
            fields: {},
        };
    }

    if (f1 && f2) {
        return {
            activeModeIsInclude: f1.activeModeIsInclude,
            fields: fields.reduce<{[key: string]: IncludeAndExcludeFilterFieldState<T>}>(
                (output, fieldName) => {
                    output[fieldName] = mergeIncludeAndExcludeField(
                        f1.fields[fieldName],
                        f2.fields[fieldName],
                    );

                    return output;
                },
                {},
            ),
        };
    }

    if (f1) {
        return {
            ...f1,
            fields: pick(f1.fields, fields),
        };
    }

    //Need to cast to non-undefined as TS doesn't see that this must be set in order to reach here
    const f2r: IncludeAndExcludeFilterState<T> = f2 as IncludeAndExcludeFilterState<T>;

    return {
        ...f2r,
        fields: pick(f2r?.fields, fields),
    };
}

//UpgradeFilterFormatMethod[], list of functions that are called in order to modify the filters state.
//Their position in the sequence indicates which version they 'upgrade' to, so the first item upgrade
//from version 0 (the original un-versioned format) to version 1. The next method would go from 1 to 2, etc.
const upgradePersistedFiltersStateMethods: UpgradeFilterFormatMethod[] = [
    //upgrade from version 0 (before versioning was added) to version 1
    (data: FiltersState, searchType: 'saved' | 'quick'): FiltersState => {
        //We changed location and language from type=includeOrExclude to  type=includeAndExclude
        //and we removed ownedContent, so need to merge values into author and site

        return {
            ...data,
            location:
                !data.location || searchType === 'quick'
                    ? data.location
                    : includeOrExcludeToIncludeAndExclude<LocationDefinition>(
                          data.location as IncludeOrExcludeFilterState<LocationDefinition>,
                          'location',
                      ),
            language:
                !data.language || searchType === 'quick'
                    ? data.langauge
                    : includeOrExcludeToIncludeAndExclude(
                          data.language as IncludeOrExcludeFilterState,
                          'language',
                      ),
            author: mergeIncludeAndExclude(
                data.author as IncludeAndExcludeFilterState<string>,
                data.ownedContent as IncludeAndExcludeFilterState<string>,
                ['author'],
            ),
            site: mergeIncludeAndExclude(
                data.site as IncludeAndExcludeFilterState<string>,
                data.ownedContent as IncludeAndExcludeFilterState<string>,
                ['domain'],
            ),
        };
    },
    (data: FiltersState, searchType: 'saved' | 'quick'): FiltersState => {
        return searchType === 'saved' ? {...data, socialPanels: {}} : data;
    },
];

//The exported config
const filtersConfig: FiltersConfigType = {
    upgradePersistedFiltersStateMethods,
    versionNumber: upgradePersistedFiltersStateMethods.length,

    savedSearchFilters: ({
        pageTypes,
        projectId,
        flags,
    }: {
        pageTypes: PageType[];
        projectId: number;
        flags?: CCFlagsType;
    }): FilterSectionConfig[] => {
        if (!pageTypes) {
            debugger;
        }
        const {hasSocialPanelsEnabled} = flags!;

        return [
            {
                sectionName: 'keyword',
                filters: [
                    {
                        filterName: 'keyword',
                        type: 'textfield',
                        immediate: false,
                        placeholder: 'filters.placeholders.search.keyword',
                        helperText: 'filters.helperText.keyword',
                        asyncValidation: async (value: string) => {
                            const result = await validateFilterQuery({
                                query: value,
                                projectId,
                            });

                            if (!result.errors || result.errors.length === 0) {
                                return true;
                            } else {
                                return result.errors.map((error: any) => ({
                                    key: 'raw',
                                    props: {value: error},
                                }));
                            }
                        },
                        toAPI: (
                            filterState: FilterState,
                            output: APIFilterFormat,
                            prop: string,
                        ) => {
                            const value: string = (filterState as string)?.replace(/[,]/g, '');

                            output.search = value || undefined;
                        },
                        fromAPI: getMapValFromAPI<string>('', 'search'),
                    } as TextfieldFilterConfig,
                ],
            },
            {
                // This is overridden with this hook: useTempUpdateFilterOptions
                sectionName: 'pageType',
                filters: [
                    {
                        filterName: 'pageType',
                        type: 'checkboxes',
                        options: mapPageTypesToOptions(pageTypes),
                        ignoreIfAllSelected: true,
                        toAPI: getPageTypesToAPI(pageTypes, 'pageType'),
                        fromAPI: (
                            data: APIFilterFormat,
                            prop: keyof APIFilterFormat,
                            definitions: FromApiFormatData,
                        ) => {
                            const pageTypesNames = pageTypes.map(({value}) => value);
                            const selectedPageTypes = pick(
                                checkboxesFromApiFormat(data, prop, definitions),
                                ...pageTypesNames,
                            );
                            const numSelectedPageTypes = Object.values(
                                selectedPageTypes,
                            ).reduce<number>(
                                (total, isChecked) => (isChecked ? total + 1 : total),
                                0,
                            );

                            if (numSelectedPageTypes === pageTypes.length) {
                                return {}; //if everything selected, nothing is selected
                            }

                            return selectedPageTypes;
                        },
                    } as CheckboxesFilterConfig,
                ],
            },
            {
                sectionName: 'sentiment',
                filters: [
                    {
                        filterName: 'sentiment',
                        type: 'checkboxes',
                        options: SENTIMENT_CATEGORIES.map((sentiment) => ({
                            value: sentiment,
                            label: `sentiments.${sentiment}`,
                        })),
                        toAPI: checkboxesToApiFormat,
                        fromAPI: checkboxesFromApiFormat,
                    } as CheckboxesFilterConfig,
                ],
            },
            {
                sectionName: 'classifications',
                filters: [
                    {
                        filterName: 'classifications',
                        type: 'checkboxes',
                        options: EMOTIONS_CATEGORIES.map((emotion) => ({
                            value: emotion,
                            label: `emotions.${emotion}`,
                        })),
                        toAPI: getCheckboxesToApiFormat(classificationToAPI),
                        fromAPI: getCheckboxesFromApiFormat(classificationFromAPI),
                    } as CheckboxesFilterConfig,
                ],
            },
            {
                sectionName: 'gender',
                filters: [
                    {
                        filterName: 'gender',
                        type: 'checkboxes',
                        options: GENDERS.map((gender) => ({value: gender, label: gender})),
                        toAPI: checkboxesToApiFormat,
                        fromAPI: checkboxesFromApiFormat,
                    } as CheckboxesFilterConfig,
                ],
            },
            {
                sectionName: 'author',
                filters: [
                    {
                        filterName: 'author',
                        type: 'includeAndExclude',
                        fields: [
                            {
                                fieldName: 'author',
                                placeholder: 'filters.placeholders.search.author',
                                displayChipInsideField: true,
                            },
                        ],
                        toAPI: getIncludeAndExcludeToApiFormat([
                            {
                                field: 'author',
                                apiIncludeField: 'author',
                                apiExcludeField: 'xauthor',
                            },
                        ]),
                        fromAPI: getIncludeAndExcludeFromApiFormat<string, string>([
                            {
                                field: 'author',
                                apiIncludeField: 'author',
                                apiExcludeField: 'xauthor',
                                getById: (item: string): string => item,
                            },
                        ]),
                    } as IncludeAndExcludeFilterConfig<string, string>,
                ],
            },
            {
                sectionName: 'site',
                filters: [
                    {
                        filterName: 'site',
                        type: 'includeAndExclude',
                        fields: [
                            {
                                fieldName: 'domain',
                                placeholder: 'filters.placeholders.search.site',
                                displayChipInsideField: true,
                                validation: (value: string) => {
                                    value = value.replace('www.', '').replace(/http(s|):\/\//, '');

                                    return isFQDN(value)
                                        ? null
                                        : 'filters.validation.validateDomainMsg';
                                },
                            },
                        ],
                        toAPI: getIncludeAndExcludeToApiFormat([
                            {
                                field: 'domain',
                                apiIncludeField: 'domain',
                                apiExcludeField: 'xdomain',
                            },
                        ]),
                        fromAPI: getIncludeAndExcludeFromApiFormat<string, string>([
                            {
                                field: 'domain',
                                apiIncludeField: 'domain',
                                apiExcludeField: 'xdomain',
                                getById: (item: string): string => item,
                            },
                        ]),
                    } as IncludeAndExcludeFilterConfig<string, string>,
                ],
            },
            {
                sectionName: 'tag',
                filters: [
                    {
                        filterName: 'tag',
                        type: 'includeAndExclude',
                        fields: [
                            {
                                fieldName: 'tag',
                                placeholder: 'filters.placeholders.search.tag',
                                autosuggestType: 'tag',
                                displayChipInsideField: true,
                                comparisonFunc: compareById,
                                getLabel: ({name}: TagDefinition) => name,
                                getId,
                            },
                        ],
                        toAPI: getIncludeAndExcludeToApiFormat<TagDefinition, number>([
                            {
                                field: 'tag',
                                apiIncludeField: 'anyTag',
                                apiExcludeField: 'xtag',
                                valueToAPI: ({id}) => id,
                            },
                        ]),
                        fromAPI: getIncludeAndExcludeFromApiFormat<number, TagDefinition>([
                            {
                                field: 'tag',
                                apiIncludeField: 'anyTag',
                                apiExcludeField: 'xtag',
                                getById: getTagById,
                            },
                        ]),
                    } as IncludeAndExcludeFilterConfig<TagDefinition, number>,
                ],
            },
            {
                sectionName: 'language',
                filters: [
                    {
                        filterName: 'language',
                        type: 'includeAndExclude',
                        fields: [
                            {
                                fieldName: 'language',
                                placeholder: 'filters.placeholders.search.language',
                                autosuggestType: 'language',
                                displayChipInsideField: true,
                                comparisonFunc: compareById,
                                getLabel: getFullName,
                                getId,
                            },
                        ],
                        toAPI: getIncludeAndExcludeToApiFormat<LanguageDefinition, string>([
                            {
                                field: 'language',
                                apiIncludeField: 'language',
                                apiExcludeField: 'xlanguage',
                                valueToAPI: ({id}) => id,
                            },
                        ]),
                        fromAPI: getIncludeAndExcludeFromApiFormat<string, LanguageDefinition>([
                            {
                                field: 'language',
                                apiIncludeField: 'language',
                                apiExcludeField: 'xlanguage',
                                getById: getLanguageById,
                            },
                        ]),
                    } as IncludeAndExcludeFilterConfig<LanguageDefinition, string>,
                ],
            },
            {
                sectionName: 'location',
                filters: [
                    {
                        filterName: 'location',
                        type: 'includeAndExclude',
                        fields: [
                            {
                                fieldName: 'location',
                                autosuggestType: 'location',
                                placeholder: 'filters.placeholders.search.location',
                                comparisonFunc: compareById,
                                getLabel: getFullName,
                                getId,
                            },
                        ],
                        toAPI: getIncludeAndExcludeToApiFormat<LocationDefinition, string>([
                            {
                                field: 'location',
                                apiIncludeField: 'location',
                                apiExcludeField: 'xlocation',
                                valueToAPI: ({id}) => id,
                            },
                        ]),
                        fromAPI: getIncludeAndExcludeFromApiFormat<
                            string,
                            LocationDefinition | Promise<LocationDefinition | undefined>
                        >([
                            {
                                field: 'location',
                                apiIncludeField: 'location',
                                apiExcludeField: 'xlocation',
                                getById: getLocationById,
                            },
                        ]),
                    } as IncludeAndExcludeFilterConfig<LocationDefinition, string>,
                ],
            },
            {
                sectionName: 'twitterAuthor',
                filters: [
                    {
                        filterName: 'twitterVerified',
                        type: 'checkboxes',
                        label: 'filters.twitterAuthor.verification',
                        options: [
                            {label: 'filters.twitterAuthor.verified', value: true},
                            {label: 'filters.twitterAuthor.unverified', value: false},
                        ],
                        toAPI: (
                            filterState: FilterState,
                            output: APIFilterFormat,
                            prop: keyof APIFilterFormat,
                        ): void => {
                            const checkboxesFilterState: CheckboxesFilterState =
                                filterState as CheckboxesFilterState;

                            if (
                                checkboxesFilterState.true ||
                                (checkboxesFilterState.false &&
                                    !(checkboxesFilterState.true && checkboxesFilterState.false))
                            ) {
                                output[prop] = !!checkboxesFilterState.true;
                            }
                        },
                        fromAPI: (
                            data: APIFilterFormat,
                            prop: keyof APIFilterFormat,
                            definitions: FromApiFormatData,
                        ): CheckboxesFilterState => {
                            if (data[prop] === true) {
                                return {true: true, false: false};
                            } else if (data[prop] === false) {
                                return {true: false, false: true};
                            }

                            return {true: false, false: false};
                        },
                    } as CheckboxesFilterConfig,
                    {
                        filterName: 'accountType',
                        type: 'checkboxes',
                        label: 'filters.twitterAuthor.accountType',
                        options: [
                            {label: 'filters.twitterAuthor.individual', value: 'individual'},
                            {
                                label: 'filters.twitterAuthor.organizational',
                                value: 'organisational',
                            },
                        ],
                        toAPI: checkboxesToApiFormat,
                        fromAPI: checkboxesFromApiFormat,
                    } as CheckboxesFilterConfig,
                    {
                        filterName: 'followersRange',
                        type: 'range',
                        label: 'filters.twitterAuthor.followersLabel',
                        minValue: 0,
                        maxValue: MAX_INT,
                        step: 1,
                        toAPI: getRangeToApiFormat('twitterFollowersMin', 'twitterFollowersMax'),
                        fromAPI: getRangeFromApiFormat(
                            'twitterFollowersMin',
                            'twitterFollowersMax',
                        ),
                    } as RangeFilterConfig,
                ],
            },
            {
                sectionName: 'threadEntryType',
                filters: [
                    {
                        filterName: 'threadEntryType',
                        type: 'checkboxes',
                        options: [
                            {value: 'post', label: 'filters.threadEntryType.post'},
                            {value: 'reply', label: 'filters.threadEntryType.reply'},
                            {value: 'share', label: 'filters.threadEntryType.share'},
                        ],
                        toAPI: checkboxesToApiFormat,
                        fromAPI: checkboxesFromApiFormat,
                    } as CheckboxesFilterConfig,
                ],
            },
            {
                sectionName: 'socialPanels',
                filters: [
                    {
                        filterName: 'socialPanels',
                        type: 'includeAndExclude',
                        fields: [
                            {
                                fieldName: 'socialPanels',
                                autosuggestType: 'socialPanel',
                                placeholder: 'filters.placeholders.search.socialPanels',
                                comparisonFunc: compareById,
                                getLabel: getSocialPanelLabel,
                            },
                        ],
                        toAPI: getIncludeAndExcludeToApiFormat<ISocialPanel, string>([
                            {
                                field: 'socialPanels',
                                apiIncludeField: 'audienceList',
                                apiExcludeField: 'xaudienceList',
                                valueToAPI: (panel: ISocialPanel) =>
                                    panel.crimsonAudienceId?.toString(),
                            },
                        ]),
                        fromAPI: getIncludeAndExcludeFromApiFormat<
                            string,
                            ISocialPanel | Promise<ISocialPanel | undefined>
                        >([
                            {
                                field: 'socialPanels',
                                apiIncludeField: 'audienceList',
                                apiExcludeField: 'xaudienceList',
                                getById: getSocialPanelById,
                            },
                        ]),
                    } as IncludeAndExcludeFilterConfig<ISocialPanel, string>,
                ],
                hidden: !hasSocialPanelsEnabled,
            },
            //This is a section for filters that do not have direct controls
            //They are wither used for drill-ins, or as part of a cards functionality
            {
                sectionName: 'nonPersisted',
                hidden: true,
                filters: [
                    //topicWheel
                    {
                        filterName: 'topicWheel-name',
                        type: 'textfield',
                        toAPI: getTextfieldToApiFormat('topicLabel'),
                        fromAPI: null,
                    },
                    {
                        filterName: 'topicWheel-mentions',
                        type: 'multiValueTextfield',
                        toAPI: (
                            filterState: FilterState,
                            output: APIFilterFormat,
                            prop: string,
                        ): void => {
                            const value = filterState as string[];

                            if (isValidValue(value)) {
                                output.mentionId = value.join(',');
                            }
                        },
                        fromAPI: null,
                    },

                    //Drill ins
                    {
                        filterName: 'insightsUrl',
                        type: 'textfield',
                        toAPI: textfieldFilterToAPIFormat,
                        fromAPI: null,
                    },
                    {
                        filterName: 'insightsHashtag',
                        type: 'textfield',
                        toAPI: textfieldFilterToAPIFormat,
                        fromAPI: null,
                    },
                    {
                        filterName: 'dayOfWeek',
                        type: 'textfield',
                        toAPI: textfieldFilterToAPIFormat,
                        fromAPI: null,
                    },
                    {
                        filterName: 'hourOfDay',
                        type: 'textfield',
                        toAPI: textfieldFilterToAPIFormat,
                        fromAPI: null,
                    },
                    {
                        filterName: 'queryId',
                        type: 'textfield',
                        toAPI: textfieldFilterToAPIFormat,
                        fromAPI: null,
                    },
                    {
                        filterName: 'interest',
                        type: 'textfield',
                        toAPI: textfieldFilterToAPIFormat,
                        fromAPI: null,
                    },

                    //workfow
                    {
                        filterName: 'assigned',
                        type: 'textfield',
                        toAPI: textfieldFilterToAPIFormat,
                        fromAPI: textfieldFilterFromAPIFormat,
                    },
                    {
                        filterName: 'priority',
                        type: 'textfield',
                        toAPI: textfieldFilterToAPIFormat,
                        fromAPI: textfieldFilterFromAPIFormat,
                    },
                    {
                        filterName: 'checked',
                        type: 'textfield',
                        toAPI: textfieldFilterToAPIFormat,
                        fromAPI: textfieldFilterFromAPIFormat,
                    },
                    {
                        filterName: 'status',
                        type: 'textfield',
                        toAPI: textfieldFilterToAPIFormat,
                        fromAPI: textfieldFilterFromAPIFormat,
                    },

                    //category - this is in the streams app, need to fix it there
                ],
            },
        ];
    },
    quickSearchFilters: ({
        pageTypes,
        projectId,
    }: {
        pageTypes: PageType[];
        projectId: number;
    }): FilterSectionConfig[] => {
        return [
            {
                sectionName: 'keyword',
                filters: [
                    {
                        filterName: 'keyword',
                        type: 'textfield',
                        immediate: false,
                        //displayChipInsideField: true,
                        placeholder: 'filters.placeholders.search.keyword',
                        helperText: 'filters.helperText.keyword',
                        asyncValidation: async (value: string) => {
                            const result = await validateFilterQuery({
                                query: value,
                                projectId,
                            });

                            if (!result.errors || result.errors.length === 0) {
                                return true;
                            } else {
                                return result.errors.map((error: any) => ({
                                    key: 'raw',
                                    props: {value: error},
                                }));
                            }
                        },
                        toAPI: handleQueryAppend,
                        fromAPI: (
                            data: APIFilterFormat,
                            prop: string,
                            definitions: FromApiFormatData,
                        ): FilterState => {
                            const value: string = (data.queryAppend as string)?.slice(5) || ''; //remove the ' AND ' part.;
                            //Handle the fact the this may or may not be wrapped in backets, check if so and if it's safe to remove
                            if (value.charAt(0) === '(' && value.charAt(value.length - 1) === ')') {
                                const removedBrackets = value.slice(1, -1);
                                const openIndex = removedBrackets.indexOf('(');
                                const closeIndex = removedBrackets.indexOf(')');

                                if (
                                    (openIndex === -1 && closeIndex === -1) ||
                                    openIndex < closeIndex
                                ) {
                                    return removedBrackets;
                                }
                            }

                            return value;
                        },
                    } as TextfieldFilterConfig,
                ],
            },
            {
                // This is overridden with this hook: useTempUpdateFilterOptions
                sectionName: 'pageType',
                filters: [
                    {
                        filterName: 'pageType',
                        type: 'checkboxes',
                        options: mapPageTypesToOptions(pageTypes),
                        ignoreIfAllSelected: true,
                        toAPI: getPageTypesToAPI(pageTypes, 'contentSource'),
                        fromAPI: getCheckboxesFromApiFormat(undefined, 'contentSource'),
                    } as CheckboxesFilterConfig,
                ],
            },
            {
                sectionName: 'classifications',
                filters: [
                    {
                        filterName: 'classifications',
                        type: 'checkboxes',
                        options: EMOTIONS_CATEGORIES.map((emotion) => ({
                            value: emotion,
                            label: `emotions.${emotion}`,
                        })),
                        toAPI: getCheckboxesToApiFormat(capitalize, 'emotion'),
                        fromAPI: getCheckboxesFromApiFormat(
                            (value) => value.toString().toLowerCase(),
                            'emotion',
                        ),
                    } as CheckboxesFilterConfig,
                ],
            },
            {
                sectionName: 'sentiment',
                filters: [
                    {
                        filterName: 'sentiment',
                        type: 'checkboxes',
                        options: SENTIMENT_CATEGORIES.map((sentiment) => ({
                            value: sentiment,
                            label: `sentiments.${sentiment}`,
                        })),
                        toAPI: checkboxesToApiFormat,
                        fromAPI: checkboxesFromApiFormat,
                    } as CheckboxesFilterConfig,
                ],
            },
            {
                sectionName: 'gender',
                filters: [
                    {
                        filterName: 'gender',
                        type: 'checkboxes',
                        options: GENDERS.map((gender) => ({value: gender, label: gender})),
                        toAPI: getCheckboxesToApiFormat(genderToQSAPI),
                        fromAPI: getCheckboxesFromApiFormat(genderFromQSAPI),
                    } as CheckboxesFilterConfig,
                ],
            },
            {
                sectionName: 'author',
                filters: [
                    {
                        filterName: 'ownedContent',
                        type: 'includeAndExclude',
                        noExclusion: true,
                        fields: [
                            {
                                fieldName: 'author',
                                placeholder: 'filters.placeholders.search.author',
                                displayChipInsideField: true,
                            },
                            {
                                fieldName: 'domain',
                                hidden: true,
                            },
                        ],
                        toAPI: getIncludeAndExcludeToApiFormat([
                            {
                                field: 'author',
                                apiIncludeField: 'author',
                                apiExcludeField: 'xauthor',
                            },
                            {
                                field: 'domain',
                                apiIncludeField: 'site',
                                apiExcludeField: 'xsite',
                            },
                        ]),
                        fromAPI: getIncludeAndExcludeFromApiFormat<string, string>([
                            {
                                field: 'author',
                                apiIncludeField: 'author',
                                apiExcludeField: 'xauthor',
                                getById: (item: string): string => item,
                            },
                            {
                                field: 'domain',
                                apiIncludeField: 'domain',
                                apiExcludeField: 'xdomain',
                                getById: (item: string): string => item,
                            },
                        ]),
                    } as IncludeAndExcludeFilterConfig<string, string>,
                ],
            },
            {
                sectionName: 'language',
                filters: [
                    {
                        filterName: 'language',
                        type: 'includeAndExclude',
                        fields: [
                            {
                                fieldName: 'language',
                                placeholder: 'filters.placeholders.search.language',
                                autosuggestType: 'language',
                                displayChipInsideField: true,
                                comparisonFunc: compareById,
                                getLabel: getFullName,
                                getId,
                            },
                        ],
                        toAPI: getIncludeAndExcludeToApiFormat<LanguageDefinition, string>([
                            {
                                field: 'language',
                                apiIncludeField: 'language',
                                apiExcludeField: 'xlanguage',
                                valueToAPI: ({id}) => id,
                            },
                        ]),
                        fromAPI: getIncludeAndExcludeFromApiFormat<string, LanguageDefinition>([
                            {
                                field: 'language',
                                apiIncludeField: 'language',
                                apiExcludeField: 'xlanguage',
                                getById: getLanguageById,
                            },
                        ]),
                    } as IncludeAndExcludeFilterConfig<LanguageDefinition, string>,
                ],
            },
            {
                sectionName: 'location',
                filters: [
                    {
                        filterName: 'location',
                        type: 'includeAndExclude',
                        fields: [
                            {
                                fieldName: 'location',
                                autosuggestType: 'location',
                                placeholder: 'filters.placeholders.search.location',
                                comparisonFunc: compareById,
                                getLabel: getFullName,
                                getId,
                            },
                        ],
                        toAPI: getIncludeAndExcludeToApiFormat<LocationDefinition, string>([
                            {
                                field: 'location',
                                apiIncludeField: 'location',
                                apiExcludeField: 'xlocation',
                                valueToAPI: ({id}) => id,
                            },
                        ]),
                        fromAPI: getIncludeAndExcludeFromApiFormat<
                            string,
                            LocationDefinition | Promise<LocationDefinition | undefined>
                        >([
                            {
                                field: 'location',
                                apiIncludeField: 'location',
                                apiExcludeField: 'xlocation',
                                getById: getLocationById,
                            },
                        ]),
                    } as IncludeAndExcludeFilterConfig<LocationDefinition, string>,
                ],
            },
            {
                sectionName: 'hidden',
                hidden: true,
                filters: [
                    {
                        filterName: 'insightsUrl',
                        type: 'textfield',
                        toAPI: (
                            filterState: FilterState,
                            output: APIFilterFormat,
                            prop: string,
                        ): void => {
                            const value: string = filterState as string;

                            if (value) {
                                output.links = [value];
                            }
                        },
                    } as TextfieldFilterConfig,
                    {
                        filterName: 'insightsHashtag',
                        type: 'textfield',
                        toAPI: handleQueryAppend,
                    } as TextfieldFilterConfig,
                    {
                        filterName: 'hourOfDay',
                        type: 'textfield',
                        toAPI: textfieldFilterToAPIFormat,
                    } as TextfieldFilterConfig,
                ],
            },
        ];
    },
};

export default filtersConfig;
