import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const {colors, font} = theme;
    const v2 = isV2(theme);

    return {
        badge: {
            backgroundColor: v2 ? colors.accentDefault : colors.primaryBlue100,
            color: 'white',
            width: 16,
            height: 16,
            fontWeight: font.bold,
            borderRadius: 8,
            fontSize: 12,
            lineHeight: '16px',
            textAlign: 'center',
            position: 'absolute',
            bottom: '2px',
            right: '5px',
            transform: 'translate(50%, 50%)',
        },
        button: {
            fontFamily: 'inherit',
            width: 28,
            height: 28,
            padding: v2 ? 3 : 8,
        },
    };
});
