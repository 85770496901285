import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({colors, v2}: any) => {
    const errorCol = v2 ? colors.error : colors.primaryRed100;
    const errorBgCol = colors.errorBg;

    return {
        chip: {
            maxWidth: '100%',
        },
        tooltip: {
            maxWidth: 190,
        },
        chipError: {
            backgroundColor: errorBgCol,
            color: errorCol,
            border: `1px solid ${errorCol}`,
            '& svg': {
                fill: `${errorCol} !important`,
            },
        },
        chipLabel: {
            padding: '0 8px',
            display: 'inline-flex',
            flexFlow: 'row nowrap',
            gap: '4px',
            alignItems: 'center',
        },

        chipLabelInner: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            lineHeight: 'inherit',
        },
        warningIcon: {
            padding: 0,
            flex: 'auto 0 0',
            '& svg': {
                heigth: '0.6em',
                width: '0.6em',
            },
        },
        deleteIcon: {
            padding: 0,
            '& svg': {
                heigth: '0.6em',
                width: '0.6em',
            },

            '&:hover': {
                background: 'transparent',
            },
        },
    };
});
