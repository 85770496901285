import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles(({colors, font, mixin, fonts, v2}: Theme) => ({
    container: {
        overflowX: 'unset',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        //height: 450,
        width: '100%',
    },
    filter: {
        margin: '20px 0px',
        width: 257,
    },
    table: {},
    header: {
        '& .MuiTableCell-stickyHeader': {
            background: v2 ? colors.white : colors.lightGrey60,
        },
    },
    headerCell: {
        '&.MuiTableCell-root': {
            verticalAlign: v2 ? 'bottom' : 'inherit',
        },
    },
    headerCellContainer: {
        alignItems: 'center',
        display: 'flex',
        padding: v2 ? '11px 24px 0 24px' : '14px 16px',
    },
    headerCellTooltip: {
        marginLeft: 4,
        marginBottom: v2 ? 8 : 1,
    },
    headerSortButton: {
        ...mixin.blankButton,

        marginBottom: v2 ? 3 : 'initial',
        paddingBottom: v2 ? 4 : 'initial',
    },
    headerSortButtonActive: {boxShadow: v2 ? `0 3px ${colors.accentDefault}` : 'none'},
    headerSortIconWrapper: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 10px',
    },
    headerSortIcon: {
        margin: -8,
        ...(v2 ? mixin.icon.normalSize : mixin.icon.bigSize),
    },
    headerSortIconActive: {
        fill: colors.darkGrey100,
    },
    headerSortIconInactive: {
        fill: colors.darkGrey40,
        visibility: v2 ? 'hidden' : 'visible',
    },
    headerNoSort: {
        paddingBottom: v2 ? 8 : 1,
    },
    headerCellText: {
        ...mixin.truncate,
        ...(v2
            ? {
                  color: colors.carbon,
                  ...fonts.bodyStrong,
              }
            : {
                  color: colors.textDefault,
                  fontSize: 16,
                  fontWeight: font.bold,
                  textTransform: 'uppercase',
              }),
        maxWidth: 180,
    },
    headerCellTextActive: {},
    body: {
        background: colors.white,
        '& .MuiTableCell-root': {
            padding: v2 ? '0px 24px' : '14px 16px',
        },
    },
    row: {
        height: 47,
        '&.MuiTableRow-hover:hover': {
            background: v2 ? colors.bgHover : colors.primaryYellow10,
        },
        '&.MuiTableRow-hover:hover>.MuiTableCell-root:last-child': {
            '& .actions': {
                visibility: 'visible !important',
            },
        },
    },
    rowCell: {
        ...mixin.truncate,
        ...(v2 ? fonts.bodyNormal : {fontSize: 16, fontWeight: font.regular}),
    },
    cell: {
        ...mixin.truncate,
        maxWidth: 180,
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        visibility: 'hidden',
    },
    actionIcon: {
        ...mixin.icon.normalSize,
    },
    notFound: {
        height: 350,
    },
    notFoundIcon: {
        fill: v2 ? colors.carbon : colors.darkGrey60,
        ...mixin.icon.massiveSize,
    },
    notFoundTitle: {
        textAlign: 'center',
        margin: 12,
        ...(v2
            ? fonts.display1Strong
            : {
                  fontSize: 20,
                  lineHeight: '25px',
                  fontWeight: font.bold,
              }),
    },
    notFoundDesc: {
        textAlign: 'center',
        margin: 12,
        ...(v2
            ? fonts.headtitleNormal
            : {
                  fontSize: 16,
                  lineHeight: '20px',
                  fontWeight: font.regular,
              }),
    },
    notFoundButton: {
        margin: 12,
    },
    loading: {
        position: 'absolute',
        background: v2 ? colors.bgOverlayLight : colors.lightGrey20,
        opacity: 0.8,
        width: '97.5%',
    },
    loadingMore: {
        height: 70,
        padding: 16,
    },
    notLoading: {
        display: 'none',
    },
}));
