import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles((theme: Theme) => ({
    container: {
        position: 'sticky',
        zIndex: 1299,
        top: 0,
        width: '100%',
    },
    searchBarContainer: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.v2 ? theme.colors.uiGrayDefault : '#FFF',
        height: theme.elements.searchBarContainerHeight,
        padding: 0,
        boxShadow: theme.v2
            ? `0px 1px 0px ${theme.colors.uiGrayDarker}`
            : `0px 2px 0px ${theme.colors.lightGrey100}`,
        marginBottom: theme.v2 ? 0 : 2,
    },
    backBtn: {
        margin: theme.v2 ? '0 0 0 6px' : '0 0 0 20px',
    },
    backBtnIcon: {
        width: theme.v2 ? 24 : 20,
        height: theme.v2 ? 24 : 20,
        flex: `0 0 20px`,
        alignSelf: 'center',
        margin: theme.v2 ? -4 : -2,
    },
    quickContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: theme.v2 ? '1 1 auto' : '1 0 auto',
        padding: theme.v2 ? '0 20px 0 0' : '0 20px',
    },
    quickContainerRight: {
        flex: '0 1 auto',
        display: 'flex',
    },
    searchBoxContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'auto',
        flex: '1 1 auto',
    },
    searchBox: {
        minHeight: theme.v2 ? 40 : 44,
        width: 'auto',
        margin: '0 10px',
        flex: '1 1 auto',
        position: 'relative',
    },
    searchBoxInner: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    title: {
        ...(theme.v2
            ? theme.fonts.headtitleStrong
            : {fontSize: '20px', fontWeight: theme.font.semiBold}),
        lineHeight: theme.v2 ? '24px' : '25px',
        color: theme.v2 ? theme.colors.textDefault : theme.colors.darkGrey100,
        whiteSpace: 'nowrap',
        marginRight: '10px',
    },
    lastNdays: {
        width: '30%',
        fontWeight: theme.font.semiBold,
        marginLeft: 6,
    },
    savedSearchTitle: {
        maxWidth: '30ch',
        fontWeight: theme.font.semiBold,
        fontSize: '14px',
        color: '#2f3638',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    savedSearchBtnMsg: {
        textAlign: 'left',
        padding: 16,
    },
    save: {
        display: 'inline',
        marginLeft: '10px',
    },
}));
