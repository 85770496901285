import {useCallback, useEffect, useMemo, useState} from 'react';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import usePrevious from 'hsi/hooks/usePrevious';
import {queryLocations} from './service';

const mergeLocations = (filters, location) =>
    isEmpty(location) ? filters : {...filters, location};

const parseLocationsRes = (filters, res) => mergeLocations(filters, flatten(res));

const useLocationsFilterParser = ({filters, filterOptions}) => {
    const [error, setError] = useState();
    const [parsedFilters, setParsedFilters] = useState();
    const [loadingLocationsQuery, setLoadingLocationsQuery] = useState(false);

    const prevFilters = usePrevious(filters);

    const fetch = useCallback(async () => {
        setLoadingLocationsQuery(true);

        try {
            const res = await queryLocations({filters, filterOptions});
            const newParsedFilters = parseLocationsRes(filters, res);
            setParsedFilters(newParsedFilters);
        } catch (newError) {
            setError(newError);
            setParsedFilters(undefined);
        } finally {
            setLoadingLocationsQuery(false);
        }
    }, [filterOptions, filters]);

    const locationsChanged = useMemo(
        () => !isEqual(prevFilters?.location, filters?.location),
        [filters, prevFilters],
    );

    const filtersChanged = useMemo(() => !isEqual(prevFilters, filters), [filters, prevFilters]);

    const hasLocationsFilter = useMemo(() => !isEmpty(filters?.location), [filters]);

    useEffect(() => {
        if (!hasLocationsFilter) return;
        if (filtersChanged) fetch();
    }, [fetch, filters, filtersChanged, hasLocationsFilter]);

    const loading = useMemo(
        () => loadingLocationsQuery || (hasLocationsFilter && filtersChanged),
        [filtersChanged, hasLocationsFilter, loadingLocationsQuery],
    );
    const outFilters = useMemo(
        () =>
            !hasLocationsFilter || (!locationsChanged && filtersChanged) ? filters : parsedFilters,
        [filters, filtersChanged, hasLocationsFilter, locationsChanged, parsedFilters],
    );

    return {
        error,
        filters: outFilters,
        loading,
    };
};

export default useLocationsFilterParser;
