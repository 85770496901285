import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

import bg from 'hsi/themes/hsi/img/hsi/homepage.svg';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);
    const maxWidth = v2 ? '722px' : '670px';
    const mainBackground = v2 
        ? {backgroundColor: theme.colors.uiGrayDefault} 
        : {
            background:
                `url(${bg}) ${theme.colors.primaryBlue20} no-repeat bottom center !important`,
        };

    return {
        root: {
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'flex-start',
            ...mainBackground,
            overflow: 'hidden',
            flex: '1 1 auto',
        },
        bgNoSearch: {
            minHeight: `calc(100vh - ${theme.elements.navBarContainerHeight}))`,
            '@media (max-width: 1239px)': mainBackground,

            '@media (min-width: 1240px)': mainBackground,
        },
        '@media (min-width: 1240px)': {
            bgWithSearches: mainBackground,
        },
        '@media (max-width: 1239px)': {
            bgWithSearches: mainBackground,
        },
        loading: {
            position: 'relative',
            top: '100px',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        helpOpen: {
            '@media (max-width: 1500px)': {
                paddingRight: '360px',
            },
        },
        container: {
            height: v2 
                ? 'auto'
                : `calc(40vh - ${theme.elements.navBarContainerHeight})`,
            minHeight: v2 ? '220px' : '400px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            padding: v2 ? undefined : '0 5vw',        
        },
        titleContainer: {
            margin: v2 ? '43px 0 0 0' : '13px 0 0 0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        title: {
            ...(v2 ? theme.fonts.display2Strong : {fontSize: '44px', fontWeight: 900}),
            textAlign: 'center',
        },
        searchButton: {
            margin: '24px 0 8px 0',
        },

        queryBuilder: {
            width: maxWidth,
            margin: '16px 0 8px 0',
        },
        recentlyViewed: {
            backgroundColor: v2 ? 'transparent' : theme.colors.recentSearchesBg,
            '---quicksearch-recentlyViewed-width': v2 ? '700px' : '672px',
            padding: v2 
                ? '20px calc(50% - var(---quicksearch-recentlyViewed-width) / 2) 0' 
                : '80px calc(50% - var(---quicksearch-recentlyViewed-width) / 2) 0',
            width: '100%',
            flex: '1 1 auto',
            alignItems: 'center',

            
        },
        recentlyViewedHeader: {
            justifyContent: 'space-between',
            height: '54px',
            alignItems: 'center',
            display: 'flex',
        },
        recentlyViewedViewAll: {
            ...theme.mixin.linkColor,
            ...(v2 ? theme.fonts.bodyLargeNormal : {fontSize: '16px', fontWeight: 'bold'}),
            flex: '0 0 auto',
            textDecoration: 'none',
            lineHeight: '30px',
        },
        recentlyViewedTitle: {
            color: v2 
                ? theme.colors.textDefault 
                : theme.colors.darkGrey100,
            ...(v2 ? theme.fonts.headtitleStrong : {fontSize: '20px', fontWeight: theme.font.bold}),
            padding: '0',
            margin: '0',
            flex: '0 0 auto',
            lineHeight: '30px',
        },
        recentSearches: {
            justifyContent: 'space-between',
            listStyleType: 'none',
            flexFlow: 'row wrap',
            width: '100%',
            display: 'flex',
            margin: 0,
            padding: 0,
            paddingTop: v2 ? '16px' : '20px',
            '&:after': {
                content: '""',
                flex: v2 ? '0 0 225px' : '0 0 212px',
            },
        },
        recentSearchItem: {
            flex: v2 ? '0 0 225px' : '0 0 212px',
            overflow: 'hidden',
            margin: '0 0 16px 0',
        },
        recentSearchLink: {
            '&&': {
                color: v2 ? theme.colors.textDefault : theme.colors.primaryBlue100,
            },
            backgroundColor: v2 ? theme.colors.bgActive : theme.colors.primaryBlue20,
            ...(v2 ? theme.fonts.bodyNormal : {fontSize: '16px', fontWeight: 700,}),
            margin: 0,
            textDecoration: 'none',
            padding: v2 ? '0 24px 0px 24px' : '8px 24px 9px 24px',
            cursor: 'pointer',
            borderRadius: v2 ? '50px' : '100px',
            textAlign: 'center',
            display: 'block',
            justifyContent: 'center',
            alignItems: 'center',
            height: v2 ? '40px' : undefined,
            lineHeight: v2 ? '40px' : undefined,
            ...theme.mixin.truncate,

            '&:hover': {
                backgroundColor: v2 
                    ? theme.colors.bgSubtle 
                    : theme.colors.primaryBlue40,
            }
        },
    };
});
