//TODO ideally would remove the need for this

import {AppConfigType, ConfigType} from 'hsi/types/config';

let config: ConfigType | undefined = undefined;
let _resolve: ((config: ConfigType) => void) | undefined = undefined;

export function set(value: ConfigType) {
    if (_resolve) {
        config = value;
        _resolve(value);
        _resolve = undefined;
    } else {
        if (process.env.STORYBOOK) {
            config = value;
        } else {
            //for some reason jest makes repeat calls to set, for now I have just caught and ignored the errors
            throw new Error('Repeat calls to setConfig');
        }
    }
}

export default function getConfig<TConfigType extends ConfigType = AppConfigType>() {
    if (config === undefined) {
        throw new Error(
            'Unable to access config - "set" must be called before config can be accessed',
        );
    }

    return config as TConfigType;
}

//allow async access to config once it has loaded
export const getConfigOnSet = new Promise<ConfigType>((resolve) => {
    _resolve = resolve;
});
