import {useMemo} from 'react';
import {useAppSelector} from './useRedux';
import {parseError} from 'hsi/utils/api/errorParsing';

/**
 * Parses search errors into standard format, and filters out errors that are not relevent to us
 *
 * @returns An array of ApiErrorType objects or null, will never return an empty array
 */
export default function useSearchApiError() {
    const {error: rawSearchError} = useAppSelector((state) => state.search);

    return useMemo(
        () => parseError((rawSearchError as any)?.message),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [(rawSearchError as any)?.message],
    );
}
