/////////////////////
// Legacy to State //
/////////////////////

//We need to keep this to ensure that persisted filters using the original format can be converted
//to the new format. Should never need to change - any changes should a made in the config file.

import {
    LegacyFiltersState,
    FiltersState,
    APICategory,
    LocationDefinition,
    LanguageDefinition,
    TagDefinition,
    FromApiFormatData,
    FilterConfigByName,
} from 'hsi/types/filters';
import {IdObject, SearchType} from 'hsi/types/shared';
import isObject from 'lodash/isObject';

import apiFormatToFilterState from './apiFormatToFilterState';
import legacyFilterStateToAPIFormat from './legacyToAPI';

export default function legacyFormatToFiltersState(
    filters: LegacyFiltersState,
    searchType: SearchType = 'saved',
    allFiltersConfig: FilterConfigByName,
    categories: APICategory[] = [],
): FiltersState {
    const definitions: FromApiFormatData = {
        locations: extractValuesFrom<LocationDefinition>(filters, 'location', 'xlocation'),
        languages: extractValuesFrom<LanguageDefinition>(filters, 'language', 'xlanguage'),
        tags: extractValuesFrom<TagDefinition>(filters, 'tag', 'xtag'),
        categories,
    };

    const apiFormat = legacyFilterStateToAPIFormat(filters, searchType);

    //if we can extract object values from the legacy data and provide to apiFormatToFilterState, then this is a non issue
    return apiFormatToFilterState(apiFormat, allFiltersConfig, definitions);
}

function extractValuesFrom<T extends IdObject<number | string>>(
    filters: LegacyFiltersState,
    ...keys: string[]
): T[] {
    const values: {[key: string]: T} = {};

    keys.forEach((key) => {
        // no point in return value for a foreach - technically we should not cause
        // side effects, but for a simple method like this, I think it's fine
        // eslint-disable-next-line no-unused-expressions
        filters?.[key]?.forEach((val: T) => {
            if (isObject(val) && val.id) {
                values[val.id] = val;
            }
        });
    });

    return Object.values(values);
}
