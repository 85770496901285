import {useMemo} from 'react';

import {T} from 'hsi/i18n';
import { ColumnDefinition, FormatColumnDefinition, SortColumnDefinition } from 'hsi/components/table/ManagedTable/types';
import { TopAuthorsCardData } from 'hsi/types/cards';
import { formatPercent } from 'hsi/utils/formatNumber';
import CompactNumber from 'hsi/components/format/CompactNumber';
import AuthorCell from './AuthorCell';

export type TopAuthorsTableRow = TopAuthorsCardData[number] & {position: number};
type Columns = keyof TopAuthorsTableRow;
type TableColumnDefinition = ColumnDefinition<Columns> & SortColumnDefinition<TopAuthorsTableRow, Columns> & FormatColumnDefinition<TopAuthorsTableRow, Columns>;


export default function useGetTableColumns(isSavedSearch: boolean, classes: Record<string, string>) {
    return useMemo<TableColumnDefinition[]>(() => {
        const columns = [
            {
                name: 'position',
                label: <span className="offscreen">{T('cards.topauthors.tableFields.position')}</span>,
                horizontalAlign: 'start',
                width: 'auto',
                format: (row) => <span className={classes.subtle}>{row.position}</span>
            } as TableColumnDefinition,
            {
                name: 'name',
                label: T('cards.topauthors.tableFields.name'),
                width: '2fr',

                horizontalAlign: 'start',

                sortable: isSavedSearch,

                format: (row) => <AuthorCell displayName={row.displayName} domain={row.domain} countryName={row.countryName} avatarUrl={row.twitterAvatar} />,
            } as TableColumnDefinition,
            {
                name: 'value',
                label: T('cards.topauthors.tableFields.mentions'),
                width: 'minmax(75px, 1fr)',
                sortable: isSavedSearch,
                defaultSortDir: 'desc',
                truncateHeaderLabelText: true,
                format: ({value, percentageOfTotal}) => <><CompactNumber tooltip value={value} />&nbsp;<span className={classes.subtle}>({formatPercent(percentageOfTotal)})</span></>
            } as TableColumnDefinition,
        ]
                
        if(isSavedSearch) {
            columns.push({
                name: 'twitterFollowers',
                label: T('cards.topauthors.tableFields.followers'),
                width: 'minmax(75px, 1fr)',
                sortable: true,
                defaultSortDir: 'desc',
                truncateHeaderLabelText: true,
                format: (row) => <CompactNumber value={row.twitterFollowers} tooltip />,
            } as TableColumnDefinition,
            {
                name: 'reachEstimate',
                label: T('cards.topauthors.tableFields.reach'),
                width: 'minmax(75px, 1fr)',
                sortable: true,
                defaultSortDir: 'desc',
                truncateHeaderLabelText: true,
                format: (row) => <CompactNumber value={row.reachEstimate} tooltip />,
            } as TableColumnDefinition);
        }

        return columns;
    }, [classes.subtle, isSavedSearch])
}
