import qs from 'qs';

export default function isBCR() {
    return (
        typeof document.location !== 'undefined' &&
        (['quick-search.brandwatch.com', 'quick-search.stage.brandwatch.com'].includes(
            document.location.hostname,
        ) ||
            qs.parse(document.location.search, {ignoreQueryPrefix: true}).theme === 'bcr')
    );
}
