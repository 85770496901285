import React, {useEffect} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {useDispatch} from 'react-redux';

//Components
import DataGathering from './DataGathering';
import InsightsContainer from 'hsi/containers/InsightsContainer';
import Intro, {
    dataManagementIntro,
    filtersIntro,
    linkedinSearchIntro,
    multipleSearchIntro,
    savedSearchAlertsIntro,
    savedSearchReportsIntro,
    timezoneIntro,
    bulkActionsIntro,
} from 'hsi/containers/Intro';
import SavedSearchBar from 'hsi/components/SavedSearchBar';
import SearchResults from 'hsi/containers/SearchResults';
import {LoadErrorMsg} from 'hsi/components/ErrorMessage';
import {ExportChartPortal} from 'hsi/utils/export';
import FullscreenLoading from '../../components/FullscreenLoading';
import {HeadingContents} from 'hsi/components/aria/Heading';
import withLoadQueryState from 'hsi/hocs/withLoadQueryState';

//Actions
import {setMentionsOrder} from 'hsi/slices/mentions';

// Hooks
import useEventTrack from 'hsi/hooks/useEventTrack';
import useQueryContext from 'hsi/hooks/useQueryContext';
import {useAppSelector} from 'hsi/hooks/useRedux';

//Utils
import {scrollTop} from 'hsi/utils/scroll';
import {isLinkedinSearch} from 'hsi/utils/dataManagement/linkedin';
import {T} from 'hsi/i18n';

//Constants
const useStyles = makeStyles({
    '@global': {
        body: {
            overflowY: 'hidden',
        },
    },
});

//The component
const SavedSearchResults = () => {
    const dispatch = useDispatch();
    const {trackWithSearchData} = useEventTrack();
    useStyles();

    //redux
    const isSearchesLoaded = useAppSelector(
        (state) => (state.search as any).searchesEverLoaded as boolean,
    );
    const {savedSearch} = useQueryContext();

    //Side effects
    useEffect(() => {
        trackWithSearchData('viewed', {});
        scrollTop();

        //reset sort by to prevent invalid sort order when switching between quick and saved search
        dispatch(setMentionsOrder('date', false));

        // Check if deps can be added without issue
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isSearchesLoaded) {
        return <FullscreenLoading message={T('loading')} />;
    }

    return savedSearch ? (
        <>
            <Intro
                sequence={[
                    linkedinSearchIntro,
                    multipleSearchIntro,
                    savedSearchAlertsIntro,
                    savedSearchReportsIntro,
                    timezoneIntro,
                    dataManagementIntro,
                    filtersIntro,
                    bulkActionsIntro,
                ]}
            />
            <SavedSearchBar savedSearch={savedSearch} />
            <HeadingContents>
                {!isLinkedinSearch(savedSearch) && <DataGathering />}
                <InsightsContainer isSavedSearch={true}>
                    {savedSearch && <SearchResults />}
                </InsightsContainer>
                <ExportChartPortal />
            </HeadingContents>
        </>
    ) : (
        <LoadErrorMsg line1={T('savedSearchNotFound')} />
    );
};

export default withLoadQueryState(SavedSearchResults);
