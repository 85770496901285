import {useCallback, useMemo, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Button from 'hsi/components/Button';
import AutocompleteMultiple from 'hsi/components/AutocompleteMultiple';
import {MenuHeader, MenuFooter} from 'hsi/components/Menu';

import useUniqueId from 'hsi/hooks/useUniqueId';

import {
    isGuidedSearchTwitterAccount,
    inputAccountValueParser as parser,
} from 'hsi/containers/QueryBuilder/GuidedQueryForm/utils';
import {T} from 'hsi/i18n';

import {SelectAccountsProps} from '.';

const useStyles = makeStyles(() => ({
    footerBtn: {
        marginLeft: 8,
    },
    menuContent: {
        padding: 16,
    },
}));

const twitterHandleFormat = /^@?(\w){1,15}$/;
const isValidTwitterHandle = (handle: string) => twitterHandleFormat.test(handle);

interface SelectTwitterAccountsProps extends SelectAccountsProps {
    onBack: () => void;
}

const SelectTwitterAccounts = ({
    onBack,
    onCancel,
    onChange,
    values,
}: SelectTwitterAccountsProps) => {
    const cl = useStyles();

    const defaultTwitterAccounts = useMemo(
        () =>
            values
                .filter(isGuidedSearchTwitterAccount)
                .map((item) => item?.username || item?.name || item),
        [values],
    );

    const [twitterAccounts, setTwitterAccounts] = useState<string[]>(
        defaultTwitterAccounts as string[],
    );
    const [error, setError] = useState<{title: string; desc: string}>();

    const handleChange = useCallback(
        (handles: string[]) => {
            setError(undefined);

            if (handles.every((handle) => isValidTwitterHandle(handle))) {
                const handleValues = handles.map((handle) => handle.replace(/@/, ''));
                setTwitterAccounts(handleValues);
            } else {
                setError({
                    title: T('queryBuilder.twitterUsernameValidationErrorTitle'),
                    desc: T('queryBuilder.twitterUsernameValidationErrorDesc'),
                });
            }
        },
        [setTwitterAccounts],
    );

    const onAddAccounts = useCallback(
        (selected: string[]) => {
            const otherItems = values.filter((value) => !isGuidedSearchTwitterAccount(value));
            const newTwitterItems = selected.map(parser.twitter);

            onChange([...otherItems, ...newTwitterItems]);
        },
        [onChange, values],
    );

    const labelId = useUniqueId(null, 'twitterAccountsInput');

    return (
        <>
            <MenuHeader id={labelId} onBack={onBack}>
                {T('queryBuilder.twitterAccounts')}
            </MenuHeader>
            <div className={cl.menuContent}>
                <AutocompleteMultiple
                    autoFocus
                    disableClearable
                    error={error}
                    freeSolo
                    helperText={T('queryBuilder.twitterInputHelperText')}
                    labelId={labelId}
                    onChange={handleChange}
                    placeholder={T('queryBuilder.enterTwitterAccounts')}
                    value={twitterAccounts}
                />
            </div>
            <MenuFooter alignRight>
                <Button className={cl.footerBtn} onClick={onCancel} priority="primary" size="small">
                    {T('queryBuilder.cancel')}
                </Button>
                <Button
                    className={cl.footerBtn}
                    disabled={!twitterAccounts.length}
                    onClick={() => onAddAccounts(twitterAccounts)}
                    priority="cta"
                    size="small"
                >
                    {T('queryBuilder.addPagesButton')}
                </Button>
            </MenuFooter>
        </>
    );
};

export default SelectTwitterAccounts;
