import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

import {FullViewport} from 'hsi/components/FullViewport';
import IconRouter from 'hsi/components/IconRouter';

import {T} from 'hsi/i18n';

const useStyles = makeStyles(({font}) => ({
    noResults: {
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        '& .icon-wrapper': {
            flex: '0 0 100%',
            '& .app-icon.icon-mention': {
                height: '56px',
                width: '56px',
            },
        },
        '& .main': {
            fontSize: '24px',
            margin: '12px 0',
            fontWeight: font.semiBold,
        },
    },
}));

const BaseNoResults = () => {
    const classes = useStyles();

    return (
        <div className={classes.noResults} data-testid="noResults">
            <div className="icon-wrapper">
                <IconRouter name="mention" />
            </div>
            <div className="main">{T('noResults')}</div>
        </div>
    );
};

const NoResults = ({fullViewport}) =>
    fullViewport ? (
        <FullViewport>
            <BaseNoResults />
        </FullViewport>
    ) : (
        <BaseNoResults />
    );

NoResults.propTypes = {
    fullViewport: PropTypes.bool,
};

export default NoResults;
