import {PropsWithChildren, ReactNode} from 'react';
import cn from 'classnames';

import WithMenuAndTooltip from 'hsi/components/Mention/WithMenuAndTooltip';
import PulseLoader from 'hsi/components/PulseLoader';

import useStyles from './styles';
import {T} from 'hsi/i18n';

type MetricsListItemProps = PropsWithChildren<{
    name: ReactNode;
    menu?: Parameters<typeof WithMenuAndTooltip>[0]['menu'];
    buttonClassName?: string;
    disabled?: boolean;
    pending?: boolean;
    loadingLbl?: string;
}>;

export default function MetricListItem({
    name,
    children,
    menu,
    buttonClassName,
    disabled,
    pending,
    loadingLbl,
}: MetricsListItemProps) {
    const classes = useStyles();

    return (
        <div className={classes.metricsListItem}>
            <dt className={classes.metricsListTerm}>{name}</dt>
            <dd className={classes.metricsListDefinition}>
                {pending ? (
                    <>
                        <PulseLoader size="small" className={classes.loader} />
                        <span className="offscreen">{loadingLbl ?? T('loading')}</span>
                    </>
                ) : menu ? (
                    <WithMenuAndTooltip menu={menu}>
                        <button
                            type="button"
                            className={cn(classes.addMenu, buttonClassName)}
                            disabled={disabled}
                        >
                            {children}
                        </button>
                    </WithMenuAndTooltip>
                ) : (
                    children
                )}
            </dd>
        </div>
    );
}
