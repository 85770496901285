import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => {

    return {
        btn: {
            marginLeft: 10,
        }
    };
});
