import { useMemo } from 'react';

// Cards
import { BenchmarkBySearch, BenchmarkCard } from 'hsi/components/Cards/Benchmark';
import EmotionHistoryCard from 'hsi/components/Cards/EmotionHistory';
import EmotionVolumeCard from 'hsi/components/Cards/EmotionVolume';
import GenderCard from 'hsi/components/Cards/Gender';
import GeographyCard from 'hsi/components/Cards/Geography';
import HeatmapCard from 'hsi/components/Cards/Heatmap';
import MentionsHistoryCard from 'hsi/components/Cards/MentionsHistory';
import NetSentimentHistoryCard from 'hsi/components/Cards/NetSentimentHistory';
import PageTypeBySearchCard from 'hsi/components/Cards/PageTypeBySearch';
import SentimentHistoryCard from 'hsi/components/Cards/SentimentHistory';
import SentimentVolumeCard from 'hsi/components/Cards/SentimentVolume';
import SentimentVolumeBySearchCard from 'hsi/components/Cards/SentimentVolumeBySearch';
import ShareOfVoiceCard from 'hsi/components/Cards/ShareOfVoice';
import TopAuthorsCard from 'hsi/components/Cards/TopAuthors';
import TopAuthorsBySearchCard from 'hsi/components/Cards/TopAuthorsBySearch';
import TopHashtagsCard from 'hsi/components/Cards/TopHashtags';
import TopHashtagsBySearchCard from 'hsi/components/Cards/TopHashtagsBySearch';
import TopInterestsCard from 'hsi/components/Cards/TopInterests';
import TopLanguagesCard from 'hsi/components/Cards/TopLanguages';
import TopSharedURLsCard from 'hsi/components/Cards/TopSharedURLs';
import TopSitesCard from 'hsi/components/Cards/TopSites';
import TopSitesBySearchCard from 'hsi/components/Cards/TopSitesBySearch';
import TopTopicsBySearchCard from 'hsi/components/Cards/TopTopicsBySearch';
import TopicWheelCard from 'hsi/components/Cards/TopicWheel';
import TotalMentionsCard from 'hsi/components/Cards/TotalMentions';
import TotalVolumeCard from 'hsi/components/Cards/TotalVolume';
import TotalVolumeBySearchCard from 'hsi/components/Cards/TotalVolumeBySearch';
import WordCloudCard from 'hsi/components/Cards/WordCloud';

// Hooks
import { useTheme } from '@mui/material';
import useFlags from 'hsi/hooks/useFlags';
import useQueryContext from 'hsi/hooks/useQueryContext';
import { CardComponentConfig, CardComponentType } from 'hsi/types/cards';
import { ChartKey } from 'hsi/types/charts';
import { ConfigType } from 'hsi/types/config';
import useQueryData from './useQueryData';
import { CARD_SECTION_HIDDEN, CARD_SECTION_PINNED } from 'hsi/constants/config';

// Constants
export const componentByName: Record<ChartKey, CardComponentType> = {
    totalMentions: TotalMentionsCard,
    totalVolume: TotalVolumeCard,
    totalVolumeBySearch: TotalVolumeBySearchCard,
    mentionsHistory: MentionsHistoryCard,
    heatmap: HeatmapCard,
    wordCloud: WordCloudCard,
    toptopicsBySearch: TopTopicsBySearchCard,
    benchmark: BenchmarkCard,
    benchmarkBySearch: BenchmarkBySearch,
    sentimentVolume: SentimentVolumeCard,
    sentimentVolumeBySearch: SentimentVolumeBySearchCard,
    sentimentHistory: SentimentHistoryCard,
    netSentimentHistory: NetSentimentHistoryCard,
    emotionVolume: EmotionVolumeCard,
    emotionHistory: EmotionHistoryCard,
    pageTypeBySearch: PageTypeBySearchCard,
    topSharedURLs: TopSharedURLsCard,
    topsites: TopSitesCard,
    topauthors: TopAuthorsCard,
    topauthorsBySearch: TopAuthorsBySearchCard,
    topsitesBySearch: TopSitesBySearchCard,
    tophashtags: TopHashtagsCard,
    tophashtagsBySearch: TopHashtagsBySearchCard,
    topinterests: TopInterestsCard,
    toplanguages: TopLanguagesCard,
    gender: GenderCard,
    geography: GeographyCard,
    topicWheel: TopicWheelCard,
    shareOfVoice: ShareOfVoiceCard,
};

const SECTION_SORT_WEIGHTS: Record<string, number> = {
    [CARD_SECTION_HIDDEN]: -1,
    [CARD_SECTION_PINNED]: 1
}

//Transforms config rules into cards to render
export default function useGetCards({cardRules, cardHeights}: ConfigType['searchResults']) {
    const flags = useFlags();
    const {components}: any = useTheme();
    const queryContext = useQueryContext();

    const dashboardCardsOptions = useQueryData(
        queryContext.savedSearchId, 
        queryContext.isMultipleSearch 
            ? "multipleSearchDashboardCardsOptions" 
            : "dashboardCardsOptions"
    );

    return useMemo(() => {
        const mappedCards = cardRules
            .filter(({requires}) => !requires || requires({queryContext, flags}))
            .map(
                ({name, section, getTitle}) =>
                    ({
                        name,
                        section: flags.hasDashboardPinAndHide 
                            ? dashboardCardsOptions?.[name]?.visibility === 'pinned' 
                                ? CARD_SECTION_PINNED 
                                : dashboardCardsOptions?.[name]?.visibility === 'hidden' 
                                    ? CARD_SECTION_HIDDEN
                                    : section
                            : section,
                        title: getTitle({flags, queryContext}),
                        height: cardHeights[name],
                        component:
                            // Themed components should start with Card_
                            (components && (components[`Card_${name}`] as CardComponentType)) ||
                            componentByName[name],
                    } as CardComponentConfig),
            );
        
        if(dashboardCardsOptions !== null) {
            mappedCards.sort(({section: sectionA}, {section: sectionB}) => {
                return (SECTION_SORT_WEIGHTS[sectionB] ?? 0) - (SECTION_SORT_WEIGHTS[sectionA] ?? 0);
            });
        }

        return mappedCards;
    }, [cardRules, queryContext, flags, dashboardCardsOptions, cardHeights, components]);
}
