import {useMemo} from 'react';

import CopyField from 'hsi/components/CopyField';
import FacebookLoginButton from '../FacebookLoginButton';

import useFacebookInviteUrl from 'hsi/hooks/useFacebookInviteUrl';
import useStyles from './styles';
import useUniqueId from 'hsi/hooks/useUniqueId';

import {T} from 'hsi/i18n';

type AuthenticateFacebookProps = {
    className?: string;
    onLogin?: () => void;
};

const AuthenticateFacebook = ({className, onLogin}: AuthenticateFacebookProps) => {
    const classes = useStyles();
    const inviteUrl = useFacebookInviteUrl();
    const copyId = useUniqueId(null, 'copyField');

    const content = useMemo(() => {
        if (inviteUrl.isError) {
            return T('authTokens.tokensPage.loginBlock.copyLinkFailed');
        }

        if (inviteUrl.isLoading) {
            return T('loading');
        }

        return inviteUrl.data;
    }, [inviteUrl.data, inviteUrl.isError, inviteUrl.isLoading]);

    return (
        <div className={className}>
            <FacebookLoginButton onLogin={onLogin} />
            <div className={classes.divider}>
                <span className={classes.dividerText}>
                    {T('authTokens.tokensPage.loginBlock.or')}
                </span>
            </div>
            <label htmlFor="copyAuthenticateField" className={classes.copyLinkLabel} id={copyId}>
                {T('authTokens.tokensPage.loginBlock.copyLinkLabel')}
            </label>
            <CopyField
                id="copyAuthenticateField"
                content={content}
                error={inviteUrl.isError}
                labelId={copyId}
                loading={inviteUrl.isLoading}
            />
        </div>
    );
};

export default AuthenticateFacebook;
