import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    item: {
        '&&': {
            //Needed to ensure that these styles have a higher specificity and are not overridden
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'none', //<<< what is this for?
        },
    },
}));
