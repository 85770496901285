import React, {useLayoutEffect, useRef} from 'react';

import Mention from 'hsi/components/Mention';
import PulseLoader from 'hsi/components/PulseLoader';

import useStateRef from 'hsi/hooks/useStateRef';

import useStyles from './styles';
import {MentionType, SavedSearchMentionType} from 'hsi/types/mentions';

type RenderNativeMentionProps = {
    mention: MentionType;
};

const RenderNativeMention = ({mention}: RenderNativeMentionProps) => {
    const classes = useStyles();

    switch ((mention as SavedSearchMentionType).pageType) {
        case 'twitter':
            return <RenderNativeTwitterMention mention={mention as SavedSearchMentionType} />;
        //TODO: Fix instagram OEMBED
        // case 'instagram':
        //     return <RenderNativeInstagramMention mention={mention} />;
        default: //<will also be for all quicksearch mentions
            return (
                <Mention
                    data={mention}
                    fulltext
                    editable={false}
                    hideMetrics
                    hideOriginalLink
                    className={classes.generic}
                />
            );
    }
};

export default RenderNativeMention;

const loadTwitterEmbed = (callback?: (didLoad: boolean) => void) => {
    const existingScript = document.getElementById('twitterEmbedScript');

    if (!existingScript) {
        const script = document.createElement('script');
        script.src = 'https://platform.twitter.com/widgets.js';
        script.id = 'twitterEmbedScript';
        document.body.appendChild(script);
        script.onload = () => callback && callback(true);
    }

    existingScript && callback && callback(false);
};

//Internal components
function RenderNativeTwitterMention({mention}: {mention: SavedSearchMentionType}) {
    const classes = useStyles();
    const rootRef = useRef(null);
    const [isLoadedRef, setIsLoaded] = useStateRef(false);

    useLayoutEffect(
        () => {
            loadTwitterEmbed(() => {
                //deal with quicksearch or saved search
                const id = mention.guid || mention.id.split('/').pop();

                window.twttr.widgets
                    .createTweet(id, rootRef.current, {align: 'left'})
                    .then(() => setIsLoaded(true));
            });
        },
        // Check if deps can be added without issue
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <div ref={rootRef}>
            {!isLoadedRef.current && (
                <div className={classes.twitterLoader}>
                    <PulseLoader size="medium" />
                </div>
            )}
        </div>
    );
}

// const loadInstagramEmbed = (callback) => {
//     const existingScript = document.getElementById('instagramEmbedScript');
//     if (!existingScript) {
//         const script = document.createElement('script');
//         script.src = '//platform.instagram.com/en_US/embeds.js';
//         script.id = 'instagramEmbedScript';
//         document.body.appendChild(script);
//         script.onload = () => callback && callback(true);
//     }

//     existingScript && callback && callback(false);
// };

// function RenderNativeInstagramMention({mention}) {
//     const classes = useStyles();
//     const [ref, {width} = {}] = useElementSize(null, {width: true});

//     useEffect(() => {
//         loadInstagramEmbed((firstLoad) => {
//             !firstLoad && window?.instgrm?.Embeds?.process?.();
//         });
//     }, []);

//     return (
//         <div ref={ref}>
//             <div className={classes.instagram} style={{width: `${Math.floor(width)}px`}}>
//                 <blockquote className="instagram-media" data-instgrm-version="13">
//                     <a href={mention.url}>{mention.url}</a>
//                 </blockquote>
//             </div>
//         </div>
//     );
// }
