import { isV2 } from 'hsi/types/theme';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(
    (theme) => {
        const v2 = isV2(theme);
        return {
            nav: {
                display: 'flex',
                alignItems: 'center',
                flexFlow: 'row wrap',
                gap: v2 ? '0 16px' : '0 20px'
            },
        };
    }
);
