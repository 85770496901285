import ISO6391 from 'iso-639-1';
import urlParse from 'url-parse';
import mapValues from 'lodash/mapValues';
import {EMOTION_THRESHOLD} from 'hsi/constants/config';
import {
    MentionDataImageType,
    MentionDataType,
    MentionEmotionsType,
    AnyMentionType,
} from 'hsi/types/mentions';
import {EmotionsType} from 'hsi/types/shared';

//TODO in an ideal world, this would be refactored. Maybe to understand that saved search and quick search mentions are different,
//maybe to not be required at all. But it works, and is so complex and confusing that it seems like more effort than it's worth

export default function normaliseMention(mention: AnyMentionType): MentionDataType {
    if (!mention) {
        debugger;
    }
    const site =
        mention.site || mention.domain
            ? (mention.site || mention.domain!).split('.')[0]
            : mention.url
            ? urlParse(mention.url).hostname
            : 'unknown';

    const accessorsName = allAccessors[site as AllAccessorKeys]
        ? site
        : allAccessors[mention.contentSource as AllAccessorKeys]
        ? mention.contentSource
        : 'default';

    //the object with the accessor rules
    const accessors = allAccessors[accessorsName as AllAccessorKeys];

    const emotions = parseEmotions(mention);
    const emotion = getEmotion(emotions); //[name] - only values above confidence threshold, array sorted by highest confidence first (or null)
    const sentiment = getField('sentiment', mention, accessors);
    const location = getField('location', mention, accessors);
    const language = getField('language', mention, accessors);

    return {
        id: mention.resourceId || mention.id,
        site: getField('site', mention, accessors),
        title: getField('title', mention, accessors),
        fullname: getField('fullname', mention, accessors),
        username: getField('author', mention, accessors),
        avatar: getField('avatarUrl', mention, accessors),
        reachEstimate: getField('reachEstimate', mention, accessors),
        url: mention.url,
        domain: getField('domain', mention, accessors),
        date: mention.date,
        snippet: mention.snippet,
        fullText: mention.fullText,
        sentiment,
        emotion,
        emotions,
        language,
        location,
        contentCategory: accessorsName,
        hasAuthor: getField('hasAuthor', mention, accessors),
        hasMetaData: !!sentiment || !!emotion || !!language || !!location,
        authorUrl: getField('authorUrl', mention, accessors),
        tags: mention.tags,
        image: getField('image', mention, accessors),
        video: getField('video', mention, accessors),
        pageTypeName:
            mention.pageTypeName ||
            mention.pageType ||
            ((mention as any).contentSourceName as string) ||
            null,

        //metrics
        twitterRetweets: mention.twitterRetweets,
        twitterImpressions: mention.impressions,
        facebookComments: mention.facebookComments,
        facebookLikes: mention.facebookLikes,
        redditScore: mention.redditScore,
        instagramInteractionsCount: mention.instagramInteractionsCount,
    };
}
type AccessorType = {
    site: (this: AccessorType, mention: AnyMentionType) => string;
    fullname: (this: AccessorType, mention: AnyMentionType) => string | null;
    author: (this: AccessorType, mention: AnyMentionType) => string | null;
    hasAuthor: (this: AccessorType, mention: AnyMentionType) => boolean;
    title: (this: AccessorType, mention: AnyMentionType) => string | null;
    authorUrl: (this: AccessorType, mention: AnyMentionType) => string | null;
    language: (this: AccessorType, mention: AnyMentionType) => string | null;
    location: (this: AccessorType, mention: AnyMentionType) => string | null;
    image: (this: AccessorType, mention: AnyMentionType) => MentionDataImageType | null;
    video: (this: AccessorType, mention: AnyMentionType) => string | null;
};

//field type get functions
function getField<TField extends keyof AccessorType>(
    field: TField,
    mention: AnyMentionType,
    accessors: AccessorType,
): ReturnType<AccessorType[TField]>;
function getField<TField extends keyof AnyMentionType>(
    field: TField,
    mention: AnyMentionType,
    accessors: AccessorType,
): AnyMentionType[TField];
function getField<TField extends keyof AccessorType | keyof AnyMentionType>(
    this: AccessorType,
    field: TField,
    mention: AnyMentionType,
    accessors: AccessorType,
): any {
    return accessors[field as keyof AccessorType]
        ? (accessors[field as keyof AccessorType] as any).call(accessors, mention)
        : mention[field as keyof AnyMentionType];
}

function getEmotion(emotions: MentionEmotionsType | null): EmotionsType[] | null {
    if (!emotions) {
        return null;
    }

    return Object.entries(emotions)
        .sort((a, b) => b[1] - a[1])
        .map(([name]) => name) as EmotionsType[];
}

//Accessors
const defaultAccessor: AccessorType = {
    site: (mention) => {
        return mention.site || mention.domain
            ? mention.site || mention.domain
            : mention.url
            ? urlParse(mention.url).hostname
            : 'unknown';
    },
    fullname: (mention) =>
        mention.fullname && mention.fullname !== undefined
            ? mention.fullname
            : mention.author && mention.author !== undefined
            ? mention.author
            : null,
    author: () => null,
    hasAuthor: function (mention) {
        return !!this.author(mention) || !!this.fullname(mention);
    }, //do we show a human name as the author?
    title: (d) => (d.title && d.title !== 'undefined title' ? d.title : null),
    authorUrl: () => null,
    language: (mention) => (mention.language ? ISO6391.getName(mention.language) : null),
    location: (mention) => {
        if (mention.city) {
            return `${mention.city}, ${mention.region}, ${mention.country}`;
        } else if (mention.region) {
            return `${mention.region}, ${mention.country}`;
        } else if (mention.country) {
            return `${mention.country}`;
        } else if (mention.continent) {
            return mention.continent;
        } else if (mention.locationName) {
            return mention.locationName;
        }
        return null;
    },
    image: (mention) => {
        const url = mention.mediaUrls && mention.mediaUrls.length ? mention.mediaUrls[0] : null;

        // Some mentions have imageInfo, which we can use to get the image dimensions before it loads,
        // and reduce reflowing/rerendering
        if (mention.imageInfo && mention.imageInfo.length > 0) {
            const imageInfo = mention.imageInfo[0];

            return {
                url: imageInfo.url,
                width: imageInfo.imageDimensions.width,
                height: imageInfo.imageDimensions.height,
                alt: '',
            };
        }

        //This is how quicksearch currently supplies images
        if ((mention as any).imageInfos && (mention as any).imageInfos.length > 0) {
            return {
                url: (mention as any).imageInfos[0].url,
                width: undefined,
                height: undefined,
                alt: '',
            };
        }

        //Some mentions only give their image url here, so we still need to do this
        return url && mention.subtype !== 'video' && !url.includes('.mp4') //the .mp4 check seems like a really fragile check - is it needed?
            ? {url, width: undefined, height: undefined, alt: ''}
            : null;
    },
    video: (mention) => {
        const url = mention.mediaUrls && mention.mediaUrls.length ? mention.mediaUrls[0] : null;

        return url && (mention.subtype === 'video' || url.includes('.mp4')) //the .mp4 check seems like a really fragile check - is it needed?
            ? url
            : null;
    },
};

// Customize platform header metadata for each mention type.
// e.g. the author value for twitter mentions should be the author name where for news/forum mentions should be the domain url
const baseAllAccessors: Record<string, Partial<AccessorType>> = {
    /** TWITTER */
    twitter: {
        site: () => 'twitter',
        fullname: (d: AnyMentionType) =>
            d.fullname
                ? d.fullname.indexOf('(')
                    ? d.fullname.slice(d.fullname.indexOf('(') + 1, -1)
                    : d.fullname
                : null,
        author: (d: AnyMentionType) => '@' + d.author,
        hasAuthor: () => true,
        title: () => null,
        authorUrl: (d: AnyMentionType) => 'https://twitter.com/' + d.author,
    },

    /** GOOGLE */
    //does this actually exist?
    //Yes, because we access these based on 'site', to allow domain specific handling.
    //It's a terrible system, because it can't handle different differing TLDs e.g. example.com vs example.net
    //But that is an unlikely situation I guess
    google: {
        fullname: (d: AnyMentionType) => d.author || d.site || d.domain || null,
    },

    /** REDDIT */
    reddit: {
        site: (mention) => {
            if (
                mention.url.startsWith('https://www.reddit.com/r/') ||
                mention.url.startsWith('https://reddit.com/r/')
            ) {
                return (
                    'reddit.com' + urlParse(mention.url).pathname.split('/').slice(0, 3).join('/')
                );
            }

            return 'reddit';
        },
        author: (d: AnyMentionType) => null,
    },

    /** TUMBLR */
    tumblr: {
        fullname: (d: AnyMentionType) => null,
        author: (d: AnyMentionType) => urlParse(d.url).hostname,
        authorUrl: (d: AnyMentionType) => urlParse(d.url).origin,
        hasAuthor: () => false,
    },

    /** FLICKR */
    flickr: {
        fullname: (d: AnyMentionType) => {
            if (d.author) {
                return d.author.indexOf('nobody@flickr.com') !== -1
                    ? d.author.split('nobody@flickr.com (')[1].slice(0, -1)
                    : d.author;
            } else {
                return d.site || d.domain || null;
            }
        },
        hasAuthor: (mention: AnyMentionType) => !!mention.author,
    },

    /** NEWS */
    news: {
        fullname: () => null,
        author: (d: AnyMentionType) => d.site || d.domain || null,
        authorUrl: (d: AnyMentionType) => '//' + (d.site || d.domain),
        hasAuthor: () => false,
    },

    /** BLOGS */
    blog: {
        fullname: () => null,
        author: (d: AnyMentionType) => d.site || d.domain || null,
        authorUrl: (d: AnyMentionType) => urlParse(d.url).origin,
        hasAuthor: () => false,
    },

    /** FORUMS */
    forum: {
        fullname: () => null,
        author: (d: AnyMentionType) => d.site || d.domain || null,
        authorUrl: (d: AnyMentionType) => '//' + (d.site || d.domain),
        hasAuthor: () => false,
    },

    /** REVIEW */
    review: {
        //fullname : d => d.fullname && isNaN(parseInt(d.fullname)) && d.fullname != 'undefined' ? d.fullname : null,
        //author : d => d.author && isNaN(parseInt(d.author)) && d.author != 'undefined' ? '@'+d.author : null,
        fullname: () => null,
        author: (d: AnyMentionType) => d.site || d.domain || null,
        authorUrl: (d: AnyMentionType) => '//' + (d.site || d.domain),
        hasAuthor: () => false,
    },

    /** FACEBOOK */
    facebook: {
        fullname: (d: AnyMentionType) =>
            !!d?.fullname && d.fullname !== 'undefined' ? d.fullname : d.site || d.domain || null,
        author: (d: AnyMentionType) => (!!d?.author && d.author !== 'undefined' ? d.author : null),
        title: (d: AnyMentionType) => (!!d?.title && d.title !== d.snippet ? d.title : null),
    },

    /** INSTAGRAM */
    instagram: {
        title: (d: AnyMentionType) => 
            !d.title || d.title.includes(removeEllipsis(d.snippet || '' )) || d.snippet.includes(d.title)
                ? null
                : d.title,
        // snippet: (d: AnyMentionType) =>
        //     d.title && d.title.includes(removeEllipsis(d.snippet)) ? d.title : d.snippet,
        fullname: () => 'instagram.com',
        author: (d: AnyMentionType) => (!!d?.author && d.author !== 'undefined' ? d.author : null),
    },

    /** YOUTUBE */
    youtube: {
        fullname: (d: AnyMentionType) =>
            d.author && d.author !== undefined ? d.author : d.fullname || null,
        author: () => null,
    },

    /** QQ */
    qq: {},
} as const;

// add blogs to allAccessors so we can use its fallback icon for blog
baseAllAccessors.blogs = baseAllAccessors.blog;

const allAccessors = {
    default: defaultAccessor,

    ...mapValues(baseAllAccessors, (accessor) => ({
        ...defaultAccessor,
        ...accessor,
    })),
};

type AllAccessorKeys = keyof typeof allAccessors;

//Internal helpers
const removeEllipsis = (str: string) => {
    if (str.length > 3 && str.endsWith('...')) {
        return str.slice(0, str.length - 3);
    }
    return str;
};

export function parseEmotions(mention: AnyMentionType): MentionEmotionsType | null {
    return mention.classifications
        ? mention.classifications.reduce<MentionEmotionsType | null>(
              (emotions, {classifierId, confidence, name}) => {
                  if (classifierId === 'emotions' && confidence >= EMOTION_THRESHOLD) {
                      emotions = emotions || ({} as MentionEmotionsType);
                      emotions[name as keyof MentionEmotionsType] = confidence;
                  }

                  return emotions;
              },
              null,
          )
        : null;
}
