import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => ({
    root: {
        textAlign: 'center',
        color: theme.colors.textDefault,
        fontSize: '12px',
        marginTop: '8px',
        marginBottom: '35px',
    },

    icon: {
        display: 'inlineBlock',
        fill: theme.colors.textDefault,
        width: '24px',
        height: '24px',
    },
}));
