import {useCallback, useState} from 'react';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import {useSelector} from 'react-redux';

import {validateQuery} from 'hsi/services/queryValidationService';

/*
 * useQueryValidation hook
 * to perform a validation flow within a component
 *
 * validation = {
 *    data: {}, // data returns from validateQuery with query data
 *    errors: [],
 *    validating: bool, //for a spinner
 *    success: bool,
 *    validate: func, //for <SearchButton/>
 * }
 *
 * e.g.
 *
 * const Component = () => {
 *
 *    const validated = ({ query, errors }) => {
 *        //do something like dispatching an action,
 *        //saving the query or pushing the navigation
 *        return false //to cut the rendering flow
 *        return true //or undefined to continue with the rendering flow
 *    }
 *
 *    const validation = useQueryValidation({ validated });
 *
 *    return <div>{
 *      render stuff
 *      along with a validation spinner
 *      or the validation errors
 *    }</div>
 *}
 */

const useQueryValidation = ({onValidated} = {}) => {
    const [data, setData] = useState();
    const [errors, setErrors] = useState();
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const projects = useSelector((state) => state.user.projects);

    const validate = useCallback(
        async (query) => {
            setLoading(true);

            try {
                const res = await validateQuery({query, projectId: projects[0].id, isSaved: false});

                if (!isEmpty(res.issues)) {
                    setErrors(res.issues);
                } else {
                    if (isFunction(onValidated) ? onValidated({...res, query}) : true) {
                        const newData = {...res, query};
                        setData(newData);
                    } else {
                        setData(null);
                    }
                }
            } catch (e) {
                setErrors(e);
            } finally {
                setLoaded(true);
                setLoading(false);
            }
        },
        [onValidated, projects],
    );

    return {
        data,
        errors,
        loaded,
        success: !!data && loaded && !loading,
        validating: loading,
        validate,
    };
};

export default useQueryValidation;
