//Legacy code, used only by mentions table. DO NOT USE ELSEWHERE

import {useEffect, useState} from 'react';

const getBbox = (el) => el.getBoundingClientRect();

const isVisible = (bb) =>
    (xVisible(bb.left) || xVisible(bb.right)) && (yVisible(bb.top) || yVisible(bb.bottom));

const xVisible = (x) => x >= 0 && x <= window.innerWidth;
const yVisible = (y) => y >= 0 && y <= window.innerHeight;

const useUntilVisible = ({ref, onVisible, getContainer}) => {
    const [el, setEl] = useState();

    useEffect(() => {
        if (el) return;

        const _el = ref.current;
        const container = typeof getContainer === 'function' ? getContainer() : _el.parentNode;

        const update = () => {
            if (!isVisible(getBbox(_el))) return;
            container.removeEventListener('mousewheel', update);
            container.removeEventListener('scroll', update);
            container.removeEventListener('resize', update);
            onVisible();
        };

        container.addEventListener('mousewheel', update);
        container.addEventListener('scroll', update);
        container.addEventListener('resize', update);

        setEl(_el);
        // Check if deps can be added without issue
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [el]);
};

export default useUntilVisible;
