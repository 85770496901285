//TODO elegantly handle going back to isLoaded = false after isLoaded was true

import React, {CSSProperties, ReactElement, useCallback, useMemo, useState} from 'react';

import Fade from '@mui/material/Fade';

//Components
import PulseLoader from 'hsi/components/PulseLoader';

//Other
import useStyles from './styles';

//Consts
const defaultLoading = <PulseLoader size="medium" />;
const defaultMinWidth = '26px'; //The width of the default loading indicator

//Types
type PendingDisplayProps = {
    children: ReactElement;
    isLoaded: boolean;
    loading?: ReactElement;
    minWidth?: string;
    minHeight?: string;
};

interface PendingDisplayCSSProperties extends CSSProperties {
    '--pendingDisplay-minWidth'?: string;
    '--pendingDisplay-minHeight'?: string;
}

//The component
export default function PendingDisplay({
    children,
    isLoaded,
    minWidth = defaultMinWidth,
    minHeight,
    loading = defaultLoading,
}: PendingDisplayProps) {
    const classes = useStyles();
    const [doShowContent, setDoShowContent] = useState(isLoaded);

    //Calculated values
    const sizeStyles = useMemo<PendingDisplayCSSProperties | undefined>(() => {
        if (!minWidth && !minHeight) {
            return undefined;
        }

        return {
            '--pendingDisplay-minWidth': minWidth,
            '--pendingDisplay-minHeight': minHeight,
        };
    }, [minWidth, minHeight]);

    //Callbacks
    const onLoadingIndicatorExited = useCallback(() => setDoShowContent(true), [setDoShowContent]);

    //Rendering
    return (
        <div className={classes.pendingDisplay} style={sizeStyles}>
            {!doShowContent && (
                <div
                    aria-hidden={isLoaded}
                    inert={isLoaded ? 'inert' : undefined}
                    className={classes.pendingWrapper}
                >
                    <Fade in={!isLoaded} onExited={onLoadingIndicatorExited}>
                        {loading}
                    </Fade>
                </div>
            )}
            <Fade in={doShowContent}>
                <div aria-hidden={!doShowContent} inert={!doShowContent ? 'inert' : undefined}>
                    {doShowContent && children}
                </div>
            </Fade>
        </div>
    );
}
