// TODO: Convert to typescript
// TODO: Update tests
const linkedAccountByPlatform = {
    instagram: (d, {fbPageAuth}) =>
        d?.instagramBusinessAccountId && fbPageAuth
            ? {name: fbPageAuth?.name, icon: 'logo-instagram'}
            : null,
    linkedin: (d) => ({name: d.name, icon: 'linkedin'}),
};

const getLinkedAccount = (d, opt) => {
    const get = linkedAccountByPlatform[d.platform];
    return (typeof get === 'function' && get(d, opt)) || null;
};

export const addLinkedAccounts = (authentications) => {
    const fbPageAuthsByPageId = authentications.reduce((res, d) => {
        if (d.fbPageId && d.type === 'facebook-page') res[d.fbPageId] = d;
        return res;
    }, {});

    return authentications.map((d) => ({
        ...d,
        linkedAccount: getLinkedAccount(d, {
            fbPageAuth: fbPageAuthsByPageId[d.fbPageId],
        }),
    }));
};
