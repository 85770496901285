import {useCallback, useEffect, useMemo, useState} from 'react';
import isEqual from 'lodash/isEqual';

import useQueryValidation from '../useQueryValidation';
import usePrevious from 'hsi/hooks/usePrevious';
import useProject from '../useProject';

import {markActiveTags} from './utils';
import {getInitialPeriod} from 'hsi/utils/dates';
import {loadMentions} from './service';

const useMentions = ({
    filterOptions,
    query,
    searchId,
    searchType,
    projects,
    page,
    pageSize,
    payload,
}) => {
    const {errors, loaded: validated, validate, validating} = useQueryValidation();

    const [results, setResults] = useState();
    const [resultsTotal, setResultsTotal] = useState();
    const [loadingMentions, setLoadingMentions] = useState(false);
    const [mentionsError, setMentionsError] = useState();

    const {
        project,
        loading: loadingProject,
        error: projectError,
    } = useProject({
        query,
        searchId: [searchId],
        projects,
    });

    const prevPage = usePrevious(page);
    const prevPayload = usePrevious(payload);
    const prevProject = usePrevious(project);

    const shouldValidate = useMemo(() => query && validated === false, [query, validated]);

    const shouldFetchMentions = useMemo(
        () =>
            !!project &&
            !!searchType &&
            (!!query || !!searchId) &&
            (!isEqual(prevProject, project) ||
                !isEqual(prevPage, page) ||
                !isEqual(prevPayload, payload)),
        [page, payload, prevPage, prevPayload, prevProject, project, query, searchId, searchType],
    );

    const loading = useMemo(
        () => loadingMentions || loadingProject || validating,
        [loadingMentions, loadingProject, validating],
    );

    const hasMoreData = useMemo(
        () => (results === undefined ? undefined : results.length < resultsTotal),
        [results, resultsTotal],
    );

    const fetchMentions = useCallback(async () => {
        setLoadingMentions(true);

        const {id: projectId, timezone: tz} = project;
        const dateRange = payload?.dateRange || getInitialPeriod(tz);

        try {
            const response = await loadMentions({
                projectId,
                searchType,
                searchId,
                query,
                filters: payload.filters,
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                page,
                pageSize,
            });

            setResultsTotal(response?.data?.resultsTotal);
            const newResults =
                markActiveTags(response?.data?.results, payload?.filters, filterOptions) || [];
            if (isEqual(page, prevPage)) {
                setResults(newResults);
            } else {
                setResults((prevResults) => (prevResults || []).concat(newResults));
            }
        } catch (error) {
            setMentionsError(error);
        } finally {
            setLoadingMentions(false);
        }
    }, [filterOptions, page, pageSize, payload, prevPage, project, query, searchId, searchType]);

    useEffect(() => {
        if (shouldValidate) {
            validate(query);
        }
    }, [query, shouldValidate, validate]);

    useEffect(() => {
        if (shouldFetchMentions) {
            fetchMentions();
        }
    }, [fetchMentions, shouldFetchMentions]);

    return {
        mentions: results,
        hasMoreData,
        loading,
        error: projectError || mentionsError,
        validationErrors: errors,
        project,
    };
};

export default useMentions;
