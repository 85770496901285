import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);

    return {
        dropDownIcon: {
            width: 12,
            height: 12,
            margin: v2 ? 0 : 16,
            fill: v2 ? theme.colors.bgOverlayHeavy : theme.colors.primaryBlue100,
        },
        textFieldNotchedOutline: {
            boxShadow: `none !important`,
            border: 'none !important',
            borderRadius: '2px',
        },
        textFieldRoot: {
            cursor: 'pointer',
        },
        textFieldAdornedEnd: {
            backgroundColor: v2 ? theme.colors.white : theme.colors.primaryBlue20,
            paddingRight: 0,
        },
        listItem: {
            cursor: 'pointer',
            '&.Mui-disabled': {
                cursor: 'not-allowed',
            },
            '&:hover': {
                backgroundColor: v2 ? theme.colors.lightGrey40 : theme.colors.primaryYellow20,
            },
            '&.Mui-selected': {
                backgroundColor: v2
                    ? theme.colors.bgSubtle
                    : `${theme.colors.primaryYellow40} !important`,
            },
        },
        listItemSecondaryAction: {
            textAlign: 'right',
        },
        dense: {
            marginLeft: 0,
        },
        inputAdornment: {
            position: 'relative',
        },
        menuContent: {
            padding: 0,
        },
        searchFieldWrapper: {
            padding: '10px 16px',
        },
        noOptionsFound: {
            padding: '30px 16px 40px',
            textAlign: 'center',
            color: theme.colors.textMeta,
        },
        menuList: {
            maxHeight: 45 * 5,
            padding: '2px 0',
            overflowY: 'auto',
            overflowX: 'hidden',
        },
        denseIcon: {
            width: 12,
            height: 12,
            margin: v2 ? 0 : '18px 8px 16px 4px',
        },
        withArrow: {
            overflowY: 'auto',
            overflowX: 'auto',
            overflow: 'visible !important',
            '&::before': {
                content: '""',
                display: 'block',
                width: 0,
                height: 0,
                position: 'absolute',
                left: '50%',
                transform: 'translate(-50%, 0)',
                margin: '-8px auto 0',
                borderStyle: 'solid',
                borderWidth: '0 8px 8px 8px',
                borderColor: `transparent transparent transparent transparent`,
            },
        },
        loadingContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 10,
            textAlign: 'center',
        },
        loadingAnim: {
            position: 'absolute',
            top: '50%',
            transform: 'translate(0, -50%)',
        },
        selectInputWrapper: {
            ...theme.mixin.blankButton,
            position: 'relative',
            display: 'block',
            width: '100%',
        },
    };
});
