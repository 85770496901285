import IconButton from '@mui/material/IconButton';
import IconRouter from 'hsi/components/IconRouter';
import useStyles from './styles';
import { T } from 'hsi/i18n';
import classNames from 'classnames';
import { ReactNode } from 'react';
import useIsCardInteractivityDisabled from 'hsi/hooks/useIsCardInteractivityDisabled';

type PaginationButtonsArgs = {
    page: number;
    pages: number;
    setPage: (newPage: number) => void;
    prevLbl?: string;
    nextLbl?: string;
    className?: string;
    a11yPageDesc?: (page: number, pages: number) => ReactNode;
};

export default function PaginationButtons({className, page, pages, setPage, prevLbl = T('paginationButtons.prevLbl'), nextLbl = T('paginationButtons.nextLbl'), a11yPageDesc = defaultA11yPageDesc}: PaginationButtonsArgs) {
    const cl = useStyles();
    const interactivityDisabled = useIsCardInteractivityDisabled();

    return pages <= 1 || interactivityDisabled
        ? null 
        : (
            <div className={classNames(cl.paginationButtons, className)}>
                <IconButton
                    aria-label={prevLbl}
                    disabled={page <= 1}
                    onClick={() => setPage(page - 1)}
                    size="small"
                    className={cl.btn}
                >
                    <IconRouter name="left-arrow" aria-hidden className={cl.icon} />
                </IconButton>

                <div className={cl.pageIndicator} aria-hidden>
                    {page}/{pages}
                </div>

                <div className="offscreen" aria-live="polite" aria-atomic>{a11yPageDesc(page, pages)}</div>

                <IconButton
                    aria-label={nextLbl}
                    disabled={page >= pages}
                    onClick={() => setPage(page + 1)}
                    size="small"
                    className={cl.btn}
                >
                    <IconRouter name="right-arrow" aria-hidden className={cl.icon} />
                </IconButton>
            </div>
    );
}

function defaultA11yPageDesc(page: number, pages: number) {
    return T('paginationButtons.a11yPageDesc', {page, pages});
}