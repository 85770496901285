import {useCallback, useEffect, useState} from 'react';

import {useDataSource} from 'hsi/hooks/useDataSource';
import useParseCsv, {ParseCsvErrorReasons} from 'hsi/hooks/useParseCsv';

import {ValidationItem} from 'hsi/types/dataManagement';

type UseNonOwnedInputProps = {
    dataType: ReturnType<typeof useDataSource>;
    defaultFailedItems?: ValidationItem[];
    defaultItems?: string[];
    getVerifyErrorFromCode: ({
        errorCode,
        failureReason,
    }: {
        errorCode?: number;
        failureReason?: string;
    }) => string;
    maxLength: number;
    onAdd: (args: any) => any;
    onClose: () => void;
};

const useNonOwnedInput = ({
    dataType,
    defaultFailedItems = [],
    defaultItems = [],
    getVerifyErrorFromCode,
    maxLength,
    onAdd,
    onClose,
}: UseNonOwnedInputProps) => {
    const [submitError, setSubmitError] = useState(false);
    const [items, setItems] = useState<string[]>(defaultItems);
    const [failedItems, setFailedItems] = useState<ValidationItem[]>(defaultFailedItems);
    const [parserError, setParserError] = useState<ParseCsvErrorReasons>();

    const atMaxLength = items.length >= maxLength;

    const resetInput = useCallback(() => {
        setSubmitError(false);
        setFailedItems(defaultFailedItems);
        setItems(defaultItems);
        setParserError(undefined);
    }, [defaultFailedItems, defaultItems]);

    const onItemsChange = useCallback(
        (newValues: string[]) => {
            setSubmitError(false);

            const uniqueItems = [...new Set(newValues)];
            const newItems = uniqueItems.slice(0, maxLength);

            setItems(newItems);
        },
        [maxLength],
    );

    const validateItems = useCallback(
        (newValues: string[]) => {
            const failedItemErrors = failedItems
                .filter((item) => newValues.includes(item.value))
                .map((value) => ({
                    label: getVerifyErrorFromCode({
                        errorCode: value?.errorCode,
                        failureReason: value?.failureReason,
                    }),
                    reason: value.failureReason,
                    value: value.value,
                }));

            failedItemErrors.length && setSubmitError(true);

            const newErrors = [...failedItemErrors];
            return newErrors;
        },
        [failedItems, getVerifyErrorFromCode],
    );

    const onSubmit = useCallback(
        async (event?: {preventDefault: () => void}) => {
            event?.preventDefault?.();

            setFailedItems(defaultFailedItems);

            try {
                const {failed, succeeded} = await dataType.validate(items);

                if (failed.length) {
                    setFailedItems(failed as ValidationItem[]);
                    setSubmitError(true);
                } else {
                    const addPayload = onAdd(succeeded);
                    dataType.add.mutate(addPayload);
                    onClose();
                }
            } catch (error) {
                console.log(error);
            }
        },
        [dataType, defaultFailedItems, items, onAdd, onClose],
    );

    const {acceptedFileTypes, parseCsv} = useParseCsv({
        onError: (error) => {
            setParserError(error);
        },
        onSuccess: (newPages) => {
            onItemsChange([...items, ...newPages]);
        },
        uploadLimit: maxLength,
    });

    useEffect(() => {
        if (items.length > maxLength) {
            setSubmitError(true);
        }
    }, [maxLength, items.length]);

    return {
        acceptedFileTypes,
        atMaxLength,
        failedItems,
        items,
        isLoading: dataType.isLoading,
        onItemsChange,
        onSubmit,
        parseCsv,
        parserError,
        resetInput,
        submitError,
        validateItems,
    };
};

export default useNonOwnedInput;
