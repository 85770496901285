import IconButton from '@mui/material/IconButton';

import Button from 'hsi/components/Button';
import ChipInput, {ChipInputProps} from 'hsi/components/ChipInput';
import IconRouter from 'hsi/components/IconRouter';
import Tooltip from 'hsi/components/Tooltip';

import useStyles from './styles';

import tns from 'hsi/i18n/tns';

const t = tns('dataManagement.addDataSourcesDialog.nonOwnedInput');

interface NonOwnedInputProps extends Partial<ChipInputProps> {
    atMaxLength: boolean;
    error: boolean;
    hasStatus?: ChipInputProps['hasStatus'];
    helperText: string;
    maxLength: number;
    onInfoButtonClick: () => void;
    onUploadCsvButtonClick: () => void;
    onValuesChange: ChipInputProps['onValuesChange'];
    platform: string;
    renderFailedStatus?: ChipInputProps['renderFailedStatus'];
    renderLoadingStatus?: ChipInputProps['renderLoadingStatus'];
    renderSuccessStatus?: ChipInputProps['renderSuccessStatus'];
    type: string;
    validateValues: ChipInputProps['validateValues'];
    values: ChipInputProps['values'];
}

const NonOwnedInput = ({
    atMaxLength,
    error,
    hasStatus,
    helperText,
    maxLength,
    onInfoButtonClick,
    onUploadCsvButtonClick,
    onValuesChange,
    platform,
    renderFailedStatus,
    renderLoadingStatus,
    renderSuccessStatus,
    type,
    validateValues,
    values,
}: NonOwnedInputProps) => {
    const classes = useStyles();

    const getInputError = (clearErrors?: () => void, valuesWithError?: string[]) => ({
        desc: (
            <div className={classes.inputErrorWrapper}>
                {t('chipError', {
                    num: valuesWithError?.length || 0,
                    type,
                })}
                <Button className={classes.inputErrorButton} onClick={clearErrors} priority="text">
                    {t('chipErrorButton')}
                </Button>
            </div>
        ),
    });

    const getMaxLengthError = () => ({
        desc: t('maxError', {
            num: maxLength,
            type,
        }),
    });

    return (
        <ChipInput
            className={classes.input}
            cta={
                !atMaxLength ? (
                    <>
                        <Button
                            className={classes.cta}
                            onClick={onUploadCsvButtonClick}
                            priority="secondary"
                        >
                            <IconRouter className={classes.ctaIcon} name="cloud-download" />
                            {t('cta')}
                        </Button>
                        <Tooltip tooltip={t('infoButtonTooltip')}>
                            <IconButton
                                aria-label={t('infoButtonLabel', {platform, type})}
                                onClick={onInfoButtonClick}
                                className={classes.infoButton}
                                size="large"
                            >
                                <IconRouter className={classes.ctaIcon} name="c-question-e" />
                            </IconButton>
                        </Tooltip>
                    </>
                ) : null
            }
            error={error}
            getInputErrorMessage={(_errorValues, valuesWithError, clearErrors) =>
                valuesWithError?.length
                    ? getInputError(clearErrors, valuesWithError)
                    : atMaxLength
                    ? getMaxLengthError()
                    : null
            }
            hasStatus={hasStatus}
            helperText={helperText}
            itemName={type}
            label={t('label', {platform, type})}
            maxLength={maxLength}
            onValuesChange={onValuesChange}
            placeholder={t('placeholder', {platform, type})}
            renderFailedStatus={renderFailedStatus}
            renderLoadingStatus={renderLoadingStatus}
            renderSuccessStatus={renderSuccessStatus}
            validateValues={validateValues}
            values={values}
        />
    );
};

export default NonOwnedInput;
