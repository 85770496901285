import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>(({v2, colors}) => ({
    table: {
        '&&': {
            "--table-row-minHeight": '58px',
        },
    },
    subtle: {
        color: v2 ? colors.textSubtle : colors.darkGrey60,
    }
}));
