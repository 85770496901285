import React from 'react';

import AdvancedOptions from './AdvancedOptions';
import CheckboxesPanel from './CheckboxesPanel';
import InputAccounts from './InputAccounts';
import InputChips from './InputChips';
import InputChipsToggle from './InputChipsToggle';
import InputHashtags from './InputHashtags';
import InputText from './InputText';
import Select from './Select';

import {getValues} from 'hsi/utils/formulaParser';

const componentsMap = {
    'input-text': InputText,
    'input-chips': InputChips,
    'input-chips-toggle': InputChipsToggle,
    'input-hashtags': InputHashtags,
    'input-accounts': InputAccounts,
    'checkboxes-panel': CheckboxesPanel,
    'advanced-options': AdvancedOptions,
    select: Select,
};

const renderComponents = ({components, sectionName, formula, onChange}) =>
    components.map((d, i) => {
        const Component = componentsMap[d.type];
        const key = `${sectionName}_${d.name || i}`;
        return (
            <Component
                {...d}
                formula={formula}
                onChange={onChange}
                sectionName={sectionName}
                values={getValues({
                    componentName: d.name,
                    formula,
                    sectionName,
                })}
                key={key}
            />
        );
    });

export {renderComponents};
