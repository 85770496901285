import {useState} from 'react';

import Banner from 'hsi/components/Banner';
import Button from 'hsi/components/Button';
import DialogContainer from 'hsi/containers/DataManagement/AddDataSourceDialog/DialogContainer';
import UploadInfoDialog from 'hsi/containers/DataManagement/AddDataSourceDialog/UploadInfoDialog';
import NonOwnedInstagramAccountInput from './NonOwnedInstagramAccountInput';
import UploadZone from 'hsi/components/UploadZone';

import useDataSourceCount from 'hsi/hooks/useDataSourceCount';
import {useInstagramAccounts} from 'hsi/hooks/useDataSource';
import useNonOwnedInput from 'hsi/containers/DataManagement/AddDataSourceDialog/NonOwnedInput/useNonOwnedInput';
import useStyles from './styles';

import {T} from 'hsi/i18n';
import tns from 'hsi/i18n/tns';

const t = tns('dataManagement.addDataSourcesDialog.addNonOwnedInstagramAccounts');

const getVerifyErrorFromCode = ({
    errorCode,
    failureReason,
}: {
    errorCode?: number;
    failureReason?: string;
}) => {
    console.log(failureReason);
    if (failureReason === "Account is already being tracked as 'non-owned'") {
        return 'dataManagement.addDataSourcesDialog.addNonOwnedInstagramAccounts.chipError.alreadyTracked';
    }

    switch (errorCode) {
        case 404:
        case 0:
            return 'dataManagement.addDataSourcesDialog.addNonOwnedInstagramAccounts.chipError.notFound';
        default:
            return 'dataManagement.addDataSourcesDialog.addNonOwnedInstagramAccounts.chipError.unknown';
    }
};

type AddNonOwnedInstagramAccountsProps = {
    info: ReturnType<typeof useDataSourceCount>;
    onBack: () => void;
    onClose: () => void;
};

const AddNonOwnedInstagramAccounts = ({
    info,
    onBack,
    onClose,
}: AddNonOwnedInstagramAccountsProps) => {
    const classes = useStyles();
    const maxLength = info?.sourcesRemaining;
    const instagramAccounts = useInstagramAccounts();

    const [isInfoOpen, setIsInfoOpen] = useState(false);

    const {
        acceptedFileTypes,
        atMaxLength,
        items,
        isLoading,
        onItemsChange,
        onSubmit,
        parseCsv,
        resetInput,
        parserError,
        submitError,
        validateItems,
    } = useNonOwnedInput({
        onAdd: (succeeded) => ({
            accounts: succeeded.map((item: any) => ({
                instagramBusinessAccoutId: item.id,
                igbaHandle: item.value,
                owned: false,
            })),
        }),
        dataType: instagramAccounts,
        getVerifyErrorFromCode,
        maxLength,
        onClose,
    });

    return (
        <>
            <DialogContainer
                actions={
                    <>
                        <Button
                            className={classes.backButton}
                            onClick={onBack}
                            priority="secondary"
                        >
                            {T('back')}
                        </Button>
                        <Button onClick={onClose} priority="primary">
                            {T('cancel')}
                        </Button>
                        <Button
                            disabled={!items.length || submitError || isLoading}
                            onClick={onSubmit}
                            priority="cta"
                        >
                            {t('addButton')}
                        </Button>
                    </>
                }
                onClose={onClose}
                title={t('title')}
            >
                <form className={classes.container} onSubmit={onSubmit}>
                    <UploadZone accept={acceptedFileTypes} parseData={parseCsv}>
                        <NonOwnedInstagramAccountInput
                            atMaxLength={atMaxLength}
                            error={submitError}
                            maxLength={maxLength}
                            onAccountsChange={onItemsChange}
                            openInfo={() => setIsInfoOpen(true)}
                            accounts={items}
                            parserError={parserError}
                            resetInput={resetInput}
                            validateAccounts={validateItems}
                        />
                    </UploadZone>
                    <Banner className={classes.infoBanner}>{t('infoBanner')}</Banner>
                </form>
            </DialogContainer>
            <UploadInfoDialog
                acceptText={t('info.cta')}
                invalidExamples={[
                    {columnA: t('info.invalidExample1a'), columnB: t('info.invalidExample1b')},
                ]}
                invalidTitle={t('info.invalidTitle')}
                isOpen={isInfoOpen}
                onAccept={() => setIsInfoOpen(false)}
                title={t('title')}
                validTitle={t('info.validTitle')}
                validExamples={[
                    t('info.validExample1'),
                    t('info.validExample2'),
                    t('info.validExample3'),
                ]}
            />
        </>
    );
};

export default AddNonOwnedInstagramAccounts;
