import {isEqual} from 'lodash';
import {DependencyList, EffectCallback, useEffect, useRef} from 'react';

function useDeepCompareMemoize(value: any) {
    const ref = useRef<any>();
    // it can be done by using useMemo as well
    // but useRef is rather cleaner and easier

    if (!isEqual(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
}

/**
 * This operates like reacts built in useEffect, except rather than comparing it's deps by reference, it compares by value
 *
 * @param callback - as useEffect
 * @param dependencies - as useEffect
 */
export default function useEffectDeepCompare(
    callback: EffectCallback,
    dependencies: DependencyList,
) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
