import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    root: {
        maxWidth: '336px',
        padding: '28px',
        textAlign: 'left',
    },
    title: {
        textAlign: 'left',
        padding: 0,
        margin: '0 0 8px',
        ...(theme.v2
            ? theme.typgrphy.headtitleStrong
            : {
                  fontSize: '20px',
                  fontWeight: theme.font.bold,
              }),
    },
    copy: {
        ...(theme.v2
            ? theme.typgrphy.bodyNormal
            : {
                  fontSize: '16px',
                  fontWeight: theme.font.normal,
              }),
    },
    link: {
        ...theme.mixin.linkColor,
        display: 'inline-flex',
        alignItems: 'center',
        gap: '4px',
        marginTop: '1em',
        textDecoration: 'none',
        ...(theme.v2
            ? theme.typgrphy.bodyStrong
            : {
                  fontSize: '16px',
                  fontWeight: theme.font.bold,
              }),
    },

    linkIcon: {
        position: 'relative',
        top: '1px',
        ...(theme.v2
            ? {
                  fill: 'currentColor',
              }
            : null),
    },

    //custom markdown styles
    p: {
        '& + &': {
            marginTop: '1em',
        },
    },
}));
