import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    content: {
        padding: '6px 9px',
        borderRadius: 3,
        color: 'white',
        fontSize: '13px',
        textAlign: 'left',
    },
    title: {
        fontSize: '11px',
        paddingBottom: 2,
        textTransform: 'capitalize',
    },
    value: {
        display: 'inline-flex',
        alignItems: 'flex-start',
    },
    colorSample: {
        flex: '0 0 8px',
        width: '8px',
        height: '8px',
        alignSelf: 'center',
        borderRadius: '50%',
        marginRight: '5px',
    },
    amount: {
        flex: '1 1 auto',
        fontSize: '13px',
        fontWeight: 'bold',
        marginRight: '4px',
    },
    unit: {
        flex: '1 1 auto',
        fontSize: '11px',
        alignSelf: 'flex-end',
        paddingBottom: '1px',
        textTransform: 'lowercase',
    },
}));
