import cn from 'classnames';
import {ReactNode} from 'react';

import DefaultCard from './DefaultCard';
import SelectableCard from './SelectableCard';

import useStyles from './styles';

interface CardListProps {
    children: (typeof DefaultCard)[] | (typeof SelectableCard)[] | ReactNode;
    className?: string;
}

const CardList = ({children, className}: CardListProps) => {
    const classes = useStyles();

    return <div className={cn(classes.cardWrapper, className)}>{children as ReactNode}</div>;
};

export default CardList;
