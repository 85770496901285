import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

//Components
import HistoryCard from 'hsi/components/Cards/HistoryCard';
import CardTitle from 'hsi/components/Card/CardTitle';
import InfoPopupContent from 'hsi/components/InfoPopupContent';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useDates from 'hsi/hooks/useDates';
import useGetLoadData from './useGetLoadData';

//Other
import {T} from 'hsi/i18n';

//Constants
const type = 'mentionsHistory';

//The components
const MentionsHistory = React.forwardRef(({title, ...props}, ref) => {
    const {
        links: {dashboardInfoMentionsHistoryCTA: popupCTA},
    } = useConfig();
    const {formatTo} = useDates();

    //Redux
    const {data, loaded, loading, error} = useSelector((state) => state.chart[type]);
    const aggregate = useSelector((state) => state.cardPersistState[type]?.aggregate);
    const breakdown = useSelector((state) => state.cardPersistState[type]?.breakdown);

    const loadData = useGetLoadData(type);

    const config = {
        drillInLabel: (item) =>
            formatTo(item?.date, breakdown === 'months' ? 'LLL yyyy ZZZZ' : 'DD ZZZZ'),
        filters: () => ({}),
    };

    const popup = useMemo(
        () => (
            <InfoPopupContent
                title={T(`cards.${type}.info.title`)}
                copy={T(`cards.${type}.info.copy`)}
                ctaUrl={popupCTA}
                ctaLabel={T('cards.infoCTALabel')}
            />
        ),
        [popupCTA],
    );

    return (
        <HistoryCard
            {...props}
            title={
                <CardTitle
                    title={title}
                    tooltipComponent={popup}
                    type={type}
                    hasData={!!data}
                />
            }
            breakdown={breakdown}
            aggregate={aggregate}
            config={config}
            data={data}
            error={error}
            loaded={loaded}
            loading={loading}
            trackingName={{
                // Historic tracking names, to be removed
                customized: 'Clicked to customise Volume by Mentions History',
                drillIn: 'Volume by Mentions History Drill In',
            }}
            type={type}
            loadData={loadData}
            ref={ref}
            data-testid={type}
        />
    );
});

MentionsHistory.propTypes = {
    props: PropTypes.shape({
        fadeOnVisible: PropTypes.func.isRequired,
        height: PropTypes.number.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onContentChanged: PropTypes.func.isRequired,
        showConfig: PropTypes.bool.isRequired,
    }),
};

MentionsHistory.displayName = 'MentionsHistory';

export default MentionsHistory;
