import {useMemo} from 'react';
import cn from 'classnames';

import Chip, {ChipProps} from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';

//Components
import Tooltip from 'hsi/components/Tooltip';
import OverflowTooltipHook from 'hsi/components/SimpleTooltip/OverflowTooltipHook';
import IconRouter from 'hsi/components/IconRouter';

//Other
import useStyles from './styles';

import {T} from 'hsi/i18n';

//Consts
const deleteIcon = <IconRouter name="e-remove" />;

//Types
export type ChipErrorType<TValue = string> = {
    label?: string;
    reason?: string;
    value: TValue;
};

export interface ChipWithErrorProps<TValue = string>
    extends Omit<ChipProps, 'classes' | 'deleteIcon'> {
    error?: ChipErrorType<TValue>;
}

//The component
export default function ChipWithError<TValue = string>({
    className,
    error,
    label,
    role = 'presentation',
    ...props
}: ChipWithErrorProps<TValue>) {
    const classes = useStyles();

    const labelContent = useMemo(
        () => (
            //Temp cast to any to fix type error with non-TS component
            <>
                <OverflowTooltipHook tooltip={label as any}>
                    <div className={classes.chipLabelInner}>{label}</div>
                </OverflowTooltipHook>
                {error && <Tooltip tooltip={error.label ? T(error.label) : T('unknownError')} noAria>
                    <IconRouter
                        aria-label={error.label ? T(error.label) : T('unknownError')}
                        className={classes.warningIcon}
                        name="warning"
                    />
                </Tooltip>}
            </>
        ),
        [label, classes.chipLabelInner, classes.warningIcon, error],
    );

    return(
        <Chip
            {...props}
            className={cn(className, classes.chip, error && classes.chipError)}
            classes={{label: classes.chipLabel}}
            data-testid="chip"
            deleteIcon={
                <IconButton
                    aria-label={`Delete ${label}`}
                    className={classes.deleteIcon}
                    role="button"
                    size="large">
                    {deleteIcon}
                </IconButton>
            }
            label={labelContent}
            role={role}
        />
    );
}
