import cn from 'classnames';
import {
    FocusEventHandler,
    KeyboardEventHandler,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import {Tab, TabPanel, Tabs, tabProps} from '../Tabs';
import Examples from './Examples';
import Header from './Header';
import Operators from './Operators';

import {Paper} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import classNames from 'classnames';
import {HeadingContents} from 'hsi/components/aria/Heading';
import {introAnchors} from 'hsi/containers/Intro';
import useTrackFocusWithin from 'hsi/hooks/useTrackFocusWithin';
import {T} from 'hsi/i18n';
import getFocusableElements from 'hsi/utils/html/getFocusableElements';
import useStyles from './styles';

const sections = [
    {labelId: 'queryHelpPanel.operators.heading', component: Operators},
    {labelId: 'queryHelpPanel.examples.heading', component: Examples},
];

type SearchHelpDrawerProps = {
    id?: string;
    open: boolean;
    autofocus?: boolean;
    onClose: () => void; //TODO this will always be remembered & persisted & focus will be returned to opening element?
    onSubmit: (value: string) => void;
};

type DrawerStatus = 'open' | 'closed' | 'opening' | 'closing';

export const defaultQueryHelpPanelId = 'queryHelpPanel';

export default function SearchHelpDrawer({open, ...rest}: SearchHelpDrawerProps) {
    const rootRef = useRef<HTMLDivElement | null>(null);
    const animationsRef = useRef<Animation[] | null>(null);
    const classes = useStyles();
    const [state, setState] = useState<DrawerStatus>(open ? 'open' : 'closed');

    const isVisible = state === 'open' || state === 'opening' || state === 'closing';
    const theme = useTheme();

    const cancelAnimation = useCallback(() => {
        //This seems to be a bug with eslint understanding the ?. operator
        //eslint-disable-next-line no-unused-expressions
        animationsRef.current?.forEach((animation) => animation.cancel());
        animationsRef.current = null;
    }, []);

    const doOpenAnimation = useCallback(() => {
        const elem = rootRef.current;

        if (!elem) {
            return;
        }

        setState('opening');

        const drawerAnimation = elem.animate(
            {
                marginRight: 0,
            },
            {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
                fill: 'forwards',
            },
        );

        drawerAnimation.onfinish = () => setState('open');
        animationsRef.current = [drawerAnimation];
    }, [setState, theme.transitions.duration.enteringScreen, theme.transitions.easing.sharp]);

    const doCloseAnimation = useCallback(() => {
        const elem = rootRef.current;

        if (!elem) {
            return;
        }

        setState('closing');

        const closedMargingRight = getComputedStyle(elem).getPropertyValue('--closedOffset');

        const drawerAnimation = elem.animate(
            {
                marginRight: closedMargingRight,
            },
            {
                easing: 'linear',
                duration: theme.transitions.duration.leavingScreen,
                fill: 'forwards',
            },
        );

        drawerAnimation.onfinish = () => setState('closed');
        animationsRef.current = [drawerAnimation];
    }, [theme.transitions.duration.leavingScreen, setState]);

    const doOpen = useCallback(() => {
        switch (state) {
            case 'open':
                return;
            case 'closing':
                cancelAnimation();
                doOpenAnimation();
                break;
            case 'closed':
                doOpenAnimation();
                break;
            case 'opening':
            default:
                return;
        }
    }, [state, cancelAnimation, doOpenAnimation]);

    const doClose = useCallback(() => {
        switch (state) {
            case 'open':
                doCloseAnimation();
                break;
            case 'closing':
                return;
            case 'closed':
                return;
            case 'opening':
                cancelAnimation();
                doCloseAnimation();
                break;
            default:
                return;
        }
    }, [state, cancelAnimation, doCloseAnimation]);

    useEffect(
        () => {
            if (state === null) {
                //on initial load, set state based on open value
                setState(open ? 'open' : 'closed');

                if (!open && rootRef.current) {
                    rootRef.current.style.marginRight = getComputedStyle(
                        rootRef.current,
                    ).getPropertyValue('--closedOffset');
                }
            } else {
                open ? doOpen() : doClose();
            }
        },
        //This warning is simply wrong, useEffect should not exhaustively list
        //all it's dependencies, just the ones that trigger side effects when they change
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [open],
    );

    useEffect(() => {
        open ? doOpen() : doClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <div ref={rootRef} className={classNames(classes.drawer, open && classes.open)}>
            {isVisible ? <SearchHelpDrawerContent {...rest} opened={state === 'open'} /> : null}
        </div>
    );
}

type SearchHelpDrawerContentProps = Omit<SearchHelpDrawerProps, 'open'> & {opened: boolean};

function SearchHelpDrawerContent({
    opened,
    onClose,
    onSubmit,
    autofocus = false,
    id = defaultQueryHelpPanelId,
}: SearchHelpDrawerContentProps) {
    const classes = useStyles();
    const openerElemRef = useRef<Element | null>(null);

    const [sectionIdx, setSectionIdx] = useState<number>(0);

    const onTabChange = (_e: React.ChangeEvent<{}>, value: number) => setSectionIdx(value);

    const onKeyDown: KeyboardEventHandler = useCallback(
        (e) => {
            if (e.code === 'Escape') {
                onClose();
            }
        },
        [onClose],
    );

    const onFocus: FocusEventHandler = useCallback((e) => {
        if (!openerElemRef.current) {
            //record where focus entered this component from, so we can return focus when the element closes
            openerElemRef.current = e.relatedTarget;
        }
    }, []);

    const {isFocusWithin, ...trackFocusProps} = useTrackFocusWithin({onFocus});

    //Keep isFocusWithin value in ref so it can be accessed in the unmount effect handler
    const isFocusWithinRef = useRef<boolean>(isFocusWithin);
    isFocusWithinRef.current = isFocusWithin;

    //When this component is first opened, if autofocus = true then move focus to the first focusable element
    useEffect(() => {
        if (opened && autofocus) {
            focusOnQueryHelpPanel(id);
        }
        // We just want to run this code once when the component mounts
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opened]);

    useEffect(() => {
        //When the component unmounts, this method will be called
        return () => {
            if (isFocusWithinRef.current) {
                //if focus is still inside this component, return focus to the opening element (if applicable)
                (openerElemRef.current as HTMLElement)?.focus?.();
            }
        };
    }, []);

    return (
        <Paper
            component="section"
            elevation={16}
            className={classes.content}
            aria-labelledby={`${id}.header.heading`}
            onKeyDown={onKeyDown}
            {...trackFocusProps}
        >
            <div className={classes.sticky} id={id}>
                <Header onClose={onClose} id={`${id}.header`} />
                <p className={classes.intro}>{T('queryHelpPanel.intro')}</p>
                <Tabs value={sectionIdx} onChange={onTabChange}>
                    {sections.map(({labelId}, idx) => (
                        <Tab label={T(labelId)} key={labelId} {...tabProps(id, idx)} />
                    ))}
                </Tabs>
            </div>
            <HeadingContents>
                <div className={cn(classes.scroll, introAnchors.quickSearchResults.operators)}>
                    {sections.map(({component: SectionComponent, labelId}, idx) => {
                        return (
                            <TabPanel tabId={id} value={sectionIdx} index={idx} key={labelId}>
                                <SectionComponent onSubmit={onSubmit} />
                            </TabPanel>
                        );
                    })}
                </div>
            </HeadingContents>
        </Paper>
    );
}

export function focusOnQueryHelpPanel(id: string = defaultQueryHelpPanelId) {
    const element = document.getElementById(id);

    if (element) {
        const focusableElements = getFocusableElements(element);

        focusableElements[0]?.focus();

        return true;
    }

    return false;
}
