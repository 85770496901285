import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);

    return {
        metricsList: {
            columns: 2,
            margin: 0,
            padding: 0,
        },

        metricsListItem: {
            display: 'flex',
            gap: '4px',
            breakInside: 'avoid',
            marginTop: v2 ? '10px' : '12px',

            '&:first-child': {
                marginTop: 0,
            },
        },
        metricsListTerm: {
            flex: '0 0 auto',
            margin: 0,
            padding: 0,
            ...(v2
                ? {
                      ...theme.fonts.bodyNormal,
                  }
                : {
                      fontSize: '16px',
                  }),
        },
        metricsListDefinition: {
            flex: '0 1 auto',
            minWidth: 0,
            margin: 0,
            padding: 0,
            display: 'flex',
            flexFlow: 'row wrap',
            ...(v2
                ? {
                      ...theme.fonts.bodyStrong,
                  }
                : {
                      fontSize: '16px',
                      fontWeight: theme.font.bold,
                  }),
        },

        addMenu: {
            ...theme.mixin.blankButton,
            ...theme.mixin.truncate,
            fontSize: 'inherit',
            fontWeight: 'inherit',
            color: 'inherit',
            position: 'relative',
            display: 'block',
            whiteSpace: 'normal',
            textAlign: 'left',
            paddingRight: '17px',

            '&[disabled]': {
                opacity: 0.5,
                cursor: 'default',
            },

            '&:after': v2
                ? {
                      ...theme.mixin.downArrow,
                      left: 'calc(100% - 15px)',
                      top: 'calc(50% - 3px)',
                  }
                : {},
            '&:hover:after': v2
                ? {}
                : {
                      ...theme.mixin.downArrow,
                      left: 'calc(100% - 15px)',
                      top: 'calc(50% - 3px)',
                  },
        },

        loader: {
            marginTop: v2 ? undefined : 5,
        },
    };
});
