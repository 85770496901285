import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    root: {
        marginLeft: theme.v2 ? 24 : 29,

        // '&>.MuiTextField-root': {
        //     width: '100%'
        // }
    },
}));
