import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) =>
    isV2(theme)
        ? {
              root: {
                  fontSize: '14px',
                  lineHeight: '20px',
              },
              isMax: {
                  color: theme.colors.primaryRed100,
              },
          }
        : {
              root: {
                  fontSize: '16px',
                  lineHeight: '20px',
              },
              isMax: {
                  color: theme.colors.primaryRed100,
              },
          },
);
