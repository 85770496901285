import React from 'react';
import {OverflowTooltip} from 'hsi/components/SimpleTooltip';

const CustomTick = ({payload, x, y, width, height, tickFormatter}) => {
    const content = tickFormatter(payload.value);

    return (
        <foreignObject cursor="pointer" height={height} width={width} x={x} y={y - height / 2}>
            <div
                style={{
                    //why are these styles inline?
                    height,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <OverflowTooltip tooltip={content}>
                    <div
                        style={{
                            //why are these styles inline?
                            color: 'gray',
                            fontSize: 12,
                            maxWidth: 80,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            alignItems: 'center',
                            wordBreak: 'break-word',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        }}
                    >
                        <span style={{width}}>{content}</span>
                    </div>
                </OverflowTooltip>
            </div>
        </foreignObject>
    );
};

export default CustomTick;
