import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    heatmapCard: {
        width: '100%',
        height: '100%',
        marginTop: '0',

        '& .row': {
            width: '100%',
            height: '40px',
            display: 'flex',
            margin: '0 0 1px',
            '@media print': {
                colorAdjust: 'exact',
                margin: '0 0 3px',
            },
            '.printMedia &': {
                colorAdjust: 'exact',
                margin: '0 0 3px',
            },

            '& label': {
                minWidth: '55px',
                maxWidth: '55px',
                height: '40px',
                textAlign: 'right',
                padding: '10px 15px 0 0',
                verticalAlign: 'middle',
                color: '#5d6466',
                fontSize: '12px',
            },

            '& .grid-item': {
                width: '20px',
                height: '40px',
                background: '#f0f2f2',
                flexGrow: '1',
                marginRight: '1px',
                '@media print': {
                    marginRight: '3px',
                },
                '.printMedia &': {
                    marginRight: '3px',
                },
            },
            '& .grid-item:hover': {
                cursor: 'pointer',
            },
        },

        '& .x-axis': {
            display: 'flex',
            marginLeft: '55px',

            '& label': {
                width: '10px',
                height: '40px',
                color: '#5d6466',
                fontSize: '12px',
                transform: 'rotate(-45deg)',
                margin: '10px 1px 0 0',
                flexGrow: 1,
                position: 'relative',
                left: '-4px',

                '&:nth-child(even)': {
                    visibility: 'hidden',
                },
            },
        },

        '& .legend': {
            padding: '25px 0 10px 55px',
            display: 'flex',

            '& .bucket-item': {
                flex: '0 0 20%',
                '& .bucket-color-sample': {
                    height: '10px',
                    borderLeft: '1px solid white',
                    '@media print': {
                        colorAdjust: 'exact',
                        borderLeft: '3px solid white',
                    },
                    '.printMedia &': {
                        colorAdjust: 'exact',
                        borderLeft: '3px solid white',
                    },
                },

                '& .bucket-label': {
                    fontSize: '12px',
                    color: '#5d6466',
                    textAlign: 'center',
                    height: '17px',
                    marginTop: '8px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',

                    '& .dash-sep': {
                        margin: '0 1px',
                    },
                },

                '&:last-child': {
                    '& .bucket-color-sample': {
                        borderRight: '1px solid white',
                    },
                },
            },
        },
    },
}));
