import axios from 'axios';

import {MAX_DATA_SOURCES} from 'hsi/constants/config';

const FACEBOOK_API = '/api/facebook';
const INSTAGRAM_API = '/api/instagram/v2';
const LINKEDIN_API = '/fe-api/linkedin';
const headers = {'Content-Type': 'application/json'};

export const facebookApi = axios.create({baseURL: FACEBOOK_API, headers});
export const instagramApi = axios.create({baseURL: INSTAGRAM_API, headers});
export const linkedinApi = axios.create({baseURL: LINKEDIN_API, headers});

/**
 * Current Facebook and Instagram API documentation:
 * https://brandwatch.atlassian.net/wiki/spaces/PROD/pages/2922057818/Facebook+and+Instagram+API+Endpoints
 *
 * BCR Facebook implementation:
 * https://github.com/BrandwatchLtd/jobsworth/blob/master/fe/src/fbApi.js
 *
 * BCR Instagram implementation:
 * https://github.com/BrandwatchLtd/jobsworth/blob/master/fe/src/igApi.js
 */

export const getFacebookPages = async ({
    order,
    orderBy,
    page,
    search,
    size,
}: {order?: string; orderBy?: string; page?: number; search?: string; size?: number} = {}) => {
    const params = {
        enabled: true,
        page,
        search: search?.length ? search : undefined,
        size: size || MAX_DATA_SOURCES,
        // Boolean field sorts have a bug so we add in a text field to the sort to resolve the issue. It would
        // be the data teams responsibility to fix
        sort: order && orderBy ? `${orderBy},name,${order}` : undefined,
    };
    const {data} = await facebookApi.get('/trackedpages', {params});
    return data;
};

export const addFacebookPages = async (pages: {pageId: string; owned: boolean}[]) => {
    const {data} = await facebookApi.post('/trackedpages', pages);
    return data;
};

/**
 * This currently only handles one delete at a time but could easily be changed
 * to handle multiple deletes if we were to add that capability to the UI
 */
export const deleteFacebookPage = async (pageId: string, owned: boolean) => {
    const {data} = await facebookApi.patch('/trackedpages', [{pageId, owned, enabled: false}]);
    return data;
};

/**
 * This is used to check if Facebook pages exist at the minute but could also be used
 * for suggestions.
 */
export const findFacebookPages = async (pageNames: string[]) => {
    const {data} = await facebookApi.post('/pages/discovery', pageNames);
    return data;
};

export const getInstagramAccounts = async ({
    order,
    orderBy,
    page,
    search,
    size,
    fetchGraphHashtagCount,
    owned,
    authenticated,
}: {
    order?: string;
    orderBy?: string;
    page?: number;
    search?: string;
    size?: number;
    fetchGraphHashtagCount?: boolean;
    owned?: boolean;
    authenticated?: boolean;
} = {}) => {
    const params = {
        enabled: true,
        page,
        search: search?.length ? search : undefined,
        size: size || MAX_DATA_SOURCES,
        sort: order && orderBy ? `${orderBy},${order}` : undefined,
        fetchGraphHashtagCount,
        owned,
        authenticated,
    };
    const {data} = await instagramApi.get('/trackedaccounts', {params});
    return data;
};

export const deleteInstagramAccount = async (
    instagramBusinessAccountId: string,
    owned: boolean,
) => {
    const {data} = await instagramApi.patch('/trackedaccounts', [
        {instagramBusinessAccountId, owned, enabled: false},
    ]);
    return data;
};

export const addInstagramAccounts = async (
    accounts: {
        instagramBusinessAccountId: string;
        name: string;
        owned: boolean;
    }[],
) => {
    const {data} = await instagramApi.post('/trackedaccounts', accounts);
    return data;
};

export const verifyInstagramAccounts = async (handles: string[]) => {
    const {data} = await instagramApi.post('/trackedaccounts/verify/handles', handles);
    return data;
};

export const getInstagramHashtags = async ({
    order,
    orderBy,
    page,
    search,
    size,
}: {order?: string; orderBy?: string; page?: number; search?: string; size?: number} = {}) => {
    const params = {
        enabled: true,
        page,
        search: search?.length ? search : undefined,
        size: size || MAX_DATA_SOURCES,
        sort: order && orderBy ? `${orderBy},hashtag,${order}` : undefined,
    };
    const {data} = await instagramApi.get('/hashtags', {params});
    return data;
};

export const addInstagramHashtags = async (
    instagramBusinessAccountId: string,
    hashtags: string[],
) => {
    const {data} = await instagramApi.post('/hashtags', {
        instagramBusinessAccountId,
        hashtags,
    });
    return data;
};

export const deleteInstagramHashtag = async (
    instagramBusinessAccountId: string,
    hashtag: string,
) => {
    const params = {
        hashtag, // TODO: Check this shouldn't be hashtagssss with an s
        instagramBusinessAccountId,
    };
    const {data} = await instagramApi.patch('/hashtags', null, {params});
    return data;
};

// TODO: Maybe remove
export const getFacebookPageAuthentications = async () => {
    const params = {
        enabled: true,
        size: MAX_DATA_SOURCES,
    };
    const {data} = await facebookApi.get('/authentications/pages', {params});
    return data;
};

type GetFacebookUserAuthenticationsArgs = {
    authenticated?: boolean;
    enabled?: boolean;
    order?: string;
    orderBy?: string;
    page?: number;
    search?: string;
    size?: number;
};

export const getFacebookUserAuthentications = async ({
    authenticated = undefined,
    enabled = true,
    order,
    orderBy,
    page,
    search,
    size,
}: GetFacebookUserAuthenticationsArgs = {}) => {
    const params = {
        authenticated,
        enabled,
        page,
        search: search?.length ? search : undefined,
        size: size || MAX_DATA_SOURCES,
        sort: order && orderBy ? `${orderBy},facebookUserName,${order}` : undefined,
    };
    // This is on the Instagram API as it was just easier for the data team to add it there
    const {data} = await instagramApi.get('/authentications/users', {params});
    return data;
};

export const deleteFacebookUserAuthentication = async (fbUserId: string) => {
    // This is on the Instagram API as it was just easier for the data team to add it there
    const {data} = await instagramApi.delete('/authentications/users', {data: [fbUserId]});
    return data;
};

export const getInstagramAuthentications = async () => {
    const params = {
        enabled: true,
        size: MAX_DATA_SOURCES,
    };
    const {data} = await instagramApi.get('/authentications', {params});
    return data;
};

export const addFacebookAuthentications = async (facebookAppId: string, userToken: string) => {
    // This is on the Instagram API as it was just easier for the data team to add it there
    const {data} = await instagramApi.post('/authentications', {facebookAppId, userToken});
    return data;
};

export const getLinkedinAuthentications = async () => {
    const {data} = await linkedinApi.get('/authentications');
    return data;
};

export const getLinkedinOrgDetails = async (org: string) => {
    const params = {org};
    const {data} = await linkedinApi.get('/org-details', {params});
    return data;
};

export const createLinkedinChannel = async ({
    org,
    channelName,
    projectId,
}: {
    org: string;
    channelName: string;
    projectId: string;
}) => {
    const {data} = await linkedinApi.post('/channel', {org, channelName, projectId});
    return data;
};

export const getLinkedinChannel = async ({
    channelId,
    projectId,
}: {
    channelId: string;
    projectId: string;
}) => {
    const params = {channelId, projectId};
    const {data} = await linkedinApi.get('/channel', {params});
    return data;
};

export const deleteLinkedinChannel = async ({
    channelId,
    projectId,
}: {
    channelId: string;
    projectId: string;
}) => {
    const {data} = await linkedinApi.delete('/channel', {data: {channelId, projectId}});
    return data;
};

export const getLinkedinChannels = async () => {
    const {data} = await linkedinApi.get('/channels');
    return data;
};

export const getTotalCounts = async () => {
    const params = {
        page: 0,
        size: 1,
    };
    const response = await Promise.all([
        getFacebookPages(params),
        getInstagramAccounts(params),
        getInstagramHashtags(params),
        getLinkedinChannels(),
        getFacebookUserAuthentications({...params, authenticated: true}),
    ]);

    const facebookPageCount = response?.[0]?.page?.totalElements || 0;
    const instagramAccountCount = response?.[1]?.page?.totalElements || 0;
    const instagramHashtagCount = response?.[2]?.page?.totalElements || 0;
    const linkedinChannelCount = response?.[3]?.length || 0;
    const facebookUserAuthenticationsCount = response?.[4]?.page?.totalElements || 0;

    return {
        facebookPageCount,
        facebookUserAuthenticationsCount,
        instagramAccountCount,
        instagramHashtagCount,
        linkedinChannelCount,
        total:
            facebookPageCount +
            instagramAccountCount +
            instagramHashtagCount +
            linkedinChannelCount,
    };
};
