import {makeStyles} from 'tss-react/mui';
import merge from 'lodash/merge';

//Styles required to make the component work, but not how it is styled
export const structuralStyles = (classes) => ({
    table: {
        //Vars
        '--sortableTable-menuColWidth': '90px',

        //Styles
        display: 'grid',
        overflow: 'hidden',
    },
    tableHeader: {
        display: 'contents',
    },
    tableHeaderRow: {
        display: 'contents',
    },
    headerCol: {
        display: 'flex',
        whiteSpace: 'nowrap',
    },
    headerClickable: {
        cursor: 'pointer',
    },
    headerContent: {
        display: 'flex',
        flexFlow: 'row nowrap',
    },
    headerContentWrapper: {
        display: 'block',
    },
    arrows: {
        display: 'flex',
        flexFlow: 'column nowrap',
    },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        [`&.${classes.headerContent}`]: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },

    tableBody: {
        display: 'contents',
    },

    row: {
        display: 'contents',
    },

    col: {
        display: 'flex',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },

    colTxt: {
        display: 'block',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },

    menuCol: {},

    noItemsCell: {
        gridColumnStart: 1,
        gridColumnEnd: 'calc(var(--sortableTable-numColumns) + 1)',
    },
    clickableRow: {
        cursor: 'pointer !important',
    },
});

export default makeStyles()((theme, _params, classes) => {
    return merge({}, structuralStyles(classes), {
        table: {
            //Vars
            '--sortableTable-menuColWidth': '90px',

            //Styles
            borderRadius: theme.v2 ? '3px' : null,
            boxShadow: theme.v2
                ? null
                : '0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 0 0 0 rgba(0, 0, 0, 0.08)',
        },
        tableHeader: {},
        tableHeaderRow: {},
        headerCol: {
            background: theme.v2 ? theme.colors.white : theme.colors.lightGrey60,
            height: theme.v2 ? '40px' : '48px',
            borderBottom: theme.v2 ? `1px solid ${theme.colors.borders}` : null,

            ...(theme.v2
                ? theme.fonts.bodyStrong
                : {
                      fontSize: '16px',
                      fontWeight: theme.font.bold,
                  }),
            textTransform: theme.v2 ? null : 'uppercase',
            color: theme.v2 ? theme.colors.textDefault : theme.colors.darkGrey100,
            letterSpacing: '0.04px',
            padding: '0 16px',
            margin: '0 0 0 0',
            textAlign: 'left',
        },
        actions: {},
        headerColActions: {
            justifyContent: 'center',
        },
        headerContent: {
            alignItems: 'center',
            gap: '8px',
            minWidth: 0,

            [`&.${classes.sortable}`]: {
                ...theme.mixin.blankButton,
            },
        },
        headerContentWrapper: {
            flex: '0 1 auto',
            margin: 0,
        },
        arrows: {
            flex: '0 0 auto',
        },
        arrow: {
            width: '18px',
            height: '18px',
            margin: '-7px',
            fill: theme.v2 ? theme.colors.bgOverlayHeavy : 'rgba(69, 75, 83, 0.2)',
            display: theme.v2 ? 'none' : null,

            [`&.${classes.sorting}`]: {
                fill: theme.v2 ? theme.colors.bgOverlayHeavy : 'rgba(69, 75, 83, 1)',
                display: 'block',
            },
        },
        down: {},
        sorting: {},
        sortable: {},
        ellipsis: {},

        tableBody: {},

        row: {
            [`&:hover > .${classes.col}`]: {
                backgroundColor: theme.v2 ? null : theme.colors.primaryYellow10,
            },

            [`&:last-child > .${classes.col}`]: {
                border: theme.v2 ? 'none' : null,
            },

            [`&:nth-of-type(even) > .${classes.col}`]: {
                background: theme.v2 ? null : theme.colors.lightGrey20,
            },

            [`&:nth-of-type(even):hover > .${classes.col}`]: {
                backgroundColor: theme.v2 ? null : theme.colors.primaryYellow10,
            },
        },

        col: {
            ...(theme.v2
                ? theme.fonts.bodyNormal
                : {
                      fontSize: '16px',
                      fontWeight: theme.font.regular,
                  }),
            borderBottom: theme.v2
                ? `1px dotted ${theme.colors.borders}`
                : '1px solid rgba(0, 0, 0, 0.16)',

            alignItems: 'center',
            height: theme.v2 ? '41px' : '48px',
            padding: '0 16px',
            alignSelf: 'center',

            background: theme.v2 ? theme.colors.white : theme.colors.lightGrey10,

            [`&.${classes.sorting}`]: {
                ...(theme.v2
                    ? theme.fonts.bodyNormal
                    : {
                          fontWeight: theme.font.semiBold,
                      }),
            },

            '& a': {
                textDecoration: 'none',
                ...theme.mixin.linkColor,
                fontWeight: 700,
            },
        },

        menuCol: {
            justifyContent: 'center',
            '& .MuiIconButton-root': {
                padding: '4px',
            },
        },

        noItemsCell: {
            justifyContent: 'center',
        },

        actionIconButton: {
            opacity: 0,
            '&.MuiIconButton-root': {
                padding: theme.v2 ? 6 : 10,
            },
            '&:hover': {
                backgroundColor: theme.colors.uiGrayDark,
                opacity: 'revert',
            },
        },
        actionIcon: {
            fill: theme.colors.accentDefault,
            height: theme.v2 ? 18 : 14,
            width: theme.v2 ? 18 : 14,
        },
    });
});
