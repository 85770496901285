import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles((theme: Theme) => {
    return {
        card: {
            margin: isV2(theme) ? '10px' : '2px',
        },
    };
});
