import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles(({colors, v2}: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: '0px 20px',
    },
    statusContainer: {
        border: v2 ? `1px solid ${colors.borders}` : `1px solid ${colors.darkGrey60}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 240,
        width: '100%',
    },
    backButton: {
        marginRight: 'auto',
    },
    infoBanner: {
        marginTop: 24,
        marginBottom: 8,
    },
}));
