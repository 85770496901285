import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    tooltip: {
        padding: '0 !important',
        pointerEvents: 'none',
    },

    anchor: {
        pointerEvents: 'none',
    },
}));
