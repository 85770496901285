import classNames from "classnames";
import { polymorphicForwardRef } from "hsi/types/react-polymorphic";
import OverflowTooltip, {OverflowTooltipProps} from "../Tooltip/OverflowTooltip";
import { useMemo } from "react";

type TruncateWithTooltipProps = {
    className?: string;
    portal?: boolean;
    tooltip?: OverflowTooltipProps['tooltip'];
    tooltipProps?: Omit<OverflowTooltipProps, 'children' | 'portal' | 'tooltip'>;
};

const TruncateWithTooltip = polymorphicForwardRef<TruncateWithTooltipProps, 'div'>(function TruncateWithTooltip({children, as: Component = 'div', portal, tooltip, tooltipProps, className, ...rest}, ref) {
    const tooltipPropsPlusContent = useMemo(() => ({
        ...tooltipProps,
        portal,
        tooltip: tooltip ?? <Component className={className} {...rest}>{children}</Component>
    }), [Component, children, className, portal, rest, tooltip, tooltipProps]);

    return <OverflowTooltip {...tooltipPropsPlusContent}>
        <Component className={classNames(className, 'truncate')} ref={ref} {...rest}>
            {children}
        </Component>
    </OverflowTooltip>;
});

export default TruncateWithTooltip;