import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';

import resultService from 'hsi/services/resultService';
import {initDateRange} from 'hsi/slices/filters';
import {selectAdditionalQueries, selectLinkedinChannelIds} from 'hsi/selectors';

import processCardRules from 'hsi/utils/cards/processCardRules';
import {updateCardsInnerState} from 'hsi/slices/cardPersistState';
import getConfig from 'hsi/config';
import getCardTypeLimit from 'hsi/utils/cards/getCardTypeLimit';

import ChartsWorker from 'hsi/workers/Charts';

import {
    CLEAR_RESULTS,
    DATA_LOADED,
    DATA_ABORTED,
    LOAD_ERROR,
    LOADING_DATA,
    PEAKS_LOADED,
    TOGGLE_CONFIG,
} from 'hsi/constants/actionTypes';
import {filterStateToAPIFormat} from 'hsi/utils/filters';

import {persistActiveQuery} from 'hsi/actions/queryActions';

/**
 * 
 * @param {*} type 
 * @param {*} isBreakdownChange 
 * @param {*} flags 
 * @param {*} pageTypes 
 * @param {{limit?: number}} [options]
 * @returns 
 */
export const loadData = (type, isBreakdownChange = false, flags, pageTypes, options) => {
    if (!flags || !pageTypes) {
        debugger;
    }

    return async (dispatch, getState) => {
        const state = getState();
        let {
            query: {context: queryContext},
            filters: {dateRange, allFiltersConfig, filters},
            cardPersistState,
            queryUserData,
        } = state;
        const additionalQueries = selectAdditionalQueries(state);
        const linkedinChannelIds = selectLinkedinChannelIds(state);

        dispatch({
            type: LOADING_DATA,
            payload: {type, isBreakdownChange},
        });

        if (dateRange.startDate === '' && dateRange.endDate === '') {
            await dispatch(initDateRange(dateRange?.timezone || queryContext.timezone));

            dateRange = getState().filters.dateRange;
        }

        if (['toptopicsBySearch', 'wordCloud'].includes(type)) {
            const {orderBy, types} = cardPersistState[type];

            queryContext = {
                ...queryContext,
                additionalParams: {
                    extract: types.map((t) => t.toLowerCase()),
                    orderBy: orderBy,
                },
            };
        }

        if (['totalVolume', 'totalVolumeBySearch'].includes(type)) {
            const sideDrawer = cardPersistState[type];

            queryContext = {
                ...queryContext,
                additionalParams: {
                    metrics: compact(Object.values(sideDrawer)).map(({id}) => id),
                },
            };
        }

        const {themeColors} = getConfig();

        //Load & format data
        try {
            const data = await ChartsWorker.loadData(
                queryContext,
                type,
                filterStateToAPIFormat(filters, allFiltersConfig),
                dateRange,
                additionalQueries,
                cardPersistState,
                queryUserData[queryContext.savedSearchId]?.cardTableSort?.[type]?.sortColumn,
                options?.limit ?? undefined,
                linkedinChannelIds,
                processCardRules(type, queryContext, flags),
                {
                    themeColors,
                    pageTypes: pageTypes.map(({isAvailable, ...rest}) => rest),
                },
                flags,
            );

            dispatch({
                type: DATA_LOADED,
                payload: {
                    breakdown: cardPersistState?.[type]?.breakdown,
                    data: data,
                    isBreakdownChange: false,
                    type,
                },
            });
        } catch (e) {
            //we can suppress abort errors because they are the result of user actions
            if (e.name !== 'AbortError') {
                dispatch({
                    type: LOAD_ERROR,
                    payload: {
                        errorCode:
                            e.body && Array.isArray(e.body.errors) && e.body.errors.length > 0
                                ? e.body.errors[0].error_code
                                : null,
                        type,
                    },
                });
            }
        }
    };
};

export const loadAllSearchResults = (flags, pageTypes) => {
    if (!flags || !pageTypes) {
        debugger;
    }

    return async (dispatch, getState) => {
        const charts = getState().charts;
        let dataToDownload = [];

        Object.keys(charts).forEach((cardName) => {
            if (isEmpty(charts[cardName].data)) dataToDownload.push(cardName);
        });

        await Promise.all(
            dataToDownload.map((cardName) =>
                dispatch(loadData(cardName, undefined, flags, pageTypes, {limit: getCardTypeLimit(cardName, true).limit})),
            ),
        );
    };
};

export const abortSearchResults = (type) => (dispatch) => {
    dispatch({
        type: DATA_ABORTED,
        payload: {type},
    });
    return resultService.abortType(type);
};

export const skipLoadData = (type) => (dispatch) => {
    dispatch({
        type: DATA_LOADED,
        payload: {data: null, type},
    });
};

/**
 * 
 * @param {*} type 
 * @param {*} flags 
 * @param {*} pageTypes 
 * @param {{limit?: number}} [options] 
 * @returns 
 */
export const loadSearchResults = (type, flags, pageTypes, options) => {
    if (!flags || !pageTypes) {
        debugger;
    }
    
    return (dispatch, getState) => {
        const queryContext = getState().query.context;
        const rules = processCardRules(type, queryContext, flags);

        if (!rules || !rules.requires) {
            dispatch(skipLoadData(type));
            return;
        }

        // Load data for this card type //
        dispatch(loadData(type, true, flags, pageTypes, options));

        if (rules.peaks) {
            dispatch(detectPeaks(type));
        }
    };
};

export const clearResults = () => ({type: CLEAR_RESULTS});

export const toggleConfig = (type) => (dispatch, getState) => {
    const {sideDrawer} = getState().results;
    if (type) {
        if (sideDrawer.type === type)
            dispatch({
                type: TOGGLE_CONFIG,
                payload: {open: !sideDrawer.open, type},
            });
        else
            dispatch({
                type: TOGGLE_CONFIG,
                payload: {open: true, type},
            });
    } else {
        dispatch({
            type: TOGGLE_CONFIG,
            payload: {open: !sideDrawer.open},
        });
    }
};

//TODO needs flags and pageTypes
export const reloadWordCloud = (payload, type, flags, pageTypes, limit) => (dispatch, getState) => {
    if (!flags || !pageTypes) {
        debugger;
    }
    dispatch(updateCardsInnerState({type: 'wordCloud', value: payload}));

    if (type === 'orderBy' || type === 'types') {
        dispatch(loadData('wordCloud', undefined, flags, pageTypes, {limit}));
    }
    flags?.hasCardOptionsPersistance && dispatch(persistActiveQuery());
};

//TODO needs flags and pageTypes
export const reloadTotalVolume = (payload, flags, pageTypes) => (dispatch, getState) => {
    if (!flags || !pageTypes) {
        debugger;
    }
    dispatch(updateCardsInnerState({type: 'totalVolume', value: payload}));
    dispatch(loadData('totalVolume', false, flags, pageTypes));
    flags?.hasCardOptionsPersistance && dispatch(persistActiveQuery());
};

export const detectPeaks = (type) => async (dispatch, getState) => {
    const state = getState();
    const {context: queryContext} = state.query;
    const additionalQueries = selectAdditionalQueries(state);
    const linkedinChannelIds = selectLinkedinChannelIds(state);

    const {dateRange} = state.filters;

    if (dateRange.startDate === '' && dateRange.endDate === '') {
        await dispatch(initDateRange(dateRange?.timezone || queryContext.timezone));
    }

    const {cardPersistState, filters} = getState();

    dispatch({
        type: TOGGLE_CONFIG,
        payload: false,
    });

    return resultService
        .detectPeaks({
            queryContext,
            type,
            filters,
            additionalQueries,
            linkedinChannelIds,
            cardPersistState,
        })
        .then((data) => {
            if (data.length) {
                dispatch({
                    type: PEAKS_LOADED,
                    payload: {type, peaks: data},
                });
            }
        })
        .catch((e) => {
            console.log(e);
        });
};

