import {DurationLike, DateTime} from 'luxon';

//Date/time string types

type TYear = `${number}${number}${number}${number}`;
type TMonth = `${number}${number}`;
type TDay = `${number}${number}`;
type THours = `${number}${number}`;
type TMinutes = `${number}${number}`;
type TSeconds = `${number}${number}`;
type TMilliseconds = `${number}${number}${number}`;

/**
 * Represent a string like `2021-01-08`
 */
export type TDateISODate = `${TYear}-${TMonth}-${TDay}`;

/**
 * Represent a string like `14:42:34.678`
 */
type TDateISOTime = `${THours}:${TMinutes}:${TSeconds}.${TMilliseconds}`;

/**
 * Represent a string like `2021-01-08T14:42:34.678Z` (format: ISO 8601).
 *
 * It is not possible to type more precisely (list every possible values for months, hours etc) as
 * it would result in a warning from TypeScript:
 *   "Expression produces a union type that is too complex to represent. ts(2590)
 */
export type TDateISO = `${TDateISODate}T${TDateISOTime}Z`;

export type InputDateType = string | TDateISO | TDateISODate | Date | DateTime | number;

export interface RangeDefinition {
    id: string;
    label: string;
    start: DurationLike;
    end: DurationLike;
}

//Type guards
const TDateISORegex =
    /^[0-9]{4}-[01][0-9]-[0-3][0-9]T[0-2][0-9]:[0-5][0-9]:[0-5][0-9](\.[0-9]{1,3})?Z$/;

export function isTDateISO(date: InputDateType): date is TDateISO {
    return typeof date === 'string' && TDateISORegex.test(date);
}

const TDateISODateRegex = /^[0-9]{4}-[01][0-9]-[0-3][0-9]$/;

export function isTDateISODate(date: InputDateType): date is TDateISODate;
export function isTDateISODate(date: string): date is TDateISODate;

export function isTDateISODate(date: any): date is TDateISODate {
    return typeof date === 'string' && TDateISODateRegex.test(date);
}

export function isDateTime(date: InputDateType): date is DateTime {
    return date instanceof DateTime;
}

export function isDate(date: InputDateType): date is Date {
    return date instanceof Date;
}
