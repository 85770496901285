import { useCallback, useEffect, useMemo, useState } from "react";

import { focusOnQueryHelpPanel } from '../QueryBuilder/components/SearchHelpDrawer';

//Consts
const localStorageId = 'advancedSearchHelp';
const localStorageDisabledValue = 'disabled';

export default function useQueryBuilderState(setIsQueryBuilderOpen: (isOpen: boolean) => void, setIsQueryHelpPanelOpen: (isOpen: boolean) => void) {
    const [autofocusHelpPanel, setAutofocusHelpPanel] = useState(false);
    const [openHelpPanelWithQueryBuilder, setOpenHelpPanelWithQueryBuilder] = useState(localStorage.getItem(localStorageId) !== localStorageDisabledValue);

    const setQueryBuilderOpen = useCallback((isOpen:boolean) => {
        setIsQueryBuilderOpen(isOpen);

        setAutofocusHelpPanel(false);

        if(isOpen) {
            if(openHelpPanelWithQueryBuilder) {
                setIsQueryHelpPanelOpen(true);
            }
            
        } else {
            setIsQueryHelpPanelOpen(false);
        }
    }, [openHelpPanelWithQueryBuilder, setIsQueryBuilderOpen, setIsQueryHelpPanelOpen]);

    const setHelpOpen = useCallback((isOpen: boolean, fromUserAction: boolean = false) => {
        setIsQueryHelpPanelOpen(isOpen);

        if(isOpen) {
            focusOnQueryHelpPanel();
            setAutofocusHelpPanel(true);
        } else {
            setAutofocusHelpPanel(false);
        }

        if(fromUserAction) {
            setOpenHelpPanelWithQueryBuilder(isOpen);
        }
    }, [setIsQueryHelpPanelOpen]);

    useEffect(() => {
        localStorage.setItem(localStorageId, openHelpPanelWithQueryBuilder ? "" : localStorageDisabledValue);
    }, [openHelpPanelWithQueryBuilder]);

    return useMemo(() => ({
        autofocusHelpPanel,
        setQueryBuilderOpen,
        setHelpOpen
    }), [autofocusHelpPanel, setHelpOpen, setQueryBuilderOpen]);
}