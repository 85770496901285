import React, {useEffect, useState, useMemo} from 'react';
import find from 'lodash/find';
import {useDispatch, useSelector} from 'react-redux';

//Components
import Button from '../Button';
import {Dialog, DialogContent, DialogActions} from 'hsi/components/Dialog';
import FormError from 'hsi/components/FormError';
import PulseLoader from 'hsi/components/PulseLoader';
import TextField from 'hsi/components/TextField';
import SelectField from 'hsi/components/LegacySelectField';
import CheckboxList from 'hsi/components/CheckboxList';
import HighlightedText from 'hsi/components/QueryEditor/HighlightedText';

import {
    clearError,
    openSaveSearchDialog,
    saveSearch,
    updateSearch,
    openEditSearch,
} from 'hsi/actions/savedSearchActions';
import {setLinkedinChannelIds} from 'hsi/slices/query';
import {getHistoricDataStartDate} from 'hsi/utils';

//Hooks
import useEventTrack from 'hsi/hooks/useEventTrack';
import useRefCallback from 'hsi/hooks/useRefCallback';
import {useLinkedinChannels} from 'hsi/hooks/useDataSource';
import useLinkedinIsEnabled from 'hsi/hooks/useLinkedinIsEnabled';
import useSearchApiError from 'hsi/hooks/useSearchApiError';

//Other
import useStyles from './styles';
import {T} from 'hsi/i18n';

//Constants
const DESC_LIMIT = 500;
const NAME_LIMIT = 1024;

//The component
export default function SaveSearchDialog() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {track, trackWithSearchData} = useEventTrack();

    const {
        saving,
        openSaveDialog: isOpen,
        editQueryId: queryId,
    } = useSelector((state) => state.search);
    const isEditing = !!queryId;

    const {projects = []} = useSelector((state) => state.user);
    const BLANK_OBJ = {};
    const searchObj =
        useSelector(
            isEditing
                ? (state) => state.search.searches.find((search) => search.id === queryId)
                : (state) => state.query,
        ) || BLANK_OBJ;
    const isGuidedSearch = useSelector((state) => state?.query?.isGuided);

    //extract values from search object
    const {booleanQuery: query, name: savedName, description: savedDescription} = searchObj;

    const apiError = useSearchApiError();

    const defaultTeamId = useMemo(
        () =>
            find(projects, ({id}) => id === Number(localStorage.getItem('lastSelectedTeam')))?.id ||
            projects[0]?.id,
        [projects],
    );

    //State
    const [description, setDescription] = useState('');
    const [name, setName] = useState('');
    const [teamId, setTeamId] = useState(defaultTeamId);
    const [error, setError] = useState(null);

    const isLinkedinEnabled = useLinkedinIsEnabled();
    const linkedinChannels = useLinkedinChannels();
    const currentProjectLinkedinChannels = linkedinChannels.getActiveByProject(String(teamId));
    const [selectedLinkedinChannels, setSelectedLinkedinChannels] = useState(
        isEditing ? currentProjectLinkedinChannels : [],
    );

    const linkedinChannelIds = useMemo(
        () => selectedLinkedinChannels.map(({id}) => id),
        [selectedLinkedinChannels],
    );

    const getStartDate = useRefCallback((teamId) =>
        getHistoricDataStartDate({
            timezone: projects.find((p) => p.id === teamId)?.timezone ?? 'UTC',
        }),
    );

    const search = useMemo(
        () =>
            isEditing
                ? {
                      ...searchObj,
                      name,
                      description,
                  }
                : {
                      query,
                      name,
                      teamId,
                      description,
                      startDate: getStartDate(teamId),
                  },
        [description, getStartDate, isEditing, name, query, searchObj, teamId],
    );

    //Callbacks
    const updateName = useRefCallback((e) => {
        setName(e.target.value);
        setError(null);
    });

    const selectTeam = useRefCallback(({value}) => {
        setTeamId(value);
        setError(null);
    });

    const updateDesc = useRefCallback((e) => {
        if (e.target.value.length > DESC_LIMIT) {
            return false;
        }

        setDescription(e.target.value);
        setError(null);
    });

    const validateAndSaveSearch = useRefCallback(() => {
        if (name.trim() === '') {
            setError('name');
            return false;
        }

        if (name.trim().length > NAME_LIMIT) {
            setError('nameLength');
            return false;
        }

        if (!teamId || teamId === null || !find(projects, ({id}) => id === teamId)?.id) {
            setError('team');
            return false;
        }

        // Store team locally once confirm its valid
        localStorage.setItem('lastSelectedTeam', teamId);

        dispatch(setLinkedinChannelIds(linkedinChannelIds));

        //edit or save
        if (isEditing) {
            dispatch(updateSearch(search));
            trackWithSearchData('editSearchDialogSearchEdited', {
                search,
            });
        } else {
            // This matches the searchData but don't get the updated payload
            // until after this is called. We need to keep this in sync with
            // the search payload, sorry.
            dispatch(
                saveSearch(search, (savedSearch) => {
                    track('saveSearchDialogSearchCreated', {
                        isGuidedSearch,
                        search,
                        searchId: savedSearch.id,
                        selectedLinkedinChannels: selectedLinkedinChannels,
                    });
                }),
            );
        }
    });

    const close = useRefCallback((skipTracking) => {
        dispatch(openSaveSearchDialog(false));

        if (skipTracking !== true) {
            if (isEditing) {
                trackWithSearchData('editSearchDialogToggled', {
                    search,
                    state: 'closed',
                });
            } else {
                trackWithSearchData('saveSearchDialogToggled', {
                    search,
                    state: 'closed',
                });
            }
        }
    });

    const edit = useRefCallback(() => {
        if (isEditing) {
            trackWithSearchData('editSearchDialogEditButtonClicked', {
                search,
            });
            dispatch(openEditSearch(searchObj));
        }

        close(true);
    });

    //Side effects
    useEffect(() => {
        //init fields on open
        if (isOpen && !queryId) {
            setName('');
            setTeamId(defaultTeamId);
            setDescription('');
            setError(null);
            dispatch(clearError());
        }

        if (isOpen) {
            if (isEditing) {
                trackWithSearchData('editSearchDialogToggled', {
                    search,
                    state: 'open',
                });
            } else {
                trackWithSearchData('saveSearchDialogToggled', {
                    search,
                    state: 'open',
                });
            }
        }
        // Check if deps can be added without issue
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(
        () => {
            if (queryId) {
                //init values based on query that is being edited
                setName(savedName);
                setDescription(savedDescription || '');
                setTeamId(searchObj.project?.id);
            }
        },
        // Check if deps can be added without issue
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [queryId],
    );

    useEffect(() => {
        //clear errors when values change
        setError(null);
        dispatch(clearError());
        // Check if deps can be added without issue
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name]);

    if (!isOpen) {
        return null;
    }

    //Render
    return (
        <Dialog
            title={isEditing ? T('edit.search') : T('save.search')}
            open={isOpen}
            onClose={close}
            className={classes.saveSearchDialog}
        >
            <DialogContent>
                <div className={classes.field}>
                    <div className={classes.query}>
                        <HighlightedText text={query || ''} ellipsis />
                        <button type="button" onClick={edit} className={classes.editQueryBtn}>
                            {T('edit')}
                        </button>
                    </div>
                </div>
                <div className={classes.field}>
                    {error === 'name' && (
                        <FormError
                            actionText={T('name.action')}
                            errorText={T('name.error')}
                            type="warning"
                            id="saveSearchDialog.name.error"
                        />
                    )}
                    {error === 'nameLength' && (
                        <FormError
                            actionText={T('name.action2')}
                            errorText={T('name.length.error', {limit: NAME_LIMIT})}
                            type="warning"
                            id="saveSearchDialog.name.error"
                        />
                    )}
                    {apiError && (
                        <FormError
                            actionText={apiError.descId ? T(apiError.descId) : apiError.desc}
                            errorText={apiError.titleId ? T(apiError.titleId) : T('api.error2')}
                            type="warning"
                            id="saveSearchDialog.name.error"
                        />
                    )}
                    <label className={classes.label} htmlFor="save-search-name">{T('name')}</label>
                    <TextField
                        required
                        id="save-search-name"
                        error={
                            error === 'name' || error === 'nameLength' ? {error: true} : null
                        }
                        fullWidth
                        onChange={updateName}
                        value={name}
                        InputProps={
                            error
                                ? {
                                        inputProps: {
                                            'aria-errormessage': 'saveSearchDialog.name.error',
                                        },
                                    }
                                : undefined
                        }
                    />
                </div>

                <div className={classes.field}>
                    <label className={classes.label} htmlFor="save-search-description">
                        {T('description')} {T('optional')}
                    </label>
                    <TextField
                        fullWidth
                        id="save-search-description"
                        onChange={updateDesc}
                        value={description}
                    />
                </div>
                <div className={classes.field}>
                    <label className={classes.label} htmlFor="save-search-select-team">{T('team')}</label>
                    <SelectField //<<<TODO this should use a combobox
                        id="save-search-select-team"
                        className={'grey-select-field'}
                        error={error === 'team'}
                        readOnly={isEditing}
                        onChange={isEditing ? null : selectTeam}
                        options={
                            projects?.map((p) => ({
                                label: p.name,
                                value: p.id,
                            })) || []
                        }
                        value={teamId}
                    />
                    {error === 'team' && (
                        <FormError
                            actionText={T('team.action')}
                            errorText={T('team.error')}
                            type="warning"
                        />
                    )}
                </div>

                {isLinkedinEnabled && currentProjectLinkedinChannels?.length > 0 && (
                    <div className={classes.field}>
                        <label className={classes.label} htmlFor="save-search-select-linkedin-channels">
                            {T('linkedinChannels.saveSearchDialog.title')}
                        </label>
                        <CheckboxList
                            options={currentProjectLinkedinChannels}
                            selectedOptions={selectedLinkedinChannels}
                            onChange={setSelectedLinkedinChannels}
                            labelField={'name'}
                            valueField={'id'}
                        />
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={close} priority="secondary">
                    {T('cancel')}
                </Button>
                <Button
                    disabled={saving && !apiError}
                    onClick={validateAndSaveSearch}
                    priority="cta"
                >
                    {saving && !apiError ? (
                        <>
                            <p aria-live="polite" className={classes.hide}>
                                Saving search.
                            </p>
                            <PulseLoader />
                        </>
                    ) : (
                        T('save')
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
