import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
    },
    scheduleTitleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        borderBottom: '1px #ccc solid',
    },
    scheduleTitle: {
        margin: '10px',
        fontSize: '17px',
    },
    reportBody: {
        margin: '20px',
        marginTop: '10px',
    },
    label: {
        margin: '10px 0',
        paddingBottom: '5px',
        fontWeight: theme.font.bold,
        fontSize: '16px',
        display: 'block',
    },
    reportNameInput: {
        width: '100%',
    },
    schedulePicker: {
        margin: '10px 0',
    },
    timespan: {
        backgroundColor: theme.colors.lightGrey20,
        padding: '16px 12px',
    },
    timespanPicker: {
        display: 'flex',
        alignItems: 'center',
        margin: '10px 0',
    },
    timespanLabel: {
        width: '100px',
    },
    timePickerAt: {
        margin: '0 10px',
    },
    timePickerDiv: {
        margin: '0 5px',
    },
    checkbox: {
        '--checkbox-gap': '4px',
        marginRight: '10px',
    },
    startDatePickerContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '7px',
        height: '40px',
        '& .MuiFormControlLabel-root': {
            marginRight: 0,
        },
        '& .MuiCheckbox-root': {
            padding: 0,
            width: '20px',
        },
    },
    startDatePickerTitle: {
        alignSelf: 'center',
        marginRight: '10px',
        cursor: 'default',
    },
    scheduleSummary: {
        margin: '30px 0',
    },
    scheduleFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    reportExampleButton: {
        display: 'flex',
        alignItems: 'center',
        color: theme.mixin.linkColor.color,
        textDecoration: 'none',
        cursor: 'pointer',
        '& svg': {
            fill: theme.mixin.linkColor.color,
            width: '16px',
            height: '16px',
            marginLeft: '6px',
        },
    },
    scheduleActions: {
        display: 'flex',
    },
    postScheduleContainer: {
        position: 'relative',
    },
    postSchedule: {
        margin: '0 5px',
    },
    postScheduleProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    cancelSchedule: {
        margin: '0 5px',
    },
}));

export default useStyles;
