import React from 'react';

//Components
import FilterChip from 'hsi/components/Filters/FilterChip';

//Other
import {T} from 'hsi/i18n';

export function toStateFormat(displayFormat) {
    const stateFormat = {};

    displayFormat.forEach(({id, subcategories}) => {
        stateFormat[id] = {};

        subcategories && subcategories.forEach((subCatId) => (stateFormat[id][subCatId] = true));
    });

    return stateFormat;
}

export function toDisplayFormat(stateFormat) {
    const displayFormat = [];

    Object.keys(stateFormat).forEach((id) => {
        const subcategories = Object.keys(stateFormat[id])
            .filter((childId) => stateFormat[id][childId])
            .map((id) => +id);

        subcategories.length > 0 && displayFormat.push({id, subcategories});
    });

    return displayFormat;
}

export function stateToChips(stateFormat, categories, handleDelete, filterConf) {
    return [
        ...toChips(stateFormat.include, categories, true, handleDelete, filterConf),
        ...toChips(stateFormat.exclude, categories, false, handleDelete, filterConf),
    ];
}

function toChips(state, categories, mode, handleDelete, filterConf) {
    const categoryIds = Object.keys(state);

    return categoryIds.map((categoryId) => {
        const category = categories.find((category) => +category.id === +categoryId);

        if (!category) {
            //How should this be handled?
            return null;
        } else {
            //have all subcategories been selected
            const allSubcategories = Object.keys(state[categoryId]);
            const subcategories = allSubcategories
                .filter((childId) => state[categoryId][childId])
                .map((val) => +val)
                .sort((a, b) => a - b);

            if (subcategories.length === 0) {
                return null; //no subcategories selected
            }

            const allSubcatsSelected = allSubcategories.length === subcategories.length;

            return (
                <FilterChip
                    key={categoryId}
                    value={categoryId}
                    label={
                        allSubcatsSelected
                            ? T(
                                  filterConf.chipLabelAllSubcats ||
                                      'filters.category.chipLabelAllSubcats',
                                  {name: category.name},
                              )
                            : T(filterConf.chipLabel || 'filters.category.chipLabel', {
                                  name: category.name,
                                  subCats: subcategories.map(
                                      (id) =>
                                          category.children.find((child) => +child.id === id).name,
                                  ),
                              })
                    }
                    include={mode}
                    actionType={mode ? 'c-add-2' : 'c-delete-2'}
                    onDelete={() => handleDelete(categoryId, mode)}
                />
            );
        }
    });
}
