import React, {useMemo, useCallback, useState, useEffect} from 'react';

//Components
import TextField from 'hsi/components/TextField';
import ErrorMessage from 'hsi/components/Filters/ErrorMessage';
import Label from 'hsi/components/Filters/Label';
import AutosuggestInput from 'hsi/components/Filters/AutosuggestInput';
import Markdown from 'hsi/components/Markdown';
import Alert, {mergeReferencePropsMUI} from 'hsi/components/Tooltip/Alert';

//Hooks
import useFiltersState from 'hsi/components/Filters/hooks/useFiltersState';
import useUniqueId from 'hsi/hooks/useUniqueId';
import useRefCallback from 'hsi/hooks/useRefCallback';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';
import getAsyncStateProps from 'hsi/components/Filters/helpers/getAsyncStateProps';

//Consts
const defaultApplyKeyCodes = [13, 188];
const defaultApplyKeys = ['Enter'];

//The component
export default function TextfieldFilter({
    compact,
    autoScrollDrawer = null,
    disabled,
    onChange,
    filterConf: filterConfig,
}) {
    const classes = useStyles();
    const [textfieldValue, setTextfieldValue] = useState('');

    const {
        filterName,
        label,
        placeholder,
        //maxValues,
        //limitLabel,
        autosuggestType,
        applyKeyCodes = defaultApplyKeyCodes,
        applyKeys = defaultApplyKeys,
        tooltipPlacement,
        helperText,
    } = filterConfig;

    const {
        useSelector,
        actions: {setValueTextfield, setError, clearFilter},
    } = useFiltersState();

    const filterState = useSelector((state) => state.filters[filterName]);
    const error = useSelector((state) => state.error[filterName]);
    const pending = useSelector((state) => !!state.pending[filterName]);

    //Callbacks
    const onInputChange = useCallback(
        () => error && setError(filterName, null),
        [error, setError, filterName],
    );

    const onAdd = useRefCallback((value) => {
        if (value !== filterState) {
            if (value) {
                setValueTextfield(filterName, value);
                //Literally a bug with the linter not understanding the ?. syntax
                // eslint-disable-next-line no-unused-expressions
                onChange?.(filterName, 'textfield', value);
            } else {
                clearFilter(filterName);
            }
        }
    });

    //Calculated values
    const errorMsg = useMemo(
        () =>
            error ? (
                <ErrorMessage
                    error={error}
                    wide={tooltipPlacement === 'top' || tooltipPlacement === 'bottom'}
                />
            ) : null,
        [error, tooltipPlacement],
    );

    const fieldId = useUniqueId(null, `textfieldFilter-${filterName}-`);

    //Side effects
    useEffect(() => {
        if (autosuggestType) {
        }
        setTextfieldValue(filterState);
    }, [filterState, autosuggestType]);

    //Render
    return (
        <div className={classes.root}>
            {(label || placeholder) && (
                <Label invisible={!label} htmlFor={fieldId}>
                    {label ? T(label) : T(placeholder)}
                </Label>
            )}
            {autosuggestType ? (
                <AutosuggestInput
                    autosuggestType={autosuggestType}
                    autoScrollDrawer={autoScrollDrawer}
                    onInputChange={onInputChange}
                    onAdd={onAdd}
                    id={fieldId}
                    placeholder={placeholder ? T(placeholder) : null}
                    tooltip={errorMsg}
                    textfieldValue={textfieldValue}
                    setTextfieldValue={setTextfieldValue}
                />
            ) : (
                <Alert
                    visible={!!error}
                    theme="light"
                    content={errorMsg || ''}
                    placement="right"
                    mergeReferenceProps={mergeReferencePropsMUI}
                    getPositionElement={getErrorIcon}
                >
                    <TextField
                        id={fieldId}
                        placeholder={placeholder ? T(placeholder) : null}
                        value={textfieldValue}
                        onChange={(evt) => setTextfieldValue(evt.target.value)}
                        onKeyDown={(evt) => {
                            onInputChange();

                            if (applyKeyCodes.includes(evt.which) || applyKeys.includes(evt.key)) {
                                onAdd(evt.target.value);
                            }
                        }}
                        onPaste={onInputChange}
                        onBlur={(evt) => onAdd(evt.target.value)}
                        error={!!error}
                        helperText={helperText && <Markdown>{T(helperText)}</Markdown>}
                        {...getAsyncStateProps(pending, error, null, disabled, textfieldValue)}
                    />
                </Alert>
            )}
        </div>
    );
}

function getErrorIcon(elem) {
    return elem.parentElement.querySelector('.MuiInputAdornment-root > div');
}
