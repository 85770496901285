import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({colors, v2}) => ({
    inputRoot: {
        height: v2 ? 40 : 'auto',
        margin: v2 ? '26px 24px 0 24px' : '9px 9px 6px 9px',
        padding: '0 9px !important',
        width: 'auto',
        '&.Mui-focused': {
            boxShadow: v2
                ? `0 0 0 2px ${colors.accentDefault}`
                : `0 0 0 3px ${colors.secondaryOrange100}`,
        },
    },
    input: {
        height: v2 ? 40 : 'auto',
        padding: v2 ? '0 !important' : 'inherit',
        '&:focus': {
            height: v2 ? 38 : 'auto',
        },
    },
    inputIcon: {
        height: v2 ? 16 : 14,
        marginRight: 6,
        width: v2 ? 16 : 14,
    },
    noOptions: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        fontSize: v2 ? 13 : 'inherit',
        justifyContent: 'space-around',
        height: v2 ? 120 : 130,
        ...(!v2 && {
            color: '#000',
        }),
        '& svg': {
            fill: colors.darkGrey100,
            height: 24,
            width: 24,
        },
        '& strong': {
            ...(!v2 && {
                fontSize: 20,
                fontWeight: 700,
            }),
        },
    },
    option: {
        // increase specificity to overwrite base styles: https://docs.tss-react.dev/increase-specificity
        '&&&': {
            alignItems: 'center',
            display: 'flex',
            fontSize: v2 ? 13 : 'inherit',
            minHeight: v2 ? 40 : 48,
            padding: v2 ? '0 24px' : '6px 16px',
            '&:hover': {
                backgroundColor: v2 ? colors.bgHover : colors.primaryYellow20,
            },
            '&[aria-selected="true"]': {
                backgroundColor: v2 ? colors.bgSubtle : `${colors.primaryYellow40} !important`,
            },
        },
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        maxHeight: v2 ? 212 : 206,
    },
    popper: {
        // Scroll box styles
        maxHeight: v2 ? 212 : 206,
        width: 'auto !important',
        '&.MuiAutocomplete-listbox': {
            maxHeight: v2 ? 212 : 206,
        },
    },
}));
