import React from 'react';

//Other
import {T} from 'hsi/i18n';

//The component
export default function SuccessMessage({value}) {
    return (
        <>
            {T('managetagsdialog.notifications.success.first')}
            <span className="itemName">{'"' + value + '"'}</span>
            {T('managetagsdialog.notifications.success.created')}
        </>
    );
}
