import {makeStyles} from 'tss-react/mui';
import {isV2} from 'hsi/types/theme';

export default makeStyles<void, 'selected'>()((theme, _params, classes) => {
    return {
        wrapper: {
            width: 680,
            display: 'flex',
        },

        relativeDateRanges: {
            flex: '0 0 180px',
            background: isV2(theme) ? theme.colors.uiGrayDefault : theme.colors.primaryBlue20,
            padding: 0,
        },
        selected: {},
        relativeDateRange: {
            padding: '10px 20px',

            [`&.${classes.selected}`]: {
                background: isV2(theme) ? theme.colors.bgSubtle : theme.colors.primaryYellow40,
            },
        },

        dateRangePicker: {},

        timezone: {
            background: isV2(theme) ? theme.colors.white : '#FFF',
            flexGrow: 1,
            padding: theme.v2 ? '16px 24px' : '0px 10px 10px 10px',
        },
        timezoneTitleWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        timezoneTitle: {
            ...(isV2(theme) ? theme.fonts.bodyLargeNormal : null),
            margin: '10px 0',
        },
        timezoneTitleIcon: {
            height: 18,
            marginLeft: 6,
            width: 18,
        },
        timezoneHelpTooltip: {
            maxWidth: theme.v2 ? undefined : 370,
        },
        timezoneDropdown: {
            backgroundColor: 'white',
            border: isV2(theme)
                ? `1px solid ${theme.colors.uiGrayPopupBorder}`
                : `2px solid ${theme.colors.secondaryPurple100}`,
            borderRadius: theme.v2 ? 3 : undefined,
            width: theme.v2 ? 312 : 480,
            height: theme.v2 ? undefined : 320,
            zIndex: 12345,
        },
        timezoneSelect: {
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: theme.v2 ? 32 : undefined,
            width: theme.v2 ? 312 : undefined,

            '& > .MuiButton-label': {
                display: 'flex',
                flexFlow: 'row nowrap',
                ...(isV2(theme)
                    ? {...theme.fonts.bodyNormal, textTransform: 'initial'}
                    : {fontSize: '16px', fontWeight: '600'}),
            },
        },
        timezoneSelectBtnLbl: {
            flex: '1 1 auto',
            textAlign: 'left',
        },
        timezoneSelectClockIcon: {
            height: theme.v2 ? undefined : 20,
            width: theme.v2 ? undefined : 20,

            '& > svg': {
                fontSize: theme.v2 ? '1.5rem' : undefined,
            },
        },
        timezoneSelectDropdownIcon: {
            marginLeft: 'auto',

            '& > svg': {
                fontSize: theme.v2 ? undefined : '1.5rem', //TODO check this is the right way around
            },
        },
        timezoneSelectIcon: {
            marginRight: theme.v2 ? 6 : undefined,
        },
        timezoneDropdownDefaultOption: {
            alignItems: 'center',
            borderBottom: isV2(theme) ? `1px solid ${theme.colors.bgSubtle}` : `1px solid #CCC`,
            display: 'flex',

            ...(isV2(theme) ? theme.fonts.bodyNormal : {fontSize: '1rem'}),
            marginTop: theme.v2 ? 4 : undefined,
            minHeight: theme.v2 ? 40 : 48,
            padding: theme.v2 ? '6px 24px' : '6px 16px',

            '&:hover': {
                backgroundColor: isV2(theme) ? theme.colors.bgHover : theme.colors.primaryYellow20,
                cursor: 'pointer',
            },
            '&[aria-selected="true"]': {
                backgroundColor: isV2(theme)
                    ? theme.colors.bgSubtle
                    : `${theme.colors.primaryYellow40} !important`,
            },
        },
        timezoneDropdownDefaultOptionSelected: {
            backgroundColor: isV2(theme)
                ? theme.colors.bgSubtle
                : `${theme.colors.primaryYellow40} !important`,
        },
        timezoneOptionIcon: {
            fill: isV2(theme) ? theme.colors.primaryBlue100 : theme.colors.primaryBlue100,
            marginRight: 6,
        },

        disabled: {},
    };
});
