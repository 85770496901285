import {forwardRef, useCallback, useMemo} from 'react';
import isEmpty from 'lodash/isEmpty';

//Components
import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle, { formatCardTitleAsString } from 'hsi/components/Card/CardTitle';
import InfoPopup from './InfoPopup';
import ManagedGridTable from 'hsi/components/table/ManagedGridTable';
import PaginationButtons from 'hsi/components/PaginationButtons';

//Hooks
import useCardData from 'hsi/hooks/useCardData';
import useDrillInMentions from 'hsi/hooks/useDrillInMentions';
import useIsSavedSearch from 'hsi/hooks/useIsSavedSearch';
import useCardTableTrackingAndPersistence from '../hooks/useCardTableTrackingAndPersistence';
import usePaginateRows from 'hsi/components/table/ManagedTable/usePaginateRows';
import useFormatRows from 'hsi/components/table/ManagedTable/useFormatRows';
import useGetTableColumns, { TopAuthorsTableRow } from './useGetTableColumns';
import useGetDrillInData from './useGetDrillInData';
import { useCardTypeLimit } from 'hsi/utils/cards/getCardTypeLimit';

//Other
import useStyles from './styles';
import { CardTypeProps } from 'hsi/types/cards';
import useAddPositionToRows from '../hooks/useAddPositionToRows';
import { T } from 'hsi/i18n';
import { TableSortDir } from 'hsi/components/table/types';

//Consts
const type = 'topauthors';
const defaultSortColumn = 'value'; //i.e. total;
const DEFAULT_DATA: TopAuthorsTableRow[] = []


//The component
const TopAuthors = forwardRef<HTMLDivElement, CardTypeProps>(({title, ...props}, ref) => {
    const classes = useStyles();
    const isSavedSearch = useIsSavedSearch();
    const drillInMentions = useDrillInMentions();
    const getDrillInData = useGetDrillInData();

    const {perPage} = useCardTypeLimit(type);

    const {data, loading, loaded, error, loadData, isCardSelected} = useCardData({
        type,
        dataSelector: (state) => state.chart[type],
    });
    const hasData = !isEmpty(data);
    const rows = hasData ? (data as TopAuthorsTableRow[]): DEFAULT_DATA;

    const columns = useGetTableColumns(isSavedSearch, classes);

    const onSortingChanged = useCallback((newColumn: keyof TopAuthorsTableRow | undefined, newDirection: TableSortDir | undefined, oldColumn: keyof TopAuthorsTableRow | null | undefined, oldDirection: TableSortDir | null | undefined) => {
        if(oldColumn !== null && newColumn !== oldColumn) {
            loadData();
        }
    }, [loadData]);

    const {page, pages, setPage, ...sorting} = useCardTableTrackingAndPersistence(type, columns, {defaultSortColumn, totalRows: rows.length, rowsPerPage: perPage, onSortingChanged});

    // Add position property to row data
    const positionedRows = useAddPositionToRows(rows);

    // Apply sorting
    const sortedRows = useMemo(() => {
        return sorting.sortDirection === 'asc' 
            ? [...positionedRows].reverse()
            : positionedRows;
    }, [positionedRows, sorting.sortDirection]);

    // Apply pagination
    const paginatedRows = usePaginateRows(sortedRows, page, perPage);

    // Apply formatting
    const formattedRows = useFormatRows(paginatedRows, columns);

    return (
        <CardLoadState
            title={
                <CardTitle
                    title={title}
                    type={type}
                    tooltipComponent={<InfoPopup type={type} isSavedSearch={isSavedSearch} />}
                    hasData={hasData}
                />
            }
            error={error}
            hasData={hasData}
            loading={loading}
            loaded={loaded}
            loadData={loadData}
            selected={isCardSelected}
            ref={ref}
            data-testid={type}
            type={type}
            {...props}
        >
            <ManagedGridTable
                className={isSavedSearch ? classes.table : undefined}
                columns={columns}
                rowData={formattedRows}
                caption={T('managedTable.caption', {name: formatCardTitleAsString(title)})}

                rowSelectable
                onRowClick={(index: number) => {
                    drillInMentions(getDrillInData(paginatedRows[index]));
                }}

                {...sorting}
            />
            <PaginationButtons page={page} pages={pages} setPage={setPage} />
        </CardLoadState>
    );
});

export default TopAuthors;
