import React from 'react';

//Components
import Icon, {IconProps} from 'hsi/components/Icon/';

//Hooks
import useConfig from 'hsi/hooks/useConfig';

interface IconRouterProps extends IconProps {
    name: string;
}

//The component
const IconRouter = React.forwardRef<SVGSVGElement, IconRouterProps>(({name, ...rest}, ref) => {
    const config = useConfig();
    const mappedName = config.iconNamesMap?.[name] ? config.iconNamesMap[name] : name;

    return <Icon ref={ref} name={mappedName} realName={name} {...rest} />;
});

export default IconRouter;
