import React, {useMemo, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

//Components
import CardLoadState from 'hsi/components/Card/CardLoadState';
import PieChart from 'hsi/components/PieChart';
import CardTitle from 'hsi/components/Card/CardTitle';
import OverflowTooltip from 'hsi/components/SimpleTooltip/OverflowTooltipHook';

//Hooks
import useEventTrack from 'hsi/hooks/useEventTrack';
import useGetLoadData from '../useGetLoadData';
import useElementSize from 'hsi/hooks/useElementSize';
import useIsExport from 'hsi/hooks/useIsExport';

//Actions
import {mentionsDrillIn} from 'hsi/slices/mentions';

//Utils
import {formatBigInt} from 'hsi/utils/formatNumber';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Consts
const type = 'shareOfVoice';
const height = 316;

//The components
export default React.forwardRef(function ShareOfVoiceCard({title, ...props}, ref) {
    const classes = useStyles();
    const {trackWithSearchData} = useEventTrack();
    const [sizeRef, {width} = {}] = useElementSize(null, {width: true});
    const isExport = useIsExport();
    //Redux
    const dispatch = useDispatch();
    const {data, loaded, loading, error} = useSelector((state) => state.chart[type]);

    const loadData = useGetLoadData(type);

    const getLegendPercentage = (percent) => {
        const calculatedPercent = percent * 100;
        if (calculatedPercent === 0) return '0%';
        if (calculatedPercent < 1) return '< 1%';
        return Math.round(calculatedPercent) + '%';
    };
    //Callbacks
    const legendFormatter = useCallback(
        (value, entry) => (
            <span className={classes.legend}>
                <strong>
                    <OverflowTooltip tooltip={value}>
                        <div>
                            {getLegendPercentage(entry.payload.percent)} {value}
                        </div>
                    </OverflowTooltip>
                </strong>
            </span>
        ),
        [classes],
    );

    const exportLegendFormatter = useCallback(
        (value, entry) => (
            <span className={classes.legend}>
                <strong>
                    {getLegendPercentage(entry.payload.percent)} {value}
                </strong>{' '}
                | {formatBigInt(entry.payload.value)} {T('mentions_lc')}
            </span>
        ),
        [classes],
    );

    const drillIn = useCallback(
        ({payload: {name, id}}) => {
            const drillinFilter = {queryId: id?.toString()};
            trackWithSearchData('cardDrilledIn', {
                type,
                value: name,
            });
            dispatch(mentionsDrillIn(drillinFilter, type, null, name));
        },
        [dispatch, trackWithSearchData],
    );

    const content = useMemo(
        () => (
            <div ref={sizeRef} data-testid="shareOfVoiceChartWrapper">
                <PieChart
                    data={data?.categories}
                    formatLegend={isExport ? exportLegendFormatter : legendFormatter}
                    height={height}
                    onClick={drillIn}
                    width={width}
                />
            </div>
        ),
        [data, drillIn, exportLegendFormatter, isExport, legendFormatter, sizeRef, width],
    );

    return (
        <CardLoadState
            {...props}
            title={<CardTitle title={title} type={type} hasData={!!data && !!data.total} />}
            error={error}
            hasData={!!data && !!data.total}
            loadData={loadData}
            loaded={loaded}
            loading={loading}
            ref={ref}
            data-testid={type}
            type={type}
        >
            {content}
        </CardLoadState>
    );
});
