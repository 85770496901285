const styles = ({colors}) => ({
    CreateReportMenu: {
        color: colors.textDefault,
        '& .info': {
            margin: '0px 6px 26px 6px',
        },
        '& .check-wrapper': {
            
        },
        '& .MuiFormControlLabel-root': {
            marginRight: '0px',
        },
        '& .checkbox-message': {
            verticalAlign: 'middle',
        },
    },
    checkboxField: {
        flex: '1 0 auto',
        '--checkbox-gap': '4px',
        marginLeft: '21px',
    },
});

export default styles;
