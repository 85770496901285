// TODO: Add tests
// TODO: Refactor to typescript
import useConfig from 'hsi/hooks/useConfig';
import {isLinkedinChannelSourceType} from 'hsi/utils/dataManagement/linkedin';

const useLinkedinIsEnabled = () => {
    const {
        targettedData: {sourceTypes},
    } = useConfig();
    return !!sourceTypes.find((d) => isLinkedinChannelSourceType(d?.id));
};

export default useLinkedinIsEnabled;
