// All tracking name should be added here like i18n. This will make it easier to
// review them and to keep the consistent. We can also add descriptions or info
// on previous versions of that name.

// List of potential locations in the app
const products = [
    'alerts page',
    'auth tokens page',
    'dashboard',
    'home page',
    'mentions table',
    'reports page',
    'saved searches page',
    'streams',
    'targeted data page',
    'unknown', // This is the default for a page that isn't currently named
];

// List of potential actions that can be performed
// These should be past tense as the user should have just completed the action
const actions = [
    'added',
    'applied',
    'clicked',
    'completed',
    'created',
    'customized',
    'deleted',
    'drilled in',
    'edited',
    'errored',
    'exported',
    'logged in',
    'removed',
    'responded',
    'selected',
    'scrolled',
    'shown',
    'saved',
    'sorted',
    'started',
    'toggled',
    'triggered',
    'viewed',
    'pinned',
    'unpinned',
    'hidden',
    'shown',
];

const getProduct = (name: string) => products.find((product) => product === name);

const getAction = (name: string) => actions.find((action) => action === name);

// All tracking events should be in the format of:
// "product - actions". This function ensures that we always use that format
// and always pick consistent options.
// This method also means that the app won't even compile if you use a tracking
// name that doesn't exist.
const getName = (product: string, action: string, item?: string) => {
    if (!getProduct(product)) {
        throw new Error(`Product must be in list: ${product}`);
    }

    if (!getAction(action)) {
        throw new Error(`Action must be in list: ${action}`);
    }

    if (!item) {
        return `${product} - ${action}`;
    }

    if (item && typeof item !== 'string') {
        throw new Error(`Item must be a string: ${item}`);
    }

    return `${product} - ${item} ${action}`;
};

const trackingNames = {
    actionsMenuClicked: (location: string) => getName(location, 'clicked', 'actions menu'),
    addTargetedDataDialogToggled: (location: string) =>
        getName(location, 'toggled', 'add targeted data dialog'),
    advancedSearchHelpButtonClicked: (location: string) =>
        getName(location, 'clicked', 'advanced search help button'),
    advancedSearchHelpKeyboardShortcutPressed: (location: string) =>
        getName(location, 'clicked', 'advanced search help keyboard shortcut pressed'),
    alertCreated: (location: string) => getName(location, 'created', 'alert'),
    alertsPageButtonClicked: (location: string) =>
        getName(location, 'clicked', 'alerts page button'),
    buildReportButtonClicked: (location: string) =>
        getName(location, 'clicked', 'build report button'),
    cardCustomized: (location: string) => getName(location, 'customized', 'card'),
    cardDrilledIn: (location: string) => getName(location, 'drilled in', 'card'),
    cardExported: (location: string) => getName(location, 'exported', 'card'),
    cardTableSorted: (location: string) => getName(location, 'sorted', 'card table'),
    cardTooltipShown: (location: string) => getName(location, 'shown', 'card tooltip'),
    cardPinned: (location: string) => getName(location, 'pinned', 'card'),
    cardHidden: (location: string) => getName(location, 'hidden', 'card'),
    cardUnpinned: (location: string) => getName(location, 'unpinned', 'card'),
    cardShown: (location: string) => getName(location, 'shown', 'card'),
    configDrawerToggled: (location: string) => getName(location, 'toggled', 'config drawer'),
    dashboardPdfExported: (location: string) => getName(location, 'exported', 'dashboard pdf'),
    dashboardPdfClicked: (location: string) => getName(location, 'clicked', 'dashboard pdf'),
    dataGatheringToggled: (location: string) => getName(location, 'toggled', 'data gathering'),
    deleteSearchDialogToggled: (location: string) =>
        getName(location, 'toggled', 'delete search dialog'),
    editSearchDialogEditButtonClicked: (location: string) =>
        getName(location, 'clicked', 'edit search dialog edit button'),
    editSearchDialogSearchEdited: (location: string) =>
        getName(location, 'edited', 'edit search dialog search'),
    editSearchDialogSearchErrored: (location: string) =>
        getName(location, 'errored', 'edit search dialog search'),
    editSearchDialogToggled: (location: string) =>
        getName(location, 'toggled', 'edit search dialog'),
    filterDrawerFilterApplied: (location: string) =>
        getName(location, 'applied', 'filter drawer filter'),
    filterDrawerToggled: (location: string) => getName(location, 'toggled', 'filter drawer'),
    filterDrawerSectionToggled: (location: string) =>
        getName(location, 'toggled', 'filter drawer section'),
    guidedSearchCompleted: (location: string) => getName(location, 'completed', 'guided search'),
    guidedSearchStarted: (location: string) => getName(location, 'started', 'guided search'),
    guidedSearchUserResponded: (location: string) =>
        getName(location, 'responded', 'guided search user'),
    helpCenterButtonClicked: (location: string) =>
        getName(location, 'clicked', 'help center button'),
    homePageButtonClicked: (location: string) => getName(location, 'clicked', 'home page button'),
    introTooltipUserResponded: (location: string) =>
        getName(location, 'responded', 'intro tooltip user'),
    manageTokensButtonClicked: (location: string) =>
        getName(location, 'clicked', 'manage tokens button'),
    mentionDeleted: (location: string) => getName(location, 'deleted', 'mention'),
    mentionDrawerExported: (location: string) => getName(location, 'exported', 'mention drawer'),
    mentionDrawerScrolled: (location: string) => getName(location, 'scrolled', 'mention drawer'),
    mentionDrawerSorted: (location: string) => getName(location, 'sorted', 'mention drawer'),
    mentionDrawerToggled: (location: string) => getName(location, 'toggled', 'mention drawer'),
    mentionDrawerTooltipShown: (location: string) =>
        getName(location, 'shown', 'mention drawer tooltip'),
    mentionEdited: (location: string) => getName(location, 'edited', 'mention'),
    mentionLinkClicked: (location: string) => getName(location, 'clicked', 'mention link'),
    mentionNLAViewed: (location: string) => getName(location, 'viewed', 'mention NLA'),
    mentionViewed: (location: string) => getName(location, 'viewed', 'mention'),
    multipleSearchApplied: (location: string) => getName(location, 'applied', 'multiple search'),
    multipleSearchClearAllButtonClicked: (location: string) =>
        getName(location, 'clicked', 'clear all button'),
    multipleSearchDropdownToggled: (location: string) =>
        getName(location, 'toggled', 'multiple search'),
    multipleSearchRemoved: (location: string) => getName(location, 'removed', 'multiple search'),
    newQuickSearchButtonClicked: (location: string) =>
        getName(location, 'clicked', 'new quick search button'),
    quickSearchTriggered: (location: string) => getName(location, 'triggered', 'quick search'),
    quickLinkClicked: (location: string) => getName(location, 'clicked', 'quick link'),
    recentSavedSearchButtonClicked: (location: string) =>
        getName(location, 'clicked', 'recently saved search button'),
    reportsPageButtonClicked: (location: string) =>
        getName(location, 'clicked', 'reports page button'),
    reportScheduleCreated: (location: string) => getName(location, 'created', 'report schedule'),
    reportScheduleEdited: (location: string) => getName(location, 'edited', 'report schedule'),
    reportCreateClicked: (location: string) => getName(location, 'clicked', 'create report'),
    reportCancelClicked: (location: string) => getName(location, 'clicked', 'cancel export'),
    reportScheduleOpen: (location: string) => getName(location, 'clicked', 'report schedule'),
    saveSearchDialogToggled: (location: string) =>
        getName(location, 'toggled', 'save search dialog'),
    saveSearchDialogSearchCreated: (location: string) =>
        getName(location, 'created', 'save search dialog search'),
    savedSearchesPageButtonClicked: (location: string) =>
        getName(location, 'clicked', 'saved searches page'),
    searchClicked: (location: string) => getName(location, 'clicked', 'search'),
    searchDateRangeApplied: (location: string) => getName(location, 'applied', 'search date range'),
    searchDateRangeSelected: (location: string) =>
        getName(location, 'selected', 'search date range'),
    searchRelativeDateRangeSelected: (location: string) =>
        getName(location, 'selected', 'search relative date range'),
    searchRelativeDateRangeApplied: (location: string) =>
        getName(location, 'applied', 'search relative date range'),
    searchDeleted: (location: string) => getName(location, 'deleted', 'search'),
    searchErrored: (location: string) => getName(location, 'errored', 'search'),
    snapshotExportClicked: (location: string) => getName(location, 'clicked', 'snapshot export'),
    quotaShown: (location: string) => getName(location, 'shown', 'quota'),
    navSettingsButtonClicked: (location: string) =>
        getName(location, 'clicked', 'nav settings button'),
    tableSorted: (location: string) => getName(location, 'sorted', 'table'),
    tagApplied: (location: string) => getName(location, 'applied', 'tag'),
    tagCreated: (location: string) => getName(location, 'created', 'tag'),
    tagRemoved: (location: string) => getName(location, 'removed', 'tag'),
    tagDialogToggled: (location: string) => getName(location, 'toggled', 'tag dialog'),
    taggedMentionsViewed: (location: string) => getName(location, 'viewed', 'tagged mentions'),
    targetedDataSourceAdded: (location: string) =>
        getName(location, 'added', 'targeted data source'),
    targetedDataSourceDeleted: (location: string) =>
        getName(location, 'deleted', 'targeted data source'),
    targetedDataSourceSelected: (location: string) =>
        getName(location, 'selected', 'targeted data source'),
    targetedDataSourceAuthStarted: (location: string) =>
        getName(location, 'started', 'targeted data source authentication'),
    targetedDataSourceAuthCompleted: (location: string) =>
        getName(location, 'completed', 'targeted data source authentication'),
    targetedDataLinkedinChannelCreated: (location: string) =>
        getName(location, 'created', 'linkedin channel'),
    userLoggedIn: (location: string) => getName(location, 'logged in', 'user'),
    viewAllSearchesButtonClicked: (location: string) =>
        getName(location, 'clicked', 'view all searches button'),
    viewed: (location: string) => getName(location, 'viewed'),
    linkedinMenuClicked: (location: string) => getName(location, 'clicked', 'linkedin menu'),
    linkedinChannelsSaved: (location: string) => getName(location, 'saved', 'linkedin channels'),
    targetedDataExploreModeClicked: (location: string) =>
        getName(location, 'clicked', 'explore mode'),

    mentionsFullLayoutChanged: (location: string) =>
        getName(location, 'clicked', 'Expanded layout toggled'),

    toggleMentionsSelectMode: (location: string) =>
        getName(location, 'clicked', 'select multiple toggled'),

    bulkMentionSentimentEditRequested: (location: string) =>
        getName(location, 'clicked', 'bulk sentiment requested'),
    bulkMentionTagEditRequested: (location: string) =>
        getName(location, 'clicked', 'bulk tag requested'),
    bulkMentionDeleteRequested: (location: string) =>
        getName(location, 'clicked', 'bulk delete requested'),
    bulkMentionSentimentEditComplete: (location: string) =>
        getName(location, 'completed', 'bulk sentiment confirmed'),
    bulkMentionTagEditComplete: (location: string) =>
        getName(location, 'completed', 'bulk tag confirmed'),
    bulkMentionDeleteComplete: (location: string) =>
        getName(location, 'completed', 'bulk delete confirmed'),
};

export default trackingNames;

export type TrackingNames = keyof typeof trackingNames;

export {actions, getAction, getName, getProduct, products, trackingNames};
