import {expandRect, intersectRect} from './geometry';

const scrollToChildElement = (parentScrollableEl: Element, childEl: Element, offset: number) => {
    if (
        parentScrollableEl.getBoundingClientRect().height <
        childEl.getBoundingClientRect().top + offset
    ) {
        parentScrollableEl.scrollTop += offset;
    }
};

const scrollTop = () => {
    if (window.scrollY) {
        window.scroll(0, 0);
    }
};

function getRelativePos(elm: Element, scrollParent: Element) {
    const pPos = scrollParent.getBoundingClientRect(); // parent pos
    const cPos = elm.getBoundingClientRect(); // target pos
    const pos = {
        top: cPos.top - pPos.top + scrollParent.scrollTop,
        right: cPos.right - pPos.right,
        bottom: cPos.bottom - pPos.bottom,
        left: cPos.left - pPos.left,
    };

    return pos;
}

function elementInViewport(elem: Element, margin = 0) {
    var bounding = elem.getBoundingClientRect();

    if (bounding.width === 0 || bounding.height === 0) {
        return false;
    }

    return intersectRect(expandRect(bounding, margin), {
        top: 0,
        bottom: window.innerHeight || document.documentElement.clientHeight,
        left: 0,
        right: window.innerWidth || document.documentElement.clientWidth,
    });
}

export {scrollToChildElement, scrollTop, elementInViewport, getRelativePos};
