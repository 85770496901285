import {PageTypeDefinitionType} from 'hsi/types/shared';
import basePageTypes from 'hsi/configurations/_base/pageTypes';

//Loses news, blogs and QQ
const exclude = ['news', 'blog', 'qq'];

const pageTypes: PageTypeDefinitionType[] = basePageTypes.filter(
    ({value}) => !exclude.includes(value),
);

export default pageTypes;
