import {useMemo, useCallback, useState} from 'react';
import cn from 'classnames';

//Components
import Chip from 'hsi/components/Chip';
import IconRouter from 'hsi/components/IconRouter';
import AddOrCreateTag from 'hsi/components/AddOrCreateTag';
import HorizontalOverflow, {
    defaultGetOverflowContent,
} from 'hsi/components/layout/HorizontalOverflow';
import Tooltip from 'hsi/components/Tooltip';
import SuccessMessage from 'hsi/components/Notifications/TagSuccessMessage';
import ErrorMessage from 'hsi/components/Notifications/TagErrorMessage';
import PulseLoader from 'hsi/components/PulseLoader';
import Popover, {PopoverRenderFunc} from 'hsi/components/Popover';

//Actions
import {addMentionsTag, removeMentionsTag} from 'hsi/slices/mentions';
import {saveTag} from 'hsi/actions/tagActions';

//Hooks
import useProjectId from 'hsi/hooks/useProjectId';
import useEventTrack from 'hsi/hooks/useEventTrack';
import {useAppDispatch} from 'hsi/hooks/useRedux';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Types
import {SavedSearchMentionType} from 'hsi/types/mentions';
import {isFulfilled} from '@reduxjs/toolkit';

export type TagsProps = {
    chipClassName?: string;
    className?: string;
    editable?: boolean;
    disabled?: boolean;
    pending?: boolean;
    ellipsis?: boolean;
    mention?: SavedSearchMentionType; //required if editable
    size?: Parameters<typeof Chip>[0]['size'];
    tags: string[];
};

//The component
export default function Tags({
    chipClassName,
    className,
    editable,
    disabled,
    pending,
    ellipsis,
    mention,
    size = 'medium',
    tags,
}: TagsProps) {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const projectId = useProjectId()!;
    const {trackWithSearchData} = useEventTrack();
    const canEdit = editable && !pending && !disabled && !!mention;
    const [working, setWorking] = useState(false);

    const autocompleteClasses = useMemo(
        () => ({root: classes.autocompleteRoot}),
        [classes.autocompleteRoot],
    );

    //Callbacks
    const handleDelete = useCallback(
        async (tagName: string) => {
            if (!canEdit) {
                return;
            }

            const result = await dispatch(
                removeMentionsTag({ids: [mention.id], tagName, projectId}),
            );

            if (isFulfilled(result)) {
                trackWithSearchData('tagRemoved', {
                    tag: tagName,
                });
            }
        },
        [canEdit, dispatch, mention?.id, projectId, trackWithSearchData],
    );

    const saveChange = useCallback(
        async (tagName: string, close?: () => void) => {
            if (canEdit) {
                setWorking(true);
                const response = await dispatch(
                    addMentionsTag({ids: [mention.id], tagName, projectId}),
                );
                setWorking(false);

                if (isFulfilled(response)) {
                    trackWithSearchData('tagApplied', {
                        tag: tagName,
                        selectedMentions: 1,
                    });
                }

                close?.();
            }
        },
        [canEdit, dispatch, mention?.id, projectId, trackWithSearchData],
    );

    const addNewAndSaveChange = useCallback(
        async (tagName: string, close: () => void) => {
            if (canEdit) {
                const successMessage = <SuccessMessage value={tagName} />;
                const errorMessage = <ErrorMessage />;
                setWorking(true);

                const result = await dispatch(
                    saveTag(tagName, successMessage, errorMessage, projectId),
                );

                if (result) {
                    trackWithSearchData('tagCreated', {
                        tag: tagName,
                    });

                    saveChange(tagName, close);
                } else {
                    close?.();
                }
            }
        },
        [canEdit, dispatch, projectId, saveChange, trackWithSearchData],
    );

    const getOverflowContent = useCallback(
        (overflowIndex: number, totalChildren: number) => {
            return (
                <Tooltip tooltip={tags.slice(overflowIndex).join(', ')}>
                    <span className={classes.hiddenChips}>
                        {defaultGetOverflowContent(overflowIndex, totalChildren)}
                    </span>
                </Tooltip>
            );
        },
        [tags, classes.hiddenChips],
    );

    //Render

    const tagsContent = useMemo(
        () =>
            tags?.map((tag) => (
                <Chip
                    size={size}
                    key={tag}
                    label={tag}
                    disabled={pending || disabled}
                    onDelete={editable ? () => handleDelete(tag) : undefined}
                    className={chipClassName}
                    overflowTooltip
                    data-testid="mentionTagsTag"
                    aria-disabled={!!pending || !!disabled}
                />
            )),
        [tags, size, pending, disabled, editable, chipClassName, handleDelete],
    );

    const addTagsPopoverContent = useCallback<PopoverRenderFunc>(
        ({close, labelId, descriptionId}) => {
            return (
                <>
                    <span id={labelId} className="offscreen">
                        {T('mentionComponent.tags.addTagMenuLbl')}
                    </span>
                    <span id={descriptionId} className="offscreen">
                        {T('mentionComponent.tags.addTagMenuDesc')}
                    </span>
                    <AddOrCreateTag
                        onKeyDown={(e) => {
                            if (e.code !== 'Escape') {
                                e.stopPropagation();
                            }
                        }}
                        currentTags={tags}
                        onAddTag={(tagName: string) => saveChange(tagName, close)}
                        onCreateAndAddTag={(tagName: string) => {
                            addNewAndSaveChange(tagName, close);
                        }}
                        disabled={pending}
                        pending={working}
                        autocompleteClasses={autocompleteClasses}
                    />
                </>
            );
        },
        [addNewAndSaveChange, autocompleteClasses, pending, saveChange, tags, working],
    );

    return (
        <div
            className={cn(
                classes.tags,
                !!ellipsis && classes.withEllipsis,
                !!disabled && classes.disabled,
                className,
            )}
            data-testid="mentionTagsWrapper"
            onClick={(e) => e.stopPropagation()}
        >
            {editable && (
                <Popover
                    disabled={pending || disabled}
                    content={addTagsPopoverContent}
                    portal
                    visuallyHiddenDismiss
                >
                    <button
                        className={cn(classes.addTag, !!disabled && classes.disabled)}
                        aria-disabled={!!pending || !!disabled}
                    >
                        {pending ? (
                            <PulseLoader
                                size="small"
                                className={classes.loader}
                                data-testid="mention.tags.pending"
                            />
                        ) : (
                            <IconRouter aria-hidden name="tag-add" />
                        )}
                        <span>{T('mentionComponent.tags.add')}</span>
                    </button>
                </Popover>
            )}
            {tagsContent && ellipsis ? (
                <HorizontalOverflow
                    className={classes.overflowTagsHolder}
                    gap={8}
                    getOverflowContent={getOverflowContent}
                >
                    {tagsContent}
                </HorizontalOverflow>
            ) : (
                tagsContent
            )}
        </div>
    );
}
