import React from 'react';

//Other
import {T} from 'hsi/i18n';

//The component
export default function ErrorMessage() {
    return (
        <>
            <span>{T('managetagsdialog.errors.tagExists.main')}</span>
            <br />
            {T('managetagsdialog.errors.tagExists.secondary')}
        </>
    );
}
