import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import {registerFilterType, getBlankObj, checkUpdate} from './../utils';

registerFilterType(
    'checkboxes',
    getBlankObj,
    (filterConfig, filterState, ignored = false) => {
        if (isEmpty(filterState)) {
            return true;
        }

        const isIgnoreMode = ignored && filterConfig.ignoreIfAllSelected;
        let numSelected = 0; //only used if isIgnoreMode = true

        for (let i = 0; i < filterConfig.options.length; i++) {
            const option = filterConfig.options[i];
            const value = isObject(option) ? option.value : option;

            if (filterState[value]) {
                if (isIgnoreMode) {
                    numSelected++;
                } else {
                    return false;
                }
            }
        }

        return isIgnoreMode
            ? numSelected === 0 || numSelected === filterConfig.options.length
            : true;
    },
    {
        //reducers
        toggleIsCheckboxChecked: (state, {payload: {filterName, value}}) => {
            //TODO validate - is correct filter type + is value valid?

            state.filters[filterName][value] = !state.filters[filterName][value];

            checkUpdate(state);
        },
    },
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    (filterConfig, currentValue, persistedValue) => {
        if (isObject(persistedValue)) {
            const values = Object.values(persistedValue);

            if (values.every((val) => val === true || val === false)) {
                const keys = Object.keys(persistedValue);

                if (
                    keys.every(
                        (key) =>
                            !!filterConfig.options.find(
                                (option) => option === key || option?.value.toString() === key,
                            ),
                    )
                ) {
                    return {
                        ...persistedValue,
                    };
                }
            }
        }

        return currentValue;
    },
);
