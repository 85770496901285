import React, {useMemo, useCallback} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

//Components
import IconRouter from 'hsi/components/IconRouter';
import Markdown, {linkAsButtonRendererFactory} from 'hsi/components/Markdown';

import useEventTrack from 'hsi/hooks/useEventTrack';
import {openSaveSearchDialog} from 'hsi/actions/savedSearchActions';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Constants
import {MAX_QUICK_SEARCH_LENGTH} from 'hsi/constants/config';

//The component
const CharLimitErrorMsg = ({className, customStyle}) => {
    const cl = useStyles();
    const {track} = useEventTrack();

    const dispatch = useDispatch();
    const {isViewOnly} = useSelector((state) => state.user);

    const handleClick = useCallback(() => {
        track('saveSearchDialogToggled', {
            state: 'open',
        });
        dispatch(openSaveSearchDialog(true));
    }, [dispatch, track]);

    const markdownRenderers = useMemo(
        () => ({
            a: linkAsButtonRendererFactory(() => handleClick),
        }),
        [handleClick],
    );

    const msg = useMemo(() => {
        return (
            !isViewOnly && (
                <Markdown components={markdownRenderers} classes={cl}>
                    {T('saveSearchTooltip.characterLimitError', {
                        linkText: T('saveSearchTooltip.link'),
                        linkUrl: '#',
                    })}
                </Markdown>
            )
        );
    }, [cl, isViewOnly, markdownRenderers]);

    return (
        <div className={cn(cl.wrapper, className)} style={customStyle}>
            <div className="hsib-icon-holder">
                <IconRouter name="warning" className={cl.icon} />
            </div>
            <div className={cl.message}>
                <div>
                    {T('queryBuilder.characterLimitError', {
                        characterLimit: MAX_QUICK_SEARCH_LENGTH,
                    })}
                </div>
                {msg}
            </div>
        </div>
    );
};

CharLimitErrorMsg.propTypes = {
    className: PropTypes.string,
    customStyle: PropTypes.object,
};

export default CharLimitErrorMsg;
export {CharLimitErrorMsg};
