//////////////////
// API to State //
//////////////////

import {
    APIFilterFormat,
    FilterConfig,
    FilterConfigByName,
    FiltersState,
    FromApiFormatData,
} from 'hsi/types/filters';

export default function apiFormatToFilterState(
    apiState: APIFilterFormat,
    allFiltersConfig: FilterConfigByName,
    definitions: FromApiFormatData,
): FiltersState {
    try {
        return Object.values(allFiltersConfig).reduce(
            (output: FiltersState, filterConfig: FilterConfig) => {
                if (filterConfig?.fromAPI) {
                    output[filterConfig.filterName] = filterConfig.fromAPI(
                        apiState,
                        filterConfig.filterName as keyof APIFilterFormat,
                        definitions,
                    );
                }

                return output;
            },
            {},
        );
    } catch (e) {
        if (process.env.NODE_ENV === 'development') {
            console.error('apiFormatToFilterState failed!');
            console.log(apiState);
            console.log(allFiltersConfig);
            console.log(definitions);
            console.log(e);
        }
    }

    return {}; //not ideal
}
