import React, {useRef} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import IconRouter from 'hsi/components/IconRouter';
import PulseLoader from 'hsi/components/PulseLoader';

import useUntilVisible from 'hsi/containers/MentionsTable/useUntilVisible';

import useStyles from './styles';

export const Datum = ({classes, col, datum, tz}) => (
    <>
        {col.dataKeys.map((dataKey, i) => {
            const d = datum[dataKey];
            const parser = col?.parsers?.[dataKey];
            const content = parser ? parser(d, tz) : d;
            return (
                <div className={classes.datumItem} key={i}>
                    {col.id === 'date' ? (
                        <a href={datum.originalUrl} target="blank">
                            {content}
                        </a>
                    ) : (
                        content
                    )}
                </div>
            );
        })}
    </>
);

Datum.propTypes = {
    classes: PropTypes.object.isRequired,
    col: PropTypes.shape({
        dataKeys: PropTypes.array.isRequired,
        id: PropTypes.string.isRequired,
        parsers: PropTypes.object,
    }).isRequired,
    datum: PropTypes.object.isRequired,
    tz: PropTypes.string.isRequired,
};

export const Cell = ({
    children,
    classes,
    columnId,
    columnIndex,
    onClick,
    role = 'cell',
    sort,
    width,
}) => (
    <div
        className={classes.cell}
        data-column-id={columnId}
        data-column-index={columnIndex}
        onClick={!onClick ? null : onClick}
        role={role}
        aria-sort={sort}
        style={{width: `${width}%`}}
    >
        {children}
    </div>
);

Cell.propTypes = {
    children: PropTypes.any.isRequired,
    classes: PropTypes.object.isRequired,
    columnId: PropTypes.string.isRequired,
    columnIndex: PropTypes.number,
    onClick: PropTypes.func,
    role: PropTypes.string,
    sort: PropTypes.string,
    width: PropTypes.number.isRequired,
};

export const Header = ({classes, columns, onClick, sort}) => {
    const isSorted = (id) => sort?.criteria.toString() === id.toString();
    const currentSort = (id) => (isSorted(id) ? sort.orders[0] : undefined);

    return (
        <div className={classes.headerContainer} role="rowgroup">
            <div className={classes.header} role="row">
                {columns?.map(({id, width}, i) => (
                    <Cell
                        classes={classes}
                        columnId={id}
                        columnIndex={i}
                        key={i}
                        onClick={() => onClick(id)}
                        role="columnheader"
                        sort={
                            currentSort(id) === 'desc'
                                ? 'descending'
                                : currentSort(id) === 'asc'
                                ? 'ascending'
                                : 'none'
                        }
                        width={width}
                    >
                        {id}
                        {currentSort(id) === 'desc' && (
                            <IconRouter
                                className={classes.sortArrow}
                                name="down-arrow"
                                role="presentation"
                            />
                        )}
                        {currentSort(id) === 'asc' && (
                            <IconRouter
                                className={classes.sortArrow}
                                name="up-arrow"
                                role="presentation"
                            />
                        )}
                    </Cell>
                ))}
            </div>
        </div>
    );
};

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            dataKeys: PropTypes.array.isRequired,
            id: PropTypes.string.isRequired,
            parsers: PropTypes.object,
        }).isRequired,
    ).isRequired,
    onClick: PropTypes.func.isRequired,
    sort: PropTypes.object,
};

export const RowContent = ({classes, columns, datum, tz}) =>
    columns.map((col, i) => (
        <Cell key={i} classes={classes} columnId={col.id} width={col.width}>
            <Datum classes={classes} col={col} datum={datum} tz={tz} />
        </Cell>
    ));

RowContent.propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            dataKeys: PropTypes.array.isRequired,
            id: PropTypes.string.isRequired,
            parsers: PropTypes.object,
        }).isRequired,
    ).isRequired,
    datum: PropTypes.object.isRequired,
    tz: PropTypes.string.isRequired,
};

export const Row = ({classes, columns, datum, index, tz}) => (
    <div
        className={cn(
            classes.row,
            index % 2 === 0 && classes.rowHighlight,
            index === 0 && classes.firstRow,
        )}
        data-testid="row"
        role="row"
    >
        <RowContent classes={classes} columns={columns} datum={datum} tz={tz} />
    </div>
);

Row.propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            dataKeys: PropTypes.array.isRequired,
            id: PropTypes.string.isRequired,
            parsers: PropTypes.object,
        }).isRequired,
    ).isRequired,
    datum: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
};

export const LastRow = ({classes, columns, datum, index, onVisible, tz}) => {
    const ref = useRef();
    useUntilVisible({ref, onVisible});
    return (
        <div
            className={cn(classes.row, index % 2 === 0 && classes.rowHighlight)}
            data-testid="lastRow"
            ref={ref}
            role="row"
        >
            <RowContent classes={classes} columns={columns} datum={datum} tz={tz} />
        </div>
    );
};

LastRow.propType = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            dataKeys: PropTypes.array.isRequired,
            id: PropTypes.string.isRequired,
            parsers: PropTypes.object,
        }).isRequired,
    ).isRequired,
    datum: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onVisible: PropTypes.func.isRequired,
    tz: PropTypes.string.isRequired,
};

export const Loader = ({classes}) => (
    <div className={classes.loader}>
        <PulseLoader size="small" />
    </div>
);

Loader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export const Table = ({columns, hasMoreData, loadMore, onHeaderClick, rows, sort, tz}) => {
    const classes = useStyles();

    return (
        <div className={classes.table} role="table">
            <Header
                classes={classes}
                columns={columns}
                onClick={(id) => onHeaderClick({columnId: id})}
                sort={sort}
            />
            <div role="rowgroup">
                {rows.map((item, i, allRows) => {
                    const BaseRow = hasMoreData && i === allRows.length - 1 ? LastRow : Row;
                    return (
                        <BaseRow
                            classes={classes}
                            columns={columns}
                            datum={item}
                            key={`${item.id}-${i}`}
                            index={i}
                            onVisible={loadMore}
                            tz={tz}
                        />
                    );
                })}
                {hasMoreData && <Loader classes={classes} />}
            </div>
        </div>
    );
};

Table.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            dataKeys: PropTypes.array.isRequired,
            id: PropTypes.string.isRequired,
            parsers: PropTypes.object,
        }).isRequired,
    ).isRequired,
    hasMoreData: PropTypes.bool.isRequired,
    loadMore: PropTypes.func.isRequired,
    onHeaderClick: PropTypes.func.isRequired,
    rows: PropTypes.array.isRequired,
    sort: PropTypes.object,
    tz: PropTypes.string.isRequired,
};

export default Table;
