const colors = {
    //ALL NEW COLORS
    carbon: '#3F3F3F',
    //uigrays
    uiGrayDarker: '#E4E4E4',
    uiGrayDark: '#EEEEEE',
    uiGrayDefault: '#F8F8F8',
    uiGrayPopupBorder: '#CBCBCB',
    //accent
    accentActive: '#1691CF',
    accentDefault: '#239EDB',
    accentHover: '#30ABE8',
    //black and whites
    black: '#212121',
    white: '#FFFFFF',
    // borders
    borders: 'rgba(63, 63, 63, 0.2)',
    //text
    textDefault: 'rgba(63, 63, 63, 1)',
    textSubtle: 'rgba(63, 63, 63, 0.75)',
    textDisabled: 'rgba(63, 63, 63, 0.3)',
    textHyperlink: '#239EDB',
    //background
    bgDefault: 'rgba(63, 63, 63, 1)',
    bgOverlayHeavy: 'rgba(63, 63, 63, 0.75)',
    bgOverlayLight: 'rgba(63, 63, 63, 0.3)',
    bgSubtle: 'rgba(63, 63, 63, 0.15)',
    bgActive: 'rgba(63, 63, 63, 0.08)',
    bgDisabled: 'rgba(63, 63, 63, 0.05)',
    bgHover: 'rgba(63, 63, 63, 0.04)',
    //
    error: '#FC4870',
    success: '#00BE96',
    warning: '#DAAE45',
    info: '#DCF0F9',
    //TODO: remove OLD COLORS when not referenced anywhere
    lightGrey100: '#E3DDD8',
    lightGrey80: '#E9E4E0',
    lightGrey60: '#EEEBE8',
    lightGrey50: '#DCD5D0',
    lightGrey40: '#F4F1EF',
    lightGrey20: '#F9F8F7',
    lightGrey10: '#FCFCFB',
    darkGrey100: '#3f3f3f',
    darkGrey80: '#504C4D',
    darkGrey60: '#7C797A',
    darkGrey40: '#A7A5A6',
    darkGrey20: '#D3D2D3',
    darkGrey10: '#E9E9E9',
    secondaryGreen100: '#28863E',
    secondaryGreen80: '#539E65',
    secondaryGreen60: '#7EB68B',
    secondaryGreen40: '#A9CFB2',
    secondaryGreen20: '#D4E7D8',
    secondaryGreen10: '#EAF3EC',
    secondaryPurple100: '#239edb', // accent
    secondaryPurple80: '#766499',
    secondaryPurple60: '#988BB3',
    secondaryPurple40: '#BBB1CC',
    secondaryPurple20: '#DDD8E6',
    secondaryPurple10: '#EEECF2',
    primaryRed100: '#BA3038',
    primaryRed80: '#C85960',
    primaryRed60: '#D68388',
    primaryRed40: '#E3ACAF',
    primaryRed20: '#F1D6D7',
    primaryRed10: '#F8EAEB',
    secondaryOrange100: '#239edb',
    secondaryOrange80: '#F58C4D',
    secondaryOrange60: '#F8A97A',
    secondaryOrange40: '#FAC6A6',
    secondaryOrange20: '#FDE2D3',
    secondaryOrange10: '#FEF1E9',
    secondaryBlue100: '#2F6B9A', // replaced links colors
    secondaryBlue80: '#5989AE',
    secondaryBlue60: '#82A6C2',
    secondaryBlue40: '#ACC4D7',
    secondaryBlue20: '#D5E1EB',
    secondaryBlue10: '#EAF0F5',
    primaryYellow100: '#FBA919', // background for primary buttons
    primaryYellow80: '#FCBB47',
    primaryYellow60: '#FCCC75',
    primaryYellow40: '#FDDDA3',
    primaryYellow20: '#FEEED1',
    primaryYellow10: '#FFF6E8', // accent for hover rows
    primaryBlue100: '#239edb', //primary accents color, like button texts or chart lines.
    primaryBlue80: '#43597A',
    primaryBlue60: '#72839B',
    primaryBlue40: '#dcdcdc',
    primaryBlue20: '#e4e4e4', // home background
    primaryBlue10: '#E8EAEE',
    recentSearchesBg: '#f8f8f8', // as bcr dashboard container
    icons: '#239edb',
    buttonHover: '#dcdcdc',
    // sentiment
    positive: '#4BA15A',
    neutral: '#BBB9C0',
    negative: '#D74A4A',

    // emotion
    anger: '#E35A7A',
    disgust: '#906BA7',
    fear: '#F99131',
    joy: '#FFBE0B',
    sadness: '#6163A1',
    surprise: '#8AC539',

    // gender
    female: '#A72E5F',
    male: '#3B79CC',
    //special ones
    fbcolor: '#1877F2',
    //made this one up. TODO: ask HSI
    fbcolorActive: '#659ef6',
    snackBarCloseButtonBg: '#f5f8f5',
    keyline: '#0D2F5C',
    textMeta: '#2F3538',

    //platforms
    linkedin: '#0A66C2',
    linkedinHovered: '#1974cf',
} as const;

export default colors;
