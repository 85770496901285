import useStyles from './styles';
import { polymorphicForwardRef } from 'hsi/types/react-polymorphic';
import classNames from 'classnames';



export default polymorphicForwardRef<{}, 'div'>(function PopoverDisplay({as: Component = 'div', children, className, ...rest}, ref) {
    const classes = useStyles();

    return <Component className={classNames(classes.popover, className)} {...rest} ref={ref}>
        {children}
    </Component>
});
