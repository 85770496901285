import Polyglot from 'node-polyglot';
import {configByAppSource} from 'hsi/config/configByAppSource';
import en from './en.js';
import bw_en from './bw-en.js';
import falcon_en from './falcon-en.js';
import cision_en from './cision-en.js';

//Add multi platform and multi language support
let lang = 'en';

const phrases = configByAppSource(
    {
        HSI: en,
        FALCON: falcon_en,
        C3: cision_en,
        NGC3: cision_en,
        LUQI: cision_en,
        C1: cision_en,
    },
    bw_en,
);

const polyglot = new Polyglot({phrases, locale: lang});

export function T(id, vars) {
    if (polyglot.has(id)) {
        return polyglot.t(id, vars);
    } else {
        return new Polyglot({phrases: en, locale: 'en'}).t(id, vars);
    }
}

export function CT(val) {
    if (typeof val === 'string') {
        return val;
    } else if ('id' in val) {
        return T(val.id, val.vars);
    }

    return '';
}
