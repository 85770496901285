import React from 'react';
import PropTypes from 'prop-types';

import img from 'hsi/img/401cat.png';
import tns from 'hsi/i18n/tns';

import useConfig from 'hsi/hooks/useConfig';

import useStyles from './styles';

const t = tns('unauthorized');

const getErrorMsg = (code, errors) => {
    switch (code) {
        case 'AUTOPROVISION_TIMEOUT':
            return t('autoprovisionTimeoutMsg');
        case 'AUTOPROVISION_ERROR':
            return t('autoprovisionErrorMsg');
        case 'NO_DEFAULT_PROJECT_ID':
            return errors[0];
        default:
            return t('defaultMsg');
    }
};

// TODO: Create a HSI specific component
const AuthErrorPage = ({code, errors, status}) => {
    const classes = useStyles();
    const {appSource} = useConfig();

    return (
        <div className={classes.root}>
            {appSource === 'HSI' && <img alt="" className={classes.img} src={img} />}
            <h1 className={classes.title}>{t('title')}</h1>
            <div className={classes.msg}>{getErrorMsg(code, errors)}</div>
        </div>
    );
};

AuthErrorPage.propTypes = {
    code: PropTypes.string,
    errors: PropTypes.array,
    status: PropTypes.number,
};

export default AuthErrorPage;
