export function removeValue(arr: any[], value: number) {
    const index = arr.indexOf(value);

    if (value === -1) {
        return arr;
    }

    return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

//shifts the elements in an array by num (must be an integer)
// returns new array with shifted values
//e.g.
// arrayRotate([1,2,3,4,5], -2) -> [4, 5, 1, 2, 3]
// arrayRotate([1,2,3,4,5], 2) -> [3, 4, 5, 1, 2]

export function arrayRotate(arr: any[], count: number) {
    arr = [...arr];
    count -= arr.length * Math.floor(count / arr.length);
    arr.push.apply(arr, arr.splice(0, count));

    return arr;
}

export function makeNumberList(start: number, end: number, step: number = 1) {
    const output: number[] = [];

    if  (start === end || step === 0) {
        return output;
    }

    if  (start < end && step < 0) {
        throw new Error('If start is less than end, step must be positive');
    }

    if  (start > end && step > 0) {
        throw new Error('If start is greater than end, step must be negative');
    }

    if  (step > 0) {
        for  (let i = start; i < end; i += step) {
            output.push(i);
        }
    } else {
        for  (let i = start; i > end; i += step) {
            output.push(i);
        }
    }

    return output;
}

//Tests if two arrays contain extractly the same values (reference equality), regardless of order e.g.:
// [1,2,3] and [2,3,1] = yes
// [1,1,2] and [1,2] = no
export function sameValues(arr1: any[], arr2: any[]) {
    if (arr1.length !== arr2.length) {
        return false;
    }

    const arr1test = arr1.slice().sort();
    const arr2test = arr2.slice().sort();

    return !arr1test.some((val, idx) => val !== arr2test[idx]);
}

export function isArray(arr: any[]) {
    return !!arr && Array.isArray(arr) && !!arr?.length;
}

export function arraysEqual<T>(a: T[], b: T[]) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
  
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }