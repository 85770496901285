import {useCallback, useMemo, useRef} from 'react';
import classnames from 'classnames';

//Hooks
import useUniqueId from 'hsi/hooks/useUniqueId';

//Other
import {polymorphicForwardRef} from 'hsi/types/react-polymorphic';

import useStyles from '../styles';
import {assignRef} from 'hsi/utils/react';

import {useHorizontalPositionedContext} from '..';
import { HorizontalPositionedItemContext } from './context';


//Types
type HorizontalPositionedColumnsItemProps = {};

const HorizontalPositionedColumnsWrapperItem = polymorphicForwardRef<
    HorizontalPositionedColumnsItemProps,
    'div'
>(function HorizontalPositionedColumnsItem(
    {as: Component = 'div', className, onError, id: idParam, children, ...rest},
    ref,
) {
    const localRef = useRef<HTMLElement | null>();
    const id = useUniqueId(idParam);
    const {setItemHeight, setItemRef, id: parentId} = useHorizontalPositionedContext() || {};

    const updateSizeEvent = useCallback(
        (evt: any) => {
            evt.currentTarget.clientHeight && setItemHeight?.(id, evt.currentTarget.clientHeight);
            onError?.(evt);
        },
        [onError, setItemHeight, id],
    );

    const setRef = useCallback(
        (elem: HTMLElement | null) => {
            if (elem) {
                //add listener for video/image loaded events
                elem.addEventListener('loadeddata', updateSizeEvent, true);
                elem.addEventListener('load', updateSizeEvent, true);
            }

            setItemRef?.(id, elem); //record this ref in the parent component

            assignRef(ref, elem);
            localRef.current = elem;
        },
        [setItemRef, id, ref, updateSizeEvent],
    );

    const horizontalPositionedItemContextValue = useMemo(() => ({
        updateSize: () => {
            localRef.current && setItemHeight?.(id, localRef.current.clientHeight);
        }
    }), [id, setItemHeight]);

    const classes = useStyles();

    return (
        <Component
            {...rest}
            onError={updateSizeEvent}
            ref={setRef}
            className={classnames(classes.item, className)}
            data-hpc-id={parentId}
            id={id}
        ><HorizontalPositionedItemContext.Provider value={horizontalPositionedItemContextValue}>
            {children}
        </HorizontalPositionedItemContext.Provider></Component>
    );
});

export default HorizontalPositionedColumnsWrapperItem;

