import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({colors, fonts, v2}: any) => ({
    card: {
        backgroundColor: v2 ? colors.white : colors.lightGrey20,
        border: `1px solid ${colors.borders}`,
        borderRadius: v2 ? 5 : 0,
        color: v2 ? colors.carbon : colors.textDefault,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: v2 ? 212 : 220,
        width: v2 ? 207 : 212,
        padding: 10,

        '& .MuiCardContent-root': {
            padding: 0,
            display: 'flex',
            flexWrap: 'wrap',
        },
    },
    cardTitle: {
        height: 'auto',
        padding: v2 ? '0px 0px 9px 0px' : '9px 0px',
        ...(v2 ? fonts.bodyLargeStrong : {fontSize: 16, fontWeight: 700}),
        flex: '0 0 100%',
    
    },
    cardDesc: {
        ...(v2
            ? fonts.bodyLargeNormal
            : {
                  fontSize: 16,
                  fontWeight: 400,
              }),
        flex: '0 0 100%',
    },
}));
