import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>(({colors, font, mixin, fonts, v2}) => ({
    wrapper: {
        minHeight: 40,
    },
    fbButton: {
        height: 40,
        width: '100%',
        backgroundColor: colors.fbcolor,
        color: colors.white,
        ...(v2 ? fonts.bodyLargeStrong : {fontSize: 14, fontWeight: font.semiBold}),

        '&:active': {
            backgroundColor: colors.fbcolorActive,
        },
        //TODO all static CSS classes need to go. I have no idea what this is referring to, and no real way to find out, short of searching for EVERY SINGLE instance of the word 'loading' (which is, incidentally, how I found this).
        //As well as needing to go, I also suspect this is in fact wrong, and these styles have stopped working. However, I currently don't really know how to test the facebook stuff soo *shrug*
        '& .loading': {
            backgroundColor: colors.primaryBlue10,
        },
        '&:focus-visible': {
            outline: v2
                ? `3px solid ${colors.accentActive}`
                : `3px solid ${colors.secondaryOrange100}`,
        },
    },
    facebookIcon: {
        ...mixin.icon.normalSize,
        fill: colors.white,
        marginRight: 8,
        position: 'relative',
        top: -2,
    },
    loading: {},
}));
