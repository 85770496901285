import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import {renderComponents} from './baseComponents';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    container: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        paddingBottom: 30,
    },
    title: {
        marginTop: '20px',
    },
});

const Section = ({className, components, formula, name: sectionName, onChange, title}) => {
    const cl = useStyles();
    return (
        <div className={cn(className, cl.container)}>
            <div className={cl.title}>{title}</div>
            {renderComponents({
                components,
                sectionName,
                formula,
                onChange,
            })}
        </div>
    );
};

Section.propTypes = {
    className: PropTypes.string,
    components: PropTypes.array.isRequired,
    formula: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    title: PropTypes.string,
};

export default Section;
