import makeStyles from '@mui/styles/makeStyles';
import bg from 'hsi/img/homepage_graphic.svg';

export default makeStyles((theme) => ({
    page: {
        minHeight: '100vh',
        zIndex: 1,
    },
    '@media (max-width: 999px)': {
        page: {
            background: 'none',
        },
    },
    '@media (min-width: 1000px) and (max-width: 1240px)': {
        page: {
            background: `url('${bg}') no-repeat top 0 right -100px`,
        },
    },
    '@media (min-width: 1240px) and (max-width: 1379px)': {
        page: {
            background: `url('${bg}') no-repeat top 0 right -50px`,
        },
    },
    '@media (min-width: 1380px)': {
        page: {
            background: `url('${bg}') no-repeat top right`,
        },
    },

    title: {
        fontSize: 24,
        marginBottom: 16,
    },

    subtitle: {
        paddingBottom: 8,
    },

    formContainer: {
        maxWidth: '405px',
        minWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 30,
    },

    form: {
        marginTop: '25%',
    },

    inputBox: {
        paddingTop: 16,
    },

    textField: {
        marginBottom: '10px',
    },

    logo: {
        width: 92,
    },

    btnHolder: {
        margin: '15px 0',
        textAlign: 'left',
    },
}));
