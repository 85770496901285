import {ChangeEvent, useCallback, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';

//Components
import AutocompleteOption from './Option';
import Autocomplete, {AutocompleteProps, InlinePopperComponent} from 'hsi/components/Autocomplete';
import FullscreenLoading from 'hsi/components/FullscreenLoading';

//Hooks
import {useAppSelector} from 'hsi/hooks/useRedux';
import useUniqueId from 'hsi/hooks/useUniqueId';

//Other
import useStyles from './styles';
import {T} from 'hsi/i18n';

//Types
import {TagDefinition} from 'hsi/types/filters';
import flatten from 'lodash/flatten';
import { AutocompleteChangeReason, AutocompleteInputChangeReason } from '@mui/material/useAutocomplete';

export type AddOrCreateTagProps = {
    currentTags: string[];
    onAddTag: (tagName: string) => void;
    onCreateAndAddTag: (name: string) => void;
    onClearSelectedTag?: () => void;
    floatingOptions?: boolean;
    pending?: boolean;
    disabled?: boolean;
    autocompleteClasses?: AutocompleteProps<AddOrCreateOption, false, false, true>['classes'];
    autoSelect?: boolean;
    required?: boolean;
} & JSX.IntrinsicElements['div'];

export type AddOrCreateOption = {id: number; label: string; isNew: boolean};

//Consts
const NEW_TAG: AddOrCreateOption = {id: -1, label: '', isNew: true};

//The component
export default function AddOrCreateTag({
    className,
    autocompleteClasses: _autocompleteClasses,
    currentTags,
    onAddTag,
    onCreateAndAddTag,
    onClearSelectedTag,
    floatingOptions = false,
    disabled = false,
    pending = false,
    autoSelect = false,
    required = false,
    'aria-errormessage': ariaErrorMessage,
    id: _id,
    ...rest
}: AddOrCreateTagProps) {
    const id = useUniqueId(_id, 'addOrCreateTag');
    const {classes} = useStyles();
    const allTags: TagDefinition[] = useAppSelector((state) => state.tags.results);
    const valueRef = useRef('');
    const rootRef = useRef<HTMLDivElement | null>(null);

    const [inputValue, setInputValue] = useState('');

    const currentTagsSet = useMemo(() => new Set(currentTags), [currentTags]);
    const allTagsSet = useMemo(
        () => new Set(allTags.filter((tag) => !!tag.name).map((tag) => tag.name!.toLowerCase())),
        [allTags],
    ) as Set<string>;

    const options = useMemo(() => {
        return [
            NEW_TAG,
            ...allTags
                .filter((tag) => !!tag.name)
                .map((tag) => ({id: tag.id, label: tag.name!, isNew: false})),
        ];
    }, [allTags]);

    const autocompleteClasses = useMemo(
        () =>
            floatingOptions
                ? _autocompleteClasses
                : mergeClassesObjs({paper: classes.inlinePaper}, _autocompleteClasses),
        [classes, floatingOptions, _autocompleteClasses],
    );

    //currently applied tags are disabled
    const getOptionDisabled = useMemo(() => {
        return (option: AddOrCreateOption) => {
            return !option.isNew && currentTagsSet.has(option.label);
        };
    }, [currentTagsSet]);

    //Callbacks
    const onChange = useCallback(
        (_e: any, tag: null | string | AddOrCreateOption, reason: AutocompleteChangeReason) => {
            if (reason === 'clear') {
                onClearSelectedTag?.();
            } else if (
                reason === 'selectOption' ||
                reason === 'blur' ||
                reason === 'createOption'
            ) {
                if (!tag || (reason === 'blur' && valueRef.current === '')) {
                    return; //do not autoselect if nothing has been entered
                }

                const tagName =
                    typeof tag === 'string' ? tag : tag.isNew ? valueRef.current : tag.label;
                setInputValue(tagName);

                if (!allTagsSet.has(tagName.toLowerCase())) {
                    onCreateAndAddTag(tagName);
                } else {
                    const normalisedTag = allTags.find(
                        ({name}) => name?.toLowerCase() === tagName.toLowerCase(),
                    )!;
                    setInputValue(normalisedTag.name!);
                    onAddTag(normalisedTag.name!);
                }
            }
        },
        [onClearSelectedTag, allTagsSet, onCreateAndAddTag, allTags, onAddTag],
    );

    const filterOptions = useCallback(
        (options: AddOrCreateOption[], {inputValue}: {inputValue: string}) => {
            valueRef.current = inputValue;

            const filteredOptions = options.filter(
                (option) =>
                    option.isNew || option.label.toLowerCase().includes(inputValue.toLowerCase()),
            );

            //remove the 'NEW_TAG' option if inputValue is empty, or this is a match for an existing tag
            if (inputValue === '' || allTagsSet.has(inputValue.toLowerCase())) {
                return filteredOptions.slice(1);
            }

            return filteredOptions;
        },
        [allTagsSet],
    );

    const onInputChange = useCallback(
        (e: ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
            if (reason !== 'reset') {
                setInputValue(value);
            }
        },
        [],
    );

    /**
     * The Autocomplete component sadly swallows the 'escape' event, which prevents the dialog from closing
     * when it is pressed. This code is to re-create this event to allow the dialog to be closed correctly
     */
    const recreateEscEvent: NonNullable<
        AutocompleteProps<AddOrCreateOption, false, false, true>['onClose']
    > = useCallback((event, reason) => {
        if (reason === 'escape') {
            const target = rootRef.current;
            const fakeEvent = new KeyboardEvent('keydown', {
                key: 'Escape',
                code: 'Escape',
                bubbles: true,
            });

            target?.dispatchEvent(fakeEvent);
        }
    }, []);

    return (
        <div className={classNames(className, classes.addOrCreateTag)} {...rest} ref={rootRef}>
            {pending && (
                <FullscreenLoading
                    className={classes.loading}
                    message={T('addOrCreateTag.pending')}
                    offscreenMessage
                />
            )}
            <label htmlFor={id} className="offscreen">
                {T('addOrCreateTag.comboboxLbl')}
            </label>
            <Autocomplete
                id={id}
                open={floatingOptions ? undefined : true}
                PopperComponent={floatingOptions ? undefined : InlinePopperComponent}
                classes={autocompleteClasses}
                disabled={disabled || pending}
                onClose={floatingOptions ? undefined : recreateEscEvent}
                placeholder={T('addOrCreateTag.placeholder')}
                options={options}
                multiple={false}
                freeSolo={true}
                autoSelect={autoSelect}
                required={required}
                aria-errormessage={ariaErrorMessage}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
                getOptionDisabled={getOptionDisabled}
                isOptionEqualToValue={(option, value) => {
                    return option.label === value.label;
                }}
                filterOptions={filterOptions}
                onChange={disabled || pending ? undefined : onChange}
                renderOptionComponent={AutocompleteOption}
                //We need to take over control of the input value in order to disable the 'reset' behaviour
                //Where selecting a value
                onInputChange={onInputChange}
                inputValue={inputValue}
            />
        </div>
    );
}

function mergeClassesObjs<T extends Record<string, string> = Record<string, string>>(
    ...classesObjs: (Partial<T> | undefined)[]
): Partial<T> {
    const filteredClassesObjs = classesObjs.filter(
        (classesObj) => classesObj !== undefined,
    ) as Partial<T>[];
    const allKeys = new Set(
        flatten(filteredClassesObjs.map((classesObj) => Object.keys(classesObj))),
    );

    const mergedClassesObj: Partial<T> = {};

    allKeys.forEach((key) => {
        mergedClassesObj[key as unknown as keyof T] = classNames(
            ...filteredClassesObjs.map((classesObj) => classesObj[key]),
        ) as any;
    });

    return mergedClassesObj;
}
