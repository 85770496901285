/* eslint-disable react-hooks/exhaustive-deps */
import {DependencyList, useMemo, useRef} from 'react';
import {shallowEqual} from 'react-redux';

//The hook, like useMemo, but can also check the returned value to see if it has actually changed,
//using a keepPreviousValueTest, which defaults to shallowEqual (will also count reference equality as equal,
//regardless of keepPreviousValueTest)
//replaceWithRefFuncCheck optional, called for each prop, called with key/index and value, if it returns true,
//replaces value with ref function
export default function useMemoCompare<T extends {} | []>(
    memoFunc: () => T,
    deps: DependencyList,
    keepPreviousValueTest?: (value: T, previousValue: T | undefined) => boolean,
    replaceWithRefFuncCheck?: <TKey extends keyof T>(key: TKey, value: T[TKey]) => boolean,
): T;

export default function useMemoCompare<T>(
    memoFunc: () => T,
    deps: DependencyList,
    keepPreviousValueTest: (value: T, previousValue: T | undefined) => boolean,
): T;

export default function useMemoCompare<T>(
    memoFunc: () => T,
    deps: DependencyList,
    keepPreviousValueTest: (value: T, previousValue: T | undefined) => boolean = shallowEqual,
    replaceWithRefFuncCheck?: <TKey extends keyof T>(key: TKey, value: T[TKey]) => boolean,
) {
    const lastRef: any = useRef();
    const proxyRef: any = useRef();

    if (replaceWithRefFuncCheck && !proxyRef.current) {
        proxyRef.current = {
            raw: {},
            proxy: {},
        };
    }

    let value = useMemo<T>(memoFunc, deps);

    if (replaceWithRefFuncCheck) {
        value = (value instanceof Array ? [...value] : {...value}) as T; //clone it

        Object.keys(value as {}).forEach((key: string) => {
            const val = value[key as keyof T];

            if (replaceWithRefFuncCheck(key as keyof T, val)) {
                proxyRef.current.raw[key] = val;

                if (!proxyRef.current.proxy[key]) {
                    proxyRef.current.proxy[key] = (...args: any[]) =>
                        proxyRef.current.raw[key](...args);
                }

                value[key as keyof T] = proxyRef.current.proxy[key];
            }
        });
    }

    if (value === lastRef.current || keepPreviousValueTest(value, lastRef.current)) {
        return lastRef.current;
    } else {
        lastRef.current = value;
        return value;
    }
}
