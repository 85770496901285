/* eslint-disable react/style-prop-object */
import Avatar from 'hsi/components/Avatar';
import PlatformIcon from 'hsi/components/PlatformIcons';
import SiteFavicon from 'hsi/components/SiteFavicon';
import Tooltip from 'hsi/components/Tooltip';
import TruncateWithTooltip from 'hsi/components/TruncateWithTooltip';
import TextList from 'hsi/components/format/TextList';

import useStyles from './styles';

import {inferPlatformFromDomain} from 'hsi/utils/platform';
import {ReactNode} from 'react-markdown';
import { T } from 'hsi/i18n';

type AuthorAvatarArgs = {
    avatarUrl?: string;
    domain?: string;
    miniIcon?: ReactNode;
    classes: ReturnType<typeof useStyles>;
};

function AuthorAvatar({avatarUrl, domain, miniIcon, classes: cl}: AuthorAvatarArgs) {
    const avatarContent = (
        <Avatar
            className={cl.avatar}
            src={avatarUrl}
            platformIcon={miniIcon}
            onClick={(e) => e?.stopPropagation()}
        />
    );

    return domain 
        ? <Tooltip tooltip={domain} noAria portal>{avatarContent}</Tooltip> 
        : avatarContent;
};

type AuthorDetailsArgs = {
    displayName: string;
    countryName?: string;
    classes: ReturnType<typeof useStyles>;
};

const AuthorDetails = ({displayName, countryName, classes}: AuthorDetailsArgs) => (
    <div className={classes.authorDetails}>
        <TruncateWithTooltip portal className={classes.authorName} tooltip={displayName}>{displayName}</TruncateWithTooltip>
        {countryName && <TruncateWithTooltip portal className={classes.location} tooltip={countryName}>{countryName}</TruncateWithTooltip>}
    </div>
);

type AuthorCellArgs = {
    displayName: string;
    domain: string;
    avatarUrl?: string;
    countryName: string;
};

export default function AuthorCell({displayName, domain, avatarUrl, countryName}: AuthorCellArgs) {
    const platform = inferPlatformFromDomain(domain);
    const hasPlatformIcon = !!platform;
    const hasAvatar = !!(avatarUrl || hasPlatformIcon || domain);
    const announceDomain = !!domain && domain !== displayName;

    const cl = useStyles();

    const miniIcon = hasAvatar ? (
        hasPlatformIcon ? (
            <PlatformIcon aria-hidden name={platform} width="20" height="20" />
        ) : (
            <SiteFavicon aria-hidden domain={domain} className={cl.favicon} />
        )
    ) : undefined;

    return (
        <>
            <div className={cl.container} aria-hidden>
                {hasAvatar && (
                    <AuthorAvatar
                        avatarUrl={avatarUrl}
                        domain={domain}
                        miniIcon={miniIcon}
                        classes={cl}
                    />
                )}
                <AuthorDetails displayName={displayName} countryName={countryName} classes={cl} />
            </div>
            <div className="offscreen">
                {(!!countryName || announceDomain )
                    ?   <TextList type="unit">
                            {T('cards.topauthors.authorNameA11yDesc', {name: displayName})}
                            {countryName && T('cards.topauthors.authorLocationA11yDesc', {location: countryName})}
                            {announceDomain && T('cards.topauthors.authorDomainA11yDesc', {domain})}
                        </TextList>
                    : displayName
                }
            </div>
        </>
        
    );
};
