import makeStyles from '@mui/styles/makeStyles';
import { Theme } from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        gap: '4px',
    },

    number: {
        flex: '0 0 35px',
    },

    meter: {
        flex: `1 1 auto`,
    },
}));
