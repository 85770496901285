//Component
import Dialog, {DialogActions, DialogContent, DialogTitle} from 'hsi/components/Dialog';
import Button from 'hsi/components/Button';
import PendingModal from 'hsi/components/PendingModal';
import AddOrCreateTag from 'hsi/components/AddOrCreateTag';
import SuccessMessage from 'hsi/components/Notifications/TagSuccessMessage';
import ErrorMessage from 'hsi/components/Notifications/TagErrorMessage';

//Hooks
import {useAppSelector, useAppDispatch} from 'hsi/hooks/useRedux';
import useProjectId from 'hsi/hooks/useProjectId';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useIsActionPendingOnMentions from 'hsi/hooks/useIsActionPendingOnMentions';

//Actions
import {addMentionsTag} from 'hsi/slices/mentions';
import {saveTag} from 'hsi/actions/tagActions';
import {showNotification} from 'hsi/actions/notificationsActions';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';
import {useCallback, useMemo, useRef, useState} from 'react';
import Markdown from 'hsi/components/Markdown';
import FormError from 'hsi/components/FormError';
import {isFulfilled} from '@reduxjs/toolkit';

//Types
export type BulkEditTagsDialogProps = {
    isOpen?: boolean;
    onClose?: () => void;
    onClosed?: () => void;
};

//The component
export default function BulkEditTagsDialog({
    isOpen = false,
    onClose: _onClose,
    onClosed: _onClosed,
}: BulkEditTagsDialogProps) {
    const {classes} = useStyles();
    const dispatch = useAppDispatch();
    const projectId = useProjectId()!;
    const {trackWithSearchData} = useEventTrack();
    const mentionsStatePending = useAppSelector(
        (state) => state.mentions.loading || state.mentions.isBulkActionPending,
    );
    const selectedMentionsState = useAppSelector((state) => state.mentions.selectedMentions);

    //This is used to track the mentions that actually get changed,
    //as the process of changing sentiment can happen so fast that it
    //applies before this dialog completes it's closing transition
    const selectedMentionsAppliedRef = useRef<Record<string, boolean>>();
    const selectedMentions = selectedMentionsAppliedRef.current ?? selectedMentionsState;
    const numSelectedMentions = Object.keys(selectedMentions).length;

    const selectedMentionIds = useMemo(
        () => Object.keys(selectedMentions).filter((mentionId) => selectedMentions[mentionId]),
        [selectedMentions],
    );

    const mentionsHaveActionPending = useIsActionPendingOnMentions(selectedMentionIds);
    const loading = mentionsStatePending || mentionsHaveActionPending;

    //Internal state
    const [working, setWorking] = useState(false); //Is this dialog performing an action
    const [isNewTag, setIsNewTag] = useState<boolean | null>(null);
    const [tagName, setTagName] = useState('');
    const [showErrors, setShowErrors] = useState(false);

    const showTagNameError = tagName === '' && showErrors;

    //Callbacks
    const onClose = useMemo(() => {
        return _onClose
            ? () => {
                  !working && _onClose();
              }
            : undefined;
    }, [working, _onClose]);

    const onClosed = useCallback(() => {
        setWorking(false);
        setIsNewTag(null);
        setTagName('');
        setShowErrors(false);

        _onClosed?.();
    }, [_onClosed]);

    const saveChange = useCallback(
        async (tagName: string) => {
            if (!loading) {
                setWorking(true);

                const response = await dispatch(
                    addMentionsTag({ids: selectedMentionIds, tagName, projectId}),
                );

                setWorking(false);

                if (isFulfilled(response)) {
                    trackWithSearchData('bulkMentionTagEditComplete', {
                        selectedMentions: numSelectedMentions,
                        tag: tagName,
                    });
                }

                return true;
            }

            return false;
        },
        [
            dispatch,
            loading,
            numSelectedMentions,
            projectId,
            selectedMentionIds,
            trackWithSearchData,
        ],
    );

    const addNewAndSaveChange = useCallback(
        async (tagName: string) => {
            if (!loading) {
                const successMessage = <SuccessMessage value={tagName} />;
                const errorMessage = <ErrorMessage />;
                setWorking(true);

                const success = await dispatch(
                    saveTag(tagName, successMessage, errorMessage, projectId),
                );

                if (success) {
                    trackWithSearchData('tagCreated', {
                        tag: tagName,
                    });

                    return saveChange(tagName);
                } else {
                    dispatch(
                        showNotification({
                            message: T('mentionsContainer.bulkAddTagsDialog.addtagError'),
                            variant: 'warning',
                        }),
                    );
                }
            }

            return false;
        },
        [dispatch, loading, projectId, saveChange, trackWithSearchData],
    );

    const onSubmit = useCallback(async () => {
        if (tagName === '') {
            setShowErrors(true);
        } else {
            setShowErrors(false);

            if (isNewTag !== null) {
                if (isNewTag) {
                    await addNewAndSaveChange(tagName);
                } else {
                    await saveChange(tagName);
                }

                _onClose?.();
            }
        }
    }, [addNewAndSaveChange, isNewTag, _onClose, saveChange, tagName]);

    return (
        <Dialog
            aria-labelledby="bulkEditTags.title"
            aria-describedby="bulkEditTags.description"
            className={classes.root}
            open={isOpen}
            onClose={onClose}
            onClosed={onClosed}
            disableCloseBtn={working}
        >
            <PendingModal
                pending={loading || working}
                message={T('mentionsContainer.bulkAddTagsDialog.pending')}
                loaderSize="medium"
            >
                <div>
                    <DialogTitle id="bulkEditTags.title">
                        {T('mentionsContainer.bulkAddTagsDialog.title')}
                    </DialogTitle>
                    <DialogContent>
                        <Markdown id="bulkEditTags.description" classes={{p: classes.copy}}>
                            {T('mentionsContainer.bulkAddTagsDialog.description')}
                        </Markdown>
                        <AddOrCreateTag
                            currentTags={[]}
                            onAddTag={(tagName: string) => {
                                setTagName(tagName);
                                setIsNewTag(false);
                            }}
                            onCreateAndAddTag={(tagName: string) => {
                                setTagName(tagName);
                                setIsNewTag(true);
                            }}
                            onClearSelectedTag={() => {
                                setTagName('');
                                setIsNewTag(null);
                            }}
                            floatingOptions
                            autoSelect
                            required
                            aria-errormessage={
                                showTagNameError
                                    ? 'mentionsContainer.bulkAddTagsDialog.tagField.error'
                                    : undefined
                            }
                        />
                        {showTagNameError && (
                            <FormError
                                type="warning"
                                id="mentionsContainer.bulkAddTagsDialog.tagField.error"
                                errorText={T(
                                    'mentionsContainer.bulkAddTagsDialog.tagField.errorText',
                                )}
                                actionText={T(
                                    'mentionsContainer.bulkAddTagsDialog.tagField.actionText',
                                )}
                            />
                        )}
                        <p className={classes.copy}>
                            {isNewTag ? (
                                T('mentionsContainer.bulkAddTagsDialog.newTagLbl')
                            ) : (
                                <>&nbsp;</>
                            )}
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <Button priority="text" onClick={onClose}>
                            {T('mentionsContainer.bulkAddTagsDialog.cancelAction')}
                        </Button>
                        <Button priority="cta" onClick={onSubmit}>
                            {T('mentionsContainer.bulkAddTagsDialog.confirmAction', {
                                count: numSelectedMentions,
                            })}
                        </Button>
                    </DialogActions>
                </div>
            </PendingModal>
        </Dialog>
    );
}
