import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual';
import {
    registerFilterType,
    includes,
    checkUpdate,
    isValueValid,
    setErrorForFilter,
} from './../utils';

//Consts
const DEFAULT_INCLUDEOREXCLUDE = {
    activeModeIsInclude: true,
    values: [],
};

registerFilterType(
    //name
    'includeOrExclude',
    //get initial state from config
    () => DEFAULT_INCLUDEOREXCLUDE,
    //is filter state empty check
    (filterConfig, filterState) => !filterState?.values?.length,
    {
        //reducers/actions {[actionName]: func(state, action)}
        setIncludeOrExcludeMode: (state, {payload: {filterName, value}}) => {
            const filterConfig = state.allFiltersConfig[filterName];
            //check filter is correct type
            if (filterConfig.type === 'includeOrExclude') {
                if (filterConfig.noExclusion && !value) {
                    setErrorForFilter(
                        filterConfig,
                        filterConfig.noExclusionErrMsg || 'filters.validation.noExclusion',
                        state,
                    );
                } else {
                    state.filters[filterName].activeModeIsInclude = !!value;
                    state.update++;
                }
            } else {
                throw new Error('This action is only valid for includeOrExclude filters');
            }
        },
        removeValueIncludeOrExclude: (state, {payload: {filterName, value}}) => {
            if (state.allFiltersConfig[filterName].type !== 'includeOrExclude') {
                throw new Error('This action is only valid for includeOrExclude type filters');
            }

            const index = state.filters[filterName].values.findIndex((item) =>
                isEqual(item, value),
            );

            if (index !== -1) {
                state.filters[filterName].values.splice(index, 1);

                checkUpdate(state);
            }
        },
    },
    {
        //validated reducers func(state, payload)
        addValueIncludeOrExclude: (state, {filterName, value}) => {
            state.filters[filterName].values.push(value);
        },
    },
    //validate value
    (state, filterName, value, skipAsync = false) => {
        const filterState = state.filters[filterName];
        const filterConfig = state.allFiltersConfig[filterName];

        if (includes(filterState.values, value, filterState.comparisonFunc)) {
            return filterConfig.duplicateValueErrMsg || 'filters.validation.duplicateValue';
        }

        //Check validation
        if (filterConfig.validation) {
            const errorMsg = filterConfig.validation(value, state);

            if (errorMsg) {
                return errorMsg;
            }
        }

        //Max values
        if (filterConfig.maxValues) {
            //calculate total number of values for this field (taking into account removing value from other mode)
            const numValues = filterState.values.length;

            //If have reached or exceeded the maximum number of values
            if (numValues >= filterConfig.maxValues) {
                return filterConfig.maxValuesErrMsg || 'filters.validation.maxValues';
            }
        }

        if (!skipAsync && value && filterConfig.asyncValidation) {
            return filterConfig.asyncValidation(value, state);
        }

        return true;
    },
    //validate async action
    (state, filterConfig, {action, value, filterName}) => {
        if (action !== 'addValueIncludeOrExclude') {
            throw new Error(
                'Invalid action for this filter type, check you have the correct filterName and/or are using the correct action',
            );
        }

        return isValueValid(state, filterName, value);
    },
    undefined,
    undefined,
    undefined,
    undefined,
    (filterConfig, currentValue, persistedValue) => {
        if (isObject(persistedValue)) {
            const keys = Object.keys(persistedValue);

            if (
                keys.length === 2 &&
                typeof persistedValue.activeModeIsInclude === 'boolean' &&
                persistedValue.values instanceof Array
            ) {
                return {
                    activeModeIsInclude: persistedValue.activeModeIsInclude,
                    values: [...persistedValue.values],
                };
            }
        }

        return currentValue;
    },
);
