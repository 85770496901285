import {useState} from 'react';
import classNames from 'classnames';
import {Player as VideoPlayer} from 'video-react';

import stopPropagation from 'hsi/utils/html/stopPropagation';

import useStyles from './styles';

import {MentionDataType} from 'hsi/types/mentions';
import useElementSize from 'hsi/hooks/useElementSize';

type MediaProps = {
    mentionData: MentionDataType;
    className?: string;
    monitorAspectRatio?: boolean;
    preferVideo?: boolean;
};

export default function Media({
    mentionData,
    className,
    monitorAspectRatio = false,
    preferVideo = false,
}: MediaProps) {
    const classes = useStyles();

    const [ref, elementSize] = useElementSize<HTMLDivElement>(undefined, {
        x: false,
        y: false,
        width: true,
        height: true,
        enabled: monitorAspectRatio,
    });
    const [imageLoadFailed, setImageLoadFailed] = useState(false);
    const [imageDimensions, setImageDimensions] = useState<
        undefined | {width: number; height: number}
    >(undefined);

    const showVideo = !!mentionData.video;
    const showImage = !!mentionData.image && (!preferVideo || (preferVideo && !showVideo));

    //Is this image wider than the container
    const isImgWide =
        imageDimensions === undefined || elementSize === undefined
            ? null
            : imageDimensions.width / imageDimensions.height >
              elementSize.width / elementSize.height;

    return (
        <div className={classNames(classes.media, className)} ref={ref}>
            {showImage && (
                <img
                    className={classNames(
                        classes.image,
                        imageLoadFailed && classes.error,
                        isImgWide === false && classes.tall,
                    )}
                    src={mentionData.image!.url}
                    width={mentionData.image!.width}
                    height={mentionData.image!.height}
                    alt={mentionData.image!.alt}
                    onError={() => setImageLoadFailed(true)}
                    onLoad={(e) => {
                        const imgElement = e.target as HTMLImageElement;

                        setImageDimensions({
                            width: imgElement.naturalWidth,
                            height: imgElement.naturalHeight,
                        });
                    }}
                />
            )}
            {showVideo && (
                <div className={classes.videoContainer} onClick={stopPropagation}>
                    <VideoPlayer
                        fluid={!monitorAspectRatio}
                        width={elementSize?.width ?? 100}
                        height={elementSize?.height ?? 100}
                    >
                        <source src={mentionData.video!} />
                    </VideoPlayer>
                </div>
            )}
        </div>
    );
}
