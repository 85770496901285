import React, {useMemo, useCallback, useEffect, ReactElement, PropsWithChildren} from 'react';

//Hooks
import useOnVisible from 'hsi/hooks/useOnVisible';

//Utils
import {mergeRefs} from 'hsi/utils/react';

//Other
import {fadeContext} from '../index';

//The component
export default React.forwardRef<HTMLElement, Parameters<React.FC<PropsWithChildren<{}>>>[0]>(
    function SearchResultsCard({children}, ref) {
        const child = React.Children.only(children) as ReactElement;

        //Trigger loading of data once this card is in view
        const [isVisible, onVisibleRef, element] = useOnVisible(ref);

        //Callbacks
        //-dispatch load event each time element content changes to inform the
        //-horizontal positioning component that it needs to re-check positions
        const onContentChanged = useCallback(() => {
            if (element) {
                element.dispatchEvent(new Event('load', {bubbles: false, cancelable: false}));
            }
        }, [element]);

        useEffect(
            () => onContentChanged,
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [element],
        );

        //Render
        return useMemo(
            () =>
                React.cloneElement(child, {
                    ref: mergeRefs(onVisibleRef, (child as any).ref),
                    isVisible,
                    onContentChanged,
                    fadeOnVisible: fadeContext,
                }),
            [child, isVisible, onVisibleRef, onContentChanged],
        );
    },
);
