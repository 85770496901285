import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    historyCardContent: {
        '& .history-card-chart': {
            marginTop: '0',
            height: '366px',
        },
        '& .recharts-legend-wrapper ul.recharts-default-legend .recharts-legend-item .recharts-legend-item-text':
            {
                maxWidth: '100px',
            },
    },
    historyCardTitle: {
        display: 'flex',
        '& .title': {
            flex: '0 1 auto',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            alignSelf: 'center',
        },
        '& .breakdown': {
            flex: '0 0 auto',
            color: theme.v2 ? theme.colors.textHyperlink : theme.colors.primaryBlue100,
            borderRadius: '50px',
            backgroundColor: theme.v2 ? theme.colors.white : theme.colors.primaryBlue20,
            padding: theme.v2 ? '4px 2px' : '4px 28px',
            cursor: 'pointer',
            outline: 'none',
            marginLeft: theme.v2 ? '6px' : '8px',
            marginRight: theme.v2 ? '6px' : '8px',
            '@media print': {
                marginLeft: '4px',
                color: theme.v2 ? theme.colors.textDefault : theme.colors.darkGrey100,
                backgroundColor: theme.v2 ? theme.colors.white : 'transparent !important',
                textTransform: 'lowercase',
                padding: '0',
                marginTop: '2px',
            },
            '.printMedia &': {
                marginLeft: '4px',
                color: theme.v2 ? theme.colors.textDefault : theme.colors.darkGrey100,
                backgroundColor: theme.v2 ? theme.colors.white : 'transparent !important',
                textTransform: 'lowercase',
                padding: '0',
                marginTop: '2px',
            },
        },

        '& .dropArrow': {
            fill: theme.v2 ? theme.colors.textHyperlink : theme.colors.primaryBlue100,
            transformOrigin: 'center',
            verticalAlign: 'middle',
            position: 'relative',
            top: '-1px',
            marginLeft: theme.v2 ? '6px' : '10px',
            width: '15px',
            height: '15px',
            '@media print': {
                display: 'none',
            },
            '.printMedia &': {
                display: 'none',
            },
        },
    },
    upsell: {
        backgroundColor: theme.colors.lightGrey60,
        fontSize: '12px',
        padding: '12px',
        fontWeight: theme.font.semiBold,
        borderRadius: '4px',
        '& a': {
            textDecoration: 'none',
        },
    },
    selectMenuItemCheckIcon: {
        width: '16px',
    },
}));
