import { useMemo } from "react";

const EMPTY_ARRAY = Object.freeze([]);

export type WithPosition<T> = T & {position: number};


export default function useAddPositionToRows<T>(data: undefined | null | T[] | Readonly<T[]>): (WithPosition<T>)[] {
    return useMemo(() => {
        return data 
        ? data.map((row, index) => ({...row, position: index + 1}))
        : EMPTY_ARRAY as unknown as (T & {position: number})[];
    }, [data]);
}