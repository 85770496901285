import React, {ElementType} from 'react';
import {Button as MuiButton, ButtonProps as MuiButtonProps} from '@mui/material';

/**
  Mapping HS buttons to material-ui
  see HS style guide and mui theme overrides in /theme/index.js:
  https://hootsuite.com/dashboard#/tapas/?@=global-stage-3/buttons
*/

export interface ButtonProps extends MuiButtonProps {
    component?: ElementType;
    priority?: 'cta' | 'primary' | 'standard' | 'secondary' | 'text';
}

export const Button = React.forwardRef(
    (props: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
        const {priority, color, variant, ...rest} = props;

        switch (priority) {
            case 'cta':
                return <MuiButton variant="contained" ref={ref} {...rest} />;
            case 'primary':
                return <MuiButton variant="contained" color="secondary" ref={ref} {...rest} />;
            case 'secondary':
                return <MuiButton ref={ref} {...rest} />;
            case 'standard':
            default:
                return <MuiButton variant="contained" color="secondary" ref={ref} {...rest} />;
        }
    },
);

export default Button;
