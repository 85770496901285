import {useCallback, useEffect, useMemo, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';

import AddNonOwnedFacebookPages from 'hsi/containers/DataManagement/AddDataSourceDialog/AddNonOwnedFacebookPages';
import CheckboxOptionsMenu from './CheckboxOptionsMenu';

import useDataManagementConfig from 'hsi/hooks/useDataManagementConfig';
import {useFacebookPages} from 'hsi/hooks/useDataSource';

import {
    isGuidedSearchFacebookNonOwnedPage,
    inputAccountValueParser as parser,
} from 'hsi/containers/QueryBuilder/GuidedQueryForm/utils';
import {T} from 'hsi/i18n';

import {SelectAccountsProps} from '.';
import {FacebookPageItem} from 'hsi/types/dataManagement';

const useStyles = makeStyles({
    notFoundRoot: {
        padding: `24px 24px 32px`,
        textAlign: 'center',
    },
});

interface SelectNonOwnedFacebookPagesProps extends SelectAccountsProps {
    onBack: () => void;
}

interface SelectExistingNonOwnedFacebookPagesProps extends SelectNonOwnedFacebookPagesProps {
    onAddNewPage: () => void;
}

const NoPagesFoundComponent = () => {
    const cl = useStyles();
    return (
        <div className={cl.notFoundRoot}>{T('queryBuilder.notTrackingNonOwnedFacebookPages')}</div>
    );
};

const SelectExistingNonOwnedFacebookPages = (props: SelectExistingNonOwnedFacebookPagesProps) => {
    const {onAddNewPage, onChange, setLoading, values} = props;
    const {maxDataSources} = useDataManagementConfig();
    const facebookPages = useFacebookPages({defaultSize: maxDataSources});

    const defaultFacebookPages = useMemo(() => {
        const defaultFaceookIds = values
            .filter(isGuidedSearchFacebookNonOwnedPage)
            .map(({fbPageId}) => fbPageId);

        return facebookPages?.activeNonOwned?.filter(({id}) => defaultFaceookIds.includes(id));
    }, [facebookPages?.activeNonOwned, values]);

    const onAddPages = useCallback(
        (selected: FacebookPageItem[]) => {
            const otherPages = values.filter((item) => !isGuidedSearchFacebookNonOwnedPage(item));
            const newFacebookPages = selected.map(parser.facebook);

            onChange([...otherPages, ...newFacebookPages]);
        },
        [onChange, values],
    );

    useEffect(() => {
        if (facebookPages?.isLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [facebookPages?.isLoading, setLoading]);

    return (
        <CheckboxOptionsMenu
            {...props}
            alwaysShowFooter
            defaultOptions={defaultFacebookPages}
            menuTitle={T('queryBuilder.fbPagesNonOwned')}
            NoPagesFoundComponent={NoPagesFoundComponent}
            onAddNewPage={onAddNewPage}
            onAddPages={onAddPages}
            options={facebookPages?.activeNonOwned}
            valueField="id"
        />
    );
};

const SelectNonOwnedFacebookPages = (props: SelectNonOwnedFacebookPagesProps) => {
    const [showAddNewPage, setShowAddNewPage] = useState(false);

    return showAddNewPage ? (
        <AddNonOwnedFacebookPages
            {...props}
            onBack={() => setShowAddNewPage(false)}
            onClose={() => setShowAddNewPage(false)}
        />
    ) : (
        <SelectExistingNonOwnedFacebookPages
            {...props}
            onAddNewPage={() => setShowAddNewPage(true)}
        />
    );
};

export default SelectNonOwnedFacebookPages;
