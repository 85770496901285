import makeStyles from '@mui/styles/makeStyles';

import {Theme, isV2} from 'hsi/types/theme';

const MAX_WIDTH = '800px';

export default makeStyles((theme: Theme) => {
    const v2 = isV2(theme);

    return {
        createPDFExport: {
            color: theme.colors.textDefault,
            fontSize: '16px',

            '& > div > .MuiDialog-paper': {
                height: 'auto',
                maxWidth: MAX_WIDTH,
                flex: `1 1 ${MAX_WIDTH}`,
            },
        },
        leftAndRight: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
        },
        clear: {
            ...theme.mixin.blankButton,
            ...(v2
                ? theme.fonts.headtitleStrong
                : {
                      fontSize: '16px',
                      fontWeight: theme.font.bold,
                  }),

            color: v2 ? theme.colors.textHyperlink : theme.colors.secondaryBlue100,
            lineHeight: '24px',
            marginTop: '12px',
        },

        cardWrapper: {
            pointerEvents: 'none',
            marginTop: '10px',
        },
        inputWrapper: {
            position: 'relative',
        },
        sectionMargin: {
            margin: v2 ? '16px 0 6px 0' : '16px 0 0 0',
        },
        fieldLabel: {
            fontWeight: v2 ? theme.fonts.bodyNormal.fontWeight : 700,
            fontSize: v2 ? theme.fonts.bodyNormal.fontSize : '16px',
        },
        clearField: {
            ...theme.mixin.blankButton,
            ...(v2
                ? theme.fonts.bodyStrong
                : {
                      fontSize: '16px',
                      fontWeight: theme.font.bold,
                  }),

            color: v2 ? theme.colors.textHyperlink : theme.colors.secondaryBlue100,
            lineHeight: '24px',
        },
        annotationInput: {
            width: '100%',
            marginBottom: '15px',
        },

        helperText: {
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            margin: '4px -12px 0 0',
        },
        warning: {
            display: v2 ? 'none' : 'flex',
            alignItems: 'center',
            gap: 4,
        },
        warningIcon: {
            fill: theme.colors.primaryRed100,
            width: '21px',
            height: '21px',
            padding: '5px',
            backgroundColor: theme.colors.primaryRed40,
            borderRadius: '50%',
        },
        characterLimit: {
            flex: '1 0 auto',
            textAlign: 'right',
            color: theme.colors.textDefault,
        },
    };
});
