import {forwardRef, PropsWithChildren} from 'react';
import classNames from 'classnames';

import useClasses from './styles';

//
/**
 * Adds a scrollbar if required. It requires the parent element to be the desired size, and be position: relative|absolute|fixed
 */
export default forwardRef<HTMLDivElement, PropsWithChildren<{className?: string}>>(function VerticalScroll(
    {children, className},
    ref,
) {
    const classes = useClasses();

    return (
        <div className={classNames(classes.root, className)} ref={ref}>
            {children}
        </div>
    );
});
