// TODO: Add tests
import {useQuery} from '@tanstack/react-query';
import {useAppSelector} from 'hsi/hooks/useRedux';

import FacebookTokenService from 'hsi/services/facebookTokenService';

import {FACEBOOK_INVITE_URL} from 'hsi/constants/queryKeys';

const useFacebookInviteUrl = () => {
    const {BCR_URL}: {BCR_URL: string} = useAppSelector((state) => state.user.settings);
    const clientId: string = useAppSelector((state) => state.user.account.client.id);

    const inviteUrl = useQuery({
        queryKey: [FACEBOOK_INVITE_URL, clientId],
        queryFn: () => FacebookTokenService.getInviteUrl(clientId),
        select: (result) => {
            if (!result?.invitePath) {
                throw new Error('No invite path');
            }

            return new URL(result.invitePath, BCR_URL).toString();
        },
    });

    return inviteUrl;
};

export default useFacebookInviteUrl;
