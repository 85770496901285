import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    return {
        instagram: {
            overflow: 'hidden',
            position: 'relative',
            '& .instagram-media': {
                width: '100%',
                minHeight: '300px',
            },
        },
        reddit: {
            border: 'none',
            width: '100%',
        },
        twitterLoader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '300px',
        },
        generic: {
            ...(theme.v2
                ? {
                      //padding: 0,
                      margin: 0,
                      border: `1px solid ${theme.colors.darkGrey20}`,
                      borderRadius: '3px',
                  }
                : {
                      margin: 0,
                  }),
        },
    };
});
