//TODO handle nested feed keyboard
//https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/feed_role

// Page Down: Move focus to next article.
// Page Up: Move focus to previous article.
// Control + End: Move focus to the first focusable element after the feed.
// Control + Home: Move focus to the first focusable element before the feed.
// If a feed is nested within a feed, such as a comments feed within a feed of blog posts, the convention is to tab
//into the nested feed with the Tab key and to provide another key, such as Alt + Page Down, to navigate from an
//'outer' article to the first item in that article's nested feed. Navigate between the nested feed and main feed
//with Control + End, moving focus from the inner feed to the next article in the outer feed.

import {KeyboardEvent, createContext, useCallback, useContext, useMemo} from 'react';

//Components
import FeedItem from './FeedItem';
import FeedItemWrapper from './FeedItemWrapper';
import {getMatchingChildren, getNextFocusableElement, getPrevFocusableElement} from 'hsi/utils/dom';
import {polymorphicForwardRef} from 'hsi/types/react-polymorphic';

//Types
type FeedProps = {
    busy?: boolean;
    setSize?: number;
};

type FeedContextType = {
    setSize: number;
};

//Context
const FeedContext = createContext<FeedContextType | undefined>(undefined);
FeedContext.displayName = 'FeedContext';

//The component
const Feed = polymorphicForwardRef<FeedProps, 'div'>(function Feed(
    {as: Component = 'div', children, busy, setSize = -1, onKeyDown, ...rest},
    ref,
) {
    const contextValue = useMemo(
        () => ({
            setSize,
        }),
        [setSize],
    );

    const onKeyDownHandler = useCallback(
        (event: KeyboardEvent<HTMLElement>) => {
            const feedElement = event.currentTarget;

            switch (event.code) {
                case 'PageUp':
                    (getPrevItem(feedElement, event.target as Element) as HTMLElement)?.focus?.();
                    event.preventDefault();
                    event.stopPropagation();
                    break;
                case 'PageDown':
                    (getNextItem(feedElement, event.target as Element) as HTMLElement)?.focus?.();
                    event.preventDefault();
                    event.stopPropagation();
                    break;
                case 'Home':
                    if (event.ctrlKey) {
                        focusOnElementBefore(feedElement);
                        event.preventDefault();
                        event.stopPropagation();
                    }
                    break;
                case 'End':
                    if (event.ctrlKey) {
                        focusOnElementAfter(feedElement);
                        event.preventDefault();
                        event.stopPropagation();
                    }
                    break;
            }
            onKeyDown?.(event);
        },
        [onKeyDown],
    );

    return (
        <Component
            {...rest}
            onKeyDown={onKeyDownHandler}
            role="feed"
            aria-busy={busy ? 'true' : 'false'}
            ref={ref}
        >
            <FeedContext.Provider value={contextValue}>{children}</FeedContext.Provider>
        </Component>
    );
}) as ReturnType<typeof polymorphicForwardRef<FeedProps, 'div'>> & {
    Item: typeof FeedItem;
    ItemWrapper: typeof FeedItemWrapper;
};

export const Item = (Feed.Item = FeedItem);
export const ItemWrapper = (Feed.ItemWrapper = FeedItemWrapper);

export default Feed;

export function useFeedContext() {
    return useContext(FeedContext);
}

//Internal helpers
function getChildItems(feedElement: Element) {
    return getMatchingChildren(feedElement, 'article, [role="article"]');
}

function getCurrentItem(currentElem: Element, items: Element[]) {
    return items.find((elem) => elem === currentElem || elem.contains(currentElem));
}

function getNextItem(feedElement: Element, currentElem: Element) {
    const items = getChildItems(feedElement);
    const currentItem = getCurrentItem(currentElem, items);

    if (!currentItem) {
        return;
    }

    const currentIndex = items.indexOf(currentItem);

    if (currentIndex + 1 >= items.length) {
        return undefined;
    }

    return items[currentIndex + 1];
}

function getPrevItem(feedElement: Element, currentElem: Element) {
    const items = getChildItems(feedElement);
    const currentItem = getCurrentItem(currentElem, items);

    if (!currentItem) {
        return;
    }

    const currentIndex = items.indexOf(currentItem);

    if (currentIndex === 0) {
        return undefined;
    }

    return items[currentIndex - 1];
}

function focusOnElementAfter(feedElement: HTMLElement) {
    getNextFocusableElement(feedElement)?.focus();
}

function focusOnElementBefore(feedElement: HTMLElement) {
    getPrevFocusableElement(feedElement)?.focus();
}
