import MuiButton from '@mui/material/Button';

import { Styles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import {Theme, isV2} from 'hsi/types/theme';

const RawTextButton = withStyles((styles: Styles<Theme, {}, never>) => {
    const theme = styles as Theme;
    const v2 = isV2(theme);

    return {
        text: {
            border: 'none',
            backgroundColor: 'unset',
            '& span': {
                textTransform: 'uppercase !important',
                width: 'auto',
            },
            '&:hover': {
                color: 'unset',
                boxShadow: 'unset',
                backgroundColor: 'unset',
            },
            '&:focus': {
                outline: 'unset',
                boxShadow: 'unset',
                backgroundColor: 'unset',
            },
            '&:focus-visible > span': {
                outline: v2
                    ? `2px solid ${theme.colors.accentActive}`
                    : `2px solid ${theme.colors.secondaryOrange100}`,
            },
        },
    };
})(MuiButton);

export default RawTextButton;
