import {ReactNode} from 'react';
import classNames from 'classnames';
import MuiDialogTitle, {DialogTitleProps as MuiDialogTitleProps} from '@mui/material/DialogTitle';

//Other
import useStyles from './styles';
import Heading from 'hsi/components/aria/Heading';

interface DialogTitleProps extends MuiDialogTitleProps {
    info?: ReactNode;
    className?: string;
}

export default function DialogTitle({
    children,
    info,
    className,
    ...other
}: DialogTitleProps) {
    const classes = useStyles();

    return (
        <MuiDialogTitle
            {...other as any}
            component="div"
            className={classNames(classes.title, className)}
        >
            <Heading className={classes.titleHeading}>{children}</Heading>
            {info}
        </MuiDialogTitle>
    );
}
