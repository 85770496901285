import makeStyles from '@mui/styles/makeStyles';

import HighlightedText from 'hsi/components/QueryEditor/HighlightedText';
import RawTextButton from 'hsi/containers/QueryBuilder/components/RawTextButton';

import {useAppDispatch} from 'hsi/hooks/useRedux';

import {setBooleanQuery} from 'hsi/actions/queryActions';

import {Theme, isV2} from 'hsi/types/theme';
import {examples} from './config';
import tns from 'hsi/i18n/tns';
import Heading, { HeadingContents } from 'hsi/components/aria/Heading';
const t = tns('queryBuilder');

const exampleStyles = makeStyles((theme: Theme) => {
    const {colors, mixin} = theme;
    const v2 = isV2(theme);

    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            margin: v2 ? '16px 20px' : '24px 16px',
            ...(v2 ? {} : {borderBottom: `1px solid ${colors.darkGrey20}`}),
            '& .MuiButton-text': {
                color: v2 ? colors.textHyperlink : colors.secondaryBlue100,
                ...(v2
                    ? {
                          fontWeight: theme.fonts.bodyStrong.fontWeight,
                          fontSize: theme.fonts.bodyStrong.fontSize,
                      }
                    : {}),
                '& svg': {
                    ...(v2 ? {} : mixin.linkColor),
                },
            },
            '& svg': {
                width: '14px',
                height: '14px',
            },
        },
        description: {
            ...(v2 ? {margin: '8px 0'} : {marginBottom: '8px'}),
        },
        query: {
            backgroundColor: v2 ? colors.uiGrayDefault : colors.lightGrey20,
            padding: v2 ? '16px 8px 16px 16px' : '4px',
        },
    };
});

type TryThisSearchProps = {
    onClick: React.MouseEventHandler;
};

const TryThisSearch = ({onClick}: TryThisSearchProps) => (
    <RawTextButton size="small" onClick={onClick}>
        {t('tryThisSearch')}
    </RawTextButton>
);

type ExampleProps = {
    description: string;
    onSubmit: (value: string) => void;
    query: string;
};

const Example = ({description, query, onSubmit}: ExampleProps) => {
    const cl = exampleStyles();

    return (
        <div className={cl.container} data-testid="searchHelpExample">
            <Heading className={cl.description}>{description}</Heading>
            <HeadingContents>
                <div className={cl.query}>
                    <HighlightedText text={query} />
                </div>
                <TryThisSearch onClick={() => onSubmit(query)} />
            </HeadingContents>
        </div>
    );
};

type ExamplesProps = {
    onSubmit: (value: string) => void;
};

const Examples = ({onSubmit}: ExamplesProps) => {
    const dispatch = useAppDispatch();

    const _onSubmit = (query: string) => {
        query = query.replace(/\r?\n|\r/g, '');
        dispatch(setBooleanQuery({booleanQuery: query, guidedForm: null, validated: true}));
        onSubmit(query);
    };

    return (
        <div data-testid="searchHelpExamples">
            {examples.map((d, i) => (
                <Example {...d} onSubmit={_onSubmit} key={i} />
            ))}
        </div>
    );
};

export default Examples;
