import http from '../classes/Http';
import later from '@breejs/later';

const desUrl = '/des-api/schedules';

const buildCrons = ({startDate, hours, minutes}) => {
    const h = Math.min(Math.max(hours, 0), 23);
    const m = Math.min(Math.max(minutes, 0), 59);

    const startDay = startDate.date();
    const startMonth = startDate.month() + 1; //moment 0-11 vs cron 1-12

    const weekly = (weeks) => weeks.map((w) => (startDay + w * 7) % 31).join();
    const monthly = (months) => months.map((m) => (startMonth + m) % 12).join();

    return {
        P1D: `${m} ${h} * * *`,
        P7D: `${m} ${h} ${weekly([0, 1, 2, 3])} * *`,
        P14D: `${m} ${h} ${weekly([0, 2])} * *`,
        P1M: `${m} ${h} ${startDay} * *`,
        P3M: `${m} ${h} ${startDay} ${monthly([0, 3, 6, 9])} *`,
    };
};

const buildReportScheduleParams = ({
    hours,
    logo,
    minutes,
    name,
    projectId,
    queryId,
    recipients,
    startDate,
    timespan,
    type,
}) => {
    const start = startDate;

    const crons = buildCrons({
        hours,
        minutes,
        startDate: start,
    });

    const schedule = later.parse.cron(crons[timespan]);

    return {
        format: 'pdf',
        interval: {
            schedule,
            startDate: start.toISOString(),
            type,
        },
        name,
        options: {
            logo,
        },
        queryData: {
            queryId: Array.isArray(queryId) ? queryId : [queryId],
            projectId,
        },
        recipients,
        reportType: 'snapshot',
        timespan,
    };
};

//TODO test reports crons
//const testCrons = () => {
//const startDate = moment.utc().startOf('day');
//const crons = buildCrons({
//startDate,
//hours: 8,
//minutes: 0,
//});
//Object.keys(crons).forEach(ts => {
//const schedule = later.parse.cron(crons[ts]);
//const nextRun = later.schedule(schedule).next(10, startDate);
//console.log(ts,'nextRun',nextRun.map(d => moment.utc(d).toString()));
//});
//}

//send pageSize and page params in order to enable pagination
const getReports = async ({projectId}) => {
    const response = await http.get(`${desUrl}?projectId=${projectId}`);
    const {results} = response?.body;

    if (!results || response.error || !Array.isArray(results)) {
        return [];
    }

    return results;
};

export const listReports = async ({projects}) => {
    const projectIds = projects.filter((project) => project.id).map((project) => project.id);
    const reportRequests = projectIds.map((projectId) => getReports({projectId}));
    const reports = await Promise.all(reportRequests);

    return reports.flat();
};

export const editReport = ({
    hours,
    id,
    logo,
    minutes,
    name,
    projectId,
    queryId,
    recipients,
    startDate,
    timespan,
    type,
}) => {
    const params = buildReportScheduleParams({
        hours,
        logo,
        minutes,
        name,
        projectId,
        queryId,
        recipients,
        startDate,
        timespan,
        type,
    });
    return http.patch(`${desUrl}/${id}`, params);
};

export const createReport = ({
    hours,
    logo,
    minutes,
    name,
    projectId,
    queryId,
    recipients,
    startDate,
    timespan,
    type,
}) => {
    const params = buildReportScheduleParams({
        hours,
        logo,
        minutes,
        name,
        projectId,
        queryId,
        recipients,
        startDate,
        timespan,
        type,
    });
    return http.post(desUrl, params);
};

export const updateReport = ({id, params}) => {
    return http.patch(`${desUrl}/${id}`, {...params});
};

export const deleteReport = ({id}) => http.delete(`${desUrl}/${id}`);

export const deleteReports = async (ids) => {
    const deleteReportsMap = ids.map((id) => deleteReport(id));
    await Promise.all(deleteReportsMap);
};
