import {forwardRef} from 'react';

type AsProp<C extends React.ElementType> = {as?: C};
type PropsToOmit<C extends React.ElementType, P> = keyof (AsProp<C> & P);

type PolymorphicComponentProp<C extends React.ElementType, Props = {}> = React.PropsWithChildren<
    Props & AsProp<C>
> &
    Omit<React.ComponentPropsWithoutRef<C>, PropsToOmit<C, Props>>;

type PolymorphicRef<C extends React.ElementType> = React.ComponentPropsWithRef<C>['ref'];

export type PolymorphicComponentPropWithRef<
    C extends React.ElementType,
    Props = {},
> = PolymorphicComponentProp<C, Props> & {ref?: PolymorphicRef<C>};

export function polymorphicForwardRef<Props, DefaultElementType extends React.ElementType>(
    component: <C extends React.ElementType>(
        props: PolymorphicComponentPropWithRef<C, Props>,
        ref: PolymorphicRef<C>,
    ) => React.ReactElement | null,
) {
    type PolymorphicProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<C, Props>;
    type PolymorphicComponent = <C extends React.ElementType = DefaultElementType>(
        props: PolymorphicProps<C>,
    ) => React.ReactElement | null;

    return forwardRef(component) as PolymorphicComponent;
}
