import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

import useComponentsBaseStyles from './baseStyles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    cols: {
        display: 'flex',
    },
});

const CheckboxesPanel = ({items, name, onChange, sectionName, title, values}) => {
    const ccl = useComponentsBaseStyles();
    const cl = useStyles();
    const getItem = (name) => items.find((d) => d.name === name);

    const toggleChecked = (e) => {
        const nextValues = e.target.checked
            ? values.concat(getItem(e.target.name))
            : values.filter((d) => d.name !== e.target.name);
        onChange({
            sectionName,
            componentName: name,
            values: nextValues,
        });
    };

    const split = Math.ceil(items.length / 2);
    const cols = [items.slice(0, split), items.slice(split, items.length)];

    return (
        <div className={ccl.container}>
            <div className={ccl.title} id={name}>
                {title}
            </div>
            <div className={cl.cols}>
                {cols.map((col, i) => (
                    <FormGroup key={i}>
                        {col.map((d) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!values.find((v) => v.name === d.name)}
                                        onChange={toggleChecked}
                                        name={d.name}
                                        color="primary"
                                    />
                                }
                                label={d.title}
                                key={d.name}
                            />
                        ))}
                    </FormGroup>
                ))}
            </div>
        </div>
    );
};

CheckboxesPanel.propTypes = {
    items: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    sectionName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
};

export default CheckboxesPanel;
