import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles(({font, fonts, v2}: Theme) => ({
    dialogContainer: {
        '& .MuiDialog-paper': {
            width: v2 ? 735 : 804,
        },
    },
    subtitle: {
        ...(v2
            ? fonts.bodyLargeNormal
            : {
                  fontSize: 17,
                  fontWeight: font.regular,
              }),
        marginBottom: v2 ? 5 : 19,
    },
    cardList: {
        margin: 'auto',
    },
    narrowCardList: {
        justifyContent: 'center',
    },
    cardIcon: {
        height: 40,
        margin: 9,
        width: 40,
    },
}));
