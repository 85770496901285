import makeStyles from '@mui/styles/makeStyles';

const CATHEIGHT = 50;
const BASEHEIGHT = 150;

const getChartHeight = (amount) => BASEHEIGHT + (amount - 1) * CATHEIGHT + 'px';

export default makeStyles((theme) => ({
    chartContainer: {
        '& .chart': {
            marginTop: '0',
        },
        '& .catSize1': {
            height: getChartHeight(1),
        },
        '& .catSize2': {
            height: getChartHeight(2),
        },
        '& .catSize3': {
            height: getChartHeight(3),
        },
        '& .catSize4': {
            height: getChartHeight(4),
        },
        '& .catSize5': {
            height: getChartHeight(5),
        },
        '& .catSize6': {
            height: getChartHeight(6),
        },
        '& .catSize7': {
            height: getChartHeight(7),
        },
        '& .catSize8': {
            height: getChartHeight(8),
        },
        '& .catSize9': {
            height: getChartHeight(9),
        },
        '& .catSize10': {
            height: getChartHeight(10),
        },
    },
    upsell: {
        backgroundColor: theme.colors.lightGrey60,
        fontSize: '12px',
        padding: '12px',
        fontWeight: theme.font.semiBold,
        borderRadius: '4px',
        '& a': {
            textDecoration: 'none',
        },
    },
    selectMenuItemCheckIcon: {
        width: '16px',
    },
}));
