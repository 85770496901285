import {
    useRef,
    useState,
    useMemo,
    useCallback,
    useEffect,
    ChangeEventHandler,
    createContext,
} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEmail from 'validator/lib/isEmail';

import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Collapse from '@mui/material/Collapse';

//Components
import HelpIcon from 'hsi/components/HelpIcon';
import ChipInput from 'hsi/components/ChipInput';
import Button from 'hsi/components/Button';
import TextField from 'hsi/components/TextField';
import Tooltip from 'hsi/components/Tooltip';
import FormError from 'hsi/components/FormError';
import PulseLoader from 'hsi/components/PulseLoader';
import IconRouter from 'hsi/components/IconRouter';
import Select from './Select';
import InfoPopupContent from 'hsi/components/InfoPopupContent/';
import Filters from 'hsi/components/Filters';
import FilterSummary from 'hsi/components/Filters/Summary';
import WithLoadFirst from 'hsi/components/LoadFirst';
import ContextStore from 'hsi/components/ContextStore';
import Markdown from 'hsi/components/Markdown';

//Hooks
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import useConfig from 'hsi/hooks/useConfig';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useRefCallback from 'hsi/hooks/useRefCallback';
import useGetProjectIdFromQueryId from 'hsi/hooks/useGetProjectIdFromQueryId';
import useGetFiltersConfig from 'hsi/hooks/useGetFiltersConfig';
import {ProjectIdProvider} from 'hsi/hooks/useProjectId';
import useStyles from './styles';

//Actions
import {clearError, clearSuccess, saveAlert, updateAlert} from 'hsi/slices/alerts';
import {loadLanguages} from 'hsi/actions/autocompleteActions';
import {showNotification} from 'hsi/actions/notificationsActions';
import {loadTags} from 'hsi/actions/tagActions';
import filtersReducer, {
    initialState as filtersInitialState,
    initStateFromConfig,
    reset,
} from 'hsi/slices/filters';

//Utils
import {makeNumberList, arrayRotate} from 'hsi/utils/array';
import {createAlertObject} from './utils';
import findFirstScrollElement from 'hsi/utils/findFirstScrollElement';
import {getNumAppliedFilters} from 'hsi/slices/filters/utils';
import {apiFormatToFilterState, filterStateToAPIFormat} from 'hsi/utils/filters';
import {T} from 'hsi/i18n';

//Types
import {ApiAlert} from 'hsi/types/alerts';
import {SavedSearchType} from 'hsi/types/shared';
import {FiltersState, LocationDefinition, TagDefinition} from 'hsi/types/filters';
import {SelectProps} from '@mui/material';
import locationsService from 'hsi/services/locationsService';
import Checkbox from 'hsi/components/Checkbox';

const AlertsFiltersContext = createContext(null);
AlertsFiltersContext.displayName = 'AlertsFiltersContext';

const BLANK_ARR: any[] = []; //cast as required

type AddEditAlertProps = {
    alert?: ApiAlert;
    queryId: number;
    onClose: () => void;
    savedSearch: SavedSearchType;
    wrapContent?: (content: JSX.Element) => JSX.Element;
    wrapActions?: (content: JSX.Element) => JSX.Element;
    loadedLocations: LocationDefinition[];
};

type SelectOnChangeHandler = NonNullable<SelectProps['onChange']>;

//The component
function AddEditAlert({
    alert,
    queryId,
    onClose,
    savedSearch,
    wrapContent = undefined,
    wrapActions = undefined,
    loadedLocations,
}: AddEditAlertProps) {
    const rootRef = useRef<any>(); //using the correct HtmlDivElement type did not work here. No idea why.
    const filtersRootRef = useRef<any>();
    const {classes, cx} = useStyles();
    const config = useConfig();
    const {track} = useEventTrack();
    const projectId = useGetProjectIdFromQueryId(queryId || (alert?.queryId as number)) as number;
    const {allFiltersConfig} = useGetFiltersConfig('saved', projectId);

    const {alerts: alertsConfig, links} = config;

    //Redux
    const dispatch = useAppDispatch();
    const {canSaveSearch} = useAppSelector((state) => state.user);
    const filtersRedux = useAppSelector((state) => state.filters);
    const alertsError = useAppSelector((state) => state.alerts.error);
    const alertsSuccess = useAppSelector((state) => state.alerts.success);
    const emailAddress = useAppSelector((state) => state.user.account.userEmail as string);

    const languages = useAppSelector((state) => state.autocomplete.languageAutocomplete.results);
    const tags = useAppSelector(
        (state) => state.allTags[projectId]?.allTags || (BLANK_ARR as TagDefinition[]),
    );

    //Get filters config
    const {config: filtersConfig} = useGetFiltersConfig('saved', projectId);

    //State
    const [name, setName] = useState(alert?.name || '');
    const [volumeAlertChecked, setVolumeAlertChecked] = useState(
        alert?.alertTypes?.includes('threshold') || false,
    );
    const [volumeAlertVal, setVolumeAlertVal] = useState<number | undefined>(
        alert?.thresholdPercentage || alertsConfig.defaultAlertThresholdPercentage,
    );
    const [mentionsAlertChecked, setMentionsAlertChecked] = useState(
        alert?.alertTypes?.includes('scheduled') || false,
    );
    const [mentionAlertFrequency, setMentionAlertFrequency] = useState(
        alert?.frequency || alertsConfig.defaultNewMentionFrequency,
    );
    const [mentionAlertWeekday, setMentionAlertWeekday] = useState(
        alert?.repeatOnDayOfWeek || alertsConfig.defaultDayOfWeek,
    );
    const [mentionAlertTime, setMentionAlertTime] = useState(
        alert?.repeatOnHourOfDay || alertsConfig.defaultMentionAlertTime,
    );
    const [mentionsPerAlert, setMentionsPerAlert] = useState(
        alert?.mentionsPerAlert || alertsConfig.alertDefaultNumMentionsPerAlert,
    );
    const [emailCreator, setEmailCreator] = useState<boolean>(
        alert?.emailCreator ?? !config.alerts.noDefaultAlertEmail,
    );
    const [additionalRecipients, setRecipients] = useState(
        alert?.additionalRecipients.map(({recipient}) => recipient) || [],
    );
    const [filters, setFilters] = useState<FiltersState>(() => {
        const filters = alert?.filter
            ? apiFormatToFilterState(alert.filter, allFiltersConfig, {
                  languages,
                  locations: loadedLocations,
                  tags,
                  categories: [],
              })
            : filtersRedux.filters;

        return filters;
    });
    const [saving, setSaving] = useState(false);

    const [showErrors, setShowErrors] = useState(false);
    const [showEditFilters, setShowEditFilters] = useState(false);

    const [hasScrolledToError, setHasScrolledToError] = useState(false);

    //Error handling
    const errors = useMemo(() => {
        let hasErrors = false;
        let errors: {
            api?: string;
            duplicateName?: boolean;
            name?: boolean;
            type?: boolean;
            additionalRecipients?: boolean;
            invalidVolumeAlertValue?: boolean;
            invalidRecipients?: string[];
        } = {};

        if (alertsError && alertsError?.error) {
            alertsError?.body.errors.forEach(({code, message}: {code: number; message: string}) => {
                if (code === 201) {
                    errors.duplicateName = true;
                } else {
                    errors.api = message; //other errors
                }
            });

            hasErrors = true;
        }

        if (name.trim() === '') {
            errors.name = true;
            hasErrors = true;
        }

        if (!volumeAlertChecked && !mentionsAlertChecked) {
            errors.type = true;
            hasErrors = true;
        }

        if (!emailCreator && (!additionalRecipients || additionalRecipients.length === 0)) {
            errors.additionalRecipients = true;
            hasErrors = true;
        }

        if (volumeAlertChecked && (volumeAlertVal === undefined || volumeAlertVal <= 0)) {
            errors.invalidVolumeAlertValue = true;
            hasErrors = true;
        }

        const invalidRecipients = (additionalRecipients || []).filter((r) => !isEmail(r || ''));

        if (invalidRecipients.length > 0) {
            errors.invalidRecipients = invalidRecipients;
            hasErrors = true;
        }

        return hasErrors ? errors : null;
    }, [
        alertsError,
        name,
        volumeAlertChecked,
        mentionsAlertChecked,
        emailCreator,
        additionalRecipients,
        volumeAlertVal,
    ]);

    //Callbacks
    const updateName = useCallback<ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
        (e) => {
            setName(e.target.value);
            setSaving(false);

            //clear duplicate name error
            if (errors?.duplicateName) {
                dispatch(clearError());
            }
        },
        [setName, setSaving, errors?.duplicateName, dispatch],
    );

    const toggleVolumeAlert = useCallback(() => {
        setVolumeAlertChecked((val) => !val);
        setSaving(false);
    }, [setVolumeAlertChecked, setSaving]);

    const updateVolumeAlert = useCallback<
        ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    >(
        (e) =>
            setVolumeAlertVal(
                e.target.value === '' || e.target.value === null || e.target.value === undefined
                    ? undefined
                    : +e.target.value,
            ),
        [setVolumeAlertVal],
    );

    const toggleMentionsAlert = useCallback(() => {
        setMentionsAlertChecked((val) => !val);
        setSaving(false);
    }, [setMentionsAlertChecked, setSaving]);

    const updateMentionAlertFrequency = useCallback<SelectOnChangeHandler>(
        (e) => setMentionAlertFrequency(e.target.value as number),
        [setMentionAlertFrequency],
    );

    const updateMentionAlertWeekday = useCallback<SelectOnChangeHandler>(
        (e) => setMentionAlertWeekday(e.target.value as number),
        [setMentionAlertWeekday],
    );

    const updateMentionAlertTime = useCallback<SelectOnChangeHandler>(
        (e) => setMentionAlertTime(e.target.value as number),
        [setMentionAlertTime],
    );

    const updateMentionsPerAlert = useCallback<SelectOnChangeHandler>(
        (e) => setMentionsPerAlert(e.target.value as number),
        [setMentionsPerAlert],
    );

    const onRecipientsChange = useCallback((newValue: string[]) => {
        const lowerCaseValues = newValue.map((value) => value.toLowerCase());
        const uniqueValues = [...new Set(Array.from(lowerCaseValues))];

        setShowErrors(false);
        setRecipients(uniqueValues);
    }, []);

    const validateRecipients = useCallback(
        (values: string[]) => {
            const invalidEmailAddress = values.filter(
                (recipient) => !isEmail(recipient) || recipient === emailAddress,
            );
            const newErrors = invalidEmailAddress.map((recipient) =>
                recipient === emailAddress
                    ? {
                          label: 'alert.recipients.errors.self.lbl',
                          reason: T('alert.recipients.errors.self.desc'),
                          value: recipient,
                      }
                    : {
                          label: 'alert.recipients.errors.invalidRecipient.lbl',
                          reason: T('alert.recipients.errors.invalidRecipient.desc'),
                          value: recipient,
                      },
            );

            newErrors.length && setShowErrors(true);
            return newErrors;
        },
        [emailAddress],
    );

    const onSaveAlert = useRefCallback(() => {
        setShowErrors(true);
        setHasScrolledToError(false);

        if (!!errors) {
            return;
        }

        const newAlert = createAlertObject(alert, savedSearch, {
            name,
            volumeAlertChecked,
            mentionsAlertChecked,
            volumeAlertVal: volumeAlertVal!,
            additionalRecipients,
            mentionAlertFrequency,
            mentionAlertTime,
            mentionAlertWeekday,
            filters: filterStateToAPIFormat(filters, allFiltersConfig),
            mentionsPerAlert,
            emailCreator,
        });

        const onSuccess = () => {
            track('alertCreated', {});
        };

        if (alert) {
            //editing alert
            newAlert.id = alert.id;
            dispatch(
                updateAlert({
                    alert: {
                        ...newAlert,
                        id: alert.id,
                    },
                    successMessage: T('alert.edited', {name: newAlert.name}),
                    onSuccess,
                }),
            );
        } else {
            //creating a new alert
            dispatch(
                saveAlert({
                    alert: newAlert,
                    successMessage: T('alert.created', {name: newAlert.name}),
                    onSuccess,
                }),
            );
        }

        setSaving(true);
    });

    const autoScrollDrawer = useCallback((childElem: Element) => {
        try {
            const scrollElem = findFirstScrollElement(filtersRootRef.current ?? null);

            if ((scrollElem as any).style) {
                (scrollElem as any).style.scrollBehavior = 'smooth';
            }
            childElem.scrollIntoView();
        } catch (e) {
            console.log(e);
        }
    }, []);

    //Calculated values
    let secondTime = alertsConfig.alertMentionFrequenciesWithCalculatedSecondTime[
        mentionAlertFrequency
    ]
        ? alertsConfig.alertMentionFrequenciesWithCalculatedSecondTime[mentionAlertFrequency](
              mentionAlertTime,
          )
        : null;

    const numFilters = useMemo(
        () => getNumAppliedFilters(filtersConfig, filters, true),
        [filtersConfig, filters],
    );

    const filterTooltip = useMemo(() => {
        return (
            <div className={classes.tooltipContent}>
                <FilterSummary config={filtersConfig} filters={filters} />
            </div>
        );
    }, [filters, filtersConfig, classes.tooltipContent]);

    const alertsInfoPopup = useMemo(
        () => (
            <InfoPopupContent
                title={T('alert.infoPopup.title')}
                copy={T('alert.infoPopup.copy')}
                ctaLabel={T('alert.infoPopup.cta')}
                ctaUrl={links.alertsLearnMore}
                markdown
            />
        ),
        [links.alertsLearnMore],
    );

    const increaseVolumePopup = useMemo(
        () => (
            <InfoPopupContent
                title={T('alert.increaseVolumePopup.title')}
                copy={T('alert.increaseVolumePopup.copy')}
                markdown
            />
        ),
        [],
    );

    const newAlertsPopup = useMemo(
        () => (
            <InfoPopupContent
                title={T('alert.newAlertsPopup.title')}
                copy={T('alert.newAlertsPopup.copy')}
                markdown
            />
        ),
        [],
    );

    const numMentionsPopup = useMemo(
        () => (
            <InfoPopupContent
                title={T('alert.numMentionsPopup.title')}
                copy={T('alert.numMentionsPopup.copy')}
                markdown
            />
        ),
        [],
    );

    //init filters state
    const initialFiltersState = useMemo(() => {
        return initStateFromConfig({
            ...cloneDeep(filtersInitialState),
            config: filtersConfig,
            persistFilters: {filters},
        });
    }, [filters, filtersConfig]);

    const getNameErrorState = useMemo(() => {
        const hasErrors = showErrors && (errors?.name || errors?.duplicateName);
        return hasErrors ? true : undefined;
    }, [errors?.duplicateName, errors?.name, showErrors]);

    const getNameErrorText = useMemo(() => {
        const hasNameError = showErrors && errors?.name;
        const hasDuplicateNameError = showErrors && errors?.duplicateName;

        if (hasNameError) {
            return (
                <FormError
                    type="warning"
                    errorText={T('name.error')}
                    actionText={T('name.action4')}
                />
            );
        }

        if (hasDuplicateNameError) {
            return (
                <FormError
                    type="warning"
                    errorText={T('api.error3')}
                    actionText={T('api.action')}
                />
            );
        }

        return undefined;
    }, [errors, showErrors]);

    //Side effects
    useEffect(
        () => {
            if (alertsSuccess) {
                //Not sure why we need this?
                setSaving(false);

                //Close the dialog
                onClose();

                //resets the redux state
                dispatch(clearSuccess());
            }
        },
        //We do not want this executing if onClose changes, and onClose will never be stale
        // When we do actually execute, so this warning is incorrect and actively harmfull
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [alertsSuccess, dispatch],
    );

    useEffect(
        () => {
            if (showErrors && errors && rootRef.current && !hasScrolledToError) {
                //scroll to first error
                const firstError = rootRef.current.querySelector('.form-error');

                firstError && autoScrollDrawer(firstError);
                setHasScrolledToError(true);
            }
        },
        //We do not want this executing if autoScrollDrawer changes, and it will never be stale
        // When we do actually execute, so this warning is incorrect and actively harmful
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showErrors, errors],
    );

    return (
        <ProjectIdProvider value={projectId}>
            <ContextStore //TODO proper TS types for ContextStore
                reducer={filtersReducer}
                initialState={initialFiltersState}
                context={AlertsFiltersContext}
            >
                {({
                    useSelector,
                    dispatch,
                    getState,
                    subscribe,
                }: {
                    useSelector: (selector: any) => any;
                    dispatch: (action: any) => void;
                    getState: () => any;
                    subscribe: (subscriber: any) => any;
                }) => {
                    const clearFilters = () => {
                        dispatch((reset as any)());
                    };

                    //Rendering
                    const content = (
                        <div ref={rootRef} role="form">
                            {showErrors && errors?.api && (
                                <FormError type="warning" errorText={errors.api} />
                            )}
                            <div className={classes.info}>
                                <label htmlFor="alert-name" className={classes.category}>
                                    {T('alert.name')}
                                </label>
                                <span
                                    aria-describedby="alert-name"
                                    className={classes.instructions}
                                >
                                    {T('alert.instructions')}
                                </span>
                                <TextField
                                    id="alert-name"
                                    fullWidth
                                    error={getNameErrorState}
                                    className={classes.alertName}
                                    value={name}
                                    inputProps={{maxLength: alertsConfig.nameLengthLimit}}
                                    onChange={updateName}
                                    helperText={getNameErrorText}
                                    required
                                />
                            </div>

                            <fieldset className={classes.info}>
                                <legend className={classes.heading}>
                                    <HelpIcon
                                        buttonLbl={T('alert.alertMeHelpLbl')}
                                        tooltip={alertsInfoPopup}
                                        placement="bottom-start"
                                        className={classes.alertMe}
                                    >
                                        <span>{T('alert.alertMe')}</span>
                                    </HelpIcon>
                                </legend>
                                <div
                                    className={cx(
                                        classes.checkboxesList,
                                        showErrors &&
                                            (errors?.type || errors?.invalidVolumeAlertValue)
                                            ? classes.error
                                            : null,
                                    )}
                                >
                                    <div className={classes.checkboxWithLabel}>
                                        <Checkbox
                                            id="alertVolumeIncrease"
                                            data-at="alertVolumeIncrease" //What is this property for? it's literally not references anywhere in the rest of the codebase
                                            checked={volumeAlertChecked}
                                            onChange={toggleVolumeAlert}
                                            className={classes.toggle}
                                        />
                                        <label
                                            className={classes.checkboxLabel}
                                            htmlFor="alertVolumeIncrease"
                                        >
                                            {T('alert.alertVolumeIncrease')}
                                        </label>
                                        <HelpIcon
                                            buttonLbl={T('alert.alertVolumeIncreaseHelpLbl')}
                                            tooltip={increaseVolumePopup}
                                            placement="bottom-start"
                                            children={undefined}
                                        />
                                    </div>

                                    {volumeAlertChecked && (
                                        <label className={classes.optionLabel}>
                                            <span>{T('alert.increaseThreshold')}</span>
                                            <TextField
                                                type="number"
                                                value={volumeAlertVal}
                                                onChange={updateVolumeAlert}
                                                className={classes.input}
                                            />
                                            <span>{'%'}</span>
                                        </label>
                                    )}

                                    <div className={classes.checkboxWithLabel}>
                                        <Checkbox
                                            data-at="alertNewMentions" //What is this property for? it's literally not references anywhere in the rest of the codebase
                                            checked={mentionsAlertChecked}
                                            onChange={toggleMentionsAlert}
                                            id="alertNewMentions"
                                            className={classes.toggle}
                                        />
                                        <label
                                            className={classes.checkboxLabel}
                                            htmlFor="alertNewMentions"
                                        >
                                            {T('alert.alertNewMentions')}
                                        </label>
                                        <HelpIcon
                                            buttonLbl={T('alert.alertNewMentionsHelpLbl')}
                                            tooltip={newAlertsPopup}
                                            placement="bottom-start"
                                            children={undefined}
                                        />
                                    </div>

                                    {mentionsAlertChecked && (
                                        <>
                                            <Select
                                                value={mentionAlertFrequency}
                                                onChange={updateMentionAlertFrequency}
                                                className={classes.input}
                                            >
                                                {alertsConfig.alertNewMentionsFrequencies.map(
                                                    (value) => (
                                                        <MenuItem
                                                            key={value}
                                                            value={value}
                                                            selected={
                                                                mentionAlertFrequency === value
                                                            }
                                                        >
                                                            {T(
                                                                `alert.newMentionFrequency.${value}`,
                                                            )}
                                                        </MenuItem>
                                                    ),
                                                )}
                                            </Select>
                                            {alertsConfig.alertMentionFrequenciesWithDay.includes(
                                                mentionAlertFrequency,
                                            ) && (
                                                <>
                                                    <span className={classes.interstitial}>
                                                        {'on'}
                                                    </span>
                                                    <Select
                                                        value={mentionAlertWeekday}
                                                        onChange={updateMentionAlertWeekday}
                                                        className={classes.input}
                                                    >
                                                        {[
                                                            'monday',
                                                            'tuesday',
                                                            'wednesday',
                                                            'thursday',
                                                            'friday',
                                                            'saturday',
                                                            'sunday',
                                                        ].map((day, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={index + 1}
                                                                selected={
                                                                    mentionAlertWeekday ===
                                                                    index + 1
                                                                }
                                                            >
                                                                {T(`dayNames.${day}`)}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </>
                                            )}

                                            {alertsConfig.alertMentionFrequenciesWithTime.includes(
                                                mentionAlertFrequency,
                                            ) && (
                                                <>
                                                    <span className={classes.interstitial}>
                                                        {'at'}
                                                    </span>
                                                    <Select
                                                        value={mentionAlertTime}
                                                        onChange={updateMentionAlertTime}
                                                        className={classes.input}
                                                    >
                                                        {arrayRotate(
                                                            makeNumberList(0, 24),
                                                            alertsConfig.alertMentionFrequenciesTimeOffset,
                                                        ).map((hour) => (
                                                            <MenuItem
                                                                key={hour}
                                                                value={hour}
                                                                selected={mentionAlertTime === hour}
                                                            >
                                                                {hour === 0
                                                                    ? '12am'
                                                                    : hour === 12
                                                                    ? '12pm'
                                                                    : hour < 12
                                                                    ? `${hour}am`
                                                                    : `${hour - 12}pm`}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </>
                                            )}
                                            {secondTime && (
                                                <span className={classes.secondTime}>
                                                    {'and ' + secondTime}
                                                </span>
                                            )}
                                            {alertsConfig.alertMentionFrequenciesWithTime.includes(
                                                mentionAlertFrequency,
                                            ) && (
                                                <span className={classes.interstitial}>
                                                    {'UTC'}
                                                </span>
                                            )}
                                        </>
                                    )}
                                </div>

                                {showErrors && errors?.type && (
                                    <FormError
                                        type="warning"
                                        errorText={T('alert.type.error')}
                                        actionText={T('alert.type.action')}
                                    />
                                )}

                                {showErrors && errors?.invalidVolumeAlertValue && (
                                    <FormError
                                        type="warning"
                                        errorText={T('invalidVolumeAlertValueError')}
                                    />
                                )}
                            </fieldset>

                            <div className={classes.info}>
                                <InputLabel
                                    className={classes.numAlertsLabel}
                                    id="mentionsPerAlert-label"
                                >
                                    <span>{T('alert.numMentions.question')}</span>{' '}
                                    <HelpIcon
                                        buttonLbl={T('alert.alertNumMentionsHelpLbl')}
                                        tooltip={numMentionsPopup}
                                        placement="bottom-start"
                                        children={undefined}
                                    />
                                </InputLabel>
                                <div className={classes.numAlerts}>
                                    <span className={classes.interstitial}>
                                        {T('alert.numMentions.upTo')}
                                    </span>
                                    <Select
                                        value={mentionsPerAlert}
                                        onChange={updateMentionsPerAlert}
                                        id="mentionsPerAlert"
                                        labelId="mentionsPerAlert-label"
                                        className={classes.input}
                                    >
                                        {alertsConfig.alertNumMentionOptions.map((mins) => (
                                            <MenuItem
                                                key={mins}
                                                value={mins}
                                                selected={mentionsPerAlert === mins}
                                            >
                                                {mins}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <span className={classes.interstitial}>
                                        {T('alert.numMentions.mentions')}
                                    </span>
                                </div>
                            </div>
                            <fieldset className={cx(classes.info)}>
                                <legend className={classes.heading}>
                                    {T('alert.recipients.heading')}
                                </legend>
                                <div className={cx(classes.checkboxesList, classes.space)}>
                                    <div className={classes.checkboxWithLabel}>
                                        <Checkbox
                                            id="alert.emailCreator"
                                            checked={emailCreator}
                                            onChange={(e) => setEmailCreator(e.target.checked)}
                                            className={classes.toggle}
                                        />
                                        <label
                                            className={classes.checkboxLabel}
                                            htmlFor="alert.emailCreator"
                                        >
                                            {T('alert.emailCreator.lbl', {email: emailAddress})}
                                        </label>
                                    </div>
                                </div>

                                <ChipInput
                                    id="alert.additionalRecipients"
                                    error={showErrors && errors?.additionalRecipients}
                                    getInputErrorMessage={(errorValues) =>
                                        errorValues?.length
                                            ? {
                                                  title: T('alert.recipientErrorTitle'),
                                                  desc: T('alert.recipientErrorDesc'),
                                              }
                                            : isEmpty(additionalRecipients)
                                            ? {
                                                  title: T(
                                                      'alert.recipients.errors.noRecipients.lbl',
                                                  ),
                                                  desc: T(
                                                      'alert.recipients.errors.noRecipients.desc',
                                                  ),
                                              }
                                            : null
                                    }
                                    helperText={T('alert.recipients.instructions')}
                                    label={T('alert.recipients.additional')}
                                    onValuesChange={onRecipientsChange}
                                    validateValues={validateRecipients}
                                    values={additionalRecipients}
                                    className={cx(classes.addRecipients)}
                                    required={emailCreator && errors?.additionalRecipients}
                                />
                            </fieldset>

                            <div className={cx(classes.info, classes.filters)}>
                                {numFilters === 0 && (
                                    <>
                                        <IconRouter name="filter" />
                                        <b>{T('no.filters.applied')}</b>
                                        <button
                                            className={classes.optionsButtonLink}
                                            onClick={() => {
                                                setShowEditFilters(true);
                                                setTimeout(
                                                    () => autoScrollDrawer(filtersRootRef.current),
                                                    1000 / 60,
                                                );
                                            }}
                                        >
                                            {T('alert.editFilters')}
                                        </button>
                                    </>
                                )}
                                {numFilters > 0 && (
                                    <>
                                        <IconRouter aria-hidden name="filter" />
                                        <Tooltip
                                            tooltip={filterTooltip}
                                            distance={5}
                                            placement="top"
                                            theme="light"
                                            floatingProps={{className: classes.wideTooltip}}
                                        >
                                            <Markdown tabIndex={0} className={classes.numFilters}>
                                                {numFilters === 1
                                                    ? T('one.filter.applied')
                                                    : T('num.filters.applied', {num: numFilters})}
                                            </Markdown>
                                        </Tooltip>
                                        <button
                                            className={classes.optionsButtonLink}
                                            onClick={() => {
                                                setShowEditFilters(true);
                                                setTimeout(
                                                    () => autoScrollDrawer(filtersRootRef.current),
                                                    1000 / 60,
                                                );
                                            }}
                                        >
                                            {T('alert.editFilters')}
                                        </button>
                                        <button
                                            className={classes.optionsButtonLink}
                                            onClick={clearFilters}
                                        >
                                            {T('clear.filters')}
                                        </button>
                                    </>
                                )}
                            </div>
                            <p className={classes.filtersInfo}>{T('alert.filtersInfo')}</p>
                            <Collapse in={showEditFilters} timeout={0}>
                                <div className={cx(classes.info)} ref={filtersRootRef}>
                                    {
                                        <Filters
                                            useSelector={useSelector}
                                            dispatch={dispatch}
                                            getState={getState}
                                            subscribe={subscribe}
                                            autoScrollDrawer={autoScrollDrawer}
                                            canSaveSearch={canSaveSearch}
                                            isSavedSearch={true}
                                            compact
                                            onChange={(state: any) => {
                                                //TODO proper types
                                                setFilters(state.filters);
                                            }}
                                            children={undefined}
                                            className={undefined}
                                            isAlertEdit
                                        />
                                    }
                                </div>
                            </Collapse>
                        </div>
                    );

                    //This isn't a callback, it's a render func, therefore the sequence of hook execution
                    // will be preserved
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const actions = useMemo(
                        () => (
                            <>
                                <Button onClick={onClose} priority={alertsConfig.closeBtnPriority}>
                                    {T('cancel')}
                                </Button>
                                <Button
                                    priority={alertsConfig.saveBtnPriority}
                                    onClick={onSaveAlert}
                                    disabled={saving}
                                >
                                    {saving && !errors?.duplicateName ? (
                                        <PulseLoader size="medium" />
                                    ) : (
                                        T('save')
                                    )}
                                </Button>
                            </>
                        ),
                        //These values changing will trigger re-render of the parent component,
                        // which in turn will re-render here
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                        [onClose, alertsConfig, onSaveAlert, saving, errors],
                    );

                    return (
                        <>
                            {wrapContent ? wrapContent(content) : content}
                            {wrapActions ? wrapActions(actions) : actions}
                        </>
                    );
                }}
            </ContextStore>
        </ProjectIdProvider>
    );
}

type LoadDataType = {
    languagesLoaded: boolean;
    isLoadingLangs: boolean;
    tagsProjectId: number | null;
    tagsLoading: boolean;
    tagsLoaded: boolean;
    dispatch: ReturnType<typeof useAppDispatch>;
    projectId: number | null;
    filterLocations: string[];
    isLocationsLoaded: boolean;
    loadedLocations: LocationDefinition[];
};

//TODO error handling
export default WithLoadFirst<
    Omit<AddEditAlertProps, 'loadedLocations'>,
    LoadDataType,
    AddEditAlertProps
>(
    (props, data) => {
        return !!(
            data?.languagesLoaded &&
            data?.tagsLoaded &&
            data.tagsProjectId === data.projectId &&
            data?.isLocationsLoaded
        );
    },
    {
        useCheckHook: ({alert, queryId}) => {
            const dispatch = useAppDispatch();
            const languages = useAppSelector(
                (state) => state.autocomplete.languageAutocomplete.results,
            );
            const isLoadingLangs = useAppSelector(
                (state) => state.autocomplete.languageAutocomplete.loading,
            );
            const tagsProjectId = useAppSelector((state) => state.tags.projectId);
            const tagsLoading = useAppSelector((state) => state.tags.loading);
            const tagsLoaded = useAppSelector((state) => state.tags.loaded);

            const projectId = useGetProjectIdFromQueryId(queryId || (alert?.queryId as number));

            const languagesLoaded = languages.length > 0;

            const filterLocations = useMemo(
                () => [...(alert?.filter?.location ?? []), ...(alert?.filter?.xlocation ?? [])],
                [alert?.filter?.location, alert?.filter?.xlocation],
            );
            const [isLocationsLoaded, setIsLocationsLoaded] = useState(
                filterLocations.length === 0,
            );
            const [loadedLocations, setLoadedLocations] = useState<LocationDefinition[]>([]);

            useEffect(() => {
                if (filterLocations.length > 0) {
                    locationsService
                        .getAllByIds(filterLocations)
                        .then((locationsResults) => {
                            setIsLocationsLoaded(true);
                            setLoadedLocations(locationsResults.results);
                        })
                        .catch(() => {
                            dispatch(
                                showNotification({
                                    message: T('autocomplete.locations.loadingError'),
                                    hidable: true,
                                    variant: 'warning',
                                    anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
                                }),
                            );
                        });
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, []);

            //describe the state of the loaded/unloaded data
            return {
                languagesLoaded,
                isLoadingLangs,
                tagsProjectId,
                tagsLoading,
                tagsLoaded,
                dispatch,
                projectId,

                filterLocations,
                isLocationsLoaded,
                loadedLocations,
            };
        },
        onFirstLoad: (
            props,
            {
                dispatch,
                languagesLoaded,
                isLoadingLangs,
                tagsProjectId,
                projectId,
                tagsLoaded,
                tagsLoading,
            },
        ) => {
            if (!languagesLoaded && !isLoadingLangs) {
                dispatch(loadLanguages()); //load langs
            }

            if (tagsProjectId !== projectId || (!tagsLoading && !tagsLoaded)) {
                dispatch(loadTags(projectId)); //load tags
            }
        },
        mergeProps: (props, {loadedLocations}) => ({
            ...props,
            loadedLocations,
        }),
    },
)(AddEditAlert);
