import { Children, PropsWithChildren, ReactElement, ReactNode, cloneElement, forwardRef, isValidElement, JSXElementConstructor, useMemo, useCallback } from "react";
import {useFloating, UseFloatingOptions, autoUpdate, flip, shift} from '@floating-ui/react-dom';
import { mergeRefs } from "hsi/utils/react";

//#region Consts
const PositionReferenceRef = 'PositionReference';
const PositionFloatingRef = 'PositionFloating';

export const SimpleFlip = [flip()];
export const SimpleFlipAndShift = [flip(), shift()]
//#endregion

//#region Types
type PositionProps = PropsWithChildren<Omit<UseFloatingOptions, 'elements'>>;
//#endregion

/**
 * The position component is used to position one elenent relative to another, generally as a 'floating' element, such as a popover or tooltip 
 */
const Position = forwardRef<HTMLElement, PositionProps>(function Position({children, middleware, open, placement, platform, strategy, whileElementsMounted, transform, ...rest}, ref) {
    const whileElementMountedCallback = useCallback<NonNullable<typeof whileElementsMounted>>((...args) => {
        const callback1 = autoUpdate(...args);
        const callback2 = whileElementsMounted?.(...args);

        return () => {
            callback1?.();
            callback2?.();
        }
    }, [whileElementsMounted]);

    const floatingOptions = {
        middleware, open, placement, platform, strategy, whileElementsMounted: whileElementMountedCallback, transform
    };

    const {refs: {setReference, setFloating}, floatingStyles} = useFloating<HTMLElement>(floatingOptions);

    const referenceElement = Children.only(getElementByComponentId(children, PositionReferenceRef)?.props?.children) as ReactElement | null;
    const floatingElement = Children.only(getElementByComponentId(children, PositionFloatingRef)?.props?.children) as ReactElement | null;

    const referenceElementBaseRef = (referenceElement as any)?.ref;
    const referenceElementProps = useMemo(() => ({
        ref: mergeRefs(ref, setReference, referenceElementBaseRef)
    }), [ref, setReference, referenceElementBaseRef]);

    const floatingElementBaseRef = (floatingElement as any)?.ref
    const floatingElementBaseStyles = floatingElement?.props?.style;
    const floatingElementProps = useMemo(() => ({
        ref: mergeRefs(setFloating, floatingElementBaseRef), 
        style: {...floatingElementBaseStyles, ...floatingStyles}
    }), [setFloating, floatingElementBaseRef, floatingStyles, floatingElementBaseStyles]);

    return <>
        {referenceElement && cloneElement(referenceElement, {...referenceElementProps, ...rest})/* <<<<< TODO rest might contain props which overwrite reference elements own props, how to merge? e.g. stylej */}
        {open && referenceElement && floatingElement && cloneElement(floatingElement, floatingElementProps)}
    </>
});

//#region Sub-components
const PositionReference = addComponentId(forwardRef<HTMLElement, PropsWithChildren<{}>>(function PositionReference({children, ...rest}, ref) {
    const child = Children.only(children) as ReactElement;

    return cloneElement(child, {ref: mergeRefs(ref, (child as any).ref)})
}), PositionReferenceRef);

const PositionFloating = addComponentId(forwardRef<HTMLElement, PropsWithChildren<{}>>(function PositionFloating({children, ...rest}, ref) {
    const child = Children.only(children) as ReactElement;

    return cloneElement(child, {ref: mergeRefs(ref, (child as any).ref)})
}), PositionFloatingRef);
//#endregion

export default Object.assign(Position, {
    Reference: PositionReference,
    Floating: PositionFloating
});

//#region Utils
type ComponentWithId<T extends JSXElementConstructor<any>> = T & {_componentId: string};

function addComponentId<T extends JSXElementConstructor<any>>(obj: T, type: string): ComponentWithId<T> {
    return Object.assign(obj as any, {_componentId: type});
}

function getElementByComponentId(children: ReactNode, id: string): ReactElement | null {
    let foundElement: ReactElement | null = null;

    //Iterate over children in order to avoid modifying the element
    Children.forEach(children, (child) => {
        if(isValidElement(child)) {
            if(getComponentIdOfElement(child) === id) {
                if(foundElement !== null) {
                    throw new Error('Found multiple children matching supplied component id');
                }

                foundElement = child;
            }
        }
    })

    return foundElement;
}

//@ts-expect-error TS complains that ComponentWithId<T> isn't valid, because T might be a string, which is not allowed, but the actual logic of the typeguard will prevent that
function isTypedComponent<T extends string | JSXElementConstructor<any>>(component: T): component is ComponentWithId<T> {
    if((typeof component) === 'string' || (typeof component) === 'symbol') {
        return false;
    }
    return ('_componentId' in (component as any)) ? !!(component as any)?._componentId : false;
}

function getElementType(node: ReactNode): JSXElementConstructor<any> | string | null {
    if(isValidElement(node)) {
        return node.type;
    }

    return null;
}

function getComponentIdOfElement(element: ReactElement): string | null {
    const type = getElementType(element);

    return type && isTypedComponent(type) ? type._componentId : null
}
//#endregion
