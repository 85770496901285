import { isV2 } from 'hsi/types/theme';
import { makeStyles } from 'tss-react/mui';

export default makeStyles<void, 'right'>()(
    (theme, _params, classes) => {
        const v2 = isV2(theme);
        const {mixin, font} = theme;

        return {
            navButton: {
                borderRadius: 100,
                height: v2 ? 'inherit' : 44,
                color: 'currentColor!important',

                [`&:nth-child(1 of .${classes.right})`]: {
                    marginLeft: 'auto',
                },
            },
            right: {},
            icon: {
                width: v2 ? 24 : 16,
                height: v2 ? 24 : 16,
            },
            label: v2
                ? {...mixin.offscreen}
                : {
                    fontFamily: font.name,
                    fontWeight: 700,
                    fontSize: 16,
                    marginLeft: 10,
                },
        }
});
