import React, {useMemo, useLayoutEffect, useState, useRef, ReactNode} from 'react';
import cn from 'classnames';

//Hooks
import useStyles from './styles';
import useElementSize from 'hsi/hooks/useElementSize';

//Other
import {T} from 'hsi/i18n';

//Consts
const sizeToContentHookOptions = {
    width: true,
    getTargetElement: (x: HTMLDivElement) => x?.parentElement ?? x,
};
const defaultHookOptions = {width: true};

//The component
export type GetOverflowContent = (overflowIndex: number, totalChildren: number) => ReactNode;

export interface HorizontalOverflowProps extends React.HTMLAttributes<HTMLDivElement> {
    className?: string;
    gap?: number;
    getOverflowContent?: GetOverflowContent;
    sizeToContent?: boolean;
}

export default function HorizontalOverflow({
    children,
    className,
    style,
    gap = 0,
    getOverflowContent = defaultGetOverflowContent,
    sizeToContent = false,
    ...rest
}: HorizontalOverflowProps) {
    const classes = useStyles();

    const containerRef = useRef<HTMLDivElement>(null);

    //Measure element sizes
    const [sizeRef, dimensions] = useElementSize(
        containerRef,
        sizeToContent ? sizeToContentHookOptions : defaultHookOptions,
    );

    const [overflowIndicatorRef, {width: overflowIndicatorWidth} = {width: undefined}] =
        useElementSize<HTMLDivElement>(undefined, {
            width: true,
        }); //Needed to measure the size of the overflow indicator

    //Internal state
    const [overflowIndex, setOverflowIndex] = useState(-1);

    //Memo
    const styleObj = useMemo(
        () => ({
            ...style,
            '--gap': `${gap}px`,
        }),
        [style, gap],
    );

    //Side effect
    useLayoutEffect(() => {
        const element = containerRef.current;

        if (!element || !dimensions) {
            return;
        }

        const childNodes = Array.from(element.children) as HTMLElement[];

        if (!childNodes.every((child) => child instanceof HTMLElement)) {
            throw new Error(
                'HorizontalOverflow component requires that all children are HTMLElements',
            );
        }

        const overflowIndicator = childNodes.pop();

        if (!overflowIndicator || !(overflowIndicator instanceof HTMLElement)) {
            return;
        }

        sizeToContent && (element.style.maxWidth = '100%');

        const {right: containerRight} = sizeToContent
            ? element.getBoundingClientRect()
            : dimensions!;

        //make all child nodes visible
        childNodes.forEach((node) => (node.style.display = ''));

        //if result = -1, then all children can be displayed and the overflowIndicator can be hidden,
        //otherwise hide all nodes after this point
        const firstHiddenIndex = childNodes.findIndex((node, index) => {
            const bounds = node.getBoundingClientRect();
            const threshold =
                index === childNodes.length - 1
                    ? containerRight
                    : containerRight - (gap + (overflowIndicatorWidth ?? 0));

            return bounds.right > threshold;
        });

        if (firstHiddenIndex === -1) {
            overflowIndicator.style.display = 'none';
        } else {
            overflowIndicator.style.display = '';

            for (let i = firstHiddenIndex; i < childNodes.length; i++) {
                childNodes[i].style.display = 'none';
            }
        }

        sizeToContent && (element.style.maxWidth = 'fit-content');

        if (overflowIndex !== firstHiddenIndex) {
            setOverflowIndex(firstHiddenIndex); //trigger a re-render & store overflow index
        }
    }, [dimensions, gap, overflowIndex, overflowIndicatorWidth, sizeToContent, children]);

    return (
        <div className={cn(classes.root, className)} style={styleObj} {...rest} ref={sizeRef}>
            {children}
            <div ref={overflowIndicatorRef}>
                {getOverflowContent(overflowIndex, React.Children.count(children))}
            </div>
        </div>
    );
}

//Helper methos
export function defaultGetOverflowContent(overflowIndex: number, totalChildren: number) {
    return T('horizontalOverflow.defaultMsg', {num: totalChildren - overflowIndex});
}
