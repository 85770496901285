import { useMemo } from "react";
import { ReactNode } from "react-markdown";
import { FormatColumnDefinition, FormatColumnsDefinition } from "./types";



export default function useFormatRows<RowItemType, Columns extends string | number | symbol = keyof RowItemType>(rows: RowItemType[] | Readonly<RowItemType[]>, columns: FormatColumnsDefinition<RowItemType, Columns>) {
    return useMemo(() => {
        return rows.map((row) => {
            return (columns as FormatColumnDefinition<RowItemType, Columns>[]).reduce((output, {name, format}) => {
                output[name] = format 
                    ? format(row, name) 
                    : row[name as unknown as keyof RowItemType] as ReactNode;

                return output;
            }, {} as Record<Columns, ReactNode>)
        })
    }, [columns, rows]);
}