//This needs a refactor, although I'm not really sure if we even need this in redux at all

import filter from 'lodash/filter';
import {
    TAGS_LOADING,
    TAGS_LOADED,
    TAGS_LOAD_ERROR,
    SAVED_TAG,
    SAVE_TAG_ERROR,
    EDITED_TAG,
    DELETED_TAG,
    TAGGED_MENTIONS_ERROR,
    TAGGED_MENTIONS_LOADING,
    TAGGED_MENTIONS_LOADED,
} from '../constants/actionTypes';

const initialState = {
    results: [],
    projectId: null,
    loading: false,
    loaded: false,
    error: false,
    mentions: {
        results: [],
        isOpen: true,
        loading: false,
        error: false,
        page: 0,
        hasMore: false,
        resultsTotal: 0,
    },
};

const tagsReducer = (state = initialState, action) => {
    switch (action.type) {
        case TAGS_LOADING:
            return {
                ...state,
                projectId: action.payload,
                results: [],
                loading: true,
                loaded: false,
                error: false,
            };
        case TAGS_LOADED:
            return {
                ...state,
                projectId: action.payload.projectId,
                results: action.payload.results,
                loading: false,
                loaded: true,
                error: false,
            };
        case TAGS_LOAD_ERROR:
            return {...state, error: true, loading: false, loaded: false};
        case DELETED_TAG:
            return {...state, results: filter(state.results, (o) => o.id !== action.payload.id)};
        case EDITED_TAG:
            let editedTagList = filter(state.results, (o) => o.id !== action.payload.id);
            editedTagList.push(action.payload);
            return {
                ...state,
                results: editedTagList,
            };
        case SAVED_TAG:
            let withNewTag = state.results.slice(0);
            withNewTag.push(action.payload);
            return {...state, results: withNewTag, error: false};
        case SAVE_TAG_ERROR:
            return {...state, error: true};
        case TAGGED_MENTIONS_LOADING:
            const mentionsState = Object.assign({}, state.mentions, {
                ...action.payload,
            });
            return {...state, mentions: mentionsState};

        case TAGGED_MENTIONS_LOADED:
            const mentionsResultsState = Object.assign({}, state.mentions, {
                ...action.payload,
                error: false,
            });
            return {
                ...state,
                mentions: mentionsResultsState,
            };
        case TAGGED_MENTIONS_ERROR:
            return {
                ...state,
                mentions: {
                    ...state.mentions,
                    error: true,
                    loading: false,
                },
            };
        default:
            return state;
    }
};

export default tagsReducer;
