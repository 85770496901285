import {QuickSearchMentionApiType, QuickSearchMentionType} from 'hsi/types/mentions';

export default function normaliseQuickSearchMention(
    mention: QuickSearchMentionApiType,
): QuickSearchMentionType {
    return {
        apiType: 'quick',
        ...mention,
    };
}
