
import Drawer from '@mui/material/Drawer';

//Components
import ToggleDrawerButton from 'hsi/components/ToggleDrawerButton';
import WordCloudControls from './WordCloudControls';
import TotalVolumeControls from './TotalVolumeControls';

//Hooks
import { useAppSelector } from 'hsi/hooks/useRedux';

//Other
import {T} from 'hsi/i18n';

import useStyles from './styles';
import { useMemo } from 'react';
import Heading, { HeadingContents, ResetHeadingLevel } from 'hsi/components/aria/Heading';
import { CardType } from 'hsi/types/cards';
import { PersistedCardType } from 'hsi/slices/cardPersistState';

//Types
export type ConfigSideDrawerProps = {
    onClose: () => void;
};

//The component
export default function ConfigSideDrawer ({onClose}: ConfigSideDrawerProps) {
    const classes = useStyles();
    const {open, type} = useAppSelector((state) => state?.results?.sideDrawer as {open: boolean, type: CardType});
    const selectedParams = useAppSelector((state) => state?.cardPersistState[type as PersistedCardType]);
    
    const configControls = useMemo(() => {
        switch(type) {
            case 'wordCloud':
                return <WordCloudControls selectedParams={selectedParams} />
            case 'totalVolume':
                return <TotalVolumeControls selectedParams={selectedParams} />;
            default:
                throw new Error('Unknown type for config controls');
        }
    }, [selectedParams, type]);

    return (
        <Drawer
            anchor="right"
            className={classes.drawer}
            data-testid="configSideDrawer"
            onClose={onClose}
            open={open}
            disablePortal={process.env.NODE_ENV !== 'test'} //This breaks the tests for some reason
        >
            <ResetHeadingLevel>
                <div className={classes.content}>
                    <Heading className={classes.title}>{T('configSideDrawer.title')}</Heading>
                    <HeadingContents>
                        <ToggleDrawerButton
                            direction="right"
                            toggleDrawer={onClose}
                            open={open}
                            openLabel={''}
                            className={classes.closeBtn}
                        />
                        <div className={classes.controls}>{configControls}</div>
                    </HeadingContents>
                </div>
            </ResetHeadingLevel>
        </Drawer>
    );
};
