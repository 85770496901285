import {useState, useEffect, MutableRefObject} from 'react';

/** https://usehooks.com/useOnScreen/
 * This hook provides a ref that let's you know if an element is on screen or not on screen.
 * It will retrigger if an element is hidden and then reappears. This is where it differs from
 * useOnVisible which only triggers once. This is currently used for infinite scroll on the DataTable
 * component
 *
 * This is a much more lightweight hook compared to useOnVisible, it does not allow a configurable 'root',
 * it generates a new IntersectionObserver for each call of this hook (inefficient when there are many things
 * inside the same scrolling context being checked), but is useful for simpler situations where that extra
 * capability is not required.
 * */

export default function useOnScreen<T extends Element>(
    ref: MutableRefObject<T>,
    rootMargin: string = '0px',
): boolean {
    const [isIntersecting, setIntersecting] = useState<boolean>(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Update our state when observer callback fires
                setIntersecting(entry.isIntersecting);
            },
            {
                rootMargin,
            },
        );

        const currentRef = ref.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [ref, rootMargin]);

    return isIntersecting;
}
