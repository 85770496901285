import makeStyles from '@mui/styles/makeStyles';
import { isV2 } from 'hsi/types/theme';

export default makeStyles((theme) => {
    const v2 = isV2(theme);

    return {
        nav: {
            flex: '1 1 auto',
        },
        icon: {
            width: v2 ? 24 : 16,
            height: v2 ? 24 : 16,
        },
    };
});
