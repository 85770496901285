import {useAppSelector} from './useRedux';

export default function useIsActionPendingOnMentions(
    mentionIds: string | string[] | Record<string, boolean>,
) {
    const mentionIdsArr =
        typeof mentionIds === 'string'
            ? [mentionIds]
            : mentionIds instanceof Array
            ? mentionIds
            : objSetToArray(mentionIds);

    return useAppSelector(
        mentionIdsArr.length === 1
            ? (state) => state.mentions.editMentionStatus[mentionIdsArr[0]]?.loading === true
            : (state) => {
                  const editMentionStatus = state.mentions.editMentionStatus;

                  return mentionIdsArr.some(
                      (mentionId) => editMentionStatus[mentionId]?.loading === true,
                  );
              },
    );
}

function objSetToArray(obj: Record<string, boolean>) {
    const arr: string[] = [];

    for (let key in obj) {
        if (obj.hasOwnProperty(key) && obj[key]) {
            arr.push(key);
        }
    }

    return arr;
}
