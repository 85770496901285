//Components
import Time from 'hsi/components/Time';
import {MouseEventHandler} from 'react';

type MentionDateProps = {
    date: any;
    classes: {
        date?: string;
    };
    onViewOriginalClick?: MouseEventHandler<HTMLAnchorElement>;
    url?: string;
};

//The component
export default function MentionDate({date, classes, onViewOriginalClick, url}: MentionDateProps) {
    const time = <Time className={classes.date} format="MMM d, h:mm a ZZZZ" value={date} />;

    return url ? (
        <a //When add a link to the original mention on the date for some reason. We have decided to keep this, even though it doesn't make sense because some users will be used to it. I have taken it out of the tab order to make it easier to use with keyboard navigation
            className={classes.date}
            href={url}
            rel="noreferrer"
            target="_blank"
            onClick={onViewOriginalClick}
            tabIndex={-1}
        >
            {time}
        </a>
    ) : (
        time
    );
}
