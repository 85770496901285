import qs from 'qs';

import http from 'hsi/classes/Http';

const url = '/api/locations';

export const serializeParams = (params) =>
    qs.stringify(params, {addQueryPrefix: true, indices: false});

export const validLocationId = ({locationId, filterOptions}) =>
    !!(filterOptions?.filters?.location || []).find((locId) => locationId === locId);

export const parseLocationRes = (res, filterOptions) =>
    (res?.body?.results || [])
        .map(({id}) => id)
        .filter((locationId) => validLocationId({locationId, filterOptions}));

export const fetchLocationQuery = async ({locationId, filterOptions}) =>
    http
        .get(`${url}${serializeParams({prefix: locationId, pageSize: 1, page: 0})}`)
        .then((res) => parseLocationRes(res, filterOptions));

export const queryLocation = async ({locationId, filterOptions}) =>
    validLocationId({locationId, filterOptions})
        ? Promise.resolve(locationId)
        : fetchLocationQuery({locationId, filterOptions});

export const queryLocations = async ({filters, filterOptions}) =>
    Promise.all(filters?.location?.map((locationId) => queryLocation({locationId, filterOptions})));
