import http from "hsi/classes/Http";
import { AllFilteringState } from "hsi/types/filters";
import { QueryContextType } from "hsi/types/query";
import { filterStateToAPIFormat } from "hsi/utils/filters";
import { getSearchParams, queryParamsToString, QuickSearchParamsType } from "hsi/utils/url";


export async function checkSearchHasData(
    projectId: number, 
    filters: Pick<AllFilteringState, 'filters' | 'allFiltersConfig' | 'dateRange'>,
    queryContext: QueryContextType,
) {
    //timezone is not required as start & end date are already specified
    const {search, timezone, ...params} = getSearchParams(
        filterStateToAPIFormat(filters.filters, filters.allFiltersConfig),
        filters.dateRange,
        queryContext,
        undefined,
        undefined,
        true
    ) as QuickSearchParamsType;

    return http.get<{result: boolean}>(`/apiv2/${projectId}/quick-search/${encodeURIComponent(search)}/results/hasData${queryParamsToString(params)}`)
}
