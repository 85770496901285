import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => {
    return {
        textFilterField: {
            display: 'flex',
            flexFlow: 'column nowrap',
            gap: 4,
            flex: '1 1 100%',
            alignSelf: 'stretch',
        },

        textFilterInput: {
            display: 'flex',
            flexWrap: 'wrap',

            '& > *': {
                flex: '1 1 100%',
                marginLeft: 0,
            },
        },

        chipsContainer: {
            display: 'flex',
            justifyContent: 'left',
            flexWrap: 'wrap',
            gap: 4,
        },
    };
});
