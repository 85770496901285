import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    saveSearchDialog: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none !important',
        },
    },
    query: {
        background: theme.v2 ? theme.colors.uiGrayDark : theme.colors.lightGrey40,
        padding: '8px 12px',
        display: 'flex',
        justifyContent: 'space-between',
        gap: 8,
    },
    editQueryBtn: {
        ...theme.mixin.linkButton,

        textTransform: 'uppercase',

        ...(theme.v2
            ? {
                  ...theme.typgrphy.buttonNormal,
              }
            : {
                  fontSize: 16,
                  fontWeight: theme.font.normal,
              }),
        //color: theme.
    },
    hide: {
        position: 'absolute',
        overflow: 'hidden',
        width: '1px',
        height: '1px',
        margin: '-1px',
        padding: 0,
        border: 0,
        clip: 'rect(0, 0, 0, 0)',
    },
    field: {
        paddingLeft: '6px',
        marginBottom: '25px',
    },
    label: {
        fontSize: '14px',
        color: theme.v2 ? theme.colors.textDefault : '#2f3638',
        fontWeight: theme.font.semiBold,
    },
}));
