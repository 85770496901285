import {useCallback} from 'react';
import cn from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import IconRouter from 'hsi/components/IconRouter';
import Tooltip from 'hsi/components/Tooltip';

import useConfig from 'hsi/hooks/useConfig';

import {T} from 'hsi/i18n';

const useStyles = makeStyles({
    listItem: {
        '&:hover $helpIcon': {
            opacity: 1,
        },
    },
    listItemText: {
        display: 'flex',
        alignItems: 'center',
    },
    helpIcon: {
        opacity: 0,
        marginLeft: 6,
        height: 16,
        width: 16,
        '& .app-icon': {
            height: 16,
            width: 16,
        },
    },
});

type MenuRootProps = {
    menuLabel: string;
    onSelectAccountType: (
        accountType: ReturnType<
            typeof useConfig
        >['guidedSearch']['socialAccounts'][number]['selectorType'],
    ) => void;
};

const MenuRoot = ({menuLabel, onSelectAccountType}: MenuRootProps) => {
    const cl = useStyles();
    const config = useConfig();

    const getHelpIcon = useCallback(
        (elem: HTMLElement) => elem.querySelector<HTMLElement>(`.${cl.helpIcon}`),
        [cl.helpIcon],
    );

    return (
        <List aria-label={menuLabel}>
            {config.guidedSearch.socialAccounts.map(({selectorType, label, tooltip}, i) => (
                <ListItem
                    aria-label={T(label)}
                    className={cn(cl.listItem)}
                    key={i}
                    onClick={() => onSelectAccountType(selectorType)}
                    role="listitem"
                >
                    <Tooltip
                        align="left"
                        morePadding
                        placement="right"
                        theme="light"
                        tooltip={T(tooltip)}
                        distance={8}
                        getPositionElement={getHelpIcon}
                    >
                        <ListItemText
                            primary={
                                <div className={cl.listItemText}>
                                    <span>{T(label)}</span>

                                    <IconRouter
                                        aria-label={`${T(label)} help`}
                                        className={cl.helpIcon}
                                        name="c-question-e"
                                    />
                                </div>
                            }
                        />
                    </Tooltip>
                    <KeyboardArrowRightIcon />
                </ListItem>
            ))}
        </List>
    );
};

export default MenuRoot;
