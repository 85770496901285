// TODO: Convert to typescript
// TODO: Add tests
const authenticate = (callback) => {
    const loginCallback = (e) => {
        if (e.data.name === 'authTokenRegistered') {
            window.removeEventListener('message', loginCallback);
            callback(e.data);
        }
    };
    window.addEventListener('message', loginCallback);
    window.open('/fe-api/linkedin/login', 'linkedin_signin', 'height=500,width=400');
};

export default authenticate;
