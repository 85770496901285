import {PropsWithChildren} from 'react';
import CCFlagsProvider from './CCFlagsProvider';

import UrlFlagsProvider from './UrlFlagsProvider';

import useBypassCC from 'hsi/hooks/useBypassCC';


type LoadFlagsProps = PropsWithChildren;

export default function LoadFlags({children}: LoadFlagsProps) {
    const bypassCC = useBypassCC();
    return bypassCC ? <UrlFlagsProvider>{children}</UrlFlagsProvider>
    : <CCFlagsProvider>{children}</CCFlagsProvider>;
}
