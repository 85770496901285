// Hooks
import useConfig from 'hsi/hooks/useConfig';
import {useAppSelector} from 'hsi/hooks/useRedux';
import {useEffect} from 'react';

// Configs
import {CHURN_ZERO_DATA_BY_REGION, ChurnZeroRegion, GCID_REGION_RANGES} from 'hsi/constants/config';

// Global variables
let isChurnZeroInitialized = false;

/* Get ChurnZero data (url and key), if the "sourceId" is in the region range. */
function getChurnZeroData(sourceId: string) {
    let region: ChurnZeroRegion = 'US';
    const sourceIdAsNumber = Number(sourceId);
    /* This check is in case the 'sourceId' would ever include other chars 
        rather than only digits and could not be converted to a number. */
    if (isNaN(sourceIdAsNumber)) {
        throw new Error('Could not convert sourceId into a number.');
    }
    /* The sourceId should be in the GCID region ranges (not just exactly match the identifier),
        so we can map the user with the region.
        
        We should use these ranges as follows:
        - US is everything from 2000001 to 5000000
        - Canada (+LATAM,/APAC) is everything from 5000001 to 82600000000
        - EMEIA is everything from 82600000001 to 92100000000
        - France is everything from 92100000001 and above 

        If the sourceId is below the US range, i.e. 2000001, then fallback to the US region. */
    for (const [key, {from, to}] of Object.entries(GCID_REGION_RANGES)) {
        if (sourceIdAsNumber >= from && sourceIdAsNumber <= to) {
            region = key as ChurnZeroRegion;
        }
    }

    return CHURN_ZERO_DATA_BY_REGION[region];
}

/* This creates a <script> tag for ChurnZero with the source to the required url. */
function fetchChurnZero(url: string) {
    const cz = document.createElement('script');
    cz.type = 'text/javascript';
    cz.async = true;
    cz.src = url;
    const script: any = document.getElementsByTagName('script')[0];
    script.parentNode.insertBefore(cz, script);
}

function initializeChurnZero(sourceId: string, sourceEmail: string) {
    /* This is to prevent of iniatilizing ChurnZero after it has been already initialized. 
        This is just in case if the <ChurnZeroSnippet> would be mounted for a second time.
        I'm not 100% sure if it is currently possible, but if it is, the error should be caught by this. */
    if (isChurnZeroInitialized) {
        throw new Error(
            'Trying to initialize ChurnZero, although it has been initialized already.',
        );
    }
    /* ChurnZero is configured through global window.ChurnZero object/array. */
    window.ChurnZero = window.ChurnZero ?? [];
    const churnZeroData = getChurnZeroData(sourceId);
    if (churnZeroData) {
        const {url, key} = churnZeroData;
        fetchChurnZero(url);

        /* 'setAppKey' is required to identify the app in the ChurnZero system. "key" is a unique ID of the app 
            and can be retrieved from the configs file. */
        window.ChurnZero.push(['setAppKey', key]);
        /* 'setContact' is used for the user identification in the app. This is done by identifying:
            - the 'contact' (aka "sourceId"), which is a unique record to identify user's account;
            - the 'account' (aka "sourceEmail"), which must be unique within the account.
            Resource: https://support.churnzero.com/hc/en-us/articles/360004683552-Integrate-ChurnZero-using-Javascript */
        window.ChurnZero.push(['setContact', sourceId, sourceEmail]);
        isChurnZeroInitialized = true;
    }
}

const ChurnZeroSnippet = () => {
    // Redux
    const {sourceId, sourceEmail} = useAppSelector((state) => state.user.account);
    const {appSource} = useConfig();

    useEffect(() => {
        if (appSource === 'C1' && sourceId && sourceEmail) {
            try {
                initializeChurnZero(sourceId, sourceEmail);
            } catch (error) {
                console.error('Could not initialize ChurnZero. ', error);
            }
        }
    }, [appSource, sourceId, sourceEmail]);

    return null;
};

export default ChurnZeroSnippet;
