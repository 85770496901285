import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 8,

        '& .hsib-icon-holder': theme.v2
            ? null
            : {
                  flex: '0 0 36px',
                  width: '36px',
                  height: '36px',
                  padding: '8px',
                  marginLeft: '8px',
                  marginRight: '12px',
                  borderRadius: '50%',
                  backgroundColor: theme.colors.primaryRed40,
              },
    },
    icon: theme.v2
        ? {
              fill: theme.colors.error,
              width: 38,
              height: 38,
              margin: '0 6px',
              paddingRight: 12,
              borderRight: `1px ${theme.colors.uiGrayDarker} solid`,
          }
        : {
              fill: theme.colors.primaryRed100,
              width: '20px',
              height: '20px',
          },
    message: {
        ...(theme.v2 ? theme.typgrphy.bodyNormal : null),

        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'left',
        padding: theme.v2 ? '8px 0' : 8,
        '& div': {
            margin: '0 3px',
        },
    },
    a: {
        ...theme.mixin.linkButton,
        ...(theme.v2 ? theme.typgrphy.bodyStrong : null),
        cursor: 'pointer',
    },
}));
