import {forwardRef, useCallback, useMemo} from 'react';
import isEmpty from 'lodash/isEmpty';

//Components
import CardTitle, { formatCardTitleAsString } from 'hsi/components/Card/CardTitle';
import InfoPopupContent from 'hsi/components/InfoPopupContent';

//Hooks
import useConfig from 'hsi/hooks/useConfig';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';
import { CardTypeProps, TopLanguageType } from 'hsi/types/cards';

import useCardData from 'hsi/hooks/useCardData';
import CardLoadState from 'hsi/components/Card/CardLoadState';
import ManagedGridTable from 'hsi/components/table/ManagedGridTable';
import useDrillInMentions from 'hsi/hooks/useDrillInMentions';
import useAddPositionToRows, { WithPosition } from '../hooks/useAddPositionToRows';
import { ColumnDefinition, FormatColumnDefinition } from 'hsi/components/table/ManagedTable/types';
import CompactNumber from 'hsi/components/format/CompactNumber';
import { getLanguageDrillInFilters } from 'hsi/utils/filters/drillInFilters';
import useFormatRows from 'hsi/components/table/ManagedTable/useFormatRows';
import NumberWithPercentMeter from 'hsi/components/NumberWithPercentMeter';
import useIsSavedSearch from 'hsi/hooks/useIsSavedSearch';
import { AddUniqAuthorsCTA } from 'hsi/components/UniqAuthorsCTA';

//Types
type TopLanguageWithPositionType = WithPosition<TopLanguageType>;
type Columns = keyof TopLanguageWithPositionType;
type TableColumnDefinition = ColumnDefinition<Columns> & FormatColumnDefinition<TopLanguageWithPositionType, Columns>;

//Constants
const type = 'toplanguages';
const DEFAULT_DATA: TopLanguageType[] = [];


//The components
const TopLanguages = forwardRef<HTMLDivElement, CardTypeProps>(({title, ...props}, ref) => {
    const {
        links: {dashboardInfoTopLanguagesCTA: popupCTA},
    } = useConfig();
    
    const drillInMentions = useDrillInMentions();
    const getDrillInData = useGetDrillInData();

    const {data, loading, loaded, error, loadData, isCardSelected} = useCardData({
        type,
        dataSelector: (state) => state.chart[type],
    });
    
    const columns = useGetColumns();


    //Calculated values
    const hasData = !!data && !isEmpty(data);

    // Add position property to row data
    const positionedRows = useAddPositionToRows(data ?? DEFAULT_DATA);

    // Apply formatting
    const formattedRows = useFormatRows(positionedRows, columns);


    const popup = useMemo(
        () => (
            <InfoPopupContent
                title={T(`cards.${type}.info.title`)}
                copy={T(`cards.${type}.info.copy`)}
                ctaUrl={popupCTA}
                ctaLabel={T('cards.infoCTALabel')}
            />
        ),
        [popupCTA],
    );

    return (
        <CardLoadState
            title={
                <CardTitle
                    title={title}
                    type={type}
                    tooltipComponent={popup}
                    hasData={hasData}
                />
            }
            error={error}
            hasData={hasData}
            loading={loading}
            loaded={loaded}
            loadData={loadData}
            selected={isCardSelected}
            ref={ref}
            data-testid={type}
            type={type}
            {...props}
        >
            <AddUniqAuthorsCTA type={type}>
                <ManagedGridTable
                    columns={columns}
                    rowData={formattedRows}
                    caption={T('managedTable.caption', {name: formatCardTitleAsString(title)})}

                    rowSelectable
                    onRowClick={(index: number) => {
                        drillInMentions(getDrillInData(positionedRows[index]));
                    }}
                    sortColumn="total"
                    sortDirection="desc"
                />
            </AddUniqAuthorsCTA>
        </CardLoadState>
    );
});

TopLanguages.displayName = 'TopLanguages';

export default TopLanguages;



function useGetColumns(): TableColumnDefinition[] {
    const classes = useStyles();
    const isSavedSearch = useIsSavedSearch();

    return useMemo<TableColumnDefinition[]>(() => {
        const columns: TableColumnDefinition[] = [
            {
                name: 'position',
                label: <span className="offscreen">{T('cards.toplanguages.tableFields.position')}</span>,
                horizontalAlign: 'start',
                width: 'auto',
                format: (row) => <span className={classes.tableRowPosition}>{row.position}</span>
            },
            {
                name: 'name',
                label: T(`cards.toplanguages.tableFields.name`),
                width: '1fr',
                truncateCellText: true,
                horizontalAlign: 'start',
                format: ({name}) => <>
                    {name}
                </>
            },
            {
                name: 'total',
                label: T(`cards.toplanguages.tableFields.total`),
                width: 'minmax(auto, 15%)',
                format: (row) => <CompactNumber value={+row.total} tooltip />
            },
        ];

        if(isSavedSearch) {
            columns.push({
                name: 'authorTotal',
                label: T(`cards.toplanguages.tableFields.authorTotal`),
                width: 'minmax(150px, 25%)',
                format: (row) => <NumberWithPercentMeter value={row.authorTotal} fraction={row.authorPercent} />
            });
        }

        return columns
        
    }, [classes.tableRowPosition, isSavedSearch]);
}



function useGetDrillInData() {
    return useCallback(
        (language: TopLanguageType) => ({
            dates: undefined,
            filter: getLanguageDrillInFilters(language),
            label: language.name,
            tracking: {
                name: 'cardDrilledIn',
                type,
                value: language.name,
            },
            type,
        } as const),
        [],
    );
};
