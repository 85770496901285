import EntitiesRemaining from 'hsi/components/EntitiesRemaining';
import PulseLoader from 'hsi/components/PulseLoader';

import useStyles from './styles';

import {T} from 'hsi/i18n';

type SubtitleProps = {
    isLoading: boolean;
    maxEntities: number;
    numEntities: number;
};

const Subtitle = ({isLoading, maxEntities, numEntities}: SubtitleProps) => {
    const classes = useStyles();

    return (
        <div className={classes.subtitleWrapper}>
            {isLoading ? (
                <PulseLoader className={classes.subtitleLoader} />
            ) : (
                <>
                    <div className={classes.subtitle} data-testid="datamanagement-subtitle">
                        {T('dataManagement.subtitle', {num: numEntities})}
                    </div>
                    <span className={classes.subtitleEntitiesLeft} />
                    <EntitiesRemaining
                        content={T('dataSourcesLeft.content', {num: maxEntities})}
                        maxEntities={maxEntities}
                        numEntities={numEntities}
                        title={T('dataSourcesLeft.title')}
                    />
                </>
            )}
        </div>
    );
};

export default Subtitle;
