import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    return {
        root: {
            display: 'inline-block',
        },

        icon: {
            marginRight: '8px',
            fill: 'currentColor',
        },

        btn: {
            flexFlow: 'column nowrap',
        },

        btnContent: {
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
            opacity: 0,
            visibility: 'hidden',
            '--c2cTransitionTime': '0.4s',
            transition: 'visibility 0s var(--c2cTransitionTime) linear, opacity calc(var(--c2cTransitionTime) / 2) 0s linear',

            '&:not(:first-child)': {
                marginTop: '-16px',
            }
        },

        visible: {
            transition: 'visibility var(--c2cTransitionTime) 0s linear, opacity calc(var(--c2cTransitionTime) / 2) calc(var(--c2cTransitionTime) / 2) linear',
            visibility: 'visible',
            opacity: 1,
        }
    };
});