import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);

    return {
        root: {
            display: 'flex',
            flexFlow: 'row nowrap',
            gap: '1px',
            background: v2 ? theme.colors.uiGrayDarker : theme.colors.darkGrey10,
            border: `1px solid ${v2 ? theme.colors.uiGrayDarker : theme.colors.darkGrey10}`,
            borderRadius: '3px 3px',
        },

        item: {
            display: 'flex',
        },

        itemLabel: {
            background: v2 ? theme.colors.white : theme.colors.primaryBlue20,
            padding: v2 ? '8px 12px' : 12,
            cursor: 'pointer',
            color: v2 ? theme.colors.primaryBlue100 : theme.colors.primaryBlue100,
            lineHeight: v2 ? undefined : 1.25,

            ...(v2
                ? theme.fonts.buttonNormal
                : {fontFamily: theme.font.name, fontSize: 16, fontWeight: theme.font.regular}),

            textTransform: 'unset',

            '&:hover': {
                background: v2 ? theme.colors.bgHover : theme.colors.primaryBlue30,
            },

            '$input:checked + &': {
                color: v2 ? theme.colors.white : theme.colors.white,
                background: v2 ? theme.colors.primaryBlue100 : theme.colors.primaryBlue100,
                boxShadow: v2 ? `0 0 0 1px ${theme.colors.primaryBlue100}` : undefined,
                cursor: 'initial',
                fontWeight: v2 ? undefined : theme.font.bold,
                '& .app-icon': {
                    fill: theme.colors.white,
                },
            },

            '$input:focus-visible + &': {
                outline: v2
                    ? `3pt solid ${theme.colors.accentDefault}`
                    : `3pt solid ${theme.colors.secondaryOrange100}`,
                zIndex: 1,
            },

            '$input:checked:focus-visible + &': {
                outline: v2 ? `3pt solid ${theme.colors.uiGrayDarker}` : undefined,
            },
        },
        input: {
            ...theme.mixin.offscreen,
        },
    };
});
