//TODO implement fadeOnStart

import React, {useMemo, useEffect, useRef, useCallback, ReactElement} from 'react';

//Hooks
import useOnVisible, {VisibleObserver} from 'hsi/hooks/useOnVisible';

//Utils
import {mergeRefs} from 'hsi/utils/react';

type FadeOnVisibleProps = {
    children: ReactElement;
    context?: React.Context<VisibleObserver>;
    fadeOnStart?: boolean;
    fadeTime?: number;
};

//The component
export default React.forwardRef<HTMLElement, FadeOnVisibleProps>(function FadeInOnVisible(
    {children, context, fadeOnStart = false, fadeTime = 300},
    ref,
) {
    const dataRef = useRef({start: true, hasFaded: false});

    const [isReadyToFade, onFadeRef, childElement] = useOnVisible(ref, undefined, context);

    //Callbacks
    const doFade = useCallback(() => {
        if (dataRef.current.hasFaded || !childElement) {
            return;
        }

        dataRef.current.hasFaded = true;

        //Fade in element
        childElement.animate([{opacity: 0}, {opacity: 1}], {duration: fadeTime, fill: 'forwards'});
    }, [childElement, fadeTime]);

    //Side effects
    useEffect(
        () => {
            const {start, hasFaded} = dataRef.current;

            if (!hasFaded && childElement) {
                childElement.style.opacity = '0'; //start faded out
            }

            if (!hasFaded && isReadyToFade && childElement) {
                if (!start || fadeOnStart) {
                    doFade();
                }
            }

            dataRef.current.start = false;
        },
        // Should revisit if the other deps can be added without issue the next time some changes this
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isReadyToFade, childElement],
    );

    return useMemo(() => {
        const child = React.Children.only(children);

        return React.cloneElement(child, {ref: mergeRefs(onFadeRef, (child as any).ref)});
    }, [children, onFadeRef]);
});
