// TODO: Convert to typescript
// TODO: Add tests
import {stripTrailingSlash} from 'hsi/utils/url';
import {SelectDataSourceTypes} from 'hsi/containers/DataManagement/AddDataSourceDialog/SelectDataSource';
import {TargetedDataDataTypes, AuthenticationDataTypes} from 'hsi/types/dataManagement';

export const isLinkedinSearch = (d) => d?.type === 'linkedin';

const isLinkedinPlatform = (d) => d?.platform === 'linkedin';

const getLinkedinAuthentications = (authentications) =>
    (Array.isArray(authentications) ? authentications : []).filter(isLinkedinPlatform);

export const removeLinkedinAuthentications = (authentications) =>
    (Array.isArray(authentications) ? authentications : []).filter((d) => !isLinkedinPlatform(d));

export const isAuthenticated = (authentications) =>
    getLinkedinAuthentications(authentications).length > 0;

export const getLinkedinAuthenticationNamesByUuid = (authentications) =>
    getLinkedinAuthentications(authentications).reduce((res, d) => {
        if (d.uuid) res[d.uuid] = d.name;
        return res;
    }, {});

const getLinkedinData = (targetedData) =>
    (Array.isArray(targetedData) ? targetedData : []).filter(isLinkedinPlatform);

export const findLinkedinOrg = (targetedData, orgDetails) =>
    getLinkedinData(targetedData).find(
        (d) => d?.data?.linkedinOrganizationUrn === orgDetails.orgUrn,
    );

const parseLinkedinOrgUrl = (orgValue) => stripTrailingSlash(orgValue).split('/').pop().trim();

const parseLinkedinOrgName = (orgValue) =>
    orgValue
        .trim()
        .replace(/[$&+,:;=?@#|'<>.^*()%!-/ ]+/g, '-')
        .replace(/(^-|-$)/g, '');

/*
 * parse linkedin org input, see:
 * https://github.com/brandwatch/frontend-apps/blob/main/bcr/core/public/src/helpers/linkedinApiHelper.js -> getOrgDetails
 * https://learn.microsoft.com/en-us/linkedin/marketing/integrations/community-management/organizations/organization-lookup-api?view=li-lms-2022-08&tabs=http#retrieve-organizations
 */
export const parseLinkedinOrgInput = (orgValue = '') =>
    orgValue.includes('linkedin.com')
        ? parseLinkedinOrgUrl(orgValue)
        : parseLinkedinOrgName(orgValue);

export const getLinkedinOrgIdFromUrn = (orgUrn) =>
    typeof orgUrn === 'string' ? orgUrn.split(':').slice(-1).pop() : undefined;

export const isLinkedinPageType = (pageType) => pageType?.value === 'linkedin';

export const isLinkedinChannelSourceType = (sourceTypeId) =>
    sourceTypeId === TargetedDataDataTypes.LinkedinChannel;

export const isLinkedinAuthenticationSourceType = (sourceTypeId) =>
    sourceTypeId === AuthenticationDataTypes.LinkedinChannelAuthentication;

export const isLinkedinSelectableSource = (sourceId) =>
    sourceId === SelectDataSourceTypes.ownedLinkedinPages;
