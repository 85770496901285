import {useEffect} from 'react';
import useRefCallback from './useRefCallback';

//The hook
export default function useWindowEvent(
    event: keyof WindowEventMap,
    handler?: (...args: any) => void,
) {
    const handlerFunc = useRefCallback((...args: any) => handler?.(...args));
    const hasHandler = !!handler;

    useEffect(
        () => {
            //Add the listener
            hasHandler && window.addEventListener(event, handlerFunc);

            //return tidy up function
            return hasHandler
                ? () => {
                      window.removeEventListener(event, handlerFunc);
                  }
                : undefined;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [event, hasHandler],
    );
}
