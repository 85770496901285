import React, {useEffect, Fragment} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import withLoadQueryState from 'hsi/hocs/withLoadQueryState';
import useQueryContext from 'hsi/hooks/useQueryContext';
import {loadSearch} from 'hsi/actions/savedSearchActions';
import EditSearchBar from 'hsi/components/EditSearchBar';
import QuickSearchBar from 'hsi/components/QuickSearchBar';
import SearchResults from 'hsi/containers/SearchResults';
import InsightsContainer from 'hsi/containers/InsightsContainer';

const EditSavedSearch = () => {
    const {savedSearch} = useQueryContext();
    const dispatch = useDispatch();
    const hasSavedSearch = !isEmpty(savedSearch);
    const {id: searchId} = useParams();

    useEffect(() => {
        !hasSavedSearch && dispatch(loadSearch(searchId));
    }, [dispatch, hasSavedSearch, searchId]);

    return (
        <Fragment>
            {hasSavedSearch && <EditSearchBar savedSearch={savedSearch} />}
            <QuickSearchBar savedSearch={savedSearch} />
            <InsightsContainer isSavedSearch={false}>
                {hasSavedSearch && <SearchResults />}
            </InsightsContainer>
        </Fragment>
    );
};

export default withLoadQueryState(EditSavedSearch);
