//Obsolete - DO NOT USE - use Tooltip component instead
import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippy.js/react';

//Hooks

//Styles
import 'tippy.js/themes/light.css';
import useStyles from './styles';

const SimpleTooltip = ({
    active,
    align,
    boundary,
    children,
    distance,
    morePadding,
    placement,
    theme,
    tooltip,
    tooltipContentClassName,
    visible,
    delay,
    duration,
    ...rest
}) => {
    const simpleTooltip = {
        arrow: true,
        duration: 0,
        delay: 0,
    };

    const {classes, cx} = useStyles();
    const [hideOnScroll, setHideOnScroll] = useState(false);
    const [listenerAdded, setListenerAdded] = useState(false);
    const timeout = useRef(null);

    const onScroll = useCallback(() => {
        if (!hideOnScroll) {
            setHideOnScroll(true);
            timeout.current = setTimeout(() => setHideOnScroll(false), 100);
        }
    }, [hideOnScroll]);

    const onShow = () => {
        if (!listenerAdded) {
            window.addEventListener('scroll', onScroll, true);
            setListenerAdded(true);
        }
    };

    const onHide = () => {
        if (listenerAdded) {
            window.removeEventListener('scroll', onScroll, true);
            setListenerAdded(false);
        }
    };

    useEffect(() => {
        return () => {
            if (listenerAdded) {
                window.removeEventListener('scroll', onScroll, true);
            }
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (hideOnScroll) return children;

    const contentClassName =
        tooltipContentClassName ||
        cx(classes.tooltipContent, morePadding && classes.morePadding, classes[align]);

    return (
        <Tippy
            animateFill={false}
            arrow={simpleTooltip.arrow}
            boundary={boundary}
            className={classes.root}
            content={<div className={contentClassName}>{tooltip}</div>}
            delay={delay || simpleTooltip.delay}
            distance={distance}
            duration={duration || simpleTooltip.duration}
            enabled={active}
            ignoreAttributes
            inertia={false}
            onHidden={onHide}
            onShow={onShow}
            placement={placement}
            sticky={false}
            theme={theme}
            updateDuration={0}
            visible={visible}
            {...rest}
        >
            {children}
        </Tippy>
    );
};

SimpleTooltip.propTypes = {
    active: PropTypes.bool,
    align: PropTypes.oneOf(['center', 'left', 'right']),
    boundary: PropTypes.oneOf(['scrollParent', 'viewport', 'window']),
    children: PropTypes.any.isRequired,
    distance: PropTypes.number,
    morePadding: PropTypes.bool,
    placement: PropTypes.oneOf([
        'bottom',
        'bottom-start',
        'left',
        'left-start',
        'right',
        'right-start',
        'top',
        'top-start',
    ]),
    theme: PropTypes.oneOf(['dark', 'light']),
    tooltip: PropTypes.any.isRequired,
    tooltipContentClassName: PropTypes.string,
    visible: PropTypes.bool,
};

SimpleTooltip.defaultProps = {
    active: true,
    boundary: 'scrollParent',
    distance: 10,
    morePadding: false,
    placement: 'top',
    theme: 'dark',
};

export default SimpleTooltip;

//Identical to SimpleTooltip except does not hide on scroll
export function SimpleTooltipScroll({
    active,
    align,
    boundary,
    children,
    distance,
    morePadding,
    placement,
    theme,
    tooltip,
    tooltipContentClassName,
    visible,
    delay,
    duration,
}) {
    const simpleTooltip = {
        arrow: true,
        duration: 0,
        delay: 0,
    };

    const {classes, cx} = useStyles();

    const contentClassName =
        tooltipContentClassName ||
        cx(classes.tooltipContent, morePadding && classes.morePadding, classes[align]);

    return (
        <Tippy
            animateFill={false}
            arrow={simpleTooltip.arrow}
            boundary={boundary}
            className={classes.root}
            content={<div className={contentClassName}>{tooltip}</div>}
            delay={delay || simpleTooltip.delay}
            distance={distance}
            duration={duration || simpleTooltip.duration}
            enabled={active}
            ignoreAttributes
            inertia={false}
            placement={placement}
            sticky={false}
            theme={theme}
            updateDuration={0}
            visible={visible}
        >
            {children}
        </Tippy>
    );
}

SimpleTooltipScroll.defaultProps = SimpleTooltip.defaultProps;
