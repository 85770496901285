import React, {useMemo, useCallback} from 'react';

//Components
import InclusionSelect from 'hsi/components/Filters/InclusionSelect';
import MultiValueTextInput from 'hsi/components/Filters/MultiValueTextInput';
import ErrorMessage from 'hsi/components/Filters/ErrorMessage';

//Hooks
import useFiltersState from 'hsi/components/Filters/hooks/useFiltersState';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//The component
export default function IncludeOrExcludeFilter({
    autoScrollDrawer = null,
    disabled,
    filterConf: filterConfig,
    onChange,
}) {
    const classes = useStyles();

    const {
        filterName,
        label,

        placeholder,
        maxValues,
        limitLabel,
        autosuggestType,
        addValueKeyCodes,
        displayChipInsideField,
        getLabel,
        getId,
        noExclusion,
    } = filterConfig;

    const {
        useSelector,
        actions: {
            setIncludeOrExcludeMode,
            addValueIncludeOrExclude,
            removeValueIncludeOrExclude,
            setError,

            isValueValid,
        },
    } = useFiltersState();

    const filterState = useSelector((state) => state.filters[filterName]);
    const error = useSelector((state) => state.error[filterName]);
    const pending = useSelector((state) => state.pending[filterName]);

    //Callbacks
    const onIncludeModeChange = useCallback(
        (mode) => setIncludeOrExcludeMode(filterName, mode),
        [filterName, setIncludeOrExcludeMode],
    );

    const onAdd = useCallback(
        (value) => {
            addValueIncludeOrExclude(filterName, value);

            onChange &&
                onChange(
                    filterName,
                    'includeOrExclude',
                    filterState.activeModeIsInclude,
                    (getLabel && getLabel(value)) || value,
                );
        },
        [filterName, addValueIncludeOrExclude, onChange, getLabel, filterState.activeModeIsInclude],
    );

    const onDelete = useCallback(
        (value) => removeValueIncludeOrExclude(filterName, value),
        [filterName, removeValueIncludeOrExclude],
    );

    const onInputChange = useCallback(
        () => error && setError(filterName, null),
        [error, setError, filterName],
    );

    const onBeforeAdd = useCallback(
        (value) => {
            if (!value) {
                return false;
            }

            const okOrErrorCode = isValueValid(filterName, value, true);

            if (okOrErrorCode !== true) {
                setError(filterName, okOrErrorCode);
                return false;
            }

            return true;
        },
        [filterName, isValueValid, setError],
    );

    //Calculated values
    const chips = useMemo(
        () =>
            filterState.values.map((value) => ({
                actionType: filterState.activeModeIsInclude ? 'c-add-2' : 'c-delete-2',
                include: filterState.activeModeIsInclude,
                key: (getId && getId(value)) || value,
                label: (getLabel && getLabel(value)) || value,
                value,
            })),
        [filterState.values, filterState.activeModeIsInclude, getId, getLabel],
    );

    const errorMsg = useMemo(() => (error ? <ErrorMessage error={error} wide /> : null), [error]);

    //Render
    return (
        <div className={classes.root}>
            <InclusionSelect
                include={filterState.activeModeIsInclude}
                onChange={onIncludeModeChange}
                disabled={disabled}
                noExclusion={!!noExclusion}
            />
            <MultiValueTextInput
                key={filterName}
                placeholderText={placeholder ? T(placeholder) : null}
                labelText={label ? T(label) : null}
                autoScrollDrawer={autoScrollDrawer}
                autosuggestType={autosuggestType}
                errorMsg={errorMsg}
                chips={chips}
                displayChipInsideField={displayChipInsideField}
                maxValues={maxValues}
                limitLabel={limitLabel}
                onBeforeAdd={onBeforeAdd}
                onAdd={onAdd}
                onDelete={onDelete}
                onInputChange={onInputChange}
                addValueKeyCodes={addValueKeyCodes}
                disabled={disabled}
                pending={pending}
                error={!!error}
            />
        </div>
    );
}
