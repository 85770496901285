import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);

    return {
        closeButton: {
            position: 'absolute',
            zIndex: 2,
            cursor: 'pointer',
            margin: 0,

            ...(v2
                ? {
                      top: '19px',
                      right: '15px',
                  }
                : {
                      top: '20px',
                      right: '20px',
                  }),
            '& svg': {
                width: '16px',
                height: '16px',

                transform: 'scale(1.7)',
            },
        },

        //Confirm delete dialog
        confirmDelete: {},

        cannotUndo: {
            '&&': {
                fontWeight: v2 ? undefined : theme.font.bold,
                padding: 0,
            },
        },

        warning: {},
        info: {},

        body: {
            '&&': {
                //workaround to deal with CSS specificity
                display: 'flex',
                alignItems: 'flex-start',
                flexFlow: 'row nowrap',
                padding: v2 ? '16px 18px' : 0,
                background: v2 ? theme.colors.uiGrayDefault : undefined,
                color: theme.colors.textDefault,
                maxWidth: 'none',
            },

            ...(v2
                ? theme.fonts.bodyNormal
                : {
                      fontSize: '16px',
                  }),

            '&.warning': {},

            '&.info': {},
        },

        iconContainer: {
            flex: '0 0 24px',
            display: v2 ? 'inline-flex' : 'none',
            alignSelf: 'center',
            marginRight: '18px',
        },

        icon: {
            height: '24px',
            width: '24px',

            '&.warning': {
                fill: theme.colors.warning,
            },
        },

        copy: {
            borderLeft: v2 ? `1px solid ${theme.colors.borders}` : undefined,
            paddingLeft: v2 ? '18px' : undefined,
        },

        information: {
            '&&': {
                //workaround to deal with CSS specificity
                padding: 0,
                color: theme.colors.textDefault,
                maxWidth: 'none',
            },

            ...(v2
                ? theme.fonts.bodyNormal
                : {
                      fontSize: '16px',
                  }),
        },

        pendingHolder: {
            position: 'absolute',
            zIndex: 1,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            background: 'rgba(0, 0, 0, 0.1)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
});
