import makeStyles from '@mui/styles/makeStyles';
import { isV2 } from 'hsi/types/theme';

export default makeStyles((theme) => {
    const v2 = isV2(theme);

    return {
        title: {
            display: 'flex',
            alignItems: 'center',
        },
        brand: {
            ...(v2 ? theme.fonts.headlineStrong : {fontWeight: 900, fontSize: '28px'}),

            lineHeight: '35px',
            color: v2 ? theme.colors.textDefault : theme.colors.darkGrey100,
            padding: '0 16px 2px 0',
        },
        subBrand: {
            ...(v2
                ? theme.fonts.bodyLargeNormal
                : {fontWeight: theme.font.regular, fontSize: '14px'}),
            color: v2 ? theme.colors.textSubtle : theme.colors.darkGrey80,
            lineHeight: '20px',
            borderLeft: `1px solid ${theme.colors.darkGrey60}`,
            padding: '0 16px',
        },
    }
});
