import React, {
    forwardRef,
    ReactNode,
    MouseEventHandler,
    useMemo,
    ReactElement,
    ForwardedRef,
} from 'react';

import cn from 'classnames';
import MuiAvatar from '@mui/material/Avatar';

//Other
import useStyles from './styles';

type MuiAvatarProps = Parameters<typeof MuiAvatar>[0];

interface AvatarProps<TUrl extends string | undefined> extends Omit<MuiAvatarProps, 'onClick'> {
    size?: 'medium' | 'small';
    platformIcon?: ReactNode;
    url?: TUrl;
    onClick?: MouseEventHandler<TUrl extends string ? HTMLAnchorElement : HTMLDivElement>;
    showMiniIcon?: boolean;
}

function Avatar<TUrl extends string | undefined>(
    {
        src,
        size = 'medium',
        platformIcon,
        url,
        onClick,
        showMiniIcon = true,
        ...other
    }: AvatarProps<TUrl>,
    ref: ForwardedRef<HTMLDivElement>,
) {
    const {classes, cx} = useStyles();

    const props: any = useMemo(
        () => ({
            className: cn(
                classes.avatarWrapper,
                classes?.[size!],
                !src && platformIcon && classes.onlyPlatformIcon,
            ),
            onClick,

            ...(url
                ? {
                      href: url,
                      target: '_blank',
                  }
                : undefined),
        }),
        [classes, onClick, platformIcon, size, src, url],
    );

    const Component: TUrl extends string ? 'a' : 'div' = (url ? 'a' : 'div') as any;

    return (
        <Component {...props} data-testid="avatar" ref={ref}>
            <MuiAvatar
                className={cx(classes.avatar, !src && 'no-img')}
                src={src || undefined}
                aria-hidden
                {...other}
            >
                {!src && !!platformIcon && platformIcon}
            </MuiAvatar>
            {!!src && showMiniIcon && !!platformIcon && platformIcon}
        </Component>
    );
}

type TAvatar = {
    (props: AvatarProps<string> & {ref?: ForwardedRef<HTMLAnchorElement>}): ReactElement;
    (props: AvatarProps<undefined> & {ref?: ForwardedRef<HTMLDivElement>}): ReactElement;
};

export default forwardRef(Avatar) as TAvatar;
