import cn from 'classnames';

//Hooks
import useDebouncedValue from 'hsi/hooks/useDebouncedValue';

import styles from './styles';
import {T} from 'hsi/i18n';

type CharacterLimitProps = {
    current?: number;
    max?: number;
    maxLimitMsg?: string;
    hideMaxAlert?: boolean;
} & JSX.IntrinsicElements['span'];

export default function CharacterLimit({
    className,
    current = 0,
    max = 600,
    maxLimitMsg = 'characterLimit.maxLimitMsg',
    hideMaxAlert = false,
    ...rest
}: CharacterLimitProps) {
    const isMax = current > max;
    const classes = styles();

    const [debouncedCurrentCharacters] = useDebouncedValue(current, 1500);
    const remainingCharacters = Math.max(0, max - debouncedCurrentCharacters);
    const showMax = debouncedCurrentCharacters > max;

    return (
        <>
            <span
                aria-hidden
                className={cn(classes.root, className, isMax && classes.isMax)}
                {...rest}
            >
                {T('characterLimit.msg', {current, max})}
            </span>
            {!showMax && (
                <span className="offscreen" aria-live="polite">
                    {T('characterLimit.ariaAnnounce', {remaining: remainingCharacters})}
                </span>
            )}
            {showMax && !hideMaxAlert && (
                <p className="offscreen" aria-live="assertive" role="alert">
                    {T(maxLimitMsg, {tooMany: debouncedCurrentCharacters - max})}
                </p>
            )}
        </>
    );
}
