//TODO this probably needs to live in the config?
//PP actually, I think this whole approach needs a fundamental re-think as
//what we currently have doesn't seem to work, and is not al all accessible

import {createSelector} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import Feedback from 'hsi/components/Feedback';
import NewBadge from 'hsi/components/NewBadge';

import {openFilters} from 'hsi/slices/filtersOpen';
import {openQueryBuilder} from 'hsi/slices/quickSearchPage';
import {openSearchHelp, closeSearchHelp} from 'hsi/slices/quickSearchResultsPage';
import QueryService from 'hsi/services/queryService';

import {operators} from 'hsi/containers/QueryBuilder/components/SearchHelpDrawer/config';
import getConfig from 'hsi/config';
import tns from 'hsi/i18n/tns';

/*
 * how to use the intro manager:
 *
 * add a intro config:
 * {
 *  id: 'intro-xxx' (prepend "intro-" as a good practice)
 *  steps: [{
 *    anchor: `.${introAnchors.xxx.yyy}` (DOM selector from introAnchors)
 *    placement: 'string' (see https://mui.com/api/popper/)
 *    offset: { x, y }
 *    style: {}
 *    title: <> | function | 'i18n'
 *    content: <> | function | 'i18n'
 *    disablePortal: boolean (see https://mui.com/api/popper/)
 *    onInit: optional function
 *    onNext: optional function
 *  }]
 *  condition: optional async function
 *  selectConditionState: optional selector to pass condition's state through useSelector
 * }
 *
 * where each step is a tooltip
 *
 * how to integrate the intro into the UI:
 *    add the <Intro {...introConfig} /> component somewhere in the app (pass the intro config as component parameters)
 *    add a DOM selector for the step anchor to the introAnchors below (prepend "intro-" to the selector as a good practice)
 *    attach the selector to a DOM element somewhere in the app to hook the tooltip step into the DOM (e.g. <div className={introAnchors.xxx.yyy} />)
 *
 * step config:
 *    onInit: is a function called on tooltip mount
 *     use it to perform operations before the tooltip is open
 *     it receives a function initialize as parameter
 *     you have to explicity call initialize() in order to open the tooltip
 *     otherwise it won't show on the screen
 *
 *    onNext: is a function called when users hit the next button
 *     it receives a next callback as parameter
 *     you have to explicitly call next() in order to continue the sequence
 *     otherwise it will be interrupted (pretty much like a node middleware)
 */

const introAnchors = {
    quickSearch: {
        searchBar: 'intro-search-bar',
        guidedMenu: 'intro-guided-menu',
        searchHelp: 'intro-search-help',
        helpCenter: 'intro-help-center',
    },
    quickSearchResults: {
        searchBox: 'intro-quick-search-results-search-box',
        operators: 'intro-quick-search-results-operators',
    },
    dataManagement: {
        explore: 'intro-data-management-explore',
        settingsButton: 'intro-data-management',
    },
    filters: {
        filtersPanel: 'intro-filters-panel',
    },
    savedSearch: {
        createAlert: 'intro-saved-search-create-alert',
        createReport: 'intro-saved-search-create-report',
        addMultipleSearch: 'intro-multiple-search-add-search',
        addLinkedinSearch: 'intro-linkedin-search-add-search',
    },
    timezone: {
        barDatePicker: 'intro-bar-date-picker',
    },
    bulkActions: {
        enableBulkActions: 'intro-enable-bulk-actions',
    },
};

const t = tns('intro');
const baseStyle = {maxWidth: '260px'};

const description = (text) => (
    <>
        {' '}
        {text.split('\n').map((txt, i) => (
            <div key={i} dangerouslySetInnerHTML={{__html: txt}} />
        ))}{' '}
    </>
);

const quickSearchIntro = (appName) => ({
    id: 'intro',
    steps: [
        {
            anchor: `.${introAnchors.quickSearch.searchBar}`,
            placement: 'right-start',
            offset: {x: '4px', y: '20px'},
            style: baseStyle,
            title: t('quickSearch.welcomeTitle', {appName}),
            content: description(t('quickSearch.welcomeDescription', {appName})),
            disablePortal: true,
        },

        {
            anchor: `.${introAnchors.quickSearch.guidedMenu}`,
            placement: 'right-start',
            offset: {x: '4px', y: '20px'},
            style: baseStyle,
            title: t('quickSearch.guidedMenuTitle'),
            content: description(t('quickSearch.guidedMenuDescription')),
            disablePortal: true,
            onInit: ({dispatch, initialize}) => {
                dispatch(openQueryBuilder());
                setTimeout(initialize, 400);
            },
        },

        {
            anchor: `.${introAnchors.quickSearch.searchHelp}`,
            placement: 'right-start',
            offset: {x: '4px', y: '20px'},
            style: baseStyle,
            title: t('quickSearch.searchHelpTitle'),
            content: description(t('quickSearch.searchHelpDescription')),
            disablePortal: false,
        },

        {
            anchor: `.${introAnchors.quickSearch.helpCenter}`,
            placement: 'bottom-end',
            offset: {x: '-20px', y: '2px'},
            style: baseStyle,
            title: t('quickSearch.helpCenterTitle'),
            content: description(t('quickSearch.helpCenterDescription')),
        },
    ],
});

const quickSearchResultsIntro = {
    id: 'intro-quick-search-results',

    condition: ({booleanQuery, isGuided}) => {
        if (isGuided) return false;
        const ops =
            typeof booleanQuery === 'string'
                ? operators //Count how many operators are in use for this query
                      .map((op) => (typeof op.match === 'function' ? op.match(booleanQuery) : []))
                      .filter((d) => !isEmpty(d))
                      .flat()
                : [];
        return ops.length < 10; //If it's less than 10, show the guidance popover
    },

    selectConditionState: createSelector(
        (state) => state?.query?.booleanQuery,
        (state) => state?.query?.isGuided,
        (booleanQuery, isGuided) => ({booleanQuery, isGuided}),
    ),

    steps: [
        {
            anchor: `.${introAnchors.quickSearchResults.searchBox}`,
            placement: 'bottom-start',
            offset: {x: '24px'},
            style: baseStyle,
            title: t('quickSearchResults.needGuidanceTitle'),
            content: description(t('quickSearchResults.needGuidanceDescription')),
        },

        {
            anchor: `.${introAnchors.quickSearchResults.operators}`,
            placement: 'left-start',
            style: baseStyle,
            title: t('quickSearchResults.operatorsTitle'),
            content: description(t('quickSearchResults.operatorsDescription')),
            onInit: ({dispatch, initialize}) => {
                dispatch(openSearchHelp());
                setTimeout(initialize, 1000); //wait for drawer transition
            },
            onNext: ({dispatch, next}) => {
                dispatch(closeSearchHelp());
                next();
            },
        },

        {
            anchor: `.${introAnchors.quickSearchResults.searchBox}`,
            placement: 'bottom-end',
            offset: {x: '-20px'},
            style: baseStyle,
            title: t('quickSearchResults.guidanceTitle'),
            content: description(t('quickSearchResults.guidanceDescription')),
        },
    ],
};

const filtersIntro = {
    id: 'intro-filters',
    steps: [
        {
            anchor: `.${introAnchors.filters.filtersPanel}`,
            placement: 'right-start',
            offset: {y: '32px'},
            style: baseStyle,
            title: t('filters.panelTitle'),
            content: description(t('filters.panelDescription')),
            onInit: ({dispatch, initialize}) => {
                dispatch(openFilters(true));
                setTimeout(initialize, 1000);
            },
        },
    ],
};

const savedSearchAlertsIntro = {
    id: 'intro-saved-search-alerts',

    condition: ({isMultipleSearch}) => !isMultipleSearch,

    selectConditionState: createSelector(
        (state) => state?.query?.context?.isMultipleSearch,
        (isMultipleSearch) => ({isMultipleSearch}),
    ),

    steps: [
        {
            anchor: `.${introAnchors.savedSearch.createAlert}`,
            placement: 'bottom-end',
            offset: {x: '-40px'},
            style: baseStyle,
            title: t('savedSearchAlerts.createAlertTitle'),
            content: description(t('savedSearchAlerts.createAlertDescription')),
        },
    ],
};

const savedSearchReportsIntro = {
    id: 'intro-saved-search-reports',

    condition: ({isMultipleSearch}) => !isMultipleSearch,

    selectConditionState: createSelector(
        (state) => state?.query?.context?.isMultipleSearch,
        (isMultipleSearch) => ({isMultipleSearch}),
    ),

    steps: [
        {
            anchor: `.${introAnchors.savedSearch.createReport}`,
            placement: 'bottom-end',
            offset: {x: '-40px'},
            style: baseStyle,
            title: t('savedSearchReports.createReportTitle'),
            content: description(t('savedSearchReports.createReportDescription')),
        },
    ],
};

const timezoneIntro = {
    id: 'intro-timezone',
    steps: [
        {
            anchor: `.${introAnchors.timezone.barDatePicker}`,
            placement: 'bottom',
            style: {maxWidth: '274px'},

            title: (
                <span style={{alignItems: 'center', display: 'flex', fontSize: '17px'}}>
                    {t('timezone.selectTimezoneTitle')}
                    <NewBadge />
                </span>
            ),

            content: description(t('timezone.selectTimezoneDescription')),
        },
    ],
};

const quickSearchFeedbackIntro = {
    id: 'intro-quick-search-feedback',

    condition: async ({userId}) => {
        const recentGuidedQueries = await QueryService.recentGuidedQueries({userId});
        return Array.isArray(recentGuidedQueries) && recentGuidedQueries.length >= 3;
    },

    selectConditionState: createSelector(
        (state) => state?.user?.account?.id,
        (userId) => ({userId}),
    ),

    steps: [
        {
            anchor: `.${introAnchors.quickSearch.searchBar}`,
            placement: 'bottom-end',
            offset: {x: '-20px'},
            style: baseStyle,
            title: t('quickSearchFeedback.feedbackTitle'),
            content: ({onClose}) => (
                <>
                    {description(t('quickSearchFeedback.feedbackDescription'))}
                    <div style={{height: 8}} />
                    <Feedback
                        onFeedbackSent={onClose}
                        ui={'quick_search_results'}
                        type="intro tooltip"
                    />
                </>
            ),
        },
    ],
};

const linkedinSearchIntro = {
    id: 'intro-linkedin-search',

    steps: [
        {
            anchor: `.${introAnchors.savedSearch.addLinkedinSearch}`,
            offset: {x: '-4px'},
            placement: 'bottom',
            style: {maxWidth: '300px'},

            title: <span style={{fontSize: '17px'}}>{t('linkedinSearch.title')}</span>,

            content: () => description(t('linkedinSearch.desc')),
        },
    ],
};

const multipleSearchIntro = {
    id: 'intro-multiple-search',

    condition: ({isMultipleSearch}) => !isMultipleSearch,

    selectConditionState: createSelector(
        (state) => state?.query?.context?.isMultipleSearch,
        (isMultipleSearch) => ({isMultipleSearch}),
    ),

    steps: [
        {
            anchor: `.${introAnchors.savedSearch.addMultipleSearch}`,
            placement: 'bottom',
            style: {maxWidth: '520px'},

            title: <span style={{fontSize: '17px'}}>{t('multipleSearch.addSearchTitle')}</span>,

            content: () =>
                description(
                    t('multipleSearch.addSearchDescription', {
                        maxMultipleSearches: getConfig()?.multipleSearches.maxMultipleSearches,
                    }),
                ),
        },
    ],
};

const dataManagementIntro = {
    id: 'intro-data-management',
    steps: [
        {
            anchor: `.${introAnchors.dataManagement.settingsButton}`,
            offset: {x: '-22px'},
            placement: 'bottom-end',
            style: {maxWidth: '336px'},

            title: t('dataManagement.title'),

            content: description(t('dataManagement.desc')),
        },
    ],
};

const dataManagementExploreIntro = {
    id: 'intro-data-management-explore',
    steps: [
        {
            anchor: `.${introAnchors.dataManagement.explore}`,
            offset: {x: '-100px'},
            placement: 'top-end',
            style: {maxWidth: '336px'},

            title: t('dataManagementExplore.title'),

            content: description(t('dataManagementExplore.desc')),
        },
    ],
};

const bulkActionsIntro = {
    id: 'intro-bulk-actions',
    condition: ({isMentionsOpen, isSelectMode}) => isMentionsOpen && !isSelectMode,

    selectConditionState: createSelector(
        (state) => !!state?.mentions?.isOpen,
        (state) => !!state?.mentions?.isSelectMode,
        (isMentionsOpen, isSelectMode) => {
            return {isMentionsOpen, isSelectMode};
        },
    ),

    steps: [
        {
            anchor: `.${introAnchors.bulkActions.enableBulkActions}`,
            offset: {x: '20px'},
            placement: 'left',
            style: {maxWidth: '336px'},

            title: t('bulkActions.title'),

            content: description(t('bulkActions.desc')),
        },
    ],
};

const showFunnelToSMMMessage = {
    id: 'intro-funnel-users',
    steps: [
        {
            anchor: `.${introAnchors.quickSearch.searchBar}`,
            offset: {x: 0, y: '-155px'},
            placement: 'bottom',
            style: {maxWidth: '336px'},

            title: t('funnelMessage.title'),

            content: description(t('funnelMessage.content')),

            showArrow: false
        },
    ],
};

export {
    introAnchors,
    dataManagementExploreIntro,
    dataManagementIntro,
    filtersIntro,
    linkedinSearchIntro,
    multipleSearchIntro,
    quickSearchIntro,
    quickSearchFeedbackIntro,
    quickSearchResultsIntro,
    savedSearchAlertsIntro,
    savedSearchReportsIntro,
    timezoneIntro,
    bulkActionsIntro,
    showFunnelToSMMMessage
};
