import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
        pointerEvents: 'none',
    },

    animContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },

    message: {
        position: 'absolute',
        textAlign: 'center',
        top: 'calc(50% + 60px)',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '80vw',
        width: 380,
        fontSize: 15,
    },
}));
