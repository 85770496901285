export const parseSortOptions = (columns, messageParser) =>
    columns.map((col) => ({
        key: col.id,
        values: messageParser.sortTypes,
    }));

//TODO [MentionsTable] filter options with exclusions: xauthor, xlanguage, xlocation
export const parseFilterOptions = (filterOptions) =>
    Object.keys(filterOptions).map((key) => ({
        key,
        values: filterOptions[key],
    }));

export const parseOptions = ({columns, filterOptions, messageParser}) =>
    !messageParser || !columns || !filterOptions
        ? []
        : [
              {
                  action: 'sort',
                  data: parseSortOptions(columns, messageParser),
              },
              {
                  action: 'filter',
                  data: parseFilterOptions(filterOptions),
              },
          ];
