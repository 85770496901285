import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    total: {
        fontSize: '44px',
        color: theme.v2 ? theme.colors.textDefault : theme.colors.primaryBlue100,
        fontWeight: theme.v2 ? theme.typgrphy.display2Strong.fontWeight : 700,
        lineHeight: theme.v2 ? theme.typgrphy.display2Strong.lineHeight : '44px',
        letterSpacing: '0.09px',
        textTransform: 'uppercase',
        marginBottom: '20px',
    },
}));
