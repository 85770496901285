import { PropsWithChildren } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

//Other
import useStyles from './styles';
import { Path } from "history";

export type NavItemThemes = 'small' | 'large';

export type NavItemProps = PropsWithChildren<{
    isActive?: NavLinkProps['isActive'];
    title?: string;
    url: Path;
    onClick?: NavLinkProps['onClick'];
    theme?: NavItemThemes;
}>;

export default function NavItem({isActive, title, url, onClick, theme = "large", children}: NavItemProps) {
    const {classes, cx} = useStyles();

    return <NavLink
        className={cx(classes.navItem, classes[theme])}
        isActive={isActive}
        onClick={onClick}
        to={url}
        data-text={title} // Used when links are bold and take up more width when active
    >
        <span className={classes.linkText}>{children}</span>
    </NavLink>
}
