import React from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import AutocompleteMultiple from 'hsi/components/AutocompleteMultiple';

const InputChipsElement = ({
    defaultValue,
    descriptionId,
    labelId,
    onChange,
    placeholder,
    ...rest
}) => {
    return (
        <AutocompleteMultiple
            {...{
                defaultValue,
                disableClearable: true,
                descriptionId,
                labelId,
                freeSolo: true,
                onChange,
                placeholder: isEmpty(defaultValue) ? placeholder : '',
                ...rest,
            }}
        />
    );
};

InputChipsElement.propTypes = {
    defaultValue: PropTypes.array.isRequired,
    descriptionId: PropTypes.string.isRequired,
    labelId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
};

export default InputChipsElement;
