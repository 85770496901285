import {
    LOG_IN,
    LOG_OUT,
    LOG_IN_LOADING,
    SET_USED_QUERIES,
    SET_USER_LINK_PENDING,
    SET_PACKAGE_USAGE,
    SET_LOGIN_LOADING_MSG,
    OPEN_IMPERSONATION_DIALOG,
    SET_IMPERSONATION_LOADING,
} from 'hsi/constants/actionTypes';
import eventTracking from 'hsi/utils/mixpanel';
import {AnyAction} from 'redux';

const initialState = {
    isLoggedIn: null,
    loginLoading: true,
    loginLoadingMessage: null,
    projects: null,
    packageUsage: {},
    usedQueries: 0,
    openImpersonationDialog: false,
    impersonationLoading: false,
};

const userReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case LOG_IN_LOADING:
            return {
                ...state,
                loginLoading: action.payload,
            };
        case LOG_IN:
            const account = action.payload.account;
            if (action && action.payload && action.payload.account) {
                eventTracking.register({
                    email: account.username,
                    client: account.client.name,
                    clientId: account.client.id,
                    source: (window as any).APP_SOURCE,
                });
                eventTracking.identify(account.id);

                eventTracking.people.set({
                    distinct_id: account.id,
                    email: account.username,
                    first_name: account.firstName,
                    last_name: account.lastName,
                    client: account.client.name,
                    language: account.uiLanguage,
                    created: account.creationDate,
                    role: account.uiRole,
                });

                if (
                    (window as any).APP_SOURCE === 'FALCON' &&
                    typeof (window as any).analytics !== 'undefined'
                ) {
                    (window as any).analytics.identify(
                        account.id,
                        {
                            email: account.username,
                            createdAt: account.creationDate,
                            firstName: account.firstName,
                            lastName: account.lastName,
                            client: account.client.name,
                        },
                        {
                            integrations: {
                                All: false,
                                Appcues: true,
                            },
                        },
                    );
                }
            }
            return {
                ...state,
                ...action.payload,
            };
        case LOG_OUT:
            return {
                isLoggedIn: false,
                loginLoading: false,
                projects: [],
            };

        case SET_LOGIN_LOADING_MSG:
            return {
                ...state,
                loginLoadingMessage: action.payload,
            };

        case SET_USED_QUERIES:
            return {
                ...state,
                usedQueries: action.payload.queryCount,
            };
        case SET_USER_LINK_PENDING:
            return {
                ...state,
                loginLoading: false,
                linkPending: action.payload,
                isLoggedIn: action.payload === true ? false : state.isLoggedIn,
            };
        case SET_PACKAGE_USAGE:
            return {...state, packageUsage: action.payload};
        case OPEN_IMPERSONATION_DIALOG:
            return {...state, openImpersonationDialog: action.payload};
        case SET_IMPERSONATION_LOADING:
            return {...state, impersonationLoading: action.payload};
        default:
            return state;
    }
};

export default userReducer;
