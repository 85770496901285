//This method is needed if you want to track focus and work around React portals
//Do I need to add a fallback to monitoring blur/hover events on window?
//Do I want to make focus tracking work like 'focus-visible'?

import {useMemo, useRef, useState} from 'react';

//First argument is optional set of 'base' event handlers
//This is to allow easy merging of existing handlers to the events
//as well as adding the new handlers to make this work
export default function useFocusOrPointerIn<ElementType extends Element = HTMLElement>({
    onFocus: onFocusBase,
    onBlur: onBlurBase,
    onMouseEnter: onMouseEnterBase,
    onMouseLeave: onMouseLeaveBase,
}: {
    onFocus?: (e: React.FocusEvent<ElementType>) => void;
    onBlur?: (e: React.FocusEvent<ElementType>) => void;
    onMouseEnter?: (e: React.MouseEvent<ElementType>) => void;
    onMouseLeave?: (e: React.MouseEvent<ElementType>) => void;
} = {}) {
    const blurTIDRef = useRef<NodeJS.Timeout | undefined>();
    const [isMouseIn, setIsMouseIn] = useState(false);
    const [isFocusIn, setIsFocusIn] = useState(false);

    const props = useMemo(
        () => ({
            onFocus: (e: React.FocusEvent<ElementType>) => {
                setIsFocusIn(true);
                if (blurTIDRef.current) {
                    clearTimeout(blurTIDRef.current);
                    blurTIDRef.current = undefined;
                }
                onFocusBase?.(e);
            },
            onBlur: (e: React.FocusEvent<ElementType>) => {
                blurTIDRef.current = setTimeout(() => setIsFocusIn(false), 0);
                onBlurBase?.(e);
            },
            onMouseEnter: (e: React.MouseEvent<ElementType>) => {
                setIsMouseIn(true);
                onMouseEnterBase?.(e);
            },
            onMouseLeave: (e: React.MouseEvent<ElementType>) => {
                setIsMouseIn(false);
                onMouseLeaveBase?.(e);
            },
        }),
        [onFocusBase, onBlurBase, onMouseEnterBase, onMouseLeaveBase],
    );

    return useMemo(
        () => [isMouseIn || isFocusIn, props, isMouseIn, isFocusIn] as const,
        [isMouseIn, isFocusIn, props],
    );
}
