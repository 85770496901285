import {makeStyles} from '@mui/styles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles((theme: Theme) => {
    const {colors, fonts, font} = theme;
    const v2 = isV2(theme);

    return {
        selectWrapper: {
            position: 'relative',
            display: 'block',
        },
        select: {
            width: '100%',
            appearance: 'none',
            outline: 'none',
            border: v2 ? `1px solid ${colors.borders}` : 0,
            borderRadius: '2px',
            background: v2 ? colors.white :  colors.primaryBlue20,
            padding: v2 ? '11px 35px 12px 12px' : '8px 48px 8px 28px',
            margin: 0,
            lineHeight: v2 ? 'normal' : '28px',
            color: v2 ? colors.textDefault : colors.primaryBlue100,
            font: 'inherit',
            cursor: 'pointer',
            ...v2 ? fonts.bodyNormal : { fontSize: '16px', fontWeight: font.bold},

            '&:focus-visible': {
                outline: `3pt solid ${theme.colors.secondaryOrange100}`,
            },
        },
        icon: {
            position: 'absolute',
            pointerEvents: 'none',
            top: v2 ? 13 : 15,
            right: v2 ? 8 : 16,
            width: v2 ? 12 : undefined,
            height: v2 ? 12 : undefined,
        },
    };
});
