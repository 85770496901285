import React, {useMemo, useCallback, useState, useEffect} from 'react';

//Components
import TextfieldWithAlert from 'hsi/components/Filters/TextfieldWithAlert';
import ErrorMessage from 'hsi/components/Filters/ErrorMessage';
import Label from 'hsi/components/Filters/Label';

//Hooks
import useFiltersState from 'hsi/components/Filters/hooks/useFiltersState';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//The component
export default function RangeFilter({
    compact,
    autoScrollDrawer = null,
    isSavedSearch,
    onChange,
    filterConf: {
        filterName,
        disabled,
        label,
        minValue = Number.MIN_VALUE,
        maxValue = Number.MAX_VALUE,
        step,
        decimalPlaces = 0,
        labelMin = 'min',
        placeholderMin = 'min',
        labelMax = 'max',
        placeholderMax = 'max',
        placeholderTo = 'to',
    },
}) {
    const {classes, cx} = useStyles();

    const {
        useSelector,
        actions: {setRangeMin, setRangeMax, setError},
    } = useFiltersState();

    const filterState = useSelector((state) => state.filters[filterName]);
    const error = useSelector((state) => state.error[filterName]);

    //Init state
    const [min, setMin] = useState(filterState.min ?? '');
    const [max, setMax] = useState(filterState.min ?? '');

    //Callbacks
    const updateMin = useCallback(() => {
        setRangeMin(filterName, min);

        onChange && onChange(filterName, 'range', 'min', min);
    }, [setRangeMin, filterName, min, onChange]);

    const updateMax = useCallback(() => {
        setRangeMax(filterName, max);

        onChange && onChange(filterName, 'range', 'max', max);
    }, [setRangeMax, filterName, max, onChange]);

    const clearErrors = useCallback(
        (type) => setError(filterName, {...error, [type]: null}),
        [error, filterName, setError],
    );

    //Calculated values
    const inputProps = useMemo(
        () => ({
            min: minValue === Number.MIN_VALUE ? undefined : minValue,
            max: maxValue === Number.MAX_VALUE ? undefined : maxValue,
            step,
        }),
        [minValue, maxValue, step],
    );

    const minTooltip = useMemo(
        () => (error?.min ? <ErrorMessage error={error?.min} errorProps={inputProps} /> : null),
        [error?.min, inputProps],
    );

    const maxTooltip = useMemo(
        () => (error?.max ? <ErrorMessage error={error?.max} errorProps={inputProps} /> : null),
        [error?.max, inputProps],
    );

    //Side effects
    useEffect(() => setMin(filterState.min), [filterState.min]);

    useEffect(() => setMax(filterState.max), [filterState.max]);

    //Render
    return (
        <fieldset className={cx(classes.wrapper, compact && classes.compact)}>
            {label && <Label component="legend">{T(label)}</Label>}
            <div className={classes.rangeFieldInputs}>
                {labelMin && (
                    <label htmlFor={`${filterName}-min`} className={classes.fieldLabel}>
                        {T(labelMin)}
                    </label>
                )}
                <TextfieldWithAlert
                    className={classes.rangeMinInput}
                    placeholder={placeholderMin ? T(placeholderMin) : null}
                    value={min ?? ''}
                    onChange={(ev) => {
                        clearErrors('min');
                        setMin(ev.target.value);
                    }}
                    onBlur={updateMin}
                    onKeyDown={(evt) => {
                        clearErrors('min');
                        evt.keyCode === 13 && updateMin();
                    }}
                    alert={minTooltip}
                    tooltipPlacement="bottom"
                    id={`${filterName}-min`}
                    type="number"
                    inputProps={inputProps}
                />
                <div className={classes.rangeToLabel}>{T(placeholderTo)}</div>

                {labelMax && (
                    <label htmlFor={`${filterName}-max`} className={classes.fieldLabel}>
                        {T(labelMax)}
                    </label>
                )}
                <TextfieldWithAlert
                    className={classes.rangeMinInput}
                    placeholder={placeholderMax ? T(placeholderMax) : null}
                    value={max ?? ''}
                    onChange={(ev) => {
                        clearErrors('max');
                        setMax(ev.target.value);
                    }}
                    onBlur={updateMax}
                    onKeyDown={(evt) => {
                        clearErrors('max');
                        evt.keyCode === 13 && updateMax();
                    }}
                    alert={maxTooltip}
                    tooltipPlacement="bottom"
                    id={`${filterName}-max`}
                    type="number"
                    inputProps={inputProps}
                />
            </div>
        </fieldset>
    );
}
