import React, {forwardRef} from 'react';
import cn from 'classnames';

//Components
import IconRouter from 'hsi/components/IconRouter';

//Other
import {Platform, platformIcons} from 'hsi/utils/platform';

type IconRouterArgs = Parameters<typeof IconRouter>[0];

interface PlatformIconArgs extends Omit<IconRouterArgs, 'ref'> {
    fallback?: Platform | 'default';
    is48?: boolean;
}

//The component
export default forwardRef<SVGSVGElement, PlatformIconArgs>(function PlatformIcon(
    {name, fallback, is48 = true, className, ...rest},
    ref,
) {
    const icon =
        platformIcons[name as keyof typeof platformIcons] ||
        platformIcons[fallback as keyof typeof platformIcons] ||
        'custom';

    return (
        <IconRouter
            name={icon + (is48 ? '48' : '')}
            className={cn('platform-icon', className)}
            {...rest}
            ref={ref}
        />
    );
});
