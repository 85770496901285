import {forwardRef, useCallback, useMemo} from 'react';
import isEmpty from 'lodash/isEmpty';

import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle, { formatCardTitleAsString } from 'hsi/components/Card/CardTitle';
import InfoPopupContent from 'hsi/components/InfoPopupContent';
import ManagedGridTable from 'hsi/components/table/ManagedGridTable';
import PaginationButtons from 'hsi/components/PaginationButtons';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useCardData from 'hsi/hooks/useCardData';
import useDrillInMentions from 'hsi/hooks/useDrillInMentions';
import useGetTableColumns from './useGetTableColumns';
import useGetDrillInData from './useGetDrillInData';

//Hooks
import useIsSavedSearch from 'hsi/hooks/useIsSavedSearch';
import useCardTableTrackingAndPersistence from '../hooks/useCardTableTrackingAndPersistence';
import usePaginateRows from 'hsi/components/table/ManagedTable/usePaginateRows';
import useFormatRows from 'hsi/components/table/ManagedTable/useFormatRows';
import { useCardTypeLimit } from 'hsi/utils/cards/getCardTypeLimit';
import useAddPositionToRows from '../hooks/useAddPositionToRows';

//Types
import {CardTypeProps} from 'hsi/types/cards';
import { TopHashtagTableRowItem } from './types';
import { TableSortDir } from 'hsi/components/table/types';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

const type = 'tophashtags';
const defaultSortColumn = 'value'; //i.e. total;
const DEFAULT_DATA: TopHashtagTableRowItem[] = [];

//The component
const TopHashtag = forwardRef<HTMLDivElement, CardTypeProps>(({title, ...props}, ref) => {
    const classes = useStyles();

    const {
        links: {dashboardInfoTopHashtagsCTA: popupCTA},
    } = useConfig();

    const drillInMentions = useDrillInMentions();
    const getDrillInData = useGetDrillInData();
    const isSavedSearch = useIsSavedSearch();

    const {perPage} = useCardTypeLimit(type);
    
    //Redux
    const {data, loading, loaded, error, loadData, isCardSelected} = useCardData({
        type,
        dataSelector: (state) => state.chart[type],
    });

    const hasData = !isEmpty(data);
    const rows = hasData ? (data as TopHashtagTableRowItem[]): DEFAULT_DATA;
    const columns = useGetTableColumns(isSavedSearch, classes);

    //Calculated values
    const popup = useMemo(
        () => (
            <InfoPopupContent
                copy={T(
                    `cards.${type}.info.${isSavedSearch ? 'savedSearchCopy' : 'quickSearchCopy'}`,
                )}
                ctaLabel={T('cards.infoCTALabel')}
                ctaUrl={popupCTA}
                title={T(`cards.${type}.info.title`)}
            />
        ),
        [popupCTA, isSavedSearch],
    );

    const onSortingChanged = useCallback((newColumn: keyof TopHashtagTableRowItem | undefined, newDirection: TableSortDir | undefined, oldColumn: keyof TopHashtagTableRowItem | null | undefined, oldDirection: TableSortDir | null | undefined) => {
        if(oldColumn !== null && newColumn !== oldColumn) {
            loadData();
        }
    }, [loadData]);

    const {page, pages, setPage, ...sorting} = useCardTableTrackingAndPersistence(type, columns, {defaultSortColumn, totalRows: data?.length ?? 0, rowsPerPage: perPage, onSortingChanged});

    const positionedRows = useAddPositionToRows(rows);

    const sortedRows = useMemo(() => {
        return sorting.sortDirection === 'asc' 
            ? [...positionedRows].reverse()
            : positionedRows;
    }, [positionedRows, sorting.sortDirection]);

    // Apply pagination
    const paginatedRows = usePaginateRows(sortedRows, page, perPage);

    // Apply formatting
    const formattedRows = useFormatRows(paginatedRows, columns);

    return (
        <CardLoadState
            title={
                <CardTitle title={title} type={type} tooltipComponent={popup} hasData={hasData} />
            }
            error={error}
            hasData={hasData}
            loading={loading}
            loaded={loaded}
            loadData={loadData}
            selected={isCardSelected}
            ref={ref}
            data-testid={type}
            type={type}
            {...props}
        >
            <ManagedGridTable
                columns={columns}
                rowData={formattedRows}
                caption={T('managedTable.caption', {name: formatCardTitleAsString(title)})}

                rowSelectable
                onRowClick={(index: number) => {
                    drillInMentions(getDrillInData(paginatedRows[index]));
                }}

                {...sorting}
            />
            <PaginationButtons page={page} pages={pages} setPage={setPage} />
        </CardLoadState>
    );
});

export default forwardRef<HTMLDivElement, CardTypeProps>(function TopHashTags(props, ref) {
    return <TopHashtag {...props} ref={ref} />;
});
