import React, {useCallback, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Delaunay} from 'd3-delaunay';
import {calculateChartCoordinate, getOffset} from 'recharts/lib/util/DOMUtils';
import isFunction from 'lodash/isFunction';
import throttle from 'lodash/throttle';

import ChartTooltip from 'hsi/components/ChartTooltip';
import ChartTooltipContent from 'hsi/components/ChartTooltipContent';
import ActiveLineDot from '../ActiveLineDot';

import useDates from 'hsi/hooks/useDates';

const RechartsVoronoi = ({
    formattedGraphicalItems,
    offset,
    onActivePointClick,
    onlyX,
    timezone,
    titleDateFormat,
    tooltipUnit,
}) => {
    const {formatTo} = useDates();

    const [activePoint, setActivePoint] = useState(null);

    const lastPointRef = useRef(null);
    const hoverRef = useRef(false);
    const ref = useRef();

    const findItemWithVoronoi = useCallback(
        (mouseX, mouseY) => {
            // can't find first point sometimes, add.
            const dataPoints = [{voroX: -100, voroY: -100}];

            if (!hoverRef.current) {
                lastPointRef.current = null;
                return;
            }

            (formattedGraphicalItems || []).forEach((item) => {
                if (item.item.type.displayName === 'Line') {
                    const {points} = item.props;
                    const {stroke, dataKey, name} = item.item.props;
                    points.forEach((point, index) => {
                        dataPoints.push({
                            ...point,
                            voroX: point.x - offset.left,
                            voroY: point.y,
                            color: stroke,
                            label: name || dataKey,
                            dataKey,
                            index,
                        });
                    });
                }
            });

            if (hoverRef.current && mouseX && mouseY) {
                const yAccesor = onlyX ? () => 1 : (point) => point.voroY;
                const delaunay = Delaunay.from(dataPoints, (point) => point.voroX, yAccesor);
                const index = delaunay.find(mouseX, mouseY);

                if (index) {
                    const pointFound = dataPoints[index];
                    if (
                        !lastPointRef.current ||
                        lastPointRef.current.x !== pointFound.x ||
                        lastPointRef.current.y !== pointFound.y
                    ) {
                        lastPointRef.current = pointFound;
                        setActivePoint(pointFound);
                    }
                }
            }
        },
        [formattedGraphicalItems, offset?.left, onlyX],
    );

    const _handleMouseMove = throttle((e) => {
        if (!ref.current) return;
        const offset = getOffset(ref.current);
        const coords = calculateChartCoordinate(e, offset);
        findItemWithVoronoi(coords.chartX, coords.chartY);
    }, 20);

    const handleMouseMove = useCallback(
        (event) => {
            hoverRef.current = true;
            if (isFunction(event?.persist)) {
                event.persist();
            }
            _handleMouseMove(event);
        },
        [_handleMouseMove],
    );

    const handleMouseEnter = useCallback(() => {
        hoverRef.current = true;
    }, []);

    const handleMouseLeave = useCallback(() => {
        hoverRef.current = false;
        lastPointRef.current && setActivePoint(null);
        lastPointRef.current = null;
    }, []);

    const formatTooltipDate = useCallback(
        (date) => formatTo(date, titleDateFormat),
        [formatTo, titleDateFormat],
    );

    const rectH = offset.height + offset.top + 30;

    return (
        <>
            <rect
                data-testid="voroniTooltipArea"
                fill="transparent"
                height={rectH}
                onClick={() => onActivePointClick(activePoint)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove}
                ref={(r) => {
                    ref.current = r;
                }}
                width={offset.width}
                x={offset.left}
                y={0}
            />
            {!!activePoint?.value && (
                <>
                    <ChartTooltip x={activePoint.x} y={activePoint.y} onHide={handleMouseLeave}>
                        <ChartTooltipContent
                            title={formatTooltipDate(activePoint.payload.date)}
                            valueItems={[
                                {
                                    color: activePoint.color,
                                    amount: isInt(activePoint.value)
                                        ? parseFloat(activePoint.value).toFixed(0)
                                        : parseFloat(activePoint.value).toFixed(2),
                                    unit: tooltipUnit,
                                },
                            ]}
                        />
                    </ChartTooltip>
                    <ActiveLineDot
                        cx={activePoint.x}
                        cy={activePoint.y}
                        stroke={activePoint.color}
                    />
                </>
            )}
        </>
    );
};

RechartsVoronoi.propTypes = {
    formattedGraphicalItems: PropTypes.array, // passed in by Recharts
    mouseX: PropTypes.number,
    mouseY: PropTypes.number,
    onActivePointClick: PropTypes.func,
    onlyX: PropTypes.bool,
    timezone: PropTypes.string,
    titleDateFormat: PropTypes.string,
    tooltipUnit: PropTypes.string,
};

RechartsVoronoi.defaultProps = {
    onlyX: false,
};

export default RechartsVoronoi;

const isInt = (n) => {
    return parseInt(n) === n;
};
