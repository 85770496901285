//This is frankly a mess, and needs a fundamental re-think. Should be tackled as
//part of a future refactor of the guided query section.

import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';

const toArray = (arr) => (Array.isArray(arr) ? arr : [arr]).filter((d) => !!d);

const getValues = ({formula, sectionName, componentName}) =>
    toArray(formula?.guidedForm?.[sectionName]?.[componentName]);

//returns {booleanQuery: string, guidedForm: {include?: {}, exclude?: {}, type: string}}
const updateFormula = ({config, prevFormula, sectionName, componentName, values}) => {
    const nextFormula = {
        ...prevFormula,
        guidedForm: {
            ...(prevFormula.guidedForm || {}),
            [sectionName]: {
                ...(prevFormula?.guidedForm?.[sectionName] || {}),
                [componentName]: values,
            },
        },
    };

    return parseFormula({
        sections: config.sections,
        formula: nextFormula,
    });
};

const flatComponents = (sections) =>
    sections
        .map((d) => d.components)
        .flat(Infinity)
        .map((d) => d.components || d)
        .flat(Infinity);

const addSectionNames = (components, sections) =>
    components.map((compConfig) => {
        const sectionConfig = sections.find((sectionConfig) =>
            sectionConfig.components.find((_compConfig) => _compConfig.name === compConfig.name),
        );
        const {name: sectionName} = sectionConfig;
        return {...compConfig, sectionName};
    });

//returns {booleanQuery: string, guidedForm: {include?: {}, exclude?: {}, type: string}}
//I do not understand what this is doing or how it works.
const parseFormula = ({sections, formula}) => {
    const allComponents = addSectionNames(flatComponents(sections), sections);

    const sectionsBools = joinBools(
        toArray(
            sections.map((sectionConfig, i) => {
                const {name: sectionName, operator: sectionOp} = sectionConfig;

                const componentsBools = joinBools(
                    toArray(
                        allComponents.map((compConfig) => {
                            const {
                                name: componentName,
                                parseFormula,
                                operator: componentOp,
                            } = compConfig;

                            const values = getValues({
                                formula,
                                sectionName,
                                componentName,
                            });

                            const bool =
                                !isEmpty(values) &&
                                typeof parseFormula == 'function' &&
                                parseFormula({values, formula});

                            return (
                                !isEmpty(bool) && {
                                    bool,
                                    operator: componentOp,
                                }
                            );
                        }),
                    ),
                );

                return (
                    !isEmpty(componentsBools) && {
                        bool: componentsBools.join(' '),
                        operator: sectionOp,
                    }
                );
            }),
        ),
    );

    const booleanQuery = allComponents
        .filter((compConfig) => compConfig.reduceFormula)
        .reduce((booleanQuery, compConfig) => {
            const {reduceFormula, name: componentName, sectionName} = compConfig;
            const values = getValues({
                formula,
                sectionName,
                componentName,
            });
            return !isEmpty(values) ? reduceFormula({values, formula, booleanQuery}) : booleanQuery;
        }, sectionsBools.join(' '));

    return {
        ...formula,
        booleanQuery,
    };
};

const joinBools = (bools) => bools.map((d, i) => joinBool(i, d.bool, d.operator));

const joinBool = (i, bool, operator = 'OR') => {
    const preOps = {
        OR: () => i > 0 && `OR ${bool}`,
        AND: () => i > 0 && `AND ${opBrackets(bool)}`,
        NOT: () => `NOT ${opBrackets(bool)}`,
    };
    return (preOps[operator] || (() => null))() || bool;
};

const isMultiWord = (d) => String(d).split(' ').length > 1;
const rmSpaces = (d) => String(d).replace(/ /g, '');
const rmAt = (d) => String(d).replace(/@/g, '');
const quotes = (d) => `"${String(d).replace(/"/g, '')}"`;
const hashtag = (d) => `#${String(d).replace(/#/g, '')}`;
const standardAuthor = (d) => `@${d}`;
const facebookAuthor = (d) => `channelId:${String(d.fbPageId)}`;
const linkedinAuthor = (d) => `channelId:${String(d.orgId)}`;

const brackets = (values, bool) =>
    Array.isArray(values) && values.length > 1 ? `(${bool})` : bool;

//simple brackets wrapper
const opBrackets = (bool) => (hasOperator(bool) ? `(${bool})` : bool);
const hasOperator = (bool) => bool.split(/OR|NOT/).length > 1;

const OR = (values, parse) => joinOR(parseOR(values, parse));

const parseOR = (values, parse = (d) => d) => [...new Set(toArray(values).map(parse))];

const joinOR = (orValues) => orValues.join(' OR ');

const fieldOR = (values, parse) => {
    const orValues = parseOR(values, parse);
    return brackets(orValues, joinOR(orValues));
};

const parseBrand = ({values, relatedTerms}) => {
    const brandValues = values
        .map(parseMultiWordValues)
        .flat()
        .map((d) => parseRelatedTerms(d, relatedTerms));
    return OR([OR(brandValues), `title:${fieldOR(brandValues)}`]);
};

const parseBrandContext = ({values, booleanQuery}) => {
    const brandBool = parseBrand({values});
    return isEmpty(booleanQuery)
        ? brandBool
        : `${opBrackets(booleanQuery)} AND ${opBrackets(brandBool)}`;
};

const parseLinks = ({values}) =>
    OR([`url:${fieldOR(values, quotes)}`, `links:${fieldOR(values, quotes)}`]);

const getAuthorParserKey = (d) => (['twitter'].includes(d.type) ? 'standard' : d.type);

const authorsParsers = {
    standard: (values = []) => {
        if (values.length === 0) return [];
        const usernames = values.map((d) => rmAt(d?.username || d));

        const authors = `author:${fieldOR(usernames)}`;
        const engagingWiths = `engagingWith:${fieldOR(usernames)}`;
        const atAuthors = OR(usernames, standardAuthor);

        return OR([authors, engagingWiths, atAuthors]);
    },
    facebook: (values = []) => OR(values, facebookAuthor),
    instagram: (values = []) => {
        if (values.length === 0) return [];

        const valueToChannelId = (value) => String(value.igbid);

        const ownedValues = values.filter((value) => !!value?.owned);
        const ownedUsernames = ownedValues.map((value) => rmAt(value?.username || value));
        const ownedIds = ownedValues.map(valueToChannelId);
        const ownedChannelIds = `channelId:${fieldOR(ownedIds)}`;
        const ownedAuthors = `author:${fieldOR(ownedUsernames)}`;
        const ownedEngagingWiths = `engagingWith:${fieldOR(ownedUsernames)}`;

        const ownedInstagramAuthors = ownedValues.length
            ? `((${ownedAuthors} OR ${ownedEngagingWiths}) AND ${ownedChannelIds})`
            : undefined;

        const nonOwnedValues = values.filter((value) => !value?.owned);
        const nonOwnedIds = nonOwnedValues.map(valueToChannelId);
        const nonOwnedChannelIds = nonOwnedValues.length
            ? `channelId:${fieldOR(nonOwnedIds)}`
            : undefined;

        /** Example returns
         * With only non owned returns channelId: meId
         * With only owned returns author: me AND channelId: meId
         * With both returns (author:(me1 OR me2) AND channelId:(me1 OR me2)
         */
        return OR(toArray([ownedInstagramAuthors, nonOwnedChannelIds]));
    },
    linkedin: (values = []) => OR(values, linkedinAuthor),
};

/** Returns "author" with one author or "(author1) OR (author2) OR (author3)" for multiple authors
 * This makes sure that we add author seperately but still allow it all to be wrapped with brackets
 * when it's excluded with a NOT
 */
const parseAuthors = ({values}) => {
    const valuesByParser = groupBy(values, getAuthorParserKey);
    const authors = Object.keys(valuesByParser)
        .map((key) => {
            const parser = authorsParsers[key];
            return parser ? parser(valuesByParser[key]) : [];
        })
        .filter((value) => value.length);

    return OR(authors);
};

const parseHashtags = ({values}) => OR(values, hashtag);

/**
 * Takes a string array of values and coverts them to hashtags and adds in the site operator to limit it to
 * Instagram hashtags
 * @param {values} string[]
 * @returns string
 */
const parseInstagramHashtags = ({values}) =>
    `(site:instagram.com AND ${opBrackets(parseHashtags({values}))})`;

const parseNames = ({values}) => OR([OR(values, quotes), `title:${fieldOR(values, quotes)}`]);

const parseMultiWord = (d) => {
    const multiWordValues = parseMultiWordValues(d);
    return brackets(multiWordValues, OR(multiWordValues));
};

const parseMultiWordValues = (d) => (isMultiWord(d) ? [quotes(d)].concat(rmSpaces(d)) : d);

const parseRelatedTerms = (d, relatedTerms) => {
    if (isEmpty(relatedTerms)) return d;
    const relTermsValues = relatedTerms.map(parseMultiWordValues).flat();
    const relTermsBool = OR(relTermsValues);
    return `(${d} AND ${opBrackets(relTermsBool)})`;
};

const parseTerms = ({values}) => {
    const termsValues = values.map(parseMultiWordValues).flat();
    return OR([OR(termsValues), `title:${fieldOR(termsValues)}`]);
};

export {
    authorsParsers,
    getValues,
    updateFormula,
    parseFormula,
    OR,
    parseBrand,
    parseBrandContext,
    parseLinks,
    parseAuthors,
    parseHashtags,
    parseInstagramHashtags,
    parseMultiWord,
    parseRelatedTerms,
    parseTerms,
    parseNames,
};
