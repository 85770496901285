import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => ({
    loadingHolder: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
    },

    message: {
        maxWidth: '80vw',
        fontSize: 15,
    },

    content: {
        opacity: 0.5,
    },
}));
