//TODO We should be doing this using react-router logic.
//That would allow us to get rid of this whole concept, and
//all the 'thunks' it relies on

import {ComponentType, useMemo, useState} from 'react';
import {useParams, useLocation} from 'react-router-dom';

import useEffectDeepCompare from 'hsi/hooks/useEffectDeepCompare';
import useURLSearchParams from 'hsi/hooks/useURLSearchParams';
import {loadQueryStateFromURL} from 'hsi/slices/query';
import {useActiveQueryPageTypes} from 'hsi/hooks/usePageTypes';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import getQueryTypeFromUrl from 'hsi/utils/app/getQueryTypeFromUrl';
import useFlags from 'hsi/hooks/useFlags';

const withLoadQueryState =
    <T extends {} = {}>(Component: ComponentType<T>) =>
    (props: T) => {
        const [done, setDone] = useState(false);
        const dispatch = useAppDispatch();
        const urlParams = useParams();
        const location = useLocation();
        const urlSearchParams = useURLSearchParams() as Record<string, string>;
        const queryType = getQueryTypeFromUrl(location, urlParams);
        const pageTypes = useActiveQueryPageTypes();
        const flags = useFlags();

        const isSearchesLoaded = useAppSelector(
            (state) => (state.search as any).searchesEverLoaded as boolean,
        );

        const isRecentQueriesLoaded = useAppSelector((state) => state.query.recentQueriesLoaded);

        useEffectDeepCompare(() => {
            (async () => {
                if (queryType) {
                    if ((queryType === 'quick' && isRecentQueriesLoaded) || isSearchesLoaded) {
                        await dispatch(
                            loadQueryStateFromURL({
                                location,
                                urlParams,
                                urlSearchParams,
                                pageTypes,
                                flags,
                            }),
                        );
                    }
                }
                setDone(true);
            })();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            dispatch,
            location,
            urlParams,
            urlSearchParams,
            isSearchesLoaded,
            isRecentQueriesLoaded,
            queryType,
        ]);

        return useMemo(() => {
            return done ? <Component {...props} /> : null;
        }, [done, props]);
    };

export default withLoadQueryState;
