import {useCallback} from 'react';
import cn from 'classnames';

import ButtonBase from '@mui/material/ButtonBase';

import IconRouter from 'hsi/components/IconRouter';
import PulseLoader from 'hsi/components/PulseLoader';

import useFacebookLogin from 'hsi/hooks/useFacebookLogin';
import useStyles from './styles';

import {T} from 'hsi/i18n';

type FacebookLoginButtonProps = {
    onLogin?: () => void;
};

const FacebookLoginButton = ({onLogin}: FacebookLoginButtonProps) => {
    const classes = useStyles();
    const {loading, login} = useFacebookLogin();

    const handleLogin = useCallback(() => {
        login(onLogin);
    }, [login, onLogin]);

    return (
        <div className={classes.wrapper}>
            <ButtonBase
                className={cn(classes.fbButton, loading && classes.loading)}
                disabled={loading}
                onClick={handleLogin}
            >
                {loading ? (
                    <PulseLoader />
                ) : (
                    <>
                        <IconRouter className={classes.facebookIcon} name="logo-facebook" />
                        <span>{T('authTokens.tokensPage.loginBlock.loginButton')}</span>
                    </>
                )}
            </ButtonBase>
        </div>
    );
};

export default FacebookLoginButton;
