import React, {
    useMemo,
    useCallback,
    MouseEvent,
    MouseEventHandler,
    forwardRef,
    KeyboardEventHandler,
    PropsWithRef,
} from 'react';
import cn from 'classnames';

//Components
import Content from './Content';
import IconRouter from 'hsi/components/IconRouter';
import Metrics from './Metrics';
import Media from './Media';
import Tags from './Tags';
import WithMenuAndTooltip from './WithMenuAndTooltip';

//Utils
import normalize from 'hsi/components/Mention/normalize';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';
import 'hsi/../node_modules/video-react/dist/video-react.css';
import {MentionDataType, MentionType, isSavedSearchMention} from 'hsi/types/mentions';
import MentionHeader from './Header';
import useUniqueId from 'hsi/hooks/useUniqueId';
import {EditMentionType} from 'hsi/slices/mentions';

type MentionPropsBase = {
    data: MentionType;
    editMentionStatus?: EditMentionType;
    fulltext?: boolean;
    extraClasses?: Record<string, string>;
    onAuthorClick?: (event: MouseEvent<HTMLElement>, menionData: MentionDataType) => void;
    hideMetrics?: boolean; //used by render native mention
    hideOriginalLink?: boolean;
    setAsSelectedMention?: () => void;
    onViewOriginalClick?: () => void;
    editable?: boolean;
    wideLayout?: boolean;
    selectable?: boolean;
    isSelected?: boolean;
    setIsSelected?: (isSelected: boolean) => void;
    selectionDisabled?: boolean;
} & JSX.IntrinsicElements['article'];

export type MentionProps = PropsWithRef<MentionPropsBase>;

const isWideLayoutA = true;

//The component
export default forwardRef<HTMLElement, MentionPropsBase>(function Mention(
    {
        data: mention,
        editMentionStatus, // = {loading: true, error: false, emotion: false, sentiment: false, location: false, tags: true},
        fulltext,
        extraClasses,
        className,
        onAuthorClick,
        hideMetrics = false,
        hideOriginalLink = false,
        setAsSelectedMention,
        onViewOriginalClick: _onViewOriginalClick,
        editable = false,
        selectable = false,
        isSelected = false,
        setIsSelected,
        selectionDisabled = false,
        wideLayout = false,

        onClick,
        id: baseId,

        ...rest
    },
    ref,
) {
    const id = useUniqueId(baseId, 'mention_');

    const classes = {...useStyles(), ...extraClasses};

    //Calculated values
    const mentionData = useMemo(() => normalize(mention), [mention]);
    const layout = wideLayout ? (isWideLayoutA ? 'wideA' : 'wideB') : 'standard';

    const hasMedia = !!mentionData.image || !!mentionData.video;

    const ariaLabel = useMemo((): string => {
        return selectable
            ? T(
                  `mentionComponent.mentionSelectableAriaLbl.${
                      (isSelected ? 'selected' : 'unselected') +
                      (selectionDisabled ? 'Disabled' : '')
                  }`,
                  {
                      name: mentionData.title || mentionData.snippet,
                      author: mentionData.username || mentionData.fullname,
                      platform: mentionData.pageTypeName,
                  },
              )
            : T('mentionComponent.mentionAriaLbl', {
                  name: mentionData.title || mentionData.snippet,
                  author: mentionData.username || mentionData.fullname,
                  platform: mentionData.pageTypeName,
              });
    }, [
        isSelected,
        mentionData.fullname,
        mentionData.pageTypeName,
        mentionData.snippet,
        mentionData.title,
        mentionData.username,
        selectable,
        selectionDisabled,
    ]);

    //Callbacks
    const _onAuthorClick = useCallback<MouseEventHandler<HTMLElement>>(
        (ev) => {
            onAuthorClick?.(ev, mentionData);
            ev.stopPropagation();
        },
        [onAuthorClick, mentionData],
    );

    const onViewOriginalClick = useCallback<MouseEventHandler<HTMLElement>>(
        (e) => {
            e.stopPropagation();

            _onViewOriginalClick && _onViewOriginalClick();
        },
        [_onViewOriginalClick],
    );

    const onClickHandler = useCallback<MouseEventHandler<HTMLElement>>(
        (event) => {
            if (selectable) {
                !selectionDisabled && setIsSelected?.(!isSelected);
            } else {
                setAsSelectedMention?.();
            }
            onClick?.(event);
        },
        [isSelected, onClick, selectable, selectionDisabled, setAsSelectedMention, setIsSelected],
    );

    const onKeyDownHandler = useCallback<KeyboardEventHandler<HTMLElement>>(
        (event) => {
            if (
                event.target === event.currentTarget &&
                (event.code === 'Enter' || event.code === 'Space')
            ) {
                if (selectable) {
                    !selectionDisabled && setIsSelected?.(!isSelected);
                } else {
                    setAsSelectedMention?.();
                }

                event.preventDefault();
                event.stopPropagation();
            }
        },
        [isSelected, selectable, selectionDisabled, setAsSelectedMention, setIsSelected],
    );

    return (
        <article
            {...rest}
            className={cn(
                classes.root,
                (setAsSelectedMention || (selectable && (!selectionDisabled || isSelected))) &&
                    classes.rootClickable,
                className,
                classes[`layout_${layout}`],
                hasMedia && classes.hasMedia,
                selectable && isSelected && classes.selected,
            )}
            onClick={onClickHandler}
            onKeyDown={onKeyDownHandler}
            ref={ref}
            id={id}
            aria-label={ariaLabel}
            aria-describedby={`${id}_content`}
        >
            <MentionHeader
                mentionData={mentionData}
                mention={mention}
                onAuthorClick={_onAuthorClick}
                hideOriginalLink={hideOriginalLink || selectable}
                onViewOriginalClick={onViewOriginalClick}
                selectable={selectable}
                isSelected={isSelected}
                disableHeaderLinks={selectable}
            />
            <div className={cn(classes.content)}>
                <Content
                    className={classes.mentionText}
                    articleId={id}
                    mention={mention}
                    mentionData={mentionData}
                    fulltext={fulltext}
                    disableLinks={selectable}
                    selectable={selectable}
                />
                {hasMedia && (
                    <Media
                        mentionData={mentionData}
                        className={classes.media}
                        monitorAspectRatio={layout === 'wideA'}
                        preferVideo={wideLayout}
                    />
                )}
            </div>
            {!hideMetrics && (
                <Metrics
                    mentionData={mentionData}
                    mention={mention}
                    editMentionStatus={editMentionStatus}
                    editable={!selectable && editable}
                    className={classes.metrics}
                />
            )}
            <footer className={classes.footer}>
                {isSavedSearchMention(mention) && (
                    <Tags
                        className={classes.tags}
                        mention={mention}
                        tags={mention.tags}
                        editable={editable && !selectable}
                        disabled={editMentionStatus?.loading}
                        pending={editMentionStatus?.loading && editMentionStatus?.tags}
                        size="small"
                        ellipsis
                    />
                )}
                {!selectable && !hideOriginalLink && (
                    <div className={classes.seeOriginal}>
                        <WithMenuAndTooltip
                            tooltip={T('mentionComponent.seeOriginal')}
                            tooltipNoAria
                        >
                            <a
                                href={mention.url}
                                rel="noreferrer"
                                target="_blank"
                                onClick={onViewOriginalClick}
                            >
                                <IconRouter name="open" className={classes.seeOriginalIcon} />
                                <span className="offscreen">
                                    {T('mentionComponent.seeOriginal')}
                                </span>
                            </a>
                        </WithMenuAndTooltip>
                    </div>
                )}
            </footer>
        </article>
    );
});
