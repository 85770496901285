//TODO could actually handle tracking multiple element
import { useState, useCallback, useEffect, useMemo, MouseEventHandler, FocusEventHandler } from "react";
import useUniqueId from "./useUniqueId";


type UseDetectClickOutsideProps<T extends HTMLElement> = {
    onClick?: MouseEventHandler<T>,
    onFocus?: FocusEventHandler<T>,
};

/**
 * Calls supplied callback if the user clicks outside the desired element, or focus leaves
 * 
 * @param onClickOutside Callback to execute when user 'exits' an element (focus leaves or clicks outside)
 * @param props Props to merge with returned event handlers 
 * @returns A set of event handlers to apply to the desired element
 */
export default function useDetectLeaveElement<T extends HTMLElement = HTMLElement>(onClickOutside: (e: MouseEvent | FocusEvent) => void, {onClick: _onClick, onFocus: _onFocus, ...rest}: UseDetectClickOutsideProps<T> = {}): UseDetectClickOutsideProps<T> {
    const trackingProp = `__useDetectClickOutside_${useUniqueId()}`;
    const [isFocusWithin, setIsFocusWithin] = useState(false);

    const onClick: MouseEventHandler<T> = useCallback((e) => {
        _onClick?.(e);

        //Mark this event as having come from within the currently tracked element
        (e.nativeEvent as any)[trackingProp] = true;

        setIsFocusWithin(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_onClick]);

    const onFocus: FocusEventHandler<T> = useCallback((e) => {
        _onFocus?.(e);
        
        //Mark this event as having come from within the currently tracked element
        (e.nativeEvent as any)[trackingProp] = true;

        setIsFocusWithin(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_onFocus]);

    const onWindowClick = useCallback((e: MouseEvent) => {
        if(!(e as any)[trackingProp]) {
            onClickOutside(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onClickOutside]);

    const onWindowFocus = useCallback((e: FocusEvent) => {
        if(!(e as any)[trackingProp]) {
            onClickOutside(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onClickOutside]);

    useEffect(() => {
        if(isFocusWithin) {
            window.addEventListener('click', onWindowClick);
            window.addEventListener('focusin', onWindowFocus);
        } else {
            window.removeEventListener('click', onWindowClick);
            window.removeEventListener('focusin', onWindowFocus);
        }

        return () => {
            window.removeEventListener('click', onWindowClick);
            window.removeEventListener('focusin', onWindowFocus);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFocusWithin]);


    return useMemo(() => ({
        onClick,
        onFocus,
        ...rest
    }), [onClick, onFocus, rest])
}