import colors from './colors';
import elements from './elements';
import * as components from './components';

//Classes
import ThemeBuilder from 'hsi/classes/ThemeBuilder';

//Other
import brandwatchTheme from 'hsi/themes/bw';
import {Colors} from 'hsi/types/theme';

//The theme
const theme = new ThemeBuilder(
    'cision',
    true,
    {
        colors: function () {
            return (
                ThemeBuilder.nullMerge<Partial<Colors>>(colors, this._getExtendedValue('colors')) ||
                {}
            );
        },
        components: {...components, ...brandwatchTheme.components},
        font: () => ({
            '@font-face': [
                {
                    fontFamily: 'Proxima Nova',
                    src: `url("./fonts/ProximaNovaBold.otf") format("opentype")`,
                    fontWeight: 700,
                },
                {
                    fontFamily: 'Proxima Nova',
                    src: `url("./fonts/ProximaNovaSbold.otf") format("opentype")`,
                    fontWeight: 600,
                },
                {
                    fontFamily: 'Proxima Nova',
                    src: `url("./fonts/ProximaNovaReg.otf") format("opentype")`,
                    fontWeight: 400,
                },
                {
                    fontFamily: 'Proxima Nova',
                    src: `url("./fonts/ProximaNovaLight.otf") format("opentype")`,
                    fontWeight: 300,
                },
                {
                    fontFamily: 'Proxima Nova',
                    src: `url("./fonts/ProximaNovaThin.otf") format("opentype")`,
                    fontWeight: 300,
                },
            ],
            name: "'Proxima Nova', sans-serif",
            light: 300,
            bold: 700,
            semiBold: 600,
            regular: 400,
        }),
        fonts: function ({font}) {
            return (
                ThemeBuilder.nullMerge(
                    {
                        display2Strong: {
                            fontWeight: font.semiBold,
                        },
                        display1Normal: {
                            fontSize: '33px',
                            fontWeight: font.light,
                        },
                        display1Strong: {
                            fontWeight: font.semiBold,
                        },
                        headlineNormal: {
                            fontSize: '23px',
                            fontWeight: font.light,
                        },
                        headlineStrong: {
                            fontWeight: font.semiBold,
                        },
                        headtitleNormal: {
                            fontSize: '18px',
                            fontWeight: font.regular,
                        },
                        headtitleStrong: {
                            fontWeight: font.bold,
                        },
                        bodyLargeNormal: {
                            fontWeight: font.regular,
                        },
                        bodyLargeStrong: {
                            fontWeight: font.bold,
                        },
                        bodyNormal: {
                            fontSize: '14px',
                            fontWeight: font.regular,
                        },
                        bodyStrong: {
                            fontSize: '15px',
                            fontWeight: font.bold,
                        },
                        bodySmall: {
                            fontWeight: font.regular,
                        },
                        bodySmallStrong: {
                            fontWeight: font.bold,
                        },
                        buttonLarge: {
                            fontWeight: font.semiBold,
                        },
                        buttonNormal: {
                            fontWeight: font.semiBold,
                        },
                        labelStrong: {
                            fontWeight: font.bold,
                        },
                        labelNormal: {
                            fontWeight: font.regular,
                        },
                    },
                    this._getExtendedValue('fonts'),
                ) || {}
            );
        },

        elements: function (theme) {
            return ThemeBuilder.nullMerge(elements(theme), this._getExtendedValue('elements'));
        },

        overrides: function ({colors}) {
            return ThemeBuilder.nullMerge(
                {
                    /********     BUTTON    ********/
                    MuiButton: {
                        root: {
                            borderRadius: '20px',
                        },
                        contained: {
                            borderRadius: '20px',
                        },
                        containedPrimary: {
                            borderRadius: '20px',
                        },
                        containedSecondary: {
                            borderRadius: '20px',
                        },
                    },

                    /********   BUTTON GROUP    *******/
                    MuiButtonGroup: {
                        grouped: {
                            backgroundColor: colors.white,
                            '&:hover': {
                                backgroundColor: colors.buttonHover,
                                color: colors.primaryBlue60,
                            },
                        },
                    },

                    /*********    Chip   *********/
                    MuiChip: {
                        root: {
                            color: colors.accentDefault,
                            borderRadius: '8px',
                            backgroundColor: colors.white,
                            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)',
                        },
                        clickable: {
                            '&:hover': {
                                backgroundColor: colors.bgSubtle,
                            },
                            '&:focus': {
                                backgroundColor: colors.bgSubtle,
                                boxShadow: 'none',
                                outline: 'none',
                            },
                            '&:active': {
                                backgroundColor: colors.bgSubtle,
                            },
                        },
                        deletable: {
                            '&:hover, &:focus': {
                                backgroundColor: colors.white,
                            },
                        },
                    },
                },
                this._getExtendedValue('overrides'),
            );
        },
    },
    //Extends
    brandwatchTheme,
);

export default theme;
