import useGetProjectIdFromQueryId from "./useGetProjectIdFromQueryId";
import useQueryContext from "./useQueryContext";
import { useCallback } from "react";
import { putQueryUserDataKey } from "hsi/services/queryUserDataService";
import { QueryUserData, QueryUserDataKeys } from "hsi/types/queryUserData";
import { useAppSelector, useAppStore } from "./useRedux";
import { Nullable } from "hsi/types/shared";
import { updateQueryUserData } from "hsi/slices/queryUserData";
import { RootState } from "hsi/utils/configureStore";
import useIsExport from "./useIsExport";



/**
 * Get the per-query/user persisted data property for the supplied query id. If queryId is null, undefined or unknown, will return null.
 * Will trigger an update of the calling component every time this property of the query data changes
 * @param queryId queryId to get the data for. If null or undefined, will return null
 * @param key the prop within the queryUserData to retrieve
 * @returns null or the persisted value
 */
export default function useQueryUserData<Prop extends QueryUserDataKeys>(queryId: number | null | undefined, key: Prop): QueryUserData[Prop] | null;
/**
 * Get the per-query/user persisted data property returned by the supplied selector
 * Will trigger an update of the calling component every time the selector returns different data (using referential equality)
 * @param queryId queryId to get the data for. If null or undefined, will return null
 * @param selector a function called with the persisted data for this query, which selects and return data
 * @returns the selected value
 */
export default function useQueryUserData<ReturnProp>(queryId: number | null | undefined, selector: (state: Partial<Nullable<QueryUserData>> | null) => ReturnProp): ReturnProp;
export default function useQueryUserData(queryId: number | null | undefined, keyOrSelector: any): any {
    const selector = queryId
         ?(typeof keyOrSelector === 'string')
            ? (state: RootState)  => state.queryUserData[queryId]?.[keyOrSelector as QueryUserDataKeys] ?? null
            : (state: RootState) => keyOrSelector(state.queryUserData[queryId])
        : () => null
    
    return useAppSelector(selector);
}

/**
 * Get the per-query/user persisted data property for the current query. If no current query, will return null.
 * Will trigger an update of the calling component every time this property of the query data changes
 * @param key the prop within the queryUserData to retrieve
 * @returns null or the persisted value
 */
export function useCurrentQueryUserData<Prop extends QueryUserDataKeys>(key: Prop): QueryUserData[Prop] | null;
/**
 * Get the per-query/user persisted data property for the current query returned by the supplied selector
 * Will trigger an update of the calling component every time the selector returns different data (using referential equality)
 * @param selector a function called with the persisted data for this query, which selects and return data
 * @returns the selected value
 */
export function useCurrentQueryUserData<ReturnProp>(selector: (state: Partial<Nullable<QueryUserData>> | null) => ReturnProp): ReturnProp;
export function useCurrentQueryUserData(keyOrSelector: any): any {

    const {savedSearchId} = useQueryContext();

    return useQueryUserData(savedSearchId, keyOrSelector);
}

/**
 * Get the per-query/user persisted data for the supplied query id. If queryId is null, undefined or unknown, will return null
 * @param queryId queryId to get the data for. If null or undefined, will return null
 * @returns null or an object that may contain the QueryUserData props, if they are set
 */
export function useAllQueryUserData(queryId: number | null | undefined): Partial<Nullable<QueryUserData>> | null {
    return useAppSelector(queryId ? state => state.queryUserData[queryId!] : () => null);
}

/**
 * Get the per-query/user persisted data for the current query (if applicable). If not in a query context, will return null
 * @returns null or an object that may contain the QueryUserData props, if they are set
 */
export function useAllCurrentQueryUserData() {
    const {savedSearchId} = useQueryContext();

    return useAllQueryUserData(savedSearchId);
}

/**
 * Returns a function to set persisted values on a per query/user basis
 * If called with a queryId of null, or an invalid query Id, will return null
 * @param queryId 
 * @returns function to set persisted values OR null
 */
export function useGetQueryUserDataSetter(queryId: number | null) {
    const isExport = useIsExport();
    const projectId = useGetProjectIdFromQueryId(!isExport ? queryId : null);
    const store = useAppStore();

    // setter can only be called if queryId and projectId are not null
    const setter = useCallback(async <T extends QueryUserDataKeys>(key: T, value: QueryUserData[T] | ((currentValue: QueryUserData[T] | null) => QueryUserData[T] | null) | null) => {
        const initialValue = store.getState().queryUserData[queryId!]?.[key] ?? null;

        const useValue = value instanceof Function 
            ? value(initialValue) 
            : value;
        
        //Update the store
        await store.dispatch(updateQueryUserData({queryId: queryId as number, key, value: useValue}));

        // persist the new value
        const result = await putQueryUserDataKey(queryId!, projectId!, key, useValue);

        if(result.ok) {
            //Update the state with the new value
            await store.dispatch(updateQueryUserData({queryId: queryId as number, key, value: useValue}));
        }

        return result;
    }, [projectId, queryId, store]);

    return queryId !== null && projectId !== null 
        ? setter
        : null;
}