import React from 'react';
import PropTypes from 'prop-types';

//Other
import useStyles from './styles';

//The component
export default function ChartTooltipContent({title, valueItems, children, byCategoryTotals}) {
    const classes = useStyles();

    return (
        <div className={classes.content}>
            {!!title && <div className={classes.title}>{title}</div>}
            {!!valueItems &&
                valueItems.map((dv, i) => (
                    <div className={classes.value} key={`val-${i}`}>
                        {!!dv.color && (
                            <div
                                className={classes.colorSample}
                                style={{backgroundColor: dv.color}}
                            />
                        )}
                        {!byCategoryTotals && (
                            <>
                                <div className={classes.amount}>{dv.amount}</div>
                                {!!dv.unit && <div className={classes.unit}>{dv.unit}</div>}
                            </>
                        )}
                        {!!byCategoryTotals && (
                            <>
                                {!!dv.unit && <div className={classes.unit}>{dv.unit}</div>}
                                <div className={classes.amount}>{': ' + dv.amount}</div>
                            </>
                        )}
                    </div>
                ))}
            {children}
        </div>
    );
}

ChartTooltipContent.propTypes = {
    title: PropTypes.any,
    valueItems: PropTypes.arrayOf(
        PropTypes.shape({
            color: PropTypes.string,
            amount: PropTypes.oneOfType([PropTypes.any]).isRequired,
            unit: PropTypes.string,
        }),
    ),
    children: PropTypes.any,
};
