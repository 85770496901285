import {makeStyles} from 'tss-react/mui';

export default makeStyles()((theme, _params, classes) => ({
    compact: {}, //needs to exist to be referenced elsewhere
    section: {
        padding: 0,

        '& > .MuiCollapse-root > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner': {
            paddingBottom: 12,
        },
        [`&.${classes.compact} > .MuiCollapse-root > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner`]:
            {
                padding: theme.v2 ? '8px 0' : 0,
            },
    },

    icon: theme.v2
        ? {
              marginRight: '10px',
          }
        : {
              width: '38px',
              height: '38px',
              marginLeft: '-9px',
          },

    groupTitle: {
        paddingLeft: '0',
        color: theme.v2 ? theme.colors.textDefault : theme.colors.darkGrey100,

        ...(theme.v2
            ? theme.typgrphy.bodyLargeStrong
            : {
                  fontSize: '16px',

                  fontWeight: 700,
              }),
    },

    sectionHead: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },

    sectionHeadButton: {
        ...theme.mixin.blankButton,
        display: 'flex',
        alignItems: 'center',
        flex: '1 1 100%',
        marginBottom: 8,

        [`&.${classes.compact}`]: {
            marginBottom: 0,
        },

        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
}));
