//Obsolete
//This was an early experiment of using the MUI autocomplete
//component. It has been replaced with a new Autocomplete
//component, which also uses the MUI autocomplete component,
//but in a less opinionated, more flexible way

import {forwardRef, useCallback} from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import MuiAutocomplete, {
    AutocompleteProps as MuiAutocompletePropsBase,
    AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';

import IconRouter from 'hsi/components/IconRouter';
import TextField, {TextFieldProps} from 'hsi/components/TextField';

import {T} from 'hsi/i18n';

import useStyles from './styles';
import {PopperProps} from '@mui/material';

type Option = {
    id: string;
    label: string;
};

type MuiAutocompleteProps = MuiAutocompletePropsBase<any, false, true, false>;

interface AutocompleteProps extends Partial<MuiAutocompleteProps> {
    getOptionLabel?: (option: any) => string;
    noOptionsText?: React.ReactNode;
    onChange: MuiAutocompleteProps['onChange'];
    options: MuiAutocompleteProps['options'];
    renderInput?: (args: any) => React.ReactNode;
    renderOptionComponent?: (args: any) => React.ReactNode;
}

const Autocomplete = forwardRef(
    (
        {
            getOptionLabel = (option: Option) => option.label,
            onChange,
            options,
            noOptionsText = <DefaultNoOptionsComponent />,
            renderInput = renderDefaultInputComponent,
            renderOptionComponent = renderDefaultOptionComponent,
            ...props
        }: AutocompleteProps,
        ref,
    ) => {
        const {classes} = useStyles();
        const {inputIcon, ...autocompleteClasses} = classes;

        const PopperComponent = useCallback((props: PopperProps) => {
            const {anchorEl, labelId, ...other} = props as any; //remove any unfriendly props
            return <div {...other} />;
        }, []);

        return (
            <MuiAutocomplete
                classes={autocompleteClasses}
                disableClearable
                forcePopupIcon={false}
                getOptionLabel={getOptionLabel}
                noOptionsText={noOptionsText}
                onChange={onChange}
                options={options}
                PopperComponent={PopperComponent}
                ref={ref}
                renderInput={(params) => renderInput({classes, onChange, params})}
                renderOption={(props, option, {inputValue}) => {
                    const matches = match(getOptionLabel(option), inputValue, {insideWords: true});
                    const parts = parse(getOptionLabel(option), matches);

                    return <li {...props}>{renderOptionComponent({option, parts})}</li>;
                }}
                {...props}
            />
        );
    },
);

export const renderDefaultOptionComponent = ({parts}: {parts: ReturnType<typeof parse>}) => (
    <div>
        {parts.map((part, index) => {
            if (part.highlight) {
                return (
                    <span key={index}>
                        <strong>{part.text}</strong>
                    </span>
                );
            } else {
                return <span key={index}>{part.text}</span>;
            }
        })}
    </div>
);

interface renderDefaultInputComponentProps extends Partial<TextFieldProps> {
    params: AutocompleteRenderInputParams;
    classes: Record<string, string>;
}

export const renderDefaultInputComponent = ({
    classes,
    descId,
    error,
    helperText,
    label,
    params,
    placeholder,
}: renderDefaultInputComponentProps) => (
    <TextField
        autoFocus
        {...{
            ...params,
            descId,
            error,
            helperText,
            InputProps: {
                ...params.InputProps,
                startAdornment: <IconRouter className={classes.inputIcon} name="search" />,
            },
            label,
            placeholder,
            ref: params.InputProps.ref,
        }}
    />
);

export const DefaultNoOptionsComponent = () => {
    const {classes} = useStyles();

    return (
        <div role="status" className={classes.noOptions}>
            <IconRouter name="search" aria-hidden />
            <div>
                <strong>{T('autocomplete.noOptions.defaultTitle')}</strong>
            </div>
            <div>{T('autocomplete.noOptions.defaultDesc')}</div>
        </div>
    );
};

export default Autocomplete;
