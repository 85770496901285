export const createError = (error_description) => ({error_description});

export const parseErrorMessage = (error) => {
    const msg =
        error?.body?.error_description ||
        error?.body?.errors?.[0]?.message ||
        error?.error_description;

    return `Error${msg ? `: ${msg}` : ''}`;
};
