import {useState, useMemo, ReactNode, useCallback, useEffect} from 'react';

import CircularProgress from '@mui/material/CircularProgress';

//Components
import Button from 'hsi/components/Button';
import IconRouter from 'hsi/components/IconRouter';
import SearchBox from 'hsi/components/SearchBox';
import Item from './Item';

//Other
import useStyles from './styles';

import {T} from 'hsi/i18n';
import AnnounceMessageDebounced from 'hsi/components/aria/AnnounceMessageDebounced';
import Heading from 'hsi/components/aria/Heading';

export type MultipleOptionsPopoverContentProps<T> = {
    options: T[];
    initialOptions: T[];
    getOptionLabel: (id: T) => string;
    onSaveSelected?: (selectedOptions: T[]) => void;
    onChangeSelected?: (selectedOptions: T[]) => void;
    onCancelSelected?: () => void;
    onClearAllSelected?: () => void;
    loading?: boolean;
    title: ReactNode;
    disabledOptionTooltip?: string;
    maxOptions?: number;
    otherSavedSearches?: string[] | number[];
    noResultsMessage?: ReactNode;
    noOptionsMessage?: ReactNode;
    info?: ReactNode,

    close?: (manual: boolean) => void;
};

export default function MultipleOptionsPopoverContent<T>({
    options,
    initialOptions,
    getOptionLabel,
    onChangeSelected,
    onSaveSelected,
    onCancelSelected,
    onClearAllSelected,
    loading,
    title,
    disabledOptionTooltip,
    maxOptions,
    otherSavedSearches,
    noResultsMessage,
    noOptionsMessage,
    info,
    close,
}: MultipleOptionsPopoverContentProps<T>) {
    const cl = useStyles();

    //State
    const [filter, setFilter] = useState('');
    const [selectedOptions, setSelectedOptions] = useState(initialOptions);

    //Calculated values
    const filteredOptions = useMemo(
        () =>
            options.filter((id: T) =>
                getOptionLabel(id).toLowerCase().includes(filter.toLowerCase()),
            ),
        [filter, getOptionLabel, options],
    );

    // Total searches must contain saved searches and LinkedIn pages
    const totalSelectedSearches = (otherSavedSearches ? otherSavedSearches.length : 0) + selectedOptions.length;
    const optionsLeft = maxOptions === undefined ? null : maxOptions - totalSelectedSearches;
    const hasOptionsResults = filteredOptions.length > 0;
    const hasOptions = options.length > 0;

    //Callbacks
    const onClickCancel = useCallback(() => {
        onCancelSelected?.();
        close?.(true);
    }, [close, onCancelSelected]);

    const onClickClear = useCallback(() => {
        onClearAllSelected?.();
        setSelectedOptions([]);
    }, [onClearAllSelected]);

    const onClickSave = useCallback(() => {
        onSaveSelected?.(selectedOptions);
        close?.(true);
    }, [close, onSaveSelected, selectedOptions]);

    const onChange = useCallback((changedItem: T, checked: boolean) => {
        setSelectedOptions(
            checked
                ? (curSelectedOptions) => Array.from(new Set([...curSelectedOptions, changedItem]))
                : (curSelectedOptions) =>
                      curSelectedOptions.filter((option) => option !== changedItem),
        );
    }, []);

    //Side effects
    useEffect(() => {
        onChangeSelected?.(selectedOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOptions]);

    useEffect(() => {
        setSelectedOptions((selectedOptions) => {
            //Filter out any selected values that are no longer available
            const allOptions = new Set(options);
            const newSelectedOptions = selectedOptions.filter((selectedOption) =>
                allOptions.has(selectedOption),
            );

            //Return new value if changed
            return newSelectedOptions.length !== selectedOptions.length
                ? newSelectedOptions
                : selectedOptions;
        });
    }, [options]);

    //Render
    return (
        <div className={cl.container}>
            <SearchBox
                placeholder={T('multipleOptionsPopover.searchBoxPlaceholder')}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                fullWidth
                InputProps={{inputProps: {'aria-label': T('multipleOptionsPopover.searchBoxLbl')}}}
                className={cl.searchBox}
            />

            <AnnounceMessageDebounced
                message={
                    filter
                        ? hasOptionsResults
                            ? T('multipleOptionsPopover.filterResultsStatus', {
                                  num: filteredOptions.length,
                              })
                            : ''
                        : T('multipleOptionsPopover.availableOptionsStatus', {
                              num: filteredOptions.length,
                          })
                }
            />

            <div className={cl.titleContainer}>
                <Heading className={cl.title}>{title}</Heading>
                {maxOptions !== undefined && (
                    <>
                        <p className="offscreen">
                            {T('multipleOptionsPopover.selectableLimitHint', {num: maxOptions})}
                        </p>
                        <div aria-hidden className={cl.optionsLeft} data-testid="optionsLeft">
                            {T('multipleOptionsPopover.optionsRemaining', {num: optionsLeft!})}
                        </div>
                        <AnnounceMessageDebounced
                            wait={2000}
                            message={T('multipleOptionsPopover.optionsRemaining', {
                                num: optionsLeft!,
                            })}
                        />
                    </>
                )}
            </div>

            <div className={cl.checkboxes}>
                {hasOptionsResults ? (
                    <ul className={cl.checkboxesList}>
                        {filteredOptions.map((id) => {
                            const checked = selectedOptions.includes(id);
                            return (
                                <Item
                                    key={getOptionLabel(id)}
                                    id={id}
                                    checked={checked}
                                    disabled={
                                        maxOptions !== undefined && optionsLeft! <= 0 && !checked
                                    }
                                    onChange={onChange}
                                    label={
                                        <div className={cl.checkboxLabel}>{getOptionLabel(id)}</div>
                                    }
                                    disabledTooltip={disabledOptionTooltip}
                                />
                            );
                        })}
                    </ul>
                ) : (
                    <NoResults message={hasOptions ? noResultsMessage : noOptionsMessage} />
                )}
            </div>
            {info && <div className={cl.info}>{info}</div>}
            <div className={cl.actions}>
                <div className={cl.actionsLeft}>
                    <Button
                        onClick={onClickClear}
                        priority="text"
                        className={cl.clearAllButton}
                        disabled={selectedOptions.length === 0}
                    >
                        {T('multipleOptionsPopover.clearAll')}
                    </Button>
                </div>

                <div className={cl.actionsRight}>
                    <Button
                        onClick={onClickCancel}
                        className={cl.cancelButton}
                        disabled={!hasOptionsResults}
                    >
                        {T('multipleOptionsPopover.cancel')}
                    </Button>

                    <Button
                        onClick={onClickSave}
                        priority="cta"
                        disabled={loading || !hasOptionsResults}
                    >
                        <span>{T('multipleOptionsPopover.save')}</span>
                        {loading && (
                            <CircularProgress size={20} className={cl.saveButtonProgress} />
                        )}
                    </Button>
                </div>
            </div>
        </div>
    );
}

function NoResults({message}: {message?: ReactNode}) {
    const cl = useStyles();

    return (
        <div className={cl.noResultsContainer} role="status" aria-live="assertive">
            <div className={cl.noResultsIconContainer}>
                <IconRouter aria-hidden className={cl.noResultsIcon} name="search" />
            </div>
            {typeof message === 'string' ? (
                <div className={cl.noResultsText}>{message}</div>
            ) : (
                message || (
                    <>
                        <div className={cl.noResultsText}>
                            {T('multipleOptionsPopover.noResults')}
                        </div>
                        <div className={cl.noResultsHint}>
                            {T('multipleOptionsPopover.noResultsHint')}
                        </div>
                    </>
                )
            )}
        </div>
    );
}
