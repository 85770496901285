import {useCallback, useMemo, useRef, useState} from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

// Components
import Dialog, {DialogActions, DialogContent, DialogTitle} from 'hsi/components/Dialog';
import Radio from 'hsi/components/Radio';
import Button from 'hsi/components/Button';
import PendingModal from 'hsi/components/PendingModal';
import FormError from 'hsi/components/FormError';

//Hooks
import {useAppSelector, useAppDispatch} from 'hsi/hooks/useRedux';
import useProjectId from 'hsi/hooks/useProjectId';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useIsActionPendingOnMentions from 'hsi/hooks/useIsActionPendingOnMentions';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';
import {updateMentionsSentiment} from 'hsi/slices/mentions';
import {SENTIMENT_CATEGORIES} from 'hsi/constants/config';

//Types
import {SentimentType} from 'hsi/types/shared';
import {isFulfilled} from '@reduxjs/toolkit';

export type BulkChangeSentimentDialogProps = {
    isOpen?: boolean;
    onClose?: () => void;
    onClosed?: () => void;
};

export default function BulkChangeSentimentDialog({
    isOpen = false,
    onClose: _onClose,
    onClosed: _onClosed,
}: BulkChangeSentimentDialogProps) {
    const {classes} = useStyles();
    const mentionsStatePending = useAppSelector(
        (state) => state.mentions.loading || state.mentions.isBulkActionPending,
    );
    const stateSelectedMentions = useAppSelector((state) => state.mentions.selectedMentions);

    const projectId = useProjectId()!;
    const dispatch = useAppDispatch();
    const {trackWithSearchData} = useEventTrack();

    //Local state
    const [sentiment, setSentiment] = useState<SentimentType | ''>('');
    const [showErrors, setShowErrors] = useState(false);
    const [working, setWorking] = useState(false);

    //Calculated values
    const hasSentimentError = sentiment === '';
    const showSentimentError = showErrors && hasSentimentError;

    //This is used to track the mentions that actually get changed,
    //as the process of changing sentiment can happen so fast that it
    //applies before this dialog completes it's closing transition
    const selectedMentionsAppliedRef = useRef<Record<string, boolean>>();
    const selectedMentions = selectedMentionsAppliedRef.current ?? stateSelectedMentions;

    const numSelectedMentions = Object.keys(selectedMentions).length;

    const mentionsHaveActionPending = useIsActionPendingOnMentions(selectedMentions);
    const loading = working || mentionsStatePending || mentionsHaveActionPending;

    //Callbacks
    const onClose = useMemo(() => {
        return _onClose
            ? () => {
                  !working && _onClose();
              }
            : undefined;
    }, [working, _onClose]);

    const onClickApply = useCallback(async () => {
        if (sentiment) {
            const numSelectedMentions = Object.keys(stateSelectedMentions).length;
            setShowErrors(false);
            setWorking(true);

            //store the mentions that are selected at this exact point
            selectedMentionsAppliedRef.current = stateSelectedMentions;

            const response = await dispatch(
                updateMentionsSentiment({
                    ids: Object.keys(selectedMentionsAppliedRef.current!),
                    sentiment,
                    projectId,
                    minDelay: 1000,
                }),
            );

            if (isFulfilled(response)) {
                trackWithSearchData('bulkMentionSentimentEditComplete', {
                    selectedMentions: numSelectedMentions,
                });
            }

            _onClose?.();
        } else {
            //hitting submit without picking a sentiment will show an error
            setShowErrors(true);
        }
    }, [dispatch, _onClose, sentiment, projectId, stateSelectedMentions, trackWithSearchData]);

    const onSentimentChange = (e: React.ChangeEvent<HTMLInputElement>, value: any) => {
        if (SENTIMENT_CATEGORIES.includes(value)) {
            value && setSentiment(value);
        }
    };

    const onClosed = useCallback(() => {
        //reset local state
        setShowErrors(false);
        setSentiment('');
        setWorking(false);
        selectedMentionsAppliedRef.current = undefined;

        _onClosed?.();
    }, [_onClosed]);

    //Rendering
    return (
        <Dialog
            className={classes.root}
            open={isOpen}
            onClose={onClose}
            onClosed={onClosed}
            disableCloseBtn={working}
            aria-labelledby="bulkChangeSentimentDialog.title"
        >
            <PendingModal
                pending={loading}
                message={T('mentionsContainer.bulkChangeSentiment.pending')}
                loaderSize="medium"
            >
                <div>
                    <DialogTitle id="bulkChangeSentimentDialog.title">
                        {T('mentionsContainer.bulkChangeSentiment.title')}
                    </DialogTitle>
                    <DialogContent>
                        <FormControl component="fieldset">
                            <RadioGroup
                                className={classes.sentiments}
                                aria-label={T(
                                    'mentionsContainer.bulkChangeSentiment.sentimentField.label',
                                )}
                                name="sentiment"
                                value={sentiment}
                                onChange={onSentimentChange}
                            >
                                {SENTIMENT_CATEGORIES.map((sentiment) => (
                                    <FormControlLabel
                                        key={sentiment}
                                        value={sentiment}
                                        className={classes.sentiment}
                                        control={<Radio required />}
                                        label={T(`sentiments.${sentiment}`)}
                                        aria-errormessage={
                                            showSentimentError
                                                ? 'mentionsContainer.bulkChangeSentiment.sentimentField.error'
                                                : undefined
                                        }
                                    />
                                ))}
                            </RadioGroup>
                            {showSentimentError && (
                                <FormError
                                    type="warning"
                                    id="mentionsContainer.bulkChangeSentiment.sentimentField.error"
                                    errorText={T(
                                        'mentionsContainer.bulkChangeSentiment.sentimentField.errorText',
                                    )}
                                    actionText={T(
                                        'mentionsContainer.bulkChangeSentiment.sentimentField.actionText',
                                    )}
                                />
                            )}
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button priority="text" onClick={onClose}>
                            {T('mentionsContainer.bulkChangeSentiment.cancelAction')}
                        </Button>
                        <Button
                            priority="cta"
                            onClick={onClickApply}
                        >
                            {T('mentionsContainer.bulkChangeSentiment.confirmAction', {
                                count: numSelectedMentions,
                            })}
                        </Button>
                    </DialogActions>
                </div>
            </PendingModal>
        </Dialog>
    );
}
