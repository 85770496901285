import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

const useStyles = makeStyles((theme: Theme) => {
    const v2 = isV2(theme);

    return {
        container: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '60vh',
            margin: 'auto',
        },
        loader: {
            alignItems: 'center',
            display: 'flex',
            height: '60vh',
        },
        pageImg: {
            height: v2 ? 120 : 145,
            marginTop: v2 ? 64 : 88,
            marginBottom: v2 ? 44 : 12,
            width: v2 ? 280 : 214,
        },
        pageErrorIcon: {
            height: 100,
            width: 100,
        },
        pageTitle: {
            textAlign: 'center',
            lineHeight: '40px',
            margin: 12,
            ...(v2
                ? theme.fonts.display1Strong
                : {
                      fontSize: 24,
                      lineHeight: '30px',
                      fontWeight: theme.font.bold,
                  }),
        },
        pageDesc: {
            textAlign: 'center',
            lineHeight: '24px',
            margin: 12,
            ...(v2
                ? theme.fonts.headtitleNormal
                : {
                      fontSize: 20,
                      lineHeight: '35px',
                      fontWeight: theme.font.regular,
                  }),
        },
        pageButton: {
            margin: 12,
        },
        contentContainer: {
            width: '97.5%',
            marginBottom: 16,
        },
        banner: {marginTop: 12, marginBottom: 0},
        bannerText: {
            '& p': {
                ...(v2
                    ? {
                          fontSize: `${theme.fonts.bodyLargeNormal.fontSize} !important`,
                          fontWeight: `${theme.fonts.bodyLargeNormal.fontWeight} !important`,
                      }
                    : {
                          fontSize: '16px !important',
                          fontWeight: `${theme.font.regular} !important`,
                      }),
            },
            '& ul': {
                margin: 0,
                paddingLeft: 24,
                '& li': {
                    ...(v2
                        ? theme.fonts.bodyLargeNormal
                        : {fontSize: 16, fontWeight: theme.font.regular}),
                },
            },
        },
    };
});

export default useStyles;
