import makeStyles from '@mui/styles/makeStyles';

import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles((theme: Theme) => {
    const v2 = isV2(theme);

    return {
        contentTitle: {
            display: 'block',
            ...theme.mixin.wordBreak,

            ...(v2
                ? {
                      ...theme.fonts.bodyStrong,
                      margin: '0 0 8px',
                      color: theme.colors.darkGrey60,
                  }
                : {
                      fontSize: 'inherit',
                      fontWeight: theme.font.bold,
                      margin: '0 0 8px',
                  }),
        },
        contentSnippet: {
            ...theme.mixin.wordBreak,

            ...(v2
                ? {
                      ...theme.fonts.bodyNormal,
                  }
                : {
                      fontSize: '16px',
                      fontWeight: theme.font.regular,
                  }),
        },
        highlight: {
            backgroundColor: theme.colors.darkGrey20,
        },
    };
});
