import getConfig from 'hsi/config';
import {CCFlagsType} from 'hsi/types/cc-flags';
import {FilterConfigByName, FilterSectionConfig} from 'hsi/types/filters';
import {PageType, SearchType} from 'hsi/types/shared';

function getFiltersConfig<TSearchType extends SearchType>(
    searchType: TSearchType,
    pageTypes: PageType[],
    projectId: number,
    flags: CCFlagsType,
): {config: FilterSectionConfig[]; version: number; allFiltersConfig: FilterConfigByName} {
    const config = getConfig();

    const filtersConfig = config?.filters?.[`${searchType as TSearchType}SearchFilters`]({
        pageTypes,
        projectId,
        flags,
    });

    const allFiltersConfig = filtersConfig.reduce<FilterConfigByName>(
        (output, {filters}) =>
            filters.reduce((output, filterConfig) => {
                output[filterConfig.filterName] = filterConfig;

                return output;
            }, output),
        {},
    );

    return {
        config: filtersConfig,
        version: config.filters.versionNumber,
        allFiltersConfig,
    };
}

export default getFiltersConfig;
