import {useCallback, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Chip from '@mui/material/Chip';

//Component
import _Alert from 'hsi/components/Tooltip/Alert';
import AuthStartMenu from './AuthStartMenu';
import IconRouter from 'hsi/components/IconRouter';
import Markdown from 'hsi/components/Markdown';
import Popover, {PopoverRenderFunc} from 'hsi/components/Popover';
import TrackWithIGBAMenu from './TrackWithIGBAMenu';

import {T} from 'hsi/i18n';

import {Theme} from 'hsi/types/theme';

const Alert: any = _Alert; // TODO: Remove once tooltip alert is typed

const useStyles = makeStyles((theme: Theme) => {
    const {colors} = theme;

    return {
        muiChipLabel: {
            maxWidth: 250,
            paddingRight: 8,
        },
        chipLabelContent: {
            display: 'flex',
            alignItems: 'center',
            borderRight: `1px solid ${colors.borders}`,
            cursor: 'pointer',
        },
        labelText: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        iconWarning: {
            width: 16,
            height: 16,
            marginRight: 8,
            flex: `0 0 16px`,
        },
        iconArrow: {
            margin: `0 4px`,
            width: 16,
            flex: `0 0 16px`,
            position: 'static !important' as 'static', // Typescript doesn't like !important
        },
        iconArrowWrapper: {
            height: 14,
            display: 'inline-flex',
        },
        tooltip: {
            '&&': {
                maxWidth: 'auto',
            },
        },
        tooltipP: {
            '&&': {
                margin: 0,
                fontSize: '14px',
            },
        },
    };
});

type HashtagChipProps = {
    hasIGAuth: boolean;
    label: string;
    missingIG: boolean;
    value: string;
};

const HashtagChipWithMissingIG = ({
    hasIGAuth,
    label,
    value,
    ...rest
}: Omit<HashtagChipProps, 'missingIG'>) => {
    const classes = useStyles();

    const [showAlert, setShowAlert] = useState(true);

    const menuContent = useCallback<PopoverRenderFunc>(
        ({close}) =>
            !hasIGAuth ? (
                <AuthStartMenu onClose={close} />
            ) : (
                <TrackWithIGBAMenu hashtag={value} onClose={close} />
            ),
        [hasIGAuth, value],
    );

    const getDownArrow = useCallback(
        (elem: HTMLElement) => {
            return elem.querySelector(`.${classes.iconArrowWrapper}`);
        },
        [classes.iconArrowWrapper],
    );

    return (
        <Alert
            disableAria
            visible={showAlert}
            morePadding
            align="left"
            theme="light"
            portal
            content={
                <Markdown classes={{p: classes.tooltipP}}>
                    {T('queryBuilder.trackWithIgTooltip')}
                </Markdown>
            }
            getPositionElement={getDownArrow}
        >
            <Popover content={menuContent} portal onShow={() => setShowAlert(false)}>
                <Chip
                    classes={{
                        label: classes.muiChipLabel,
                    }}
                    label={
                        <div className={classes.chipLabelContent} data-testid="hashtagChip">
                            <IconRouter
                                className={classes.iconWarning}
                                data-testid="hashtagChipWarningIcon"
                                name="c-warning-e"
                            />
                            <span className={classes.labelText}>{label}</span>
                            <span className={classes.iconArrowWrapper}>
                                <IconRouter
                                    className={classes.iconArrow}
                                    data-testid="hashtagChipDownArrowIcon"
                                    name="chevron-down"
                                />
                            </span>
                        </div>
                    }
                    {...rest}
                />
            </Popover>
        </Alert>
    );
};

const HashtagChip = ({hasIGAuth, missingIG, value, ...rest}: HashtagChipProps) =>
    missingIG ? <HashtagChipWithMissingIG {...{hasIGAuth, value, ...rest}} /> : <Chip {...rest} />;

export default HashtagChip;
