import pick from 'lodash/pick';

import {
    CREATE_REPORT,
    DELETE_REPORT,
    EDIT_REPORT,
    HIDE_SNACKBAR_NOTIFICATION,
    LOAD_REPORTS_ERROR,
    REPORT_ERROR,
    SHOW_SNACKBAR_NOTIFICATION,
    TOGGLE_REPORT,
} from '../constants/actionTypes';

const initialState = {
    isOpen: false,
    message: '',
    variant: '', //what variants are supported? success, info, warning - I think thats it?
    hidable: true,
    notificationId: null,
    anchorOrigin: {vertical: 'top', horizontal: 'center'},
};

const reducerKeys = Object.keys(initialState);
const showPayloadKeys = reducerKeys.filter((key) => key !== 'isOpen'); //minus isOpen

const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_REPORT:
        case DELETE_REPORT:
        case EDIT_REPORT:
        case LOAD_REPORTS_ERROR:
        case REPORT_ERROR:
        case SHOW_SNACKBAR_NOTIFICATION:
        case TOGGLE_REPORT:
            return {
                ...state,

                //restore default values
                hidable: true,
                anchorOrigin: {vertical: 'top', horizontal: 'center'},
                notificationId: null,
                variant: '',

                //pick relevent values
                ...pick(action.payload, showPayloadKeys),

                isOpen: true,
            };
        case HIDE_SNACKBAR_NOTIFICATION:
            return {
                ...state,
                isOpen: false,
                message: '',
                variant: '',
                notificationId: '',
                hidable: true,
                anchorOrigin: {vertical: 'top', horizontal: 'center'},
            };
        default:
            return state;
    }
};

export default notificationsReducer;
