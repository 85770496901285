import {useCallback, useEffect, useMemo} from 'react';
import {Link} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';

//Components
import DateRangeBar from 'hsi/components/DateRangeBar';
import IconRouter from 'hsi/components/IconRouter';
import QueryBuilder from 'hsi/containers/QueryBuilder';
import Banner from 'hsi/components/Banner';
import SaveSearchButton from 'hsi/components/SaveSearchButton';

//Hooks
import useDates from 'hsi/hooks/useDates';
import useQueryDateRange from 'hsi/hooks/useQueryDateRange';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';

//Other
import useQueryBuilderState from 'hsi/containers/QueryBuilder/useQueryBuilderState';

import {openQueryBuilder, closeQueryBuilder, openSearchHelp, closeSearchHelp, reset} from 'hsi/slices/quickSearchResultsPage';
import {getSearchResultsUrl, isProduction} from 'hsi/utils/url';
import {introAnchors} from 'hsi/containers/Intro';
import useStyles from './styles';
import {T} from 'hsi/i18n';

//Consts
import {HELP_BUTTON_PLACEMENT} from 'hsi/containers/QueryBuilder/config';

//Types
import {SavedSearchType} from 'hsi/types/shared';
import Heading from '../aria/Heading';

type QuickSearchBarArgs = {
    savedSearch?: SavedSearchType;
};

//The Component
//as well use quick searches, this is also used when editing a saved search query
export default function QuickSearchBar({savedSearch}: QuickSearchBarArgs) {
    const classes = useStyles();
    const dates = useDates();
    const dispatch = useAppDispatch();
    const canSaveSearch = useAppSelector((state) => state.user.canSaveSearch);
    const isSavedSearch = !isEmpty(savedSearch);
    const dateRangeProps = useQueryDateRange(!isSavedSearch);

    //Quicksearch state
    const isQueryBuilderOpen = useAppSelector(state => state.quickSearchResultsPage.isQueryBuilderOpen);
    const isQueryHelpPanelOpen = useAppSelector(state => state.quickSearchResultsPage.isQueryHelpPanelOpen);

    
    const setIsQueryBuilderOpen = useCallback((isOpen: boolean) => {
        dispatch(isOpen ? openQueryBuilder() : closeQueryBuilder());
    }, [dispatch]);

    const setIsQueryHelpPanelOpen = useCallback((isOpen: boolean) => {
        dispatch(isOpen ? openSearchHelp() : closeSearchHelp());
    }, [dispatch]);

    const {autofocusHelpPanel, setQueryBuilderOpen, setHelpOpen} = useQueryBuilderState(setIsQueryBuilderOpen, setIsQueryHelpPanelOpen);

    const [title, link] = useMemo(() => {
        return !isSavedSearch
            ? [<div className={classes.title}>{T('searchbar.newsearch')}</div>, '/']
            : [
                  <div className={classes.savedSearchTitle}>{savedSearch.name}</div>,
                  getSearchResultsUrl(savedSearch),
              ];
    }, [classes, savedSearch, isSavedSearch]);

    // stage api only provides previous 7 days of data
    const endDateThreshold = isProduction() ? 29 : 7;

    //Calculates values
    const maxDate = new Date(dates.now());
    const minDate = new Date(dates.getPreviousDate(dates.now(), {days: endDateThreshold}));
    const dateRangeBanner = (
        <Banner variant="info" noMargin>
            <strong>{T('datepicker.disabled.bold')}</strong> {T('datepicker.disabled.historical')}
        </Banner>
    );

    useEffect(() => {
        //Clean up method, called on component unload
        return () => {
            dispatch(reset());//reset the page state
        }
    }, [dispatch]);

    //Render
    return (
        <div className={classes.container}>
            <div className={classes.searchBarContainer}>
                <Link
                    component={IconButtonStripNavigate}
                    to={link}
                    className={classes.backBtn}
                    aria-label={T('searchbar.backBtn')}
                >
                    <IconRouter name="arrow-back" className={classes.backBtnIcon} />
                </Link>
                <div className={classes.quickContainer}>
                    <div className={classes.searchBoxContainer}>
                        <Heading>{title}</Heading>
                        <div
                            className={cn(
                                classes.searchBox,
                                introAnchors.quickSearchResults.searchBox,
                            )}
                        >
                            <QueryBuilder
                                className={classes.searchBoxInner}
                                helpButtonPlacement={HELP_BUTTON_PLACEMENT.LIST}
                                open={isQueryBuilderOpen}
                                setOpen={setQueryBuilderOpen}
                                helpOpen={isQueryHelpPanelOpen}
                                setHelpOpen={setHelpOpen}
                                autofocusHelpPanel={autofocusHelpPanel}
                            />
                        </div>
                    </div>
                    <div className={classes.quickContainerRight}>
                        <DateRangeBar
                            className={introAnchors.timezone.barDatePicker}
                            banner={dateRangeBanner}
                            minDate={minDate}
                            maxDate={maxDate}
                            {...dateRangeProps}
                        />
                        <div className={classes.save}>
                            {isEmpty(savedSearch) && canSaveSearch && (
                                <SaveSearchButton children={null} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

//Internal components
const IconButtonStripNavigate = ({navigate, ...props}: any) => <IconButton {...props} size="large" />;
