import {makeStyles} from 'tss-react/mui';
import { keyframes } from '@mui/system';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export default makeStyles()((theme) => ({
    'ax-radial-progress': {
        display: 'block',
        marginRight: 'auto',
        marginLeft: 'auto',
        '& .ax-progress-infinite__arc': {
            fill: 'none',
            animation: `${spin} 1s linear infinite`,
        },
        '&. ax-progress-infinite__filter--subtle': {
            stroke: theme.v2 ? theme.colors.bgSubtle : theme.colors.textDefault,
        },
        '& .ax-progress-infinite__arc-gradient-stop--subtle': {
            stopColor: theme.v2 ? theme.colors.bgSubtle : theme.colors.textDefault,
        },

        '& .ax-progress-infinite__arc-gradient-stop--subtle:last-of-type': {
            stopOpacity: 0,
        },
        '& .ax-progress-infinite__arc-gradient-stop--white:last-of-type': {
            stopOpacity: 0,
        },
    },
    'ax-radial-progress--tiny': {
        width: '14px',
        height: '14px',
    },
    'ax-radial-progress--small': {
        width: '18px',
        height: '18px',
    },
    'ax-radial-progress--medium': {
        width: '26px',
        height: '26px',
    },
    'ax-radial-progress--large': {
        width: '40px',
        height: '40px',
    },
}));
