import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'hsi/utils/configureStore';

const selectSocialPanelsState = (state: RootState) => state.socialPanels;

export const selectSocialPanels = createSelector(
    selectSocialPanelsState,
    (state) => state.results
);

export const selectSocialPanelsLoading = createSelector(
    selectSocialPanelsState,
    (state) => state.loading
);

export const selectSocialPanelsLoaded = createSelector(
    selectSocialPanelsState,
    (state) => state.loaded
);

export const selectSocialPanelsError = createSelector(
    selectSocialPanelsState,
    (state) => state.error
);

export const selectSocialPanelsTotal = createSelector(
    selectSocialPanelsState,
    (state) => state.total
);

export const selectSocialPanelsStatus = createSelector(
    selectSocialPanelsLoading,
    selectSocialPanelsLoaded,
    selectSocialPanelsError,
    (loading, loaded, error) => ({
        socialPanelsLoading: loading,
        socialPanelsLoaded: loaded,
        socialPanelsError: error
    })
);