import React, {useState, useCallback, Fragment} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import isEmail from 'validator/lib/isEmail';
import makeStyles from '@mui/styles/makeStyles';

import {DialogActions, DialogContent} from 'hsi/components/Dialog';
import TextField from 'hsi/components/TextField';
import Select from 'hsi/components/Select';
import Button from 'hsi/components/Button';
import Banner from 'hsi/components/Banner';
import PulseLoader from 'hsi/components/PulseLoader';
import {selectImpersonationLoading} from 'hsi/selectors';
import {impersonateUser} from 'hsi/actions/userActions';

const sourceOptions = <>
    <option value="hootsuiteinsights">Hootsuite</option>
    <option value="falcon.io">Falcon</option>
    <option value="c3">C3</option>
    <option value="ngc3">NGC3</option>
    <option value="luqi">Luqi</option>
    <option value="c1">C1</option>
</>;

const useStyles = makeStyles({
    fieldWrap: {
        padding: '20px 0',
    },
    loadingContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255, 0.8)',
        zIndex: 10,
    },
});

const ImpersonationForm = () => {
    const [tab, _setTab] = useState('userId');
    const [formState, setFormState] = useState({});
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const loading = useSelector(selectImpersonationLoading);
    const classes = useStyles();

    const handleImpersonate = useCallback(() => {
        let newError = null;
        if (tab === 'userId' && !formState.brandwatchUserId) {
            newError = 'Invalid Brandwatch userId.';
        } else if (tab === 'username' && !isEmail(formState.brandwatchUsername || '')) {
            newError = 'Invalid Brandwatch username.';
        } else if (tab === 'sourceId' && (!formState.id || !formState.source)) {
            newError = 'Invalid source id or integration';
        }
        setError(newError);
        if (!newError) {
            dispatch(impersonateUser(formState));
        }
    }, [tab, formState, dispatch]);

    const setField = (field) => (ev) => {
        setFormState({...formState, [field]: ev.value || ev.target.value});
    };

    const setTab = (val) => {
        setFormState({});
        _setTab(val);
    };

    return (
        <Fragment>
            {loading && (
                <div className={classes.loadingContainer}>
                    <PulseLoader />
                </div>
            )}
            <DialogContent>
                {!!error && (
                    <Banner show variant="warning">
                        {error}
                    </Banner>
                )}
                <Tabs value={tab} onChange={(ev, tab) => setTab(tab)}>
                    <Tab label="By Brandwatch userId" value="userId" />
                    <Tab label="By Brandwatch Username" value="username" />
                    <Tab label="By source id" value="sourceId" />
                </Tabs>
                <div>
                    {tab === 'userId' && (
                        <div className={classes.fieldWrap}>
                            <label htmlFor="impersonation.userId">Brandwatch UserId</label>
                            <TextField
                                id="impersonation.userId"
                                fullWidth
                                value={formState.brandwatchUserId || ''}
                                type="number"
                                onChange={setField('brandwatchUserId')}
                                name="brandwatchUserId"
                            />
                        </div>
                    )}
                    {tab === 'username' && (
                        <div className={classes.fieldWrap}>
                            <label htmlFor="impersonation.username">Brandwatch Username</label>
                            <TextField
                                id="impersonation.username"
                                fullWidth
                                type="email"
                                value={formState.brandwatchUsername || ''}
                                onChange={setField('brandwatchUsername')}
                                name="brandwatchUsername"
                            />
                        </div>
                    )}
                    {tab === 'sourceId' && (
                        <div>
                            <div className={classes.fieldWrap}>
                                <label htmlFor="impersonation.integration">Integration</label>
                                <Select
                                    id="impersonation.integration"
                                    value={formState.source || ''}
                                    onChange={setField('source')}
                                    name="source"
                                >{sourceOptions}</Select>
                            </div>
                            <div className={classes.fieldWrap}>
                                <label htmlFor="impersonation.sourceId">Source Id</label>
                                <TextField
                                    id="impersonation.sourceId"
                                    fullWidth
                                    value={formState.id || ''}
                                    onChange={setField('id')}
                                    name="id"
                                />
                            </div>
                            <div className={classes.fieldWrap}>
                                <label htmlFor="impersonation.sourceOrg">Source Org Id (optional)</label>
                                <TextField
                                    id="impersonation.sourceOrg"
                                    fullWidth
                                    value={formState.org_id || ''}
                                    onChange={setField('org_id')}
                                    name="org_id"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button priority="cta" onClick={handleImpersonate}>
                    SUBMIT
                </Button>
            </DialogActions>
        </Fragment>
    );
};

export default ImpersonationForm;
