import {Theme} from 'hsi/types/theme';

const styles = ({font, mixin}: Theme) => ({
    root: {
        height: '60px',
        backgroundColor: '#d9f4fd',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        '& > div': {
            display: 'inline-flex',
            alignItems: 'center',
            '&:first-child > div': {
                height: '36px',
                width: '36px',
                borderRadius: '18px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '12px',
                marginLeft: '20px',
            },

            '& .refresh-link': {
                ...mixin.linkColor,
                display: 'inline-block',
                margin: '0 0 0 20px',
                padding: '0',
                border: 'none',
                background: 'transparent',
                fontWeight: font.semiBold,
                cursor: 'pointer',
                '&:hover': {textDecoration: 'underline'},
                lineHeight: '24px',
                textDecoration: 'none',
            },
            '& .divider': {
                height: '24px',
                borderRight: '1px solid #c9d1d4',
                paddingRight: '20px',
                marginRight: '20px',
            },
        },

        '& > div:last-child': {
            cursor: 'pointer',
            marginRight: '18px',
        },
    },
    blankButton: {
        padding: '0',
        margin: '0',
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
    },
});

export default styles;
