import {brandConfig} from './GuidedQueryForm/config/brandConfig';
import {issueConfig} from './GuidedQueryForm/config/issueConfig';
import {campaignConfig} from './GuidedQueryForm/config/campaignConfig';
import {personConfig} from './GuidedQueryForm/config/personConfig';
import {socialAccountConfig} from './GuidedQueryForm/config/socialAccountConfig';
import {topicConfig} from './GuidedQueryForm/config/topicConfig';

import tns from 'hsi/i18n/tns';
const t = tns('queryBuilder.guidedOptionsLabels');

export const GUIDED_OPTS = [
    {
        id: 'brand',
        label: t('brand'),
        ...brandConfig,
    },
    {
        id: 'issue',
        label: t('issue'),
        ...issueConfig,
    },
    {
        id: 'campaign',
        label: t('campaign'),
        ...campaignConfig,
    },
    {
        id: 'person',
        label: t('person'),
        ...personConfig,
    },
    {
        id: 'topic',
        label: t('topic'),
        ...topicConfig,
    },
    {id: 'socialAccount', label: t('socialAccount'), ...socialAccountConfig},
];

export const HELP_BUTTON_PLACEMENT = {
    TOP: 'top',
    LIST: 'list',
} as const;
