//TODO arrow?
//TODO portal?
import {UseFloatingOptions} from '@floating-ui/react-dom';
import useUniqueId from "hsi/hooks/useUniqueId";
import reactNodeAsElement, { mergeRefs } from "hsi/utils/react";
import { Children, FocusEvent, FocusEventHandler, MouseEvent, MouseEventHandler, PropsWithChildren, ReactElement, Ref, cloneElement, forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import Position from "../../layout/Position";
import classNames from "classnames";
import { DebouncedFuncLeading, debounce } from 'lodash';


type TooltipBubbleProps = PropsWithChildren<{
    id?: string;
    "aria-hidden"?: boolean;
    className?: string;
    onFocus?: FocusEventHandler;
    onBlur?: FocusEventHandler;
    onMouseEnter?: MouseEventHandler;
    onMouseLeave?: MouseEventHandler;
}>;

type TooltipChildProps = PropsWithChildren<{
    tabIndex?: number;
    "aria-describedby"?: string;
    onFocus?: FocusEventHandler;
    onBlur?: FocusEventHandler;
    onMouseEnter?: MouseEventHandler;
    onMouseLeave?: MouseEventHandler;
    ref?: Ref<Element>;
}>;

export type TooltipProps = PropsWithChildren<{
    id?: string;
    tooltip: ReactElement<TooltipBubbleProps>;
    mouseExitTimeout?: number | null;
    onShow?: (isVisible: boolean) => void;
}> & Pick<UseFloatingOptions, 'middleware' | 'placement'>;

/**
 * A base component to be used to create actual tooltip-like components
 */
export default forwardRef<HTMLElement, TooltipProps>(function Tooltip({
    children,
    id: idProp,
    placement = 'top', 
    middleware, 
    mouseExitTimeout = 0.5,
    tooltip: tooltipProp, 
    onShow,
}, ref) {
    const id = useUniqueId(idProp, 'tooltip');

    const [isReferenceFocused, setIsReferenceFocused] = useState(false);
    const [isTooltipFocused, setIsTooltipFocused] = useState(false);

    const [isReferenceHover, onReferenceMouseEnter, onReferenceMouseLeave] = useIsHovered(mouseExitTimeout);
    const [isTooltipHover, onTooltipMouseEnter, onTooltipMouseLeave] = useIsHovered(mouseExitTimeout);

    const isTooltipVisible = isReferenceFocused || isTooltipFocused || isReferenceHover || isTooltipHover;

    const child = useMemo(() => {
        const rawChild = reactNodeAsElement<TooltipChildProps>(Children.only(children));

        return cloneElement(rawChild, {
            "aria-describedby": id, 
            tabIndex: rawChild.props.tabIndex ?? 0,
            ref: mergeRefs(ref, (rawChild as any).ref),
            onFocus: (e: FocusEvent) => {
                rawChild.props.onFocus?.(e);
                setIsReferenceFocused(true);
            },
            onBlur: (e: FocusEvent) => {
                rawChild.props.onBlur?.(e);
                setIsReferenceFocused(false);
            },
            onMouseEnter: (e: MouseEvent) => {
                rawChild.props.onMouseEnter?.(e);
                onReferenceMouseEnter();
                
            },
            onMouseLeave: (e: MouseEvent) => {
                rawChild.props.onMouseLeave?.(e);
                onReferenceMouseLeave();
            }
        });
    }, [children, id, ref, onReferenceMouseEnter, onReferenceMouseLeave]);

    const tooltip = useMemo(() => {
        return cloneElement(
            tooltipProp, 
            {
                id, 
                "aria-hidden": !isTooltipVisible,
                className: classNames(tooltipProp.props.className, isTooltipVisible ? undefined : 'offscreen'),
                onFocus: (e: FocusEvent) => {
                    tooltipProp.props.onFocus?.(e);
                    setIsTooltipFocused(true);
                },
                onBlur: (e: FocusEvent) => {
                    tooltipProp.props.onBlur?.(e);
                    setIsTooltipFocused(false);
                },
                onMouseEnter: (e: MouseEvent) => {
                    tooltipProp.props.onMouseEnter?.(e);
                    onTooltipMouseEnter();
                },
                onMouseLeave: (e: MouseEvent) => {
                    tooltipProp.props.onMouseLeave?.(e);
                    onTooltipMouseLeave();
                }
            }
        );
    }, [tooltipProp, id, isTooltipVisible, onTooltipMouseEnter, onTooltipMouseLeave]);

    useEffect(() => {
        onShow?.(isTooltipVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTooltipVisible]);

    return <Position open placement={placement} middleware={middleware}>
        <Position.Reference>{child}</Position.Reference>
        <Position.Floating>{tooltip}</Position.Floating>
    </Position>
});


function useIsHovered(mouseExitTimeout: number | null) {
    const [isHovered, setIsHovered] = useState(false);
    
    const onMouseLeave = useMemo<() => void | DebouncedFuncLeading<() => void>>(() => {
        if(mouseExitTimeout === null) {
            return () => {
                setIsHovered(false);
            }
        }

        return debounce(() => {
            setIsHovered(false);
        }, mouseExitTimeout * 1000, {trailing: true});
    }, [mouseExitTimeout])

    const onMouseEnter = useCallback(() => {
        setIsHovered(true);
        (onMouseLeave as DebouncedFuncLeading<() => void>).cancel?.();
    }, [onMouseLeave]);

    return [isHovered, onMouseEnter, onMouseLeave] as const;
}