import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'hsi/components/Checkbox';
import DatePicker from './DatePicker';

import {T} from 'hsi/i18n';

const StartDatePicker = ({cl, setStartDateEnabled, startDateEnabled}) => (
    <>
        <div className={cl.startDatePickerContainer}>
            <Checkbox
                checked={startDateEnabled}
                className={cl.checkbox}
                onChange={(e) => setStartDateEnabled(e.target.checked)}
            >{T('reports.editReportModal.startDateCheckbox')}</Checkbox>

            <DatePicker disabled={!startDateEnabled} open={startDateEnabled} />
        </div>
    </>
);

StartDatePicker.propTypes = {
    cl: PropTypes.object.isRequired,
    setStartDateEnabled: PropTypes.func.isRequired,
    startDateEnabled: PropTypes.bool.isRequired,
};

export default StartDatePicker;
