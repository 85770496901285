import Chip from '@mui/material/Chip';

import {TargetedDataPlatform} from 'hsi/types/dataManagement';
import IconRouter from 'hsi/components/IconRouter';

import makeStyles from '@mui/styles/makeStyles';

const iconNames = {
    facebook: 'facebook-square-bw',
    instagram: 'instagram-bw',
    twitter: 'twitter-bw',
    linkedin: 'linkedin-bw',
};

const useStyles = makeStyles(({colors, v2}: any) => ({
    chipRoot: {
        margin: 3,
    },
    icon: {
        width: 16,
        height: 16,
        fill: v2 ? colors.carbon : colors.textDefault,
    },
    chipLabel: {
        paddingLeft: 8,
    },
}));

type AccountChipProps = {
    accountType: TargetedDataPlatform;
    label: string;
    onDelete: () => any;
};

const AccountChip = ({accountType, label, onDelete}: AccountChipProps) => {
    const cl = useStyles();

    return (
        <Chip
            classes={{root: cl.chipRoot, label: cl.chipLabel}}
            icon={<IconRouter name={iconNames[accountType]} className={cl.icon} />}
            label={label}
            onDelete={onDelete}
        />
    );
};

export default AccountChip;
