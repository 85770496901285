import React, {useCallback, useMemo} from 'react';

//Components
import Label from 'hsi/components/Filters/Label';
import SelectCategoryField from 'hsi/components/SelectCategoryField';
import InclusionSelect from 'hsi/components/Filters/InclusionSelect';
import InputWrapper from 'hsi/components/Filters/InputWrapper';

//Hooks
import useFiltersState from 'hsi/components/Filters/hooks/useFiltersState';

//Utils
import {toStateFormat, toDisplayFormat, stateToChips} from './helpers';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//The component
export default function CategoryFilter({compact, disabled, onChange, filterConf}) {
    const classes = useStyles();

    const {filterName, categories, label} = filterConf;

    const {
        useSelector,
        actions: {setCategoryMode, setCategoryValue, deleteCategory},
    } = useFiltersState();

    const filterState = useSelector((state) => state.filters[filterName]);

    //Callbacks
    const handleInclusionToggle = useCallback(
        (isIncludeMode) => setCategoryMode(filterName, isIncludeMode),
        [filterName, setCategoryMode],
    );

    const handleDelete = useCallback(
        (categoryId, mode) => deleteCategory(filterName, categoryId, mode),
        [deleteCategory, filterName],
    );

    const onSelect = useCallback(
        (value) => {
            onChange && onChange(filterName, 'category', value, filterState.activeModeIsInclude);

            setCategoryValue(filterName, toStateFormat(value), filterState.activeModeIsInclude);
        },
        [filterName, filterState.activeModeIsInclude, onChange, setCategoryValue],
    );

    //Memos
    const value = useMemo(
        () => toDisplayFormat(filterState[filterState.activeModeIsInclude ? 'include' : 'exclude']),
        [filterState],
    );

    const chips = useMemo(
        () => stateToChips(filterState, categories, handleDelete, filterConf),
        [categories, filterConf, filterState, handleDelete],
    );

    //Render
    return (
        <fieldset className={classes.root}>
            {!!label && (
                <Label indent component="legend">
                    {T(label)}
                </Label>
            )}
            {!filterConf.noInclusionExclusion && (
                <InclusionSelect
                    include={filterState.activeModeIsInclude}
                    onChange={handleInclusionToggle}
                    disabled={filterConf.disabled}
                    noExclusion={filterConf.noExclusion}
                />
            )}
            <InputWrapper chips={chips}>
                <SelectCategoryField //TODO field label?
                    categories={categories}
                    value={value}
                    onChange={onSelect}
                    className="searchBox"
                    withSearch
                />
            </InputWrapper>
        </fieldset>
    );
}
