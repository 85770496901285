import React, {useEffect} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {InputErrorMsg} from 'hsi/components/ErrorMessage';

import logService from 'hsi/services/logService';

import {T} from 'hsi/i18n';

const useStyles = makeStyles((theme) => ({
    errors: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    errorMessage: {
        padding: 20,
        marginTop: 10,
    },
    title: {
        fontWeight: 'bold',
        fontSize: 24,
        padding: 0,
    },
    subtitle: {
        fontSize: 18,
    },
    maxHeight: {
        maxHeight: 220,
        overflowY: 'auto',
    },
}));

const errorMap = {
    mixed_and_or: T('errorMap.mixed_and_or'),
    lowercase_operator: T('errorMap.lowercase_operator'),
    missing_left_operand: T('errorMap.missing_left_operand'),
    missing_right_operand: T('errorMap.missing_right_operand'),
    dangling_parenthesis: T('errorMap.dangling_parenthesis'),
    trailing_parenthesis: T('errorMap.trailing_parenthesis'),
    dangling_quote: T('errorMap.dangling_quote'),
    quote_in_term_error: T('errorMap.quote_in_term_error'),
};

const ValidationErrors = ({errors}) => {
    const classes = useStyles();
    const query = useSelector((state) => state?.query?.booleanQuery);

    useEffect(() => {
        if (!!errors.length) {
            const msg = `SEARCH SYNTAX ERROR: "${query}". ${errors.map((e) => e.message)}`;
            const log = async () => logService.info(msg);
            log();
        }
    }, [query, errors]);

    return (
        <div className={classes.errors} data-testid="validationErrors">
            <div className={classes.title}>{T('oops')}</div>
            <div className={classes.subtitle}>{T('there.appears.an.error')}</div>
            <div className={classes.errorMessage}>
                <InputErrorMsg
                    desc={
                        <ul>
                            {errors.map((err, i) => (
                                <li key={`${err.errorCode}-${i}`}>
                                    {errorMap[err.errorCode]
                                        ? errorMap[err.errorCode]
                                        : err.message}
                                </li>
                            ))}
                        </ul>
                    }
                    showMoreHelpInfo
                    title={
                        errors.length === 1
                            ? T('fix.error')
                            : T('fix.errors', {errors: errors.length})
                    }
                />
            </div>
        </div>
    );
};

PropTypes.ValidationErrors = {
    errors: PropTypes.array.isRequired,
};

export default ValidationErrors;
