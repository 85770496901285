import makeStyles from '@mui/styles/makeStyles';

const useComponentsBaseStyles = makeStyles((theme) => {
    if (theme.v2)
        return {
            container: {
                margin: '14px 0',
            },
            title: {
                fontWeight: theme.typgrphy.bodyStrong.fontWeight,
                fontSize: theme.typgrphy.bodyStrong.fontSize,
            },
            subtitle: {
                fontWeight: theme.typgrphy.bodyNormal.fontWeight,
                fontSize: theme.typgrphy.bodyNormal.fontSize,
                margin: '4px 0',
            },
        };
    else
        return {
            container: {
                margin: '14px 0',
            },
            title: {
                fontWeight: 'bold',
            },
            subtitle: {
                margin: '4px 0',
            },
        };
});

export default useComponentsBaseStyles;
