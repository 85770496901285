import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';

import IconRouter from 'hsi/components/IconRouter';

import {T} from 'hsi/i18n';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({colors, v2, font}) => ({
    header: {
        textTransform: v2 ? 'uppercase' : 'none',
        height: 47,
        lineHeight: '47px',
        textAlign: 'center',
        fontWeight: v2 ? 500 : font.bold,
        fontSize: v2 ? '12px' : '14px',
        borderBottom: `1px solid ${colors.borders}`,
        position: 'relative',
    },
    headerLabelWBackButton: {
        padding: '0 40px',
    },
    backButton: {
        position: 'absolute',
        left: 6,
        top: 24,
        transform: 'translate(0, -50%)',
    },
    footer: {
        borderTop: `1px solid ${colors.borders}`,
        padding: '12px 16px',
        display: 'flex',
        alignItems: 'center',
    },
    alignRight: {
        justifyContent: 'flex-end',
    },
}));

const MenuHeader = ({backLabel = T('back'), children, id, onBack}) => {
    const classes = useStyles();
    return (
        <div className={classes.header}>
            {!!onBack ? (
                <>
                    <IconButton
                        aria-label={backLabel}
                        className={classes.backButton}
                        onClick={onBack}
                        size="small"
                    >
                        <IconRouter name="chevron-left" />
                    </IconButton>
                    <div
                        aria-level="3"
                        className={classes.headerLabelWBackButton}
                        id={id}
                        role="heading"
                    >
                        {children}
                    </div>
                </>
            ) : (
                children
            )}
        </div>
    );
};

MenuHeader.propTypes = {
    backLabel: PropTypes.string,
    children: PropTypes.any.isRequired,
    id: PropTypes.string,
    onBack: PropTypes.func,
};

const MenuFooter = ({alignRight, children}) => {
    const classes = useStyles();
    return <div className={cn(classes.footer, !!alignRight && classes.alignRight)}>{children}</div>;
};

MenuFooter.propTypes = {
    alignRight: PropTypes.bool,
    children: PropTypes.any.isRequired,
};

export {MenuHeader, MenuFooter};
