import {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

//Components
import AlertDialog from 'hsi/components/AlertDialog';
import DateRangeBar from 'hsi/components/DateRangeBar';
import {AlertsButton, ManageSearchButton, TagsButton} from './buttons';
import _CreateReport from 'hsi/components/SavedSearchBar/CreateReport';
import IconRouter from 'hsi/components/IconRouter';
import {introAnchors} from 'hsi/containers/Intro';
import LinkedinSelector from './LinkedinSelector';
import ManageTagsDialog from 'hsi/components/ManageTagsDialog';
import SaveSearchDialog from 'hsi/components/SaveSearchDialog';
import OverflowTooltip from 'hsi/components/SimpleTooltip/OverflowTooltipHook';
import MultipleSearchBar from 'hsi/containers/MultipleSearchBar';

//Hooks
import useEventTrack from 'hsi/hooks/useEventTrack';
import useQueryDateRange from 'hsi/hooks/useQueryDateRange';
import useLinkedinIsEnabled from 'hsi/hooks/useLinkedinIsEnabled';
import {useLinkedinChannels} from 'hsi/hooks/useDataSource';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import useConfig from 'hsi/hooks/useConfig';
import useQueryContext from 'hsi/hooks/useQueryContext';
import {useSearchesById} from 'hsi/hooks/useSearchesById';

// Selectors
import {selectAdditionalQueries, selectLinkedinChannelIds} from 'hsi/selectors';

//Actions
import {openEditSavedSearchDialog} from 'hsi/actions/savedSearchActions';

//Other
import {isLinkedinSearch} from 'hsi/utils/dataManagement/linkedin';
import useStyles from './styles';
import {T} from 'hsi/i18n';

//Types
import {SavedSearchType} from 'hsi/types/shared';
import {DateTime} from 'luxon';
import Heading, { HeadingContents } from '../aria/Heading';

type SavedSearchBarArgs = {
    savedSearch: SavedSearchType;
};

//Consts
const CreateReport = _CreateReport as any; //TODO fix until CreateReport is converted into TS

//The component
const SavedSearchBar = ({savedSearch}: SavedSearchBarArgs) => {
    const dispatch = useAppDispatch();
    const {trackWithSearchData} = useEventTrack();
    const {classes, cx} = useStyles();
    const dateRangeProps = useQueryDateRange(false);
    const isLinkedinEnabled = useLinkedinIsEnabled();
    const linkedinChannelIds = useAppSelector(selectLinkedinChannelIds);
    const linkedinChannels = useLinkedinChannels();
    const {searchesById} = useSearchesById();
    const additionalQueries = useAppSelector(selectAdditionalQueries);
    const {projectId} = useQueryContext();
    const {minDataDate} = useConfig();

    const userIsViewOnly = useAppSelector((state) => state.user.isViewOnly);

    const availableLinkedInChannelIds = useMemo(
        () => {
            const activeLinkedinChannels = linkedinChannels.getActiveByProject(String(projectId)).map(({id}) => String(id));
            // If a linkedin channel is selected, but then made inactive, it will persist as selected in the backend (show up in saved searches etc)
            // Therefore we add selected inactive channels to enable our users to deselect an inactive linked in channel from their search.
            const selectedInactiveLinkedinChannels = linkedinChannelIds.map(id => String(id)).filter(id => !activeLinkedinChannels.includes(id));
            return [...activeLinkedinChannels, ...selectedInactiveLinkedinChannels];
        },
        [linkedinChannels, projectId, linkedinChannelIds],
    );

    //PP not sure what this is doing - is this filtering out any queries that no longer exist?
    //If so that points to... deeper issues with the code
    //CD: should this and the function below be filtered out at the redux level
    const initialAdditionalQueries = useMemo(
        () =>
            (additionalQueries || []).filter((id) => String(searchesById[id]?.project?.id) === String(projectId)
            ),
        [additionalQueries, projectId, searchesById],
    );

    //How is this different to _currentLinkInChannelIds?
    //I think because it filters out channel ids that are no longer in the project?
    //which is probably a sign of deeper issues? Also, it's strings - just to make life confusing.
    const currentLinkedInChannelIds = useMemo(
        () =>
            availableLinkedInChannelIds
                .filter((id) => linkedinChannelIds.includes(Number(id))),
        [availableLinkedInChannelIds, linkedinChannelIds],
    );

    const {monthsHistoricData, monthsRollingData} = useAppSelector(
        (state) => state.user.account.client,
    );

    const [isTagsDialogOpen, setIsTagsDialogOpen] = useState(false);
    const [isCreateAlertDialogOpen, setIsCreateAlertDialogOpen] = useState(false);

    const toggleManageTagsDialog = () => {
        if (!isTagsDialogOpen) {
            trackWithSearchData('tagDialogToggled', {
                state: !isTagsDialogOpen ? 'open' : 'closed',
            });
        }

        setIsTagsDialogOpen(!isTagsDialogOpen);
    };

    const toggleEditSearchDialog = () => {
        dispatch(openEditSavedSearchDialog(true, savedSearch.id));
    };

    const toggleCreateAlertDialog = () => {
        setIsCreateAlertDialogOpen(!isCreateAlertDialogOpen);
    };

    if (!savedSearch) {
        return <div className={classes.searchBar} />;
    }

    //dates

    const maxDate = DateTime.now();
    const minDate = DateTime.max(
        DateTime.fromISO(minDataDate), //ensure that extreme values for monthsHistoricData or monthsRollingData don't break things
        DateTime.min(
            DateTime.now().setZone('UTC').minus({months: monthsHistoricData}).startOf('month'), //timezones matters here right?
            DateTime.now().minus({months: monthsRollingData}),
        ),
    );

    return (
        <div className={classes.searchBar}>
            <Link className={classes.backBtn} aria-label={T('searchbar.backBtn')} to="/searches">
                <IconRouter aria-hidden name="arrow-back" className={classes.backBtnIcon} />
            </Link>

            <OverflowTooltip
                overflowChildSelector={`.${classes.searchLabel}`}
                tooltip={T('savedsearchbar.breadcrumbToolTip', {
                    section: T('savedsearchbar.savedsearches'),
                    name: savedSearch.name,
                })}
            >
                <Heading className={classes.breadcrumbsContainer}>
                    <span className={classes.savedSearches}>
                        {T('savedsearchbar.savedsearches')}
                    </span>
                    <IconRouter aria-hidden className={classes.arrowIcon} name="large-right-arrow" />
                    <span className="offscreen">: </span>
                    <span className={classes.searchLabel}>{savedSearch.name}</span>
                </Heading>
            </OverflowTooltip>
            <HeadingContents>
                <div className={classes.btnsContainer}>
                    {!userIsViewOnly && !isLinkedinSearch(savedSearch) && (
                        <ManageSearchButton
                            className={cx(classes.manageSearchButton, classes.fixed)}
                            onClick={toggleEditSearchDialog}
                            savedSearch={savedSearch}
                        />
                    )}

                    <MultipleSearchBar
                        initialAdditionalQueries={initialAdditionalQueries}
                        currentLinkedInChannelIds={currentLinkedInChannelIds}
                        className={classes.grow}
                    />
                    {isLinkedinEnabled && !linkedinChannels.isLoading && (
                        <LinkedinSelector
                            className={cx(introAnchors.savedSearch.addLinkedinSearch, classes.fixed)}
                            currentLinkedInChannelIds={currentLinkedInChannelIds}
                            linkedinChannels={linkedinChannels}
                            availableLinkedInChannelIds={availableLinkedInChannelIds}
                            initialAdditionalQueries={initialAdditionalQueries}
                        />
                    )}

                    {!userIsViewOnly && (
                        <TagsButton
                            className={cx(classes.tagBtn, classes.right, classes.fixed)}
                            onClick={toggleManageTagsDialog}
                        />
                    )}
                </div>
                <div className={classes.controlsContainer}>
                    <DateRangeBar
                        className={introAnchors.timezone.barDatePicker}
                        minDate={minDate}
                        maxDate={maxDate}
                        {...dateRangeProps}
                    />

                    {!userIsViewOnly && (
                        <AlertsButton
                            className={introAnchors.savedSearch.createAlert}
                            onClick={toggleCreateAlertDialog}
                        />
                    )}

                    {<CreateReport className={introAnchors.savedSearch.createReport} />}
                </div>
            </HeadingContents>

            <ManageTagsDialog open={isTagsDialogOpen} closeFunc={toggleManageTagsDialog} />

            <AlertDialog
                title={T('new.email.alert')}
                open={isCreateAlertDialogOpen}
                closeFunc={toggleCreateAlertDialog}
                savedSearch={savedSearch}
                queryId={savedSearch.id}
            />

            <SaveSearchDialog />
        </div>
    );
};

SavedSearchBar.propTypes = {
    savedSearch: PropTypes.object.isRequired,
};

export default SavedSearchBar;
