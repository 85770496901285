import React, {useCallback, useMemo, useEffect, useRef} from 'react';
import {compose} from 'redux';
import {
    useSelector as useReduxSelector,
    useDispatch as useReduxDispatch,
    useStore,
} from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';

//components
import ContextStore from 'hsi/components/ContextStore';
import CollapsibleFilterSection from 'hsi/components/Filters/CollapsibleFilterSection';

import CheckboxesFilter from 'hsi/components/Filters/CheckboxesFilter';
import IncludeAndExcludeFilter from 'hsi/components/Filters/IncludeAndExcludeFilter';
import MultiValueTextfieldFilter from 'hsi/components/Filters/MultiValueTextfieldFilter';
import IncludeOrExcludeFilter from 'hsi/components/Filters/IncludeOrExcludeFilter';
import RangeFilter from 'hsi/components/Filters/RangeFilter';
import TextfieldFilter from 'hsi/components/Filters/TextfieldFilter';
import SelectFilter from 'hsi/components/Filters/SelectFilter';
import CategoryFilter from 'hsi/components/Filters/CategoryFilter';

//Hooks
import useRefCallback from 'hsi/hooks/useRefCallback';
import filtersReducer, {
    initialState as filtersInitialState,
    useBoundActions as useBoundActionsDefault,
    initStateFromConfig,
} from 'hsi/slices/filters';
import useTempUpdateFilterOptions from 'hsi/hooks/useTempUpdateFilterOptions';
import useGetFiltersConfig from 'hsi/hooks/useGetFiltersConfig';
import useProjectId from 'hsi/hooks/useProjectId';

//Other
import useStyles from './styles';
import {T} from 'hsi/i18n';

//Constants
const typeToComponent = {
    checkboxes: CheckboxesFilter,
    includeAndExclude: IncludeAndExcludeFilter,
    multiValueTextfield: MultiValueTextfieldFilter,
    includeOrExclude: IncludeOrExcludeFilter,
    range: RangeFilter,
    textfield: TextfieldFilter,
    select: SelectFilter,
    category: CategoryFilter,
    // category: CategorySection,
    // workflow: WorkflowSection,
};

export const FiltersContext = React.createContext(null);
FiltersContext.displayName = 'FiltersContext';

//The component
export default function Filters({
    children,
    autoScrollDrawer,
    canSaveSearch,
    isSavedSearch,
    className,
    getSectionTestIds = null, //optional testids
    compact = false,
    //filter state handling props
    useSelector,
    dispatch,
    getState,
    subscribe,
    useBoundActions = useBoundActionsDefault,
    //optional onChange type callbacks
    onChange,
    onSectionCollapsedChange = null,
    onFilterChange = null,
    isAlertEdit,
}) {
    const classes = useStyles();

    const projectId = useProjectId();
    //state management
    const actions = useBoundActions(dispatch, getState); //TODO different actions for different global

    const contextState = useMemo(() => ({useSelector, actions}), [useSelector, actions]);

    //Calculated values
    const filterProps = useMemo(
        () => ({
            isSavedSearch,
            autoScrollDrawer,
            compact,
        }),
        [compact, autoScrollDrawer, isSavedSearch],
    );

    //Begin onChange handling
    const lastUpdateRef = useRef();

    if (!lastUpdateRef.current) {
        lastUpdateRef.current = getState();
    }

    const _onChange = useRefCallback(() => {
        const newState = getState();
        const lastState = lastUpdateRef.current;

        //Record new state;
        lastUpdateRef.current = newState;

        if (onChange) {
            //Only trigger onChange if the filters themselves have changed
            if (
                newState.filters !== lastState?.filters ||
                newState.collapsedSections !== lastState.collapsedSections
            ) {
                //Do not trigger an update from filters initialising
                if (!isEmpty(lastState?.filters) && !isEmpty(lastState?.collapsedSections)) {
                    onChange(newState);
                }
            }
        }
    });

    useEffect(() => {
        const unsubscribe = subscribe(_onChange);

        return () => {
            unsubscribe();
        };
    }, [_onChange, subscribe]);
    //end onChange handling

    // Ugly fix for streams
    //TODO: clean up
    const selectorFiltersConfig = useSelector((state) => state.config);
    const {config: stateFiltersConfig} = useGetFiltersConfig(
        isSavedSearch ? 'saved' : 'quick',
        projectId,
        isAlertEdit,
    );
    const filtersConfig = isAlertEdit ? selectorFiltersConfig : stateFiltersConfig;

    //should have access to correct pagetypes here, so can automatically monitor and update them as required
    useTempUpdateFilterOptions(dispatch);

    const content = useMemo(() => {
        return filtersConfig?.map(({sectionName, label, initOpened, filters, hidden}) => {
            filters = filters.filter(
                ({shouldRender}) => !shouldRender || shouldRender({canSaveSearch}),
            );

            if (filters.length === 0 || hidden) {
                return null;
            }

            return (
                <CollapsibleFilterSection
                    key={sectionName}
                    label={label ? T(label) : T('filters.type.' + sectionName)}
                    name={sectionName}
                    compact={compact}
                    onCollapsedChange={
                        onSectionCollapsedChange
                            ? (collapsed) => onSectionCollapsedChange(sectionName, collapsed)
                            : null
                    }
                    labelProps={
                        getSectionTestIds ? {'data-testid': getSectionTestIds(sectionName)} : null
                    }
                >
                    {filters.map((filter) => {
                        const Component = typeToComponent[filter.type];
                        return Component ? (
                            <Component
                                key={filter.filterName}
                                {...filterProps}
                                onChange={onFilterChange}
                                filterConf={filter}
                            />
                        ) : (
                            <div key={filter.filterName}>
                                {filter.filterName} ({filter.type})
                            </div>
                        );
                    })}
                </CollapsibleFilterSection>
            );
        });
    }, [
        canSaveSearch,
        compact,
        filtersConfig,
        filterProps,
        getSectionTestIds,
        onFilterChange,
        onSectionCollapsedChange,
    ]);

    //Render
    return (
        <FiltersContext.Provider value={contextState}>
            {children}
            <ul className={cn(classes.filtersWrapper, compact && classes.compact, className)}>
                {content}
            </ul>
        </FiltersContext.Provider>
    );
}

//Internal components

// -Helper method to use filters with their own local store
const LocalFilterStateContext = React.createContext();
LocalFilterStateContext.displayName = 'LocalFilterStateContext';

Filters.LocalState = function LocalState({filtersConfig, ...props}) {
    const initialState = useMemo(
        () =>
            initStateFromConfig({
                ...cloneDeep(filtersInitialState),
                config: filtersConfig,
            }),
        [filtersConfig],
    );

    return (
        <ContextStore
            reducer={filtersReducer}
            initialState={initialState}
            context={LocalFilterStateContext}
        >
            {({useSelector, dispatch, getState, subscribe}) => (
                <Filters
                    {...props}
                    useSelector={useSelector}
                    dispatch={dispatch}
                    getState={getState}
                    subscribe={subscribe}
                />
            )}
        </ContextStore>
    );
};

// -Helper method to use filters component with global redux store
Filters.GlobalState = function GlobalState({mapSelector = defaultMapSelector, ...props}) {
    const dispatch = useReduxDispatch();
    const store = useStore();
    const useSelector = useCallback(
        // eslint-disable-next-line react-hooks/rules-of-hooks
        (selector) => useReduxSelector(compose(selector, mapSelector)),
        [mapSelector],
    );

    const getState = useCallback(() => mapSelector(store.getState()), [mapSelector, store]);

    return (
        <Filters
            {...props}
            useSelector={useSelector}
            dispatch={dispatch}
            getState={getState}
            subscribe={store.subscribe}
        />
    );
};

const defaultMapSelector = (state) => state.filters;
