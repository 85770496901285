
import isEmpty from 'lodash/isEmpty';

//Components
import IconRouter from 'hsi/components/IconRouter';

//Hooks
import { useAppDispatch, useAppSelector } from 'hsi/hooks/useRedux';

//Actions
import {reset as resetFilters} from 'hsi/actions/filtersActions';

//Other
import {selectLinkedinChannelIds} from 'hsi/selectors';
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Types
export type NoResultsMsgProps = {
    isSavedSearch: boolean;
    checkSearchHasData: () => void;
};


//The component
export default function NoResultsMsg({isSavedSearch, checkSearchHasData}: NoResultsMsgProps) {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const {noFiltersApplied, filters} = useAppSelector((state) => state.filters);
    const hasDataCheckStatus = useAppSelector((state) => state.searchResults.hasDataCheckStatus);

    const isCheckHasDataError = hasDataCheckStatus === 'error';

    const linkedinChannelIds = useAppSelector(selectLinkedinChannelIds);
    const hasLinkedinFilter = !!(filters?.pageType as any)?.linkedin;
    const showLinkedinWarning = !isCheckHasDataError && hasLinkedinFilter && isEmpty(linkedinChannelIds);

    return (
        <div className={classes.root} role="status" aria-live="polite">
            {isSavedSearch && (
                <IconRouter aria-hidden name="filter" className={classes.icon} />
            )}

            <div className={classes.title}>{isCheckHasDataError ? T('noSearchResults.checkDataErrorTitle') : T('noSearchResults.noDataTitle')}</div>

            {isCheckHasDataError 
                ? <div className={classes.subtitle}>
                    <span>{T('noSearchResults.checkingHasDataError')}</span>
                    <button
                        className={classes.link}
                        onClick={() => {checkSearchHasData();}}
                    >
                        {T('noSearchResults.retryCheckingHasData')}
                    </button>
                </div>
                : showLinkedinWarning ? (
                    <div className={classes.subtitle}>
                        <span>{T('noSearchResults.linkedinWarning')}</span>
                        <button className={classes.link} onClick={() => dispatch((resetFilters as any)({reset: true}))}>
                            {T('noSearchResults.clearFilters')}
                        </button>
                    </div>
                ) : (
                    <div className={classes.subtitle}>
                        <span>
                            {T(
                                'noSearchResults.subtitle' +
                                    (isSavedSearch ? 'Saved' : 'Quick'),
                            )}
                        </span>

                        {!noFiltersApplied && (
                            <span>
                                <span>
                                    {T(
                                        'noSearchResults.try' +
                                            (isSavedSearch ? 'Saved' : 'Quick'),
                                    )}
                                </span>
                                <button
                                    className={classes.link}
                                    onClick={() => dispatch((resetFilters as any)({reset: true}))}
                                >
                                    {T('noSearchResults.clearFilters')}
                                </button>
                            </span>
                        )}
                    </div>
            )}
        </div>
    );
}
