import {useState, useRef, useCallback} from 'react';

//TODO something is wrong with this...
export default function useStateRef<T = any>(initialValue: T) {
    const [value, _setValue] = useState<T>(initialValue);
    const valueRef = useRef(value);

    const setValue = useCallback((value: T | {(value?: T): T}) => {
        if (value instanceof Function) {
            valueRef.current = value(valueRef.current);
            _setValue(() => valueRef.current);
            return valueRef.current;
        } else {
            valueRef.current = value;
            _setValue(value);
            return value;
        }
    }, []);

    return [valueRef, setValue] as const;
}
