import baseConfig from 'hsi/configurations/_base/config';
//import { AppConfigType } from 'hsi/types/config';
//import {Mutable} from 'hsi/types/shared';
import {AuthenticationDataTypes, TargetedDataDataTypes} from 'hsi/types/dataManagement';

import iconsConfig from 'hsi/icons/config';

//const baseConfig = _baseConfig as unknown as Mutable<typeof _baseConfig>;

const config = {
    ...baseConfig,

    //Path to SVG file generated by SvgStore plugin
    svgPath: iconsConfig.axiomy.fileName,

    //Used by IconRouter to map icon name to SVG file
    iconNamesMap: {
        export: 'download',
        'hsi-arrow-down': 'triangle-down',
        'hsi-arrow-right': 'triangle-right',
        'hsi-double-arrow-left': 'double-chevron-left',
        'hsi-double-arrow-right': 'double-chevron-right',
        close: 'cross',
        search: 'magnify-glass',
        'settings-gear-64': 'settings',
        'mui-close': 'cross',
        'e-remove': 'cross',
        launch: 'open',
        'mui-launch': 'open',
        'mui-keyboard-arrow-down': 'chevron-down',
        'down-arrow': 'chevron-down',
        'arrow-back': 'arrow-left',
        'cloud-download': 'download',
        earth: 'globe',
        pin: 'location',
        'arrow-drop-up': 'triangle-up',
        'arrow-drop-down': 'triangle-down',
        'mui-more-horiz': 'ellipsis',
        'more-horizontal': 'ellipsis',
        success: 'tick',
        'f-check': 'tick',
        'c-question': 'question-mark-circle-fill',
        info: 'help-circle',
        'emoticon-neutral': 'face-neutral',
        'emoticon-positive': 'face-happy',
        'emoticon-negative': 'face-sad',
        'tag-add': 'add-tag',
        twitter: 'twitter-source-icon',
        twitter48: 'twitter-source-icon',
        'x-twitter': 'x-twitter-source-icon',
        'x-twitter48': 'x-twitter-source-icon',
        facebook: 'facebook-source-icon',
        facebook48: 'facebook-source-icon',
        'logo-facebook': 'facebook-source-icon',
        'logo-twitter': 'x-twitter-source-icon',
        instagram: 'instagram-source-icon',
        instagram48: 'instagram-source-icon',
        'logo-instagram': 'instagram-source-icon',
        tumblr48: 'tumblr-source-icon',
        forums48: 'forum-source-icon',
        youtube48: 'youtube-source-icon',
        reddit48: 'reddit-source-icon',
        news48: 'news-source-icon',
        blogs48: 'blog-source-icon',
        reviews48: 'review-source-icon',
        qq48: 'tencent-qq-icon',
        delete: 'trash',
        mention: 'speech',
        'a-chat': 'speech',
        'mui-warning': 'warning-circle-fill',
        warning: 'warning-circle-fill',
        'c-warning': 'warning-circle-fill',
        'left-arrow': 'chevron-left',
        'right-arrow': 'chevron-right',
        'large-right-arrow': 'chevron-right',
        'mui-remove-red-eye': 'preview',
        'c-add-2': 'plus-circle',
        'c-delete-2': 'minus-circle',
        'c-question-e': 'mui-help-outline',
        'c-info': 'information-circle-fill',
        'c-info-e': 'information-circle',
        'mui-add-circle': 'plus-circle',
        'small-triangle-up': 'chevron-up',
        'small-triangle-down': 'chevron-down',
        pin2: 'question-mark-circle',
        hat: 'mui-school',
        'trend-up': 'arrow-up-right',
        'trend-down': 'arrow-down-right',
        mentions: 'annotate',
        'f-add': 'add',
        'mui-warning-rounded': 'c-warning-e',
        'mui-equalizer': 'mui-assessment',
    },
    themeColors: {
        ...baseConfig.themeColors,
        colorScaleDefault: '#F0F0EF',
        primaryColor: '#0087CA',
        topicType: {
            hashtag: '#4CC1D4',
            place: '#895CA2',
            organisation: '#FD8126',
            person: '#D3CD0B',
            word: '#393A3A',
            phrase: '#8C4019',
        },
        gender: {male: '#3B79CC', female: '#A72E5F', unknown: '#878787'},
        heatmap: ['#D3F1FF', '#94D2F1', '#63C9FB', '#239EDB'],
        sentiment: {positive: '#4BA15A', negative: '#D74A4A', neutral: '#BBB9C0'},
        volume: {low: '#C2C2C2', mid: '#5ACBDC', high: '#3B74B7'},
        trending: {low: '#C2C2C2', mid: '#F9B800', high: '#FF634A'},
        random: [
            '#f598b7',
            '#e35a7a',
            '#906ba7',
            '#90a015',
            '#55cee6',
            '#24adbd',
            '#c8c819',
            '#8ac539',
            '#ffbe0a',
            '#9E6139',
            '#78909C',
        ],
        none: ['#393A3A'],
        default: ['#d3f1ff', '#94d2f1', '#0087ca', '#63c9fb', '#239edb'],
        emotion: {
            anger: '#e35a7a',
            disgust: '#906ba7',
            fear: '#f99131',
            joy: '#ffbe0b',
            sadness: '#6163a1',
            surprise: '#8ac539',
        },
        pageTypes: {
            twitter: '#1DA1F2',
            facebook: '#1877F2',
            facebook_public: '#1B50F2',
            instagram: '#B44F92',
            tumblr: '#35465D',
            forum: '#FFBE0A',
            youtube: '#DE0000',
            reddit: '#FF4500',
            news: '#9E6139',
            blog: '#55CDE6',
            review: '#2F9170', //TODO test
            qq: '#78909C',
            linkedin: '#E35A7A',
        },
        sparkline: '#0087ca',
        success: '#00BE96',
        error: '#FC4870',
        warning: '#DAAE45',
        choroplethMapColors: {
            render: ['#D3F1FF', '#94D2F1', '#63C9FB', '#239EDB'],
            hover: '#F99132',
            pressed: '#F99132',
        },
        heatmapColors: ['#D3F1FF', '#94D2F1', '#63C9FB', '#239EDB'],
    },
    editorConfig: {
        padding: {
            top: 10,
            bottom: 8,
        },
        fontSize: 13,
        lineHeight: 20,
        suggestFontSize: 13,
        suggestLineHeight: 20,
        fontFamily: "'Roboto', sans-serif",
    },
    lineChart: {
        leftPadding: -15,
        hasSideLabel: false,
        tickLine: true,
        axisLine: true,
        tickMargin: 12,
    },
    dataManagement: {
        ...baseConfig.dataManagement,
        tabs: [
            {
                id: TargetedDataDataTypes.FacebookPage,
                info: 'dataManagement.info.facebookPages',
                label: 'dataManagement.tabs.facebookPages',
                logo: 'logo-facebook',
                title: 'dataManagement.tabs.facebookPages',
                url: '/data-management/facebook-pages',
            },
            {
                id: TargetedDataDataTypes.InstagramAccount,
                info: 'dataManagement.info.instagramAccounts',
                label: 'dataManagement.tabs.instagramAccounts',
                logo: 'logo-instagram',
                title: 'dataManagement.tabs.instagramAccounts',
                url: '/data-management/instagram-accounts',
            },
            {
                id: TargetedDataDataTypes.InstagramHashtag,
                info: 'dataManagement.info.instagramHashtags',
                label: 'dataManagement.tabs.instagramHashtags',
                logo: 'logo-instagram',
                title: 'dataManagement.tabs.instagramHashtags',
                url: '/data-management/instagram-hashtags',
            },
            {
                id: TargetedDataDataTypes.LinkedinChannel,
                info: 'dataManagement.info.linkedinPages',
                label: 'dataManagement.tabs.linkedinPages',
                logo: 'linkedin',
                title: 'dataManagement.tabs.linkedinPages',
                url: '/data-management/linkedin-pages',
            },
            {
                id: AuthenticationDataTypes.FacebookUserAuthentication,
                info: 'dataManagement.info.whosConnected',
                label: 'dataManagement.alternateLabel.whosConnected',
                title: 'dataManagement.tabs.whosConnected',
                url: '/data-management/whos-connected',
            },
            {
                id: AuthenticationDataTypes.LinkedinChannelAuthentication,
                label: 'dataManagement.tabs.linkedinAuthentications',
                title: 'dataManagement.tabs.linkedinAuthentications',
                url: '/data-management/linkedin-authentications',
            },
        ],
    },
} as const;

export default config;
