import React, {useMemo} from 'react';
import isObject from 'lodash/isObject';
import cn from 'classnames';

//Utils
import {isFilterStateEmpty} from 'hsi/slices/filters/utils';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//The component
export default function Summary({config, filters}) {
    const classes = useStyles();

    const filterConfigs = useMemo(() => {
        const filterConfigs = [];

        config.forEach(({filters: filtersConfig, hidden}) => {
            !hidden &&
                filtersConfig.forEach((filterConfig) => {
                    filterConfigs.push(filterConfig);
                });
        });

        return filterConfigs;
    }, [config]);

    return (
        <dl className={classes.list}>
            {filterConfigs
                .filter(
                    (filterConfig) =>
                        !isFilterStateEmpty(filterConfig, filters[filterConfig.filterName], true),
                )
                .map((filterConfig) =>
                    renderFilterSummaryByType[filterConfig.type](
                        classes,
                        filterConfig,
                        filters[filterConfig.filterName],
                    ),
                )}
        </dl>
    );
}

const renderFilterSummaryByType = {
    checkboxes: (classes, filterConfig, filterState) => {
        return (
            <div className={classes.filter} key={filterConfig.filterName}>
                <dt className={classes.filterName}>
                    {formatFilterKey(filterConfig.filterName, T)}:
                </dt>
                {filterConfig.options
                    .filter((option) => {
                        const value = isObject(option) ? option.value : option;
                        return !!filterState[value];
                    })
                    .map((option) => (
                        <dd
                            key={isObject(option) ? option.value : option}
                            className={classes.filterValue}
                        >
                            {T(isObject(option) ? option.label : option)}
                        </dd>
                    ))}
            </div>
        );
    },

    includeAndExclude: (classes, filterConfig, filterState) =>
        filterConfig.fields
            .filter((fieldConfig) => !fieldConfig.hidden)
            .map((fieldConfig) => {
                const fieldState = filterState.fields[fieldConfig.fieldName];

                return (
                    <React.Fragment key={fieldConfig.fieldName}>
                        {fieldState.include.length > 0 && (
                            <div className={classes.filter}>
                                <dt className={classes.filterName}>
                                    {formatFilterKey(fieldConfig.fieldName, T)}:
                                </dt>
                                {fieldState.include.map((value) => (
                                    <dd
                                        key={
                                            fieldConfig.getLabel
                                                ? fieldConfig.getLabel(value)
                                                : value
                                        }
                                        className={classes.filterValue}
                                    >
                                        {fieldConfig.getLabel ? fieldConfig.getLabel(value) : value}
                                    </dd>
                                ))}
                            </div>
                        )}

                        {fieldState.exclude.length > 0 && (
                            <div className={classes.filter}>
                                <dt className={classes.filterName}>
                                    {T('filters.exclude', {
                                        name: formatFilterKey(fieldConfig.fieldName, T),
                                    })}
                                    :
                                </dt>
                                {fieldState.exclude.map((value) => (
                                    <dd
                                        key={
                                            fieldConfig.getLabel
                                                ? fieldConfig.getLabel(value)
                                                : value
                                        }
                                        className={classes.filterValue}
                                    >
                                        {fieldConfig.getLabel ? fieldConfig.getLabel(value) : value}
                                    </dd>
                                ))}
                            </div>
                        )}
                    </React.Fragment>
                );
            }),

    includeOrExclude: (classes, filterConfig, filterState) => (
        <div className={classes.filter} key={filterConfig.filterName}>
            <dt className={classes.filterName}>
                {filterState.activeModeIsInclude
                    ? formatFilterKey(filterConfig.filterName, T)
                    : T('filters.exclude', {name: formatFilterKey(filterConfig.filterName, T)})}
                :
            </dt>
            {filterState.values.map((value) => (
                <dd
                    key={filterConfig.getLabel ? filterConfig.getLabel(value) : value}
                    className={cn(
                        classes.filterValue,
                        filterConfig.filterName === 'location' && classes.semicolon,
                    )}
                >
                    {filterConfig.getLabel ? filterConfig.getLabel(value) : value}
                </dd>
            ))}
        </div>
    ),

    multiValueTextfield: (classes, filterConfig, filterState) => (
        <div className={classes.filter} key={filterConfig.filterName}>
            <dt className={classes.filterName}>{formatFilterKey(filterConfig.filterName, T)}:</dt>
            {filterState.map((value) => (
                <dd key={value} className={classes.filterValue}>
                    {value}
                </dd>
            ))}
        </div>
    ),

    range: (classes, filterConfig, filterState) => (
        <div className={classes.filter} key={filterConfig.filterName}>
            <dt className={classes.filterName}>{formatFilterKey(filterConfig.filterName, T)}:</dt>
            <dd className={classes.filterValue}>
                {filterState.min !== null && filterState.max != null
                    ? T('filters.summary.rangeBetween', filterState)
                    : filterState.min !== null
                    ? T('filters.summary.rangeMin', filterState)
                    : T('filters.summary.rangeMax', filterState)}
            </dd>
        </div>
    ),

    textfield: (classes, filterConfig, filterState) => (
        <div className={classes.filter} key={filterConfig.filterName}>
            <dt className={classes.filterName}>{formatFilterKey(filterConfig.filterName, T)}:</dt>
            <dd className={classes.filterValue}>{filterState}</dd>
        </div>
    ),
};

export function formatFilterKey(name, T) {
    return T(`filters.type.${name}`) || name;
}
