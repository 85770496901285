import {CCFlagsType} from 'hsi/types/cc-flags';
import {PageTypeDefinitionType} from 'hsi/types/shared';

function checkLDFlag(flags: CCFlagsType | undefined, flag: keyof CCFlagsType) {
    return !flags || !!flags[flag];
}

const pageTypes: PageTypeDefinitionType[] = [
    {
        value: 'twitter',
        iconName: 'x-twitter',
        isAvailable: (_, flags) => checkLDFlag(flags, 'twitterDataSourceEnabled'),
    },
    {
        value: 'facebook',
        isAvailable: ({hasFacebookPages}) => hasFacebookPages ?? true,
        iconName: 'facebook',
    },
    {
        value: 'facebook_public',
        iconName: 'facebook',
    },
    {
        value: 'instagram',
        isAvailable: ({hasInstagramAccounts}) => hasInstagramAccounts ?? true,
        iconName: 'instagram',
    },
    {
        value: 'tumblr',
        iconName: 'tumblr',
    },
    {
        value: 'forum',
        iconName: 'forums',
    },
    {
        value: 'youtube',
        iconName: 'youtube',
    },
    {
        value: 'reddit',
        iconName: 'reddit',
    },
    {
        value: 'news',
        iconName: 'news',
    },
    {
        value: 'blog',
        iconName: 'blogs',
    },
    {
        value: 'review',
        iconName: 'reviews',
    },
    {
        value: 'qq',
        iconName: 'qq',
    },
    {
        value: 'linkedin',
        iconName: 'linkedin',
        isAvailable: (targetedDataAvailability, flags, isSavedSearch, projectId) =>
            // TODO: isSavedSearch is not guarenteed to be up to date so we can't use this check here
            // For the filter checkboxes it's actually overridden here: useTempUpdateFilterOptions
            // (!projectId || !!isSavedSearch) &&
            targetedDataAvailability?.hasLinkedinChannels ?? true,
    },
];

export default pageTypes;
