import React, {Fragment, useState, memo} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {PieChart as RechartsPieChart, Pie, Cell, Legend, Customized} from 'recharts';

//Components
import ChartTooltip from 'hsi/components/ChartTooltip';
import ChartTooltipContent from 'hsi/components/ChartTooltipContent';

//Utils
import {formatBigInt, formatPercentWithLowerBound} from 'hsi/utils/formatNumber';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//The component
function PieChart({
    data,
    width,
    height,
    showLegend = true,
    formatLegend = undefined,
    onClick = null,
}) {
    const classes = useStyles();

    const [tooltip, setTooltip] = useState(null);

    if (!width || !height) return <span />;

    return (
        <RechartsPieChart
            width={width}
            height={height}
            margin={{bottom: 0}}
            onMouseLeave={() => setTooltip(null)}
            className={cn(classes.pieChart, showLegend && classes.withLegend)}
        >
            <Pie
                data={data}
                cx="50%"
                cy="50%"
                dataKey="value"
                innerRadius="70%"
                outerRadius="100%"
                minAngle={2}
                isAnimationActive={false}
                onMouseEnter={(ev) =>
                    setTooltip({
                        percent: ev.percent,
                        value: ev.value,
                        data: ev.payload,
                        pos: ev.tooltipPosition,
                    })
                }
                onClick={(ev) => onClick?.(ev.payload)}
            >
                {data &&
                    data.map((piece) => (
                        <Cell key={piece.id} fill={piece.color} className="hello" />
                    ))}
            </Pie>
            {showLegend && (
                <Legend
                    align="center"
                    verticalAlign="bottom"
                    height={40}
                    iconType="circle"
                    iconSize={12}
                    margin={{top: 40}}
                    formatter={formatLegend}
                />
            )}
            {!!tooltip && (
                <Customized
                    key="tooltip"
                    component={
                        <Fragment>
                            <ChartTooltip
                                {...tooltip.pos}
                                open={true}
                                showArrow={false}
                                animationMs={200}
                                distance={0}
                                onHide={() => setTooltip(null)}
                            >
                                <ChartTooltipContent
                                    title={tooltip.data.name}
                                    valueItems={[
                                        {
                                            amount: formatPercentWithLowerBound(tooltip?.percent),
                                            color: tooltip.data.fill,
                                            unit: `(${formatBigInt(tooltip?.value)} ${T(
                                                'mentions',
                                            )})`,
                                        },
                                    ]}
                                />
                            </ChartTooltip>
                        </Fragment>
                    }
                />
            )}
        </RechartsPieChart>
    );
}

PieChart.propStypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.number,
            id: PropTypes.string,
            color: PropTypes.string,
        }),
    ),
    showLegend: PropTypes.bool,
    formatLegend: PropTypes.func,
};

export default memo(PieChart);
