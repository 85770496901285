import {
    AnyAction,
    ThunkDispatch,
    createAsyncThunk,
    isFulfilled,
    isRejectedWithValue,
} from '@reduxjs/toolkit';

//Redux
import {showNotification} from 'hsi/actions/notificationsActions';
import {loadTags} from 'hsi/actions/tagActions';
import {RootReducer} from 'hsi/reducers/rootReducer';

//Types
import {AllFilteringState, LocationDefinition} from 'hsi/types/filters';
import {
    MentionAPIIdentifier,
    MentionType,
    QuickSearchMentionApiType,
    SavedSearchMentionApiType,
    isSavedSearchMention,
} from 'hsi/types/mentions';
import {QueryContextType} from 'hsi/types/query';
import {EmotionsType, SentimentType} from 'hsi/types/shared';

import {addDrillInToFiltersState} from 'hsi/utils/filters';
import {T} from 'hsi/i18n';

//Misc
import {selectAdditionalQueries, selectLinkedinChannelIds} from 'hsi/selectors';
import {
    loadMentionsCursoredPagination as loadMentionsCursoredPaginationApi,
    loadQuickSearchMentions as loadQuickSearchMentionsApi,
    loadSavedSearchMentions as loadSavedSearchMentionsApi,
    patchMentions as patchMentionsApi,
    deleteMentions as deleteMentionsApi,
    SavedSearchApiResults,
    PatchMentionData,
    APIEmotionType,
} from 'hsi/services/mentionsService';
import {cleanObject} from 'hsi/utils/url';
import getEmotionsFromMention from 'hsi/utils/mentions/getEmotionsFromMention';
import {EMOTIONS_CATEGORIES, MAX_SELECTED_MENTIONS} from 'hsi/constants/config';
import {ucfirst} from 'hsi/utils/misc';
import {EditMentionType, MentionOrderByQuicksearch, MentionOrderBySavedsearch} from '.';
import awaitforAtLeast from 'hsi/utils/func/awaitForAtLeast';
import {RootState} from 'hsi/utils/configureStore';
import {normaliseAPIError} from 'hsi/utils/normaliseApiError';

//The thunks

export type SetMentionSelectedPayload = {id: string; selected: boolean};
export type SetMentionSelectedFulfilledPayload = {
    newNumSelected: number;
    maxSelectedMentions: number;
} & SetMentionSelectedPayload;

/**
 * I didn't want to have to build this as a thunk, but putting the validation logic and the notification
 * in required it.
 */
export const setMentionSelected = createAsyncThunk<
    SetMentionSelectedFulfilledPayload,
    SetMentionSelectedPayload,
    {state: RootReducer; rejectValue: void}
>('mentions/setMentionSelected', ({id, selected}, {getState, rejectWithValue}) => {
    const {mentions} = getState();

    if (selected) {
        //Is this a mention that is not currently selected, does it exist, and have we not yet reached the max number of selected mentions?
        if (
            !mentions.selectedMentions[id] &&
            mentions.results.some((mention) => mention.id === id) &&
            mentions.numSelectedMentions < MAX_SELECTED_MENTIONS
        ) {
            return {
                id,
                selected,
                newNumSelected: mentions.numSelectedMentions + 1,
                maxSelectedMentions: MAX_SELECTED_MENTIONS,
            };
        }
    } else {
        //is this a mention that is currently selected?
        if (mentions.selectedMentions[id]) {
            return {
                id,
                selected,
                newNumSelected: mentions.numSelectedMentions - 1,
                maxSelectedMentions: MAX_SELECTED_MENTIONS,
            };
        }
    }

    return rejectWithValue(); //nothing has changed, reject
});

export type MentionsLoadArg = {queryContext: QueryContextType; append?: boolean};
export type MentionsLoadFulfilledPayload = {
    results: (QuickSearchMentionApiType | SavedSearchMentionApiType)[];
    page: number;
    cursor: string | undefined;
    hasMore: boolean;
};
export type MentionsLoadRejectedPayload = {errorCode: string | undefined; showError: boolean};
export type MentionsLoadThunkApi = {state: RootReducer; rejectValue: MentionsLoadRejectedPayload};

export const loadMentions = createAsyncThunk<
    MentionsLoadFulfilledPayload,
    MentionsLoadArg,
    MentionsLoadThunkApi
>('mentions/loadMentions', async ({queryContext, append = false}, {getState, rejectWithValue}) => {
    const state = getState();
    const {mentions} = state;
    const additionalQueries = selectAdditionalQueries(state);
    const linkedinChannelIds = selectLinkedinChannelIds(state);

    const filters = addDrillInToFiltersState(
        state.filters as unknown as AllFilteringState, //TODO remove the cast once filters slice is converted to TS
        mentions.drillInFilter,
        mentions.drillInDates,
    );

    const page = append ? mentions.page + 1 : 0;

    //if (!mentions.isOpen) return; //TODO this seems potentially dangerous - do we need it?

    try {
        const result = await (queryContext.isSavedSearch
            ? loadSavedSearchMentionsApi({
                  queryContext,
                  filters,
                  orderBy: mentions.orderBy as MentionOrderBySavedsearch,
                  orderIsAsc: mentions.orderIsAsc,
                  resultsPage: page,
                  additionalQueries,
                  linkedinChannelIds,
                  format: undefined,
              })
            : loadQuickSearchMentionsApi({
                  queryContext,
                  filters,
                  orderBy: mentions.orderBy as MentionOrderByQuicksearch,
                  orderIsAsc: mentions.orderIsAsc,
                  resultsPage: page,
              }));

        if (result.status === 200) {
            const data = result.body;
            const results = data.results || [];

            const hasMore = results.length < data.resultsTotal;

            return {results, page, cursor: (data as SavedSearchApiResults).nextCursor, hasMore};
        }

        return rejectWithValue({showError: false, errorCode: result.status.toString()});
    } catch (e: any) {
        console.error('load mentions error', e);

        return rejectWithValue({
            showError: !(e instanceof DOMException),
            errorCode: e?.body?.errors?.[0]?.code,
        });
    }
});

export const loadMentionsCursoredPagination = createAsyncThunk<
    MentionsLoadFulfilledPayload,
    MentionsLoadArg & {pageSize?: number},
    MentionsLoadThunkApi
>(
    'mentions/loadMentionsCursoredPagination',
    async ({queryContext, append = false, pageSize}, {getState, rejectWithValue}) => {
        if (queryContext.searchType === 'quick') {
            throw new Error('Quick search does not support cursored pagination');
        }

        const state = getState();
        const {mentions} = state;
        const additionalQueries = selectAdditionalQueries(state);
        const linkedinChannelIds = selectLinkedinChannelIds(state);

        const filters = addDrillInToFiltersState(
            state.filters as unknown as AllFilteringState, //TODO remove the cast once filters slice is converted to TS
            mentions.drillInFilter,
            mentions.drillInDates,
        );

        const page = append ? mentions.page + 1 : 0;

        try {
            const result = await loadMentionsCursoredPaginationApi({
                queryContext,
                filters,
                orderBy: mentions.orderBy as MentionOrderBySavedsearch,
                orderIsAsc: mentions.orderIsAsc,
                cursor: append ? mentions.cursor : undefined,
                additionalQueries,
                linkedinChannelIds,
                pageSize,
            });

            if (result.status === 200) {
                const data = result.body;
                const results = data.results || [];
                const hasMore = results.length < data.resultsTotal && !!data.nextCursor;

                return {results, page, cursor: data.nextCursor as string, hasMore};
            }

            return rejectWithValue({showError: false, errorCode: result.status.toString()});
        } catch (e: any) {
            console.error('load cursored mentions error', e);

            return rejectWithValue({
                showError: !(e instanceof DOMException),
                errorCode: e?.body?.errors?.[0]?.code,
            });
        }
    },
);

type MentionAPIError = {
    httpCode: number | undefined;
    errors: {
        code: number | undefined;
        message: string;
    }[];
    message: string;
};

export const patchMentions = createAsyncThunk<
    SavedSearchMentionApiType[] | void,
    {
        patch: PatchMentionData[];
        projectId?: number;
        minDelay?: number;
        isBulkAction?: boolean;
    },
    {state: RootReducer; rejectValue: MentionAPIError}
>(
    'mentions/patchMentions',
    async (
        {patch, projectId, minDelay, isBulkAction = false},
        {getState, dispatch, rejectWithValue},
    ) => {
        const state = getState();

        const resolvedProjectId = projectId || state.query.context.projectId;

        if (!resolvedProjectId) {
            throw new Error('Unable to edit mention, a projectId is required');
        }

        try {
            const result = await awaitforAtLeast(
                patchMentionsApi(patch, resolvedProjectId),
                minDelay,
            );

            if (result.status === 202) {
                dispatch(
                    showNotification({
                        //Notify users that there may be a delay in reflecting their change
                        variant: 'info',
                        message: T('mentionComponent.metadata.msg202'),
                    }),
                );

                return;
            }

            dispatch(loadTags(projectId, false)); //<<<PP Why are we doing this here? This seems like something that shouldn't be here

            if (result.status === 200) {
                return result.body;
            } else {
                //what about other statuses? I don't think any other statuses will be returned here,
                //so this code should never get executed
                console.log('patchMentions: other result - ', result);
                throw new Error('Unknown status: ' + result.status);
            }
        } catch (e) {
            const normalisedError = normaliseAPIError(e as any, 'mentionComponent.errors.patch');

            dispatch(
                showNotification({
                    variant: 'warning',
                    message: normalisedError.message,
                }),
            );

            return rejectWithValue(normalisedError);
        }
    },
    {
        condition: ({patch}, {getState}) =>
            !checkMentionsHaveActiveStatus(
                patch.map(({mention: {resourceId}}) => ({resourceId})),
                getState().mentions.editMentionStatus,
            ),
        dispatchConditionRejection: true,
    },
);

//Examples of errors
//e =   {error: true, status: 501, body: {?}} - `/api/projects_/${projectId}/data/mentions`
//      {error: true, status: 400, body: {error: "project_call_failed", error_description: "Could not retrieve project data"}, nestedError: {errors: [{code: 0, message: "request_syntactically_incorrect"}]}} - `/api/projects/${projectId}+1/data/mentions`
//      {error: true, status: 404, body: {errors: [{code: 400, error_code: "project_not_found", message: "Project not found"}]}} - `/api/projects/${projectId}1/data/mentions`
//      {error: true, status: 400, body: {errors: [{code: 0, message: "Cannot deserialize field 'null'"}]}} - wrong format of body
//      {error: true, status: 500, body: {errors: [{code: 0, message: "internal_server_error"}]}} - missing resourceId
//      {error: true, status: 500, body: {}} - unable to connect to server

export const updateMentionsSentiment = createAsyncThunk<
    {sentiment: SentimentType},
    {
        ids: string[];
        sentiment: SentimentType;
        projectId?: number;
        minDelay?: number;
        isBulkAction?: boolean;
    },
    {state: RootReducer; rejectValue: MentionAPIError | undefined}
>(
    'mentions/updateMentionSentiment',
    async (
        {ids, sentiment, projectId, minDelay, isBulkAction = false},
        {getState, dispatch, rejectWithValue},
    ) => {
        const state = getState();
        const mentions = getMentionsByIds(ids, state.mentions.results);

        if (mentions.length !== ids.length) {
            throw new Error(
                `Unable to update mention(s) sentiment, reason: Unknown mention id(s): "${ids}"`,
            );
        }

        if (!mentions.every(isSavedSearchMention)) {
            throw new Error(
                `Unable to update mention(s) sentiment, reason: Only saved search mentions can be edited`,
            );
        }

        const patchData = {sentiment};

        const response = await dispatch(
            patchMentions({
                patch: mentions.map((mention) => ({
                    mention,
                    patchData,
                })),
                projectId,
                minDelay,
                isBulkAction,
            }),
        );

        return handlePatchResponse(
            response,
            dispatch,
            patchData,
            T('mentionComponent.mentionsSentimentChanged', {
                sentiment,
                count: ids.length,
            }),
            rejectWithValue,
            T('mentionComponent.errors.patch.bulkActionPending'),
        );
    },
);

export const updateMentionsLocation = createAsyncThunk<
    {location: LocationDefinition['id']},
    {
        ids: string[];
        location: LocationDefinition;
        projectId?: number;
        minDelay?: number;
        isBulkAction?: boolean;
    },
    {state: RootReducer; rejectValue: MentionAPIError | undefined}
>(
    'mentions/updateMentionSentiment',
    async (
        {ids, location, projectId, minDelay, isBulkAction = false},
        {getState, dispatch, rejectWithValue},
    ) => {
        const state = getState();
        const mentions = getMentionsByIds(ids, state.mentions.results);

        if (mentions.length !== ids.length) {
            throw new Error(
                `Unable to update mention(s) location, reason: Unknown mention id(s): "${ids}"`,
            );
        }

        if (!mentions.every(isSavedSearchMention)) {
            throw new Error(
                `Unable to update mention(s) location, reason: Only saved search mentions can be edited`,
            );
        }

        const patchData = {location: location.id};

        const response = await dispatch(
            patchMentions({
                patch: mentions.map((mention) => ({
                    mention,
                    patchData,
                })),
                projectId,
                minDelay,
                isBulkAction,
            }),
        );

        return handlePatchResponse(
            response,
            dispatch,
            patchData,
            T('mentionComponent.mentionsLocationChanged', {count: ids.length}), //TODO include location is message?
            rejectWithValue,
            T('mentionComponent.errors.patch.bulkActionPending'),
        );
    },
);

export const updateMentionsEmotion = createAsyncThunk<
    void, //{addClassifications?: APIEmotionType[]; removeClassifications?: APIEmotionType[]},
    {
        ids: string[];
        emotions: Record<EmotionsType, boolean>;
        projectId?: number;
        minDelay?: number;
        isBulkAction?: boolean;
    },
    {state: RootReducer; rejectValue: MentionAPIError | undefined}
>(
    'mentions/updateMentionEmotion',
    async (
        {ids, emotions, projectId, minDelay, isBulkAction = false},
        {getState, dispatch, rejectWithValue},
    ) => {
        const state = getState();
        const mentions = getMentionsByIds(ids, state.mentions.results);

        if (mentions.length !== ids.length) {
            throw new Error(
                `Unable to update mention(s) emotion, reason: Unknown mention id(s): "${ids}"`,
            );
        }

        if (!mentions.every(isSavedSearchMention)) {
            throw new Error(
                `Unable to update mention(s) emotion, reason: Only saved search mentions can be edited`,
            );
        }

        const response = await dispatch(
            patchMentions({
                patch: mentions.map((mention) => {
                    //Calculate how emotions have changed from current values
                    const currentEmotions = getEmotionsFromMention(mention);

                    const add: EmotionsType[] = [];
                    const remove: EmotionsType[] = [];

                    EMOTIONS_CATEGORIES.forEach((emotion) => {
                        if (emotions[emotion] !== currentEmotions[emotion]) {
                            //if emotion value has changed
                            (emotions[emotion] ? add : remove).push(emotion); //add to appropriate list
                        }
                    });

                    const patchData = cleanObject({
                        //cleanObject removes empty arrays from the object
                        addClassifications: add.map(
                            (e) => `emotions:${ucfirst(e)}` as APIEmotionType,
                        ),
                        removeClassifications: remove.map(
                            (e) => `emotions:${ucfirst(e)}` as APIEmotionType,
                        ),
                    });

                    return {
                        mention,
                        patchData,
                    };
                }),
                projectId,
                minDelay,
                isBulkAction,
            }),
        );

        return handlePatchResponse(
            response,
            dispatch,
            undefined,
            T('mentionComponent.mentionsEmotionChanged', {count: ids.length}),
            rejectWithValue,
            T('mentionComponent.errors.patch.bulkActionPending'),
        );
    },
);

export const addMentionsTag = createAsyncThunk<
    {addTag: string} | void,
    {ids: string[]; tagName: string; projectId: number; minDelay?: number; isBulkAction?: boolean},
    {state: RootReducer; rejectValue: MentionAPIError | undefined}
>(
    'mentions/addMentionTag',
    async (
        {ids, tagName, projectId, minDelay, isBulkAction = false},
        {getState, dispatch, rejectWithValue},
    ) => {
        const state = getState();
        const mentions = getMentionsByIds(ids, state.mentions.results);

        if (mentions.length !== ids.length) {
            throw new Error(
                `Unable to update mention(s) tags, reason: Unknown mention id(s): "${ids}"`,
            );
        }

        if (!mentions.every(isSavedSearchMention)) {
            throw new Error(
                `Unable to update mention(s) tags, reason: Only saved search mentions can be edited`,
            );
        }

        const patchData = {addTag: tagName};

        const response = await dispatch(
            patchMentions({
                patch: mentions.reduce<PatchMentionData[]>((output, mention) => {
                    //skip mentions that already have this tag
                    if (!mention.tags.includes(tagName)) {
                        output.push({
                            mention,
                            patchData,
                        });
                    }

                    return output;
                }, []),
                projectId,
                minDelay,
                isBulkAction,
            }),
        );

        return handlePatchResponse(
            response,
            dispatch,
            patchData,
            T('mentionComponent.mentionsTagAdded', {tagName, count: ids.length}),
            rejectWithValue,
            T('mentionComponent.errors.patch.bulkActionPending'),
        );
    },
);

export const removeMentionsTag = createAsyncThunk<
    {removeTag: string} | void,
    {ids: string[]; tagName: string; projectId: number; minDelay?: number; isBulkAction?: boolean},
    {state: RootReducer; rejectValue: MentionAPIError | undefined}
>(
    'mentions/removeMentionTag',
    async (
        {ids, tagName, projectId, minDelay, isBulkAction = false},
        {getState, dispatch, rejectWithValue},
    ) => {
        const state = getState();
        const mentions = getMentionsByIds(ids, state.mentions.results);

        if (mentions.length !== ids.length) {
            throw new Error(
                `Unable to update mention(s) tags, reason: Unknown mention id(s): "${ids}"`,
            );
        }

        if (!mentions.every(isSavedSearchMention)) {
            throw new Error(
                `Unable to update mention(s) tags, reason: Only saved search mentions can be edited`,
            );
        }

        const patchData = {removeTag: tagName};

        const response = await dispatch(
            patchMentions({
                patch: mentions.reduce<PatchMentionData[]>((output, mention) => {
                    //skip mentions that already have this tag
                    if (mention.tags.includes(tagName)) {
                        output.push({
                            mention,
                            patchData,
                        });
                    }

                    return output;
                }, []),
                projectId,
                minDelay,
                isBulkAction,
            }),
        );

        return handlePatchResponse(
            response,
            dispatch,
            patchData,
            T('mentionComponent.mentionsTagRemoved', {tagName, count: ids.length}),
            rejectWithValue,
            T('mentionComponent.errors.patch.bulkActionPending'),
        );
    },
);

function handlePatchResponse<
    TResponse extends {payload: any},
    TSuccessReturn,
    TRejectWithValue extends (value: any) => any,
>(
    response: TResponse,
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
    successReturn: TSuccessReturn,
    successMsg: string,
    rejectWithValue: TRejectWithValue,
    abortedMsg: string,
): TSuccessReturn | ReturnType<TRejectWithValue> {
    if (isFulfilled(response)) {
        dispatch(
            showNotification({
                variant: 'success',
                message: successMsg,
            }),
        );

        return successReturn;
    } else if (response.payload && isRejectedWithValue(response)) {
        return rejectWithValue(response.payload!);
    } else {
        //was aborted
        dispatch(
            showNotification({
                variant: 'warning',
                message: abortedMsg,
            }),
        );

        return rejectWithValue(undefined);
    }
}

/**
 * fulfilled payload is array of the deleted mentions ids
 * TODO add checkMentionsHaveActiveStatus check
 */
export const deleteMentions = createAsyncThunk<
    string[],
    {
        mentions: MentionAPIIdentifier[];
        projectId?: number;
        minDelay?: number;
        isBulkAction?: boolean;
    },
    {state: RootReducer; rejectValue: MentionAPIError}
>(
    'mentions/deleteMentions',
    async (
        {mentions, projectId, minDelay, isBulkAction = false},
        {getState, dispatch, rejectWithValue},
    ) => {
        const resolvedProjectId = projectId || getState().query.context.projectId;

        if (!resolvedProjectId) {
            throw new Error('Unable to delete mention(s), a projectId is required');
        }

        try {
            await awaitforAtLeast(deleteMentionsApi(mentions, resolvedProjectId), minDelay);

            dispatch(
                showNotification({
                    variant: 'success',
                    message: T('mentionComponent.mentionsDeleted'),
                }),
            );

            return mentions.map(({resourceId}) => resourceId);
        } catch (e) {
            const normalisedError = normaliseAPIError(e as any, 'mentionComponent.errors.delete');

            dispatch(
                showNotification({
                    variant: 'warning',
                    message: normalisedError.message,
                }),
            );

            return rejectWithValue(normalisedError);
        }
    },
    // {
    //     //TODO does this work? Will need to handle rejected status notification in each location
    //     condition: ({mentions}, {getState}) => !checkMentionsHaveActiveStatus(mentions, getState().mentions.editMentionStatus),
    //     dispatchConditionRejection: true,
    // }
);

///////////
// Utils //
///////////

function getMentionsByIds(ids: string[], mentions: MentionType[]) {
    const allIds = new Set(ids);

    return mentions.filter(({id}) => allIds.has(id));
}

function checkMentionsHaveActiveStatus(
    mentions: {resourceId: string}[],
    editMentionStatus: Record<string, EditMentionType>,
): boolean {
    return mentions.some((mention) => !!editMentionStatus[mention.resourceId]?.loading);
}
