import {TagDefinition} from 'hsi/types/filters';
import {AnyAction} from 'redux';
import {TAGS_LOADED, TAGS_LOADING, TAGS_LOAD_ERROR} from '../constants/actionTypes';

//This is to deal with the limitations of the current tags reducer, which only stores one project at a time

///////////
// Types //
///////////

type ProjectTags = {
    isLoading: boolean;
    isLoaded: boolean;
    allTags: TagDefinition[];
    tagsById: {[key: number]: TagDefinition}; //key is tag ID
};

//key is project ID
type AllTagsReducerState = {[key: number]: ProjectTags};

//action payload
type TagsLoadingActionPayload = number;

type TagsLoadedActionPayload = {
    projectId: number;
    results: TagDefinition[];
};

type TagsLoadErrorPayload = number;

//////////
// Vars //
//////////

const initialState: AllTagsReducerState = {};

////////////////
//The reducer //
////////////////

const allTagsReducer = (state = initialState, action: AnyAction): AllTagsReducerState => {
    switch (action.type) {
        case TAGS_LOADING:
            return tagsLoading(state, action.payload);
        case TAGS_LOADED:
            return tagsLoaded(state, action.payload);
        case TAGS_LOAD_ERROR:
            return tagsLoadError(state, action.payload);
    }

    return state;
};

function tagsLoading(
    state: AllTagsReducerState,
    payload: TagsLoadingActionPayload,
): AllTagsReducerState {
    return {
        ...state,
        [payload]: {
            ...state[payload],
            isLoading: true,
        },
    };
}

function tagsLoaded(
    state: AllTagsReducerState,
    payload: TagsLoadedActionPayload,
): AllTagsReducerState {
    return {
        ...state,
        [payload.projectId]: {
            isLoaded: true,
            isLoading: false,
            allTags: payload.results,
            tagsById: payload.results.reduce<{[key: number]: TagDefinition}>(
                (output, tag: TagDefinition) => {
                    output[tag.id] = tag;

                    return output;
                },
                {},
            ),
        },
    };
}

//we don't currently handle tag load errors here.
function tagsLoadError(
    state: AllTagsReducerState,
    payload: TagsLoadErrorPayload,
): AllTagsReducerState {
    return {
        ...state,
        [payload]: {
            ...state[payload],
            isLoading: false,
        },
    };
}

export default allTagsReducer;
