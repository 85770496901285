import React, {ReactElement, ReactNode, useMemo} from 'react';

//Components
import Tooltip from 'hsi/components/Tooltip';

type ConditionalButtonArgs = {
    className?: string;
    children: ReactNode;
    useConditionHook: () => boolean;
    enabledToolTip?: ReactNode;
    disabledTooltip?: ReactNode;
};

//The component
export default function ConditionalButton({
    className,
    children,
    useConditionHook,
    enabledToolTip = undefined,
    disabledTooltip = undefined,
}: ConditionalButtonArgs) {
    const conditionResult = useConditionHook();
    const tooltip = conditionResult ? enabledToolTip : disabledTooltip;

    const child = useMemo(() => {
        const rawChild = React.Children.only(children) as ReactElement; //should be a DOM element or support passing props

        return conditionResult
            ? rawChild
            : React.cloneElement(rawChild, {disabled: true, onClick: null});
    }, [conditionResult, children]);

    return useMemo(() => {
        if (!conditionResult && tooltip) {
            return (
                <Tooltip tooltip={tooltip}>
                    <div className={className}>{child}</div>
                </Tooltip>
            );
        } else if (tooltip) {
            return <Tooltip tooltip={tooltip}>{child}</Tooltip>;
        }

        return child;
    }, [className, child, conditionResult, tooltip]);
}
