import React, {ReactNode, useMemo} from 'react';

//Components
import IconRouter from 'hsi/components/IconRouter';
import MentionsList from 'hsi/components/MentionsList';
import PulseLoader from 'hsi/components/PulseLoader';

//Other
import useStyle from './styles';
import {T} from 'hsi/i18n';
import {TagDefinition} from 'hsi/types/filters';
import {SavedSearchMentionType} from 'hsi/types/mentions';
import Mention from 'hsi/components/Mention';
import VerticalScroll from 'hsi/components/layout/VerticalScroll';

//The Component
type TaggedMentionsProps = {
    selectedTag: TagDefinition;
    mentions: {
        error: boolean;
        hasMore: boolean;
        isOpen: boolean;
        loading: boolean;
        page: number;
        results: SavedSearchMentionType[];
        resultsTotal: number;
    };
    loadMentionsFunc: () => void;
};

export default function TaggedMentions({
    selectedTag,
    loadMentionsFunc,
    mentions,
}: TaggedMentionsProps) {
    const classes = useStyle();
    const {results, loading, resultsTotal, isOpen, page, ...mentionsListProps} = mentions;

    const mentionElements: ReactNode = useMemo(
        () =>
            results &&
            results.length > 0 &&
            results.map((mention) => {
                return <Mention key={mention.resourceId} data={mention} editable={false} />;
            }),
        [results],
    );

    return (
        <div className={classes.taggedMentions}>
            {results && results.length > 0 && selectedTag && (
                <>
                    <div className={classes.title}>
                        {T('managetagsdialog.taggedMentionsTitle', {
                            tag: selectedTag.name,
                            number: resultsTotal,
                        })}
                    </div>
                    <div className={classes.list}>
                        <VerticalScroll>
                            <MentionsList
                                asFeed
                                loading={loading}
                                loadMore={loadMentionsFunc}
                                {...mentionsListProps}
                            >
                                {mentionElements}
                            </MentionsList>
                        </VerticalScroll>
                    </div>
                </>
            )}
            {(results.length === 0 || (!results && loading)) && (
                <Message isLoading={loading} selectedTag={selectedTag} />
            )}
        </div>
    );
}

type MessageProps = {
    isLoading: boolean;
    selectedTag: TagDefinition;
};

function Message({isLoading, selectedTag}: MessageProps) {
    const classes = useStyle();

    return (
        <div className={classes.mentionsList}>
            {isLoading && <PulseLoader size="large" />}
            {!isLoading && (
                <>
                    <div className={classes.iconWrapper}>
                        <IconRouter aria-hidden name="mention" className={classes.icon} />
                    </div>
                    <div className={classes.main}>
                        {!selectedTag
                            ? T('managetagsdialog.taggedMentions')
                            : T('managetagsdialog.noMentions')}
                    </div>
                    <div className={classes.secondary}>
                        {!selectedTag ? '' : T('managetagsdialog.tryAgain')}
                    </div>
                </>
            )}
        </div>
    );
}
