import {useCallback, useEffect, useMemo, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';

import AddNonOwnedInstagramAccounts from 'hsi/containers/DataManagement/AddDataSourceDialog/AddNonOwnedInstagramAccounts';
import CheckboxOptionsMenu from './CheckboxOptionsMenu';

import useDataManagementConfig from 'hsi/hooks/useDataManagementConfig';
import {useInstagramAccounts} from 'hsi/hooks/useDataSource';

import {
    isGuidedSearchInstagramNonOwnedAccount,
    inputAccountValueParser,
} from 'hsi/containers/QueryBuilder/GuidedQueryForm/utils';
import tns from 'hsi/i18n/tns';

import {SelectAccountsProps} from '.';

const t = tns('queryBuilder');

const useStyles = makeStyles({
    notFoundRoot: {
        padding: `24px 24px 32px`,
        textAlign: 'center',
    },
});

const NoPagesFoundComponent = () => {
    const cl = useStyles();
    return <div className={cl.notFoundRoot}>{t('notTrackingNonOwnedInstagramAccounts')}</div>;
};

interface SelectNonOwnedInstagramAccountsProps extends SelectAccountsProps {
    onBack: () => void;
}

interface SelectExistingNonOwnedInstagramAccountsProps
    extends SelectNonOwnedInstagramAccountsProps {
    onAddNewPage: () => void;
}

const SelectExistingInstagramAccounts = (props: SelectExistingNonOwnedInstagramAccountsProps) => {
    const {onAddNewPage, onChange, setLoading, values} = props;
    const {maxDataSources} = useDataManagementConfig();

    // TODO: Currently skipping pagination. Checkbox should be changed to lazy load data and control the filtering via api
    const instagramAccounts = useInstagramAccounts({defaultSize: maxDataSources});

    const defaultInstagramAccounts = useMemo(() => {
        const selectedIds = values
            .filter(isGuidedSearchInstagramNonOwnedAccount)
            .map(({igbid}) => igbid);

        return instagramAccounts?.activeNonOwned?.filter(({id}) => selectedIds.includes(id));
    }, [instagramAccounts?.activeNonOwned, values]);

    const onAddPages = useCallback(
        (selected: typeof instagramAccounts.activeNonOwned) => {
            const otherPages = values.filter(
                (item) => !isGuidedSearchInstagramNonOwnedAccount(item),
            );
            const newIGItems = selected.map(inputAccountValueParser.instagram);

            onChange([...otherPages, ...newIGItems]);
        },
        [instagramAccounts, onChange, values],
    );

    useEffect(() => {
        if (instagramAccounts?.isLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [instagramAccounts?.isLoading, setLoading]);

    return (
        <CheckboxOptionsMenu
            {...props}
            defaultOptions={defaultInstagramAccounts}
            labelField="name"
            menuTitle={t('nonOwnedInstagramAccounts')}
            NoPagesFoundComponent={NoPagesFoundComponent}
            onAddNewPage={onAddNewPage}
            onAddPages={onAddPages}
            options={instagramAccounts?.activeNonOwned}
            valueField="id"
        />
    );
};

const SelectNonOwnedInstagramAccounts = (props: SelectNonOwnedInstagramAccountsProps) => {
    const [showAddNewPage, setShowAddNewPage] = useState(false);

    return showAddNewPage ? (
        <AddNonOwnedInstagramAccounts
            {...props}
            onBack={() => setShowAddNewPage(false)}
            onClose={() => setShowAddNewPage(false)}
        />
    ) : (
        <SelectExistingInstagramAccounts {...props} onAddNewPage={() => setShowAddNewPage(true)} />
    );
};

export default SelectNonOwnedInstagramAccounts;
