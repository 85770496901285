import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    clear: {
        ...theme.mixin.blankButton,
        position: 'absolute',
        top: 'calc(50% - 4px)',
        transform: 'translateY(-50%)',
        right: '0',
        fontSize: '14px',
        fontWeight: '700',
        color: theme.colors.secondaryBlue100,
        flex: '0 0 100%',
    },
    compact: {
        top: '50%',
    },
}));
