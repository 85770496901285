export enum TargetedDataType {
    Page = 'page',
    User = 'user',
    Account = 'account',
    Hashtag = 'hashtag',
}

export enum TargetedDataPlatform {
    Facebook = 'facebook',
    Instagram = 'instagram',
    Linkedin = 'linkedin',
    Twitter = 'twitter',
}

export enum AuthenticationDataTypes {
    FacebookUserAuthentication = 'facebookUserAuthentications',
    FacebookPageAuthentication = 'facebookPageAuthentications',
    InstagramAccountAuthentication = 'instagramAccountAuthentications',
    LinkedinChannelAuthentication = 'linkedinChannelAuthentications',
}

export enum TargetedDataDataTypes {
    FacebookPage = 'facebook-page',
    InstagramAccount = 'instagram-account',
    InstagramHashtag = 'instagram-hashtag',
    LinkedinChannel = 'linkedin-channel',
}

export type DataManagementDataTypes =
    | TargetedDataDataTypes
    | AuthenticationDataTypes.FacebookUserAuthentication
    | AuthenticationDataTypes.LinkedinChannelAuthentication;

interface Summary {
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
}

interface BaseItem {
    active: boolean;
    authenticated: boolean;
    created: string;
    enabled: boolean;
    id: string;
    name: string;
}

export interface FacebookPageItem extends BaseItem {
    owned: boolean;
    username?: string;
    type: TargetedDataDataTypes.FacebookPage;
}

export interface InstagramAccountItem extends BaseItem {
    displayName?: string;
    hashtagsRemaining: number;
    owned: boolean;
    type: TargetedDataDataTypes.InstagramAccount;
}

export interface InstagramHashtagItem extends BaseItem {
    keyId: string;
    linkedAccount: string;
    linkedAccountId: string;
    type: TargetedDataDataTypes.InstagramHashtag;
}

export interface LinkedinChannelItem extends BaseItem {
    projectId: string;
    type: TargetedDataDataTypes.LinkedinChannel;
}

export interface LinkedinAuthenticationItem extends BaseItem {}

export interface AuthenticationItem extends BaseItem {
    email: string;
    type: AuthenticationDataTypes.FacebookUserAuthentication;
}

export type Item =
    | BaseItem
    | AuthenticationItem
    | FacebookPageItem
    | InstagramAccountItem
    | InstagramHashtagItem
    | LinkedinChannelItem;

export type Data = {
    items: Item[];
    summary: Summary;
};

interface BaseAuthentication {
    name: string;
    authenticated: boolean;
}

export interface FacebookPageAuthentication extends BaseAuthentication {
    created: string;
    fbPageId: string;
    platform: TargetedDataPlatform.Facebook;
    type: AuthenticationDataTypes.FacebookPageAuthentication;
    url: string;
}

export interface FacebookUserAuthentication extends BaseAuthentication {
    created: string;
    email: string;
    fbUserId: string;
    platform: TargetedDataPlatform.Facebook;
    type: AuthenticationDataTypes.FacebookUserAuthentication;
}

export interface InstagramAccountAuthentication extends BaseAuthentication {
    created: string;
    fbPageId: string;
    instagramBusinessAccountId: string;
    platform: TargetedDataPlatform.Instagram;
    type: AuthenticationDataTypes.InstagramAccountAuthentication;
}

export interface LinkedinChannelAuthentication extends BaseAuthentication {
    uuid: string;
    platform: TargetedDataPlatform.Linkedin;
    type: AuthenticationDataTypes.LinkedinChannelAuthentication;
}

export type TrackableOwnedAuthenticationResponse = {
    authenticatedForFacebook: boolean;
    authenticatedForFacebookAdsPosts: boolean;
    authenticatedForInstagram: boolean;
    authenticatedForInstagramAtMentioned: boolean;
    facebookPageId: string;
    facebookPageName: string;
    facebookPageHandle?: string;
    facebookPageRole: string;
    instagramBusinessAccountId: string;
    igbaHandle: string;
    igbaDisplayName: string;
};

interface BaseTrackabledOwnedAuthentication {
    id: string;
    name: string;
}

export interface TrackableOwnedFacebookPageAuthentication
    extends BaseTrackabledOwnedAuthentication {
    type: AuthenticationDataTypes.FacebookPageAuthentication;
}

export interface TrackableOwnedInstagramAccountAuthentication
    extends BaseTrackabledOwnedAuthentication {
    type: AuthenticationDataTypes.InstagramAccountAuthentication;
}

export type TrackableOwnedAuthentication =
    | TrackableOwnedFacebookPageAuthentication
    | TrackableOwnedInstagramAccountAuthentication;

export type DataResponse = {
    succeeded: Item[];
    failed: Item[];
};

export interface BaseValidationItem {
    succeeded: boolean;
    value: string;
}

export interface FacebookPageValidationItem extends BaseValidationItem {
    errorCode?: number;
    failureReason?: string;
    id?: string;
    name?: string;
    type: TargetedDataDataTypes.FacebookPage;
}

export interface InstagramAccountValidationItem extends BaseValidationItem {
    errorCode?: number;
    failureReason?: string;
    id?: string;
    name?: string;
    type: TargetedDataDataTypes.InstagramAccount;
}

export type ValidationItem = FacebookPageValidationItem | InstagramAccountValidationItem;

export type ValidationResponse = {
    succeeded: ValidationItem[];
    failed: ValidationItem[];
};
