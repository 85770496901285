import {excludeConfig} from './excludeConfig';
import {parseTerms, parseHashtags} from 'hsi/utils/formulaParser';
import {brandContextComponent} from './brandConfig';

import tns from 'hsi/i18n/tns';
const t = tns('queryBuilder');

const topicIncludeConfig = {
    name: 'include',
    label: 'include',
    operator: 'OR',
    title: t('includeTitle'),
    components: [
        brandContextComponent,

        {
            name: 'terms',
            type: 'input-chips',
            title: t('topicTermsTitle'),
            subtitle: t('topicTermsSubtitle'),
            placeholder: t('topicTermsPlaceholder'),
            parseFormula: parseTerms,
            chipElemProps: {
                newChipChars: ',',
            },
        },

        {
            name: 'hashtags',
            type: 'input-hashtags',
            title: t('topicHashtagsTitle'),
            subtitle: t('topicHashtagsSubtitle'),
            placeholder: t('topicHashtagsPlaceholder'),
            parseFormula: parseHashtags,
        },
    ],
};

const topicConfig = {
    sections: [topicIncludeConfig, excludeConfig],
};

export {topicConfig};
