//An item to use in a Feed component, can be passed an 'as' component, defaults to
//an article
//https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/feed_role

import {PolymorphicComponentPropWithRef, PolymorphicRef} from 'hsi/types/shared';
import {forwardRef} from 'react';
import {useFeedContext} from '..';

export type BaseFeedItemProps = {
    'aria-posinset': number;
    tabIndex?: number;
};

type FeedItemProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<
    C,
    BaseFeedItemProps
>;

type FeedItemComponent = <C extends React.ElementType = 'article'>(
    props: FeedItemProps<C>,
) => React.ReactElement | null;

const FeedItem: FeedItemComponent = forwardRef(
    <C extends React.ElementType = 'article'>(
        {as, children, busy, tabIndex = 0, ...rest}: FeedItemProps<C>,
        ref?: PolymorphicRef<C>,
    ) => {
        const {setSize} = useFeedContext() ?? {setSize: -1};
        const Component = as || 'article';

        return (
            <Component {...rest} aria-setsize={setSize} tabIndex={tabIndex} ref={ref}>
                {children}
            </Component>
        );
    },
);

export default FeedItem;
