import {forwardRef, useMemo, useCallback} from 'react';

//Components
import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle, { formatCardTitleAsString } from 'hsi/components/Card/CardTitle';
import ChoroplethMap from 'hsi/components/ChoroplethMap';
import NumberWithPercentMeter from 'hsi/components/NumberWithPercentMeter';
import {AddUniqAuthorsCTA} from 'hsi/components/UniqAuthorsCTA';
import InfoPopupContent from 'hsi/components/InfoPopupContent';
import CompactNumber from 'hsi/components/format/CompactNumber';
import PaginationButtons from 'hsi/components/PaginationButtons';
import ManagedGridTable from 'hsi/components/table/ManagedGridTable';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useMultipleSearchIds from 'hsi/hooks/useMultipleSearchIds';
import useQueryContext from 'hsi/hooks/useQueryContext';
import {useSearchesById} from 'hsi/hooks/useSearchesById';
import useCardTableTrackingAndPersistence from '../hooks/useCardTableTrackingAndPersistence';
import useFormatRows from 'hsi/components/table/ManagedTable/useFormatRows';
import { useCardTypeLimit } from 'hsi/utils/cards/getCardTypeLimit';import useSortRows from 'hsi/components/table/ManagedTable/useSortRows';
import usePaginateRows from 'hsi/components/table/ManagedTable/usePaginateRows';
import useIsCardInteractivityDisabled from 'hsi/hooks/useIsCardInteractivityDisabled';
import useDrillInMentions from 'hsi/hooks/useDrillInMentions';
import useCardData from 'hsi/hooks/useCardData';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';
import { CardTypeProps, GeographyDataItem } from 'hsi/types/cards';
import { ColumnDefinition, FormatColumnDefinition, SortColumnDefinition } from 'hsi/components/table/ManagedTable/types';
import { getLocationDrillInFilters } from 'hsi/utils/filters/drillInFilters';


//Consts
const type = 'geography' as const;
const DEFAULT_DATA: GeographyDataItem[] = [];

// Types
type GeographyColumnDefinition = ColumnDefinition<string> & SortColumnDefinition<GeographyDataItem> & FormatColumnDefinition<GeographyDataItem>;


// The components
export default forwardRef<HTMLDivElement, CardTypeProps>(function Geography({title, ...props}, ref) {
    const classes = useStyles();
    const {
        links: {dashboardInfoGeographyCTA: popupCTA},
    } = useConfig();
    const drillInMentions = useDrillInMentions();
    const getDrillInData = useGetDrillInData();
    const {perPage} = useCardTypeLimit(type);
    const queryContext = useQueryContext();
    const {isMultipleSearch, isSavedSearch} = queryContext;
    const {searchIds} = useMultipleSearchIds();
    const {searchesById} = useSearchesById();
    const isCardInteractivityDisabled = useIsCardInteractivityDisabled();

    //Redux
    const {data, loading, loaded, error, loadData, isCardSelected} = useCardData({
        type,
        dataSelector: (state) => state.chart[type],
    });

    const hasData = useMemo(() => !!data?.some(({total}) => total > 0), [data]);

    // Callbacks
    const onItemClick = useCallback((item: GeographyDataItem) => {
        drillInMentions(getDrillInData(item));
    }, [drillInMentions, getDrillInData]);

    // Calculated values
    const columns = useMemo(
        () => getColumns(!!isMultipleSearch, !!isSavedSearch, searchIds, searchesById), 
        [isMultipleSearch, isSavedSearch, searchIds, searchesById]
    );

    // Get rows
    const rows = data ? data : DEFAULT_DATA;

    const {page, pages, setPage, ...sorting} = useCardTableTrackingAndPersistence<GeographyDataItem>(type, columns, {defaultSortColumn: 'total', totalRows: rows.length, rowsPerPage: perPage});

    // Apply sorting
    const sortedRows = useSortRows(rows, columns, sorting.sortColumn, sorting.sortDirection);

    // Apply pagination
    const paginatedRows = usePaginateRows(sortedRows, page, perPage);

    // Apply formatting
    const formattedRows = useFormatRows(paginatedRows, columns);

    const content = useMemo(() => {
        return (
            <div>
                <div
                    className={classes.chart}
                    inert={isCardInteractivityDisabled ? 'inert' : undefined}
                    
                >
                    <ChoroplethMap data={rows} onItemClick={onItemClick} />
                </div>
                <AddUniqAuthorsCTA type={type}>
                    <ManagedGridTable<keyof GeographyDataItem>
                        columns={columns}
                        rowData={formattedRows}
                        caption={T('managedTable.caption', {name: formatCardTitleAsString(title)})}

                        rowSelectable
                        onRowClick={(index: number) => {
                            onItemClick(paginatedRows[index]);
                        }}

                        {...sorting}
                    />
                    <PaginationButtons page={page} pages={pages} setPage={setPage} />
                </AddUniqAuthorsCTA>
            </div>
        );
    }, [classes.chart, isCardInteractivityDisabled, rows, onItemClick, columns, formattedRows, title, sorting, page, pages, setPage, paginatedRows]);

    const popup = useMemo(
        () => (
            <InfoPopupContent
                title={T(`cards.${type}.info.title`)}
                copy={T(`cards.${type}.info.copy`)}
                ctaUrl={popupCTA}
                ctaLabel={T('cards.infoCTALabel')}
            />
        ),
        [popupCTA],
    );

    //Render
    return (
        <CardLoadState
            {...props}
            title={
                <CardTitle title={title} tooltipComponent={popup} type={type} hasData={hasData} />
            }
            error={error}
            loading={loading}
            loaded={loaded}
            hasData={hasData}
            selected={isCardSelected}
            loadData={loadData}
            type={type}
            ref={ref}
            data-testid={type}
        >
            {content}
        </CardLoadState>
    );
});

function getColumns(isMultipleSearch: boolean, isSavedSearch: boolean, searchIds: number[], searchesById: Record<number, {name: string}>): GeographyColumnDefinition[] {
    const baseColumns = [
        {
            name: 'name',
            label: T(`cards.${type}.countries`),
            width: '2fr',
            horizontalAlign: 'start',
            truncateCellText: true,

            sortable: true,
            sortType: 'natural',
        },
        {
            name: 'total',
            label: T(isMultipleSearch ? 'total' : 'mentions'),
            width: '1fr',
            format: (row) => <CompactNumber value={row.total} tooltip />,

            sortable: true,
            sortType: 'numeric',
            defaultSortDir: 'desc',
        },
    ] as GeographyColumnDefinition[];
    
    const authorField: GeographyColumnDefinition = {
        name: 'authorTotal',
        label: T('uniqAuthors'),
        width: '1fr',
        format: ({authorTotal, authorPercent}) => (
            <NumberWithPercentMeter
                value={authorTotal}
                fraction={authorPercent}// 'authorPercent is a value between 0-1, with 1 = 100% (as in, it's not a percent, it's a fraction)
            />
        ),
        truncateHeaderLabelText: true,

        sortable: true,
        sortType: 'numeric',
        defaultSortDir: 'desc',
    };

    const searchIdFields: GeographyColumnDefinition[] = searchIds.map((searchId) => ({
        name: searchId.toString() as any,//This 'any' cast is needed as we can't truly type the geography row - if were were creating this from scratch, we would not have designed it like this
        label: searchesById[searchId]?.name,
        width: '1fr',
        format: (row) => <CompactNumber value={row[searchId]} tooltip />,

        truncateHeaderLabelText: true,

        sortable: true,
        sortType: 'numeric',
        defaultSortDir: 'desc',
    }));

    if (isMultipleSearch) {
        return [...baseColumns, ...searchIdFields];
    }

    if (!isMultipleSearch && isSavedSearch) {
        return [...baseColumns, authorField];
    }

    return baseColumns;
}


function useGetDrillInData() {
    const getDrillInData = useCallback(
        (item: GeographyDataItem) => ({
            dates: undefined,
            filter: getLocationDrillInFilters(item),
            label: item.name,
            tracking: {
                name: 'cardDrilledIn',
                type,
                value: item.name,
            },
            type,
        }),
        [],
    );

    return getDrillInData;
}