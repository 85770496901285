import makeStyles from '@mui/styles/makeStyles';

import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles((theme: Theme) => {
    const v2 = isV2(theme);

    return {
        root: {
            display: 'grid',
            background: '#FFF',
            position: 'relative',
            padding: v2 ? ' 16px 16px 20px' : '20px 16px',
            border: v2 ? undefined : `2px solid ${theme.colors.lightGrey80}`,

            gridTemplateColumns: '100%',
            gridTemplateRows: 'auto 1fr auto auto',
            gridTemplateAreas: `"header"
                "content"
                "metrics"
                "footer"`,
        },
        rootClickable: {
            cursor: 'pointer',

            '&:hover': {
                background: v2 ? theme.colors.uiGrayDefault : theme.colors.lightGrey20,
            },
        },

        hasMedia: {},

        selected: {
            borderColor: v2 ? undefined : '#5D6366',
            backgroundColor: v2 ? theme.colors.primaryBlue10 : undefined,

            '&:hover': {
                background: v2 ? theme.colors.primaryBlue20 : theme.colors.lightGrey20,
            },
        },

        layout_standard: {},

        layout_wideA: {
            gridTemplateColumns: '1fr',
            gridTemplateRows: 'auto 1fr auto auto',
            gridTemplateAreas: `"header"
                "content"
                "metrics"
                "footer"`,

            '&.hasMedia': {
                gridTemplateColumns: '33% 16px calc(67% - 16px)',
                gridTemplateRows: 'auto 1fr auto auto',
                gridTemplateAreas: `"image . header"
                    "image . content"
                    "image . metrics"
                    "image . footer"`,
            },
        },

        layout_wideB: {},

        content: {
            gridArea: 'content',
            overflow: 'auto',

            '$layout_wideA &': {
                gridArea: 'unset',
                overflow: 'initial',
                display: 'contents',
            },

            '$layout_wideB$hasMedia &': {
                display: 'grid',

                gap: '16px',
                gridTemplateColumns: 'calc(67% - 16px) 33%',
                gridTemplateRows: 'auto',
                gridTemplateAreas: '"content image"',
            },
        },

        mentionText: {
            '$layout_wideA &': {
                gridArea: 'content',
                overflow: 'auto',
            },

            '$layout_wideB &': {
                gridArea: 'content',
            },
        },

        media: {
            '$layout_standard &': {
                marginTop: '12px',
            },

            '$layout_wideA &': {
                gridArea: 'image',
            },

            '$layout_wideB &': {
                gridArea: 'image',
            },
        },

        contentTitle: {
            display: 'block',
            ...theme.mixin.wordBreak,

            ...(v2
                ? {
                      ...theme.fonts.bodyStrong,
                      margin: '0 0 8px',
                      color: theme.colors.darkGrey60,
                  }
                : {
                      fontSize: 'inherit',
                      fontWeight: theme.font.bold,
                      margin: '0 0 8px',
                  }),
        },
        contentSnippet: {
            ...theme.mixin.wordBreak,

            ...(v2
                ? {
                      ...theme.fonts.bodyNormal,
                  }
                : {
                      fontSize: '16px',
                      fontWeight: theme.font.regular,
                  }),
        },
        highlight: {
            backgroundColor: theme.colors.darkGrey20,
        },
        showMore: {
            ...theme.mixin.blankButton,
            ...theme.mixin.linkColor,
            fontSize: 'inherit',
        },
        showLess: {
            ...theme.mixin.blankButton,
            ...theme.mixin.linkColor,
            fontSize: 'inherit',
        },
        openSingleMention: {
            display: 'block',
            position: 'absolute',
            zIndex: -1,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0,
            width: '100%',
        },
        metrics: {
            gridArea: 'metrics',
        },
        footer: {
            gridArea: 'footer',
            display: 'flex',
            borderTop: v2 ? `1px dashed ${theme.colors.darkGrey20}` : undefined,
            marginTop: v2 ? '15px' : undefined,
            padding: v2 ? '11px 0 0' : '12px 0 2px',
            alignItems: 'center',
        },
        tags: {
            flex: '10 1 auto',
        },
        seeOriginal: {
            flex: '0 0 24px',
            height: 16,
            marginLeft: 16,
            '& a': {
                width: 16,
                height: 16,
                display: 'block',
            },
        },
        seeOriginalIcon: {
            display: 'block',
            width: '16px',
            height: '16px',
            lineHeight: '16px',
            fill: v2 ? undefined : theme.colors.primaryBlue100,
        },

        error: {},
    };
});
