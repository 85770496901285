import {AutocompleteChangeDetails, AutocompleteChangeReason} from '@mui/base/useAutocomplete';
import cn from 'classnames';
import {ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';

import Autocomplete, {AutocompleteProps} from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import {uniq} from 'lodash';

import Markdown from 'hsi/components/Markdown';
import {MenuPaper} from 'hsi/components/Menu';
import TextField, {TextFieldProps} from 'hsi/components/TextField';
import ChipWithError, {ChipErrorType} from './ChipWithError';
import FormError from '../FormError';

import useUniqueId from 'hsi/hooks/useUniqueId';
import useStyles from './styles';

import {InputErrorMsgProps} from 'hsi/components/ErrorMessage/InputErrorMsg';
import {T} from 'hsi/i18n';

//Consts
const defaultSplitInputOn = /,+/;

//Types
interface BaseChipInputProps<TChip, TFreeSolo extends boolean>
    extends Pick<
        AutocompleteProps<TChip, true, true, TFreeSolo>,
        'className' | 'disabled' | 'id' | 'freeSolo' | 'autoHighlight' | 'loading'
    > {
    cta?: React.ReactNode;
    error?: boolean;
    getInputErrorMessage?: (
        chipErrors?: ChipErrorType<TChip>[],
        valuesWithError?: TChip[],
        clearErrors?: () => void,
    ) => InputErrorMsgProps | null;
    hasStatus?: 'failed' | 'loading' | 'success';
    helperText?: string;
    limitLabel?: string;
    itemName?: string;
    isAutoSelect?: boolean;
    label?: string;
    maxLength?: number;
    onInputChange?: (rawInput: string) => void;
    onValuesChange?: (
        newValues: Value<TChip, true, true, TFreeSolo>,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<Value<TChip, true, true, TFreeSolo>[number]>,
    ) => boolean | undefined | void;
    placeholder?: TextFieldProps['placeholder'];
    renderFailedStatus?: () => React.ReactNode;
    renderLoadingStatus?: () => React.ReactNode;
    renderSuccessStatus?: () => React.ReactNode;
    validateValues?: (values: TChip[]) => ChipErrorType<TChip>[];
    values?: TChip[];
    splitInputOn?: string | RegExp;
    endAdornment?: ReactNode;
    required?: boolean;

    //TODO ideally would only be optional if TFreeSolo = true
    options?: AutocompleteProps<TChip, true, true, TFreeSolo>['options'];
    getOptionLabel?: AutocompleteProps<TChip, true, true, TFreeSolo>['getOptionLabel'];
}

interface StringChipInputProps<TChip extends string, TFreeSolo extends boolean>
    extends BaseChipInputProps<TChip, TFreeSolo> {
    chipComponent?: React.ComponentType<RenderChipProps<TChip>>;
}

interface NonStringChipInputProps<TChip, TFreeSolo extends boolean>
    extends BaseChipInputProps<TChip, TFreeSolo> {
    chipComponent: React.ComponentType<RenderChipProps<TChip>>;
}

export type ChipInputProps<TChip = string, TFreeSolo extends boolean = true> = TChip extends string
    ? StringChipInputProps<TChip, TFreeSolo>
    : NonStringChipInputProps<TChip, TFreeSolo>;

type GetTagPropsReturnType = {
    className?: string;
    'data-tag-index': number;
    disabled: boolean;
    onDelete: (event: any) => void;
    tabIndex: number;
};

export type Value<T, Multiple, DisableClearable, FreeSolo> = Multiple extends undefined | false
    ? DisableClearable extends true
        ? NonNullable<T | AutocompleteFreeSoloValueMapping<FreeSolo>>
        : T | null | AutocompleteFreeSoloValueMapping<FreeSolo>
    : Array<T | AutocompleteFreeSoloValueMapping<FreeSolo>>;

export type AutocompleteFreeSoloValueMapping<FreeSolo> = FreeSolo extends true ? string : never;

export default function ChipInput<TFreeSolo extends boolean, TChip = string>({
    id,
    className,
    cta,
    disabled = false,
    error = false,
    getInputErrorMessage,
    hasStatus,
    helperText,
    limitLabel = 'left',
    itemName,
    isAutoSelect = false,
    label,
    maxLength,
    onInputChange,
    onValuesChange,
    placeholder,
    renderFailedStatus,
    renderLoadingStatus,
    renderSuccessStatus,
    values = [],
    validateValues,
    endAdornment,
    freeSolo = true as TFreeSolo,
    options,
    getOptionLabel,
    autoHighlight,
    loading,
    required,

    splitInputOn = defaultSplitInputOn,

    //These properties are only optional if TChip is a string, so the default values will only be used then.
    chipComponent: ChipComponent = DefaultRenderChip as React.ComponentType<RenderChipProps<TChip>>,
}: ChipInputProps<TChip, TFreeSolo>) {
    const classes = useStyles();
    const descId = useUniqueId(null, 'chipInputDescId');
    const labelId = useUniqueId(null, 'chipInputLabelId');

    const [chipErrors, setChipErrors] = useState<ChipErrorType<TChip>[]>([]);
    const [inputValue, _setInputValue] = useState('');

    const inputStateRef = useRef<{
        element: HTMLInputElement | undefined;
        value: string;
        selectionStart: number | null;
        selectionEnd: number | null;
    }>({element: undefined, value: '', selectionStart: null, selectionEnd: null});

    const [isFocused, setIsFocused] = useState(false);
    const [closed, setClosed] = useState(false);

    const recordInputState = useCallback((input: HTMLInputElement) => {
        inputStateRef.current.element = input;
        inputStateRef.current.value = input.value;
        inputStateRef.current.selectionStart = input.selectionStart;
        inputStateRef.current.selectionEnd = input.selectionEnd;
    }, []);

    const rollbackInputState = useCallback(() => {
        const {element, value, selectionStart, selectionEnd} = inputStateRef.current;

        if (element) {
            _setInputValue(value);
            element.value = value;
            element.selectionStart = selectionStart;
            element.selectionEnd = selectionEnd;
        }
    }, []);

    const setInputValue = useCallback(
        (newValue: string) => {
            onInputChange?.(newValue);
            _setInputValue(newValue);
        },
        [onInputChange],
    );

    const valuesWithoutError = useMemo(
        () => values.filter((value) => !chipErrors.map((error) => error.value)?.includes(value)),
        [chipErrors, values],
    );
    const valuesWithError = useMemo(
        () => values.filter((value) => chipErrors.map((error) => error.value)?.includes(value)),
        [chipErrors, values],
    );
    const hasError = chipErrors.length > 0 || error;
    const remainingValues = maxLength && maxLength - values.length;

    const errorsByValue = useMemo(() => {
        return chipErrors.reduce((output, chipError) => {
            if (!output.has(chipError.value)) {
                output.set(chipError.value, []);
            }

            output.get(chipError.value)?.push(chipError);

            return output;
        }, new Map<TChip, ChipErrorType<TChip>[]>());
    }, [chipErrors]);

    const onChange = useCallback(
        (
            newValues: Value<TChip, true, true, TFreeSolo>,
            reason: AutocompleteChangeReason,
            details?: AutocompleteChangeDetails<Value<TChip, true, true, TFreeSolo>[number]>,
        ) => {
            if (maxLength !== undefined && maxLength < newValues.length) {
                return;
            }

            const isChangeValid = onValuesChange?.(newValues, reason, details);

            if (isChangeValid === false) {
                //assume undefined/void = true
                //If onChange returns false, we want to essentially 'cancel' the add,
                //so we need to rollback inputValue
                rollbackInputState();
            }
        },
        [maxLength, onValuesChange, rollbackInputState],
    );

    const clearErrors = useCallback(() => {
        onChange(valuesWithoutError, 'clear');
    }, [onChange, valuesWithoutError]);

    const getErrorText = useMemo(() => {
        return getInputErrorMessage?.(chipErrors, valuesWithError, clearErrors);
    }, [chipErrors, clearErrors, getInputErrorMessage, valuesWithError]);

    //Handle validation
    useEffect(() => {
        if (values && values.length > 0 && validateValues) {
            setChipErrors(uniq(validateValues(values)));
        } else if (chipErrors.length > 0) {
            setChipErrors([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, validateValues]);

    return (
        <div className={cn(classes.wrapper, className)}>
            {(!!maxLength || !!label) && (
                <div className={classes.labelWrapper}>
                    {!!label && (
                        <label className={classes.label} id={labelId} htmlFor={id}>
                            {label}
                        </label>
                    )}
                    {!!maxLength && !disabled && (
                        <Markdown
                            className={cn(
                                classes.chipsLeftMsg,
                                remainingValues !== undefined &&
                                    remainingValues <= 0 &&
                                    classes.chipsLeftError,
                            )}
                            data-testid="chipInputLeft"
                        >
                            {T(limitLabel, {num: remainingValues, name: itemName})}
                        </Markdown>
                    )}
                </div>
            )}
            {hasStatus === 'failed' && renderFailedStatus?.()}
            {hasStatus === 'loading' && renderLoadingStatus?.()}
            {hasStatus === 'success' && renderSuccessStatus?.()}
            {!hasStatus && (
                <Autocomplete
                    id={id}
                    className={classes.input}
                    disabled={disabled}
                    disableClearable
                    freeSolo={freeSolo}
                    fullWidth
                    inputValue={inputValue}
                    multiple
                    autoSelect={isAutoSelect}
                    onChange={(event, newValues, reason, details) => {
                        event?.preventDefault?.();
                        onChange(newValues, reason, details);
                    }}
                    onInputChange={(event, rawInputValue: string) => {
                        if (event?.target instanceof HTMLInputElement) {
                            recordInputState(event.target); //I need to record the state of the input field so I can 'rollback' if required
                        }

                        event?.preventDefault?.();

                        const rawValue = rawInputValue.split(splitInputOn);

                        if (freeSolo && rawValue.length > 1) {
                            const newValues: Value<TChip, true, true, TFreeSolo> = [...values];

                            rawValue
                                .map((str) => str.trim())
                                .filter((value) => !!value)
                                .filter((value) => !newValues.includes(value as TChip))
                                .forEach((newValue) => {
                                    newValues.push(newValue as any);
                                    onChange([...newValues], 'createOption', {
                                        option: newValue as any,
                                    });
                                    setInputValue('');
                                });
                        } else {
                            setInputValue(rawInputValue);
                        }
                    }}
                    options={options || ([] as TChip[])}
                    getOptionLabel={getOptionLabel}
                    open={isFocused && !closed && (options?.length ?? 0) > 0}
                    onFocus={() => {
                        setIsFocused(true);
                    }}
                    onBlur={() => {
                        setIsFocused(false);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Escape') {
                            !closed && setClosed(true);
                        } else {
                            setClosed(false);
                        }
                    }}
                    autoHighlight={autoHighlight}
                    loading={loading}
                    filterSelectedOptions
                    renderInput={(params) => {
                        params.InputProps.endAdornment = endAdornment ? (
                            <InputAdornment className={classes.endAdornment} position="end">
                                {endAdornment}
                            </InputAdornment>
                        ) : undefined;

                        return (
                            <TextField
                                {...params}
                                descId={descId}
                                error={hasError}
                                label={labelId}
                                placeholder={!values.length && placeholder ? placeholder : ''}
                                data-testid={placeholder}
                                required={required}
                                helperText={
                                    hasError ? (
                                        <FormError
                                            type="warning"
                                            errorText={getErrorText?.desc as string}
                                            actionText={getErrorText?.title as string}
                                        />
                                    ) : (
                                        helperText
                                    )
                                }
                            />
                        );
                    }}
                    // Turns out that getTagProps isn't typed properly until 2021 and we don't have 2021 MUI :(
                    // I have added my own types for now
                    renderTags={(values, getTagProps: any) =>
                        values.map((value, index: number) => (
                            <ChipComponent
                                errors={errorsByValue.get(value)}
                                value={value}
                                index={index}
                                {...(getTagProps({index}) as GetTagPropsReturnType)}
                            />
                        ))
                    }
                    value={values}
                    PaperComponent={options ? MenuPaper : undefined}
                />
            )}
            {!!cta && !hasStatus && <div className={classes.cta}>{cta}</div>}
        </div>
    );
}

//Chip
export type RenderChipProps<TChip = string> = {
    value: TChip;
    index: number;
    errors: ChipErrorType<TChip>[] | undefined;
} & GetTagPropsReturnType;

function DefaultRenderChip({
    value,
    index,
    errors,
    onDelete,
    className,
    ...rest
}: RenderChipProps<string>) {
    return <ChipWithError {...rest} label={value} onDelete={onDelete} error={errors?.[0]} />;
}
