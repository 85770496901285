import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({colors}) => ({
    icon: {
        fontSize: 20,
        width: '20px',
        height: '20px',
    },
    iconWrapper: {
        minWidth: '36px',
        height: '36px',
        borderRadius: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    successWrapper: {
        backgroundColor: colors.secondaryGreen40,
    },
    success: {
        backgroundColor: colors.secondaryGreen10,
    },
    warning: {
        backgroundColor: colors.primaryRed10,
    },
    warningWrapper: {
        backgroundColor: colors.primaryRed40,
    },
    successIcon: {
        fill: colors.secondaryGreen100,
    },
    warningIcon: {
        fill: colors.primaryRed100,
    },
    info: {
        backgroundColor: colors.secondaryBlue10,
    },
    infoWrapper: {
        backgroundColor: colors.secondaryBlue20,
        alignSelf: 'start',
    },
    infoIcon: {
        fill: colors.icons,
    },

    root: {
        maxWidth: '394px',
        minHeight: '85px',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        padding: '12px',
    },
    messageWrapper: {
        marginLeft: '20px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '260px',
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        color: colors.darkGrey100,
        fontSize: '16px',
        '& span': {
            fontWeight: 700,
        },
        '& .itemName': {
            fontWeight: 700,
            maxWidth: '200px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            verticalAlign: 'bottom',
        },
    },
    messageTitle: {
        fontWeight: 'bold',
        marginBottom: 3,
        fontSize: '16px',
    },
    action: {
        flex: '0 0 auto',
        margin: 0,
        padding: 0,
        alignSelf: 'baseline',
        '& .MuiIconButton-root': {
            padding: '7px',
            backgroundColor: colors.snackBarCloseButtonBg,
        },
    },
}));
