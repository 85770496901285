const styles = ({v2, colors, font}) => {
    return {
        root: {
            width: v2 ? 300 : 296,
            paddingBottom: '8px',
        },

        searchFieldContainer: {
            padding: '12px 16px',
            '& .icon-search, icon-magnify-glass': {
                width: '17px',
                height: '17px',
            },
        },

        optionsContainer: {
            minHeight: '100px',
            maxHeight: '280px',
            overflowY: 'auto',
        },

        loading: {
            textAlign: 'center',
            padding: '56px 0 70px 0',
            listStyleType: 'none',
        },

        menuItem: {
            paddingLeft: '16px !important',
            paddingRight: '16px !important',
            '&.Mui-disabled': {
                pointerEvents: 'auto',
            },
        },

        bigIcons: {
            height: '60px',
        },

        searchMsg: {
            fontSize: '14px',
            color: colors.textDefault,
            textAlign: 'center',
            fontWeight: font.semiBold,
            padding: '54px 0 67px 0',
        },

        iconContainer: {
            width: '32px',
            height: '32px',
            minWidth: '32px',
            background: colors.lightGrey10,
            borderRadius: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${colors.lightGrey80}`,
            marginRight: '8px',
        },

        label: {
            flex: '1 10 auto',
        },

        contentRight: {},

        addNewItem: {
            '& .iconContainer': {
                display: 'none',
            },
        },

        addNewHint: {
            display: 'inline-flex',
            alignItems: 'center',
            textTransform: 'uppercase',
            fontWeight: font.bold,
            fontSize: '10px',
            '& .app-icon': {
                transform: 'scaleY(-1)',
                marginLeft: 5,
            },
        },

        disabledLabel: {
            maxWidth: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            display: 'inline-block',
        },
    };
};

export default styles;
