import {registerFilterType, isValueValid} from './../utils';

registerFilterType(
    'textfield',
    () => '',
    (filterConfig, filterState) => !filterState,
    {}, //reducers
    {
        //validated reducers func(state, payload)
        setValueTextfield: (state, {filterName, value}) => {
            state.filters[filterName] = value;
        },
    },
    (state, filterName, value, skipAsync = false) => {
        const filterConfig = state.allFiltersConfig[filterName];

        //Check validation
        if (filterConfig.validation) {
            const errorMsg = filterConfig.validation(value, state);

            if (errorMsg) {
                return errorMsg;
            }
        }

        if (!skipAsync && value && filterConfig.asyncValidation) {
            return filterConfig.asyncValidation(value, state);
        }

        return true;
    },
    (state, filterConfig, {action, value, mode, filterName, fieldName}) => {
        if (action !== 'setValueTextfield') {
            throw new Error(
                'Invalid action for this filter type, check you have the correct filterName and/or are using the correct action',
            );
        }

        return isValueValid(state, filterName, value);
    },
    undefined,
    undefined,
    undefined,
    undefined,
    (filterConfig, currentValue, persistedValue) => {
        return typeof persistedValue === 'string' ? persistedValue : '';
    },
);
