import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    content: {
        //modifier
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: 8,
    },
    compact: {
        gap: 4,
    },
}));
