import {excludeConfig} from './excludeConfig';
import {parseNames, parseAuthors} from 'hsi/utils/formulaParser';
import {brandContextComponent} from './brandConfig';

import tns from 'hsi/i18n/tns';
const t = tns('queryBuilder');

const personIncludeConfig = {
    name: 'include',
    label: 'include',
    operator: 'OR',
    title: t('includeTitle'),
    components: [
        brandContextComponent,

        {
            name: 'person-names',
            type: 'input-chips',
            title: t('personNamesTitle'),
            subtitle: t('personNamesSubitle'),
            placeholder: t('personNamesPlaceholder'),
            parseFormula: parseNames,
            chipElemProps: {
                newChipChars: ',',
            },
        },

        {
            name: 'social-accounts',
            type: 'input-accounts',
            title: t('personSocialAccountsTitle'),
            subtitle: t('personSocialAccountsSubitle'),
            placeholder: t('personSocialAccountsPlaceholder'),
            parseFormula: parseAuthors,
        },
    ],
};

const personConfig = {
    sections: [personIncludeConfig, excludeConfig],
};

export {personConfig};
