import mixpanel, {Dict} from 'mixpanel-browser';
import {isProduction} from 'hsi/utils/url';

const tokens = {
    HSI: '7bd7076fda3b412a5ec099f4a75877f0',
    FALCON: 'e09dac315dcc82b10ba7ea61343849b0',
    C3: '77c25b07ecc18bb8f679624e4d851612',
    NGC3: '7205875d1cca43576491472f63105219',
    LUQI: '4574b8e79e59c76dec31818073d1f3af',
    //TODO: Add token for C1
    C1: '77c25b07ecc18bb8f679624e4d851612',
};

export let token: string;

const appSource: 'HSI' | 'FALCON' | 'C3' | 'NGC3' | 'LUQI' | 'C1' | undefined = (window as any)?.APP_SOURCE;

if (!!(appSource && isProduction() && tokens?.[appSource])) {
    token = tokens[appSource];
} else {
    token = 'NO_TOKEN';
}

let loaded = false;
mixpanel.init(token, {
    // Check if mixpanel is initialised so that we can skip if not
    loaded: () => {
        loaded = true;
    },
});

const trackingError = (name: string) => {
    console.log(name, {app: appSource, url: window.document.URL, token});
};

// Make sure mixpanel always fails gracefully otherwise it can block any code
// after it from excuting
const eventTracking = {
    identify: (arg: string) => {
        if (loaded) {
            mixpanel.identify(arg);
        } else {
            trackingError('Mixpanel identify not initialised');
        }
    },
    register: (arg: Dict) => {
        if (loaded) {
            mixpanel.register(arg);
        } else {
            trackingError('Mixpanel register not initialised');
        }
    },
    people: {
        set: (arg: Dict) => {
            if (loaded) {
                if (mixpanel?.people) {
                    mixpanel.people.set(arg);
                }
            } else {
                trackingError('Mixpanel people set not initialised');
            }
        },
    },
    track: (name: string, value?: Dict) => {
        if (loaded) {
            if ((window as any)?.APP_SOURCE === 'FALCON' && isProduction()) {
                window.parent.postMessage(
                    {
                        type: 'frame.listen.tracking',
                        payload: {
                            name: `New Listen - ${name}`,
                            product: 'Listen',
                        },
                    },
                    'https://app.falcon.io',
                );
            }

            if (!!value) {
                mixpanel.track(name, value);
            } else {
                mixpanel.track(name);
            }
        } else {
            trackingError('Mixpanel track not inititalised');
        }
    },
};

export default eventTracking;
