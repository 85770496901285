//TODO what on earth is going on here?
//On further inspection, this is far worse than I first thought, this is a total mess, it is not clear how any of this works
//or why it has been built this way.
//This feels like ReportsProvider should be a hook?
import React, {createContext, useCallback, useContext, useMemo, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment-timezone';

import useConfig from 'hsi/hooks/useConfig';
import useQueryContext from 'hsi/hooks/useQueryContext';

import * as reportActions from 'hsi/actions/reportActions';

import tns from 'hsi/i18n/tns';
const t = tns('reports');
const tcr = tns('savedsearchbar.buildreport');

const ReportsContext = createContext();

//What are props? Do they do anything? I don't think they should?
export const ReportsProvider = (props) => {
    const {exportType, reports: config} = useConfig();
    const {timespanTypes} = config;
    const dispatch = useDispatch();

    //Selectors
    const defaultEmail = useSelector((state) => state.user.account.username);
    const {data, error, itemError, itemLoading, itemSuccess, loading, success} = useSelector(
        (state) => state.reports,
    );
    const queryContext = useQueryContext();
    const _projectId = queryContext?.savedSearch?.project?.id;
    const _queryId = queryContext?.savedSearch?.id;
    const timezone = queryContext?.timezone;
    const defaultDate = moment.tz(timezone).startOf('day');

    //States
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

    const [hours, setHours] = useState(config.defaultHours);
    const [id, setId] = useState(null);
    const [minutes, setMinutes] = useState(config.defaultMinutes);
    const [name, setName] = useState('');
    const [projectId, setProjectId] = useState(_projectId);
    const [queryId, setQueryId] = useState(_queryId);
    const [recipients, setRecipients] = useState([defaultEmail]);
    const [startDate, setStartDate] = useState(defaultDate);
    const [timespan, setTimespan] = useState(config.defaultTimespan);

    //Memos
    const item = useMemo(
        () => ({
            error: itemError,
            loading: itemLoading,
            success: itemSuccess,
        }),
        [itemError, itemLoading, itemSuccess],
    );

    const report = useMemo(
        () => ({
            hours,
            id,
            logo: exportType,
            minutes,
            name,
            projectId,
            queryId,
            recipients,
            startDate,
            timespan,
            type: timespanTypes[timespan],
        }),
        [
            exportType,
            hours,
            id,
            minutes,
            name,
            projectId,
            queryId,
            recipients,
            startDate,
            timespan,
            timespanTypes,
        ],
    );

    const reports = useMemo(
        () => ({
            data,
            error,
            loading,
            success,
        }),
        [data, error, loading, success],
    );

    //Handle modal
    const reset = useCallback(() => {
        setHours(config.defaultHours);
        setId(null);
        setMinutes(config.defaultMinutes);
        setName('');
        setProjectId(_projectId);
        setQueryId(_queryId);
        setRecipients([defaultEmail]);
        setStartDate(defaultDate);
        setTimespan(config.defaultTimespan);
    }, [_projectId, _queryId, config, defaultEmail, defaultDate]);

    const closeModal = useCallback(() => {
        setIsModalOpen(false);
        reset();
    }, [reset]);

    const openModal = useCallback(
        (report) => {
            if (report) {
                setHours(report?.hours);
                setId(report.id);
                setMinutes(report?.minutes);
                setName(report.name);
                setProjectId(report.projectId);
                setQueryId(report.queryId);
                setRecipients(report.recipients);
                setStartDate(defaultDate);
                setTimespan(report.timespan);
            } else {
                reset();
            }
            setIsModalOpen(true);
        },
        [defaultDate, reset],
    );

    //Manage reports
    const activateReport = useCallback(
        (report) => {
            dispatch(
                reportActions.toggleReport({
                    errorMsg: t('reportEditionError'),
                    report,
                    successMsg: t('reportEdited', {name: report.name}),
                    timespanTypes,
                }),
            );
        },
        [timespanTypes, dispatch],
    );

    const createReport = useCallback(() => {
        dispatch(
            reportActions.createReport({
                errorMsg: tcr('reportError'),
                report,
                successMsg: tcr('reportCreated', {name: report.name}),
                timespanTypes,
            }),
        );
        closeModal();
        setIsSnackbarOpen(true);
    }, [closeModal, dispatch, report, timespanTypes]);

    const deleteReport = useCallback(
        (report) => {
            dispatch(
                reportActions.deleteReport({
                    errorMsg: t('reportDeletedError'),
                    report,
                    successMsg: t('reportDeleted', {name: report.name}),
                }),
            );
        },
        [dispatch],
    );

    const editReport = useCallback(() => {
        dispatch(
            reportActions.editReport({
                errorMsg: t('reportEditionError'),
                report,
                successMsg: t('reportEdited', {name: report.name}),
                timespanTypes,
            }),
        );
        closeModal();
        setIsSnackbarOpen(true);
    }, [closeModal, dispatch, report, timespanTypes]);

    const loadReports = useCallback(() => {
        dispatch(
            reportActions.loadReports({
                errorMsg: t('actionError'),
                timespanTypes,
            }),
        );
    }, [dispatch, timespanTypes]);

    //Handle snackbar
    const clearSnackbar = useCallback(() => {
        dispatch(reportActions.clearReportSuccess());
        dispatch(reportActions.clearReportError());
    }, [dispatch]);

    //Provider value
    const actions = useMemo(
        () => ({
            activateReport,
            clearSnackbar,
            closeModal,
            createReport,
            deleteReport,
            editReport,
            loadReports,
            openModal,
            reset,
        }),
        [
            activateReport,
            clearSnackbar,
            closeModal,
            createReport,
            deleteReport,
            editReport,
            loadReports,
            openModal,
            reset,
        ],
    );

    const value = useMemo(
        () => ({
            actions,
            defaultDate,
            isModalOpen,
            setIsModalOpen,
            isSnackbarOpen,
            setIsSnackbarOpen,
            item,
            setHours,
            setMinutes,
            setName,
            setStartDate,
            setRecipients,
            setTimespan,
            report,
            reports,
        }),
        [
            actions,
            defaultDate,
            isModalOpen,
            isSnackbarOpen,
            item,
            report,
            reports,
            setHours,
            setIsModalOpen,
            setIsSnackbarOpen,
            setMinutes,
            setName,
            setStartDate,
            setRecipients,
            setTimespan,
        ],
    );

    return <ReportsContext.Provider value={value} {...props} />;
};

const useReports = () => {
    const context = useContext(ReportsContext);

    if (context === undefined) {
        throw new Error('useReports must be used with ReportsProvider');
    }

    return context;
};

export default useReports;
