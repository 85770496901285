import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';

import useComponentsBaseStyles from './baseStyles';

const InputText = ({name, onChange, placeholder, sectionName, subtitle, title, values}) => {
    const ccl = useComponentsBaseStyles();

    const _onChange = (e) =>
        onChange({
            sectionName,
            componentName: name,
            values: [String(e.target.value)],
        });

    return (
        <div className={ccl.container}>
            <div className={ccl.title} id={name}>
                {title}
            </div>
            <div className={ccl.subtitle} id={`${name}-desc`}>
                {subtitle}
            </div>
            <TextField
                defaultValue={values}
                fullWidth
                inputProps={{
                    'aria-describedby': `${name}-desc`,
                    'aria-labelledby': name,
                }}
                onChange={_onChange}
                placeholder={placeholder}
                variant="outlined"
            />
        </div>
    );
};

InputText.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    sectionName: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
};

export default InputText;
