import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles(({colors, font, fonts, v2}: Theme) => ({
    label: {
        ...(v2
            ? fonts.buttonNormal
            : {
                  fontSize: 16,
                  fontWeight: font.bold,
              }),
    },
    timezone: {
        alignItems: 'center',
        borderLeft: `1px solid ${colors.primaryBlue100}`,
        display: 'flex',
        height: 18,
        marginLeft: 10,
        paddingLeft: 8,
    },
    calendarIcon: {
        fill: colors.primaryBlue100,
        height: 20,
        width: 19,
    },
    dropdownIcon: {
        fill: colors.primaryBlue100,
        height: 22,
        width: 22,
    },

    footer: {
        height: '60px',
        background: '#FFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 10px',
        borderTop: v2 ? `1px solid ${colors.borders}` : `1px solid ${colors.lightGrey60}`,
        gap: '8px',
    },
}));
