import useLoadMentions from 'hsi/hooks/useLoadMentions';
import useMentionsUpdateTrigger from 'hsi/hooks/useMentionsUpdateTrigger';
import {useAppSelector} from 'hsi/hooks/useRedux';
import {MentionType} from 'hsi/types/mentions';
import {useMemo} from 'react';

export default function useConnectedMentions() {
    useMentionsUpdateTrigger();

    const results: MentionType[] | undefined = useAppSelector((state) => state.mentions.results);
    const error: boolean = useAppSelector((state) => state.mentions.error);
    const hasMore: boolean = useAppSelector((state) => state.mentions.hasMore);
    const loading: boolean = useAppSelector((state) => state.mentions.loading);

    const loadMentionsFunc = useLoadMentions(true);

    return useMemo(
        () => ({
            results,
            error,
            hasMore,
            loading,
            loadMentionsFunc,
        }),
        [results, error, hasMore, loading, loadMentionsFunc],
    );
}
