import {
    AuthenticationDataTypes,
    FacebookPageAuthentication,
    FacebookUserAuthentication,
    InstagramAccountAuthentication,
    LinkedinChannelAuthentication,
    TargetedDataPlatform,
    TrackableOwnedAuthenticationResponse,
    TrackableOwnedAuthentication,
    TrackableOwnedFacebookPageAuthentication,
    TrackableOwnedInstagramAccountAuthentication,
} from 'hsi/types/dataManagement';

/* Example facebookPageAuthentication response
{
    "_embedded": {
        "businessAuthenticationSummaryDTOList": [
            {
                "authenticated": false,
                "authenticatedForAtMentioned": false,
                "authenticatedForNonOwned": false,
                "instagramBusinessAccountId": "17841449079762815",
                "facebookPageId": "101326254932249",
                "handle": "bwilson5235",
                "displayName": "Ben",
                "userMostRecentlyAuthenticatedAt": "2021-12-02T10:02:50.518305Z",
                "pageMostRecentlyAuthenticatedAt": "2021-12-02T10:02:50.622867Z"
            },
        ]
    },
    "_links": {
        "self": {
            "href": "https://api.stage.brandwatch.net/instagram/v2/authentications?page=0&size=1000"
        }
    },
    "page": {
        "size": 1000,
        "totalElements": 8,
        "totalPages": 1,
        "number": 0
    }
}
*/

const toFacebookPageAuthentication = (authentication: any): FacebookPageAuthentication => ({
    authenticated: authentication.authenticated,
    created: authentication.mostRecentlyCreatedAt,
    fbPageId: authentication.pageId,
    name: authentication.name,
    platform: TargetedDataPlatform.Facebook,
    type: AuthenticationDataTypes.FacebookPageAuthentication,
    url: authentication.url,
});

export const formatFacebookPageAuthentications = (response: any) => {
    const authentications = response?._embedded?.pageAuthenticationSummaryDTOList;

    if (!authentications || !Array.isArray(authentications)) {
        return [];
    }

    return authentications.map((authentication) => toFacebookPageAuthentication(authentication));
};

/*
{
    "_embedded": {
        "userAuthenticationSummaryDTOList": [
            {
                "authenticated": true,
                "facebookUserId": "396175752403429",
                "facebookUserName": "Sam Foster",
                "facebookUserEmail": "sfoster@brandwatch.com",
                "created": "2022-10-12T14:30:34.581845Z",
                "expires": null,
                "deauthorisedAt": null,
                "reauthenticationRequestedAt": null,
                "expiresInXDays": 60,
                "status": {
                    "level": "OK",
                    "code": 0
                }
            },
        ]
    },
    "_links": {
        "self": {
            "href": "https://api.stage.brandwatch.net/instagram/v2/authentications/users?page=0&size=1000"
        }
    },
    "page": {
        "size": 1000,
        "totalElements": 10,
        "totalPages": 1,
        "number": 0
    }
}
*/

const toFacebookUserAuthentication = (authentication: any): FacebookUserAuthentication => ({
    authenticated: authentication.authenticated,
    created: authentication.created,
    email: authentication.facebookUserEmail,
    fbUserId: authentication.facebookUserId,
    name: authentication.facebookUserName,
    platform: TargetedDataPlatform.Facebook,
    type: AuthenticationDataTypes.FacebookUserAuthentication,
});

export const formatFacebookUserAuthentications = (response: any) => {
    const authentications = response?._embedded?.userAuthenticationSummaryDTOList;
    if (!authentications || !Array.isArray(authentications)) {
        return [];
    }

    return authentications.map((authentication) => toFacebookUserAuthentication(authentication));
};

/* Example instagram account authentication response
{
    "_embedded": {
        "businessAuthenticationSummaryDTOList": [
            {
                "authenticated": false,
                "authenticatedForAtMentioned": false,
                "authenticatedForNonOwned": false,
                "instagramBusinessAccountId": "17841449079762815",
                "facebookPageId": "101326254932249",
                "handle": "bwilson5235",
                "displayName": "Ben",
                "userMostRecentlyAuthenticatedAt": "2021-12-02T10:02:50.518305Z",
                "pageMostRecentlyAuthenticatedAt": "2021-12-02T10:02:50.622867Z"
            },
        ]
    },
    "_links": {
        "self": {
            "href": "https://api.stage.brandwatch.net/instagram/v2/authentications?page=0&size=1000"
        }
    },
    "page": {
        "size": 1000,
        "totalElements": 8,
        "totalPages": 1,
        "number": 0
    }
}
*/

const toInstagramAccountAuthentication = (authentication: any): InstagramAccountAuthentication => ({
    authenticated: authentication.authenticated,
    created:
        authentication.pageMostRecentlyAuthenticatedAt ||
        authentication.userMostRecentlyAuthenticatedAt,
    fbPageId: authentication.facebookPageId,
    instagramBusinessAccountId: authentication.instagramBusinessAccountId,
    name: authentication.displayName || authentication.handle,
    platform: TargetedDataPlatform.Instagram,
    type: AuthenticationDataTypes.InstagramAccountAuthentication,
});

export const formatInstagramAuthentications = (response: any) => {
    const authentications = response?._embedded?.businessAuthenticationSummaryDTOList;

    if (!authentications || !Array.isArray(authentications)) {
        return [];
    }

    return authentications.map((authentication) =>
        toInstagramAccountAuthentication(authentication),
    );
};

/*
[]
*/

const toLinkedinChannelAuthentication = (authentication: any): LinkedinChannelAuthentication => ({
    name: authentication?.userName,
    authenticated: authentication?.authenticated,
    uuid: authentication?.uuid,
    platform: TargetedDataPlatform.Linkedin,
    type: AuthenticationDataTypes.LinkedinChannelAuthentication,
});

export const formatLinkedinAuthentications = (response: any) => {
    const authentications = response;

    if (!authentications || !Array.isArray(authentications)) {
        return [];
    }

    return authentications.map((authentication) => toLinkedinChannelAuthentication(authentication));
};

const toTrackableOwnedFacebookPageAuthentication = (
    response: any,
): TrackableOwnedFacebookPageAuthentication => ({
    id: response.facebookPageId,
    name: response.facebookPageName,
    type: AuthenticationDataTypes.FacebookPageAuthentication,
});

const toTrackableOwnedInstagramAccountAuthentication = (
    response: any,
): TrackableOwnedInstagramAccountAuthentication => ({
    id: response.instagramBusinessAccountId,
    name: response.igbaDisplayName || response.igbaHandle,
    type: AuthenticationDataTypes.InstagramAccountAuthentication,
});

export const formatTrackableOwnedAuthentications = (
    response: TrackableOwnedAuthenticationResponse[],
): TrackableOwnedAuthentication[] => {
    if (!response || !Array.isArray(response)) {
        return [];
    }

    const trackableOwnedFacebookPageAuthentications = response
        .filter((response) => response?.authenticatedForFacebook && response?.facebookPageId)
        .map((authentication) => toTrackableOwnedFacebookPageAuthentication(authentication));

    const trackableOwnedInstagramAccountAuthentications = response
        .filter(
            (response) =>
                response.authenticatedForInstagram &&
                response.instagramBusinessAccountId &&
                (response.igbaDisplayName || response.igbaHandle),
        )
        .map((response) => toTrackableOwnedInstagramAccountAuthentication(response));

    return [
        ...trackableOwnedFacebookPageAuthentications,
        ...trackableOwnedInstagramAccountAuthentications,
    ];
};
