import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    root: {
        border: 0,
        padding: 0,

        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'start',
        gap: 8,
        marginLeft: theme.v2 ? 24 : 29,
    },
}));
