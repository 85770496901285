import {useEffect, useMemo} from 'react';

import NonOwnedInput from 'hsi/containers/DataManagement/AddDataSourceDialog/NonOwnedInput';
import PulseLoader from 'hsi/components/PulseLoader';
import UploadStatus from 'hsi/containers/DataManagement/AddDataSourceDialog/UploadStatus';

import useStyles from './styles';
import {useUploadZone} from 'hsi/components/UploadZone';

import tns from 'hsi/i18n/tns';
import {ChipErrorType} from 'hsi/components/ChipInput/ChipWithError';
import {ParseCsvErrorReasons} from 'hsi/hooks/useParseCsv';

const t = tns('dataManagement.addDataSourcesDialog.addNonOwnedFacebookPages');

type NonOwnedFacebookPageInputProps = {
    atMaxLength: boolean;
    error: boolean;
    maxLength: number;
    onPagesChange: (newValue: string[]) => boolean | undefined | void;
    openInfo: () => void;
    pages: string[];
    parserError?: ParseCsvErrorReasons;
    resetInput: () => void;
    uploadStatusTimeout?: number;
    validatePages: (newValue: string[]) => ChipErrorType[];
};

const NonOwnedFacebookPageInput = ({
    atMaxLength,
    error,
    maxLength,
    onPagesChange,
    openInfo,
    pages,
    parserError,
    resetInput,
    uploadStatusTimeout = 5000,
    validatePages,
}: NonOwnedFacebookPageInputProps) => {
    const classes = useStyles();
    const uploadZone = useUploadZone();
    const statusError = uploadZone.error || parserError;

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;

        if (uploadZone.file) {
            timer = setTimeout(() => uploadZone.reset(), uploadStatusTimeout);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [uploadStatusTimeout, uploadZone]);

    useEffect(() => {
        if (error) {
            uploadZone.reset();
        }
    }, [error, uploadZone]);

    const hasStatus = useMemo(() => {
        if (uploadZone.isLoading) {
            return 'loading';
        }

        if (statusError) {
            return 'failed';
        }

        if (uploadZone.file) {
            return 'success';
        }

        return undefined;
    }, [statusError, uploadZone.file, uploadZone.isLoading]);

    return (
        <NonOwnedInput
            atMaxLength={atMaxLength}
            error={error}
            hasStatus={hasStatus}
            helperText={t('helperText')}
            maxLength={maxLength}
            onInfoButtonClick={openInfo}
            onValuesChange={onPagesChange}
            onUploadCsvButtonClick={uploadZone.open}
            platform={t('platform')}
            renderFailedStatus={() => (
                <UploadStatus
                    iconName="warning"
                    itemsLength={pages.length}
                    maxItems={maxLength}
                    onCtaClick={() => {
                        resetInput();
                        uploadZone.reset();
                    }}
                    status={statusError}
                    type={t('type')}
                />
            )}
            renderLoadingStatus={() => (
                <div className={classes.statusContainer}>
                    <PulseLoader />
                </div>
            )}
            renderSuccessStatus={() => (
                <UploadStatus
                    file={uploadZone.file}
                    iconName="success"
                    itemsLength={pages.length}
                    maxItems={maxLength}
                    onCtaClick={uploadZone.reset}
                    type={t('type')}
                />
            )}
            type={t('type')}
            validateValues={validatePages}
            values={pages}
        />
    );
};

export default NonOwnedFacebookPageInput;
