import {useRef, useEffect, ReactNode} from 'react';

import MuiDialog, {DialogProps as MuiDialogProps} from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';

//Components
import DialogTitle from './Title';
import DialogCloseButton from './CloseButton';
import { HeadingContents, ResetHeadingLevel } from 'hsi/components/aria/Heading';

//Other
import {AddPortalRoot} from 'hsi/contexts/portal';
import {AriaAnnounceProvider} from 'hsi/hooks/useAriaAnnounce';


interface DialogProps extends Omit<MuiDialogProps, 'title'> {
    children?: ReactNode;
    className?: string;
    open: boolean;
    onClose?: () => void;
    onClosed?: () => void;
    /**
     * If you want a close button to be displayed, but disabled
     */
    disableCloseBtn?: boolean;
    noCloseBtn?: boolean;
    title?: ReactNode;
    titleInfo?: ReactNode;
}

const Dialog = ({
    title,
    titleInfo,
    onClose,
    onClosed,
    disableCloseBtn = false,
    noCloseBtn = false,
    children,
    className,
    open,
    ...other
}: DialogProps) => {
    //All this to make onClosed work
    const onClosedTIdRef = useRef<null | undefined | ReturnType<typeof setTimeout>>(null); //null is also used to check for first run - after that, we use undefined
    const leavingScreenDuration = 300; //could be configurable, but I don't think we need that right now - this is the default

    useEffect(
        () => {
            if (open) {
                if (onClosedTIdRef.current) {
                    clearTimeout(onClosedTIdRef.current);
                }

                onClosedTIdRef.current = undefined;
            } else {
                //on first run, this will be null - use this to prevent an initial call to onClosed when starting closed
                if (onClosedTIdRef.current !== null && onClosed) {
                    //if we get here, value has changed from true to false AND we have an onClosed handler
                    onClosedTIdRef.current = setTimeout(onClosed, leavingScreenDuration);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [!!open],
    );

    return (
        <ResetHeadingLevel>
            <MuiDialog className={className} onClose={onClose} open={!!open} {...other}>
                <AddPortalRoot>
                    <AriaAnnounceProvider disabled={!open}>
                        {!!title && <DialogTitle info={titleInfo}>{title}</DialogTitle>}
                        {!noCloseBtn && !!onClose && (
                            <DialogCloseButton disabled={disableCloseBtn} onClose={onClose} />
                        )}
                        <HeadingContents>
                            {children}
                        </HeadingContents>
                    </AriaAnnounceProvider>
                </AddPortalRoot>
            </MuiDialog>
        </ResetHeadingLevel>
    );
};

export default Dialog;
export {Dialog, DialogTitle, DialogCloseButton, DialogContent, DialogActions, DialogContentText};
