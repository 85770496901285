import {useCallback, useEffect, useMemo} from 'react';

import CheckboxOptionsMenu from './CheckboxOptionsMenu';

import useDataManagementConfig from 'hsi/hooks/useDataManagementConfig';
import {useInstagramAccounts} from 'hsi/hooks/useDataSource';
import {useAppDispatch} from 'hsi/hooks/useRedux';

import {toggleFacebookLoginDialog} from 'hsi/slices/facebookAuthentication';
import {
    isGuidedSearchInstagramOwnedAccount,
    inputAccountValueParser as parser,
} from 'hsi/containers/QueryBuilder/GuidedQueryForm/utils';
import {T} from 'hsi/i18n';

import {SelectAccountsProps} from '.';

interface SelectOwnedInstagramAccountsProps extends SelectAccountsProps {
    onBack: () => void;
}

const SelectOwnedInstagramAccounts = (props: SelectOwnedInstagramAccountsProps) => {
    const {onChange, setLoading, values} = props;
    const dispatch = useAppDispatch();

    const {maxDataSources} = useDataManagementConfig(); // TODO: Need to paginate this
    const instagramAccounts = useInstagramAccounts({defaultSize: maxDataSources});

    const defaultOwnedInstagramAccounts = useMemo(() => {
        const selectedIds = values
            .filter(isGuidedSearchInstagramOwnedAccount)
            .map(({igbid}) => igbid);

        return instagramAccounts?.activeOwned.filter(({id}) => selectedIds.includes(id));
    }, [instagramAccounts?.activeOwned, values]);

    const onAddPages = useCallback(
        (selected: typeof instagramAccounts.activeOwned) => {
            const otherPages = values.filter((item) => !isGuidedSearchInstagramOwnedAccount(item));
            const newIGItems = selected.map(parser.instagram);

            onChange([...otherPages, ...newIGItems]);
        },
        [instagramAccounts, onChange, values],
    );

    useEffect(() => {
        if (instagramAccounts?.isLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [instagramAccounts?.isLoading, setLoading]);

    return (
        <CheckboxOptionsMenu
            {...props}
            defaultOptions={defaultOwnedInstagramAccounts}
            labelField="name"
            menuTitle={T('queryBuilder.igAccounts')}
            onAddNewPage={() => dispatch(toggleFacebookLoginDialog(true))}
            onAddPages={onAddPages}
            options={instagramAccounts?.activeOwned}
            valueField="id"
        />
    );
};

export default SelectOwnedInstagramAccounts;
