import React, {useState, useEffect, useRef} from 'react';
import qs from 'qs';

export const UrlFlagsContext = React.createContext({});
UrlFlagsContext.displayName = 'UrlFlagsContext';

const UrlFlagsProvider = ({children}) => {
    const [urlFlags, setUrlFlags] = useState(parseFlags(document.location.hash.replace('#', '')));

    //maintain ref to current setUrlFlags func & value
    const urlFlagsRef = useRef({});
    urlFlagsRef.current.val = urlFlags;
    urlFlagsRef.current.set = setUrlFlags;

    //register & tidy up event handlers
    useEffect(() => {
        const handler = () => {
            const hash = document.location.hash.replace('#', '');

            if (hash) {
                urlFlagsRef.current.set(parseFlags(hash, urlFlagsRef.current.val));
            }
        };

        window.addEventListener('hashchange', handler);

        return () => {
            window.removeEventListener('hashchange', handler);
        };
    });

    return <UrlFlagsContext.Provider value={urlFlags}>{children}</UrlFlagsContext.Provider>;
};

export default UrlFlagsProvider;

function parseFlags(str, currentFlags = null) {
    const obj = qs.parse(str);

    for (var key in obj) {
        if (obj[key] === 'true') {
            obj[key] = true;
        } else if (obj[key] === 'false') {
            obj[key] = false;
        }
    }

    return currentFlags ? {...currentFlags, ...obj} : obj;
}
