import format, {NumberFormatType} from 'hsi/utils/number/format';
import {ReactElement} from 'react';

export type NumberProps = {
    value: number;
    format?: NumberFormatType;
    locale?: string | string[];
};

export default function Number({
    value,
    format: formatName = 'number',
    locale = 'en-US',
}: NumberProps) {
    return format(value, formatName, locale) as unknown as ReactElement; //Need to make this cast as react typescript binding incorrectly do not include string
}
