import { isV2 } from 'hsi/types/theme';
import { makeStyles } from 'tss-react/mui';

export default makeStyles<void, 'open'>()((theme, _params, classes) => {
    const v2 = isV2(theme);
    
    return {
        editSearchPopover: {
            overflow: 'hidden',
            height: 0,

            [`&.${classes.open}`]: {
                height: 'auto',
                border: v2 ? `1px solid ${theme.colors.borders}` : undefined,
                boxShadow: v2 ? `0px 4px 9px ${theme.colors.borders}` : undefined,
                backgroundColor: v2 ? theme.colors.white : undefined,
                marginTop: v2 ? '4px' : undefined,
            },
        },
        searchBar: {
            backgroundColor: theme.colors.white,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',

            border: v2 ? `1px solid ${theme.colors.borders}` : undefined,
            borderRadius: v2 ? '3px' : undefined,

            justifyContent: v2 ? undefined : 'center',
        },
    editGuidedSearchBtn: {
            ...theme.mixin.blankButton,
            position: 'absolute',
            zIndex: 100,
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
        },
        searchBarEditor: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        },
        guidance: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',

            margin: v2 ? '6px 17px' : '6px',

            fontStyle: v2 ? undefined : 'italic',
        },
        charLimit: {
            textAlign: v2 ? 'right' : undefined,
        },
        charLimitError: {
            backgroundColor: v2 ? theme.colors.uiGrayDefault : theme.colors.primaryRed10,
            
            margin: v2 ? '12px 0' : undefined,
            
            padding: v2 ? undefined : 8,
        },
        searchBarIcon: {
            width: v2 ? 38 : 42,
            height: v2 ? 38 : 42,
            flex: v2 ? '0 0 38px' : '0 0 42px',
            fill: v2 ? theme.colors.bgOverlayHeavy : theme.colors.darkGrey100,
            padding: v2 ? 10 : 12,

            zIndex: v2 ? 2 : undefined,
        },
        searchBarCollapseBtn: {
            width: v2 ? 38 : 42,
            height: v2 ? 38 : 42,
            flex: '0 0 42px',
            backgroundColor: 'transparent !important',
        },
        searchBarCollapseBtnIcon: {
            width: v2 ? 14 : 12,
        },
        validationLoader: {
            display: 'block',
            position: 'absolute',
            zIndex: 20,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: v2 ? theme.colors.white : 'rgba(255,255,255, 0.75)',
            opacity: v2 ? 0.75 : undefined,
        },
        validationAnim: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        hr: {
            display: v2 ? undefined : 'none',
            margin: v2 ? '10px 24px 0 24px' : undefined,
        },
        guidedOptsContainer: {
            backgroundColor: 'white',

            borderTop: v2 ? undefined : `1px solid ${theme.colors.darkGrey20}`,
            paddingTop: v2 ? undefined : 16,
        },
        guidedOptHeader: {
            ...v2 
                ? theme.fonts.buttonNormal 
                : {fontSize: 24, fontWeight: 'bold'},
            marginTop: 0,
            marginBottom: 0,
            marginLeft: v2 ? 24 : 16,
            height: v2 ? '48px' : undefined,
            lineHeight: v2 ? '48px' : undefined,
            verticalAlign: v2 ? 'middle' : undefined,
        },
        guidedOptsList: {
            borderTop: v2 ? `1px solid ${theme.colors.borders}` : undefined,
        },
        guidedOptItem: {
            display: v2 ? 'flex' : undefined,
            paddingLeft: v2 ? 24 : 16,
            paddingRight: v2 ? 24 : 16,
            color: v2 ? theme.colors.carbon : undefined,
            '& .MuiListItemText-primary': {
                ...(v2 ? theme.fonts.bodyNormal : {fontWeight: 'normal'}),
            },
            '&:hover': {
                color: v2 ? theme.colors.accentDefault : theme.colors.secondaryBlue100,
            },
        },
        guidedOptItemArrow: {
            fill: v2 ? theme.colors.accentDefault : theme.colors.secondaryBlue100,
            transform: 'rotate(180deg)',
        },
        recentQueriesContainer: {
            backgroundColor: 'white',
            margin: v2 ? '5px 24px 18px 24px' : undefined,
            ...(v2 ? theme.fonts.bodyNormal : {fontSize: 16}),
            
            borderTop: v2 ? undefined : `1px solid ${theme.colors.darkGrey20}`,
            padding: v2 ? undefined : 16,
        },
        recentQueriesTitle: {
            ...(v2 ? theme.fonts.buttonNormal : {fontSize: 16}),
            margin: 0,
            paddingTop: v2 ? '5px' : undefined,
            height: v2 ? '48px' : undefined,
            lineHeight: v2 ? '48px' : '20px',
            verticalAlign: v2 ? 'middle' : undefined,
        },
        recentQueriesItem: {
            '& .MuiListItemText-primary': {
                fontWeight: 'normal',
            },
        },
        errorContainer: {
            backgroundColor: v2 ? theme.colors.uiGrayDefault : theme.colors.primaryRed10,
            padding: 16,
            margin: v2 ? '12px 0' : undefined,
        },
        searchHelpButton: {
            borderTop: `1px solid ${theme.colors.darkGrey20}`,
            paddingLeft: 14,
        },
        queryEditor: {
            marginLeft: v2 ? -8 : undefined,
        },
        open: {},
    };
});
