import React from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import cn from 'classnames';
import IconButton from '@mui/material/IconButton';
import MuiSnackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import {useTheme} from '@mui/material';

//Components
import IconRouter from 'hsi/components/IconRouter';
import Markdown from 'hsi/components/Markdown';

//Hooks
import useConfig from 'hsi/hooks/useConfig';

//Actions
import {hideNotification} from 'hsi/actions/notificationsActions';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Consts
const variantIcon = {
    success: 'success',
    warning: 'warning',
    info: 'c-info',
};

const handleClose = (dispatch, reason) => {
    reason !== 'clickaway' && dispatch(hideNotification());
};

export default function SnackBarNotification() {
    const classes = useStyles();
    const {components} = useTheme();
    const {customSnackbars} = useConfig();

    //Redux
    const dispatch = useDispatch();
    const {
        variant,
        message,
        title,
        isOpen,
        anchorOrigin,
        hidable = true,
        notificationId,
        markdown,
    } = useSelector((state) => state.notifications);
    
    //Computed values
    const CustomComponent = components?.[customSnackbars?.[notificationId]] ?? null;

    const actions = hidable ? (
        <IconButton
            key="close"
            aria-label={T('snackBar.dismissLbl')}
            color="inherit"
            onClick={() => handleClose(dispatch)}
            size="large"
        >
            <IconRouter aria-hidden name="mui-close" className={classes.icon} />
        </IconButton>
    ) : null;

    //Render
    return (
        <>
            {CustomComponent && <CustomComponent />}
            <MuiSnackbar
                anchorOrigin={anchorOrigin}
                open={isOpen && !CustomComponent}
                autoHideDuration={200000000}
                onClose={(ev, reason) => handleClose(dispatch, reason)}
                className={classes.rootClass}
                data-testid="snackbar"
            >
                <SnackbarContent
                    className={classes[variant]}
                    aria-describedby="client-snackbar"
                    message={
                        <div id="client-snackbar" className={classes.message}>
                            <div className={cn(classes.iconWrapper, classes[variant + 'Wrapper'])}>
                                <IconRouter
                                    name={variantIcon[variant]}
                                    className={cn(classes.icon, classes[variant + 'Icon'])}
                                />
                            </div>
                            <div className={classes.messageWrapper}>
                                {!!title && <div className={classes.messageTitle}>{title}</div>}
                                <div>{markdown ? <Markdown>{message}</Markdown> : message}</div>
                            </div>
                        </div>
                    }
                    action={actions}
                    classes={{
                        root: classes.root,
                        message: classes.message,
                        action: classes.action,
                    }}
                />
            </MuiSnackbar>
        </>
    );
}

SnackBarNotification.propTypes = {
    className: PropTypes.string,
    message: PropTypes.object,
    handleClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'info']),
};
SnackBarNotification.defaultProps = {
    variant: 'success',
};
