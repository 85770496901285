import React, {useState} from 'react';
import PropTypes from 'prop-types';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import RawTextButton from 'hsi/containers/QueryBuilder/components/RawTextButton';

import {renderComponents} from './baseComponents';

import useComponentsBaseStyles from './baseStyles';

import tns from 'hsi/i18n/tns';
const t = tns('queryBuilder');

const AdvancedOptions = ({components, formula, onChange, sectionName}) => {
    const ccl = useComponentsBaseStyles();
    const [open, setOpen] = useState(false);

    return (
        <div className={ccl.container}>
            <RawTextButton
                size="small"
                endIcon={open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                onClick={() => setOpen((prev) => !prev)}
            >
                {t('showAdvancedOptions')}
            </RawTextButton>
            {open && (
                <div>
                    {renderComponents({
                        components,
                        sectionName,
                        formula,
                        onChange,
                    })}
                </div>
            )}
        </div>
    );
};

AdvancedOptions.propTypes = {
    components: PropTypes.array.isRequired,
    formula: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    sectionName: PropTypes.string.isRequired,
};

export default AdvancedOptions;
