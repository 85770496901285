import React from 'react';
import IconButton from '@mui/material/IconButton';

import Button from 'hsi/components/Button';
import IconRouter from 'hsi/components/IconRouter';
import Tooltip from 'hsi/components/Tooltip';
import ConditionalButton from 'hsi/components/ConditionalButton';

//Hooks
import useIsMultipleSearch from 'hsi/hooks/useIsMultipleSearch';

//Other
import {T} from 'hsi/i18n';

//Consts
const useIsNotMultipleSearch = () => !useIsMultipleSearch();

export function ManageSearchButton({savedSearch, ...props}) {
    return (
        <ConditionalButton
            useConditionHook={useIsNotMultipleSearch}
            disabledTooltip={T('multipleSearch.editSearchTooltip', {name: savedSearch.name})}
            enabledToolTip={T('savedsearchbar.managesearch')}
        >
            <IconButton {...props} aria-label={T('savedsearchbar.managesearch')} size="large">
                <IconRouter name="pencil" />
            </IconButton>
        </ConditionalButton>
    );
}

export function TagsButton(props) {
    return (
        <Tooltip tooltip={T('savedsearchbar.managetags')}>
            <IconButton {...props} aria-label={T('savedsearchbar.managetags')} size="large">
                <IconRouter name="tag" />
            </IconButton>
        </Tooltip>
    );
}

export function AlertsButton(props) {
    return (
        <ConditionalButton
            useConditionHook={useIsNotMultipleSearch}
            disabledTooltip={T('multipleSearch.alertsButtonTooltip')}
        >
            <Button {...props} priority="standard">
                {T('savedsearchbar.createalert')}
            </Button>
        </ConditionalButton>
    );
}
