import {createAsyncThunk} from '@reduxjs/toolkit';
//Services
import {
    createAlert as serviceCreateAlert,
    deleteAlert as serviceDeleteAlert,
    loadAlerts as serviceLoadAlerts,
    updateAlert as serviceUpdateAlert,
} from 'hsi/services/alertService';

import {showNotification} from 'hsi/actions/notificationsActions';
import {RootReducer} from 'hsi/reducers/rootReducer';

//Consts
import {DELETE_ALERT_ERROR} from 'hsi/constants/actionTypes';
import {ProjectType} from 'hsi/types/shared';
import {ApiAlert} from 'hsi/types/alerts';

import {normaliseAPIError} from 'hsi/utils/normaliseApiError'

export type AlertsLoadRejectedPayload = {httpCode: number | undefined; errors: any};
export type AlertsLoadThunkApi = {rejectValue: AlertsLoadRejectedPayload};

export const loadAlerts = createAsyncThunk<
    ApiAlert[],
    {projects: ProjectType[]},
    AlertsLoadThunkApi
>('alerts/loadAlerts', async ({projects}, {dispatch, rejectWithValue}) => {
    try {
        const result = await serviceLoadAlerts(projects);
        
        return result;
    } catch (e) {
        const normalisedError = normaliseAPIError(e as any, 'alert.errors.load');

        dispatch(
            showNotification({
                variant: 'warning',
                message: normalisedError.message,
            }),
        );

        return rejectWithValue(normalisedError);
    }
});

type saveAlertTypes = {
    alert: Omit<ApiAlert, 'id'>;
    successMessage: any;
    onSuccess: {(): any} | null;
};

export const saveAlert = createAsyncThunk<
    ApiAlert,
    saveAlertTypes,
    {rejectValue: any}
>('alerts/saveAlert', async ({alert, successMessage, onSuccess}, {dispatch, rejectWithValue}) => {
    try {
        const result = await serviceCreateAlert(alert);
        
        if(result.ok) {
            if (successMessage) {
                dispatch(
                    showNotification({
                        message: successMessage,
                        variant: 'success',
                    }),
                );
            }
            onSuccess?.();
        } 
        else {
            //TODO actually handle error
            throw new Error("Failed to create alert");
        }
        
        return result.body;
    } catch (e) {
        return rejectWithValue(e);
    }
});

type updateAlertTypes = {
    alert: ApiAlert;
    successMessage: any;
    onSuccess: {(): any} | null;
};

export const updateAlert = createAsyncThunk<ApiAlert, updateAlertTypes, {rejectValue: any}>(
    'alerts/updateAlert',
    async ({alert, successMessage, onSuccess}, {dispatch, rejectWithValue}) => {
        try {
            const result = await serviceUpdateAlert(alert);
            
            if(result.ok) {
                if (successMessage) {
                    dispatch(
                        showNotification({
                            message: successMessage,
                            variant: 'success',
                        }),
                    );
                }
                onSuccess?.();
            } else {
                //TODO actually handle error
                throw new Error("Failed to update alert");
            }
            
            return result.body;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

type deleteAlertTypes = {
    alertId: number;
    successMessage: any;
    onSuccess: {(): any} | null;
};

export const deleteAlert = createAsyncThunk<
    number | undefined,
    deleteAlertTypes,
    {state: RootReducer; rejectValue: AlertsLoadRejectedPayload}
>(
    'alerts/deleteAlert',
    async ({alertId, successMessage, onSuccess}, {dispatch, getState, rejectWithValue}) => {
        const state = getState();
        const {alerts} = state as RootReducer;
        const alert: ApiAlert | null = alerts.alertsById[alertId];
        if (!alert) {
            dispatch({
                type: DELETE_ALERT_ERROR,
                payload: `Alert with id: ${alertId} not found`,
            });

            return;
        }
        try {
            const result = await serviceDeleteAlert(alertId, alert.projectId);

            if(result.ok) {
                if (successMessage) {
                    dispatch(
                        showNotification({
                            message: successMessage,
                            variant: 'success',
                        }),
                    );
                }
                onSuccess?.();
                return alertId;
            } else {
                console.log('delete alert error: ', result);
                throw new Error("Error deleting alert");//TODO better error handling
            }
            
        } catch (e) {
            const normalisedError = normaliseAPIError(e as any, 'alert.errors.delete');

            dispatch(
                showNotification({
                    variant: 'warning',
                    message: normalisedError.message,
                }),
            );

            return rejectWithValue(normalisedError);
        }
    },
);
