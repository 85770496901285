import find from 'lodash/find';

import getConfig from 'hsi/config';
import { CardType } from 'hsi/types/cards';
import { AppConfigType } from 'hsi/types/config';
import useIsSavedSearch from 'hsi/hooks/useIsSavedSearch';
import { useConfig } from 'hsi/contexts/ConfigContext';
import { useMemo } from 'react';

export default function getCardTypeLimit(type: CardType, isSavedSearch: boolean) {
    const {
        searchResults: {cardRules, cardDefaultPerPage},
    } = getConfig();

    return getCardTypeLimitFromRules(type, cardRules, isSavedSearch, cardDefaultPerPage);

}

export function useCardTypeLimit(type: CardType) {
    const isSavedSearch = useIsSavedSearch();
    const {
        searchResults: {cardRules, cardDefaultPerPage},
    } = useConfig();

    return useMemo(() => getCardTypeLimitFromRules(type, cardRules, isSavedSearch, cardDefaultPerPage), [cardRules, isSavedSearch, type, cardDefaultPerPage])
}

function getCardTypeLimitFromRules(type: CardType, cardRules: AppConfigType['searchResults']['cardRules'], isSavedSearch: boolean, defaultPerPage: number) {
    const rules = find(cardRules, ({name}) => name === type);

    return {
        limit: (isSavedSearch ? rules?.savedSearchLimit : rules?.quickSearchLimit) ?? undefined,
        perPage: rules?.itemsPerPage ?? defaultPerPage
    };
}