import {MutableRefObject, PropsWithChildren, createContext, useContext, useRef} from 'react';

const portalRootId = 'portalRootElem';

function getPortalRoot() {
    const root = document.getElementById(portalRootId);

    if (root) {
        return root;
    }

    const newRoot = document.createElement('div');
    newRoot.id = portalRootId;
    document.body.append(newRoot);

    return newRoot;
}

const portalRootElem = getPortalRoot();

const context = createContext<MutableRefObject<HTMLElement | null> | null>(null);
context.displayName = 'PortalContext';

export function useGetPortalRoot() {
    const portalRootRef = useContext(context);

    return portalRootRef?.current ?? portalRootElem;
}

export function AddPortalRoot({children}: PropsWithChildren) {
    const portalRootRef = useRef<HTMLElement>(null);

    //TODO wait until ref is set before displaying children?
    return (
        <context.Provider value={portalRootRef}>
            {children}
            <div ref={portalRootRef as any} />
        </context.Provider>
    );
}
