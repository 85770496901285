import { forwardRef, useMemo, ReactElement, ForwardedRef } from "react";
import { TableSortDir } from "../../types";

// Components
import IconRouter from "hsi/components/IconRouter";

// Other
import useStyles from './styles';
import classNames from "classnames";
import TruncateWithTooltip from "hsi/components/TruncateWithTooltip";

// Types

type SortIndicatorBaseProps = {
    sort?: TableSortDir;
    truncateTextContent?: boolean;
};

export type SortIndicatorButtonProps = SortIndicatorBaseProps & JSX.IntrinsicElements['button'];
export type SortIndicatorProps = SortIndicatorBaseProps & Omit<JSX.IntrinsicElements['div'], 'onClick'>;

type SortIndicatorType = {
    (props: SortIndicatorButtonProps & {ref?: ForwardedRef<HTMLElement>}): (ReactElement | null);
    (props: SortIndicatorProps & {ref?: ForwardedRef<HTMLElement>}): (ReactElement | null);
}


//The component
const SortIndicator = forwardRef<HTMLElement, SortIndicatorButtonProps & SortIndicatorProps>(function SortIndicator({children, className, sort, truncateTextContent, onClick, ...rest}, ref) {
    const classes = useStyles();

    const content = useMemo(() => <>
        {truncateTextContent 
            ? <TruncateWithTooltip as="span" className={classes.content}>{children}</TruncateWithTooltip>
            : <span className={classes.content}>{children}</span>}
        {!!sort && <IconRouter aria-hidden className={classes.icon} name={`arrow-drop-${getArrowDir(sort)}`} />}
    </>, [children, classes.content, classes.icon, sort, truncateTextContent]);

    if(onClick) {
        return <button className={classNames(classes.sortButton, className, classes.active)} onClick={onClick} {...rest} ref={ref as any}>
            {content}
        </button>
    }

    return <div className={classNames(classes.sortButton, className)} {...rest} ref={ref as any}>
        {content}
    </div>
});

export default SortIndicator as SortIndicatorType;



function getArrowDir(sort: TableSortDir) {
    return sort === 'desc' ? 'down' : 'up';
}