import {
    CLEAR_REPORT_ERROR,
    CLEAR_REPORT_SUCCESS,
    CREATE_REPORT,
    DELETE_REPORT,
    EDIT_REPORT,
    LOAD_REPORTS,
    LOAD_REPORTS_ERROR,
    LOADING_REPORT_ITEM,
    LOADING_REPORTS,
    REPORT_ERROR,
    TOGGLE_REPORT,
} from 'hsi/constants/actionTypes';

const initialState = {
    data: [],
    error: false,
    itemError: false,
    itemLoading: null,
    itemSuccess: false,
    loading: true,
    success: false,
};

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_REPORT:
            return {
                ...state,
                data: state.data.concat(action.payload.report),
                itemError: false,
                itemLoading: false,
                itemSuccess: true,
            };
        case REPORT_ERROR:
            return {
                ...state,
                itemError: action.payload.report,
                itemLoading: false,
            };
        case LOAD_REPORTS:
            return {
                ...state,
                data: action.payload.reports,
                error: false,
                loading: false,
                success: true,
            };
        case LOADING_REPORT_ITEM:
            return {
                ...state,
                itemLoading: action.payload.report,
            };
        case LOADING_REPORTS:
            return {
                ...state,
                loading: true,
            };
        case LOAD_REPORTS_ERROR:
            return {
                ...state,
                error: true,
                success: false,
            };
        case EDIT_REPORT:
        case TOGGLE_REPORT:
            return {
                ...state,
                data: state.data.map((report) =>
                    report.id === action.payload.report.id ? action.payload.report : report,
                ),
                error: false,
                itemLoading: null,
                itemSuccess: true,
            };
        case DELETE_REPORT:
            return {
                ...state,
                data: state.data.filter((report) => report.id !== action.payload.report.id),
                error: false,
                itemLoading: null,
                itemSuccess: true,
            };
        case CLEAR_REPORT_SUCCESS:
            return {
                ...state,
                itemSuccess: false,
                success: false,
            };
        case CLEAR_REPORT_ERROR:
            return {
                ...state,
                error: false,
                itemError: false,
            };
        default:
            return state;
    }
};

export default reportReducer;
