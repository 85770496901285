import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => {
    if (theme.v2)
        return {
            totalsContainer: {
                display: 'flex',
                flexFlow: 'row wrap',
                margin: 0,
                '& .total': {
                    flex: '0 0 50%',
                    display: 'flex',
                    flexFlow: 'row wrap',
                    alignItems: 'center',
                    color: theme.colors.textDefault,
                    paddingBottom: '25px',
                    '& .app-icon': {
                        width: '22px',
                        height: '22px',
                        marginLeft: '-4px',
                        '&.icon-trend-down': {
                            fill: theme.colors.negative,
                        },
                        '&.icon-trend-up': {
                            fill: theme.colors.positive,
                        },
                    },

                    '& .title': {
                        fontSize: '18px',
                        flex: '0 0 100%',
                        paddingBottom: '5px',
                    },
                    '& .results': {
                        alignSelf: 'stretch',
                        display: 'flex',
                        alignItems: 'center',
                        margin: 0,
                        padding: '0 5px',
                        '& .number': {
                            fontSize: '33px',
                            color: theme.colors.textDefault,
                            textTransform: 'uppercase',
                            fontWeight: theme.font.bold,
                        },
                        '& .comparison': {
                            fontSize: '13px',
                            '& .percentage': {
                                display: 'flex',
                                alignItems: 'center',
                                '& span': {
                                    fontWeight: theme.font.bold,
                                    fontSize: '17px',
                                },
                            },
                            '& .previousPercentage': {
                                textTransform: 'uppercase',
                                fontWeight: theme.font.bold,
                                paddingLeft: '4px',
                            },
                        },
                        '& .slash': {
                            '&:after': {
                                display: 'inline-block',
                                content: "''",
                                borderRight: `1px solid ${theme.colors.borders}`,
                                WebkitTransform: 'rotate(15deg)',
                                transform: 'rotate(15deg)',
                                height: '3rem',
                                paddingRight: '15px',
                                marginRight: '15px',
                            },
                        },
                    },
                },
            },
        };
    else
        return {
            totalsContainer: {
                display: 'flex',
                flexFlow: 'row wrap',
                margin: 0,
                '& .total': {
                    flex: '0 0 50%',
                    display: 'flex',
                    flexFlow: 'row wrap',
                    alignItems: 'center',
                    color: theme.colors.darkGrey100,
                    paddingBottom: '25px',
                    '& .app-icon': {
                        width: '22px',
                        height: '22px',
                        marginLeft: '-4px',
                        '&.icon-trend-down': {
                            fill: theme.colors.negative,
                        },
                        '&.icon-trend-up': {
                            fill: theme.colors.positive,
                        },
                    },

                    '& .title': {
                        fontSize: '18px',
                        flex: '0 0 100%',
                        paddingBottom: '5px',
                    },
                    '& .results': {
                        alignSelf: 'stretch',
                        display: 'flex',
                        alignItems: 'center',
                        margin: 0,
                        '& .number': {
                            fontSize: '33px',
                            color: theme.colors.primaryBlue100,
                            textTransform: 'uppercase',
                            fontWeight: theme.font.bold,
                        },
                        '& .comparison': {
                            fontSize: '13px',
                            '& .percentage': {
                                display: 'flex',
                                alignItems: 'center',
                                '& span': {
                                    fontWeight: theme.font.bold,
                                    fontSize: '17px',
                                },
                            },
                            '& .previousPercentage': {
                                textTransform: 'uppercase',
                                fontWeight: theme.font.bold,
                                paddingLeft: '4px',
                            },
                        },
                        '& .slash': {
                            '&:after': {
                                display: 'inline-block',
                                content: "''",
                                borderRight: `1px solid ${theme.colors.darkGrey100}`,
                                WebkitTransform: 'rotate(15deg)',
                                transform: 'rotate(15deg)',
                                height: '3rem',
                                paddingRight: '15px',
                                marginRight: '15px',
                            },
                        },
                    },
                },
            },
        };
});
