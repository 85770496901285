import React, {useMemo} from 'react';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

//Components
import HistoryCard from 'hsi/components/Cards/HistoryCard';
import CardTitle from 'hsi/components/Card/CardTitle';
import InfoPopupContent from 'hsi/components/InfoPopupContent';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useDates from 'hsi/hooks/useDates';
import useGetLoadData from './useGetLoadData';

//Other
import {T} from 'hsi/i18n';
const type = 'emotionHistory';

//The components
const EmotionHistory = React.forwardRef(({title, ...props}, ref) => {
    const {
        links: {dashboardInfoSentimentHistoryCTA: popupCTA},
    } = useConfig();
    const {formatTo} = useDates();

    //Redux
    const breakdown = useSelector((state) => state.cardPersistState[type]).breakdown;
    const {data, loaded, loading, error} = useSelector((state) => state.chart[type]);

    const loadData = useGetLoadData(type);

    const popup = useMemo(
        () => (
            <InfoPopupContent
                copy={T(`cards.${type}.info.copy`)}
                ctaLabel={T('cards.infoCTALabel')}
                ctaUrl={popupCTA}
                title={T(`cards.${type}.info.title`)}
            />
        ),
        [popupCTA],
    );

    const config = {
        drillInLabel: (item) =>
            `${capitalize(item?.key)} - ${formatTo(
                item?.date,
                breakdown === 'months' ? 'LLL yyyy ZZZZ' : 'DD ZZZZ',
            )}`,
        filters: (item) => ({classifications: {[item?.key.toLowerCase()]: true}}),
    };

    return (
        <HistoryCard
            {...props}
            title={
                <CardTitle title={title} tooltipComponent={popup} type={type} hasData={!!data} />
            }
            breakdown={breakdown}
            aggregate="volume"
            config={config}
            data={data}
            error={error}
            loaded={loaded}
            loading={loading}
            trackingName={{
                // Historic tracking names, to be removed
                customized: 'Clicked to customise Volume by Emotion History',
                drillIn: 'Volume by Emotion History Drill In',
            }}
            type={type}
            loadData={loadData}
            ref={ref}
            data-testid={type}
        />
    );
});

EmotionHistory.propTypes = {
    props: PropTypes.shape({
        fadeOnVisible: PropTypes.func.isRequired,
        height: PropTypes.number.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onContentChanged: PropTypes.func.isRequired,
        showConfig: PropTypes.bool.isRequired,
    }),
};

EmotionHistory.displayName = 'EmotionHistory';

export default EmotionHistory;
