//TODO this component is not really typed, but I think this whole intro concept needs a total
//redesign, so there doesn't seem to be much point to putting effort into making sense
//out of this mess
import {useCallback, useMemo, useEffect, useState, memo} from 'react';

import useCondition from './useCondition';
import Item from './Item';
import {isIntroFinished} from './storage';

type IntroProps = {sequence: any[]};

const Intro = ({sequence}: IntroProps) => {
    const remove = useCallback(
        (seq: any, _id: any) => seq.filter(({id}: {id: string}) => id !== _id),
        [],
    );
    const unfinished = useCallback(
        (seq: any) =>
            seq.filter(({id, disabled}: {id: string; disabled?: boolean}) => {
                const isFinished = isIntroFinished(id);

                return !isFinished && !disabled;
            }),
        [],
    );

    const defaultSeq = useMemo(() => unfinished(sequence), [sequence, unfinished]);

    const [seq, setSeq] = useState(defaultSeq);
    const [config, setConfig] = useState(seq[0]);
    const passed = useCondition(config || {});

    const next = useCallback(
        (seq: any, config: any) => {
            const nextSeq = unfinished(remove(seq, config?.id));
            if (nextSeq.length === 0) return;
            setSeq(nextSeq);
            setConfig(nextSeq[0]);
        },
        [remove, unfinished],
    );

    const onFinished = useCallback(() => next(seq, config), [config, next, seq]);

    useEffect(() => {
        if (passed === false) next(seq, config);
    }, [config, next, passed, seq, unfinished]);

    if (!config || !passed) {
        return null;
    }

    return <Item {...config} onFinished={onFinished} />;
};

export * from './config';

export default memo(Intro);
