/**
 * Action Types
 */

export const CLEAR_ALERT_ERROR = 'CLEAR_ALERT_ERROR';
export const CLEAR_ALERT_SUCCESS = 'CLEAR_ALERT_SUCCESS';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_REPORT_ERROR = 'CLEAR_REPORT_ERROR';
export const CLEAR_REPORT_SUCCESS = 'CLEAR_REPORT_SUCCESS';
export const CREATE_REPORT = 'CREATE_REPORT';
export const CLEAR_RESULTS = 'CLEAR_RESULTS';
export const DATA_LOADED = 'DATA_LOADED';
export const DATA_ABORTED = 'DATA_ABORTED';
export const DELETE_ALERT = 'DELETE_ALERT';
export const DELETE_REPORT = 'DELETE_REPORT';
export const DELETE_SEARCH = 'DELETE_SEARCH';
export const DELETED_TAG = 'DELETED_TAG';
export const EDIT_ALERT = 'EDIT_ALERT';
export const EDIT_MENTION = 'EDIT_MENTION';
export const EDIT_REPORT = 'EDIT_REPORT';
export const EDITED_TAG = 'EDITED_TAG';
export const EXPORT_MENTIONS = 'EXPORT_MENTIONS';
export const HIDE_SNACKBAR_NOTIFICATION = 'HIDE_SNACKBAR_NOTIFICATION';
export const LOAD_ALERTS = 'LOAD_ALERTS';
export const LOADING_CATEGORIES = 'LOADING_CATEGORIES';
export const LOADED_CATEGORIES = 'LOADED_CATEGORIES';
export const LOAD_CATEGORIES_ERROR = 'LOAD_CATEGORIES_ERROR';
export const LOADING_ASSIGNMENT = 'LOADING_ASSIGNMENT';
export const LOADED_ASSIGNMENT = 'LOADED_ASSIGNMENT';
export const LOAD_ASSIGNMENT_ERROR = 'LOAD_ASSIGNMENT_ERROR';
export const LOAD_ERROR = 'LOAD_ERROR';
export const LOAD_LANGUAGES = 'LOAD_LANGUAGES';
export const LOAD_LANGUAGES_ERROR = 'LOAD_LANGUAGES_ERROR';
export const LOAD_LOCATIONS = 'LOAD_LOCATIONS';
export const LOAD_LOCATIONS_ERROR = 'LOAD_LOCATIONS_ERROR';
export const LOAD_REPORTS = 'LOAD_REPORTS';
export const LOAD_REPORTS_ERROR = 'LOAD_REPORTS_ERROR';
export const LOADING_REPORT_ITEM = 'LOADING_REPORT_ITEM';
export const LOADING_REPORTS = 'LOADING_REPORTS';
export const SET_SEARCHES_LOADING = 'SET_SEARCHES_LOADING';
export const LOAD_SEARCHES = 'LOAD_SEARCHES';
export const SET_SEARCH_LOADING = 'SET_SEARCH_LOADING';
export const LOADED_LANGUAGES = 'LOADED_LANGUAGES';
export const LOADED_LOCATIONS = 'LOADED_LOCATIONS';
export const LOADING_DATA = 'LOADING_DATA';
export const LOADING_LANGUAGES = 'LOADING_LANGUAGES';
export const LOADING_LOCATIONS = 'LOADING_LOCATIONS';
export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const LOG_IN_LOADING = 'LOG_IN_LOADING';
export const SET_LOGIN_LOADING_MSG = 'SET_LOGIN_LOADING_MSG';
export const MENTIONS_DRILL_IN = 'mentions/mentionsDrillIn';
export const MENTIONS_DRILL_OUT = 'MENTIONS_DRILL_OUT';
export const MENTIONS_LOAD_ERROR = 'MENTIONS_LOAD_ERROR';
export const MENTIONS_LOADED = 'MENTIONS_LOADED';
export const MENTIONS_LOADING = 'MENTIONS_LOADING';
export const MENTIONS_TABLE_ERROR = 'MENTIONS_TABLE_ERROR';
export const MENTIONS_TABLE_LOADED = 'MENTIONS_TABLE_LOADED';
export const MENTIONS_TABLE_LOADING = 'MENTIONS_TABLE_LOADING';
export const MENTIONS_SET_ORDER = 'MENTIONS_SET_ORDER';
export const MENTION_DELETING = 'MENTION_DELETING';
export const MENTION_DELETED = 'MENTION_DELETED';
export const MORE_PROJECT_QUERIES_LOADED = 'MORE_PROJECT_QUERIES_LOADED';
export const PROJECT_QUERY_AND_GROUPS_LOADED = 'PROJECT_QUERY_AND_GROUPS_LOADES';
export const OPEN_SAVE_SEARCH_DIALOG = 'OPEN_SAVE_SEARCH_DIALOG';
export const OPEN_EDIT_SAVE_SEARCH_DIALOG = 'OPEN_EDIT_SAVE_SEARCH_DIALOG';
export const OPEN_EDIT_SAVE_SEARCH = 'OPEN_EDIT_SAVE_SEARCH';
export const PEAKS_LOADED = 'PEAKS_LOADED';
export const REPORT_ERROR = 'REPORT_ERROR';
export const RESET_LOCATIONS = 'RESET_LOCATIONS';
export const SAVE_ALERT_ERROR = 'SAVE_ALERT_ERROR';
export const SAVE_ALERT_SUCCESS = 'SAVE_ALERT_SUCCESS';
export const DELETE_ALERT_ERROR = 'DELETE_ALERT_ERROR';
export const SAVE_SEARCH_ERROR = 'SAVE_SEARCH_ERROR';
export const SAVE_SEARCH_SUCCESS = 'SAVE_SEARCH_SUCCESS';
export const SAVE_SEARCH_START = 'SAVE_SEARCH_SAVING';
export const SAVE_TAG_ERROR = 'SAVE_TAG_ERROR';
export const SAVED_TAG = 'SAVED_TAG';
export const SHOW_SNACKBAR_NOTIFICATION = 'SHOW_SNACKBAR_NOTIFICATION';
export const TAGGED_MENTIONS_ERROR = 'TAGGED_MENTIONS_ERROR';
export const TAGGED_MENTIONS_LOADED = 'TAGGED_MENTIONS_LOADED';
export const TAGGED_MENTIONS_LOADING = 'TAGGED_MENTIONS_LOADING';
export const TAGS_LOAD_ERROR = 'TAGS_LOAD_ERROR';
export const TAGS_LOADED = 'TAGS_LOADED';
export const TAGS_LOADING = 'TAGS_LOADING';
export const TOGGLE_CONFIG = 'TOGGLE_CONFIG';
export const TOGGLE_MENTIONS = 'TOGGLE_MENTIONS';
export const TOGGLE_REPORT = 'TOGGLE_REPORT';
export const SET_MENTIONS_FULL = 'SET_MENTIONS_FULL';
export const SET_USED_QUERIES = 'SET_USED_QUERIES';
export const SET_USER_LINK_PENDING = 'SET_USER_LINK_PENDING';
export const SET_PACKAGE_USAGE = 'SET_PACKAGE_USAGE';
export const WORD_CLOUD_CONFIG_UPDATE = 'WORD_CLOUD_CONFIG_UPDATE';
export const TOTAL_VOLUME_CONFIG_UPDATE = 'TOTAL_VOLUME_CONFIG_UPDATE';
export const SET_QUERY = 'SET_QUERY';
export const SET_RECENT_QUERIES = 'SET_RECENT_QUERIES';
export const SET_SEARCH_CONTEXT = 'SET_SEARCH_CONTEXT';
export const SET_SAVED_SEARCH_QUERY_DATA = 'SET_SAVED_SEARCH_QUERY_DATA';
export const UPDATE_SEARCH_START = 'UPDATE_SEARCH_START';
export const UPDATE_SEARCH_SUCCESS = 'UPDATE_SEARCH_SUCCESS';
export const UPDATE_SEARCH_ERROR = 'UPDATE_SEARCH_ERROR';
export const SEARCH_LOADED = 'SEARCH_LOADED';
export const VALIDATE_QUERY = 'VALIDATE_QUERY';
export const EXPORTED_PDF = 'EXPORTED_PDF';
export const EXPORT_CARD = 'EXPORT_CARD';
export const EXPORTED_CARD = 'EXPORTED_CARD';
export const OPEN_IMPERSONATION_DIALOG = 'OPEN_IMPERSONATION_DIALOG';
export const SET_IMPERSONATION_LOADING = 'SET_IMPERSONATION_LOADING';

//From slices
export const OPEN_FILTERS = 'filtersOpen/openFilters';
export const UPDATE_BREAKDOWN = 'filters/updateBreakdown';
export const UPDATE_DATERANGE = 'filters/updateDateRange';
export const INIT_DATERANGE = 'filters/initDateRange';
export const TOGGLE_IS_CHECKBOX_CHECKED = 'filters/toggleIsCheckboxChecked';
export const SET_INCLUDE_AND_EXCLUDE_MODE = 'filters/setIncludeAndExludeMode';
export const SET_INCLUDE_OR_EXCLUDE_MODE = 'filters/setIncludeOrExcludeMode';
export const ADD_VALUE_INCLUDE_AND_EXCLUDE = 'filters/addValueIncludeAndExclude';
export const REMOVE_VALUE_INCLUDE_AND_EXCLUDE = 'filters/removeValueIncludeAndExclude';
export const ADD_VALUE_INCLUDE_OR_EXCLUDE = 'filters/addValueIncludeOrExclude';
export const REMOVE_VALUE_INCLUDE_OR_EXCLUDE = 'filters/removeValueIncludeOrExclude';
export const ADD_VALUE_MULTI_VALUE_TEXTFIELD = 'filters/addValueMultiValueTextfield';
export const REMOVE_VALUE_MULTI_VALUE_TEXTFIELD = 'filters/removeValueMultiValueTextfield';
export const FILTERS_SET_RANGE_MIN = 'filters/setRangeMin';
export const FILTERS_SET_RANGE_MAX = 'filters/setRangeMax';
export const FILTERS_SET_TEXTFIELD_VALUE = 'filters/setValueTextfield';
export const FILTERS_SET_CATEGORY_MODE = 'filters/setCategoryMode';
export const FILTERS_SET_CATEGORY_VALUE = 'filters/setCategoryValue';
export const FILTERS_SET_SELECT_VALUE = 'filters/setValueSelect';
export const CLEAR_FILTER = 'filters/clearFilter';
export const CLEAR_SECTION_FILTERS = 'filters/clearSectionFilters';
export const FILTERS_SET_CONFIG = 'filters/setConfig';
export const FILTERS_LOAD_PERSISTED_FILTERS = 'filters/loadPersistedFilters';
export const RESET_FILTERS = 'filters/reset';
export const FILTERS_SET_SECTION_OPEN = 'filters/setSectionOpen';
export const FILTERS_SET_ERROR = 'filters/setError';
export const FILTERS_VALIDATE_THEN_APPLY = 'filters/validateThenApply/fulfilled';
export const SUBMIT_QUERY = 'query/submitQuery/fulfilled';
// Social Panels
export const LOADING_SOCIAL_PANELS = 'LOADING_SOCIAL_PANELS';
export const LOADED_SOCIAL_PANELS = 'LOADED_SOCIAL_PANELS';
export const LOAD_SOCIAL_PANELS_ERROR = 'LOAD_SOCIAL_PANELS_ERROR';
export const RESET_SOCIAL_PANELS = 'RESET_SOCIAL_PANELS';

//All filter actions that could affect the filtering results
export const ALL_UPDATE_FILTERS_ACTIONS = [
    FILTERS_SET_SELECT_VALUE,
    FILTERS_SET_CATEGORY_MODE,
    FILTERS_SET_CATEGORY_VALUE,
    TOGGLE_IS_CHECKBOX_CHECKED,
    SET_INCLUDE_AND_EXCLUDE_MODE,
    SET_INCLUDE_OR_EXCLUDE_MODE,
    ADD_VALUE_INCLUDE_AND_EXCLUDE,
    REMOVE_VALUE_INCLUDE_AND_EXCLUDE,
    ADD_VALUE_INCLUDE_OR_EXCLUDE,
    REMOVE_VALUE_INCLUDE_OR_EXCLUDE,
    ADD_VALUE_MULTI_VALUE_TEXTFIELD,
    REMOVE_VALUE_MULTI_VALUE_TEXTFIELD,
    FILTERS_SET_RANGE_MIN,
    FILTERS_SET_RANGE_MAX,
    FILTERS_SET_TEXTFIELD_VALUE,
    CLEAR_FILTER,
    CLEAR_SECTION_FILTERS,
    RESET_FILTERS,
    //FILTERS_SET_CONFIG,
    FILTERS_LOAD_PERSISTED_FILTERS,
    FILTERS_VALIDATE_THEN_APPLY,
];

export const ALL_RELOAD_SEARCH_DATA_ACTIONS = [
    CLEAR_RESULTS,
    UPDATE_DATERANGE,
    SUBMIT_QUERY,
    ...ALL_UPDATE_FILTERS_ACTIONS,
];