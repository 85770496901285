import DataGrid, { DataGridProps } from "hsi/components/aria/DataGrid";
import { ForwardedRef, ReactElement, forwardRef, useCallback, useMemo } from "react";
import ManagedTable, { ManagedTableProps } from "../ManagedTable";
import GridComponents from "../ManagedTable/GridComponents";
import { SortColumnDefinition } from "../ManagedTable/types";
import { T } from "hsi/i18n";



type ManagedGridTableProps<Columns extends string | number | symbol> = {

} & ManagedTableProps<Columns>
  & Omit<DataGridProps, 'children' | 'rowsPerPage'>;


function ManagedGridTable<Columns extends string | number | symbol>({
        //Grid props
        id,
        initialCell: _initialCell,
        multiselectable,
        getGridFromSource,
        onActive,
        onSelect: _onSelect,
        onWrap,
        onValueDragStart,
        onValueDrag,
        onValueDragEnd,

        //Table props
        onRowClick,
        onSortClick,
        components,
        columns,

        keyboardInstructions,
        ...props
    }: ManagedGridTableProps<Columns>, 
    ref: ForwardedRef<HTMLTableElement>
) {
    const initialCell = _initialCell ?? `header::${columns[0].name.toString()}`;

    const onSelect = useCallback((value: string) => {
        if(value.startsWith('header::')) {
            if(onSortClick) {
                const columnNameStr = value.substring(8);
                const columnDefinition = columns.find(({name}) => typeof name === 'number' 
                    ? name === +columnNameStr
                    : name === columnNameStr.toString());
                
                if((columnDefinition as SortColumnDefinition<any>)?.sortable) {
                    onSortClick(columnDefinition?.name as any, new Event('onSelect'));
                }
            }
        } else {
            const [valueStr,, rowIndex] = value.split(':');

            //it's a value
            if(onRowClick && valueStr === 'value' && rowIndex) {
                onRowClick( +rowIndex, new Event('onSelect'));
            }
        }

        _onSelect?.(value);
    }, [_onSelect, columns, onRowClick, onSortClick]);

    const gridKeyboardInstructions = useMemo(() => ([
        ...keyboardInstructions ?? [],
        T('mangedTableGrid.keyboardInstructions'),
    ]), [keyboardInstructions])

    return <DataGrid
        id={id}
        initialCell={initialCell}
        multiselectable={multiselectable}
        getGridFromSource={getGridFromSource}
        onActive={onActive}
        onSelect={onSelect}
        onWrap={onWrap}
        onValueDragStart={onValueDragStart}
        onValueDrag={onValueDrag}
        onValueDragEnd={onValueDragEnd}
    >
        <ManagedTable<Columns> 
            {...props} 
            columns={columns} 
            components={components ?? GridComponents} 
            onSortClick={onSortClick ? noop : undefined }
            onRowClick={onRowClick ? noop : undefined}
            keyboardInstructions={gridKeyboardInstructions}
            ref={ref}
        />
    </DataGrid>
}

export default forwardRef(ManagedGridTable) as any as <Columns extends string | number | symbol>(p: ManagedGridTableProps<Columns> & { ref?: ForwardedRef<HTMLTableElement> }) => ReactElement

const noop = () => {};