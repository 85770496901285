//OBSOLETE - use hsi/components/Tooltip/OverflowTooltip instead
//Version of OverflowTooltip that uses the useElementSize hook instead of the
//ReactResizeDetector component, because it adds markup which can break layouts

import React, {useState, useEffect, useRef, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';

//Components
import SimpleTooltip from './SimpleTooltip';

//hooks
import useElementSize from 'hsi/hooks/useElementSize';

//Utils
import {checkOverflow} from './utils';
import {mergeRefs} from 'hsi/utils/react';

/**
 * Gets wrapped around content that is expected to potentially overflow
 * its container (e.g. a monitor name), and displays a standard ToolTip
 * component when hovering over that content.
 */
const OverflowTooltip = ({
    active,
    boundary,
    children,
    distance,
    offset,
    overflowChildSelector,
    tooltip,
    visible,
}) => {
    // this will trigger an update everytime the component is resized
    const [setNodeCallback, {width} = {}] = useElementSize(null, {width: true});

    const [isOverflown, setOverflown] = useState(false);
    const ref = useRef();

    //Callbacks
    const doCheck = useCallback(() => {
        const elem = overflowChildSelector
            ? ref.current.querySelector(overflowChildSelector)
            : ref.current;
        elem && setOverflown(checkOverflow(elem, offset));
        !elem && setOverflown(false);

        return () => setOverflown(false);
    }, [overflowChildSelector, setOverflown, offset]);

    //Calculated values
    const content = useMemo(() => {
        const child = React.Children.only(children);

        return React.cloneElement(child, {ref: mergeRefs(child.ref, setNodeCallback, ref)});
    }, [children, setNodeCallback]);

    //Side effects
    useEffect(() => {
        doCheck();

        return () => setOverflown(false);
        // Check if you can add deps without issue
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    return (
        <>
            {active && isOverflown ? (
                <SimpleTooltip
                    boundary={boundary}
                    distance={distance}
                    tooltip={tooltip || content}
                    visible={visible}
                >
                    {content}
                </SimpleTooltip>
            ) : (
                content
            )}
        </>
    );
};

OverflowTooltip.propTypes = {
    active: PropTypes.bool,
    boundary: PropTypes.string,
    children: PropTypes.any.isRequired,
    distance: PropTypes.number,
    offset: PropTypes.number,
    overflowChildSelector: PropTypes.string,
    tooltip: PropTypes.oneOfType([PropTypes.any, PropTypes.bool]),
    visible: PropTypes.bool,
};

OverflowTooltip.defaultProps = {
    active: true,
    offset: 0,
};

export default OverflowTooltip;
