import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import {useTheme} from '@mui/material/styles';
import PulseLoader from 'hsi/components/PulseLoader';
import {handleAlertInvite} from 'hsi/services/alertService';

/*
 * replicates the BCR route path from:
 * [https://github.com/BrandwatchLtd/utiltaskmanager]/src/runtimecollective/alerts/InviteGenerator.java
 * [https://github.com/BrandwatchLtd/frontend]/server/lib/routing.js
 * TODO put this route in a shared place for all three repos: utiltaskmanager, frontend, hootsuite-insights; needs sync with a couple of other teams
 */
const handleAlertInvitePath = '/alert/recipient/options/';

const useStyles = makeStyles((theme) => ({
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
    },
}));

const HandleAlertInvite = () => {
    const location = useLocation();
    const theme = useTheme();
    const cl = useStyles();

    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState();

    const searchParams = new URLSearchParams(location.search);
    const opts = {
        action: searchParams.get('action'),
        recipient: searchParams.get('email'),
        alertId: searchParams.get('id'),
    };

    const {HandleAlertInvite} = theme.components;

    useEffect(() => {
        const getRes = async () => {
            setLoading(true);
            const res = await handleAlertInvite(opts);
            setLoading(false);
            return setResponse(res);
        };

        getRes();
        // Check if you can add deps without issue
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={loading ? cl.loading : ''}>
            {loading ? (
                <PulseLoader />
            ) : (
                <HandleAlertInvite success={response?.ok} error={response?.error} />
            )}
        </div>
    );
};

export {handleAlertInvitePath, HandleAlertInvite};
