import {useCallback, useState} from 'react';
import cn from 'classnames';

import Button from 'hsi/components/Button';
import CardList from 'hsi/components/CardList';
import DialogContainer from 'hsi/containers/DataManagement/AddDataSourceDialog/DialogContainer';
import IconRouter from 'hsi/components/IconRouter';
import {RadioButtonGroup, RadioButtonGroupProps} from 'hsi/components/Radio';
import SelectableCard from 'hsi/components/CardList/SelectableCard';

import useDataManagementConfig from 'hsi/hooks/useDataManagementConfig';
import useStyles from './styles';
import useUniqueId from 'hsi/hooks/useUniqueId';

import {T} from 'hsi/i18n';

export enum SelectDataSourceTypes {
    ownedFacebookPagesAndInstagramAccounts = 'ownedFacebookPagesAndInstagramAccounts',
    nonOwnedFacebookPages = 'nonOwnedFacebookPages',
    ownedLinkedinPages = 'ownedLinkedinPages',
    nonOwnedInstagramAccounts = 'nonOwnedInstagramAccounts',
    instagramHashtags = 'instagramHashtags',
}

export type SelectDataSourceProps = {
    hasFacebookAuthentications?: boolean;
    onClose: () => void;
    onNext: (selectedSource: SelectDataSourceTypes) => void;
};

const SelectDataSource = ({hasFacebookAuthentications, onClose, onNext}: SelectDataSourceProps) => {
    const classes = useStyles();
    const {selectableSources} = useDataManagementConfig();
    const sourceSelectorTitleId = useUniqueId(null, 'sourceSelectorTitle');

    const [selectedSource, setSelectedSource] = useState<SelectDataSourceTypes>();

    const handleClose = useCallback(() => {
        onClose();
        setSelectedSource(undefined);
    }, [onClose]);

    const handleSelect = useCallback<NonNullable<RadioButtonGroupProps['onChange']>>((value) => {
        setSelectedSource(value as any);
    }, []);

    const handleNext = useCallback(() => {
        selectedSource && onNext(selectedSource);
    }, [onNext, selectedSource]);

    return (
        <DialogContainer
            actions={
                <>
                    <Button onClick={handleClose}>{T('cancel')}</Button>
                    <Button disabled={!selectedSource} onClick={handleNext} priority="cta">
                        {T('next')}
                    </Button>
                </>
            }
            className={cn(selectableSources.length > 4 && classes.dialogContainer)}
            onClose={handleClose}
            title={T('dataManagement.addDataSourcesDialog.selectSource.title')}
            transitionDuration={undefined}
        >
            <div id={sourceSelectorTitleId} className={classes.subtitle}>
                {T('dataManagement.addDataSourcesDialog.selectSource.subtitle')}
            </div>
            <CardList
                className={cn(
                    classes.cardList,
                    selectableSources.length < 5 && classes.narrowCardList,
                )}
            >
                <RadioButtonGroup labelId={sourceSelectorTitleId} onChange={handleSelect}>
                    {selectableSources.map((source: any) => (
                        <SelectableCard
                            {...{
                                cardCategory: 'dataSourceTypes',
                                cardDesc: T(source.desc),
                                cardTitle: T(source.title),
                                disabled:
                                    source.requiresAuthentication && !hasFacebookAuthentications,
                                disabledTooltipText: source.disabledTooltip
                                    ? T(source.disabledTooltip)
                                    : undefined,
                                key: source.id,
                                id: source.id,
                                ImageComponent: () => (
                                    <IconRouter
                                        aria-hidden
                                        className={classes.cardIcon}
                                        name={source.logo}
                                    />
                                ),
                            }}
                        />
                    ))}
                </RadioButtonGroup>
            </CardList>
        </DialogContainer>
    );
};

export default SelectDataSource;
