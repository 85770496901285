import {useCallback} from 'react';
import {useDebounce} from '@react-hook/debounce';

const func = (n: number) => n + 1;

//returns a function that can be called to trigger an update of a component.
// if supplied, debounce delay is delay in ms before action triggers
export default function useForceUpdate(debounceDelay = 0) {
    const [, setValue, setValueImmediately] = useDebounce(0, debounceDelay);

    return useCallback(() => {
        debounceDelay ? setValue(func) : setValueImmediately(func);
    }, [debounceDelay, setValue, setValueImmediately]);
}
