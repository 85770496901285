import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);

    return {
        root: {
            background: theme.colors.white,//theme.colors.lightGrey60,
            
            borderRadius: '3px',
            margin: '0 16px',
        },

        header: {
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
            gap: '4px',
            padding: '16px',
        },

        heading: {
            flex: '0 0 auto',
            margin: 0,
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
            gap: '4px',
            ...v2 ? theme.fonts.bodyStrong : undefined,
        },

        btn: {
            marginLeft: 'auto',
            padding: '8px 12px',
            height: 'auto',
            whiteSpace: 'nowrap',
        },

        info: {
            padding: '16px',
            border: `1px solid ${theme.colors.uiGrayDarker}`,
            borderWidth: '1px 0',

            display: 'grid',
            gridTemplateColumns: '1fr 32px',
            gridTemplateRows: 'repeat(2, auto)',
            gridColumnGap: '16px',
            gridRowGap: '8px',
        },
        infoHeading: {
            margin: 0,
            gridArea: '1 / 1 / 2 / 2',

            ...v2 ? theme.fonts.bodySmallStrong : undefined,
        },
        infoCopy: {
            gridArea: '2 / 1 / 3 / 2',
            margin: 0,

            ...v2 ? theme.fonts.bodySmall : undefined,
        },
        infoDismiss: {
            gridArea: '1 / 2 / 3 / 2',
            alignContent: 'center',
        },
        infoDismissBtn: {
            padding: '8px',
        },
        infoDismissBtnIcon: {
            width: '16px',
            height: '16px',
        },

        output: {
            padding: '16px',
            minHeight: '312px',
            position: 'relative',
            display: 'flex',
            flexFlow: 'column nowrap',
            gap: '16px',
        },

        errorNotification: {
            flex: '1 1 auto',
            justifyContent: 'center',
        },

        loadIndicator: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
        },

        results: {
            flex: '1 1 auto',
            whiteSpace: 'pre-wrap',
            maxWidth: '60em',

            '& ul': {
                display: 'flex',
                flexFlow: 'column nowrap',
                gap: '1em',
                listStyle: 'outside',
                margin: '0 0 0 20px',
                padding: 0,
            },
        },

        copyToClipboard: {
            flex: '0 0 auto',
            alignSelf: 'flex-end',
        },
    };
});
