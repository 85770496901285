import {useCallback, useEffect, useMemo} from 'react';

import CheckboxOptionsMenu from './CheckboxOptionsMenu';

import useDataManagementConfig from 'hsi/hooks/useDataManagementConfig';
import {useFacebookPages} from 'hsi/hooks/useDataSource';
import {useAppDispatch} from 'hsi/hooks/useRedux';

import {
    isGuidedSearchFacebookOwnedPage,
    inputAccountValueParser as parser,
} from 'hsi/containers/QueryBuilder/GuidedQueryForm/utils';
import {toggleFacebookLoginDialog} from 'hsi/slices/facebookAuthentication';
import {T} from 'hsi/i18n';

import {SelectAccountsProps} from '.';

const SelectOwnedFacebookPages = (props: SelectAccountsProps & {onBack: () => void}) => {
    const {onChange, setLoading, values} = props;
    const dispatch = useAppDispatch();
    const {maxDataSources} = useDataManagementConfig(); // TODO: Need to paginate this
    const facebookPages = useFacebookPages({defaultSize: maxDataSources});

    const defaultFacebookPages = useMemo(() => {
        const defaultFacebookIds = values
            .filter(isGuidedSearchFacebookOwnedPage)
            .map(({fbPageId}) => fbPageId);

        return facebookPages.activeOwned.filter(({id}) => defaultFacebookIds.includes(id));
    }, [facebookPages.activeOwned, values]);

    const onAddPages = useCallback(
        (selected: typeof facebookPages.activeOwned) => {
            const otherItems = values.filter((value) => !isGuidedSearchFacebookOwnedPage(value));
            const newFacebookItems = selected.map(parser.facebook);

            onChange([...otherItems, ...newFacebookItems]);
        },
        [facebookPages, onChange, values],
    );

    useEffect(() => {
        if (facebookPages?.isLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [facebookPages?.isLoading, setLoading]);

    return (
        <CheckboxOptionsMenu
            {...props}
            defaultOptions={defaultFacebookPages}
            menuTitle={T('queryBuilder.fbPagesOwned')}
            onAddNewPage={() => dispatch(toggleFacebookLoginDialog(true))}
            onAddPages={onAddPages}
            options={facebookPages?.activeOwned}
            valueField="id"
        />
    );
};

export default SelectOwnedFacebookPages;
