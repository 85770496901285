import { useMemo } from "react";




export default function usePaginateRows<RowItemType>(rows: RowItemType[] | Readonly<RowItemType[]>, page: number, perPage: number) {
    page |= 0;
    perPage |= 0;

    return useMemo(() => {
        return rows.slice((page - 1) * perPage, page * perPage);
    }, [page, perPage, rows]);
}