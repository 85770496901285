module.exports = {
    hsi: {
        path: __dirname + '/hsi/**/*.svg',
        fileName: 'static/svg/hsi.icons.svg',
        prefix: 'icon-',
    },
    axiomy: {
        path: __dirname + '/axiomy/**/*.svg',
        fileName: 'static/svg/axiomy.icons.svg',
        prefix: 'icon-',
    },
};
