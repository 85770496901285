//
import React from 'react';

//Components
import TextField from 'hsi/components/TextField';
import Alert, {mergeReferencePropsMUI} from 'hsi/components/Tooltip/Alert';

//The component
export default React.forwardRef(function TextfieldWithAlert(
    {alert, tooltipPlacement = 'left', ...props},
    ref,
) {
    return (
        <Alert
            visible={!!alert}
            theme="light"
            content={alert || ''}
            placement={tooltipPlacement}
            mergeReferenceProps={mergeReferencePropsMUI}
            portal
        >
            <TextField {...props} ref={ref} />
        </Alert>
    );
});
