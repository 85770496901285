import {useState, useMemo, useEffect, PropsWithChildren} from 'react';
import classNames from 'classnames';

import CharacterLimit from 'hsi/components/CharacterLimit';
import {CharLimitErrorMsg, MultipleErrorMsg} from 'hsi/components/ErrorMessage/';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import IconRouter from 'hsi/components/IconRouter';
import {introAnchors} from 'hsi/containers/Intro';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import PulseLoader from 'hsi/components/PulseLoader';
import {QueryEditor, HighlightedText} from 'hsi/components/QueryEditor';
import Heading, {HeadingContents} from 'hsi/components/aria/Heading';

import logService from 'hsi/services/logService';

import {
    setQueryErrors,
    clearQueryErrors,
    setGuidedQueryType,
    setQueryValidated,
    setQueryValidating,
    goToRecentQuery,
    setBooleanQuery,
} from 'hsi/actions/queryActions';
import {addMessage} from 'hsi/slices/ariaAnnounce';
import {openSaveSearchDialog} from 'hsi/actions/savedSearchActions';

import useQueryApiErrors from 'hsi/hooks/useQueryApiErrors';
import useEventTrack from 'hsi/hooks/useEventTrack';

import {GUIDED_OPTS} from '../config';
import {MAX_EDIT_SEARCH_LENGTH, MAX_QUICK_SEARCH_LENGTH} from 'hsi/constants/config';

import {T} from 'hsi/i18n';
import useStyles from './styles';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';

type CreateOrSelectQueryProps = PropsWithChildren<{
    autofocus?: boolean; //?
    defaultQuery?: string;
    isEditSearch?: boolean;
    onSubmit: (query: string) => void;
    onGoToHelp?: () => void;
    open?: boolean;
    setOpen?: () => void;
}>;

export default function CreateOrSelectQuery({
    autofocus,
    children,
    defaultQuery,
    isEditSearch,
    onSubmit,
    onGoToHelp,
    open,
    setOpen,
}: CreateOrSelectQueryProps) {
    const {classes} = useStyles();
    const dispatch = useAppDispatch();
    const {trackWithSearchData} = useEventTrack();

    const [openGuided, setOpenGuided] = useState(true);

    const persistId = useAppSelector((state) => state.query.persistId);
    const recentQueries = useAppSelector((state) => state.query.recentQueries);
    const booleanQuery = useAppSelector((state) => state.query.booleanQuery);
    const validating = useAppSelector((state) => state.query.validating);
    const isGuided = useAppSelector((state) => state.query.isGuided);

    //only show character limit if user has actually started typing something
    const [hasEnteredBooleanQuery, setHasEnteredBooleanQuery] = useState(false);

    const errors = useQueryApiErrors();

    const _defaultQuery = defaultQuery || booleanQuery || '';

    const maxLength = isEditSearch ? MAX_EDIT_SEARCH_LENGTH : MAX_QUICK_SEARCH_LENGTH;
    const isQuickSearchMax = booleanQuery?.length > MAX_QUICK_SEARCH_LENGTH;

    const filteredRecentQueries = useMemo(() => {
        const tquery = (booleanQuery || '').trim();
        const lcQuery = tquery.toLowerCase();
        return lcQuery
            ? recentQueries.filter(
                  (item) =>
                      item.booleanQuery.toLowerCase().includes(lcQuery) &&
                      item.id !== persistId &&
                      tquery !== item.booleanQuery,
              )
            : recentQueries;
    }, [booleanQuery, recentQueries, persistId]);
    const showMaxRecent = booleanQuery ? 5 : 2;

    //Is there anything to actually show in the editSearchPopover?
    const editSearchPopoverHasContent = openGuided || !!filteredRecentQueries?.length || !!children;
    //Can the edit search popover currently be shown
    const canShowEditSearchPopover =
        editSearchPopoverHasContent && !validating && !errors && !isGuided;

    useEffect(() => {
        errors && logService.info(`SEARCH SYNTAX ERROR: "${booleanQuery}". ${errors.join(', ')}`);
        // Check if deps can be added without issue
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    return (
        <>
            <div className={classNames(classes.searchBar, introAnchors.quickSearch.searchBar)}>
                {isGuided && (
                    <button
                        className={classes.editGuidedSearchBtn}
                        aria-label={T('queryBuilder.editGuidedSearchBtnLbl')}
                        aria-haspopup="dialog"
                        aria-expanded={open}
                        onClick={setOpen}
                    ></button>
                )}
                <div className={classes.searchBarEditor}>
                    <IconRouter
                        name="search"
                        className={classes.searchBarIcon}
                        data-testid="queryStartSearchIcon"
                        aria-hidden
                    />
                    <QueryEditor
                        autofocus={autofocus}
                        className={classes.queryEditor}
                        defaultQuery={_defaultQuery}
                        disabled={isGuided}
                        maxLines={10}
                        maxQueryLength={MAX_QUICK_SEARCH_LENGTH}
                        onChange={(query: string | undefined) => {
                            if (query && query.length > 0 && !hasEnteredBooleanQuery) {
                                setHasEnteredBooleanQuery(true);
                            }

                            dispatch(
                                setBooleanQuery({
                                    booleanQuery: query ?? '',
                                    guidedForm: null,
                                    validated: false,
                                }),
                            );
                        }}
                        onError={(errors) => {
                            dispatch(setQueryErrors(errors));
                            trackWithSearchData('searchErrored', {
                                errors,
                            });
                        }}
                        onFocus={() => {
                            dispatch(clearQueryErrors());
                        }}
                        onSubmit={(query) => {
                            if (!isQuickSearchMax) {
                                setTimeout(() => {
                                    dispatch(clearQueryErrors());
                                    onSubmit && onSubmit(query);
                                });
                            }
                        }}
                        onGoToHelp={onGoToHelp}
                        onValidated={() => dispatch(setQueryValidated(true))}
                        onValidating={(validating) => dispatch(setQueryValidating(validating))}
                        placeholder={T('queryBuilder.placeholder')}
                        showEllipsis={isGuided || !open}
                    />
                    {open && (
                        <IconButton
                            aria-label={T(
                                openGuided
                                    ? 'queryBuilder.hideGuidedLbl'
                                    : 'queryBuilder.showGuidedLbl',
                            )}
                            className={classes.searchBarCollapseBtn}
                            data-testid="queryStartDropdownIconButton"
                            aria-expanded={openGuided}
                            onClick={() => {
                                setOpenGuided(!openGuided);
                                dispatch(
                                    addMessage({
                                        message: T(
                                            openGuided
                                                ? 'queryBuilder.guidedHidden'
                                                : 'queryBuilder.guidedShown',
                                        ),
                                        priority: 'assertive',
                                    }),
                                );
                            }}
                        >
                            <IconRouter
                                className={classes.searchBarCollapseBtnIcon}
                                aria-hidden="true"
                                name={'small-triangle-' + (openGuided ? 'up' : 'down')}
                            />
                        </IconButton>
                    )}
                </div>
                {open && !isGuided && (
                    <div className={classes.guidance}>
                        <span>{T('queryBuilder.tabFormattingMsg')}</span>
                        {hasEnteredBooleanQuery && (
                            <CharacterLimit
                                className={classes.charLimit}
                                current={booleanQuery ? booleanQuery.length : 0}
                                max={maxLength}
                            />
                        )}
                    </div>
                )}

                {!isGuided && !validating && !!errors && (
                    <div className={classes.errorContainer}>
                        <MultipleErrorMsg
                            action={() => dispatch(openSaveSearchDialog(true))}
                            errors={errors.map(({descId, desc, linkId}) => ({
                                descId,
                                desc,
                                linkId,
                            }))}
                        />
                    </div>
                )}

                {!isGuided && !isEditSearch && isQuickSearchMax && (
                    <CharLimitErrorMsg className={classes.charLimitError} />
                )}
            </div>
            {!!validating && (
                <div className={classes.validationLoader}>
                    <PulseLoader size="small" className={classes.validationAnim} />
                </div>
            )}
            {canShowEditSearchPopover && (
                <div
                    className={classNames(
                        classes.editSearchPopover,
                        introAnchors.quickSearch.guidedMenu,
                        open && classes.open,
                    )}
                >
                    <Collapse in={openGuided}>
                        <div className={classes.guidedOptsContainer}>
                            <p className={classes.guidedOptHeader}>
                                {T('queryBuilder.guidedTitle')}
                            </p>
                            <List
                                className={classes.guidedOptsList}
                                dense
                                data-testid="guidedSuggestions"
                            >
                                {/* Probably makes sense to replace this list of buttons with an aria menu. We could also address the below issues at the same time. */}
                                {GUIDED_OPTS.map((opt, i) => (
                                    <ListItem //For some reason this outputs a div element. This should be an li to be nested in the list correctly
                                        button
                                        className={classes.guidedOptItem}
                                        key={i}
                                        onClick={() => {
                                            trackWithSearchData('guidedSearchStarted', {
                                                type: opt.id,
                                            });
                                            dispatch(setGuidedQueryType(opt.id));
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <span //This needs to change, should probably use the Markdown component to achieve this
                                                    dangerouslySetInnerHTML={{
                                                        __html: T(
                                                            `queryBuilder.guidedOptions.${opt.id}`,
                                                        ),
                                                    }}
                                                />
                                            }
                                        />
                                        <IconRouter
                                            name="arrow-back"
                                            className={classes.guidedOptItemArrow}
                                            aria-hidden
                                        />
                                    </ListItem>
                                ))}
                            </List>

                            {!!filteredRecentQueries.length && (
                                <Divider className={classes.hr} aria-hidden />
                            )}
                        </div>
                    </Collapse>
                    {!!filteredRecentQueries.length && (
                        <div className={classes.recentQueriesContainer}>
                            <Heading className={classes.recentQueriesTitle}>
                                {T('queryBuilder.recentQueriesTitle')}
                            </Heading>
                            <HeadingContents>
                                <List dense>
                                    {filteredRecentQueries
                                        .slice(0, showMaxRecent)
                                        .map((item, i) => (
                                            <ListItem
                                                button
                                                className={classes.recentQueriesItem}
                                                key={i}
                                                onClick={() => {
                                                    dispatch(goToRecentQuery(item));
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <HighlightedText
                                                            ellipsis
                                                            text={item.booleanQuery}
                                                        />
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                </List>
                            </HeadingContents>
                        </div>
                    )}
                    {children}
                </div>
            )}
        </>
    );
}
