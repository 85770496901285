import get from 'lodash/get';
import QueryService from 'hsi/services/queryService';
import {excludeConfig} from './excludeConfig';
import {
    OR,
    getValues,
    parseBrand,
    parseBrandContext,
    parseLinks,
    parseAuthors,
    parseHashtags,
    parseMultiWord,
} from 'hsi/utils/formulaParser';

import tns from 'hsi/i18n/tns';
const t = tns('queryBuilder');

const brandIncludeConfig = {
    name: 'include',
    label: 'include',
    operator: 'OR',
    title: t('includeTitle'),
    components: [
        {
            name: 'brand-name',
            type: 'input-text',
            title: t('brandNameTitle'),
            subtitle: t('brandNameSubtitle'),
            placeholder: t('brandNamePlaceholder'),
            parseFormula: ({values, formula}) =>
                parseBrand({
                    values,
                    relatedTerms: getValues({
                        formula,
                        sectionName: 'include',
                        componentName: 'related-terms',
                    }),
                }),
        },

        {
            name: 'related-terms',
            type: 'input-chips-toggle',
            title: t('brandRelatedTermsTitle'),
            info: t('brandRelatedTermsInfo'),
            subtitle: t('brandRelatedTermsSubtitle'),
            placeholder: t('brandRelatedTermsPlaceholder'),
            chipElemProps: {
                newChipChars: ',',
            },
        },

        {
            name: 'nicknames',
            type: 'input-chips',
            title: t('brandNicknamesTitle'),
            subtitle: t('brandNicknamesSubtitle'),
            placeholder: t('brandNicknamesPlaceholder'),
            parseFormula: ({values}) => OR(values.map(parseMultiWord)),
            chipElemProps: {
                newChipChars: ',',
            },
        },

        {
            name: 'hashtags',
            type: 'input-hashtags',
            title: t('brandHashtagsTitle'),
            subtitle: t('brandHashtagsSubtitle'),
            placeholder: t('brandHashtagsPlaceholder'),
            parseFormula: parseHashtags,
        },

        {
            name: 'owned-social-accounts',
            type: 'input-accounts',
            title: t('brandOwnedSocialAccountsTitle'),
            subtitle: t('brandOwnedSocialAccountsSubtitle'),
            placeholder: t('brandOwnedSocialAccountsPlaceholder'),
            parseFormula: parseAuthors,
        },

        {
            name: 'include-websites',
            type: 'input-chips',
            title: t('brandWebsitesTitle'),
            subtitle: t('brandWebsitesSubtitle'),
            placeholder: t('brandWebsitesPlaceholder'),
            parseFormula: parseLinks,
        },
    ],
};

const brandConfig = {
    sections: [brandIncludeConfig, excludeConfig],
};

const brandContextComponent = {
    name: 'brand-context',
    type: 'select',
    title: t('brandContextTitle'),
    placeholder: t('brandContextPlaceholder'),
    getOptions: () =>
        QueryService.queriesByGuidedType({
            type: 'brand',
        }),
    reduceFormula: parseBrandContext,
};

const getBrandName = (d) => String(get(d, ['include', 'brand-name']));

export {brandConfig, brandContextComponent, getBrandName};
