import InfoPopupContent from 'hsi/components/InfoPopupContent';

import useConfig from 'hsi/hooks/useConfig';

import {T} from 'hsi/i18n';
import { CardType } from 'hsi/types/cards';

export default function InfoPopup({type, isSavedSearch}: {type: CardType, isSavedSearch: boolean}) {
    const {
        links: {dashboardInfoTopAuthorsCTA: popupCTA},
    } = useConfig();
    return (
        <InfoPopupContent
            copy={T(`cards.${type}.info.${isSavedSearch ? 'savedSearchCopy' : 'quickSearchCopy'}`)}
            ctaLabel={T('cards.infoCTALabel')}
            ctaUrl={popupCTA}
            title={T(`cards.${type}.info.title`)}
        />
    );
};
