// TODO: See if we might want to remove this
// TODO: We at least want to add tests
import {useMemo} from 'react';
import {useQuery} from '@tanstack/react-query';

import useLinkedinIsEnabled from 'hsi/hooks/useLinkedinIsEnabled';

import * as TargetedDataService from 'hsi/services/targetedDataService';

import {addLinkedAccounts} from 'hsi/utils/dataManagement/linkedAccounts';
import {removeLinkedinAuthentications} from 'hsi/utils/dataManagement/linkedin';
import {
    formatFacebookPageAuthentications,
    formatFacebookUserAuthentications,
    formatInstagramAuthentications,
    formatLinkedinAuthentications,
} from 'hsi/utils/dataManagement/formatAuthentications';
import * as queryKeys from 'hsi/constants/queryKeys';

const useAuthentications = (isGetEnabled = false) => {
    const isLinkedinEnabled = useLinkedinIsEnabled();

    const facebookUserAuthentications = useQuery({
        enabled: isGetEnabled,
        queryKey: [`${queryKeys.FACEBOOK_USER_AUTHENTICATIONS}-1`], // TODO: This clashes with new data management, as this file will be remove I think it's okay to leave the hack in
        queryFn: () =>
            TargetedDataService.getFacebookUserAuthentications().then((response) =>
                formatFacebookUserAuthentications(response),
            ),
    });
    const facebookPageAuthentications = useQuery({
        enabled: isGetEnabled,
        queryKey: [queryKeys.FACEBOOK_PAGE_AUTHENTICATIONS],
        queryFn: () =>
            TargetedDataService.getFacebookPageAuthentications().then((response) =>
                formatFacebookPageAuthentications(response),
            ),
    });
    const instagramAccountAuthentications = useQuery({
        enabled: isGetEnabled,
        queryKey: [queryKeys.INSTAGRAM_ACCOUNT_AUTHENTICATIONS],
        queryFn: () =>
            TargetedDataService.getInstagramAuthentications().then((response) =>
                formatInstagramAuthentications(response),
            ),
    });
    const linkedinChannelAuthentications = useQuery({
        enabled: isGetEnabled,
        queryKey: [queryKeys.LINKEDIN_CHANNEL_AUTHENTICATIONS],
        queryFn: () =>
            TargetedDataService.getLinkedinAuthentications().then((response) =>
                formatLinkedinAuthentications(response),
            ),
    });

    const authentications = useMemo(() => {
        if (
            facebookPageAuthentications.isSuccess &&
            facebookUserAuthentications.isSuccess &&
            instagramAccountAuthentications.isSuccess &&
            linkedinChannelAuthentications.isSuccess
        ) {
            const allAuthentications = addLinkedAccounts([
                ...(facebookPageAuthentications?.data || []),
                ...(facebookUserAuthentications?.data || []),
                ...(instagramAccountAuthentications?.data || []),
                ...(linkedinChannelAuthentications?.data || []),
            ]);

            return isLinkedinEnabled
                ? allAuthentications
                : removeLinkedinAuthentications(allAuthentications);
        } else {
            return [];
        }
    }, [
        facebookPageAuthentications?.data,
        facebookPageAuthentications.isSuccess,
        facebookUserAuthentications?.data,
        facebookUserAuthentications.isSuccess,
        instagramAccountAuthentications?.data,
        instagramAccountAuthentications.isSuccess,
        isLinkedinEnabled,
        linkedinChannelAuthentications?.data,
        linkedinChannelAuthentications.isSuccess,
    ]);

    return {
        authentications,
        error:
            facebookPageAuthentications.isError ||
            facebookUserAuthentications.isError ||
            instagramAccountAuthentications.isError ||
            linkedinChannelAuthentications.isError,
        loaded:
            facebookPageAuthentications.isFetched &&
            facebookUserAuthentications.isFetched &&
            instagramAccountAuthentications.isFetched &&
            linkedinChannelAuthentications.isFetched,
        loading:
            facebookPageAuthentications.isLoading ||
            facebookUserAuthentications.isLoading ||
            instagramAccountAuthentications.isLoading ||
            linkedinChannelAuthentications.isLoading,
    };
};

export default useAuthentications;
