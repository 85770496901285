import {forwardRef, useCallback, useMemo} from 'react';

//Components
import CardTitle, { formatCardTitleAsString } from 'hsi/components/Card/CardTitle';
import InfoPopupContent from 'hsi/components/InfoPopupContent';
import {formatDomainHeader} from './DomainHeader';
import CardLoadState from 'hsi/components/Card/CardLoadState';
import ManagedGridTable from 'hsi/components/table/ManagedGridTable';
import PaginationButtons from 'hsi/components/PaginationButtons';
import CompactNumber from 'hsi/components/format/CompactNumber';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useDrillInMentions from 'hsi/hooks/useDrillInMentions';
import useIsSavedSearch from 'hsi/hooks/useIsSavedSearch';
import useCardTableTrackingAndPersistence from '../hooks/useCardTableTrackingAndPersistence';
import usePaginateRows from 'hsi/components/table/ManagedTable/usePaginateRows';
import useFormatRows from 'hsi/components/table/ManagedTable/useFormatRows';
import { useCardTypeLimit } from 'hsi/utils/cards/getCardTypeLimit';
import useCardData from 'hsi/hooks/useCardData';
import useAddPositionToRows, { WithPosition } from '../hooks/useAddPositionToRows';

//Utils
import { getDomainDrillInFilters } from 'hsi/utils/filters/drillInFilters';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Types
import { CardTypeProps, TopSiteType } from 'hsi/types/cards';
import { ColumnDefinition, FormatColumnDefinition } from 'hsi/components/table/ManagedTable/types';
type TopSiteWithPositionType = WithPosition<TopSiteType>;
type Columns = keyof TopSiteWithPositionType;
type TableColumnDefinition = ColumnDefinition<Columns> & FormatColumnDefinition<TopSiteWithPositionType, Columns>;

//Constants
const type = 'topsites';
const DEFAULT_DATA: TopSiteType[] = [];


//The components
const TopSites = forwardRef<HTMLDivElement, CardTypeProps>(({title, ...props}, ref) => {
    const {
        links: {dashboardInfoTopSitesCTA: popupCTA},
    } = useConfig();

    const {perPage} = useCardTypeLimit(type);


    const isSavedSearch = useIsSavedSearch();
    const drillInMentions = useDrillInMentions();
    const getDrillInData = useGetDrillInData(isSavedSearch);


    const {data, loading, loaded, error, loadData, isCardSelected} = useCardData({
        type,
        dataSelector: (state) => state.chart[type],
    });
    const hasData = !!((data?.length ?? 0) > 0);
    const rows = hasData ? (data as TopSiteType[]) : DEFAULT_DATA;

    //Calculated values
    const columns = useGetColumns();
    const {page, pages, setPage} = useCardTableTrackingAndPersistence(type, columns, {totalRows: rows.length, rowsPerPage: perPage});

    // Add position property to row data
    const positionedRows = useAddPositionToRows(rows);

    // Apply pagination
    const paginatedRows = usePaginateRows(positionedRows, page, perPage);

    // Apply formatting
    const formattedRows = useFormatRows(paginatedRows, columns);
    
    const popup = useMemo(
        () => (
            <InfoPopupContent
                copy={T(`cards.${type}.info.copy`)}
                ctaLabel={T('cards.infoCTALabel')}
                ctaUrl={popupCTA}
                title={T(`cards.${type}.info.title`)}
            />
        ),
        [popupCTA],
    );

    //Render
    return (
        <CardLoadState
            title={
                <CardTitle
                    title={title}
                    type={type}
                    tooltipComponent={popup}
                    hasData={hasData}
                />
            }
            error={error}
            hasData={hasData}
            loading={loading}
            loaded={loaded}
            loadData={loadData}
            selected={isCardSelected}
            ref={ref}
            data-testid={type}
            type={type}
            {...props}
        >
            <ManagedGridTable
                columns={columns}
                rowData={formattedRows}
                caption={T('managedTable.caption', {name: formatCardTitleAsString(title)})}

                rowSelectable
                onRowClick={(index: number) => {
                    drillInMentions(getDrillInData(paginatedRows[index]));
                }}
                sortColumn="value"
                sortDirection="desc"
            />
            <PaginationButtons page={page} pages={pages} setPage={setPage} />
        </CardLoadState>
    );
});

TopSites.displayName = 'TopSites';

export default TopSites;


function useGetDrillInData(isSavedSearch: boolean) {
    return useCallback(
        ({name}:TopSiteType) => ({
            dates: undefined,
            filter: getDomainDrillInFilters(name, isSavedSearch),
            label: name,
            tracking: {
                name: 'cardDrilledIn',
                type,
                value: name,
            },
            type,
        } as const),
        [isSavedSearch],
    );
};

function useGetColumns(): TableColumnDefinition[] {
    const classes = useStyles();

    return useMemo<TableColumnDefinition[]>(() => ([
        {
            name: 'position',
            label: <span className="offscreen">{T('cards.topsites.tableFields.position')}</span>,
            horizontalAlign: 'start',
            width: 'auto',
            format: (row) => <span className={classes.tableRowPosition}>{row.position}</span>
        },
        {
            name: 'name',
            label: T(`cards.topsites.tableFields.name`),
            width: '1fr',
            truncateCellText: true,
            horizontalAlign: 'start',
            format: formatDomainHeader,
        },
        {
            name: 'value',
            label: T(`cards.topsites.tableFields.value`),
            width: 'auto',
            format: (row) => <CompactNumber value={+row.value} tooltip />
        }
    ]), [classes]);
}
