import ConditionalWrap from 'hsi/components/ConditionalWrap';
import DefaultCard, {DefaultCardProps} from 'hsi/components/CardList/DefaultCard';
import {RadioButton} from 'hsi/components/Radio';
import Tooltip from 'hsi/components/Tooltip';

import useStyles from './styles';

import {T} from 'hsi/i18n';

export interface SelectableCardProps extends DefaultCardProps {
    cardCategory: string;
    disabledTooltipText?: string;
}

const SelectableCard = ({
    cardCategory,
    cardDesc,
    cardTitle,
    disabled = false,
    disabledTooltipText = T('unavailable'),
    id,
    ImageComponent,
}: SelectableCardProps) => {
    const classes = useStyles();

    return (
        <RadioButton
            className={classes.radioInput}
            disabled={disabled}
            name={cardCategory}
            unstyled
            value={id}
        >
            <ConditionalWrap
                wrap={(children: React.ReactNode) =>
                    disabled ? (
                        <Tooltip
                            distance={10}
                            placement="top"
                            theme="dark"
                            tooltip={disabledTooltipText}
                        >
                            {children}
                        </Tooltip>
                    ) : (
                        children
                    )
                }
            >
                <DefaultCard
                    {...{
                        cardDesc,
                        cardTitle,
                        disabled,
                        ImageComponent,
                        id,
                    }}
                />
            </ConditionalWrap>
        </RadioButton>
    );
};

export default SelectableCard;
