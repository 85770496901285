import {makeStyles} from '@mui/styles';
import {Theme} from 'hsi/types/theme';

export default makeStyles((theme: Theme) => ({
    /* SelectableMenuItem styles */
    menuItemsWrapper: {
        display: 'block',
        overflow: 'auto',
        maxHeight: 180,
        width: '100%',
        listStyle: 'none',
        padding: 0,
        margin: 0,
    },

    menuItem: {
        minHeight: 40,
        width: '100%',
    },

    selectMenuItemTextContainer: {
        fontSize: '14px',
    },

    selectMenuItemCheck: theme.v2
        ? {
              minWidth: '30px',
          }
        : {
              width: '16px',
              minWidth: '16px',
              marginLeft: '2px',
              marginRight: '10px',
          },
    selectMenuItemCheckIcon: theme.v2
        ? {}
        : {
              top: '-1px',
              width: '16px',
              height: '16px',
              position: 'relative',
              transform: 'scale(1.1)',
              strokeWidth: '3px',
          },

    selectMenuItemText: {
        pointerEvents: 'none',
    },

    selectMenuItemTextWithIcon: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',

        '& > $selectMenuItemText': {
            ...(theme.v2
                ? {
                      ...theme.fonts.bodyLargeNormal,
                  }
                : {
                      fontSize: '14px',
                      fontWeight: theme.font.bold,
                      minWidth: '100px',
                  }),
            '.selected &': {
                fontWeight: theme.font.bold,
            },
        },
    },
    selectMenuItemIcon: {
        '& svg': {
            display: 'block',
            width: '16px',
            height: '16px',
        },
    },
}));
