import {useCallback} from 'react';
import useIsSavedSearch from 'hsi/hooks/useIsSavedSearch';
import {getAuthorDrillInFilters} from 'hsi/utils/filters/drillInFilters';
import { TopAuthorsCardData } from 'hsi/types/cards';

export default function useGetDrillInData() {
    const isSavedSearch = useIsSavedSearch();

    return useCallback(
        ({name: authorName, domain}: Pick<TopAuthorsCardData[number], 'name' | 'domain'>) => ({
            type: 'topauthors',
            dates: undefined,
            drillInFrom: domain || undefined,
            filter: getAuthorDrillInFilters(authorName, domain, isSavedSearch),
            label: authorName,
            tracking: {
                name: 'cardDrilledIn',
                value: authorName,
            },
        } as const),
        [isSavedSearch],
    );
};

