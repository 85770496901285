import {Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState} from 'react';
import {DateTime, DurationLike} from 'luxon';

import {TimezoneID} from 'hsi/utils/timezones';
import useMemoCompare from './useMemoCompare';

const defaultUpdateFrequency = {
    minutes: 1,
};

type useRollingTimeArgs = {
    updateFrequency?: DurationLike;
    offsetFromNow?: DurationLike;
    timezone?: TimezoneID;
    now?: DateTime;
};

//The hook
export default function useRollingTime({
    updateFrequency = defaultUpdateFrequency,
    offsetFromNow,
    now: _now,
    timezone = 'UTC',
}: useRollingTimeArgs = {}) {
    const [stateNow, setStateNow] = useState(DateTime.now);
    const tIdRef = useRef<ReturnType<typeof setTimeout>>();
    const updateFrequencyRef = useRef<DurationLike>(updateFrequency);

    updateFrequencyRef.current = updateFrequency; //keep update frequence ref updated, so if it's value changes it will be reflected

    useEffect(() => {
        //always end current interval
        tIdRef.current && clearInterval(tIdRef.current);
        tIdRef.current = undefined;

        if (_now) {
            //if a 'now' value is supplied...
            //...no need for an interval, so stop here
            return;
        }

        //Set an interval to set the local state 'now', and keep it updated
        updateStateNow(setStateNow, updateFrequencyRef, tIdRef, timezone);

        //Tidy up method to make sure that the timeout is cleared
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            tIdRef.current && clearInterval(tIdRef.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timezone, _now]);

    //always use argument 'now' value in preference over local state 'now'
    const now = _now ?? stateNow;

    return useMemoCompare(
        () => (offsetFromNow ? now.minus(offsetFromNow) : now),
        [offsetFromNow, now],
        (value, prevValue) => !!prevValue && prevValue.equals(value),
    );
}

function updateStateNow(
    setStateNow: Dispatch<SetStateAction<DateTime>>,
    updateFrequencyRef: MutableRefObject<DurationLike>,
    tIdRef: MutableRefObject<ReturnType<typeof setTimeout> | undefined>,
    timezone: TimezoneID,
) {
    //update 'now' value
    setStateNow(() => DateTime.now().setZone(timezone));

    //calculate timeout delay
    const delay: number = DateTime.now().plus(updateFrequencyRef.current).valueOf() - Date.now();

    tIdRef.current = setTimeout(() => {
        //repeat the call after the delay
        updateStateNow(setStateNow, updateFrequencyRef, tIdRef, timezone);
    }, delay);
}
