import moment from 'moment-timezone';
import {MONTHS_HISTORIC_DATA} from 'hsi/constants/config';

type searchType = 'saved' | 'quick';

export const getSearchType = (searchId: string) => (searchId ? 'saved' : 'quick');
export const quickFiltersSupported: string[] = ['sentiment', 'author', 'language', 'location'];
export const isSavedSearch = (searchType: searchType) => searchType === 'saved';
export const hasAdditionalQueries = (additionalQueries: any[]): boolean =>
    Array.isArray(additionalQueries) && additionalQueries.length > 0;

export const getHistoricDataStartDate = ({timezone}: {timezone: string}) =>
    moment()
        .tz(timezone)
        .subtract(MONTHS_HISTORIC_DATA, 'months')
        .startOf('month')
        .startOf('day')
        .format('YYYY-MM-DD');
