import {
    useFacebookPages,
    useInstagramAccounts,
    useInstagramHashtags,
    useLinkedinChannels,
    useFacebookUserAuthentications,
    useLinkedinAuthenticationsV2,
} from 'hsi/hooks/useDataSource';

import {
    AuthenticationDataTypes,
    DataManagementDataTypes,
    TargetedDataDataTypes,
} from 'hsi/types/dataManagement';

const useDataManagement = (type?: DataManagementDataTypes) => {
    const typeEnabled = {
        [AuthenticationDataTypes.FacebookUserAuthentication]:
            type === AuthenticationDataTypes.FacebookUserAuthentication || !type ? true : false,
        [TargetedDataDataTypes.FacebookPage]:
            type === TargetedDataDataTypes.FacebookPage || !type ? true : false,
        [TargetedDataDataTypes.InstagramAccount]:
            type === TargetedDataDataTypes.InstagramAccount || !type ? true : false,
        [TargetedDataDataTypes.InstagramHashtag]:
            type === TargetedDataDataTypes.InstagramHashtag || !type ? true : false,
        [TargetedDataDataTypes.LinkedinChannel]:
            type === TargetedDataDataTypes.LinkedinChannel || !type ? true : false,
        [AuthenticationDataTypes.LinkedinChannelAuthentication]:
            type === AuthenticationDataTypes.LinkedinChannelAuthentication || !type ? true : false,
    };

    const facebookUserAuthentications = useFacebookUserAuthentications({
        isGetEnabled: typeEnabled[AuthenticationDataTypes.FacebookUserAuthentication],
    });
    const facebookPages = useFacebookPages({
        isGetEnabled: typeEnabled[TargetedDataDataTypes.FacebookPage],
    });
    const instagramAccounts = useInstagramAccounts({
        isGetEnabled: typeEnabled[TargetedDataDataTypes.InstagramAccount],
    });
    const instagramHashtags = useInstagramHashtags({
        isGetEnabled: typeEnabled[TargetedDataDataTypes.InstagramHashtag],
    });
    const linkedinChannels = useLinkedinChannels({
        isGetEnabled: typeEnabled[TargetedDataDataTypes.LinkedinChannel],
    });
    const linkedinAuthentications = useLinkedinAuthenticationsV2({
        isGetEnabled: typeEnabled[AuthenticationDataTypes.LinkedinChannelAuthentication],
    });

    return {
        [AuthenticationDataTypes.LinkedinChannelAuthentication]: linkedinAuthentications,
        [AuthenticationDataTypes.FacebookUserAuthentication]: facebookUserAuthentications,
        [TargetedDataDataTypes.FacebookPage]: facebookPages,
        [TargetedDataDataTypes.InstagramAccount]: instagramAccounts,
        [TargetedDataDataTypes.InstagramHashtag]: instagramHashtags,
        [TargetedDataDataTypes.LinkedinChannel]: linkedinChannels,
    };
};

export default useDataManagement;
