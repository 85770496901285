import React, {useState, useEffect, memo} from 'react';
import {Transition} from 'react-transition-group';

import useStyles from './styles';

//The component
const CustomLineDot = ({cx, cy, stroke}) => {
    const [show, setShow] = useState(false);

    useEffect(
        () => setShow(true), //why?
        [],
    );

    const classes = useStyles();

    return (
        <Transition in={show} timeout={10}>
            {(animState) => (
                <circle
                    fill={stroke}
                    stroke={stroke}
                    r={4}
                    cx={cx}
                    cy={cy}
                    className={`${classes.activeLineDot} ${animState}`}
                />
            )}
        </Transition>
    );
};

export default memo(CustomLineDot);
