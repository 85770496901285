//TODO probably shouldn't use this, it doesn't do what it sounds like, not really an alert (or tooltip)
//need to think about what this should be called, or if we do need a tooltip alert component

import {forwardRef, useState, useEffect, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';

//Components
import PositionedBubble from 'hsi/components/Tooltip/PositionedBubble';
export {mergeReferencePropsMUI} from 'hsi/components/Tooltip/PositionedBubble';

//The component
const Alert = forwardRef(function Alert({visible, content: _content, disableAria, ...rest}, ref) {
    const [dismissed, setDismissed] = useState(false);

    const setOpen = useCallback((open) => {
        !open && setDismissed(true);
    }, []);

    const content = useMemo(
        () =>
            _content instanceof Function
                ? _content
                : ({tooltipId}) =>
                      tooltipId ? <div id={`${tooltipId}-label`}>{_content}</div> : _content,
        [_content],
    );

    //Side effect
    useEffect(
        () => {
            //called when visible value changes. If visible has gone from false to true, reset dismissed to false if required
            if (visible && dismissed) {
                setDismissed(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [!!visible],
    );

    return (
        <PositionedBubble
            content={content}
            open={visible && !dismissed}
            setOpen={setOpen}
            role={disableAria ? 'none' : 'alert'}
            labelled
            noHover
            {...rest}
            ref={ref}
        />
    );
});

export default Alert;

Alert.propTypes = {
    visible: PropTypes.bool,
};
