import React, {memo} from 'react';

export default memo(function TrendingIcon({trend, targetX, y, height}) {
    const transform = `translate(${targetX + 5}, ${y + height - 17}), scale(0.75)`;

    switch (trend) {
        case 'up':
            return (
                <path
                    d="M 9 14 V 5.842 l 2.535 2.537 l 1.414 -1.414 l -4.242 -4.243 L 8 2.015 L 3.05 6.964 v 0.002 l 1.414 1.413 L 7 5.842 V 14 z"
                    className="trendingArrow"
                    transform={transform}
                ></path>
            );
        case 'down':
            return (
                <path
                    d="M 6.999 2 v 8.158 L 4.464 7.621 L 3.05 9.035 l 4.242 4.243 l 0.707 0.707 l 4.95 -4.949 v -0.002 l -1.414 -1.413 l -2.536 2.537 V 2 z"
                    className="trendingArrow"
                    transform={transform}
                ></path>
            );
        default:
            return <g></g>;
    }
});
