import {makeStyles} from 'tss-react/mui';

export default makeStyles()((theme) => ({
    alertDialog: {
        ...(theme.v2 ? theme.typgrphy.bodyNormal : null),

        '& .MuiDialog-paper': {
            width: '700px',
        },
    },
}));
