
import {useCallback} from 'react';

import { getQueryIdDrillInFilters } from 'hsi/utils/filters/drillInFilters';
import { ParsedTotalVolumeBySearchRow } from './types';

export default function useGetDrillInData () {
    return useCallback(({name, id}: ParsedTotalVolumeBySearchRow) => ({
        dates: undefined,
        filter: getQueryIdDrillInFilters(id),
        label: name,
        tracking: {
            name: 'cardDrilledIn',
            value: name,
        },
        type: 'totalVolumeBySearch',
    } as const), []);
};
