import {useCallback} from 'react';

import Button from 'hsi/components/Button';
import IconRouter from 'hsi/components/IconRouter';

import {useAppDispatch} from 'hsi/hooks/useRedux';

import {toggleFacebookLoginDialog} from 'hsi/slices/facebookAuthentication';

import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import {T} from 'hsi/i18n';

import {isV2, Theme} from 'hsi/types/theme';

const useStyles = makeStyles((theme: Theme) => {
    const v2 = isV2(theme);
    const {colors, font, fonts} = theme;
    return {
        root: {
            padding: v2 ? 50 : 40,
            textAlign: 'center',
        },
        iconContainer: {
            textAlign: 'center',
            '& .app-icon': {
                width: 40,
                height: 40,
                fill: colors.accentDefault,
            },
            '& img': !v2
                ? {
                      width: 103,
                  }
                : {},
        },
        title: {
            color: colors.darkGrey100,
            paddingBottom: v2 ? 8 : 16,
            paddingTop: 16,
            ...(v2
                ? fonts.bodyLargeStrong
                : {
                      fontSize: 16,
                      fontWeight: font.bold,
                  }),
        },
    };
});

type AuthStartMenuProps = {
    onClose: () => void;
};

const AuthStartMenu = ({onClose}: AuthStartMenuProps) => {
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const {elements, v2} = (useTheme as any)(); // TODO: Type useTheme hook

    const onStartAuth = useCallback(() => {
        onClose();
        dispatch(toggleFacebookLoginDialog(true));
    }, [dispatch, onClose]);

    return (
        <div className={classes.root}>
            <div className={classes.iconContainer}>
                {!!v2 ? <IconRouter name="c-warning" /> : <img alt="" src={elements.failImage} />}
            </div>
            <div className={classes.title}>{T('queryBuilder.menuFBAuthStartTitle')}</div>
            <Button priority="text" color="primary" onClick={onStartAuth}>
                {T('queryBuilder.menuFBAuthStartLink')}
            </Button>
        </div>
    );
};

export default AuthStartMenu;
