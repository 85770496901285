import React, {useMemo, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import capitalize from 'lodash/capitalize';

//Components
import CardLoadState from 'hsi/components/Card/CardLoadState';
import PieChart from 'hsi/components/PieChart';
import CardTitle from 'hsi/components/Card/CardTitle';
import InfoPopupContent from 'hsi/components/InfoPopupContent';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useGetLoadData from '../useGetLoadData';
import useElementSize from 'hsi/hooks/useElementSize';
import useIsExport from 'hsi/hooks/useIsExport';
import useIsCardInteractivityDisabled from 'hsi/hooks/useIsCardInteractivityDisabled';

//Actions
import {mentionsDrillIn} from 'hsi/slices/mentions';

//Utils
import {formatBigInt} from 'hsi/utils/formatNumber';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Consts
const type = 'sentimentVolume';
const height = 316;

//The components
export default React.forwardRef(function SentimentVolume({title, ...props}, ref) {
    const classes = useStyles();
    const {
        links: {dashboardInfoSentimentVolumeCTA: popupCTA},
    } = useConfig();
    const {trackWithSearchData} = useEventTrack();
    const [sizeRef, {width} = {}] = useElementSize(null, {width: true});
    const isExport = useIsExport();

    const isCardInteractivityDisabled = useIsCardInteractivityDisabled();

    //Redux
    const dispatch = useDispatch();
    const {data, loaded, loading, error} = useSelector((state) => state.chart[type]);

    //Callbacks
    const legendFormatter = useCallback(
        (value, entry) => (
            <span className={classes.legend}>
                <strong>
                    {Math.round(entry.payload.percent * 100)}% {value}
                </strong>
            </span>
        ),
        [classes],
    );

    const exportLegendFormatter = useCallback(
        (value, entry) => (
            <span className={classes.legend}>
                <strong>
                    {Math.round(entry.payload.percent * 100)}% {value}
                </strong>{' '}
                | {formatBigInt(entry.payload.value)} {T('mentions_lc')}
            </span>
        ),
        [classes],
    );

    const drillIn = useCallback(
        (drillIn) => {
            const drillinValue = drillIn.id;
            const drillinFilter = {sentiment: {[drillinValue]: true}};
            const label = capitalize(drillIn.name);
            trackWithSearchData('cardDrilledIn', {
                type,
                value: label,
            });
            dispatch(mentionsDrillIn(drillinFilter, type, null, label));
        },
        [dispatch, trackWithSearchData],
    );

    const loadData = useGetLoadData(type);

    const content = useMemo(
        () => (
            <div
                inert={isCardInteractivityDisabled ? 'inert' : undefined}
                ref={sizeRef}
                data-testid="sentimentVolumeChartWrapper"
            >
                <PieChart
                    data={data?.categories}
                    formatLegend={isExport ? exportLegendFormatter : legendFormatter}
                    height={height}
                    onClick={drillIn}
                    width={width}
                />
            </div>
        ),
        [
            data,
            drillIn,
            exportLegendFormatter,
            isExport,
            legendFormatter,
            sizeRef,
            width,
            isCardInteractivityDisabled,
        ],
    );

    const popup = useMemo(
        () => (
            <InfoPopupContent
                copy={T(`cards.${type}.info.copy`)}
                ctaLabel={T('cards.infoCTALabel')}
                ctaUrl={popupCTA}
                title={T(`cards.${type}.info.title`)}
            />
        ),
        [popupCTA],
    );
    return (
        <CardLoadState
            {...props}
            title={
                <CardTitle
                    title={title}
                    tooltipComponent={popup}
                    type={type}
                    hasData={!!data && !!data.total}
                />
            }
            error={error}
            hasData={!!data && !!data.total}
            loadData={loadData}
            loaded={loaded}
            loading={loading}
            ref={ref}
            data-testid={type}
            type={type}
        >
            {content}
        </CardLoadState>
    );
});
