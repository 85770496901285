import React, {useCallback, forwardRef} from 'react';
import cn from 'classnames';
import ReactMarkdown from 'react-markdown';
import visit from 'unist-util-visit';
import attrsPlugin from 'remark-attr';
import remarkParse from 'remark-parse';

//Other
import useStyles from './styles';

//types
type ReactMarkdownProps = Pick<
    Parameters<typeof ReactMarkdown>[0],
    | 'rehypePlugins'
    | 'remarkPlugins'
    | 'components'
    | 'skipHtml'
    | 'sourcePos'
    | 'rawSourcePos'
    | 'includeElementIndex'
    | 'allowedElements'
    | 'disallowedElements'
    | 'allowElement'
    | 'unwrapDisallowed'
    | 'linkTarget'
    | 'transformLinkUri'
    | 'transformImageUri'
    | 'children'
>; // 'remarkRehypeOptions'

interface MarkdownProps
    extends ReactMarkdownProps,
        Omit<
            React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
            'children' | 'ref'
        > {
    classes?: Record<string, string>;
}

//Consts
const defaultRemarkPlugins: ReactMarkdownProps['remarkPlugins'] = [
    remarkParse,
    [attrsPlugin, {enableAtxHeaderInline: true}],
];

//the component
const Markdown = forwardRef<HTMLDivElement, MarkdownProps>(function Markdown(
    {
        className,
        classes: customClasses,
        children,

        components,
        remarkPlugins = defaultRemarkPlugins,
        rehypePlugins,
        skipHtml,
        sourcePos,
        rawSourcePos,
        includeElementIndex,
        allowedElements,
        disallowedElements,
        allowElement,
        unwrapDisallowed,
        linkTarget,
        transformLinkUri,
        transformImageUri,

        ...props
    },
    ref,
) {
    const classes = useStyles();

    //Callbacks
    const addClassesPlugin = useCallback(
        () =>
            (function transformer(tree: any) {
                //I cannot figure out how to correctly type this
                visit(tree, 'element', (node: any) => {
                    const className = cn(classes[node.tagName], customClasses?.[node.tagName]);

                    if (!className) {
                        return;
                    }

                    if (node.properties.className) {
                        node.properties.className.push(className);
                    } else {
                        node.properties.className = [className];
                    }
                });
            }),
        [classes, customClasses],
    );

    return (
        <div className={cn(classes.root, className)} {...props} ref={ref}>
            <ReactMarkdown
                rehypePlugins={rehypePlugins || [addClassesPlugin]}
                remarkPlugins={remarkPlugins}
                children={children}
                components={components}
                skipHtml={skipHtml}
                sourcePos={sourcePos}
                rawSourcePos={rawSourcePos}
                includeElementIndex={includeElementIndex}
                allowedElements={allowedElements}
                disallowedElements={disallowedElements}
                allowElement={allowElement}
                unwrapDisallowed={unwrapDisallowed}
                linkTarget={linkTarget}
                transformLinkUri={transformLinkUri}
                transformImageUri={transformImageUri}
            />
        </div>
    );
});

export default Markdown;

export function linkAsButtonRendererFactory(
    getOnClickFromHref: (
        href: string | undefined,
    ) => (event: React.MouseEvent<HTMLButtonElement>) => void,
): NonNullable<MarkdownProps['components']>['a'] {
    return ({node, href, ...props}) => (
        <button {...(props as any)} type="button" onClick={getOnClickFromHref(href)} />
    );
}
