import {parse, ParseConfig, ParseResult} from 'papaparse';
import {useCallback} from 'react';

export type ParseCsvErrorReasons = 'pageLimitExceeded' | 'unknown';

export type UseParseCsvProps = {
    mapToResults?: (row: string[]) => string;
    onError?: (reason: ParseCsvErrorReasons) => void;
    onSuccess?: (results: string[]) => void;
    onValidate?: (parsedResults: string[]) => string[];
    skipEmptyLines?: ParseConfig['skipEmptyLines'];
    uploadLimit?: number;
};

export const singleColumnParser = (row: string[]): string => row[0].trim().toLowerCase();

export const acceptedFileTypes = {
    'application/vnd.ms-excel': ['.csv'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.csv'],
    'text/csv': ['.csv'],
    'text/plain': ['.csv'],
};

const useParseCsv = ({
    mapToResults = singleColumnParser,
    onError,
    onSuccess,
    onValidate,
    skipEmptyLines = true,
    uploadLimit,
}: UseParseCsvProps = {}) => {
    const parseCsv = useCallback(
        (file: File) => {
            parse(file, {
                complete: async (csv: ParseResult<string[]>) => {
                    const rowLength = csv.data.filter((row) => row[0]).length;

                    if (!uploadLimit || rowLength <= uploadLimit) {
                        const parsedResults = csv.data.map(mapToResults);
                        const newResult = (await onValidate?.(parsedResults)) || parsedResults;
                        onSuccess?.(newResult);
                    } else {
                        onError?.('pageLimitExceeded');
                    }
                },
                error: () => {
                    onError?.('unknown');
                },
                skipEmptyLines,
            });
        },
        [mapToResults, onError, onSuccess, onValidate, skipEmptyLines, uploadLimit],
    );

    return {acceptedFileTypes, parseCsv};
};

export default useParseCsv;
