import React, {forwardRef} from 'react';
import cn from 'classnames';
import MenuItem from '@mui/material/MenuItem';
import MenuList, {MenuListProps} from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

//Components
import IconRouter from 'hsi/components/IconRouter';
import Checkbox from 'hsi/components/Checkbox';

//Hooks
import useUniqueId from 'hsi/hooks/useUniqueId';

//Other
import useStyles from './styles';

//The components(s)
type MenuItemLabelArgs = {
    icon?: JSX.Element;
    children: JSX.Element;
    classes: ReturnType<typeof useStyles>;
    id?: string;
    htmlFor?: string;
};

const MenuItemLabel = ({icon, children, classes, id, htmlFor}: MenuItemLabelArgs) => (
    <ListItemText className={classes.selectMenuItemTextContainer} id={id}>
        {!!icon ? (
            <div className={classes.selectMenuItemTextWithIcon}>
                <div className={cn(classes.selectMenuItemIcon)}>{icon}</div>
                <label htmlFor={htmlFor} className={classes.selectMenuItemText}>
                    {children}
                </label>
            </div>
        ) : (
            <label htmlFor={htmlFor} className={classes.selectMenuItemText}>
                {children}
            </label>
        )}
    </ListItemText>
);

type SelectableMenuItemArgs = {
    className?: string;
    children: JSX.Element;
    icon?: JSX.Element;
    selected: boolean;
    onClick?: React.MouseEventHandler<Element>;
};

export const SelectableMenuItem = ({
    className,
    children,
    icon,
    selected,
    onClick,
}: SelectableMenuItemArgs) => {
    const classes = useStyles();

    return (
        <MenuItem
            className={cn(classes.menuItem, selected && 'selected', className)}
            selected={selected}
            onClick={onClick}
        >
            <ListItemIcon className={classes.selectMenuItemCheck}>
                {selected ? (
                    <IconRouter name="f-check" className={cn(classes.selectMenuItemCheckIcon)} />
                ) : (
                    <span />
                )}
            </ListItemIcon>
            <MenuItemLabel {...{icon, children, classes}} />
        </MenuItem>
    );
};

type CheckableMenuItemArgs = {
    className?: string;
    children: JSX.Element;
    icon?: JSX.Element;

    checked: boolean;
    onChange?: (checked: boolean) => void;
    id?: string;
    onClick?: React.MouseEventHandler<HTMLLIElement>;
};

export const CheckableMenuItem = ({
    className,
    children,
    icon,
    checked,
    onChange,
    id,
    onClick,
    ...rest
}: CheckableMenuItemArgs) => {
    const classes = useStyles();
    id = useUniqueId(id, 'menuItem-');

    return (
        <MenuItem
            {...rest}
            className={cn(classes.menuItem, className)}
            onClick={(e) => {
                onChange?.(!checked);
                onClick?.(e);
            }}
        >
            <ListItemIcon>
                <Checkbox
                    checked={checked}
                    onChange={(ev) => onChange && onChange(ev.target.checked)}
                    inputProps={{id}}
                />
            </ListItemIcon>
            <MenuItemLabel icon={icon} classes={classes} htmlFor={id}>
                {children}
            </MenuItemLabel>
        </MenuItem>
    );
};

export const MenuWrapper = forwardRef<HTMLUListElement, MenuListProps>(function MenuWrapper(
    {children, className, ...rest}: MenuListProps,
    ref,
) {
    const classes = useStyles();

    return (
        <MenuList className={cn(classes.menuItemsWrapper, className)} {...rest} ref={ref}>
            {children}
        </MenuList>
    );
});
