import makeStyles from '@mui/styles/makeStyles';

const styles = (theme) => {
    return {
        root: {
            width: '100%',
            height: '60px',
            padding: '0 20px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '16px',
            fontWeight: 700,
        },

        infoBg: {
            backgroundColor: theme.colors.secondaryBlue10,
        },

        infoIconBg: {
            backgroundColor: theme.colors.secondaryBlue40,
            '& svg': {
                fill: theme.colors.secondaryBlue100,
            },
        },

        warnBg: {
            backgroundColor: theme.colors.primaryYellow10,
        },

        warnIconBg: {
            backgroundColor: theme.colors.primaryYellow40,
            '& svg': {
                fill: theme.colors.primaryYellow100,
            },
        },

        errorBg: {
            backgroundColor: theme.colors.primaryRed10,
        },

        errorIconBg: {
            backgroundColor: theme.colors.primaryRed40,
            '& svg': {
                fill: theme.colors.primaryRed100,
            },
        },

        iconWrapper: {
            width: '36px',
            height: '36px',
            borderRadius: '100%',
            marginRight: '12px',
            padding: '8px',

            '& svg': {
                width: '20px',
                height: '20px',
            },
        },

        exitLink: {
            marginLeft: 'auto',
            fontWeight: theme.font.semiBold,
            color: theme.colors.primaryDark,
            textDecoration: 'none',
            marginRight: '10px',

            '&:hover': {
                textDecoration: 'underline',
            },
        },
        chevron: {
            width: '17px',
            height: '17px',
            fill: 'currentColor',
        },
    };
};

export default makeStyles(styles);
