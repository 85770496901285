import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

const LinkText = ({classes, handleClick, link, text}) => {
    let textArray = text.split('[link]');

    const linkFragment = (
        <span className={classes.link} onClick={() => handleClick()} role="button">
            {link}
        </span>
    );

    textArray.splice(1, 0, linkFragment);

    return (
        <div>
            {textArray.map((part, idx) => (
                <Fragment key={idx}>{part}</Fragment>
            ))}
        </div>
    );
};

LinkText.propTypes = {
    classes: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired,
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default LinkText;
