import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    isQueryHelpPanelOpen: false,
    isQueryBuilderOpen: false,
};

const slice = createSlice({
    name: 'quickSearchResultsPage',
    initialState,
    reducers: {
        
        openQueryBuilder: (state) => {
            state.isQueryBuilderOpen = true;
        },
        closeQueryBuilder: (state) => {
            state.isQueryBuilderOpen = false;
        },

        openSearchHelp: (state) => {
            state.isQueryHelpPanelOpen = true;
        },

        closeSearchHelp: (state) => {
            state.isQueryHelpPanelOpen = false;
        },

        reset: (state) => {
            state.isQueryHelpPanelOpen = false;
            state.isQueryBuilderOpen = false;
        },
    },
});

export const {openQueryBuilder, closeQueryBuilder, openSearchHelp, closeSearchHelp, reset} = slice.actions;
export default slice.reducer;
