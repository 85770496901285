import http from '../classes/Http';

import {IPanelSearchRequestParams, ISocialPanel} from 'hsi/types/socialPanels';
import {SOCIAL_PANEL_GENERATIONAL_NAMES} from 'hsi/constants/config';

export const getSocialPanelList = async (params: IPanelSearchRequestParams) => {
    const response = await http.post('/fe-api/social-panels', params);
    return response.body;
};

export const getGenerationalSocialPanels = (panels: ISocialPanel[]) => {
    const SOCIAL_PANEL_TYPE = 'FILE_UPLOAD';
    return panels.filter(
        (panel) =>
            SOCIAL_PANEL_GENERATIONAL_NAMES.includes(panel.name) &&
            panel.type === SOCIAL_PANEL_TYPE,
    );
};
