import React from 'react';
import Button from 'hsi/components/Button';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//The component
export default function DeleteMessage({tag, confirmDelete, toggleMsg}) {
    const classes = useStyles();

    return (
        <div className={classes.deleteMessage}>
            <div className={classes.title}>{T('managetagsdialog.delete.title', tag)}</div>
            <div className={classes.sub}>{T('managetagsdialog.delete.sub')}</div>
            <div className={classes.disclaimer}>{T('managetagsdialog.delete.disclaimer')}</div>
            <div className={classes.actions}>
                <Button priority="secondary" onClick={toggleMsg}>
                    {T('cancel')}
                </Button>
                <Button priority="primary" onClick={(e) => confirmDelete(e, tag)}>
                    {T('delete')}
                </Button>
            </div>
        </div>
    );
}
