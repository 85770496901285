import makeStyles from '@mui/styles/makeStyles';

import {Theme} from 'hsi/types/theme';
import {tabsHeight} from 'hsi/containers/QueryBuilder/components/Tabs';

export default makeStyles(({colors, v2}: Theme) => ({
    guidedWrapper: {
        backgroundColor: v2 ? colors.white : colors.lightGrey10,
        ...(v2
            ? {
                  boxShadow: `0px 4px 9px ${colors.borders}`,
                  padding: '0px 24px',
              }
            : null),
    },
    header: {
        backgroundColor: v2 ? colors.white : colors.secondaryPurple100,
    },
    error: {
        backgroundColor: v2 ? colors.uiGrayDefault : colors.primaryRed10,
    },
    formula: {
        backgroundColor: v2 ? colors.uiGrayDefault : colors.lightGrey40,
        ...(v2 ? {} : {borderBottom: `1px solid ${colors.darkGrey20}`}),
    },
    tabs: {
        ...(v2 ? {margin: 0} : {height: tabsHeight}),
        backgroundColor: v2 ? colors.white : colors.lightGrey20,
    },
    section: {
        ...(v2 ? {} : {padding: '0 16px'}),
    },
    footer: {},
}));
