import classNames from 'classnames';

//Components
import Avatar from 'hsi/components/Avatar';
import MentionDate from '../MentionDate';
//import {OverflowTooltip} from 'hsi/components/SimpleTooltip/';
import Checkbox from 'hsi/components/Checkbox';

//Utils
import {useAuthorDetails, iconFromMentionData} from 'hsi/components/Mention/utils';
import stopPropagation from 'hsi/utils/html/stopPropagation';
import {MouseEventHandler} from 'react';
import {MentionDataType, MentionType} from 'hsi/types/mentions';

import useStyles from './styles';
import OverflowTooltip from 'hsi/components/Tooltip/OverflowTooltip';

type MentionHeaderProps = {
    mention: MentionType;
    mentionData: MentionDataType;
    onAuthorClick?: MouseEventHandler<HTMLElement>;
    hideOriginalLink?: boolean;
    onViewOriginalClick?: MouseEventHandler<HTMLAnchorElement>;
    isSelectModeAvailable?: boolean;
    selectable?: boolean;
    isSelected?: boolean;
    disableHeaderLinks?: boolean;
};

//The component
export default function MentionHeader({
    mention,
    mentionData,
    onAuthorClick,
    hideOriginalLink,
    onViewOriginalClick,
    isSelectModeAvailable = true,
    selectable = false,
    isSelected = false,
    disableHeaderLinks = false,
}: MentionHeaderProps) {
    const classes = useStyles();
    const {name, handle, rawName} = useAuthorDetails(mentionData, classes, disableHeaderLinks);
    const isSelectVisible = selectable;

    return (
        <header className={classes.header}>
            <span className={classes.avatar}>
                <Avatar
                    src={mentionData.avatar ?? undefined}
                    platformIcon={iconFromMentionData(mentionData)}
                    url={disableHeaderLinks ? undefined : mentionData.authorUrl ?? undefined}
                    onClick={onAuthorClick || stopPropagation}
                    showMiniIcon
                />
            </span>
            <div className={classes.nameLocationHandleAndDate}>
                <div className={classes.nameAndDate}>
                    <OverflowTooltip tooltip={rawName}>
                        <span className={classes.name}>{name}</span>
                    </OverflowTooltip>

                    <MentionDate
                        date={mentionData.date}
                        url={hideOriginalLink ? undefined : mention.url}
                        onViewOriginalClick={onViewOriginalClick}
                        classes={classes}
                    />
                </div>
                {(handle || mentionData.location) && (
                    <div className={classes.handleAndLocation}>
                        {handle && (
                            <>
                                <OverflowTooltip tooltip={mentionData.username}>
                                    <span className={classes.handle}>{handle}</span>
                                </OverflowTooltip>
                            </>
                        )}
                        {handle && mentionData.location ? <>&nbsp;·&nbsp;</> : null}
                        {mentionData.location && (
                            <OverflowTooltip tooltip={mentionData.location}>
                                {<span className={classes.location}>{mentionData.location}</span>}
                            </OverflowTooltip>
                        )}
                    </div>
                )}
            </div>
            {isSelectModeAvailable && (
                <span className={classNames(classes.select, !isSelectVisible && classes.hidden)}>
                    <Checkbox //This checkbox is just for show, it is not used to control anything
                        inputProps={{'aria-hidden': true, tabIndex: -1}}
                        checked={isSelected}
                        onChange={noop}
                        data-testid="select-mention"
                    />
                </span>
            )}
        </header>
    );
}

function noop() {}
