import { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';

//Other
import useStyles from './styles';
import {T} from 'hsi/i18n';
import Button from '../Button';
import IconRouter from '../IconRouter';

//Types
type CopyToClipboardProps = {
    className?: string;
    disabled?: boolean;
    value: string;
};

type C2CState = 'ready' | 'copied' | 'failed';

//Consts
const RESET_STATE_TIME = 4;//time in seconds


//The component
export default function CopyToClipboard({className, value, disabled}: CopyToClipboardProps) {
    const classes = useStyles();
    const [state, setState] = useState<C2CState>('ready');
    const tIdRef = useRef<NodeJS.Timeout | undefined>(undefined);

    //Callbacks
    const onCopyClick = useCallback(() => {
        if(copy(value)) {
            setState('copied');
        } else {
            setState('failed');
        }

        if(tIdRef.current) {
            clearInterval(tIdRef.current);
        }

        tIdRef.current = setTimeout(() => {
            tIdRef.current = undefined;
            setState('ready');
        }, RESET_STATE_TIME * 1000);
    }, [value])

    return <div className={classNames(classes.root, className)}>
        <Button priority="primary" onClick={onCopyClick} disabled={disabled} className={classes.btn}>
            <div className={classNames(classes.btnContent, state === 'ready' && classes.visible)} aria-hidden={state !== 'ready'}><IconRouter className={classes.icon} aria-hidden name="copy" />{T('copyToClipboard.label')} </div>
            <div className={classNames(classes.btnContent, state === 'copied' && classes.visible)} aria-hidden={state !== 'copied'}><IconRouter className={classes.icon} aria-hidden name="success" />{T('copyToClipboard.copied')} </div>
            <div className={classNames(classes.btnContent, state === 'failed' && classes.visible)} aria-hidden={state !== 'failed'}><IconRouter className={classes.icon} aria-hidden name="c-warning" />{T('copyToClipboard.failed')} </div>
        </Button>
    </div>
}
