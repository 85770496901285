import { makeStyles } from 'tss-react/mui';

import { isV2 } from 'hsi/types/theme';

export default makeStyles()((theme) => {
    const v2 = isV2(theme);

    if (v2) {
        //TODO get rid of this branch here
        return {
            formError: {
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'left',
                margin: '10px 0',
                fontSize: theme.fonts.bodyNormal.fontSize,
                fontWeight: theme.fonts.bodyNormal.fontWeight,
                color: theme.colors.textDefault,
                order: 2,
                backgroundColor: theme.colors.uiGrayDefault,
                padding: 20,

                '&.warning': {
                    '& .icon-wrapper': {
                        backgroundColor: theme.colors.white,
                    },
                },

                '& .icon-wrapper': {
                    width: '40px',
                    height: '40px',
                    borderRadius: '100%',
                    marginRight: '12px',
                    padding: '10px',

                    '& svg': {
                        fill: theme.colors.error,
                        width: '20px',
                        height: '20px',
                    },
                },

                '& .text-wrapper': {
                    display: 'flex',
                    flexDirection: 'column',
                },

                '& .error-text': {
                    fontWeight: '700',
                    flex: '0 0 50%',
                },

                '& .action-text': {
                    fontWeight: 'normal',
                    flex: '0 0 50%',
                },
            },

            '& .Mui-error': {
                display: 'none',
            },
        };
    } else {
        return {
            formError: {
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'left',
                margin: '10px 0',
                fontSize: '14px',
                color: '#2f3638',

                '&.warning': {
                    '& .icon-wrapper': {
                        backgroundColor: theme.colors.errorBg,
                    },
                },

                '& .icon-wrapper': {
                    width: '40px',
                    height: '40px',
                    borderRadius: '100%',
                    marginRight: '12px',
                    padding: '8px 10px',
                    alignSelf: 'center',

                    '& svg': {
                        fill: theme.colors.primaryRed100,
                        width: '20px',
                        height: '20px',
                    },
                },

                '& .text-wrapper': {
                    display: 'flex',
                    flexDirection: 'column',
                },

                '& .error-text': {
                    fontWeight: '700',
                    flex: '0 0 50%',
                },

                '& .action-text': {
                    fontWeight: 'normal',
                    flex: '0 0 50%',
                },
            },

            '& .Mui-error': {
                display: 'none',
            },
        };
    }
});
