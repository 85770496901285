import React from 'react';
import cn from 'classnames';
import {getChangePercentage} from 'hsi/utils/misc';

const BenchmarkLine = (props) => {
    const {x, y, width, height} = props;
    let targetX = x + width;
    return (
        <svg>
            <rect
                y={y - 2}
                x={targetX}
                width={2}
                height={height + 4}
                strokeWidth={2}
                strokeOpacity={0}
                className="benchmarkLine"
            />
        </svg>
    );
};

const PercentageChange = (props) => {
    const changePercentage = getChangePercentage(props.currentValue, props.previousValue);
    const showedPercentage =
        (changePercentage === Infinity ? '∞' : Math.abs(changePercentage)) + '%';

    const {x, y, width, height, trendingIconComponent: TrendingIcon} = props;
    let targetX = x + width;

    return (
        <>
            {changePercentage > 0 && (
                <TrendingIcon trend="up" targetX={targetX} y={y} height={height} />
            )}
            {changePercentage < 0 && (
                <TrendingIcon trend="down" targetX={targetX} y={y} height={height} />
            )}
            {changePercentage === 0 && (
                <TrendingIcon trend="equal" targetX={targetX} y={y} height={height} />
            )}
            <text
                x={targetX}
                y={y + height - 7}
                className={cn(
                    'percentage',
                    changePercentage > 0 && 'positive',
                    changePercentage < 0 && 'negative',
                )}
            >
                {showedPercentage}
            </text>
        </>
    );
};

const CustomBar = (props) => {
    const {x, y, width, height, color, striped, dataKey, hoveredKey} = props;
    const opacity = !!hoveredKey && hoveredKey !== dataKey ? 0.4 : striped ? 0.7 : 1;
    return (
        <>
            <rect
                x={x}
                y={y}
                width={width}
                height={height}
                stroke="#FFF"
                strokeWidth="1"
                mask={striped ? 'url(#mask-stripe)' : 'none'}
                fill={color}
                opacity={opacity}
            />
            {props.value === props[dataKey] && props[dataKey] > props.currentValue && (
                <PercentageChange {...props} />
            )}
            {props.value === props.currentValue && props.previousValue <= props.currentValue && (
                <PercentageChange {...props} />
            )}
        </>
    );
};

export {BenchmarkLine, CustomBar};
