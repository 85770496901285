import cn from 'classnames';
import {useMemo} from 'react';

import Button from 'hsi/components/Button';
import IconRouter from 'hsi/components/IconRouter';

import useStyles from './styles';

import tns from 'hsi/i18n/tns';

const t = tns('dataManagement.addDataSourcesDialog');

type UploadStatusProps = {
    containerClassName?: string;
    file?: File;
    iconClassName?: string;
    iconName: string;
    iconWrapperClassName?: string;
    itemsLength: number;
    maxItems: number;
    onCtaClick: () => void;
    status?: 'success' | 'pageLimitExceeded' | 'unknown' | 'fileFormat' | 'fileSize';
    type: string;
};

const UploadStatus = ({
    file,
    iconName,
    itemsLength,
    maxItems,
    onCtaClick,
    status = 'success',
    type,
}: UploadStatusProps) => {
    const classes = useStyles();

    const statusClasses = useMemo(
        () =>
            status === 'success'
                ? {
                      container: classes.successStatus,
                      iconWrapper: classes.successStatusIconWrapper,
                      icon: classes.successStatusIcon,
                  }
                : {
                      container: classes.errorStatus,
                      iconWrapper: classes.errorStatusIconWrapper,
                      icon: classes.errorStatusIcon,
                  },
        [classes, status],
    );

    return (
        <div className={cn(classes.statusContainer, statusClasses.container)}>
            <div className={cn(classes.statusIconWrapper, statusClasses.iconWrapper)}>
                <IconRouter
                    className={cn(classes.statusIcon, statusClasses.icon)}
                    name={iconName}
                />
            </div>
            <div className={classes.statusTitle}>
                {t(`uploadStatus.${status}.title`, {max: maxItems, num: itemsLength, type})}
            </div>
            {file && <div className={classes.statusDesc}>{file.name}</div>}
            <div className={classes.statusDesc}>
                {t(`uploadStatus.${status}.desc`, {max: maxItems, num: itemsLength, type})}
            </div>
            <Button className={classes.statusButton} onClick={onCtaClick}>
                {t(`uploadStatus.${status}.cta`, {type})}
            </Button>
        </div>
    );
};

export default UploadStatus;
