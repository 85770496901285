import React, {useMemo} from 'react';
import isObject from 'lodash/isObject';

//Components
import Select from 'hsi/components/Select';
import Label from 'hsi/components/Filters/Label';

//Hooks
import useFiltersState from 'hsi/components/Filters/hooks/useFiltersState';
import useUniqueId from 'hsi/hooks/useUniqueId';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//The component
export default function SelectFilter({
    disabled,
    onChange, //TODO implement
    filterConf: filterConfig,
}) {
    const classes = useStyles();

    const {
        filterName,
        label,
        placeholder,
        options,
        //maxValues,
        //limitLabel,
        //autosuggestType,
        //applyKeyCodes = defaultApplyKeyCodes,
        //applyKeys = defaultApplyKeys,
        //tooltipPlacement,
        //helperText,
    } = filterConfig;

    const {
        useSelector,
        actions: {
            setValueSelect,
            //setError,
            //clearFilter,
        },
    } = useFiltersState();

    const filterState = useSelector((state) => state.filters[filterName]);

    const fieldId = useUniqueId(null, `selectFilter-${filterName}-`);

    const normalisedOptions = useMemo(() => {
        return options.map((option) => {
            if (!isObject(option)) {
                return <option key={option} value={option}>{option}</option>
            } else {
                return <option key={option.value} value={option.value}>{T(option.label)}</option>
            }
        });
    }, [options]);

    return (
        <div className={classes.root}>
            {(label || placeholder) && (
                <Label invisible={!label} htmlFor={fieldId}>
                    {label ? T(label) : T(placeholder)}
                </Label>
            )}
            <Select
                id={fieldId}
                placeholder={placeholder ? T(placeholder) : null}
                value={filterState || ''}
                disabled={disabled}
                setValue={(newValue) => {
                    onChange && onChange(filterName, 'select', newValue);
                    setValueSelect(filterName, newValue);
                }}
            >{normalisedOptions}</Select>
        </div>
    );
}
