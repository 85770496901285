import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
    root: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        height: '100%',
        'overflow-x': 'hidden',
        'overflow-y': 'auto',
        scrollBehavior: 'smooth',
    },
}));
