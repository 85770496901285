export function checkOverflow(el: HTMLElement, offset = 0) {
    if (!el) return false;
    const curOverflow = el.style.overflow;
    if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden';

    const isOverflowing =
        el.clientWidth + offset < el.scrollWidth || el.clientHeight + 1 + offset < el.scrollHeight;

    el.style.overflow = curOverflow;
    return isOverflowing;
}
