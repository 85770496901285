import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
    isAddOwnedDialogOpen: false,
    isFacebookLoginDialogOpen: false,
};

const slice = createSlice({
    name: 'facebookAuthentication',
    initialState,
    reducers: {
        toggleAddOwnedDialog: (state, {payload}: PayloadAction<boolean>) => {
            state.isAddOwnedDialogOpen = payload;
        },
        toggleFacebookLoginDialog: (state, {payload}: PayloadAction<boolean>) => {
            state.isFacebookLoginDialogOpen = payload;
        },
    },
});

export const {toggleAddOwnedDialog, toggleFacebookLoginDialog} = slice.actions;
export default slice.reducer;
