import React, {useCallback, useMemo, useState} from 'react';
//import {saveAs} from 'file-saver';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import CircularProgress from '@mui/material/CircularProgress';
import LaunchIcon from '@mui/icons-material/Launch';
import TextField from '@mui/material/TextField';

import Button from 'hsi/components/Button';
import {Dialog, DialogContent, DialogActions} from 'hsi/components/Dialog';
import SelectRecipients from './SelectRecipients';
import StartDatePicker from './StartDatePicker';
import TimePicker from './TimePicker';
import FormError from 'hsi/components/FormError';

import useConfig from 'hsi/hooks/useConfig';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useReports from 'hsi/hooks/useReports';

import useStyles from './styles';

import tns from 'hsi/i18n/tns';
const t = tns('reports.editReportModal');
const tspan = tns('reports.timespanLabels');

const EditReportModal = ({acceptButtonText, onAccept, titleText}) => {
    const cl = useStyles();
    const {links, reports: reportsConfig} = useConfig();
    const {track} = useEventTrack();

    const {actions, defaultDate, isModalOpen, item, report, setName, setRecipients, setTimespan} =
        useReports();

    const [nameError, setNameError] = useState(false);
    const [recipientsError, setRecipientsError] = useState(false);
    const [startDateEnabled, setStartDateEnabled] = useState(false);

    const acceptDisabled = useMemo(
        () => item.loading || report.startDate.isBefore(defaultDate) || !!recipientsError,
        [defaultDate, item.loading, recipientsError, report.startDate],
    );

    const onClose = useCallback(() => {
        actions.closeModal();
        setStartDateEnabled(false);
    }, [actions]);

    const postSchedule = useCallback(() => {
        if (isEmpty(report?.name)) {
            setNameError(true);
            return;
        }
        if (isEmpty(report?.recipients)) {
            setRecipientsError(true);
            return;
        }
        track('reportScheduleCreated', {
            report,
        });
        onAccept();
    }, [onAccept, report, track]);

    const scheduleSummary = useMemo(
        () =>
            t('scheduleSummary', {
                timespanLabel: tspan(report.timespan),
                hours: defaultDate.hours(report.hours).format('HH'),
                minutes: defaultDate.minutes(report.minutes).format('mm'),
                timezone: reportsConfig.timezone,
            }),
        [defaultDate, report.hours, report.minutes, report.timespan, reportsConfig.timezone],
    );

    if (!isModalOpen) {
        return null;
    }

    return (
        <Dialog title={titleText} open={isModalOpen} onClose={onClose}>
            <DialogContent className="container">
                <div>
                    <div id="name" className={cl.label}>
                        {t('reportName')}
                    </div>
                    <TextField
                        className={cl.reportNameInput}
                        error={nameError}
                        helperText={
                            nameError && (
                                <FormError
                                    type="warning"
                                    errorText={'Please enter a name for your report'}
                                />
                            )
                        }
                        inputProps={{'aria-labelledby': 'name'}}
                        onChange={(e) => {
                            setName(e.target.value);
                            if (nameError) setNameError(false);
                        }}
                        placeholder={t('reportNamePlaceholder')}
                        value={report?.name}
                        variant="outlined"
                        required
                    />
                </div>

                <div className={cl.schedulePicker}>
                    <label id="frequency" className={cl.label}>
                        {t('schedulePickerTitle')}
                    </label>

                    <div className={cl.timespan}>
                        <div className={cl.timespanPicker}>
                            <TimePicker
                                cl={cl}
                                enableTimeSelection={false}
                                timespan={report?.timespan}
                                setTimespan={setTimespan}
                            />

                            <StartDatePicker
                                cl={cl}
                                startDateEnabled={startDateEnabled}
                                setStartDateEnabled={setStartDateEnabled}
                            />
                        </div>
                    </div>
                </div>

                <div
                    className={cl.scheduleSummary}
                    dangerouslySetInnerHTML={{__html: scheduleSummary}}
                    data-testid="reportSummary"
                />
                <SelectRecipients
                    error={recipientsError}
                    recipients={report?.recipients}
                    setError={setRecipientsError}
                    setRecipients={setRecipients}
                />
            </DialogContent>
            <DialogActions className={cl.scheduleFooter}>
                <Button
                    className={cl.reportExampleButton}
                    onClick={() => openReportExample(links.reportExample)}
                >
                    <span>{t('reportExample')}</span>
                    <LaunchIcon />
                </Button>

                <div className={cl.scheduleActions}>
                    <Button
                        className={cl.cancelSchedule}
                        onClick={() => {
                            track('reportCancelClicked');
                            actions.closeModal();
                        }}
                    >
                        {t('cancelSchedule')}
                    </Button>

                    <div className={cl.postScheduleContainer}>
                        <Button
                            className={cl.postSchedule}
                            disabled={acceptDisabled}
                            onClick={postSchedule}
                            priority="cta"
                        >
                            {acceptButtonText}
                        </Button>
                        {item.loading && (
                            <CircularProgress size={24} className={cl.postScheduleProgress} />
                        )}
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    );
};

EditReportModal.propTypes = {
    acceptButtonText: PropTypes.string.isRequired,
    onAccept: PropTypes.func.isRequired,
    titleText: PropTypes.string.isRequired,
};

const openReportExample = (url) =>
    fetch(url, {credentials: 'same-origin'})
        .then((r) => r.blob())
        .then((b) => {
            //saveAs(blob, 'mentions.pdf');//Replace below code with this
            const blob = new Blob([b], {type: 'application/pdf'});
            const data = URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = data;
            a.target = '_blank';
            //a.download = 'report-example.pdf';
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                URL.revokeObjectURL(data);
                document.body.removeChild(a);
            }, 100);
        });

export default EditReportModal;
