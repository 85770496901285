import {CategoryDefinition, LanguageDefinition, LocationDefinition} from 'hsi/types/filters';
import {AsyncResultsType} from 'hsi/types/shared';
import {AnyAction} from 'redux';
import {
    LOADED_LOCATIONS,
    LOADED_LANGUAGES,
    LOADING_LOCATIONS,
    LOADING_LANGUAGES,
    LOAD_LANGUAGES_ERROR,
    LOAD_LOCATIONS_ERROR,
    RESET_LOCATIONS,
    LOADING_CATEGORIES,
    LOADED_CATEGORIES,
    LOAD_CATEGORIES_ERROR,
    LOADING_ASSIGNMENT,
    LOADED_ASSIGNMENT,
    LOAD_ASSIGNMENT_ERROR,
} from '../constants/actionTypes';

type AssignmentType = {}; //TODO

const initialState = {
    languageAutocomplete: {
        loading: false,
        loaded: false,
        error: null,
        results: [],
    } as AsyncResultsType<LanguageDefinition>,
    locationAutocomplete: {
        loading: false,
        loaded: false,
        error: null,
        results: [],
    } as AsyncResultsType<LocationDefinition>,
    categories: {
        loading: false,
        loaded: false,
        results: [],
        error: null,
    } as AsyncResultsType<CategoryDefinition>,
    assignment: {
        loading: false,
        loaded: false,
        results: [],
        error: null,
    } as AsyncResultsType<AssignmentType>,
};

type StateType = typeof initialState;

export function normaliseLanguage(lang: {[key: string]: any}): LanguageDefinition {
    return {
        id: lang.code,
        fullName: lang.name,
        alpha: lang.alpha, //?
    };
}

function loading(state: StateType, prop: keyof StateType): StateType {
    return {
        ...state,
        [prop]: {
            loading: true,
            loaded: false,
            error: null,
            results: [],
        },
    };
}

function loaded<T>(state: StateType, prop: keyof StateType, results: T[]): StateType {
    return {
        ...state,
        [prop]: {
            loading: false,
            loaded: true,
            error: null,
            results,
        },
    };
}

function reset(state: StateType, prop: keyof StateType): StateType {
    return {
        ...state,
        [prop]: {
            results: [],
            loading: false,
            loaded: false,
            error: null,
        },
    };
}

function loadError(state: StateType, prop: keyof StateType): StateType {
    return {
        ...state,
        [prop]: {
            results: [],
            loading: false,
            loaded: false,
            error: true,
        },
    };
}

const autocompleteReducer = (state: StateType = initialState, action: AnyAction) => {
    switch (action.type) {
        //Locations
        case LOADING_LOCATIONS:
            return loading(state, 'locationAutocomplete');
        case LOADED_LOCATIONS:
            return loaded<LocationDefinition>(
                state,
                'locationAutocomplete',
                action.payload.results,
            );
        case RESET_LOCATIONS:
            return reset(state, 'locationAutocomplete');
        case LOAD_LOCATIONS_ERROR:
            return loadError(state, 'locationAutocomplete');

        //Languages
        case LOADING_LANGUAGES:
            return loading(state, 'languageAutocomplete');
        case LOADED_LANGUAGES:
            return loaded<LanguageDefinition>(
                state,
                'languageAutocomplete',
                action.payload.results.map(normaliseLanguage),
            );
        case LOAD_LANGUAGES_ERROR:
            return loadError(state, 'languageAutocomplete');

        //Categories
        case LOADING_CATEGORIES:
            return loading(state, 'categories');
        case LOADED_CATEGORIES:
            console.log('categories: ', action.payload);
            return loaded<CategoryDefinition>(state, 'categories', action.payload);
        case LOAD_CATEGORIES_ERROR:
            return loadError(state, 'categories');

        //Assignments
        case LOADING_ASSIGNMENT:
            return loading(state, 'assignment');
        //return {...state, categories: {loading: true, loaded: false, results: [], error: null}};//guessing this was a bug
        case LOADED_ASSIGNMENT:
            return loaded<AssignmentType>(state, 'assignment', action.payload);
        case LOAD_ASSIGNMENT_ERROR:
            return loadError(state, 'assignment');
        default:
            return state;
    }
};

export default autocompleteReducer;
