import cn from 'classnames';
import {useCallback} from 'react';

import {DialogActions, Dialog, DialogContent} from 'hsi/components/Dialog';

import useStyles from './styles';

export type DialogContainerProps = {
    actions: React.ReactNode;
    children: React.ReactNode;
    className?: string;
    onClose: () => void;
    title: string;
    transitionDuration?: number;
};

const DialogContainer = ({
    actions,
    children,
    className,
    onClose,
    title,
    transitionDuration = 0,
}: DialogContainerProps) => {
    const classes = useStyles();

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    return (
        <Dialog
            className={cn(classes.container, className)}
            onClose={handleClose}
            open={true}
            title={title}
            transitionDuration={transitionDuration}
        >
            <DialogContent className={classes.contentWrapper}>{children}</DialogContent>
            <DialogActions>{actions}</DialogActions>
        </Dialog>
    );
};

export default DialogContainer;
