import merge from 'lodash/merge';
import {registerFilterType, checkUpdate} from './../utils';

//categories: [{id: children: [{id}]}]
export function getInitStateFromCategories(categories) {
    const initState = {
        activeModeIsInclude: true,
        include: {},
        exclude: {},
    };

    // TODO: Need to make sure that categories get set for streams
    (categories || []).forEach(({id, children}) => {
        initState.include[id] = {};
        initState.exclude[id] = {};

        children.forEach(({id: childId}) => {
            initState.include[id][childId] = false;
            initState.exclude[id][childId] = false;
        });
    });

    return initState;
}

function allValuesFalse(objects) {
    return objects.every((object) => {
        return Object.values(object).every((value) => value === false);
    });
}

registerFilterType(
    'category',
    (filterConfig) => {
        return getInitStateFromCategories(filterConfig.categories);
    },
    (filterConfig, filterState) =>
        allValuesFalse(Object.values(filterState.include)) &&
        allValuesFalse(Object.values(filterState.exclude)),
    {
        //reducers
        setCategoryMode: (state, {payload: {filterName, value}}) => {
            //check filter is correct type
            if (state.allFiltersConfig[filterName].type === 'category') {
                state.filters[filterName].activeModeIsInclude = !!value;
                state.update++;
            } else {
                throw new Error('This action is only valid for category filters');
            }
        },
        setCategoryValue: [
            ['filterName', 'value', 'mode'],
            (state, {payload: {filterName, value, mode}}) => {
                if (state.allFiltersConfig[filterName].type === 'category') {
                    // for now just merge in the new value - should really check the format, values etc
                    merge(state.filters[filterName][mode ? 'include' : 'exclude'], value);

                    checkUpdate(state);
                } else {
                    throw new Error('This action is only valid for category filters');
                }
            },
        ],
        deleteCategory: [
            ['filterName', 'category', 'mode'],
            (state, {payload: {filterName, category, mode}}) => {
                if (state.allFiltersConfig[filterName].type === 'category') {
                    const filterState = state.filters[filterName][mode ? 'include' : 'exclude'];

                    Object.keys(filterState[category]).forEach((id) => {
                        filterState[category][id] = false;
                    });

                    checkUpdate(state);
                } else {
                    throw new Error('This action is only valid for category filters');
                }
            },
        ],
    },
    {
        //validated reducers func(state, payload)
        // setValueTextfield: (state, {filterName, value}) => {
        //     state.filters[filterName] = value;
        // },
    },
    (state, filterName, value) => {
        //TODO need to check format is correct, values are valid, etc

        return true;
    },
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    (filterConfig, currentValue, persistedValue) => {
        return persistedValue; //TODO should check this is correct
    },
);
