import React, {useMemo} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

//Components
import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle from 'hsi/components/Card/CardTitle';
import IconRouter from 'hsi/components/IconRouter';
import InfoPopupContent from 'hsi/components/InfoPopupContent';

//Hooks
import useGetLoadData from '../useGetLoadData';
import useConfig from 'hsi/hooks/useConfig';

//Other
import useStyles from './styles';
import {T} from 'hsi/i18n';

//Constants
const type = 'gender';

//The components
const Gender = React.forwardRef(({title, ...props}, ref) => {
    const classes = useStyles();

    //Redux
    const {data, loading, loaded, error} = useSelector((state) => state.chart[type]);
    const isSelectedCard = useSelector((state) => state.mentions.drillInCard === type);
    const hasData = useMemo(() => !!data?.some(({value}) => value > 0), [data]);

    //Calculated values
    const loadData = useGetLoadData(type);

    const content = useMemo(() => {
        if (!data) return null;

        return (
            <div className={classes.genderCard}>
                {data.map(({id, name, pct, value}) => (
                    <div className={cn('gender', id)} key={id}>
                        <IconRouter labelId={id} name={id} role="img" title={name} />
                        <h3 className="percentage">{T('percentage', {pct})}</h3>
                        <h4 className={cn('total', id)}>
                            {T(`cards.${type}.total`, {volume: value})}
                        </h4>
                    </div>
                ))}
            </div>
        );
    }, [classes, data]);

    const popup = useGetPopup();

    //Render
    return (
        <CardLoadState
            {...props}
            title={
                <CardTitle title={title} tooltipComponent={popup} type={type} hasData={hasData} />
            }
            error={error}
            loading={loading}
            loaded={loaded}
            hasData={hasData}
            selected={isSelectedCard}
            loadData={loadData}
            ref={ref}
            data-testid={type}
            type={type}
        >
            {content}
        </CardLoadState>
    );
});

export function useGetPopup() {
    const {
        links: {dashboardInfoGenderCTA: popupCTA},
    } = useConfig();

    return useMemo(
        () => (
            <InfoPopupContent
                copy={T(`cards.${type}.info.copy`)}
                ctaLabel={T('cards.infoCTALabel')}
                ctaUrl={popupCTA}
                title={T(`cards.${type}.info.title`)}
            />
        ),
        [popupCTA],
    );
}

Gender.propTypes = {
    props: PropTypes.shape({
        fadeOnVisible: PropTypes.func.isRequired,
        height: PropTypes.number.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onContentChanged: PropTypes.func.isRequired,
        showConfig: PropTypes.bool.isRequired,
    }),
};

Gender.displayName = 'Gender';

export default Gender;
