//Hooks
import {useAppSelector} from './useRedux';

//Types
import {QueryContextType} from 'hsi/types/query';

//The hook
export default function useIsSavedSearch(includeEditMode: boolean = false): boolean {
    return useAppSelector((state) => {
        const {isSavedSearch, isEditSearch} = state.query.context as QueryContextType;

        return isSavedSearch || (includeEditMode && isEditSearch);
    });
}
