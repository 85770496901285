import {forwardRef} from 'react';

import MuiCard, {CardProps as MuiCardProps} from '@mui/material/Card';
import MuiCardContent from '@mui/material/CardContent';

import useStyles from './styles';

export interface DefaultCardProps extends MuiCardProps {
    cardDesc?: React.ReactNode;
    cardTitle: React.ReactNode;
    disabled?: boolean;
    id: string;
    ImageComponent?: React.ElementType;
}

const DefaultCard = forwardRef<Element, DefaultCardProps>(
    ({cardDesc, cardTitle, ImageComponent, id, ...rest}, ref) => {
        const classes = useStyles();

        return (
            <MuiCard className={classes.card} ref={ref} component="div" {...rest}>
                {ImageComponent && <ImageComponent />}
                <MuiCardContent>
                    <div className={classes.cardTitle}>{cardTitle}</div>
                    {cardDesc && <div className={classes.cardDesc}>{cardDesc}</div>}
                </MuiCardContent>
            </MuiCard>
        );
    },
);

export default DefaultCard;
