import { Children, PropsWithChildren, ReactElement, useMemo } from "react";

export type TextListProps = PropsWithChildren<{
    locale?: string | string[];
    style?: Intl.ListFormatOptions['style'];
    type?: Intl.ListFormatOptions['type'];
    localeMatcher?: Intl.ListFormatOptions['localeMatcher'];
}>;

export default function TextList({children, locale = 'en-US', style, type, localeMatcher}: TextListProps) {
    const [values, indexes] = useMemo(() => {
        const values = Children.toArray(children).filter((child) => !!child);
        const indexes = values.map((value, index) => index.toString());

        return [values, indexes];
    }, [children]);
    

    const listFormatter = useMemo(() => {
        return new Intl.ListFormat(locale, { style, type, localeMatcher })
    }, [locale, localeMatcher, style, type]);

    return useMemo(() => listFormatter.formatToParts(indexes).map(({value, type}) => {
        return type === 'literal'
            ? value
            : values[+value]
    }), [indexes, listFormatter, values]) as unknown as ReactElement;
}
