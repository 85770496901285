import React, {useEffect} from 'react';
import {TopicsCloud} from '@brandwatch/topics-visualisations/dist/topics-vis.explore-mode.js';

//hooks
import useElementSize from 'hsi/hooks/useElementSize';

//The component
export default function WordCloudContainer({wordCloudRef, onWordClick, collection, legend}) {
    const [containerSizeRef, {width: containerWidth} = {}, container] = useElementSize(null, {
        width: true,
    });

    //Side effects
    useEffect(
        //init wordCloud
        () => {
            if (container) {
                wordCloudRef.current = new TopicsCloud({
                    container,
                    onClick: onWordClick,
                });

                wordCloudRef.current.update(collection, legend);
            } else {
                wordCloudRef.current = null;
            }
        },
        // We only want to re-run this if the container changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [container],
    );

    useEffect(
        //update the wordcloud's size when the contain changes size
        () => {
            wordCloudRef.current && wordCloudRef.current.renderBasedOnContainerDimensionsChanges();
        },
        // We only want to re-run this if the container width changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [containerWidth],
    );

    useEffect(
        //update wordcloud with collection and legend data
        () => {
            wordCloudRef.current && wordCloudRef.current.update(collection, legend);
        },
        // We only want to re-run this if the data changes.
        // I wonder if this could be merged with the init useEffect?
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [collection, legend],
    );

    return (
        <div
            ref={containerSizeRef}
            className="word-cloud-card-contents"
            style={{height: '275px'}}
        />
    );
}
