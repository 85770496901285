//This component is an example of one that is trying to do too much in one thing. This should be achieved by composing other, simpler components to create a more
//flexible set of components, which are individually easier to understand, test, modify and reuse
import {useCallback, useMemo, useState} from 'react';
import cn from 'classnames';

import Button from 'hsi/components/Button';
import Checkbox from 'hsi/components/Checkbox';
import IconRouter from 'hsi/components/IconRouter';
import SearchBox from 'hsi/components/SearchBox';

import useStyles from './styles';

import {isSubstringInString} from 'hsi/utils/misc';
import {T} from 'hsi/i18n';

type CheckboxListProps = {
    className?: string;
    cta?: string;
    defaultFilter?: string;
    formatLabel?: (item: any) => React.ReactNode;
    handleCtaClick?: () => void;
    label?: string;
    labelField?: string;
    options?: any[];
    onChange: (options: any[]) => void;
    optionsListClassName?: string;
    renderNoResults?: (props: any) => React.ReactNode;
    searchAutoFocus?: boolean;
    searchPlaceholder?: string;
    selectedOptions?: any[];
    showSearchAtLength?: number;
    showSelectAll?: boolean;
    valueField?: string;
};

const CheckboxList = ({
    className,
    cta,
    defaultFilter = '',
    formatLabel,
    handleCtaClick,
    label,
    labelField = 'label',
    options = [],
    onChange,
    optionsListClassName,
    renderNoResults = renderDefaultNoResults,
    searchAutoFocus = false,
    searchPlaceholder = T('search.shortPlaceholder'),
    selectedOptions = [],
    showSearchAtLength,
    showSelectAll = false,
    valueField = 'value',
}: CheckboxListProps) => {
    const classes = useStyles();

    const [filter, setFilter] = useState(defaultFilter);

    const isAllSelected = useMemo(
        () => options.length === selectedOptions.length,
        [options.length, selectedOptions.length],
    );
    const isSelectAllIndeterminate = useMemo(
        () => options.length > selectedOptions.length && selectedOptions.length > 0,
        [options.length, selectedOptions.length],
    );
    const showSearch = useMemo(
        () => showSearchAtLength && options?.length >= showSearchAtLength,
        [options, showSearchAtLength],
    );
    const filteredOptions = useMemo(
        () =>
            showSearch
                ? options.filter(
                      (option) =>
                          isSubstringInString(option[labelField], filter) ||
                          isSubstringInString(option[valueField], filter),
                  )
                : options,
        [filter, labelField, options, showSearch, valueField],
    );

    const selectAll = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) =>
            onChange?.(event.target.checked ? options : []),
        [onChange, options],
    );

    const isItemSelected = useCallback(
        (item: any) =>
            !!selectedOptions.find(
                (selectedOption) => selectedOption[valueField] === item[valueField],
            ),
        [selectedOptions, valueField],
    );

    const onItemChange = useCallback(
        (item: any, checked: boolean) => {
            if (checked) {
                onChange?.([...selectedOptions, item]);
            } else {
                onChange?.(
                    selectedOptions.filter(
                        (selectedOption) => selectedOption[valueField] !== item[valueField],
                    ),
                );
            }
        },
        [onChange, selectedOptions, valueField],
    );

    return (
        <div className={cn(classes.container, className)} data-testid="checkboxList">
            {!!label && <div className={classes.label}>{label}</div>}
            {!!showSearch && (
                <div className={classes.searchWrapper}>
                    <SearchBox
                        autoFocus={searchAutoFocus}
                        className={classes.search}
                        fullWidth
                        onChange={(event) => setFilter(event.target.value)}
                        placeholder={searchPlaceholder}
                        value={filter}
                    />
                </div>
            )}
            {!!showSelectAll && (
                <Checkbox
                    className={classes.selAllItem}
                    checked={isSelectAllIndeterminate ? null : isAllSelected}
                    children={T('selectAll')}
                    onChange={selectAll}
                />
            )}
            <fieldset className={cn(classes.optionList, optionsListClassName)}>
                {filteredOptions.map((item) => (
                    <Checkbox
                        checked={isItemSelected(item)}
                        children={formatLabel?.(item) || item[labelField]}
                        onChange={(event) => onItemChange(item, event.target.checked)}
                        className={classes.listItem}
                        key={`${item[labelField]}-${item[valueField]}`}
                    />
                ))}
                {!!options?.length && !filteredOptions.length && renderNoResults({classes})}
            </fieldset>
            {!!cta && (
                <Button
                    className={classes.cta}
                    fullWidth
                    onClick={handleCtaClick}
                    priority="secondary"
                >
                    <IconRouter className={classes.ctaIcon} name="f-add" />
                    {cta}
                </Button>
            )}
        </div>
    );
};

const renderDefaultNoResults = ({classes}: {classes: ReturnType<typeof useStyles>}) => (
    <div className={classes.noResults}>
        <IconRouter className={classes.noResultsIcon} name="search" />
        <div className={classes.noResultsTitle}>{T('checkboxList.noResultsTitle')}</div>
        <div className={classes.noResultsDesc}>{T('checkboxList.noResultsDesc')}</div>
    </div>
);

export default CheckboxList;
