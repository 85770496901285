import {useMemo} from 'react';
import {selectAdditionalQueries, selectLinkedinChannelIds, selectSavedSearch} from 'hsi/selectors';
import {useAppSelector} from './useRedux';

export default function useMultipleSearchIds() {
    const savedSearch = useAppSelector(selectSavedSearch);
    const additionalQueries = useAppSelector(selectAdditionalQueries);
    const linkedinChannelIds = useAppSelector(selectLinkedinChannelIds);

    const _searchIds: number[] = useMemo(
        () => [(savedSearch?.id as number) || null, ...(additionalQueries || [])].filter((value) => value !== null) as number[],
        [savedSearch, additionalQueries],
    );

    //XXX include LI channels in multiple searches (TODO linkedin: remove this when LI is implemented without BCR channels)
    const searchIds: number[] = linkedinChannelIds ? _searchIds.concat(linkedinChannelIds) : _searchIds;

    return {searchIds}; //why is this in a object?
}
