//Components
import ExportMenu from '../ExportMenu';
import OrderingMenu from '../OrderingMenu';

import IconRouter from 'hsi/components/IconRouter';
import HelpIcon from 'hsi/components/HelpIcon';
import InfoPopupContent from 'hsi/components/InfoPopupContent';
import Tooltip from 'hsi/components/Tooltip';

//Hooks
import useEventTrack from 'hsi/hooks/useEventTrack';
import useFlags from 'hsi/hooks/useFlags';

import useStyles from './styles';
import {T} from 'hsi/i18n';
import {useMemo} from 'react';
import useConfig from 'hsi/hooks/useConfig';
import {MENTION_LAYOUT_TYPES, MentionsLayout, clearSelectedMentions} from 'hsi/slices/mentions';
import RadioButtons from 'hsi/components/RadioButtons';
import Toggle from 'hsi/components/Toggle';
import BulkActionsMenu from '../BulkActionsMenu';
import {MAX_SELECTED_MENTIONS} from 'hsi/constants/config';
import {useAppSelector, useAppDispatch} from 'hsi/hooks/useRedux';
import {introAnchors} from 'hsi/containers/Intro';
import Heading from 'hsi/components/aria/Heading';

//Types
type MentionsContainerHeaderProps = {
    isSelectMode?: boolean;
    setIsSelectMode?: (value: boolean) => void;
    isFull: boolean;
    isSavedSearch: boolean;
    fullLayout: MentionsLayout;
    setFullLayout?: (newLayout: MentionsLayout) => void;
    toggleIsFull?: () => void;
    toggleIsOpen?: () => void;
};

export default function MentionsContainerHeader({
    isSelectMode = false,
    setIsSelectMode,
    isFull,
    fullLayout,
    setFullLayout,
    toggleIsFull,
    toggleIsOpen,
    isSavedSearch,
}: MentionsContainerHeaderProps) {
    const {classes, cx} = useStyles();
    const {trackWithSearchData} = useEventTrack();

    const isSelectModeEnabled = !!setIsSelectMode;
    const numSelectedMentions = useAppSelector((state) => state.mentions.numSelectedMentions) ?? 0;

    const dispatch = useAppDispatch();

    const {
        links: {dashboardInfoMentionsCTA: popupCTA},
        hasIcons4layoutSelection,
    } = useConfig();

    const tooltipContent = useMemo(
        () => (
            <InfoPopupContent
                title={T('mentionsContainer.info.title')}
                copy={T('mentionsContainer.info.copy')}
                ctaUrl={popupCTA}
                ctaLabel={T('cards.infoCTALabel')}
            />
        ),
        [popupCTA],
    );

    const {mentionsOptionsRowEnabled} = useFlags();

    return (
        <header className={cx(classes.header, isFull && classes.full)}>
            <div className={classes.row}>
                <div className={classes.left}>
                    <Heading className={classes.title}>{T('mentionsContainer.title')}</Heading>
                    <HelpIcon
                        buttonLbl={T('mentionsContainer.infoBtnLbl')}
                        icon="c-question-e"
                        onClick={(e) => e.stopPropagation()}
                        onShow={() => trackWithSearchData('mentionDrawerTooltipShown', {})}
                        size="medium"
                        tooltip={tooltipContent}
                    />
                </div>
                <div className={classes.right}>
                    {toggleIsFull && (
                        <button
                            className={classes.showMoreLessBtn}
                            type="button"
                            onClick={toggleIsFull}
                            aria-expanded={isFull}
                        >
                            {isFull
                                ? T('mentionsContainer.showLess')
                                : T('mentionsContainer.showMore')}
                            <span className={classes.showMoreLessBtnIconContainer}>
                                <IconRouter
                                    aria-hidden
                                    name={
                                        isFull ? 'hsi-double-arrow-right' : 'hsi-double-arrow-left'
                                    }
                                    className={
                                        (classes.showMoreLessBtnIcon,
                                        isFull
                                            ? classes.doubleArrowIconRight
                                            : classes.doubleArrowIconLeft)
                                    }
                                />
                            </span>
                        </button>
                    )}
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.left}>
                    <OrderingMenu isSavedSearch={isSavedSearch} />

                    {isFull && (
                        <RadioButtons<MentionsLayout>
                            label={T('mentionsContainer.fullLayout.label')}
                            name="mentionsFullLayout"
                            value={fullLayout}
                            setValue={setFullLayout}
                        >
                            {MENTION_LAYOUT_TYPES.map((sortType) => {
                                const text = T(`mentionsContainer.fullLayout.${sortType}`);

                                return (
                                    <RadioButtons.Item value={sortType} key={sortType}>
                                        {hasIcons4layoutSelection ? (
                                            <>
                                                <Tooltip tooltip={text} distance={20} portal noAria>
                                                    <IconRouter aria-hidden name={sortType} />
                                                </Tooltip>
                                                <span className="offscreen">{text}</span>
                                            </>
                                        ) : (
                                            text
                                        )}
                                    </RadioButtons.Item>
                                );
                            })}
                        </RadioButtons>
                    )}
                </div>
                <div className={classes.right}>
                    <ExportMenu isSavedSearch={isSavedSearch} />
                </div>
            </div>
            {isSelectModeEnabled &&  (
                <div className={cx(classes.row, classes.verticalSpace)}>
                    <div className={cx(classes.left, introAnchors.bulkActions.enableBulkActions)}>
                        { (mentionsOptionsRowEnabled  || isFull ) &&   <>
                        <Toggle
                            mode="switch"
                            id="mentionsIsSelectMode"
                            checked={isSelectMode}
                            onChange={(e, newValue) => setIsSelectMode(newValue)}
                            size="large"
                        />
                        <label
                        className={classes.selectModeSwitchLbl}
                        htmlFor="mentionsIsSelectMode"
                        >
                            {T('mentionsContainer.selectModeToggleLbl')}
                        </label>
                        </>
                        }
                        {isSelectMode && (
                            <>
                                <span className={classes.numSelectedMentions}>
                                    {T('mentionsContainer.numSelectedMentions', {
                                        num: numSelectedMentions,
                                        max: MAX_SELECTED_MENTIONS,
                                    })}
                                </span>
                                <button
                                    className={classes.clearSelectedMentions}
                                    disabled={numSelectedMentions === 0}
                                    onClick={() => dispatch(clearSelectedMentions())}
                                >
                                    {T('mentionsContainer.clearSelectedMentions')}
                                </button>
                            </>
                        )}
                    </div>
                    {  (mentionsOptionsRowEnabled || isFull ) &&  
                    <div className={classes.right}>
                        <BulkActionsMenu
                            isSelectMode={isSelectMode}
                            isFull={isFull}
                            isAnyMentionsSelected={numSelectedMentions > 0}
                        />
                    </div>
}
                </div>
            )}
        </header>
    );
}
