import {useCallback} from 'react';

//Components
import Select from 'hsi/components/Select';
import SaveSearchTooltipDialog from 'hsi/components/SaveSearchTooltipDialog';
import ConditionalWrap from 'hsi/components/ConditionalWrap';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';


//The component
export default function InclusionSelect({include = true, onChange, disabled, noExclusion, id}) {
    const classes = useStyles();
    
    const value = include ? 'include' : 'exclude';

    //Callbacks
    const setValue = useCallback(
        (value) => {
            onChange && onChange(value === 'include');
        },
        [onChange],
    );

    const wrapFunc = useCallback(
        (children) => {
            return noExclusion ? (
                <SaveSearchTooltipDialog
                    distance={15}
                    messageId="exclusionFilter"
                    placement="top"
                    theme={'light'}
                >
                    <div>{children}</div>
                </SaveSearchTooltipDialog>
            ) : (
                children
            );
        },
        [noExclusion],
    );

    //Render
    return (
        <ConditionalWrap wrap={wrapFunc}>
            <Select
                id={id}
                readOnly={noExclusion}
                value={value}
                setValue={setValue}
                className={classes.root}
                disabled={!!disabled}
            >
                <option value="include" disabled={!!disabled}>{T('filters.inclusionDropDown.include')}</option>
                <option value="exclude" disabled={!!(noExclusion || disabled)}>{T('filters.inclusionDropDown.exclude')}</option>
            </Select>
        </ConditionalWrap>
    );
}
