//TODO this needs to be a tooltip
//Could add option to appear on hover or on click?
//Could add option for tooltip style?
//Could add option for icon size?

import {HTMLAttributes, ReactElement, forwardRef} from 'react';
import IconButton, { IconButtonOwnProps } from '@mui/material/IconButton';
import classNames from 'classnames';

//Components
import IconRouter from 'hsi/components/IconRouter';
import Popover from 'hsi/components/Popover';

//Hooks
import useUniqueId from 'hsi/hooks/useUniqueId';

//Other
import useStyles from './styles';
import {Placement} from '@floating-ui/react-dom-interactions';

export type HelpIconSize = 'normal' | 'medium' | 'large';

interface HelpIconArgs extends HTMLAttributes<HTMLDivElement> {
    tooltip: ReactElement;
    tooltipDistance?: Parameters<typeof Popover>[0]['distance'];
    placement?: Placement;
    size?: HelpIconSize;
    icon?: string;
    buttonLbl: string;
    onShow?: () => void;
}

//The component
export default function HelpIcon({
    children,
    tooltip,
    tooltipDistance = undefined,
    placement,
    className = '',
    id = undefined,
    size = 'normal',
    icon = 'info',
    buttonLbl,
    onShow,
    ...rest
}: HelpIconArgs) {
    const classes = useStyles();
    id = useUniqueId(id, 'help');

    return (
        <div className={classNames(classes.root, classes[size], className)} {...rest}>
            {children && (
                <label className={classes.msg} htmlFor={id}>
                    {children}
                </label>
            )}
            <Popover
                content={tooltip} //TODO link labelId and descriptionId, with aria-props - use a context?
                distance={tooltipDistance}
                placement={placement}
                onShow={onShow}
                portal
            >
                <HelpIconButton
                    icon={icon}
                    size={size}
                    aria-label={buttonLbl}
                />
            </Popover>
        </div>
    );
}

type HelpIconButtonProps = Omit<IconButtonOwnProps, 'size'> & {
    icon?: string;
    size?: HelpIconSize;
    className?: string;
};

export const HelpIconButton = forwardRef<HTMLButtonElement, HelpIconButtonProps>(function HelpIconButton({
    icon = 'info', 
    size = 'normal',
    className,
    ...rest
}, ref) {
    const classes = useStyles();
    return <IconButton
        className={classNames(classes.btn, classes[size], className, 'hideForExport')}
        size="large"
        {...rest}
        ref={ref}
    >
        <IconRouter className={classNames(classes.icon, classes[size])} name={icon} />
    </IconButton>
})
