import { forwardRef } from 'react';
import classNames from 'classnames';

import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';
import OverflowTooltip from '../Tooltip/OverflowTooltip';

import useStyles from './styles';

interface ChipProps extends MuiChipProps {
    className?: string;
    label: string;
    overflowTooltip?: boolean;
}

const Chip = forwardRef(
    (
        {label, className, overflowTooltip = false, ...props}: ChipProps,
        ref: React.ForwardedRef<HTMLDivElement>,
    ) => {
        const classes = useStyles();

        const content = (
            <MuiChip
                className={classNames(className, classes.chip)}
                label={label}
                ref={ref}
                {...props}
            />
        );

        return overflowTooltip ? (
            <OverflowTooltip overflowChildSelector={'.MuiChip-label'} tooltip={label}>
                {content}
            </OverflowTooltip>
        ) : (
            content
        );
    },
);

export default Chip;
