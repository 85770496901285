import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual';

import {
    registerFilterType,
    getBlankObj,
    includes,
    indexOf,
    checkUpdate,
    isValueValid,
} from './../utils';

//Consts
const DEFAULT_INCLUDEANDEXLUDE_FIELD = {
    include: [],
    exclude: [],
};

registerFilterType(
    'includeAndExclude',
    (filterConfig) => ({
        activeModeIsInclude: true,
        fields: filterConfig.fields.reduce((output, {fieldName}) => {
            output[fieldName] = DEFAULT_INCLUDEANDEXLUDE_FIELD;

            return output;
        }, {}),
    }),
    (filterConfig, filterState) =>
        !filterState ||
        Object.values(filterState.fields).every(
            ({include, exclude}) => include.length === 0 && exclude.length === 0,
        ),
    {
        //reducers
        setIncludeAndExcludeMode: (state, {payload: {filterName, value}}) => {
            //check filter is correct type
            if (state.allFiltersConfig[filterName].type === 'includeAndExclude') {
                state.filters[filterName].activeModeIsInclude = !!value;
                state.update++;
            } else {
                throw new Error('This action is only valid for includeAndExclude filters');
            }
        },
        removeValueIncludeAndExclude: [
            ['filterName', 'field', 'value'],
            (state, {payload: {filterName, field, value}}) => {
                if (state.allFiltersConfig[filterName].type !== 'includeAndExclude') {
                    throw new Error('This action is only valid for includeAndExclude type filters');
                }

                //try removing from include
                const includeValues = state.filters[filterName].fields[field].include;
                const includeIndex = includeValues.findIndex((item) => isEqual(item, value));

                if (includeIndex !== -1) {
                    includeValues.splice(includeIndex, 1);
                    checkUpdate(state);
                    return;
                }

                const excludeValues = state.filters[filterName].fields[field].exclude;
                const excludeIndex = excludeValues.findIndex((item) => isEqual(item, value));

                if (excludeIndex !== -1) {
                    excludeValues.splice(excludeIndex, 1);
                    checkUpdate(state);
                    return;
                }

                //If you get here, value was not present in state
            },
        ],
    },
    {
        //validated reducers func(state, payload)
        addValueIncludeAndExclude: [
            ['filterName', 'fieldName', 'mode', 'value'],
            (state, {filterName, fieldName, mode, value}) => {
                const config = state.allFiltersConfig[filterName];
                const fieldConfig = config.fields.find((field) => field.fieldName === fieldName);
                const fieldState = state.filters[filterName].fields[fieldName];

                const modeValues = fieldState[mode ? 'include' : 'exclude'];

                // If this value is already present in the opposite mode (e.g. adding x to include, but x is already in exclude),
                // then remove from the alt mode
                const altModeValues =
                    state.filters[filterName].fields[fieldName][!mode ? 'include' : 'exclude'];
                const altModeIndex = indexOf(altModeValues, value, fieldConfig.comparisonFunc);

                //remove duplicate value from other mode (if required)
                if (altModeIndex !== -1) {
                    altModeValues.splice(altModeIndex, 1);
                }

                //Add value to state
                modeValues.push(value);
            },
        ],
    },
    //Validate value
    (state, filterName, value, fieldName, mode, skipAsync = false) => {
        const filterState = state.filters[filterName];
        const filterConfig = state.allFiltersConfig[filterName];
        const fieldState = filterState.fields[fieldName];
        const fieldConfig = filterConfig.fields.find((field) => field.fieldName === fieldName);

        const modeValues = fieldState[mode ? 'include' : 'exclude'];

        //Check for duplicate values
        if (includes(modeValues, value, fieldConfig.comparisonFunc)) {
            return fieldConfig.duplicateValueErrMsg || 'filters.validation.duplicateValue';
        }

        //Check validation
        if (fieldConfig.validation) {
            const errorMsg = fieldConfig.validation(value, state);

            if (errorMsg) {
                return errorMsg;
            }
        }

        // If this value is already present in the opposite mode (e.g. adding x to include, but x is already in exclude),
        // then remove from the alt mode
        const altModeValues = fieldState[!mode ? 'include' : 'exclude'];
        const altModeIndex = indexOf(altModeValues, value, fieldConfig.comparisonFunc);

        if (fieldConfig.maxValues) {
            //calculate total number of values for this field (taking into account removing value from other mode)
            const numValues =
                modeValues.length + altModeValues.length + (altModeIndex !== -1 ? -1 : 0);

            //If have reached or exceeded the maximum number of values
            if (numValues >= fieldConfig.maxValues) {
                return fieldConfig.maxValuesErrMsg || 'filters.validation.maxValues';
            }
        }

        if (!skipAsync && value && fieldConfig.asyncValidation) {
            return fieldConfig.asyncValidation(value, state);
        }

        return true;
    },
    //validate async action
    (state, filterConfig, {action, value, mode, filterName, fieldName}) => {
        if (action !== 'addValueIncludeAndExclude') {
            throw new Error(
                'Invalid action for this filter type, check you have the correct filterName and/or are using the correct action',
            );
        }

        const fieldConfig = filterConfig.fields.find((field) => field.fieldName === fieldName);

        if (!fieldConfig) {
            throw new Error('Invalid fieldName for this filter');
        }

        return isValueValid(state, filterName, value, fieldName, mode);
    },
    getBlankObj,
    getBlankObj,
    (filterConfig, error, state, action, fieldName) => {
        state.error[filterConfig.filterName][fieldName] = error;
    },
    (filterConfig, isPending, state, fieldName) => {
        state.pending[filterConfig.filterName][fieldName] = isPending;
    },
    (filterConfig, currentValue, persistedValue) => {
        if (isObject(persistedValue)) {
            const keys = Object.keys(persistedValue);

            if (
                keys.length === 2 &&
                keys.includes('activeModeIsInclude') &&
                keys.includes('fields')
            ) {
                const output = {
                    activeModeIsInclude: !!persistedValue.activeModeIsInclude, //force to boolean
                    fields: {...currentValue.fields},
                };

                Object.keys(output.fields).forEach((fieldName) => {
                    const field = persistedValue.fields[fieldName];

                    if (field && field.include instanceof Array && field.exclude instanceof Array) {
                        output.fields[fieldName] = {
                            include: [...field.include],
                            exclude: [...field.exclude],
                        };
                    }
                });

                return output;
            }
        }

        return currentValue;
    },
);
