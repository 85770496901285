import PropTypes from 'prop-types';
import cn from 'classnames';

import CharacterLimit from 'hsi/components/CharacterLimit';
import {HighlightedText} from 'hsi/components/QueryEditor';

import makeStyles from '@mui/styles/makeStyles';

import {T} from 'hsi/i18n';

const useStyles = makeStyles((theme) => {
    if (theme.v2)
        return {
            container: {
                position: 'relative',
                height: '74px',
            },
            title: {
                padding: '12px 16px',
                fontWeight: theme.fonts.bodyStrong.fontWeight,
                fontSize: theme.fonts.bodyStrong.fontSize,
            },
            formula: {
                padding: '0 16px 12px',
                backgroundColor: 'inherit',
                zIndex: 1,
                position: 'absolute',
                overflowY: 'scroll',
                overflowX: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxHeight: '210px',
                width: '100%',
                '&:hover': {
                    whiteSpace: 'unset',
                },
            },
            help: {
                padding: '0 16px 12px',
                fontStyle: 'normal',
                backgroundColor: 'inherit',
            },
            characterLimit: {
                position: 'absolute',
                top: '12px',
                right: '16px',
            },
        };
    else
        return {
            container: {
                position: 'relative',
                height: '74px',
            },
            title: {
                padding: '10px 10px 0',
                fontWeight: 'bold',
            },
            formula: {
                padding: '0 10px 10px',
                backgroundColor: 'inherit',
                zIndex: 1,
                position: 'absolute',
                overflowY: 'scroll',
                overflowX: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxHeight: '210px',
                width: '100%',
                '&:hover': {
                    whiteSpace: 'unset',
                },
            },
            help: {
                padding: '3px 10px',
                fontStyle: 'italic',
            },
            characterLimit: {
                position: 'absolute',
                top: '10px',
                right: '10px',
            },
        };
});

const Formula = ({className, exitGuidedMode, formula, maxLength}) => {
    const cl = useStyles();
    return (
        <div
            className={cn(cl.container, className)}
            onClick={() => !!formula.booleanQuery && exitGuidedMode()}
        >
            <div className={cl.title}>{T('queryBuilder.formulaTitle')}</div>
            {formula.booleanQuery ? (
                <HighlightedText className={cl.formula} text={formula.booleanQuery} />
            ) : (
                <div className={cl.help}>{T('queryBuilder.formulaHelp')}</div>
            )}

            <CharacterLimit
                className={cl.characterLimit}
                current={formula?.booleanQuery?.length || 0}
                max={maxLength}
            />
        </div>
    );
};

Formula.propTypes = {
    className: PropTypes.string,
    exitGuidedMode: PropTypes.func.isRequired,
    formula: PropTypes.object.isRequired,
    maxLength: PropTypes.number.isRequired,
};

export default Formula;
