/**
 * Alert Service
 */
import http from '../classes/Http';

export default class locationsService {
    static async getById(id) {
        return await http.get(`/api/locations/${id}`).then((res) => res.body);
    }

    static async getAllByIds(...ids) {
        const queryString = ids.map((id) => `locationIds=${id}`).join('&');
        const url = `/api/locations?${queryString}`;

        return await http.get(url).then((res) => res.body);
    }
}
