/**
 * Search Service
 */
import omit from 'lodash/omit';
import http from '../classes/Http';
import {deleteReports, listReports} from './reportsService';
import find from 'lodash/find';

class savedSearchService {
    static async loadSearches(projects) {
        let queries = [];
        return http
            .get(`/api/user/queries?pageSize=10000&state=active`)
            .then(async (queriesRes) => {
                queries = queriesRes.body.results.map((query) => ({
                    ...query,
                    project: find(projects, (p) => p.id === query.projectId),
                }));
                return http
                    .get('/fe-api/searches')
                    .then(async (res) => {
                        const searchIds = await queries.map((r) => r.id);
                        const queryDataFromInsightsDB = res.body;
                        return {
                            results: [...queries],
                            queryData: queryDataFromInsightsDB.filter((qd) =>
                                searchIds.includes(qd.queryId),
                            ),
                        };
                    })
                    .catch((e) => ({
                        hasError: true,
                        errorMessage:
                            e.body && Array.isArray(e.body.errors) && e.body.errors.length > 0
                                ? e.body.errors[0].message
                                : null,
                    }));
            })
            .catch((e) => ({
                hasError: true,
                errorMessage:
                    e.body && Array.isArray(e.body.errors) && e.body.errors.length > 0
                        ? e.body.errors[0].message
                        : null,
            }));
    }

    static async loadSearch(searchId) {
        // This search ID sometimes comes from the url params and can be a string
        return http
            .get('/fe-api/searches')
            .then(
                (res) => res.body.results.filter((d) => parseInt(d.id) === parseInt(searchId))[0],
            );
    }

    static loadSearchApi() {
        const ctrl = new AbortController();
        const {signal} = ctrl;
        return {
            loadSearch: async (projectId, searchId) =>
                http
                    .get(`/api/projects/${projectId}/queries/${searchId}`, {signal})
                    .then((res) => res.body),
            ctrl,
        };
    }

    static async saveSearch(search) {
        let saveSearchBody = {
            type: 'monitor',
            languages: [],
            booleanQuery: search.query,
            name: search.name,
            description: search.description || '',
            project: search.teamId,
        };
        if (search.contentSources) {
            saveSearchBody.contentSources = search.contentSources;
        }
        if (search.startDate) saveSearchBody.startDate = search.startDate;

        return http.post('/fe-api/save-query/', saveSearchBody).then((res) => res.body);
    }

    static async updateSearch(search) {
        // Need to update the request object to match BCR //
        const payload = omit(search, ['includedTerms', 'project', 'token', 'team']);
        payload.contentSources = search.contentSources || null;
        return http
            .put('/fe-api/edit-query/' + search.project.id + '/queries/' + search.id, payload)
            .then((res) => res.body);
    }

    static async deleteSearch(search) {
        const searchId = search.id;
        const projectId = search.project.id;

        const reports = await listReports({projects: [{id: projectId}]});
        const reportsBySearchId = reports.filter(
            (report) => report.queryData?.queryId[0] === searchId,
        );
        if (Array.isArray(reportsBySearchId) && reportsBySearchId.length > 0) {
            await deleteReports(reportsBySearchId);
        }

        return http
            .delete(`/api/projects/${projectId}/queries/${searchId}`)
            .then((res) => res.body);
    }

    static async loadProjectQueries(pid) {
        return http
            .get(`/api/projects/${pid}/queries/summary?state=active`)
            .then((res) => res.body);
    }

    static async loadProjectQueryGroup(pid) {
        return http.get(`/api/projects/${pid}/querygroups`).then((res) => res.body);
    }
}

export default savedSearchService;
