//This needs a refactor, although I'm not really sure if we even need this in redux at all

import find from 'lodash/find';

import {
    TAGS_LOADING,
    TAGS_LOADED,
    TAGS_LOAD_ERROR,
    SAVED_TAG,
    SAVE_TAG_ERROR,
    EDITED_TAG,
    DELETED_TAG,
    TAGGED_MENTIONS_LOADED,
    TAGGED_MENTIONS_ERROR,
    TAGGED_MENTIONS_LOADING,
} from 'hsi/constants/actionTypes';

import * as tagsService from 'hsi/services/tagsService';
import {loadSavedSearchMentions} from 'hsi/services/mentionsService';

import {initDateRange} from 'hsi/slices/filters';
import {showNotification} from './notificationsActions';
import {T} from 'hsi/i18n';

export const loadTags =
    (projectId, showLoading = true) =>
    (dispatch, getState) => {
        projectId = projectId || getState().query.context.projectId;
        showLoading && dispatch({type: TAGS_LOADING, payload: projectId});
        return tagsService
            .loadTags(projectId)
            .then((data) => {
                dispatch({
                    type: TAGS_LOADED,
                    payload: {
                        projectId,
                        results: data.results,
                    },
                });
            })
            .catch((e) => {
                dispatch({type: TAGS_LOAD_ERROR, payload: projectId});
            });
    };

//Error message here is for nothing but if the tag you're trying to create already exists.
//Any other kind of error... doesn't get a message I guess?
//PP I'm adding a generic error notification
export const saveTag =
    (tagName, successMessage, errorMessage, projectId) => (dispatch, getState) => {
        projectId = projectId || getState().query.context.projectId;
        const {
            tags: {results},
        } = getState();
        const existingTag = find(results, {name: tagName});
        if (!existingTag) {
            return tagsService
                .saveTag(projectId, {name: tagName})
                .then((data) => {
                    dispatch({
                        type: SAVED_TAG,
                        payload: {...data},
                    });
                    successMessage &&
                        dispatch(showNotification({message: successMessage, variant: 'success'}));

                    return true;
                })
                .catch((e) => {
                    dispatch({type: SAVE_TAG_ERROR});

                    dispatch(
                        showNotification({
                            variant: 'warning',
                            message: T('mentionComponent.errors.saveTag.unknown'),
                        }),
                    );

                    return false;
                });
        } else {
            dispatch(showNotification({message: errorMessage, variant: 'warning'}));

            return false;
        }
    };

export const editTag = (tag, successMessage, errorMessage, projectId) => (dispatch, getState) => {
    projectId = projectId || getState().query.context.projectId;
    return tagsService
        .editTag(projectId, tag)
        .then((data) => {
            dispatch({
                type: EDITED_TAG,
                payload: {...data},
            });
            dispatch(showNotification({message: successMessage, variant: 'success'}));
        })
        .catch((e) => {
            console.log(e);
            dispatch(showNotification({message: errorMessage, variant: 'warning'}));
        });
};

export const deleteTag = (tagId, projectId) => (dispatch, getState) => {
    projectId = projectId || getState().query.context.projectId;
    return tagsService
        .deleteTag(projectId, tagId)
        .then((data) => {
            dispatch({
                type: DELETED_TAG,
                payload: {...data},
            });
        })
        .catch((e) => {
            console.log(e);
        });
};

export const loadTaggedMentions =
    (append = false, tag) =>
    async (dispatch, getState) => {
        const {
            filters: {dateRange},
            mentions: {orderBy, orderIsAsc},
            query: {additionalQueries, context: queryContext, linkedinChannelIds},
            tags: {mentions},
        } = getState();

        if (dateRange.startDate === '' || dateRange.endDate === '') {
            await dispatch(initDateRange(dateRange?.timezone || queryContext.timezone));
        }

        const filterState = getState().filters;

        const filters = {
            filters: {
                tag: {
                    activeModeIsInclude: true,
                    fields: {
                        tag: {
                            include: [tag],
                            exclude: [],
                        },
                    },
                },
            },
            dateRange: filterState.dateRange,
            allFiltersConfig: {tag: filterState.allFiltersConfig.tag},
        };

        !append && dispatch({type: TAGGED_MENTIONS_LOADING, payload: {loading: true}});
        const page = append ? mentions.page + 1 : 0;

        return loadSavedSearchMentions({
            queryContext,
            filters,
            orderBy,
            orderIsAsc,
            resultsPage: page,
            additionalQueries,
            linkedinChannelIds,
        })
            .then((res) => {
                const results = append
                    ? [...mentions.results, ...res.body.results]
                    : res.body.results;
                const hasMore =
                    results.length < res.body.resultsTotal &&
                    (!res.body.paginationLimit || page < res.body.paginationLimit);
                dispatch({
                    type: TAGGED_MENTIONS_LOADED,
                    payload: {
                        resultsTotal: res.body.resultsTotal,
                        results,
                        page,
                        hasMore,
                        loading: false,
                    },
                });
            })
            .catch((e) => {
                console.log('load mentions error', e);
                dispatch({type: TAGGED_MENTIONS_ERROR});
            });
    };

export const resetTaggedMentions = () => (dispatch) => {
    dispatch({
        type: TAGGED_MENTIONS_LOADED,
        payload: {
            results: [],
            isOpen: true,
            loading: false,
            error: false,
            page: 0,
            hasMore: false,
            resultsTotal: 0,
        },
    });
};
