//TODO implement indeterminate style. We currently aren't using this feature of checkboxes, so I've not implmented it.

import cn from 'classnames';
import React, {
    ChangeEventHandler,
    ForwardedRef,
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from 'react';

import useStyles from './styles';
import {mergeRefs} from 'hsi/utils/react';
import useUniqueId from 'hsi/hooks/useUniqueId';

type InputProps = React.JSX.IntrinsicElements['input'];
type LabelProps = React.JSX.IntrinsicElements['label'];

interface CheckboxBaseProps
    extends Omit<LabelProps, 'onChange' | 'ref' | 'aria-label' | 'aria-labelledby'> {
    /**
     * A value of null = indeterminate. Undefined = false
     */
    checked?: boolean | null;
    disabled?: boolean;
    'aria-label'?: string;
    'aria-labelledby'?: string;

    onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    inputProps?: Omit<
        InputProps,
        'checked' | 'className' | 'onChange' | 'disabled' | 'aria-label' | 'aria-labelledby'
    >;
}

export type CheckboxProps = CheckboxBaseProps & {ref?: ForwardedRef<HTMLLabelElement>};

export default forwardRef<HTMLLabelElement, CheckboxBaseProps>(function Checkbox(
    {
        checked,
        onChange,
        className,
        inputProps,
        disabled,
        id: idProp,
        'aria-label': ariaLabel,
        'aria-labelledby': ariaLabelledby,
        children,
        ...rest
    },
    ref,
) {
    const id = useUniqueId(idProp, 'checkbox');
    const classes = useStyles();
    const inputRef = useRef<HTMLInputElement>(null);
    const indeterminate = checked === null;

    const onChangeHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (e) => {
            !disabled && onChange?.(e, e.target.checked);
        },
        [disabled, onChange],
    );

    const mergedInputRef = useMemo(() => mergeRefs(inputProps?.ref, inputRef), [inputProps?.ref, inputRef]);

    useEffect(() => {
        inputRef.current && (inputRef.current.indeterminate = indeterminate);
    }, [inputRef, indeterminate]);

    return (
        <>
            <input
                type="checkbox"
                id={id}
                checked={!!checked}
                className={classes.input}
                onChange={onChangeHandler}
                aria-disabled={disabled}
                aria-label={ariaLabel}
                aria-labelledby={ariaLabelledby}
                {...inputProps}
                ref={mergedInputRef}
            />
            <label htmlFor={id} className={cn(classes.label, className)} ref={ref} {...rest}>
                <span aria-hidden className={classes.checkboxProxy}></span>
                {children && <span className={classes.labelContent}>{children}</span>}
            </label>
        </>
    );
});
