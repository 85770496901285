import { CardType } from "hsi/types/cards";
import useQueryContext from "./useQueryContext";
import useQueryData from "./useQueryData";
import useFlags from "./useFlags";

/**
 * Based on the current query context, should this card be hidden?
 * @param type card type
 * @returns is the supplied card set to be hidden
 */
export default function useIsCardHidden(type: CardType) {
    const {savedSearchId, isMultipleSearch} = useQueryContext();
    const {hasDashboardPinAndHide} = useFlags();

    return useQueryData(savedSearchId, hasDashboardPinAndHide 
        ? (state) => state?.[isMultipleSearch ? 'multipleSearchDashboardCardsOptions' : 'dashboardCardsOptions']?.[type]?.visibility === 'hidden'
        : () => false
    );
}