import { isV2 } from 'hsi/types/theme';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => {
    const v2 = isV2(theme);
    const {colors} = theme;

    return {
        root: {
            '--autocomplete-input-margin': 0,
            '--autocomplete-option-padding': v2 ? '6px 15px' : '6px 9px',
        },
        inputRoot: {
            height: v2 ? 40 : 'auto',
            margin: 'var(--autocomplete-input-margin)',
            padding: '0 9px !important',
            width: 'auto',
            '&:has(:focus-visible)': {
                boxShadow: v2
                    ? `inset 0 0 0 3px ${colors.accentDefault}`
                    : `inset 0 0 0 3px ${colors.secondaryOrange100}`,
            },
        },
        input: {
            height: v2 ? 40 : 'auto',
            padding: v2 ? '0 !important' : 'inherit',
            '&:focus': {
                height: v2 ? 38 : 'auto',
            },
        },

        option: {
            alignItems: 'center',
            display: 'flex',
            fontSize: v2 ? 13 : 'inherit',
            minHeight: v2 ? 40 : 48,
            padding: 'var(--autocomplete-option-padding',
            '&:hover': {
                backgroundColor: v2 ? colors.bgHover : colors.primaryYellow20,
            },
            '&[aria-selected="true"]': {
                backgroundColor: v2 ? colors.bgSubtle : `${colors.primaryYellow40} !important`,
            },
        },
        paper: {
            //boxShadow: 'none',
            margin: 0,
        },
        listbox: {
            padding: 0,
        },
        popper: {
            //boxShadow: '1px 1px 2px 0 #00000099',
        },
    };
});

export const defaultInputStyles = makeStyles()((theme) => {
    const v2 = isV2(theme);

    return {
        inputIcon: {
            height: v2 ? 16 : 14,
            marginRight: 6,
            width: v2 ? 16 : 14,
        },
    };
});

//Used by the custom 'DefaultPopperComponent', which actually diplays the content inline.
export const inlinePopperStyles = makeStyles()((theme) => ({
    inlinePopper: {
        boxShadow: 'none',
        margin: 0,
        flex: '1 1 auto',
        minHeight: 0,

        width: 'auto !important',
        position: 'relative',
    },
}));

export const noOptionsStyles = makeStyles()((theme) => {
    const v2 = isV2(theme);
    const {colors} = theme;

    return {
        noOptions: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            fontSize: v2 ? 13 : 'inherit',
            justifyContent: 'space-around',
            height: v2 ? 120 : 130,
            ...(!v2 && {
                color: '#000',
            }),
        },
        noOptionsIcon: {
            fill: colors.darkGrey100,
            height: 24,
            width: 24,
        },
        noOptionsHeader: {
            ...(!v2 && {
                fontSize: 20,
                fontWeight: 700,
            }),
        },
    };
});
