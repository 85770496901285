import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => ({
    list: {
        margin: '0 16px',
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: theme.v2 ? '20px' : '8px',
    },

    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(565px, 1fr))',

        '& > *': {
            maxHeight: '330px',
        },
    },

    moreMentionsLoader: {
        padding: '24px',
        textAlign: 'center',
        gridColumn: '1/-1', //when in uniform mode, span all of the columns
    },

    error: {
        marginTop: '100px',
    },

    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));
