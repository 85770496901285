import {useMemo} from 'react';
import {Player as VideoPlayer} from 'video-react';

//Components
import Content from 'hsi/components/Mention/Content';
import Metrics from 'hsi/components/Mention/Metrics';
import Tags from 'hsi/components/Mention/Tags';

//Other
import normalize from 'hsi/components/Mention/normalize';
import useStyles from './styles';
import {SavedSearchMentionType} from 'hsi/types/mentions';
import MentionHeader from 'hsi/components/Mention/Header';
import preventDefaultAndStopPropagation from 'hsi/utils/html/preventDefaultAndStopPropagation';

type ExportMentionProps = {
    mention: SavedSearchMentionType;
};

//The component
export default function ExportMention({mention}: ExportMentionProps) {
    //Calculated values
    const classes = useStyles();

    const mentionData = useMemo(() => normalize(mention), [mention]);

    const hasMedia = !!mentionData?.image || !!mentionData?.video;

    return (
        <a
            href={mention.url}
            rel="noreferrer"
            target="_blank"
            className={classes.root}
            data-testid="exportMention"
        >
            <div className={classes.content}>
                <MentionHeader
                    hideOriginalLink
                    mentionData={mentionData}
                    mention={mention}
                    disableHeaderLinks
                />
                <Content
                    {...{
                        fulltext: false,
                        mention,
                        mentionData,
                    }}
                />

                <Metrics mention={mention} mentionData={mentionData} />

                <Tags editable={false} ellipsis={false} size="small" tags={mention?.tags} />
            </div>

            {hasMedia && (
                <div className={classes.media} data-testid="exportMentionMedia">
                    {!!mentionData.image && (
                        <img
                            alt={mentionData.image.alt}
                            className={classes.image}
                            data-testid="exportMentionMediaImage"
                            height={mentionData.image.height}
                            src={mentionData.image.url}
                            width={mentionData.image.width}
                        />
                    )}
                    {!!mentionData.video && (
                        <div className={classes.video} onClick={preventDefaultAndStopPropagation}>
                            <VideoPlayer>
                                <source
                                    data-testid="exportMentionMediaVideo"
                                    src={mentionData.video}
                                />
                            </VideoPlayer>
                        </div>
                    )}
                </div>
            )}
        </a>
    );
}
