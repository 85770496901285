export const noop = () => {};

export function combine(): () => void;
export function combine(...funcs: (undefined | null)[]): () => void;
export function combine<TFunc extends (...args: any[]) => any>(func: TFunc): TFunc;
export function combine<TFunc extends (...args: any[]) => any>(
    ...funcs: (TFunc | undefined | null)[]
): TFunc;

export function combine<TFunc extends (...args: any[]) => any>(
    ...funcs: (TFunc | undefined | null)[]
) {
    const funcSet = new Set<TFunc>(funcs.filter((x) => x instanceof Function) as any);

    //unique list of functions
    const arr = Array.from(funcSet);

    if (arr.length === 0) {
        return noop; //? or null? or throw an error?
    }

    if (arr.length === 1) {
        return arr[0];
    }

    //return a function that calls all functions
    return (...args: Parameters<TFunc>) => arr.map((func) => func(...args));
}
