import React, { ReactNode } from 'react';

import { PaperProps } from '@mui/material';
import { MenuPaper } from 'hsi/components/Menu';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(({mixin, colors, v2, font}) => ({
    title: {
        margin: '25px 16px 0 16px',
        padding: '0 0 7px 0',
        fontWeight: font.bold,
        color: v2 ? colors.darkGrey80 : colors.darkGrey100,
        fontSize: v2 ? 12 : 14,
        lineHeight: '17.6px',
        textTransform: 'uppercase',
        borderBottom: `1px solid ${colors.borders}`,
    },
    content: {
        '& ul, &ul.MuiAutocomplete-listbox': {
            padding: v2 ? '3px 0 !important' : '0 !important',
        },
    },
    footer: {
        padding: v2 ? '22px 24px' : '16px 24px',
        fontWeight: font.bold,
        fontSize: v2 ? 13 : 16,
        borderTop: !v2
            ? `2px solid ${colors.secondaryPurple100}`
            : '1px solid rgba(72, 70, 77, 0.2)',
        '& a': {
            textDecoration: 'none',
        },
        '& svg': {
            ...mixin.linkColor,
            position: 'relative',
            top: 1,
        },
    },
}));

interface AutocompletePopupPaperProps extends Omit<PaperProps, 'title'> {
    title?: ReactNode;
    footer?: ReactNode;
}

const AutocompletePopupPaper = React.forwardRef<HTMLDivElement, AutocompletePopupPaperProps>(
    ({children, footer, title, ...rest}, ref) => {
        const { classes } = useStyles();

        const hasChildren = !!React.Children.toArray(children).filter((c) => !!c).length;

        if (!hasChildren && !footer) {
            return null;
        }

        return (
            <MenuPaper {...rest} ref={ref} onMouseDown={(ev) => ev.preventDefault()}>
                {!!title && <div className={classes.title}>{title}</div>}
                <div className={classes.content}>{children}</div>
                {!!footer && <div className={classes.footer}>{footer}</div>}
            </MenuPaper>
        );
    },
);

export default AutocompletePopupPaper;
