import {AuthenticationDataTypes, TargetedDataDataTypes} from 'hsi/types/dataManagement';

export const FACEBOOK_INVITE_URL = 'FACEBOOK_INVITE_URL';
export const FACEBOOK_PAGES = TargetedDataDataTypes.FacebookPage;
export const FACEBOOK_PAGE_AUTHENTICATIONS = AuthenticationDataTypes.FacebookPageAuthentication;
export const FACEBOOK_USER_AUTHENTICATIONS = AuthenticationDataTypes.FacebookUserAuthentication;
export const INSTAGRAM_ACCOUNTS = TargetedDataDataTypes.InstagramAccount;
export const INSTAGRAM_ACCOUNT_AUTHENTICATIONS =
    AuthenticationDataTypes.InstagramAccountAuthentication;
export const INSTAGRAM_HASHTAGS = TargetedDataDataTypes.InstagramHashtag;
export const LINKEDIN_CHANNELS = TargetedDataDataTypes.LinkedinChannel;
export const LINKEDIN_CHANNEL_AUTHENTICATIONS =
    AuthenticationDataTypes.LinkedinChannelAuthentication;
export const TRACKABLE_OWNED_AUTHENTICATIONS = 'TRACKABLE_OWNED_AUTHENTICATIONS';
export const DATA_SOURCE_COUNT = 'DATA_SOURCE_COUNT';

export const getMentionsExportAllowanceQueryKey = ['user', 'getMentionsExportAllowance'];
