import {MouseEvent, MouseEventHandler, useCallback, useMemo} from 'react';
import cn from 'classnames';
import {sortBy} from 'lodash';
import LinkifyIt from 'linkify-it';
import tlds from 'tlds';

import {T} from 'hsi/i18n';
import {MentionDataType, MentionType, SavedSearchMentionType} from 'hsi/types/mentions';
import useStyles from './styles';

const linkify = new LinkifyIt();
linkify.tlds(tlds);

const stopLinkClickPropagation: MouseEventHandler<Element> = (e: MouseEvent<Element>) => {
    if ((e.target as Element)?.matches?.('a, a *')) {
        e.stopPropagation();
    }
};

type ClassesType = ReturnType<typeof useStyles>;

type MentionContentProps = {
    articleId?: string;
    mention: MentionType;
    mentionData: MentionDataType;
    fulltext?: boolean;
    disableLinks?: boolean;
    selectable?: boolean;
} & JSX.IntrinsicElements['div'];

const MentionContent = ({
    articleId,
    mention,
    mentionData,
    fulltext,
    onClick,
    disableLinks = false,
    selectable = false,
    ...rest
}: MentionContentProps) => {
    const classes = useStyles();

    const [rawContent, isFulltext] =
        fulltext && mentionData.fullText
            ? [mentionData.fullText, true]
            : [mentionData.snippet || '', false];

    const titleSameAsContent =
        mentionData.title === rawContent ||
        (mention as SavedSearchMentionType).pageType === 'twitter';

    const title = useMemo(
        () =>
            titleSameAsContent || !mentionData.title
                ? undefined
                : processText(mentionData.title, undefined, classes, disableLinks),
        [titleSameAsContent, mentionData.title, classes, disableLinks],
    );
    const content = useMemo(
        () =>
            processText(
                rawContent,
                isFulltext ? undefined : mention.matchPositions,
                classes,
                disableLinks,
            ),
        [rawContent, isFulltext, mention.matchPositions, classes, disableLinks],
    );

    const onClickHandler = useCallback<MouseEventHandler<HTMLDivElement>>(
        (e) => {
            onClick?.(e);

            stopLinkClickPropagation(e);
        },
        [onClick],
    );

    const hasTitle = !!title;
    const HeaderComponent = 'h3'; //TODO this should probably change in future to a dynamic header level component, but that doesn't exist yet.
    const ContentComponent = hasTitle ? 'div' : HeaderComponent;

    return (
        <div
            data-testid="mentionContent"
            onClick={disableLinks ? undefined : onClickHandler}
            {...rest}
        >
            {hasTitle && (
                <HeaderComponent id={`${articleId}_title`} className={cn(classes.contentTitle)}>
                    <span dangerouslySetInnerHTML={{__html: title}} />

                    {selectable && (
                        <span className="offscreen">{T('mentionComponent.clickToSelectLbl')}</span>
                    )}
                </HeaderComponent>
            )}
            <ContentComponent
                id={hasTitle ? `${articleId}_content` : `${articleId}_title`}
                className={cn(classes.contentSnippet)}
            >
                <span dangerouslySetInnerHTML={{__html: content}} />
                {selectable && !hasTitle && (
                    <span className="offscreen">{T('mentionComponent.clickToSelectLbl')}</span>
                )}
            </ContentComponent>
        </div>
    );
};

export default MentionContent;

type InsertType = {index: number; insert: string};
type InsertWithTypeType = InsertType & {type: ['open' | 'close', string]};

function processText(
    text: string,
    matchPositions: MentionType['matchPositions'],
    classes: ClassesType,
    disableLinks: boolean = false,
) {
    let inserts: InsertWithTypeType[] = [];

    const spanType = `span.${classes.highlight}`;

    if (!disableLinks) {
        //parse links
        (linkify.match(text) || []).forEach(({index, lastIndex, url}) => {
            inserts.push({
                index: index,
                insert: `<a href="${url}" target="_blank">`,
                type: ['open', 'a'],
            });

            inserts.push({
                index: lastIndex,
                insert: '</a>',
                type: ['close', 'a'],
            });
        });
    }

    //parse highlights
    matchPositions &&
        matchPositions.forEach(({start, length}) => {
            inserts.push({
                index: start,
                insert: `<span class="${classes.highlight}">`,
                type: ['open', spanType],
            });

            inserts.push({
                index: start + length,
                insert: `</span>`,
                type: ['close', spanType],
            });
        });

    //detect and resolve overlapping highlights and links
    inserts = sortBy(inserts, 'index');

    let stack: InsertWithTypeType[] = [];

    const finalInserts: InsertType[] = inserts.reduce<InsertType[]>((output, insert, index) => {
        if (insert.type[0] === 'open') {
            stack.push(insert);

            output.push(insert);
        } else if (insert.type[0] === 'close') {
            const curStackTop = stack[stack.length - 1];

            if (insert.type[1] === (curStackTop as InsertWithTypeType).type?.[1]) {
                stack.pop();
                output.push(insert);
            } else {
                //trying to close an earlier tag
                // TODO theoretically could be more than one layer deep - not needed for this current useage

                if (insert.type[1] !== 'a') {
                    // -need to split this around the a tag

                    //...close the span before the link starts...
                    insertBefore(output, curStackTop, {
                        index: curStackTop.index,
                        insert: '</span>',
                    });

                    //...reopen span after link starts...
                    insertAfter(output, curStackTop, {
                        ...stack[stack.length - 2],
                        index: curStackTop.index,
                    });

                    output.push(insert);

                    //update the stack
                    stack = stack.slice(0, -2);
                    stack.push(curStackTop);
                } else {
                    // -need to close currently open tag
                    output.push({
                        index: insert.index,
                        insert: `</${curStackTop.type[1].split('.')[0]}>`,
                    });

                    //-now add this tag
                    output.push(insert);

                    const reOpenClosedTag = {
                        ...curStackTop,
                        index: insert.index,
                    };

                    //-now re-open closed tag
                    output.push(reOpenClosedTag);

                    //update the stack
                    stack = stack.slice(0, -2);
                    stack.push(reOpenClosedTag);
                }
            }
        }

        return output;
    }, []);

    //parse linebreaks
    const matches = text.matchAll('\n' as unknown as RegExp);

    for (const match of matches) {
        finalInserts.push({
            index: match.index!,
            insert: '<br />',
        });
    }

    return applyInserts(text, finalInserts);
}

// str: text to add inserts to
// inserts: [{index: [positive integer], insert: [str]}]
// preSorted: bool - if true, will skip sorting inserts in index order (if inserts are not sorted skipping this give undefined results)
function applyInserts(str: string, inserts: InsertType[], preSorted = false) {
    if (!preSorted) {
        inserts = sortBy(inserts, 'index');
    }

    const strChunks = [];
    let lastIndex = 0;

    inserts.forEach(({index, insert}) => {
        strChunks.push(str.slice(lastIndex, index));
        strChunks.push(insert);

        lastIndex = index;
    });

    strChunks.push(str.slice(lastIndex));

    return strChunks.join('');
}

function insertBefore<T>(array: T[], item: T, newItem: T) {
    const index = array.indexOf(item);

    if (index === -1) {
        throw new Error('Item not present in array');
    }

    array.splice(index, 0, newItem);
}

function insertAfter<T>(array: T[], item: T, newItem: T) {
    const index = array.indexOf(item);

    if (index === -1) {
        throw new Error('Item not present in array');
    }

    array.splice(index + 1, 0, newItem);
}
