import { ReactNode, useMemo } from 'react';

import { offset } from '@floating-ui/react-dom';
import cn from 'classnames';

//Components
import IconRouter from 'hsi/components/IconRouter';
import PopoverDisplay from 'hsi/components/Popover/PopoverDisplay';
import Tooltip from 'hsi/components/aria/Tooltip';

import QuickPie from 'hsi/components/QuickPie';

//Utils
import { formatBigIntFull } from 'hsi/utils/formatNumber';

//Other
import { T } from 'hsi/i18n';
import useStyles from './styles';

type EntitiesRemainingArgs = {
    content: ReactNode;
    title: ReactNode;
    maxEntities: number;
    numEntities: number;
    onShow?: () => void;
};

const tooltipMiddleware = [offset(5)];


const EntitiesRemaining = ({
    content,
    maxEntities,
    numEntities,
    onShow,
    title,
}: EntitiesRemainingArgs) => {
    const classes = useStyles();

    const entitiesRemaining = maxEntities - numEntities > 0 ? maxEntities - numEntities : 0;

    const popoverContent = useMemo(() => <PopoverDisplay>
            <span className="offscreen">{T('entitiesRemaining.ariaTooltipLbl', {title, content, used: numEntities, total: maxEntities})}</span>
            <div className={classes.popup} aria-hidden>
                <div className={classes.popupTextWrapper}>
                    <div className={classes.popupTitle}>
                        {title}
                    </div>
                    <div className={classes.popupContent}>
                        {content}
                    </div>
                </div>
                <QuickPie num={numEntities} max={maxEntities} />
            </div>
        </PopoverDisplay>, 
        [classes.popup, classes.popupTextWrapper, classes.popupTitle, classes.popupContent, title, content, numEntities, maxEntities]
    );

    return (
        <Tooltip
            placement="bottom"
            middleware={tooltipMiddleware}
            tooltip={popoverContent}
            onShow={onShow ? (visible) => {visible && onShow()} : undefined}
        >
            <div
                className={cn(
                    classes.entitiesRemaining,
                    !entitiesRemaining && classes.showAsWarning,
                )}
                data-testid="entitiesRemaining"
                tabIndex={0}
                aria-label={T('entitiesRemaining.msg', {smart_count: formatBigIntFull(entitiesRemaining)})}
                role="note"
            >
                {entitiesRemaining > 0 ? (
                    <IconRouter aria-hidden="true" name="success" className={classes.successIcon} />
                ) : (
                    <IconRouter aria-hidden="true" name="warning" className={classes.warningIcon} />
                )}
                <span aria-hidden className={classes.remaining}>
                    {T('entitiesRemaining.msg', {smart_count: formatBigIntFull(entitiesRemaining)})}
                </span>
                <IconRouter name="c-question" className={classes.icon} aria-hidden="true" />
            </div>
        </Tooltip>
    );
};

export default EntitiesRemaining;
