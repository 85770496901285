import { forwardRef } from 'react';
import cn from 'classnames';

//Components
import Meter from '../Meter';

//Utils

//Other
import useStyles from './styles';
import CompactNumber from '../format/CompactNumber';
import format from 'hsi/utils/number/format';
import Tooltip from '../Tooltip';

//Types
type NumberWithPercentMeterProps = {
    value: number;
    fraction: number;
    className?: string;
};

export default forwardRef<HTMLDivElement, NumberWithPercentMeterProps>(function NumberWithPercentMeter(
    {value, fraction: percent, className},
    ref,
) {
    const classes = useStyles();
    const formattedPercent = format(percent, 'percent');

    return (
        <div ref={ref} className={cn(classes.root, className)}>
            <CompactNumber className={classes.number} tooltip value={value} />
            <Tooltip portal noAria tooltip={formattedPercent}>
                <Meter className={classes.meter} value={percent * 100} max={100} description={formattedPercent} />
            </Tooltip>
        </div>
    );
});
