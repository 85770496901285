import {parseAuthors} from 'hsi/utils/formulaParser';

import tns from 'hsi/i18n/tns';
const t = tns('queryBuilder');

const socialAccountIncludeConfig = {
    name: 'include',
    label: 'include',
    operator: 'OR',
    title: t('includeTitle'),
    components: [
        {
            name: 'owned-social-accounts',
            type: 'input-accounts',
            title: t('socialAccountsSocialAccountsTitle'),
            subtitle: t('socialAccountsSocialAccountsSubtitle'),
            placeholder: t('socialAccountsSocialAccountsPlaceholder'),
            parseFormula: parseAuthors,
        },
    ],
};

const socialAccountConfig = {
    sections: [socialAccountIncludeConfig],
};

export {socialAccountConfig};
