import React, {createRef, useCallback, useEffect, useMemo, useState} from 'react';
import {push} from 'redux-first-history';
import {useDispatch, useSelector} from 'react-redux';

import Button from 'hsi/components/Button';
import ConfirmDialog from 'hsi/components/ConfirmDialog';
import EntitiesRemaining from 'hsi/components/EntitiesRemaining';
import PendingDisplay from 'hsi/components/PendingDisplay';
import NameField from './NameField';
import FullscreenLoading from 'hsi/components/FullscreenLoading';
import SaveSearchDialog from 'hsi/components/SaveSearchDialog';
import SortableTable from 'hsi/components/SortableTable';
import Time from 'hsi/components/Time';

import useConfig from 'hsi/hooks/useConfig';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useHasQuickSearch from 'hsi/hooks/useHasQuickSearch';
import {useSearchesById} from 'hsi/hooks/useSearchesById';

import {deleteAlert} from 'hsi/slices/alerts';
import {resetQueryData} from 'hsi/actions/queryActions';
import {deleteSearch, openEditSavedSearchDialog} from 'hsi/actions/savedSearchActions';
import {updateUsedQueries} from 'hsi/actions/userActions';

import {isLinkedinSearch} from 'hsi/utils/dataManagement/linkedin';

import {T} from 'hsi/i18n';

import useStyles from './styles';
import Heading, {HeadingContents} from 'hsi/components/aria/Heading';

const SavedSearches = () => {
    const config = useConfig();
    const dispatch = useDispatch();
    const {track} = useEventTrack();
    const hasQuickSearch = useHasQuickSearch();
    const classes = useStyles();
    const {searchesById} = useSearchesById();

    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const alerts = useSelector((state) => state.alerts.alerts);
    const savedSearchQueries = useSelector((state) => state.query.savedSearchQueries);
    const rawSearches = useSelector((state) => state.search.searches);
    const isloading = useSelector((state) => state.search.searchesLoading);
    const maxSearches = useSelector((state) => state.user.account.client.queryLimit);
    const clientId = useSelector((state) => state.user.account.client.id);

    const usedQueries = useSelector((state) => state.user.usedQueries);
    const isViewOnly = useSelector((state) => state.user.isViewOnly);

    const searches = useMemo(
        () =>
            (rawSearches || [])
                //Added  search.id validation to avoid empty searches
                //TODO: fix it better
                .filter((search) => !isLinkedinSearch(search) && search.id)
                .map((search) => ({
                    ...search,
                    team: search.project.name,
                    lastModifiedUsername: config.noEmailDomain
                        ? search.lastModifiedUsername?.replace(/@.*$/, '')
                        : search.lastModifiedUsername,
                })),
        [config.noEmailDomain, rawSearches],
    );

    useEffect(() => {
        track('viewed', {});
        dispatch(updateUsedQueries(clientId));
    }, [clientId, dispatch, track]);

    const openEditSearchDialog = useCallback(
        (search) => {
            dispatch(openEditSavedSearchDialog(true, search.id));
        },
        [dispatch],
    );

    const getAdditionalQueries = useCallback(
        (id) => {
            return (savedSearchQueries[id]?.additionalQueries || []).filter(
                (id) => !!searchesById[id],
            );
        },
        [searchesById, savedSearchQueries],
    );

    const toggleConfirmDelete = useCallback(
        (search) => {
            if (!confirmDeleteOpen) {
                track('deleteSearchDialogToggled', {
                    state: 'open',
                });
            } else {
                track('deleteSearchDialogToggled', {
                    state: 'closed',
                });
            }

            setSelectedItem(search);
            setConfirmDeleteOpen((confirmDeleteOpen) => !confirmDeleteOpen);
        },
        [confirmDeleteOpen, track],
    );

    const goToNewQuickSearch = useCallback(() => {
        track('newQuickSearchButtonClicked', {});
        dispatch(resetQueryData());
        dispatch(push({pathname: '/'}));
    }, [dispatch, track]);

    const onDelete = useCallback(() => {
        track('searchDeleted', {
            search: selectedItem,
        });

        dispatch(deleteSearch(selectedItem));

        if (alerts) {
            alerts
                .filter(({queryId}) => queryId === selectedItem.id)
                .forEach((alert) => {
                    dispatch(deleteAlert(alert.id, null));
                });
        }

        setConfirmDeleteOpen(false);
        setSelectedItem(null);
    }, [alerts, dispatch, selectedItem, track]);

    const onClickSearch = useCallback(
        (item) => {
            track('searchClicked', {
                search: item,
            });
        },
        [track],
    );

    const onClickMenu = useCallback(() => {
        track('actionsMenuClicked', {});
    }, [track]);

    const nameRef = createRef();

    const formatNameField = useCallback(
        (item) => (
            <NameField
                label={item?.name}
                item={item}
                getAdditionalQueries={getAdditionalQueries}
                onClickSearch={onClickSearch}
                containerRef={nameRef}
            />
        ),
        [getAdditionalQueries, nameRef, onClickSearch],
    );

    const fields = [
        {
            id: 'name',
            label: T('name'),
            width: '30%',
            renderFunc: formatNameField,
            ref: nameRef,
        },
        {
            id: 'team',
            label: T('team'),
            width: '10%',
        },
        {
            id: 'description',
            label: T('description'),
            width: '20%',
        },
        {
            id: 'lastModificationDate',
            label: T('last.modified'),
            colSize: 'min-content',
            format: (date) => <Time value={date} format="ccc, MMM dd yyyy" />,
        },
        {
            id: 'lastModifiedUsername',
            label: T('by.user'),
            width: '20%',
        },
    ];

    const menuEntries = [
        {
            label: T('manage.search'),
            onClick: openEditSearchDialog,
        },
        {
            label: T('delete'),
            onClick: toggleConfirmDelete,
        },
    ];

    const noSearchesAndNoQuotaLeft = usedQueries === maxSearches && !searches.length;

    return (
        <div className={classes.container}>
            <div className={classes.savedSearchesRoot} data-testid="hsi-savedSearchesRoot">
                <div className={classes.savedSearchesWrapper}>
                    <div className={classes.savedSearchesTitle}>
                        <Heading>{T('saved.searches')}</Heading>
                        <PendingDisplay minWidth="122px" isLoaded={!isloading}>
                            <EntitiesRemaining
                                content={T('savedSearchesLeft.popupContent', {num: maxSearches})}
                                maxEntities={maxSearches}
                                numEntities={usedQueries}
                                onShow={() => {
                                    track('quotaShown', {
                                        type: 'savedSearchesPage',
                                    });
                                }}
                                title={T('savedSearchesLeft.popupTitle')}
                                type="savedSearchesPage"
                            />
                        </PendingDisplay>
                    </div>
                    {hasQuickSearch && (
                        <Button
                            classes={{label: 'cta'}}
                            className={classes.newQuickSearch}
                            onClick={goToNewQuickSearch}
                            priority="cta"
                        >
                            {T('savedsearchbar.newquicksearch')}
                        </Button>
                    )}
                </div>
                <HeadingContents>
                    {isloading ? (
                        <FullscreenLoading className={classes.loading} />
                    ) : (
                        <div className="tableWrapper">
                            <SortableTable
                                caption={T('savedSearchesList.tableLbL')}
                                fields={fields}
                                items={searches}
                                itemTypeName={T('searches')}
                                noItemsMsg={
                                    noSearchesAndNoQuotaLeft
                                        ? T('savedSearchesList.noSearchesAndNoQuotaLeft', {
                                              num: maxSearches,
                                          })
                                        : 0
                                }
                                menuEntries={isViewOnly ? undefined : menuEntries}
                                onClickMenu={onClickMenu}
                                onSorted={(sortData) =>
                                    track('tableSorted', {
                                        ...sortData,
                                    })
                                }
                            />
                        </div>
                    )}
                </HeadingContents>
            </div>

            <ConfirmDialog
                body={
                    <>
                        {T('confirm.delete.lost.data.1')}
                        <strong>{T('confirm.delete.lost.data.2')}</strong>
                        {T('confirm.delete.lost.data.3')}
                    </>
                }
                closeFunc={toggleConfirmDelete}
                confirmFunc={onDelete}
                information={T('confirm.delete.saved.search', {
                    search: selectedItem?.name || 'N/A',
                })}
                open={confirmDeleteOpen}
                showCannotUndo
                title={T('delete.saved.search')}
                type="warning"
            />

            <SaveSearchDialog />
        </div>
    );
};

SavedSearches.propTypes = {};

export default SavedSearches;
