import http, { HttpResult } from "hsi/classes/Http";
import { QueryData } from "hsi/types/queryData";

const isTest = process.env.NODE_ENV === 'test';

export async function getQueryDataKey<T extends keyof QueryData>(queryId: number, projectId: number, key: T): Promise<HttpResult<QueryData[T] | null>> {
    return http.get<QueryData[T]>(`/apiv2/${projectId}/saved-search/${queryId}/query-data/${key}`);
}

export async function putQueryDataKey<T extends keyof QueryData>(queryId: number, projectId: number, key: T, value: QueryData[T] | null): Promise<HttpResult<void>> {
    return isTest 
    ? Promise.resolve({
        status: 200,
        ok: true,
    } as HttpResult<void>)
    : http.put<void>(`/apiv2/${projectId}/saved-search/${queryId}/query-data/${key}`, {value});
}