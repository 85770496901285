import makeStyles from '@mui/styles/makeStyles';

import {Theme} from 'hsi/types/theme';

export default makeStyles((theme: Theme) => ({
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255, 0.8)',
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 1,
        left: 0,
        top: 0,
    },

    orgInputContainer: {
        marginBottom: '16px',
    },
    orgInputTitle: {
        fontWeight: 'bold',
        marginTop: '8px',
        marginBottom: '24px',
    },
    orgInputDescription: {
        marginBottom: '8px',
    },
    orgInputSearchContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 8,
    },
    orgInputSearchButton: {
        color: theme.colors.primaryBlue100,
    },

    channelInputTitle: {
        fontWeight: 'bold',
        marginTop: '12px',
        marginBottom: '20px',
    },

    messageBox: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.colors.white,
        padding: '16px',
        marginBottom: '16px',
    },
    messageTitle: {
        fontWeight: 'bold',
        marginBottom: '8px',
    },
    messageBody: {
        width: 'calc(100% - 36px - 16px)',
    },
    messageIcon: {
        width: '36px',
        height: '36px',
        marginRight: '16px',
        fill: theme.colors.primaryBlue100,
    },

    orgDetailsBox: {
        display: 'flex',
        gap: '12px',
        backgroundColor: theme.colors.white,
        padding: '16px 32px',
        marginBottom: '16px',
        width: '100%',
    },
    orgDetailsCol: {
        display: 'flex',
        flexDirection: 'column',
    },
    orgDetailsColTitle: {
        flexGrow: 1,
        alignItems: 'end',
    },
    orgDetailsColValue: {
        maxWidth: '220px',
    },
    orgDetailsColLogo: {
        alignItems: 'end',
        flexGrow: 1,
    },
    orgDetailsItemTitle: {
        fontWeight: 'bold',
    },
    orgDetailsItemValue: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    orgDetailsLogo: {
        maxWidth: '60px',
    },

    connectWithLinkedin: {
        display: 'flex',
        justifyContent: 'center',
    },
    connectWithLinkedinBtn: {
        width: '400px',
        color: theme.colors.white,
        backgroundColor: theme.colors.linkedin,
        '&:hover': {
            color: theme.colors.white,
            backgroundColor: theme.colors.linkedinHovered,
        },
    },
    connectWithLinkedinIcon: {
        width: '21px',
        height: '21px',
    },

    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    updateActions: {
        display: 'flex',
        gap: '8px',
    },

    orgErrorMessage: {
        color: theme.colors.error,
        marginBottom: '32px',
    },
}));
