import {makeStyles} from 'tss-react/mui';

export default makeStyles()((theme, _params, classes) => {
    const {colors, font, mixin, fonts, v2} = theme;
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: '0 20px',
        },
        accountLabel: {
            marginBottom: 8,
            padding: '0px 4px',
            ...(v2 ? fonts.bodyLargeNormal : {fontSize: '16px', fontWeight: font.semiBold}),
        },
        accountInput: {
            marginBottom: 25,
        },
        accountInputLabel: {
            flex: '1 1 auto',
            paddingTop: 0,
            paddingBottom: 0,
            textAlign: 'left',
            ...(v2
                ? {...fonts.bodyNormal, textTransform: 'initial'}
                : {fontSize: 16, fontWeight: font.semiBold}),
        },
        accountInputOptionLeft: {
            marginLeft: 'auto',
        },
        accountInputCta: {
            display: 'flex',
            justifyContent: 'flex-start',
            padding: v2 ? 20 : '30px 15px',
            outline: v2 ? `1px solid ${colors.borders}` : `2px solid ${colors.secondaryPurple100}`,
            borderRadius: 0,
            color: colors.primaryBlue100,
        },
        accountInputCtaIcon: {
            fill: colors.primaryBlue100,
            marginRight: 8,
            padding: 2,
            ...mixin.icon.normalSize,
        },
        hashtagInput: {
            width: '100%',
            '& .MuiInputBase-root': {
                height: 160,
                '&.MuiInputBase-root.Mui-disabled': {
                    backgroundColor: v2 ? colors.bgDisabled : colors.primaryBlue10,
                },
            },
        },
        acknowledgeCheckbox: {
            backgroundColor: v2 ? colors.uiGrayDefault : colors.primaryBlue10,
            marginTop: 20,
            padding: v2 ? 16 : 10,
            lineHeight: '20px',
            ...(v2 ? fonts.bodyNormal : {fontWeight: `${font.regular}`, fontSize: `16px`}),
        },
        backButton: {
            marginRight: 'auto',
        },
        infoBanner: {
            marginTop: 24,
            marginBottom: 8,
        },
        pulseLoader: {
            '& circle.ax-progress-infinite__arc': {
                stroke: `${colors.darkGrey20} !important`,
            },
        },
    };
});
