/**
 * Mentions Service
 */
import {pick} from 'lodash';

import http, {HttpResult} from 'hsi/classes/Http';
import {getSearchParams} from 'hsi/utils/url';
import {MENTIONS_PAGE_SIZE} from 'hsi/constants/config';
import {filterStateToAPIFormat} from 'hsi/utils/filters';
import {QueryContextType} from 'hsi/types/query';
import {AllFilteringState, DateRange, FilterConfigByName, FiltersState} from 'hsi/types/filters';
import {
    MentionAPIIdentifier,
    QuickSearchMentionApiType,
    SavedSearchMentionApiType,
} from 'hsi/types/mentions';
import {TDateISO} from 'hsi/types/dates';
import {EmotionsType} from 'hsi/types/shared';
import Http from 'hsi/classes/Http';

let mentionCtrl: AbortController | null = null;

const QUICK_SEARCH_ORDERS = ['date', 'id', 'random', 'relevance', 'author_followers'] as const;
const SAVED_SEARCH_ORDERS = [
    'accounttype',
    'added',
    'assignment',
    'author',
    'brightviewConfidence',
    'checked',
    'date',
    'facebookcomments',
    'facebooklikes',
    'facebookrole',
    'facebookshares',
    'gender',
    'impact',
    'impressions',
    'instagramcommentcount',
    'instagramfollowercount',
    'instagramfollowingcount',
    'instagraminteractionscount',
    'instagramlikecount',
    'instagrampostcount',
    'lastassignmentdate',
    'linkedinComments',
    'linkedinEngagement',
    'linkedinImpressions',
    'linkedinLikes',
    'linkedinShares',
    'linkedinVideoViews',
    'md5',
    'monthlyvisitors',
    'priority',
    'publicationname',
    'queryiddate',
    'reachEstimate',
    'redditscore',
    'resourcetype',
    'status',
    'subtype',
    'threadentrytype',
    'title',
    'twitterfollowers',
    'twitterfollowing',
    'twitterpostcount',
    'twitterreplycount',
    'twitterRetweets',
    'twitterrole',
    'twitterverified',

    //'updated', 'impact', 'random', 'reachEstimate'
] as const;

type SavedSearchOrders = (typeof SAVED_SEARCH_ORDERS)[number];
type QuickSearchOrders = (typeof QUICK_SEARCH_ORDERS)[number];

function isQuickSearchOrder(order: string): order is QuickSearchOrders {
    return QUICK_SEARCH_ORDERS.includes(order as any);
}

function isSavedSearchOrder(order: string): order is SavedSearchOrders {
    return SAVED_SEARCH_ORDERS.includes(order as any);
}

export type QuickSearchApiResults = {
    results: QuickSearchMentionApiType[];
    resultsTotal: number;
    scaledResultsTotal: number;
    resultsPage: number;
    resultsPageSize: number;
    startDate: TDateISO;
    endDate: TDateISO;
};

export type SavedSearchApiResults = {
    results: SavedSearchMentionApiType[];
    resultsTotal: number;
    resultsPage: number;
    resultsPageSize: number;
    prevCursor?: string;
    nextCursor?: string;
    startDate: TDateISO;
    endDate: TDateISO;
};

/**
 * Make request to load additional mentions using a previously obtained cursor for the position within the results set.
 * Only supported for saved searched
 *
 * @returns promise of type HttpResult<{results: SavedSearchMEntionApiType[]}>
 */
export async function loadMentionsCursoredPagination({
    queryContext,
    filters,
    orderBy = 'date',
    orderIsAsc = false,
    cursor = undefined,
    pageSize = MENTIONS_PAGE_SIZE,
    additionalQueries,
    linkedinChannelIds,
    fullText = true,
}: {
    queryContext: QueryContextType;
    filters: AllFilteringState;
    orderBy: SavedSearchOrders;
    orderIsAsc: boolean;
    cursor?: string;
    pageSize?: number;
    additionalQueries: number[];
    linkedinChannelIds: number[];
    fullText?: boolean;
}) {
    if (mentionCtrl) mentionCtrl.abort();
    mentionCtrl = new AbortController();
    const {signal} = mentionCtrl;
    const {projectId} = queryContext;

    const orderDirection = orderIsAsc ? 'asc' : 'desc';

    /** QUICK SEARCH */
    if (queryContext.searchType === 'quick') {
        throw new Error('Quick search does not support cursored pagination');
    } else {
        //https://developers.brandwatch.com/docs/retrieving-mentions
        /** SAVED SEARCH */
        if (!isSavedSearchOrder(orderBy)) {
            throw new Error(
                `Invalid orderBy parameter "${orderBy}", must be one of: ${SAVED_SEARCH_ORDERS.join(
                    ', ',
                )}`,
            );
        }

        const params = getSearchParams(
            filterStateToAPIFormat(filters.filters, filters.allFiltersConfig),
            filters.dateRange,
            queryContext,
            additionalQueries,
            linkedinChannelIds,
        );

        const pagination = !cursor ? 'page=0' : `cursor=${cursor}`;

        return http.get<SavedSearchApiResults>(
            `/api/projects/${projectId}/data/mentions${
                fullText ? '/fulltext' : ''
            }${params}&${pagination}&pageSize=${pageSize}&orderBy=${orderBy}&orderDirection=${orderDirection}`,
            {signal},
        );
    }
}

/**
 * Load saved search mentions.
 *
 * @returns If requesting a CSV format result, returns a promise of type HttpResult<string> containing the CSV data. Otherwise will return a promise of type HttpResult<{results: SavedSearchMEntionApiType[]}>
 */
export async function loadSavedSearchMentions<T extends 'CSV' | undefined>({
    queryContext,
    filters,
    orderBy = 'date',
    orderIsAsc = false,
    resultsPage = 0,
    pageSize = MENTIONS_PAGE_SIZE,
    additionalQueries,
    linkedinChannelIds,
    format,
}: {
    queryContext: QueryContextType;
    filters: AllFilteringState;
    orderBy?: SavedSearchOrders | QuickSearchOrders;
    orderIsAsc?: boolean;
    resultsPage?: number;
    pageSize?: number;
    additionalQueries?: number[];
    linkedinChannelIds?: number[];
    /**
     * Optional parameter to request CSV format data. Defaults to returning regular data format otherwise
     */
    format?: T;
}): Promise<HttpResult<T extends 'CSV' ? string : SavedSearchApiResults>> {
    if (queryContext.searchType === 'quick') {
        throw new Error(
            'Invalid arguments. This method can only be used to load mentions for saved searches',
        );
    }

    if (mentionCtrl) mentionCtrl.abort();
    mentionCtrl = new AbortController();
    const {signal} = mentionCtrl;
    const {projectId} = queryContext;

    const orderDirection = orderIsAsc ? 'asc' : 'desc';

    //https://developers.brandwatch.com/docs/retrieving-mentions
    if (!isSavedSearchOrder(orderBy)) {
        throw new Error(
            `Invalid orderBy parameter "${orderBy}", must be one of: ${SAVED_SEARCH_ORDERS.join(
                ', ',
            )}`,
        );
    }

    const params = getSearchParams(
        filterStateToAPIFormat(filters.filters, filters.allFiltersConfig),
        filters.dateRange,
        queryContext,
        additionalQueries,
        linkedinChannelIds,
    );

    const formatStr = format ? `.${format.toLowerCase()}` : '/fulltext';

    return !!format
        ? (http.get(
              `/api/projects/${projectId}/data/mentions${formatStr}${params}&page=${resultsPage}&pageSize=${pageSize}&orderBy=${orderBy}&orderDirection=${orderDirection}`,
              {signal, headers: {Accept: 'text/csv', 'upgrade-insecure-requests': '1'}},
              true,
          ) as any)
        : http.get<{results: SavedSearchMentionApiType[]}>(
              `/api/projects/${projectId}/data/mentions${formatStr}${params}&page=${resultsPage}&pageSize=${pageSize}&orderBy=${orderBy}&orderDirection=${orderDirection}`,
              {signal, headers: {Accept: 'text/csv', 'upgrade-insecure-requests': '1'}},
          );
}

/**
 * Load quick search mentions
 */
export async function loadQuickSearchMentions({
    queryContext,
    filters,
    orderBy = 'date',
    orderIsAsc = false,
    resultsPage = 0,
    pageSize = MENTIONS_PAGE_SIZE,
}: {
    queryContext: QueryContextType;
    filters: AllFilteringState;
    orderBy?: SavedSearchOrders | QuickSearchOrders;
    orderIsAsc?: boolean;
    resultsPage?: number;
    pageSize?: number;
}) {
    if (queryContext.searchType === 'saved') {
        throw new Error(
            'Invalid arguments. This method can only be used to load mentions for quick searches',
        );
    }

    if (mentionCtrl) mentionCtrl.abort();
    mentionCtrl = new AbortController();
    const {signal} = mentionCtrl;
    const {projectId} = queryContext;

    const orderDirection = orderIsAsc ? 'asc' : 'desc';

    if (!isQuickSearchOrder(orderBy)) {
        throw new Error(
            `Invalid orderBy parameter "${orderBy}", must be one of: ${QUICK_SEARCH_ORDERS.join(
                ', ',
            )}`,
        );
    }

    const params = getSearchParams(
        filterStateToAPIFormat(filters.filters, filters.allFiltersConfig),
        filters.dateRange,
        queryContext,
    );

    return http.get<QuickSearchApiResults>(
        `/api/projects/${projectId}/search/data/mentions${params}&page=${resultsPage}&pageSize=${pageSize}&orderBy=${orderBy}&orderDirection=${orderDirection}`,
        {signal},
    );
}

/**
 * Make a change to a mention
 *
 * @param mentions The mentions to be patched. Only requires the params needed to identify the mention by the API
 * @param patchData  - the values to change
 * @param projectId the projectId containing the mention
 * @returns An HttpResults object
 */
export type APIEmotionType = `emotions:${Capitalize<EmotionsType>}`;

export type PatchMentionData = {
    mention: MentionAPIIdentifier;
    patchData: Partial<Omit<SavedSearchMentionApiType, 'resourceId' | 'queryId' | 'date'>> & {
        addTag?: string;
        removeTag?: string;
        location?: string;
        addClassifications?: APIEmotionType[];
        removeClassifications?: APIEmotionType[];
    };
};

export async function patchMentions(patch: PatchMentionData[], projectId: number) {
    const data = patch.map(({mention, patchData}) => ({
        ...pick(mention, ['resourceId', 'queryId', 'date']),
        ...patchData,
    }));

    return http.patch<SavedSearchMentionApiType[]>(
        `/api/projects/${projectId}/data/mentions`,
        data,
        //{signal},
    );
}

/**
 * Delete a list of mentions. Must all be part of the same project.
 * If there is an error, will not delete any mentions. Will not fail if passed a correctly formatted
 * resourceId for a non-existant mention.
 *
 * @param mentions A list of mentions to delete
 * @param projectId The project ID
 * @returns HttpResult object with body = 'OK' on success
 */
export async function deleteMentions(mentions: MentionAPIIdentifier[], projectId: number) {
    const data = mentions.map(({date, resourceId, queryId}) => ({
        date,
        mentionId: resourceId,
        queryId,
    }));

    return http.delete<'OK'>(`/api/projects/${projectId}/data/mentions`, data);
}

export async function getIrisSummary(
    {
        queryContext,
        dateRange,
        filters,
        filtersConfig,
        additionalQueries,
        linkedinChannelIds,
        signal
    }: {
        queryContext: QueryContextType,
        dateRange: DateRange,
        filters: FiltersState,
        filtersConfig: FilterConfigByName,
        additionalQueries: number[];
        linkedinChannelIds: number[];
        signal?: AbortSignal
    }
) {
    const searchParams = getSearchParams(
        filterStateToAPIFormat(filters, filtersConfig),
        dateRange,
        queryContext,
        additionalQueries,
        linkedinChannelIds,
        true,
    );

    const params = {
        ...searchParams,
        projectId: queryContext.projectId
    }

    return fetch( "/fe-api/iris-conversation-insights", {
        method: 'POST',
        ...Http.defaultOptions,
        body: JSON.stringify(params || {}),
        signal
    });
}