//TODO storybook integration
//TODO unit tests

import {forwardRef, useState, useMemo, useEffect, useRef, ForwardedRef, ReactElement} from 'react';

//Components
import PositionedBubble, {
    PositionedBubblePositionElementProps,
    PositionedBubbleProps,
} from 'hsi/components/Tooltip/PositionedBubble';

//Utils
import {combineProps} from 'hsi/utils/react';
import {Diff} from 'hsi/types/shared';

//Exports
export {mergeReferencePropsMUI} from 'hsi/components/Tooltip/PositionedBubble';

//Prop types
interface TooltipProps<TChildElement extends HTMLElement>
    extends Omit<
        PositionedBubbleProps<TChildElement>,
        'content' | 'floatingProps' | 'role' | 'setOpen'
    > {
    disable?: boolean;
    dialog?: boolean;
    tooltip: PositionedBubbleProps<TChildElement>['content'];
    floatingProps?: JSX.IntrinsicElements['div'];
}

interface TooltipPositionElementProps<
    TChildElement extends HTMLElement,
    TPositionedElement extends HTMLElement,
> extends Omit<
        PositionedBubblePositionElementProps<TChildElement, TPositionedElement>,
        'content' | 'floatingProps' | 'role' | 'setOpen'
    > {
    disable?: boolean;
    dialog?: boolean;
    tooltip: PositionedBubbleProps<TChildElement>['content'];
    floatingProps?: JSX.IntrinsicElements['div'];
}

//The exported element type (combines ref as one of the props)
type TooltipType = {
    <TChildElement extends HTMLElement = HTMLElement>(
        props: TooltipProps<TChildElement> & {ref?: ForwardedRef<TChildElement>},
    ): ReactElement;
    <
        TChildElement extends HTMLElement = HTMLElement,
        TPositionedElement extends HTMLElement = TChildElement,
    >(
        props: TooltipPositionElementProps<TChildElement, TPositionedElement> & {
            ref?: ForwardedRef<TChildElement>;
        },
    ): ReactElement;
};

//Overloaded function types
function Tooltip<TChildElement extends HTMLElement = HTMLElement>(
    props: TooltipProps<TChildElement>,
    ref: ForwardedRef<TChildElement>,
): React.ReactElement;
function Tooltip<
    TChildElement extends HTMLElement = HTMLElement,
    TPositionedElement extends HTMLElement = TChildElement,
>(
    props: TooltipPositionElementProps<TChildElement, TPositionedElement>,
    ref: ForwardedRef<TChildElement>,
): React.ReactElement;

//The component
function Tooltip<
    TChildElement extends HTMLElement = HTMLElement,
    TPositionedElement extends HTMLElement = TChildElement,
>(
    {
        tooltip,
        disable,
        dialog,
        delay = [400, 0],
        noHover,
        open,
        floatingProps: _floatingProps,
        ...rest
    }: TooltipProps<TChildElement> &
        Partial<
            Diff<
                TooltipPositionElementProps<TChildElement, TPositionedElement>,
                TooltipProps<TChildElement>
            >
        >,
    ref: React.ForwardedRef<TChildElement>,
) {
    const [isFloatingFocus, setIsFloatingFocus] = useState(false);
    const [isElementFocus, setIsElementFocus] = useState(false);

    const [isTooltipHover, setIsTooltipHover] = useState(false);
    const [isElementHover, setIsElementHover] = useState(false);

    const hover = useApplyDelay(isTooltipHover || isElementHover, delay); //keep track of open state/delay internally
    const focus = isFloatingFocus || isElementFocus;

    const floatingProps = useMemo(
        () => ({
            ..._floatingProps,
            onMouseEnter: () => setIsTooltipHover(true),
            onMouseLeave: () => setIsTooltipHover(false),

            onFocus: () => setIsFloatingFocus(true),
            onBlur: () => setIsFloatingFocus(false),
        }),
        [setIsTooltipHover, _floatingProps],
    );

    const childProps = useMemo(
        () => ({
            onMouseEnter: () => setIsElementHover(true),
            onMouseLeave: () => setIsElementHover(false),

            onFocus: () => setIsElementFocus(true),
            onBlur: () => setIsElementFocus(false),
        }),
        [],
    );

    return (
        <PositionedBubble<TChildElement>
            open={open ?? ((hover || focus) && !disable)}
            setOpen={noop}
            noHover
            content={tooltip}
            floatingProps={floatingProps}
            role={dialog ? 'dialog' : 'tooltip'}
            {...combineProps(childProps, rest)}
            ref={ref}
            aria-hidden="true"
        />
    );
}

export default forwardRef(Tooltip) as unknown as TooltipType;

function noop() {}

function useApplyDelay(open: boolean, delay?: number | number[]) {
    const [isOpen, setIsOpen] = useState(false);
    const tIdRef = useRef<NodeJS.Timeout | null>(null);

    const [openDelay, closeDelay] =
        typeof delay === 'number' ? [delay, delay] : delay instanceof Array ? delay : [0, 0];

    useEffect(() => {
        if (tIdRef.current) {
            clearTimeout(tIdRef.current);
            tIdRef.current = null;
        }

        if (open) {
            //apply open delay
            if (openDelay) {
                tIdRef.current = setTimeout(() => setIsOpen(true), openDelay);
            } else {
                setIsOpen(true);
            }
        } else {
            //apply close delay
            if (closeDelay) {
                tIdRef.current = setTimeout(() => setIsOpen(false), closeDelay);
            } else {
                setIsOpen(false);
            }
        }

        return () => {
            tIdRef.current && clearTimeout(tIdRef.current);
        };
    }, [closeDelay, open, openDelay]);

    return isOpen; //TODO apply delay
}
