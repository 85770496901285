//OBSOLETE - this should be replaced with the Autoselect component in all places where it is used
//This component is an accessiblity nightmare, and needs careful consideration to fix

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import cn from 'classnames';
import find from 'lodash/find';
import InputAdornment from '@mui/material/InputAdornment';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import debounce from 'lodash/debounce';

import Tooltip from 'hsi/components/Tooltip';
import IconRouter from 'hsi/components/IconRouter';
import TextField from 'hsi/components/TextField';
import {T} from 'hsi/i18n';

import PulseLoader from 'hsi/components/PulseLoader';
import styles from './styles';

class SearchableSelectContent extends Component {
    static propTypes = {
        options: PropTypes.array,
        loading: PropTypes.bool,
        bigIcons: PropTypes.bool,
        iconType: PropTypes.string,
        onChange: PropTypes.func,
        onKeyDown: PropTypes.func,
        onSelect: PropTypes.func,
        onAdd: PropTypes.func,
        isAsync: PropTypes.bool,
        startSearchMsg: PropTypes.any,
        noResultsMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        showSearch: PropTypes.bool,
        className: PropTypes.string,
        debounceSearch: PropTypes.bool,
        fixFirstHeight: PropTypes.bool,
        itemDisabledTooltip: PropTypes.string,
        searchPlaceholder: PropTypes.string,
    };

    static defaultProps = {
        options: [],
        loading: false,
        isAsync: false,
        startSearchMsg: 'Type to search...',
        noResultsMsg: 'No results found',
        showSearch: false,
        showAddNewItem: false,
        debounceSearch: false,
    };

    state = {
        search: '',
        selectIndex: -1,
    };

    lastLoading = false;

    constructor(props, context) {
        super(props, context);
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        setTimeout(() => {
            this.inputRef.current && this.inputRef.current.focus();
            const {classes, fixFirstHeight} = this.props;
            const elem = document.querySelector(`.${classes.optionsContainer} .sws-selected`);
            elem &&
                elem.scrollIntoView({
                    bahavior: 'smooth',
                    block: 'nearest',
                });
            if (fixFirstHeight) {
                this.firstHeight = document.querySelector(
                    `.${classes.optionsContainer}`,
                ).offsetHeight;
            }
        });

        const {loading} = this.props;
        if (this.lastLoading !== loading) {
            this.setState({selectIndex: -1});
            this.lastLoading = loading;
        }
    }

    componentDidUpdate() {
        const {search, selectIndex} = this.state;
        if (
            search.trim() &&
            this.options.length &&
            this.options[0].label.toLowerCase().includes(search.trim().toLowerCase()) &&
            selectIndex === -1
        ) {
            this.setState({selectIndex: 0});
        }
    }

    onInputChange = (ev) => {
        const search = ev.target.value;
        this.setState({search, selectIndex: -1});
        const {onChange, debounceSearch} = this.props;
        if (this.props.onChange) {
            const tsearch = search.trim();
            onChange && (debounceSearch ? this.triggerOnChange(tsearch) : onChange(tsearch));
        }
    };

    triggerOnChange = debounce((search) => this.props.onChange(search), 200, {
        leading: true,
        trailing: true,
    });

    onKeyDown = (ev) => {
        ev.stopPropagation();

        this.props?.onKeyDown?.(ev);
    };

    renderNoResultsMsg = (search, noResultsMsg) => {
        if (typeof noResultsMsg == 'function') {
            const NoResultsMsg = noResultsMsg;
            return <NoResultsMsg search={search} />;
        }
        return noResultsMsg;
    };

    getOptions = () => {
        const {showAddNewItem, options, onAdd, classes} = this.props;
        const {search} = this.state;
        const searchLc = (search || '').trim().toLowerCase();
        const shouldShowAddNewItem =
            !!showAddNewItem &&
            !!searchLc &&
            (!options.length || !find(options, (o) => o.label.toLowerCase() === searchLc));
        const _options = shouldShowAddNewItem
            ? [
                  {
                      func: onAdd,
                      label: search,
                      contentRight: (
                          <span className={classes.addNewHint}>
                              {T('addNew')}
                              <IconRouter name="reply" />
                          </span>
                      ),
                      className: classes.addNewItem,
                      value: 'add-new',
                  },
                  ...options,
              ]
            : options;
        this.options = _options;
        this.shouldShowAddNewItem = shouldShowAddNewItem;
        return {options: _options, showAddNew: shouldShowAddNewItem};
    };

    render() {
        const {
            onSelect,
            isAsync,
            startSearchMsg,
            noResultsMsg,
            loading,
            searchPlaceholder,
            bigIcons,
            iconType,
            classes,
            showSearch,
            className,
            itemDisabledTooltip,
        } = this.props;
        const {search} = this.state;
        const hasSearch = !!search.trim();
        const {options} = this.getOptions();

        return (
            <div
                className={cn(classes.root, className)}
                onKeyDown={this.onKeyDown}
                onClick={(e) => e.stopPropagation()}
            >
                {!!showSearch && (
                    <div className={classes.searchFieldContainer}>
                        <TextField
                            placeholder={searchPlaceholder}
                            value={search}
                            onChange={this.onInputChange}
                            autoFocus={true}
                            inputRef={(el) => {
                                this.inputRef.current = el;
                            }}
                            fullWidth
                            InputProps={{
                                autoFocus: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconRouter name="search" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                )}
                {!!loading && hasSearch && (
                    <li className={classes.loading}>
                        <PulseLoader size="small" />
                    </li>
                )}
                <MenuList
                    className={classes.optionsContainer}
                    style={this.firstHeight ? {height: this.firstHeight} : null} //??
                >
                    {!!options &&
                        !loading &&
                        (!isAsync || hasSearch) &&
                        options.map((item, i) => {
                            let label =
                                item.disabled && itemDisabledTooltip ? (
                                    <Tooltip tooltip={itemDisabledTooltip} delay={[700, 0]} portal>
                                        <span className={classes.disabledLabel}>{item.label}</span>
                                    </Tooltip>
                                ) : (
                                    item.label
                                );
                            return (
                                <MenuItem
                                    key={i}
                                    component="div"
                                    className={cn(
                                        classes.menuItem,
                                        bigIcons && classes.bigIcons,
                                        item.className,
                                    )}
                                    onClick={() =>
                                        !item.disabled &&
                                        (item.func ? item.func(item) : onSelect && onSelect(item))
                                    }
                                    disabled={item.disabled}
                                >
                                    {iconType && (
                                        <div className={classes.iconContainer}>
                                            <IconRouter name={iconType} />
                                        </div>
                                    )}
                                    <Typography variant="inherit" noWrap className={classes.label}>
                                        {label}
                                    </Typography>
                                    {item.contentRight && (
                                        <div className={classes.contentRight}>
                                            {item.contentRight}
                                        </div>
                                    )}
                                </MenuItem>
                            );
                        })}
                    {!loading && (!options.length || (!hasSearch && isAsync)) && (
                        <div className={classes.searchMsg}>
                            {!hasSearch
                                ? startSearchMsg
                                : this.renderNoResultsMsg(search, noResultsMsg)}
                        </div>
                    )}
                </MenuList>
            </div>
        );
    }
}

export default withStyles(styles)(SearchableSelectContent);
