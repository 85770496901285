
import makeStyles from '@mui/styles/makeStyles';
import {T} from 'hsi/i18n';

const useStyles = makeStyles(({v2}) => ({
    newBadge: {
        padding: v2 ? '0px 4px' : '2px 4px',
        fontSize: v2 ? '8px' : '12px',
        color: v2 ? 'white' : '#CB4807',
        backgroundColor: v2 ? '#8AC539' : undefined,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'uppercase',
        borderRadius: '2px',
        marginLeft: '8px',
        fontWeight: 700,
        height: '20px',
        lineHeight: '20px',
    },
}));

export default function NewBadge () {
    const classes = useStyles();

    return <span className={classes.newBadge}>{T('newBadge')}</span>;
};
