import {useState} from 'react';
import cn from 'classnames';

//Components
import PeakLabel from 'hsi/components/PeakLabel';
import IconRouter from 'hsi/components/IconRouter';
import Number from 'hsi/components/format/Number';
import Button from 'hsi/components/Button';
import AIChip from 'hsi/components/AIChip';

//Hooks
import useDates from 'hsi/hooks/useDates';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Types
import format from 'hsi/utils/number/format';
import Markdown from 'hsi/components/Markdown';
import {PeakReasonType, PeakType} from 'hsi/types/peaks';

type PeakExplainationProps = {
    peak: PeakType;
};

export default function PeakExplanation({peak}: PeakExplainationProps) {
    const {classes} = useStyles();
    const [collapsed, setCollapsed] = useState(true);
    const {formatTo} = useDates();

    return (
        <div
            className={cn(classes.peakExplanation, peak.reasons.length > 3 && classes.collapsible)}
        >
            <h3 className={classes.title}>
                {T('mentionsContainer.peak.drawerTitle')}
                <AIChip className={classes.AIChip} />
            </h3>
            <div className={classes.peak}>
                <PeakLabel
                    className={classes.peakLabel}
                    fill={peak.fill}
                    text={peak.text}
                    label={T('mentionsContainer.peak.label', {
                        name: peak.text,
                        peaked: formatTo(peak.highestVolumeDate, 'ccc, MMM dd yyyy'),
                    })}
                />
                <span className="offscreen">
                    , {T('mentionsContainer.peak.highestVolumeLbl', {value: peak.highestVolume})}
                </span>
                <span aria-hidden="true" className={classes.highestVolume}>
                    <Number format="compact" value={peak.highestVolume} />
                </span>
                <span className={classes.peakDiff}>
                    {T('mentionsContainer.peak.diff', {
                        percent: peak.aboveDailyMedianPercent,
                    })}
                </span>
            </div>
            {peak.reasons.length === 0 ? (
                <p>{T('mentionsContainer.peak.driver.none')}</p>
            ) : (
                <>
                    <ul className={classes.reasonList}>
                        {peak.reasons.map((r, i) => (
                            <PeakReason
                                key={r.data + '_' + r.volume + '_' + r.increaseTimes}
                                className={collapsed && i > 2 ? 'offscreen' : undefined}
                                reason={r}
                                fill={peak.fill}
                            />
                        ))}
                    </ul>
                    {peak.reasons.length > 3 && (
                        <Button
                            aria-hidden="true"
                            className={classes.reasonExpandButton}
                            priority="text"
                            size="small"
                            onClick={() => setCollapsed(!collapsed)}
                        >
                            {T(collapsed ? 'readMore' : 'readLess')}
                        </Button>
                    )}
                </>
            )}
        </div>
    );
}

type PeakReasonProps = {
    fill: string;
    reason: PeakReasonType;
    className?: string;
};

function PeakReason({fill, reason, className}: PeakReasonProps) {
    const {classes} = useStyles();
    const {icon, text} = peakReasonContent(reason);

    return (
        <li className={cn(classes.reason, className)}>
            <IconRouter
                className={classes.reasonIcon}
                name={icon}
                style={{color: fill}}
                aria-hidden="true"
            />
            <Markdown
                className={classes.reasonText}
                classes={{p: classes.reasonTextP}}
                linkTarget={'_blank'}
            >
                {text}
            </Markdown>
        </li>
    );
}

// Utility methods
function peakReasonContent(r: PeakReasonType) {
    let icon = '';
    let text = '';
    const formattedVolume = format(r.volume);

    switch (r.type) {
        case 'expandedUrls':
            icon = 'url';
            text = T('mentionsContainer.peak.driver.url', {
                volume: formattedVolume,
                data: r.data,
                url: r.data.split('/')[2],
            });
            break;
        case 'facebookThreadUrl':
            icon = 'facebook';
            text = T('mentionsContainer.peak.driver.facebook', {
                volume: formattedVolume,
                url: r.data,
            });
            break;
        case 'hashtags':
            icon = 'hashtag';
            text = T('mentionsContainer.peak.driver.hashtag', {
                volume: formattedVolume,
                hashtag: r.data,
            });
            break;
        case 'news':
            icon = 'news';
            text = T('mentionsContainer.peak.driver.news', {volume: formattedVolume});
            break;
        case 'redditThreadUrl':
            icon = 'reddit';
            text = T('mentionsContainer.peak.driver.reddit', {
                volume: formattedVolume,
                url: r.data,
            });
            break;
        case 'twitterRetweetOf':
            icon = 'twitter';
            text = T('mentionsContainer.peak.driver.retweet', {
                volume: formattedVolume,
                url: r.data,
            });
            break;
        case 'video':
            icon = 'video';
            text = T('mentionsContainer.peak.driver.video', {volume: formattedVolume});
            break;
        default:
            icon = '';
            text = '';
    }

    return {icon, text};
}
