import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';

//Components
import ExportSearchResults from 'hsi/containers/ExportSearchResults';
import ExportMentions from 'hsi/containers/ExportMentions';

//Other
import makeExportTheme from 'hsi/themes/export';
import {BaseApp, getHistory, getStore} from 'hsi/utils/entry-factory';
import {set} from 'hsi/config';
import loadIcons from 'hsi/utils/loadIcons';

import {QueryClient} from '@tanstack/react-query';

//Consts
export const ExportContext = React.createContext(false);
ExportContext.displayName = 'ExportContext';

const typeComponents = {
    cards: ExportSearchResults,
    mentions: ExportMentions,
};

//The component
const App = ({configs, themes, testData}) => {
    return (
        <ExportContext.Provider value={true}>
            <BrowserRouter basename="export">
                <Route
                    path="/:type?"
                    render={({match}) =>
                        getAppFromParams(match.params.type || 'cards', configs, themes, testData)
                    }
                />
            </BrowserRouter>
        </ExportContext.Provider>
    );
};

export default App;

function getAppFromParams(type, configs, themes, testData) {
    if (testData) {
        window.desData = testData[type || 'cards'];
    }

    const preloadedState = window?.desData?.state || {};

    const appSource = window?.desData?.appSource || 'BW';
    window.APP_SOURCE = appSource;

    const Component = typeComponents[type];

    const history = getHistory('export');

    const config = configs[appSource];
    const theme = makeExportTheme(themes[appSource]);
    const [store] = getStore(null, history, preloadedState);

    set(config);

    //Load icons
    config.svgPath && loadIcons(config.svgPath);

    const queryClient = new QueryClient();

    return (
        <BaseApp config={config} theme={theme} store={store} queryClient={queryClient}>
            <Component />
        </BaseApp>
    );
}
