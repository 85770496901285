import React from 'react';
import {isSafari, isChrome, isFirefox, isEdge} from 'react-device-detect';

import IconRouter from 'hsi/components/IconRouter';

import img from 'hsi/img/401cat.png';
import {T} from 'hsi/i18n';

import useConfig from 'hsi/hooks/useConfig';

import useStyles from './styles';

const SessionBlockedError = () => {
    const classes = useStyles();
    const {appSource} = useConfig();

    return (
        <div className={classes.root}>
            {appSource === 'HSI' && <img alt="" src={img} />}
            <h1>{T('sessionBlocked.title')}</h1>

            {isSafari && (
                <>
                    <p dangerouslySetInnerHTML={{__html: T('sessionBlocked.safari')}}></p>
                    <a
                        href="https://support.apple.com/en-ca/guide/safari/sfri40732/14.0/mac/11.0"
                        rel="noreferrer"
                        target="_blank"
                    >
                        {T('sessionBlocked.learnSafari')}
                        <IconRouter name="mui-launch" />
                    </a>
                </>
            )}

            {isChrome && (
                <>
                    <p>{T('sessionBlocked.chrome')}</p>

                    <a
                        href="https://support.google.com/chrome/answer/95647"
                        rel="noreferrer"
                        target="_blank"
                    >
                        {T('sessionBlocked.learnChrome')}
                        <IconRouter name="mui-launch" />
                    </a>
                </>
            )}

            {isFirefox && (
                <>
                    <p>{T('sessionBlocked.firefox')}</p>

                    <a
                        href="https://support.mozilla.org/en-US/kb/disable-third-party-cookies"
                        rel="noreferrer"
                        target="_blank"
                    >
                        {T('sessionBlocked.learnFirefox')}
                        <IconRouter name="mui-launch" />
                    </a>
                </>
            )}

            {isEdge && (
                <>
                    <p>{T('sessionBlocked.edge')}</p>

                    <a
                        href="https://support.microsoft.com/search/results?query=allow+cookies"
                        rel="noreferrer"
                        target="_blank"
                    >
                        {T('sessionBlocked.learnEdge')}
                        <IconRouter name="mui-launch" />
                    </a>
                </>
            )}

            <p>{T('sessionBlocked.contact')}</p>
        </div>
    );
};

export default SessionBlockedError;
