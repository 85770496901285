import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import ErrorItem from './ErrorItem';
import IconRouter from 'hsi/components/IconRouter';

import makeStyles from '@mui/styles/makeStyles';
import styles from './styles';

const MultipleErrorMsg = ({action, className, errors}) => {
    const useStyles = makeStyles(styles);
    const classes = useStyles();

    if (!errors || !errors.length) return null;

    return (
        <div role="alert" className={cn(classes.root, className)}>
            <div className={classes.iconContainer}>
                <IconRouter name="warning" className={classes.icon} />
            </div>
            <div className={classes.content}>
                {errors.map((item, i) => (
                    <div className={classes.errorItem} key={i}>
                        <ErrorItem {...{action, classes, item}} />
                    </div>
                ))}
            </div>
        </div>
    );
};

MultipleErrorMsg.propTypes = {
    action: PropTypes.func,
    className: PropTypes.string,
    errors: PropTypes.array.isRequired,
};

export {MultipleErrorMsg};
