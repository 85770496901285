import {useCallback} from 'react';

import Button from 'hsi/components/Button';
import CheckboxList from 'hsi/components/CheckboxList';
import {Dialog, DialogContent, DialogActions, DialogContentText} from 'hsi/components/Dialog';
import IconRouter from 'hsi/components/IconRouter';
import PulseLoader from 'hsi/components/PulseLoader';

import {useAppDispatch} from 'hsi/hooks/useRedux';
import useAddFacebookPagesAndInstagramAccounts from 'hsi/hooks/useAddFacebookPagesAndInstagramAccounts';
import useStyles from './styles';

import {AuthenticationDataTypes, TrackableOwnedAuthentication} from 'hsi/types/dataManagement';
import {toggleFacebookLoginDialog, toggleAddOwnedDialog} from 'hsi/slices/facebookAuthentication';
import {T} from 'hsi/i18n';

const AddOwnedFacebookPagesAndInstagramAccountsDialog = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const {
        addFacebookPagesAndInstagramAccounts,
        isAddEnabled,
        isAddOwnedDialogOpen,
        isLoginLoading,
        selectProps,
    } = useAddFacebookPagesAndInstagramAccounts();

    const onClose = useCallback(() => {
        dispatch(toggleAddOwnedDialog(false));
    }, [dispatch]);

    const onBack = useCallback(async () => {
        await onClose();
        dispatch(toggleFacebookLoginDialog(true));
    }, [dispatch, onClose]);

    const onSubmit = useCallback(async () => {
        addFacebookPagesAndInstagramAccounts();
        onClose();
    }, [addFacebookPagesAndInstagramAccounts, onClose]);

    return (
        <Dialog
            className={classes.dialog}
            onClose={onClose}
            open={isAddOwnedDialogOpen}
            title={T('authTokens.tokensPage.allowModal.title')}
            transitionDuration={0}
        >
            <DialogContent>
                <DialogContentText className={classes.dialogContentText}>
                    {T('authTokens.tokensPage.allowModal.desc')}
                </DialogContentText>

                {isLoginLoading ? (
                    <div className={classes.loadingContainer}>
                        <PulseLoader />
                    </div>
                ) : (
                    <CheckboxList
                        {...selectProps}
                        cta={T('authTokens.tokensPage.allowModal.cta')}
                        formatLabel={(item: TrackableOwnedAuthentication) => {
                            switch (item.type) {
                                case AuthenticationDataTypes.FacebookPageAuthentication:
                                    return (
                                        <div className={classes.checkboxItem}>
                                            <IconRouter
                                                aria-label={T(
                                                    'authTokens.tokensPage.allowModal.facebookIconLabel',
                                                )}
                                                className={classes.checkboxIcon}
                                                name="logo-facebook" // TODO: Get from config
                                            />
                                            {item.name}
                                        </div>
                                    );
                                case AuthenticationDataTypes.InstagramAccountAuthentication:
                                    return (
                                        <div className={classes.checkboxItem}>
                                            <IconRouter
                                                aria-label={T(
                                                    'authTokens.tokensPage.allowModal.instagramIconLabel',
                                                )}
                                                className={classes.checkboxIcon}
                                                name="logo-instagram" // TODO: Get from config
                                            />
                                            {item.name}
                                        </div>
                                    );
                            }
                        }}
                        handleCtaClick={onBack}
                        label={T('authTokens.tokensPage.allowModal.listTitle')}
                        labelField="name"
                        optionsListClassName={classes.selectList}
                        searchPlaceholder={T('authTokens.tokensPage.allowModal.searchPlaceholder')}
                        showSearchAtLength={1}
                        showSelectAll
                        valueField="id"
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button className={classes.backButton} onClick={onBack}>
                    {T('back')}
                </Button>
                <Button onClick={onClose}>{T('cancel')}</Button>
                <Button disabled={!isAddEnabled} onClick={onSubmit} priority="cta">
                    {T('authTokens.tokensPage.allowModal.track')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddOwnedFacebookPagesAndInstagramAccountsDialog;
