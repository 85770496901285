import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {saveIntroFinished, saveStepIdx, loadStepIdx} from './storage';
import Step from './Step';

type ItemProps = {id: string; steps: any[]; onFinished: ({id}: {id: string}) => any};

const Item = ({id, steps, onFinished}: ItemProps) => {
    const dispatch = useDispatch();

    const defaultStepIdx = useMemo(() => loadStepIdx(id), [id]);

    const [stepIdx, setStepIdx] = useState(defaultStepIdx);
    const [open, setOpen] = useState(true);
    const step = steps[stepIdx];

    const next = useCallback(() => {
        const idx = Math.min(stepIdx + 1, steps.length - 1);
        saveStepIdx(id, idx);
        setStepIdx(idx);
    }, [id, stepIdx, steps.length]);

    const onNext = useCallback(
        (event?: Event) => {
            event?.stopPropagation();
            if (typeof step?.onNext === 'function') step.onNext({dispatch, next});
            else next();
        },
        [dispatch, next, step],
    );

    const onClose = useCallback(
        (event?: Event) => {
            event?.stopPropagation();
            saveIntroFinished(id);
            setOpen(false);
            if (typeof onFinished === 'function') onFinished({id});
        },
        [id, onFinished],
    );

    useEffect(() => {
        setStepIdx(loadStepIdx(id));
        setOpen(true);
    }, [id]);

    useEffect(() => {
        if (typeof step?.onInit === 'function') {
            setOpen(false);
            step.onInit({
                dispatch,
                initialize: () => setOpen(true),
            });
        }
    }, [dispatch, step]);

    if (!step || !open) {
        return null;
    }

    return (
        <Step
            key={step.anchor}
            onClose={onClose}
            onNext={onNext}
            step={step}
            stepIdx={stepIdx}
            stepsLength={steps.length}
        />
    );
};

export default Item;
