import defaults from 'lodash/defaultsDeep';
import bw_en from './bw-en.js';

const phrases = defaults(
    {
        savedsearchbar: {
            buildreport: {
                button: 'Create Report',
            },
        },
        intro: {
            quickSearchResults: {
                needGuidanceTitle: 'Need help?',
                needGuidanceDescription:
                    "Your search isn't using many boolean operators. Would you like to improve it?",
                guidanceDescription:
                    'Click the dropdown and select <strong>Advanced search help</strong> to see a list of operators and examples to help refine your results.',
            },
            dataManagement: {
                desc: 'You can add owned LinkedIn data as a source in the Content source settings area.',
            },
        },
        exportToPDF: {
            annotations: {
                cardsSelector: 'Select the charts you want to export',
                byDefault: 'By default, all charts will be exported.',
            },
        },
        homeNavBar: {
            dataManagement: 'Content source settings',
        },
        dataManagement: {
            title: 'Content source settings',
        },
    },
    bw_en,
);

export default phrases;
