import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);

    return {
        itemContainer: {
            position: 'relative',
            cursor: 'pointer',
            padding: v2 ? 0 : '0 15px',
        },
        label: {
            display: 'inline-flex',
            alignItems: 'center',
            gap: '10px',
            padding: '10px',
            cursor: 'inherit',

            '&.disabled': {
                cursor: 'unset',
            },
        },
        labelText: {
            '&.disabled': {
                opacity: 0.5,
                cursor: 'unset',
            },
        },
        itemContainerChecked: {
            backgroundColor: v2 ? undefined : theme.colors.primaryYellow40,
        },
        disabled: {},
    };
});
