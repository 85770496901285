import React, {useCallback} from 'react';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import {
    LineChart as ReLineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Customized,
    Label,
    Legend,
    ReferenceDot,
} from 'recharts';

//Components
import PeakLabel from 'hsi/components/PeakLabel';
import VoronoiTooltip from './VoronoiTooltip';
import CustomLegend from './CustomLegend';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useElementSize from 'hsi/hooks/useElementSize';
import useLineChart from './useLineChart';

//Utils
import {formatBigInt} from 'hsi/utils/formatNumber';

//Other
import useStyles from './styles';
import {T} from 'hsi/i18n';

//Constants
const DRAW_MARK_THRESHOLD = 17;
const TOOLTIP_DAY_FORMAT = 'DD ZZZZ';
const TOOLTIP_HOUR_FORMAT = 'MMM d, yyyy h:mm a ZZZZ';

const elementSizeOptions = {
    width: true,
    height: true,
    x: false,
    y: false,
    getTargetElement: (x) => x?.parentElement,
};

//The component
const LineChart = React.forwardRef(
    (
        {categories, interval, series: _series, timezone, peaks, tooltipUnit, drillInCard, ...rest},
        ref,
    ) => {
        const classes = useStyles();
        const config = useConfig();
        const [sizeRef, {width, height} = {}] = useElementSize(ref, elementSizeOptions);
        const {peaksAugmented, series, x, y} = useLineChart({
            _series,
            categories,
            interval,
            peaks,
            width,
        });
        //Calculated values
        const numDays = series.length;
        const drawPoints = width / numDays > DRAW_MARK_THRESHOLD;

        //Callbacks
        const drillIn = useCallback(
            (params) => {
                const {
                    dataKey: key,
                    payload: {date, dateNumber},
                } = params;
                drillInCard?.({key, date}, find(peaksAugmented, {dateNumber}) || null);
            },
            [drillInCard, peaksAugmented],
        );

        const onPeakClick = useCallback(
            (peak) => {
                drillInCard?.({key: peak.id, date: peak.highestVolumeDate}, peak);
            },
            [drillInCard],
        );

        const renderPeakIcon = useCallback(
            (props, peak) => {
                const x = props.viewBox.x - 1.75;
                const y = props.viewBox.y - props.viewBox.height - 3;

                return (
                    <g transform={`translate(${x}, ${y})`}>
                        <PeakLabel
                            fill={peak.fill}
                            onClick={() => onPeakClick(peak)}
                            text={peak.text}
                        />
                    </g>
                );
            },
            [onPeakClick],
        );

        //Render
        if (!width || !height) return <div data-testid="blankLineChart" ref={sizeRef} {...rest} />;

        return (
            <div data-testid="lineChart" ref={sizeRef} {...rest}>
                <ReLineChart
                    className={classes.lineMarkChartRecharts}
                    data={series}
                    height={height}
                    margin={{
                        top: 35,
                        right: 5,
                        bottom: 0,
                        left: config.lineChart.leftPadding,
                    }}
                    width={width}
                    title='line chart'
                >
                    <CartesianGrid vertical={false} />

                    <XAxis
                        allowDecimals={false}
                        axisLine={config.lineChart.axisLine}
                        dataKey="dateNumber"
                        domain={x.domain}
                        minTickGap={interval === 'months' ? 40 : 20}
                        padding={{left: 25, right: 30}}
                        ticks={x.ticks}
                        tickCount={20}
                        tickFormatter={(date) => x.axisDateFormat(date)}
                        tickLine={config.lineChart.tickLine}
                        tickMargin={9}
                        type="number"
                    />

                    <YAxis
                        allowDecimals={false}
                        axisLine={false}
                        domain={y.domain}
                        ticks={y.ticks}
                        tickFormatter={(value) => formatBigInt(value, 1000)}
                        tickLine={false}
                        tickMargin={config.lineChart.tickMargin}
                    >
                        {!!!config.lineChart.hasSideLabel && (
                            <Label
                                angle={270}
                                offset={18}
                                position="left"
                                style={{textAnchor: 'middle !important'}}
                            >
                                {T('cards.mentionsHistory.yAxisName')}
                            </Label>
                        )}
                    </YAxis>

                    {categories.map((cat, i) => (
                        <Line
                            key={`lmc-${i}`}
                            className={cat.className}
                            dataKey={cat.id}
                            dot={drawPoints ? {r: 4, fill: cat.color, stroke: 0} : false}
                            isAnimationActive={false}
                            stroke={cat.color}
                            type="monotone"
                        />
                    ))}

                    <Customized
                        key="tooltip"
                        component={
                            <VoronoiTooltip
                                onActivePointClick={drillIn}
                                onlyX={series.length === 1}
                                timezone={timezone}
                                titleDateFormat={
                                    interval === 'days' ? TOOLTIP_DAY_FORMAT : TOOLTIP_HOUR_FORMAT
                                }
                                tooltipUnit={tooltipUnit}
                            />
                        }
                    />

                    {peaksAugmented &&
                        peaksAugmented.map((peak, i) => (
                            <ReferenceDot
                                isFront={true}
                                label={(props) => renderPeakIcon(props, peak)}
                                key={`peak-${i}`}
                                x={peak.dateNumber}
                                y={peak.highestVolume}
                            />
                        ))}

                    <Legend
                        align="center"
                        height={49}
                        iconType="circle"
                        iconSize={12}
                        formatter={(value, entry, index) => (
                            <CustomLegend
                                content={categories[index].name || categories[index].id}
                            />
                        )}
                        margin={{top: 40}}
                        verticalAlign="bottom"
                    />
                </ReLineChart>
            </div>
        );
    },
);

LineChart.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            color: PropTypes.string,
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            name: PropTypes.string,
        }),
    ),
    drillInCard: PropTypes.func,
    interval: PropTypes.oneOf(['days', 'hours', 'months']), // only days and hours!
    peaks: PropTypes.array,
    series: PropTypes.arrayOf(
        PropTypes.shape({
            date: PropTypes.string,
            // and the values with it's id as key,
        }),
    ),
    showLegend: PropTypes.bool,
    timezone: PropTypes.string.isRequired,
    tooltipUnit: PropTypes.string,
};

export default LineChart;
