import {useCallback, useState} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import AddDataSourceDialog from 'hsi/containers/DataManagement/AddDataSourceDialog';

import Subtitle from './Subtitle';
import Table from 'hsi/containers/DataManagement/Table';
import Tabs from './Tabs';
import TitleBar from './TitleBar';

import useDataManagementConfig from 'hsi/hooks/useDataManagementConfig';
import useDataSourceCount from 'hsi/hooks/useDataSourceCount';
import useIsViewOnly from 'hsi/hooks/useIsViewOnly';
import useStyles from './styles';
import useUniqueId from 'hsi/hooks/useUniqueId';

import {T} from 'hsi/i18n';

const DataManagement = () => {
    const classes = useStyles();
    const {learnMoreLink, rootUrl, tabs} = useDataManagementConfig();
    const info = useDataSourceCount();

    const isViewOnly = useIsViewOnly();
    const titleDescId = useUniqueId(null, 'dataManagementTitleDesc');

    const [isAddDataSourceDialogOpen, setIsAddDataSourceDialogOpen] = useState(false);

    const handleAddDataSource = useCallback(() => {
        setIsAddDataSourceDialogOpen(true);
    }, []);

    const handleAddDataSourceClose = useCallback(() => {
        setIsAddDataSourceDialogOpen(false);
    }, []);

    if (isViewOnly) return <Redirect to="/" />;

    return (
        <div className={classes.container} data-testid="newDataManagement">
            <TitleBar
                handleAddDataSource={handleAddDataSource}
                isAddDataSourceEnabled={info?.sourcesRemaining > 0}
                learnMoreLink={learnMoreLink}
                titleDescId={titleDescId}
            />
            <Subtitle
                isLoading={info.isLoading}
                maxEntities={info.sourcesLimit}
                numEntities={info.sourcesCount}
            />
            <Tabs links={tabs} />
            <Switch>
                {tabs.map((dataSourceType: any) => (
                    <Route
                        exact
                        key={dataSourceType.id}
                        path={dataSourceType.url}
                        component={() => (
                            <Table
                                info={dataSourceType.info && T(dataSourceType.info)}
                                handleAddDataSource={handleAddDataSource}
                                label={T(dataSourceType.label)}
                                type={dataSourceType.id}
                            />
                        )}
                    />
                ))}
                <Route path={rootUrl} render={() => <Redirect to={tabs[0].url} />} />
            </Switch>
            <AddDataSourceDialog
                info={info}
                onClose={handleAddDataSourceClose}
                open={isAddDataSourceDialogOpen}
            />
        </div>
    );
};

export default DataManagement;
