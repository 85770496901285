import Tooltip from 'hsi/components/Tooltip';
import { polymorphicForwardRef } from 'hsi/types/react-polymorphic';
import format from 'hsi/utils/number/format';

export type CompactNumberProps = {
    value: number;
    locale?: string | string[];
    tooltip?: boolean;
};

export default polymorphicForwardRef<CompactNumberProps, 'span'>(function CompactNumber({
    as: Component = 'span',
    value,
    locale = 'en-US',
    tooltip = false,
    ...rest
}, ref) {
    const formattedNumber = format(value, 'number', locale);
    const compactFormattedNumber = format(value, 'compact', locale);
    const isDifferent = formattedNumber !== compactFormattedNumber;

    const content = isDifferent 
        ? <Component ref={ref} {...rest}>
            <span className="offscreen">{formattedNumber}</span>
            <span aria-hidden>{compactFormattedNumber}</span>
        </Component>
        : <Component ref={ref} {...rest}>{formattedNumber}</Component>

    return tooltip && isDifferent
        ? <Tooltip portal tooltip={format(value, 'number', locale)} noAria>{content}</Tooltip>
        : content
})