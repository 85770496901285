

import { forwardRef } from 'react';
import classNames from 'classnames';

//Components
import IconRouter from '../IconRouter';

//Other
import useStyles from './styles';
import {T} from 'hsi/i18n';

//Types
type AIChipProps = JSX.IntrinsicElements['span'];


//The component
export default forwardRef<HTMLSpanElement, AIChipProps>(function AIChip({className, "aria-label": ariaLabel = T('aiChip.a11yLabel'), ...rest}, ref) {
    const classes = useStyles();

    return <span className={classNames(classes.chip, className)} {...rest} ref={ref}>
        <span className="offscreen">{ariaLabel}</span>
       <IconRouter className={classes.icon} name="ai-chip" aria-hidden />
    </span>
});