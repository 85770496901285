import { makeStyles } from 'tss-react/mui';

import { isV2 } from 'hsi/types/theme';


export default makeStyles()(
    (theme, _params, classes) => {
        const v2 = isV2(theme);
        const {colors, fonts, elements} = theme;

        return {
            headerBar: {
                fontSize: '16px',
                minHeight: elements.navBarContainerHeight,
                display: 'flex',
                paddingRight: 7,
                marginBottom: '2px',
                alignItems: 'center',
                padding: '0 20px',
                backgroundColor: 'white',
            },

            backBtn: {
                alignSelf: 'flex-start',
                marginRight: '20px',
                marginTop: v2 ? 7 : 10,
                position: 'relative',
            },
            backBtnIcon: {
                width: 20,
                height: 20,
                fill: v2 ? colors.bgOverlayHeavy : '#2f3638',
                '&:hover': {
                    ...(v2 ? {fill: colors.accentActive} : {}),
                },
            },

            title: {
                ...(v2 ? fonts.headtitleStrong : {fontSize: '24px', fontWeight: 'bold'}),
                color: v2 ? colors.textDefault : colors.darkGrey100,
                padding: '0 32px 0 0',
                flex: '0 0 auto',
                alignSelf: 'center',
                margin: 0,
            },
        };
    }
);