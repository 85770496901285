import React, {useState, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';

import Button from 'hsi/components/Button';
import TextField from 'hsi/components/TextField';
import {logIn, setLoginLoading, linkUser} from 'hsi/actions/userActions';
import tns from 'hsi/i18n/tns';
import logo from 'hsi/img/logo-tab.svg';
import useStyles from './styles';

const t = tns('manualLogin');
const mapStateToProps = (state) => ({
    linkPending: !!state.user.linkPending,
    loginErrors: state.user.errors,
    loginErrorCode: state.user.errorCode,
    loginSubmitted: state.user.loginSubmitted,
});

const ManualLogin = ({
    dispatch,
    linkPending,
    loginErrors,
    loginErrorCode,
    loginSubmitted,
    classes,
    submitAction,
    btnPriority = 'primary',
}) => {
    classes = {...useStyles(), ...classes};
    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    const [error, setError] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const onSubmit = useCallback(
        ({user, pass}) => {
            if (user && pass) {
                dispatch(setLoginLoading(true));
                if (submitAction) {
                    dispatch(submitAction({user, pass}));
                } else {
                    dispatch((linkPending ? linkUser : logIn)({user, pass}));
                }
                setSubmitted(true);
            } else {
                setError({
                    title: t('somethingMissing'),
                    desc: t('somethingMissingDesc'),
                });
            }
        },
        [dispatch, linkPending, submitAction],
    );

    const onPressEnter = (ev) => ev.keyCode === 13 && onSubmit({user, pass});

    useEffect(() => {
        if (submitted && (loginErrors || loginErrorCode)) {
            if (loginErrors && loginErrors[0] === 'Auth error: Bad credentials') {
                setError({
                    title: t('badCredentials'),
                    desc: t('badCredentialsDesc'),
                    showMoreHelpInfo: false,
                });
            } else if (loginErrorCode === 'PRODUCT_DISABLED') {
                setError({
                    title: t('productDisabled'),
                    desc: t('productDisabledDesc'),
                    showMoreHelpInfo: false,
                });
            } else if (loginErrorCode === 'MISSING_CREDENTIALS') {
                setError(null);
            } else {
                setError({
                    title: t('genericError'),
                    desc: t('genericErrorDesc'),
                    showMoreHelpInfo: false,
                });
            }
        } else {
            setError(null);
        }
    }, [loginErrors, loginErrorCode, submitted]);

    return (
        <div className={classes.page}>
            <div className={classes.formContainer}>
                <img src={logo} alt="Brandwatch" className={classes.logo} />
                <form className={classes.form} onKeyDown={onPressEnter}>
                    <h2 className={classes.title}>{t(linkPending ? 'almostThere' : 'login')}</h2>
                    <div className={classes.subtitle}>
                        {t(linkPending ? 'linkUserSubtitle' : 'subtitle')}
                    </div>
                    <div className={classes.inputBox}>
                        <TextField
                            fullWidth
                            className={classes.textField}
                            inputProps={{
                                autoComplete: 'username',
                                'data-testid': 'login-username-input',
                            }}
                            label={t('email')}
                            value={user}
                            onChange={(e) => setUser(e.target.value)}
                            error={!!error}
                        />
                        <br />

                        <TextField
                            fullWidth
                            type="password"
                            className={classes.textField}
                            inputProps={{
                                autoComplete: 'current-password',
                                'data-testid': 'login-password-input',
                            }}
                            label={t('password')}
                            value={pass}
                            onChange={(e) => setPass(e.target.value)}
                            error={error}
                        />
                        <br />
                        <div className={classes.btnHolder}>
                            <Button
                                onClick={() => onSubmit({user, pass})}
                                priority={btnPriority}
                                data-testid="manual-login-button"
                            >
                                {t(linkPending ? 'getStarted' : 'login')}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default connect(mapStateToProps)(ManualLogin);
