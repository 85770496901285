import { useCallback, useEffect, useMemo, useState } from "react";



/**
 * Handles calculating pages, current page state & provides callbacks to update the value.
 * Does not actually apply any pagination to underlying data.
 * If totalItems is zero, page and pages will always be returned as zero. Page cannot be zero otherwise, 
 * and will always be limited to between 1 & pages
 * @param totalItems positive integer, representing the total number of items to be paginated
 * @param itemsPerPage positive non-zero integer, how many items per page
 * @param initialPage Optional positive non-zero integer for the initial page
 * @returns 
 */
export default function useManagedPagination(totalItems: number, itemsPerPage: number, initialPage: number = 1) {
    //ensure these are int values
    totalItems |= 0;
    itemsPerPage |= 0;
    initialPage |= 0;

    const pages = Math.ceil(Math.max(0, totalItems) / itemsPerPage);
    const [page, _setPage] = useState(() => clampPage(initialPage, pages));

    const setPage = useCallback((newPage: number) => {
        _setPage(clampPage(newPage, pages))
    }, [pages]);

    const nextPage = useCallback(() => {
        _setPage((page) => clampPage(page+1, pages));
    }, [pages]);

    const prevPage = useCallback(() => {
        _setPage((page) => clampPage(page-1, pages));
    }, [pages]);

    useEffect(() => {
        //If pages changes, need to check that current page value is still valid
        const newPage = clampPage(page, pages);

        //If newPage is different to current page, then current page is no longer valid, update page
        if(newPage !== page) {
            _setPage(newPage);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pages])

    return useMemo(() => ({
        page,
        pages,
        setPage,
        nextPage,
        prevPage,

        offset: (page - 1) * itemsPerPage,
        zeroIndexPage: page - 1,
    }), [itemsPerPage, nextPage, page, pages, prevPage, setPage]);
}

/**
 * Clamps page to within valid range. Page numbers begin from 1. Will only return 0 if pages = 0
 * @param page the requested page
 * @param pages the number of pages. Must be an int value
 * @returns the valid page number
 */
function clampPage(page: number, pages: number) {
    return Math.min(pages, Math.max(page, 1));
}