import {useCallback, useMemo} from 'react';
import cn from 'classnames';
import copy from 'copy-to-clipboard';

import Button from 'hsi/components/Button';
import PulseLoader from 'hsi/components/PulseLoader';
import TextField from 'hsi/components/TextField';

import {T} from 'hsi/i18n';

import useStyles from './styles';
import useAriaAnnounce from 'hsi/hooks/useAriaAnnounce';
import useUniqueId from 'hsi/hooks/useUniqueId';

type CopyFieldProps = {
    id?: string;
    className?: string;
    content?: string;
    error?: boolean;
    labelId: string;
    loading?: boolean;
    ariaCopiedAnnounce?: string;
};

const CopyField = ({
    id: _id,
    className,
    content,
    error,
    labelId,
    loading,
    ariaCopiedAnnounce = T('copyField.defaultAriaCopiedAnnounce'),
}: CopyFieldProps) => {
    const id = useUniqueId(_id);
    const classes = useStyles();
    const announce = useAriaAnnounce();

    const buttonContent = useMemo(() => {
        if (loading) {
            return <PulseLoader />;
        }

        if (error) {
            return T('oops');
        }

        return T('copy');
    }, [error, loading]);

    const onClick = useCallback(() => {
        if (content) {
            copy(content);
            announce(ariaCopiedAnnounce);
        }
    }, [announce, ariaCopiedAnnounce, content]);

    return (
        <div className={cn(classes.container, className)}>
            <div className={classes.txtContainer}>
                <TextField
                    id={id}
                    label={labelId}
                    value={content}
                    fullWidth
                    InputProps={{
                        classes: {
                            input: classes.inputElem,
                            notchedOutline: classes.txtOutline,
                        },
                        readOnly: true,
                    }}
                />
            </div>
            <Button
                className={classes.btn}
                disabled={!content || error || loading}
                onClick={onClick}
            >
                {buttonContent}
            </Button>
        </div>
    );
};

export default CopyField;
