import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => ({
    label: theme.v2
        ? {
              ...theme.fonts.bodyStrong,
              padding: 0,
          }
        : {
              fontSize: 16,
              fontWeight: theme.font.bold,
              padding: 0,
          },
    invisible: {
        ...theme.mixin.offscreen,
    },
    indent: {
        marginLeft: theme.v2 ? 24 : 29,
    },
}));
