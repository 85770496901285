import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

import EditReportModal from 'hsi/containers/Reports/EditReportModal';
import {LoadErrorMsg} from 'hsi/components/ErrorMessage';
import HeaderBar from 'hsi/components/HeaderBar';
import PulseLoader from 'hsi/components/PulseLoader';
import SortableTable from 'hsi/components/SortableTable';

import useConfig from 'hsi/hooks/useConfig';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useReports, {ReportsProvider} from 'hsi/hooks/useReports';

import makeStyles from '@mui/styles/makeStyles';

import {T} from 'hsi/i18n';

const useStyles = makeStyles((theme) => ({
    container: {
        justifyContent: 'flex-start',
    },
    title: {
        paddingTop: 0,
    },
    loading: {
        width: '40px',
        height: '40px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginLeft: '-20px',
    },
    content: {
        padding: 20,
    },
}));

const ReportsPage = () => {
    const cl = useStyles();
    const {reports: config} = useConfig();
    const {track} = useEventTrack();

    const {actions, isModalOpen, item, reports} = useReports();
    const {loadReports} = actions;

    useEffect(() => {
        loadReports();
    }, [loadReports]);

    const tableHeader = [
        {
            id: 'name',
            label: T('reports.header.name'),
            width: '25%',
        },
        {
            id: 'queryName',
            label: T('reports.header.queryName'),
            format: (queryName, item) => (
                <Link to={`/search/results/${item.projectId}/${item.queryId}`}>{queryName}</Link>
            ),
            width: '25%',
        },
        {
            id: 'timespan',
            label: T('reports.header.timespan'),
            format: (timespan) => T(`reports.timespanLabels.${timespan}`),
            width: '10%',
        },
        {
            id: 'nextRunAt',
            label: T('reports.header.nextRunAt'),
            format: (date) => moment.utc(date).format(config.tableDateFormat),
            width: '15%',
        },
        {
            id: 'active',
            label: T('reports.header.active'),
            format: (active, currentItem) =>
                item.loading?.id === currentItem.id
                    ? T('reports.status.loading')
                    : active
                    ? T('reports.status.active')
                    : T('reports.status.paused'),
            width: '10%',
        },
        {
            id: 'createdAt',
            label: T('reports.header.createdAt'),
            format: (date) => moment.utc(date).format(config.tableDateFormat),
            width: '15%',
        },
    ];

    const menuEntries = [
        {
            label: T('reports.actions.edit'),
            onClick: actions.openModal,
        },
        {
            formatLabel: (currentItem) =>
                currentItem.active ? T('reports.actions.pause') : T('reports.actions.resume'),
            onClick: actions.activateReport,
        },
        {
            label: T('reports.actions.delete'),
            onClick: actions.deleteReport,
        },
    ];

    if (reports?.loading && !reports?.error)
        return <PulseLoader className={cl.loading} size="large" />;

    return (
        <>
            <HeaderBar title={!reports.error && !reports.loading && T('reports.title')} backButtonUrl="/" />

            <div className={cl.content}>
                {reports.error ? (
                    <LoadErrorMsg line1={T('api.error2')} />
                ) : (
                    <SortableTable
                        caption={T('reports.tableLbL')}
                        defaultSort={tableHeader[0].id}
                        defaultSortDir="desc"
                        fields={tableHeader}
                        items={reports.data}
                        itemTypeName="Reports"
                        menuEntries={menuEntries}
                        onSorted={(sortData) =>
                            track('tableSorted', {
                                ...sortData,
                            })
                        }
                    />
                )}
            </div>

            <EditReportModal
                acceptButtonText={T('reports.editReportModal.editScheduleAcceptButton')}
                onAccept={actions.editReport}
                open={isModalOpen}
                titleText={T('reports.editReportModal.editScheduleTitle')}
            />
        </>
    );
};

const Reports = (props) => (
    <ReportsProvider {...props}>
        <ReportsPage {...props} />
    </ReportsProvider>
);

export default Reports;
