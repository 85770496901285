import makeStyles from '@mui/styles/makeStyles';

import {Theme, isV2} from 'hsi/types/theme';

const useStyles = makeStyles((theme: Theme, ...args) => {
    if (isV2(theme)) {
        return {
            queryEditorRoot: {
                width: '100%',
                display: 'flex',
                overflow: 'hidden',
                minHeight: 38,
                position: 'relative',
                '& .query-error': {
                    backgroundColor: theme.colors.primaryYellow40,
                },
            },
            queryEditor: {
                minHeight: 38,
                margin: 'auto',
                '& .monaco-editor .detected-link': {
                    textDecoration: 'none',
                },
                '& .monaco-editor .decorationsOverviewRuler': {
                    width: '0.3rem !important',
                },
                '& .monaco-editor .monaco-editor-hover-content': {
                    textAlign: 'left',
                    fontFamily: 'Roboto',
                    width: '18rem',
                    color: '#3f3f3f',
                    maxHeight: 'unset !important',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'left',
                    background: 'white',
                    padding: '16px',
                },
                '& .monaco-editor .monaco-editor-hover .hover-row:not(:first-child):not(:empty)': {
                    borderTop: 'unset',
                },
                '& .monaco-editor .monaco-editor-hover .hover-row': {
                    borderTop: 'none !important',
                },
                '& .monaco-editor .monaco-editor-hover .markdown-hover': {
                    backgroundColor: 'white !important',
                    fontSize: '13px !important',
                    lineHeight: '20px !important',
                    fontFamily: 'Roboto !important',
                    padding: '0 !important',
                },
                '& .monaco-editor .monaco-editor-hover .markdown-hover img': {
                    width: 40,
                    height: 40,
                    objectFit: 'contain',
                    borderRadius: '4px',
                },
                '& .monaco-editor .monaco-editor-hover .marker': {
                    padding: '1rem',
                    backgroundColor: '#efefef',
                    fontSize: '13px !important',
                    lineHeight: '20px !important',
                    fontFamily: 'Roboto !important',
                },
                '& .monaco-editor .monaco-editor-hover .hover-row.status-bar': {
                    display: 'none',
                },
                '& .monaco-editor .bracket-match': {
                    border: '1px solid #239edb',
                    backgroundColor: 'unset',
                },
                '& .monaco-editor .suggest-widget': {
                    width: '23rem',
                },
                '& .monaco-editor .suggest-widget .message': {
                    backgroundColor: '#efefef !important',
                },
                '& .monaco-editor .suggest-widget .monaco-list': {
                    backgroundColor: '#efefef',
                },
            },
            placeholder: {
                fontSize: theme.fonts.bodyNormal.fontSize,
                fontWeight: theme.fonts.bodyNormal.fontWeight,
                //fontFamily: 'Monaco, Menlo, monospace',
                fontStyle: 'normal',
                color: theme.colors.textDisabled,
                position: 'absolute',
                top: '13px',
                left: '16px',
                zIndex: 10,
                pointerEvents: 'none',
            },
            hlText: {
                fontSize: theme.fonts.bodyNormal.fontSize,
                fontWeight: theme.fonts.bodyNormal.fontWeight,

                //For some reason, monaco add an apparently pointless <br /> on the end of formatted text, which 
                //again, for some unknown reason voiceover on mac reads this out as 'empty group'. This fixes that.
                '& > br:last-child': {
                    display: 'none',
                },
            },
            hlTextEllipsis: {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            },
            highlightEllipsisOverlay: {
                pointerEvents: 'none',
                position: 'absolute',
                left: 16,
                right: 0,
                zIndex: 10,
                backgroundColor: 'white',
                top: ({padding}: any) => {
                    return `${padding.top}px`;
                },
                minHeight: ({lineHeight}: any) => {
                    return `${lineHeight}px`;
                },
                lineHeight: ({lineHeight}: any) => {
                    return `${lineHeight}px`;
                },
                fontFamily: ({fontFamily}: any) => {
                    return fontFamily;
                }
            },
        };
    } else {
        return {
            queryEditorRoot: {
                width: '100%',
                display: 'flex',
                overflow: 'hidden',
                minHeight: 42,
                position: 'relative',
                backgroundColor: 'white',
                //padding: '12px 0',
                '& .query-error': {
                    backgroundColor: theme.colors.primaryYellow40,
                },
            },
            queryEditor: {
                minHeight: 42,
                margin: 'auto',
                '& .monaco-editor .detected-link': {
                    textDecoration: 'none',
                },
                '& .monaco-editor .decorationsOverviewRuler': {
                    width: '0.3rem !important',
                },
                '& .monaco-editor .monaco-editor-hover-content': {
                    textAlign: 'left',
                    fontFamily: 'Roboto',
                    width: '18rem',
                    color: '#3f3f3f',
                    maxHeight: 'unset !important',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'left',
                    background: 'white',
                    padding: '16px',
                },
                '& .monaco-editor .monaco-editor-hover .hover-row:not(:first-child):not(:empty)': {
                    borderTop: 'unset',
                },
                '& .monaco-editor .monaco-editor-hover .hover-row': {
                    borderTop: 'none !important',
                },
                '& .monaco-editor .monaco-editor-hover .markdown-hover': {
                    backgroundColor: 'white !important',
                    fontSize: '13px !important',
                    lineHeight: '20px !important',
                    fontFamily: 'Roboto !important',
                    padding: '0 !important',
                },
                '& .monaco-editor .monaco-editor-hover .markdown-hover img': {
                    width: 40,
                    height: 40,
                    objectFit: 'contain',
                    borderRadius: '4px',
                },
                '& .monaco-editor .monaco-editor-hover .marker': {
                    padding: '1rem',
                    backgroundColor: '#efefef',
                    fontSize: '13px !important',
                    lineHeight: '20px !important',
                    fontFamily: 'Roboto !important',
                },
                '& .monaco-editor .monaco-editor-hover .hover-row.status-bar': {
                    display: 'none',
                },
                '& .monaco-editor .bracket-match': {
                    border: '1px solid #239edb',
                    backgroundColor: 'unset',
                },
                '& .monaco-editor .suggest-widget': {
                    width: '23rem',
                },
                '& .monaco-editor .suggest-widget .message': {
                    backgroundColor: '#efefef !important',
                },
                '& .monaco-editor .suggest-widget .monaco-list': {
                    backgroundColor: '#efefef',
                },
            },
            placeholder: {
                fontSize: '16px',
                //fontFamily: 'Monaco, Menlo, monospace',
                fontStyle: 'italic',
                color: theme.colors.darkGrey60,
                position: 'absolute',
                top: 9,
                left: 16,
                zIndex: 10,
                pointerEvents: 'none',
            },
            hlText: {
                fontSize: '15px',
                fontFamily: 'Monaco, Menlo, monospace',

                //For some reason, monaco add an apparently pointless <br /> on the end of formatted text, which 
                //again, for some unknown reason voiceover on mac reads this out as 'empty group'. This fixes that.
                '& > br:last-child': {
                    display: 'none',
                },
            },
            hlTextEllipsis: {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            },
            highlightEllipsisOverlay: {
                pointerEvents: 'none',
                position: 'absolute',
                left: 16,
                right: 0,
                top: 10,
                bottom: 11,
                zIndex: 10,
                backgroundColor: 'white',
            },
        };
    }
});

export default useStyles;
