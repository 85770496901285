import React, {useMemo, useState, useEffect, useCallback} from 'react';
import cn from 'classnames';

import IconButton from '@mui/material/IconButton';

import Dialog, {DialogActions, DialogContent} from 'hsi/components/Dialog';
import Avatar from 'hsi/components/Avatar';
import ConfirmDialog from 'hsi/components/ConfirmDialog';
import IconRouter from 'hsi/components/IconRouter';
import PulseLoader from 'hsi/components/PulseLoader';
import RenderNativeMention from '../RenderNativeMention';
import EmotionMenuContent from './EmotionMenuContent';
import LocationMenuContent from './LocationMenuContainer';
import {SentimentMenuContent} from 'hsi/components/Mention/SentimentMenu';
import {OverflowTooltip} from 'hsi/components/SimpleTooltip/';
import Tags from 'hsi/components/Mention/Tags';
import Time from 'hsi/components/Time';
import WithMenuAndTooltip from '../WithMenuAndTooltip';
import MetricListItem from './MetricsListItem';

import {T} from 'hsi/i18n';
import useStyles from './styles';

//Hooks
import useEventTrack from 'hsi/hooks/useEventTrack';
import useProjectId from 'hsi/hooks/useProjectId';
import {useAppDispatch} from 'hsi/hooks/useRedux';

import {useAuthorDetails, iconFromMentionData} from '../utils';
import normalize from 'hsi/components/Mention/normalize';

import {EditMentionType, deleteMentions} from 'hsi/slices/mentions';

import {formatBigInt} from 'hsi/utils/formatNumber';

//Types
import {MentionType, isSavedSearchMention} from 'hsi/types/mentions';
import {PopoverRenderFunc} from 'hsi/components/Popover';
import {PaperProps} from '@mui/material';
import Heading, { HeadingContents } from 'hsi/components/aria/Heading';

type SingleMentionViewProps = {
    open?: boolean;
    mention?: MentionType;
    editMentionStatus?: EditMentionType;
    //isSavedSearch: boolean;//do I need this?
    editable?: boolean;
    closeFunc: () => void;
    nextMention?: () => void;
    prevMention?: () => void;
    loading?: boolean;
    onViewOriginalClick?: () => void;
};

const SingleMentionView = ({
    open = false,
    mention: _mention,
    editMentionStatus,
    editable = false,
    closeFunc,
    nextMention,
    prevMention,
    loading = false,
    onViewOriginalClick,
}: SingleMentionViewProps) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const {trackWithSearchData} = useEventTrack();
    const projectId = useProjectId()!;

    const [mention, setMention] = useState(_mention); //need to cache last mention for transition effect to work
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

    const isSavedSearch = isSavedSearchMention(mention);

    //Memoised data
    const mentionData = useMemo(() => (mention ? normalize(mention) : null), [mention]);

    const {name, handle} = useAuthorDetails(mentionData, classes);

    const locationMenuContent = useMemo<PopoverRenderFunc | undefined>(
        () =>
            editable && mention && isSavedSearch
                ? ({labelId, descriptionId, close}) => (
                      <LocationMenuContent
                          mention={mention}
                          close={close}
                          labelId={labelId}
                          descriptionId={descriptionId}
                      />
                  )
                : undefined,
        [editable, mention, isSavedSearch],
    );

    const emotionMenuContent = useMemo<PopoverRenderFunc | undefined>(
        () =>
            editable && mention && isSavedSearch
                ? ({labelId, descriptionId, close}) => (
                      <EmotionMenuContent
                          mention={mention}
                          close={close}
                          labelId={labelId}
                          descriptionId={descriptionId}
                      />
                  )
                : undefined,
        [editable, isSavedSearch, mention],
    );

    const sentimentMenuContent = useMemo<PopoverRenderFunc | undefined>(
        () =>
            editable && mention && isSavedSearch
                ? ({labelId, descriptionId, close}) => (
                      <SentimentMenuContent
                          labelId={labelId}
                          descriptionId={descriptionId}
                          close={close}
                          value={mentionData?.sentiment!}
                          mention={mention}
                      />
                  )
                : undefined,
        [editable, isSavedSearch, mention, mentionData?.sentiment],
    );

    //Callbacks
    const onDeleteClick = useCallback(() => setConfirmDeleteOpen(true), [setConfirmDeleteOpen]);

    const closeConfirmDelete = useCallback(
        () => setConfirmDeleteOpen(false),
        [setConfirmDeleteOpen],
    );

    //Side effects
    useEffect(() => {
        //retain a reference to the last supplied mention - needed for transitions, etc
        _mention && setMention(_mention);
    }, [_mention]);

    if (!mention || !mentionData) {
        return null;
    }

    return <>
        <Dialog
            id="singleMentionView"
            open={open}
            className={classes.singleMentionView}
            maxWidth={false}
            PaperProps={{'data-testid': 'singleMentionView'} as PaperProps}
            onClose={closeFunc}
            title={T('singleMentionView.title')}
        >
            <DialogContent>
                <div className={classes.root} inert={loading ? 'inert' : undefined}>
                    {loading && (
                        <div className={classes.loadingHolder}>
                            <PulseLoader size="medium" />
                        </div>
                    )}
                    <div className={classes.leftCol}>
                        <div className={classes.seeOriginalAndDelete}>
                            <a
                                className={classes.seeOriginalLink}
                                href={mention.url}
                                onClick={onViewOriginalClick}
                                rel="noreferrer"
                                target="_blank"
                            >
                                <IconRouter name="open" className={classes.seeOriginalIcon} />
                                <span>{T('singleMentionView.seeOriginal')}</span>
                            </a>
                            {isSavedSearch && (
                                <WithMenuAndTooltip
                                    tooltip={
                                        <span>{T('singleMentionView.deleteMention')}</span>
                                    }
                                >
                                    <IconButton onClick={onDeleteClick} className={classes.deleteBtn} size="large">
                                        <IconRouter
                                            name="delete"
                                            className={classes.deleteBtnIcon}
                                        />
                                        <span className="offscreen">
                                            {T('singleMentionView.deleteMention')}
                                        </span>
                                    </IconButton>
                                </WithMenuAndTooltip>
                            )}
                        </div>
                        <RenderNativeMention mention={mention} key={mentionData.url} />
                    </div>
                    <div className={classes.rightCol}>
                        <Heading className={classes.sectionTitle}>
                            {mentionData.hasAuthor
                                ? T('singleMentionView.author')
                                : T('singleMentionView.source')}
                        </Heading>
                        <HeadingContents>
                            <div className={classes.source}>
                                <div className={classes.avatar}>
                                    <Avatar
                                        src={mentionData.avatar ?? undefined}
                                        platformIcon={iconFromMentionData(mentionData)}
                                        url={mentionData.authorUrl ?? undefined}
                                        showMiniIcon
                                    />
                                </div>
                                <div className={classes.sourceAndHandle}>
                                    {name && (
                                        <OverflowTooltip tooltip={name}>
                                            <div className={classes.sourceName}>{name}</div>
                                        </OverflowTooltip>
                                    )}

                                    {handle && (
                                        <OverflowTooltip tooltip={handle}>
                                            <div className={classes.sourceHandle}>{handle}</div>
                                        </OverflowTooltip>
                                    )}
                                </div>
                            </div>

                            {isSavedSearch && mention.pageType === 'twitter' && (
                                <dl className={cn(classes.metricsList, classes.singleColMarginTop)}>
                                    <MetricListItem name={T('singleMentionView.tweets')}>
                                        {formatBigInt(mention.twitterPostCount)}
                                    </MetricListItem>
                                    <MetricListItem name={T('singleMentionView.followers')}>
                                        {formatBigInt(mention.twitterFollowers)}
                                    </MetricListItem>
                                    <MetricListItem name={T('singleMentionView.following')}>
                                        {formatBigInt(mention.twitterFollowing)}
                                    </MetricListItem>
                                </dl>
                            )}
                        </HeadingContents>

                        <hr className={classes.hr} />

                        <Heading className={classes.sectionTitle}>{T('singleMentionView.post')}</Heading>
                        <HeadingContents>
                            <dl
                                className={classes.metricsList}
                                data-testid="singleMentionViewDefinitionList"
                            >
                                <MetricListItem name={T('singleMentionView.date')}>
                                    <Time format="MMM d, h:mm a ZZZZ" value={mentionData.date} />
                                </MetricListItem>
                                <MetricListItem
                                    name={T('singleMentionView.location')}
                                    menu={locationMenuContent}
                                    disabled={editMentionStatus?.loading}
                                    pending={editMentionStatus?.location}
                                >
                                    <span data-testid="singleMentionViewLocation">
                                        {mentionData.location || T('singleMentionView.n/a')}
                                    </span>
                                </MetricListItem>
                                <MetricListItem name={T('singleMentionView.language')}>
                                    {mentionData.language || T('singleMentionView.n/a')}
                                </MetricListItem>
                                {isSavedSearch && (
                                    <MetricListItem
                                        name={T('singleMentionView.sentiment')}
                                        disabled={editMentionStatus?.loading}
                                        pending={editMentionStatus?.sentiment}
                                        menu={sentimentMenuContent}
                                    >
                                        <span className={classes.iconAndText}>
                                            <IconRouter
                                                name={`emoticon-${mentionData.sentiment}`}
                                                className={cn(
                                                    classes.sentimentIcon,
                                                    classes[mentionData.sentiment!],
                                                )}
                                            />
                                            <span
                                                className={classes.iconLabel}
                                                data-testid="singleMentionViewSentiment"
                                            >
                                                {T(`sentiments.${mentionData.sentiment}`)}
                                            </span>
                                        </span>
                                    </MetricListItem>
                                )}
                                {isSavedSearch && (
                                    <MetricListItem
                                        name={T('singleMentionView.emotion')}
                                        menu={emotionMenuContent}
                                        disabled={editMentionStatus?.loading}
                                        pending={editMentionStatus?.emotion}
                                    >
                                        {mentionData.emotion ? (
                                            mentionData.emotion.map((emotion, index) => (
                                                <React.Fragment key={emotion}>
                                                    {index !== 0 && (
                                                        <>
                                                            ,<br />
                                                        </>
                                                    )}
                                                    <span className={classes.iconAndText}>
                                                        <span
                                                            className={cn(
                                                                classes.emotionIcon,
                                                                classes[
                                                                    `emotion_${emotion.toLowerCase()}`
                                                                ],
                                                            )}
                                                        />
                                                        <span
                                                            className={classes.iconLabel}
                                                            data-testid="singleMentionViewEmotion"
                                                        >
                                                            {T(`emotions.${emotion.toLowerCase()}`)}
                                                        </span>
                                                    </span>
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <span className={classes.iconAndText}>
                                                <IconRouter
                                                    name="ban"
                                                    className={classes.emotionNAIcon}
                                                    aria-hidden
                                                />
                                                <span
                                                    className={classes.iconLabel}
                                                    data-testid="singleMentionViewEmotion"
                                                >
                                                    {T('singleMentionView.n/a')}
                                                </span>
                                            </span>
                                        )}
                                    </MetricListItem>
                                )}
                                {isSavedSearch && (
                                    <MetricListItem name={T('singleMentionView.reach')}>
                                        {mentionData.reachEstimate
                                            ? formatBigInt(mentionData.reachEstimate)
                                            : T('singleMentionView.n/a')}
                                    </MetricListItem>
                                )}

                                {isSavedSearch && mention.pageType === 'twitter' && (
                                    <>
                                        <MetricListItem name={T('singleMentionView.retweets')}>
                                            {formatBigInt(mention.twitterRetweets)}
                                        </MetricListItem>
                                        <MetricListItem name={T('singleMentionView.impressions')}>
                                            {formatBigInt(mention.impressions)}
                                        </MetricListItem>
                                        <MetricListItem name={T('singleMentionView.replies')}>
                                            {formatBigInt(mention.twitterReplyCount)}
                                        </MetricListItem>
                                    </>
                                )}

                                {isSavedSearch && mention.pageType === 'instagram' && (
                                    <>
                                        <MetricListItem name={T('singleMentionView.interactions')}>
                                            {formatBigInt(mention.instagramInteractionsCount)}
                                        </MetricListItem>
                                        <MetricListItem name={T('singleMentionView.likes')}>
                                            {formatBigInt(mention.instagramLikeCount)}
                                        </MetricListItem>
                                        <MetricListItem name={T('singleMentionView.comments')}>
                                            {formatBigInt(mention.instagramCommentCount)}
                                        </MetricListItem>
                                    </>
                                )}

                                {isSavedSearch && mention.pageType === 'facebook' && (
                                    <>
                                        <MetricListItem name={T('singleMentionView.likes')}>
                                            {formatBigInt(mention.facebookLikes)}
                                        </MetricListItem>
                                        <MetricListItem name={T('singleMentionView.comments')}>
                                            {formatBigInt(mention.facebookComments)}
                                        </MetricListItem>
                                    </>
                                )}

                                {isSavedSearch && mention.pageType === 'reddit' && (
                                    <>
                                        <MetricListItem name={T('singleMentionView.redditScore')}>
                                            {formatBigInt(mention.redditScore!)}
                                        </MetricListItem>
                                    </>
                                )}
                            </dl>
                        </HeadingContents>

                        {isSavedSearch && (
                            <>
                                <hr className={classes.hr} />

                                <Heading className={classes.sectionTitle}>
                                    {T('singleMentionView.tags')}
                                </Heading>
                                <HeadingContents>
                                    <Tags
                                        mention={mention}
                                        tags={mention.tags}
                                        editable={editable}
                                        disabled={editMentionStatus?.loading}
                                        pending={editMentionStatus?.tags}
                                        ellipsis={false}
                                        size="small"
                                    />
                                </HeadingContents>
                            </>
                        )}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <div className={classes.navigation} inert={loading ? 'inert' : undefined}>
                    <IconButton
                        className={cn(classes.navBtn, classes.leftArrow)}
                        onClick={prevMention}
                        disabled={!prevMention}
                        size="large">
                        <IconRouter
                            name="large-right-arrow"
                            className={cn(classes.navBtnIcon, classes.leftArrowIcon)}
                        />
                        <span className="offscreen">{T('singleMentionView.prevMention')}</span>
                    </IconButton>
                    <IconButton
                        className={cn(classes.navBtn, classes.rightArrow)}
                        onClick={nextMention}
                        disabled={!nextMention}
                        size="large">
                        <IconRouter
                            name="large-right-arrow"
                            className={cn(classes.navBtnIcon, classes.rightArrowIcon)}
                        />
                        <span className="offscreen">{T('singleMentionView.nextMention')}</span>
                    </IconButton>
                </div>
            </DialogActions>
        </Dialog>

        {isSavedSearch && (
            <ConfirmDialog
                key={mentionData.id}
                open={confirmDeleteOpen}
                closeFunc={closeConfirmDelete}
                confirmFunc={() => {
                    trackWithSearchData('mentionDeleted', {
                        mention: mentionData.id,
                    });
                    return dispatch(deleteMentions({mentions: [mention], projectId})).then(
                        closeConfirmDelete,
                    );
                }}
                confirmLabel={T('singleMentionView.delete.confirm')}
                title={T('singleMentionView.delete.title')}
                body={T('singleMentionView.delete.body')}
                showCannotUndo
                type="warning"
            />
        )}
    </>;
};
export default SingleMentionView;
