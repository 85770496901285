import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({colors}) => ({
    lineMarkChartRecharts: {
        cursor: 'pointer !important',

        '& .recharts-cartesian-grid': {
            '& line': {
                opacity: 0.2,
                strokeDasharray: '8px',
                stroke: `${colors.keyline} !important`,
            },
        },
        '& .recharts-legend-wrapper': {
            left: `0 !important`,
            cursor: 'default',
        },
        '& .recharts-yAxis': {
            textTransform: 'uppercase',
            '& .recharts-label': {
                fontSize: '12px',
                fill: `${colors.textMeta} !important`,
                fontWeight: 'normal',
                textAnchor: 'middle !important',
            },
        },
        '& .recharts-xAxis': {
            '& .recharts-cartesian-axis-tick-value': {
                fill: colors.primaryBlue100,
            },
        },
        '& .recharts-cartesian-axis-tick-value': {
            fontSize: '12px',
        },
        '& .recharts-line-curve': {
            strokeWidth: '2 !important',
        },
        '& .recharts-reference-dot': {
            '& circle': {
                display: 'none',
            },
            '& .peak-label': {
                cursor: 'pointer',
            },
        },
    },
}));
