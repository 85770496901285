import {
    AuthenticationDataTypes,
    AuthenticationItem,
    Data,
    DataResponse,
    FacebookPageItem,
    FacebookPageValidationItem,
    Item,
    InstagramAccountItem,
    InstagramAccountValidationItem,
    InstagramHashtagItem,
    LinkedinAuthenticationItem,
    LinkedinChannelItem,
    TargetedDataDataTypes,
    ValidationResponse,
} from 'hsi/types/dataManagement';

export const emptyData = {
    summary: {
        number: 0,
        size: 0,
        totalElements: 0,
        totalPages: 0,
    },
    items: [],
};

// FACEBOOK PAGES
/** Example data inputs
 * [Get response](hsi/test-utils/mockData/targetedDataService/getFacebookPages.json)
 * [Add response](TBC)
 * [Delete response](hsi/test-utils/mockData/targetedDataService/deleteFacebookPage.json)
 * [Validation response](hsi/test-utils/mockData/targetedDataService/findFacebookPages.json)
 */
const toFacebookPage = (page: any): FacebookPageItem => ({
    active: page.authenticated && page.enabled,
    authenticated: page.authenticated,
    created: page.created,
    enabled: page.enabled,
    id: String(page.pageId),
    name: page.name,
    owned: page.owned,
    type: TargetedDataDataTypes.FacebookPage,
    username: page?.username,
});

const facebookPagesListParser = (data: any): Data => {
    const summary = data?.page;
    const pages = data?._embedded?.trackedPageDTOList;

    if (!summary || !summary.totalElements || !pages || !Array.isArray(pages)) {
        return emptyData;
    }

    return {
        summary: {
            number: summary.number,
            size: summary.size,
            totalElements: summary.totalElements,
            totalPages: summary.totalPages,
        },
        items: pages.map((page) => toFacebookPage(page)),
    };
};

const facebookPagesResponseParser = (data: any, _inputItem: Item): DataResponse => {
    const responses = data?._embedded?.trackedPageResponseDTOList;

    if (!responses || !Array.isArray(responses)) {
        return {succeeded: [], failed: []};
    }

    return {
        succeeded: responses
            .filter((response: any) => response.succeeded)
            .map((response: any) => toFacebookPage(response.trackedPageDTO)),
        failed: responses
            .filter((response: any) => !response.succeeded)
            .map((response: any) => toFacebookPage(response.trackedPageDTO)),
    };
};

const toFacebookPageValidation = (response: any): FacebookPageValidationItem => ({
    errorCode: response?.errorCode,
    failureReason: response?.failureReason,
    id: response?.page?.pageId,
    name: response?.page?.name,
    succeeded: response.succeeded,
    type: TargetedDataDataTypes.FacebookPage,
    value: response.searchParam,
});

const facebookPagesValidationParser = (data: any): ValidationResponse => {
    const responses = data;

    if (!responses || !Array.isArray(responses)) {
        return {succeeded: [], failed: []};
    }

    return {
        succeeded: responses
            .filter((response: any) => response.succeeded)
            .map((response: any) => toFacebookPageValidation(response)),
        failed: responses
            .filter((response: any) => !response.succeeded)
            .map((response: any) => toFacebookPageValidation(response)),
    };
};

// INSTAGRAM ACCOUNTS
/** Example data inputs
 * [Get response](TBC)
 * [Add response](TBC)
 * [Delete response](hsi/test-utils/mockData/targetedDataService/deleteInstagramAccount.json)
 * [Validation response](hsi/test-utils/mockData/targetedDataService/findInstagramHashtags.json)
 */
const toInstagramAccount = (account: any): InstagramAccountItem => ({
    active: account.authenticated && account.enabled,
    authenticated: account.authenticated,
    created: account.created,
    displayName: account?.igbaDisplayName,
    enabled: account.enabled,
    hashtagsRemaining: account.hashtagsRemaining,
    id: String(account.instagramBusinessAccountId),
    name: account.igbaHandle,
    owned: account.owned,
    type: TargetedDataDataTypes.InstagramAccount,
});

const instagramAccountsListParser = (data: any): Data => {
    const summary = data?.page;
    const accounts = data?._embedded?.trackedAccountDTOList;

    if (!summary || !summary.totalElements || !accounts || !Array.isArray(accounts)) {
        return emptyData;
    }

    return {
        summary: {
            number: summary.number,
            size: summary.size,
            totalElements: summary.totalElements,
            totalPages: summary.totalPages,
        },
        items: accounts.map((account) => toInstagramAccount(account)),
    };
};

const instagramAccountsResponseParser = (data: any, _inputItem: Item): DataResponse => {
    const responses = data?._embedded?.responseDTOList;

    return {
        succeeded: responses
            .filter((response: any) => response.succeeded)
            .map((response: any) => toInstagramAccount(response.dto)),
        failed: responses
            .filter((response: any) => !response.succeeded)
            .map((response: any) => toInstagramAccount(response.dto)),
    };
};

const toInstagramAccountValidation = (response: any): InstagramAccountValidationItem => ({
    errorCode: response?.statusCode,
    failureReason: response?.message,
    id: response?.dto?.instagramBusinessAccountId,
    name: response?.igbaDisplayName || response?.igbaHandle,
    succeeded: response.succeeded,
    type: TargetedDataDataTypes.InstagramAccount,
    value: response.dto.igbaHandle,
});

const instagramAccountsValidationParser = (data: any): ValidationResponse => {
    const responses = data;

    return {
        succeeded: responses
            .filter((response: any) => response.succeeded)
            .map((response: any) => toInstagramAccountValidation(response)),
        failed: responses
            .filter((response: any) => !response.succeeded)
            .map((response: any) => toInstagramAccountValidation(response)),
    };
};

// INSTAGRAM HASHTAGS
/** Data examples
 * [Get response](TBC)
 * [Add response](hsi/test-utils/mockData/targetedDataService/addInstagramHashtags.json)
 * [Delete response](hsi/test-utils/mockData/targetedDataService/deleteInstagramHashtag.json)
 * [Validation response](No api for this yet)
 */
const toInstagramHashtag = (hashtag: any): InstagramHashtagItem => ({
    active: hashtag.authenticated && hashtag.enabled,
    authenticated: hashtag.authenticated,
    created: hashtag.created,
    enabled: hashtag.enabled,
    id: String(hashtag.graphHashtagId),
    keyId: `${hashtag.graphHashtagId}-${hashtag.instagramBusinessAccountId}`,
    linkedAccount: hashtag.igbaHandle,
    linkedAccountId: hashtag.instagramBusinessAccountId,
    name: hashtag.hashtag,
    type: TargetedDataDataTypes.InstagramHashtag,
});

const instagramHashtagsListParser = (data: any): Data => {
    const summary = data?.page;
    const hashtags = data?._embedded?.hashtagDTOList;

    if (!summary || !summary.totalElements || !hashtags || !Array.isArray(hashtags)) {
        return emptyData;
    }

    return {
        summary: {
            number: summary.number,
            size: summary.size,
            totalElements: summary.totalElements,
            totalPages: summary.totalPages,
        },
        items: hashtags.map((hashtag) => toInstagramHashtag(hashtag)),
    };
};

const instagramHashtagsResponseParser = (data: any, inputItem: Item): DataResponse => {
    const addResponses = data?._embedded?.responseDTOList;
    if (addResponses) {
        return {
            succeeded: addResponses
                .filter((response: any) => response.succeeded)
                .map((response: any) => toInstagramHashtag(response.dto)),
            failed: addResponses
                .filter((response: any) => !response.succeeded)
                .map((response: any) => toInstagramHashtag(response.dto)),
        };
    }

    // Currently only support single deletes
    const deleteResponse = data;

    return {
        succeeded: deleteResponse.succeeded ? [inputItem] : [],
        failed: !deleteResponse.succeeded ? [inputItem] : [],
    };
};

// LINKEDIN CHANNELS
/** Example data inputs
 * [Get response](TBC)
 * [Add response](TBC)
 * [Delete response](hsi/test-utils/mockData/targetedDataService/deleteLinkedinChannel.json)
 * [Validate response](Not supported)
 */
const toLinkedinChannel = (channel: any): LinkedinChannelItem => ({
    active: channel.authorised,
    authenticated: channel.authorised,
    created: channel.creationDate,
    enabled: channel.authorised,
    id: String(channel.id),
    name: channel.name,
    projectId: String(channel.projectId),
    type: TargetedDataDataTypes.LinkedinChannel,
});

const linkedinChannelsListParser = (data: any): Data => {
    const channels = data;

    if (!channels || !Array.isArray(channels)) {
        return emptyData;
    }

    return {
        summary: {
            number: 0,
            size: channels.length,
            totalElements: channels.length,
            totalPages: 1,
        },
        items: channels.map((channel) => toLinkedinChannel(channel)),
    };
};

const linkedinChannelsResponseParser = (_data: any, inputItem: Item): DataResponse => {
    return {
        succeeded: [inputItem],
        failed: [],
    };
};

// FACEBOOK USER AUTHENTICATIONS (AKA CONNECTIONS)
/** Example data inputs
 * [Get response](TBC)
 * [Add response](hsi/test-utils/mockData/facebookTokenService/storeUserToken.json)
 * [Delete response](hsi/test-utils/mockData/targetedDataService/deleteFacebookUserAuthentications.json)
 * [Validate response](Not supported)
 */
const toFacebookUserAuthentication = (authentication: any): AuthenticationItem => ({
    active: authentication.authenticated,
    authenticated: authentication.authenticated,
    created: authentication.created,
    enabled: authentication.authenticated,
    id: String(authentication.facebookUserId),
    name: authentication.facebookUserName,
    email: authentication.facebookUserEmail,
    type: AuthenticationDataTypes.FacebookUserAuthentication,
});

const facebookUserAuthenticationsListParser = (data: any): Data => {
    const summary = data?.page;
    const authentications = data?._embedded?.userAuthenticationSummaryDTOList;

    if (!summary || !summary.totalElements || !authentications || !Array.isArray(authentications)) {
        return emptyData;
    }

    return {
        summary: {
            number: summary.number,
            size: summary.size,
            totalElements: summary.totalElements,
            totalPages: summary.totalPages,
        },
        items: authentications.map((authentication) =>
            toFacebookUserAuthentication(authentication),
        ),
    };
};

// TODO: This response will change as we won't be getting the response from
// storeUserToken but from addFacebookAuthentications
// We need to make sure that it handles delete and add methods correctly

const facebookUserAuthenticationsResponseParser = (data: any, inputItem: Item): DataResponse => {
    return {
        succeeded: [inputItem],
        failed: [],
    };
};

// LINKEDIN CHANNEL AUTHENTICATION
/** Example data inputs
 * [Get response](TBC)
 * [Add response](Not supported)
 * [Delete response](Not supported)
 * [Validate response](Not supported)
 */
const toLinkedinChannelAuthentication = (d: any): LinkedinAuthenticationItem => ({
    active: d?.authenticated,
    authenticated: d?.authenticated,
    created: new Date().toISOString(), //no info about date for linkedin
    enabled: d?.authenticated,
    id: d?.uuid,
    name: d?.userName,
    //platform: TargetedDataPlatform.Linkedin,
    //type: AuthenticationDataTypes.LinkedinChannelAuthentication,
});

const linkedinAuthenticationsListParser = (data: any): Data => {
    const items = data.map(toLinkedinChannelAuthentication);
    return {
        items,
        summary: {
            number: 0,
            size: items.length,
            totalElements: items.length,
            totalPages: 1,
        },
    };
};

//dummy parser to pass type check, no mutations for linkedin
const linkedinAuthenticationsResponseParser = (data: any, inputItem: Item): DataResponse => ({
    succeeded: [],
    failed: [],
});

export const parsers = {
    [TargetedDataDataTypes.FacebookPage]: {
        list: facebookPagesListParser,
        response: facebookPagesResponseParser,
        validate: facebookPagesValidationParser,
    },
    [TargetedDataDataTypes.InstagramAccount]: {
        list: instagramAccountsListParser,
        response: instagramAccountsResponseParser,
        validate: instagramAccountsValidationParser,
    },
    [TargetedDataDataTypes.InstagramHashtag]: {
        list: instagramHashtagsListParser,
        response: instagramHashtagsResponseParser,
        validate: null,
    },
    [TargetedDataDataTypes.LinkedinChannel]: {
        list: linkedinChannelsListParser,
        response: linkedinChannelsResponseParser,
        validate: null,
    },
    [AuthenticationDataTypes.FacebookUserAuthentication]: {
        list: facebookUserAuthenticationsListParser,
        response: facebookUserAuthenticationsResponseParser,
        validate: null,
    },
    [AuthenticationDataTypes.LinkedinChannelAuthentication]: {
        list: linkedinAuthenticationsListParser,
        response: linkedinAuthenticationsResponseParser,
        validate: null,
    },
};

export default parsers;
