import {useMemo} from 'react';
//Hooks
import {useAppSelector} from 'hsi/hooks/useRedux';
import useDates from 'hsi/hooks/useDates';
//Types
import {InputDateType} from 'hsi/types/dates';
//Other
import tns from 'hsi/i18n/tns';

const t = tns('benchmarkSubtitle');

//The hook
export default function useBenchmarkSubTitle(startDate: InputDateType, endDate: InputDateType) {
    const {
        formatTo,
        getPreviousPeriod,
        isToday,
        getISODate,
        getEarliestDate,
        normalizeDate,
        isSame,
    } = useDates();
    const {
        diff,
        startDate: prevStartDate,
        endDate: prevEndDate,
    } = getPreviousPeriod(startDate, endDate);

    const userClient = useAppSelector((state) => state.user?.account?.client);

    const startDateSubtitle: string = useMemo(() => {
        if (isToday(startDate)) {
            const startDateString: string = `${formatTo(startDate, 'DD')} (${formatTo(
                startDate,
                'ZZZZ',
            )})`;
            return `${t('today')} ${startDateString}`;
        } else {
            const endDateString: string = `${formatTo(endDate, 'DD')} (${formatTo(
                endDate,
                'ZZZZ',
            )})`;
            return diff > 1 ? `${formatTo(startDate, 'DD')} - ${endDateString}` : endDateString;
        }
    }, [diff, formatTo, isToday, startDate, endDate]);

    const endDateSubtitle: string = useMemo(() => {
        if (isToday(startDate)) {
            return `${t('yesterday')} ${formatTo(prevEndDate, 'DD')} (${formatTo(
                prevEndDate,
                'ZZZZ',
            )})`;
        } else {
            const endDateSubtitle: string = `
            ${formatTo(prevStartDate, 'DD')} - ${formatTo(prevEndDate, 'DD')} (${formatTo(
                prevEndDate,
                'ZZZZ',
            )})`;
            return endDateSubtitle;
        }
    }, [isToday, startDate, prevStartDate, prevEndDate, formatTo]);

    const hasPreviousValue: boolean = useMemo(() => {
        const furtherAvailableData = normalizeDate(Date.now())
            .minus({
                months: Math.max(userClient?.monthsRollingData, userClient?.monthsHistoricData),
            })
            .startOf('day')
            .toISO();
        const earliest = getEarliestDate([getISODate(prevStartDate), furtherAvailableData]);
        return isSame(getISODate(earliest), getISODate(furtherAvailableData), 'millisecond');
    }, [
        userClient?.monthsRollingData,
        userClient?.monthsHistoricData,
        isSame,
        normalizeDate,
        getEarliestDate,
        getISODate,
        prevStartDate,
    ]);

    const benchmarkSubHeader: string = t('subtitle', {
        start_date: startDateSubtitle,
        end_date: endDateSubtitle,
    });

    return {hasPreviousValue, benchmarkSubHeader};
}
