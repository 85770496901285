/**
 * User Service
 */
import http from 'hsi/classes/Http';

const bypassLogin =
    process.env.REACT_APP_DEV_BYPASS_LOGIN && JSON.parse(process.env.REACT_APP_DEV_BYPASS_LOGIN);

class userService {
    static async logIn({user, pass, jwt}) {
        if (bypassLogin) {
            return {isLoggedIn: true, projects: []};
        } else {
            let params;
            if (jwt) {
                params = {jwt};
            } else if (user && pass) {
                params = {user, pass};
            }
            return http.post('/fe-api/login', params).then((res) => res.body);
        }
    }

    static async logOut() {
        return http.post('/fe-api/logout').then((res) => res.body);
    }

    static async clientUseData(clientId) {
        return http.get(`/api/client/${clientId}/usagesummary`).then((res) => res.body);
    }

    static async linkUser(params) {
        return http.post(`/fe-api/link-user`, params).then((res) => res.body);
    }

    static async getPackageUsage() {
        return http.get('/api/client/package-usage').then((res) => res.body);
    }

    static async impersonate(params) {
        return http.post('/fe-api/impersonate', params);
    }
}

export default userService;
