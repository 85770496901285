//////////////////
// State to API //
//////////////////

import {APIFilterFormat, FilterConfigByName, FiltersState} from 'hsi/types/filters';

export default function filterStateToAPIFormat(
    filtersState: FiltersState,
    allFiltersConfig: FilterConfigByName,
): APIFilterFormat {
    try {
        return Object.keys(allFiltersConfig).reduce<APIFilterFormat>(
            (output: APIFilterFormat, filterName: string) => {
                const filterConfig = allFiltersConfig[filterName];

                filterConfig?.toAPI?.(
                    filtersState[filterName],
                    output,
                    filterName as keyof APIFilterFormat,
                );

                return output;
            },
            {},
        );
    } catch (e) {
        debugger;
    }

    return {}; //hmmm??
}
