import makeStyles from '@mui/styles/makeStyles';

import Banner from 'hsi/components/Banner';

import {isV2, Theme} from 'hsi/types/theme';

const useStyles = makeStyles((theme: Theme) => {
    const v2 = isV2(theme);
    const {colors, font} = theme;

    return {
        banner: {
            backgroundColor: 'transparent !important',
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 8,
            alignItems: 'start',
            '& .hsib-icon-holder': {
                marginLeft: 0,
                marginRight: 8,
                backgroundColor: v2 ? 'transparent' : `${colors.secondaryBlue40} !important`,
                '& .hsib-icon': {
                    fill: v2 ? colors.warning : `${colors.secondaryBlue100} !important`,
                },
            },
        },
        title: {
            fontWeight: font.bold,
            fontSize: v2 ? 13 : 16,
            color: v2 ? colors.darkGrey80 : colors.darkGrey100,
        },
        desc: {
            marginTop: 2,
            marginBottom: 4,
            fontSize: v2 ? 13 : 16,
            '& a': {
                textDecoration: 'none',
                fontWeight: font.bold,
            },
        },
    };
});

type InputWarningFooterProps = {
    desc: React.ReactNode;
};

const InputWarningFooter = ({desc}: InputWarningFooterProps) => {
    const classes = useStyles();

    return (
        <Banner variant="warning" className={classes.banner} iconSize="small">
            <div className={classes.desc}>{desc}</div>
        </Banner>
    );
};

export default InputWarningFooter;
