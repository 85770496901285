// TODO: Add tests
import {useConfig} from 'hsi/contexts/ConfigContext';

//re-export
export default useConfig;

export function withConfig(Component: React.ElementType<any>) {
    const HOCComponent = (props: {[key: string]: any}) => {
        const config = useConfig();

        return <Component {...props} config={config} />;
    };

    HOCComponent.displayName = `withConfig(${
        (Component as any).displayName || (Component as any).name
    })`;

    return HOCComponent;
}
