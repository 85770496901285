import {PropsWithChildren, ReactElement, ReactNode} from 'react';
import cn from 'classnames';

//Components
import Tooltip from 'hsi/components/Tooltip';
import Checkbox from 'hsi/components/Checkbox';

//Hooks
import useUniqueId from 'hsi/hooks/useUniqueId';

//Other
import useStyles from './styles';

interface ItemProps<T> {
    id: T;
    checked: boolean;
    disabled: boolean;
    label: ReactNode;
    disabledTooltip?: string;
    onChange: (id: T, checked: boolean) => void;
}

export default function Item<T>({
    id,
    checked,
    disabled,
    label,
    disabledTooltip,
    onChange,
}: ItemProps<T>) {
    const cl = useStyles();
    const uniqId = useUniqueId();

    return (
        <li className={cn(cl.itemContainer, checked && cl.itemContainerChecked)}>
            <AddTooltipIfDisabled disabled={disabled} title={disabledTooltip}>
                <label className={cn(cl.label, disabled && cl.disabled)}>
                    <Checkbox
                        checked={checked}
                        onChange={(e) => onChange(id, e.target.checked)}
                        disabled={disabled}
                        aria-labelledby={uniqId}
                    />
                    <span
                        className={cn(cl.labelText, disabled && cl.disabled)}
                        id={uniqId}
                        aria-hidden
                    >
                        {label}
                        {disabled && !!disabledTooltip && (
                            <span className="offscreen">; {disabledTooltip}</span>
                        )}
                    </span>
                </label>
            </AddTooltipIfDisabled>
        </li>
    );
}

function AddTooltipIfDisabled({
    disabled,
    title,
    children,
}: PropsWithChildren<{disabled: boolean; title?: string}>) {
    return disabled && !!title ? (
        <Tooltip noAria tooltip={title}>
            {children}
        </Tooltip>
    ) : (
        (children as ReactElement)
    );
}
