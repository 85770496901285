import React from 'react';
import withStyles from '@mui/styles/withStyles';
import cn from 'classnames';

const styles = {
    fullViewport: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
    },
};

const FullViewportComponent = ({classes, className, children}) => (
    <div className={cn(classes.fullViewport, className)} data-testid="fullViewport">
        {children}
    </div>
);

export const FullViewport = withStyles(styles)(FullViewportComponent);

//const makeFullViewport = WrappedComponent => ({ classes, ...props }) => <FullViewportComponent classes={classes}><WrappedComponent {...props} /></FullViewportComponent>

//export const fullViewportHoc = WrappedComponent => withStyles(styles)(makeFullViewport(WrappedComponent));
