/**
 * Results Service
 */
import http from '../classes/Http';

export async function loadTags(projectId) {
    return http.get('/api/projects/' + projectId + '/ruletags').then((res) => res.body);
}

export async function saveTag(projectId, tag) {
    return http.post('/api/projects/' + projectId + '/ruletags', tag).then((res) => res.body);
}

export async function deleteTag(projectId, tagId) {
    return http.delete('/api/projects/' + projectId + '/ruletags/' + tagId).then((res) => res.body);
}

export async function editTag(projectId, tag) {
    return http
        .put('/api/projects/' + projectId + '/ruletags/' + tag.id, tag)
        .then((res) => res.body);
}
