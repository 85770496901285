//TODO proper typing

import compact from 'lodash/compact';
import filter from 'lodash/filter';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

//Components
import IconRouter from 'hsi/components/IconRouter';

//Hooks
import useEventTrack from 'hsi/hooks/useEventTrack';
import useFlags from 'hsi/hooks/useFlags';
import {useActiveQueryPageTypes} from 'hsi/hooks/usePageTypes';
import { useAppDispatch } from 'hsi/hooks/useRedux';

//Actions
import {reloadTotalVolume} from 'hsi/actions/resultsActions';

//Consts
import {TOTAL_VOLUME_TYPES} from 'hsi/constants/config';

//Other
import {T} from 'hsi/i18n';

//Types
export type MetricName = `metric${number}`;

export type TotalVolumeStateParams = {
    [key: MetricName]: {id: string, label: string}
};

export type TotalVolumeControlsProps = {
    selectedParams: TotalVolumeStateParams;
};


//The component
export default function TotalVolumeControls({selectedParams}: TotalVolumeControlsProps) {
    const dispatch = useAppDispatch();
    const {trackWithSearchData} = useEventTrack();
    const flags = useFlags();
    const pageTypes = useActiveQueryPageTypes();

    const onParamChange = (value: string, type: MetricName) => {
        if (value !== selectedParams[type]?.id) {
            const updatedValues: TotalVolumeStateParams = {
                ...selectedParams,
                [type]: filter(TOTAL_VOLUME_TYPES, {id: value})[0]
            }

            trackWithSearchData('cardCustomized', {
                metric: type,
                type: 'totalVolume',
                value: compact(Object.values(updatedValues)).map((m) => m.label),
            });
            dispatch(reloadTotalVolume(updatedValues, flags, pageTypes));
        }
    };

    return ([...Array(TOTAL_VOLUME_TYPES.length)].map((_, i) => {
            const n = i+1;
            const type: MetricName = `metric${n}`;
            const id = `select-${type}-${n}`;

            return (
                <div className="control" key={id}>
                    <span className="label" id={id}>
                        {T('configSideDrawer.totalVolume.select.label', {i: n})}
                    </span>
                    <Select
                        labelId={id}
                        displayEmpty
                        onChange={(e) => onParamChange(e.target.value, type)}
                        value={selectedParams?.[type]?.id || ''}
                        variant="outlined"
                    >
                        <MenuItem value="">{T('configSideDrawer.totalVolume.select.none')}</MenuItem>
                        {TOTAL_VOLUME_TYPES.map((selectItem) => (
                            <MenuItem key={type + selectItem.id} value={selectItem.id}>
                                {selectItem.label}
                            </MenuItem>
                        ))}
                    </Select>
                    <IconRouter name="chevron-down" className="select-icon" />
                </div>
            );
        })) as any;//Needed to fix error where TS incorrectly states that 'Element[]' is not a valid return type
};
