
//Components
import FullscreenLoading from 'hsi/components/FullscreenLoading';
import NoResultsMsg from './NoResultsMsg';

//Other
import {T} from 'hsi/i18n';


//Types
type DataCheckStatusProps = {
    isSavedSearch: boolean;
    hasData: boolean | null;
    checkSearchHasData: () => void;
};

//The component
export default function DataCheckStatus({isSavedSearch, hasData, checkSearchHasData}: DataCheckStatusProps) {
    return hasData === false 
        ? <NoResultsMsg isSavedSearch={isSavedSearch} checkSearchHasData={checkSearchHasData} />
        : hasData === null
            ? <FullscreenLoading message={T('insightsContainer.results.loading')}  />
            : null;
}