import IconRouter from 'hsi/components/IconRouter';
import Nav from 'hsi/components/Nav';

import useStyles from './styles';

import {ConfigType} from 'hsi/types/config';
import {T} from 'hsi/i18n';

type TabsProps = {
    links: ConfigType['dataManagement']['tabs'];
};

export default function Tabs ({links}: TabsProps) {
    const classes = useStyles();

    return (
        <Nav className={classes.tabs} aria-label={T('dataManagement.tableSectionLbl')}>
            {links.map(({logo, title, url}) => <Nav.Item
                title={logo ? `###${T(title)}` : T(title)}
                theme="small"
                url={url}
                key={url}
            >
                <span className={classes.tab}>
                    {logo && <IconRouter className={classes.tabIcon} name={logo} />}
                    {T(title)}
                </span>
            </Nav.Item>)}
        </Nav>
    );
};
