import http, { HttpResult } from "hsi/classes/Http";
import { QueryUserData } from "hsi/types/queryUserData";

const isTest = process.env.NODE_ENV === 'test';


export async function getQueryUserDataKey<T extends keyof QueryUserData>(queryId: number, projectId: number, key: T): Promise<HttpResult<QueryUserData[T] | null>> {
    return http.get<QueryUserData[T]>(`/apiv2/${projectId}/saved-search/${queryId}/query-user-data/${key}`);
}

export async function putQueryUserDataKey<T extends keyof QueryUserData>(queryId: number, projectId: number, key: T, value: QueryUserData[T] | null): Promise<HttpResult<void>> {
    return isTest 
        ? Promise.resolve({
            status: 200,
            ok: true,
        } as HttpResult<void>)
        : http.put<void>(`/apiv2/${projectId}/saved-search/${queryId}/query-user-data/${key}`, {value});
}
