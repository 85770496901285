import React, {useState, forwardRef, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Tippy from '@tippy.js/react';
import {translateStyle} from 'react-smooth';

//Hooks
import useRefCallback from 'hsi/hooks/useRefCallback';

//Other
import useStyles from './styles';

//Internal components
const DefaultAnchorComponent = forwardRef(function DefaultAnchorComponent({style}, ref) {
    const classes = useStyles();

    return (
        <circle
            ref={ref}
            className={classes.anchor}
            cx={0}
            cy={0}
            r={1}
            fill="transparent"
            style={style}
        />
    );
});

//The Component
export default function ChartTooltip({
    x,
    y,
    children,
    className,
    onHide,
    autoHideOnScroll,
    showArrow,
    open,
    AnchorComponent,
    animationMs,
    distance,
}) {
    const classes = useStyles();

    const [showing, setShowing] = useState(true);
    const dataRef = useRef();

    if (!dataRef.current) {
        dataRef.current = {
            lastX: x,
            lastY: y,
        };
    }

    //Callbacks
    const onScroll = useRefCallback(() => {
        if (showing && autoHideOnScroll) {
            setShowing(false);
        }

        onHide && onHide();
    });

    //Side effects
    useEffect(
        () => {
            window.addEventListener('scroll', onScroll, true);

            return () => {
                window.removeEventListener('scroll', onScroll, true);
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useEffect(
        () => {
            if (
                autoHideOnScroll &&
                !showing &&
                (dataRef.current.lastX !== x || dataRef.current.lastY !== y)
            ) {
                setShowing(true);
                dataRef.current.lastX = x;
                dataRef.current.lastY = y;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [x, y],
    );

    //Render
    const circleStyle = translateStyle({
        transform: `translate3d(${x}px, ${y}px, 0)`,
        transition: `transform ${animationMs}ms ease`,
    });

    if (!open || !showing || !x || !y) return null;

    return (
        <Tippy
            content={children}
            className={cn(classes.tooltip, className)}
            arrow={showArrow}
            enabled={true}
            visible={true}
            theme="dark"
            animateFill={false}
            inertia={true}
            sticky={true}
            updateDuration={0}
            distance={distance}
            ignoreAttributes
        >
            <AnchorComponent style={circleStyle} />
        </Tippy>
    );
}

ChartTooltip.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    className: PropTypes.string,
    showArrow: PropTypes.bool,
    open: PropTypes.bool,
    distance: PropTypes.number,
    anchorComponent: PropTypes.any,
    animationMs: PropTypes.number,
    onHide: PropTypes.func,
    autoHideOnScroll: PropTypes.bool,
};

ChartTooltip.defaultProps = {
    showArrow: true,
    open: true,
    distance: 16,
    AnchorComponent: DefaultAnchorComponent,
    animationMs: 120,
    autoHideDuration: false,
};
