import {
    FiltersState,
    LegacyFilterStateFormat,
    AllFilteringState,
    IncludeOrExcludeFilterState,
    IncludeAndExcludeFilterState,
    UpgradeFilterFormatMethod,
    FilterConfigByName,
} from 'hsi/types/filters';
import {SearchType} from 'hsi/types/shared';
import legacyFormatToFiltersState from './legacyFormatToFiltersState';

//Converts the filters state object into the latest format
export default function normalisePersistedFilters(
    filterState: LegacyFilterStateFormat | AllFilteringState,
    allFiltersConfig: FilterConfigByName,
    searchType: SearchType,
    upgradeMethods: UpgradeFilterFormatMethod[],
): FiltersState {
    if (filterState.hasOwnProperty('filtersList')) {
        return legacyFormatToFiltersState(
            (filterState as LegacyFilterStateFormat).filtersList,
            searchType,
            allFiltersConfig,
        );
    }

    //Deal with legacy state (if applicable)
    const filters: FiltersState = (filterState as AllFilteringState).filters;

    //Get the version number, default to assuming 0
    const version: number = filterState.hasOwnProperty('version')
        ? (filterState as AllFilteringState).version
        : 0;

    //Apply the relevent upgrade methods in order
    return upgradeMethods.slice(version).reduce((filters: FiltersState, upgradeMethod) => {
        return upgradeMethod(filters, searchType);
    }, filters);
}

//Helpful utility methods to simplify converting formats
export function includeOrExcludeToIncludeAndExclude<T>(
    filterState: IncludeOrExcludeFilterState<T>,
    field: string,
): IncludeAndExcludeFilterState<T> {
    return {
        activeModeIsInclude: filterState.activeModeIsInclude,
        fields: {
            [field]: {
                include: filterState.activeModeIsInclude ? [...(filterState.values || [])] : [],
                exclude: filterState.activeModeIsInclude ? [] : [...(filterState.values || [])],
            },
        },
    };
}
