import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    deleteMessage: {
        padding: '24px',
        textAlign: 'left',
    },
    title: {
        fontSize: '14px',
        color: theme.colors.textDefault,
        fontWeight: theme.font.semiBold,
        padding: '4px 0',
    },
    sub: {
        color: theme.colors.textDefault,
        fontSize: '14px',
        fontWeight: theme.font.semiBold,
        padding: '4px 0',
    },
    disclaimer: {
        fontSize: '12px',
        color: theme.colors.textMeta,
        fontStyle: 'italic',
        padding: '7px 0',
    },
    actions: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '10px',
    },
}));
