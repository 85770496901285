import delay from '../delay';

/**
 * Ensure that a minumum length of time will elapse after calling this function before the code continues
 * if minDelay is undefined, will just resolve/reject immediately
 * @param promise the promise to ensure takes a minimum amount of time
 * @param minDelay time, in milliseconds
 * @returns the result of the supplied promise
 */
export default async function awaitForAtLeast<T>(
    promise: Promise<T>,
    minDelay?: number,
): Promise<T> {
    if (minDelay! > 0) {
        const minDelayElapsedAt = Date.now() + minDelay!;

        try {
            const result = await promise;

            await getDelay(minDelayElapsedAt);

            return result;
        } catch (e: any) {
            //also apply delay to any errors
            await getDelay(minDelayElapsedAt);

            throw e;
        }
    } else {
        return promise;
    }
}

async function getDelay(minDelayElapsedAt: number) {
    const now = Date.now();

    if (now < minDelayElapsedAt) {
        await delay(minDelayElapsedAt - now);
    }
}
