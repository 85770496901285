import {useMemo} from 'react';

import * as dates from 'hsi/utils/dates';
import useTimezone from 'hsi/hooks/useTimezone';
import {InputDateType} from 'hsi/types/dates';
import {DateTimeUnit, DurationLike} from 'luxon';

// Largely adds correct project timezone to the date utility functions
const useDates = (projectId?: number) => {
    const _tz = useTimezone(projectId);

    // Memoised functions
    return useMemo(
        () => ({
            formatTo: (date: InputDateType, format: dates.DateTimeFormats, tz = _tz) =>
                dates.formatTo(date, tz, format),
            getDaysAmount: (startDate: InputDateType, endDate: InputDateType, tz = _tz) =>
                dates.getDaysAmount(startDate, endDate, tz),
            getEarliestDate: (dateArray: InputDateType[], tz = _tz) =>
                dates.getEarliestDate(dateArray, tz),
            getFutureDate: (date: InputDateType, duration: DurationLike, tz = _tz) =>
                dates.getFutureDate(date, tz, duration),
            getHours: (date: InputDateType, tz = _tz) => dates.getHours(date, tz),
            getInitialPeriod: (tz = _tz) => dates.getInitialPeriod(tz),
            getISODate: (date: InputDateType, tz = _tz) => dates.getISODate(date, tz),
            getLocalDate: (date: InputDateType, tz = _tz) => dates.getLocalDate(date, tz),
            getPreviousDate: (date: InputDateType, duration: DurationLike, tz = _tz) =>
                dates.getPreviousDate(date, tz, duration),
            getPreviousPeriod: (startDate: InputDateType, endDate: InputDateType, tz = _tz) =>
                dates.getPreviousPeriod(startDate, endDate, tz),
            getStartOf: (date: InputDateType, unit: DateTimeUnit, tz = _tz) =>
                dates.getStartOf(date, tz, unit),
            getTimestamp: (date: InputDateType, tz = _tz) => dates.getTimestamp(date, tz),
            isSame: (date1: InputDateType, date2: InputDateType, unit: DateTimeUnit, tz = _tz) =>
                dates.isSame(date1, date2, unit, tz),
            isToday: (date: InputDateType, tz = _tz) => dates.isToday(date, tz),
            normalizeDate: (date: InputDateType, tz = _tz) => dates.normalizeDate(date, tz),
            now: (tz = _tz) => dates.now(tz),
        }),
        [_tz],
    );
};

export default useDates;
