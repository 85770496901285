import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => ({
    root: {
        position: 'relative',
        flex: '1 1 100%',
        alignSelf: 'stretch',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.v2 ? '43px' : '50px',
        marginBottom: '150px',
        left: 0,
        right: 0,
        boxShadow: 'none',
        '&::before': {
            content: '""',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '0 8px 8px 8px',
            borderColor: `transparent transparent transparent transparent`,
            marginTop: '-8px',
            marginLeft: '178px',
        },
    },
    suggestionsList: {
        backgroundColor: 'white',
        margin: 0,
        border: theme.v2
            ? `1px solid ${theme.colors.borders}`
            : `2px solid ${theme.colors.secondaryPurple100}`,
        boxShadow: theme.v2 ? `0px 4px 9px r${theme.colors.borders})` : undefined,
        borderRadius: theme.v2 ? '3px' : undefined,
        padding: 0,
        listStyleType: 'none',
    },
    container: {
        display: 'flex',
    },
    suggestion: {
        display: 'block',
    },

    highlight: {},
    suggestionWrapper: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    suggestionPart: {
        '&.highlight': {
            fontWeight: theme.font.semiBold,
        },
    },

    fullWidth: {
        width: '100%',
    },
}));
