import {registerFilterType, getBlankArr, includes, checkUpdate, isValueValid} from './../utils';

registerFilterType(
    'multiValueTextfield',
    getBlankArr,
    (filterConfig, filterState) => !filterState?.length,
    {
        //reducers
        removeValueMultiValueTextfield: (state, {payload: {filterName, value}}) => {
            if (state.allFiltersConfig[filterName].type !== 'multiValueTextfield') {
                throw new Error('This action is only valid for multiValueTextfield type filters');
            }

            const index = state.filters[filterName].indexOf(value);
            state.filters[filterName].splice(index, 1);

            checkUpdate(state);
        },
    },
    {
        //validated reducers func(state, payload)
        addValueMultiValueTextfield: (state, {filterName, value}) => {
            state.filters[filterName].push(value);
        },
    },
    (state, filterName, value, skipAsync = false) => {
        const filterState = state.filters[filterName];
        const filterConfig = state.allFiltersConfig[filterName];

        if (includes(filterState, value, filterState.comparisonFunc)) {
            return filterConfig.duplicateValueErrMsg || 'filters.validation.duplicateValue';
        }

        //Check validation
        if (filterConfig.validation) {
            const errorMsg = filterConfig.validation(value, state);

            if (errorMsg) {
                return errorMsg;
            }
        }

        //Max values
        if (filterConfig.maxValues) {
            //calculate total number of values for this field (taking into account removing value from other mode)
            const numValues = filterState.length;

            //If have reached or exceeded the maximum number of values
            if (numValues >= filterConfig.maxValues) {
                return filterConfig.maxValuesErrMsg || 'filters.validation.maxValues';
            }
        }

        if (!skipAsync && value && filterConfig.asyncValidation) {
            return filterConfig.asyncValidation(value, state);
        }

        return true;
    },
    (state, filterConfig, {action, value, filterName}) => {
        if (action !== 'addValueMultiValueTextfield') {
            throw new Error(
                'Invalid action for this filter type, check you have the correct filterName and/or are using the correct action',
            );
        }

        return isValueValid(state, filterName, value);
    },
    undefined,
    undefined,
    undefined,
    undefined,
    (filterConfig, currentValue, persistedValue) => {
        if (
            persistedValue instanceof Array &&
            persistedValue.every((val) => typeof val === 'string')
        ) {
            return [...persistedValue];
        }

        return currentValue;
    },
);
