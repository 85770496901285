import {isV2} from 'hsi/types/theme';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => {
    const v2 = isV2(theme);
    return {
        peakExplanation: {
            top: 0,
            color: theme.colors.darkGrey100,
            zIndex: 10,
            position: 'relative',
            backgroundColor: theme.colors.lightGrey20,
            padding: theme.v2 ? '0 16px' : '16px',
        },
        collapsible: {
            paddingBottom: 4,
        },

        title: {
            fontSize: '14px',
            fontWeight: 700,
            textTransform: v2 ? 'capitalize' : 'uppercase',
            margin: '0',
            display: v2 ? 'flex' : 'block'
        },

        AIChip: !v2 ? {
            display: 'none !important',
        }: {
            marginLeft: '5px',
        },

        peak: {
            margin: '10px 0 0 0',
            background: theme.colors.lightGrey10,
            borderRadius: '4px',
            padding: '15px 0 10px 15px',
        },
        peakLabel: {
            width: '25px',
            height: '30px',
            float: 'left',
        },
        highestVolume: {
            float: 'left',
            margin: '0 0 0 5px',
            fontSize: '20px',
            fontWeight: 600,
        },
        peakDiff: {
            clear: 'both',
            display: 'block',
            padding: '5px 0 0 0',
        },
        reasonList: {
            listStyle: 'none',
            margin: 0,
            padding: 0,
        },
        reason: {
            height: '40px',
            borderRadius: '4px',
            backgroundColor: theme.colors.lightGrey10,
            padding: '11px 0 11px 15px',
            margin: '3px 0',
            fontSize: '12px',
            display: 'flex',
        },
        reasonIcon: {
            width: '20px',
            height: '20px',
            display: 'inline-block',
            margin: '0 10px 0 0',
        },
        reasonText: {
            position: 'relative',
            top: '1px',
        },
        reasonTextP: {
            '&&': {
                fontSize: 'inherit',
            },
        },
        reasonExpandButton: {
            display: 'block',
            margin: '0 auto',
            backgroundColor: 'transparent',
            color: theme.colors.textMeta,
            border: 'none',
            '&& .MuiButton-label': {
                textTransform: 'uppercase',
            },
            '&:active, &:focus': {
                boxShadow: 'none',
            },
        },
    };
});
