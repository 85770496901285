import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    //disabled: {},//This needs to exist even if it is empty so it can be referred to elsewhere, as $disabled
    //compact: {},//ad above

    root: {
        padding: 0,
        margin: 0,
    },

    // input: {
    //
    // }
}));
