import {makeStyles} from 'tss-react/mui';

export default makeStyles()(() => ({
    root: {
        '&&': {
            //needed due to https://github.com/mui-org/material-ui/issues/24453
            color: '#143059',
            backgroundColor: '#D0D6DE',
        },
    },
    select: {
        '&&&': {
            paddingRight: '60px',
            paddingLeft: '35px',
            borderRadius: '2px',
        },
    },
}));

export const useIconStyles = makeStyles()(() => ({
    downArrowIcon: {
        right: '35px',
        position: 'absolute',
    },
}));
