import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';

//Actions
import {openSaveSearchDialog} from 'hsi/actions/savedSearchActions';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

export default function TooltipMsg({messageId}) {
    const classes = useStyles();

    const dispatch = useDispatch();
    const maxSearches = useSelector((state) => state.user.account.client.queryLimit);
    const numSearches = useSelector((state) => (state.search.searches || []).length);
    const canSaveSearch = useSelector((state) => state.user.canSaveSearch);
    const disabled = numSearches >= maxSearches;

    const openSaveDialog = () => {
        dispatch(openSaveSearchDialog(true));
    };

    if (!canSaveSearch) {
        return <>{T('saveSearchTooltip.unauthorized')}</>;
    }

    return (
        <div className={classes.msg}>
            <button disabled={disabled} className={classes.button} onClick={openSaveDialog}>
                {T('saveSearchTooltip.main')}
            </button>{' '}
            {T(`saveSearchTooltip.${messageId}`)}
        </div>
    );
}

TooltipMsg.propTypes = {
    close: PropTypes.func,
    messageId: PropTypes.string,
};
