import {createSlice} from '@reduxjs/toolkit';
import {findIndex} from 'lodash';
import {ApiAlert} from 'hsi/types/alerts';

import {deleteAlert, loadAlerts, saveAlert, updateAlert} from './thunks';

type AlertsReducerType = {
    alerts: ApiAlert[] | null;
    alertsById: {[key: number]: ApiAlert};
    error: {error: true; status: number; body: any} | null;
    success: boolean;
};

const initialState: AlertsReducerType = {
    alerts: null,
    alertsById: {},
    error: null,
    success: false,
};

const slice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        clearSuccess: (state) => {
            state.error = initialState.error;
            state.success = initialState.success;
        },
        clearError: (state) => {
            state.error = initialState.error;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(loadAlerts.fulfilled, (state, {payload}) => {
                const alerts = payload;

                state.alerts = alerts;
                state.alertsById = alerts.reduce<{[key: number]: ApiAlert}>((output, alert) => {
                    output[alert.id] = alert;

                    return output;
                }, {});
            })
            .addCase(deleteAlert.fulfilled, (state, {payload}) => {
                const deleteAlertId = payload;
                if(deleteAlertId === undefined) return
                const {[deleteAlertId]: deletedAlert, ...alertsById} = state.alertsById;

                state.alertsById = alertsById;
                state.alerts =
                    state.alerts?.filter(function ({id}) {
                        return id !== deleteAlertId;
                    }) ?? null;
            })
            .addCase(updateAlert.fulfilled, (state, {payload}) => {
                const idx = findIndex(state.alerts, (a) => a.id === payload.id);

                if (!state.alerts || idx === null) {
                    return state;
                }

                state.alertsById = {...state.alertsById, [payload.id]: payload};
                state.alerts = [
                    ...state.alerts.slice(0, idx),
                    payload,
                    ...state.alerts.slice(idx + 1),
                ];
                state.error = null;
                state.success = true;
            })
            .addCase(updateAlert.rejected, (state, {payload}) => {
                state.error = payload;
            })
            .addCase(saveAlert.rejected, (state, {payload}) => {
                state.error = payload;
            })
            .addCase(saveAlert.fulfilled, (state) => {
                state.error = null;
                state.success = true;
            });
    },
});

export const {clearSuccess, clearError} = slice.actions;
export default slice.reducer;
export {deleteAlert, loadAlerts, saveAlert, updateAlert} from './thunks';
