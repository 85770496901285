import useDebouncedValue from 'hsi/hooks/useDebouncedValue';
import {DebounceSettings} from 'lodash';
import {AriaRole} from 'react';

export type AnnounceMessageDebouncedProps = {
    message: string;
    priority?: JSX.IntrinsicElements['div']['aria-live'];
    wait?: number;
    options?: DebounceSettings;
    role?: AriaRole;
};

export default function AnnounceMessageDebounced({
    message,
    priority,
    wait = 1500,
    options,
    role = 'status',
}: AnnounceMessageDebouncedProps) {
    const [debouncedMessage] = useDebouncedValue(message, wait, options);

    return (
        <p className="offscreen" role={role} aria-live={priority}>
            {debouncedMessage}
        </p>
    );
}
