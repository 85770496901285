import { PropsWithChildren, useMemo } from "react";
import { createPortal } from "react-dom";


type PortalArgs = PropsWithChildren<{
    target: string | Element | DocumentFragment;
}>;

export default function Portal({target: targetArg, children}: PortalArgs) {
    const target = useMemo(() => {
        if(typeof(targetArg) === 'string') {
            return document.getElementById(targetArg)!;
        }

        return targetArg;
    }, [targetArg]);

    return createPortal(children, target);
}
