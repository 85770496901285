import React, {useState, useEffect, ReactNode, useMemo} from 'react';
import {Placement} from '@floating-ui/react-dom-interactions/src/types';

//Components
import TextFieldInput from 'hsi/components/Filters/MultiValueTextInput/TextFieldInput';
import Label from 'hsi/components/Filters/Label';
import ChipInput, {RenderChipProps} from 'hsi/components/ChipInput';
import {ChipInputAutosuggest} from 'hsi/components/ChipInput/Autosuggest';
import ChipWithError from 'hsi/components/ChipInput/ChipWithError';
import IconRouter from 'hsi/components/IconRouter';

//Hooks
import useUniqueId from 'hsi/hooks/useUniqueId';

//Other
import getAsyncStateProps from 'hsi/components/Filters/helpers/getAsyncStateProps';
import {T} from 'hsi/i18n';

//Types
import {AutosuggestTypes} from 'hsi/types/autosuggest';
import {ChipType} from 'hsi/types/shared';

interface MultiValueTextInputProps<TChip> {
    placeholderText?: string;
    labelText?: ReactNode;
    autoScrollDrawer?: (elem: Element) => void; //called with the drawer element when it appears - intended to be used to scroll into view (if required)
    autosuggestType: AutosuggestTypes;
    chips: ChipType<TChip>[];
    displayChipInsideField?: boolean;
    maxValues?: number;
    limitLabel?: string; //optional language label
    tooltip?: ReactNode;
    tooltipPlacement?: Placement;
    disabled?: boolean;
    pending?: boolean;
    error?: boolean;
    errorMsg?: ReactNode;
    id?: string;

    onBeforeAdd?: (chip: TChip) => boolean;
    onAdd?: (chip: TChip) => void;
    onDelete?: (chip: TChip, index: number) => void;
    onInputChange?: () => void;
}

//Constants
const addValueKeyCodes = [13]; //enter

//The component
export default function MultiValueTextInput<TChip>({
    placeholderText,
    labelText,
    autoScrollDrawer,
    autosuggestType,
    chips,
    displayChipInsideField,
    maxValues,
    limitLabel,
    tooltip = '',
    tooltipPlacement,
    disabled,
    pending,
    error,
    errorMsg,
    id,

    onBeforeAdd,
    onAdd,
    onDelete,
    onInputChange,
}: MultiValueTextInputProps<TChip>) {
    if (tooltip) {
        //I have removed tooltip support, because as far as I could tell nothing was using it, and making it work correctly was going to be
        //a significant project
        console.error('tooltip not support for MultiValueTextInput');
    }
    const fieldId = useUniqueId(id, `multiValueInput-`);

    //State
    const [textfieldValue, setTextfieldValue] = useState('');

    //Calculated values
    const asyncProps = getAsyncStateProps(pending, error, errorMsg, disabled, textfieldValue, true);

    //Side effects
    useEffect(() => setTextfieldValue(''), [chips]);

    //Render
    if (displayChipInsideField) {
        // //Render for autosuggest
        const input = autosuggestType ? (
            <ChipInputAutosuggest
                autoSuggestType={autosuggestType}
                id={fieldId}
                {...asyncProps}
                error={error}
                placeholder={placeholderText}
                values={chips as any}
                onInputChange={onInputChange}
                onValuesChange={(newValues, reason, details) => {
                    // map to onAdd, onBeforeAdd, etc
                    switch (reason) {
                        case 'removeOption':
                            const option = details?.option! as ChipType<TChip>;

                            onDelete?.(option.value, chips.indexOf(option));
                            break;
                        case 'selectOption':
                            if (onBeforeAdd && !onBeforeAdd(details?.option.value as TChip)) {
                                //onBeforeAdd says no
                                return false; //return false prevent inputValue from being cleared
                            }

                            onAdd?.(details?.option.value as TChip);
                            break;
                    }
                }}
                maxLength={maxValues}
                limitLabel="chipInput.limitLabel"
                chipComponent={Chip}
                autoHighlight
            />
        ) : (
            <ChipInput
                id={fieldId}
                {...asyncProps}
                error={error}
                placeholder={placeholderText}
                values={chips}
                onInputChange={onInputChange}
                onValuesChange={(newValues, reason, details) => {
                    // map to onAdd, onBeforeAdd, etc
                    switch (reason) {
                        case 'createOption':
                            if (onBeforeAdd && !onBeforeAdd(details?.option as TChip)) {
                                //onBeforeAdd says no
                                return false; //return false prevent inputValue from being cleared
                            }

                            onAdd?.(details?.option as TChip);
                            break;
                        case 'removeOption':
                            const option = details?.option! as ChipType<TChip>;

                            onDelete?.(option.value, chips.indexOf(option));
                            break;
                    }
                }}
                maxLength={maxValues}
                limitLabel="chipInput.limitLabel"
                chipComponent={Chip}
            />
        );

        return (
            <>
                {(labelText || placeholderText) && (
                    <Label invisible={!labelText} htmlFor={fieldId}>
                        {labelText ? labelText : placeholderText}
                    </Label>
                )}
                {input}
            </>
        );
    }

    return (
        <>
            {(labelText || placeholderText) && (
                <Label invisible={!labelText} htmlFor={fieldId}>
                    {labelText ? labelText : placeholderText}
                </Label>
            )}
            <TextFieldInput
                placeholderText={placeholderText}
                autoScrollDrawer={autoScrollDrawer}
                autosuggestType={autosuggestType}
                chips={chips}
                limitLabel={limitLabel}
                tooltip={tooltip || ''}
                tooltipPlacement={tooltipPlacement}
                addValueKeyCodes={addValueKeyCodes}
                id={fieldId}
                textfieldValue={textfieldValue}
                setTextfieldValue={setTextfieldValue}
                onBeforeAdd={onBeforeAdd}
                onAdd={onAdd}
                onDelete={onDelete}
                onInputChange={onInputChange}
                error={!!error}
                {...asyncProps}
            />
        </>
    );
}

function Chip<TChip extends ChipType<any>>({
    value: {actionType, include, label},
    errors,
    index,
    className,
    ...props
}: RenderChipProps<TChip>) {
    const icon = useMemo(
        () =>
            actionType ? (
                <IconRouter aria-label={include ? T('include') : T('exclude')} name={actionType} />
            ) : undefined,
        [actionType, include],
    );

    return <ChipWithError label={label} icon={icon} error={errors?.[0]} {...props} />;
}
