import {makeStyles} from 'tss-react/mui';

export default makeStyles()((theme, _params, classes) => ({
    checkboxesFilter: {
        border: 0,
        padding: 0,
    },

    checkboxWrapper: {
        display: 'flex',
        alignItems: 'center',

        [`&.${classes.disabled}`]: {
            cursor: 'default',
        },

        '&:focus-within': {
            backgroundColor: theme.v2 ? theme.colors.bgHover : theme.colors.primaryYellow20,
        },
    },

    disabled: {}, //This needs to exist even if it is empty so it can be referred to elsewhere, as $disabled
    compact: {}, //ad above

    checkboxesFilterList: {
        padding: 0,
    },

    checkbox: {
        flex: '1 1 100%',
        padding: theme.v2 ? '10px 10px 10px 22px' : '8px 10px 8px 26px',

        ...(theme.v2
            ? theme.typgrphy.bodyNormal
            : {
                    fontWeight: theme.font.regular,
                    fontSize: 16,
                }),
    },

    checkboxIcon: {
        width: 20,
        height: 20,
        marginRight: 12,
        display: theme.v2 ? 'none' : null,
    },
}));
