import {forwardRef, useCallback} from 'react';

// Components
import TopMeasureBySearch, { TopMeasureItem } from 'hsi/components/Cards/TopMeasureBySearch';

// Utils
import {getAuthorDrillInFilters} from 'hsi/utils/filters/drillInFilters';

// Other
import {T} from 'hsi/i18n';
import useIsSavedSearch from 'hsi/hooks/useIsSavedSearch';
import { CardTypeProps } from 'hsi/types/cards';

// Consts
const type = 'topauthorsBySearch';

const nameField = {
    label: T('cards.topauthorsBySearch.nameFieldLabel'),
};

// The component
const TopAuthorsBySearch = forwardRef<HTMLDivElement, CardTypeProps>(function TopAuthorsBySearch(props, ref) {
    const isSavedSearch = useIsSavedSearch();

    const getDrillInData = useCallback(
        ({name: authorName}: TopMeasureItem) => ({
            dates: undefined,
            filter: getAuthorDrillInFilters(authorName, undefined, isSavedSearch),
            label: authorName,
            tracking: {
                name: 'cardDrilledIn',
                type,
                value: authorName,
            },
            type,
        } as const),
        [isSavedSearch],
    );

    return (
        <TopMeasureBySearch
            type={type}
            dataSelector={(state) => state.chart[type]}
            getDrillInData={getDrillInData}
            nameField={nameField}
            ref={ref}
            {...props}
        />
    );
});

export default TopAuthorsBySearch;
