import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => {
    return {
        inputError: {
            padding: 5,
            maxWidth: 160,
            textAlign: 'left',
            '& .error-message-icon-container': {
                flex: '0 0 36px',
                margin: '0 10px 0 0',
            },
        },
        wide: {
            maxWidth: 300,
        },
        inputErrorDesc: {
            textAlign: 'left',
            display: 'flex',
            flexFlow: 'column nowrap',
            gap: 4,

            '&>p': {
                margin: 0,
                padding: 0,
            },
        },
    };
});
