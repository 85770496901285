import mapValues from 'lodash/mapValues';
import zipObject from 'lodash/zipObject';

//Constants
const defaultReducerArgumentNames = ['filterName', 'value'];

//Register filter types
function defaultInitError() {
    return null;
}

function defaultInitPending() {
    return null;
}

function setErrorDefault(filterConfig, error, state, action, fieldName) {
    state.error[filterConfig.filterName] = error;
}

function setPendingDefault(filterConfig, isPending, state, fieldName) {
    state.pending[filterConfig.filterName] = isPending;
}

function setPersistedFilterValueDefault(filterConfig, currentValue, persistedValue) {
    return persistedValue;
}

export function registerFilterType(
    //filter type name - must be a unique string
    name,
    //init the filter state from config: func(filterConfig) => initial/empty state for this type of filter based on the filter config (if applicable)
    initFromConfig,
    //is filter state empty: func(filterConfig, filterState) => boolean, true if the filter state is equal to the initial/empty state
    isFilterStateEmpty,
    //set of reducers/actions to be added to the filters slice to allow modifying this filters state.
    //-Reducer names must be unique across all filters
    //-{[name]: func(state, action) OR [name]: [[argument names], func(state, action)]}
    // --If func has no argument names supplied, will assume default argument names (filterName, value)
    reducers = null,
    //optional: set of validated reducers. These are called using the validateThenApply reducer.
    // This is used to set values, allowing async validation to take place and be managed by the state
    // -Reducer must be unique across all filters
    // -{[name]: func(state, payload) OR [name]: [[argument names], func(state, payload)]}
    // --If func has no argument names supplied, will assume default argument names (filterName, value)
    validatedReducers = null,
    //optional: func(state, fieldName, value, ...optionalArgs), return either
    //'true' for no errors, a string error code if there is
    //an error, or a promise in the case of async validation. This should reject the
    //promise with a string error code if the validation fails. The string error code
    //must match a valid language string key with the relevent error message.
    validateValue = null,
    //Optional: func(state, filterConfig, {action, ...payload}), return as validateValue.
    // This is used to validate the action when calling the validateThenApply thunk.
    // Would ususally include validating the value. Support async validation.
    validateAsyncAction = null,
    //Optional: init the error state from config
    // func(filterConfig) => initial error state
    initErrorFromConfig = defaultInitError,
    //Optional: init the pending state from config
    // func(filterConfig) => initial pending state
    initPendingFromConfig = defaultInitPending,
    //Optional: Update the state to set an error fo this filter
    // func(filterConfig, error, state, action, fieldName) - modifies state in place
    setError = setErrorDefault,
    //Optional: Update the state to record pending state fo this filter
    // func(filterConfig, isPending, state, fieldName) - modifies state in place
    setPending = setPendingDefault,
    //Optional: set filter value using persisted value. Used to check format is valid, etc
    // func(filterConfig, currentValue, persistedValue) => new value for state
    setPersistedValue = setPersistedFilterValueDefault,
) {
    if (filterTypes[name]) {
        throw new Error('A filter type of that name already exists');
    }

    filterTypes[name] = {
        name,
        initFromConfig,
        isFilterStateEmpty,
        initErrorFromConfig,
        initPendingFromConfig,
        setError,
        setPending,
        validateValue,
        validateAsyncAction,
        setPersistedValue,
    };

    //Copy reducers into existing list
    reducers &&
        Object.keys(reducers).forEach((name) => {
            if (allReducers[name]) {
                throw new Error(
                    'A reducer with this name already exists, please ensure your names are unique: ' +
                        name,
                );
            }

            const value = reducers[name];

            //each value [[list of argument names as string], func], if just a func, assume
            // default argument names
            allReducers[name] =
                value instanceof Function ? [defaultReducerArgumentNames, value] : value;

            allFilterReducers[name] = allReducers[name][1];
        });

    //Copy validated reducers into existing list
    validatedReducers &&
        Object.keys(validatedReducers).forEach((name) => {
            if (allValidatedReducers[name]) {
                throw new Error(
                    'A validated reducer with this name already exists, please ensure your names are unique: ' +
                        name,
                );
            }

            const value = validatedReducers[name];

            //each value [[list of argument names as string], func], if just a func, assume
            // default argument names
            allValidatedReducers[name] =
                value instanceof Function ? [defaultReducerArgumentNames, value] : value;
        });
}

//Contains all configuration for the different filter types. You add to filter types by calling the registerFilterType function
const filterTypes = {};
const allReducers = {}; //{[actionName]: [[argument names], reducerFunc]}
export const allFilterReducers = {}; //{[actionName]: reducerFunc}
const allValidatedReducers = {}; //{[actionName]: [[argument names], validatedReducerFunc]}

//Utility methods for the filter slice
export function isFilterStateEmpty(filterConfig, filterState, ignored = false) {
    if (!filterTypes[filterConfig.type]) {
        throw new Error('Unknown filter type: ' + filterConfig.type);
    }

    return filterTypes[filterConfig.type].isFilterStateEmpty(filterConfig, filterState, ignored);
}

export function initStateForFilterFromConfig(filterConfig) {
    if (!filterTypes[filterConfig.type]) {
        throw new Error('Unknown filter type: ' + filterConfig.type);
    }

    return filterTypes[filterConfig.type].initFromConfig(filterConfig);
}

export function initErrorStateForFilterFromConfig(filterConfig) {
    if (!filterTypes[filterConfig.type]) {
        throw new Error('Unknown filter type: ' + filterConfig.type);
    }

    return filterTypes[filterConfig.type].initErrorFromConfig(filterConfig);
}

export function initPendingStateForFilterFromConfig(filterConfig) {
    if (!filterTypes[filterConfig.type]) {
        throw new Error('Unknown filter type: ' + filterConfig.type);
    }

    return filterTypes[filterConfig.type].initPendingFromConfig(filterConfig);
}

export function setErrorForFilter(filterConfig, error, state, action, fieldName = null) {
    if (!filterTypes[filterConfig.type]) {
        throw new Error('Unknown filter type: ' + filterConfig.type);
    }

    return filterTypes[filterConfig.type].setError(filterConfig, error, state, action, fieldName);
}

export function setPendingForFilter(filterConfig, isPending, state, fieldName) {
    if (!filterTypes[filterConfig.type]) {
        throw new Error('Unknown filter type: ' + filterConfig.type);
    }

    return filterTypes[filterConfig.type].setPending(filterConfig, isPending, state, fieldName);
}

export function includes(array, value, comparisonFunc) {
    if (!comparisonFunc) {
        return array.includes(value);
    }

    return !!array.find((val) => comparisonFunc(val, value));
}

export function indexOf(array, value, comparisonFunc) {
    if (!comparisonFunc) {
        return array.indexOf(value);
    }

    return array.findIndex((val) => comparisonFunc(val, value));
}

export function performValidatedReducer(name, state, arg) {
    if (!allValidatedReducers[name]) {
        throw new Error('Unknown action: ', name);
    }

    allValidatedReducers[name][1](state, arg);
}

export function checkUpdate(newState) {
    newState.update++;
    newState.noFiltersApplied = true;
    newState.numAppliedFilters = 0;

    newState.config.forEach(({sectionName, filters: filtersConfig}) => {
        let hasFiltersApplied = false;
        filtersConfig.forEach((filterConfig) => {
            if (!isFilterStateEmpty(filterConfig, newState.filters[filterConfig.filterName])) {
                hasFiltersApplied = true;
                newState.numAppliedFilters++;
            }
        });

        newState.sectionHasFiltersApplied[sectionName] = hasFiltersApplied;

        if (hasFiltersApplied) {
            newState.noFiltersApplied = false;
        }
    });

    return newState;
}

export function isValueValid(state, filterName, ...args) {
    const validateValueFunc =
        filterTypes?.[state?.allFiltersConfig?.[filterName]?.type]?.validateValue;

    return validateValueFunc ? validateValueFunc(state, filterName, ...args) : true;
}

//Is this a valid action to undergo async validation?
export function validateAsyncAction(state, filterConfig, action) {
    const validationFunc = filterTypes[filterConfig.type].validateAsyncAction;

    return validationFunc ? validationFunc(state, filterConfig, action) : true;
}

export function setPersistedFilterValue(filterConfig, currentValue, persistedValue) {
    const setPersistedValue = filterTypes[filterConfig.type].setPersistedValue;

    return setPersistedValue && setPersistedValue(filterConfig, currentValue, persistedValue);
}

export function getFuncsToBind(dispatch, getState, actions) {
    return {
        //bind actions for standard reducers
        ...mapValues(
            allReducers,
            ([argumentNames], name) =>
                (...args) =>
                    dispatch(actions[name](zipObject(argumentNames, args))),
        ),

        //bind validated reducer actions
        ...mapValues(
            allValidatedReducers,
            ([argumentNames], name) =>
                (...args) =>
                    dispatch(
                        actions.validateThenApply({
                            action: name,
                            getState,
                            ...zipObject(argumentNames, args),
                        }),
                    ),
        ),

        isValueValid: (...args) => isValueValid(getState(), ...args),
    };
}

export function getNumAppliedFilters(config, filters, ignored = false) {
    let numFiltersApplied = 0;

    config.forEach(({filters: filtersConfig}) => {
        filtersConfig.forEach((filterConfig) => {
            if (!isFilterStateEmpty(filterConfig, filters[filterConfig.filterName], ignored)) {
                numFiltersApplied++;
            }
        });
    });

    return numFiltersApplied;
}

//Useful shared constants/methods for filter types
export const BLANK_OBJ = {};
export const BLANK_ARR = [];

export const getBlankObj = () => BLANK_OBJ;
export const getBlankArr = () => BLANK_ARR;
