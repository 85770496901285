import {makeStyles} from 'tss-react/mui';
import {isV2} from 'hsi/types/theme';

export default makeStyles<void, 'addMoreButton' | 'addButton' | 'addMore'>()((theme, _params, classes) => {
    const v2 = isV2(theme);

    return {
        container: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },

        divider: {
            borderLeft: '1px #ccc solid',
            height: '24px',
            flex: '0 0 auto',
            margin: v2 ? '0px 15px 0px 10px' : '0px 14px 0px 16px',
        },

        chips: {
            marginLeft: '16px',
            flex: '1 1 0',
            minWidth: 0,
        },

        buttonWrapper: {
            ...theme.mixin.blankButton,
            outline: 'none',

            margin: v2 ? 0 : '0 14px',

            [`&.${classes.addMore}`]: {
                marginLeft: '10px', //add more button
            },

            //needed to re-create focus styles for the button
            [`&:focus-visible > .${classes.addMoreButton}, &:focus-visible > .${classes.addButton}`]:
                {
                    outline: v2
                        ? `3px solid ${theme.colors.accentActive}`
                        : `3px solid ${theme.colors.secondaryOrange100}`,
                },
        },

        addMore: {},

        addMoreButton: {
            backgroundColor: theme.colors.white,
            border: '1px solid #E4E4E4',
            padding: '4px',
            flex: '0 0 auto',
        },

        addMoreIcon: {
            fill: `${theme.colors.icons} !important`,
            width: '18px',
            height: '18px',
        },

        addButton: {
            flex: '0 0 auto',
            margin: 0,
            padding: '0 12px',
        },

        addIcon: {
            fill: theme.colors.icons,
            marginRight: '8px',
            width: v2 ? '16px' : '12px',
            height: v2 ? '16px' : '12px',
        },
    };
});
