//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useStyles from './styles';

//Other
import * as dates from 'hsi/utils/dates';
import {T} from 'hsi/i18n';
import {InputDateType} from 'hsi/types/dates';
import {TimezoneID} from 'hsi/utils/timezones';

type ExportHeaderType = {
    title: string;
    startDate: InputDateType;
    endDate: InputDateType;
    subtitle?: React.ReactNode;
    tagline?: React.ReactNode;
    timezone: TimezoneID;
};

//The component
export default function ExportHeader({
    endDate,
    title,
    startDate,
    subtitle,
    tagline,
    timezone,
}: ExportHeaderType) {
    const classes = useStyles();
    const config = useConfig();

    return (
        <div className={classes.header}>
            <h1 className={classes.title}>{title}</h1>
            {subtitle && <div data-testid="exportHeaderSubtitle">{subtitle}</div>}
            <div className={classes.dates} data-testid="exportHeaderDates">
                <time dateTime={dates.formatTo(startDate, timezone, 'yyyy-LL-ddZZ')}>
                    {dates.formatTo(startDate, timezone, 'DDD')}
                </time>{' '}
                -{' '}
                <time dateTime={dates.formatTo(startDate, timezone, 'yyyy-LL-ddZZ')}>
                    {dates.formatTo(endDate, timezone, 'DDD')}
                </time>{' '}
                {`(${dates.formatTo(endDate, timezone, 'ZZZZ')})`}
            </div>
            {tagline && (
                <div className={classes.tagline} data-testid="exportHeaderTagline">
                    {tagline}{' '}
                    {config?.hasBrandwatchLabel && (
                        <span className={classes.poweredBy}>
                            {T('exportToPDF.header.poweredBy')}
                        </span>
                    )}
                </div>
            )}
        </div>
    );
}
