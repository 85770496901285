import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles(({colors, v2}: Theme) => ({
    container: {
        '& .MuiDialog-paper': {
            width: v2 ? 552 : 600,
        },
    },
    contentWrapper: {
        backgroundColor: v2 ? colors.uiGrayDark : 'inherit',
    },
}));
