//OBSOLETE - use hsi/components/Tooltip/OverflowTooltip instead
import React, {useState, useEffect, useRef, Fragment} from 'react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';

import {checkOverflow} from './utils';

import SimpleTooltip from './SimpleTooltip';

/**
 * Gets wrapped around content that is expected to potentially overflow
 * its container (e.g. a monitor name), and displays a standard ToolTip
 * component when hovering over that content.
 */
const OverflowTooltip = ({
    active,
    children,
    distance,
    offset,
    overflowChildSelector,
    tooltip,
    visible,
}) => {
    const [isOverflown, setOverflown] = useState(false);
    const ref = useRef();

    const doCheck = () => {
        const elem = overflowChildSelector
            ? ref.current.querySelector(overflowChildSelector)
            : ref.current;
        elem && setOverflown(checkOverflow(elem, offset));
        !elem && setOverflown(false);

        return () => setOverflown(false);
    };

    const content = React.cloneElement(React.Children.only(children), {ref});

    useEffect(() => {
        doCheck();

        return () => setOverflown(false);
        // Check is you can add deps without issue
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {active && isOverflown ? (
                <SimpleTooltip distance={distance} tooltip={tooltip || content} visible={visible}>
                    {content}
                </SimpleTooltip>
            ) : (
                content
            )}
            <ReactResizeDetector handleWidth onResize={doCheck} />
        </Fragment>
    );
};

OverflowTooltip.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.any.isRequired,
    distance: PropTypes.number,
    offset: PropTypes.number,
    overflowChildSelector: PropTypes.string,
    tooltip: PropTypes.any, //TODO: Tooltip provided in multiple types
    visible: PropTypes.bool,
};

OverflowTooltip.defaultProps = {
    active: true,
    offset: 0,
};

export default OverflowTooltip;
