/**
 * Stream Service
 */
import http from '../classes/Http';

class logService {
    static async info(message) {
        return http.post('/fe-api/log', {message});
    }
}

export default logService;
