import React, {useMemo, useContext} from 'react';
import useBypassCC from './useBypassCC';
import useUrlFlags from './useUrlFlags';

import {CCFlagsContext} from 'hsi/containers/Login/CCFlagsProvider';
import {CCFlagsType} from 'hsi/types/cc-flags';

const BLANK = {};

window.desData = window.desData || {};

//Use this to override flags when doing development - will merge the supplied
//values with whatever flags would have been returned, but only when running locally
const localDevFlagOverrides: CCFlagsType = {
};

export default function useFlags(): CCFlagsType {
    const isBypassCC = useBypassCC();
    const baseURLFlags = useUrlFlags();
    const CCflags = useContext(CCFlagsContext);

    const flags = useMemo(() => {
        const baseRemoteFlags =  {...CCflags};
        return process.env.REACT_APP_DEPLOYMENT_TYPE === 'development'
            ? {...baseRemoteFlags, ...localDevFlagOverrides}
            : baseRemoteFlags;
    }, [CCflags]);

    const urlFlags = useMemo(() => {
        return process.env.REACT_APP_DEPLOYMENT_TYPE === 'development'
            ? {...baseURLFlags, ...localDevFlagOverrides}
            : baseURLFlags;
    }, [baseURLFlags]);

    if (!!isBypassCC) {
        return urlFlags || BLANK;
    }

    // This makes the flags in exports work
    // This should definitely be changed to be handled in a different way
    if (window?.desData?.flags) {
        return window?.desData?.flags;
    }

    return flags || BLANK; //helps tests that reply on flags to not break, without needing to mock flags
}

export const withFlags = (Component: React.ComponentType) => {
    const HOC = (props: any) => {
        const flags = useFlags();
        return <Component {...props} flags={flags} />;
    };
    HOC.displayName = `withFlags(${Component.displayName || Component.name})`;
    return HOC;
};
