import makeStyles from '@mui/styles/makeStyles';

import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles((theme: Theme) => {
    const v2 = isV2(theme);
    return {
        header: {
            top: '0',
            padding: v2 ? '10px 0' : '0',

            ...(v2
                ? theme.fonts.bodyLargeNormal
                : {
                      fontSize: '14px',
                      fontWeight: 'normal',
                  }),

            color: v2 ? theme.colors.textDefault : theme.colors.darkGrey100,
        },

        title: {
            ...(v2
                ? theme.fonts.display1Strong
                : {
                      fontSize: '17px',
                      fontWeight: 'bold',
                  }),
            paddingBottom: '5px',
            margin: v2 ? 0 : undefined,
        },

        dates: {
            paddingBottom: '2px',
        },

        tagline: {
            paddingBottom: '2px',
        },

        poweredBy: {
            color: v2 ? theme.colors.textSubtle : theme.colors.darkGrey60,
        },
    };
});
