import {makeStyles} from 'tss-react/mui';

export default makeStyles()((theme, _params, classes) => ({
    root: {
        '--asa-width': theme.v2 ? '16px' : '16px',
        '--asa-height': theme.v2 ? '16px' : '24px',
        position: 'relative',
        width: 'var(--asa-width)',
        height: 'var(--asa-height)',

        [`&.${classes.noValue}`]: {
            width: 'auto',
        },
    },
    noValue: {},
    wrapper: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 'var(--asa-width)',
        height: 'var(--asa-height)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        [`&.${classes.success}`]: {
            borderRadius: theme.v2 ? '100%' : null,
            background: theme.v2 ? theme.colors.success : null,
        },
    },
    icon: {
        width: 'var(--asa-height)', //this is intended
        height: 'var(--asa-height)',

        [`&.${classes.success}`]: {
            fill: theme.v2 ? '#FFF' : theme.colors.positive,

            width: theme.v2 ? '14px' : null,
            height: theme.v2 ? '14px' : null,
        },
        [`&.${classes.error}`]: {
            fill: theme.v2 ? theme.colors.error : theme.colors.negative,
            cursor: 'pointer',
        },
    },
    errorMsg: {
        padding: 8,

        '& p': {
            margin: 0,
            padding: 0,

            '&+p': {
                marginTop: 4,
            },
        },
    },
    success: {},
    error: {},

    //transition styles
    enter: {
        opacity: 0.01,

        [`&.${classes.enterActive}`]: {
            opacity: 1,
            transition: 'opacity 400ms ease-in',
        },
    },
    enterActive: {},
    exit: {
        opacity: 1,

        [`&.${classes.exitActive}`]: {
            opacity: 0.01,
            transition: 'opacity 400ms ease-in',
        },
    },
    exitActive: {},
}));
