import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);

    return {
        root: {
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
            gap: '12px',
            marginTop: v2 ? '12px' : '16px',
            color: theme.colors.darkGrey100,
            ...(v2
                ? {
                      ...theme.fonts.bodyLargeNormal,
                      lineHeight: '18px',
                  }
                : {
                      fontSize: '16px',
                      lineHeight: '20px',
                  }),
        },
        //sentiment styles
        sentimentIcon: {
            width: v2 ? '18px' : '20px',
            height: v2 ? '18px' : '20px',
            display: 'block',
        },
        neutral: {
            fill: theme.colors.neutral,
        },
        positive: {
            fill: theme.colors.positive,
        },
        negative: {
            fill: theme.colors.negative,
        },

        //generic metrics styles
        metric: {
            flex: '0 0 auto',
            fontSize: 'inherit',
            fontFamily: 'inherit',
            color: 'inherit',
            whiteSpace: 'nowrap',
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
            gap: v2 ? '6px' : '4px',
        },
        truncate: {
            flex: '0 1 auto',
            minWidth: 0,
        },
        metricBtn: theme.mixin.blankButton,
        metricEmpty: {
            color: v2 ? undefined : theme.colors.darkGrey60,

            '& svg': {
                fill: v2 ? undefined : theme.colors.darkGrey60,
            },
        },
        metricIcon: {
            flex: '1 1 auto',
            '&>svg': {
                display: 'block',
            },
        },
        metricContent: {
            flex: '1 1 auto',
            minWidth: 0,
            display: 'flex',
            alignItems: 'center',
        },
        editableMetric: {
            ...theme.mixin.blankButton,
            display: 'flex',
            gap: '12px',

            '&[disabled]': {
                opacity: 0.5,
            },
        },
        hidden: {
            display: 'none',
        },
        editableIcon: {
            width: '16px',
            height: '16px',
        },
        //menu
        menu: v2
            ? {}
            : {
                  borderWidth: '2px',
              },
        menuItem: {},
    };
});
