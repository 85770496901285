import {useCallback, useEffect, useState} from 'react';

import http from 'hsi/classes/Http';

import {parseColumns} from './columns';

const url = '/fe-api/tableLayout';

const useTableLayout = () => {
    const [columns, setColumns] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const fetch = useCallback(async () => {
        setLoading(true);

        try {
            const {body: newTableLayout} = await http.get(url);
            const newColumns = parseColumns(newTableLayout);
            setColumns(newColumns);
        } catch (newError) {
            setError(newError);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return {
        columns,
        loading,
        error,
    };
};

export default useTableLayout;
