import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);

    return {
        chip: {
            '&:focus-visible': {
                outline: v2
                    ? `3px solid ${theme.colors.accentActive}`
                    : `3px solid ${theme.colors.secondaryOrange100}`,
                outlineOffset: '-3px',
            },
        },

        chipWrapper: {
            display: 'inline-flex',
            '& > div': {
                display: 'none',
            },
        },
    };
});
