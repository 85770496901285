import {useCallback, useEffect, useMemo, useState} from 'react';
import pick from 'lodash/pick';

import filtersService from 'hsi/services/filtersService';

import {quickFiltersSupported} from 'hsi/utils';

const useFilterOptions = ({searchType}) => {
    const [error, setError] = useState();
    const [filterOptions, setFilterOptions] = useState();
    const [loading, setLoading] = useState(false);

    const quickFilterParser = useCallback(
        (data) => ({
            filters: pick(data?.filters, quickFiltersSupported),
            metadata: pick(data?.metadata, quickFiltersSupported),
        }),
        [],
    );

    const filterParsers = useMemo(
        () => ({
            quick: quickFilterParser,
        }),
        [quickFilterParser],
    );

    const parseFilterOptions = useCallback(
        (data) => {
            const parser = filterParsers[searchType];

            if (typeof parser !== 'function') return data;

            return parser(data);
        },
        [filterParsers, searchType],
    );

    const fetch = useCallback(async () => {
        setLoading(true);

        try {
            const newData = await filtersService.loadFilterOptions();
            const newFilterOptions = parseFilterOptions(newData);
            setFilterOptions(newFilterOptions);
        } catch (newError) {
            setError(newError);
        } finally {
            setLoading(false);
        }
    }, [parseFilterOptions]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return {
        filterOptions,
        loading,
        error,
    };
};

export default useFilterOptions;
