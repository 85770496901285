import { isV2 } from 'hsi/types/theme';
import { makeStyles } from 'tss-react/mui';

export default makeStyles<void, 'linkText' | 'large' | 'small'>()(
    (theme, _params, classes) => {
        const v2 = isV2(theme);
        const {colors, font, fonts} = theme;

        return {
            large: {},
            small: {},
            linkText: {
                margin: v2 ? 'unset' : -6,
                padding: v2 ? 'unset' : 6,
            },
            navItem: {
                ...(v2 ? fonts.headlineStrong : {fontSize: '24px', fontWeight: font.regular}),
                color: v2 ? `${colors.textSubtle} !important` : `${colors.darkGrey100} !important`,
                display: 'inline-block',
                whiteSpace: 'nowrap',
                height: '100%',
                textDecoration: 'none !important',
                position: 'relative',
                background: 'transparent 100% bottom/100% 4px no-repeat',
                '&:hover': {
                    backgroundImage: v2
                        ? null
                        : `linear-gradient(to right, ${colors.primaryBlue60}, ${colors.primaryBlue60} )`,
                },
                '&:focus-visible': {
                    outline: v2 ? null : 'none',
                },
                [`&:focus-visible > .${classes.linkText}`]: {
                    outline: v2 ? null : `2px solid ${colors.secondaryOrange100}`,
                },
                '&::after': {
                    display: 'block',
                    content: 'attr(data-text)',
                    fontWeight: v2 ? null : font.bold,
                    height: 0,
                    overflow: 'hidden',
                    visibility: 'hidden',
                },

                [`&.${classes.large}`]: {
                    //margin: '0px 48px 0 0',
                    padding: v2 ? '16px 0px' : '20px 0px',
                    ...(v2
                        ? fonts.headlineStrong
                        : {
                            fontSize: '24px',
                            fontWeight: font.regular,
                        }),
                },

                [`&.${classes.small}`]: {
                    //marginRight: v2 ? 16 : 20,
                    padding: v2 ? '12px 0px' : '7px 0px',
                    ...(v2
                        ? fonts.headtitleNormal
                        : {
                            fontSize: 24,
                            fontWeight: font.regular,
                        }),
                },
                '&.active': {
                    color: v2 ? `${colors.textDefault} !important` : `${colors.darkGrey100} !important`,
                    fontWeight: v2 ? null : font.bold,
                    backgroundImage: v2
                        ? `linear-gradient(to right, ${colors.accentDefault}, ${colors.accentDefault})`
                        : `linear-gradient(to right, ${colors.primaryBlue100}, ${colors.primaryBlue100})`,
                    letterSpacing: 0,
                },
            },
        };
    }
);
