import {ReactElement, Ref, forwardRef, useMemo} from 'react';
import {LineChart, Line} from 'recharts';
import {useTheme} from '@mui/material/styles';

//Other
import useStyles from './styles';
import { SparklineTrend, SparklineSeries } from './types';
import { T } from 'hsi/i18n';
import Tooltip from '../Tooltip';



export type SparklineProps<TDataKey extends string> = {
    animate?: boolean;
    color?: string;
    dataKey: string;
    height: number;
    width: number;
    withTrend?: boolean;
    series: SparklineSeries<TDataKey>;
} & JSX.IntrinsicElements['div'];

//The component
const Sparkline = forwardRef<HTMLDivElement, SparklineProps<string>>(function Sparkline(
    {
        series, 
        color: _color, 
        dataKey, 
        width, 
        height, 
        animate = false, 
        withTrend: _withTrend = false, 
        className, 
        ...rest
    },
    ref,
) {
    const {classes, cx} = useStyles();
    const {colors} = useTheme();

    const color = _color ?? colors.keyline;

    const [trend, withTrend] = useGetTrend(dataKey, series, _withTrend);

    if (!width || !height) return null;
    
    return (
        <>
            <Tooltip
                noAria
                tooltip={T(`sparkline.trend.${trend}`)}
                disable={!withTrend}
            >
                <div
                    className={cx(classes.sparklineWrapper, className)}
                    data-testid="sparkline"
                    {...rest}
                    aria-hidden
                    ref={ref}
                >
                    <LineChart
                        width={width}
                        height={height}
                        data={series}
                        margin={{top: 1, right: withTrend ? 16 : 0, bottom: 1, left: 0}}
                        className={classes.sparkline}
                    >
                        <Line
                            dataKey={dataKey}
                            stroke={color}
                            dot={false}
                            isAnimationActive={animate}
                            strokeWidth={2}
                        />
                    </LineChart>
                    {withTrend && <div style={{borderColor: color}} className={cx(classes.trend, classes[trend!])} />}
                </div>
            </Tooltip>
            {withTrend && (<div className="offscreen">{T(`sparkline.trend.${trend}`)}</div>)}
        </>
    );
});

export default Sparkline as <T extends string>(p: SparklineProps<T> & { ref?: Ref<HTMLDivElement> }) => ReactElement;

function useGetTrend<TDataKey extends string>(dataKey: TDataKey, series: SparklineSeries<TDataKey>, withTrend: boolean): Readonly<[SparklineTrend | null, boolean]> {
    return useMemo(() => {
        if (withTrend && series.length > 1) {
            const lastButOne = series[series.length - 2][dataKey];
            const lastOne = series[series.length - 1][dataKey];
    
            if (lastOne < lastButOne) {
                return ['down', true] as const;
            } else if (lastOne > lastButOne) {
                return ['up', true] as const;
            } else {
                
                return ['same', true] as const;
            }
        } else {
            return [null, false] as const;
        }
    }, [dataKey, series, withTrend]);
}

