import cn from 'classnames';
import IconButton from '@mui/material/IconButton';

//Components
import IconRouter from 'hsi/components/IconRouter';

//Other
import useStyles from './styles';
import {T} from 'hsi/i18n';

export type DialogCloseButtonProps = {
    className?: string;
    closeLabel?: string;
    onClose: () => void;
} & Omit<Parameters<typeof IconButton>[0], 'onClick' | 'aria-label'>;

//The component
export default function DialogCloseButton({
    onClose,
    className,
    closeLabel = 'dialog.close',
    ...rest
}: DialogCloseButtonProps) {
    const classes = useStyles();

    return (
        <IconButton
            aria-label={T(closeLabel)}
            className={cn(classes.button, className)}
            onClick={onClose}
            {...rest}
            size="large">
            <IconRouter name="close" suppressIconClassName className={classes.icon} />
        </IconButton>
    );
}
