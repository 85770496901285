import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
    root: {
        maxWidth: '720px',
        margin: 'auto',
        '& > *': {
            breakInside: 'avoid',
            marginBottom: '20px',
        },
    },
    markdown_p: {
        '&&': {
            //stupid nonsense to override default class
            fontSize: 'inherit',
        },
    },
}));
