import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    table: {
        backgroundColor: 'white',
        fontFamily: 'Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
        fontSize: 12,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 4px',
        width: '100%',
        height: '100%',
    },
    loader: {
        height: 64,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    row: {
        minHeight: 64,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        overflow: 'hidden',
    },
    firstRow: {
        marginTop: 35,
    },
    rowHighlight: {
        backgroundColor: '#f7f7f7',
    },
    cell: {
        overflow: 'hidden',
        padding: 4,
        textOverflow: 'ellipsis',
    },
    datumItem: {
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
    },
    headerContainer: {
        overflow: 'hidden',
        paddingBottom: 3,
        position: 'fixed',
        top: 0,
        width: 'calc(100% - 8px)', //mentions table padding
    },
    header: {
        height: 35,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        //replicate HS table style
        color: '#454b53',
        backgroundColor: 'white',
        fontWeight: 700,
        whiteSpace: 'nowrap',
        textTransform: 'capitalize',
        boxShadow: '0 0 3px rgba(0, 0, 0, 0.25)',

        '&:hover': {
            color: '#17191c',
            cursor: 'pointer',
        },
    },
    sortArrow: {
        position: 'fixed',
        top: 13,
        width: 10,
        height: 10,
        marginLeft: 3,
    },
    tags: {
        marginTop: 3,
        marginBottom: 0,
    },
    tagChip: {
        backgroundColor: '#FDE2D3',
    },
}));

export default useStyles;
