import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IconButton } from '@mui/material';

//Components

import Heading, { HeadingContents } from 'hsi/components/aria/Heading';
import AIChip from 'hsi/components/AIChip';
import IconRouter from 'hsi/components/IconRouter';
import Tooltip from 'hsi/components/Tooltip';
import PulseLoader from 'hsi/components/PulseLoader';
import Markdown from 'hsi/components/Markdown';
import CopyToClipboard from 'hsi/components/CopyToClipboard';
import Notification from 'hsi/components/Notification';
import Button from 'hsi/components/Button';

//hooks
import useFlags from 'hsi/hooks/useFlags';
import useGetClientAIConsent from 'hsi/hooks/useGetClientAIConsent';
import usePersistLocal from 'hsi/hooks/usePersistLocal';
import useUniqueId from 'hsi/hooks/useUniqueId';
import useQueryContext from 'hsi/hooks/useQueryContext';
import { useAppSelector } from 'hsi/hooks/useRedux';

//Other
import useStyles from './styles';
import {T} from 'hsi/i18n';
import { getIrisSummary } from 'hsi/services/mentionsService';
import readStreamAsString from 'hsi/utils/bytes/readStreamAsString';
import { QueryContextType } from 'hsi/types/query';
import { selectAdditionalQueries, selectLinkedinChannelIds } from 'hsi/selectors';
import { DateRange, FiltersState } from 'hsi/types/filters';
import { addDrillInToFiltersState } from 'hsi/utils/filters';
import useMemoCompare from 'hsi/hooks/useMemoCompare';
import { isEqual } from 'lodash';

//Types
export type ConversationSummaryProps = {};

//Consts
const INFO_DISMISSED = 'irisConversationInsightsInfoDismissed';

//The component
export default function ConversationSummary(props: ConversationSummaryProps) {
    const summaryId = useUniqueId();
    const classes = useStyles();
    const {irisConversationInsightsEnabled} = useFlags();
    const clientAIConsent = useGetClientAIConsent();
    const {isInfoDismissed, dismissInfo} = useIsInfoDismissed();
    const [isOpen, setIsOpen] = useState(false);
    const [status, setStatus] = useState<'idle' | 'pending' | 'reading' | 'complete' | 'error'>('idle');
    const [response, setResponse] = useState('');
    const outputElemRef = useRef<HTMLDivElement| null>(null);
    const abortControllerRef = useRef<AbortController | null>(null);
    
    const queryContext = useQueryContext() as QueryContextType;
    const allFilteringState = useAppSelector((state) => state.filters);
    const filtersConfig = useAppSelector((state) => state.filters.allFiltersConfig);

    const drillInFilter: FiltersState | undefined = useAppSelector(
        (state) => state.mentions.drillInFilter,
    );
    const drillInDates: DateRange | undefined = useAppSelector(
        (state) => state.mentions.drillInDates,
    );
    const additionalQueries = useAppSelector(selectAdditionalQueries);
    const linkedinChannelIds = useAppSelector(selectLinkedinChannelIds);

    const drilledInFilters = useMemoCompare(
        () => addDrillInToFiltersState(allFilteringState, drillInFilter, drillInDates), 
        [allFilteringState, drillInDates, drillInFilter],
        //filters state contains several props that can change, but would not affect which mentions are shown, e.g. collapsedSections.
        //So, we use this method to check if props we actually care about have changed, and if not we keep the old value to 
        //maintain reference equality (which is how we determine if something has changed)
        (value, prevValue) => {
            if(!prevValue) {
                return false;
            }

            //If a prop that would affect the output has not changed
            if(isEqual(value.dateRange, prevValue.dateRange) && isEqual(value.filters, prevValue.filters)) {
                return true;//keep the old object & maintain reference equality
            }

            return false;
        }
    );
    
    //Callbacks
    const doRequest = useCallback(async () => {
        if(abortControllerRef.current !== null) {
            abortControllerRef.current.abort("cancelled");
            abortControllerRef.current = null;
        }

        setStatus('pending');

        try {
            abortControllerRef.current = new AbortController();

            const response = await getIrisSummary({
                queryContext,
                dateRange: drilledInFilters.dateRange,
                filters: drilledInFilters.filters,
                filtersConfig,
                additionalQueries,
                linkedinChannelIds,
                signal: abortControllerRef.current.signal,
            });

            if(response.ok) {
                    await readStreamAsString(response.body!, (str) => {
                        setStatus('reading');
                        setResponse(str);
                    });

                    setStatus('complete');
            } else {
                setStatus('error');
            }
        }
        catch(e) {
            abortControllerRef.current = null;

            //ignore errors generated because the load was aborted
            if(!(e instanceof DOMException && e.name === "AbortError") && e !== 'cancelled') {
                setStatus('error');
            }
        }

        //finally, clear the abort controller
        abortControllerRef.current = null;
    }, [queryContext, drilledInFilters.dateRange, drilledInFilters.filters, filtersConfig, additionalQueries, linkedinChannelIds]);

    const onShowClick = useCallback(() => {
        setIsOpen(true);

        if(status !== 'idle') {
            return;
        }
        
        doRequest();
    }, [doRequest, status]);

    const onHideClick = useCallback(() => {
        if(abortControllerRef.current !== null) {
            abortControllerRef.current.abort("cancelled");
            abortControllerRef.current = null;
        }

        setIsOpen(false);
        setStatus('idle');
        setResponse('');
    }, []);

    const onRetryClick = useCallback(() => {
        if(status !== 'error') {
            return;
        }

        //move focus, so it is not lost
        outputElemRef.current?.focus();

        doRequest();
    }, [doRequest, status]);

    //Side effects
    //-if mentions changed (due to filters changing, drill-ins or changed date range), close & clear (if open)
    useEffect(() => {
        isOpen && onHideClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drilledInFilters])

    //Unless this is a saved search & the user has the flag enabled && has consented to AI features, show nothing
    if(!queryContext.isSavedSearch || clientAIConsent !== true || irisConversationInsightsEnabled !== true) {
        return null;
    }

    return <div className={classes.root}>
        <div className={classes.header}>
            <Heading className={classes.heading}>{T('irisConversationInsights.heading')} <AIChip /></Heading>
            <Button
                aria-expanded={isOpen}
                aria-controls={summaryId}
                aria-label={isOpen 
                    ? T('irisConversationInsights.closeCTAA11y') || T('irisConversationInsights.closeCTA') 
                    : T('irisConversationInsights.openCTAA11y') || T('irisConversationInsights.openCTA')
                }
                onClick={isOpen 
                    ? onHideClick 
                    : onShowClick}
                priority={isOpen ? 'primary' : 'cta'}
                className={classes.btn}
            >
                <span aria-hidden>{
                    T(isOpen ? 'irisConversationInsights.closeCTA' : 'irisConversationInsights.openCTA')
                }</span>
            </Button>
        </div>
        <HeadingContents>
            {isOpen && <div id={summaryId}>
                {!isInfoDismissed && <div className={classes.info}>
                    <p className={classes.infoHeading}>{T('irisConversationInsights.infoHeading')}</p>
                    <HeadingContents>
                        <p className={classes.infoCopy}>{T('irisConversationInsights.infoCopy')}</p>
                        <div className={classes.infoDismiss}>
                            <Tooltip tooltip={T('irisConversationInsights.infoDismissTooltip')} noAria>
                                <IconButton
                                    aria-label={T('irisConversationInsights.infoDismissA11y')}
                                    className={classes.infoDismissBtn}
                                    onClick={dismissInfo}
                                >
                                    <IconRouter name="cross" className={classes.infoDismissBtnIcon} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </HeadingContents>
                </div>}
                    
                <div className={classes.output} tabIndex={-1} ref={outputElemRef}>
                    {status === 'pending' && <PulseLoader className={classes.loadIndicator} message={T('loading')} />}
                    {status !== 'error' 
                        ? <Markdown className={classes.results} aria-live="polite" aria-busy={status === 'reading'}>{response}</Markdown>
                        : <Notification
                            className={classes.errorNotification}
                            title={T('irisConversationInsights.error.title')} 
                            description={T('irisConversationInsights.error.description')}
                            retry={onRetryClick}
                            retryDesc={T('irisConversationInsights.error.retryDesc')}
                            role="error"
                        />}
                    <CopyToClipboard className={classes.copyToClipboard} disabled={status !== 'complete'} value={response} />
                </div>
            </div>}

        </HeadingContents>
    </div>
}

function useIsInfoDismissed() {
    const [isInfoDismissed, setIsInfoDismissed] = useState(false);
    const {getItem, setItem} = usePersistLocal();

    getItem<boolean>(INFO_DISMISSED).then((result) => {
        result && setIsInfoDismissed(result);
    });

    return useMemo(() => ({
        isInfoDismissed,
        dismissInfo: () => {
            setIsInfoDismissed(true);
            setItem<boolean>(INFO_DISMISSED, true);
        }
    }), [setItem, isInfoDismissed])
}
