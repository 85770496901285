import isObject from 'lodash/isObject';
import {registerFilterType, isValueValid} from './../utils';

//Utils
import isNullOrUndefined from 'hsi/utils/isNullOrUndefined';

const DEFAULT_RANGE = {
    min: null,
    max: null,
};

const getDefaultRange = () => DEFAULT_RANGE;

registerFilterType(
    'range',
    getDefaultRange,
    (filterConfig, filterState) =>
        !filterState || (filterState.min === null && filterState.max === null),
    {}, //reducers
    {
        //validated reducers func(state, payload)
        setRangeMin: (state, {filterName, value}) => {
            state.filters[filterName].min = value;

            if (state.filters[filterName].max !== null && +state.filters[filterName].max < +value) {
                state.filters[filterName].max = value;
            }
        },
        setRangeMax: (state, {filterName, value}) => {
            state.filters[filterName].max = value;

            if (state.filters[filterName].min !== null && +state.filters[filterName].min > +value) {
                state.filters[filterName].min = value;
            }
        },
    },
    (state, filterName, value) => {
        const filterConfig = state.allFiltersConfig[filterName];

        if (!isNullOrUndefined(filterConfig.minValue) && value < filterConfig.minValue) {
            return filterConfig.minError || 'filters.validation.rangeMin';
        }

        if (!isNullOrUndefined(filterConfig.maxValue) && value > filterConfig.maxValue) {
            return filterConfig.maxError || 'filters.validation.rangeMax';
        }

        return true;
    },
    (state, filterConfig, {action, value, filterName}) => {
        if (action !== 'setRangeMin' && action !== 'setRangeMax') {
            throw new Error(
                'Invalid action for this filter type, check you have the correct filterName and/or are using the correct action',
            );
        }

        return isValueValid(state, filterName, value);
    },
    getDefaultRange,
    getDefaultRange,
    (filterConfig, error, state, action, fieldName) => {
        state.error[filterConfig.filterName][action === 'setRangeMax' ? 'max' : 'min'] = error;
    },
    undefined,

    undefined,
    undefined,
    undefined,
    undefined,
    (filterConfig, currentValue, persistedValue) => {
        if (isObject(persistedValue)) {
            const keys = Object.keys(persistedValue);

            if (keys.length === 2 && keys.includes('min') && keys.includes('max')) {
                return {
                    min:
                        persistedValue.min === null || !isNaN(+persistedValue.min)
                            ? persistedValue.min
                            : null,
                    max:
                        persistedValue.max === null || !isNaN(+persistedValue.max)
                            ? persistedValue.max
                            : null,
                };
            }
        }

        return currentValue;
    },
);
