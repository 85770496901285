import {excludeConfig} from './excludeConfig';
import {parseTerms, parseLinks, parseHashtags} from 'hsi/utils/formulaParser';
import {brandContextComponent} from './brandConfig';

import tns from 'hsi/i18n/tns';
const t = tns('queryBuilder');

const campaignIncludeConfig = {
    name: 'include',
    label: 'include',
    operator: 'OR',
    title: t('includeTitle'),
    components: [
        brandContextComponent,

        {
            name: 'terms',
            type: 'input-chips',
            title: t('campaignTermsTitle'),
            subtitle: t('campaignTermsSubtitle'),
            placeholder: t('campaignTermsPlaceholder'),
            parseFormula: parseTerms,
            chipElemProps: {
                newChipChars: ',',
            },
        },

        {
            name: 'links',
            type: 'input-chips',
            title: t('campaignLinksTitle'),
            subtitle: t('campaignLinksSubtitle'),
            placeholder: t('campaignLinksPlaceholder'),
            parseFormula: parseLinks,
        },

        {
            name: 'hashtags',
            type: 'input-hashtags',
            title: t('campaignHashtagsTitle'),
            subtitle: t('campaignHashtagsSubtitle'),
            placeholder: t('campaignHashtagsPlaceholder'),
            parseFormula: parseHashtags,
        },
    ],
};

const campaignConfig = {
    sections: [campaignIncludeConfig, excludeConfig],
};

export {campaignConfig};
