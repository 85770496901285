import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Popover from '@mui/material/Popover';
import {DateCalendar} from '@mui/x-date-pickers/DateCalendar';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

import Button from 'hsi/components/Button';

import usePrevious from 'hsi/hooks/usePrevious';
import useReports from 'hsi/hooks/useReports';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    toggleButton: {
        '& .MuiButton-label': {
            textTransform: 'capitalize',
        },
    },
    paper: {
        marginTop: '10px',
    },
}));

const DatePicker = ({disabled, open: _open}) => {
    const cl = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(_open);

    const prevDisabled = usePrevious(disabled);
    const {report, setStartDate} = useReports();

    const anchorRef = useCallback((el) => {
        if (el !== null) setAnchorEl(el);
    }, []);

    const disabledChange = disabled !== prevDisabled;
    const isOpen = !!anchorEl && ((disabledChange && !disabled) || open);

    const onChange = useCallback(
        (date) => {
            setStartDate(date);
            setOpen(false);
        },
        [setStartDate],
    );

    return (
        <div ref={anchorRef}>
            <Button
                aria-label="Start Date"
                className={cl.toggleButton}
                disabled={disabled}
                endIcon={<ExpandMoreIcon />}
                onClick={() => setOpen(!open)}
            >
                {report.startDate.format('D MMM')}
            </Button>

            {isOpen && (
                <Popover
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    classes={{
                        paper: cl.paper,
                    }}
                    data-testid="reportDatePicker"
                    onClose={() => setOpen(false)}
                    open={isOpen}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateCalendar
                            disablePast
                            format="YYYY-MM-DD"
                            onChange={onChange}
                            value={report.startDate}
                            disabled={disabled}
                        />
                    </LocalizationProvider>
                </Popover>
            )}
        </div>
    );
};

DatePicker.propTypes = {
    disabled: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
};

export default DatePicker;
