import classNames from 'classnames';
import {MergeTypesLeft, RemoveIndex} from 'hsi/types/shared';
import {combine} from 'hsi/utils/func';
import {FocusEvent, FocusEventHandler, ForwardedRef, JSXElementConstructor, LegacyRef, ReactNode, ReactElement, isValidElement} from 'react';

export default function reactNodeAsElement<Props = any>(node: ReactNode): ReactElement<Props, string | JSXElementConstructor<any>> {
    if(!isValidElement<Props>(node)) {
        throw new Error('Supplied value is not a valid react element');
    }

    return node;
}

//Needed to add LegacyRef type to allow use with IntrinsicElement types, although they do not actually work
export function mergeRefs<T extends Element>(...refs: (LegacyRef<T> | undefined)[]) {
    const filteredRefs = refs.filter((ref) => {
        if (typeof ref === 'string') {
            throw new Error('Legacy string refs are not supported by mergeRefs');
        }

        return !!ref;
    }) as NonNullable<ForwardedRef<T>>[];

    if (filteredRefs.length === 0) {
        return null;
    } else if (filteredRefs.length === 1) {
        return filteredRefs[0];
    }

    return (elem: T | null) => {
        filteredRefs.forEach((ref) => {
            ref instanceof Function ? ref(elem) : (ref.current = elem);
        });
    };
}

export function assignRef<T>(ref: ForwardedRef<T>, value: T | null) {
    if (!ref) {
        return;
    }

    ref instanceof Function ? ref(value) : (ref.current = value);
}

//TODO these do not work with portals at all
export function focusEnter<T extends Element>(func: FocusEventHandler<T>) {
    return (e: FocusEvent<T>) => !e.currentTarget.contains(e.relatedTarget) && func(e);
}

export function focusLeave<T extends Element>(func: FocusEventHandler<T>) {
    return (e: FocusEvent<T>) => !e.currentTarget.contains(e.relatedTarget) && func(e);
}

//combineProps - merge aritrary props with some standard rules:
//-className is concatenated into valid set of css classes
//-style gets flat merged in argument order
//-ref gets mergeRef'd
//-functions values get combined (don't mix functions with truthy non-functions)

type PropObjType = {
    [key: string]: any;

    ref?: React.LegacyRef<Element>;
    className?: string;
    style?: React.CSSProperties;
};

type CombinePropsArg<T> = Partial<T> | null | undefined | false;

//If any of the props in T are part of PropObjType (except the index access props), replace their types
//With those from PropObjType.
//TODO only make optional if T[K] is also optional? Not sure if that matters?
type CombinePropsGeneraliseType<T> = {
    //[Key in Exclude<keyof T, keyof RemoveIndex<PropObjType>>]: T[Key]
    [Key in keyof T as Exclude<Key, keyof RemoveIndex<PropObjType>>]: T[Key];
} & {[Key in Extract<keyof RemoveIndex<PropObjType>, keyof T>]: PropObjType[Key]};

// type T1B = {className?: 'fish'}
// type T1 = CombinePropsGeneraliseType<T1B>;
// const t1: T1 = {className: undefined};
// const t2: T1B = {};
// console.log(t1.className, t2.className);

//Merge the types to the left, then finally use CombinePropsGeneraliseType to prevent clashing 'className' etc declarations
type CombinePropsReturnType<
    T1 extends PropObjType,
    T2 extends PropObjType = T1,
    T3 extends PropObjType = T2,
    T4 extends PropObjType = T3,
    T5 extends PropObjType = T4,
> = CombinePropsGeneraliseType<
    MergeTypesLeft<T1, MergeTypesLeft<T2, MergeTypesLeft<T3, MergeTypesLeft<T4, T5>>>>
>;

export function combineProps<T1 extends PropObjType, T2 extends PropObjType = T1>(
    arg1: CombinePropsArg<T1>,
    arg2: CombinePropsArg<T2>,
): CombinePropsReturnType<T1, T2>;
export function combineProps<
    T1 extends PropObjType,
    T2 extends PropObjType = T1,
    T3 extends PropObjType = T2,
>(
    arg1: CombinePropsArg<T1>,
    arg2: CombinePropsArg<T2>,
    arg3: CombinePropsArg<T3>,
): CombinePropsReturnType<T1, T2, T3>;
export function combineProps<
    T1 extends PropObjType,
    T2 extends PropObjType = T1,
    T3 extends PropObjType = T2,
    T4 extends PropObjType = T3,
>(
    arg1: CombinePropsArg<T1>,
    arg2: CombinePropsArg<T2>,
    arg3: CombinePropsArg<T3>,
    arg4: CombinePropsArg<T4>,
): CombinePropsReturnType<T1, T2, T3, T4>;
export function combineProps<
    T1 extends PropObjType,
    T2 extends PropObjType = T1,
    T3 extends PropObjType = T2,
    T4 extends PropObjType = T3,
    T5 extends PropObjType = T4,
>(
    arg1: CombinePropsArg<T1>,
    arg2: CombinePropsArg<T2>,
    arg3: CombinePropsArg<T3>,
    arg4: CombinePropsArg<T4>,
    arg5: CombinePropsArg<T5>,
): CombinePropsReturnType<T1, T2, T3, T4, T5>;

export function combineProps(...args: CombinePropsArg<PropObjType>[]) {
    const props = {} as any;

    for (let i = 0, l = args.length; i < l; ++i) {
        const addProps = args[i];

        if (addProps) {
            //This argument is truthy, should be an object
            for (
                let i2 = 0, keys = Object.keys(addProps) as string[], kl = keys.length;
                i2 < kl;
                ++i2
            ) {
                let key = keys[i2];
                let value = addProps[key];

                switch (key) {
                    case 'ref':
                        props.ref = mergeRefs<Element>(props.ref!, value) ?? undefined;
                        break;
                    case 'className':
                        props.className = classNames(props[key], value);
                        break;
                    case 'style':
                        props.style = {...props.style, ...value};
                        break;
                    default:
                        props[key] = (
                            (value as any) instanceof Function ? combine(props[key], value) : value
                        ) as any;
                }
            }
        }
    }

    return props;
}
