import {useCallback, useState, useMemo} from 'react';
import classNames from 'classnames';
import {AutocompleteFreeSoloValueMapping, PopperProps} from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import MuiAutocomplete, {
    AutocompleteProps as MuiAutocompleteProps,
    AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';

//Components
import IconRouter from 'hsi/components/IconRouter';
import TextField, {TextFieldProps} from 'hsi/components/TextField';

//Other
import {T} from 'hsi/i18n';
import useStyles, {
    noOptionsStyles as useNoOptionsStyles,
    inlinePopperStyles as useInlinePopperStyles,
    defaultInputStyles as useDefaultInputStyles,
} from './styles';

//Types
export type RenderOptionComponentType<T> = (props: {
    option: T;
    inputValue: string;
    selected: boolean;
    disabled: boolean;
    /**
     * Is this option highlighted by keyboard interactions
     */
    isHighlighted: boolean;
    parts: {text: string; highlight: boolean}[];
}) => React.ReactElement;

export interface AutocompleteProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
> extends Omit<
        MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
        'renderInput' | 'renderOption'
    > {
    getOptionLabel: (option: T | AutocompleteFreeSoloValueMapping<FreeSolo>) => string
    noOptionsText?: React.ReactNode;

    renderInput?: (args: {
        onChange: MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['onChange'];
        params: AutocompleteRenderInputParams;
        placeholder?: string;
        required?: boolean;
        'aria-errormessage'?: string;
    }) => React.ReactNode;
    required?: boolean;
    renderOptionComponent?: RenderOptionComponentType<T>;
}

//The component
function Autocomplete<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
>({
    getOptionLabel,
    onChange,
    noOptionsText = <DefaultNoOptionsComponent />,
    renderInput = DefaultInputComponent,
    renderOptionComponent: RenderOptionComponent = DefaultOptionComponent,
    forcePopupIcon = false,
    placeholder,
    onHighlightChange: onHighlightChangeArg,
    getOptionDisabled,
    classes: _classes,
    required = false,
    'aria-errormessage': ariaErrorMessage,
    ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
    const baseClasses = useStyles().classes as typeof _classes;
    const classes = useMemo(() => ({...baseClasses, ..._classes}), [_classes, baseClasses]);

    const [keyboardHighlightedItem, setKeyboardHighlightedItem] = useState<T | null>();

    const renderInputFunc = useCallback(
        (params: AutocompleteRenderInputParams) => {
            return renderInput({
                onChange,
                params,
                placeholder,
                required,
                'aria-errormessage': ariaErrorMessage,
            });
        },
        [ariaErrorMessage, onChange, placeholder, renderInput, required],
    );

    const onHighlightChange: NonNullable<
        MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['onHighlightChange']
    > = useCallback(
        (event, option, reason) => {
            if (reason === 'keyboard') {
                setKeyboardHighlightedItem(option);
            } else if (reason === 'auto') {
                setKeyboardHighlightedItem(option);
            }

            onHighlightChangeArg?.(event, option, reason);
        },
        [onHighlightChangeArg],
    );

    const renderOption: NonNullable<
        MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['renderOption']
    > = useCallback(
        (props, option, {inputValue, selected}) => {
            const optionString = getOptionLabel(option);
            const matches = match(optionString, inputValue, {insideWords: true});
            const parts = parse(optionString, matches);

            return (
                <li {...props}>
                    <RenderOptionComponent
                        option={option}
                        parts={parts}
                        selected={selected}
                        inputValue={inputValue}
                        isHighlighted={option === keyboardHighlightedItem}
                        disabled={getOptionDisabled ? getOptionDisabled(option) : false}
                    />
                </li>
            );
        },
        [RenderOptionComponent, getOptionDisabled, getOptionLabel, keyboardHighlightedItem],
    );

    return (
        <MuiAutocomplete
            //ref={ref}//TODO?
            classes={classes}
            getOptionDisabled={getOptionDisabled}
            getOptionLabel={getOptionLabel}
            noOptionsText={noOptionsText}
            onChange={onChange}
            renderInput={renderInputFunc}
            renderOption={renderOption}
            onHighlightChange={onHighlightChange}
            {...props}
        />
    );
}

export default Autocomplete;

//Components
export function InlinePopperComponent({
    anchorEl,
    open,
    children,
    className,
    ...other
}: PopperProps) {
    const {classes} = useInlinePopperStyles();

    if (children instanceof Function) {
        throw new Error(
            'InlinePopperComponent does not support children parameter of type "function"',
        );
    }

    return (
        <div className={classNames(className, classes.inlinePopper)} {...other}>
            {children}
        </div>
    );
}

export const DefaultOptionComponent: RenderOptionComponentType<any> = ({
    parts,
}: {
    parts: ReturnType<typeof parse>;
}) => {
    return parts.map((part, index) => {
        if (part.highlight) {
            return <strong key={index}>{part.text}</strong>;
        } else {
            return part.text;
        }
    }) as any as React.ReactElement; //This cast is needed because react types incorrectly handle components that return arrays
};

interface DefaultInputComponentProps extends Partial<TextFieldProps> {
    params: AutocompleteRenderInputParams;
    onChange: any;
}

export const DefaultInputComponent = ({
    params,
    'aria-errormessage': ariaErrorMessage,
    ...rest
}: DefaultInputComponentProps) => {
    const {classes} = useDefaultInputStyles();

    return (
        <TextField
            autoFocus
            {...{
                error: !!ariaErrorMessage,
                ...params,
                ...rest,
                InputProps: {
                    ...params.InputProps,
                    startAdornment: <IconRouter className={classes.inputIcon} name="search" />,
                },
                inputProps: {
                    ...params.inputProps,
                    'aria-errormessage': ariaErrorMessage,
                },
                ref: params.InputProps.ref,
            }}
        />
    );
};

export function DefaultNoOptionsComponent() {
    const {classes} = useNoOptionsStyles();

    return (
        <div role="status" className={classes.noOptions} aria-live="polite">
            <IconRouter className={classes.noOptionsIcon} name="search" aria-hidden />
            <div>
                <strong className={classes.noOptionsHeader}>
                    {T('autocomplete.noOptions.defaultTitle')}
                </strong>
            </div>
            <div>{T('autocomplete.noOptions.defaultDesc')}</div>
        </div>
    );
}
