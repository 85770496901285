import React, {memo, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {BarChart, Bar, XAxis, YAxis, Customized, CartesianGrid} from 'recharts';
import upperfirst from 'lodash/upperFirst';

//Components
import ChartTooltip from 'hsi/components/ChartTooltip';
import ChartTooltipContent from 'hsi/components/ChartTooltipContent';
import {BenchmarkLine, CustomBar} from './CustomBarWithTarget';
import CustomTick from './CustomTick';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import {useTheme} from '@mui/material/styles';

//Utils
import {formatBigIntFull, formatBigInt} from 'hsi/utils/formatNumber';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

const BenchmarkBarChart = ({animate, barSize, breakdown, data, height, onClick, width}) => {
    const {themeColors} = useConfig();
    const {classes} = useStyles();
    const {TrendingIcon} = useTheme().components;

    const [_tooltip, setTooltip] = useState({tooltip: null, lastId: null});
    const [hoveredDataKey, setHoveredDatakey] = useState(null);
    const {tooltip} = _tooltip;

    let maxCurrent = 0,
        maxPrevious = 0;
    data.forEach((category) => {
        maxCurrent = category.currentValue > maxCurrent ? category.currentValue : maxCurrent;
        maxPrevious = category.previousValue > maxPrevious ? category.previousValue : maxPrevious;
    });
    const xAxisDataKey = maxCurrent > maxPrevious ? 'currentValue' : 'previousValue';

    const colors = useMemo(
        () => (breakdown === 'emotions' ? themeColors['emotion'] : themeColors[breakdown]),
        [breakdown, themeColors],
    );
    const randomColors = themeColors['random'];

    const chartData = useMemo(
        () =>
            data.map((category, i) => ({
                ...category,
                color:
                    category.color ||
                    (['pageTypes', 'sentiment', 'emotions'].includes(breakdown)
                        ? colors[category.id]
                        : randomColors[i]),
            })),
        [breakdown, colors, data, randomColors],
    );

    if (!width || !height) return <span />;

    const handleBarMouseEnterEvent = (ev, dataKey, placeAtEnd) => {
        showTooltip(ev, dataKey, placeAtEnd);
        setHoveredDatakey(dataKey);
    };

    const handleBarMouseLeaveEvent = (ev) => {
        setHoveredDatakey(null);
    };

    const showTooltip = (ev, dataKey, placeAtEnd) => {
        setTooltip({
            tooltip: {
                data: ev.payload,
                dataKey,
                pos: {x: placeAtEnd ? ev.x + ev.width : ev.x + ev.width / 2, y: ev.y},
            },
            lastId: ev.payload.id,
        });
    };

    const handleChartMouseLeave = () => {
        setTooltip({tooltip: null, lastId: null});
        setHoveredDatakey(null);
    };
    //TODO: make this better. A mapping between source name and desired screen name would be great.
    const formatter = (value) => (value === 'twitter' ? 'X (Twitter)' : upperfirst(value));

    const pattern = (
        <pattern
            id="pattern-stripe"
            width="8"
            height="8"
            patternUnits="userSpaceOnUse"
            patternTransform="rotate(45)"
        >
            <rect width="4" height="8" transform="translate(0,0)" fill="white" />
        </pattern>
    );
    const mask = (
        <mask id="mask-stripe">
            <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-stripe)" />
        </mask>
    );
    return (
        <BarChart
            className={classes.barChart}
            data={chartData}
            width={width}
            height={height}
            layout="vertical"
            barGap={-24}
            barCategoryGap={12}
            margin={{left: 80, right: 80, bottom: 10}}
            onMouseLeave={handleChartMouseLeave}
        >
            {pattern},{mask}
            <CartesianGrid horizontal={false} stroke="#cdd2d4" />
            <XAxis
                dataKey={xAxisDataKey}
                type="number"
                axisLine={false}
                tickLine={false}
                tickFormatter={(v) => formatBigInt(v, 1000)}
                tickMargin={5}
                height={70}
                label={{
                    value: T('volumeHorizontalChart.XaxisLegend'),
                    position: 'bottom',
                    className: 'xAxislegend',
                    offset: -20,
                }}
            />
            <YAxis
                type="category"
                dataKey="name"
                tick={<CustomTick height={34} />}
                tickFormatter={formatter}
                axisLine={false}
                tickLine={false}
                mirror={true}
                tickMargin={-86}
                padding={{top: 4, bottom: 4}}
            />
            <Bar
                dataKey="previousValue"
                shape={
                    <CustomBar
                        striped={true}
                        dataKey="previousValue"
                        hoveredKey={hoveredDataKey}
                        trendingIconComponent={TrendingIcon}
                    />
                }
                onMouseEnter={(ev) => handleBarMouseEnterEvent(ev, 'previousValue', true)}
                onMouseLeave={handleBarMouseLeaveEvent}
                isAnimationActive={animate}
                barSize={barSize}
                onClick={onClick('previous')}
            ></Bar>
            <Bar
                dataKey="currentValue"
                shape={
                    <CustomBar
                        dataKey="currentValue"
                        hoveredKey={hoveredDataKey}
                        trendingIconComponent={TrendingIcon}
                    />
                }
                onMouseEnter={(ev) => handleBarMouseEnterEvent(ev, 'currentValue', false)}
                onMouseLeave={handleBarMouseLeaveEvent}
                isAnimationActive={animate}
                barSize={barSize}
                onClick={onClick('current')}
            ></Bar>
            <Bar
                dataKey="previousValue"
                shape={<BenchmarkLine />}
                onMouseEnter={(ev) => handleBarMouseEnterEvent(ev, 'previousValue', true)}
                onMouseLeave={handleBarMouseLeaveEvent}
                isAnimationActive={animate}
                barSize={barSize}
                onClick={onClick('previous')}
            ></Bar>
            {!!tooltip && (
                <Customized
                    key="tooltip"
                    component={
                        <>
                            <ChartTooltip
                                {...tooltip.pos}
                                open={true}
                                showArrow={true}
                                animationMs={150}
                                distance={8}
                                autoHideOnScroll
                            >
                                <ChartTooltipContent
                                    byCategoryTotals={true}
                                    title={tooltip.data.name}
                                    valueItems={[
                                        {
                                            color: tooltip.data.color,
                                            amount: formatBigIntFull(tooltip.data[tooltip.dataKey]),
                                            unit: T('benchmark.tooltips.' + tooltip.dataKey),
                                        },
                                    ]}
                                />
                            </ChartTooltip>
                        </>
                    }
                />
            )}
        </BarChart>
    );
};

BenchmarkBarChart.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.number,
            id: PropTypes.string,
        }),
    ).isRequired,
    animate: PropTypes.bool,
    onClick: PropTypes.func,
    theme: PropTypes.string,
};

BenchmarkBarChart.defaultProps = {
    animate: false,
    onClick: null,
};

export default memo(BenchmarkBarChart);
