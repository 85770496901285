import {useCallback, useMemo} from 'react';

//Components
import SimpleMenu from 'hsi/components/SimpleMenu';
import Tooltip from 'hsi/components/Tooltip';

//Actions
import {
    setBulkChangeSentimentIsOpen,
    setBulkDeleteIsOpen,
    setBulkEditTagsIsOpen,
} from 'hsi/slices/mentions';

//Hooks
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import useEventTrack from 'hsi/hooks/useEventTrack';

//Other
import useStyles from './styles';
import {T} from 'hsi/i18n';
import {RootState} from 'hsi/utils/configureStore';
import {shallowEqual} from 'react-redux';

//Consts
const bulkActions = [
    {
        name: 'sentiment',
        label: 'mentionsContainer.bulkActionsMenu.actions.sentiment.title',
        action: setBulkChangeSentimentIsOpen,
        trackingName: 'bulkMentionSentimentEditRequested',
        isOpenSelector: (state: RootState) => state.mentions.bulkChangeSentimentIsOpen,
    },
    {
        name: 'tags',
        label: 'mentionsContainer.bulkActionsMenu.actions.tags.title',
        action: setBulkEditTagsIsOpen,
        trackingName: 'bulkMentionTagEditRequested',
        isOpenSelector: (state: RootState) => state.mentions.bulkChangeSentimentIsOpen,
    },
    {
        name: 'delete',
        label: 'mentionsContainer.bulkActionsMenu.actions.delete.title',
        action: setBulkDeleteIsOpen,
        trackingName: 'bulkMentionDeleteRequested',
        isOpenSelector: (state: RootState) => state.mentions.bulkChangeSentimentIsOpen,
    },
] as const;

export type BulkActionsMenuProps = {
    isSelectMode?: boolean;
    isFull?: boolean;
    isAnyMentionsSelected?: boolean;
};

export default function BulkActionsMenu({
    isFull = false,
    isSelectMode = false,
    isAnyMentionsSelected = false,
}: BulkActionsMenuProps) {
    const {classes} = useStyles();
    const dispatch = useAppDispatch();
    const selectedMentions = useAppSelector((state) => state.mentions.selectedMentions);
    const isActionOpen = useAppSelector((state) => {
        return bulkActions.map(({isOpenSelector}) => isOpenSelector(state));
    }, shallowEqual);

    const {trackWithSearchData} = useEventTrack();

    const disabled = !isSelectMode || !isAnyMentionsSelected;
    const disabledMessage = disabled
        ? isSelectMode
            ? T('mentionsContainer.bulkActionsMenu.moreActionsNoneSelectedLbl')
            : T('mentionsContainer.bulkActionsMenu.moreActionsDisabledLbl')
        : undefined;

    const getClickHandler = useCallback(
        ({action, trackingName}: (typeof bulkActions)[number]) => {
            return () => {
                trackWithSearchData(trackingName, {
                    selectedMentions: Object.keys(selectedMentions).length,
                });
                dispatch(action(true));
            };
        },
        [dispatch, selectedMentions, trackWithSearchData],
    );

    const compactContent = useMemo(
        () => (
            <SimpleMenu
                disabled={disabled}
                buttonComponent={
                    <button
                        className={classes.moreActionsBtn}
                        aria-disabled={disabled}
                        id="mentions-bulkActionsMenu-menuBtn"
                    >
                        <span className={classes.moreActionsBtnLbl}>
                            {T('mentionsContainer.bulkActionsMenu.moreActionsBtnLbL')}
                        </span>
                    </button>
                }
                entries={bulkActions.map((bulkAction) => ({
                    label: T(bulkAction.label),
                    onClick: getClickHandler(bulkAction),
                }))}
            />
        ),
        [classes.moreActionsBtn, classes.moreActionsBtnLbl, disabled, getClickHandler],
    );

    const fullContentDisabled = useMemo(
        () => (
            <div className={classes.list}>
                {bulkActions.map(({name, label}, index) => {
                    return (
                        <Tooltip key={name} tooltip={disabledMessage}>
                            <button
                                className={classes.button}
                                id={`mentions-bulkActionsMenu-${name}`}
                                aria-disabled
                                aria-haspopup="dialog"
                                aria-expanded={isActionOpen[index]}
                            >
                                {T(label)}
                            </button>
                        </Tooltip>
                    );
                })}
            </div>
        ),
        [classes.button, classes.list, disabledMessage, isActionOpen],
    );

    const fullContentEnabled = useMemo(
        () => (
            <div className={classes.list}>
                {bulkActions.map((bulkAction, index) => {
                    return (
                        <button
                            key={bulkAction.name}
                            className={classes.button}
                            onClick={getClickHandler(bulkAction)}
                            id={`mentions-bulkActionsMenu-${bulkAction.name}`}
                            aria-haspopup="dialog"
                            aria-expanded={isActionOpen[index]}
                        >
                            {T(bulkAction.label)}
                        </button>
                    );
                })}
            </div>
        ),
        [classes.button, classes.list, getClickHandler, isActionOpen],
    );

    const fullContent = disabled ? fullContentDisabled : fullContentEnabled;

    return isFull ? (
        fullContent
    ) : disabled ? (
        <Tooltip tooltip={disabledMessage}>{compactContent}</Tooltip>
    ) : (
        compactContent
    );
}
