import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles((theme: Theme) => {
    const v2 = isV2(theme);

    return {
        popover: {
            backgroundColor: '#FFF',
            border: v2
                ? `1px solid ${theme.colors.borders}`
                : `2px solid ${theme.colors.secondaryPurple100}`,
            padding: 0,
            minWidth: 120,
            borderRadius: v2 ? 3 : 0,
            color: theme.colors.darkGrey100,
            zIndex: 99999,
        },
    }
});
