import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
    addOrCreateTag: {
        width: '300px',
        //height: '360px',
        display: 'flex',
        flexFlow: 'column nowrap',
    },
    loading: {
        '&&': {
            position: 'absolute',
            zIndex: '9999',
            background: '#FFFFFF99',
            pointerEvents: 'auto',
            cursor: 'default',
        },
    },

    inlinePaper: {
        boxShadow: 'none',
    },
}));
