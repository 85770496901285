//TODO this needs to be moved to the translation file

const operators = [
    //simple:

    {
        operator: 'AND',
        description: 'Finds mentions that contain both terms. AND must be uppercase.',
        query: 'apple AND juice',
        match: (str: string) => str.match(/AND/g),
    },

    {
        operator: 'OR',
        description: 'Finds mentions that contain either term. OR must be uppercase.',
        query: 'apple OR juice',
        match: (str: string) => str.match(/OR/g),
    },

    {
        operator: 'NOT',
        description:
            'Finds mentions that contain only the first term and exclude the term following "NOT". NOT must be uppercase.',
        query: 'apple NOT juice',
        match: (str: string) => str.match(/NOT/g),
    },

    {
        operator: '()',
        description:
            'Combines two or more searches together, and finds mentions for the groups of searches in the order in which they appear.',
        query: '(apple AND juice) OR (apple AND sauce)',
        match: (str: string) => str.match(/\((.*?)\)/g),
    },

    {
        operator: '~',
        description:
            'Finds mentions where the terms are within a maximum number of words of each other, in the order specified. Add a number after the tilde to indicate the maximum number of words between the terms.',
        query: '"apple juice"~5',
        match: (str: string) => str.match(/~/g),
    },

    {
        operator: 'links:',
        description:
            'Finds Mentions containing links (including shortened links) to a specific webpage.',
        query: 'links:msn.com',
    },

    {
        operator: 'author:',
        description:
            'Finds mentions from specific authors. Returns results with exact spelling only.',
        query: 'author:justinbieber',
        match: (str: string) => str.match(/author:/g),
    },

    {
        operator: 'title:',
        description: 'Finds mentions of your terms in webpage titles.',
        query: 'title:"apple juice"',
        match: (str: string) => str.match(/title:/g),
    },

    {
        operator: 'site:',
        description: 'Finds mentions of your terms on a specific website.',
        query: 'site:twitter.com AND "apple juice"',
        match: (str: string) => str.match(/site:/g),
    },

    //{
    //operator: 'NEAR/x',
    //description: 'Finds mentions where the terms are within (x) words of each other, in either order. NEAR must be uppercase.',
    //query: '(apple OR orange) NEAR/5 (smartphone OR phone)',
    //},

    //{
    //operator: 'NEAR/xf',
    //description: 'Finds mentions where the terms appear within (x) or fewer words of each other, in the order specified. NEAR must be uppercase.',
    //query: 'Logitech NEAR/2f keyboard',
    //},

    //{
    //operator: '*',
    //description: 'Always used after a word, this finds mentions that contain the term as the root word. Mentions can contain the term with additional characters in the middle or at the end of a word.',
    //query: 'complain*',
    //},

    //advanced:

    {
        operator: '{}',
        description:
            'Finds mentions of your specified capital/lower case spelling of the term. Only works with words of up to  five characters.',
        query: '{BT}',
        advanced: true,
        match: (str: string) => str.match(/\{(.*?)\}/g),
    },

    {
        operator: 'Accents',
        description:
            'Finds mentions that contain the terms spelled exactly as specified. To exclude specific accented words, use the NOT operator (for example, Nino NOT Niño).',
        query: '“niño”',
        advanced: true,
        match: (str: string) => str.match(/(".+?")|(“.+?”)|(“.+?“)|(”.+?”)/g),
    },

    {
        operator: '<<< >>>',
        description:
            'Indicates the text inside the angle brackets is not part of the search (for example, a comment after a section of the query).',
        query: '<<< End of section >>>',
        advanced: true,
        match: (str: string) => str.match(/<<<(.*?)>>>/g),
    },

    {
        operator: 'continent:',
        description:
            'Finds mentions of your terms that originate from a specific continent. continent: must be lowercase.',
        query: 'continent:EUROPE AND "apple juice"',
        advanced: true,
        match: (str: string) => str.match(/continent:/g),
    },

    {
        operator: 'country:',
        description:
            'Finds mentions of your terms that originate from a specific country. country: must be lowercase.',
        query: 'country:GBR AND "apple juice"',
        advanced: true,
        match: (str: string) => str.match(/country:/g),
    },

    {
        operator: 'region:',
        description:
            'Finds mentions of your terms that originate from a specific state, region, or province. region: must be lowercase.',
        query: 'region:USA.FL AND “apple juice”',
        advanced: true,
        match: (str: string) => str.match(/region:/g),
    },

    {
        operator: 'city:',
        description:
            'Finds mentions of your terms that originate from a specific city. city: must be lowercase.',
        query: 'city:GBR.Greater London.London AND "apple juice"',
        advanced: true,
        match: (str: string) => str.match(/city:/g),
    },

    {
        operator: `latitude:
longitude:`,
        description:
            'Finds mentions that originate from specific coordinates. You can use these two operators together or separately.',
        query: 'latitude:[41 TO 44] AND longitude:[-73 TO -69]',
        advanced: true,
        match: (str: string) => str.match(/latitude:|longitude:/g),
    },

    {
        operator: 'url:',
        description:
            'Finds mentions of your terms on a specific website page. Use quotation marks to match the exact webpage URL.',
        query: 'url:"msn.com/news" AND "Simon Cowell"',
        advanced: true,
        match: (str: string) => str.match(/url:/g),
    },
];

const examples = [
    {
        description: 'Brand search, finding mentions for Marriott online.',
        query: `Marriott OR "Residence inn" OR "Fairfield Inn & Suites" OR links: marriott.com OR site: marriott.com OR author: (MarriottIntl OR Marriott) OR @MarriottIntl OR @Marriott OR #Marriott OR #LetYourMindTravel NOT ("Willard Marriott" OR "Bill Marriott")`,
    },

    {
        description:
            'Refined search, finding mentions for "Marriott", specifically tailored to look for amenities provided by the hotel.',
        query: `(("marriott") AND (yoga OR massage OR facial OR spa OR pets OR "pet friendly" OR dogs OR cats OR fitness OR gym OR "business center" OR weddings OR "wedding reception"))`,
    },

    {
        description:
            'Topic search, finding online conversations about self driving vehicles, and excluding irrelevant aspects.',
        query: `(("self driving" OR autonomous OR driverless) AND (Car OR vehicle OR auto OR suv OR suvs OR "sport utility vehicle" OR sedan OR sedans OR "mini van" OR minivan OR miniaturevan OR truck OR pickuptruck OR "pickup truck" OR pickuptrucks OR "pickup trucks" OR hybrid OR "two door" OR "2 door" OR 2door OR twodoor OR 2doors OR twodoors OR "four door")) NOT (AI OR "Artificial intelligence" OR artificialintelligence)`,
    },
];

export {operators, examples};
