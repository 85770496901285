import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles(({colors, font, fonts, v2}: Theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    labelWrapper: {
        display: 'inline-flex',
        marginBottom: 8,
        padding: '0px 4px',
    },
    label: {
        ...(v2 ? fonts.bodyStrong : {fontSize: 16, fontWeight: font.semiBold}),
    },
    chipsLeftMsg: {
        ...(v2
            ? fonts.bodyStrong
            : {fontSize: 16, fontWeight: font.regular, color: colors.darkGrey80}),
        marginLeft: 'auto',
    },
    chipsLeftError: {
        color: colors.primaryRed100,
    },
    input: {
        '& .MuiAutocomplete-input': {
            marginTop: v2 ? 'inherit' : 2,
            paddingBottom: '0px !important',
            paddingTop: '0px !important',
            minWidth: '65px',
        },
        '& .MuiInputBase-inputAdornedEnd': {
            marginRight: '25px',
        },
        '& .MuiInputBase-root': {
            alignContent: 'flex-start',
            alignItems: 'flex-start',
            maxHeight: 240,
            boxShadow: `inset 0 0 0 1px ${v2 ? colors.borders : colors.darkGrey60}`, //implement borders with box-shadow, because default shadows do not take up space
            overflowY: 'auto',
            padding: '5px',
            gap: '4px',

            '&.Mui-error': {
                boxShadow: `inset 0 0 0 2px ${colors.primaryRed100}`, //implement borders with box-shadow, because default shadows do not take up space
            },
            '&.Mui-disabled': {
                backgroundColor: v2 ? colors.bgDisabled : colors.lightGrey40,
            },
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            border: 'none !important', // Default borders don't handle scroll
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            border: 'none !important', // Default borders don't handle scroll
        },
    },
    endAdornment: {
        //use zero width/margin and relative position to prevent this from wrapping onto a new line, and stay next to the input
        width: 0,
        height: '36px',
        margin: 0,
        position: 'relative',
        left: '-8px',
    },
    cta: {
        border: v2 ? `1px solid ${colors.borders}` : `1px solid ${colors.darkGrey60}`,
        borderRadius: 0,
        color: colors.secondaryBlue100,
        position: 'relative',
        top: '-1px',
    },
    helperText: {
        ...(v2 ? fonts.bodyLargeNormal : {fontSize: 16, fontWeight: font.regular}),
        marginTop: 8,
        padding: '0px 4px',
    },
}));
