import deburr from 'lodash/deburr';

export const getSuggestions = (value: string, data: any[], field: string, limit?: number) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    return inputLength === 0
        ? []
        : data.filter((suggestion) => {
              let keep;
              if (limit) {
                  keep =
                      count < limit &&
                      suggestion[field].slice(0, inputLength).toLowerCase() === inputValue;

                  if (keep) {
                      count += 1;
                  }
              } else {
                  keep = suggestion[field].slice(0, inputLength).toLowerCase() === inputValue;
              }

              return keep;
          });
};

export function ucfirst(str: undefined): '';
export function ucfirst<T extends string>(str: T): Capitalize<T>;
export function ucfirst(str: string | undefined) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

export const getChangePercentage = (current: number, prev: number) => {
    if ((current === 0 && prev === 0) || (!current && !prev)) return 0;
    if (prev === 0) return Infinity;
    return Math.floor((current * 100) / prev - 100);
};

/**
 * A case insensitive way of checking if a substring is within a string.
 * This is useful when filtering a list.
 */
export const isSubstringInString = (string: string, subSubstring: string) => {
    const subStringRegExp = new RegExp(subSubstring, 'i');

    return string.toLowerCase().match(subStringRegExp);
};
