import {useCallback} from 'react';

import Button from 'hsi/components/Button';
import {Dialog, DialogContent, DialogActions} from 'hsi/components/Dialog';
import AuthenticateFacebook from 'hsi/containers/FacebookAuthentication/AuthenticateFacebook';

import {toggleFacebookLoginDialog} from 'hsi/slices/facebookAuthentication';

import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import useStyles from './styles';

import {T} from 'hsi/i18n';

const AuthenticateFacebookDialog = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const {isFacebookLoginDialogOpen} = useAppSelector((state) => state.facebookAuthentication);

    const closeDialog = useCallback(() => {
        dispatch(toggleFacebookLoginDialog(false));
    }, [dispatch]);
    return (
        <Dialog
            onClose={closeDialog}
            open={isFacebookLoginDialogOpen}
            style={{zIndex: 1350}}
            title={T('addTargetedDataDialog.authTitle')}
        >
            <DialogContent>
                <div className={classes.introText}>{T('addTargetedDataDialog.authMsg')}</div>
                <AuthenticateFacebook />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>{T('close')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AuthenticateFacebookDialog;
