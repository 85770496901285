//TODO
import {Theme as BaseTheme} from '@mui/material';
import HSIColors from 'hsi/themes/hsi/colors';
import BWColors from 'hsi/themes/bw/colors';

export type Font = {
    //TODO all optional - also, where is this used?
    url?: string; //A url?
    name?: string;
    light?: number;
    medium?: number;
    bold?: number;
    semiBold?: number;
    regular?: number;

    //TODO how/where is this used? As far as I can tell, it is not?
    // '@font-face': [
    //     {
    //         fontFamily: 'Proxima Nova',
    //         src: `url("./fonts/ProximaNovaBold.otf") format("opentype")`,
    //         fontWeight: 700,
    //     },
    //     {
    //         fontFamily: 'Proxima Nova',
    //         src: `url("./fonts/ProximaNovaSbold.otf") format("opentype")`,
    //         fontWeight: 600,
    //     },
    //     {
    //         fontFamily: 'Proxima Nova',
    //         src: `url("./fonts/ProximaNovaReg.otf") format("opentype")`,
    //         fontWeight: 400,
    //     },
    //     {
    //         fontFamily: 'Proxima Nova',
    //         src: `url("./fonts/ProximaNovaLight.otf") format("opentype")`,
    //         fontWeight: 300,
    //     },
    //     {
    //         fontFamily: 'Proxima Nova',
    //         src: `url("./fonts/ProximaNovaThin.otf") format("opentype")`,
    //         fontWeight: 300,
    //     },
    // ],
};

type FontsFont = {
    fontWeight?: number;
    fontSize?: string;
    textTransform?:
        | 'none'
        | 'capitalize'
        | 'uppercase'
        | 'lowercase'
        | 'full-width'
        | 'full-size-kana';
};

type V1Fonts = {}; //None
type V2Fonts = {
    display2Strong: FontsFont;
    display1Normal: FontsFont;
    display1Strong: FontsFont;
    headlineNormal: FontsFont;
    headlineStrong: FontsFont;
    headtitleNormal: FontsFont;
    headtitleStrong: FontsFont;
    bodyLargeNormal: FontsFont;
    bodyLargeStrong: FontsFont;
    bodyNormal: FontsFont;
    bodyStrong: FontsFont;
    bodySmall: FontsFont;
    bodySmallStrong: FontsFont;
    buttonLarge: FontsFont;
    buttonNormal: FontsFont;
    labelStrong: FontsFont;
    labelNormal: FontsFont;
};

export type Fonts = Partial<V1Fonts & V2Fonts>;

export type V1Colors = {
    [key in keyof typeof HSIColors]: string;
};
export type V2Colors = {
    [key in keyof typeof BWColors]: string;
};

export type Colors = Partial<{
    [key in keyof V1Colors | keyof V2Colors]: string;
}>;

export type Components = {
    [key: string]: any; //ComponentType TODO find proper type for React Component
};

interface ATheme extends BaseTheme {
    readonly themeId: string;
    readonly font: Font;
    readonly components: Components;
    readonly mixin: any;
    readonly elements: any;
}

export interface ThemeV1 extends ATheme {
    readonly v2: false;
    readonly fonts: V1Fonts;
    readonly colors: V1Colors;
}

export interface ThemeV2 extends ATheme {
    readonly themeId: string;
    readonly v2: true;
    readonly fonts: V2Fonts;
    readonly colors: V2Colors;
    readonly components: Components;
    readonly mixin: any;
    readonly elements: any;
}

//export type Theme = ThemeV2 & ThemeV1;

export interface Theme extends Readonly<BaseTheme> {
    readonly themeId: string;
    readonly v2: boolean;
    readonly font: Font;
    readonly fonts: Fonts;
    readonly colors: Colors;
    readonly mixin: any;
    readonly elements: any;
}

export function isV2(theme: Theme): theme is ThemeV2 {
    return !!theme.v2;
}
