import makeStyles from '@mui/styles/makeStyles';
import { Theme } from 'hsi/types/theme';

export default makeStyles<Theme>(({colors}) => {
    return {
        meter: {
            "--meter-height": '8px',
            "--meter-percentage": "0%",
            height: 'var(--meter-height)',
    
            borderRadius: 'calc(var(--meter-height) / 2)',
            overflow: 'hidden',
            position: 'relative',
            backgroundColor: colors.primaryBlue20,  

            '&::after': {
                background: colors.primaryBlue100,
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                width: 'var(--meter-percentage)',
                content: "''",
                borderRadius: 'calc(var(--meter-height) / 2)',
            }
        },
    }
});
