import {useMemo, useState, useCallback} from 'react';
import {InputDateType, RangeDefinition, TDateISODate} from 'hsi/types/dates';
import {normalizeDate, toISODate} from 'hsi/utils/dates';
import {TimezoneID} from 'hsi/utils/timezones';
import {DateTime, Interval} from 'luxon';
import {makeDaysIntervalFrom} from './utils';
import {ReadonlyDeep} from 'type-fest';

type useDateRangeAndTimezonePickerStateArgOptions = {
    initialShowDate?: InputDateType;
    initialStartDate?: InputDateType;
    initialEndDate?: InputDateType;
    initialTimezone?: TimezoneID;
    relativeDateRanges?: ReadonlyDeep<RangeDefinition[]>;
    defaultSelectedRelativeDateRangeId?: string;
    onRelativeDateRangeIdChange?: (dateRange: string | undefined) => void;
    onDateSelectionChange?: (range: Interval | undefined) => void;
    onTimezoneChange?: (newTimezone: TimezoneID) => void;
};

export default function useDateRangeAndTimezonePickerState(
    now: DateTime,
    {
        initialShowDate,
        initialTimezone = 'UTC',
        initialStartDate,
        initialEndDate,
        relativeDateRanges,
        defaultSelectedRelativeDateRangeId,
        onDateSelectionChange,
        onTimezoneChange,
        onRelativeDateRangeIdChange,
    }: useDateRangeAndTimezonePickerStateArgOptions = {},
) {
    const [showDate, setShowDate] = useState<TDateISODate | undefined>(() =>
        initialShowDate ? toISODate(normalizeDate(initialShowDate, initialTimezone)) : undefined,
    );
    const [startDate, setStartDate] = useState<TDateISODate | undefined>(() =>
        initialStartDate ? toISODate(normalizeDate(initialStartDate, initialTimezone)) : undefined,
    );
    const [endDate, setEndDate] = useState<TDateISODate | undefined>(() =>
        initialEndDate ? toISODate(normalizeDate(initialEndDate, initialTimezone)) : undefined,
    );

    const [timezone, _setTimezone] = useState(initialTimezone);

    const [selectedRelativeDateRangeId, _setSelectedRelativeDateRangeId] = useState(
        defaultSelectedRelativeDateRangeId,
    );

    //Calculated values

    const selectedRelativeDateRange = useMemo(() => {
        return relativeDateRanges?.find(({id}) => id === selectedRelativeDateRangeId);
    }, [selectedRelativeDateRangeId, relativeDateRanges]);

    //Callbacks
    const setDateSelection = useCallback(
        (startDate: TDateISODate | undefined, endDate: TDateISODate | undefined) => {
            setStartDate(startDate);
            setEndDate(endDate);
            onDateSelectionChange?.(makeDaysIntervalFrom(startDate, endDate, timezone));
        },
        [onDateSelectionChange, timezone],
    );

    const setTimezone = useCallback(
        (newTimezone: TimezoneID) => {
            _setTimezone(newTimezone);
            onTimezoneChange?.(newTimezone);

            //TODO also call onDateSelectionChange? Because if the timezone changes, the selected date range is also different
            onDateSelectionChange?.(makeDaysIntervalFrom(startDate, endDate, timezone));
        },
        [endDate, onDateSelectionChange, onTimezoneChange, startDate, timezone],
    );

    const setSelectedRelativeDateRangeId = useCallback(
        (newRelativeDateRangeId: string | undefined) => {
            _setSelectedRelativeDateRangeId(newRelativeDateRangeId);
            onRelativeDateRangeIdChange?.(newRelativeDateRangeId);

            if (newRelativeDateRangeId) {
                const newRange = relativeDateRanges?.find(({id}) => id === newRelativeDateRangeId);

                if (newRange) {
                    setShowDate(toISODate(now.minus(newRange.start).startOf('month')));
                }
            }
        },
        [now, onRelativeDateRangeIdChange, relativeDateRanges],
    );

    const reset = useCallback(() => {
        setStartDate(
            initialStartDate
                ? toISODate(normalizeDate(initialStartDate, initialTimezone))
                : undefined,
        );
        setEndDate(
            initialEndDate ? toISODate(normalizeDate(initialEndDate, initialTimezone)) : undefined,
        );
        setTimezone(initialTimezone);
    }, [initialEndDate, initialStartDate, initialTimezone, setTimezone]);

    return useMemo(
        () => ({
            showDate,
            setShowDate,
            startDate,
            endDate,
            setDateSelection,
            timezone,
            setTimezone,
            selectedRelativeDateRangeId,
            setSelectedRelativeDateRangeId,
            selectedRelativeDateRange,
            reset,
        }),
        [
            showDate,
            startDate,
            endDate,
            setDateSelection,
            timezone,
            setTimezone,
            selectedRelativeDateRangeId,
            setSelectedRelativeDateRangeId,
            selectedRelativeDateRange,
            reset,
        ],
    );
}
