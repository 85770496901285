import makeStyles from '@mui/styles/makeStyles';
import {isV2, Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);

    return {
        root: {
            fontWeight: v2 ? theme.fonts.bodyLargeStrong.fontWeight : 700,
            height: '64px',
            padding: v2 ? '8px 17px 0' : '0 9px',
            alignItems: 'center',
            position: 'sticky',
            zIndex: 999,
            top: '-10px',
            display: 'flex',
            outline: 'none',
        },
        anchor: {
            ...theme.mixin.blankButton,
            fontFamily: 'inherit',
            cursor: 'pointer',
            textDecoration: 'none',
            textTransform: v2 ? 'capitalize' : 'uppercase',
            fontSize: v2 ? theme.fonts.bodyLargeStrong.fontSize : '14px',
            fontWeight: 'inherit',
            padding: '10px',
            color: v2 ? theme.colors.textSubtle : theme.colors.darkGrey100,
            flex: '0 0 auto',
            position: 'relative',

            '&:after': {
                content: '""',
                display: 'none',
                height: 4,
                position: 'absolute',
                left: 10,
                right: 10,
                bottom: 6,
                background: theme.colors.primaryBlue60,
            },

            '&:focus-visible': {
                outline: v2 ? undefined : `2px solid ${theme.colors.secondaryOrange100}`,
            },

            '&:hover:after': {
                display: v2 ? 'none' : 'block',
            },

            '&:focus-visible:after': {
                display: v2 ? 'none' : 'block',
            },

            '&:active:after': {
                display: v2 ? 'none' : 'block',
                background: theme.colors.primaryBlue100,
            },

            '&:disabled': {
                color: v2 ? theme.colors.textDisabled : theme.colors.darkGrey60,
                cursor: 'default',

                '&:after': {
                    display: 'none',
                }
            }
        },
        moreIcon: {
            ...theme.mixin.blankButton,
            outline: 'none',
            padding: '5px',
            cursor: 'pointer',
            position: 'relative',

            '& .icon-more-horizontal': {
                width: '18px',
                height: '18px',
                marginLeft: v2 ? 0 : '-4px',
                marginTop: v2 ? '4px' : 0,
            },

            '&:after': {
                content: '""',
                display: 'none',
                height: 4,
                position: 'absolute',
                left: 5,
                right: 5,
                bottom: 6,
                background: theme.colors.primaryBlue60,
            },

            '&:focus-visible': {
                outline: v2 ? undefined : `2px solid ${theme.colors.secondaryOrange100}`,
            },

            '&:hover:after': {
                display: v2 ? 'none' : 'block',
            },

            '&:focus-visible:after': {
                display: v2 ? 'none' : 'block',
            },

            '&:active:after': {
                display: v2 ? 'none' : 'block',
                background: theme.colors.primaryBlue100,
            },
        },
    };
});
