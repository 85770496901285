import {ApiAlert} from 'hsi/types/alerts';
import {APIFilterFormat} from 'hsi/types/filters';
import {SavedSearchType, PartialProps} from 'hsi/types/shared';

type OptionalCreateAlertArgs = {
    name: string;
    volumeAlertChecked?: boolean;
    mentionsAlertChecked?: boolean;
    volumeAlertVal: number | null;
    additionalRecipients: string[];
    mentionAlertFrequency?: number;
    mentionAlertTime: number;
    mentionAlertWeekday: number | null;
    filters?: APIFilterFormat;
    mentionsPerAlert: number;
    emailCreator?: boolean;
};

//This method assumes no errors
export function createAlertObject(
    alert: PartialProps<ApiAlert, 'id'> | undefined,
    savedSearch: SavedSearchType,
    {
        name,
        volumeAlertChecked,
        mentionsAlertChecked,
        volumeAlertVal,
        additionalRecipients,
        mentionAlertFrequency,
        mentionAlertTime,
        mentionAlertWeekday,
        filters,
        mentionsPerAlert,
        emailCreator = true,
    }: OptionalCreateAlertArgs,
): PartialProps<ApiAlert, 'id'> {
    const newAlert: PartialProps<ApiAlert, 'id'> = {
        repeatOnDayOfWeek: null,
        repeatOnHourOfDay: null,
        threshold: null,
        thresholdIntervalMinutes: null,
        thresholdPercentage: null,
        thresholdStandardDeviations: null,
        thresholdVolume: null,
        frequency: 0,

        ...alert,

        emailCreator,
        additionalRecipients: [],
        alertTypes: [],
        enabled: true,
        filter: {},
        mentionsPerAlert,
        name: name,
        projectId: savedSearch.project.id,
        queryId: savedSearch.id,
    };

    if (volumeAlertChecked) {
        newAlert.alertTypes.push('threshold');
        newAlert.thresholdPercentage = volumeAlertVal;
        newAlert.frequency = 1440; //will be replaced IF mentionsAlertChecked = true
        newAlert.repeatOnHourOfDay = 8;
    }

    if (mentionsAlertChecked) {
        newAlert.alertTypes.push('scheduled');
        newAlert.frequency = mentionAlertFrequency ?? 0;
        newAlert.repeatOnDayOfWeek = mentionAlertWeekday;
        newAlert.repeatOnHourOfDay = mentionAlertTime;
    }

    additionalRecipients.forEach((r) => {
        newAlert.additionalRecipients.push({
            recipient: r,
            invited: true,
            accepted: false,
            unsubscribed: false,
        });
    });

    if (filters) {
        newAlert.filter = filters;
    } else if (alert) {
        newAlert.filter = alert.filter;
    }

    return newAlert;
}
