import {forwardRef, useCallback, useMemo} from 'react';
import isEmpty from 'lodash/isEmpty';

//Components
import CardTitle, { formatCardTitleAsString } from 'hsi/components/Card/CardTitle';
import InfoPopupContent from 'hsi/components/InfoPopupContent';
import ManagedGridTable from 'hsi/components/table/ManagedGridTable';
import PaginationButtons from 'hsi/components/PaginationButtons';
import CardLoadState from 'hsi/components/Card/CardLoadState';
import usePaginateRows from 'hsi/components/table/ManagedTable/usePaginateRows';
import useFormatRows from 'hsi/components/table/ManagedTable/useFormatRows';
import { ColumnDefinition, FormatColumnDefinition } from 'hsi/components/table/ManagedTable/types';
import CompactNumber from 'hsi/components/format/CompactNumber';

//Hooks
import useConfig from 'hsi/hooks/useConfig';

import useAddPositionToRows, { WithPosition } from 'hsi/components/Cards/hooks/useAddPositionToRows';
import useCardTableTrackingAndPersistence from 'hsi/components/Cards/hooks/useCardTableTrackingAndPersistence';
import useCardData from 'hsi/hooks/useCardData';
import useDrillInMentions from 'hsi/hooks/useDrillInMentions';
import { useCardTypeLimit } from 'hsi/utils/cards/getCardTypeLimit';

//Utils
import { getSharedUrlDillInFilters } from 'hsi/utils/filters/drillInFilters';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';
import { CardTypeProps, TopSharedUrlType } from 'hsi/types/cards';


//Types
type TopSharedUrlWithPositionType = WithPosition<TopSharedUrlType>;
type Columns = keyof TopSharedUrlWithPositionType;
type TableColumnDefinition = ColumnDefinition<Columns> & FormatColumnDefinition<TopSharedUrlWithPositionType, Columns>;


//Constants
const type = 'topSharedURLs';
const DEFAULT_DATA: TopSharedUrlType[] = [];


//The components
const TopSharedURLs = forwardRef<HTMLDivElement, CardTypeProps>(({title, ...props}, ref) => {
    const {
        links: {dashboardInfoTopSharedURLsCTA: popupCTA},
    } = useConfig();

    const {perPage} = useCardTypeLimit(type);

    const drillInMentions = useDrillInMentions();
    const getDrillInData = useGetDrillInData();
    
    const {data, loading, loaded, error, loadData, isCardSelected} = useCardData({
        type,
        dataSelector: (state) => state.chart[type],
    });
    const hasData = !isEmpty(data);
    const rows = hasData ? (data as TopSharedUrlType[]) : DEFAULT_DATA;

    //Calculated values
    const columns = useGetColumns();
    const {page, pages, setPage} = useCardTableTrackingAndPersistence(type, columns, {totalRows: rows.length, rowsPerPage: perPage});

    // Add position property to row data
    const positionedRows = useAddPositionToRows(rows);

    // Apply pagination
    const paginatedRows = usePaginateRows(positionedRows, page, perPage);

    // Apply formatting
    const formattedRows = useFormatRows(paginatedRows, columns);

    const popup = useMemo(
        () => (
            <InfoPopupContent
                title={T(`cards.${type}.info.title`)}
                copy={T(`cards.${type}.info.copy`)}
                ctaUrl={popupCTA}
                ctaLabel={T('cards.infoCTALabel')}
            />
        ),
        [popupCTA],
    );

    //Render
    return (
        <CardLoadState
            title={
                <CardTitle
                    title={title}
                    type={type}
                    tooltipComponent={popup}
                    hasData={hasData}
                />
            }
            error={error}
            hasData={hasData}
            loading={loading}
            loaded={loaded}
            loadData={loadData}
            selected={isCardSelected}
            ref={ref}
            data-testid={type}
            type={type}
            {...props}
        >
            <ManagedGridTable
                columns={columns}
                rowData={formattedRows}
                caption={T('managedTable.caption', {name: formatCardTitleAsString(title)})}

                rowSelectable
                onRowClick={(index: number) => {
                    drillInMentions(getDrillInData(paginatedRows[index]));
                }}
                sortColumn="volume"
                sortDirection="desc"
            />
            <PaginationButtons page={page} pages={pages} setPage={setPage} />
        </CardLoadState>
    );
});

TopSharedURLs.displayName = type;

export default TopSharedURLs;

function useGetDrillInData() {
    return useCallback(
        ({name: url}: TopSharedUrlType) => ({
            dates: undefined,
            filter: getSharedUrlDillInFilters(url),
            label: url,
            tracking: {
                name: 'cardDrilledIn',
                type,
                value: url,
            },
            type,
        } as const),
        [],
    );
};

function useGetColumns(): TableColumnDefinition[] {
    const classes = useStyles();

    return useMemo<TableColumnDefinition[]>(() => ([
        {
            name: 'position',
            label: <span className="offscreen">{T('cards.topSharedURLs.tableFields.position')}</span>,
            horizontalAlign: 'start',
            width: 'auto',
            format: (row) => <span className={classes.tableRowPosition}>{row.position}</span>
        },
        {
            name: 'name',
            label: T(`cards.topSharedURLs.tableFields.name`),
            width: '1fr',
            truncateCellText: true,
            horizontalAlign: 'start',
        },
        {
            name: 'volume',
            label: T(`cards.topSharedURLs.tableFields.volume`),
            width: 'auto',
            format: (row) => <CompactNumber value={+row.volume} tooltip />
        }
    ]), [classes]);
}
