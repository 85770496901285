import {useEffect} from 'react';

import useQueryContext from 'hsi/hooks/useQueryContext';
import {loadMentions} from 'hsi/slices/mentions';
import {getSearchParams} from 'hsi/utils/url';
import {addDrillInToFiltersState, filterStateToAPIFormat} from 'hsi/utils/filters';
import {QueryContextType} from 'hsi/types/query';
import {AllFilteringState, DateRange, FiltersState} from 'hsi/types/filters';
import {useAppDispatch, useAppSelector} from './useRedux';
import {selectAdditionalQueries, selectLinkedinChannelIds} from 'hsi/selectors';
import {LinkedinChannelIdsType} from 'hsi/types/shared';

const useMentionsUpdateTrigger = () => {
    const dispatch = useAppDispatch();

    const additionalQueries = useAppSelector(selectAdditionalQueries);
    const linkedinChannelIds = useAppSelector(selectLinkedinChannelIds);
    const filters: AllFilteringState = useAppSelector((state: any) => state.filters);
    const orderBy: string = useAppSelector((state: any) => state.mentions.orderBy);
    const orderIsAsc: boolean = useAppSelector((state: any) => state.mentions.orderIsAsc);
    const queryContext = useQueryContext() as QueryContextType;

    const drillInFilter: FiltersState | undefined = useAppSelector(
        (state) => state.mentions.drillInFilter,
    );
    const drillInDates: DateRange | undefined = useAppSelector(
        (state) => state.mentions.drillInDates,
    );

    const mentionsParamStr = getMentionParams(
        queryContext as QueryContextType,
        filters,
        drillInFilter,
        drillInDates,
        additionalQueries,
        linkedinChannelIds,
    );

    useEffect(() => {
        dispatch(loadMentions({queryContext}));
        // Check if deps can be added without issue
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mentionsParamStr, orderBy, orderIsAsc, dispatch]);
};

export default useMentionsUpdateTrigger;

//Internal helpers
function getMentionParams(
    queryContext: QueryContextType,
    filters: AllFilteringState,
    drillIn: FiltersState | undefined,
    drillInDates: DateRange | undefined,
    additionalQueries?: number[],
    linkedinChannelIds?: LinkedinChannelIdsType,
) {
    const drilledInFilters = addDrillInToFiltersState(filters, drillIn, drillInDates);

    return getSearchParams(
        filterStateToAPIFormat(drilledInFilters.filters, drilledInFilters.allFiltersConfig),
        drilledInFilters.dateRange,
        queryContext,
        additionalQueries,
        linkedinChannelIds,
    );
}
