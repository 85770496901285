import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => ({
    title: {
        justifyContent: 'flex-start',
        gap: '20px',
    },
    titleHeading: {
        margin: 0,
        ...theme.v2 
            ? theme.fonts.headtitleNormal 
            : {fontSize: '28px', fontWeight: 900},
    },
}));
