import { makeStyles } from 'tss-react/mui';

export default makeStyles<
    void,
    | 'disabled'
    | 'today'
    | 'passive'
    | 'selected'
    | 'selectedStart'
    | 'selectedEnd'
    | 'preview'
    | 'previewStart'
    | 'previewEnd'
    | 'startOfWeek'
    | 'endOfWeek'
    | 'startOfMonth'
    | 'endOfMonth'
    | 'weekend'
>()((theme, _params, classes) => ({
    dateRangePicker: {
        display: 'inline-block',
        background: '#FFF',
        padding: '10px',
    },

    monthBar: {
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
    },

    monthLabel: {
        flex: '1 1 0',
        textAlign: 'center',
        color: theme.v2 ? theme.colors.textDefault : '#2F3638',
        ...(theme.v2
            ? theme.fonts.bodyNormal
            : {fontSize: '12px', fontWeight: theme.font.semiBold}),
    },

    changeMonthBtn: {
        flex: '0 0 auto',
        margin: '0',

        '&:first-of-type': {
            marginRight: '-64px',
        },
        '&:last-of-type': {
            marginLeft: '-64px',
        },
    },

    changeMonthBtnIcon: {
        width: '16px',
        height: '16px',
    },

    days: {
        display: 'inline-flex',
        gap: '20px',
    },

    monthDays: {
        width: '230px',
        flex: '0 0 auto',
    },

    headerRow: {
        display: 'flex',
    },

    columnHeader: {
        flex: '1 1 0',
        textAlign: 'center',
        lineHeight: '2.667em',
        color: theme.v2 ? theme.colors.textSubtle : '#2F3638',

        ...(theme.v2 ? theme.fonts.bodySmall : {fontSize: '12px', fontWeight: theme.font.semiBold}),

        [`&.${classes.weekend}`]: {
            color: theme.v2 ? undefined : theme.colors.primaryRed100,
        },
    },

    week: {
        display: 'flex',
    },

    day: {
        flex: '1 1 0',
        display: 'flex',
        height: '34px',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',

        '&:focus': {
            outline: 'none',
        },

        //Selected color:
        //color: theme.v2 ? theme.colors.textHyperlink : 'rgba(0, 0, 0, 0.85)',

        ...(theme.v2
            ? theme.fonts.bodySmall //currently uses a non-standard font (same as v1), switching to closes match
            : {fontSize: '12px', fontWeight: theme.font.semiBold}),

        [`&:not(.${classes.disabled})`]: {
            '&:hover, &:focus-visible': {
                cursor: 'pointer',
                //background: '#EEE',
            },
            '&[aria-selected=true]': {
                // background: '#DDD',
                // fontWeight: 'bold',
            },
        },

        [`&.${classes.passive}, &.${classes.disabled}`]: {
            color: theme.v2 ? theme.colors.textSubtle : '#8c939c',
        },

        [`&.${classes.selected}`]: {
            color: theme.v2 ? theme.colors.primaryBlue100 : undefined,
            ...(theme.v2 ? theme.fonts.bodySmallStrong : null),

            '&:after': {
                content: "''",
                position: 'absolute',
                zIndex: 1,
                top: '4px',
                right: 0,
                left: 0,
                bottom: '4px',
                background: theme.colors.primaryBlue20,
            },

            [`&.${classes.selectedStart}`]: {
                color: theme.colors.white,

                '&:after': {
                    background: theme.colors.primaryBlue100,
                    borderTopLeftRadius: '13px',
                    borderBottomLeftRadius: '13px',
                },
            },
            [`&.${classes.selectedEnd}`]: {
                color: theme.colors.white,

                '&:after': {
                    background: theme.colors.primaryBlue100,
                    borderTopRightRadius: '13px',
                    borderBottomRightRadius: '13px',
                },
            },
        },

        [`&.${classes.preview}`]: {
            //use :before
            '&:before': {
                content: "''",
                position: 'absolute',
                zIndex: 2,
                top: 3,
                right: -1,
                left: -1,
                bottom: 3,

                border: `1px solid ${theme.colors.primaryBlue100}`,
                borderWidth: '1px 0',
            },

            [`&.${classes.previewStart}, &.${classes.startOfWeek}, &.${classes.startOfMonth}`]: {
                '&:before': {
                    borderLeftWidth: '1px',
                    borderTopLeftRadius: '13px',
                    borderBottomLeftRadius: '13px',
                },
            },

            [`&.${classes.previewEnd}, &.${classes.endOfWeek}, &.${classes.endOfMonth}`]: {
                '&:before': {
                    borderRightWidth: '1px',
                    borderTopRightRadius: '13px',
                    borderBottomRightRadius: '13px',
                },
            },
        },
    },

    dayNumber: {
        position: 'relative',
        zIndex: 2,
        lineHeight: 1,
        padding: '0 2px',

        [`.${classes.today} > &`]: {
            borderBottom: `2px solid ${
                theme.v2 ? theme.colors.accentDefault : theme.colors.primaryBlue100
            }`,
            borderTop: `2px solid transparent`, //Needed to keep the item 'balanced'
        },
    },

    //Day modifiers
    passive: {},
    disabled: {},
    weekend: {},
    weekday: {},
    startOfWeek: {},
    endOfWeek: {},
    startOfMonth: {},
    endOfMonth: {},
    today: {},
    selected: {},
    selectedStart: {},
    selectedEnd: {},
    preview: {},
    previewStart: {},
    previewEnd: {},
}));
