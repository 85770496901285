import React from 'react';
import PropTypes from 'prop-types';

import Button from 'hsi/components/Button';
import {Dialog, DialogContent, DialogActions} from 'hsi/components/Dialog';

import tns from 'hsi/i18n/tns';
const t = tns('queryBuilder');

const BackDialog = ({onAccept, onReject, open}) => {
    return (
        <Dialog open={open} title={t('editingThisQuery')} onClose={onReject}>
            <DialogContent>{t('swithToAdvancedDescription')}</DialogContent>
            <DialogActions>
                <Button priority="primary" size="small" onClick={onReject}>
                    {t('stayInGuidedButton')}
                </Button>
                <Button priority="cta" size="small" onClick={onAccept}>
                    {t('swithToAdvancedButton')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

BackDialog.propTypes = {
    onAccept: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default BackDialog;
