import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(({v2, mixin, fonts}) => ({
    savedSearchBtnMsg: {
        textAlign: 'left',
        padding: 16,
        maxWidth: '250px',
    },
    saveSearchLink: {
        ...mixin.blankButton,
        ...mixin.linkColor,
        cursor: 'pointer',

        ...(v2 ? fonts.bodySmallStrong : {}),
    },
}));
