import {useEffect, useMemo} from 'react';

//Components
import HelpCenterButton from './HelpCenterButton';
import MainTitle from 'hsi/components/MainTitle';
import HeaderBar from 'hsi/components/HeaderBar';
import Nav, {NavItemProps} from 'hsi/components/Nav';
import ImpersonationDialog from '../ImpersonationDialog';
import ImpersonateButton from './ImpersonateButton';
import NavButton from './NavButton';
import Banner from 'hsi/components/Banner';

//Hooks
import useIsViewOnly from 'hsi/hooks/useIsViewOnly';
import useConfig from 'hsi/hooks/useConfig';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useHasQuickSearch from 'hsi/hooks/useHasQuickSearch';
import useFlags from 'hsi/hooks/useFlags';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';
import {TrackingNames} from 'hsi/utils/trackingNames';
import {introAnchors} from 'hsi/containers/Intro';

//Utils
const getNavLinks = (
    track: (key: TrackingNames, value?: Record<string, any>) => void,
    hasQuickSearch: boolean,
): NavItemProps[] => [
    {
        children: T('homeNavBar.quicksearch'),
        url: '/',
        onClick: () => {
            track('homePageButtonClicked', {
                from: 'Saved Search',
                to: 'Quick Search',
            });
        },
        isActive: (match, location) => {
            return location.pathname === '/' || location.pathname.match(/^\/search\/results\/?$/);
        },
        hide: !hasQuickSearch,
        title: T('homeNavBar.quicksearch'),
    } as NavItemProps,
    {
        children: T('homeNavBar.savedsearches'),
        url: '/searches',
        onClick: () => {
            track('savedSearchesPageButtonClicked', {
                from: 'Quick Search',
                to: 'Saved Search',
            });
        },
        isActive: (match, location) => {
            return !!match || location.pathname.match(/^\/search\/results\/[0-9]+/);
        },
        hide: false,
        title: T('homeNavBar.savedsearches'),
    } as NavItemProps,
];

export default function HomeNavBar() {
    const {links} = useConfig();
    const {track} = useEventTrack();
    const classes = useStyles();
    const {showsHsiExitBanner} = useFlags();
    const {appSource} = useConfig();

    const hasQuickSearch = useHasQuickSearch();
    const isViewOnly = useIsViewOnly();

    const hsiExitBannerStr = (
        <p>
          <b>Action Required: Your Insights subscription is set to expire.</b>
          {' '}To avoid a disruption in service and retain all your searches <b>please reach out to Brandwatch{' '}
          <a href="https://www.brandwatch.com/p/brandwatch-listen-upgrade/">directly here</a>
          {' '}to retain access to this product.</b>
        </p>
      );

    useEffect(() => {
        // This is the highest point in the component hierarchy that has access to LD flags
        track('userLoggedIn', {
            loginDate: new Date(Date.now()),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navLinks = useMemo(() => getNavLinks(track, hasQuickSearch), [track, hasQuickSearch]);

    return (
        <>
            {/* Banner to display HSI contract ending for HSI users */}
            {appSource === 'HSI' && showsHsiExitBanner && (
                <Banner variant="hsiExit" noMargin hidable>
                    {hsiExitBannerStr}
                </Banner>
            )}

            <HeaderBar title={<MainTitle />} titleComponent="div">
                <Nav className={classes.nav} aria-label={T('homeNavBar.navLbl')}>
                    {navLinks.map((item) => (
                        <Nav.Item {...item} key={item.url} />
                    ))}

                    {!isViewOnly && (
                        <NavButton
                            to="/alerts/"
                            label={T('homeNavBar.emailAlerts')}
                            icon="email"
                            tooltip={T('homeNavBar.emailAlerts')}
                            tooltipNoAria
                            data-testid="emailAlerts"
                            onClick={() => {
                                track('alertsPageButtonClicked', {});
                            }}
                            right
                        />
                    )}
                    <NavButton
                        to="/reports/"
                        label={T('homeNavBar.reports')}
                        icon="mui-equalizer"
                        tooltip={T('homeNavBar.reports')}
                        tooltipNoAria
                        onClick={() => {
                            track('reportsPageButtonClicked', {});
                        }}
                        right
                    />

                    {!isViewOnly && <HelpCenterButton url={links.helpCenter} />}

                    {!isViewOnly && (
                        <NavButton
                            className={introAnchors.dataManagement.settingsButton}
                            to="/data-management"
                            label={T('homeNavBar.dataManagement')}
                            hideTextLabel
                            icon="settings-gear-64"
                            tooltip={T('homeNavBar.dataManagement')}
                            tooltipNoAria
                            onClick={() => {
                                track('manageTokensButtonClicked', {});
                            }}
                            right
                        />
                    )}
                </Nav>
                <ImpersonateButton />
                {!isViewOnly && <ImpersonationDialog />}
            </HeaderBar>
        </>
    );
}
