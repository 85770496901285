
/**
 * merges supplied values into array of all unique values. If values are arrays or sets will merge all values they contain.
 * This will not work if the values you're trying to merge are themselves arrays or sets. 
 * @param values 
 */
export default function mergeUniqueValues<T = any>(...values: (T | T[] | Set<T>)[]): T[] {
    const uniqueValues = new Set<T>();

    for(let value of values) {
        if((value instanceof Array) || (value instanceof Set)) {
            value.forEach((val) => {
                uniqueValues.add(val);
            })
        } else {
            uniqueValues.add(value);
        }
    }

    return Array.from(uniqueValues);
}