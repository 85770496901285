import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => ({
    button: {
        position: 'absolute',
        zIndex: 2,
        top: theme.v2 ? 15 : 33,
        right: theme.v2 ? 15 : 48,
        padding: theme.v2 ? 0 : `10px`,
    },
    icon: {
        width: 24,
        height: 24,
    },
}));
