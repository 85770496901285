import { forwardRef } from "react";
import TableDisplay, {TableDisplayComponentsType} from "../TableDisplay";

import DataGrid from "hsi/components/aria/DataGrid";
import { useManagedTableCellContext, useManagedTableHeaderCellContext } from ".";

export function wrapComponentsInGrid(DisplayComponents: TableDisplayComponentsType): TableDisplayComponentsType {
    return {
        Caption: DisplayComponents.Caption,
        Head: DisplayComponents.Head,
        Body: DisplayComponents.Body,
        Foot: DisplayComponents.Foot,
        Row: DisplayComponents.Row,
        TH: forwardRef<HTMLTableCellElement, any>((props, ref) => {
            const column = useManagedTableHeaderCellContext();

            return <DataGrid.ColumnHeader value={`header::${column?.toString()}`}><DisplayComponents.TH {...props} ref={ref} /></DataGrid.ColumnHeader>
        }),
        TD: forwardRef<HTMLTableCellElement, any>((props, ref) => {
            const {columnIndex, rowIndex} = useManagedTableCellContext();

            return <DataGrid.Cell value={`value:${columnIndex}:${rowIndex}`}><DisplayComponents.TD {...props} ref={ref} /></DataGrid.Cell>
        }),
        SortIndicator: forwardRef<HTMLElement, any>((props, ref) => {
            return <DisplayComponents.SortIndicator tabIndex={-1} {...props} ref={ref} />
        }),
    };
}

// //const GridTableDisplay: TableDisplayType = function GridTableDisplay(props) {
//     return <DataGrid>
//     <DisplayComponents {...props} />
// </DataGrid>
// }

export default wrapComponentsInGrid(TableDisplay);