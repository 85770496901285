const styles = (theme) => {
    const leftBorderWidth = theme.v2 ? 0 : '8px';

    return {
        root: {
            flex: `0 0 ${theme.elements.mentionsDrawerWidth}`,
            background: theme.v2 ? theme.colors.uiGrayDark : theme.colors.lightGrey40, //#EDEBE7, #F4F1EE
            borderLeft: leftBorderWidth
                ? `${leftBorderWidth} solid ${theme.colors.lightGrey80}`
                : null,
            position: 'relative',
            zIndex: 999,
            '--closedOffset': `calc(-${theme.elements.mentionsDrawerWidth} + 48px)`,
        },
        rootFull: {
            flexGrow: 1,
            borderLeft: theme.v2 ? `1px solid ${theme.colors.borders}` : 0,
        },
        inner: {
            width: leftBorderWidth
                ? `calc(${theme.elements.mentionsDrawerWidth} - ${leftBorderWidth})`
                : theme.elements.mentionsDrawerWidth,
            minHeight: '100%',
            position: 'relative',
            display: 'flex',
        },
        innerFull: {
            width: 'auto',
        },
        openBtn: {
            margin: 0,
            marginLeft: theme.v2 ? '4px' : '12px',
            padding: 0,
            background: 'transparent',
            border: 0,
            cursor: 'pointer',
            position: 'absolute',
            top: theme.v2 ? '26px': '18px',
            left: '8px',
            zIndex: 999,
            opacity: 1,
            '&.opening': {
                opacity: 0,
            },
            '&.closed, &.closing': {
                opacity: 1,
            },
            '&:hover $openBtnLabel, &:focus $openBtnLabel': {
                opacity: 1,
            },
        },
        hide: {
            display: 'none',
        },
        openBtnLabel: theme.v2
            ? {
                  display: 'block',
                  opacity: 0,
                  position: 'absolute',
                  top: 0,
                  right: '100%',
                  color: theme.colors.white,
                  background: theme.colors.darkGrey100,
                  padding: '8px 16px',
                  margin: '8px -10px',
                  whiteSpace: 'nowrap',
                  fontFamily: theme.font.name,
                  borderRadius: '3px',
                  ...theme.typgrphy.bodySmall,
                  '&:after': {
                      content: '""',
                      position: 'absolute',
                      left: '100%',
                      top: '50%',
                      margin: '-5px 0',
                      display: 'block',
                      width: '0',
                      height: '0',
                      border: `5px solid transparent`,
                      borderLeftColor: theme.colors.darkGrey100,
                  },
              }
            : {
                  display: 'block',
                  opacity: 0,
                  position: 'absolute',
                  top: 0,
                  right: '100%',
                  color: theme.colors.lightGrey10,
                  background: theme.colors.darkGrey100,
                  padding: '6px 28px',
                  margin: '5px 8px',
                  whiteSpace: 'nowrap',
                  fontWeight: theme.font.bold,
                  fontFamily: theme.font.name,
                  fontSize: '16px',
              },
        openBtnIcon: {
            overflow: 'visible',
            background: 'transparent',
            padding: '8px',
            position: 'absolute',
            width: '28px',
            height: '28px',
        },
        doubleArrowIconRight: {
            overflow: 'visible',
            width: '14px',
            height: '12px',
            fill: 'rgba(63, 63, 63, 1)',
        },
    };
};

export default styles;
