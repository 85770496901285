const BRACKETS_COLOR = '#000000';
const COLON_OPERATORS_COLOR = '#00A682';
const COMMENT_COLOR = '#A7A7A7';
const EXACT_SPELLING_COLOR = '#606060';
const KEY_OPERATORS_COLOR = '#3B79CC';
const LINK_HASHTAG_COLOR = '#2F9170';
const PROXIMITY_OPERATOR_COLOR = '#3F3F3F';
const QUOTES_COLOR = '#F98A39';

const HIGHLIGHTING_COLORS = [
    {token: 'operator', foreground: COLON_OPERATORS_COLOR},
    {token: 'keyword', foreground: KEY_OPERATORS_COLOR},
    {token: 'comment', foreground: COMMENT_COLOR},
    {token: 'constant.character.asterisk', foreground: COLON_OPERATORS_COLOR},
    {token: 'constant.character.interro', foreground: LINK_HASHTAG_COLOR},
    {token: 'constant.character.negation', foreground: LINK_HASHTAG_COLOR},
    {
        token: 'constant.character.proximity',
        foreground: PROXIMITY_OPERATOR_COLOR,
    },
    {token: 'delimiter.curly', foreground: EXACT_SPELLING_COLOR},
    {token: 'delimiter.parenthesis', foreground: BRACKETS_COLOR},
    {token: 'keyword.operator.near', foreground: KEY_OPERATORS_COLOR},
    {token: 'string', foreground: QUOTES_COLOR},
    {token: 'link', foreground: LINK_HASHTAG_COLOR},
    {token: 'hashtag', foreground: LINK_HASHTAG_COLOR},
    {token: '@', foreground: LINK_HASHTAG_COLOR},
    {token: 'exactspelling', foreground: EXACT_SPELLING_COLOR},
];

export default HIGHLIGHTING_COLORS;
