import {registerFilterType, isValueValid} from './../utils';

registerFilterType(
    'select',
    () => null,
    (filterConfig, filterState) => !filterState,
    {}, //reducers
    {
        //validated reducers func(state, payload)
        setValueSelect: (state, {filterName, value}) => {
            state.filters[filterName] = value;
        },
    },
    //
    (state, filterName, value) => {
        const filterConfig = state.allFiltersConfig[filterName];

        if (filterConfig.options.find((option) => option === value || option.value === value)) {
            return true;
        }

        return filterConfig.unknownValueErrMsg || 'filters.validation.unknownValue';
    },
    (state, filterConfig, {action, value, mode, filterName, fieldName}) => {
        if (action !== 'setValueSelect') {
            throw new Error(
                'Invalid action for this filter type, check you have the correct filterName and/or are using the correct action',
            );
        }

        return isValueValid(state, filterName, value);
    },
    undefined,
    undefined,
    undefined,
    undefined,
    (filterConfig, currentValue, persistedValue) => {
        if (
            filterConfig.options.find(
                (option) => option === persistedValue || option.value === persistedValue,
            )
        ) {
            return persistedValue;
        }

        return '';
    },
);
