import {useCallback, useEffect, useMemo, useState} from 'react';
import isEqual from 'lodash/isEqual';
import uniqby from 'lodash/uniqBy';

import usePrevious from 'hsi/hooks/usePrevious';

import savedSearchService from 'hsi/services/savedSearchService';

import {createError} from '../errors';

const MAX_QUERIES = 20;

// TODO: This currently assumes that searchId is an array which seems odd to me.
// We should either call it searchIds or convert it to assume that searchId is a string
const useProject = ({query, searchId, projects}) => {
    const prevQuery = usePrevious(query);
    const prevSearchId = usePrevious(searchId[0]);

    const queryChanged = useMemo(() => !isEqual(prevQuery, query), [prevQuery, query]);
    const searchIdChanged = useMemo(
        () => !isEqual(prevSearchId, searchId[0]),
        [prevSearchId, searchId],
    );
    const quickSearchProject = useMemo(() => projects?.[0], [projects]);
    const initialProject = useMemo(
        () => (query !== undefined ? quickSearchProject : undefined),
        [query, quickSearchProject],
    );

    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [project, setProject] = useState(initialProject);

    const fetchProject = useCallback(
        async (_projects) => {
            setError();
            setLoading(true);
            if (searchId.length > MAX_QUERIES) {
                setLoading(false);
                setError(createError(`Please select up to 20 searches at a time`));
                return;
            }

            try {
                const searches = await savedSearchService.loadSearches(_projects);
                const projects = (searches?.results || [])
                    // Internal search id is a number but parsed search id can be a string
                    .filter(({id}) => searchId.some((searchId) => String(id) === String(searchId)))
                    .map(({project}) => project);
                const projectIds = projects?.map(({id}) => id);

                if (projectIds.length === 0) {
                    setProject();
                    setError(createError(`Project not found for search Id ${searchId[0]}`));
                    return;
                }
                if (uniqby(projectIds).length > 1) {
                    setProject();
                    setError(createError(`No support for multiple projects`));
                    return;
                }
                const newProject = projects[0];
                setError();
                setProject(newProject);
            } catch (newError) {
                setError(newError);
            } finally {
                setLoading(false);
            }
        },
        [searchId],
    );

    useEffect(() => {
        if (!loading && queryChanged) {
            setProject(quickSearchProject);
        } else if (!loading && searchIdChanged) {
            fetchProject(projects);
        }
    }, [fetchProject, loading, queryChanged, quickSearchProject, searchIdChanged, projects]);

    return {
        project: searchIdChanged ? undefined : project,
        loading: searchIdChanged ? true : loading,
        error,
    };
};

export default useProject;
