
import { ChangeEvent, ChangeEventHandler, useCallback } from 'react';
import classNames from 'classnames';
import useStyles from './styles';
import IconRouter from '../IconRouter';

export type SelectProps<T> = {
    value?: T,
    setValue?: (value: T, e: ChangeEvent<HTMLSelectElement>) => void,
} & Omit<JSX.IntrinsicElements['select'], 'multiple' | 'size'>;


export default function Select<T = string>({className, value, setValue, onChange: _onChange, ...rest}: SelectProps<T>) {
    const classes = useStyles();

    const onChange: ChangeEventHandler<HTMLSelectElement> = useCallback((e) => {
        _onChange?.(e);

        setValue?.(e.target.value as unknown as T, e);
    }, [setValue, _onChange]);

    return <div className={classNames(classes.selectWrapper, className)}>
        <select value={value} onChange={onChange} className={classes.select} {...rest} />
        <IconRouter className={classes.icon} name='down-arrow' aria-hidden />
    </div>
}