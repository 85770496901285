export function filter<TObj extends {}, TReturn extends {} = Partial<TObj>>(
    obj: TObj,
    func: (value: any, key: string, obj: TObj) => boolean,
): TReturn {
    return Object.keys(obj).reduce<TReturn>((output, key) => {
        const value = obj[key as keyof TObj];

        if (func(value, key, obj)) {
            output[key as keyof TReturn] = value as any;
        }

        return output;
    }, {} as TReturn);
}
