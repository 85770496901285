import {makeStyles} from '@mui/styles';

export default makeStyles((theme) => ({
    control: {
        margin: '2px 0 25px 0',
    },

    configMenu: {
        position: 'relative',
        zIndex: 1,
        width: '100%',
        height: 'auto',
        minHeight: '46px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.v2 ? theme.colors.white : theme.colors.lightGrey10,
        borderRadius: '2px',
        padding: '6px 10px',
        color: theme.v2 ? theme.colors.textDefault : theme.colors.primaryBlue100,
        fontWeight: 'bold',
        cursor: 'pointer',
        border: theme.v2
            ? `1px solid ${theme.colors.borders}`
            : `1px solid ${theme.colors.darkGrey60}`,
    },

    openMenuBtn: {
        ...theme.mixin.blankButton,
        position: 'absolute',
        top: '-1px',
        left: '-1px',
        bottom: '-1px',
        right: '-1px',
    },

    chipsWrapper: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '2px',
        position: 'relative',
    },

    placeholder: {
        display: 'flex',
        alignItems: 'center',
        opacity: '1',
        fontStyle: theme.v2 ? 'normal !important' : 'italic !important',
        fontWeight: theme.v2 ? `${theme.fonts.bodyNormal.fontWeight} !important` : '400 !important',
        color: theme.v2
            ? `${theme.colors.textDisabled} !important`
            : `${theme.colors.darkGrey60} !important`,
        fontSize: theme.v2 ? `${theme.fonts.bodyNormal.fontSize} !important` : '16px !important',
        pointerEvents: 'none',
    },

    downArrowIcon: {
        fill: '#143059',
        position: 'relative',
        display: 'flex',
        alignSelf: 'center',
        pointerEvents: 'none',
    },

    chip: {
        '&:focus': {
            outline: `2px solid ${theme.v2 ? theme.colors.warning : theme.colors.primaryYellow100}`,
        },
    },

    menuContent: {
        display: 'block',

        '&&': {
            //needed to deal with MUI styles
            margin: 0,
        },
    },

    selectMenuActions: {
        margin: '1em',
        float: 'right',
        '& button': {
            margin: '0 5px',
        },
    },
}));
