import {useCallback, useRef} from 'react';

type Params = Parameters<typeof useCallback>;

export default function useRefCallback<T extends Params[0]>(callback: T): T {
    const callbackRef = useRef<T>(callback);
    callbackRef.current = callback; //ref always points to most recent version of callback

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback<T>(((...args: any[]) => callbackRef.current(...args)) as any, []);
}
