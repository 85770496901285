import React, {forwardRef} from 'react';

//Components
import TopMeasureBySearch, { TopMeasureItem } from 'hsi/components/Cards/TopMeasureBySearch';

//Utils
import { getDomainDrillInFilters } from 'hsi/utils/filters/drillInFilters';

//Other
import {T} from 'hsi/i18n';
import { CardTypeProps } from 'hsi/types/cards';
import { formatDomainHeader } from '../TopSites/DomainHeader';

//Consts
const type = 'topsitesBySearch';
const getDrillInData = ({name: domain}: TopMeasureItem) => ({
    dates: undefined,
    filter: getDomainDrillInFilters(domain, true), //multiple search will always be saved search
    label: domain,
    tracking: {
        name: 'cardDrilledIn',
        type,
        value: domain,
    },
    type,
} as const);
const nameField = {
    label: T(`cards.${type}.nameFieldLabel`),
    format: formatDomainHeader,
};


// The component
const TopSitesBySearch = forwardRef<HTMLDivElement, CardTypeProps>(function TopSitesBySearch(props, ref) {
    return (
        <TopMeasureBySearch
            type={type}
            dataSelector={(state) => state.chart[type]}
            getDrillInData={getDrillInData}
            nameField={nameField}
            ref={ref}
            {...props}
        />
    );
});

export default TopSitesBySearch;
