import {useCallback} from 'react';

//Utils
import { getHashtagDrillInFilters } from 'hsi/utils/filters/drillInFilters';

import {TopHashtagsCardData} from 'hsi/types/cards';

const type = 'tophashtags' as const;

export default function useGetDrillInData() {
    const getDrillInData = useCallback(
        (hashtagData: TopHashtagsCardData[number]) => ({
            dates: undefined,
            filter: getHashtagDrillInFilters(hashtagData.name),
            label: hashtagData.name,
            tracking: {
                name: 'cardDrilledIn',
                type,
                value: hashtagData.name,
            },
            type,
        }),
        [],
    );

    return getDrillInData;
}
