import makeStyles from '@mui/styles/makeStyles';
import {isV2, Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);
    return {
        entitiesRemaining: {
            backgroundColor: v2 ? theme.colors.bgSubtle : theme.colors.secondaryGreen20,
            borderRadius: 100,
            flex: '0 0 auto',
            display: 'inline-flex',
            alignItems: 'center',
            padding: '0 12px',
            lineHeight: '30px',
        },
        remaining: {
            flex: '0 0 auto',
            color: v2 ? theme.colors.textDefault : theme.colors.darkGrey100,

            ...(v2 ? theme.fonts.bodySmall : {fontWeight: 600, fontSize: 14}),
        },
        icon: {
            marginLeft: 7,
            flex: '0 0 auto',
            fill: v2 ? theme.colors.bgOverlayHeavy : theme.colors.darkGrey80,
        },
        successIcon: {
            marginRight: 7,
            fill: v2 ? theme.colors.success : theme.colors.secondaryGreen100,
        },

        showAsWarning: v2
            ? {}
            : {
                  backgroundColor: theme.colors.primaryYellow20,
                  height: 24,
                  borderRadius: 12,
                  padding: '0 12px',
              },

        warningIcon: {
            fill: v2 ? theme.colors.warning : theme.colors.primaryYellow100,
            flex: '0 0 20px',
            height: 20,
            width: 20,
            marginRight: 7,
        },

        popup: {
            padding: 16,
            display: 'flex',
            textAlign: 'left',
            width: 350,
        },

        popupTextWrapper: {
            flex: '1 1 auto',
        },

        popupTitle: {
            marginBottom: 8,
            ...(v2 ? theme.fonts.bodySmallStrong : {fontSize: 14, fontWeight: 'bold'}),
        },

        popupContent: {
            maxWidth: 208,
            ...(v2 ? theme.fonts.bodySmall : {fontSize: 11}),
        },
    };
});
