import {Redirect} from 'react-router-dom';
import useHasQuickSearch from 'hsi/hooks/useHasQuickSearch';
import QuickSearch from 'hsi/containers/QuickSearch';

export default function Home() {
    const hasQuickSearch = useHasQuickSearch();

    return !hasQuickSearch 
        ? <Redirect to="/searches" /> 
        : <QuickSearch />
};
