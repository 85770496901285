import {createSlice} from '@reduxjs/toolkit';
import {ISocialPanel} from 'hsi/types/socialPanels';
import {getSocialPanels} from './thunks';

const initialState: TSocialPanelState = {
    results: [],
    loading: false,
    loaded: false,
    error: null,
    total: 0,
};

export type TSocialPanelState = {
    results: ISocialPanel[];
    loading: boolean;
    loaded: boolean;
    error: null | string;
    total: null | number;
};

const slice = createSlice({
    name: 'socialPanels',
    initialState,
    reducers: {
        resetSocialPanels: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSocialPanels.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getSocialPanels.fulfilled, (state, action) => {
                state.loading = false;
                state.loaded = true;
                state.error = null;
                state.results = action.payload.results;
                state.total = action.payload.total;
            })
            .addCase(getSocialPanels.rejected, (state, action) => {
                state.loading = false;
                state.loaded = false;
                state.error = action.error.message || 'An error occurred whilst fetching social panels.';
                state.results = [];
                state.total = 0;
            });
    },
});

export const {resetSocialPanels} = slice.actions;
export default slice.reducer;
