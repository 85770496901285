import {SHOW_SNACKBAR_NOTIFICATION, HIDE_SNACKBAR_NOTIFICATION} from '../constants/actionTypes';

export function showNotification(notification) {
    return {
        type: SHOW_SNACKBAR_NOTIFICATION,
        payload: notification,
    };
}

export function hideNotification() {
    return {
        type: HIDE_SNACKBAR_NOTIFICATION,
        payload: null, //PP what is the point in a payload that is always null?
    };
}
