import {useTheme} from '@mui/material/styles';

import Button from 'hsi/components/Button';

import useStyles from './styles';

import {Theme} from 'hsi/types/theme';
import {T} from 'hsi/i18n';

type NoResultsProps = {
    handleAddDataSource: () => void;
    label: string;
};

const NoResults = ({handleAddDataSource, label}: NoResultsProps) => {
    const classes = useStyles();
    const {elements, v2}: Theme = useTheme();

    return (
        <>
            <img alt="" className={classes.pageImg} src={elements.noResultsImg} />
            <div
                aria-level={4}
                className={classes.pageTitle}
                dangerouslySetInnerHTML={{
                    __html: T('dataManagement.noResults.title', {dataType: label}),
                }}
                role="heading"
            />
            <p className={classes.pageDesc}>{T('dataManagement.noResults.desc')}</p>
            <Button
                className={classes.pageButton}
                onClick={handleAddDataSource}
                priority={v2 ? 'cta' : 'secondary'}
            >
                {T('dataManagement.noResults.cta')}
            </Button>
        </>
    );
};

export default NoResults;
