import React, {useMemo, useRef, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {CarrotSearchCircles, convertLingo3GCluster, defaultOptions} from '@brandwatch/topics-wheel';

//Components
import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle from 'hsi/components/Card/CardTitle';
import InfoPopupContent from 'hsi/components/InfoPopupContent';
import SaveSearchButton from 'hsi/components/SaveSearchButton';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useElementSize from 'hsi/hooks/useElementSize';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useGetLoadData from '../useGetLoadData';
import useQueryContext from 'hsi/hooks/useQueryContext';

//Actions
import {mentionsDrillIn} from 'hsi/slices/mentions';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Constants
const type = 'topicWheel';
const MAX_TOPIC_WHEEL_MENTION_IDS = 100; // Too many mention IDs in the request triggers a 431 error and sometimes a 400 error

//The components
const TopicWheel = React.forwardRef(({title, ...props}, ref) => {
    const classes = useStyles();
    const {
        links: {dashboardInfoTopicWheelCTA: popupCTA},
        themeColors: {topicWheel: color},
    } = useConfig();
    const {trackWithSearchData} = useEventTrack();
    const {isSavedSearch} = useQueryContext();

    const topicWheelRef = useRef();

    //Could debounce this, but I think debouncing is already handled by the container
    const [containerRef, {width: containerWidth} = {}, container] = useElementSize(null, {
        width: true,
    });

    //Redux
    const dispatch = useDispatch();
    const {data, loading, loaded, error} = useSelector((state) => state.chart[type]);
    const isSelectedCard = useSelector((state) => state.mentions.drillInCard === type);

    //Calculated values
    const loadData = useGetLoadData(type);

    const content = useMemo(() => {
        if (!isSavedSearch) {
            const msg = T(`cards.${type}.cta`).split('[link]');
            const saveLink = (
                <SaveSearchButton inlineLink>{T('uniqAuthorsCTA.linkText')}</SaveSearchButton>
            );
            msg.splice(1, 0, saveLink);

            return (
                <div className={classes.topicWheelUpsell}>
                    {msg.map((part, i) => (
                        <span key={i}>{part}</span>
                    ))}
                </div>
            );
        } else {
            return (
                <div
                    className={classes.container}
                    data-testid={`${type}Chart`}
                    ref={containerRef}
                />
            );
        }
    }, [classes, containerRef, isSavedSearch]);

    const popup = useMemo(
        () => (
            <InfoPopupContent
                copy={T(`cards.${type}.info.copy`)}
                ctaLabel={T('cards.infoCTALabel')}
                ctaUrl={popupCTA}
                title={T(`cards.${type}.info.title`)}
            />
        ),
        [popupCTA],
    );

    //Callbacks
    const drillIn = useCallback(
        (drillIn) => {
            const drillinFilter = {
                'topicWheel-name': drillIn.group.label,
                'topicWheel-mentions': drillIn.group.filter.mentionId.slice(
                    0,
                    MAX_TOPIC_WHEEL_MENTION_IDS,
                ),
            };
            trackWithSearchData('cardDrilledIn', {
                type,
                value: drillIn.group.label,
            });
            dispatch(mentionsDrillIn(drillinFilter, type, null, drillIn.group.label));
        },
        [dispatch, trackWithSearchData],
    );

    //Side effects
    useEffect(() => {
        if (!!data && container) {
            //There is already a topic wheel.
            if (topicWheelRef.current) {
                //If element has changed, we need to recreate
                if (topicWheelRef.current.get().element !== container) {
                    topicWheelRef.current.dispose();
                    topicWheelRef.current = null;
                }
            }

            const options = {...defaultOptions, rolloutAnimation: 'none', rolloutTime: 0};

            //Init topic wheel
            if (!topicWheelRef.current) {
                topicWheelRef.current = new CarrotSearchCircles({
                    ...options,
                    element: container,
                    onGroupClick: drillIn,
                    backgroundColor: color,
                });
            }
            //update topic wheel
            topicWheelRef.current.set({
                dataObject: {
                    groups: convertLingo3GCluster(data.groups),
                },
            });
        }
    }, [color, container, containerWidth, data, drillIn]);

    //if the element is resized, tell the topic wheel to resize
    useEffect(() => {
        topicWheelRef.current && topicWheelRef.current.resize();

        //On unload, do tidy up
        return () => {
            if (topicWheelRef.current) {
                topicWheelRef.current = null;
            }
        };
    }, [containerWidth]);

    //Render
    return (
        <CardLoadState
            {...props}
            title={
                <CardTitle
                    title={title}
                    tooltipComponent={popup}
                    type={type}
                    hasData={isSavedSearch ? !!data : true}
                />
            }
            error={error}
            loading={isSavedSearch ? loading : false}
            loaded={isSavedSearch ? loaded : true}
            hasData={isSavedSearch ? !!data : true}
            loadData={isSavedSearch ? loadData : null}
            selected={isSelectedCard}
            type={type}
            ref={ref}
            data-testid={type}
        >
            {content}
        </CardLoadState>
    );
});

TopicWheel.propTypes = {
    props: PropTypes.shape({
        fadeOnVisible: PropTypes.func.isRequired,
        height: PropTypes.number.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onContentChanged: PropTypes.func.isRequired,
        showConfig: PropTypes.bool.isRequired,
    }),
};

TopicWheel.displayName = 'TopicWheel';

export default TopicWheel;
