import { useMemo } from "react";
import { TableSortDir } from "../types";
import { ColumnSortFunc, SortColumnsDefinition } from "./types";



export default function useSortRows<RowItemType, Columns extends string | number | symbol = keyof RowItemType>(rows: RowItemType[] | Readonly<RowItemType[]>, columns: SortColumnsDefinition<RowItemType, Columns>, sortColumn: Columns | undefined, sortDir: TableSortDir | undefined) {
    const compareFn = useMemo(() => {
        return sortColumn ? getSortCompareFunc(columns, sortColumn, sortDir) : null;
    }, [columns, sortColumn, sortDir]);

    return useMemo(() => {
        if(!compareFn) {
            return rows;
        }

        const clonedRows = [...rows];

        clonedRows.sort(compareFn);

        return clonedRows

    }, [rows, compareFn]);
}

export function getSortCompareFunc<RowItemType, Columns extends string | number | symbol = keyof RowItemType>(columns: SortColumnsDefinition<RowItemType, Columns>, sortColumn: Columns, sortDir: TableSortDir | undefined) {
    const columnDefinition = columns.find(({name}) => name === sortColumn);
    const dirMod = sortDir === 'desc' ? -1 : 1;

    switch(columnDefinition?.sortType) {
        case undefined:// column definition does not supply a value for sortType, default to natural sorting
        case 'natural':
            const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

            return (a: RowItemType, b: RowItemType) => {
                const v1 = a[sortColumn as unknown as keyof RowItemType]?.toString() ?? '';
                const v2 = b[sortColumn as unknown as keyof RowItemType]?.toString() ?? '';

                return collator.compare(v1, v2) * dirMod;
            };
        case 'numeric':
            return (a: RowItemType, b: RowItemType) => {
                const v1 = +(a[sortColumn as unknown as keyof RowItemType] ?? 0);
                const v2 = +(b[sortColumn as unknown as keyof RowItemType] ?? 0);

                return (v1 - v2) * dirMod;
            };
        case null:// No column definition found, do not sort
            return undefined;
        default:
            return dirMod === -1 
                ? (a: RowItemType, b: RowItemType) => ((columnDefinition!.sortType as ColumnSortFunc<RowItemType>)(a, b) * -1)
                : columnDefinition!.sortType as ColumnSortFunc<RowItemType>;
                
    }
}