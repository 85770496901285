import {useAppSelector} from './useRedux';

export default function useGetProjectIdFromQueryId(queryId: number |  null): number | null {
    return useAppSelector(
        queryId === null 
            ? () => null 
            : (state) =>
                (state.search as any).searches.find(({id}: any) => id === queryId)?.project?.id ?? null,
    );
}
