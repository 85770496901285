import {makeStyles} from 'tss-react/mui';

export default makeStyles()(({colors, font}, _params, classes) => {
    return {
        root: {
            padding: '0 !important',
            pointerEvents: 'none',

            [`&.light-theme`]: {
                [`& .${classes.tooltipContent}`]: {
                    color: `${colors.textDefault} !important`,
                    fontWeight: font.regular,
                },
            },
            [`&.warning-theme`]: {
                backgroundColor: colors.warningBg,
                color: colors.textDefault,
                [`& .${classes.tooltipContent}`]: {
                    color: colors.textDefault,
                    fontWeight: font.regular,
                    textAlign: 'left',
                },
                '&[data-popper-placement^=right] .tippy-arrow': {
                    borderRight: `8px solid ${colors.warningBg}`,
                },
                '&[data-popper-placement^=left] .tippy-arrow': {
                    borderLeft: `8px solid ${colors.warningBg}`,
                },
                '&[data-popper-placement^=bottom] .tippy-arrow': {
                    borderBottom: `8px solid ${colors.warningBg}`,
                },
                '&[data-popper-placement^=top] .tippy-arrow': {
                    borderTop: `8px solid ${colors.warningBg}`,
                },
            },
        },

        tooltipContent: {
            padding: '3px 9px',
            fontSize: 13,
            fontWeight: font.semiBold,
            color: 'white',
            maxWidth: '300px',
            wordWrap: 'break-word',
        },
        morePadding: {
            [`&.${classes.tooltipContent}`]: {
                padding: '16px 24px',
            },
        },

        left: {
            [`&.${classes.tooltipContent}`]: {
                textAlign: 'left',
            },
        },
        right: {
            [`&.${classes.tooltipContent}`]: {
                textAlign: 'right',
            },
        },
    };
});
