import Button from 'hsi/components/Button';
import DialogContainer from 'hsi/containers/DataManagement/AddDataSourceDialog/DialogContainer';
import Markdown from 'hsi/components/Markdown';

import {DataManagementDataTypes} from 'hsi/types/dataManagement';
import {T} from 'hsi/i18n';

type DeleteDialogProps = {
    name?: string;
    onClose: () => void;
    onConfirm: () => void;
    open: boolean;
    type: DataManagementDataTypes;
};

const DeleteDialog = ({name, onClose, onConfirm, open, type}: DeleteDialogProps) => {
    if (!open || !name) return null;

    return (
        <DialogContainer
            actions={
                <>
                    <Button onClick={onClose} priority="secondary">
                        {T('confirmDialog.cancel')}
                    </Button>
                    <Button onClick={onConfirm} priority="cta">
                        {T('confirmDialog.confirm')}
                    </Button>
                </>
            }
            onClose={onClose}
            title={T(`dataManagement.deleteDialog.title.${type}`)}
        >
            <Markdown data-testid="deleteDialogDesc">
                {T(`dataManagement.deleteDialog.desc.${type}`, {name})}
            </Markdown>
        </DialogContainer>
    );
};

export default DeleteDialog;
