import {useCallback} from 'react';

//Components
import Chip from 'hsi/components/Chip';
import Tooltip from 'hsi/components/Tooltip';
import HorizontalOverflow, {
    HorizontalOverflowProps,
    defaultGetOverflowContent,
} from 'hsi/components/layout/HorizontalOverflow';

//Other
import useStyles from './styles';

type MultipleSearchChipsProps = {
    items: number[];
    getChipLabel: (id: number) => string;
    onDeleteChip?: (id: number) => void;
    getHiddenChipsText: typeof defaultGetOverflowContent;
} & Omit<HorizontalOverflowProps, 'getOverflowContent' | 'gap'>;

//The component
export default function MultipleSearchChips({
    items, //array of query ids
    getChipLabel,
    onDeleteChip,
    getHiddenChipsText = defaultGetOverflowContent,
    className,

    ...rest
}: MultipleSearchChipsProps) {
    const {classes, cx} = useStyles();

    //Callbacks
    const getOverflowContent = useCallback(
        (overflowIndex: number, totalChildren: number) => {
            return (
                <Tooltip tooltip={getTooltipText(items, getChipLabel, overflowIndex)}>
                    <span className={classes.hiddenChips}>
                        {getHiddenChipsText(overflowIndex, totalChildren)}
                    </span>
                </Tooltip>
            );
        },
        [items, getChipLabel, classes.hiddenChips, getHiddenChipsText],
    );

    //Render
    return (
        <HorizontalOverflow
            className={cx(classes.container, className)}
            getOverflowContent={getOverflowContent}
            gap={6}
            {...rest}
        >
            {items.map((id) => (
                <Chip
                    key={id}
                    label={getChipLabel(id)}
                    onDelete={onDeleteChip && (() => onDeleteChip(id))}
                    className={classes.chip}
                    overflowTooltip
                />
            ))}
        </HorizontalOverflow>
    );
}

//Internal helpers
function getTooltipText(
    items: number[],
    getChipLabel: (id: number) => string,
    overflowIndex: number,
) {
    return items.slice(overflowIndex).map(getChipLabel).join(', ');
}
