import capitalize from 'lodash/capitalize';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import {
    APIFilterFormat,
    CheckboxesFilterState,
    FilterState,
    FilterStateFromAPI,
    FilterStateToAPI,
    FromApiFormatData,
    IncludeAndExcludeFilterFieldState,
    IncludeAndExcludeFilterState,
    RangeFilterState,
    TextfieldFilterState,
} from 'hsi/types/filters';

import {KeyOfType, PageType} from 'hsi/types/shared';

//Local types
interface MapIncludeAndExcludeArgs<T> {
    field: string;
    apiIncludeField: KeyOfType<Required<APIFilterFormat>, T[]>;
    apiExcludeField: KeyOfType<Required<APIFilterFormat>, T[]>;
}

interface MapIncludeAndExcludeToApiArgs<ItemType, IdType> extends MapIncludeAndExcludeArgs<IdType> {
    valueToAPI?: (item: ItemType) => IdType;
}

interface MapIncludeAndExcludeFromApiArgs<ID, T> extends MapIncludeAndExcludeArgs<ID> {
    getById: (id: ID, data: FromApiFormatData) => T | undefined;
}

//shared general purpose utility methods for dealing with filters

export function isValidValue(value: any) {
    const isObjOrArr = isObject(value) || isArray(value);

    //empty objects and arrays are not valid, nor are falsy values, except for 0
    return (isObjOrArr && !isEmpty(value)) || (!isObjOrArr && (!!value || value === 0));
}

//////////////////////
//-From API methods //
//////////////////////

export function getMapValFromAPI<T>(
    defaultValue: any = undefined,
    apiProp?: keyof APIFilterFormat,
): FilterStateFromAPI {
    return (data: APIFilterFormat, prop: keyof APIFilterFormat): FilterState => {
        const result: T = data[apiProp || (prop as keyof APIFilterFormat)] as T;

        return isValidValue(result) ? result : defaultValue;
    };
}

function copyStateFromAPIIfValid(data: {[key: string]: any}, prop: string): FilterState {
    const value = data[prop];

    if (isValidValue(value)) {
        return value;
    }

    return null;
}

export const selectFilterFromAPIFormat = copyStateFromAPIIfValid;
export const textfieldFilterFromAPIFormat = copyStateFromAPIIfValid;

export function checkboxesFromApiFormat(
    data: APIFilterFormat,
    prop: keyof APIFilterFormat,
    definitions: FromApiFormatData,
    mapFunc?: (value: string | boolean) => string,
): CheckboxesFilterState {
    const rawVal: any = data[prop];

    if (rawVal === null || rawVal === undefined) {
        return {};
    }

    const values: string[] = rawVal instanceof Array ? rawVal : [rawVal];

    return (
        values?.reduce((output: CheckboxesFilterState, value: string) => {
            output[mapFunc ? mapFunc(value) : value] = true;

            return output;
        }, {}) || []
    );
}

type MapCheckboxNameFunc = (value: string | boolean) => string;

export function getCheckboxesFromApiFormat(
    mapFunc: MapCheckboxNameFunc | undefined,
    apiProp: keyof APIFilterFormat | undefined = undefined,
): FilterStateFromAPI {
    return (
        data: APIFilterFormat,
        prop: keyof APIFilterFormat,
        definitions: FromApiFormatData,
    ): FilterState => checkboxesFromApiFormat(data, apiProp || prop, definitions, mapFunc);
}

//owned content = takes from domain and author in data
function mapAndFilter<ID, T>(
    arr: ID[],
    mapFunc: (id: ID, definitions: FromApiFormatData) => T | undefined,
    definitions: FromApiFormatData,
): T[] {
    return (
        arr?.reduce((output: T[], id: ID) => {
            try {
                const mapped = mapFunc(id, definitions);

                //item may no longer exist (e.g. tags can be deleted), so mapFunc may return undefined
                !!mapped && output.push(mapped);
            } catch (e) {
                console.log('mapFunc failed: ', e);
            }

            return output;
        }, []) || []
    );
}

export function getIncludeAndExcludeFromApiFormat<ID = any, T = any>(
    fields: MapIncludeAndExcludeFromApiArgs<ID, T>[],
): FilterStateFromAPI {
    return (
        data: APIFilterFormat,
        prop: keyof APIFilterFormat,
        definitions: FromApiFormatData,
    ): FilterState => {
        //init the field state
        const fieldState: IncludeAndExcludeFilterState<T> = {
            activeModeIsInclude: true,
            fields: fields.reduce(
                (
                    output: {[key: string]: IncludeAndExcludeFilterFieldState<T>},
                    {
                        field,
                        apiIncludeField,
                        apiExcludeField,
                        getById,
                    }: MapIncludeAndExcludeFromApiArgs<ID, T>,
                ) => {
                    output[field] = {
                        include: apiIncludeField
                            ? mapAndFilter<ID, T>(
                                  data[apiIncludeField] as unknown as ID[],
                                  getById,
                                  definitions,
                              )
                            : [],
                        exclude: apiExcludeField
                            ? mapAndFilter<ID, T>(
                                  data[apiExcludeField] as unknown as ID[],
                                  getById,
                                  definitions,
                              )
                            : [],
                    };
                    return output;
                },
                {},
            ),
        };

        return fieldState;
    };
}

export function getRangeFromApiFormat(
    minApiProp: keyof APIFilterFormat,
    maxApiProp: keyof APIFilterFormat,
): FilterStateFromAPI {
    return (
        data: APIFilterFormat,
        prop: keyof APIFilterFormat,
        definitions: FromApiFormatData,
    ): FilterState => {
        return {
            min: data[minApiProp] ?? null,
            max: data[maxApiProp] ?? null,
        } as RangeFilterState;
    };
}

////////////////////
// To Api methods //
////////////////////

export const classificationToAPI = (value: string) => `emotions:${capitalize(value)}`;

function copyStateToAPIIfValid(
    filterState: FilterState,
    output: {[key: string]: any},
    prop: string,
) {
    if (isValidValue(filterState)) {
        output[prop] = filterState;
    }
}

export const selectFilterToAPIFormat = copyStateToAPIIfValid;
export const textfieldFilterToAPIFormat = copyStateToAPIIfValid;

export function getTextfieldToApiFormat(
    apiProp: KeyOfType<Required<APIFilterFormat>, string>,
): FilterStateToAPI {
    return (filterState: FilterState, output: APIFilterFormat): void => {
        if (isValidValue(filterState)) {
            output[apiProp] = filterState as TextfieldFilterState;
        }
    };
}

export function checkboxesToApiFormat<T = string>(
    filterState: FilterState,
    output: APIFilterFormat,
    prop: KeyOfType<Required<APIFilterFormat>, T[]>,
    mapFunc?: (value: string) => T,
): void {
    const value = filterState as CheckboxesFilterState;
    const values = Object.keys(value).filter((val) => value[val]); //Get all the keys that have an associated value = true

    if (values.length > 0) {
        //Not sure wy I need this, but it errors if I don't
        (output as any)[prop] = mapFunc ? values.map(mapFunc) : values;
    }
}

export function getCheckboxesToApiFormat<T = string>(
    mapFunc: (value: string) => T,
    apiProp?: KeyOfType<Required<APIFilterFormat>, T[]>,
): FilterStateToAPI {
    return (
        filterState: FilterState,
        output: APIFilterFormat,
        prop: keyof APIFilterFormat,
    ): void => {
        checkboxesToApiFormat<T>(
            filterState,
            output,
            apiProp || (prop as KeyOfType<Required<APIFilterFormat>, T[]>),
            mapFunc,
        );
    };
}

export function getIncludeAndExcludeToApiFormat<ItemType = string, IdType = string>(
    fields: MapIncludeAndExcludeToApiArgs<ItemType, IdType>[],
): FilterStateToAPI {
    return (filterState: FilterState, output: APIFilterFormat, prop: string): void => {
        const value = filterState as IncludeAndExcludeFilterState;
        fields.forEach(
            ({
                field,
                apiIncludeField,
                apiExcludeField,
                valueToAPI = (x) => x as unknown as IdType,
            }: MapIncludeAndExcludeToApiArgs<ItemType, IdType>) => {
                if (value.fields[field]) {
                    if (apiIncludeField && value.fields[field].include.length > 0) {
                        (output as any)[apiIncludeField] = [
                            ...value.fields[field].include.map(valueToAPI),
                        ];
                    }

                    if (apiExcludeField && value.fields[field].exclude.length > 0) {
                        (output as any)[apiExcludeField] = [
                            ...value.fields[field].exclude.map(valueToAPI),
                        ];
                    }
                }
            },
        );
    };
}

export function getRangeToApiFormat(
    minApiProp: KeyOfType<Required<APIFilterFormat>, number>,
    maxApiProp: KeyOfType<Required<APIFilterFormat>, number>,
): FilterStateToAPI {
    return (filterState: FilterState, output: APIFilterFormat, prop: string): void => {
        const value = filterState as RangeFilterState;

        if (value.min !== null) {
            output[minApiProp] = value.min;
        }

        if (value.max !== null) {
            output[maxApiProp] = value.max;
        }
    };
}

export function getPageTypesToAPI(
    pageTypes: PageType[],
    apiProp: KeyOfType<Required<APIFilterFormat>, string[]> | undefined = undefined,
): FilterStateToAPI {
    return (filterState: FilterState, output: APIFilterFormat, prop: string): void => {
        const value: CheckboxesFilterState = filterState as CheckboxesFilterState;
        const values = Object.keys(value).filter((val) => value[val]);

        const key: KeyOfType<Required<APIFilterFormat>, string[]> = (apiProp || prop) as KeyOfType<
            Required<APIFilterFormat>,
            string[]
        >;

        //TODO apply 'non-available' pageTypes using the 'x' filter?
        output[key] = values.length > 0 ? values : pageTypes.map((pageType) => pageType.value);
    };
}

export const classificationFromAPI = (value: string | boolean) => {
    const str = value.toString();
    const emotionsStr = 'emotions:';

    return (str.indexOf(str) === 0 ? str.substr(emotionsStr.length) : str).toLowerCase();
};
