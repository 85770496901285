import {useMemo} from 'react';

import {selectSearches} from 'hsi/selectors';

import {useLinkedinChannels} from 'hsi/hooks/useDataSource';
import {useAppSelector} from './useRedux';

export const useSearchesById = () => {
    const searches = useAppSelector(selectSearches);
    const linkedinChannels = useLinkedinChannels();

    const _searchesById = useMemo(
        () =>
            searches?.reduce((result: any, item: any) => {
                result[item.id] = item;
                return result;
            }, {}) || {},
        [searches],
    );

    const linkedinById = useMemo(
        () =>
            linkedinChannels?.data?.reduce<Record<string, any>>((result, item) => {
                result[item.id] = item;
                return result;
            }, {}) || {},
        [linkedinChannels?.data],
    );

    //XXX include LI channels in multiple searches (TODO linkedin: remove this when LI is implemented without BCR channels)
    const searchesById = {..._searchesById, ...linkedinById};

    return {searchesById};
};
