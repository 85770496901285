import {scalePow, scaleLinear} from 'd3-scale';
import max from 'lodash/max';

import {getChartRangeMax} from 'hsi/utils/chart';
import {formatBigInt} from 'hsi/utils/formatNumber';

class ColorScale {
    constructor(values, colors, defaultColor) {
        this.colors = colors;
        this.range = this.colors.length - 1;
        this.maxVal = Math.max(getChartRangeMax(max(values)), this.range);
        this.defaultColor = defaultColor || '#f0f2f2';

        if (this.maxVal <= this.colors.length) {
            this.scale = scaleLinear()
                .domain([1, Math.max(this.maxVal, this.range)])
                .rangeRound([0, this.range])
                .clamp(true);
        } else {
            this.scale = scalePow()
                .exponent(0.2)
                .domain([1, this.maxVal])
                .rangeRound([0, this.range])
                .clamp(true);
        }
    }

    getColor(val) {
        return val ? this.colors[this.scale(val)] : this.defaultColor;
    }

    getBucketLabel = (from, to) => `${formatBigInt(from)} - ${formatBigInt(to)}`;

    getBuckets() {
        // color buckets
        let ticks;
        if (this.maxVal <= this.colors.length) {
            ticks = [...this.colors.keys()].map((v) => v + 1);
        } else {
            ticks = [...this.colors.keys()]
                .map((v) => v + 0.5)
                .map((v) => parseInt(Math.floor(this.scale.invert(v))));
        }
        const buckets = [{label: {id: '0_mentions'}, color: this.defaultColor}];
        let lastTick = 0;
        ticks.forEach((t, i) => {
            buckets.push({
                label: this.getBucketLabel(lastTick + 1, t),
                color: this.colors[i],
            });
            lastTick = t;
        });
        return buckets;
    }
}

export default ColorScale;
