import {useCallback, useState} from 'react';

import MenuRoot from './MenuRoot';
import SelectOwnedInstagramAccounts from './SelectOwnedInstagramAccounts';
import SelectNonOwnedFacebookPages from './SelectNonOwnedFacebookPages';
import SelectNonOwnedInstagramAccounts from './SelectNonOwnedInstagramAccounts';
import SelectOwnedFacebookPages from './SelectOwnedFacebookPages';
import SelectTwitterAccounts from './SelectTwitterAccounts';

import useDataSourceCount from 'hsi/hooks/useDataSourceCount';

import {GuidedSearchTargetedDataTypes} from '../../../utils';
import {ConfigType} from 'hsi/types/config';

export interface SelectAccountsProps {
    info: ReturnType<typeof useDataSourceCount>;
    menuLabel: string;
    onCancel: () => void;
    onChange: (values: GuidedSearchTargetedDataTypes[]) => void;
    setLoading: (value: boolean) => void;
    updatePopper: () => void;
    values: GuidedSearchTargetedDataTypes[];
}

const SelectAccounts = (props: SelectAccountsProps) => {
    const [currentSelect, setCurrentSelect] = useState<
        ConfigType['guidedSearch']['socialAccounts'][number]['selectorType'] | null
    >(null);

    const onBack = useCallback(() => {
        setCurrentSelect(null);
        setTimeout(props.updatePopper);
    }, [props?.updatePopper]);

    switch (currentSelect) {
        case 'twitter':
            return <SelectTwitterAccounts onBack={onBack} {...props} />;
        case 'facebookOwned':
            return <SelectOwnedFacebookPages onBack={onBack} {...props} />;
        case 'facebookNonOwned':
            return <SelectNonOwnedFacebookPages onBack={onBack} {...props} />;
        case 'instagramOwned':
            return <SelectOwnedInstagramAccounts onBack={onBack} {...props} />;
        case 'instagramNonOwned':
            return <SelectNonOwnedInstagramAccounts onBack={onBack} {...props} />;
        default:
            return <MenuRoot {...props} onSelectAccountType={setCurrentSelect} />;
    }
};

export default SelectAccounts;
